import { Component, HostListener, OnInit } from '@angular/core';
import { LoginTokenService } from '../../services/login-token.service';
import { LoginToken } from '../../models/LoginToken.model';
import { AlertController, InfiniteScrollCustomEvent, LoadingController, ToastController } from '@ionic/angular';
import { AllDataService } from '../../services/all-data.service';
import { AuthService } from '../../services/auth/auth.service';
import { Profile } from '../../models/Profile.model';
import { CommonService } from '../../services/common.service';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-login-token-info',
  templateUrl: './login-token-info.component.html',
  styleUrls: ['./login-token-info.component.scss'],
})
export class LoginTokenInfoComponent implements OnInit {

  isModalOpen = false;
  completeList: LoginToken[] = [];
  filteredList: LoginToken[] = [];
  viewFilteredList: LoginToken[] = [];

  loginPhone: string = null;
  loading: HTMLIonLoadingElement = null;
  selectedProfile: Profile = null;
  isMobile: boolean = null;

  constructor(
    private loginTokenService: LoginTokenService,
    private allDataService: AllDataService,
    private authService: AuthService,
    private toastController: ToastController,
    private loadingCtrl: LoadingController,
    private alertController: AlertController,
    private commonService: CommonService
  ) { }

  ngOnInit() { }

  ngOnDestroy() { }

  async getLoginTokens(clicked?: boolean) {
    try {
      this.isMobile = this.commonService.isMobile();
      this.loginPhone = this.authService.getLoginPhone();
      await this.showLoading('Loading login list...');
      this.completeList = await this.loginTokenService.getAll();
      this.filteredList = [];
      if(this.completeList?.length) {
        this.loading?.dismiss();
      } else {
        this.loading?.dismiss();
        this.commonService.generateToast('danger', 'Something went wrong.')
      }
      this.selectedProfile = await this.allDataService.profileService.getCurrentProfile();
      if(this.selectedProfile?.accessTo?.length && Array.isArray(this.selectedProfile?.accessTo)) {
        this.selectedProfile?.accessTo?.forEach(staff => {
          this.filteredList.push(...this.completeList?.filter(x => staff?.userId == x?.userId));
        });
      }
  
      this.filteredList.push(...this.completeList?.filter(x => this.loginPhone == x?.userId));
  
      this.filteredList.sort((a,b) => b?.createdStamp - a?.createdStamp);
  
      this.viewFilteredList = [...this.filteredList.slice(0,50)]
  
      if(clicked) {
        if(this.completeList?.length) {
          this.commonService.generateToast('success', 'Refresh Login Device List');
        } else {
          this.commonService.generateToast('danger', 'Something went wrong.');
        }
      }
    } catch (error) {
      SentryUtilites.setLog("LoginTokenInfoComponent:getLoginTokens", error)
    }
  }

  async showLoading(msg: string) {
    try {
      this.loading = await this.loadingCtrl.create({ 
        message: msg
      });
      this.loading.present();
    } catch (error) {
      SentryUtilites.setLog("LoginTokenInfoComponent:showLoading", error)
    }
  }

  openModal(value: boolean = true) {
    try {
      this.isModalOpen = value;
      if(this.isModalOpen) {
        this.getLoginTokens();
      }
    } catch (error) {
      SentryUtilites.setLog("LoginTokenInfoComponent:openModal", error)
    }
  }

  async presentLogoutConfirm(loginToken: LoginToken) {
    try {
      const alert = await this.alertController.create({
        header: 'Alert!',
        message: 'Are you sure you want to Logout this device?',
        mode: 'ios',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {},
          },
          {
            text: 'Logout',
            role: 'confirm',
            cssClass: 'alert-button-logout',
            handler: () => {
              this.initiateLogout(loginToken)
            },
          },
        ],
      });
      
      await alert.present();
    } catch (error) {
      SentryUtilites.setLog("LoginTokenInfoComponent:presentLogoutConfirm", error)
    }
  }

  async initiateLogout(loginToken: LoginToken) {
    try {
      let res = await this.loginTokenService.initiateLogout(loginToken?._localUUID);
      if(res) {
        this.commonService.generateToast('success', 'Logout process initiated');
      } else {
        this.commonService.generateToast('danger', 'Something went wrong.');
      }
    } catch (error) {
      SentryUtilites.setLog("LoginTokenInfoComponent:initiateLogout", error)
    }
  }

  checkPhoneLogin(device: string) {
    try {
      if(
        device.includes('ios') ||
        device.includes('ipad') ||
        device.includes('iphone') ||
        device.includes('android') ||
        device.includes('tablet') ||
        device.includes('mobile') ||
        device.includes('phablet') ||
        device.includes('cordova')
        ) {
          return true;
        }
      return false;
    } catch (error) {
      SentryUtilites.setLog("LoginTokenInfoComponent:checkPhoneLogin", error)
      return false;
    }
  }

  getStaff(id: string) {
    try {
      let filterStaff = this.selectedProfile?.accessTo?.filter(staff => staff?.userId == id);
      return filterStaff[0]?.name || filterStaff[0]?.userId;
    } catch (error) {
      SentryUtilites.setLog("LoginTokenInfoComponent:getStaff", error)
      return '';
    }
  }

  dismissLoggedinDevices() {
    try {
      this.filteredList = [];
      this.isModalOpen = false;
    } catch (error) {
      SentryUtilites.setLog("LoginTokenInfoComponent:dismissLoggedinDevices", error)
    }
  }

  @HostListener('window:resize', ['$event'])
  OnResize() {
    try {
      setTimeout(()=> {
        this.isMobile = this.commonService.isMobile();
      }, 20)
    } catch (error) {
      SentryUtilites.setLog("LoginTokenInfoComponent:OnResize", error)
    }
  }

  loadMoreListData(event: any) {
    try {
      if (
        this.viewFilteredList.length > 0 &&
        this.viewFilteredList.length <= this.filteredList.length
      ) {
        let appendListLength = this.filteredList.length - this.viewFilteredList.length;
        let lastEl = this.viewFilteredList[this.viewFilteredList.length - 1];
        let counter = 0;
        for (let i = 0; i < this.filteredList.length; i++) {
          if (this.filteredList[i]._localUUID == lastEl._localUUID) {
            counter = 1;
            continue;
          }
          if (counter > 0 && appendListLength >= 50) {
            if (counter <= 50) {
              this.viewFilteredList.push({ ...this.filteredList[i] });
            } else {
              break;
            }
            counter++;
          } else if(counter > 0 && appendListLength < 50) {
            if (counter <= appendListLength) {
              this.viewFilteredList.push({ ...this.filteredList[i] })
            } else {
              break;
            }
            counter++;
          }
        }
        (event as InfiniteScrollCustomEvent)?.target?.complete();
      }
    } catch (error) {
      SentryUtilites.setLog("LoginTokenInfoComponent:loadMoreListData", error)
    }
  }

}
