import { Injectable } from "@angular/core";
import { Utility } from "../utils/utility";
import { ExpressServerService } from "./api/express-server.service";
import { AuthService } from "./auth/auth.service";
import { Ingredient } from "../models/Ingredient.model";
import { SentryUtilites } from "../utils/sentryUtilites";

@Injectable({
  providedIn: 'root'
})
export class IngredientService {
  
  constructor(
    private expressServerService: ExpressServerService,
    private authService: AuthService,
  ) { }
  
  async getByUUID(uuid: string): Promise<Ingredient> {
    try {
      let res = await this.expressServerService.makeGetAllCall(`ingredient/getByUUID?uuid=${uuid}`);
      return res?.['records'] || null;
    } catch (error) {
      SentryUtilites.setLog("IngredientService:getByUUID", error)
      return null;
    }
  }

  async getWithOpeningStockByUUID(uuid: string): Promise<Ingredient> {
    try {
      let res = await this.expressServerService.makeGetAllCall(`ingredient/getWithOpeningStockByUUID?uuid=${uuid}`);
      return res?.['records'] || null;
    } catch (error) {
      SentryUtilites.setLog("IngredientService:getWithOpeningStockByUUID", error)
      return null;
    }
  }

  async getAll(): Promise<Ingredient[]> {
    try {
      let profileId = Utility.getFromLocalStorage('selectedProfile');
      let userId = Utility.getFromLocalStorage('selectedProfileUserId');
      let res = await this.expressServerService.makeGetAllCall(`ingredient/getAllByProfile?profileId=${profileId}&userId=${userId}`);
      return res?.['records'] || [];
    } catch (error) {
      SentryUtilites.setLog("IngredientService:getAll", error)
      return [];
    }
  }

  async getAllWithOpeningStockByProfile() : Promise<Ingredient[]> {
    try {
      let profileId = Utility.getFromLocalStorage('selectedProfile');
      let userId = Utility.getFromLocalStorage('selectedProfileUserId');
      let res = await this.expressServerService.makeGetAllCall(`ingredient/getAllWithOpeningStockByProfile?profileId=${profileId}&userId=${userId}`);
      return res?.['records'] || [];
    } catch (error) {
      SentryUtilites.setLog("IngredientService:getAllWithOpeningStockByProfile", error)
      return [];
    }
  }

  async save(ingredient: Ingredient): Promise<Ingredient> {
    try {
      if(Utility.isTruthy(ingredient)) {
        let profileId = Utility.getFromLocalStorage('selectedProfile');
        let selectedProfileUserId = Utility.getFromLocalStorage('selectedProfileUserId');
        ingredient.userId = selectedProfileUserId;
        ingredient.profileId = profileId;
        ingredient.createdBy = ingredient.lastModifiedBy = this.authService.getLoginPhone();
        ingredient.createdByName = ingredient.lastModifiedByName = Utility.getCreatedByName();
        let timeStamp = +new Date();
        ingredient.createdStamp = timeStamp;
        ingredient.updatedStamp = timeStamp;
        ingredient.userUpdatedStamp = timeStamp;
        if(!ingredient?.deletedStamp) {
          ingredient.deletedStamp = 0;
        }
        ingredient.syncStamp = 0;
        if(!ingredient?.deletedStamp) {
          ingredient._localUUID = Utility.getUUID();
        }
        delete ingredient?.['_id'];
        delete ingredient?.todayEOD?.['_id'];
        delete ingredient?.yesterdayEOD?.['_id'];
    
    
        let res = await this.expressServerService.makeEzoOfServerPostCall(`ingredient/save`,{ingredient});
        return res?.['records'] || null;
      } else {
        return null;
      }
    } catch (error) {
      SentryUtilites.setLog("IngredientService:save", error)
      return null;
    }
  }

  async update(ingredient: Ingredient): Promise<Ingredient> {
    return new Promise(async (resolve, reject) => {
      try {
        if(ingredient?._localUUID) {
          ingredient.lastModifiedBy = this.authService.getLoginPhone();
          ingredient.lastModifiedByName = Utility.getCreatedByName();
          ingredient.updatedStamp = +new Date();
          ingredient.userUpdatedStamp = ingredient.updatedStamp;
          delete ingredient['_id'];
      
          let res = await this.expressServerService.makeEzoOfServerPostCall(`ingredient/save`,{ingredient});
          return resolve(res?.['records'] || null);
        }
        return resolve(null);
      } catch (error) {
        SentryUtilites.setLog("IngredientService:update", error)
        return resolve(null);
      }
    })
  }

  

}