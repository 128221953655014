import { MonthWiseItemStockDao } from './dao/month-wise-item-stock.dao';
import { MonthWisePartyCreditDao } from './dao/month-wise-party-credit.dao';
import { MonthWisePartyCreditService } from './month-wise-party-credit.service';
import { MonthWiseItemStockService } from './month-wise-item-stock.service';
import { EstimateDao } from './dao/estimate.dao';
import { ItemStockAdjustService } from './item-stock-adjust.service';
import { ItemStockAdjustDao } from './dao/item-stock-adjust.dao';
import { KotDao } from './dao/kot.dao';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ExpressServerService } from './api/express-server.service';
import { PartyService } from './party.service';
import { ItemService } from './item.service';
import { SaleService } from './sale.service';
import { EstimateService } from './estimate.service';
import { MoneyInService } from './money-in.service';
import { PurchaseService } from './purchase.service';
import { MoneyOutService } from './money-out.service';
import { PartyCategoryService } from './party-category.service';
import { ItemCategoryService } from './item-category.service';
import { ProfileService } from './profile.service';
import { ProfileDao } from './dao/profile.dao';
import { PartyDao } from './dao/party.dao';
import { ItemDao } from './dao/item.dao';
import { MoneyInDao } from './dao/money-in.dao';
import { MoneyOutDao } from './dao/money-out.dao';
import { ItemCategoryDao } from './dao/item-category.dao';
import { PartyCategoryDao } from './dao/party-category.dao';
import { SaleDao } from './dao/sale.dao';
import { PurchaseDao } from './dao/purchase.dao';
import { KotService } from './kot.service';
import * as Ably from 'ably';
import { AuthService } from './auth/auth.service';
import { IItem, Item } from '../models/Item.model';
import { Utility } from '../utils/utility';
import { IDataRepo } from '../../interface/IDataRepo.interface';
import ItemCategory, { IItemCategory } from '../models/ItemCategory.model';
import { IItemStockAdjust, ItemStockAdjust } from '../models/ItemStockAdjust.model';
import Party, { IParty } from '../models/Party.model';
import PartyCategory, { IPartyCategory } from '../models/PartyCategory.model';
import { IMoneyIn, MoneyIn } from '../models/MoneyIn.model';
import { IMoneyOut, MoneyOut } from '../models/MoneyOut.model';
import { ISale, Sale } from '../models/Sale.model';
import { IPurchase, Purchase } from '../models/Purchase.model';
import { IDataService } from '../../interface/IDataService.interface';
import { IProfile, Profile } from '../models/Profile.model';
import { ImageDao } from './dao/image.dao';
import { ImageService } from './image.service';
import { IKot,Kot } from '../models/Kot.model';
import { IImage,Image } from '../models/image.model';
import { IItemUnit, ItemUnit } from '../models/ItemUnit.model';
import { ItemUnitDao } from './dao/item-unit.dao';
import { ItemUnitService } from './item-unit.service';
import PartyItemPriceMap, { IPartyItemPriceMap } from '../models/PartyItemPriceMap.model';
import { PartyItemPriceMapDao } from './dao/party-item-price-map.dao';
import { PartyItemPriceMapService } from './party-item-price-map.service';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { LicenceDao } from './dao/licence.dao';
import { LicenceService } from './licence.service';
import { ILicence, Licence } from '../models/Licence.model';
import { ExpenseDao } from './dao/expense.dao';
import { ExpenseService } from './expense.service';
import { Expense, IExpense } from '../models/Expense.model';
import { CutOffDayDao } from './dao/cut-off-day.dao';
import { CutOffDayService } from './cut-off-day.service';
import { CutOffDay, ICutOffDay } from '../models/CutOffDay.model';
import { LoginTokenService } from './login-token.service';
import { LogoutService } from './logout.service';
import { Estimate, IEstimate } from '../models/Estimate.model';
import { BillItem } from '../models/BillItem.model';
import { Utils } from '../utils/utils';
import { SentryUtilites } from '../utils/sentryUtilites';
import { ISaleReturn, SaleReturn } from '../models/SaleReturn.model';
import { SaleReturnDao } from './dao/sale-return.dao';
import { SaleReturnService } from './sale-return.service';
import { PurchaseReturnDao } from './dao/purchase-return.dao';
import { PurchaseReturnService } from './purchase-return.service';
import { IPurchaseReturn, PurchaseReturn } from '../models/PurchaseReturn.model';

@Injectable({
  providedIn: 'root'
})
export class AllDataService {

  collectionsSchemaMap = {
    "CutOffDay": ICutOffDay.SCHEMA_NAME,
    "Expense": IExpense.SCHEMA_NAME,
    "Image": IImage.SCHEMA_NAME,
    "ItemCategory": IItemCategory.SCHEMA_NAME,
    "Item": IItem.SCHEMA_NAME,
    "ItemStockAdjust": IItemStockAdjust.SCHEMA_NAME,
    "ItemUnit": IItemUnit.SCHEMA_NAME,
    "Kot": IKot.SCHEMA_NAME,
    "Licence": ILicence.SCHEMA_NAME,
    "MoneyIn": IMoneyIn.SCHEMA_NAME,
    "MoneyOut": IMoneyOut.SCHEMA_NAME,
    "PartyCategory": IPartyCategory.SCHEMA_NAME,
    "Party": IParty.SCHEMA_NAME,
    "PartyItemPriceMap": IPartyItemPriceMap.SCHEMA_NAME,
    "Profile": IProfile.SCHEMA_NAME,
    "Purchase": IPurchase.SCHEMA_NAME,
    "PurchaseReturn": IPurchaseReturn.SCHEMA_NAME,
    "Sale": ISale.SCHEMA_NAME,
    "SaleReturn": ISaleReturn.SCHEMA_NAME,
    "Estimate": IEstimate.SCHEMA_NAME
  }

  listForceReloadSubs = new BehaviorSubject<
    'item-list' | 'party-list' | 'sale-list' | 'sale-return-list' | 'estimate-list' |
    'purchase-list' | 'purchase-return-list' | 'expense-list' | 'moneyin-list' | 'moneyout-list' |
    'profile-list' | 'item-stock-adjust-list' | 'image-list' | 'licence-list' |
    'kot-list' | 'cut-off-day-list' | 'item-category-list' | 'party-category-list' |
    'item-unit-list' | 'party-item-price-map-list' |
    'all-list' | ''>('');

  lastSyncSubs = new BehaviorSubject<boolean>(true);

  collectionToListSubsMap = {
    itemCategory: 'item-category-list',
    itemUnit: 'item-unit-list',
    item: 'item-list',
    profile: 'profile-list',
    licence: 'licence-list',
    itemStockAdjust: 'item-stock-adjust-list',
    party: 'party-list',
    cutOffDay: 'cut-off-day-list',
    partyCategory: 'party-category-list',
    partyItemPriceMap: 'party-item-price-map-list',
    moneyIn: 'moneyin-list',
    moneyOut: 'moneyout-list',
    sale: 'sale-list',
    saleReturn: 'sale-return-list',
    estimate: 'estimate-list',
    purchase: 'purchase-list',
    purchaseReturn: 'purchase-return-list',
    expense: 'expense-list',
    kot: 'kot-list',
    image: 'image-list',
  };

  profileDao: ProfileDao;
  licenceDao: LicenceDao;
  itemCategoryDao: ItemCategoryDao;
  itemUnitDao: ItemUnitDao;
  partyCategoryDao: PartyCategoryDao;
  partyItemPriceMapDao: PartyItemPriceMapDao;
  partyDao: PartyDao;
  cutOffDayDao: CutOffDayDao;
  itemDao: ItemDao;
  itemStockAdjustDao: ItemStockAdjustDao;
  moneyInDao: MoneyInDao;
  moneyOutDao: MoneyOutDao;
  saleDao: SaleDao;
  saleReturnDao: SaleReturnDao;
  estimateDao : EstimateDao;
  purchaseDao: PurchaseDao;
  purchaseReturnDao: PurchaseReturnDao;
  expenseDao: ExpenseDao;
  kotDao: KotDao;
  imageDao: ImageDao;

  profileService: ProfileService;
  licenceService: LicenceService;
  partyService: PartyService;
  cutOffDayService: CutOffDayService;
  itemService: ItemService;
  itemStockAdjustService: ItemStockAdjustService;
  saleService: SaleService;
  saleReturnService: SaleReturnService;
  estimateService : EstimateService;
  moneyInService: MoneyInService;
  purchaseService: PurchaseService;
  purchaseReturnService: PurchaseReturnService;
  expenseService: ExpenseService;
  moneyOutService: MoneyOutService;
  partyCategoryService: PartyCategoryService;
  partyItemPriceMapService: PartyItemPriceMapService;
  itemCategoryService: ItemCategoryService;
  itemUnitService: ItemUnitService;
  kotService: KotService;
  imageService: ImageService;

  isTimeDifference = false;

  isPreviousDataSyncComplete: boolean = true;
  collectionsTrueMap: {
    [key: string]: boolean;
  } = {}

  constructor(
    private ngxIndexedDBService: NgxIndexedDBService,
    private expressServerService: ExpressServerService,
    private loginTokenService: LoginTokenService,
    private authService: AuthService,
    private router: Router,
    private logOutService: LogoutService,
    private monthWisePartyCreditService: MonthWisePartyCreditService,
    private monthWiseItemStockService: MonthWiseItemStockService,
    private monthWisePartyCreditDao: MonthWisePartyCreditDao,
    private monthWiseItemStockDao: MonthWiseItemStockDao,
  ) {
    this.profileDao = ProfileDao.getInstance(this.ngxIndexedDBService, this.router);
    this.licenceDao = LicenceDao.getInstance(this.ngxIndexedDBService, this.router);
    this.partyCategoryDao = PartyCategoryDao.getInstance(this.ngxIndexedDBService, this.router);
    this.partyItemPriceMapDao = PartyItemPriceMapDao.getInstance(this.ngxIndexedDBService, this.router);
    this.itemCategoryDao = ItemCategoryDao.getInstance(this.ngxIndexedDBService, this.router);
    this.itemUnitDao = ItemUnitDao.getInstance(this.ngxIndexedDBService, this.router);
    this.partyDao = PartyDao.getInstance(this.ngxIndexedDBService, this.router);
    this.cutOffDayDao = CutOffDayDao.getInstance(this.ngxIndexedDBService, this.router);
    this.itemDao = ItemDao.getInstance(this.ngxIndexedDBService, this.router);
    this.itemStockAdjustDao = ItemStockAdjustDao.getInstance(this.ngxIndexedDBService, this.router);
    this.moneyInDao = MoneyInDao.getInstance(this.ngxIndexedDBService, this.router);
    this.moneyOutDao = MoneyOutDao.getInstance(this.ngxIndexedDBService, this.router);
    this.saleDao = SaleDao.getInstance(this.ngxIndexedDBService, this.router);
    this.saleReturnDao = SaleReturnDao.getInstance(this.ngxIndexedDBService, this.router);
    this.estimateDao = EstimateDao.getInstance(this.ngxIndexedDBService, this.router);
    this.purchaseDao = PurchaseDao.getInstance(this.ngxIndexedDBService, this.router);
    this.purchaseReturnDao = PurchaseReturnDao.getInstance(this.ngxIndexedDBService, this.router);
    this.expenseDao = ExpenseDao.getInstance(this.ngxIndexedDBService, this.router);
    this.kotDao = KotDao.getInstance(this.ngxIndexedDBService, this.router);
    this.imageDao = ImageDao.getInstance(this.ngxIndexedDBService, this.router);

    this.profileService = ProfileService.getInstance(this.profileDao, this.partyDao, this.itemDao, this.expressServerService, this.authService, this.monthWisePartyCreditDao, this.monthWiseItemStockDao);
    this.licenceService = LicenceService.getInstance(this.licenceDao, this.expressServerService, this.authService);
    this.partyService = PartyService.getInstance(this.partyDao, this.saleDao, this.saleReturnDao, this.purchaseDao, this.purchaseReturnDao, this.expenseDao, this.moneyInDao, this.moneyOutDao, this.expressServerService,this.authService, this.monthWisePartyCreditService);
    this.cutOffDayService = CutOffDayService.getInstance(this.cutOffDayDao, this.expressServerService, this.authService);
    this.itemService = ItemService.getInstance(this.itemDao, this.expressServerService,this.authService, this.monthWiseItemStockService);
    this.itemStockAdjustService = ItemStockAdjustService.getInstance(this.itemStockAdjustDao, this.itemDao, this.expressServerService,this.authService, this.monthWiseItemStockDao);
    this.saleService = SaleService.getInstance(this.saleDao, this.partyDao, this.itemDao, this.moneyInDao, this.partyItemPriceMapDao, this.profileDao, this.expressServerService,this.authService, this.monthWisePartyCreditDao, this.monthWiseItemStockDao);
    this.saleReturnService = SaleReturnService.getInstance(this.saleReturnDao, this.partyDao, this.itemDao, this.saleDao, this.moneyOutDao, this.partyItemPriceMapDao, this.profileDao, this.expressServerService,this.authService, this.monthWisePartyCreditDao, this.monthWiseItemStockDao);
    //TODO remove estimate service comment
    this.estimateService = EstimateService.getInstance(this.estimateDao, this.partyDao, this.itemDao, this.partyItemPriceMapDao, this.profileDao, this.expressServerService,this.authService);
    this.moneyInService = MoneyInService.getInstance(this.moneyInDao, this.partyDao, this.saleDao, this.purchaseReturnDao, this.expressServerService,this.authService, this.monthWisePartyCreditDao);
    this.purchaseService = PurchaseService.getInstance(this.purchaseDao, this.partyDao, this.itemDao, this.moneyOutDao, this.expressServerService,this.authService, this.monthWisePartyCreditDao, this.monthWiseItemStockDao);
    this.purchaseReturnService = PurchaseReturnService.getInstance(this.purchaseReturnDao, this.partyDao, this.itemDao, this.purchaseDao, this.moneyInDao, this.expressServerService,this.authService, this.monthWisePartyCreditDao, this.monthWiseItemStockDao);
    this.expenseService = ExpenseService.getInstance(this.expenseDao, this.partyDao, this.moneyOutDao, this.expressServerService,this.authService, this.monthWisePartyCreditDao);
    this.moneyOutService = MoneyOutService.getInstance(this.moneyOutDao, this.partyDao, this.purchaseDao, this.expenseDao, this.saleReturnDao, this.expressServerService,this.authService, this.monthWisePartyCreditDao);
    this.partyCategoryService = PartyCategoryService.getInstance(this.partyCategoryDao, this.expressServerService,this.authService);
    this.partyItemPriceMapService = PartyItemPriceMapService.getInstance(this.partyItemPriceMapDao, this.expressServerService,this.authService);
    this.itemCategoryService = ItemCategoryService.getInstance(this.itemCategoryDao, this.expressServerService,this.authService);
    this.itemUnitService = ItemUnitService.getInstance(this.itemUnitDao, this.expressServerService,this.authService);
    this.kotService = KotService.getInstance(this.kotDao, this.expressServerService,this.authService);
    this.imageService = ImageService.getInstance(this.imageDao, this.expressServerService,this.authService);
    setInterval(() => {
      if(Utility.getFromLocalStorage('Initial_data_download_complete') && Utility.getFromLocalStorage('isPremiumAccess') && this.isPreviousDataSyncComplete) {
        this.checkFetchAll();
      }
    }, environment.syncIntervalMillis);
    if(Utility.getFromLocalStorage('Initial_data_download_complete')) {
      this.socketConnect();
    }
  }

  _socketSubscriber:Subscription;

  async socketConnect(){
    // if(this._socketSubscriber){
    //   this._socketSubscriber.unsubscribe();
    // }
    // let currentProfile=await this.profileService.getCurrentProfile();
    // let profileId=currentProfile._localUUID;
    // let observer=this.expressServerService.getServerSentEvent(`https://db.ezobooks.in/kappa/socket/connect/${profileId}`).subscribe(packet =>{
    //   // console.log(packet);
    //   if(packet.data){
    //     // console.log("fetching all");
    //     this.checkFetchAll();
    //   }
    // });
    // this._socketSubscriber=observer;
  }

  /**
   * 
   * @param collectionsName : provide collection Namw
   * @description : check all collection sync from server for those collections are true from checkFetchAll 
   */
  checkDataSyncComplete(collectionsName: string) {
    this.collectionsTrueMap[collectionsName] = true;
    for(const key in this.collectionsTrueMap) {
      if(!this.collectionsTrueMap[key]) {
        return;
      }
    }
    this.isPreviousDataSyncComplete = true;
  }
  // ------------------------------------

  trySyncUnsynced() {
    try {
      this.profileService.trySyncUnsynced();
      this.partyService.trySyncUnsynced();
      this.cutOffDayService.trySyncUnsynced();
      this.itemService.trySyncUnsynced();
      this.itemStockAdjustService.trySyncUnsynced();
      this.moneyInService.trySyncUnsynced();
      this.saleService.trySyncUnsynced();
      this.saleReturnService.trySyncUnsynced();
      this.estimateService.trySyncUnsynced();
      this.moneyOutService.trySyncUnsynced();
      this.purchaseService.trySyncUnsynced();
      this.purchaseReturnService.trySyncUnsynced();
      this.expenseService.trySyncUnsynced();
      this.partyCategoryService.trySyncUnsynced();
      this.partyItemPriceMapService.trySyncUnsynced();
      this.itemCategoryService.trySyncUnsynced();
      this.itemUnitService.trySyncUnsynced();
      this.kotService.trySyncUnsynced();
      this.imageService.trySyncUnsynced();
      this.isPreviousDataSyncComplete = true;
    } catch (error: any) {
      SentryUtilites.setLog("AllDataService:trySyncUnsynced", error)
    }
  }

  initService() {
    this.listForceReloadSubs.next('profile-list');
    this.profileService.initService();
    this.listForceReloadSubs.next('party-list');
    this.partyService.initService();
    this.listForceReloadSubs.next('cut-off-day-list');
    this.cutOffDayService.initService();
    this.listForceReloadSubs.next('item-list');
    this.itemService.initService();
    this.listForceReloadSubs.next('item-stock-adjust-list');
    this.itemStockAdjustService.initService();
    this.listForceReloadSubs.next('sale-list');
    this.saleService.initService();
    this.listForceReloadSubs.next('sale-return-list');
    this.saleReturnService.initService();
    this.listForceReloadSubs.next('estimate-list');
    this.estimateService.initService();
    this.listForceReloadSubs.next('moneyin-list');
    this.moneyInService.initService();
    this.listForceReloadSubs.next('purchase-list');
    this.purchaseService.initService();
    this.listForceReloadSubs.next('purchase-return-list');
    this.purchaseReturnService.initService();
    this.listForceReloadSubs.next('expense-list');
    this.expenseService.initService();
    this.listForceReloadSubs.next('moneyout-list');
    this.moneyOutService.initService();
    this.partyCategoryService.initService();
    this.partyItemPriceMapService.initService();
    this.itemCategoryService.initService();
    this.itemUnitService.initService();
    this.kotService.initService();
    this.listForceReloadSubs.next('image-list');
    this.imageService.initService();
  }

  registerListner() {
    try {
      let phone = this.authService.getLoginPhone();
      if (phone) {
        let options: Ably.Types.ClientOptions = { key: 'Po63uA.5mswoA:BpwqEaNOwfS4inRPWrZa0eNcGPCr_9bErTajR3g6bVo' };
        let client = new Ably.Realtime(options); /* inferred type Ably.Realtime */
        let channel = client.channels.get('data-sync'); /* inferred type Ably.Types.RealtimeChannel */
        channel.subscribe(phone, (message) => {
          if (message?.data?.type == "ItemCategory") {
            this.collectionSyncInterval('itemCategory',IItemCategory.SCHEMA_NAME, this.itemCategoryDao, this.itemCategoryService);
          } else if (message?.data?.type == "ItemUnit") {
            this.collectionSyncInterval('itemUnit',IItemUnit.SCHEMA_NAME ,this.itemUnitDao, this.itemUnitService);
          } else if (message?.data?.type == "Item") {
            this.collectionSyncInterval('item',IItem.SCHEMA_NAME ,this.itemDao, this.itemService);
          } else if (message?.data?.type == "Profile") {
            this.collectionSyncInterval('profile',IProfile.SCHEMA_NAME ,this.profileDao, this.profileService);
          } else if (message?.data?.type == "Licence") {
            this.collectionSyncInterval('licence',ILicence.SCHEMA_NAME ,this.licenceDao, this.licenceService);
          } else if (message?.data?.type == "ItemStockAdjust") {
            this.collectionSyncInterval('itemStockAdjust',IItemStockAdjust.SCHEMA_NAME, this.itemStockAdjustDao, this.itemStockAdjustService);
          } else if (message?.data?.type == "Party") {
            this.collectionSyncInterval('party',IParty.SCHEMA_NAME, this.partyDao, this.partyService);
          } else if (message?.data?.type == "CutOffDay") {
            this.collectionSyncInterval('cutOffDay',ICutOffDay.SCHEMA_NAME, this.cutOffDayDao, this.cutOffDayService);
          } else if (message?.data?.type == "PartyCategory") {
            this.collectionSyncInterval('partyCategory',IPartyCategory.SCHEMA_NAME, this.partyCategoryDao, this.partyCategoryService);
          }  else if (message?.data?.type == "PartyItemPriceMap") {
            this.collectionSyncInterval('partyItemPriceMap',IPartyItemPriceMap.SCHEMA_NAME, this.partyItemPriceMapDao, this.partyItemPriceMapService);
          } else if (message?.data?.type == "MoneyIn") {
            this.collectionSyncInterval('moneyIn',IMoneyIn.SCHEMA_NAME, this.moneyInDao, this.moneyInService);
          } else if (message?.data?.type == "MoneyOut") {
            this.collectionSyncInterval('moneyOut',IMoneyOut.SCHEMA_NAME, this.moneyOutDao, this.moneyOutService);
          } else if (message?.data?.type == "Sale") {
            this.collectionSyncInterval('sale',ISale.SCHEMA_NAME, this.saleDao, this.saleService);
          } else if (message?.data?.type == "SaleReturn") {
            this.collectionSyncInterval('saleReturn',ISaleReturn.SCHEMA_NAME, this.saleReturnDao, this.saleReturnService);
          } else if (message?.data?.type == "Estimate") { //estimate
            this.collectionSyncInterval('estimate',IEstimate.SCHEMA_NAME, this.estimateDao, this.estimateService);
          } else if (message?.data?.type == "Purchase") {
            this.collectionSyncInterval('purchase',IPurchase.SCHEMA_NAME, this.purchaseDao, this.purchaseService);
          } else if (message?.data?.type == "PurchaseReturn") {
            this.collectionSyncInterval('purchaseReturn',IPurchaseReturn.SCHEMA_NAME, this.purchaseReturnDao, this.purchaseReturnService);
          } else if (message?.data?.type == "Expense") {
            this.collectionSyncInterval('expense',IExpense.SCHEMA_NAME, this.expenseDao, this.expenseService);
          } else if (message?.data?.type == "Kot") {
            this.collectionSyncInterval('kot',IKot.SCHEMA_NAME, this.kotDao, this.kotService);
          } else if (message?.data?.type == "Image") {
            this.collectionSyncInterval('image',IImage.SCHEMA_NAME, this.imageDao, this.imageService);
          }
  
        });
      }
    } catch (error: any) {
      SentryUtilites.setLog("AllDataService:registerListner", error)
    }
  }

  async dataSyncInterval(collection: string): Promise<boolean> {
    try {
      if(collection == 'Profile') {
        this.collectionSyncInterval('profile',IProfile.SCHEMA_NAME ,this.profileDao, this.profileService);
      }else if(collection == 'Licence') {
        this.collectionSyncInterval('licence',ILicence.SCHEMA_NAME ,this.licenceDao, this.licenceService);
      }else if(collection == 'Item') {
        await this.collectionSyncInterval('item',IItem.SCHEMA_NAME ,this.itemDao, this.itemService);
      }else if(collection == 'ItemCategory') {
        this.collectionSyncInterval('itemCategory',IItemCategory.SCHEMA_NAME, this.itemCategoryDao, this.itemCategoryService);
      }else if(collection == 'ItemStockAdjust') {
        await this.collectionSyncInterval('itemStockAdjust',IItemStockAdjust.SCHEMA_NAME, this.itemStockAdjustDao, this.itemStockAdjustService);
      }else if(collection == 'ItemUnit') {
        this.collectionSyncInterval('itemUnit',IItemUnit.SCHEMA_NAME, this.itemUnitDao, this.itemUnitService);
      }else if(collection == 'Party') {
        await this.collectionSyncInterval('party',IParty.SCHEMA_NAME, this.partyDao, this.partyService);
      }else if(collection == 'CutOffDay') {
        this.collectionSyncInterval('cutOffDay',ICutOffDay.SCHEMA_NAME, this.cutOffDayDao, this.cutOffDayService);
      }else if(collection == 'PartyCategory') {
        this.collectionSyncInterval('partyCategory',IPartyCategory.SCHEMA_NAME, this.partyCategoryDao, this.partyCategoryService);
      }else if(collection == 'PartyItemPriceMap') {
        this.collectionSyncInterval('partyItemPriceMap',IPartyItemPriceMap.SCHEMA_NAME, this.partyItemPriceMapDao, this.partyItemPriceMapService);
      }else if(collection == 'MoneyIn') {
        await this.collectionSyncInterval('moneyIn',IMoneyIn.SCHEMA_NAME, this.moneyInDao, this.moneyInService);
      }else if(collection == 'MoneyOut') {
        await this.collectionSyncInterval('moneyOut',IMoneyOut.SCHEMA_NAME, this.moneyOutDao, this.moneyOutService);
      }else if(collection == 'Sale') {
        await this.collectionSyncInterval('sale',ISale.SCHEMA_NAME, this.saleDao, this.saleService);
      }else if(collection == 'SaleReturn') {
        await this.collectionSyncInterval('saleReturn',ISaleReturn.SCHEMA_NAME, this.saleReturnDao, this.saleReturnService);
      }else if(collection == 'Estimate') {
        this.collectionSyncInterval('estimate',IEstimate.SCHEMA_NAME, this.estimateDao, this.estimateService);
      }else if(collection == 'Purchase') {
        await this.collectionSyncInterval('purchase',IPurchase.SCHEMA_NAME, this.purchaseDao, this.purchaseService);
      } else if (collection == "PurchaseReturn") {
        await this.collectionSyncInterval('purchaseReturn',IPurchaseReturn.SCHEMA_NAME, this.purchaseReturnDao, this.purchaseReturnService);
      } else if(collection == 'Expense') {
        await this.collectionSyncInterval('expense',IExpense.SCHEMA_NAME, this.expenseDao, this.expenseService);
      }else if(collection == 'Kot') {
        this.collectionSyncInterval('kot',IKot.SCHEMA_NAME, this.kotDao, this.kotService);
      }else if(collection == 'Image') {
        this.collectionSyncInterval('image',IImage.SCHEMA_NAME, this.imageDao, this.imageService);
      }
      //this.collectionSyncInterval('priceMap',IItemStockAdjust.SCHEMA_NAME, this.itemStockAdjustDao, this.itemStockAdjustService);
      return true;
    } catch (error: any) {
      SentryUtilites.setLog("AllDataService:dataSyncInterval", error)
      return false;
    }
  }

  async checkFetchAll() {
    try {
      let fullPath = `check/fetchAllv3`;
      let profiles = await this.profileService.getAllByPromise();
      let ownerUserId = this.authService.getLoginPhone();
  
      let collectionWiseLastSyncStamp = {};
  
      for(const key in this.collectionsSchemaMap) {
        let accessProfiles:{
          profileId:string,
          userId: string,
          lastSyncStamp:number
        }[] = [];
  
        let accessProfilesIdArr: string[] = [];
        let accessProfilesUserIdArr: string[] = [];
  
        profiles?.forEach(profile => {
          if(profile?.userId != ownerUserId) {
            accessProfilesIdArr.push(profile?._localUUID);
            accessProfilesUserIdArr.push(profile?.userId);
            accessProfiles.push({
              profileId: profile?._localUUID,
              userId: profile?.userId,
              lastSyncStamp: Utility.getCollectionSyncStamp(this.collectionsSchemaMap[key], key=='licence' ? profile?.userId : profile?._localUUID)
            });
          }
        });
  
        collectionWiseLastSyncStamp[key] = {
          lastSyncStamp:Utility.getCollectionSyncStamp(this.collectionsSchemaMap[key]),
          accessProfiles
        };
      }
  
      let body = {
        collectionWiseLastSyncStamp
      }
  
      let res = await this.expressServerService.makeFetchAllCall(fullPath,body);
  
      if(res?.isLogoutRequest) {
        await this.logOutService.logout()
        return null;
      }

      this.collectionsTrueMap = {};

      for(const key in res?.collections) {
        if(res?.collections[key]) {
          this.collectionsTrueMap[Utility.firstLetterLowerCaseOfWord(key)] = false;
        }
      }

      if(Object?.keys(this.collectionsTrueMap)?.length) {
        this.isPreviousDataSyncComplete = false;
      }
  
      for(const key in res?.collections) {
        if(res?.collections[key]) {
          await this.dataSyncInterval(key);
        }
      }

      for(const key in res?.collections) {
        if(
          res?.collections[key] && (
            key == 'Sale' ||
            key == 'SaleReturn' ||
            key == 'Purchase' ||
            key == 'PurchaseReturn' ||
            key == 'Expense' ||
            key == 'MoneyOut' ||
            key == 'MoneyIn' ||
            key == 'ItemStockAdjust' ||
            key == 'Item' ||
            key == 'Party'
          )
        ) {
          this.listForceReloadSubs.next(this.collectionToListSubsMap[Utility.firstLetterLowerCaseOfWord(key)]);
        }
      }
  
      if(
        res?.collections['Sale'] ||
        res?.collections['SaleReturn'] ||
        res?.collections['Purchase'] ||
        res?.collections['PurchaseReturn'] ||
        res?.collections['Expense'] ||
        res?.collections['MoneyOut'] ||
        res?.collections['MoneyIn']
      ) {
        this.listForceReloadSubs.next('party-list');
      }
  
      if(
        res?.collections['Sale'] ||
        res?.collections['SaleReturn'] ||
        res?.collections['Purchase'] ||
        res?.collections['PurchaseReturn'] ||
        res?.collections['ItemStockAdjust']
      ) {
        this.listForceReloadSubs.next('item-list');
      }
    } catch (error: any) {
      SentryUtilites.setLog("AllDataService:checkFetchAll", error)
    }
  }

  async checkPro() {
    try {
      let fullPath = `licence/checkPro`;
      let body = {};
      let res = await this.expressServerService.makeFetchAllCall(fullPath,body);
      if(
        res?.proExpiryStamp 
        && Utility.isNumber(res?.proExpiryStamp) 
        && Number(res?.proExpiryStamp) > +new Date()
      ) {
        this.dataSyncInterval('Licence');
      }
    } catch (error: any) {
      SentryUtilites.setLog("AllDataService:checkPro", error)
      return null;
    }
  }

  async checkFetchAllLicence() {
    try {
      let fullPath = `check/licenceFetchAllv3`;
      let profiles = await this.profileService.getAllByPromise();
      let ownerUserId = this.authService.getLoginPhone();
  
      let collectionWiseLastSyncStamp = {};
  
      let accessProfiles:{
        profileId:string,
        userId: string,
        lastSyncStamp:number
      }[] = [];
  
      let accessProfilesIdArr: string[] = [];
      let accessProfilesUserIdArr: string[] = [];
  
      profiles?.forEach(profile => {
        if(profile?.userId != ownerUserId) {
          accessProfilesIdArr.push(profile?._localUUID);
          accessProfilesUserIdArr.push(profile?.userId);
          accessProfiles.push({
            profileId: profile?._localUUID,
            userId: profile?.userId,
            lastSyncStamp: Utility.getCollectionSyncStamp(this.collectionsSchemaMap['Licence'], profile?.userId)
          });
        }
      });
  
      collectionWiseLastSyncStamp['Licence'] = {
        lastSyncStamp:Utility.getCollectionSyncStamp(this.collectionsSchemaMap['Licence']),
        accessProfiles
      };
  
      let body = {
        collectionWiseLastSyncStamp
      }
  
      let res = await this.expressServerService.makeFetchAllCall(fullPath,body);
  
      if(res?.isLogoutRequest) {
        await this.logOutService.logout()
        return null;
      }
  
      for(const key in res?.collections) {
        if(res?.collections[key]) {
          this.dataSyncInterval(key);
        }
      }
    } catch (error: any) {
      SentryUtilites.setLog("AllDataService:checkFetchAllLicence", error)
      return null;
    }
  }

  async collectionSyncInterval(
    collection: string,
    schemaName: string,
    collectionDao: IDataRepo<
      Item | ItemCategory | ItemUnit | ItemStockAdjust |
      Party | PartyCategory | PartyItemPriceMap | MoneyIn | MoneyOut | Estimate |
      Sale | SaleReturn | Purchase | PurchaseReturn | Profile | Kot | Image | Licence | Expense | CutOffDay
    >,
    collectionService: IDataService<
      Item | ItemCategory | ItemUnit | ItemStockAdjust |
      Party | PartyCategory | PartyItemPriceMap | MoneyIn | MoneyOut | Estimate |
      Sale | SaleReturn | Purchase | PurchaseReturn | Profile | Kot | Image | Licence | Expense | CutOffDay
    >
  ): Promise<boolean> {
    try {
      let isEmitListSubs = false;
      let currentResponseRecordLength: number = 0;
      do {
        this.isTimeDifference = false;
        Utility.setToLocalStorage('isTimeZoneMisMatched',this.isTimeDifference);
  
        // let fullPath = `${collection}/getAll?lastSyncStamp=${Utility.getCollectionSyncStamp(collection)}`;
        let fullPath = `${collection}/fetchAllv3`;
  
        let profiles = await this.profileService.getAllByPromise();
        let ownerUserId = this.authService.getLoginPhone();
        let accessProfiles:{
          profileId:string,
          userId: string,
          lastSyncStamp:number
        }[] = [];
  
        let accessProfilesIdArr: string[] = [];
        let accessProfilesUserIdArr: string[] = [];
  
        profiles?.forEach(profile => {
          if(profile?.userId != ownerUserId) {
            accessProfilesIdArr.push(profile?._localUUID);
            accessProfilesUserIdArr.push(profile?.userId);
            accessProfiles.push({
              profileId: profile?._localUUID,
              userId: profile?.userId,
              lastSyncStamp: Utility.getCollectionSyncStamp(schemaName, collection=='licence' ? profile?.userId : profile?._localUUID)
            });
          }
        });
  
        let body = {
          lastSyncStamp:Utility.getCollectionSyncStamp(schemaName),
          accessProfiles
        };
  
        let res = await this.expressServerService.makeFetchAllCall(fullPath,body);
        currentResponseRecordLength = 0;
        if (res && res?.['records']) {
          currentResponseRecordLength = res?.['records']?.length || 0;
  
          if(
            currentResponseRecordLength && (
              collection == 'profile' ||
              collection == 'licence' ||
              collection == 'itemCategory' ||
              collection == 'itemUnit' ||
              collection == 'cutOffDay' ||
              collection == 'partyCategory' ||
              collection == 'partyItemPriceMap' ||
              collection == 'estimate' ||
              collection == 'kot' ||
              collection == 'image'
            )
          ) {
            isEmitListSubs = true;
          }
  
          let st:{d: number, h: number, m: number, s: number, mm: number, y: number} = res['st'];
          if(!this.isTimeDifference && st?.d) {
            let serverDt = new Date();
            serverDt.setDate(st.d);
            serverDt.setHours(st.h);
            serverDt.setMinutes(st.m);
            serverDt.setSeconds(st.s);
            serverDt.setMonth(st.mm);
            serverDt.setFullYear(st.y);
  
            let serverDtStamp = +new Date(serverDt);
            let clientDtStamp = +new Date();
  
            let timeDelta = clientDtStamp - serverDtStamp;
            this.isTimeDifference = (Math.abs(timeDelta) > (1000*60*15));
  
            Utility.setToLocalStorage('isTimeZoneMisMatched',this.isTimeDifference);
  
          }
  
          Utility.setCollectionLastRespSyncStamp(collection,+new Date());
  
          let records: any[] = res?.['records'];
          let latestUpdateStamp = 0;
          let dataSetUpdated = false;
  
          if(collection=='profile') {
            if(records?.length) {
              let loginPhone = this.authService.getLoginPhone();
              let accessRemoved = false;
  
              records?.forEach((profile: Profile) => {
                if(profile?.userId != loginPhone) {
                  let accessTo = profile?.accessTo;
                  if(Array.isArray(accessTo)) {
                    let matchedAccessTo = accessTo?.filter(accessTo => accessTo?.userId==loginPhone);
                    if(!matchedAccessTo?.length) {
                      accessRemoved = true;
                    }
                  }
                }
              });
  
              if(accessRemoved) {
                await this.logOutService.logout();
                return null;
              }else {
                this.profileService.setCreatedByName();
              }
  
            }
          }
  
          let resAccessProfilesIdArr:string[] = [];
          let resAccessUserIdArr:string[] = [];
  
          for (let i = 0; i < records?.length; i++) {
            let record = records[i];
  
            if(accessProfilesIdArr.indexOf(record?.profileId) != -1) {
              if(resAccessProfilesIdArr.indexOf(record?.profileId) === -1) {
                resAccessProfilesIdArr.push(record?.profileId);
              }
            }
  
            if(collection == 'licence') {
              if(accessProfilesUserIdArr.indexOf(record?.userId) != -1) {
                if(resAccessUserIdArr.indexOf(record?.userId) === -1) {
                  resAccessUserIdArr.push(record?.userId);
                }
              }
            }
  
            let oldRecord:any = await collectionDao?.getByUUID(record?._localUUID);
            if (oldRecord && oldRecord?.updatedStamp < record?.updatedStamp) {
              record._localId = oldRecord?._localId;
              await collectionDao?.updateDb(record);
              dataSetUpdated = true;
              collectionService?.updateSubs?.next(record);
  
              // Revert Old Credits
              await this.revertCredit(collection,oldRecord);
              // -------------------------------------------------------------------------------------------
              
              // Add New Credit if record is not deleted
              await this.addCredit(collection,record);
              // --------------------------------------------------------------------------------------------
  
              // Revert Old Stocks
              await this.revertStock(collection,oldRecord);
              // -------------------------------------------------------------------------------------------
              
              // Add New Stock if record is not deleted
              await this.addStock(collection,record);
              // --------------------------------------------------------------------------------------------
  
            } else if(!oldRecord) {
              if(record?.syncStamp <= record?.updatedStamp) {
                record.syncStamp = record?.updatedStamp + 10;
              }
              await collectionDao?.saveDb(record);
              dataSetUpdated = true;
  
              // if(collection == 'party') {
              //   await this.monthWisePartyCreditService.save(record?._localUUID);
              // }else if(collection == 'item') {
              //   await this.monthWiseItemStockService.save(record?._localUUID);
              // }else 
              if(collection == 'itemStockAdjust') {
                await this.monthWiseItemStockDao?.modifyStock(
                  record?.linkedItemUUID,
                  record?.createdStamp,
                  Number(record?.quantity)
                );
              }else {
                await this.addCredit(collection,record);
                await this.addStock(collection,record);
              }
            }
  
            if (Number(record?.updatedStamp) > latestUpdateStamp) {
              latestUpdateStamp = Number(record?.updatedStamp)

              // fixed issue of Profile is getting continuously true in check api, because accessprofile lastSyncStamp going zero of profile payload 
              if(schemaName == 'profile') {
                Utility.setCollectionSyncStamp(schemaName, latestUpdateStamp, record?._localUUID);
              }
            }

          }
  
          latestUpdateStamp = latestUpdateStamp > 0 ? latestUpdateStamp : +new Date();
  
          Utility.setCollectionSyncStamp(schemaName, latestUpdateStamp);
  
          resAccessProfilesIdArr?.forEach(profileId => {
            Utility.setCollectionSyncStamp(schemaName, latestUpdateStamp, profileId);
          });
          if(collection == 'licence') {
            resAccessUserIdArr?.forEach(userId => {
              Utility.setCollectionSyncStamp(schemaName, latestUpdateStamp, userId);
            });
            this.listForceReloadSubs.next('licence-list')
          }
  
          if(currentResponseRecordLength < 500 && collection!='licence') {
            accessProfilesIdArr?.forEach(profileId => {
              let currentLastSyncStamp =  Utility.getCollectionSyncStamp(schemaName,profileId);
              if(!currentLastSyncStamp && !currentResponseRecordLength) {
                Utility.setCollectionSyncStamp(schemaName, +new Date(), profileId);
              }
            });
          }
  
          if (dataSetUpdated) {
            collectionService?.reloadList();
          }
  
        }
  
      }while(currentResponseRecordLength == 500)
  
      if(collection == 'image') {
        // this.imageService.getImageBase64();
        // ? all-list emit at last sync entity
        this.listForceReloadSubs.next('all-list')
      }
  
      this.lastSyncSubs.next(true);
  
      if(isEmitListSubs) {
        this.listForceReloadSubs.next(this.collectionToListSubsMap[collection])
      }

      this.checkDataSyncComplete(collection)
  
      return true;
    } catch (error: any) {
      SentryUtilites.setLog("AllDataService:collectionSyncInterval", error)
      return false;
    }
  }
  
  /**
   * 
   * @param collection : provide collection name
   * @param record : provide newRecord
   * @returns : updated monthWisePartyCredit object
   */
  async addCredit(collection: string, record: any) {
    try {
      if(!record?.deletedStamp) {
        if(collection == 'sale') {
          if(record?.billCompleteStamp) {
            await this.monthWisePartyCreditDao.modifyCredit(
              record?.party?._localUUID,
              record?.billDateStamp,
              record?.totalAmount
            );
          }
        }else if(collection == 'moneyOut' || collection == 'purchaseReturn') {
          await this.monthWisePartyCreditDao.modifyCredit(
            record?.party?._localUUID,
            record?.billDateStamp,
            record?.totalAmount
          );
        }else if(collection == 'expense' || collection == 'moneyIn' || collection == 'purchase') {
          await this.monthWisePartyCreditDao.modifyCredit(
            record?.party?._localUUID,
            record?.billDateStamp,
            -record?.totalAmount
          );
        } else if(collection == 'saleReturn') {
          if(record?.billCompleteStamp) {
            await this.monthWisePartyCreditDao.modifyCredit(
              record?.party?._localUUID,
              record?.billDateStamp,
              -record?.totalAmount
            );
          }
        }
       }
      return true;
    } catch (error: any) {
      SentryUtilites.setLog("AllDataService:addCredit", error)
      return false;
    }
  }
  // ----------------------------------------------------

  /**
   * 
   * @param collection : provide collection name
   * @param oldRecord : provide oldRecord
   * @returns : updated monthWisePartyCredit object
   */
  async revertCredit(collection: string, oldRecord: any) {
    try { 
      if(collection == 'sale') {
        if(oldRecord?.billCompleteStamp) {
          await this.monthWisePartyCreditDao.modifyCredit(
            oldRecord?.party?._localUUID,
            oldRecord?.billDateStamp,
            -oldRecord?.totalAmount
          );
        }
      }else if(collection == 'moneyOut' || collection == 'purchaseReturn') {
        await this.monthWisePartyCreditDao.modifyCredit(
          oldRecord?.party?._localUUID,
          oldRecord?.billDateStamp,
          -oldRecord?.totalAmount
        );
      }else if(collection == 'expense' || collection == 'moneyIn' || collection == 'purchase') {
        await this.monthWisePartyCreditDao.modifyCredit(
          oldRecord?.party?._localUUID,
          oldRecord?.billDateStamp,
          oldRecord?.totalAmount
        );
      } else if(collection == 'saleReturn') {
        if(oldRecord?.billCompleteStamp) {
          await this.monthWisePartyCreditDao.modifyCredit(
            oldRecord?.party?._localUUID,
            oldRecord?.billDateStamp,
            oldRecord?.totalAmount
          );
        }
      }
      return true;
    } catch (error: any) {
      SentryUtilites.setLog("AllDataService:revertCredit", error)
      return false;
    }
  }
  // ----------------------------------------------------

  /**
   * 
   * @param collection : provide collection name
   * @param record : provide newRecord
   * @returns : updated monthWiseItemStock object
   */
  async addStock(collection: string, record: any) {
    try {
      if(!record?.deletedStamp) {
        if(collection == 'sale' && record?.billCompleteStamp || collection == 'purchaseReturn') {
          const billItems: BillItem[] = record?.billItems || [];
          let billItemLength = billItems?.length;
          for(let i = 0; i < billItemLength; i++) {
            let billItem = billItems[i];
            let qty = billItem?.unit === billItem?.item?.primaryUnit ? billItem?.quantity : Utils.capFractionsToTwo(billItem?.quantity / (billItem?.convertRatioMultiplier || 1));
            await this.monthWiseItemStockDao?.modifyStock(
              billItem?.item?._localUUID,
              record?.billDateStamp,
              -qty
            )
          }
        } else if(collection == 'purchase' || collection == 'saleReturn' && record?.billCompleteStamp) {
          const billItems: BillItem[] = record?.billItems || [];
          let billItemLength = billItems?.length;
          for(let i = 0; i < billItemLength; i++) {
            let billItem = billItems[i];
            let qty = billItem?.unit === billItem?.item?.primaryUnit ? billItem?.quantity : Utils.capFractionsToTwo(billItem?.quantity / (billItem?.convertRatioMultiplier || 1));
            await this.monthWiseItemStockDao?.modifyStock(
              billItem?.item?._localUUID,
              record?.billDateStamp,
              qty
            )
          }
        }
      }
      return true;
    } catch (error: any) {
      SentryUtilites.setLog("AllDataService:addStock", error)
      return false;
    }
  }
  // ----------------------------------------------------

  /**
   * 
   * @param collection : provide collection name
   * @param oldRecord : provide oldRecord
   * @returns : updated monthWiseItemStock object
   */
  async revertStock(collection: string, oldRecord: any) {
    try {
      if(collection == 'sale' && oldRecord?.billCompleteStamp || collection == 'purchaseReturn') {
        const billItems: BillItem[] = oldRecord?.billItems || [];
        let billItemLength = billItems?.length;
        for(let i = 0; i < billItemLength; i++) {
          let billItem = billItems[i];
          let qty = billItem?.unit === billItem?.item?.primaryUnit ? billItem?.quantity : Utils.capFractionsToTwo(billItem?.quantity / (billItem?.convertRatioMultiplier || 1));
          await this.monthWiseItemStockDao?.modifyStock(
            billItem?.item?._localUUID,
            oldRecord?.billDateStamp,
            qty
          )
        }
      } else if(collection == 'purchase' || collection == 'saleReturn' && oldRecord?.billCompleteStamp) {
        const billItems: BillItem[] = oldRecord?.billItems || [];
        let billItemLength = billItems?.length;
        for(let i = 0; i < billItemLength; i++) {
          let billItem = billItems[i];
          let qty = billItem?.unit === billItem?.item?.primaryUnit ? billItem?.quantity : Utils.capFractionsToTwo(billItem?.quantity / (billItem?.convertRatioMultiplier || 1));
          await this.monthWiseItemStockDao?.modifyStock(
            billItem?.item?._localUUID,
            oldRecord?.billDateStamp,
            -qty
          )
        }
      }
      return true;
    } catch (error: any) {
      SentryUtilites.setLog("AllDataService:revertStock", error)
      return false;
    }
  }
  // ----------------------------------------------------

  async getAllUnsyncData() {
    try {
      
      let unsyncedCollections: {
        [key: string]: {
          count: number,
          getAllUnsynced:Function,
        }
      } = {
        'Items': {
          count: 0,
          getAllUnsynced: this.itemDao.getAllUnsynced.bind(this),
        },
        'ItemCategories': {
          count: 0,
          getAllUnsynced: this.itemCategoryDao.getAllUnsynced.bind(this),
        },
        'ItemStockAdjust': {
          count: 0,
          getAllUnsynced: this.itemStockAdjustDao.getAllUnsynced.bind(this),
        },
        'ItemUnit': {
          count: 0,
          getAllUnsynced: this.itemUnitDao.getAllUnsynced.bind(this),
        },
        'Parties': {
          count: 0,
          getAllUnsynced: this.partyDao.getAllUnsynced.bind(this),
        },
        'PartyCategories': {
          count: 0,
          getAllUnsynced: this.partyCategoryDao.getAllUnsynced.bind(this),
        },
        'Money Ins': {
          count: 0,
          getAllUnsynced: this.moneyInDao.getAllUnsynced.bind(this),
        },
        'Money Outs': {
          count: 0,
          getAllUnsynced: this.moneyOutDao.getAllUnsynced.bind(this),
        },
        'Sales': {
          count: 0,
          getAllUnsynced: this.saleDao.getAllUnsynced.bind(this),
        },
        'Sale Return': {
          count: 0,
          getAllUnsynced: this.saleReturnDao.getAllUnsynced.bind(this),
        },
        'Estimate':{
          count: 0,
          getAllUnsynced: this.estimateDao.getAllUnsynced.bind(this),
        },
        'Kot': {
          count: 0,
          getAllUnsynced: this.kotDao.getAllUnsynced.bind(this),
        },
        'Purchase': {
          count: 0,
          getAllUnsynced: this.purchaseDao.getAllUnsynced.bind(this),
        },
        'Purchase Return': {
          count: 0,
          getAllUnsynced: this.purchaseReturnDao.getAllUnsynced.bind(this),
        },
        'Expense': {
          count: 0,
          getAllUnsynced: this.expenseDao.getAllUnsynced.bind(this),
        },
        'Image': {
          count: 0,
          getAllUnsynced: this.imageDao.getAllUnsynced.bind(this),
        },
        'Cut Off Day': {
          count: 0,
          getAllUnsynced: this.cutOffDayDao.getAllUnsynced.bind(this),
        },
      };
  
      let totalUnsyncedRecords: number = 0;
      for(let collection in unsyncedCollections) {
        unsyncedCollections[collection].count = (await unsyncedCollections[collection].getAllUnsynced())?.length;
        totalUnsyncedRecords += unsyncedCollections[collection].count;
      }
  
      return {
        totalUnsyncedRecords,
        unsyncedCollections
      }
    } catch (error: any) {
      // TODO : return value in error case handle by shubham  
      SentryUtilites.setLog("AllDataService:revertStock", error)
      return null
    }
  }

}
