import { PurchaseBillPrint } from "src/models/PurchaseBillPrint.model";
import { Arrays } from "../utils/ReferenceData";
import { commonTempData } from "./commonTempData";
import { Utility } from "../utils/Utility";

export class Temp18Purchase {

  constructor(
    private data: PurchaseBillPrint,
  ) {
  }
  textFontSize = 3;
  boldTextFontSize = 3.3;
  headerFontSize = 5;
  totalSaving = 0;

  finalTaxPercentageTableObj = {};
  isTax = false;
  sameState = false;

  async main() {
    this.finalTaxPercentageTableObj = {};
    return `
      <style>
      ${commonTempData.invoiceHtmlTemplateCss()}
      .three-inch-holder *{
        margin:0;
        padding:0;
      }
      .three-inch-holder b{
        color:#000;
      }
      .three-inch-holder .text-sm{
        font-size:10px;
      }
      .text-3i-p{
        font-size:10px;
      }
      </style>
      <span class="invoice-template-main">
        <div 
          class="container-new arial three-inch-holder"
          style="width:70mm;background: white;padding: 2mm;padding-bottom: 70px;visibility: visible;-webkit-print-color-adjust: exact !important;color-adjust: exact !important;">
          ${this.header()}
          ${await this.templateBody()}
          ${await this.footer()}
        </div>
      </span>
      `
  }

  header() {
    return `
        ${this.sellerDetails()}
        ${this.invoiceTitle()}`;

  }

  async footer(){
    let domain = '';
    let includeDukanUrl = 0;
    try {
      includeDukanUrl = this.data?.settings?.include3InchOnlineDukanLink ? 1 : 0
    } catch (err) {

    }
    if (
      this.data?.onlineDukan?.domain != "") {
      domain = this.data?.onlineDukan?.domain;
    }

    let html = ``;
    let note = this.data?.bill?.note
    
    html = `<div class="row" style="margin-top:20px;"></div>
    `
    if (note) {
      html += `
    <div style="text-align:left">
      <p style="font-size:${this.textFontSize}mm;">(Note : ${note})</p>
    </div>
    <br/>
    `
    }
    
    html += `
    <div style="text-align:center">
        <p style="font-size:${this.textFontSize}mm;text-align: justify;text-align-last: center;word-break:break-word" >${(this.data?.extra?.thermalTncTemplate || '').replace(/\n/g, '<br/>')}</p>
        ${domain?.length > 0 && includeDukanUrl ? `
           <p style="font-size:${this.textFontSize}mm">Now Order Online @</p>
          <p style="font-size:${this.textFontSize}mm">https://${domain}.ezo.digital</p>
        `: ''}
        
      </div>
    `;

    // if(this.data?.qrPayLink){
    //   let qrCodeBase64 = await commonTempData.generateQR(this.data?.qrPayLink);

    //   html += `
    //   <div style="width: 100%; margin: auto;" class="m-l-neg-10 m-l-neg-2">
    //     <img style="width: 100%;" src="${qrCodeBase64}">
    //   </div>`;
    // }

    return html;
    
  }

  includeTaxPercentHeader = false;
  includeHsnHeader = false;
  includeMrpHeader = false;

  async templateBody() {
    let html = '';
    let itemRowsHtml = '';

    let totalQty = 0;
    let PaidAmt = 0;
    let totalTax = 0;
    let isTax = false;
    let totalTaxAmt = 0;
    let sameState = false;

    let partyInfo = this.data?.bill?.partyData?.profileData || null;
    
    let partyStateCode = partyInfo?.gstNumber?.substring(0,2) ? Utility.statesNamesByStateCode[partyInfo?.gstNumber?.substring(0,2)] : null;
      let profileStateCode = this.data?.profile?.gstin?.substring(0,2) ? Utility.statesNamesByStateCode[this.data?.profile?.gstin?.substring(0,2)] : null;
      let userState = profileStateCode || this.data?.profile?.addressProvience?.toUpperCase();
      let partyState = partyStateCode || this.data?.bill?.deliveryProvience?.toUpperCase();

      let gstStr='SGST';

      if(userState && partyState) {
        sameState = userState === partyState;
        if(sameState && Arrays.UTS.indexOf(userState) != -1) {
          gstStr='UTGST';
        } 
      } else {
        sameState = true;
        if(Arrays.UTS.indexOf(userState) != -1 || Arrays.UTS.indexOf(partyState) != -1) {
          gstStr='UTGST';
        } 
      }
      
    if (this.data?.bill?.gstAmount || this.data?.bill?.cessAmount) {
      totalTaxAmt = this.data?.bill?.gstAmount + this.data?.bill?.cessAmount;
      isTax = true;
    }

    if (this.data?.bill?.moneyOut) {
      PaidAmt = (this.data?.bill?.moneyOut?.amount ||  0);
    }

    if (this.data?.bill?.amountPaid) {
      PaidAmt = this.data?.bill?.amountPaid;
    }

    let gstTableObj = {};

    for (let i = 0; i < this.data?.bill?.billItems?.length; i++) {
      let invoiceItem = this.data?.bill?.billItems[i];
      if ((this.data?.settings?.pSetTaxMrpHsnPrintStatus===false) && (invoiceItem?.taxPercentage)) {
        this.includeTaxPercentHeader = true;
      }
      if (!(this.data?.settings?.pSetTaxMrpHsnPrintStatus===false) && (invoiceItem?.mrp || invoiceItem?.item?.mrp)) {
        this.includeMrpHeader = true;
      }
      if (!(this.data?.settings?.pSetTaxMrpHsnPrintStatus===false) && (invoiceItem?.hsn)) {
        this.includeHsnHeader = true;
      }

    }

    for (let i = 0; i < this.data?.bill?.billItems?.length; i++) {
      let invoiceItem = this.data?.bill?.billItems[i];
      totalTax += invoiceItem.unitTaxAmount || 0;
      totalQty += invoiceItem.quantity || 0;
      let el = invoiceItem;
      gstTableObj = this.calculationForHsnTable(el, gstTableObj);

      if ((el?.unitTaxAmount || el?.unitCessAmount) && !this.isTax) {
        this.isTax = true;
      }

      this.finalTaxPercentageTableObj = this.addSameTaxPercentageObjs(gstTableObj);
      try {
        itemRowsHtml += this.itemRow(i + 1, invoiceItem);
      } catch (err) {
        console.error(err);
      }
    }

    let itemNameColLength = 16 + 7 + 6 + 6;
    if (this.includeHsnHeader) { itemNameColLength -= 7 };
    if (this.includeTaxPercentHeader) { itemNameColLength -= 6 };
    if (this.includeMrpHeader) { itemNameColLength -= 6 };

    if(this.data?.bill?.totalSaving>0 && this.data?.settings?.billSavingsAmount){
      this.totalSaving=this.data?.bill?.totalSaving;
    }
    
    let htmlHeader = `
      <style>
      #table-3inch td,
      #table-3inch th,
      #table-3inch td p,
      #table-gst-breakup th,
      #table-gst-breakup td
      {
        font-size:${this.textFontSize}mm;
        vertical-align: top;
      }
      #table-gst-breakup th,
      #table-gst-breakup td{
        text-align:center !important;
      }
      </style>
      <table id="table-3inch">
        <thead>
          <tr>
            <th style="width:5mm !important">Sr</th>
            ${this.includeHsnHeader ? `<th style="width:7mm !important">HSN</th>` : ''}
            <th style="width:${itemNameColLength}mm !important;text-align:left;">Item Name</th>
            ${this.includeTaxPercentHeader ? `<th style="width:6mm !important">GST</th>` : ''}
            <th style="width:5mm !important">Qt</th>
            ${this.includeMrpHeader ? `<th style="width:7mm !important">MRP</th>` : ''}
            <th style="width:7mm !important">Rate</th>
            <th style="width:8mm !important">Amt</th>
          </tr>
        </thead>
        <tbody>
          ${itemRowsHtml}
        </tbody>
      </table>
      
       
      <div class="row">
        <hr/>
      </div>
      `;
    html = htmlHeader + html;
    let balanceAmt = ((this.data?.bill?.totalAmount || 0) - PaidAmt);
    let balHtml = '';
    
    let prevBalHtml = '';
    if(this.data?.bill.partyPreviousBalance > 0 && this.data?.bill?.billCompleteStamp) {
      prevBalHtml = `<b style="font-size:${this.boldTextFontSize}mm">Previous Bal :</b> <span style="font-size:${this.textFontSize}mm">${commonTempData.unitAdjuster(this.data?.bill?.partyPreviousBalance)}</span>`;
      prevBalHtml += `<br> <span style="font-size:${this.textFontSize}mm">as on ${commonTempData.dateToDDMMYY(this.data?.bill?.billCompleteStamp)}</span>`;
    }

    let serviceChargeStr=``;
    
    if(this.data?.bill?.serviceChargePercentage){
      serviceChargeStr=`<div class="col-xs-12" style="text-align:right">
      <b style="font-size:${this.boldTextFontSize}mm">Service Charge:</b> <span style="font-size:${this.textFontSize}mm">(${this.data?.bill?.serviceChargePercentage}%) ${this.data?.bill?.serviceChargeAmount}</span>
    </div>`;
    }
    html+=`
    <div class="row">
      <hr/>
    </div>
    <div class="row">
      <hr/>
      <div class="col-xs-12 text-3i-h3" style="text-align:right">
      </div>
      `
    

    let cashdiscounttag=``;
    if(this.data?.bill?.cashDiscount){
      cashdiscounttag +=
      `<div class="col-xs-12" style="text-align:right;font-size:${this.boldTextFontSize}mm">
      Cash Discount : ${commonTempData.unitAdjuster(this.data?.bill?.cashDiscount)} (${this.data?.bill?.cashDiscountPercentage}%)
      </div>`
    }
    html += `
        <div class="row">
          <hr/>
          ${cashdiscounttag}
          <div class="col-xs-6">
          
          </div>
          <div class="col-xs-6" style="text-align:right; font-size:${this.textFontSize}mm;">
            ${this.data?.bill?.roundOffValue ? `Round Off: ${this.data?.bill?.roundOffValue}` : '' }
          </div>
          <hr/>
          ${serviceChargeStr}
          <div class="col-xs-6" style="text-align:left">
            <b style="font-size:${this.boldTextFontSize}mm">Qty:</b> <span style="font-size:${this.textFontSize}mm">${commonTempData.unitAdjusterUptoThree(totalQty)}</span>
          </div>
          <div class="col-xs-9">
          </div>
          <div class="col-xs-3" style="text-align:right">
            ${balHtml}
          </div>
          <div class="col-xs-6">
          </div>
          <div class="col-xs-6" style="text-align:right">
            ${prevBalHtml}
          </div>
        </div>
        <div class="row">
          <hr/>
          ${this.includeTaxPercentHeader ? this.taxTable() : ''}
        </div>
        <div class="col-xs-12 text-3i-h3" style="text-align:right">
        Sub Total (without tax) : ${commonTempData.unitAdjuster(this.data?.bill?.subTotalAmount)}
        </div>
          <hr/>
          `
          if (!sameState && isTax) {
            html += this.commonTaxAndAmtDetails('IGST', commonTempData.unitAdjuster(this.data?.bill?.gstAmount));
          }
      
          if (sameState && isTax) {
            html += this.commonTaxAndAmtDetails(gstStr, commonTempData.unitAdjuster(this.data?.bill?.gstAmount / 2));
          }
      
          if (sameState && isTax) {
            html += this.commonTaxAndAmtDetails('CGST', commonTempData.unitAdjuster(this.data?.bill?.gstAmount / 2));
          }
      
          if (this.data?.bill?.cessAmount) {
            html += this.commonTaxAndAmtDetails('CESS', commonTempData.unitAdjuster(this.data?.bill?.cessAmount));
          }

          if (this.data?.bill?.roundOffValue) {
            html += this.commonTaxAndAmtDetails('Round off', commonTempData.unitAdjuster(this.data?.bill?.roundOffValue));
          }

          html +=` 
          <div class="col-xs-12" style="text-align:center">
          <hr/>
            <b  class="text-3i-h2">
              Total : ${commonTempData.unitAdjuster(this.data?.bill?.totalAmount)}
            </b>
          <hr/>
          </div>
          `
          html+=`
          <div class="row">
            <hr/>
          </div>
            `

    let additionalAmount = this.data?.bill?.additionalAmount || 0;
    if (additionalAmount) {
      html += this.commonTaxAndAmtDetails('Labour/Delivery Charges', additionalAmount);
    }

    if (this.data?.bill?.cashDiscount) {
      html += `<div class="col-xs-12" style="text-align:right">
              <b style="font-size:${this.boldTextFontSize}mm">Cash Discount : </b>
              <span style="font-size:${this.textFontSize}mm">${commonTempData.unitAdjuster(this.data?.bill?.cashDiscount)} (${this.data?.bill?.cashDiscountPercentage}%)</span>
            </div>`;
    }


    let receivedOrPaid =  'Paid Amount' ;
    let modeOfPayment =  this.data?.bill?.moneyOut?.txnMode || '';
    let txnNo =  this.data?.bill?.moneyOut?.txnRef || '';
    
    if (PaidAmt) {
      html += `<div class="col-xs-12" style="text-align:right">
              <b style="font-size:${this.boldTextFontSize}mm">${receivedOrPaid} : </b>
              <span style="font-size:${this.textFontSize}mm">${commonTempData.unitAdjuster(PaidAmt || 0)}</span>
              <br/>
              ${modeOfPayment ? ` <b style="font-size:${this.boldTextFontSize}mm">Mode Of Payment : </b>
              <span style="font-size:${this.textFontSize}mm">${modeOfPayment}</span>` : ''}
              ${txnNo ? ` <br/><b style="font-size:${this.boldTextFontSize}mm">Txn No : </b>
              <span style="font-size:${this.textFontSize}mm">${txnNo}</span>` : ''}
             
            </div>`;
    }

    if (this.totalSaving > 0) {
      html += `<div class="col-xs-12" style="text-align:center">
              <b style="font-size:${this.boldTextFontSize}mm">Total saving on this bill : </b>
              <span style="font-size:${this.textFontSize}mm">${commonTempData.unitAdjuster(this.totalSaving)}</span>
            </div>`;
    }

    if (balanceAmt > 0.01) {
      html += `<div class="col-xs-12" style="text-align:right"><b style="font-size:${this.boldTextFontSize}mm;">Bal :</b> <span style="font-size:${this.textFontSize}mm">${commonTempData.unitAdjuster(balanceAmt)}</span></div>`;
    }

    html += `
          
        </div>
        <div class="row">
          <hr/>
        </div>
      `
    return html;
  }

  commonTaxAndAmtDetails(title, value) {

    return `
          <div class="col-xs-9" style="text-align:right;font-size:${this.boldTextFontSize}mm">${title}</div>
          <div class="col-xs-3" style="text-align:right;font-size:${this.textFontSize}mm">${value}</div>
      `
  }

  itemRow(index,invoiceItem: any) {
    return `
        <tr>
            <td>${index}</td>
            ${this.includeHsnHeader ? `<td>${(((invoiceItem?.hsn) || '') + '')}</td>` : ''}
            <td style="text-align:left;">
              <p>${invoiceItem?.item?.itemName}</p>
              <p style="word-wrap: break-word;">${(((invoiceItem?.itemDes && invoiceItem.itemDes != "0") ? invoiceItem.itemDes : '') + '').substring(0, 100)}</p>
              <p style="word-wrap: break-word;">${(invoiceItem?.itemSrl ? '(' + invoiceItem.itemSrl + ')' : '')}</p>
            </td>
            ${this.includeTaxPercentHeader ? `<td>${invoiceItem?.taxPercentage ? invoiceItem?.taxPercentage + "%" : ''}</td>` : ''}
            <td>${commonTempData.unitAdjusterUptoThree(invoiceItem?.quantity)}</td>
            ${this.includeMrpHeader ? `<td>${invoiceItem?.mrp || (invoiceItem?.item?.mrp)?.toFixed(2)?.replace(/\.00$/, '') || ''}</td>` : ''}
            <td>${(invoiceItem?.price)?.toFixed(3)?.replace(/\.00$/, '')}</td>
            <td>${(invoiceItem?.total)?.toFixed(2)?.replace(/\.00$/, '')}</td>
        </tr>
      
      `
  }


  taxTable(){
    let gstTableHtml = `
      <h3 style="font-size:${this.boldTextFontSize + 1}mm;margin-top:5mm;">GST Breakup</h3>`
    let taxSlabs:any={};
    this.data?.bill?.billItems?.forEach((el)=>{
      
      if(el?.taxPercentage){
        if(!taxSlabs[el.taxPercentage]){
          taxSlabs[el.taxPercentage]={arr:[],taxableAmount:0,tax:0};
        }
        taxSlabs[el.taxPercentage].arr.push(el);
        taxSlabs[el.taxPercentage].taxableAmount+=(el.price*el.quantity);
        taxSlabs[el.taxPercentage].tax+=(el.itemTotalGstAmount)
      }
    });
    let partyInfo = (this.data?.bill?.partyData?.profileData || null);
    
    let partyStateCode = partyInfo?.gstNumber?.substring(0,2) ? Utility.statesNamesByStateCode[partyInfo?.gstNumber?.substring(0,2)] : null;
    let profileStateCode = this.data?.profile?.gstin?.substring(0,2) ? Utility.statesNamesByStateCode[this.data?.profile?.gstin?.substring(0,2)] : null;
    let userState = profileStateCode || this.data?.profile?.addressProvience?.toUpperCase();
    let partyState = partyStateCode || this.data?.bill?.deliveryProvience?.toUpperCase();

    let gstStr='SGST';

    if(userState && partyState) {
      this.sameState = userState === partyState;
      if(this.sameState && Arrays.UTS.indexOf(userState) != -1) {
        gstStr='UTGST';
      } 
    } else {
      this.sameState = true;
      if(Arrays.UTS.indexOf(userState) != -1 || Arrays.UTS.indexOf(partyState) != -1) {
        gstStr='UTGST';
      } 
    }

    let html = gstTableHtml;

    html += `
      <table id="table-gst-breakup" style="width: 100%; text-align: center;table-layout: fixed;">
      <thead class="no-b-btm" style="-webkit-print-color-adjust: exact !important;color-adjust: exact !important;">
        <tr>
        
        <td>Gross</td>
        <td>
          ${(!this.sameState && this.isTax) ? 'IGST' : gstStr}
        </td>
        <td>
          ${(!this.sameState && this.isTax) ? 'IGST Amt' : `${gstStr} Amt`}
        </td>`
        if (this.sameState && this.isTax) {
          html += `<td>CGST</td><td>CGST Amt</td>`
        }

    html += `
        </tr>`

    html += `
      </thead>
      <tbody>`
    let isTaxSlab=false;
      for(let k in taxSlabs){
        isTaxSlab=true;
        let cgst=false;
        if (this.sameState && this.isTax) {
          cgst=true;
        }
        html+=`<tr>
          <td>${taxSlabs[k].taxableAmount}</td>
          <td>${cgst?(Number(k)/2)?.toFixed(2)?.replace(/\.00$/, ''):(Number(k))?.toFixed(2)?.replace(/\.00$/, '')}%</td>
          <td>${cgst?(taxSlabs[k].tax/2)?.toFixed(2)?.replace(/\.00$/, ''):(taxSlabs[k].tax)?.toFixed(2)?.replace(/\.00$/, '')}</td>
          ${cgst?`
            <td>${(Number(k)/2)?.toFixed(2)?.replace(/\.00$/, '')}%</td>
            <td>${(taxSlabs[k].tax/2)?.toFixed(2)?.replace(/\.00$/, '')}</td>
          `:''}
          
        </tr>`
      }

    html += `
      </tbody>
      </table>
      `

    return isTaxSlab?html:'';
  }

  sellerDetails() {
    let html = "";
    if(this.data?.profile?.logoLink){
      html += `
      <div style="display: flow-root;width: 100%;">
        <img src="${this.data?.profile?.logoLink}" style="display: block;margin:auto;width:100px;height: 100px; max-width: none !important;">
      </div>
      `
    }
    let sellerProfileData = this.data?.profile;
    html += `<div style="text-align:center">
          <h3 style="font-size:${this.headerFontSize}mm;">${sellerProfileData?.legalName || ''}</h3>
          <p style="font-size:${this.textFontSize}mm">${sellerProfileData?.addressLine1 || ''},${sellerProfileData?.addressCity || ''}${sellerProfileData?.addressProvience ? ', ' + sellerProfileData?.addressProvience : ''}${sellerProfileData?.addressPostalCode ? ', ' + sellerProfileData?.addressPostalCode : ''}</p>
          <p style="font-size:${this.textFontSize}mm">${(sellerProfileData?.contactPersonPhone && sellerProfileData?.contactPersonPhone != '0') ? 'Phone: ' + sellerProfileData?.contactPersonPhone : ''}</p>
          <p style="font-size:${this.textFontSize}mm">${(sellerProfileData?.contactPersonEmail) ? 'Email: ' + sellerProfileData?.contactPersonEmail : ''}</p>
          <p style="font-size:${this.textFontSize}mm" style="word-break:break-word">${sellerProfileData?.gstin ? 'GST Number : ' + sellerProfileData?.gstin : ''}</p>
          <p style="font-size:${this.textFontSize}mm" style="word-break:break-word">${sellerProfileData?.fssaiNumber ? 'FSSAI No. : ' + sellerProfileData?.fssaiNumber : ''}</p>
          <p style="font-size:${this.textFontSize}mm" style="word-break:break-word">${sellerProfileData?.licenseNumber ? 'License No. : ' + sellerProfileData?.licenseNumber : ''}</p>
          <p style="font-size:${this.textFontSize}mm" style="word-break:break-word">
            ${
              sellerProfileData?.additionalInputFieldTitle1 
              && ((sellerProfileData?.additionalInputFieldTitle1 +"").trim()).length>0 
              && (sellerProfileData?.additionalInputFieldValue1)
              && ((sellerProfileData?.additionalInputFieldValue1 + "").trim()).length>0 ? `${sellerProfileData?.additionalInputFieldTitle1}:</b> ${sellerProfileData?.additionalInputFieldValue1}` : ''
            }
          </p>
          <p style="font-size:${this.textFontSize}mm" style="word-break:break-word">
            ${
              sellerProfileData?.additionalInputFieldTitle2 
              && ((sellerProfileData?.additionalInputFieldTitle2 +"").trim()).length>0 
              && (sellerProfileData?.additionalInputFieldValue2)
              && ((sellerProfileData?.additionalInputFieldValue2 + "").trim()).length>0 ? `${sellerProfileData?.additionalInputFieldTitle2}:</b> ${sellerProfileData?.additionalInputFieldValue2}` : ''
            }
          </p>
          <p style="font-size:${this.textFontSize}mm" style="word-break:break-word">
            ${
              sellerProfileData?.additionalInputFieldTitle3 
              && ((sellerProfileData?.additionalInputFieldTitle3 +"").trim()).length>0 
              && (sellerProfileData?.additionalInputFieldValue3)
              && ((sellerProfileData?.additionalInputFieldValue3 + "").trim()).length>0 ? `${sellerProfileData?.additionalInputFieldTitle3}:</b> ${sellerProfileData?.additionalInputFieldValue3}` : ''
            }
          </p>
          <p style="font-size:${this.textFontSize}mm" style="word-break:break-word">
            ${
              sellerProfileData?.additionalInputFieldTitle4 
              && ((sellerProfileData?.additionalInputFieldTitle4 +"").trim()).length>0 
              && (sellerProfileData?.additionalInputFieldValue4)
              && ((sellerProfileData?.additionalInputFieldValue4 + "").trim()).length>0 ? `${sellerProfileData?.additionalInputFieldTitle4}:</b> ${sellerProfileData?.additionalInputFieldValue4}` : ''
            }
          </p>
          <p style="font-size:${this.textFontSize}mm" style="word-break:break-word">
            ${
              sellerProfileData?.additionalInputFieldTitle5 
              && ((sellerProfileData?.additionalInputFieldTitle5 +"").trim()).length>0 
              && (sellerProfileData?.additionalInputFieldValue5)
              && ((sellerProfileData?.additionalInputFieldValue5 + "").trim()).length>0 ? `${sellerProfileData?.additionalInputFieldTitle5}:</b> ${sellerProfileData?.additionalInputFieldValue5}` : ''
            }
          </p>
        </div>`

    return html;

  }

  invoiceTitle() {
    let party = this.data?.bill?.partyData?.profileData || null;
    let isBillDateDiff=commonTempData.isBillDateDiff(this.data?.bill?.createdStamp,this.data?.bill?.billDateStamp);

    let html = `
        <div class="row">
          <hr/>
          <h4 style="font-size:${this.headerFontSize - 1}mm"  class="text-center">${commonTempData.getInvoiceTitle(this.data) || ''}</h4>
          <hr/>
          <div class="col-xs-12">
           <b style="font-size:${this.boldTextFontSize}mm">Bill No :</b> <span style="font-size:${this.boldTextFontSize}mm">${this.data?.bill?.billNo || ''}</span>
          </div>
          <div class="col-xs-12">
           <b style="font-size:${this.boldTextFontSize}mm">Created on :</b> <span style="font-size:${this.boldTextFontSize}mm">${commonTempData.dateToDDMMYYYHHMMAP(this.data?.bill?.createdStamp)}</span>
          </div>
          ${isBillDateDiff?`
            <div class="col-xs-12">
            <b style="font-size:${this.boldTextFontSize}mm">Date :</b> <span style="font-size:${this.boldTextFontSize}mm">${commonTempData.dateToDDMMYYY(this.data?.bill?.billDateStamp)}</span>
            </div>
          `:''}
          <div class="col-xs-12">
            ${this.data?.bill?.dueDateStamp && this.data?.bill?.dueDateStamp>0 ? `<b style="font-size:${this.boldTextFontSize}mm">Due Date:</b> <span style="font-size:${this.boldTextFontSize}mm">${commonTempData.dateToDDMMYYY(this.data?.bill?.dueDateStamp)}</span>` : ''}
          </div>
        </div>
        <div class="row">
          <hr/>
          <div class="col-xs-12">
            <b style="font-size:${this.boldTextFontSize}mm">Purchase From :</b> 
            <span style="font-size:${this.textFontSize}mm">${party?.contactPersonName}${party?.contactPersonPhone ? ' <hr/> '+party?.contactPersonPhone : ''}</span>
            ${this.data?.bill?.isPartyDeleted ? "<hr/><p class='text-danger'>Party Has Been Deleted</p>" : "" }
            ${party?.gstNumber ? `<hr/>`+party?.gstNumber+`<hr/>` :''}
          </div>
          
        </div>`;

        if(party?.addressLine1){
          html+=`
          <div class="row">
          <div class="col-xs-12">
            <b style="font-size:${this.boldTextFontSize}mm">Billing Address : </b> <span style="font-size:${this.textFontSize}mm">${party?.addressLine1 ? party?.addressLine1 : ''} ${party?.addressState ? ', ' + party?.addressState : ''}${party?.addressPincode ? ', ' + party?.addressPincode : ''}</span>
          </div>
          </div>`
        }

        if(this.data?.bill?.deliveryProvience || party?.addressOneLine1 || party?.addressOneState){
          let deliveryState = this.data?.bill?.deliveryProvience || party?.addressOneState;
          html+=`
          <div class="row">
          <div class="col-xs-12">
            <b style="font-size:${this.boldTextFontSize}mm">Delivery Address : </b> <span style="font-size:${this.textFontSize}mm">${party?.addressOneLine1 || ''}${deliveryState? ', '+deliveryState:''}${party?.addressOnePincode?', '+party?.addressOnePincode:''}</span>
          </div>
          </div>`
        }
        
      html += `
        <div class="row">
          <hr/>
        </div>
      `;

    return html;
  }


  addSameTaxPercentageObjs(mainObj) {
    let sanitizedObj = {};
    for (const key in mainObj) {
      let holder = {};
      mainObj[key].forEach(function (d) {
        if (d?.taxPercentage != 0) {
          if (holder.hasOwnProperty(d?.taxPercentage)) {
            holder[d?.taxPercentage] = {
              "taxAmount": (holder[d?.taxPercentage].taxAmount + d?.taxAmount),
              "itemPrice": (holder[d?.taxPercentage].itemPrice + (d?.itemPrice * d?.quantity) - (d?.isIncTax ? d?.taxAmount : 0) - (d.discount || 0))
            };
          } else {
            holder[d?.taxPercentage] = {
              "taxAmount": d?.taxAmount,
              "itemPrice": (d?.itemPrice * d?.quantity) - (d?.isIncTax ? d?.taxAmount : 0) - (d.discount || 0)
            };
          }
        }
      }
      );
      let array = [];

      for (let prop in holder) {
        array.push({ taxPercentage: prop, taxAmount: holder[prop]?.taxAmount, taxableValue: holder[prop]?.itemPrice });
      }
      if (array.length) {
        sanitizedObj[key] = array;
      }
    }

    return sanitizedObj;
  }

  commonHsnTableCode(el) {
    let isIncTax = false;

      if (el?.ppIncTax) {
        isIncTax = true;
      }
 

    return {
      'taxAmount': el?.itemTotalTaxAmount || 0,
      'itemPrice': el?.price,
      'taxPercentage': el?.taxPercentage || 0,
      'quantity': el?.quantity || 1,
      'isIncTax': isIncTax,
      'discount': el?.discount || 0
    }

  }

  calculationForHsnTable(el, gstTableObj) {

    if (el?.taxPercentage) {
      if (gstTableObj[el?.taxPercentage]) {
        gstTableObj[el?.taxPercentage].push({
          ...this.commonHsnTableCode(el)
        })
      } else {
        gstTableObj[el?.taxPercentage] = [{
          ...this.commonHsnTableCode(el)
        }]
      }
    }

    return gstTableObj;

  }

  hsnSacTable() {
    if (!Object.keys(this.finalTaxPercentageTableObj).length) {
      return '';
    }

    let partyInfo = (this.data?.bill?.partyData?.profileData || null);

    let partyStateCode = partyInfo?.gstNumber?.substring(0,2) ? Utility.statesNamesByStateCode[partyInfo?.gstNumber?.substring(0,2)] : null;
    let profileStateCode = this.data?.profile?.gstin?.substring(0,2) ? Utility.statesNamesByStateCode[this.data?.profile?.gstin?.substring(0,2)] : null;
    let userState = profileStateCode || this.data?.profile?.addressProvience?.toUpperCase();
    let partyState = partyStateCode || this.data?.bill?.deliveryProvience?.toUpperCase();

    let gstStr='SGST';

    if(userState && partyState) {
      this.sameState = userState === partyState;
      if(this.sameState && Arrays.UTS.indexOf(userState) != -1) {
        gstStr='UTGST';
      } 
    } else {
      this.sameState = true;
      if(Arrays.UTS.indexOf(userState) != -1 || Arrays.UTS.indexOf(partyState) != -1) {
        gstStr='UTGST';
      } 
    }
    
    let html = '';

    html += `
      <table id="table-gst-breakup" style="width: 100%; text-align: center;table-layout: fixed;">
      <thead class="no-b-btm" style="-webkit-print-color-adjust: exact !important;color-adjust: exact !important;">
        <tr>
        
        <td>Gross</td>
        <td>
          ${(!this.sameState && this.isTax) ? 'IGST' : `${gstStr}`}
        </td>
        <td>
          ${(!this.sameState && this.isTax) ? 'IGST Amt' : `${gstStr} Amt`}
        </td>`
    if (this.sameState && this.isTax) {
      html += `<td>CGST</td><td>CGST Amt</td>`
    }

    html += `
        </tr>
        <tr>`

    html += `
      </thead>
      <tbody>`

    for (const key in this.finalTaxPercentageTableObj) {
      if (this.finalTaxPercentageTableObj[key]) {
        html += this.gstSacRow(key, this.finalTaxPercentageTableObj[key]);
      }
    }

    html += `
      </tbody>
      </table>
      `

    return html;
  }



  gstSacRow(taxPercentage, gstData) {
    let html = '';
    for (let i = 0; i < gstData.length; i++) {
      const element = gstData[i];
      
      html += `
          <tr>
          
          <td>
            ${commonTempData.unitAdjuster(element?.taxableValue || 0)}</td>
          <td>
          ${(!this.sameState && this.isTax) ? (+element?.taxPercentage)?.toFixed(2)?.replace(/\.00$/, '') : (+element?.taxPercentage / 2)?.toFixed(2)?.replace(/\.00$/, '')}%
          </td>
          <td>
          ${(!this.sameState && this.isTax) ? (element?.taxAmount)?.toFixed(2)?.replace(/\.00$/, '') : (element?.taxAmount / 2)?.toFixed(2)?.replace(/\.00$/, '')}</td>
          `
      if (this.sameState && this.isTax) {
        html += `<td>
            ${(+element?.taxPercentage / 2)?.toFixed(2)?.replace(/\.00$/, '')}%
            </td>
            <td>
            ${(element?.taxAmount / 2)?.toFixed(2)?.replace(/\.00$/, '')}</td>
            `
      }
      html += `
            </tr>
            `
    }

    return html;
  }

}

