import { SaleBillPrint } from "src/models/SaleBillPrint.model";
import { Arrays } from "../utils/ReferenceData";
import { commonTempData } from "./commonTempData";
import { Utility } from "../utils/Utility";

export class Temp1{

    constructor(
      private data: SaleBillPrint,
      ){
      }

    finalHsnTableObj={};
    isTax=false;
    sameState = false;

    async main(){
      return `
      <style>
      ${commonTempData.tallyTemplateCss()}
      </style>
      <span class="st-inv-mobi-screen">
        <style>${commonTempData.mobileCss()}</style>
      </span>
      <span class="invoice-template-main">
        <div 
          class="container-new arial inv-body-holder"
          style="width:${this.data.pageWidth || 210}mm;background: white;padding: 0;visibility: visible;-webkit-print-color-adjust: exact !important;color-adjust: exact !important;">
          ${this.header()}
          ${this.templateBody()}
          ${await this.footer()}
          <div class="inv-bottom-wrapper" style="margin:0 !important; padding:0 !important">
            <div style="height: 1px; width: 100%;">
            </div>
            <div style="height: 9px;display: flow-root;width:100%;text-align:right;font-size: 12px;color: #d3d3d3;" class="f-16 inv-bottom">
            </div>
          </div>
        </div>
      </span> 
      `
    }


    header(){
      return `
        ${this.sellerDetails()}
        ${this.invoiceTitle()}
        ${this.partyDetails()} 
        ${this.seconadryPartyDetails()}
      `
    }

    async footer(){
      let amountCalculation = '' ;
      amountCalculation = this.footerAmountCalculation();
     
      return `
        ${amountCalculation}
        ${this.hsnSacTable()}
        ${await this.footerBankDetails()}
        ${this.footerTNCAndNotes()}
        `
    }


    // S:  HSN Table Code
    addSameTaxPercentageObjs(mainObj){
      let sanitizedObj = {};
      for (const key in mainObj) {
        let holder = {};
        mainObj[key].forEach(function(d) {
          if(d.taxPercentage != 0){
            if (holder.hasOwnProperty(d.taxPercentage)) {
              holder[d.taxPercentage] = {
                "taxAmount":(holder[d.taxPercentage].taxAmount + d.taxAmount),
                //"itemPrice":(holder[d.taxPercentage].itemPrice - (d.itemPrice || 0))
                "itemPrice":(holder[d.taxPercentage].itemPrice+(d.itemPrice * d.quantity)-(d.isIncTax?d.taxAmount:0) -(d.discount || 0))
              };
            } else {
              holder[d.taxPercentage] = {
                "taxAmount":d.taxAmount,
                //"itemPrice":(d.itemPrice || 0) -(d.discount || 0)
                "itemPrice":(d.itemPrice * d.quantity) - (d.isIncTax?d.taxAmount:0) -(d.discount || 0)
              };
            }
          }
        }
        );
        let array = [];
        for (let prop in holder) {
          array.push({ taxPercentage: prop, taxAmount: holder[prop].itemPrice * (+prop/100), taxableValue: holder[prop].itemPrice});
        }
        if(array.length){
          sanitizedObj[key] = array;
        }
      }
      return sanitizedObj;
    }

    commonHsnTableCode(el){
      let isIncTax = false;

        if(el.spIncTax){
          isIncTax = true;
        }
      
      return {
        'taxAmount': el.unitGstAmount *el.quantity  ||0,
        'itemPrice':  el.subTotal,
        'taxPercentage': el.taxPercentage||0,
        'quantity': el.quantity ||1,
        'isIncTax': isIncTax,
        'discount': el?.unitDiscountAmount || 0
      }

    }
    calculationForHsnTable(el,hsnTableObj){
      if(el.hsn ||el.taxPercentage){ 
        if(el.hsn){
          if(hsnTableObj[el.hsn]){
            hsnTableObj[el.hsn].push({
              ...this.commonHsnTableCode(el)
            })
          } else{
            hsnTableObj[el.hsn] = [{
              ...this.commonHsnTableCode(el)
            }]
          }
        } else{
          if(el.taxPercentage || el.totalAmount){
            if(hsnTableObj['noHsn']){
              hsnTableObj['noHsn'].push({
                ...this.commonHsnTableCode(el)

              })
            }else{
              hsnTableObj['noHsn'] = [{
                ...this.commonHsnTableCode(el)
              }]
            }
          }
        }
      }

      return hsnTableObj;

    }

    hsnSacTable(){

      if((this.data?.settings?.pSetTaxMrpHsnPrintStatus===false)){
        return '';
      }

      if(!Object.keys(this.finalHsnTableObj).length){
        return '';
      }

      let partyInfo = this.data?.bill?.partyData?.profileData || null;

      let partyStateCode = partyInfo?.gstNumber?.substring(0,2) ? Utility.statesNamesByStateCode[partyInfo?.gstNumber?.substring(0,2)] : null;
      let profileStateCode = this.data?.profile?.gstin?.substring(0,2) ? Utility.statesNamesByStateCode[this.data?.profile?.gstin?.substring(0,2)] : null;
      let userState = profileStateCode || this.data?.profile?.addressProvience?.toUpperCase();
      let partyState = partyStateCode || this.data?.bill?.deliveryProvience?.toUpperCase();

      let gstStr='SGST';

      if(userState && partyState) {
        this.sameState = userState === partyState;
        if(this.sameState && Arrays.UTS.indexOf(userState) != -1) {
          gstStr='UTGST';
        } 
      } else {
        this.sameState = true;
        if(Arrays.UTS.indexOf(userState) != -1 || Arrays.UTS.indexOf(partyState) != -1) {
          gstStr='UTGST';
        } 
      }

      let html ='';

      //wrapper class
      html+=`<div class="inv-section-hsn-table-wrapper" style="margin:0 !important; padding:0 !important">
              <div style="height: 1px; width: 100%;"></div>`

      //S: Main div
      html +=`<div class="margin-10-new inv-section-hsn-table" style="margin: 0 23px;border: 1px solid #000000;border-left: 0;">`

      html +=`<table style="width: 100%; text-align: center;table-layout: fixed;">`

      html +=`<thead class="no-b-btm" style="border-bottom: 1px solid #000000;-webkit-print-color-adjust: exact !important;color-adjust: exact !important;">`
      html +=`
        <tr>
        <td class="font-12 padding-5555" rowspan="2"
          style="text-transform:uppercase;color:#000;text-align: center !important;padding:0 2px;border-right:0;border-top:0;">
          HSN/SAC</td>
        <td class="font-12 padding-5555" rowspan="2"
          style="text-transform:uppercase;color:#000;text-align: center !important;padding:0 2px;border-right:0;border-top:0;">
          Taxable value</td>
        <td class="font-10 padding-5555" colspan="2"
          style="text-transform:uppercase;color:#000;text-align: center;font-weight: normal;padding:0 2px;border-right:0;border-bottom:0;border-top:0;">
          ${(!this.sameState&&this.isTax)?'IGST':`${gstStr}`}
        </td>`
        if(this.sameState&&this.isTax){
          html +=`<td class="font-10 padding-5555" colspan="2"
          style="text-transform:uppercase;color:#000;text-align: center;font-weight: normal;padding:0 2px;border-right:0;border-bottom:0;border-top:0;">
          CGST</td>`
        }

        html +=`<td class="font-12 padding-5555" rowspan="2"
          style="text-transform:uppercase;color:#000;text-align: center;padding:0 2px;border-right:0;border-top:0;border-right: 0;">
          Total Tax</td>
        </tr>
        <tr>
          <td class="font-10 padding-5555"
            style="font-weight: normal;text-transform:uppercase;color:#000;text-align: left;padding:0 2px;border-right:0;">
            Rate</td>
          <td class="font-10 padding-5555"
            style="font-weight: normal;text-transform:uppercase;color:#000;text-align: right !important;padding:0 2px;border-right:0;">
            Amt</td>`

            if(this.sameState && this.isTax){
              html+=`<td class="font-10 padding-5555"
                style="font-weight: normal;text-transform:uppercase;color:#000;text-align: left;padding:0 2px;border-right:0;">
                Rate</td>
              <td class="font-10 padding-5555"
                style="font-weight: normal;text-transform:uppercase;color:#000;text-align: right;padding:0 2px;border-right:0;">
                Amt</td>`
            }

        html+=`</tr>`
          
      html +=`</thead>`

      html +=`<tbody>`

      html+=this.makeHsnSacRows()

      html +=`</tbody>`

      html +=`</table>`

      //E: Main div
      html +=`</div>`
      //wrapper div
      html +=`</div>`

      return html;
    }

    makeHsnSacRows(){
      let html ='';
      for (const key in this.finalHsnTableObj) {
        if(this.finalHsnTableObj[key]){
          html +=this.hsnSacRow(key,this.finalHsnTableObj[key]);
        }
      }

      return html;
    }

    hsnSacRow(hsnCode,hsnData){
      let html ='';
      for (let i = 0; i < hsnData.length; i++) {
        const element = hsnData[i];
        html +=`
          <tr>
          <td class="padding-5555 font-12" style="color:#000;border-bottom: 0;border-top: 0;padding: 0 2px;border-right:0;text-align: center;">
            ${(hsnCode !='noHsn')?hsnCode:''}</td>
          <td class="padding-5555 font-12"
            style="color:#000;border-bottom: 0;border-top: 0;padding: 0 2px;border-right:0;text-align: center !important;">
            ${commonTempData.unitAdjuster(element?.taxableValue || 0)}</td>
          <td class="padding-5555 font-12" style="color:#000;border-bottom: 0;border-top: 0;padding: 0 2px;border-right:0;text-align: left;">
          ${(!this.sameState&&this.isTax)?(+element?.taxPercentage)?.toFixed(2)?.replace(/\.00$/, ''):(+element?.taxPercentage/2)?.toFixed(2)?.replace(/\.00$/, '')}%
          </td>
          <td class="padding-5555 font-12" style="color:#000;border-bottom: 0;border-top: 0;padding: 0 2px;border-right:0;text-align: right;">
          ${(!this.sameState&&this.isTax)?(element?.taxAmount)?.toFixed(2)?.replace(/\.00$/, ''):((element?.taxAmount)?.toFixed(2)/2)?.toFixed(2)?.replace(/\.00$/, '')}</td>
          `
          if(this.sameState&&this.isTax){
            html+=`<td class="padding-5555 font-12" style="color:#000;border-bottom: 0;border-top: 0;padding: 0 2px;border-right:0;text-align: left;">
            ${(+element?.taxPercentage/2)?.toFixed(2)?.replace(/\.00$/, '')}%
            </td>
            <td class="padding-5555 font-12" style="color:#000;border-bottom: 0;border-top: 0;padding: 0 2px;border-right:0;text-align: right;">
            ${((element?.taxAmount)?.toFixed(2)/2)?.toFixed(2)?.replace(/\.00$/, '')}</td>
            `
          }
          html+=`<td class="padding-5555 font-12"
            style="color:#000;border-bottom: 0;border-top: 0;padding: 0 2px;border-right:0;text-align: right;border-right: 0;">
            ${(element?.taxAmount)?.toFixed(2)?.replace(/\.00$/, '')}</td>
            </tr>
            `       
      }
    
      return html;
    }

    // E:  HSN Table Code

    sellerDetails(){
      // S: OWNER DETAILS
        let html = "";
        let el=this.data?.profile || null;
        // close these two divs in last
        html += `
        <div class="inv-header-wrapper" style="margin:0 !important; padding:0 !important">
        <div style="height: 1px; width: 100%;"></div>
        <div style="display: flex;flex-wrap:wrap;width: 100%;align-items: flex-start;background: white;padding:0 25px;"
        class="top padding-002510-new inv-header">
        <div style="display: flow-root;width:100%;text-align:right;font-size: 12px;color: #d3d3d3;" class="f-16">
          ${this.data?.addressTo || ''}
        </div>
        <div style="display: flow-root;width: 80%;">`

        if(el?.legalName){
            html += `
            <div style="display: flow-root;font-weight: bold;color: ${this.data?.bill?.color}" class="font-24 f-b">
                ${el?.legalName}
                <img class="addProfileInfo icons-height no-print" src="/assets/icons/edit-inv.svg">
            </div>`
        }

        if(el?.addressLine1 || el?.addressProvience){
            html +=`
            <div style="display: flow-root;font-size: 16px;color: #000;" class="f-16">
                ${el?.addressLine1},${el?.addressCity || ''}${el?.addressProvience?', '+el?.addressProvience:''}${el?.addressPostalCode?', '+el?.addressPostalCode:''}
            </div>
            `
        }

        if(el?.contactPersonPhone && el?.contactPersonPhone!='0'){
            html += `
            <div style="display: flow-root;font-size: 16px;color: #000;" class="f-16">
                ${el?.contactPersonPhone}
            </div>
            `
        }

        if(el?.contactPersonEmail){
            html += `
            <div style="display: flow-root;font-size: 16px;color: #000;" class="f-16">
                ${el?.contactPersonEmail}
            </div>
            `
        }

        if(el?.gstin){
          html += `
          <div style="display: flow-root;font-size: 16px;color: #000;font-weight: bold;" class="f-16">
              ${el?.gstin}
          </div>
          `
      }


      if(el?.fssaiNumber){
        html += `
        <div style="display: flow-root;font-size: 16px;color: #000;font-weight: bold;" class="f-16">
            FSSAI No. : ${el?.fssaiNumber}
        </div>
        `
      }


      if(el?.licenseNumber){
        html += `
        <div style="display: flow-root;font-size: 16px;color: #000;font-weight: bold;" class="f-16">
            License No. : ${el?.licenseNumber}
        </div>
        `
      }

        if(
          el?.additionalDateFieldTitle 
          && ((el.additionalDateFieldTitle +"").trim()).length>0 
          && (el?.additionalDateFieldValue)
          && ((el?.additionalDateFieldValue + "").trim()).length>0
          ){
            html += `
            <div style="display: flow-root;font-size: 14px;color: #000;" class="f-14 m-5-new">
              <b>${el?.additionalDateFieldTitle}:</b> ${el?.additionalDateFieldValue}
            </div>
            `
        }

        if(
          el?.additionalInputFieldTitle 
          && ((el?.additionalInputFieldTitle +"").trim()).length>0 
          && (el?.additionalInputFieldValue)
          && ((el?.additionalInputFieldValue + "").trim()).length>0
          ){
            html += `
            <div style="display: flow-root;font-size: 14px;color: #000;" class="f-14 m-5-new">
              <b>${el?.additionalInputFieldTitle}:</b> ${el?.additionalInputFieldValue}
            </div>
            `
        }

        if(
          el?.additionalInputFieldTitle1 
          && ((el?.additionalInputFieldTitle1 +"").trim()).length>0 
          && (el?.additionalInputFieldValue1)
          && ((el?.additionalInputFieldValue1 + "").trim()).length>0
          ){
            html += `
            <div style="display: flow-root;font-size: 14px;color: #000; font-weight: bold;" class="f-14 m-5-new">
              ${el?.additionalInputFieldTitle1}: ${el?.additionalInputFieldValue1}
            </div>
            `
        }

        if(
          el?.additionalInputFieldTitle2 
          && ((el?.additionalInputFieldTitle2 +"").trim()).length>0 
          && (el?.additionalInputFieldValue2)
          && ((el?.additionalInputFieldValue2 + "").trim()).length>0
          ){
            html += `
            <div style="display: flow-root;font-size: 14px;color: #000; font-weight: bold;" class="f-14 m-5-new">
              ${el?.additionalInputFieldTitle2}: ${el?.additionalInputFieldValue2}
            </div>
            `
        }

        if(
          el?.additionalInputFieldTitle3 
          && ((el?.additionalInputFieldTitle3 +"").trim()).length>0 
          && (el?.additionalInputFieldValue3)
          && ((el?.additionalInputFieldValue3 + "").trim()).length>0
          ){
            html += `
            <div style="display: flow-root;font-size: 14px;color: #000; font-weight: bold;" class="f-14 m-5-new">
              ${el?.additionalInputFieldTitle3}: ${el?.additionalInputFieldValue3}
            </div>
            `
        }

        if(
          el?.additionalInputFieldTitle4 
          && ((el?.additionalInputFieldTitle4 +"").trim()).length>0 
          && (el?.additionalInputFieldValue4)
          && ((el?.additionalInputFieldValue4 + "").trim()).length>0
          ){
            html += `
            <div style="display: flow-root;font-size: 14px;color: #000; font-weight: bold;" class="f-14 m-5-new">
              ${el?.additionalInputFieldTitle4}: ${el?.additionalInputFieldValue4}
            </div>
            `
        }

        if(
          el?.additionalInputFieldTitle5 
          && ((el?.additionalInputFieldTitle5 +"").trim()).length>0 
          && (el?.additionalInputFieldValue5)
          && ((el?.additionalInputFieldValue5 + "").trim()).length>0
          ){
            html += `
            <div style="display: flow-root;font-size: 14px;color: #000; font-weight: bold;" class="f-14 m-5-new">
              ${el?.additionalInputFieldTitle5}: ${el?.additionalInputFieldValue5}
            </div>
            `
        }

        //closing one div out of two
        html+=`</div>`;

        if(this.data?.profile?.logoLink){
            html += `
            <div style="display: flow-root;width: 20%;">
              <img src="${this.data?.profile?.logoLink}" style="display: block;margin-left: auto;width:70%;">
            </div>
            `
        }

        //closing last div
        html+=`</div>`;
        // E: OWNER DETAILS
        // wrapper div
        html+=`</div>`;

        return html;

    }

    templateBody(){
      if(!this.data?.bill?.billItems?.length){
        return '';
      }

      let html = '';
      let totalQty = 0;
      let isHsn = false;
      let isMrp = false;
      let isTax=false;
      let isAdditionlDateField = false;
      let isAdditionlInputField = false;
      let isExpiryDate = false;
      let additionlDateFieldTitle = '';
      let additionlInputFieldTitle = '';
      let isDiscount =false;
      let dynamicColumnCount = 0;
      let dynamicColWidthCss;

      let hsnTableObj={};


      for (let i = 0; i <  this.data?.bill?.billItems?.length; i++) {
        let el = this.data?.bill?.billItems[i] ;
        hsnTableObj = this.calculationForHsnTable(el,hsnTableObj);
        if(!(this.data?.settings?.pSetTaxMrpHsnPrintStatus===false) && (el?.hsn && !isHsn)){
            isHsn = true;
            dynamicColumnCount++;
        }
        if(!(this.data?.settings?.pSetTaxMrpHsnPrintStatus===false) && (el?.mrp ||el?.item?.mrp) && !isMrp){
          isMrp=true;
          dynamicColumnCount++;
        }

        if(!(this.data?.settings?.pSetTaxMrpHsnPrintStatus===false) && (el?.unitGstAmount || el?.unitCessAmount) && !isTax){
            isTax = true;
            this.isTax = true;
            dynamicColumnCount++;
        }

        if(el?.unitDiscountAmount && !isDiscount){
          isDiscount = true;
          dynamicColumnCount++;
        }
        if((!el?.item?.expiryDate && (el?.additionalDateFieldTitle && el?.additionalDateFieldValue))  && !isAdditionlDateField){
          isAdditionlDateField = true;
          additionlDateFieldTitle =  el?.additionalDateFieldTitle
          dynamicColumnCount++;
        }

        if(el?.item?.expiryDate){
          isExpiryDate = true;
          isAdditionlDateField = false;
        }

        if(el?.additionalInputFieldTitle && el?.additionalInputFieldValue && !isAdditionlInputField){
          isAdditionlInputField = true;
          additionlInputFieldTitle = el?.additionalInputFieldTitle
          dynamicColumnCount++;
        }
      }
      this.finalHsnTableObj = this.addSameTaxPercentageObjs(hsnTableObj);

      if(dynamicColumnCount == 1){
        dynamicColWidthCss = `extraColOne`
      }
      else if(dynamicColumnCount == 2){
        dynamicColWidthCss = `extraColTwo`
      }
      else if(dynamicColumnCount == 3){
        dynamicColWidthCss = `extraColThree`
      }
      else if(dynamicColumnCount == 4){
        dynamicColWidthCss = `extraColFour`
      }

      let dynamicBoldCss = '';
      if(this.data.settings.makeItemNameBold){
        dynamicBoldCss = 'font-weight: bold;';
      }

      //wrapper class 
      html+=`<div class="inv-item-list-holder-wrapper" style="margin:0 !important; padding:0 !important">
              <div style="height:1px; width: 100%;"></div>`
      // Main div , close it in last
      html += `<div style="width: 100%;padding:0px 25px;" class="padding-102525-new inv-item-list-holder">`;

      // Table close it before main div closing
      html += `<table style="width: 100%; text-align: center;table-layout: fixed;" ${dynamicColumnCount? `class='${dynamicColWidthCss}'`:''}>`;
 
      html+=`
      <thead  class="no-b-btm"
      style="background-color: ${this.data?.bill?.color} !important;-webkit-print-color-adjust: exact !important; -webkit-princolor-adjust: exact !important;"
      >
        <th class="cl-sr" style="text-align: center;color: #ffffff !important;padding:0 3px;border-right:0;">#</th>
        <th style="text-align: center;color: #ffffff !important;width: 50%;padding:0 3px;border-right:0;text-transform: uppercase;">Item Names</th>
        <th class="cl-hsn" style="text-align: center;color: #ffffff !important;padding:0 3px;border-right:0;text-transform: uppercase;${!isHsn?'display: none':''}">HSN</th>
        <th class="cl-qty" style="text-align: center;color: #ffffff !important;padding:0 3px;border-right:0;text-transform: uppercase;">Qty</th>
        <th class="cl-aif" style="text-align: center;color: #ffffff !important;padding:0 3px;border-right:0;text-transform: uppercase;${!isAdditionlInputField?'display: none':''}">${additionlInputFieldTitle}</th>
        <th class="cl-adf" style="text-align: center;color: #ffffff !important;padding:0 3px;border-right:0;text-transform: uppercase;${!isExpiryDate?'display: none':''}">Expiry Date</th>
        <th class="cl-adf" style="text-align: center;color: #ffffff !important;padding:0 3px;border-right:0;text-transform: uppercase;${!isAdditionlDateField?'display: none':''}">${additionlDateFieldTitle}</th>
        <th class="cl-mrp" style="text-align: center;color: #ffffff !important;padding:0 3px;border-right:0;text-transform: uppercase;${!isMrp?'display: none':''}">MRP</th>
        <th class="cl-rate" style="text-align: center;color: #ffffff !important;padding:0 3px;border-right:0;text-transform: uppercase;">RATE</th>
        <th class="cl-discount" style="text-align: center;color: #ffffff !important;padding:0 3px;border-right:0;text-transform: uppercase;${!isDiscount?'display: none':''}">Disc</th>
        <th class="cl-tax" style="text-align: center;color: #ffffff !important;padding:0 3px;border-right:0;text-transform: uppercase;${!isTax?'display: none':''}">Tax Amt</th>
        <th class="cl-total" style="text-align: right;color: #ffffff !important;padding:0 3px;text-transform: uppercase;">Total</th>
      </thead>
      `
      // close it after applying conditions
      html += `<tbody>`

      for (let i = 0; i < this.data?.bill.billItems?.length; i++) {

        let el = this.data?.bill.billItems[i] ;
        // let discountPercent = commonTempData.reCalDiscountPercentage(this.data,el);
        totalQty= totalQty + (+el?.quantity);

        html += `
        <tr>
          <td style="text-align: center;border-bottom:0;border-top: 0;padding:0 3px;border-right:0;-webkit-print-color-adjust: exact !important;color-adjust: exact !important;vertical-align:top;">${i+1}</td>
          <td class="padding-0515"
            style="text-align: center;border-bottom:0;border-top: 0;border-right:0;width: 50%;overflow: hidden;text-overflow: ellipsis;padding:0 5px;white-space: nowrap;-webkit-print-color-adjust: exact !important;color-adjust: exact !important;${dynamicBoldCss}">
            ${(el?.item?.itemName||'')} ${( el?.variant ? " (" + el?.variant + ")" : "")}
            <span style="display: block;font-weight:normal !important; word-wrap: break-word;">${((el?.itemDes && el.itemDes !="0")?el.itemDes:'')}</span>
            <span style="display: block;font-weight:normal !important; word-wrap: break-word;">${(el?.itemSrl?'('+el.itemSrl+')':'')}</span>
          </td>
          <td style="-webkit-print-color-adjust: exact !important;color-adjust: exact !important;vertical-align:top;text-align: center;border-bottom:0;border-top: 0;padding:0 3px;border-right:0;${!isHsn?'display: none':''}">${el?.hsn || el?.item?.hsn || ''}</td>
          <td style="-webkit-print-color-adjust: exact !important;color-adjust: exact !important;vertical-align:top;text-align: center;border-bottom:0;border-top: 0;padding:0 3px;border-right:0;">${commonTempData.unitAdjusterUptoThree((el?.quantity||0))+" "+(el?.unit||'')}</td>
          <td style="-webkit-print-color-adjust: exact !important;color-adjust: exact !important;vertical-align:top;text-align: center;border-bottom:0;border-top: 0;padding:0 3px;border-right:0;${!isAdditionlInputField?'display: none':''}">${el?.additionalInputFieldValue||''}</td>
          <td style="-webkit-print-color-adjust: exact !important;color-adjust: exact !important;vertical-align:top;text-align: center;border-bottom:0;border-top: 0;padding:0 3px;border-right:0;${!(isExpiryDate)?'display: none':''}">${el?.item?.expiryDate? commonTempData.dateToDDMMYYY(el?.item?.expiryDate):''}</td>
          <td style="-webkit-print-color-adjust: exact !important;color-adjust: exact !important;vertical-align:top;text-align: center;border-bottom:0;border-top: 0;padding:0 3px;border-right:0;${!(isAdditionlDateField)?'display: none':''}">${el?.additionalDateFieldValue||''}</td>
          <td style="-webkit-print-color-adjust: exact !important;color-adjust: exact !important;vertical-align:top;text-align: center;border-bottom:0;border-top: 0;padding:0 3px;border-right:0;${!isMrp?'display: none':''}">${el?.mrp || ''}</td>
          <td style="-webkit-print-color-adjust: exact !important;color-adjust: exact !important;vertical-align:top;text-align: center;border-bottom:0;border-top: 0;padding:0 3px;border-right:0;">${el?.price}</td>
          <td style="-webkit-print-color-adjust: exact !important;color-adjust: exact !important;vertical-align:top;text-align: center;border-bottom:0;border-top: 0;padding:0 3px;border-right:0;${!isDiscount?'display: none':''}">${(el?.unitDiscountAmount?(this.data.settings.showTaxAndDiscAmt?((commonTempData.unitAdjuster(el?.unitDiscountAmount)) +'<br>'):'')+'('+ ((+el?.discountPercentage || 0) + (+el?.billCashDiscountPercentage || 0)) +'%'+')':'')}</td>
          <td style="-webkit-print-color-adjust: exact !important;color-adjust: exact !important;vertical-align:top;text-align: center;border-bottom:0;border-top: 0;padding:0 3px;border-right:0;${!isTax?'display: none':''}">
          ${((el?.effectiveTaxPercentage>0) ? (this.data.settings.showTaxAndDiscAmt?commonTempData.unitAdjuster(el?.unitTaxAmount):'')+' ('+(el?.taxPercentage||0)+(el?.unitCessAmount?(el?.taxPercentage?'+':'')+el?.unitCessAmount:'')+')%':'')}
          </td>
          <td style="-webkit-print-color-adjust: exact !important;color-adjust: exact !important;vertical-align:top;text-align: right;padding:0 3px;border-bottom:0;border-top: 0;${dynamicBoldCss}">${commonTempData.unitAdjuster(el?.total)}</td>
        </tr>
        `
      }
      // tbody tag closed
      html += `</tbody>`

      html +=`
      <thead class="border" style="border-top: 1px solid #000000;border-bottom: 1px solid #000000;border-color:${this.data?.bill?.color} !important;-webkit-print-color-adjust: exact !important;color-adjust: exact !important;">
         <th style="text-align: center;padding:0 3px;border-right:0;"></th>
         <th style="text-align: center;width: 50%;padding:0 3px;border-right:0;">Total</th>
         <th style="text-align: center;padding:0 3px;border-right:0;${!isHsn?'display: none':''}"></th>
         <th style="text-align: center;padding:0 3px;border-right:0;">${commonTempData.unitAdjusterUptoThree(totalQty)}</th>
         <th style="text-align: center;padding:0 3px;border-right:0;${!isAdditionlInputField?'display: none':''}"></th>
         <th style="text-align: center;padding:0 3px;border-right:0;${!isExpiryDate?'display: none':''}"></th>
         <th style="text-align: center;padding:0 3px;border-right:0;${!isAdditionlDateField?'display: none':''}"></th>
         <th style="text-align: center;padding:0 3px;border-right:0;${!isMrp?'display: none':''}"></th>
         <th style="text-align: center;padding:0 3px;border-right:0;"></th>
         <th style="text-align: center;padding:0 3px;border-right:0;${!isDiscount?'display: none':''}">
         ${(this.data?.bill?.discountAmount?'₹'+this.data?.bill?.discountAmount:'')}
         </th>
         <th style="text-align: center;padding:0 3px;border-right:0;${!isTax?'display: none':''}">
         ${((this.data?.bill?.gstAmount||0)+(this.data?.bill?.cessAmount))>0?'₹'+commonTempData.unitAdjuster((this.data?.bill?.gstAmount||0)+(this.data?.bill?.cessAmount)):''}
         </th>
         <th style="text-align: right;padding:0 3px;">
         ₹${commonTempData.unitAdjuster(this.data?.bill?.totalAmount)}
         </th>
       </thead>
      `
      html += `</table>`
      html += `</div>`
      html += `</div>`
      return html;
    }

    invoiceTitle(){
      let html = '';
      let invoiceTitle = commonTempData.getInvoiceTitle(this.data);

      let editIcon='';
        
      if(invoiceTitle){
        html += `
        <div class="inv-title-holder-wrapper" style="margin:0 !important; padding:0 !important">
        <div style="height: 1px; width: 100%;"></div>
          <div style="display: flex;width: 100%;align-items: flex-start;background: white;padding:0 25px 5px;"
          class="padding-002510-new inv-title-holder">
              <div style="width: 100%;text-align: center;font-size: 16px;color: ${this.data?.bill?.color};font-weight: bold;" class="f-20 f-b">
              ${invoiceTitle} ${editIcon}
              </div>
          </div>
        </div>
        `
      }
      else {
        html += `
        <div class="inv-title-holder-wrapper" style="margin:0 !important; padding:0 !important">
        <div style="height: 1px; width: 100%;"></div>
          <div style="display: flex;width: 100%;align-items: flex-start;background: white;padding:0 25px 0px;"
          class="padding-002510-new inv-title-holder">
              <div style="width: 100%;text-align: center;font-size: 16px;color: ${this.data?.bill?.color};font-weight: bold;" class="f-20 f-b">
                 Invoice ${editIcon}
              </div>
          </div>
        </div>
        `
      }
      return html;
    }

    partyDetails(){
      let el = this.data?.bill?.partyData?.profileData || null;

      let isParty = this?.data?.bill?.isPartyDeleted;
      
      let html ='';

      if(!el) return '' ;

      // close this div in last
      html +=`
      <div class="inv-bill-details-wrapper" style="margin:0 !important; padding:0 !important">
      <div style="height: 1px; width: 100%;"></div>
        <div style="display: flex;width: 100%;align-items: flex-start;background: white;padding:0 25px 0px;"
        class="padding-002510-new inv-bill-details">
      `
      //Naming this div as div1, close it after applying conditions
      html +=`
        <div style="display: flow-root;width: 58%;">
      `
      let billTitle =  'Bill To';
     
      html +=`
        <div style="display: flow-root;font-weight: bold;font-size: 14px;color: #000;" class="f-14 f-b">${billTitle}</div>
        
      `
      if(el?.legalName){
        html +=`
         <div style="display: flow-root;font-size: 14px;color: #000;font-weight: bold;" class="f-14">
         ${el?.legalName}
         </div>
        `
      }

      if(el?.contactPersonName == el?.contactPersonPhone){
        html +=this.commonLeftSideFormatForPartyDetails(el?.contactPersonPhone)
      } 

      if(el?.contactPersonName){
        html+=`
      <div style="display: flow-root;font-size: 14px;color: #000;font-weight:bold;" class="f-14 m-5-new">
          ${el?.contactPersonName}
      </div>`
      }
      
      if(isParty){
            html+=`<div style="display: flow-root;font-size: 14px;color: red;font-weight:bold;" class="f-14 m-5-new">
                      Party Has Been Deleted
                  </div>`
      }
      if(el?.contactPersonPhone && el?.contactPersonPhone !='0'){
          html +=this.commonLeftSideFormatForPartyDetails(el?.contactPersonPhone)
      }
      
      if(el?.contactPersonEmail){
        html +=`
        <div style="display: flow-root;font-size: 14px;color: #000;" class="f-14 m-5-new">
          ${el?.contactPersonEmail}
        </div>`
      }

      if(el?.addressLine1 || el?.addressState){
        html +=`
        <div style="display: flow-root;font-weight: bold;font-size: 14px;color: #000;" class="f-14 f-b">
         Billing Address
      </div>
        <div style="display: flow-root;font-size: 14px;color: #000;" class="f-14 m-5-new">
          ${el?.addressLine1|| ''}${el?.addressState? ', '+el?.addressState:''}${el?.addressPincode?', '+el?.addressPincode:''}
        </div>`
      }

      if(el?.additionalDateFieldTitle
        && ((el?.additionalDateFieldTitle)+"").trim().length>0
        && el?.additionalDateFieldValue
        && ((el?.additionalDateFieldValue)+"").trim().length>0
        ){
        html +=`
        <div style="display: flow-root;font-size: 14px;color: #000;" class="f-14 m-5-new">
          <b>${el.additionalDateFieldTitle}:</b> ${el?.additionalDateFieldValue}
        </div>`
      }

      if(el?.additionalInputFieldTitle
        && ((el?.additionalInputFieldTitle)+"").trim().length>0 
        && el?.additionalInputFieldValue
        && ((el?.additionalInputFieldValue)+"").trim().length>0
        ){
        html +=`
        <div style="display: flow-root;font-size: 14px;color: #000;" class="f-14 m-5-new">
          <b>${el.additionalInputFieldTitle}:</b> ${el?.additionalInputFieldValue}
        </div>`
      }

      if(el?.gstNumber){
        html +=`
        <div style="display: flow-root;font-size: 14px;color: #000;font-weight: bold;" class="f-14 m-5-new">
            ${el?.gstNumber}
        </div>`
      }
      if(el?.udf1T && el?.udf1V){
        let text = `${el?.udf1T|| ''}${el?.udf1V? ' : '+el?.udf1V:''}`
        html +=this.commonLeftSideFormatForPartyDetails(text)
      }
      if(el?.udf2T && el?.udf2V){
        let text = `${ el?.udf2T || ''}${el?.udf2V? ' : '+el?.udf2V:''}`
        html +=this.commonLeftSideFormatForPartyDetails(text)
      }
      if(el?.udf3T && el?.udf3V){
        let text = `${el?.udf3T || ''}${el?.udf3V? ' : '+el?.udf3V:''}`
        html +=this.commonLeftSideFormatForPartyDetails(text)
      }


/*       if(this.data.deliveryLocation || this.data.deliveryState){
        html +=`
        <div style="display: flow-root;font-weight: bold;font-size: 16px;color: #000;margin-top: 5px;" class="f-16 f-b">
           Delivery Address
        </div>
        <div style="display: flow-root;font-size: 16px;color: #000;" class="f-16 m-5-new">
           ${this.data.deliveryLocation? this.data.deliveryLocation: ''}${this.data.deliveryState? ', '+this.data.deliveryState:''}
        </div>`
      } */
      if(this.data?.bill?.deliveryProvience || el?.addressOneLine1 || el?.addressOneState) {
        let deliveryState = this.data?.bill?.deliveryProvience || el?.addressOneState;
        html +=`
        <div style="display: flow-root;font-weight: bold;font-size: 14px;color: #000;" class="f-14 f-b">
           Delivery Address
        </div>
        <div style="display: flow-root;font-size: 14px;color: #000;" class="f-14 m-5-new">
           ${el?.addressOneLine1 || ''}${deliveryState? ', '+deliveryState:''}${el?.addressOnePincode?', '+el?.addressOnePincode:''}
        </div>`
      }

      // div1 closed
      html +=`</div>`

    // This is Right side of party Details
    //Naming this div as div2, close it after applying conditions
      html +=`<div style="display: flow-root;width: 42%;align-items: flex-start;flex-wrap: wrap;">`

     
        if(this.data?.bill?.billNo){
          let title = `Invoice No`
          html += this.commonRightSideFormatForPartyDetails(title, this.data?.bill.billNo);
        }
      
      

      if(this.data?.bill?.purOrderNo && this.data?.bill?.purOrderNo != ' ' ){
        html += this.commonRightSideFormatForPartyDetails('PO No',this.data?.bill?.purOrderNo);
      }
      if(this.data?.bill?.challanNo && this.data?.bill?.challanNo != ' ' ){
        html += this.commonRightSideFormatForPartyDetails('Challan No',this.data?.bill?.challanNo);
      }
      
            html+=`<div
            style="display: flow-root;font-weight: bold;font-size: 14px;color: #000;width: 50%;float: left;"
            class="f-14 f-b m-5-new">
              Created On :
            </div>
      
            <div
            style="display: flow-root;font-size: 14px;color: #000;width: 50%;float: left;text-align: right;"
            class="f-14 m-5-new">
               ${commonTempData.dateToDDMMYYYHHMMAP(this.data?.bill?.billCompleteStamp)}
            </div>`
      let isBillDateDiff=commonTempData.isBillDateDiff(this.data?.bill?.billCompleteStamp,this.data?.bill?.billDateStamp);

      if(isBillDateDiff && this.data?.bill?.billDateStamp){
        html += this.commonDateFormat('Bill Date', this.data?.bill?.billDateStamp);
      }

      if(this.data?.bill?.dueDateStamp && this.data?.bill?.dueDateStamp>0){
        html += this.commonDateFormat('Due Date', this.data?.bill?.dueDateStamp);
      }

      if(this.data?.bill?.eWayBillNo){
        html += this.commonRightSideFormatForPartyDetails('E-way Bill No',this.data?.bill?.eWayBillNo);
      }

      if(this.data?.bill?.eWayBillDate){
        html += this.commonDateFormat('E-way Bill Date', this.data?.bill?.eWayBillDate)

      }

      if(this.data?.bill?.vehicleNumber){
        html += this.commonRightSideFormatForPartyDetails('Vehicle No',this.data?.bill?.vehicleNumber);
      }

      if(this.data?.bill?.transporterName){
        html += this.commonRightSideFormatForPartyDetails('Transporter Name',this.data?.bill?.transporterName);
      }

      if(this.data?.bill?.deliveryDate){
        html += this.commonDateFormat('Delivery Date',this.data?.bill?.deliveryDate)
      }
      // div2 closed
      html +=`</div>`
      // main div closed
      html +=`</div>`

      // wrapper div
      html+=`</div>`

      return html;

    }


    

    seconadryPartyDetails(){
      let el = this.data?.bill?.secondaryPartyData?.profileData || null;
      let html ='';

      if(!el) return '' ;

      // close this div in last
      html +=`
      <div class="inv-bill-details-wrapper" style="margin:0 !important; padding:0 !important">
      <div style="height: 1px; width: 100%;"></div>
        <div style="display: flex;width: 100%;align-items: flex-start;background: white;padding:0 25px 0px;"
        class="padding-002510-new inv-bill-details">
      `
      //Naming this div as div1, close it after applying conditions
      html +=`
        <div style="display: flow-root;width: 58%;">
      `
      let billTitle = 'Bill To';
      
      
      if(el?.legalName){
        html +=`
          <div style="display: flow-root;font-weight: bold;font-size: 14px;color: #000;" class="f-14 f-b">${billTitle}</div>
        `
        html +=`
         <div style="display: flow-root;font-size: 14px;color: #000;font-weight: bold;" class="f-14">
         ${el?.legalName}
         </div>
        `
      }

      if(el?.contactPersonPhone && el?.contactPersonName == el?.contactPersonPhone){
        html +=this.commonLeftSideFormatForPartyDetails(el?.contactPersonPhone)
      } else {

        if(el?.contactPersonName){ 
          if(el?.contactPersonName == "Party Not Available"){
            html+=`<div style="display: flow-root;font-size: 14px;color: red;font-weight:bold;" class="f-14 m-5-new">
                      Party Has Been Deleted
                  </div>`
          }else{
            html+=`
            <div style="display: flow-root;font-size: 14px;color: #000;font-weight:bold;" class="f-14 m-5-new">
                ${el?.contactPersonName}
            </div>`
          }
        }

        if(el?.contactPersonPhone && el?.contactPersonPhone !='0'){
          html +=this.commonLeftSideFormatForPartyDetails(el?.contactPersonPhone)
        }
      }


      if(el?.addressLine1 || el?.addressState){
        html +=`
        <div style="display: flow-root;font-weight: bold;font-size: 14px;color: #000;" class="f-14 f-b">
         Billing Address
      </div>
        <div style="display: flow-root;font-size: 14px;color: #000;" class="f-14 m-5-new">
          ${el?.addressLine1|| ''}${el?.addressState? ', '+el?.addressState:''}${el?.addressPincode?', '+el?.addressPincode:''}
        </div>`
      }

      // div2 closed
      html +=`</div>`
      // main div closed
      html +=`</div>`
  
      // wrapper div
      html+=`</div>`
      return html;
    }
    

    footerAmountCalculation(){
      let totalTaxAmt =0;
      let receivedOrPaidAmt = 0;
      let isTax=false;
      let sameState = false;
      let partyInfo = this.data?.bill?.partyData?.profileData||null;

      let partyStateCode = partyInfo?.gstNumber?.substring(0,2) ? Utility.statesNamesByStateCode[partyInfo?.gstNumber?.substring(0,2)] : null;
      let profileStateCode = this.data?.profile?.gstin?.substring(0,2) ? Utility.statesNamesByStateCode[this.data?.profile?.gstin?.substring(0,2)] : null;
      let userState = profileStateCode || this.data?.profile?.addressProvience?.toUpperCase();
      let partyState = partyStateCode || this.data?.bill?.deliveryProvience?.toUpperCase();

      let gstStr='SGST';

      if(userState && partyState) {
        sameState = userState === partyState;
        if(sameState && Arrays.UTS.indexOf(userState) != -1) {
          gstStr='UTGST';
        } 
      } else {
        sameState = true;
        if(Arrays.UTS.indexOf(userState) != -1 || Arrays.UTS.indexOf(partyState) != -1) {
          gstStr='UTGST';
        } 
      }

      if(this.data?.bill?.gstAmount || this.data?.bill?.cessAmount){
          totalTaxAmt = this.data?.bill?.gstAmount + this.data?.bill?.cessAmount;
          isTax = true;
        }

      if(this.data?.bill?.moneyIn){
        receivedOrPaidAmt = (this.data?.bill?.moneyIn?.amount || 0)
      }

      if(this.data?.bill?.amountReceived) {
        receivedOrPaidAmt = this.data?.bill?.amountReceived;
      }

      let html = '';

      //wrapper class
      html+=`<div class="inv-total-holder-wrapper" style="margin:0 !important; padding:0 !important">
        <div style="height:1px;width: 100%;"></div>`
      // html+=EzoAd.showAd(this.data?.user);
      // close this main div in last
      html += `
      <div style="display: flex;width: 100%;align-items: flex-start;background: #ffffff;padding:0 25px 0;"
        class="padding-002500 inv-total-holder">`;

      //Naming this div as div1, close it after applying conditions
      html += `<div style="display: flow-root;width: 50%;padding: 0 10px 0 0;" class="padding-00100000">`;

      html += `
      <div style="display: flow-root;font-weight: bold;font-size: 14px;color: #000;text-transform: capitalize;"
      class="f-14 f-b">
         Invoice Amount in Words
      </div>
      <div style="display: flow-root;font-size: 14px;color: #000;text-transform: capitalize;" class="f-14">
        ${commonTempData.convertToIndianCurrency((this.data?.bill?.totalAmount)?.toString())}
        </div>`

      //closing div1
      html += `</div>`;

      //Naming this div as div2, close it after applying conditions
      html += ` <div style="display: flow-root;width: 50%;align-items: flex-start;flex-wrap: wrap;">`    
      let serviceChargeStr=``;
      if(this.data?.bill?.serviceChargePercentage){
        serviceChargeStr=`<div style="display: flow-root;font-size: 14px;color: #000;width: 50%;float: left;"
        class="f-14 m-5-new">
          Service Charge
        </div>
        <div
        style="display: flow-root;font-size: 14px;color: #000;width: 50%;float: left;text-align: right;"
        class="f-14 m-5-new">
           (${this.data?.bill?.serviceChargePercentage}%) ₹${commonTempData.unitAdjuster(this.data?.bill?.serviceChargeAmount)}
        </div>`
      }
      html += `
      <div class="flex" style="display:flex;flex-wrap:wrap;justify-content:flex-start;width:100%;">
      <div style="display: flow-root;font-size: 14px;color: #000;width: 50%;float: left;"
      class="f-14 m-5-new">
        Sub Total
         <span style="font-size: 10px"
         class="f-10 m-5-new"> without Tax</span>
      </div>
      <div
      style="display: flow-root;font-size: 14px;color: #000;width: 50%;float: left;text-align: right;"
      class="f-14 m-5-new">
         ₹${commonTempData.unitAdjuster(this.data?.bill?.subTotalAmount)}
      </div>

      ${serviceChargeStr}

      </div>
      `
      
      if(this.data?.bill?.cashDiscount){
        html +=this.commonAmountCalculationFormatForFooter('Cash Discount', `${commonTempData.unitAdjuster(this.data?.bill?.cashDiscount)} (${this.data?.bill?.cashDiscountPercentage}%)`);
      }
      if(!sameState&&isTax){
        html += this.commonAmountCalculationFormatForFooter('IGST',commonTempData.unitAdjuster(this.data?.bill?.gstAmount));
      }
      if(sameState&&isTax){
        html += this.commonAmountCalculationFormatForFooter(gstStr,commonTempData.unitAdjuster(this.data?.bill?.gstAmount/2));

        html += this.commonAmountCalculationFormatForFooter('CGST',commonTempData.unitAdjuster(this.data?.bill?.gstAmount/2));
      }

      if(this.data?.bill?.cessAmount){
        html += this.commonAmountCalculationFormatForFooter('CESS',commonTempData.unitAdjuster(this.data?.bill?.cessAmount));
      }

      let additionalAmount = this.data?.bill?.additionalAmount||0 ;
      if(additionalAmount){
        html +=this.commonAmountCalculationFormatForFooter('Labour/Delivery Charges', additionalAmount);
      }

      if(this.data?.bill.roundOffValue){
        html +=this.commonAmountCalculationFormatForFooter('Round Off', `${this.data?.bill.roundOffValue}`);
      }

      html +=`
      <div
      style="display: flow-root;font-weight: bold;text-transform:uppercase;font-size: 16px;color: #000;width: 50%;float: left;"
      class="f-16 f-b">
        Total Amount
      </div>
      <div
      style="display: flow-root;font-size: 16px;color: #000;font-weight: bold;width: 50%;float: left;text-align: right;"
      class="f-16 f-b dv-inv-temp-total-amount">
        ₹${commonTempData.unitAdjuster(this.data?.bill?.totalAmount ||0)}
      </div>`
      //@ts-ignore
      let receivedOrPaid = (this.data?.isPurchase || this.data?.isExpense)? 'Paid Amount': 'Received Amount';
      let modeOfPayment =  this.data?.bill?.moneyIn?.txnMode || '';
      let txnNo =  this.data?.bill?.moneyIn?.txnRef || '';
      if(receivedOrPaidAmt){
        html +=this.commonAmountCalculationFormatForFooter(receivedOrPaid, commonTempData.unitAdjuster(receivedOrPaidAmt||0));
        if(modeOfPayment){
          html +=`<div class="flex" style="display:flex;flex-wrap:wrap;justify-content:flex-start;width:100%;">
          <div style="display: flow-root;font-size: 14px;color: #000;width: 50%;float: left;" class="f-14">
            Txn Mode : 
          </div>
          <div style="display: flow-root;font-size: 14px;color: #000;width: 50%;float: left;text-align: right;" class="f-14">
             ${modeOfPayment}
          </div>
          </div>`
          if(txnNo){
          html +=`
          <div class="flex" style="display:flex;flex-wrap:wrap;justify-content:flex-start;width:100%;">
          <div style="display: flow-root;font-size: 14px;color: #000;width: 50%;float: left;" class="f-14">
            Txn No : 
          </div>
          <div style="display: flow-root;font-size: 14px;color: #000;width: 50%;float: left;text-align: right;" class="f-14">
             ${txnNo}
          </div>
          </div>`;

          }
        }
      }
      let balanceAmt = ((this.data?.bill?.totalAmount||0) - receivedOrPaidAmt);
      if(balanceAmt>0.01){
        html +=this.commonAmountCalculationFormatForFooter('Balance', commonTempData.unitAdjuster(balanceAmt));
      }

      if(this.data?.bill?.billCompleteStamp && (this.data?.bill.partyPreviousBalance)) {
        html +=this.commonAmountCalculationFormatForFooter('Previous Balance', commonTempData.unitAdjuster(this.data?.bill.partyPreviousBalance));
        html += `<p class="text-3i-h3" style="text-align:right">as on ${commonTempData.dateToDDMMYY(this.data?.bill?.billCompleteStamp)}</p>`;
        
        if(balanceAmt>0.01){
          html +=this.commonAmountCalculationFormatForFooter('Total Balance', commonTempData.unitAdjuster((this.data?.bill.partyPreviousBalance) + balanceAmt));
        }
      }
  
      if(this.data?.bill?.totalSaving>0 && this.data?.settings?.billSavingsAmount){
        html +=`
        <div
        style="display: flow-root;font-weight: bold;text-transform:uppercase;font-size: 16px;color: #000;width: 50%;float: left;"
        class="f-16 f-b">
          Total Saving
        </div>
        <div
        style="display: flow-root;font-size: 16px;color: #000;font-weight: bold;width: 50%;float: left;text-align: right;"
        class="f-16 f-b">
          ₹${commonTempData.unitAdjuster(this.data?.bill?.totalSaving)}
        </div>`
      }

      // div2 closed
      html +=`</div>`
      // main div closed
      html +=`</div>`
      //wrapper div
      html +=`</div>`

      return html;
    }

    
    async footerBankDetails(){

      let obj = this.data?.profile || null;
      let html = '';

      // close this div in last
      html +=`
      <div class="inv-footer-wrapper" style="margin:0 !important; padding:0 !important"> 
        <div style="height: 1px; width: 100%;"></div>
        <div style="display: flex;width: 100%;align-items: flex-start;background: #ffffff;padding:0 25px 0;"
        class="padding-002500 inv-footer">`

      //Naming this div as div1, close it after applying conditions
      html += `
      <div style="display: flex;width: 46%;float: left;" class="m-5-new">
      `

      
      if(obj && (obj?.bankName || obj?.bankAccountNo  || obj?.bankIFSC || obj?.bankUPI)){
        if(obj?.bankUPI && this.data.qrPayLink){
          let qrCodeBase64 = await commonTempData.generateQR(this.data.qrPayLink);
    
          html += `
          <div style="width: 30%;float: left;" class="m-l-neg-10 m-l-neg-2">
            <img style="width: 100%;" src="${qrCodeBase64}">
          </div>`
        }
      //Naming this div as div2, close it after applying conditions
        html +=`
        <div style="width: 70%;float: left;padding: 0;" class="">
        <div style="font-weight: bold;font-size: 12px;width: 100%;color: #000;" class="f-16 f-b">Bank Details</div>
        `
        if(obj?.bankName){
          html += this.commonBankDetailsFormatForFooter(obj?.bankName)
        }

        if(obj?.bankAccountNo){
          html += this.commonBankDetailsFormatForFooter('Acc No : '+obj?.bankAccountNo)
        }
        if(obj?.bankAccountNo && obj?.bankAccountType){
          html += this.commonBankDetailsFormatForFooter('Acc Type : '+obj?.bankAccountType)
        }
        if(obj?.bankIFSC){
          html += this.commonBankDetailsFormatForFooter('IFSC : '+obj?.bankIFSC)
        }
        if(obj?.bankUPI){
          html += this.commonBankDetailsFormatForFooter(obj?.bankUPI)
        }
        else if(!obj?.bankUPI && obj?.bankAccountNo && obj?.bankIFSC){
          html += this.commonBankDetailsFormatForFooter(obj?.bankAccountNo+'@'+obj?.bankIFSC+'.ifsc.npci');
        }
      // div2 closed
        html += `</div>`
      }else{
        html+=`
        <div style="width: 70%;float: left;padding: 0;" class="addBankDetails no-print">
        <div style="font-weight: bold;font-size: 12px;width: 100%;" class="f-16 f-b">
         <a> Add Bank Details <img src="/assets/icons/edit-inv.svg" class="icons-height">
         </a>
        </div>
        </div>
        `
      }
      // div1 closed
      html +=`</div>`;

      if(!this.data.settings.showSignatureNotRequiredText && this.data.settings.showSignatureInTemplates){
        html += this.commonSignatureFormatForFooter(commonTempData?.whiteLogo,'Customer Signature');
      }
      if(!this.data.settings.showSignatureNotRequiredText){
        let signAlignCss = '';
        if(! this.data.settings.showSignatureInTemplates){
          signAlignCss = 'margin-left:26%'
        }

        let imgStr=this.data?.profile.signatureLink || commonTempData?.whiteLogo;
        if(imgStr.indexOf(".png")!=-1 || imgStr.indexOf(".jpg")!=-1 || imgStr.indexOf(".jpeg")!=-1 || imgStr.indexOf(".webp")!=-1){
          imgStr=`https://img.ezobanks.com/${imgStr}`
        }
        if(imgStr) {
          html+= `
          <div style="display: flow-root;width: 27%;float: left;padding: 0 20px 0;${signAlignCss}" class="padding-16-new m-5-new">
          <img style="width: 100%;object-fit: contain;object-position: bottom;margin-bottom:5px; height:130px;" class="m-5-new" src="${imgStr}">
          <div style="width: 100%;text-align: center;font-size: 12px;" class="f-16">Authorized Signatory</div>
          </div>
          `
        } else {
          html+= `
          <div style="display: flow-root;width: 27%;float: left;padding: 0 20px 0;${signAlignCss}" class="padding-16-new m-5-new">
          <div style="width: 100%;text-align: center;font-size: 12px; margin-top:135px;" class="f-16">Authorized Signatory</div>
          </div>
          `
        }
      }


    // Main div closed
      html +=`</div>`;
    // wrapper div  
      html+=`</div>`;
      return html;

    }

    footerTNCAndNotes(){
 
      //TODO:Terms and Condition not showing properly while downloading.
      let html = '';

      if(this.data?.bill?.note){
        html += this.commmonTNCAndNotesFormat('Notes',this.data?.bill?.note,'inv-section-notes');
      }

      if(this.data?.bill?.tnc){
        html += this.commmonTNCAndNotesFormat('Terms and Conditions',(this.data?.bill?.tnc?this.data?.bill?.tnc +'':'').replace(/\n/g,'<br/>'),'inv-section-terms');
      }

      html += this.commmonTNCAndNotesFormat(`<a class="addTNC no-print">Change Terms And Conditions<img src="/assets/icons/edit-inv.svg" class="icons-height"></a>`,'','addTNC');
      if(this.data.settings.showSignatureNotRequiredText){
        html+=`
        <div class="inv-no-signature-wrapper" style="margin:0 !important; padding:0 !important"> 
          <div style="height: 1px; width: 100%;"></div>
          <div style="display: flex;width: 100%;align-items: flex-start;background: white;padding:0 25px 0px;"
          class="padding-002510-new inv-no-signature">
              <div style="width: 100%;text-align: center;font-size: 12px;" class="f-14">
                This is computer generated Bill. Signature Not Required
              </div>
          </div>
        </div>  
        `
      }
      return html;
    }

    commmonTNCAndNotesFormat(title, value,sectionClassName){
     return `
        <div class="${sectionClassName}-wrapper" style="margin:0 !important; padding:0 !important;"> 
          <div style="height: 1px; width: 100%;"></div>
          <div
          style="display: flex;flex-wrap:wrap;width: 100%;background: #ffffff;padding:0 25px 0px;"
          class="padding-002525-new m-5-new ${sectionClassName}">
            <div style="font-weight: bold;width: 100%;color: #000;" class="f-14 f-b">${title}</div>
            <div style="width: 100%;" class="f-14">
              ${value}
            </div>
          </div>
        </div>
        `
    }

    commonBankDetailsFormatForFooter(text){
      return ` <div style="font-size: 12px;width: 100%;" class="f-16">${text}</div>`
    }

    commonSignatureFormatForFooter(imgBase64, title){
      if(imgBase64.indexOf(".png")!=-1 || imgBase64.indexOf(".jpg")!=-1 || imgBase64.indexOf(".jpeg")!=-1 || imgBase64.indexOf(".webp")!=-1){
        
        imgBase64=`https://img.ezobanks.com/${imgBase64}`
      }
      
      //TODO: Add css and If condition if no img
      return `
      <div style="display: flow-root;width: 27%;float: left;padding: 0 20px 0;" class="padding-16-new m-5-new">
      <img style="width: 100%;object-fit: contain;object-position: bottom;margin-bottom:5px; height:130px;" class="m-5-new imageBase64" src="${imgBase64}">
      <div style="width: 100%;text-align: center;font-size: 12px;" class="f-16">${title}</div>
      </div>
      `
    }

    commonAmountCalculationFormatForFooter(title, value){
      return `
      <div class="flex" style="display:flex;flex-wrap:wrap;justify-content:flex-start;width:100%;">
      <div style="display: flow-root;font-size: 14px;color: #000;width: 50%;float: left;"
      class="f-14">
        ${title}
      </div>
      <div
      style="display: flow-root;font-size: 14px;color: #000;width: 50%;float: left;text-align: right;"
      class="f-14">
         ₹${value}
      </div>
      </div>
      `
    }

    commonAmountCalculationFormatForFooterForMoneyIn(title, value){
      return `
      <div class="flex" style="display:flex;flex-wrap:wrap;justify-content:flex-start;width:100%;">
      <div style="display: flow-root;font-size: 14px;color: #000;width: 50%;float: left; font-weight:bold"
      class="f-14 m-5-new">
        ${title}
      </div>
      <div
      style="display: flow-root;font-size: 14px;color: #000;width: 50%;float: left;text-align: right;"
      class="f-14 m-5-new">
         ${value}
      </div>
      </div>
      `
    }

    commonLeftSideFormatForPartyDetails(text){
      return `
      <div style="display: flow-root;font-size: 14px;color: #000;" class="f-14 m-5-new">
          ${text}
      </div>`
    }

    commonRightSideFormatForPartyDetails(title, value){
      return `
      <div class="flex" style="display:flex;flex-wrap:wrap;justify-content:flex-start;width:100%;">
      <div
      style="display: flow-root;font-weight: bold;font-size: 14px;color: #000;width: 50%;float: left;"
      class="f-14 f-b">
        ${title} :
      </div>

      <div
      style="display: flow-root;font-size: 14px;color: #000;width: 50%;float: left;text-align: right;"
      class="f-14">
         ${value}
      </div></div>`
    }

    commonDateFormat(dateName, timeStamp){
      return `
        <div
          style="display: flow-root;font-weight: bold;font-size: 14px;color: #000;width: 50%;float: left;"
          class="f-14 f-b m-5-new">
            ${dateName} :
          </div>

          <div
          style="display: flow-root;font-size: 14px;color: #000;width: 50%;float: left;text-align: right;"
          class="f-14 m-5-new">
             ${commonTempData.dateToDDMMYYY(timeStamp)}
        </div>
      `
    }
    
    commonDateTimeFormat(dateName, timeStamp){
      return `
        <div
          style="display: flow-root;font-weight: bold;font-size: 14px;color: #000;width: 50%;float: left;"
          class="f-14 f-b m-5-new">
            ${dateName} :
          </div>

          <div
          style="display: flow-root;font-size: 14px;color: #000;width: 50%;float: left;text-align: right;"
          class="f-14 m-5-new">
             ${commonTempData.dateToDDMMYYYHHMMAP(timeStamp)}
        </div>
      `
    }

}

