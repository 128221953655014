import { BehaviorSubject } from "rxjs";
import { Utility } from "../utils/utility";
import { ExpressServerService } from "./api/express-server.service";
import { IDataService } from "../../interface/IDataService.interface";
import { AuthService } from "./auth/auth.service";
import { CutOffDay } from "../models/CutOffDay.model";
import { CutOffDayDao } from "./dao/cut-off-day.dao";
import { SentryUtilites } from "../utils/sentryUtilites";

export class CutOffDayService implements IDataService<CutOffDay>{
  private static _instance: CutOffDayService;

  public static getInstance(
    dao: CutOffDayDao,
    expressServerService: ExpressServerService,
    authService: AuthService,
  ) {
    if (!this._instance) {
      this._instance = new CutOffDayService(
        dao,
        expressServerService,
        authService,
      )
      this._instance.initService();
    }
    return this._instance;
  }

  constructor(
    dao: CutOffDayDao,
    expressServerService: ExpressServerService,
    authService: AuthService,
  ) {
    this.dao = dao;
    this.expressServerService = expressServerService;
    this.authService = authService;
  }

  dao: CutOffDayDao;
  expressServerService: ExpressServerService;
  authService: AuthService;

  LIST_REFRESH_RATE = 1000;
  selectedProfileId: string = null;
  selectedProfileUserId: string = null;
  list: CutOffDay[] = [];
  listSubs = new BehaviorSubject<CutOffDay[]>([]);
  updateSubs = new BehaviorSubject<CutOffDay>(null);

  lastReloadStamp: number = 0;
  isReloadPostpond = false;

  initService() {
    this.selectedProfileId = Utility.getFromLocalStorage('selectedProfile');
    this.selectedProfileUserId = Utility.getFromLocalStorage('selectedProfileUserId');
    this.list = [];
    this.reloadList();
  }

  reloadList = async () => {
    try {
      if (this.isReloadPostpond) {
        return;
      }
      const currentStamp = +new Date();
      if (this.lastReloadStamp < (currentStamp - this.LIST_REFRESH_RATE)) {
        this.lastReloadStamp = currentStamp;
        let docs = await this.dao.getAllByProfile(this.selectedProfileId);
        this.list = docs;
        this.listSubs.next(this.list);
        this.trySyncUnsynced();
      } else {
        this.isReloadPostpond = true;
        setTimeout(() => {
          this.isReloadPostpond = false;
          this.reloadList();
        }, this.LIST_REFRESH_RATE + 100);
      }
    } catch (error) {
      SentryUtilites.setLog("CutOffDayService:reloadList", error)
      return null;
    }
  }

  getAllByPromise() {
    return this.dao.getAllByProfile(this.selectedProfileId);
  }

  getAllByPromiseByProfile(profileId: string) {
    return this.dao.getAllByProfile(profileId);
  }

  getById(id: number): Promise<CutOffDay> {
    return this.dao.getById(id);
  }

  getByUUID(uuid: string): Promise<CutOffDay> {
    return this.dao.getByUUID(uuid);
  }

  async getLatestCutOff(): Promise<CutOffDay> {
    try {
      let allCutOffDays = await this.getAllByPromise();
      if(allCutOffDays?.length && Array.isArray(allCutOffDays)) {
        return allCutOffDays[0];
      }
      return null;
    } catch (error) {
      SentryUtilites.setLog("CutOffDayService:getLatestCutOff", error)
      return null;
    }
  }

  save(cutOffDay: CutOffDay): Promise<CutOffDay> {
    return new Promise(async (resolve, reject) => {
      try {
        if(Utility.isTruthy(cutOffDay)) {
          if (!cutOffDay.profileId) {
            cutOffDay.profileId = this.selectedProfileId;
          }
    
          cutOffDay.userId = this.selectedProfileUserId;
          cutOffDay.createdBy = cutOffDay.lastModifiedBy = this.authService.getLoginPhone();
          cutOffDay.createdByName = cutOffDay.lastModifiedByName = Utility.getCreatedByName();
    
          cutOffDay.endStamp = +new Date();
          cutOffDay.startStamp = +new Date().setHours(0,0,0,0);
          let allCutOffDays = await this.getAllByPromise();
          if(allCutOffDays?.length) {
            allCutOffDays?.sort((a:CutOffDay,b:CutOffDay) => b?.createdStamp - a?.createdStamp);
            let endStamp = allCutOffDays.length ? allCutOffDays[0]?.endStamp : +new Date().setHours(0,0,0,0);
            let oneDaysAgoStamp = +new Date() - (86400000);
            if(cutOffDay.startStamp && endStamp > oneDaysAgoStamp) {
              cutOffDay.startStamp = allCutOffDays[0]?.endStamp;
            }
          }
    
          let savedCutOffDay = await this.dao.save(cutOffDay);
          this.reloadList();
          return resolve(savedCutOffDay);
        } else {
          return resolve(null);
        }
      } catch (error) {
        SentryUtilites.setLog("CutOffDayService:save", error)
        return resolve(null)
      }
    });
  }

  update(cutOffDay: CutOffDay): Promise<CutOffDay> {
    return new Promise(async (resolve, reject) => {
      try {
        if(cutOffDay?._localUUID) {
          cutOffDay.lastModifiedBy = this.authService.getLoginPhone();
          cutOffDay.lastModifiedByName = Utility.getCreatedByName();
          let updatedCutOffDay = await this.dao.update(cutOffDay);
          this.reloadList();
          return resolve(updatedCutOffDay);
        }
        return resolve(null);
      } catch (error) {
        SentryUtilites.setLog("CutOffDayService:update", error)
        return resolve(null)
      }
    });
  }

  delete(cutOffDay: CutOffDay): Promise<CutOffDay> {
    return new Promise(async (resolve, reject) => {
      try {
        if(cutOffDay?._localUUID) {
          cutOffDay.lastModifiedBy = this.authService.getLoginPhone();
          cutOffDay.lastModifiedByName = Utility.getCreatedByName();
          let deletedCutOffDay = await this.dao.delete(cutOffDay);
          this.reloadList();
          return resolve(deletedCutOffDay);
        }
        return resolve(null); 
      } catch (error) {
        SentryUtilites.setLog("CutOffDayService:delete", error)
        return resolve(null)
      }
    });
  }

  isSyncLock = false;
  isSyncPostPond = false;
  async trySyncUnsynced(postpond?: boolean) {
    try {
      if (this.isSyncLock) {
        if (!this.isSyncPostPond) {
          setTimeout(() => {
            this.isSyncPostPond = true;
            this.trySyncUnsynced(true);
          }, 200);
        }
        return true;
      }
      if (postpond) {
        this.isSyncPostPond = false;
      }
      this.isSyncLock = true;
      let unSyncedElements: CutOffDay[] = await this.dao.getAllUnsynced(this.selectedProfileId);
      if (unSyncedElements && unSyncedElements?.length) {
        try {
          
          for (let i = 0; i < unSyncedElements?.length; i++) {
            let unSyncedElement = unSyncedElements[i];
            if(unSyncedElement?._localUUID) {
              unSyncedElement['updatedStamp'] = +new Date();
            }
          }
  
          await this.dao.bulkPut(unSyncedElements);
          await Utility.wait(1000);
  
          let chunkArr = Utility.getChunkArr(unSyncedElements);
          let chunkArrLength = chunkArr?.length;
  
          for(let i = 0; i < chunkArrLength; i++) {
            let result = await this.expressServerService.makeSyncCall('cutOffDay', chunkArr[i]);
            if (result && result?.['records']?.length) {
              let arr = result?.['records'];
              for (let i = 0; i < arr?.length; i++) {
                const el = arr[i];
                await this.updateSyncStamp(el);
              }
            }
          }
        } catch (error) {
          SentryUtilites.setLog("CutOffDayService:trySyncUnsynced:inner", error)
        }
      }
      this.isSyncLock = false;
    } catch (error) {
      SentryUtilites.setLog("CutOffDayService:trySyncUnsynced", error)
    }
  }

  updateSyncStamp(el: CutOffDay): Promise<CutOffDay> {
    return new Promise(async (resolve, reject) => {
      try {
        let updatedEl = await this.dao.updateSyncStamp(el);
        this.updateSubs.next(updatedEl);
        return resolve(updatedEl);
      } catch (error) {
        SentryUtilites.setLog("CutOffDayService:updateSyncStamp", error)
        return resolve(null)
      }
    });
  }
  
  copyData(fromProfileId: string, toProfileId: string): Promise<boolean> {
    return new Promise(async (resolve,reject) => {
      try {
        if(Utility.isTruthy(fromProfileId) && Utility.isTruthy(toProfileId)) {
          let fromRecords = await this.getAllByPromiseByProfile(fromProfileId);
          if(fromRecords?.length) {
            let toRecords: CutOffDay[] = [];
            for (let i = 0; i < fromRecords?.length; i++) {
              const fetchedRecord = fromRecords[i];
              if(fetchedRecord?._localUUID) {
                fetchedRecord.profileId = toProfileId;
                delete fetchedRecord?._localId;
                delete fetchedRecord?._localUUID;
                let savedRecord = await this.save(fetchedRecord);
                if(savedRecord?._localUUID) {
                  toRecords?.push(savedRecord);
                }
              }
            }
            if(fromRecords?.length === toRecords?.length) {
              return resolve(true);
            }
          }
        }
        return resolve(false);
      } catch (error) {
        SentryUtilites.setLog("CutOffDayService:copyData", error)
        return resolve(false);
      }
    });
  }

}

