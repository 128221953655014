import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { KotData, KotListComponent } from '../../components/kot-list/kot-list.component';
import { Utility } from '../../utils/utility';
import { IKot, Kot } from '../../models/Kot.model';
import { Subscription } from 'rxjs';
import { AllDataService } from '../../services/all-data.service';
import { CommonService } from '../../services/common.service';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-kot',
  templateUrl: './kot.page.html',
  styleUrls: ['./kot.page.scss'],
})
export class KotPage implements OnInit {

  @ViewChild('kotListEle') kotListEle: KotListComponent;
  @ViewChild('kotPrintAudioEle') kotPrintAudioEle: ElementRef;

  getHeaderColorClass = Utility.getHeaderColorClass;

  lastSyncTime = Utility.getCollectionLastRespSyncTime(IKot.SCHEMA_NAME);
  lastSyncTimeSub: Subscription;

  currentKot: KotData = null;
  isMobile: boolean = null;
  innerHeight: string = '';

  constructor(
    private allDataService: AllDataService,
    private commonService: CommonService,
  ) { }

  ngOnInit() {
    try {
      this.isMobile = this.commonService.isMobile();
      if(this.isMobile) {
        this.innerHeight = `${(((window?.innerHeight - 176)/window?.innerHeight) * 100)}%`;
      } else {
        this.innerHeight = `${(((window?.innerHeight - 120)/window?.innerHeight) * 100)}%`;
      }
      this.allDataService.lastSyncSubs.subscribe(x => this.lastSyncTime = Utility.getCollectionLastRespSyncTime(IKot.SCHEMA_NAME));
    } catch (error) {
      SentryUtilites.setLog("KotPage:ngOnInit", error)
    }
  }

  ionViewWillLeave() {
    this.ngOnDestroy();
  }

  ngOnDestroy() {
    this.lastSyncTimeSub?.unsubscribe();
  }

  onRowClickTrigger(kotData: KotData) {
    this.currentKot = kotData;
    this.initKot()

  }

  async initKot() {
    try {
      let rollPreference=Utility.getFromLocalStorage('selectedTemplate');
      let kotRollSize=3;
      if(rollPreference=='temp19'){
        kotRollSize=2;
      }
      if(Utility.getFromLocalStorage('pending_kot_sound_status')) {
        if(this.kotPrintAudioEle?.nativeElement) {
          (this.kotPrintAudioEle?.nativeElement as HTMLAudioElement)?.play();
        }
      }
      let div: any = document.querySelectorAll(".kot-canvas-area")[0];
      if(div) {
        div.style.display = 'block';
        div.style.width = kotRollSize==3 ? "76mm" : "50mm";
      }
  
      let kotDate: any = document.querySelectorAll(".kot-canvas-area .kot-date")[0];
      let kobillNo: any = document.querySelectorAll(".kot-canvas-area .kot-billNo")[0];
      let kotparty: any = document.querySelectorAll(".kot-canvas-area .kot-party")[0];
      let kotItemList: any = document.querySelectorAll(".kot-canvas-area .kot-itemList")[0];
  
      if(kotDate) {
        kotDate.innerHTML = `Date: ${Utility.getDateTimeString(new Date())}`;
      }
      if(kobillNo) {
        kobillNo.innerHTML = `Bill No: ${this.currentKot?.sale?.billNo || 'Bill Deleted'}`;
      }
      let documentTitle=document.title;
      document.title=`Bill No: ${this.currentKot?.sale?.billNo}`;
      if(kotparty) {
        kotparty.innerHTML = `${this.currentKot?.sale?.party?.name || ''}`;
      }
      let htmlStr = '';
      this.currentKot?.kot?.printStateItems?.forEach(element => {
        htmlStr += `<h3 style="font-size:4mm;margin:0;padding:0 1mm;">${element?.itemName || ''} (${element?.quantity || 0})</h3>`
        if(element?.note) {
          htmlStr += `<h5 style="font-size:3mm;margin:0;padding:0 1mm;">${element?.note}</h5>`
        }
      });
      if(kotItemList) {
        kotItemList.innerHTML = htmlStr;
      }
  
      let domContainer: any = document.getElementById('div-print-area');
      let dvApp: any = document.getElementById('dv-app');
      if(dvApp) {
        dvApp.style.display="none";
      }
      if(domContainer) {
        domContainer.appendChild(div?.cloneNode(true));
        domContainer?.classList?.add('active');
        domContainer.style.width = "100%";
      }
  
      setTimeout(() => {
        window?.print();
        if(domContainer) {
          domContainer.innerHTML = '';
          domContainer?.classList?.remove('active');
          domContainer.style.width = "100%";
        }
        if(div) {
          div.style.display = 'none';
        }
        if(dvApp) {
          dvApp.style.display="block";
        }
        document.title=documentTitle;
      }, 500);
    } catch (error) {
      SentryUtilites.setLog("KotPage:initKot", error)
    }
  }

  @HostListener('window:resize', ['$event'])
  OnResize() {
    try {
      setTimeout(()=> {
        this.isMobile = this.commonService.isMobile();
        if(this.isMobile) {
          this.innerHeight = `${(((window?.innerHeight - 176)/window?.innerHeight) * 100)}%`;
        } else {
          this.innerHeight = `${(((window?.innerHeight - 120)/window?.innerHeight) * 100)}%`;
        }
      }, 20)
    } catch (error) {
      SentryUtilites.setLog("KotPage:OnResize", error)
    }
  }

}
