import { BehaviorSubject } from "rxjs";
import { Utility } from "../utils/utility";
import { ExpressServerService } from "./api/express-server.service";
import { IDataService } from "../../interface/IDataService.interface";
import { ItemDao } from "./dao/item.dao";
import { PartyDao } from "./dao/party.dao";
import { MoneyIn } from "../models/MoneyIn.model";
import { AuthService } from "./auth/auth.service";
import { Utils } from "../utils/utils";
import { MonthWisePartyCreditDao } from "./dao/month-wise-party-credit.dao";
import { MonthWiseItemStockDao } from "./dao/month-wise-item-stock.dao";
import { SentryUtilites } from "../utils/sentryUtilites";
import { PurchaseReturn } from "../models/PurchaseReturn.model";
import { MoneyInDao } from "./dao/money-in.dao";
import { PurchaseReturnDao } from "./dao/purchase-return.dao";
import { PurchaseDao } from "./dao/purchase.dao";

export class PurchaseReturnService implements IDataService<PurchaseReturn>{
  private static _instance: PurchaseReturnService;

  public static getInstance(
    purchaseReturnDao: PurchaseReturnDao,
    partyDao: PartyDao,
    itemDao: ItemDao,
    purchaseDao: PurchaseDao,
    moneyInDao: MoneyInDao,
    expressServerService: ExpressServerService,
    authService: AuthService,
    monthWisePartyCreditDao: MonthWisePartyCreditDao,
    monthWiseItemStockDao: MonthWiseItemStockDao,
  ) {
    if (!this._instance) {
      this._instance = new PurchaseReturnService(
        purchaseReturnDao,
        partyDao,
        itemDao,
        purchaseDao,
        moneyInDao,
        expressServerService,
        authService,
        monthWisePartyCreditDao,
        monthWiseItemStockDao,
      )
      this._instance.initService();
    }
    this._instance.reloadList();
    return this._instance;
  }

  constructor(
    dao: PurchaseReturnDao,
    partyDao: PartyDao,
    itemDao: ItemDao,
    purchaseDao: PurchaseDao,
    moneyInDao: MoneyInDao,
    expressServerService: ExpressServerService,
    authService: AuthService,
    monthWisePartyCreditDao: MonthWisePartyCreditDao,
    monthWiseItemStockDao: MonthWiseItemStockDao,
  ) {
    this.dao = dao;
    this.expressServerService = expressServerService;
    this.itemDao = itemDao
    this.partyDao = partyDao;
    this.purchaseDao = purchaseDao;
    this.moneyInDao = moneyInDao;
    this.authService = authService;
    this.monthWisePartyCreditDao = monthWisePartyCreditDao;
    this.monthWiseItemStockDao = monthWiseItemStockDao;
  }
  dao: PurchaseReturnDao;
  itemDao: ItemDao;
  partyDao: PartyDao;
  purchaseDao: PurchaseDao;
  moneyInDao: MoneyInDao;
  expressServerService: ExpressServerService;
  authService: AuthService;
  monthWisePartyCreditDao: MonthWisePartyCreditDao;
  monthWiseItemStockDao: MonthWiseItemStockDao;

  LIST_REFRESH_RATE = 1000;
  selectedProfileId: string = null;
  selectedProfileUserId: string = null;
  updateSubs = new BehaviorSubject<PurchaseReturn>(null);


  lastReloadStamp: number = 0;
  isReloadPostpond = false;

  initService() {
    this.selectedProfileId = Utility.getFromLocalStorage('selectedProfile');
    this.selectedProfileUserId = Utility.getFromLocalStorage('selectedProfileUserId');
    this.reloadList();
  }


  async reloadList() {
    try {
      if (this.isReloadPostpond) {
        return;
      }
      const currentStamp = +new Date();
      if (this.lastReloadStamp < (currentStamp - this.LIST_REFRESH_RATE)) {
        this.lastReloadStamp = currentStamp;
        this.trySyncUnsynced();
      } else {
        this.isReloadPostpond = true;
        setTimeout(() => {
          this.isReloadPostpond = false;
          this.reloadList();
        }, this.LIST_REFRESH_RATE + 100);
      }
    } catch (error) {
      SentryUtilites.setLog("PurchaseReturnService:reloadList", error)
      return null;
    }
  }

  getAll() {
    return this.dao.getAll();
  }

  getAllByPromise() {
    return this.dao.getAllByProfile(this.selectedProfileId);
  }

  getAllByPromiseByProfile(profileId: string) {
    return this.dao.getAllByProfile(profileId);
  }

  getAllWithDeletedByProfile() {
    return this.dao.getAllWithDeletedByProfile(this.selectedProfileId);
  }

  getByBillDateRange(startTime: number, endTime: number, profileId?: string): Promise<PurchaseReturn[]> {
    return new Promise(async (resolve, reject) => {
      try {
        let allDocs = await this.dao.getAllByProfile(profileId || this.selectedProfileId);
        if (allDocs != null) {
          let filteredDocs = allDocs.filter(doc => doc?.billDateStamp >= startTime && doc?.billDateStamp < endTime);
          return resolve(filteredDocs);
        } else {
          return resolve(null);
        }
      } catch (error) {
        SentryUtilites.setLog("PurchaseReturnService:getByBillDateRange", error)
        return resolve(null);
      }
    });
  }

  getByCreatedDateRange(startTime: number, endTime: number): Promise<PurchaseReturn[]> {
    return new Promise(async (resolve, reject) => {
      try {
        let allDocs = await this.dao.getAllByProfile(this.selectedProfileId);
        if (allDocs != null) {
          let filteredDocs = allDocs.filter(doc => doc?.createdStamp >= startTime && doc?.createdStamp < endTime);
          return resolve(filteredDocs);
        } else {
          return resolve(null);
        }
      } catch (error) {
        SentryUtilites.setLog("PurchaseReturnService:getByCreatedDateRange", error)
        return resolve(null);
      }
    });
  }

  getById(id: number): Promise<PurchaseReturn> {
    return this.dao.getById(id);
  }

  getByUUID(uuid: string): Promise<PurchaseReturn> {
    return this.dao.getByUUID(uuid);
  }

  save(purchaseReturn: PurchaseReturn): Promise<PurchaseReturn> {
    return new Promise(async (resolve, reject) => {
      try {
        if(Utility.isTruthy(purchaseReturn)) {
          let currentProfile = this.selectedProfileId;
          purchaseReturn.userId = this.selectedProfileUserId;
          if(!purchaseReturn?.profileId) {
            purchaseReturn.profileId = currentProfile;
          }
          purchaseReturn.createdBy = purchaseReturn.lastModifiedBy = this.authService.getLoginPhone();
          purchaseReturn.createdByName = purchaseReturn.lastModifiedByName = Utility.getCreatedByName();
  
          if (!purchaseReturn?._localUUID) {
            purchaseReturn._localUUID = Utility.getUUID();
          }
  
          if(!purchaseReturn?.amountReceived) {
            purchaseReturn.amountReceived = 0.0;
          }
  
  
          if (purchaseReturn?.moneyIns?.length === 1) {
            purchaseReturn.moneyIns[0].userId = this.selectedProfileUserId;
            purchaseReturn.moneyIns[0].profileId = this.selectedProfileId;
            purchaseReturn.moneyIns[0].createdBy = purchaseReturn.moneyIns[0].lastModifiedBy = this.authService.getLoginPhone();
            purchaseReturn.moneyIns[0].createdByName = purchaseReturn.moneyIns[0].lastModifiedByName = Utility.getCreatedByName();
            purchaseReturn.moneyIns[0].linkedPurchaseReturnUUID = purchaseReturn?._localUUID;
            purchaseReturn.moneyIns[0]._localUUID = Utility.getUUID();
            purchaseReturn.amountReceived = purchaseReturn?.moneyIns[0]?.totalAmount || 0.0;
          }
          
          let savedPurchaseReturn = await this.dao.save(purchaseReturn);
          
          if(savedPurchaseReturn?._localUUID) {
  
            // Link Purchase
            if(savedPurchaseReturn?.linkedPurchaseUUID) {
              await this.purchaseDao.linkPurchaseReturn(savedPurchaseReturn?.linkedPurchaseUUID, savedPurchaseReturn?._localUUID);
            }
  
            //MoneyIn
            //----------------------------------------
            if (savedPurchaseReturn.moneyIns?.length === 1) {
              let savedMoneyIn = await this.moneyInDao.save(savedPurchaseReturn?.moneyIns[0]);
              if(savedMoneyIn?._localUUID) {
                let totalAmount = savedMoneyIn?.totalAmount || 0.0;
                if (totalAmount > 0) {
                  savedMoneyIn.party.lastModifiedBy = savedPurchaseReturn?.lastModifiedBy;
                  savedMoneyIn.party.lastModifiedByName = savedPurchaseReturn?.lastModifiedByName;
                  await this.partyDao.updateCredit(
                    savedMoneyIn?.party,
                    -totalAmount
                  )
                  await this.monthWisePartyCreditDao?.modifyCredit(
                    savedMoneyIn?.party?._localUUID,
                    savedMoneyIn?.billDateStamp,
                    -totalAmount
                  )
                }
              }
            }
            //----------------------------------------
  
            //Party
            savedPurchaseReturn.party.lastModifiedBy = savedPurchaseReturn?.lastModifiedBy;
            savedPurchaseReturn.party.lastModifiedByName = savedPurchaseReturn?.lastModifiedByName;
            await this.partyDao.updateCredit(
              savedPurchaseReturn?.party,
              savedPurchaseReturn?.totalAmount
              )
            await this.monthWisePartyCreditDao?.modifyCredit(
              savedPurchaseReturn?.party?._localUUID,
              savedPurchaseReturn?.billDateStamp,
              savedPurchaseReturn?.totalAmount
            )
            //----------------------------------------
  
            //Item
  
            for (let i = 0; i < savedPurchaseReturn?.billItems?.length; i++) {
              let billItem = savedPurchaseReturn?.billItems[i];
              if(billItem?.item?._localUUID) {
                billItem.item.lastModifiedBy = savedPurchaseReturn?.lastModifiedBy;
                billItem.item.lastModifiedByName = savedPurchaseReturn?.lastModifiedByName;
                let qty = billItem?.unit === billItem?.item?.primaryUnit ? billItem?.quantity : Utils.capFractionsToTwo(billItem?.quantity / billItem?.convertRatioMultiplier);
                await this.itemDao.updateStock(
                  billItem?.item,
                  -qty
                );
                await this.monthWiseItemStockDao?.modifyStock(
                  billItem?.item?._localUUID,
                  savedPurchaseReturn?.billDateStamp,
                  -qty
                )
              }
            }
  
            //----------------------------------------
  
          }
  
          this.reloadList();
          return resolve(savedPurchaseReturn);
        } else {
          return resolve(null);
        }

      } catch (err) {
        SentryUtilites.setLog("PurchaseReturnService:save", err)
        return resolve(null)
      }

    });
  }

  update(purchaseReturn: PurchaseReturn): Promise<PurchaseReturn> {
    return new Promise(async (resolve, reject) => {
      try {
        if (purchaseReturn?._localUUID) {
          purchaseReturn.lastModifiedBy = this.authService.getLoginPhone();
          purchaseReturn.lastModifiedByName = Utility.getCreatedByName();
          let oldPurchaseReturn = await this.getByUUID(purchaseReturn?._localUUID);

          if (
            oldPurchaseReturn?.party?._localUUID != null
            && oldPurchaseReturn?.party?._localUUID != ""
            && purchaseReturn?.party?._localUUID != ""
            && oldPurchaseReturn?.party?._localUUID == purchaseReturn?.party?._localUUID
          ) {

            if(purchaseReturn?.moneyIns?.length) {
              let totalAmountPaid = 0.0;
              purchaseReturn?.moneyIns?.forEach(x => totalAmountPaid += Number(x?.totalAmount));
              purchaseReturn.amountReceived = totalAmountPaid;
            }else {
              purchaseReturn.amountReceived = 0.0;
            }

            let addMoneyIns: MoneyIn[] = [];
            let updateMoneyIns: MoneyIn[] = [];
            let deleteMoneyIns: MoneyIn[] = [];

            purchaseReturn?.moneyIns?.forEach(newMoneyIn => {
              let isMatched = false;
              oldPurchaseReturn?.moneyIns?.forEach(oldMoneyIn => {
                if(oldMoneyIn?._localUUID === newMoneyIn?._localUUID) {
                  isMatched = true;
                  return;
                }
              });
              if(isMatched) {
                // ConstraintError: Unable to add key to index '_localUUID': at least one key does not satisfy the uniqueness requirements.
                // getting this error if _localId is not match with your current indexedDb _localId at time of update and delete.
                delete newMoneyIn?._localId;
                updateMoneyIns.push(newMoneyIn);
              }else {
                newMoneyIn._localUUID = Utility.getUUID();
                newMoneyIn.profileId = purchaseReturn?.profileId;
                newMoneyIn.userId = purchaseReturn?.userId;
                newMoneyIn.createdBy = newMoneyIn.lastModifiedBy = this.authService.getLoginPhone();
                newMoneyIn.createdByName = newMoneyIn.lastModifiedByName = Utility.getCreatedByName();
                newMoneyIn.linkedPurchaseReturnUUID = purchaseReturn?._localUUID;
                newMoneyIn.party = purchaseReturn?.party;
                addMoneyIns.push(newMoneyIn);
              }
            });

            oldPurchaseReturn?.moneyIns?.forEach(oldMoneyIn => {
              if(oldMoneyIn?._localUUID) {
                let shouldDelete = true;
                purchaseReturn?.moneyIns?.forEach(newMoneyIn => {
                  if(oldMoneyIn?._localUUID === newMoneyIn?._localUUID) {
                    shouldDelete = false;
                  }
                });
                if(shouldDelete) {
                  deleteMoneyIns.push(oldMoneyIn);
                }
              }
            });

            purchaseReturn.moneyIns = [...addMoneyIns, ...updateMoneyIns];

            let updatedPurchaseReturn = await this.dao.update(purchaseReturn);

            if(updatedPurchaseReturn?._localUUID) {

              //MoneyIn

              if(addMoneyIns?.length) {
                for (let i = 0; i < addMoneyIns?.length; i++) {
                  let moneyOut = addMoneyIns[i];
                  let savedMoneyIn = await this.moneyInDao.save(moneyOut);
                  if(savedMoneyIn?._localUUID) {
                    savedMoneyIn.party.lastModifiedBy = updatedPurchaseReturn?.lastModifiedBy;
                    savedMoneyIn.party.lastModifiedByName = updatedPurchaseReturn?.lastModifiedByName;
                    await this.partyDao.updateCredit(
                      savedMoneyIn?.party,
                      -savedMoneyIn?.totalAmount
                    );
                    await this.monthWisePartyCreditDao?.modifyCredit(
                      savedMoneyIn?.party?._localUUID,
                      savedMoneyIn?.billDateStamp,
                      -savedMoneyIn?.totalAmount
                    )
                  }
                }
              }

              if(updateMoneyIns?.length) {
                for (let i = 0; i < updateMoneyIns?.length; i++) {
                  let moneyOut = updateMoneyIns[i];
                  let oldMoneyIn = await this.moneyInDao.getByUUID(moneyOut?._localUUID);
                  if(oldMoneyIn?._localUUID) {
                    moneyOut._localId = oldMoneyIn?._localId;
                    moneyOut.createdStamp = oldMoneyIn?.createdStamp;
                    let savedMoneyIn = await this.moneyInDao.update(moneyOut);
                    if(savedMoneyIn?._localUUID) {
                      let deltaAmount = savedMoneyIn?.totalAmount - oldMoneyIn?.totalAmount;
                      savedMoneyIn.party.lastModifiedBy = updatedPurchaseReturn?.lastModifiedBy;
                      savedMoneyIn.party.lastModifiedByName = updatedPurchaseReturn?.lastModifiedByName;
                      await this.partyDao.updateCredit(
                        savedMoneyIn?.party,
                        -deltaAmount
                      );
                      await this.monthWisePartyCreditDao?.modifyCredit(
                        savedMoneyIn?.party?._localUUID,
                        savedMoneyIn?.billDateStamp,
                        -deltaAmount
                      )
                    }
                  }
                }
              }

              if(deleteMoneyIns?.length) {
                for (let i = 0; i < deleteMoneyIns?.length; i++) {
                  let moneyOut = deleteMoneyIns[i];
                  let oldMoneyIn = await this.moneyInDao.getByUUID(moneyOut?._localUUID);
                  if(oldMoneyIn?._localUUID) {
                    moneyOut._localId = oldMoneyIn?._localId;
                    let savedMoneyIn = await this.moneyInDao.delete(moneyOut);
                    if(savedMoneyIn?._localUUID) {
                      savedMoneyIn.party.lastModifiedBy = updatedPurchaseReturn?.lastModifiedBy;
                      savedMoneyIn.party.lastModifiedByName = updatedPurchaseReturn?.lastModifiedByName;
                      await this.partyDao.updateCredit(
                        savedMoneyIn?.party,
                        savedMoneyIn?.totalAmount
                      );
                      await this.monthWisePartyCreditDao?.modifyCredit(
                        savedMoneyIn?.party?._localUUID,
                        savedMoneyIn?.billDateStamp,
                        savedMoneyIn?.totalAmount
                      )
                    }
                  }
                }
              }

              //------------------------------------------------------------------


              //Party
              //------------------------------------------------------------------
              let deltaPartyCredit:number = 0;
              deltaPartyCredit = (updatedPurchaseReturn?.totalAmount || 0.0) - (oldPurchaseReturn?.totalAmount || 0.0)
              updatedPurchaseReturn.party.lastModifiedBy = updatedPurchaseReturn?.lastModifiedBy;
              updatedPurchaseReturn.party.lastModifiedByName = updatedPurchaseReturn?.lastModifiedByName;
              await this.partyDao.updateCredit(
                updatedPurchaseReturn?.party,
                deltaPartyCredit
              )
              await this.monthWisePartyCreditDao?.modifyCredit(
                updatedPurchaseReturn?.party?._localUUID,
                updatedPurchaseReturn?.billDateStamp,
                deltaPartyCredit
              )

              //Item
              //----------------------------------------
              for (let i = 0; i < oldPurchaseReturn?.billItems?.length; i++) {
                let billItem = oldPurchaseReturn?.billItems[i];
                if(billItem?.item?._localUUID) {
                  billItem.item.lastModifiedBy = updatedPurchaseReturn?.lastModifiedBy;
                  billItem.item.lastModifiedByName = updatedPurchaseReturn?.lastModifiedByName;
                  let qty = billItem?.unit === billItem?.item?.primaryUnit ? billItem?.quantity : Utils.capFractionsToTwo(billItem?.quantity / billItem?.convertRatioMultiplier);
                  await this.itemDao.updateStock(
                    billItem?.item,
                    qty
                  )
                  await this.monthWiseItemStockDao?.modifyStock(
                    billItem?.item?._localUUID,
                    oldPurchaseReturn?.billDateStamp,
                    qty
                  )
                }
              }

              for (let i = 0; i < updatedPurchaseReturn?.billItems?.length; i++) {
                let billItem = updatedPurchaseReturn?.billItems[i];
                if(billItem?.item?._localUUID) {
                  billItem.item.lastModifiedBy = updatedPurchaseReturn?.lastModifiedBy;
                  billItem.item.lastModifiedByName = updatedPurchaseReturn?.lastModifiedByName;
                  let qty = billItem?.unit === billItem?.item?.primaryUnit ? billItem?.quantity : Utils.capFractionsToTwo(billItem?.quantity / billItem?.convertRatioMultiplier);
                  await this.itemDao.updateStock(
                    billItem?.item,
                    -qty
                  );
                  await this.monthWiseItemStockDao?.modifyStock(
                    billItem?.item?._localUUID,
                    updatedPurchaseReturn?.billDateStamp,
                    -qty
                  )
                }
              }

            }

            this.reloadList();
            this.updateSubs.next(updatedPurchaseReturn);
            return resolve(updatedPurchaseReturn);

          }
        }
        return resolve(null)
      } catch (err) {
        SentryUtilites.setLog("PurchaseReturnService:update", err)
        return resolve(null)
      }
    });
  }

  delete(purchaseReturn: PurchaseReturn): Promise<PurchaseReturn> {
    return new Promise(async (resolve, reject) => {
      try {
        if(purchaseReturn?._localUUID) {
          purchaseReturn.lastModifiedBy = this.authService.getLoginPhone();
          purchaseReturn.lastModifiedByName = Utility.getCreatedByName();
          let purchaseTobeDeleted = await this.getByUUID(purchaseReturn?._localUUID)
          let deletedPurchaseReturn = await this.dao.delete(purchaseTobeDeleted);
  
          if(deletedPurchaseReturn?._localUUID) {
  
            // Unlink Purchase
            if(deletedPurchaseReturn?.linkedPurchaseUUID) {
              await this.purchaseDao.unLinkPurchaseReturn(deletedPurchaseReturn?.linkedPurchaseUUID, deletedPurchaseReturn?._localUUID);
            }

            //MoneyIn
  
            if (purchaseTobeDeleted?.moneyIns?.length) {
              for (let i = 0; i < purchaseTobeDeleted?.moneyIns?.length; i++) {
                const moneyOut = purchaseTobeDeleted?.moneyIns[i];
                let fetchedMoneyIn = await this.moneyInDao.getByUUID(moneyOut?._localUUID);
                if(fetchedMoneyIn?._localUUID) {
                  fetchedMoneyIn.lastModifiedBy = this.authService.getLoginPhone();
                  fetchedMoneyIn.lastModifiedByName = Utility.getCreatedByName();
                  let deletedMoneyOut = await this.moneyInDao?.delete(fetchedMoneyIn);
                  if(deletedMoneyOut?._localUUID) {
                    let totalAmount = deletedMoneyOut?.totalAmount || 0.0;
                    if (totalAmount > 0) {
                     purchaseTobeDeleted.lastModifiedBy = deletedPurchaseReturn?.lastModifiedBy;
                     purchaseTobeDeleted.lastModifiedByName = deletedPurchaseReturn?.lastModifiedByName;
                      await this.partyDao.updateCredit(
                        purchaseTobeDeleted?.party,
                        totalAmount
                      )
                      await this.monthWisePartyCreditDao?.modifyCredit(
                        purchaseTobeDeleted?.party?._localUUID,
                        purchaseTobeDeleted?.billDateStamp,
                        totalAmount
                      )
                    }
                  }
                }
              }
            }
  
            //----------------------------------------
  
            //Party
            //----------------------------------------
            deletedPurchaseReturn.party.lastModifiedBy = deletedPurchaseReturn?.lastModifiedBy;
            deletedPurchaseReturn.party.lastModifiedByName = deletedPurchaseReturn?.lastModifiedByName;
            await this.partyDao.updateCredit(
              deletedPurchaseReturn?.party,
              -(deletedPurchaseReturn?.totalAmount || 0.0)
            )
            await this.monthWisePartyCreditDao?.modifyCredit(
              deletedPurchaseReturn?.party?._localUUID,
              deletedPurchaseReturn?.billDateStamp,
              -(deletedPurchaseReturn?.totalAmount || 0)
            )
  
            //Item
            //----------------------------------------
            for (let i = 0; i < deletedPurchaseReturn?.billItems?.length; i++) {
              let billItem = deletedPurchaseReturn?.billItems[i];
              billItem.item.lastModifiedBy = deletedPurchaseReturn?.lastModifiedBy;
              billItem.item.lastModifiedByName = deletedPurchaseReturn?.lastModifiedByName;
              let qty = billItem?.unit === billItem?.item?.primaryUnit ? billItem?.quantity : Utils.capFractionsToTwo(billItem?.quantity / billItem?.convertRatioMultiplier);
              await this.itemDao.updateStock(
                billItem?.item,
                qty
              )
              await this.monthWiseItemStockDao?.modifyStock(
                billItem?.item?._localUUID,
                deletedPurchaseReturn?.billDateStamp,
                qty
              )
            }
  
          }
  
          this.reloadList();
          this.updateSubs.next(deletedPurchaseReturn);
  
          return resolve(deletedPurchaseReturn);
        }
        return resolve(null);

      } catch (err) {
        SentryUtilites.setLog("PurchaseReturnService:delete", err)
        return resolve(null);
      }

    });
  }

  isSyncLock = false;
  isSyncPostPond = false;
  async trySyncUnsynced(postpond?: boolean) {
    try {
      if (this.isSyncLock) {
        if (!this.isSyncPostPond) {
          setTimeout(() => {
            this.isSyncPostPond = true;
            this.trySyncUnsynced(true);
          }, 200);
        }
        return true;
      }
      if (postpond) {
        this.isSyncPostPond = false;
      }
      this.isSyncLock = true;
      let unSyncedElements: PurchaseReturn[] = await this.dao.getAllUnsynced(this.selectedProfileId);
      if (unSyncedElements && unSyncedElements.length) {
        try {
          
          for (let i = 0; i < unSyncedElements.length; i++) {
            let unSyncedElement = unSyncedElements[i];
            if(unSyncedElement?._localUUID) {
              unSyncedElement['updatedStamp'] = +new Date();
            }
          }
  
          await this.dao.bulkPut(unSyncedElements);
          await Utility.wait(1000);
  
          let chunkArr = Utility.getChunkArr(unSyncedElements);
          let chunkArrLength = chunkArr?.length;
  
          for(let i = 0; i < chunkArrLength; i++) {
            let result = await this.expressServerService.makeSyncCall('purchaseReturn', chunkArr[i]);
            if (result && result?.['records']?.length) {
              let arr = result?.['records'];
              for (let i = 0; i < arr.length; i++) {
                const el = arr[i];
                await this.updateSyncStamp(el);
              }
            }
          }
        } catch (err) {
          SentryUtilites.setLog("PurchaseReturnService:trySyncUnsynced:inner", err)
        }
      }
      this.isSyncLock = false;
    } catch (error) {
      SentryUtilites.setLog("PurchaseReturnService:trySyncUnsynced", error)
    }
  }

  updateSyncStamp(el: PurchaseReturn): Promise<PurchaseReturn> {
    return new Promise(async (resolve, reject) => {
      try {
        let updatedEl = await this.dao.updateSyncStamp(el);
        this.updateSubs.next(updatedEl);
        return resolve(updatedEl);
      } catch (error) {
        SentryUtilites.setLog("PurchaseReturnService:updateSyncStamp", error)
        return resolve(null);
      }
    });
  }

  async getNewPurchaseReturnNo(): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        let purchases = await this.dao.getAllByProfile(this.selectedProfileId);
        let nextPurchaseNo = 'PUR_RTN_001';
        if (purchases[0]?.billNo) {
          nextPurchaseNo = Utility.nextNo(purchases[0]?.billNo);
        }
        return resolve(nextPurchaseNo);
      } catch (error) {
        SentryUtilites.setLog("PurchaseReturnService:getNewPurchaseNo", error)
        return resolve(null);
      }

    });
  }

  /**
   * 
   * @returns : return deleted purchases from purchaseReturn dao
   */
  async getAllDeleted(): Promise<PurchaseReturn[]> {
    try {
      let res = await this.dao.getAllDeletedByProfile(this.selectedProfileId);
      return res || [];
    } catch (error) {
      SentryUtilites.setLog("PurchaseReturnService:getAllDeleted", error)
      return [];
    }
  }
  // ----------------------------------------------

  copyData(fromProfileId: string, toProfileId: string): Promise<boolean> {
    return new Promise(async (resolve,reject) => {
      try {
        if(Utility.isTruthy(fromProfileId) && Utility.isTruthy(toProfileId)) {
          let fromRecords = await this.getAllByPromiseByProfile(fromProfileId);
          if(fromRecords?.length) {
            let toRecords: PurchaseReturn[] = [];
            for (let i = 0; i < fromRecords?.length; i++) {
              const fetchedRecord = fromRecords[i];
              if(fetchedRecord?._localUUID) {
                fetchedRecord.profileId = toProfileId;
                delete fetchedRecord?._localId;
                delete fetchedRecord?._localUUID;
                let savedRecord = await this.save(fetchedRecord);
                if(savedRecord?._localUUID) {
                  toRecords?.push(savedRecord);
                }
              }
            }
            if(fromRecords?.length === toRecords?.length) {
              return resolve(true);
            }
          }
        }
        return resolve(false);
      } catch (error) {
        SentryUtilites.setLog("PurchaseReturnService:copyData", error)
        return resolve(false);
      }
    });
  }

}
