import { AllDataService } from './../../services/all-data.service';
import { Component, OnInit } from '@angular/core';
import { Utility } from '../../utils/utility';
import Party from '../../models/Party.model';
import { Router } from '@angular/router';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-payment-reminder',
  templateUrl: './payment-reminder.component.html',
  styleUrls: ['./payment-reminder.component.scss'],
})
export class PaymentReminderComponent implements OnInit {

  dpPaymentReminderDateValue: string = "";
  selectedDateRange: 'today' | 'tomorrow' = 'today';
  paymentReminderDateStamp: number = +new Date().setHours(0,0,0,0);
  paymentReminderParties: Party[] = [];

  MATHABS = Math.abs;
  maxDate = Utility.maxDateUpto50Year();

  constructor(
    private allDataService: AllDataService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.getPaymentReminderParties();
  }

  ngAfterViewInit() { }

  onDateSelect(event) {
    try {
      let value = event?.detail?.value;
      if(value) {
        this.paymentReminderDateStamp = +new Date(value).setHours(0,0,0,0);
        this.getPaymentReminderParties();
      }
      // Utility.closeDatePicker(event?.target);
    } catch (error) {
      SentryUtilites.setLog("PaymentReminderComponent:onDateSelect", error)
    }
  }

  onDateRangeChange(event) {
    try {
      this.selectedDateRange = event?.detail?.value;
      if(this.selectedDateRange === 'today') {
        this.paymentReminderDateStamp = +new Date().setHours(0,0,0,0);
      }else if(this.selectedDateRange === 'tomorrow') {
        this.paymentReminderDateStamp = +new Date().setHours(0,0,0,0) + 86400000;
      }
      this.dpPaymentReminderDateValue = Utility.ionDatePickerFormattedString(this.paymentReminderDateStamp);
      this.getPaymentReminderParties();
    } catch (error) {
      SentryUtilites.setLog("PaymentReminderComponent:onDateRangeChange", error)
    }
  }

  async getPaymentReminderParties() {
    try {
      let parties = await this.allDataService.partyService.getAllByPromise();
      let sales = await this.allDataService.saleService.getAllByPromise();
  
      const startStamp = this.paymentReminderDateStamp;
      const endStamp = +new Date(this.paymentReminderDateStamp).setHours(23,59,59,999);
  
      this.paymentReminderParties = [];
  
      if(sales?.length) {
        sales?.forEach(sale => {
          if(sale?.dueDateStamp>=startStamp && sale?.dueDateStamp<=endStamp) {
            let index = parties?.findIndex(party => party?._localUUID===sale?.party?._localUUID);
            if(index !== -1) {
              this.paymentReminderParties.push(parties[index]);
            }
          }
        });
      }
  
      //TODO Party due key
    } catch (error) {
      SentryUtilites.setLog("PaymentReminderComponent:getPaymentReminderParties", error)
    }

  }

  emitElement(party: Party) {
    this.router.navigate([`party/transaction/${party?._localUUID}`]);
  }

}
