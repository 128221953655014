<input type="file" (cancel)="cancelFileDialog($event)" style="visibility: hidden;" (change)="fileChangeEvent($event)" #fileEvent>
<ion-modal [isOpen]="isModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar color="primary">
        <ion-title>Upload Image</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="openModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <ion-grid>
        <ion-row>
          <ion-col size="12">
            <!-- <ion-item *ngIf="isImageLoader" class="img-loader">
              <ion-spinner name="lines-sharp" color="primary"></ion-spinner>
              <ion-label>Image Loading</ion-label>
            </ion-item> -->
            <image-cropper
                [imageChangedEvent]="imageChangedEvent"
                [maintainAspectRatio]="maintainAspectRatio"
                [aspectRatio]="1/1"
                format="png"
                (imageCropped)="imageCropped($event)"
                (imageLoaded)="imageLoaded($event)"
                (cropperReady)="cropperReady()"
                (loadImageFailed)="loadImageFailed()"
            ></image-cropper>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <img [src]="croppedImage" />
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
    <ion-toolbar>
      <ion-button
        class="btn-save"
        expand="full"
        [disabled]="!croppedImage ? true : false"
        (click)="upload()"
      >
        Upload
      </ion-button>
    </ion-toolbar>
  </ng-template>
</ion-modal>
