<ion-grid class="all-list" [ngClass]="{'ion-modal':isModal,'mobile': isMobile}">
  <ion-row class="padding-none party-item-custom-row col-custom-row" *ngFor="let accessTo of accessToList" (click)="getSelectedStaff(accessTo)">
    <ion-col size="6" class="txt-left padding-none" >
      <ion-row>
        <ion-col size="12">
          <ion-button fill="clear" class="btn-txt-black btn-party-item-name">
            {{ accessTo.name }}
          </ion-button>
        </ion-col>
        <ion-col size="12">
          <ion-icon class="ion-party-phone label-note" *ngIf="accessTo?.userId" name="call"></ion-icon>
          <ion-label class="label-party-phone label-note">
            {{accessTo?.userId}}
          </ion-label>
        </ion-col>
      </ion-row>
    </ion-col>
  </ion-row>

</ion-grid>