export const environment = {
  production: true,
  expressServerV2: 'https://ezobanks.com:5001/api/v2/',
  ezoOfServer: 'https://db.ezobooks.in/kappa/api/',
  ezoOfServerGmb: 'https://db.ezobooks.in/kappa/gmb/',
  ezoOfServerImage: 'https://db.ezobooks.in/kappa/',
  ezoOfLoginServer: 'https://db.ezobooks.in/kappa/api/',
  ldAdminServer: 'https://legaldocs.co.in/kappa/api/',
  sentryDsn: 'https://ab8d4965e48d88c864ca2dc1c521a828@o4507129633177600.ingest.de.sentry.io/4507368037548112',
  googleSignInClientId: "702748357643-ogh4ojtv444hg0938io7hfksvg2vg70g.apps.googleusercontent.com",
  syncIntervalMillis: 30 * 1000,
  indexedDbName: 'ezo_dbx_1',
  indexedDbVersion: 8,
};

export const firebaseConfig = {
  apiKey: 'AIzaSyDDXAt-vUA27RJKApWXbQE8pxtGxlK-uYk',
  authDomain: 'ezoapp-5bceb.firebaseapp.com',
  databaseURL: 'https://ezoapp-5bceb.firebaseio.com/',
  projectId: 'ezoapp-5bceb',
  storageBucket: 'ezoapp-5bceb.appspot.com',
  messagingSenderId: '702748357643',
  appId: '1:702748357643:web:60c95fbe1777977594430e',
  measurementId: 'G-Z2PPJCTNCS'
};
