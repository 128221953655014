<ion-toolbar *ngIf="!isSecondaryPartyView"
  [style.padding-bottom]="isFilterOptionOpen &&  innerWidth < 1080? '32px' : '0px'">
  <ion-searchbar #searchBar placeholder="Search party name or phone..." [debounce]="100"
    (ionChange)="onFilterChange($event['detail'].value)">
  </ion-searchbar>
</ion-toolbar>

<ion-button class="btn-show-hide" (click)="toggleFilterOptions()">
  {{isFilterOptionOpen ? 'Hide Filter' : 'Show Filter'}}
</ion-button>

<ion-fab *ngIf="isFilterOptionOpen" class="btn-category-by" #categoryFab>
  <ion-fab-button class="first-fab-button" size="small" (click)="closeSatisfactionSort()" (clickOutSide)="closeclickOutSideCategory()">
    Category
  </ion-fab-button>
  <ion-fab-list 
    side="bottom" 
    [ngStyle]="{'max-height': fabListHeight}"
    id="scroll-category-container">
    <ion-fab-button [ngClass]="{'filter-sort-active': !appliedCategory}" (click)="onCategoryChange('')">
      All ({{categoryWisePartyCount['All']}})
    </ion-fab-button>
    <ion-fab-button *ngFor="let cat of categories" [ngClass]="{'filter-sort-active': cat === appliedCategory}"
      (click)="onCategoryChange(cat)">
      {{cat}} ({{ categoryWisePartyCount[cat] || 0 }})
    </ion-fab-button>
  </ion-fab-list>
</ion-fab>

<ion-fab *ngIf="isFilterOptionOpen" class="btn-satisfaction-by" #satisfactionFab>
  <ion-fab-button class="first-fab-button" size="small" (click)="closeCategorySort()" (clickOutSide)="closeclickOutSideSatisfaction()">
    Satisfaction
  </ion-fab-button>
  <ion-fab-list side="bottom">
    <ion-fab-button *ngFor="let satisfaction of satisfactionObj|keyvalue: returnZero"
      [ngClass]="{'filter-sort-active': satisfaction?.value === appliedSatisfaction}"
      (click)="onSatisfactionChange(satisfaction.value)">
      {{satisfaction?.key}}
    </ion-fab-button>
  </ion-fab-list>
</ion-fab>

<ion-fab *ngIf="isFilterOptionOpen" class="btn-sort-by" #sortByFab>
  <ion-fab-button class="first-fab-button" size="small" (click)="closeCategorySatisfaction()" (clickOutSide)="closeclickOutSideSort()">
    Sort By
  </ion-fab-button>
  <ion-fab-list side="bottom">
    <ion-fab-button [ngClass]="{'filter-sort-active': sort?.key===selectedFilter}"
      *ngFor="let sort of sortObj|keyvalue: returnZero" (click)="onSortChnage(sort.key)">
      {{ sort?.value }}
    </ion-fab-button>
  </ion-fab-list>
</ion-fab>

<ion-grid class="all-list" [ngClass]="{'ion-modal':isModal,'mobile': isMobile}">
  <ion-row class="padding-none party-item-custom-row col-custom-row"
    [ngClass]="runningBillHashMap[party?._localUUID]?._localUUID ? runningBillHashMap[party?._localUUID]?.isPrintedKOT ? 'running-bill-color' : 'kot-color' : ''"
    *ngFor="let party of viewFilteredList">
    <ion-col (click)="emitElementExtended(party,runningBillHashMap[party?._localUUID]?._localUUID)" size="6"
      class="txt-left padding-none">
      <ion-row>
        <ion-col size="12">
          <ion-button fill="clear" class="btn-txt-black btn-party-item-name">
            {{ party.name }}
          </ion-button>
        </ion-col>
        <ion-col size="12">
          <ion-icon class="ion-party-phone label-note" *ngIf="party.phone" name="call"></ion-icon>
          <ion-label class="label-party-phone label-note">
            {{ isMaskPhone ? stringMask(party?.phone) : party?.phone }}
          </ion-label>
        </ion-col>
      </ion-row>
    </ion-col>
    <ion-col size="6" class="txt-right">
      <ion-row>
        <ion-col (click)="emitElementExtended(party,runningBillHashMap[party?._localUUID]?._localUUID)" size="12"
          class="padding-none">
          <ion-button fill="clear" class="padding-end-none btn-name label-note"
            *ngIf="runningBillHashMap[party?._localUUID]?._localUUID">
            {{ runningBillHashMap[party?._localUUID]?.isPrintedKOT ? 'Running Bill' : 'Pending KOT' }}
          </ion-button>
          <ion-button fill="outline" class="outline-border btn-details btn-party-running-bill label-note"
            *ngIf="runningBillHashMap[party?._localUUID]?._localUUID">
            {{ runningBillHashMap[party?._localUUID]?.totalAmount | currency: 'INR' }}
          </ion-button>
          <ion-button fill="outline" class="txt-transform-normal btn-party-credit remove-border">
            Credit: <ion-label class="margin-inline-start-10px" [color]="party.credit>=0 ? 'success' : 'danger'">{{
              MATHABS(party.credit) | currency: 'INR' }}</ion-label>
          </ion-button>
        </ion-col>
        <ion-col size="12" class="padding-none">
          <ion-col size="auto" offset="1">
            <img *ngIf="party?.satisfactionIndex" class="satisfaction-index-image"
              [src]="getSatisfactionIndexImagePath(party?.satisfactionIndex)">
            <ion-button 
              class="btn-popover icon-star" 
              title="Favorite" 
              fill="clear" 
              (click)="makeFavourite(party)">
              <ion-icon 
                *ngIf="!party?.isFavourite" 
                color="warning" 
                name="star-outline" 
                size="small">
              </ion-icon>
              <ion-icon 
                *ngIf="party?.isFavourite" 
                color="warning" name="star" size="small"></ion-icon>
            </ion-button>
            <div *ngIf="party?.syncStamp > party?.updatedStamp; then thenBlock else elseBlock"></div>
            <ng-template #thenBlock>
              <ion-button class="btn-popover party-icon-success-warning" title="Data Uploaded" fill="clear">
                <ion-icon 
                  name="checkmark-done-sharp" 
                  size="small"
                  color="success">
                </ion-icon>
              </ion-button>
            </ng-template>
            <ng-template #elseBlock>
              <ion-button class="btn-popover party-icon-success-warning" title="Warning" fill="clear">
                <ion-icon 
                  name="warning-sharp" 
                  color="warning" 
                  size="small">
                </ion-icon>
              </ion-button>
            </ng-template>
          </ion-col>
        </ion-col>
      </ion-row>
    </ion-col>
  </ion-row>
</ion-grid>

<ion-infinite-scroll threshold="10px" *ngIf="viewFilteredList?.length" (ionInfinite)="loadMoreListData($event)">
  <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Loading more data...">
  </ion-infinite-scroll-content>
</ion-infinite-scroll>