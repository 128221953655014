<ion-modal
  [ngClass]="this.checkUserPlatform?.userAgentData?.platform == 'Android' ? 'mobile-setting' : 'modal-settings'"
  [isOpen]="isModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar color="light">
        <ion-title>Item Settings</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="openSettingsModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">
      <ng-container *ngFor="let section of sections">

        <ion-button *ngIf="!viewSectionIds?.length || (viewSectionIds && viewSectionIds?.includes(section?.id))"
          color="tertiary" fill="clear" class="padding-end-none">
          {{ section?.header }}
        </ion-button>

        <ng-container *ngFor="let setting of section?.settings">

          <ion-row class="row-setting"
            *ngIf="!viewSettingKeys?.length || (viewSettingKeys && viewSettingKeys?.includes(setting?.key))">

            <ng-container *ngIf="setting.inputType==='Toggle'">
              <ion-col class="padding-bottom-none" size="10">
                <span>{{ setting.title }}</span> <br>
                <span class="grey-color">{{ setting.value ? 'Enabled' : 'Disabled' }}</span>
              </ion-col>
              <ion-col class="padding-bottom-none txt-right" size="2">
                <span>
                  <ion-toggle color="tertiary" slot="end" [checked]="setting.value" (ionChange)="
                      setting?.sharedPreference
                      ? onSharedPreferenceUpdate(setting,$event)
                      : onSettingUpdate(setting,$event)">
                  </ion-toggle>
                </span>
              </ion-col>
              <ion-col class="padding-top-none" *ngIf="setting?.description" size="12">
                <ion-label color="success">{{ setting.description }}</ion-label>
              </ion-col>

              <ng-container *ngIf="setting?.settings?.length && setting.value===true">

                <ion-col size="12">

                  <ion-row class="row-setting" *ngFor="let subSetting of setting?.settings">
                    <ion-col class="padding-bottom-none" size="10">
                      <span>{{ subSetting.title }}</span> <br>
                      <span class="grey-color">{{ subSetting.value ? 'Enabled' : 'Disabled' }}</span>
                    </ion-col>
                    <ion-col class="padding-bottom-none txt-right" size="2">
                      <span>
                        <ion-toggle color="tertiary" slot="end" [checked]="subSetting.value"
                          (ionChange)="
                            setting?.sharedPreference ? onSharedPreferenceUpdate(setting,$event) : onSettingUpdate(setting,$event)">
                        </ion-toggle>
                      </span>
                    </ion-col>
                    <ion-col class="padding-top-none" *ngIf="subSetting?.description" size="12">
                      <ion-label color="success">{{ subSetting.description }}</ion-label>
                    </ion-col>
                  </ion-row>

                </ion-col>


              </ng-container>

            </ng-container>

            <ng-container *ngIf="setting.inputType==='Segment'">
              <ion-col class="padding-bottom-none" size="12">
                <span>{{ setting.title }}</span> <br>
              </ion-col>
              <ion-col class="padding-top-none padding-bottom-none" size="12">
                <ion-item class="segment" lines="none">
                  <ion-segment mode="ios" [value]="setting.value"
                    (ionChange)="setting?.sharedPreference ? onSharedPreferenceUpdate(setting,$event) : onSettingUpdate(setting,$event)">
                    <ion-segment-button [value]="segmentBtn?.value" *ngFor="let segmentBtn of setting?.segments">
                      <ion-label>{{ segmentBtn?.title }}</ion-label>
                    </ion-segment-button>
                  </ion-segment>
                </ion-item>
              </ion-col>
            </ng-container>

            <ng-container *ngIf="setting?.inputType==='TextArea'">
              <ion-col class="padding-bottom-none" size="12">
                <span>{{ setting?.title }}</span> <br>
              </ion-col>
              <ion-col class="padding-top-none padding-bottom-none" size="12">
                <ion-textarea rows="5" [value]="setting?.value"
                  (ionChange)="setting?.sharedPreference ? onSharedPreferenceUpdate(setting,$event) : onSettingUpdate(setting,$event)"></ion-textarea>
              </ion-col>
              <ion-col *ngIf="setting?.description" size="12">
                <ion-label color="success">{{ setting?.description }}</ion-label>
              </ion-col>
            </ng-container>



          </ion-row>

        </ng-container>

      </ng-container>
    </ion-content>

    <ion-footer>
      <ion-toolbar>
        <ion-button class="btn-save" expand="full" (click)="save()">
          Save
        </ion-button>
      </ion-toolbar>
    </ion-footer>
  </ng-template>
</ion-modal>