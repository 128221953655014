import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Utility } from '../../utils/utility';
import { CommonService } from '../../services/common.service';
import { format, utcToZonedTime } from 'date-fns-tz';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
})
export class DateRangeComponent implements OnInit {

  @Input() listType: 'sale' | 'saleReturn' | 'purchase' | 'purchaseReturn' | 'expense' | 'moneyIn' | 'moneyOut' | 'ledgerItem' | 'ledgerParty' | 'kot' = null;
  @Input() selectedDateRange: 'today' | '7days' | '30days' | 'month' | 'year' | 'custom' = null;
  @Input() isTodaysEndDate: boolean = false;
  @Input() disableYearCustomFilter: boolean = false;

  @Output() dateRangeChange = new EventEmitter<{ fromStamp: number, toStamp: number }>();

  keepContentsMounted: boolean = true;
  openFromDatePicker: boolean = false;
  openToDatePicker: boolean = false;

  @ViewChild('fromDateEle') fromDateEle: any;
  @ViewChild('toDateEle') toDateEle: any;

  dpFromDateValue: string = "";
  fromDateValue: number = +new Date().setHours(0,0,0,0);
  toDateValue: number = +new Date().setHours(23,59,59,999);
  dpToDateValue: string = "";
  isMobile: boolean = null;
  maxDate = Utility.maxDateUpto50Year();
  minToDate = null;
  setDateValue = Utility.setDateValue;

  constructor(
    private commonService: CommonService
  ) { }

  ngOnInit() { 
    try {
      this.isMobile = this.commonService.isMobile();
      this.selectedDateRange = 'today';
    } catch (error) {
      SentryUtilites.setLog("DateRangeComponent:ngOnInit", error)
    }
  }

  ngAfterViewInit() {
    this.populateDateRange();
  }

  ngOnDestroy() {
    try {
      this.keepContentsMounted = false;
      this.dpFromDateValue = null;
      this.dpToDateValue = null;
      this.openFromDatePicker = false;
      this.openToDatePicker = false;
    } catch (error) {
      SentryUtilites.setLog("DateRangeComponent:ngOnDestroy", error)
    }
  }

  populateDateRange() {
    try {
      setTimeout(() => {
        this.keepContentsMounted = true;
      }, 500);
      let dateRange = this.selectedDateRange || 'today';
      if (this.listType) {
        dateRange = this.selectedDateRange || Utility.getFromLocalStorage(this.listType + 'DateRange') || 'today';
      }
      this.selectedDateRange = dateRange as any;
      this.onDateRangeChange({ detail: { value: dateRange } });
    } catch (error) {
      SentryUtilites.setLog("DateRangeComponent:populateDateRange", error)
    }
  }

  onFromToDateSelect(event) {
    try {
      this.keepContentsMounted = true;
      this.openFromDatePicker = false;
      this.openToDatePicker = false;
      if (this.selectedDateRange === 'custom') {
        this.emitDateRange(
          +new Date(this.fromDateEle?.el?.value).setHours(0, 0, 0, 0),
          +new Date(this.toDateEle?.el?.value).setHours(23, 59, 59, 999)
        );
      }
    } catch (error) {
      SentryUtilites.setLog("DateRangeComponent:onFromToDateSelect", error)
    }
  }

  onDateRangeChange(event) {
    try {
      this.openFromDatePicker = false;
      this.openToDatePicker = false;
      this.keepContentsMounted = true;
      this.selectedDateRange = event?.detail?.value;
      const todaysEndTime = +new Date().setHours(23, 59, 59, 999);
      if (this.selectedDateRange === 'today') {
        this.emitDateRange(
          +new Date().setHours(0, 0, 0, 0),
          todaysEndTime
        );
      } else if (this.selectedDateRange === '7days') {
        this.emitDateRange(
          +new Date().setHours(0, 0, 0, 0) - (8.64e7 * 7),
          todaysEndTime
        );
      } else if (this.selectedDateRange === '30days') {
        this.emitDateRange(
          +new Date().setHours(0, 0, 0, 0) - (8.64e7 * 30),
          todaysEndTime
        );
      } else if (this.selectedDateRange === 'month') {
        this.emitDateRange(
          +new Date(new Date().setDate(1)).setHours(0, 0, 0, 0),
          todaysEndTime
        );
      } else if (this.selectedDateRange === 'year') {
        this.emitDateRange(
          new Date().getMonth() > 3
            ? +new Date(new Date().getFullYear(), 3, 1)
            : +new Date(new Date().getFullYear() - 1, 3, 1),
          todaysEndTime
        );
      } else if (this.selectedDateRange === 'custom') {
        this.emitDateRange(
          +new Date().setHours(0, 0, 0, 0),
          todaysEndTime
        );
      }
  
      if (this.listType && this.selectedDateRange) {
        Utility.setToLocalStorage(this.listType + 'DateRange', this.selectedDateRange);
      }
    } catch (error) {
      SentryUtilites.setLog("DateRangeComponent:onDateRangeChange", error)
    }
  }

  emitDateRange(fromStamp: number, toStamp: number) {
    try {
      this.fromDateValue = fromStamp;
      this.toDateValue = toStamp < fromStamp ? +new Date(fromStamp).setHours(23, 59, 59, 999) : toStamp;
      this.minToDate = this.dpFromDateValue = Utility.ionDatePickerFormattedString(fromStamp);
      this.dpToDateValue = Utility.ionDatePickerFormattedString(this.toDateValue);
      this.dateRangeChange.emit({ fromStamp, toStamp: this.toDateValue });
    } catch (error) {
      SentryUtilites.setLog("DateRangeComponent:emitDateRange", error)
    }
  }

  closeDOBPicker() {
    try {
      this.openFromDatePicker = false;
      this.openToDatePicker = false;
    } catch (error) {
      SentryUtilites.setLog("DateRangeComponent:closeDOBPicker", error)
    }
  }

}
