import { BaseModel } from "./BaseModel.model";
import Party from "./Party.model";

export class IMoneyIn {

	static SCHEMA_NAME = 'moneyIn';

}

export class MoneyIn extends BaseModel {

	profileId: string;

	billNo: string;
	billDateStamp: number;
	party: Party;
	totalAmount: number;
	paymentMode: string;
	paymentId:string;
	linkedSaleUUID: string;
	linkedPurchaseReturnUUID: string;

}
