import { BaseModel } from "./BaseModel.model";

export enum ActionType {
  "Sale"= "Sale",
  "SaleReturn"= "SaleReturn",
  "Purchase" = "Purchase",
  "Scrap" = "Scrap",
  "Transfer" = "Transfer",
  "Return" = "Return",
  "EOD" = "EOD"
}

export class IngredientStockAdjust extends BaseModel {

  profileId: string;
  linkedIngredientUUID: string;
  linkedSaleUUID?: string;
  quantity: number;
  note: string;
  unit: string;
  actionType?: ActionType;
  price?: number;
  isSaleUpdate?: boolean;

}
