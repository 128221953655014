import { BaseModel } from "./BaseModel.model";
import { IngredientStockAdjust } from "./IngredientStockAdjust.model";

export class Ingredient extends BaseModel {

  profileId: string;

  name: string;

  category: string;

  stock: number;

  minStock: number;

  description: string;

  images: string[];

  unit: string;

  yesterdayEOD?: IngredientStockAdjust;

  todayEOD?: IngredientStockAdjust;

}
