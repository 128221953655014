import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AllDataService } from '../all-data.service';
import { AuthService } from './auth.service';
import { Validator } from '../../utils/validator';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Injectable({
  providedIn: 'root'
})
export class AccessControlService implements CanActivate {

  pathRoles:{[key:string]:any} = {

    // Profile
    '/profile/form': 'viewProfile',

    // Party
    '/party/form': 'createParty',
    '/party/form/_localUUID': 'editParty',
    '/party': 'viewParty',
    '/party/transaction/_localUUID': 'viewParty',

    // Item
    '/item/form': 'createItem',
    '/item/form/_localUUID': 'editItem',
    'item/barcode-print': 'editItem',
    '/item': 'viewItem',
    '/item/transaction/_localUUID': 'viewItem',

    // Money In
    '/money-in/form': 'createMoneyIn',
    '/money-in/form/_localUUID': 'editMoneyIn',
    '/money-in': 'viewMoneyIn',

    // Money Out
    '/money-out/form': 'createMoneyOut',
    '/money-out/form/_localUUID': 'editMoneyOut',
    '/money-out': 'viewMoneyOut',

    // Estimate
    '/estimate/form': 'createEstimate',
    '/estimate/form/_localUUID': 'editEstimate',
    '/estimate': 'viewEstimate',

    // Purchase
    '/purchase/form': 'createPurchase',
    '/purchase/form/_localUUID': 'editPurchase',
    '/purchase': 'viewPurchase',

    // Purchase Return
    '/purchase-return/form': 'createPurchaseReturn',
    '/purchase-return/form/_localUUID': 'editPurchaseReturn',
    '/purchase-return': 'viewPurchaseReturn',

    // Expense
    '/expense/form': 'createExpense',
    '/expense/form/_localUUID': 'editExpense',
    '/expense': 'viewExpense',

    // Sale
    '/sale/form': 'createSale',
    '/sale/form/_localUUID': 'editSale',
    '/sale': 'viewSale',
    '/bill-view/_localUUID': 'viewSale',

    // Sale Return
    '/sale-return/form': 'createSaleReturn',
    '/sale-return/form/_localUUID': 'editSaleReturn',
    '/sale-return': 'viewSaleReturn',

    // MISC
    '/kot': 'viewKot',

    // DASHBOARD

    // REPORTS
  };

  constructor(
    private allDataService: AllDataService,
    private authService: AuthService,
    private router: Router,
  ) { }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    try {
      const isUserHasAccess = await this.isUserHasAccess({next,state});
  
      if(!isUserHasAccess) {
        this.authService.getLoginPhone() && this.router.navigate(['no-access']);
      }
      return isUserHasAccess;
    } catch (error) {
      SentryUtilites.setLog("AccessControlService:canActivate", error)
      return false;
    }
  }

  async isUserHasAccess(data: {
    next?: ActivatedRouteSnapshot,
    state?: RouterStateSnapshot,
    action?:
          // Profile
            'editProfile'
          //MASK PHONE
          | 'hidePhone'
          // Party
          | 'createParty' | 'editParty' | 'deleteParty'
          // Party Category
          | 'createPartyCategory' | 'editPartyCategory' | 'viewPartyCategory' | 'deletePartyCategory' | 'whatappBulkMessage'
          // Item
          | 'createItem' | 'editItem' | 'deleteItem'
          // Item Category
          | 'createItemCategory' | 'editItemCategory' | 'viewItemCategory' | 'deleteItemCategory'
          // Money In
          | 'createMoneyIn' | 'editMoneyIn' | 'deleteMoneyIn' | 'viewMoneyIn'
          // Money Out
          | 'createMoneyOut' | 'editMoneyOut' | 'deleteMoneyOut' | 'viewMoneyOut'
          // Estimate
          | 'createEstimate' | 'editEstimate' | 'deleteEstimate' | 'viewEstimate'
          // Purchase
          | 'createPurchase' | 'editPurchase' | 'deletePurchase' | 'viewPurchase'
          // Purchase Return
          | 'createPurchaseReturn' | 'editPurchaseReturn' | 'deletePurchaseReturn' | 'viewPurchaseReturn'
          // Expense
          | 'createExpense' | 'editExpense' | 'deleteExpense' | 'viewExpense'
          // Sale
          | 'createSale' | 'editSale' | 'deleteSale' | 'viewSale'
          // Sale Return
          | 'createSaleReturn' | 'editSaleReturn' | 'deleteSaleReturn' | 'viewSaleReturn'
          // MISC
          | 'adjustStock' | 'viewKot'
          // RAW MATERIAL
          | 'createRawMaterialPurchaseEntry' | 'createRawMaterialScrapEntry' | 'createRawMaterialTransferEntry' 
          | 'createRawMaterialReturnEntry' | 'createRawMaterialEODEntry' | 'createRawMaterial' | 'editRawMaterial' 
          | 'deleteRawMaterial' | 'rawMaterialPurchaseBulkEdit'
          // DASHBOARD
          | 'viewDashboardSalesTotal' | 'viewDashboardMoneyInsTotal'
          | 'viewDashboardPurchasesTotal' | 'viewDashboardMoneyOutsTotal'
          | 'viewDashboardPayableTotal' | 'viewDashboardReceivableTotal'
          | 'viewDashboardLowStockCount' | 'viewTodaysCustomer'
          | 'viewTodaysLoyalCustomer' | 'viewTodaysNewCustomer'
          // REPORTS
          | 'viewSaleReport' | 'viewSaleWiseProfitAndLossReport' | 'viewStaffWiseSaleReport' | 'viewPurchaseReport' | 'viewMoneyInReport'
          | 'viewMoneyOutReport' | 'viewPartyLedgerReport' | 'viewPartyReceivablePayableReport' | 'viewStockSummaryReport'
          | 'viewItemSaleReport' | 'viewItemReport' | 'viewDayBookReport' | 'viewAllProfilesDayBookReport' | 'viewCutOffDayReport' | 'ItemDetailReport' | 'PartyDetailreport'
          | 'viewRawMaterialCostReport' | 'viewRawMaterialConsumptionReport' | 'viewRawMaterialPurchaseReport'
          //SETTING
          |'viewPinAccess' | 'viewSetLoginPin' | 'viewResetLoginPin' | 'viewActiveOnlineShop' | 'viewQrForOnlineShop' | 'viewSendBillToOwner' |'viewSendBillToParty'
  }) {
    try {
      let currentProfile = await this.allDataService.profileService.getCurrentProfile();
      let ownerUserId = this.authService.getLoginPhone();
  
      if(ownerUserId === currentProfile?.userId) {
        return true;
      } else {
  
        if(currentProfile?.accessTo?.length && Array.isArray(currentProfile?.accessTo)) {
  
          const accessControl = currentProfile?.accessTo?.filter(accessTo => accessTo?.userId === ownerUserId)[0];
  
          if(accessControl) {
            if(data?.next && data?.state) {
              let key = '';
              if(data.next['_urlSegment']?.segments) {
                data.next['_urlSegment']?.segments?.forEach(segment => {
                  let path = segment?.path;
                  if(path?.length === 36 && path?.match(Validator._localUUID)) {
                    path = "_localUUID";
                  }
                  key += '/'+path;
                });
              }
  
              if(key?.startsWith('/staff')) {
                return false;
              }else if(this.pathRoles.hasOwnProperty(key)) {
                return accessControl[this.pathRoles[key]];
              }else {
                return true;
              }
  
            }
  
            if(data.action) {
              return accessControl[data.action];
            }
  
          }
  
        }
  
      }
  
      return false;
    } catch (error) {
      SentryUtilites.setLog("AccessControlService:isUserHasAccess", error)
      return false;
    }
  }

}
