
export class AccessTo {

  userId: string;
  name: string;
  deletedStamp: number;

  // PROFILE
  viewProfile: boolean;
  editProfile: boolean;

  //MASK PHONE
  hidePhone?: boolean;

  // COLLECTIONS
  createParty: boolean;
  editParty: boolean;
  viewParty: boolean;
  deleteParty: boolean;
  createPartyCategory: boolean;
  editPartyCategory: boolean;
  viewPartyCategory: boolean;
  deletePartyCategory: boolean;
  createItem: boolean;
  editItem: boolean;
  viewItem: boolean;
  deleteItem: boolean;
  createItemCategory: boolean;
  editItemCategory: boolean;
  viewItemCategory: boolean;
  deleteItemCategory: boolean;
  createMoneyIn: boolean;
  editMoneyIn: boolean;
  viewMoneyIn: boolean;
  deleteMoneyIn: boolean;
  createMoneyOut: boolean;
  editMoneyOut: boolean;
  viewMoneyOut: boolean;
  deleteMoneyOut: boolean;
  createEstimate: boolean;
  editEstimate: boolean;
  viewEstimate: boolean;
  deleteEstimate: boolean;
  createPurchase: boolean;
  editPurchase: boolean;
  viewPurchase: boolean;
  deletePurchase: boolean;
  createPurchaseReturn: boolean;
  editPurchaseReturn: boolean;
  viewPurchaseReturn: boolean;
  deletePurchaseReturn: boolean;
  createExpense: boolean;
  editExpense: boolean;
  viewExpense: boolean;
  deleteExpense: boolean;
  createSale: boolean;
  editSale: boolean;
  viewSale: boolean;
  deleteSale: boolean;
  createSaleReturn: boolean;
  editSaleReturn: boolean;
  viewSaleReturn: boolean;
  deleteSaleReturn: boolean;

  // MISC
  adjustStock: boolean;
  viewKot: boolean;

  // RAW MATERIAL
  createRawMaterialPurchaseEntry: Boolean;
  createRawMaterialScrapEntry: Boolean;
  createRawMaterialTransferEntry: Boolean;
  createRawMaterialReturnEntry: Boolean;
  createRawMaterialEODEntry: Boolean;
  createRawMaterial: Boolean;
  editRawMaterial: Boolean;
  deleteRawMaterial: Boolean;
  rawMaterialPurchaseBulkEdit: Boolean;
  
  // DASHBOARD
  viewDashboardSalesTotal: boolean;
  viewDashboardMoneyInsTotal: boolean;
  viewDashboardPurchasesTotal: boolean;
  viewDashboardMoneyOutsTotal: boolean;
  viewDashboardPayableTotal: boolean;
  viewDashboardReceivableTotal: boolean;
  viewDashboardLowStockCount: boolean;
  viewTodaysCustomer:boolean; 
  viewTodaysLoyalCustomer:boolean; 
  viewTodaysNewCustomer:boolean;

  // REPORTS
  viewSaleReport: boolean;
  viewStaffWiseSaleReport : boolean;
  viewSaleWiseProfitAndLossReport: boolean;
  viewPurchaseReport: boolean;
  viewMoneyInReport: boolean;
  viewMoneyOutReport: boolean;
  viewExpenseReport: boolean;
  viewPartyLedgerReport: boolean;
  viewPartyDetailReport: boolean;
  viewPartyReceivablePayableReport: boolean;
  viewStockSummaryReport: boolean;
  viewItemSaleReport: boolean;
  viewItemReport: boolean;
  viewItemDetailReport: boolean;
  viewDayBookReport: boolean;
  viewRawMaterialCostReport: Boolean;
  viewRawMaterialConsumptionReport: Boolean;
  viewRawMaterialPurchaseReport: Boolean;
  viewAllProfilesDayBookReport:boolean; 
  viewCutOffDayReport:boolean;

  //SETTING
  viewPinAccess:boolean; 
  viewSetLoginPin:boolean; 
  viewResetLoginPin:boolean; 
  viewActiveOnlineShop:boolean; 
  viewQrForOnlineShop:boolean;
  viewSendBillToOwner:boolean;
  viewSendBillToParty:boolean;
}
