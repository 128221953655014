import { CommonService } from './services/common.service';
import { PremiumControlService } from './services/auth/premium-control.service';
/**
 * Core Imports
 */
import { Component, HostListener, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';
// -------------------------------------------------------------------------------------

/**
 * Core Imports
 */
import { fromEvent, Observable, Subscription } from 'rxjs';
// -------------------------------------------------------------------------------------

/**
 * Models
 */
import { Profile } from './models/Profile.model';
// -------------------------------------------------------------------------------------

/**
 * Services
 */
import { AllDataService } from './services/all-data.service';
import { AppUpdateService } from './services/app-update.service';
import { AuthService } from './services/auth/auth.service';
// -------------------------------------------------------------------------------------

/**
 * Components
 */
import { SettingsComponent } from './components/settings/settings.component';
// -------------------------------------------------------------------------------------

/**
 * Others
 */
import { Utility } from './utils/utility';
import PackageJson from '../../package.json';
import { LicenceInfoComponent } from './components/licence-info/licence-info.component';
import { LoginTokenInfoComponent } from './components/login-token-info/login-token-info.component';
import { IonAccordionGroup, IonFab } from '@ionic/angular';
import { LogoutService } from './services/logout.service';
import { NonPremiumModalComponent } from './components/non-premium-modal/non-premium-modal.component';
import { EventService } from './services/event.service';
import { ImageBase64Service } from './services/image-base64.service';
import { SentryUtilites } from './utils/sentryUtilites';
// -------------------------------------------------------------------------------------

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {

  /**
   * ViewChilds
   */
  @ViewChild('settingsModal') settingsModal: SettingsComponent;
  @ViewChild('licenceInfoEle') licenceInfoEle: LicenceInfoComponent;
  @ViewChild('loginTokenEle') loginTokenEle: LoginTokenInfoComponent;
  @ViewChild('createNewFabEle') createNewFabEle: IonFab;
  @ViewChild('nonPremiumModalEle') nonPremiumModalEle: NonPremiumModalComponent;
  @ViewChildren('accordionGroup') accordionGroup: QueryList<IonAccordionGroup>;
  // -------------------------------------------------------------------------------------

  // Update App
  isUpdateReady = false;

  // Profile
  selectedProfile: Profile = null;
  logoBase64: string = null;
  loginPhone: string = null;
  isPremiumAccess: boolean = true;

  // Online/Offline Status
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];
  connectionStatus: string;
  connectionMode = {
    online: 'online',
    offline: 'offline'
  };

  // licenseExpiryIndicator
  licenseExpiryIndicatorText: string = null;
  licenseExpiryIndicatorColor: string = null;

  // Side Menu
  version: string = PackageJson.version;
  isSideMenuVisible:string='none';
  pages = [
    {
      title: 'Dashboard',
      url: '/dashboard'
    },
    {
      title: 'Reports',
      url: '/reports'
    },
    {
      title: 'Party',
      url: '/party'
    },
    {
      title: 'Item',
      url: '/item'
    },
    {
      title: 'Raw Material',
      url: '/ingredient'
    },
    {
      title: 'Categories',
      url: '/categories'
    },
    {
      title: 'Sale',
      url: '/sale',
      nested: [
        {
          title: 'Sale Return',
          url: '/sale-return',
        },
      ]
    },
    {
      title: 'Kot ',
      url: '/kot'
    },
    {
      title: 'Estimate',
      url: '/estimate'
    },
    {
      title: 'Orders',
      url: '/order'
    },
    {
      title: 'Purchase',
      url: '/purchase',
      nested: [
        {
          title: 'Purchase Return',
          url: '/purchase-return',
        },
      ]
    },
    {
      title: 'Expense',
      url: '/expense'
    },
    {
      title: 'Money In',
      url: '/money-in'
    },
    {
      title: 'Money Out',
      url: '/money-out'
    },
    {
      title: 'Staff',
      url: '/staff'
    },
    {
      title: 'Settings',
      url: null
    },
    {
      title: 'License',
      url: null
    },
    {
      title: 'Login Devices',
      url: null
    },
    {
      title: 'Log Out',
      url: '/logout'
    },
  ];
  selectedPath = '';
  createNewBillObj:{[key:string]:string} = {
    'Sale': 'sale/form',
    'Estimate': 'estimate/form',
    'Purchase': 'purchase/form',
    // 'Purchase Return': 'purchase-return/form',
    'Expense': 'expense/form',
    'Money In': 'money-in/form',
    'Money Out': 'money-out/form',
    'Party': 'party/form',
    'Item': 'item/form',
  };
  returnZero = () => 0;
  // -------------------------------------------------------------------------------------

  // Utility Methods

  shortStr = Utility.shortStr;
  isTruthy = Utility.isTruthy;

  // -------------------------------------------------------------------------------------

  showPremiumPopup: boolean = false;
  isSupportModalOpen = false;
  positionLeft: boolean = false;

  isStorageZero: number = 0;

  constructor(
    private router: Router,
    private appUpdateService: AppUpdateService,
    private allDataService: AllDataService,
    private authService: AuthService,
    private premiumControlService: PremiumControlService,
    private eventService: EventService,
    private imageBase64Service: ImageBase64Service,
    private commonService: CommonService,
  ) {
    this.menuSubscriptions();
  }

  async ngOnInit() {
    try {
      this.positionLeft = window?.innerWidth > 992 ? false : true;
      this.allDataService.checkFetchAllLicence();
      this.eventService.showPremiumPopup.subscribe(showPremiumPopup => {
        if(this.showPremiumPopup != showPremiumPopup) {
          this.showPremiumPopup = showPremiumPopup;
          this.nonPremiumModalEle?.openModal(showPremiumPopup);
        }
      });
      this.eventService.showContactSupportPopup.subscribe(showContactSupportPopup => {
        this.openSupportModal(showContactSupportPopup);
      });
      window.addEventListener('storage', async (e) => {
        if(!e?.storageArea?.length && this.isStorageZero === 0) {
          console.log('storage length', e.storageArea?.length);
          Utility.setToLocalStorage('isStorageZero', 0);
          window.location.reload();
          this.isStorageZero++;
        }
      });
      this.getLoginPhone();
      this.appUpdateSubscriptions();
      this.appOnlineOfflineStatusSubscription();
      this.authService.userLoggedInSubs.subscribe((isUserLoggedIn)=>{
        this.eventService.showSideNav.subscribe(showSideNav => {
          this.isSideMenuVisible = showSideNav && isUserLoggedIn && Utility.getFromLocalStorage('Initial_data_download_complete') ? '' : 'none';
        })
        isUserLoggedIn ? this.getLoginPhone() : this.router.navigate['sign-in-with-gmail'];
      });
      this.eventService.setShowSideNav(true);
    } catch (error: any) {
        SentryUtilites.setLog("AppComponent:ngOnInit", error)
    }
  }

  ngAfterViewInit() {
    try {
      this.getSelectedProfile();
      this.licenseExpiryIndicator();
  
      this.allDataService.listForceReloadSubs.subscribe((listName: string) => {
        if (listName == 'profile-list') {
          this.getSelectedProfile();
        }
      });
      this.allDataService.listForceReloadSubs.subscribe((listName: string) => {
        if (listName == 'licence-list') {
          if(Utility.getFromLocalStorage('Initial_data_download_complete')) {
            this.isUserHasPremimumAccess(true);
          }
          this.licenseExpiryIndicator();
        }
      });
  
      let loginPhone = this.authService.getLoginPhone();
  
      if(loginPhone == '7972242792') {
        setTimeout(() => {
          document.addEventListener('keydown',(e) => {
            // Barcode Scanner Download Page Issue
            if(e?.keyCode == 74) {
              e?.preventDefault();
            }
          });
        }, 5000);
      }
    } catch (error: any) {
      SentryUtilites.setLog("AppComponent:ngAfterViewInit", error)
    }
  }

  async isUserHasPremimumAccess(alert: boolean = true) {
    try {
      this.isPremiumAccess = await this.premiumControlService.isPremiumAccess({alert: alert});
      return this.isPremiumAccess;
    } catch (error: any) {
      SentryUtilites.setLog("AppComponent:isUserHasPremimumAccess", error)
    }
  }

  ngOnDestroy() {
    this.subscriptions?.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * Online/Offline Status
   */
  appOnlineOfflineStatusSubscription() {
    try {
        // Get the online/offline status from browser window
        this.onlineEvent = fromEvent(window, this.connectionMode?.online);
        this.offlineEvent = fromEvent(window, this.connectionMode?.offline);
        this.connectionStatus = navigator?.onLine ? this.connectionMode?.online : this.connectionMode?.offline;

        this.subscriptions.push(this.onlineEvent.subscribe(e => {
          this.connectionStatus = this.connectionMode?.online;
            this.allDataService.trySyncUnsynced();
            this.allDataService.socketConnect();
          }));

          this.subscriptions.push(this.offlineEvent.subscribe(e => {
          this.connectionStatus = this.connectionMode?.offline;
        }));
    } catch (error: any) {
      SentryUtilites.setLog("AppComponent:appOnlineOfflineStatusSubscription", error)
    }
  }
  // -------------------------------------------------------------------------------------

  /**
   * Application Update Functions
   */
  appUpdateSubscriptions() {
    try {
      this.subscriptions.push(
        this.appUpdateService.appUpdateAvailableEvent.subscribe((isUpdateReady) => {
          this.isUpdateReady = isUpdateReady;
        })
      );
    } catch (error: any) {
      SentryUtilites.setLog("AppComponent:appUpdateSubscriptions", error)
    }
  }

  checkForAppUpdate() {
    this.appUpdateService.checkForAppUpdates();
  }
  // -------------------------------------------------------------------------------------

  /**
   * Profile
   */
  async getSelectedProfile() {
    try {
      let selectedProfileId = Utility.getFromLocalStorage('selectedProfile');
      if (this.isTruthy(selectedProfileId)) {
        this.selectedProfile = await this.allDataService.profileService.getByUUID(selectedProfileId);
        this.populateLogo(this.selectedProfile?.logoLink);
        if(this.isTruthy(this.selectedProfile)) {
          let selectedProfileUserId = Utility.getFromLocalStorage('selectedProfileUserId');
          if(this.selectedProfile?.userId && (!selectedProfileUserId || this.selectedProfile?.userId != selectedProfileUserId)) {
            Utility.setToLocalStorage('selectedProfileUserId', this.selectedProfile?.userId);
          }
        }
      }
    } catch (error: any) {
      SentryUtilites.setLog("AppComponent:getSelectedProfile", error)
    }
  }

  async populateLogo(logoLink: string) {
    try {
      this.logoBase64 = null;
      if(this.isTruthy(logoLink)) {
        const image = await this.allDataService.imageService.getByUUID(logoLink);
        let base64 = await this.imageBase64Service.getBase64FromDBorServerWithoutSave(image);
        if(base64) {
          this.logoBase64 = base64;
        }
      }
    } catch (error: any) {
      SentryUtilites.setLog("AppComponent:populateLogo", error)
    }
  }

  getLoginPhone() {
    try {
      this.loginPhone = this.authService.getLoginPhone();
      if(!this.isTruthy(this.loginPhone) && this.authService?.isUserLoggedIn) {
        setTimeout(() => {
          this.getLoginPhone();
        }, 3000);
      }
    } catch (error: any) {
      SentryUtilites.setLog("AppComponent:getLoginPhone", error)
    }
  }

  // -------------------------------------------------------------------------------------

  /**
  * Navigation Menu
  */
  menuSubscriptions() {
    try {
      this.subscriptions.push(this.router.events.subscribe((event: RouterEvent) => {
        if(event instanceof NavigationEnd) {
          const url = event?.url || '';
          if (url === '/') {
            this.selectedPath = '/dashboard';
          } else if (url.indexOf('/party') != -1) {
            this.selectedPath = '/party';
          } else if (url.indexOf('/item') != -1) {
            this.selectedPath = url === '/item/item-category' ? '/item/item-category' : '/item';
          } else if (url.indexOf('/sale') != -1) {
            this.selectedPath = '/sale';
          } else if (url.indexOf('/money-in') != -1) {
            this.selectedPath = '/money-in';
          } else {
            this.selectedPath = url;
          }
        }

        // close all popovers on back navigation, if open.
        if (event instanceof NavigationStart) {
          if (event.navigationTrigger === 'popstate' && Utility.getFromLocalStorage('Initial_data_download_complete')) {
            Utility.closeIonSelectAlert();
            this.commonService.closeModal();
          }
        }
      }));
    } catch (error: any) {
      SentryUtilites.setLog("AppComponent:menuSubscriptions", error)
    }
  }
  // -------------------------------------------------------------------------------------

  /**
  * Settings
  */
  async openSettingsModal() {
    try {
      if(await this.isUserHasPremimumAccess()) {
        this.settingsModal?.openSettingsModal();
      }
    } catch (error: any) {
      SentryUtilites.setLog("AppComponent:openSettingsModal", error)
    }
  }
  // -------------------------------------------------------------------------------------

  /**
  * Licence
  */
  async openLicenceModal() {
    try {
      if(await this.isUserHasPremimumAccess()) {
        this.licenceInfoEle?.openModal();
      }
    } catch (error: any) {
      SentryUtilites.setLog("AppComponent:openLicenceModal", error)
    }
  }
  // -------------------------------------------------------------------------------------

  /**
  * Login Device
  */
  async openLoginDevicesModal() {
    try {
      if(await this.isUserHasPremimumAccess()) {
        if(this.authService?.getLoginPhone() != this.selectedProfile?.userId) {
          this.router.navigate(['no-access']);
          return null;
        }
        this.loginTokenEle?.openModal();
      }
    } catch (error: any) {
      SentryUtilites.setLog("AppComponent:openLoginDevicesModal", error)
    }
  }
  // -------------------------------------------------------------------------------------

  /**
  * Menu Options Click
  */
  onMenuOptionClick(title: string) {
    try {
      if(title == 'Settings') {
        this.openSettingsModal();
      }else if(title == 'License') {
        this.openLicenceModal();
      }else if(title == 'Login Devices') {
        this.openLoginDevicesModal();
      }else if(title == 'Log Out') {
        setTimeout(() => {
          this.openSupportModal(false);
        }, 100)
      }
      this.accordionGroup?.forEach(el => el.value = undefined);
      // For 3 sale credit use below code
      // this.eventService.setShowPremiumPopup(false, false);
      // this.isSupportModalOpen = false;
    } catch (error: any) {
      SentryUtilites.setLog("AppComponent:onMenuOptionClick", error)
    }
  }
  // -------------------------------------------------------------------------------------

  closeClickOutSideCreateNew() {
    this.createNewFabEle.close();
  }

  openSupportModal(value: boolean = true) {
    this.isSupportModalOpen = value;
  }

  whatsappSupport() {
    window.open(
      `https://api.whatsapp.com/send?phone=9167134134&text=${encodeURIComponent(
        'Hi, I need help with Ezo'
      )}`,
      '_blank'
    );
  }

  remoteSupport() {
    window.open('https://www.teamviewer.com/en-in', '_blank');
  }

  /**
   * @description : license expiry indicator method calculate number days remaining for expiry
   */
  async licenseExpiryIndicator() {
    try {
      let proExpiryStamp = await this.premiumControlService.getProExpiryStamp();
      let currentDateStamp: number = +new Date().setHours(0, 0, 0, 0);
      if(proExpiryStamp && proExpiryStamp > currentDateStamp) {
        let remainingDays: number = Math.ceil((proExpiryStamp - currentDateStamp) / 86400000);
        if(remainingDays >= 3 && remainingDays <= 7) {
          this.licenseExpiryIndicatorText = `Premium Expires in ${remainingDays} Days`;
          this.licenseExpiryIndicatorColor = 'warning';
        } else if(remainingDays === 2) {
          this.licenseExpiryIndicatorText = `Premium Expires Tomorrow`;
          this.licenseExpiryIndicatorColor = 'danger';
        } else if(remainingDays === 1 && proExpiryStamp > +new Date()) {
          this.licenseExpiryIndicatorText = `Premium Expire Today`;
          this.licenseExpiryIndicatorColor = 'danger';
        }else {
          this.licenseExpiryIndicatorText = null;
          this.licenseExpiryIndicatorColor = null;
        }
      } else {
        this.licenseExpiryIndicatorText = null;
        this.licenseExpiryIndicatorColor = null;
      }
    } catch (error: any) {
      SentryUtilites.setLog("AppComponent:licenseExpiryIndicator", error)
    }
  }
  // --------------------------------------------------

  /**
   * @description: check sale credit remaining for non premium
   * @param routerLink : navigation router
   * @returns : stop excution of code further if sale credit are exhausted and show error modal
   */
  async checkSaleCredit(routerLink: string) {
    try {
      if(routerLink.includes('sale')) {
        let checkCreateSale = await this.premiumControlService.checkCreateSale();
        if(checkCreateSale) {
          this.router.navigate([routerLink]);
        }
      } else {
        this.router.navigate([routerLink]);
      }
    } catch (error: any) {
      SentryUtilites.setLog("AppComponent:checkSaleCredit", error)
    }
  }
  // ----------------------------------------------------------------------

  @HostListener('window:resize', ['$event'])
  OnResize() {
    try {
      setTimeout(() => {
        if(!this.isPremiumAccess) {
          this.positionLeft = window?.innerWidth > 992 ? false : true;
          if(!this.positionLeft) {
            this.nonPremiumModalEle.openModal(true);
          }
        }
      }, 20)
    } catch (error: any) {
      SentryUtilites.setLog("AppComponent:OnResize", error)
    }
  }
}
