<ion-item
  class="party-details-holder"
  lines="none"
  *ngIf="!isSecondaryPartyView"
  [ngClass]="{'no-drop': !isEditable}"
  (click)="openPartySelectorModal()"
>
  <ion-grid>
    <ion-row>
      <ion-col size-xs="12" size-lg="6">
        Selected Party<span class="span-mandatory">*</span>
        : 
        <span>{{selectedParty?.name}}</span>
      </ion-col>
      <ion-col size-xs="12" size-lg="6">
        Phone
        :
        <span>
          {{ isMaskPhone ? stringMask(selectedParty?.phone) : selectedParty?.phone }} 
        </span>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-item>

<ion-modal [isOpen]="isModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-title>Parcel/Customer Details</ion-title>
        <ion-buttons slot="end">
          <ion-button
            class="btn-add-party"
            fill="outline"
            color="primary"
            (click)="presentAddPartyAlert()"
            *ngIf="!isSecondaryPartyView"
          >
            Add New Party
          </ion-button>
        </ion-buttons>
        <ion-buttons slot="end">
          <ion-button (click)="openPartySelectorModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">

      <form [formGroup]="secondaryPartyForm" *ngIf="isSecondaryPartyView">
        <ion-row>
          <ion-col size="12" *ngIf="cashDiscountPercentage && isValidPhoneNumber && inpPhoneValue?.length" class="discount-text">
            {{capFractionsToTwo(cashDiscountPercentage)}}% loyalty discount added
          </ion-col>
  
          <span *ngIf="lastActiveStr?.length && inpPhoneValue?.length" class="visited-text">
            {{lastActiveStr}}
          </span>
        </ion-row>

        <ion-row class="input-row">
          <ion-col size="8">
            <ion-item lines="none">
              <ion-label position="floating">Search Party Phone</ion-label>
              <ion-input
                #formSeconadryPartyPhoneEle
                placeholder="eg. 9999999999"
                formControlName="secondaryPartyPhone"
                type="text"
                [disabled]="isEditedSale || isEditedSaleReturn"
                [color]="phoneNumberColorOnSave()"
                (ionInput)="onSecondaryPhoneChange($event)"
              ></ion-input>
            </ion-item>
            <ion-icon 
              id="invalidPhoneNumberError"
              *ngIf="showSecondaryPartyAlert"
              class="search-party-warning-circle" 
              name="alert-circle" 
              color="danger">
            </ion-icon>
            <div class="search-party-warning-msg" *ngIf="showSecondaryPartyAlert">
              Please add a valid Phone Number or leave it blank
            </div>
            <ion-list class="party-suggestions" *ngIf="showPartySuggestionsList">
              <ng-container *ngFor="let filterParty of filterPartyList">
                <ion-item (click)="setSecondaryPartyPhone(filterParty?.phone)">
                  <ion-label>
                    <p>{{filterParty?.name}}</p>
                    <h3>{{filterParty?.phone}}</h3>
                  </ion-label>
                </ion-item>
              </ng-container>
            </ion-list>
          </ion-col>

           <!-- Select DOB -->
           <ion-col size="4">
            <ion-item lines="none">
              <ion-label position="floating">DOB</ion-label>
              <ion-input
                type="text"
                readonly="true"
                [value]="secondaryPartyForm?.value?.secondaryPartyDOB ? showDayMonthValue(secondaryPartyForm?.value?.secondaryPartyDOB) : 'Select Date'"
                (click)="openDOBPicker()"
                [disabled]="isEditedSaleReturn"
              ></ion-input>
            </ion-item>
          </ion-col>
          <!-- End of DOB -->

          <ion-col size="12">
            <ion-item lines="none">
              <ion-label position="floating">Customer/Supplier Name</ion-label>
              <ion-input
                placeholder="Customer/Supplier Name"
                formControlName="secondaryPartyName"
                [disabled]="isEditedSaleReturn"
              ></ion-input>
            </ion-item>
          </ion-col>

          <ion-col size="12">
            <ion-item lines="none">
              <ion-label position="floating">Address</ion-label>
              <ion-textarea
                placeholder="Address"
                formControlName="secondaryPartyAddress"
                [disabled]="isEditedSaleReturn"
              ></ion-textarea>
            </ion-item>
          </ion-col>

          <!-- Asiign Staff Start -->
          <ion-col size="12" *ngIf="isStaffAssignToSale">
            <ion-item lines="none">
              <ion-label position="floating">Assign Staff</ion-label>
              <ion-select 
                interface="alert" 
                placeholder="Assign Staff"
                formControlName="secondaryPartyAssignStaff"
                [disabled]="isEditedSaleReturn"
                >
                <ion-select-option *ngFor="let accessTo of accessToList" [value]="accessTo">
                  {{ accessTo?.name || accessTo?.userId }}
                </ion-select-option>
              </ion-select>
            </ion-item>
          </ion-col>
          <!-- Asiign Staff End -->

        </ion-row>

      </form>

      <app-party-list
        [isModal]="true"
        [ngClass]="{'hidden':isSecondaryPartyView}"
        #partyListComponent
        [defaultCashParty]="defaultCashParty && !previouslyCashSaleAutoSelected"
        [isSecondaryPartyView]="isSecondaryPartyView"
        (partySelectedEvent)="onPartySelect($event)"
        (addNewPartyEvent)="presentAddPartyAlert($event)"
        [isSale] = 'isSale'
      >
      </app-party-list>
    </ion-content>
    <ion-footer *ngIf="isSecondaryPartyView">
      <ion-row (clickOutSide)="closeSecondaryPartyAlert()">
        <ion-col size="5">
          <ion-button class="btn-save-party" color="tertiary" expand="full" (click)="emitSave('OnlySave')">Only Save ({{ totalAmount | currency: 'INR' }})</ion-button>
        </ion-col>
        <ion-col size="7">
          <ion-button class="btn-save-party" color="primary" expand="full" (click)="emitSave('SavePrint')">Save & Print ({{ totalAmount | currency: 'INR' }})</ion-button>
        </ion-col>
      </ion-row>
    </ion-footer>
  </ng-template>
</ion-modal>

<ion-modal class="modal-dob" [isOpen]="isDOBDateModalOpen" [keepContentsMounted]="keepDateContentsMounted"
  (willDismiss)="closeDOBPicker()">
  <ng-template>
    <ion-datetime appYearDefaultBtn appHideYear id="dateOfBirth" [showDefaultButtons]="true" presentation="date" [yearValues]="2000" [preferWheel]="true"
      [value]="dpDobValue" (ionChange)="onDateOfBirthSelect($event)" (ionCancel)="onCancelDateOfBirth($event)">
      <span slot="title">Select a Date of Birth</span>
    </ion-datetime>
  </ng-template>
</ion-modal>