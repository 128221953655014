import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Party, { IParty } from '../../models/Party.model';
import { PartyListComponent } from '../../components/party-list/party-list.component';
import { AccessControlService } from '../../services/auth/access-control.service';
import { Utility } from '../../utils/utility';
import { AllDataService } from '../../services/all-data.service';
import { Subscription } from 'rxjs';
import { PinVerificationComponent } from '../../components/pin-verification/pin-verification.component';
import { AuthService } from '../../services/auth/auth.service';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-party',
  templateUrl: './party.page.html',
  styleUrls: ['./party.page.scss'],
})
export class PartyPage implements OnInit {

  @ViewChild('partyListEle') partyListEle: PartyListComponent;
  @ViewChild('pinVerificationElement') pinVerificationElement: PinVerificationComponent;

  getHeaderColorClass = Utility.getHeaderColorClass;

  lastSyncTime = Utility.getCollectionLastRespSyncTime(IParty.SCHEMA_NAME);
  subArr: Subscription[] = [];
  isTimeDifference = false;

  constructor(
    private router: Router,
    private accessControlService: AccessControlService,
    private activatedRoute: ActivatedRoute,
    private allDataService: AllDataService,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    try {
      this.subArr.push(this.allDataService.lastSyncSubs.subscribe(x => {
        this.lastSyncTime = Utility.getCollectionLastRespSyncTime(IParty.SCHEMA_NAME);
        this.isTimeDifference = this.allDataService.isTimeDifference;
      }));
    } catch (error) {
      SentryUtilites.setLog("PartyPage:ngOnInit", error)
    }
  }

  ngAfterViewInit() {
    try {
      let isPayableFilter = this.activatedRoute.snapshot.queryParamMap.get('payable');
      if(isPayableFilter && this.partyListEle) {
        this.partyListEle.onCategoryChange('payable');
      }
      let isReceivableFilter = this.activatedRoute.snapshot.queryParamMap.get('receivable');
      if(isReceivableFilter && this.partyListEle) {
        this.partyListEle.onCategoryChange('receivable');
      }
    } catch (error) {
      SentryUtilites.setLog("PartyPage:ngAfterViewInit", error)
    }
  }

  ngOnDestroy() {
    this.subArr?.forEach(sub => sub?.unsubscribe());
  }

  async onNewPartyClick() {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({action:'createParty'});
      if(!isPermit) {
        return alert("Permission: You don't have permission to create new party. Please contact to your owner.");
      }
      this.router.navigate([`party/form`]);
    } catch (error) {
      SentryUtilites.setLog("PartyPage:onNewPartyClick", error)
      alert("Something went wrong.");
    }
  }

  async onWhatappBulkMessageClick() {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({action:'whatappBulkMessage'});
      if(!isPermit) {
        return alert("Permission: You don't have permission to send WhatsApp Bulk Message. Please contact to your owner.");
      }
      this.router.navigate([`party/whatapp-bulk-msg`]);
    } catch (error) {
      SentryUtilites.setLog("PartyPage:onWhatappBulkMessageClick", error)
    }
  }

  onPartySelectedEvent(party: Party) {
    this.router.navigate([`party/transaction/${party._localUUID}`]);
  }

  async bulkDelete() {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({
        action: 'deleteParty',
      });
      if (!isPermit) {
        return alert(
          "Permission: You don't have permission to delete item. Please contact to your owner."
        );
      }
      this.openTransactionPINModal();
    } catch (error) {
      SentryUtilites.setLog("PartyPage:bulkDelete", error)
      alert("Something went wrong.");
    }
  }

  openTransactionPINModal() {
    this.pinVerificationElement?.openTransactionPINModal();
  }

  verifyTransactionPIN(event) {
    try {
      if(event) {
        let phone = Utility.getFromLocalStorage('selectedProfileUserId');
        let profileId = Utility.getFromLocalStorage('selectedProfile');
        window.open(
          `https://db.ezobooks.in/kappa/bulk/party/list?userId=${phone}&profileId=${profileId}`
        );
      }
    } catch (error) {
      SentryUtilites.setLog("PartyPage:verifyTransactionPIN", error)
    }
  }

}
