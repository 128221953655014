import { Component, HostListener, OnInit } from '@angular/core';
import { InfiniteScrollCustomEvent, LoadingController, ToastController } from '@ionic/angular';
import { ActionType, IngredientStockAdjust } from '../../../models/IngredientStockAdjust.model';
import { CommonService } from '../../../services/common.service';
import { Utility } from '../../../utils/utility';
import { ActivatedRoute, Router } from '@angular/router';
import { Ingredient } from '../../../models/Ingredient.model';
import { IngredientStockAdjustService } from '../../../services/ingredient-stock-adjust.service';
import { AccessControlService } from '../../../services/auth/access-control.service';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-ingredient-transaction',
  templateUrl: './ingredient-transaction.page.html',
  styleUrls: ['./ingredient-transaction.page.scss'],
})
export class IngredientTransactionPage implements OnInit {

  getHeaderColorClass = Utility.getHeaderColorClass;
  shortStr = Utility.shortStr;
  splitByCamelCase = Utility.splitByCamelCase;
  MATHABS = Math.abs;

  transactionsList: IngredientStockAdjust[] = [];
  viewFilteredList: IngredientStockAdjust[] = [];
  ingredient: Ingredient = null;

  isMobile: boolean = null;
  innerHeight: string = '';
  paramDocumentId: string = '';

  loading: HTMLIonLoadingElement = null;

  constructor(
    private commonService: CommonService,
    private route: ActivatedRoute,
    private ingredientStockAdjustService: IngredientStockAdjustService,
    private loadingCtrl: LoadingController,
    private toastController: ToastController,
    private accessControlService: AccessControlService,
    private router: Router,
  ) { }

  ngOnInit() {
    try {
      this.isMobile = this.commonService.isMobile();
      if(this.isMobile) {
        this.innerHeight = `${(((window?.innerHeight - 327)/window?.innerHeight) * 100)}%`;
      } else {
        this.innerHeight = `${(((window?.innerHeight - 218)/window?.innerHeight) * 100)}%`;
      }
      this.getParamDocumentId();
    } catch (error) {
      SentryUtilites.setLog("IngredientTransactionPage:ngOnInit", error)
    }
  }

  async ngAfterViewInit() {
    await this.showLoading('Loading Raw Material Transaction List...');
    this.getTransactions();
  }

  ionViewWillLeave() {
    this.loading?.dismiss();
  }

  getParamDocumentId() {
    this.paramDocumentId = this.route.snapshot.paramMap.get('documentId');
  }

  async getTransactions() {
    try {
      if(this.paramDocumentId) {
        try {
          let res = await this.ingredientStockAdjustService.getTransactions(this.paramDocumentId);
          this.ingredient = res?.ingredient || null;
          this.transactionsList = res?.ingredientStockAdjusts || [];
        } catch (error) {
          this.loading?.dismiss();
          await this.showToast('Something went wrong, Please try again later.', 'danger');
        }
        this.viewFilteredList = this.transactionsList.slice(0, 50);
        this.loading?.dismiss();
      }
    } catch (error) {
      SentryUtilites.setLog("IngredientTransactionPage:getTransactions", error)
    }
  }

  getTransactionTypeColor = (actionType: ActionType) => {
    try {
      if(actionType === ActionType.Purchase || actionType === ActionType.SaleReturn) {
        return 'success';
      } else if(actionType === ActionType.EOD) {
        return 'normal';
      }else {
        return 'danger';
      }
    } catch (error) {
      SentryUtilites.setLog("IngredientTransactionPage:getTransactionTypeColor", error)
    }
  }

  async purchaseBulkEdit() {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({action:'rawMaterialPurchaseBulkEdit'});
      if(!isPermit) {
        return alert("Permission: You don't have permission to purchase bulk edit raw material. Please contact to your owner.");
      }
      this.router.navigate([`ingredient/transaction/${this.paramDocumentId}/raw-material-purchase-bulk-edit`])
    } catch (error) {
      SentryUtilites.setLog("IngredientTransactionPage:purchaseBulkEdit", error)
    }
  }

  loadMoreListData(event) {
    try {
      if (this.viewFilteredList.length > 0 && this.viewFilteredList.length <= this.transactionsList.length) {
        let appendListLength = this.transactionsList.length - this.viewFilteredList.length;
        let lastEl = this.viewFilteredList[this.viewFilteredList.length - 1];
        let counter = 0;
        for (let i = 0; i < this.transactionsList.length; i++) {
          if (this.transactionsList[i].linkedIngredientUUID == lastEl.linkedIngredientUUID) {
            counter = 1;
            continue;
          } 
          if (counter > 0 && appendListLength >= 50) {
            if (counter <= 50) {
              this.viewFilteredList.push({ ...this.transactionsList[i] })
            } else {
              break;
            }
            counter++;
          } else if(counter > 0 && appendListLength < 50) {
            if (counter <= appendListLength) {
              this.viewFilteredList.push({ ...this.transactionsList[i] })
            } else {
              break;
            }
            counter++;
          }
        }
        (event as InfiniteScrollCustomEvent).target.complete();
      } 
    } catch (error) {
      SentryUtilites.setLog("IngredientTransactionPage:loadMoreListData", error)
    }
  }

  async showLoading(msg: string) {
    try {
      this.loading = await this.loadingCtrl.create({ 
        message: msg,
        cssClass: 'raw-material-loader'
      });
      this.loading?.present();
    } catch (error) {
      SentryUtilites.setLog("IngredientTransactionPage:showLoading", error)
    }
  }

  @HostListener('window:resize', ['$event'])
  OnResize() {
    try {
      setTimeout(() => {
        this.isMobile = this.commonService.isMobile();
        if(this.isMobile) {
          this.innerHeight = `${(((window?.innerHeight - 327)/window?.innerHeight) * 100)}%`;
        } else {
          this.innerHeight = `${(((window?.innerHeight - 218)/window?.innerHeight) * 100)}%`;
        }
      }, 50)
    } catch (error) {
      SentryUtilites.setLog("IngredientTransactionPage:OnResize", error)
    }
  }

  async showToast(msg: string, toastColor: string) {
    try {
      const toast = await this.toastController.create({
        message: msg,
        duration: 2000,
        color: toastColor,
      });
      await toast.present();
    } catch (error) {
      SentryUtilites.setLog("IngredientTransactionPage:showToast", error)
    }
  }

}
