import { Component, OnInit, ViewChild } from '@angular/core';
import ItemCategory from '../../models/ItemCategory.model';
import { Subscription } from 'rxjs';
import { AllDataService } from '../../services/all-data.service';
import {
  AlertController,
  InfiniteScrollCustomEvent,
  IonInput,
  ItemReorderEventDetail,
  ToastController,
} from '@ionic/angular';
import { AccessControlService } from '../../services/auth/access-control.service';
import { Router } from '@angular/router';
import { PinVerificationComponent } from '../pin-verification/pin-verification.component';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

type CustomItemCategory = {
  isEdit: boolean;
  category: ItemCategory;
};
@Component({
  selector: 'app-item-category-list',
  templateUrl: './item-category-list.component.html',
  styleUrls: ['./item-category-list.component.scss'],
})
export class ItemCategoryListComponent implements OnInit {
  @ViewChild('editInputEle') editInputEle: IonInput;
  @ViewChild('pinVerificationElement') pinVerificationElement: PinVerificationComponent;

  itemCategories: CustomItemCategory[] = [];
  viewItemCategories: CustomItemCategory[] = [];
  selectedItemCategory: ItemCategory = null;
  categoryWiseItemCount = {};
  subsArr: Subscription[] = [];
  selectedOptionType: string = '';
  selectedCustomItemCategory: CustomItemCategory = null;
  selectedCategoryName: string = '';

  constructor(
    private allDataService: AllDataService,
    private alertController: AlertController,
    private toastController: ToastController,
    private accessControlService: AccessControlService,
    private router: Router
  ) {}

  ngOnInit() {
    try {
      this.getCategories();
      this.subsArr?.push(this.allDataService.listForceReloadSubs.subscribe((listName: string) => {
        if (listName == 'item-category-list') {
          this.getCategories();
        }
      }))
    } catch (error) {
      SentryUtilites.setLog("ItemCategoryListComponent:ngOnInit", error)
    }
  }

  async getCategories() {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({
        action: 'viewItemCategory',
      });
      if (!isPermit) {
        return alert(
          "Permission: You don't have permission to view category. Please contact to your owner."
        );
      }
      let itemCategories =
        await this.allDataService.itemCategoryService.getAllByPromise() || [];
      itemCategories.sort((a: ItemCategory, b: ItemCategory) => {
        if(a?.savedPosition || b?.savedPosition) {
          return a?.savedPosition - b?.savedPosition;
        }
        if (a?.name?.toLowerCase() === b?.name?.toLowerCase()) {
          return a?.createdStamp - b?.createdStamp;
        }
        if (a?.name?.toLowerCase() > b?.name?.toLowerCase()) {
          return 1;
        }
        return -1;
      });

      let itemCategoryList = [];
      itemCategories?.forEach(category => itemCategoryList?.push({isEdit: false, category}));
      this.itemCategories = itemCategoryList;
      this.viewItemCategories = itemCategoryList.slice(0, this.viewItemCategories?.length > 50 ? this.viewItemCategories?.length : 50);

      this.viewItemCategories = this.itemCategories.slice(0, 50);
      let completeList = await this.allDataService.itemService.getAllByPromise() || [];
      if (completeList?.length) {
        this.categoryWiseItemCount = {};
        itemCategories?.forEach((category) => {
          this.categoryWiseItemCount[category?.name] = completeList?.filter(
            (item) =>
              item?.category?.toLowerCase() == category?.name?.toLowerCase()
          )?.length;
        });
      }
    } catch (error) {
      SentryUtilites.setLog("ItemCategoryListComponent:getCategories", error)
    }
  }

  handleReorder(ev: CustomEvent<ItemReorderEventDetail>) {
    try {
      let minValue = Math.min(ev?.detail?.from, ev?.detail?.to);
      this.viewItemCategories = ev.detail.complete(this.viewItemCategories);
      this.viewItemCategories?.forEach((itemCategory, index) => {
        if(index >= minValue) {
          itemCategory.category.savedPosition = index;
          this.allDataService.itemCategoryService.update(itemCategory?.category);
        }
      });
    } catch (error) {
      SentryUtilites.setLog("ItemCategoryListComponent:handleReorder", error)
    }
  }

  async presentAddCategoryAlert() {
    try {
      const alert = await this.alertController.create({
        header: 'Add New Category',
        buttons: [
          {
            text: 'Save',
            handler: async (value) => {
              return await this.addNewCategory(value);
            },
          },
        ],
        mode: 'ios',
        inputs: [
          {
            type: 'text',
            placeholder: 'Category Name',
            name: 'categoryName',
          },
        ],
      });
  
      await alert.present();
    } catch (error) {
      SentryUtilites.setLog("ItemCategoryListComponent:presentAddCategoryAlert", error)
    }
  }

  async addNewCategory(value: { categoryName: string }) {
    try {
      let categoryName = value?.categoryName.toLowerCase().trim()
      if(categoryName) {
        if(this.itemCategories.findIndex(ItemCategory => ItemCategory?.category?.name.toLowerCase() === categoryName) === -1) {
          let category = new ItemCategory();
          category.savedPosition = this.itemCategories?.length + 1;
          category.name = value.categoryName;
          let result = await this.allDataService.itemCategoryService.save(category);
          if (result._localUUID) {
            this.allDataService.listForceReloadSubs.next('item-category-list');
            this.getCategories();
            return true;
          } else {
            return false;
          }
        } else {
          const toast = await this.toastController.create({
            header: "Item category already exits",
            duration: 2000,
            position: 'top',
            mode: 'ios',
            color: 'warning',
          });
          await toast.present();
        }
      }
    } catch (error) {
      SentryUtilites.setLog("ItemCategoryListComponent:addNewCategory", error)
      return false;
    }
    
  }

  async enableEdit(itemCategory: CustomItemCategory) {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({
        action: 'editItemCategory',
      });
      if (!isPermit) {
        return alert(
          "Permission: You don't have permission to edit category. Please contact to your owner."
        );
      }
      this.selectedOptionType = 'edit';
      this.selectedCustomItemCategory = itemCategory;
      this.openTransactionPINModal();
    } catch (error) {
      SentryUtilites.setLog("ItemCategoryListComponent:enableEdit", error)
      alert("Something went wrong.");
    }
  }

  disableEdit(itemCategory: CustomItemCategory) {
    itemCategory.isEdit = false;
  }

  async update(categoryUUID: string) {
    try {
      let updatedCategoryName: string = (this.editInputEle as any)?.el?.value;
      if (updatedCategoryName) {
        let fetchedCategory =
          await this.allDataService.itemCategoryService.getByUUID(categoryUUID);
        if (fetchedCategory?._localUUID) {
          let oldNameOfCategory = fetchedCategory?.name;
          fetchedCategory.name = updatedCategoryName;
          let updatedCategory =
            await this.allDataService.itemCategoryService.update(fetchedCategory);
          if (updatedCategory?._localUUID) {
            this.allDataService.listForceReloadSubs.next('item-category-list');
            let allItems =
              await this.allDataService.itemService.getAllByPromise() || [];
            let filteredItems = allItems?.filter(
              (item) =>
                item?.category?.toLowerCase() === oldNameOfCategory?.toLowerCase()
            );
            if (filteredItems?.length) {
              for (let i = 0; i < filteredItems?.length; i++) {
                let item = filteredItems[i];
                item.category = updatedCategory?.name;
                await this.allDataService.itemService.update(item);
              }
            }
            this.getCategories();
            const toast = await this.toastController.create({
              message: 'Category updated successfully',
              duration: 3000,
              color: 'success',
            });
            await toast.present();
            return true;
          } else {
            const toast = await this.toastController.create({
              message: 'Something went wrong',
              duration: 3000,
              color: 'danger',
            });
            await toast.present();
            return false;
          }
        }
      } else {
        const toast = await this.toastController.create({
          message: 'Category name cannot be blank',
          duration: 3000,
          color: 'dark',
        });
        await toast.present();
      }
    } catch (error) {
      SentryUtilites.setLog("ItemCategoryListComponent:update", error)
      return false;
    }
  }

  async deleteConfirm(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        const alert = await this.alertController.create({
          header: 'Warning!',
          message: 'Are you sure want to delete?',
          mode: 'ios',
          buttons: [
            {
              text: 'No',
              role: 'cancel',
              handler: () => resolve(false),
            },
            {
              text: 'Yes',
              role: 'confirm',
              handler: () => resolve(true),
            },
          ],
        });
        await alert.present();
      } catch (error) {
        SentryUtilites.setLog("ItemCategoryListComponent:deleteConfirm", error)
      }
    });
  }

  async delete(category: ItemCategory) {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({
        action: 'deleteItemCategory',
      });
      if (!isPermit) {
        return alert(
          "Permission: You don't have permission to delete category. Please contact to your owner."
        );
      }
      this.selectedItemCategory = category
      this.selectedOptionType = 'delete';
      this.openTransactionPINModal();
    } catch (error) {
      SentryUtilites.setLog("ItemCategoryListComponent:delete", error)
    }
  }

  bulkEdit(categoryName: string) {
    try {
      this.selectedOptionType = 'bulkEdit';
      this.selectedCategoryName = categoryName;
      this.openTransactionPINModal();
    } catch (error) {
      SentryUtilites.setLog("ItemCategoryListComponent:bulkEdit", error)
    }
  }

  bulkItemEdit(categoryName: string) {
    try {
      this.router.navigate(['/item/bulk-edit'], {
        queryParams: {
          categoryName
        },
      });
    } catch (error) {
      SentryUtilites.setLog("ItemCategoryListComponent:bulkItemEdit", error)
    }
  }

  ngOnDestroy() {
    this.subsArr?.forEach(sub => {
      sub?.unsubscribe();
    })
  }

  openTransactionPINModal() {
    this.pinVerificationElement?.openTransactionPINModal();
  }

  async verifyTransactionPIN(event) {
    try {
      if(event && this.selectedOptionType === 'edit') {
        this.itemCategories?.forEach(
          (itemCategory) => (itemCategory.isEdit = false)
        );
        this.selectedCustomItemCategory.isEdit = true;
        setTimeout(() => {
          this.editInputEle?.setFocus();
        }, 500);
      } else if (event && this.selectedOptionType === 'bulkEdit') {
        this.bulkItemEdit(this.selectedCategoryName);
      } else if (event && this.selectedOptionType === 'delete') {
        const confirm = await this.deleteConfirm();
        if (confirm) {
          let dbCategory = await this.allDataService.itemCategoryService.getByUUID(
            this.selectedItemCategory?._localUUID
          );
          if (dbCategory?._localUUID) {
            let deletedCategory =
              await this.allDataService.itemCategoryService.delete(dbCategory);
            if (deletedCategory?._localUUID) {
              let allItems =
                await this.allDataService.itemService.getAllByPromise();
              let filteredItems = allItems?.filter(
                (item) =>
                  item?.category?.toLowerCase() ===
                  deletedCategory?.name?.toLowerCase()
              );
              if (filteredItems?.length) {
                for (let i = 0; i < filteredItems?.length; i++) {
                  let item = filteredItems[i];
                  item.category = null;
                  await this.allDataService.itemService.update(item);
                }
              }
              this.getCategories();
            }
          }
        }
      }
    } catch (error) {
      SentryUtilites.setLog("ItemCategoryListComponent:verifyTransactionPIN", error)
    }
  }

  loadMoreListData(event: any) {
    if (
      this.viewItemCategories.length > 0 &&
      this.viewItemCategories.length <= this.itemCategories.length
    ) {
      let appendListLength = this.itemCategories.length - this.viewItemCategories.length;
      let lastEl = this.viewItemCategories[this.viewItemCategories.length - 1];
      let counter = 0;
      for (let i = 0; i < this.itemCategories.length; i++) {
        if (this.itemCategories[i].category._localUUID == lastEl.category._localUUID) {
          counter = 1;
          continue;
        }
        if (counter > 0 && appendListLength >= 50) {
          if (counter <= 50) {
            this.viewItemCategories.push({ ...this.itemCategories[i] });
          } else {
            break;
          }
          counter++;
        } else if(counter > 0 && appendListLength < 50) {
          if (counter <= appendListLength) {
            this.viewItemCategories.push({ ...this.itemCategories[i] })
          } else {
            break;
          }
          counter++;
        }
      }
      (event as InfiniteScrollCustomEvent)?.target?.complete();
    }
  }

}
