import { BaseModel } from "./BaseModel.model";
import { BillItem } from "./BillItem.model";
import { MoneyOut } from "./MoneyOut.model";
import Party from "./Party.model";
import { TransportDetail } from "./TransportDetail.model";

export class IPurchase {

  static SCHEMA_NAME = 'purchase';

}
export class Purchase extends BaseModel {

  profileId: string;

  billNo: string;
  billDateStamp: number;
  party: Party;
  billItems: BillItem[];
  subTotalAmount: number;
  totalAmount: number;
  totalSaving: number;

  moneyOuts?: MoneyOut[];

  dueDateStamp:number;
  billingTerm:string;

  note: string;
  transportDetail: TransportDetail;

  gstAmount: number;
  cessAmount: number;
  discountAmount: number;
  cashDiscount: number;
  cashDiscountPercentage: number;
  amountPaid : number;
  additionalDiscount :number;
  senderProvience: string;
  deliveryProvience:string;

  roundOffValue: number;
  linkedPurchaseReturnUUID: string;

}
