import { BehaviorSubject } from "rxjs";
import { Purchase } from "../models/Purchase.model";
import { Utility } from "../utils/utility";
import { ExpressServerService } from "./api/express-server.service";
import { IDataService } from "../../interface/IDataService.interface";
import { ItemDao } from "./dao/item.dao";
import { PartyDao } from "./dao/party.dao";
import { PurchaseDao } from "./dao/purchase.dao";
import { MoneyOutDao } from "./dao/money-out.dao";
import { MoneyOut } from "../models/MoneyOut.model";
import { AuthService } from "./auth/auth.service";
import { Utils } from "../utils/utils";
import { MonthWisePartyCreditDao } from "./dao/month-wise-party-credit.dao";
import { MonthWiseItemStockDao } from "./dao/month-wise-item-stock.dao";
import { SentryUtilites } from "../utils/sentryUtilites";

export class PurchaseService implements IDataService<Purchase>{
  private static _instance: PurchaseService;

  public static getInstance(
    purchaseDao: PurchaseDao,
    partyDao: PartyDao,
    itemDao: ItemDao,
    moneyOutDao: MoneyOutDao,
    expressServerService: ExpressServerService,
    authService: AuthService,
    monthWisePartyCreditDao: MonthWisePartyCreditDao,
    monthWiseItemStockDao: MonthWiseItemStockDao,
  ) {
    if (!this._instance) {
      this._instance = new PurchaseService(
        purchaseDao,
        partyDao,
        itemDao,
        moneyOutDao,
        expressServerService,
        authService,
        monthWisePartyCreditDao,
        monthWiseItemStockDao,
      )
      this._instance.initService();
    }
    this._instance.reloadList();
    return this._instance;
  }

  constructor(
    dao: PurchaseDao,
    partyDao: PartyDao,
    itemDao: ItemDao,
    moneyOutDao: MoneyOutDao,
    expressServerService: ExpressServerService,
    authService: AuthService,
    monthWisePartyCreditDao: MonthWisePartyCreditDao,
    monthWiseItemStockDao: MonthWiseItemStockDao,
  ) {
    this.dao = dao;
    this.expressServerService = expressServerService;
    this.itemDao = itemDao
    this.partyDao = partyDao;
    this.moneyOutDao = moneyOutDao;
    this.authService = authService;
    this.monthWisePartyCreditDao = monthWisePartyCreditDao;
    this.monthWiseItemStockDao = monthWiseItemStockDao;
  }
  dao: PurchaseDao;
  itemDao: ItemDao;
  partyDao: PartyDao;
  moneyOutDao: MoneyOutDao;
  expressServerService: ExpressServerService;
  authService: AuthService;
  monthWisePartyCreditDao: MonthWisePartyCreditDao;
  monthWiseItemStockDao: MonthWiseItemStockDao;

  LIST_REFRESH_RATE = 1000;
  selectedProfileId: string = null;
  selectedProfileUserId: string = null;
  updateSubs = new BehaviorSubject<Purchase>(null);


  lastReloadStamp: number = 0;
  isReloadPostpond = false;

  initService() {
    this.selectedProfileId = Utility.getFromLocalStorage('selectedProfile');
    this.selectedProfileUserId = Utility.getFromLocalStorage('selectedProfileUserId');
    this.reloadList();
  }


  async reloadList() {
    try {
      if (this.isReloadPostpond) {
        return;
      }
      const currentStamp = +new Date();
      if (this.lastReloadStamp < (currentStamp - this.LIST_REFRESH_RATE)) {
        this.lastReloadStamp = currentStamp;
        this.trySyncUnsynced();
      } else {
        this.isReloadPostpond = true;
        setTimeout(() => {
          this.isReloadPostpond = false;
          this.reloadList();
        }, this.LIST_REFRESH_RATE + 100);
      }
    } catch (error) {
      SentryUtilites.setLog("PurchaseService:reloadList", error)
      return null;
    }
  }

  getAll() {
    return this.dao.getAll();
  }

  getAllByPromise() {
    return this.dao.getAllByProfile(this.selectedProfileId);
  }

  getAllByPromiseByProfile(profileId: string) {
    return this.dao.getAllByProfile(profileId);
  }

  getAllWithDeletedByProfile() {
    return this.dao.getAllWithDeletedByProfile(this.selectedProfileId);
  }

  getByBillDateRange(startTime: number, endTime: number, profileId?: string): Promise<Purchase[]> {
    return new Promise(async (resolve, reject) => {
      try {
        let allDocs = await this.dao.getAllByProfile(profileId || this.selectedProfileId);
        if (allDocs != null) {
          let filteredDocs = allDocs.filter(doc => doc?.billDateStamp >= startTime && doc?.billDateStamp < endTime);
          return resolve(filteredDocs);
        } else {
          return resolve(null);
        }
      } catch (error) {
        SentryUtilites.setLog("PurchaseService:getByBillDateRange", error)
        return resolve(null);
      }
    });
  }

  getByCreatedDateRange(startTime: number, endTime: number): Promise<Purchase[]> {
    return new Promise(async (resolve, reject) => {
      try {
        let allDocs = await this.dao.getAllByProfile(this.selectedProfileId);
        if (allDocs != null) {
          let filteredDocs = allDocs.filter(doc => doc?.createdStamp >= startTime && doc?.createdStamp < endTime);
          return resolve(filteredDocs);
        } else {
          return resolve(null);
        }
      } catch (error) {
        SentryUtilites.setLog("PurchaseService:getByCreatedDateRange", error)
        return resolve(null);
      }
    });
  }

  getById(id: number): Promise<Purchase> {
    return this.dao.getById(id);
  }

  getByUUID(uuid: string): Promise<Purchase> {
    return this.dao.getByUUID(uuid);
  }



  save(purchase: Purchase): Promise<Purchase> {
    return new Promise(async (resolve, reject) => {
      try {
        if(Utility.isTruthy(purchase)) {
          let currentProfile = this.selectedProfileId;
          purchase.userId = this.selectedProfileUserId;
          if(!purchase?.profileId) {
            purchase.profileId = currentProfile;
          }
          purchase.createdBy = purchase.lastModifiedBy = this.authService.getLoginPhone();
          purchase.createdByName = purchase.lastModifiedByName = Utility.getCreatedByName();
  
          if (!purchase?._localUUID) {
            purchase._localUUID = Utility.getUUID();
          }
  
          if(!purchase?.amountPaid) {
            purchase.amountPaid = 0.0;
          }
  
  
          if (purchase?.moneyOuts?.length === 1) {
            purchase.moneyOuts[0].userId = this.selectedProfileUserId;
            purchase.moneyOuts[0].profileId = this.selectedProfileId;
            purchase.moneyOuts[0].createdBy = purchase.moneyOuts[0].lastModifiedBy = this.authService.getLoginPhone();
            purchase.moneyOuts[0].createdByName = purchase.moneyOuts[0].lastModifiedByName = Utility.getCreatedByName();
            purchase.moneyOuts[0].linkedPurchaseUUID = purchase?._localUUID;
            purchase.moneyOuts[0]._localUUID = Utility.getUUID();
            purchase.amountPaid = purchase?.moneyOuts[0]?.totalAmount || 0.0;
          }
  
          let savedPurchase = await this.dao.save(purchase);
  
          if(savedPurchase?._localUUID) {
  
            //MoneyOut
            //----------------------------------------
            if (savedPurchase.moneyOuts?.length === 1) {
              let savedMoneyOut = await this.moneyOutDao.save(savedPurchase?.moneyOuts[0]);
              if(savedMoneyOut?._localUUID) {
                let totalAmount = savedMoneyOut?.totalAmount || 0.0;
                if (totalAmount > 0) {
                  savedMoneyOut.party.lastModifiedBy = savedPurchase?.lastModifiedBy;
                  savedMoneyOut.party.lastModifiedByName = savedPurchase?.lastModifiedByName;
                  await this.partyDao.updateCredit(
                    savedMoneyOut?.party,
                    totalAmount
                  )
                  await this.monthWisePartyCreditDao?.modifyCredit(
                    savedMoneyOut?.party?._localUUID,
                    savedMoneyOut?.billDateStamp,
                    totalAmount
                  )
                }
              }
            }
            //----------------------------------------
  
            //Party
            savedPurchase.party.lastModifiedBy = savedPurchase?.lastModifiedBy;
            savedPurchase.party.lastModifiedByName = savedPurchase?.lastModifiedByName;
            await this.partyDao.updateCredit(
              savedPurchase?.party,
              -savedPurchase?.totalAmount
              )
            await this.monthWisePartyCreditDao?.modifyCredit(
              savedPurchase?.party?._localUUID,
              savedPurchase?.billDateStamp,
              -savedPurchase?.totalAmount
            )
            //----------------------------------------
  
            //Item
  
            for (let i = 0; i < savedPurchase?.billItems?.length; i++) {
              let billItem = savedPurchase?.billItems[i];
              billItem.item.lastModifiedBy = savedPurchase?.lastModifiedBy;
              billItem.item.lastModifiedByName = savedPurchase?.lastModifiedByName;
              let qty = billItem?.unit === billItem?.item?.primaryUnit ? billItem?.quantity : Utils.capFractionsToTwo(billItem?.quantity / billItem?.convertRatioMultiplier);
              await this.itemDao.updateStock(
                billItem?.item,
                qty
              );
              await this.monthWiseItemStockDao?.modifyStock(
                billItem?.item?._localUUID,
                savedPurchase?.billDateStamp,
                qty
              )
            }
  
            //----------------------------------------
  
          }
  
          this.reloadList();
          return resolve(savedPurchase);
        } else {
          return resolve(null);
        }

      } catch (err) {
        SentryUtilites.setLog("PurchaseService:save", err)
        return resolve(null)
      }

    });
  }

  update(purchase: Purchase): Promise<Purchase> {
    return new Promise(async (resolve, reject) => {
      try {
        if (purchase?._localUUID) {
          purchase.lastModifiedBy = this.authService.getLoginPhone();
          purchase.lastModifiedByName = Utility.getCreatedByName();
          let oldPurchase = await this.getByUUID(purchase?._localUUID);

          if (
            oldPurchase?.party?._localUUID != null
            && oldPurchase?.party?._localUUID != ""
            && purchase?.party?._localUUID != ""
            && oldPurchase?.party?._localUUID == purchase?.party?._localUUID
          ) {

            if(purchase?.moneyOuts?.length) {
              let totalAmountPaid = 0.0;
              purchase?.moneyOuts?.forEach(x => totalAmountPaid += Number(x?.totalAmount));
              purchase.amountPaid = totalAmountPaid;
            }else {
              purchase.amountPaid = 0.0;
            }

            let addMoneyOuts: MoneyOut[] = [];
            let updateMoneyOuts: MoneyOut[] = [];
            let deleteMoneyOuts: MoneyOut[] = [];

            purchase?.moneyOuts?.forEach(newMoneyOut => {
              let isMatched = false;
              oldPurchase?.moneyOuts?.forEach(oldMoneyOut => {
                if(oldMoneyOut?._localUUID === newMoneyOut?._localUUID) {
                  isMatched = true;
                  return;
                }
              });
              if(isMatched) {
                // ConstraintError: Unable to add key to index '_localUUID': at least one key does not satisfy the uniqueness requirements.
                // getting this error if _localId is not match with your current indexedDb _localId at time of update and delete.
                delete newMoneyOut?._localId;
                updateMoneyOuts.push(newMoneyOut);
              }else {
                newMoneyOut._localUUID = Utility.getUUID();
                newMoneyOut.profileId = purchase?.profileId;
                newMoneyOut.userId = purchase?.userId;
                newMoneyOut.createdBy = newMoneyOut.lastModifiedBy = this.authService.getLoginPhone();
                newMoneyOut.createdByName = newMoneyOut.lastModifiedByName = Utility.getCreatedByName();
                newMoneyOut.linkedPurchaseUUID = purchase?._localUUID;
                newMoneyOut.party = purchase?.party;
                addMoneyOuts.push(newMoneyOut);
              }
            });

            oldPurchase?.moneyOuts?.forEach(oldMoneyOut => {
              let shouldDelete = true;
              purchase?.moneyOuts?.forEach(newMoneyOut => {
                if(oldMoneyOut?._localUUID === newMoneyOut?._localUUID) {
                  shouldDelete = false;
                }
              });
              if(shouldDelete) {
                deleteMoneyOuts.push(oldMoneyOut);
              }
            });

            purchase.moneyOuts = [...addMoneyOuts, ...updateMoneyOuts];

            let updatedPurchase = await this.dao.update(purchase);

            if(updatedPurchase?._localUUID) {

              //MoneyOut

              if(addMoneyOuts?.length) {
                for (let i = 0; i < addMoneyOuts?.length; i++) {
                  let moneyOut = addMoneyOuts[i];
                  let savedMoneyOut = await this.moneyOutDao.save(moneyOut);
                  if(savedMoneyOut?._localUUID) {
                    savedMoneyOut.party.lastModifiedBy = updatedPurchase?.lastModifiedBy;
                    savedMoneyOut.party.lastModifiedByName = updatedPurchase?.lastModifiedByName;
                    await this.partyDao.updateCredit(
                      savedMoneyOut?.party,
                      savedMoneyOut?.totalAmount
                    );
                    await this.monthWisePartyCreditDao?.modifyCredit(
                      savedMoneyOut?.party?._localUUID,
                      savedMoneyOut?.billDateStamp,
                      savedMoneyOut?.totalAmount
                    )
                  }
                }
              }

              if(updateMoneyOuts?.length) {
                for (let i = 0; i < updateMoneyOuts?.length; i++) {
                  let moneyOut = updateMoneyOuts[i];
                  let oldMoneyOut = await this.moneyOutDao.getByUUID(moneyOut?._localUUID);
                  if(oldMoneyOut?._localUUID) {
                    moneyOut._localId = oldMoneyOut?._localId;
                    moneyOut.createdStamp = oldMoneyOut?.createdStamp;
                    let savedMoneyOut = await this.moneyOutDao.update(moneyOut);
                    if(savedMoneyOut?._localUUID) {
                      let deltaAmount = savedMoneyOut?.totalAmount - oldMoneyOut?.totalAmount;
                      savedMoneyOut.party.lastModifiedBy = updatedPurchase?.lastModifiedBy;
                      savedMoneyOut.party.lastModifiedByName = updatedPurchase?.lastModifiedByName;
                      await this.partyDao.updateCredit(
                        savedMoneyOut?.party,
                        deltaAmount
                      );
                      await this.monthWisePartyCreditDao?.modifyCredit(
                        savedMoneyOut?.party?._localUUID,
                        savedMoneyOut?.billDateStamp,
                        deltaAmount
                      )
                    }
                  }
                }
              }

              if(deleteMoneyOuts?.length) {
                for (let i = 0; i < deleteMoneyOuts?.length; i++) {
                  let moneyOut = deleteMoneyOuts[i];
                  let oldMoneyOut = await this.moneyOutDao.getByUUID(moneyOut?._localUUID);
                  if(oldMoneyOut?._localUUID) {
                    moneyOut._localId = oldMoneyOut?._localId;
                    let savedMoneyOut = await this.moneyOutDao.delete(moneyOut);
                    if(savedMoneyOut?._localUUID) {
                      savedMoneyOut.party.lastModifiedBy = updatedPurchase?.lastModifiedBy;
                      savedMoneyOut.party.lastModifiedByName = updatedPurchase?.lastModifiedByName;
                      await this.partyDao.updateCredit(
                        savedMoneyOut?.party,
                        -savedMoneyOut?.totalAmount
                      );
                      await this.monthWisePartyCreditDao?.modifyCredit(
                        savedMoneyOut?.party?._localUUID,
                        savedMoneyOut?.billDateStamp,
                        -savedMoneyOut?.totalAmount
                      )
                    }
                  }
                }
              }

              //------------------------------------------------------------------


              //Party
              //------------------------------------------------------------------
              let deltaPartyCredit:number = 0;
              deltaPartyCredit = (updatedPurchase?.totalAmount || 0.0) - (oldPurchase?.totalAmount || 0.0)
              updatedPurchase.party.lastModifiedBy = updatedPurchase?.lastModifiedBy;
              updatedPurchase.party.lastModifiedByName = updatedPurchase?.lastModifiedByName;
              await this.partyDao.updateCredit(
                updatedPurchase?.party,
                -deltaPartyCredit
              )
              await this.monthWisePartyCreditDao?.modifyCredit(
                updatedPurchase?.party?._localUUID,
                updatedPurchase?.billDateStamp,
                -deltaPartyCredit
              )

              //Item
              //----------------------------------------
              for (let i = 0; i < oldPurchase?.billItems?.length; i++) {
                let billItem = oldPurchase?.billItems[i];
                if(billItem?.item?._localUUID) {
                  billItem.item.lastModifiedBy = updatedPurchase?.lastModifiedBy;
                  billItem.item.lastModifiedByName = updatedPurchase?.lastModifiedByName;
                  let qty = billItem?.unit === billItem?.item?.primaryUnit ? billItem?.quantity : Utils.capFractionsToTwo(billItem?.quantity / billItem?.convertRatioMultiplier);
                  await this.itemDao.updateStock(
                    billItem?.item,
                    -qty
                  )
                  await this.monthWiseItemStockDao?.modifyStock(
                    billItem?.item?._localUUID,
                    oldPurchase?.billDateStamp,
                    -qty
                  )
                }
              }

              for (let i = 0; i < updatedPurchase?.billItems?.length; i++) {
                let billItem = updatedPurchase?.billItems[i];
                if(billItem?.item?._localUUID) {
                  billItem.item.lastModifiedBy = updatedPurchase?.lastModifiedBy;
                  billItem.item.lastModifiedByName = updatedPurchase?.lastModifiedByName;
                  let qty = billItem?.unit === billItem?.item?.primaryUnit ? billItem?.quantity : Utils.capFractionsToTwo(billItem?.quantity / billItem?.convertRatioMultiplier);
                  await this.itemDao.updateStock(
                    billItem?.item,
                    qty
                  );
                  await this.monthWiseItemStockDao?.modifyStock(
                    billItem?.item?._localUUID,
                    updatedPurchase?.billDateStamp,
                    qty
                  )
                }
              }

            }

            this.reloadList();
            this.updateSubs.next(updatedPurchase);
            return resolve(updatedPurchase);

          }
        }
        return resolve(null)
      } catch (err) {
        SentryUtilites.setLog("PurchaseService:update", err)
        return resolve(null)
      }
    });
  }

  delete(purchase: Purchase): Promise<Purchase> {
    return new Promise(async (resolve, reject) => {
      try {
        if(purchase?._localUUID) {
          purchase.lastModifiedBy = this.authService.getLoginPhone();
          purchase.lastModifiedByName = Utility.getCreatedByName();
          let purchaseTobeDeleted = await this.getByUUID(purchase?._localUUID)
          let deletedPurchase = await this.dao.delete(purchaseTobeDeleted);
  
          if(deletedPurchase?._localUUID) {
  
            //MoneyOut
  
            if (purchaseTobeDeleted?.moneyOuts?.length) {
              for (let i = 0; i < purchaseTobeDeleted?.moneyOuts?.length; i++) {
                const moneyOut = purchaseTobeDeleted?.moneyOuts[i];
                let fetchedMoneyOut = await this.moneyOutDao.getByUUID(moneyOut?._localUUID);
                if(fetchedMoneyOut?._localUUID) {
                  fetchedMoneyOut.lastModifiedBy = this.authService.getLoginPhone();
                  fetchedMoneyOut.lastModifiedByName = Utility.getCreatedByName();
                  let deletedMoneyOut = await this.moneyOutDao?.delete(fetchedMoneyOut);
                  if(deletedMoneyOut?._localUUID) {
                    let totalAmount = deletedMoneyOut?.totalAmount || 0.0;
                    if (totalAmount > 0) {
                     purchaseTobeDeleted.lastModifiedBy = deletedPurchase?.lastModifiedBy;
                     purchaseTobeDeleted.lastModifiedByName = deletedPurchase?.lastModifiedByName;
                      await this.partyDao.updateCredit(
                        purchaseTobeDeleted?.party,
                        -totalAmount
                      )
                      await this.monthWisePartyCreditDao?.modifyCredit(
                        purchaseTobeDeleted?.party?._localUUID,
                        purchaseTobeDeleted?.billDateStamp,
                        -totalAmount
                      )
                    }
                  }
                }
              }
            }
  
            //----------------------------------------
  
            //Party
            //----------------------------------------
            deletedPurchase.party.lastModifiedBy = deletedPurchase?.lastModifiedBy;
            deletedPurchase.party.lastModifiedByName = deletedPurchase?.lastModifiedByName;
            await this.partyDao.updateCredit(
              deletedPurchase?.party,
              (deletedPurchase?.totalAmount || 0.0)
            )
            await this.monthWisePartyCreditDao?.modifyCredit(
              deletedPurchase?.party?._localUUID,
              deletedPurchase?.billDateStamp,
              (deletedPurchase?.totalAmount || 0)
            )
  
            //Item
            //----------------------------------------
            for (let i = 0; i < deletedPurchase?.billItems?.length; i++) {
              let billItem = deletedPurchase?.billItems[i];
              if(billItem?.item?._localUUID) {
                billItem.item.lastModifiedBy = deletedPurchase?.lastModifiedBy;
                billItem.item.lastModifiedByName = deletedPurchase?.lastModifiedByName;
                let qty = billItem?.unit === billItem?.item?.primaryUnit ? billItem?.quantity : Utils.capFractionsToTwo(billItem?.quantity / billItem?.convertRatioMultiplier);
                await this.itemDao.updateStock(
                  billItem?.item,
                  -qty
                )
                await this.monthWiseItemStockDao?.modifyStock(
                  billItem?.item?._localUUID,
                  deletedPurchase?.billDateStamp,
                  -qty
                )
              }
            }
  
          }
  
          this.reloadList();
          this.updateSubs.next(deletedPurchase);
  
          return resolve(deletedPurchase);
        }
        return resolve(null);

      } catch (err) {
        SentryUtilites.setLog("PurchaseService:delete", err)
        return resolve(null);
      }

    });
  }

  isSyncLock = false;
  isSyncPostPond = false;
  async trySyncUnsynced(postpond?: boolean) {
    try {
      if (this.isSyncLock) {
        if (!this.isSyncPostPond) {
          setTimeout(() => {
            this.isSyncPostPond = true;
            this.trySyncUnsynced(true);
          }, 200);
        }
        return true;
      }
      if (postpond) {
        this.isSyncPostPond = false;
      }
      this.isSyncLock = true;
      let unSyncedElements: Purchase[] = await this.dao.getAllUnsynced(this.selectedProfileId);
      if (unSyncedElements && unSyncedElements.length) {
        try {
          
          for (let i = 0; i < unSyncedElements.length; i++) {
            let unSyncedElement = unSyncedElements[i];
            if(unSyncedElement?._localUUID) {
              unSyncedElement['updatedStamp'] = +new Date();
            }
          }
  
          await this.dao.bulkPut(unSyncedElements);
          await Utility.wait(1000);
  
          let chunkArr = Utility.getChunkArr(unSyncedElements);
          let chunkArrLength = chunkArr?.length;
  
          for(let i = 0; i < chunkArrLength; i++) {
            let result = await this.expressServerService.makeSyncCall('purchase', chunkArr[i]);
            if (result && result?.['records']?.length) {
              let arr = result?.['records'];
              for (let i = 0; i < arr.length; i++) {
                const el = arr[i];
                await this.updateSyncStamp(el);
              }
            }
          }
        } catch (err) {
          SentryUtilites.setLog("PurchaseService:trySyncUnsynced:inner", err)
        }
      }
      this.isSyncLock = false;
    } catch (error) {
      SentryUtilites.setLog("PurchaseService:trySyncUnsynced", error)
    }
  }

  updateSyncStamp(el: Purchase): Promise<Purchase> {
    return new Promise(async (resolve, reject) => {
      try {
        let updatedEl = await this.dao.updateSyncStamp(el);
        this.updateSubs.next(updatedEl);
        return resolve(updatedEl);
      } catch (error) {
        SentryUtilites.setLog("PurchaseService:updateSyncStamp", error)
        return resolve(null);
      }
    });
  }

  async getNewPurchaseNo(): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        let purchases = await this.dao.getAllByProfile(this.selectedProfileId);
        let nextPurchaseNo = 'PUR_001';
        if (purchases[0]?.billNo) {
          nextPurchaseNo = Utility.nextNo(purchases[0]?.billNo);
        }
        return resolve(nextPurchaseNo);
      } catch (error) {
        SentryUtilites.setLog("PurchaseService:getNewPurchaseNo", error)
        return resolve(null);
      }

    });
  }

  getNewBillNo() {

  }

  /**
   * 
   * @returns : return deleted purchases from purchase dao
   */
  async getAllDeleted(): Promise<Purchase[]> {
    try {
      let res = await this.dao.getAllDeletedByProfile(this.selectedProfileId);
      return res || [];
    } catch (error) {
      SentryUtilites.setLog("PurchaseService:getAllDeleted", error)
      return [];
    }
  }
  // ----------------------------------------------

  copyData(fromProfileId: string, toProfileId: string): Promise<boolean> {
    return new Promise(async (resolve,reject) => {
      try {
        if(Utility.isTruthy(fromProfileId) && Utility.isTruthy(toProfileId)) {
          let fromRecords = await this.getAllByPromiseByProfile(fromProfileId);
          if(fromRecords?.length) {
            let toRecords: Purchase[] = [];
            for (let i = 0; i < fromRecords?.length; i++) {
              const fetchedRecord = fromRecords[i];
              if(fetchedRecord?._localUUID) {
                fetchedRecord.profileId = toProfileId;
                delete fetchedRecord?._localId;
                delete fetchedRecord?._localUUID;
                let savedRecord = await this.save(fetchedRecord);
                if(savedRecord?._localUUID) {
                  toRecords?.push(savedRecord);
                }
              }
            }
            if(fromRecords?.length === toRecords?.length) {
              return resolve(true);
            }
          }
        }
        return resolve(false);
      } catch (error) {
        SentryUtilites.setLog("PurchaseService:copyData", error)
        return resolve(false);
      }
    });
  }

}
