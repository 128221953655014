import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SaleReturn } from '../../../models/SaleReturn.model';
import { PinVerificationComponent } from '../../pin-verification/pin-verification.component';
import { AllDataService } from '../../../services/all-data.service';
import { AlertController, InfiniteScrollCustomEvent } from '@ionic/angular';
import { SentryUtilites } from '../../../utils/sentryUtilites';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sale-return-bulk-delete',
  templateUrl: './sale-return-bulk-delete.component.html',
  styleUrls: ['./sale-return-bulk-delete.component.scss'],
})
export class SaleReturnBulkDeleteComponent implements OnInit {

  @ViewChild('pinVerificationElement') pinVerificationElement: PinVerificationComponent;
  
  @Input() fromStamp:number = null;
  @Input() toStamp: number = null;
  @Input() navigateTo: string = '';

  @Output() onSaleReturnDelete = new EventEmitter<SaleReturn>();
  @Output() onBulkSaleReturnDelete = new EventEmitter<boolean>();

  // SaleReturn
  isSaleReturnModalOpen = false;
  saleReturn: SaleReturn = null;
  isDeleteSaleReturnAttachedMoneyOuts = true;
  saleReturnDeleteStarted = false;
  saleReturnDeleteCompleted = false;
  //----------------------------------------------------

  // Bulk SaleReturn
  isBulkSaleReturnModalOpen = false;
  checkAllSaleReturnCheckboxes: boolean = false;
  bulkSaleReturns: SaleReturn[] = [];
  selectedBulkSaleReturns: SaleReturn[] = [];
  bulkSaleReturnDeleteStarted = false;
  bulkSaleReturnDeleteCompleted = false;
  //----------------------------------------------------

  // Security Pin common functionality var
  selectedVar: string = '';
  bulkSelectArray: string[] = [];
  viewFilteredList:SaleReturn[] = [];
  // ---------------------------------

  constructor(
    private allDataService: AllDataService,
    private alertController: AlertController,
    private router: Router,
  ) { }

  ngOnInit() { }

  openTransactionPINModal() {
    this.pinVerificationElement?.openTransactionPINModal();
  }

  async verifyTransactionPIN(event) {
    try {
      if(event){
        if(this.selectedVar == 'SaleReturn'){
         this.openSaleReturnModal(true);
        }else if(this.selectedVar == 'BulkSaleReturn'){
          this.openBulkSaleReturnModal(true);
          await this.onDateRangeChange();
          //edit bulk saleReturn
          this.bulkSaleReturns?.forEach((el)=> {
            el["checked"] = false
          })
        }
      }else{
        this.openBulkSaleReturnModal(false);
        this.openSaleReturnModal(false);
      }
    } catch (error) {
      SentryUtilites.setLog("SaleReturnBulkDeleteComponent:verifyTransactionPIN", error)
    }
  }

  setCheckboxVarValue(variable: string, event: any) {
    this[variable] = event?.detail?.checked;
  }

  async deleteConfirm(): Promise<boolean> {
    return new Promise(async (resolve,reject) => {
      try {
        const alert = await this.alertController.create({
          mode: 'ios',
          header: 'Please Confirm!',
          message: 'Are you sure want to delete?',
          buttons: [
            {
              text: 'No',
              role: 'cancel',
              handler: () => {
                resolve(false);
                return true;
              },
            },
            {
              text: 'Yes',
              role: 'confirm',
              handler: () => {
                resolve(true);
                return true;
              },
            },
          ],
        });
        await alert.present();
      } catch (error) {
        SentryUtilites.setLog("SaleReturnBulkDeleteComponent:deleteConfirm", error)
        return resolve(false);
      }
    });
  }

  async deleteMoneyOutConfirm(): Promise<boolean> {
    return new Promise(async (resolve,reject) => {
      try {
        const alert = await this.alertController.create({
          mode: 'ios',
          header: `Also Delete Attached Money Out's`,
          message: 'Are you sure want to delete?',
          buttons: [
            {
              text: 'No',
              role: 'cancel',
              handler: () => {
                resolve(false);
                return true;
              },
            },
            {
              text: 'Yes',
              role: 'confirm',
              handler: () => {
                resolve(true);
                return true;
              },
            },
          ],
        });
        await alert.present();
      } catch (error) {
        SentryUtilites.setLog("SaleReturnBulkDeleteComponent:deleteMoneyOutConfirm", error)
        return resolve(false);
      }
    });
  }

  async onDateRangeChange(){
    try {
      this.viewFilteredList = await this.allDataService?.saleReturnService?.getByBillDateRange(this.fromStamp, this.toStamp) || [];
      this.viewFilteredList = this.viewFilteredList?.filter(x => !x?.deletedStamp);
      this.bulkSaleReturns = this.viewFilteredList.slice(0,50);
    } catch (error) {
      SentryUtilites.setLog("SaleReturnBulkDeleteComponent:onDateRangeChange", error)
    }
  }

  // SaleReturn
  async openSaleReturnModal(value: boolean = true) {
    try {
      if(value) {
        if ((await this.deleteConfirm()) === false) return;
      }
      this.isSaleReturnModalOpen = value;
    } catch (error) {
      SentryUtilites.setLog("SaleReturnBulkDeleteComponent:openSaleReturnModal", error)
    }
  }

  async initDeleteSaleReturn(saleReturn: SaleReturn) {
    try {
      this.saleReturn = saleReturn;
      if(saleReturn?.moneyOuts?.length) {
        this.isDeleteSaleReturnAttachedMoneyOuts = true;
      }
      this.selectedVar = 'SaleReturn';
      this.openTransactionPINModal();
    } catch (error) {
      SentryUtilites.setLog("SaleReturnBulkDeleteComponent:initDeleteSaleReturn", error)
    }
  }

  async deleteSaleReturn() {
    try {
      this.saleReturnDeleteStarted = true;
      let deletedSaleReturn = await this.allDataService.saleReturnService.delete(this.saleReturn);
      if (deletedSaleReturn?.deletedStamp) {
        this.saleReturn = deletedSaleReturn;
        this.allDataService.itemService.reloadList();
        this.allDataService.partyService.reloadList();
        this.allDataService.moneyOutService.reloadList();
        this.allDataService.saleService.reloadList();
        this.saleReturnDeleteCompleted = true;
        setTimeout(() => {
          this.openSaleReturnModal(false);
          this.saleReturn = null;
          this.isDeleteSaleReturnAttachedMoneyOuts = false;
          this.saleReturnDeleteStarted = false;
          this.saleReturnDeleteCompleted = false;
          if(this.navigateTo) {
            this.router.navigate([this.navigateTo]);
          }
          setTimeout(() => {
            this.onSaleReturnDelete.emit(deletedSaleReturn);
          }, 1000);
        }, 500);
      }
    } catch (error) {
      SentryUtilites.setLog("SaleReturnBulkDeleteComponent:deleteSaleReturn", error)
    }
  }

  //--------------------------------------------------------------------------------

  // Bulk SaleReturn

  async openBulkSaleReturnModal(value: boolean = true) {
    try {
      if(value) {
        if ((await this.deleteConfirm()) === false) return;
      }
      this.isBulkSaleReturnModalOpen = value;
      this.selectedBulkSaleReturns = [];
    } catch (error) {
      SentryUtilites.setLog("SaleReturnBulkDeleteComponent:openBulkSaleReturnModal", error)
    }
  }

  async initDeleteBulkSaleReturn() {
    try {
      this.selectedVar = 'BulkSaleReturn';
      if(!this.bulkSelectArray.includes('BulkSaleReturn')) {
        this.bulkSelectArray.push('BulkSaleReturn');
      }
      this.openTransactionPINModal();
    } catch (error) {
      SentryUtilites.setLog("SaleReturnBulkDeleteComponent:initDeleteBulkSaleReturn", error)
    }
  }

  /**
   * 
   * @param saleReturn : Provide saleReturn object
   * @param event : ionic CustomEventInit
   * @description : this method add checked saleReturn in selectedBulkSaleReturns array 
   *                if unchecked it will that saleReturn from selectedBulkSaleReturns array
   */
  addToSelectedBulkSaleReturnArr(saleReturn: SaleReturn,event: CustomEventInit) {
    try {
      const index = this.selectedBulkSaleReturns?.findIndex(x => x?._localUUID === saleReturn?._localUUID);
      this.bulkSaleReturns?.forEach(x => {
        if(x?._localUUID === saleReturn?._localUUID){
          x["checked"] = event?.detail?.checked;
        }
      });
      if(event?.detail?.checked) {
        if(index == -1){
          this.selectedBulkSaleReturns.push(saleReturn);    
        }
      }else {
        if(index != -1) {
          this.selectedBulkSaleReturns.splice(index,1);
        }
      }
    } catch (error) {
      SentryUtilites.setLog("SaleReturnBulkDeleteComponent:addToSelectedBulkSaleReturnArr", error)
    }
  }
  // -----------------------------

  async deleteBulkSaleReturn() {
    try {
      if(await this.deleteMoneyOutConfirm()){
        this.bulkSaleReturnDeleteStarted = true;
        if(this.selectedBulkSaleReturns?.length) {
          for (let i = 0; i < this.selectedBulkSaleReturns.length; i++) {
            let saleReturn = this.selectedBulkSaleReturns[i];
            await this.allDataService.saleReturnService.delete(saleReturn);
          }
        }
        this.allDataService.itemService.reloadList();
        this.allDataService.partyService.reloadList();
        this.allDataService.moneyOutService.reloadList();
        this.allDataService.saleService.reloadList();
        this.bulkSaleReturnDeleteCompleted = true;
        setTimeout(() => {
          this.openBulkSaleReturnModal(false);
          this.bulkSaleReturns = [];
          this.selectedBulkSaleReturns = [];
          this.bulkSaleReturnDeleteStarted = false;
          this.bulkSaleReturnDeleteCompleted = false;
          setTimeout(() => {
            this.onBulkSaleReturnDelete.emit(true);
          }, 1000);
        }, 500);
      }
    } catch (error) {
      SentryUtilites.setLog("SaleReturnBulkDeleteComponent:deleteBulkSaleReturn", error)
    }
  }

  /**
   * 
   * @param event : ionic CustomEventInit
   * @description : this method push all saleReturns in selectedBulkSaleReturns if select all checkbox is checked
   */
  selectAllSaleReturnCheckboxes(event: CustomEventInit){
    try {
      if(this.checkAllSaleReturnCheckboxes) {
        if(event?.detail?.checked) {
          this.viewFilteredList?.forEach((el)=> {el["checked"] = true});
          this.selectedBulkSaleReturns = [...this.viewFilteredList];
        } else {
          this.bulkSaleReturns?.forEach((el)=> {el["checked"] = false});
          this.viewFilteredList?.forEach((el)=> {el["checked"] = false});
          this.selectedBulkSaleReturns = [];
        }
      }
      this.checkAllSaleReturnCheckboxes = false;
    } catch (error) {
      SentryUtilites.setLog("SaleReturnBulkDeleteComponent:selectAllSaleReturnCheckboxes", error)
    }
  }
  // -----------------------------

  /**
   * @description: this method check user is click on checkbox or not.
   */
  selectAllSaleReturnCheckboxClick() {
    this.checkAllSaleReturnCheckboxes = true;
  }
  // -----------------------------

  //--------------------------------------------------------------------------------

  loadMoreListData(event) {
    try {
      if (this.bulkSaleReturns.length > 0 && this.bulkSaleReturns.length <= this.viewFilteredList.length) {
        let appendListLength = this.viewFilteredList.length - this.bulkSaleReturns.length;
        let lastEl = this.bulkSaleReturns[this.bulkSaleReturns.length - 1];
        let counter = 0;
        for (let i = 0; i < this.viewFilteredList.length; i++) {
          if (this.viewFilteredList[i]._localUUID == lastEl._localUUID) {
            counter = 1;
            continue;
          }
          if (counter > 0 && appendListLength >= 52) {
            if (counter <= 52) {
              this.bulkSaleReturns.push(this.viewFilteredList[i])
            } else {
              break;
            }
            counter++;
          } else if(counter > 0 && appendListLength < 52) {
            if (counter <= appendListLength) {
              this.bulkSaleReturns.push(this.viewFilteredList[i])
            } else {
              break;
            }
            counter++;
          }
        }
        (event as InfiniteScrollCustomEvent).target.complete();
      }
    } catch (error) {
      SentryUtilites.setLog("SaleReturnBulkDeleteComponent:loadMoreListData", error)
    }
  }

}
