<app-date-range [ngClass]="{'mobile': isMobile}" class="date-range" *ngIf="!printKotBtn" listType="kot" (dateRangeChange)="onDateRangeChange($event)"></app-date-range>
<ion-grid [ngClass]="{'mobile': isMobile}" [ngStyle]="{height: innerHeight}" class="all-list-include-date-filter">

  <ng-container *ngFor="let kotData of viewFilteredList">
     <ion-row class="item-custom-row">
  
      <ion-col size-lg="8" size-xs="6" (click)="onRowClick(kotData)">
        <ion-button fill="clear" class="btn-item-name">
          {{ shortStr(kotData?.sale?.party?.name,50) }}
        </ion-button>
      </ion-col>
  
      <ion-col size-lg="3.6" size-xs="6" (click)="onRowClick(kotData)">
        <ion-button fill="clear" class="btn-item-name btn-date btn-txt-black padding-end-none flt-rght">
          {{ kotData?.kot?.createdStamp| date: 'dd/MM/yy hh:mm:ss a' }}
        </ion-button>
      </ion-col>
  
      <ion-col size-lg="6" size-xs="6">
        <ion-button *ngIf="!kotData?.sale?.deletedStamp && kotData?.sale?._localUUID" color="primary" fill="clear" class="btn-sell-price" (click)="onRowClick(kotData)">
          BILL {{ kotData?.sale?.billNo }} <span class="sp-on-hold">{{ !kotData?.sale?.billCompleteStamp ? ' | ON HOLD' : '' }}</span>
        </ion-button>
        <ion-button *ngIf="kotData?.sale?.deletedStamp || !kotData?.sale?._localUUID" color="danger" fill="clear" class="btn-sell-price" (click)="onRowClick(kotData)">
          Sale is Deleted
        </ion-button>
        <ion-button *ngIf="viewSaleBtn && kotData?.sale?.billCompleteStamp" size="small" fill="outline" color="tertiary" class="btn-view-sale outline-border" (click)="viewSale(kotData)">
          View Sale
        </ion-button>
        <ion-button *ngIf="printKotBtn" size="small" fill="outline" color="tertiary" class="btn-view-sale outline-border" (click)="printKot(kotData)">
          Print
        </ion-button>
      </ion-col>
  
      <ion-col size-lg="5.6" size-xs="3" (click)="onRowClick(kotData)">
        <ion-button color="danger" fill="clear" class="btn-stock btn-kot-no flt-rght">
          KOT {{ kotData?.kot?.kotNo }}
        </ion-button>
      </ion-col>
  
      <ion-col size-lg="0.4" size-xs="3">
        <div *ngIf="kotData?.kot?.syncStamp > kotData?.kot?.updatedStamp; then thenBlock else elseBlock"></div>
        <ng-template #thenBlock>
          <ion-button class="btn-popover icon-sync" title="Data Uploaded" fill="clear">
            <ion-icon 
              name="checkmark-done-sharp" 
              color="success" 
              size="small">
            </ion-icon>
          </ion-button>
        </ng-template>
        <ng-template #elseBlock>
          <ion-button class="btn-popover icon-sync" title="Warning" fill="clear">
            <ion-icon 
              name="warning-sharp" 
              color="warning" 
              size="small">
            </ion-icon>
          </ion-button>
        </ng-template>
      </ion-col>
  
    </ion-row>
  </ng-container>

</ion-grid>

<ion-infinite-scroll
  threshold="10px"
  *ngIf="viewFilteredList?.length"
  (ionInfinite)="loadMoreListData($event)">
  <ion-infinite-scroll-content></ion-infinite-scroll-content>
</ion-infinite-scroll>

