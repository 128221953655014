<div class="connection-indication" [ngClass]="connectionStatus"></div>
<ion-split-pane contentId="content">
  <!--  the side menu  -->

  <div class="div-create-new" [style]="{display: isSideMenuVisible}">
    <span>
      Create New
    </span>
    <ion-fab #createNewFabEle>
      <ion-fab-button size="small" (clickOutSide)="closeClickOutSideCreateNew()">
        <ion-icon name="add"></ion-icon>
      </ion-fab-button>
      <ion-fab-list side="bottom">
        <ion-fab-button *ngFor="let item of createNewBillObj|keyvalue: returnZero" (click)="checkSaleCredit(item?.value)">
          {{ item?.key }}
        </ion-fab-button>
      </ion-fab-list>
    </ion-fab>
  </div>
  <ion-menu type="overlay" contentId="content" [style]="{display: isSideMenuVisible}">
    <ion-header>
      <div class="div-working-mode" [ngClass]="connectionStatus">
        <span class="app-version"> ver: {{ version }} </span>
        <app-build-info></app-build-info>
        <p [ngClass]="{active: connectionStatus === 'online' }"> <ion-icon name="wifi-outline"></ion-icon> You are
          Online</p>
        <p [ngClass]="{active: connectionStatus === 'offline' }"> <ion-icon name="alert-outline"></ion-icon> You are
          working Offline</p>
      </div>

      <ion-item *ngIf="isUpdateReady">
        <app-app-update></app-app-update>
      </ion-item>

      <!-- License Expiry Indicator Start -->

      <ion-item class="license-expiry-indicator" *ngIf="licenseExpiryIndicatorText" [color]="licenseExpiryIndicatorColor">
        {{ licenseExpiryIndicatorText }}
      </ion-item>

      <!-- License Expiry Indicator End -->
      
      <ion-item color="primary" class="profile-info" [routerLink]="'profile/form'">
        <ion-row>
          <ion-col size="4">
            <img *ngIf="logoBase64" [src]="logoBase64" />
          </ion-col>
          <ion-col size="8" class="txt-right">
            <ion-label>{{ selectedProfile?.legalName?.substr(0,19) }}</ion-label>
            <ion-label><ion-icon name="call"></ion-icon>{{ loginPhone }}</ion-label>
          </ion-col>
          <ion-col size="12" class="txt-right">
            <ion-label>GSTIN: {{ selectedProfile?.gstin || 'N/A' }}</ion-label>
          </ion-col>
        </ion-row>
      </ion-item>
    </ion-header>

    <ion-content>
      <ion-list>
        <ion-menu-toggle auto-hide="false" *ngFor="let p of pages">
          <ion-accordion-group expand="inset" *ngIf="p?.nested?.length" #accordionGroup>
            <ion-accordion>
              <ion-item slot="header" color="light" lines="inset"
                [routerLink]="p?.url" routerDirection="root" [class.active-item]="selectedPath === p.url"
                (click)="onMenuOptionClick(p.title)">
                <ion-label>{{p?.title}}</ion-label>
              </ion-item>
              <div class="ion-padding" slot="content" *ngFor="let nestData of p?.nested" [routerLink]="nestData?.url">{{nestData?.title}}</div>
            </ion-accordion>
          </ion-accordion-group>
          <ion-item
            *ngIf="((!selectedProfile?.itSetEnableIngredient && p.title!='Raw Material') || (selectedProfile?.itSetEnableIngredient)) && !p?.nested?.length"
            [routerLink]="p?.url" routerDirection="root" [class.active-item]="selectedPath === p.url"
            (click)="onMenuOptionClick(p.title)">
            {{ p.title }}
            <ion-text class="online-feature" *ngIf="p.title=='Raw Material'" color="success">Online Feature</ion-text>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
    </ion-content>

  </ion-menu>




  <!-- the main content -->
  <ion-router-outlet id="content" main></ion-router-outlet>
</ion-split-pane>

<ion-modal class="enable-online-billing-modal" [isOpen]="selectedProfile?.aAppEnableOnlineBillingStatus && connectionStatus === 'offline'" [backdropDismiss]="false">
  <ng-template>
    <ion-content>
      <div class="enable-online-billing">
        <span>
          Billing Stopped. Connect to Internet
        </span>
      </div>
    </ion-content>
  </ng-template>
</ion-modal>
<app-settings #settingsModal></app-settings>
<app-licence-info #licenceInfoEle></app-licence-info>
<app-login-token-info #loginTokenEle></app-login-token-info>

<app-non-premium-modal #nonPremiumModalEle [positionLeft]="positionLeft"></app-non-premium-modal>

<ion-modal 
  class="modal-support" 
  [ngStyle]="{'left':'289px'}" 
  [isOpen]="isSupportModalOpen" 
  [backdropDismiss]="true"
>
  <ng-template>
    <ion-header>
      <ion-toolbar color="primary">
        <ion-title>Support</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="openSupportModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">

      <ion-grid>
        <ion-row>
          <ion-col size="12" class="txt-center">
            <ion-button fill="clear" class="btn-txt-black padding-end-none btn-support-title">
              TALK TO EZO EXPERT
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12" class="txt-center">
            <ion-chip class="chip-support" color="tertiary">
              <img src="../../../assets/icons/support-call.png">
              <span>+91 9167134134</span>
            </ion-chip>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12" class="txt-center">
            <ion-chip class="chip-whatsapp" color="success" (click)="whatsappSupport()">
              <img src="../../../assets/icons/whatsapp.png">
              <span>WhatsApp Support</span>
            </ion-chip>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12" class="txt-center">
            <ion-chip class="chip-team-viewer" color="success" (click)="remoteSupport()">
              <img src="../../../assets/icons/team-viewer.png">
              <span>Remote Support</span>
            </ion-chip>
          </ion-col>
        </ion-row>
      </ion-grid>

    </ion-content>
  </ng-template>
</ion-modal>