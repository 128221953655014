<ion-list>
  <ion-reorder-group [disabled]="false" (ionItemReorder)="handleReorder($any($event))">
    <ion-item lines="none" *ngFor="let partyCategory of viewPartyCategories">
      <ion-button fill="clear" class="txt-transform-normal" *ngIf="!partyCategory?.isEdit">
        {{ partyCategory?.category?.name }} ({{categoryWiseItemCount[partyCategory?.category?.name] || 0}})
      </ion-button>
      <ion-input
        #editInputEle
        *ngIf="partyCategory?.isEdit"
        class="new-ion-input"
        [value]="partyCategory?.category?.name"
      >
      </ion-input>
      <ion-button *ngIf="partyCategory?.isEdit" class="btn-update" color="primary" size="small" (click)="update(partyCategory?.category?._localUUID)">
        Update
      </ion-button>
      <ion-button *ngIf="partyCategory?.isEdit" class="btn-cancel" color="light" size="small" (click)="disableEdit(partyCategory)">
        Cancel
      </ion-button>
      <ion-button color="primary" title="Edit" slot="end" fill="clear" (click)="enableEdit(partyCategory)">
        <ion-icon name="create"></ion-icon>
      </ion-button>
      <ion-button color="danger" title="Delete" slot="end" fill="clear" (click)="delete(partyCategory?.category)">
        <ion-icon name="trash"></ion-icon>
      </ion-button>
      <ion-reorder slot="end"></ion-reorder>
    </ion-item>
  </ion-reorder-group>
</ion-list>

<ion-infinite-scroll threshold="10px" *ngIf="viewPartyCategories?.length" (ionInfinite)="loadMoreListData($event)">
  <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Loading more data...">
  </ion-infinite-scroll-content>
</ion-infinite-scroll>

<app-pin-verification (verifyTransactionPin)="verifyTransactionPIN($event)" #pinVerificationElement></app-pin-verification>