import { AccessControlService } from './../../../services/auth/access-control.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ToastController } from '@ionic/angular';
import { MoneyOut } from '../../../models/MoneyOut.model';
import { AllDataService } from '../../../services/all-data.service';
import { PinVerificationComponent } from '../../pin-verification/pin-verification.component';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-money-out-row',
  templateUrl: './money-out-row.component.html',
  styleUrls: ['./money-out-row.component.scss'],
})
export class MoneyOutRowComponent implements OnInit {

  @Input() moneyOut: MoneyOut = null;

  @Output() onDeleteClick = new EventEmitter<MoneyOut>();

  @ViewChild('pinVerificationElement') pinVerificationElement: PinVerificationComponent;

  selectedMoneyOut: MoneyOut = null;

  constructor(
    private router: Router,
    private accessControlService: AccessControlService,
    private allDataService: AllDataService
  ) { }

  async ngOnInit() { }

  openTransactionPINModal() {
    this.pinVerificationElement?.openTransactionPINModal();
  }

  async billView(moneyOut: MoneyOut) {
    try {
      if(!moneyOut?.deletedStamp) {
        let isPermit = await this.accessControlService.isUserHasAccess({action:'viewMoneyOut'});
        if(!isPermit) {
          return alert("Permission: You don't have permission to view bill. Please contact to your owner.");
        }
        this.router.navigate([`bill-view-money-out/${moneyOut?._localUUID}`]);
      }
    } catch (error) {
      SentryUtilites.setLog("MoneyOutRowComponent:billView", error)
      alert("Something went wrong.");
    }
  }

  verifyTransactionPIN(event) {
    try {
      if(event) {
        this.router.navigate([`money-out/form/${this.moneyOut?._localUUID}`]);
      }
    } catch (error) {
      SentryUtilites.setLog("MoneyOutRowComponent:verifyTransactionPIN", error)
    }
  }

  async edit(moenyOut: MoneyOut) {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({action:'editMoneyOut'});
      if(!isPermit) {
        return alert("Permission: You don't have permission to delete money out. Please contact to your owner.");
      }
      this.openTransactionPINModal();
    } catch (error) {
      SentryUtilites.setLog("MoneyOutRowComponent:edit", error)
      alert("Something went wrong.");
    }
  }

  resetSelectedMoneyOut() {
    this.selectedMoneyOut = null;
  }

  delete(moneyOut: MoneyOut) {
    this.onDeleteClick.emit(moneyOut);
  }

  viewPurchase(moneyOut:MoneyOut){
    try {
      if(moneyOut?.linkedPurchaseUUID) {
        this.router.navigate([`bill-view-purchase/${moneyOut?.linkedPurchaseUUID}`]);
      }
    } catch (error) {
      SentryUtilites.setLog("MoneyOutRowComponent:viewPurchase", error)
    }
  }

  viewExpense(moneyOut:MoneyOut){
    try {
      if(moneyOut?.linkedExpenseUUID) {
        this.router.navigate([`bill-view-expense/${moneyOut?.linkedExpenseUUID}`]);
      }
    } catch (error) {
      SentryUtilites.setLog("MoneyOutRowComponent:viewExpense", error)
    }
  }

  viewSaleReturn(moneyOut:MoneyOut){
    try {
      if(moneyOut?.linkedSaleReturnUUID) {
        this.router.navigate([`bill-view-sale-return/${moneyOut?.linkedSaleReturnUUID}`]);
      }
    } catch (error) {
      SentryUtilites.setLog("MoneyOutRowComponent:viewExpense", error)
    }
  }
}
