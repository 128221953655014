<ion-header [ngClass]="{'mobile': isMobile}">
  <ion-toolbar class="{{ getHeaderColorClass() }} ion-toolbar-item-transaction">
    <app-navigation-control slot="start" type="item/transaction"></app-navigation-control>
    <ion-title class="header-title" [appHeaderTitleCss]="isMobile ? 'mobile' : ''">
      <span>
        Item Transaction
      </span>
      <ion-chip> Count: {{ filteredList?.length }} </ion-chip>
    </ion-title>
    <ion-chip class="btn-edit-item ion-chip" slot="end" color="tertiary" (click)="editItem()"> Edit </ion-chip>
    <ion-chip class="btn-delete-item ion-chip" slot="end" color="danger" (click)="deleteItem()"> Delete </ion-chip>
    <ion-chip color="primary" slot="end" class="chip-option" (click)="toggleOption()" (clickOutSide)="clickOutSide()">
      <ion-icon name="ellipsis-vertical"></ion-icon>
    </ion-chip>
  </ion-toolbar>
  <ion-list *ngIf="isOptionOpen" class="show-chip-option-item-transaction">
    <ion-item (click)="editItem()">
      Edit
    </ion-item>
    <ion-item (click)="deleteItem()">
      Delete
    </ion-item>
  </ion-list>
</ion-header>

<ion-content [ngClass]="{'mobile': isMobile}">

  <ion-card class="card-info card-info-transaction" mode="ios" [color]="ledgerStock>0 ? 'success' : 'danger'">
    <ion-card-header>
      <ion-card-title>{{ this.fetchedItem?.itemName }}</ion-card-title>
      <ion-card-subtitle>
        Sell Price: <b>₹ {{ this.fetchedItem?.sellPrice }}</b>
        <ion-chip class="chip-report" (click)="openReportView()">Report</ion-chip>
      </ion-card-subtitle>
    </ion-card-header>

    <ion-card-content>
      Stock: <ion-label>{{ ledgerStock === null ? '' : ledgerStock | number: '1.2-2' }}</ion-label>
    </ion-card-content>
  </ion-card>

  <app-date-range
   class="date-range-transaction" 
   listType="ledgerItem" 
   (dateRangeChange)="onDateRangeChange($event)"
   >
  </app-date-range>

  <ion-grid
  class="all-list-include-date-filter-item-transaction"
  [ngStyle]="{height: innerHeight}"
  >
    <ion-content>
      <ion-row class="transaction-holder padding-5px">
        <ion-col size="12" class="col-custom-row" *ngFor="let transaction of viewFilteredList"  (click)="navigateToBillView(transaction)">
          <ion-row class="padding-none">
            <ion-col size="6" class="txt-left">
              <ion-row>
                <ion-col size="12">
                  <ion-button [color]="getTransactionTypeColor(transaction?.type)" fill="clear" class="btn-item-name btn-txt-black padding-start-none txt-transform-normal">
                    {{ splitByCamelCase(transaction?.type) }}
                  </ion-button>
                  <ion-label class="label-note"> Note: {{ transaction?.note }} </ion-label>
                  <ion-chip color="primary" *ngIf="transaction.billNo">
                    {{ transaction.billNo }}
                  </ion-chip>
                </ion-col>
                <ion-col size="12">
                  <ion-label color="primary">
                    {{ transaction.billDateStamp || transaction.createdStamp | date: 'dd/MM/yy' }}
                  </ion-label>
                </ion-col>
              </ion-row>
            </ion-col>
            <ion-col size="6" class="txt-right">
              <ion-row>
                <ion-col size="12">
                  <ion-button fill="outline" class="dashed-border txt-transform-normal flt-rght">
                    <ion-label class="margin-inline-start-10px" [color]="getTransactionTypeColor(transaction?.type)">
                      {{ transaction.quantity }}
                    </ion-label>
                  </ion-button>
                  <ion-button fill="clear" class="dark-grey txt-transform-normal flt-rght"> {{ transaction.runningStock | number: '1.2-2' }} </ion-button>
                  <ion-button fill="clear" class="dark-grey txt-transform-normal flt-rght"> Running Stock </ion-button>
                </ion-col>
                <ion-col size="12">
                  <ion-col size="auto" offset="1">
                    <div *ngIf="transaction?.syncStamp > transaction?.updatedStamp; then thenBlock else elseBlock"></div>
                    <ng-template #thenBlock>
                      <ion-icon name="checkmark-done-sharp" color="success"></ion-icon>
                    </ng-template>
                    <ng-template #elseBlock>
                      <ion-icon name="warning-sharp" color="warning"></ion-icon>
                    </ng-template>
                  </ion-col>
                </ion-col>
              </ion-row>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
      <ion-infinite-scroll threshold="10px" *ngIf="viewFilteredList?.length" (ionInfinite)="loadMoreListData($event)">
        <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Loading more data...">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ion-grid>

  <app-delete-data #deleteDataEle (onItemDelete)="onItemDelete($event)"></app-delete-data>
  <app-pin-verification (verifyTransactionPin)="verifyTransactionPIN($event)" #pinVerificationElement></app-pin-verification>
