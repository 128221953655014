import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-navigation-control',
  templateUrl: './navigation-control.component.html',
  styleUrls: ['./navigation-control.component.scss'],
})
export class NavigationControlComponent implements OnInit {

  @Input() type: string = null;
  @Input() _localUUID: string = null;

  backRoute = {
    'party': 'dashboard',
    'party/form': 'party',
    'party/form/_localUUID': 'party/transaction',
    'party/transaction': 'party',
    'party/whatapp-bulk-msg': 'party',
    'item': 'dashboard',
    'item/form': 'item',
    'item/form/_localUUID': 'item/transaction',
    'bulk-item': 'item',
    'item/transaction': 'item',
    'item/item-category': 'dashboard',
    'ingredient': 'dashboard',
    'ingredient/form': 'ingredient',
    'ingredient/form/_localUUID': 'ingredient',
    'ingredient/transaction': 'ingredient',
    'ingredient/edit': 'ingredient',
    'category': 'dashboard',
    'category?selectItem=true': 'dashboard',
    'sale': 'dashboard',
    'sale/form': 'sale',
    'sale-return': 'dashboard',
    'sale-return/form': 'sale-return',
    'estimate/form' : 'estimate',
    'sale/bill-view': 'sale',
    'sale-return/bill-view': 'sale-return',
    'estimate/bill-view' : 'estimate',
    'kot': 'dashboard',
    'purchase': 'dashboard',
    'purchase/form': 'purchase',
    'purchase-return': 'dashboard',
    'purchase-return/form': 'purchase-return',
    'expense': 'dashboard',
    'expense/form': 'expense',
    'money-in': 'dashboard',
    'money-in/form': 'money-in',
    'money-out': 'dashboard',
    'money-out/form': 'money-out',
    'reports': 'dashboard',
    'profile': 'dashboard',
    'profile/form': 'dashboard',
    'staff': 'dashboard',
    'staff/form': 'staff',
    'marketing': 'dashboard',
    'ingredient/transaction/raw-material-purchase-bulk-edit': 'ingredient/transaction',
    'ingredient/transaction/raw-material-purchase-bulk-edit/_localUUID': 'ingredient/transaction',
    'ingredient/transaction/_localUUID': 'ingredient/transaction',
    'google-myBusiness': 'dashboard',
  };

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {}

  navigate() {
    try {
      if(this.type && this.backRoute[this.type]) {
        let route: string = '';
        if(this._localUUID) {
          route = this.backRoute[this.type+'/_localUUID'] + `/${this._localUUID}`;
        } else {
          route = this.backRoute[this.type];
        }
        this.router.navigate([route]);
      }
    } catch (error) {
      SentryUtilites.setLog("NavigationControlComponent:navigate", error)
    }
  }

}
