import { NgxIndexedDBService } from "ngx-indexed-db";
import { IDataRepo } from "../../../interface/IDataRepo.interface";
import { ItemUnit, IItemUnit } from "../../models/ItemUnit.model";
import { Utility } from "../../utils/utility";
import { Router } from "@angular/router";
import { SentryUtilites } from "src/app/utils/sentryUtilites";

export class ItemUnitDao implements IDataRepo<ItemUnit>{
    private static _instance: ItemUnitDao;

    public static getInstance(
        ngxIndexedDBService: NgxIndexedDBService,
        router: Router,
    ) {
        if (!this._instance) {
            this._instance = new ItemUnitDao(ngxIndexedDBService, router)
        }
        return this._instance;
    }

    constructor(
        ngxIndexedDBService: NgxIndexedDBService,
        router: Router,
        ) {
        this.ngxIndexedDBService = ngxIndexedDBService;
        this.router = router;
      }
    
      ngxIndexedDBService: NgxIndexedDBService;
      router: Router;

    save(data: ItemUnit): Promise<ItemUnit> {
        return new Promise(async (resolve, reject) => {
            try {
                if(Utility.isTruthy(data)) {
                    let timeStamp = +new Date();
                    data.createdStamp = timeStamp
                    data.updatedStamp = timeStamp
                    data.userUpdatedStamp = timeStamp
                    data.deletedStamp = 0
                    data.syncStamp = 0
    
                    data._localUUID = Utility.getUUID();
    
                    return resolve(await this.saveDb(data));
                } else {
                    return resolve(null);
                }
            } catch (err) {
                SentryUtilites.setLog("ItemUnitDao:save", err)
                return resolve(null);
            }
        });
    }

    saveDb(data: ItemUnit): Promise<ItemUnit> {
        return new Promise((resolve, reject) => {
            try {
                if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && Utility.isTruthy(data)) {
                    this.ngxIndexedDBService
                        .add(IItemUnit.SCHEMA_NAME, data)
                        .subscribe((savedItemUnit: ItemUnit) => {
                            return resolve(savedItemUnit);
                        },
                        err => {
                            console.error(err);
                            if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                                this.router.navigate(['idbx-error']);
                            } else if (typeof err?.target?.error == 'object') {
                                SentryUtilites.setLog("ItemUnitDao:saveDb", err?.target?.error)
                            } else {
                                SentryUtilites.setLog("ItemUnitDao:saveDb", err)
                            }
                        });
                } else {
                  return resolve(null);
                }
            } catch (error) {
                SentryUtilites.setLog("ItemUnitDao:saveDb", error)
                return resolve(null);
            }
        })
    }

    update(data: ItemUnit): Promise<ItemUnit> {
        return new Promise(async (resolve, reject) => {
            try {
                if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?._localUUID) {
                    let oldData = await this.getByUUID(data?._localUUID);
                    if(oldData?._localUUID) {
                        data._localId = oldData?._localId;
                        let timeStamp = +new Date();
                        data.updatedStamp = timeStamp
                        data.userUpdatedStamp = timeStamp
                        this.ngxIndexedDBService
                            .update(IItemUnit.SCHEMA_NAME, data)
                            .subscribe((updatedItemUnit: ItemUnit) => {
                                return resolve(updatedItemUnit);
                            },
                            err => {
                                console.error(err);
                                if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                                    this.router.navigate(['idbx-error']);
                                } else if (typeof err?.target?.error == 'object') {
                                    SentryUtilites.setLog("ItemUnitDao:update", err?.target?.error)
                                } else {
                                    SentryUtilites.setLog("ItemUnitDao:update", err)
                                }
                            });
                    } else {
                        return resolve(null);
                    }
                } else {
                  return resolve(null);
                }
            } catch (err) {
                SentryUtilites.setLog("ItemUnitDao:update", err)
                return resolve(null);
            }
        })
    }

    updateDb(data: ItemUnit): Promise<ItemUnit> {
        return new Promise(async (resolve, reject) => {
            try {
                if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?._localUUID) {
                    let oldData = await this.getByUUID(data?._localUUID);
                    if(oldData?._localUUID) {
                        data._localId = oldData?._localId;
                        this.ngxIndexedDBService
                            .update(IItemUnit.SCHEMA_NAME, data)
                            .subscribe((updatedItemUnit: ItemUnit) => {
                                return resolve(updatedItemUnit);
                            },
                            err => {
                                console.error(err);
                                if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                                    this.router.navigate(['idbx-error']);
                                } else if (typeof err?.target?.error == 'object') {
                                    SentryUtilites.setLog("ItemUnitDao:updateDb", err?.target?.error)
                                } else {
                                    SentryUtilites.setLog("ItemUnitDao:updateDb", err)
                                }
                            });
                    } else {
                        return resolve(null);
                    }
                } else {
                  return resolve(null)
                }
            } catch (err) {
                SentryUtilites.setLog("ItemUnitDao:updateDb", err)
                return resolve(null);
            }
        })
    }

    bulkPut(data: ItemUnit[]): Promise<boolean> {
        return new Promise((resolve, reject) => {
          try {
            if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?.length) {
                this.ngxIndexedDBService
                  .bulkPut(IItemUnit.SCHEMA_NAME, data)
                  .subscribe((data) => {
                    return resolve(true);
                  },
                  err => {
                      console.error(err);
                    if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                        this.router.navigate(['idbx-error']);
                    } else if (typeof err?.target?.error == 'object') {
                        SentryUtilites.setLog("ItemUnitDao:bulkPut", err?.target?.error)
                    } else {
                        SentryUtilites.setLog("ItemUnitDao:bulkPut", err)
                    }
                  })
            } else {
              return resolve(false)
            }
          } catch (error) {
            SentryUtilites.setLog("ItemUnitDao:bulkPut", error)
            return resolve(false);
          }
        })
      }

    delete(data: ItemUnit): Promise<ItemUnit> {
        return new Promise(async (resolve, reject) => {
            try {
                if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?._localUUID) {
                    let oldData = await this.getByUUID(data?._localUUID);
                    if(oldData?._localUUID) {
                        data._localId = oldData?._localId;
                        let timeStamp = +new Date();
                        data.updatedStamp = timeStamp;
                        data.userUpdatedStamp = timeStamp;
                        data.deletedStamp = timeStamp;
                        this.ngxIndexedDBService
                            .update(IItemUnit.SCHEMA_NAME, data)
                            .subscribe((updatedItemUnit: ItemUnit) => {
                                return resolve(updatedItemUnit);
                            },
                                err => {
                                    console.error(err);
                                    if (typeof err == 'string' && err?.includes('objectStore does not exists')) {
                                        this.router.navigate(['idbx-error']);
                                    } else if (typeof err?.target?.error == 'object') {
                                        SentryUtilites.setLog("ItemUnitDao:delete", err?.target?.error)
                                    } else {
                                        SentryUtilites.setLog("ItemUnitDao:delete", err)
                                    }
                                });
                    } else {
                        return resolve(null);
                    }
                } else {
                  return resolve(null);
                }
            } catch (err) {
                SentryUtilites.setLog("ItemUnitDao:delete", err)
                return resolve(null);
            }
        });
    }
    getById(id: number): Promise<ItemUnit> {
        return new Promise((resolve, reject) => {
            try {
                if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && id) {
                    this.ngxIndexedDBService
                        .getByKey(IItemUnit.SCHEMA_NAME, id)
                        .subscribe((profile: ItemUnit) => {
                            return resolve(profile);
                        },
                        err => {
                            console.error(err);
                            if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                                this.router.navigate(['idbx-error']);
                            } else if (typeof err?.target?.error == 'object') {
                                SentryUtilites.setLog("ItemUnitDao:getById", err?.target?.error)
                            } else {
                                SentryUtilites.setLog("ItemUnitDao:getById", err)
                            }
                        });
                } else {
                  return resolve(null)
                }
            } catch (err) {
                SentryUtilites.setLog("ItemUnitDao:getById", err)
                return resolve(null)
            }
        })
    }
    getByUUID(uuid: string): Promise<ItemUnit> {
        return new Promise((resolve, reject) => {
            try {
                if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && uuid) {
                    this.ngxIndexedDBService
                        .getByIndex(IItemUnit.SCHEMA_NAME, '_localUUID', uuid)
                        .subscribe((profile: ItemUnit) => {
                            return resolve(profile);
                        },
                        err => {
                            console.error(err);
                            if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                                this.router.navigate(['idbx-error']);
                            } else if (typeof err?.target?.error == 'object') {
                                SentryUtilites.setLog("ItemUnitDao:getByUUID", err?.target?.error)
                            } else {
                                SentryUtilites.setLog("ItemUnitDao:getByUUID", err)
                            }
                        });
                } else {
                  return resolve(null)
                }
            } catch (err) {
                SentryUtilites.setLog("ItemUnitDao:getByUUID", err)
                return resolve(null)
            }
        })
    }
    getAll(): Promise<ItemUnit[]> {
        return new Promise((resolve, reject) => {
            try {
                if(Boolean(Utility.getFromLocalStorage('_ezo_login_token'))) {
                    this.ngxIndexedDBService.getAll(IItemUnit.SCHEMA_NAME).subscribe((docs: ItemUnit[]) => {
                        docs = docs?.filter(x => !x?.deletedStamp);
                        docs?.sort((a, b) => b?.createdStamp - a?.createdStamp);
                        return resolve(docs)
                    },
                    err => {
                        console.error(err);
                        if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                            this.router.navigate(['idbx-error']);
                        } else if (typeof err?.target?.error == 'object') {
                            SentryUtilites.setLog("ItemUnitDao:getAll", err?.target?.error)
                        } else {
                            SentryUtilites.setLog("ItemUnitDao:getAll", err)
                        }
                    });
                } else {
                  return resolve([])
                }
            } catch (err) {
                SentryUtilites.setLog("ItemUnitDao:getAll", err)
                return resolve([])
            }
        })
    }
    getAllByProfile(profileId: string): Promise<ItemUnit[]> {
        return new Promise((resolve, reject) => {
            try {
                if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && profileId) {
                    this.ngxIndexedDBService.getAll(IItemUnit.SCHEMA_NAME).subscribe((docs: ItemUnit[]) => {
                        docs = docs?.filter(x => !x?.deletedStamp && x?.profileId == profileId);
                        docs?.sort((a, b) => b?.createdStamp - a?.createdStamp);
                        return resolve(docs)
                    },
                    err => {
                        console.error(err);
                        if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                            this.router.navigate(['idbx-error']);
                        } else if (typeof err?.target?.error == 'object') {
                            SentryUtilites.setLog("ItemUnitDao:getAllByProfile", err?.target?.error)
                        } else {
                            SentryUtilites.setLog("ItemUnitDao:getAllByProfile", err)
                        }
                    });
                } else {
                  return resolve([])
                }
            } catch (err) {
                SentryUtilites.setLog("ItemUnitDao:getAllByProfile", err)
                return resolve([])
            }
        })
    }
    getAllUnsynced(profileId: string): Promise<ItemUnit[]> {
        return new Promise((resolve, reject) => {
            try {
                if(Boolean(Utility.getFromLocalStorage('_ezo_login_token'))) {
                    let unSyncedElements: ItemUnit[] = [];
                    this.ngxIndexedDBService.getAll(IItemUnit.SCHEMA_NAME).subscribe(async (elArr: ItemUnit[]) => {
                        for (let i = 0; i < elArr?.length; i++) {
                            const el = elArr[i];
                            if (el?.updatedStamp > el?.syncStamp || !el?._serverIdRef) {
                                unSyncedElements.push(el);
                            }
                        }
                        return resolve(unSyncedElements)
                    },
                    err => {
                        console.error(err);
                        if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                            this.router.navigate(['idbx-error']);
                        } else if (typeof err?.target?.error == 'object') {
                            SentryUtilites.setLog("ItemUnitDao:getAllUnsynced", err?.target?.error)
                        } else {
                            SentryUtilites.setLog("ItemUnitDao:getAllUnsynced", err)
                        }
                    });
                } else {
                  return resolve([])
                }
            } catch (err) {
                SentryUtilites.setLog("ItemUnitDao:getAllUnsynced", err)
                return resolve([])
            }
        })
    }
    updateSyncStamp(data: ItemUnit): Promise<ItemUnit> {
        return new Promise(async (resolve, reject) => {
            try {
                if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?._localUUID) {
                    this.ngxIndexedDBService
                    .getByIndex(IItemUnit.SCHEMA_NAME, '_localUUID', data._localUUID)
                    .subscribe((dbEl: ItemUnit) => {
                        if(dbEl) {
                                dbEl.syncStamp = data?.syncStamp || 0;
                                dbEl._serverIdRef = data?._serverIdRef;
                                this.ngxIndexedDBService
                                    .update(IItemUnit.SCHEMA_NAME, dbEl)
                                    .subscribe((updatedEl: ItemUnit) => {
                                        return resolve(updatedEl);
                                    },
                                    err => {
                                        if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                                            console.error(err);
                                            this.router.navigate(['idbx-error']);
                                        } else if (typeof err?.target?.error == 'object') {
                                            SentryUtilites.setLog("ItemUnitDao:updateSyncStamp", err?.target?.error)
                                        } else {
                                            SentryUtilites.setLog("ItemUnitDao:updateSyncStamp", err)
                                        }
                                    });
                            } else {
                              return resolve(null);
                            }
                        },
                        err => {
                            if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                                console.error(err);
                                this.router.navigate(['idbx-error']);
                            } else if (typeof err?.target?.error == 'object') {
                                SentryUtilites.setLog("ItemUnitDao:updateSyncStamp", err?.target?.error)
                            } else {
                                SentryUtilites.setLog("ItemUnitDao:updateSyncStamp", err)
                            }
                        })
                } else {
                  return resolve(null)
                }
            } catch (err) {
                SentryUtilites.setLog("ItemUnitDao:updateSyncStamp", err)
                return resolve(null)
            }
        })
    }
}
