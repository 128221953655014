import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import * as platform from 'platform';
import { AllDataService } from './all-data.service';
import { AuthService } from './auth/auth.service';
import { MonthWisePartyCreditService } from './month-wise-party-credit.service';
import { MonthWiseItemStockService } from './month-wise-item-stock.service';
import { IMonthWisePartyCredit, MonthWisePartyCredit } from '../models/MonthWisePartyCredit.model';
import { IMonthWiseItemStock, MonthWiseItemStock } from '../models/MonthWiseItemStock.model';
import { Utils } from '../utils/utils';
import { Utility } from '../utils/utility';
import { SentryUtilites } from '../utils/sentryUtilites';

@Injectable({
  providedIn: 'root',
})
export class LedgerService {

  constructor(
    private authService: AuthService,
    private allDataService: AllDataService,
    private monthWisePartyCreditService: MonthWisePartyCreditService,
    private monthWiseItemStockService: MonthWiseItemStockService,
  ) { }

  /**
    * 
    * @description : store data in monthWisePreviousBalance and monthWiseItemStock indexeddb collection
    * @returns : return true if successfully execute code
    */
  maintainLedger(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {

        await this.authService.clearCollection(IMonthWisePartyCredit.SCHEMA_NAME);
        await this.authService.clearCollection(IMonthWiseItemStock.SCHEMA_NAME);

        let parties = await this.allDataService.partyService.getAll();
        let items = await this.allDataService.itemService.getAll();

        let sales = await this.allDataService.saleService.getAll();
        let saleReturns = await this.allDataService.saleReturnService.getAll();
        let moneyOuts = await this.allDataService.moneyOutService.getAll();
        let purchases = await this.allDataService.purchaseService.getAll();
        let purchaseReturns = await this.allDataService.purchaseReturnService.getAll();
        let expenses = await this.allDataService.expenseService.getAll();
        let moneyIns = await this.allDataService.moneyInService.getAll();
        let itemStockAdjusts = await this.allDataService.itemStockAdjustService.getAll();

        let partiesLength = parties?.length;
        let salesLength = sales?.length;
        let saleReturnsLength = saleReturns?.length;
        let moneyOutsLength = moneyOuts?.length;
        let purchasesLength = purchases?.length;
        let purchaseReturnsLength = purchaseReturns?.length;
        let expensesLength = expenses?.length;
        let moneyInsLength = moneyIns?.length;
        let itemsLength = items?.length;
        let itemStockAdjustsLength = itemStockAdjusts?.length;

        let monthWisePartyCredits = [];
        let monthWiseItemStocks = [];

        for (let i = 0; i < partiesLength; i++) {
          let monthWisePartyCredit = new MonthWisePartyCredit();
          monthWisePartyCredit.partyLocalUUID = parties[i]?._localUUID;
          monthWisePartyCredit.ledgerCredit = 0;
          monthWisePartyCredit.ledger = {};
          monthWisePartyCredits.push(monthWisePartyCredit);
        }

        for (let i = 0; i < itemsLength; i++) {
          let monthWiseItemStock = new MonthWiseItemStock();
          monthWiseItemStock.itemLocalUUID = items[i]?._localUUID;
          monthWiseItemStock.ledgerStock = 0;
          monthWiseItemStock.ledger = {};
          monthWiseItemStocks.push(monthWiseItemStock);
        }

        for (let i = 0; i < salesLength; i++) {
          const sale = sales[i];

          if(!sale?.deletedStamp && sale?.billCompleteStamp) {
            let partyCreditIndex = monthWisePartyCredits?.findIndex(monthWisePartyCredit => monthWisePartyCredit?.partyLocalUUID == sale?.party?._localUUID);
            this.modifyCredit(
              monthWisePartyCredits[partyCreditIndex], 
              sale?.billDateStamp,
              sale?.totalAmount
            );

            for (let i = 0; i < sale?.billItems?.length; i++) {
              let billItem = sale?.billItems[i];
              let qty = billItem?.unit === billItem?.item?.primaryUnit ? billItem?.quantity : Utils.capFractionsToTwo(billItem?.quantity / billItem?.convertRatioMultiplier);
              let itemStockIndex = monthWiseItemStocks?.findIndex(monthWiseItemStock => monthWiseItemStock?.itemLocalUUID == billItem?.item?._localUUID);
              this.modifyStock(
                monthWiseItemStocks[itemStockIndex], 
                sale?.billDateStamp,
                -qty
              );
            }
          }
        }

        for (let i = 0; i < saleReturnsLength; i++) {
          const saleReturn = saleReturns[i];

          if(!saleReturn?.deletedStamp && saleReturn?.billCompleteStamp) {
            let partyCreditIndex = monthWisePartyCredits?.findIndex(monthWisePartyCredit => monthWisePartyCredit?.partyLocalUUID == saleReturn?.party?._localUUID);
            this.modifyCredit(
              monthWisePartyCredits[partyCreditIndex], 
              saleReturn?.billDateStamp,
              -saleReturn?.totalAmount
            );

            for (let i = 0; i < saleReturn?.billItems?.length; i++) {
              let billItem = saleReturn?.billItems[i];
              let qty = billItem?.unit === billItem?.item?.primaryUnit ? billItem?.quantity : Utils.capFractionsToTwo(billItem?.quantity / billItem?.convertRatioMultiplier);
              let itemStockIndex = monthWiseItemStocks?.findIndex(monthWiseItemStock => monthWiseItemStock?.itemLocalUUID == billItem?.item?._localUUID);
              this.modifyStock(
                monthWiseItemStocks[itemStockIndex], 
                saleReturn?.billDateStamp,
                qty
              );
            }
          }
        }

        for (let i = 0; i < purchasesLength; i++) {
          const purchase = purchases[i];

          if(!purchase?.deletedStamp) {
            let partyCreditIndex = monthWisePartyCredits?.findIndex(monthWisePartyCredit => monthWisePartyCredit?.partyLocalUUID == purchase?.party?._localUUID);
            this.modifyCredit(
              monthWisePartyCredits[partyCreditIndex], 
              purchase?.billDateStamp,
              -purchase?.totalAmount
            );
  
            for (let i = 0; i < purchase?.billItems?.length; i++) {
              let billItem = purchase?.billItems[i];
              let qty = billItem?.unit === billItem?.item?.primaryUnit ? billItem?.quantity : Utils.capFractionsToTwo(billItem?.quantity / billItem?.convertRatioMultiplier);
              let itemStockIndex = monthWiseItemStocks?.findIndex(monthWiseItemStock => monthWiseItemStock?.itemLocalUUID == billItem?.item?._localUUID);
              this.modifyStock(
                monthWiseItemStocks[itemStockIndex], 
                purchase?.billDateStamp,
                qty
              );
            }
          }
        }

        for (let i = 0; i < purchaseReturnsLength; i++) {
          const purchaseReturn = purchaseReturns[i];

          if(!purchaseReturn?.deletedStamp) {
            let partyCreditIndex = monthWisePartyCredits?.findIndex(monthWisePartyCredit => monthWisePartyCredit?.partyLocalUUID == purchaseReturn?.party?._localUUID);
            this.modifyCredit(
              monthWisePartyCredits[partyCreditIndex], 
              purchaseReturn?.billDateStamp,
              purchaseReturn?.totalAmount
            );
  
            for (let i = 0; i < purchaseReturn?.billItems?.length; i++) {
              let billItem = purchaseReturn?.billItems[i];
              let qty = billItem?.unit === billItem?.item?.primaryUnit ? billItem?.quantity : Utils.capFractionsToTwo(billItem?.quantity / billItem?.convertRatioMultiplier);
              let itemStockIndex = monthWiseItemStocks?.findIndex(monthWiseItemStock => monthWiseItemStock?.itemLocalUUID == billItem?.item?._localUUID);
              this.modifyStock(
                monthWiseItemStocks[itemStockIndex], 
                purchaseReturn?.billDateStamp,
                -qty
              );
            }
          }
        }

        for (let i = 0; i < expensesLength; i++) {
          const expense = expenses[i];
          if(!expense?.deletedStamp) {
            let partyCreditIndex = monthWisePartyCredits?.findIndex(monthWisePartyCredit => monthWisePartyCredit?.partyLocalUUID == expense?.party?._localUUID);
            this.modifyCredit(
              monthWisePartyCredits[partyCreditIndex], 
              expense?.billDateStamp,
              -expense?.totalAmount
            );
          }
        }

        for (let i = 0; i < moneyInsLength; i++) {
          const moneyIn = moneyIns[i];
          if(!moneyIn?.deletedStamp) {
            let partyCreditIndex = monthWisePartyCredits?.findIndex(monthWisePartyCredit => monthWisePartyCredit?.partyLocalUUID == moneyIn?.party?._localUUID);
            this.modifyCredit(
              monthWisePartyCredits[partyCreditIndex], 
              moneyIn?.billDateStamp,
              -moneyIn?.totalAmount
            );
          }
        }

        for (let i = 0; i < moneyOutsLength; i++) {
          const moneyOut = moneyOuts[i];
          if(!moneyOut?.deletedStamp) {
            let partyCreditIndex = monthWisePartyCredits?.findIndex(monthWisePartyCredit => monthWisePartyCredit?.partyLocalUUID == moneyOut?.party?._localUUID);
            this.modifyCredit(
              monthWisePartyCredits[partyCreditIndex], 
              moneyOut?.billDateStamp,
              moneyOut?.totalAmount
            );
          }
          
        }

        for (let i = 0; i < itemStockAdjustsLength; i++) {
          const itemStockAdjust = itemStockAdjusts[i];
          if(!itemStockAdjust?.deletedStamp) {
            let itemStockIndex = monthWiseItemStocks?.findIndex(monthWiseItemStock => monthWiseItemStock?.itemLocalUUID == itemStockAdjust?.linkedItemUUID);
            this.modifyStock(
              monthWiseItemStocks[itemStockIndex], 
              itemStockAdjust?.createdStamp,
              Number(itemStockAdjust?.quantity)
            );
          }
        }

        await this.monthWiseItemStockService.bulkAdd(monthWiseItemStocks);

        await this.monthWisePartyCreditService.bulkAdd(monthWisePartyCredits);

        return resolve(true);
      } catch (error) {
        SentryUtilites.setLog("LedgerService:maintainLedger", error)
        return resolve(false);
      }
    })
  }
  // ------------------------------------------------------------------------------------------------------------------

  /**
   * 
   * @param monthWisePartyCredit : provide monthWisePartyCredit object
   * @param billDateStamp : provide billDateStamp
   * @param creditIncrementBy : provide credit
   * @returns : return updated monthWisePartyCredit object
   */
  modifyCredit(
    monthWisePartyCredit: MonthWisePartyCredit,
    billDateStamp: number,
    creditIncrementBy: number
  ): MonthWisePartyCredit {
    try {
      if (monthWisePartyCredit && Utility.isNumber(creditIncrementBy)) {
        let yearMonth = Utility.getYearMonthKey(billDateStamp);
        if (!monthWisePartyCredit?.ledger?.hasOwnProperty(yearMonth)) {
            monthWisePartyCredit.ledger[yearMonth] = 0;
        }
        monthWisePartyCredit.ledger[yearMonth] += Number(creditIncrementBy);
        monthWisePartyCredit.ledgerCredit += Number(creditIncrementBy);
      }
      return monthWisePartyCredit;
    } catch (error) {
      SentryUtilites.setLog("LedgerService:modifyCredit", error)
      return null;
    }
  }
  // ------------------------------------------------------------------------------------------------------------------

  /**
   * 
   * @param monthWiseItemStock : provide monthWiseItemStock object
   * @param billDateStamp : provide billDateStamp
   * @param quantity : provide quantity
   * @returns : return updated monthWiseItemStock object
   */
  modifyStock(
    monthWiseItemStock: MonthWiseItemStock,
    billDateStamp: number,
    quantity: number
  ): MonthWiseItemStock {
    try {
      if (monthWiseItemStock && Utility.isNumber(quantity)) {
        let yearMonth = Utility.getYearMonthKey(billDateStamp);
        if (!monthWiseItemStock?.ledger?.hasOwnProperty(yearMonth)) {
            monthWiseItemStock.ledger[yearMonth] = 0;
        }
        monthWiseItemStock.ledger[yearMonth] += Number(quantity);
        monthWiseItemStock.ledgerStock += Number(quantity);
      }
      return monthWiseItemStock;
    } catch (error) {
      SentryUtilites.setLog("LedgerService:modifyStock", error)
      return null;
    }
  }

}