import { NgxIndexedDBService } from "ngx-indexed-db";
import { IDataRepo } from "../../../interface/IDataRepo.interface";
import Party, { IParty } from "../../models/Party.model";
import { Utility } from "../../utils/utility";
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular";
import { SentryUtilites } from "src/app/utils/sentryUtilites";

export class PartyDao implements IDataRepo<Party>{
  private static _instance: PartyDao;

  public static getInstance(
    ngxIndexedDBService: NgxIndexedDBService,
    router: Router,
  ) {
    if (!this._instance) {
      this._instance = new PartyDao(ngxIndexedDBService, router)
    }
    return this._instance;
  }

  constructor(
    ngxIndexedDBService: NgxIndexedDBService,
    router: Router,
    ) {
    this.ngxIndexedDBService = ngxIndexedDBService;
    this.router = router;
  }

  ngxIndexedDBService: NgxIndexedDBService;
  router: Router;

  save(data: Party): Promise<Party> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && Utility.isTruthy(data)) {
          let timeStamp = +new Date();
          data.createdStamp = timeStamp
          data.updatedStamp = timeStamp
          data.userUpdatedStamp = timeStamp
          data.deletedStamp = 0
          data.syncStamp = 0
          data.credit = 0.0;
  
          if (!data?._localUUID) {
            data._localUUID = Utility.getUUID();
          }
  
          this.ngxIndexedDBService
            .add(IParty.SCHEMA_NAME, data)
            .subscribe((savedParty: Party) => {
              return resolve(savedParty);
            },
            err => {
              console.error(err);
              if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                  this.router.navigate(['idbx-error']);
                } else if (typeof err?.target?.error == 'object') {
                  SentryUtilites.setLog("PartyDao:save", err?.target?.error)
                } else {
                  SentryUtilites.setLog("PartyDao:save", err)
                }
            });
        } else {
          return resolve(null);
        }
      } catch (err) {
        SentryUtilites.setLog("PartyDao:save", err)
        return resolve(null);
      }
    });
  }

  saveDb(data: Party): Promise<Party> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && Utility.isTruthy(data)) {
          this.ngxIndexedDBService
            .add(IParty.SCHEMA_NAME, data)
            .subscribe((savedRecord: Party) => {
              return resolve(savedRecord);
            },
            err => {
              console.error(err);
              if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                  this.router.navigate(['idbx-error']);
                } else if (typeof err?.target?.error == 'object') {
                  SentryUtilites.setLog("PartyDao:saveDb", err?.target?.error)
                } else {
                  SentryUtilites.setLog("PartyDao:saveDb", err)
                }
            });
        } else {
          return resolve(null);
        }
      } catch (error) {
        SentryUtilites.setLog("PartyDao:saveDb", error)
        return resolve(null);
      }
    })
  }

  update(data: Party): Promise<Party> {
    return new Promise(async (resolve, reject) => {
      try {
        if (Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?._localUUID) {
          let oldData = await this.getByUUID(data?._localUUID);
          if(oldData?._localUUID) {
            data._localId = oldData?._localId;
            let timeStamp = +new Date();
            data.updatedStamp = timeStamp
            data.userUpdatedStamp = timeStamp
            this.ngxIndexedDBService
              .update(IParty.SCHEMA_NAME, data)
              .subscribe((updatedParty: Party) => {
                return resolve(updatedParty);
              },
              err => {
                console.error(err);
                if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                  this.router.navigate(['idbx-error']);
                } else if (typeof err?.target?.error == 'object') {
                  SentryUtilites.setLog("PartyDao:update", err?.target?.error)
                } else {
                  SentryUtilites.setLog("PartyDao:update", err)
                }
              });
          } else {
            return resolve(null);
          }
        } else {
          return resolve(null);
        }
      } catch (err) {
        SentryUtilites.setLog("PartyDao:update", err)
        return resolve(null);
      }
    })
  }

  updateDb(data: Party): Promise<Party> {
    return new Promise(async (resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?._localUUID) {
          let oldData = await this.getByUUID(data?._localUUID);
          if(oldData?._localUUID) {
            data._localId = oldData?._localId;
            this.ngxIndexedDBService
              .update(IParty.SCHEMA_NAME, data)
              .subscribe((updatedRecord: Party) => {
                return resolve(updatedRecord);
              },
              err => {
                console.error(err);
                if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                    this.router.navigate(['idbx-error']);
                  } else if (typeof err?.target?.error == 'object') {
                    SentryUtilites.setLog("PartyDao:updateDb", err?.target?.error)
                  } else {
                    SentryUtilites.setLog("PartyDao:updateDb", err)
                  }
              });
          } else {
            return resolve(null);
          }
        } else {
          return resolve(null);
        }
      } catch (err) {
        SentryUtilites.setLog("PartyDao:updateDb", err)
        return resolve(null);
      }
    })
  }

  bulkPut(data: Party[]): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?.length) {
          this.ngxIndexedDBService
            .bulkPut(IParty.SCHEMA_NAME, data)
            .subscribe((data) => {
              resolve(true);
            },
            err => {
              console.error(err);
              if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                  this.router.navigate(['idbx-error']);
                } else if (typeof err?.target?.error == 'object') {
                  SentryUtilites.setLog("PartyDao:bulkPut", err?.target?.error)
                } else {
                  SentryUtilites.setLog("PartyDao:bulkPut", err)
                }
            })
        } else {
          return resolve(false);
        }
      } catch (error) {
        SentryUtilites.setLog("PartyDao:bulkPut", error)
        return resolve(false);
      }
    })
  }

  delete(data: Party): Promise<Party> {
    return new Promise(async (resolve, reject) => {
      try {
        if (Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?._localUUID) {
          let oldData = await this.getByUUID(data?._localUUID);
          if(oldData?._localUUID) {
            data._localId = oldData?._localId;
            let timeStamp = +new Date();
            data.updatedStamp = timeStamp;
            data.userUpdatedStamp = timeStamp;
            data.deletedStamp = timeStamp;
            this.ngxIndexedDBService
              .update(IParty.SCHEMA_NAME, data)
              .subscribe((updatedParty: Party) => {
                return resolve(updatedParty);
              },
              err => {
                console.error(err);
                if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                    this.router.navigate(['idbx-error']);
                  } else if (typeof err?.target?.error == 'object') {
                    SentryUtilites.setLog("PartyDao:delete", err?.target?.error)
                  } else {
                    SentryUtilites.setLog("PartyDao:delete", err)
                  }
              });
          } else {
            return resolve(null);
          }
        } else {
          return resolve(null);
        }
      } catch (err) {
        SentryUtilites.setLog("PartyDao:delete", err)
        return resolve(null);
      }
    });
  }
  getById(id: number): Promise<Party> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && id) {
          this.ngxIndexedDBService
            .getByKey(IParty.SCHEMA_NAME, id)
            .subscribe((profile: Party) => {
              return resolve(profile);
            },
            err => {
              console.error(err);
              if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                  this.router.navigate(['idbx-error']);
                } else if (typeof err?.target?.error == 'object') {
                  SentryUtilites.setLog("PartyDao:getById", err?.target?.error)
                } else {
                  SentryUtilites.setLog("PartyDao:getById", err)
                }
            });
        } else {
          return resolve(null);
        }
      } catch (err) {
        SentryUtilites.setLog("PartyDao:getById", err)
        return resolve(null)
      }
    })
  }
  getByUUID(uuid: string): Promise<Party> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && uuid) {
          this.ngxIndexedDBService
            .getByIndex(IParty.SCHEMA_NAME, '_localUUID', uuid)
            .subscribe((party: Party) => {
              return resolve(party);
            },
            err => {
              console.error(err);
              if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                  this.router.navigate(['idbx-error']);
                } else if (typeof err?.target?.error == 'object') {
                  SentryUtilites.setLog("PartyDao:getByUUID", err?.target?.error)
                } else {
                  SentryUtilites.setLog("PartyDao:getByUUID", err)
                }
            });
        } else {
          return resolve(null);
        }
      } catch (err) {
        SentryUtilites.setLog("PartyDao:getByUUID", err)
        return resolve(null)
      }
    })
  }
  getAll(): Promise<Party[]> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token'))) {
          this.ngxIndexedDBService.getAll(IParty.SCHEMA_NAME).subscribe((docs: Party[]) => {
            docs = docs?.filter(x => !x?.deletedStamp);
            docs?.sort((a, b) => b?.createdStamp - a?.createdStamp);
            return resolve(docs)
          },
          err => {
            console.error(err);
            if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                this.router.navigate(['idbx-error']);
              } else if (typeof err?.target?.error == 'object') {
                SentryUtilites.setLog("PartyDao:getAll", err?.target?.error)
              } else {
                SentryUtilites.setLog("PartyDao:getAll", err)
              }
          });
        } else {
          return resolve([]);
        }
      } catch (err) {
        SentryUtilites.setLog("PartyDao:getAll", err)
        return resolve([])
      }
    })
  }
  getAllByProfile(profileId: string): Promise<Party[]> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && profileId) {
          this.ngxIndexedDBService.getAll(IParty.SCHEMA_NAME).subscribe((docs: Party[]) => {
            docs = docs?.filter(x => !x?.deletedStamp && x?.profileId == profileId);
            docs?.sort((a, b) => b?.createdStamp - a?.createdStamp);
            return resolve(docs)
          },
          err => {
            console.error(err);
            if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                this.router.navigate(['idbx-error']);
              } else if (typeof err?.target?.error == 'object') {
                SentryUtilites.setLog("PartyDao:getAllByProfile", err?.target?.error)
              } else {
                SentryUtilites.setLog("PartyDao:getAllByProfile", err)
              }
          });
        } else {
          return resolve([]);
        }
      } catch (err) {
        SentryUtilites.setLog("PartyDao:getAllByProfile", err)
        return resolve([])
      }
    })
  }
  getAllUnsynced(profileId: string): Promise<Party[]> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token'))) {
          let unSyncedElements: Party[] = [];
          this.ngxIndexedDBService.getAll(IParty.SCHEMA_NAME).subscribe(async (elArr: Party[]) => {
            for (let i = 0; i < elArr?.length; i++) {
              const el = elArr[i];
              if (el?.updatedStamp > el?.syncStamp || !el?._serverIdRef) {
                unSyncedElements.push(el);
              }
            }
            return resolve(unSyncedElements)
          },
          err => {
            console.error(err);
            if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                this.router.navigate(['idbx-error']);
              } else if (typeof err?.target?.error == 'object') {
                SentryUtilites.setLog("PartyDao:getAllUnsynced", err?.target?.error)
              } else {
                SentryUtilites.setLog("PartyDao:getAllUnsynced", err)
              }
          });
        } else {
          return resolve([]);
        }
      } catch (err) {
        SentryUtilites.setLog("PartyDao:getAllUnsynced", err)
        return resolve([])
      }
    })
  }
  updateSyncStamp(data: Party): Promise<Party> {
    return new Promise(async (resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?._localUUID) {
          this.ngxIndexedDBService
          .getByIndex(IParty.SCHEMA_NAME, '_localUUID', data?._localUUID)
          .subscribe((dbEl: Party) => {
            if(dbEl?._localUUID) {
                dbEl.syncStamp = data?.syncStamp || 0;
                dbEl._serverIdRef = data?._serverIdRef;
                this.ngxIndexedDBService
                  .update(IParty.SCHEMA_NAME, dbEl)
                  .subscribe((updatedEl: Party) => {
                    return resolve(updatedEl);
                  },
                  err => {
                    console.error(err);
                    if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                      this.router.navigate(['idbx-error']);
                    } else if (typeof err?.target?.error == 'object') {
                      SentryUtilites.setLog("PartyDao:updateSyncStamp", err?.target?.error)
                    } else {
                      SentryUtilites.setLog("PartyDao:updateSyncStamp", err)
                    }
                  });
              } else {
                return resolve(null);
              }
            },
            err => {
              console.error(err);
              if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                this.router.navigate(['idbx-error']);
              } else if (typeof err?.target?.error == 'object') {
                SentryUtilites.setLog("PartyDao:updateSyncStamp", err?.target?.error)
              } else {
                SentryUtilites.setLog("PartyDao:updateSyncStamp", err)
              }
            })
        } else {
          return resolve(null);
        }
      } catch (err) {
        SentryUtilites.setLog("PartyDao:updateSyncStamp", err)
        return resolve(null)
      }
    })
  }

  updateLastSaleStamp(party: Party, updatedStamp: number): Promise<Party> {
    return new Promise(async (resolve, reject) => {
      try {
        let partyUUID: string = party?._localUUID;
        let dbParty = await this.getByUUID(partyUUID);
        if(dbParty?._localUUID)  {
          dbParty.lastSaleStamp = updatedStamp;
          dbParty.updatedStamp = +new Date();
          dbParty.userUpdatedStamp =dbParty?.updatedStamp;
          dbParty.lastModifiedBy = party?.lastModifiedBy;
          dbParty.lastModifiedByName = party?.lastModifiedByName;
          let updatedParty = await this.update(dbParty);
          return resolve(updatedParty)
        }
        return resolve(null);
      } catch (err) {
        SentryUtilites.setLog("PartyDao:updateLastSaleStamp", err)
        return resolve(null);
      }

    })
  }

  updateLastEstimateStamp(party: Party, updatedStamp: number): Promise<Party> {
    return new Promise(async (resolve, reject) => {
      try {
        let partyUUID: string = party?._localUUID;
        let dbParty = await this.getByUUID(partyUUID);
        if(dbParty?._localUUID) {
          dbParty.lastSaleStamp = updatedStamp;
          dbParty.updatedStamp = +new Date();
          dbParty.userUpdatedStamp = dbParty?.updatedStamp;
          dbParty.lastModifiedBy = party?.lastModifiedBy;
          dbParty.lastModifiedByName = party?.lastModifiedByName;
          let updatedParty = await this.update(dbParty);
          return resolve(updatedParty)
        }
        return resolve(null);
      } catch (err) {
        SentryUtilites.setLog("PartyDao:updateLastEstimateStamp", err)
        return resolve(null)
      }

    })
  }

  updateCredit(party: Party, creditIncrementBy: number): Promise<Party> {
    return new Promise(async (resolve, reject) => {
      try {
        let partyUUID: string = party?._localUUID;
        let dbParty = await this.getByUUID(partyUUID)
        let currentCredit = dbParty?.credit || 0.0;
        if(currentCredit) {
          dbParty.credit = currentCredit + Number(creditIncrementBy)
          dbParty.updatedStamp = +new Date();
          dbParty.userUpdatedStamp = dbParty?.updatedStamp;
          dbParty.lastModifiedBy = party?.lastModifiedBy;
          dbParty.lastModifiedByName = party?.lastModifiedByName;
          let updatedParty = await this.update(dbParty);
          return resolve(updatedParty)
        } else {
          return resolve(null);
        }
      } catch (err) {
        SentryUtilites.setLog("PartyDao:updateCredit", err)
        return resolve(null);
      }

    });

  }

  async addCashSaleParty(profileId: string) {
    try {
      let party = new Party();
      party.name = 'Cash Sale';
      party.type = 'customer';
      party.sendAlerts = false;
      party.profileId = profileId;
      party.isCashSaleParty = true;
      return await this.save(party);
    } catch (error) {
      SentryUtilites.setLog("PartyDao:addCashSaleParty", error)
      return null;
    }
  }

}
