import { AllDataService } from './../../services/all-data.service';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Item } from '../../models/Item.model';
import { BarcodeTemplate } from './barCodetemplate';
import { Utility } from '../../utils/utility';
import JsBarcode from 'jsbarcode';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-barcode-generator',
  templateUrl: './barcode-generator.component.html',
  styleUrls: ['./barcode-generator.component.scss'],
})
export class BarcodeGeneratorComponent implements OnInit {

  @Input() item: Item = null;
  @Input() barcode: string = null;
  @Input() companyName: string = null;

  @Output() barcodeChange = new EventEmitter<string>();

  @ViewChild('printBodyDivRef') printBodyDivRef: ElementRef;
  @ViewChild('barcodeSvg') barcodeSvgRef: ElementRef;

  getHeaderColorClass = Utility.getHeaderColorClass;

  isModalOpen = false;

  constructor(
    private allDataService: AllDataService,
  ) { }

  ngOnInit() {}

  async openModal(value: boolean = true) {
    try {
      await Utility.wait(100);
      this.isModalOpen = value;
      if(this.isModalOpen) {
        this.generateValueToBeConverted();
      }
    } catch (error) {
      SentryUtilites.setLog("BarcodeGeneratorComponent:openModal", error)
    }
  }

  async checkBarCode(barcode: string){
    try {
      let allItems = await this.allDataService.itemService.getAllByPromise() || [];
      let index = allItems.findIndex(item => item?.barcode == barcode);
      return index==-1 ? true : false;
    } catch (error) {
      SentryUtilites.setLog("BarcodeGeneratorComponent:checkBarCode", error)
      return false;
    }
  }


  async generateValueToBeConverted() {
    try {
      if (!this.barcode){
        this.barcode = (await this.generateRandomBarCodeNumber()) as any;
        this.barcodeChange.emit(this.barcode);
      }
      this.barCodeRenderer();
    } catch (error) {
      SentryUtilites.setLog("BarcodeGeneratorComponent:generateValueToBeConverted", error)
    }
  }

  async generateRandomBarCodeNumber(): Promise<number>{
    try {
      const randomNumber = Utility.randomNumber(8);
      return (await this.checkBarCode(randomNumber.toString())) ? randomNumber : this.generateRandomBarCodeNumber();
    } catch (error) {
      SentryUtilites.setLog("BarcodeGeneratorComponent:generateRandomBarCodeNumber", error)
      return 0;
    }
  }

  barCodeRenderer(){
    if(this.barcodeSvgRef?.nativeElement) {
      try{
        JsBarcode(this.barcodeSvgRef?.nativeElement, String(this.barcode), {
          format: 'CODE128',
          lineColor: 'black',
          width: 1.5,
          height: 25,
          fontSize: 10,
          displayValue: true
        });
      }catch(err){
        SentryUtilites.setLog("BarcodeGeneratorComponent:barCodeRenderer", err)
      }
    }else {
      setTimeout(() => {
        this.barCodeRenderer();
      }, 500);
    }
  }

  print() {
    try {
      this.openModal(false);
      const template = new BarcodeTemplate({
        itemName: this.item?.itemName,
        companyName: this.companyName,
        mrp: this.item?.mrp,
        salePrice: this.item?.sellPrice,
        barcode: this.barcode,
      });
      this.printBodyDivRef.nativeElement.innerHTML = template.templateBody();
      this.printBodyDivRef.nativeElement.classList.add('show');
      let domContainer: any = document.getElementById('div-print-area');
      let dvApp: any = document.getElementById('dv-app');
      let itemBulkEditModal: any = document.getElementById('item-bulk-edit-modal');
      if(itemBulkEditModal) {
        itemBulkEditModal.style.visibility="hidden";
      }
      if(dvApp) {
        dvApp.style.display="none";
      }
      if(domContainer) {
        domContainer.appendChild(this.printBodyDivRef.nativeElement.cloneNode(true));
        domContainer?.classList?.add('active');
        domContainer.style.width = "100%";
      }
      let documentTitle=document.title;
      document.title=`Barcode_${this.item?.itemName}`;
      setTimeout(() => {
        window?.print();
        if(domContainer) {
          domContainer.innerHTML = '';
          domContainer?.classList?.remove('active');
          domContainer.style.width = "100%";
        }
        if(dvApp) {
          dvApp.style.display="block";
        }
        if(itemBulkEditModal) {
          itemBulkEditModal.style.visibility="inherit";
        }
        this.printBodyDivRef.nativeElement.innerHTML='';
        this.printBodyDivRef.nativeElement.classList.remove('show');
        document.title=documentTitle;
      }, 1000);
    } catch (error) {
      SentryUtilites.setLog("BarcodeGeneratorComponent:print", error)
    }
  }

}
