import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Profile } from '../../models/Profile.model';
import { AllDataService } from '../../services/all-data.service';
import { Utility } from '../../utils/utility';
import { PinVerificationComponent } from '../pin-verification/pin-verification.component';
import { Section, Setting } from '../settings/settings.component';
import { platform } from 'os';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-item-settings',
  templateUrl: './item-settings.component.html',
  styleUrls: ['./item-settings.component.scss'],
})
export class ItemSettingsComponent implements OnInit {

  @Output('onSave') onSave = new EventEmitter<boolean>();

  isModalOpen = false;
  isPinGenerationModalOpen = false;
  falseCodeExecuted = false;

  viewSectionIds: number[] = [];
  viewSettingKeys: string[] = [];

  sections: Section[] = [
    {
      id: 1,
      header: '1. Display Item Fields',
      settings: [
        {
          title: '1.1 Enable MRP',
          description: null,
          key: 'itSetEnableMRP',
          value: false,
          sharedPreference: false,
          dataType: 'boolean',
          inputType: 'Toggle',
        },{
          title: '1.2 Enable Tax Exclusive of Sale Price',
          description: null,
          key: 'itSetEnableTaxExclusiveSellPrice',
          value: false,
          sharedPreference: false,
          dataType: 'boolean',
          inputType: 'Toggle',
        },{
          title: '1.3 Enable Cess Tax',
          description: 'If you have products which attracts CESS TAX you can enable it here',
          key: 'itSetEnableCessTax',
          value: false,
          sharedPreference: false,
          dataType: 'boolean',
          inputType: 'Toggle',
        },{
          title: '1.4 Bar Code Reader',
          description: null,
          key: 'itSetEnableBarcode',
          value: false,
          sharedPreference: false,
          dataType: 'boolean',
          inputType: 'Toggle',
        },
        // {
        //   title: '1.5 Item Description',
        //   description: null,
        //   key: 'itSetEnableItemDescription',
        //   value: false,
        //   sharedPreference: false,
        //   dataType: 'boolean',
        //   inputType: 'Toggle',
        // },
        {
          title: '1.6 Item Code / SKU',
          description: null,
          key: 'itSetEnableItemCode',
          value: false,
          sharedPreference: false,
          dataType: 'boolean',
          inputType: 'Toggle',
        },{
          title: '1.7 Low Stock Alert',
          description: null,
          key: 'itSetEnableLowStockAlert',
          value: false,
          sharedPreference: false,
          dataType: 'boolean',
          inputType: 'Toggle',
        },{
          title: '1.8 Product Storage Location',
          description: null,
          key: 'itSetEnableStotageLocation',
          value: false,
          sharedPreference: false,
          dataType: 'boolean',
          inputType: 'Toggle',
        },{
          title: '1.9 Bulk Purchase Unit',
          description: `If you purchase products different unit than your sale unit, you can mention conversion factor Purchase unit and sale unit. Example You purchase 'Item A' in Dozen and Sale 'Item A' in Pieces. You can mention bulk purchase unit = Dozen, and convetion ratio as 1 Dozen = 12 Pieces`,
          key: 'itSetEnableBulkUnit',
          value: false,
          sharedPreference: false,
          dataType: 'boolean',
          inputType: 'Toggle',
        },{
          title: '1.10 Item Category',
          description: null,
          key: 'itSetEnableCategory',
          value: false,
          sharedPreference: false,
          dataType: 'boolean',
          inputType: 'Toggle',
        },{
          title: '1.11 Item Images',
          description: null,
          key: 'itSetEnableImages',
          value: false,
          sharedPreference: false,
          dataType: 'boolean',
          inputType: 'Toggle',
        },{
          title: '1.12 Hide Items for Online Sale',
          description: null,
          key: 'itSetEnableOnlineDukkanItem',
          value: false,
          sharedPreference: false,
          dataType: 'boolean',
          inputType: 'Toggle',
        },
        // {
        //   title: '1.13 Wholesale Rate',
        //   description: null,
        //   key: 'itSetEnableWholesaleRate',
        //   value: false,
        //   sharedPreference: false,
        //   dataType: 'boolean',
        //   inputType: 'Toggle',
        // },
        // {
        //   title: '1.14 Enable Raw Material',
        //   description: null,
        //   key: 'itSetEnableIngredient',
        //   value: false,
        //   sharedPreference: false,
        //   dataType: 'boolean',
        //   inputType: 'Toggle',
        // },
      ],
    }
  ];

  checkUserPlatform;

  constructor(
    private allDataService: AllDataService,
    private toastController: ToastController,
  ) { }

  ngOnInit() { 
    this.checkUserPlatform  = window?.navigator;
  }

  openSettingsModal(isOpen: boolean = true) {
    try {
      this.isModalOpen = isOpen;
      if(this.isModalOpen) {
        this.populateSettings();
        setTimeout(() => {
          this.falseCodeExecuted = true;
        }, 2000);
      }else {
        setTimeout(() => {
          this.viewSectionIds = [];
          this.viewSettingKeys = [];
        }, 500);
      }
    } catch (error) {
      SentryUtilites.setLog("ItemSettingsComponent:openSettingsModal", error)
    }
  }

  async populateSettings() {
    try {
      const profile = await this.allDataService.profileService.getCurrentProfile();
      if (profile) {
        this.sections?.forEach(section => {
          section.settings.map(setting => {
            this.applySetting(setting,profile);
            setting?.settings?.map(subSetting => {
              this.applySetting(subSetting,profile);
            });
          });
        });
      }
    } catch (error) {
      SentryUtilites.setLog("ItemSettingsComponent:populateSettings", error)
    }
  }

  applySetting(setting: Setting, profile: Profile) {
    try {
      if(setting?.sharedPreference) {
        setting.value = Utility.getFromLocalStorage(setting.key);
      }else if(
        profile[setting.key] != null
        && profile[setting.key] != undefined
      ) {
        setting.value = profile[setting.key];
      }
    } catch (error) {
      SentryUtilites.setLog("ItemSettingsComponent:applySetting", error)
    }
  }

  async onSettingUpdate(setting: Setting, event: any) {
    try {
      let value;
      if(setting.inputType === 'Toggle') {
        value = event?.detail?.checked;
      }else if(setting.inputType === 'InputBox') {
        if(setting.dataType === 'number') {
          value = Number(event?.detail?.value);
        }
        if(setting.dataType === 'string') {
          value = event?.detail?.value;
        }
      }else if(setting.inputType === 'Segment') {
        value = event?.detail?.value;
      }else if(setting.inputType === 'TextArea') {
        value = event?.detail?.value;
      }
      const profile = await this.allDataService.profileService.getCurrentProfile();
      if(profile?._localUUID) {
        profile[setting.key] = value;
        await this.allDataService.profileService.update(profile);
        this.allDataService.listForceReloadSubs.next('profile-list');
      }
    } catch (error) {
      SentryUtilites.setLog("ItemSettingsComponent:onSettingUpdate", error)
    }
  }

  async onSharedPreferenceUpdate(setting: Setting, event: any) {
    try {
      let value;
      if(setting.inputType === 'Toggle') {
        value = event?.detail?.checked;
      }else if(setting.inputType === 'InputBox') {
        if(setting.dataType === 'number') {
          value = Number(event?.detail?.value);
        }
        if(setting.dataType === 'string') {
          value = event?.detail?.value;
        }
      }else if(setting.inputType === 'Segment') {
        value = event?.detail?.value;
      }else if(setting.inputType === 'TextArea') {
        value = event?.detail?.value;
      }
  
      Utility.setToLocalStorage(setting?.key,value);
    } catch (error) {
      SentryUtilites.setLog("ItemSettingsComponent:onSharedPreferenceUpdate", error)
    }
  }

  getInputType(setting: Setting) {
    try {
      if(setting.dataType === 'string') {
        return 'text';
      }
      if(setting.dataType === 'number') {
        return 'number';
      }
    } catch (error) {
      SentryUtilites.setLog("ItemSettingsComponent:getInputType", error)
      return '';
    }
  }

  async save() {
    try {
      const toast = await this.toastController.create({
        header: 'Success',
        duration: 2000,
        position: 'top',
        mode: 'ios',
        color: 'success',
      });
      await toast.present();
      this.onSave.emit(true);
      this.openSettingsModal(false);
    } catch (error) {
      SentryUtilites.setLog("ItemSettingsComponent:save", error)
    }
  }

}
