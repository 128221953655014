import { commonTempData } from "./commonTempData";
import { MoneyOutBillPrint } from "src/models/MoneyOutBillPrint.model";

export class Temp17MoneyOut{

    constructor(
      private data: MoneyOutBillPrint,
      ){
      }

    totalSaving = 0;

    async main(){
      return `
      <style>
      ${commonTempData.invoiceHtmlTemplateCss()}
      .three-inch-holder *{
        margin:0;
        padding:0;
      }
      .three-inch-holder b{
        color:#000;
      }
      .three-inch-holder .text-sm{
        font-size:10px;
      }
      .text-3i-h1{
        font-size:18px;
        font-weight:700;
        word-break:break-word;
      }
      .text-3i-h2{
        font-size:16px;
        word-break:break-all;
      }
      .text-3i-h3{
        font-size:14px;
        word-break:break-all;
      }
      .text-3i-p{
        font-size:11px;
        word-break:break-all;
      }
      </style>
      <span class="invoice-template-main">
        <div 
          class="container-new arial three-inch-holder"
          style="width:70mm;background: white;padding: 2mm;padding-bottom: 70px;visibility: visible;-webkit-print-color-adjust: exact !important;color-adjust: exact !important;">
          ${this.header()}
          ${await this.templateBody()}
          ${this.footer()}
        </div>
      </span>
      `
    }

    header(){
      return `
        ${this.sellerDetails()}
        ${this.invoiceTitle()}`;

    }

    footer(){
      let domain='';
      let includeDukanUrl=0;
      try{
        includeDukanUrl=this.data?.settings?.include3InchOnlineDukanLink ? 1 : 0
      }catch(err){

      }
      if(
         this.data?.onlineDukan?.domain!=""){
          domain=this.data?.onlineDukan?.domain;
        }
      return `<div style="text-align:center">
        <p class="text-3i-h3" style="text-align: justify;text-align-last: center;word-break:break-word">${(this.data?.extra?.thermalTncTemplate || '').replace(/\n/g,'<br/>')}</p>
        ${domain?.length>0 && includeDukanUrl?`
          <p class="text-3i-h3">Now Order Online @</p>
          <p class="text-3i-h3">https://${domain}.ezo.digital</p>
        `:''}
        
      </div>
      <div class="row" style="margin-top:20px;"><hr/></div>`;
      
    }


    async templateBody(){
      let html='';
          html +=`
          
          <div class="col-xs-12" style="text-align:center">
          <hr/>
            <b  class="text-3i-h2">
              Paid : ${commonTempData.unitAdjuster(this.data?.bill?.totalAmount)}
            </b>
          <hr/>
          </div>
          
          `
          html+=`
        <div class="row">
        </div>
        <div class="row">
          <hr/>
          <div class="col-xs-12 text-3i-h3" style="text-align:right">
          </div>
          `
        let modeOfPayment =  this.data?.bill?.txnMode || '';
        let txnNo =  this.data?.bill?.txnRef || '';
    
          if(modeOfPayment){
            html += `<div class="col-xs-9 text-3i-h3" style="text-align:right">Mode</div><div class="col-xs-3 text-3i-h3" style="text-align:right">${modeOfPayment}</div>`
            if(txnNo){
              html += `<div class="col-xs-12 text-3i-h3" style="text-align:right">Txn No : ${txnNo}</div>`;

            }
          }

        html+=`
        </div>
        <div class="row">
          <hr/>
        </div>
      `

      if(this.data?.qrPayLink){
        let qrCodeBase64 = await commonTempData.generateQR(this.data?.qrPayLink);
  
        html += `
        <div style="width: 100%; margin: auto;" class="m-l-neg-10 m-l-neg-2">
          <img style="width: 100%;" src="${qrCodeBase64}">
        </div>`;
      }
      
      return html;
    }

    commonTwoRowDetails(title,value){
      return `
          <div class="col-xs-9" style="text-align:right">${title}</div>
          <div class="col-xs-3" style="text-align:right">${value}</div>
      `
    }

    commonTaxAndAmtDetails(title, value){

      return `
          <div class="col-xs-6"></div>
          <div class="col-xs-3 text-3i-h3" style="text-align:right">${title}</div>
          <div class="col-xs-3 text-3i-h3" style="text-align:right">${value}</div>
      `
    }

    sellerDetails(){
        let html = '';
        if(this.data?.profile?.logoLink){
          html += `
          <div style="display: flow-root;width: 100%;">
            <img src="${this.data?.profile?.logoLink}" style="display: block;margin:auto;width:100px;height: 100px; max-width: none !important;">
          </div>
          `
        }
        let sellerProfileData = this.data?.profile;
        html+=`<div style="text-align:center">
          <h3 class="text-3i-h1">${this.data?.profile?.legalName ||''}</h3>
          <p class="text-3i-h3">${sellerProfileData?.addressLine1 || ''},${sellerProfileData?.addressCity || ''}${sellerProfileData?.addressProvience?', '+sellerProfileData?.addressProvience:''}${sellerProfileData?.addressPostalCode?', '+sellerProfileData?.addressPostalCode:''}</p>
          <p class="text-3i-h3">${(this.data?.profile?.contactPersonPhone && this.data?.profile?.contactPersonPhone!='0')? 'Phone: '+this.data?.profile?.contactPersonPhone:''}</p>
          <p class="text-3i-h3">${(this.data?.profile?.contactPersonEmail)? 'Email: '+this.data?.profile?.contactPersonEmail:''}</p>
          <p class="text-3i-h3" style="word-break:break-word">${this.data?.profile?.gstin?'GST Number : '+this.data?.profile?.gstin:''}</p>
          <p class="text-3i-h3" style="word-break:break-word">${this.data?.profile?.fssaiNumber?'FSSAI No. : '+this.data?.profile?.fssaiNumber:''}</p>
          <p class="text-3i-h3" style="word-break:break-word">${this.data?.profile?.licenseNumber?'License No. : '+this.data?.profile?.licenseNumber:''}</p>
          <p class="text-3i-h3" style="word-break:break-word">
            ${
              this.data?.profile?.additionalInputFieldTitle1 
              && ((this.data?.profile?.additionalInputFieldTitle1 +"").trim()).length>0 
              && (this.data?.profile?.additionalInputFieldValue1)
              && ((this.data?.profile?.additionalInputFieldValue1 + "").trim()).length>0 ? `${this.data?.profile?.additionalInputFieldTitle1}:</b> ${this.data?.profile?.additionalInputFieldValue1}`:''
            }
          </p>
          <p class="text-3i-h3" style="word-break:break-word">
            ${
              this.data?.profile?.additionalInputFieldTitle2 
              && ((this.data?.profile?.additionalInputFieldTitle2 +"").trim()).length>0 
              && (this.data?.profile?.additionalInputFieldValue2)
              && ((this.data?.profile?.additionalInputFieldValue2 + "").trim()).length>0 ? `${this.data?.profile?.additionalInputFieldTitle2}:</b> ${this.data?.profile?.additionalInputFieldValue2}`:''
            }
          </p>
          <p class="text-3i-h3" style="word-break:break-word">
            ${
              this.data?.profile?.additionalInputFieldTitle3 
              && ((this.data?.profile?.additionalInputFieldTitle3 +"").trim()).length>0 
              && (this.data?.profile?.additionalInputFieldValue3)
              && ((this.data?.profile?.additionalInputFieldValue3 + "").trim()).length>0 ? `${this.data?.profile?.additionalInputFieldTitle3}:</b> ${this.data?.profile?.additionalInputFieldValue3}`:''
            }
          </p>
          <p class="text-3i-h3" style="word-break:break-word">
            ${
              this.data?.profile?.additionalInputFieldTitle4 
              && ((this.data?.profile?.additionalInputFieldTitle4 +"").trim()).length>0 
              && (this.data?.profile?.additionalInputFieldValue4)
              && ((this.data?.profile?.additionalInputFieldValue4 + "").trim()).length>0 ? `${this.data?.profile?.additionalInputFieldTitle4}:</b> ${this.data?.profile?.additionalInputFieldValue4}`:''
            }
          </p>
          <p class="text-3i-h3" style="word-break:break-word">
            ${
              this.data?.profile?.additionalInputFieldTitle5 
              && ((this.data?.profile?.additionalInputFieldTitle5 +"").trim()).length>0 
              && (this.data?.profile?.additionalInputFieldValue5)
              && ((this.data?.profile?.additionalInputFieldValue5 + "").trim()).length>0 ? `${this.data?.profile?.additionalInputFieldTitle5}:</b> ${this.data?.profile?.additionalInputFieldValue5}`:''
            }
          </p>
        </div>`

        return html;

    }

    invoiceTitle(){
      let party = this.data?.bill?.partyData?.profileData ||null;
      let isBillDateDiff=commonTempData.isBillDateDiff(this.data?.bill?.createdStamp,this.data?.bill?.billDateStamp);
      let html = `
        <div class="row">
          <hr/>
          <div class="col-xs-12 text-3i-h2">
           <b>Receipt No</b>: ${this.data?.bill?.billNo ||''}
          </div>
          <div class="col-xs-12 text-3i-h2">
            
            <b>Created On</b>: ${this.data?.bill?.createdStamp?commonTempData.dateToDDMMYYYHHMMAP(this.data?.bill?.createdStamp):''}
            ${isBillDateDiff? `
            <br/><b> Date</b>: ${commonTempData.dateToDDMMYY(this.data?.bill?.billDateStamp)}
            `:''}
          </div>
          ${this.data?.settings?.iSetPrintTimeOnBill?`
          <div class="row text-3i-p">
             
          </div>
          `:''}
        </div>
        
        <div class="row">
          <hr/>
          <div class="col-xs-12 text-3i-h2">
            <b>Bill To</b> : ${party?.contactPersonName}${party?.contactPersonPhone ? ' <hr/> '+party?.contactPersonPhone : ''}
            ${this.data?.bill?.isPartyDeleted ? "<hr/><p class='text-danger'>Party Has Been Deleted</p>" : "" }
            ${party?.gstNumber ? `<hr/>`+party?.gstNumber+`<hr/>` :''}
          </div>
          
          `;

          if(party?.addressLine1){
            html+=`<div class="col-xs-12 text-3i-h2">
            Address : ${party?.addressLine1 ? party?.addressLine1 : ''} ${party?.addressState ? ', ' + party?.addressState : ''}${party?.addressPincode ? ', ' + party?.addressPincode : ''}
            </div>`
          }
  
      html += `
            
        </div>
        <div class="row">
        <hr/>
        </div>
      `;
      
      return html;
    }


    commonDateFormat(dateName, timeStamp){
      return `
        <div
          style="display: flow-root;font-weight: bold;color: #000;width: 50%;float: left;"
          class="f-14 f-b m-5 text-3i-h2">
            ${dateName} :
          </div>

          <div
          style="display: flow-root;color: #000;width: 50%;float: left;text-align: right;"
          class="f-14 m-5 text-3i-h2">
             ${commonTempData.dateToDDMMYYY(timeStamp)}
        </div>
      `
    }

    commonDateFormat2(dateName, timeStamp){
      return `
        <div
          style="display: flow-root;font-weight: bold;color: #000;width: 50%;float: left;"
          class="f-14 f-b m-5 text-3i-h2">
            ${dateName} :
          </div>

          <div
          style="display: flow-root;color: #000;width: 50%;float: left;text-align: right;"
          class="f-14 m-5 text-3i-h2">
             ${commonTempData.dateToDDMMYY(timeStamp)}
        </div>
      `
    }


}

