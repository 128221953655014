import { NgxIndexedDBService } from "ngx-indexed-db";
import { IDataRepo } from "../../../interface/IDataRepo.interface";
import { Expense, IExpense } from "../../models/Expense.model";
import { MoneyOut } from "../../models/MoneyOut.model";
import { Utility } from "src/app/utils/utility";
import { Router } from "@angular/router";
import { SentryUtilites } from "src/app/utils/sentryUtilites";

export class ExpenseDao implements IDataRepo<Expense>{
  private static _instance: ExpenseDao;

  public static getInstance(
    ngxIndexedDBService: NgxIndexedDBService,
    router: Router,
  ) {
    if (!this._instance) {
      this._instance = new ExpenseDao(ngxIndexedDBService, router)
    }
    return this._instance;
  }

  constructor(
    ngxIndexedDBService: NgxIndexedDBService,
    router: Router,
    ) {
    this.ngxIndexedDBService = ngxIndexedDBService;
    this.router = router;
  }

  ngxIndexedDBService: NgxIndexedDBService;
  router: Router;

  save(data: Expense): Promise<Expense> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && Utility.isTruthy(data)) {
          let timeStamp = +new Date();
          data.createdStamp = timeStamp
          data.updatedStamp = timeStamp
          data.userUpdatedStamp = timeStamp
          data.deletedStamp = 0
          data.syncStamp = 0
  
  
          this.ngxIndexedDBService
            .add(IExpense.SCHEMA_NAME, data)
            .subscribe((savedExpense: Expense) => {
              return resolve(savedExpense);
            },
            err => {
              console.error(err);
              if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                this.router.navigate(['idbx-error']);
              } else if (typeof err?.target?.error == 'object') {
                SentryUtilites.setLog("ExpenseDao:save", err?.target?.error)
              } else {
                SentryUtilites.setLog("ExpenseDao:save", err)
              }
            });
        } else {
          return resolve(null);
        }
      } catch (err) {
        SentryUtilites.setLog("ExpenseDao:save", err)
        return resolve(null);
      }
    });
  }

  saveDb(data: Expense): Promise<Expense> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && Utility.isTruthy(data)) {
          this.ngxIndexedDBService
            .add(IExpense.SCHEMA_NAME, data)
            .subscribe((savedRecord: Expense) => {
              return resolve(savedRecord);
            },
            err => {
              console.error(err);
              if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                this.router.navigate(['idbx-error']);
              } else if (typeof err?.target?.error == 'object') {
                SentryUtilites.setLog("ExpenseDao:saveDb", err?.target?.error)
              } else {
                SentryUtilites.setLog("ExpenseDao:saveDb", err)
              }
            });
        } else {
          return resolve(null);
        }
      } catch (error) {
        SentryUtilites.setLog("ExpenseDao:saveDb", error)
        return resolve(null);
      }
    })
  }

  update(data: Expense): Promise<Expense> {
    return new Promise(async (resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?._localUUID) {
          let oldData = await this.getByUUID(data?._localUUID);
          if(oldData?._localUUID) {
            data._localId = oldData?._localId;
            let timeStamp = +new Date();
            data.updatedStamp = timeStamp
            data.userUpdatedStamp = timeStamp
            this.ngxIndexedDBService
              .update(IExpense.SCHEMA_NAME, data)
              .subscribe((updatedexpense: Expense) => {
                return resolve(updatedexpense);
              },
              err => {
                console.error(err);
                if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                  this.router.navigate(['idbx-error']);
                } else if (typeof err?.target?.error == 'object') {
                  SentryUtilites.setLog("ExpenseDao:update", err?.target?.error)
                } else {
                  SentryUtilites.setLog("ExpenseDao:update", err)
                }
              });
          } else {
            return resolve(null);
          }
        } else {
          return resolve(null);
        }
      } catch (err) {
        SentryUtilites.setLog("ExpenseDao:update", err)
        return resolve(null);
      }
    })
  }

  updateDb(data: Expense): Promise<Expense> {
    return new Promise(async (resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?._localUUID) {
          let oldData = await this.getByUUID(data?._localUUID);
          if(oldData?._localUUID) {
            data._localId = oldData?._localId;
            this.ngxIndexedDBService
              .update(IExpense.SCHEMA_NAME, data)
              .subscribe((updatedRecord: Expense) => {
                return resolve(updatedRecord);
              },
              err => {
                console.error(err);
                if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                  this.router.navigate(['idbx-error']);
                } else if (typeof err?.target?.error == 'object') {
                  SentryUtilites.setLog("ExpenseDao:updateDb", err?.target?.error)
                } else {
                  SentryUtilites.setLog("ExpenseDao:updateDb", err)
                }
              });
          } else {
            return resolve(null);
          }
        } else {
          return resolve(null);
        }
      } catch (err) {
        SentryUtilites.setLog("ExpenseDao:updateDb", err)
        return resolve(null);
      }
    })
  }

  bulkPut(data: Expense[]): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?.length) {
          this.ngxIndexedDBService
            .bulkPut(IExpense.SCHEMA_NAME, data)
            .subscribe((data) => {
              resolve(true);
            },
            err => {
              console.error(err);
              if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                this.router.navigate(['idbx-error']);
              } else if (typeof err?.target?.error == 'object') {
                SentryUtilites.setLog("ExpenseDao:bulkPut", err?.target?.error)
              } else {
                SentryUtilites.setLog("ExpenseDao:bulkPut", err)
              }
            })
        } else {
          return resolve(false);
        }
      } catch (error) {
        SentryUtilites.setLog("ExpenseDao:bulkPut", error)
        return resolve(false);
      }
    })
  }

  delete(data: Expense): Promise<Expense> {
    return new Promise(async (resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?._localUUID) {
          let oldData = await this.getByUUID(data?._localUUID);
          if(oldData?._localUUID) {
            data._localId = oldData?._localId;
            let timeStamp = +new Date();
            data.updatedStamp = timeStamp;
            data.userUpdatedStamp = timeStamp;
            data.deletedStamp = timeStamp;
            this.ngxIndexedDBService
              .update(IExpense.SCHEMA_NAME, data)
              .subscribe((updatedexpense: Expense) => {
                return resolve(updatedexpense);
              },
              err => {
                console.error(err);
                if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                  this.router.navigate(['idbx-error']);
                } else if (typeof err?.target?.error == 'object') {
                  SentryUtilites.setLog("ExpenseDao:delete", err?.target?.error)
                } else {
                  SentryUtilites.setLog("ExpenseDao:delete", err)
                }
              });
          } else {
            return resolve(null);
          }
        } else {
          return resolve(null);
        }
      } catch (err) {
        SentryUtilites.setLog("ExpenseDao:delete", err)
        return resolve(null);
      }
    });
  }
  getById(id: number): Promise<Expense> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && id) {
          this.ngxIndexedDBService
            .getByKey(IExpense.SCHEMA_NAME, id)
            .subscribe((expense: Expense) => {
              return resolve(expense);
            },
            err => {
              console.error(err);
              if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                this.router.navigate(['idbx-error']);
              } else if (typeof err?.target?.error == 'object') {
                SentryUtilites.setLog("ExpenseDao:getById", err?.target?.error)
              } else {
                SentryUtilites.setLog("ExpenseDao:getById", err)
              }
            });
        } else {
          return resolve(null);
        }
      } catch (err) {
        SentryUtilites.setLog("ExpenseDao:getById", err)
        return resolve(null)
      }
    })
  }
  getByUUID(uuid: string): Promise<Expense> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && uuid) {
          this.ngxIndexedDBService
            .getByIndex(IExpense.SCHEMA_NAME, '_localUUID', uuid)
            .subscribe((expense: Expense) => {
              return resolve(expense);
            },
            err => {
              console.error(err);
              if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                this.router.navigate(['idbx-error']);
              } else if (typeof err?.target?.error == 'object') {
                SentryUtilites.setLog("ExpenseDao:getByUUID", err?.target?.error)
              } else {
                SentryUtilites.setLog("ExpenseDao:getByUUID", err)
              }
            });
        } else {
          return resolve(null);
        }
      } catch (err) {
        SentryUtilites.setLog("ExpenseDao:getByUUID", err)
        return resolve(null)
      }
    })
  }
  getAll(): Promise<Expense[]> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token'))) {
          this.ngxIndexedDBService.getAll(IExpense.SCHEMA_NAME).subscribe((docs: Expense[]) => {
            docs = docs?.filter(x => !x?.deletedStamp);
            docs?.sort((a, b) => b?.createdStamp - a?.createdStamp);
            return resolve(docs)
          },
          err => {
            console.error(err);
            if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
              this.router.navigate(['idbx-error']);
            } else if (typeof err?.target?.error == 'object') {
              SentryUtilites.setLog("ExpenseDao:getAll", err?.target?.error)
            } else {
              SentryUtilites.setLog("ExpenseDao:getAll", err)
            }
          });
        } else {
          return resolve([]);
        }
      } catch (err) {
        SentryUtilites.setLog("ExpenseDao:getAll", err)
        return resolve([])
      }
    })
  }
  getAllByProfile(profileId: string): Promise<Expense[]> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && profileId) {
          this.ngxIndexedDBService.getAll(IExpense.SCHEMA_NAME).subscribe((docs: Expense[]) => {
            docs = docs?.filter(x => !x?.deletedStamp && x?.profileId == profileId);
            docs?.sort((a, b) => b?.createdStamp - a?.createdStamp);
            return resolve(docs)
          },
          err => {
            console.error(err);
            if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
              this.router.navigate(['idbx-error']);
            } else if (typeof err?.target?.error == 'object') {
              SentryUtilites.setLog("ExpenseDao:getAllByProfile", err?.target?.error)
            } else {
              SentryUtilites.setLog("ExpenseDao:getAllByProfile", err)
            }
          });
        } else {
          return resolve([]);
        }
      } catch (err) {
        SentryUtilites.setLog("ExpenseDao:getAllByProfile", err)
        return resolve([])
      }
    })
  }

  getAllWithDeletedByProfile(profileId: string): Promise<Expense[]> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && profileId) {
          this.ngxIndexedDBService.getAll(IExpense.SCHEMA_NAME).subscribe((docs: Expense[]) => {
            docs = docs?.filter(x => x?.profileId == profileId);
            docs?.sort((a, b) => b?.createdStamp - a?.createdStamp);
            return resolve(docs)
          },
          err => {
            console.error(err);
            if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
              this.router.navigate(['idbx-error']);
            } else if (typeof err?.target?.error == 'object') {
              SentryUtilites.setLog("ExpenseDao:getAllWithDeletedByProfile", err?.target?.error)
            } else {
              SentryUtilites.setLog("ExpenseDao:getAllWithDeletedByProfile", err)
            }
          });
        } else {
          return resolve([]);
        }
      } catch (err) {
        SentryUtilites.setLog("ExpenseDao:getAllWithDeletedByProfile", err)
        return resolve([])
      }
    })
  }

  /**
   * 
   * @param profileId : provide profile ID
   * @returns : return deleted expenses
   */
  getAllDeletedByProfile(profileId: string): Promise<Expense[]> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && profileId) {
          this.ngxIndexedDBService.getAll(IExpense.SCHEMA_NAME).subscribe((docs: Expense[]) => {
            docs = docs?.filter(x => x?.profileId == profileId && x?.deletedStamp);
            docs?.sort((a, b) => b?.createdStamp - a?.createdStamp);
            return resolve(docs)
          },
          err => {
            console.error(err);
            if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
              this.router.navigate(['idbx-error']);
            } else if (typeof err?.target?.error == 'object') {
              SentryUtilites.setLog("ExpenseDao:getAllDeletedByProfile", err?.target?.error)
            } else {
              SentryUtilites.setLog("ExpenseDao:getAllDeletedByProfile", err)
            }
          });
        } else {
          return resolve([]);
        }
      } catch (err) {
        SentryUtilites.setLog("ExpenseDao:getAllDeletedByProfile", err)
        return resolve([])
      }
    })
  }
  // -----------------------------------------------------

  getAllUnsynced(profileId: string): Promise<Expense[]> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token'))) {
          let unSyncedElements: Expense[] = [];
          this.ngxIndexedDBService.getAll(IExpense.SCHEMA_NAME).subscribe(async (elArr: Expense[]) => {
            for (let i = 0; i < elArr?.length; i++) {
              const el = elArr[i];
              if (el?.updatedStamp > el?.syncStamp || !el?._serverIdRef) {
                unSyncedElements.push(el);
              }
            }
            return resolve(unSyncedElements)
          },
          err => {
            console.error(err);
            if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
              this.router.navigate(['idbx-error']);
            } else if (typeof err?.target?.error == 'object') {
              SentryUtilites.setLog("ExpenseDao:getAllUnsynced", err?.target?.error)
            } else {
              SentryUtilites.setLog("ExpenseDao:getAllUnsynced", err)
            }
          });
        } else {
          return resolve([]);
        }
      } catch (err) {
        SentryUtilites.setLog("ExpenseDao:getAllUnsynced", err)
        return resolve([])
      }
    })
  }
  updateSyncStamp(data: Expense): Promise<Expense> {
    return new Promise(async (resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?._localUUID) {
          this.ngxIndexedDBService
          .getByIndex(IExpense.SCHEMA_NAME, '_localUUID', data._localUUID)
          .subscribe((dbEl: Expense) => {
            if(dbEl) {
                dbEl.syncStamp = data?.syncStamp || 0;
                dbEl._serverIdRef = data?._serverIdRef;
                this.ngxIndexedDBService
                  .update(IExpense.SCHEMA_NAME, dbEl)
                  .subscribe((updatedEl: Expense) => {
                    return resolve(updatedEl);
                  },
                  err => {
                    console.error(err);
                    if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                      this.router.navigate(['idbx-error']);
                    } else if (typeof err?.target?.error == 'object') {
                      SentryUtilites.setLog("ExpenseDao:updateSyncStamp", err?.target?.error)
                    } else {
                      SentryUtilites.setLog("ExpenseDao:updateSyncStamp", err)
                    }
                  });
              } else {
                return resolve(null);
              }
            },
            err => {
              console.error(err);
              if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                this.router.navigate(['idbx-error']);
              } else if (typeof err?.target?.error == 'object') {
                SentryUtilites.setLog("ExpenseDao:updateSyncStamp", err?.target?.error)
              } else {
                SentryUtilites.setLog("ExpenseDao:updateSyncStamp", err)
              }
            })
        } else {
          return resolve(null);
        }
      } catch (err) {
        SentryUtilites.setLog("ExpenseDao:updateSyncStamp", err)
        return resolve(null)
      }
    })
  }

  async linkMoneyOut(expenseUUID: string, moneyOut: MoneyOut): Promise<Boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        let expense = await this.getByUUID(expenseUUID);

        if(expense?._localUUID) {
          expense?.moneyOuts?.push(moneyOut)
          expense.amountPaid = (expense?.amountPaid || 0.0) + (moneyOut?.totalAmount || 0.0);
  
          expense.lastModifiedBy = moneyOut?.lastModifiedBy;
          expense.lastModifiedByName = moneyOut?.lastModifiedByName;
  
          await this.update(expense);
  
          return resolve(true);
        } else {
          return resolve(false);
        }

      } catch (err) {
        SentryUtilites.setLog("ExpenseDao:linkMoneyOut", err)
        return resolve(false);
      }
    })
  }

  async unLinkMoneyOut(expenseUUID: string, moneyOutUUID: string): Promise<Boolean> {
    return new Promise(async (resolve, reject) => {
      try {

        let expense = await this.getByUUID(expenseUUID);

        if(expense?._localUUID) {
          let newMoneyOutList: MoneyOut[] = [];
          let newReceivedAmount: number = 0.0;
  
          expense?.moneyOuts?.forEach(oldMoneyOut => {
            if(oldMoneyOut?._localUUID !== moneyOutUUID) {
              newReceivedAmount += oldMoneyOut?.totalAmount || 0.0;
              newMoneyOutList.push(oldMoneyOut);
            }
          });
  
          expense.moneyOuts = newMoneyOutList;
          expense.amountPaid = newReceivedAmount;
  
          await this.update(expense);
  
          return resolve(true);
        } else {
          return resolve(false);
        }

      } catch (err) {
        SentryUtilites.setLog("ExpenseDao:unLinkMoneyOut", err)
        return resolve(false);
      }
    })
  }

  async upLinkMoneyOut(expenseUUID: string, moneyOut: MoneyOut): Promise<Boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        let expense = await this.getByUUID(expenseUUID);

        if(expense?._localUUID) {
          let newMoneyOutList: MoneyOut[] = [];
          let newReceivedAmount: number = 0.0;
  
          expense?.moneyOuts?.forEach(oldMoneyOut => {
            if(oldMoneyOut?._localUUID === moneyOut?._localUUID) {
              newReceivedAmount += moneyOut?.totalAmount || 0.0;
              newMoneyOutList.push(moneyOut);
            }else {
              newReceivedAmount += oldMoneyOut?.totalAmount || 0.0;
              newMoneyOutList.push(oldMoneyOut);
            }
          });
  
          expense.moneyOuts = newMoneyOutList;
          expense.amountPaid = newReceivedAmount;
  
          expense.lastModifiedBy = moneyOut?.lastModifiedBy;
          expense.lastModifiedByName = moneyOut?.lastModifiedByName;
  
          await this.update(expense);
  
          return resolve(true);
        } else {
          return resolve(false);
        }

      } catch (err) {
        SentryUtilites.setLog("ExpenseDao:upLinkMoneyOut", err)
        return resolve(false);
      }
    })
  }
}
