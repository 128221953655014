import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AllDataService } from '../all-data.service';
import { AuthService } from './auth.service';
import { Validator } from '../../utils/validator';
import { Utility } from 'src/app/utils/utility';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Injectable({
  providedIn: 'root'
})
export class OnlineControlService implements CanActivate {

  constructor() { }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.onlineStatus({alert: true});
  }

  async onlineStatus(options?: { alert?: boolean }) {
    try {
      let isOnline = window?.navigator?.onLine;
      if(options?.alert && !isOnline) {
        alert("Please go online to use this feature.");
      }
      return isOnline;
    } catch (error) {
      SentryUtilites.setLog("OnlineControlService:onlineStatus", error)
      return false;
    }
  }

}
