import { RunningBillHashMap } from './../../../components/party-restaurant-list/party-restaurant-list.component';
import { MonthWisePartyCreditService } from './../../../services/month-wise-party-credit.service';
import { Kot } from '../../../models/Kot.model';
import { ItemRestaurantSelectorComponent } from '../../../components/item-restaurant-selector/item-restaurant-selector.component';
import { MoneyInSelectorComponent } from '../../../components/money-in-selector/money-in-selector.component';
import { Utility } from '../../../utils/utility';
import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PartySelectorComponent } from '../../../components/party-selector/party-selector.component';
import { ItemSelectorComponent } from '../../../components/item-selector/item-selector.component';
import { IonDatetime, IonInput, ToastController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { AllDataService } from '../../../services/all-data.service';
import Party from '../../../models/Party.model';
import { BillItem } from '../../../models/BillItem.model';
import { Sale } from '../../../models/Sale.model';
import { MoneyIn } from '../../../models/MoneyIn.model';
import { BillCalculations } from '../../../utils/BillCalculations';
import { BillType } from '../../../utils/enums/BillType';
import { CalculateBillTask } from '../../../utils/enums/CalculateBillTask';
import { Profile } from '../../../models/Profile.model';
import { Utils } from '../../../utils/utils';
import { SettingsComponent } from '../../../components/settings/settings.component';
import { OrderService } from '../../../services/order.service';
import { Estimate } from 'src/app/models/Estimate.model';
import { PremiumControlService } from '../../../services/auth/premium-control.service';
import { EventService } from '../../../services/event.service';
import { Validator } from '../../../utils/validator';
import { AuthService } from '../../../services/auth/auth.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-sale-form',
  templateUrl: './sale-form.page.html',
  styleUrls: ['./sale-form.page.scss'],
})
export class SaleFormPage implements OnInit, AfterViewInit {

  /*
    Native Element Ref
  */

  @ViewChild('partySelector', { static: false }) partySelector: PartySelectorComponent;
  @ViewChild('partyRestaurantSelector', { static: false }) partyRestaurantSelector: PartySelectorComponent;
  @ViewChild('itemSelector', { static: false }) itemSelector: ItemSelectorComponent;
  @ViewChild('itemRestaurantSelector', { static: false }) itemRestaurantSelector: ItemRestaurantSelectorComponent;
  @ViewChild('moneyInSelector', { static: false }) moneyInSelector: MoneyInSelectorComponent;
  @ViewChild('settingsModal') settingsModal: SettingsComponent;
  @ViewChild('amountReceivedEle') amountReceivedEle: IonInput;
  @ViewChild('paymentIdEle') paymentIdEle: IonInput;
  @ViewChild('kotPrintAudioEle') kotPrintAudioEle: ElementRef;

  getHeaderColorClass = Utility.getHeaderColorClass;
  maxDate = Utility.maxDateUpto50Year();
  states = Utility.states;
  setDateValue = Utility.setDateValue;
  isDisabledState = Utility.isDisabledState;

  /*
   Reactive Form
  */

  form: FormGroup;

  barcodeScannerMode: boolean = false;
  dueNetObj = { ...Utility.dueNetObj };

  dpBillDateValue: string = null;
  dpDueDateValue: string = null;
  dpEWayBillDateValue: string = null;
  dpDeliveryDateValue: string = null;
  minDateTransport: string = Utility.ionDatePickerFormattedString(+new Date().setHours(0, 0, 0, 0));

  showTransportDetailsCard: boolean = false;
  paramDocumentId: string = null;
  orderLocalUUID: string = null;
  estimateLocalUUID: string = null;
  fetchedSale: Sale = null;
  fetchEstimate : Estimate = null;

  amountReceivedChecked: boolean = null;
  isDisableAmountReceived: boolean = false;
  dueDateContentsMounted: boolean = true;

  onClickSave: boolean = false;

  previousBalanceChecked: boolean = false;
  previousBalanceAmount: number = null;

  selectedProfile: Profile = null;

  paymentMode: string = 'cash';

  listStyleView: boolean = false;

  noPartySelectError: boolean = false;

  iSetItemPriceHistoryStatus: boolean = false;

  iSetOutOfStockHideStatus: boolean = false;

  isOpenSecondaryPartyModal: boolean = false;
  
  minDueDate: string = Utility.ionDatePickerFormattedString(+new Date().setHours(0, 0, 0, 0));

  todayDate: number = +new Date().setHours(0, 0, 0, 0);

  //check if sale is edited
  isSaleEdited: boolean = false;

  showCashDiscountPercent: number = 0;

  applyLastSaleStamp: string = null;

  isOpenBillDatePicker: boolean = false;
  isOpenDueDatePicker: boolean = false;
  isOpenEwayBillDatePicker: boolean = false;
  isOpenDeliveryDatePicker: boolean = false;
  keepContentsMounted: boolean = false;

  isStaffAssignToSale: boolean = false;

  isNgOnInitRun: boolean = false;

  subArr: Subscription[] = [];

  partyRunningBillHashMap: RunningBillHashMap = {};

  constructor(
    private formBuilder: FormBuilder,
    private toastController: ToastController,
    private allDataService: AllDataService,
    private route: ActivatedRoute,
    private router: Router,
    private orderService: OrderService,
    private premiumControlService: PremiumControlService,
    private eventService: EventService,
    private monthWisePartyCreditService: MonthWisePartyCreditService,
    private authService: AuthService,
  ) { }

  /*
    Life Cycle Hooks
  */

  ngOnInit() {
    try {
      this.isNgOnInitRun = true;
      this.getParamDocumentId();
      this.getOrderLocalUUID();
      this.getEstimateLocalUUID();
      this.initializeReactiveForm();
      this.populateBarcodeScannerSetting();
      this.subArr.push(this.allDataService.listForceReloadSubs.subscribe((listName: string) => {
        if (listName == 'profile-list') {
          this.getSelectedProfile();
        }
      }));
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:ngOnInit", error)
    }
  }

  async ngAfterViewInit() {
    try {
      await this.getSelectedProfile();
      setTimeout(() => {
          this.populateForm();
      }, 500);
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:ngAfterViewInit", error)
    }
  }

  ionViewWillEnter() {
    if(!this.isNgOnInitRun) {
      this.ngOnInit();
      this.ngAfterViewInit();
    }
  }

  ionViewWillLeave() {
    this.ngOnDestroy();
  }
  
  ngOnDestroy() {
    try {
      this.paramDocumentId = null;
      this.orderLocalUUID = null;
      this.resetBillForm();
      this.keepContentsMounted = false;
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:ngOnDestroy", error)
    }
  }

  onSettingSave() {
    this.populateBarcodeScannerSetting();
  }

  getParamDocumentId() {
    this.paramDocumentId = this.route.snapshot.paramMap.get('documentId');
  }

  getOrderLocalUUID() {
    this.orderLocalUUID = this.route.snapshot.queryParamMap.get('orderLocalUUID');
  }

  getEstimateLocalUUID(){
    // to recieved updated value
    this.subArr.push(this.route.queryParams.subscribe(query => {
      this.estimateLocalUUID = query['estimateLocalUUID'];
    }));
  }

  populateSettings() {
    try {
      this.listStyleView = (this.selectedProfile?.iSetItemSelectorStyleWeb === 'List');
      if(this.amountReceivedChecked === null) {
        this.amountReceivedChecked = this.selectedProfile?.iSetAutoMoneyInStatus;
      }
      this.previousBalanceChecked = this.selectedProfile?.iSetAutoPreviousBalanceStatus;
      this.iSetItemPriceHistoryStatus = this.selectedProfile?.iSetItemPriceHistoryStatus;
      this.iSetOutOfStockHideStatus = this.selectedProfile?.iSetOutOfStockHideStatus;
      let phone = Utility.getFromLocalStorage('selectedProfileUserId');
      let isOwner = phone === this.authService.getLoginPhone();
      this.isStaffAssignToSale = isOwner && this.selectedProfile?.iSetAssignStaffToTheSale && !this.listStyleView;
      if(this.selectedProfile?.iSetServiceChargePercentage) {
        this.form?.patchValue({serviceChargePercentage: this.selectedProfile?.iSetServiceChargePercentage});
      }
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:populateSettings", error)
    }
  }

  // Fetch selected profile
  async getSelectedProfile() {
    try {
      this.selectedProfile = await this.allDataService.profileService.getCurrentProfile();
      this.populateSettings();
      return true;
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:getSelectedProfile", error)
      return false;
    }
  }

  async populateForm() {
    try {
      if (this.paramDocumentId) {
        this.fetchedSale = await this.allDataService.saleService.getByUUID(this.paramDocumentId);

        if(this.fetchedSale?._localUUID) {
          if(this.fetchedSale?.billCompleteStamp){
            this.isSaleEdited = true;
          }
    
          if(!this.fetchedSale?.deletedStamp) {
            this.form.patchValue({ ...this.fetchedSale });
            this.dpBillDateValue = Utility.ionDatePickerFormattedString(this.fetchedSale?.billDateStamp);
            if(this.fetchedSale?.dueDateStamp) {
              this.dpDueDateValue = Utility.ionDatePickerFormattedString(this.fetchedSale?.dueDateStamp);
            }
            // this.dueDateContentsMounted = true;
    
            if(this.listStyleView) {
              this.partySelector?.setSelectedParty(this.fetchedSale.party);
              if(this.paramDocumentId && this.fetchedSale?.billCompleteStamp) {
                // this.partySelector?.disable();
              }
            }else {
              this.partyRestaurantSelector?.setSelectedParty(this.fetchedSale.party);
              if(this.paramDocumentId && this.fetchedSale?.billCompleteStamp) {
                // this.partyRestaurantSelector?.disable();
              }
            }
            this.itemSelector?.setBillItems(this.fetchedSale.billItems);
            this.itemRestaurantSelector?.setBillItems(this.fetchedSale.billItems);
            this.itemRestaurantSelector?.openItemSelectorModal();
            this.populateTransportDetails(this.fetchedSale.transportDetail);
            this.keepContentsMounted = true;
            // this.setTransportDetailsCardVisibiltity();
            // this.disableAmountReceived();
            if(this.fetchedSale?.moneyIns?.length) {
              this.paymentMode = this.fetchedSale?.moneyIns[0]?.paymentMode;
              if(this.paymentIdEle) {
                (this.paymentIdEle as any)['el'].value = this.fetchedSale?.moneyIns[0]?.paymentId;
                this.paymentIdEle.value = this.fetchedSale?.moneyIns[0]?.paymentId;
              }
              if(this.itemRestaurantSelector) {
                this.itemRestaurantSelector.paymentId = this.fetchedSale?.moneyIns[0]?.paymentId;
              }
            }
            setTimeout(() => {
              this.form.patchValue({
                cashDiscount: this.fetchedSale?.cashDiscount,
                cashDiscountPercentage: this.fetchedSale?.cashDiscountPercentage,
              });
              this.recalculateBillItems()
            }, 1000);
          }else {
            if(this.form?.value?.party?.runningSaleBill) {
              let fetchedParty = await this.allDataService.partyService.getByUUID(this.form?.value?.party?._localUUID);
              if(fetchedParty?._localUUID) {
                fetchedParty.runningSaleBill = null;
                fetchedParty.runningSaleBillAmount = null;
                fetchedParty.isPrintedKOT = null;
                let savedParty = await this.allDataService.partyService.update(fetchedParty);
                this.allDataService.listForceReloadSubs.next('party-list');
                this.onPartySelect(savedParty);
                const toast = await this.toastController.create({
                  header: 'Running Bill is Already Deleted. Please Create New Bill',
                  duration: 2000,
                  position: 'top',
                  mode: 'ios',
                  color: 'danger',
                });
                await toast.present();
              }
            }
          }
        }
      }else if(this.orderLocalUUID) {
        let order = await this.orderService.getByUUID(this.orderLocalUUID);
        if(order?._localUUID) {
          this.populateBillNumber();
          let latestParty = await this.allDataService.partyService.getByUUID(order?.party?._localUUID);
          if(latestParty?._localUUID ) {
            this.form?.get('party')?.setValue(latestParty);
            if(this.listStyleView) {
              this.partySelector?.setSelectedParty(latestParty);
              this.partySelector?.disable();
            }else {
              this.partyRestaurantSelector?.setSelectedParty(latestParty);
              this.partyRestaurantSelector?.disable();
            }
          }else {
            alert('Party not found');
          }
          if(order?.orderItems?.length) {
            if(this.listStyleView) {
              this.itemSelector?.openItemSelectorModal();
              setTimeout(() => {
                this.itemSelector?.selectOrderItem(order?.orderItems);
              }, 500);
            }else {
              this.itemRestaurantSelector?.openItemSelectorModal();
              setTimeout(() => {
                this.itemRestaurantSelector?.selectOrderItem(order?.orderItems);
              }, 500);
            }
          }else {
            alert('Items not found');
          }
          return;
        }else {
          alert('Order not found');
        }
      }else if (this.estimateLocalUUID) {
        // this.populateEstimateForm();
        this.fetchEstimate = await this.allDataService.estimateService.getByUUID(this.estimateLocalUUID);
        if(this.fetchEstimate?._localUUID && !this.fetchEstimate?.deletedStamp) {
          this.form.patchValue({ ...this.fetchEstimate });
          this.dpBillDateValue = Utility.ionDatePickerFormattedString(this.fetchEstimate?.billDateStamp);
          if(this.fetchEstimate?.dueDateStamp) {
            this.dpDueDateValue = Utility.ionDatePickerFormattedString(this.fetchEstimate?.dueDateStamp);
          }
          // this.dueDateContentsMounted = true;
  
          if(this.listStyleView) {
            this.partySelector?.setSelectedParty(this.fetchEstimate?.party);
            if(this.estimateLocalUUID ) {
              this.partySelector?.disable();
            }
          }else {
            this.partyRestaurantSelector?.setSelectedParty(this.fetchEstimate?.party);
            if(this.estimateLocalUUID) {
              this.partyRestaurantSelector?.disable();
            }
          }
          this.itemSelector?.setBillItems(this.fetchEstimate?.billItems);
          this.itemRestaurantSelector?.setBillItems(this.fetchEstimate?.billItems);
          this.itemRestaurantSelector?.openItemSelectorModal();
          this.populateTransportDetails(this.fetchEstimate?.transportDetail);
          // this.setTransportDetailsCardVisibiltity();
          // this.disableAmountReceived();
          setTimeout(() => {
            this.form.patchValue({
              cashDiscount: this.fetchEstimate?.cashDiscount,
              cashDiscountPercentage: this.fetchEstimate?.cashDiscountPercentage,
            });
            this.recalculateBillItems()
          }, 1000);
        }
      }else{
        !this.fetchedSale && this.populateBillNumber();
        this.openPartySelectorModal(); 
      }
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:populateForm", error)
    }
    
  }

  /*
    Reactive Form Initialization
  */

  initializeReactiveForm() {
    try {
      this.form = this.formBuilder.group({
        billNo: ['', Validators.required],
        billDateStamp: [+new Date().setHours(0, 0, 0, 0), Validators.required],
        dueDateStamp: [null],
        billingTerm: [null],
        party: [null, Validators.required],
        partySecondary: [null],
        billItems: [null],
        transportDetail: this.formBuilder.group({
          additionalAmount: [null],
          purOrderNo: [null],
          challanNo: [null],
          eWayBillNo: [null],
          eWayBillDate: [],
          transporterName: [null],
          vehicleNumber: [null],
          transDistance: [null, Validators.maxLength(5)],
          deliveryLocation: [null],
          deliveryDate: [],
          tnc: [null],
        }),
        gstAmount: [0],
        cessAmount: [0],
        discountAmount: [0],
        cashDiscount: [null],
        cashDiscountPercentage: [null],
        totalAmount: [null],
        amountReceived: [null],
        subTotalAmount: [null],
        totalSaving: [null],
        note: [null],
        isPrintedKOT: [false],
        roundOffValue: [null],
        partyPreviousBalance: [null],
        serviceChargePercentage: [null],
        serviceChargeAmount: [null],
        deliveryProvience: [null],
        createdBy: [null],
        lastModifiedBy: [null],
        createdByName: [null],
        lastModifiedByName: [null],
      });
      this.subArr.push(this.form.get("amountReceived").valueChanges.pipe(distinctUntilChanged(), debounceTime(300)).subscribe(amountReceived => {
        if(+amountReceived && !this.amountReceivedChecked) {
          this.amountReceivedChecked = true;
        }
        if(amountReceived < 0) {
          this.form?.patchValue({amountReceived: Math.abs(amountReceived)});
        }
        if(amountReceived > this.form?.value?.totalAmount) {
          this.form?.patchValue({amountReceived: this.form?.value?.totalAmount});
        }
      }));
      this.subArr.push(this.form.get("billDateStamp").valueChanges.pipe(distinctUntilChanged(), debounceTime(300)).subscribe(billDateStamp => {
        this.minDueDate = Utility.ionDatePickerFormattedString(billDateStamp);
      }));
      this.subArr.push(this.form.get("totalAmount").valueChanges.pipe(distinctUntilChanged(), debounceTime(300)).subscribe(totalAmount => {
        if(this.amountReceivedChecked) {
          this.form?.patchValue({amountReceived: totalAmount});
        }
      }));
      this.subArr.push(this.form.get("serviceChargePercentage").valueChanges.pipe(distinctUntilChanged(), debounceTime(300)).subscribe(serviceChargePercentage => {
        setTimeout(() => {
          this.calculateSubTotalAmount();
        }, 500);
      }));
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:initializeReactiveForm", error)
    }
  }

  //----------------------------------------------------------------------------

  openSettingsModal() {
    this.settingsModal?.openSettingsModal();
  }

  /*
    Sale Number
  */

  async populateBillNumber() {
    try {
      this.form.patchValue({
        billNo: await this.allDataService.saleService.getNewSaleNo()
      });
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:populateBillNumber", error)
    }
  }

  

  //----------------------------------------------------------------------------

  /*
    PartyV0 Selector functons
  */

  openPartySelectorModal() {
    try {
      if (
        this.listStyleView
        && this.partySelector
        && !this.partySelector?.isPartySelected
      ) {
        this.partySelector?.openPartySelectorModal();
      } else {
        this.partySelector?.openPartySelectorModal(false);
      }
      if (
        !this.listStyleView
        && this.partyRestaurantSelector
        && !this.partyRestaurantSelector?.isPartySelected
      ) {
        this.partyRestaurantSelector?.openPartySelectorModal();
      } else {
        this.partyRestaurantSelector?.openPartySelectorModal(false);
      }
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:openPartySelectorModal", error)
    }
  }

  /**
   * @description : open secondary party modal
   */
  async openSeconadryPartyModal() {
    try {
      this.isOpenSecondaryPartyModal = true;
      await Utility.wait(500)
      this.itemRestaurantSelector?.openSeconadryPartyModal();
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:openSeconadryPartyModal", error)
    }
  }
  // ---------------------------------

  async onPartySelect(party: Party) {
    try {
      if(!this.isSaleEdited) {
        this.fetchedSale = null;
        this.paramDocumentId = null;
      }
      if (party?._localUUID) {
        this.form?.patchValue({
          isPrintedKOT: false,
          party: {...this.form?.value?.party,isPrintedKOT: false}
        });
        if(this.partyRunningBillHashMap[party?._localUUID]) {
          this.fetchedSale = this.partyRunningBillHashMap[party?._localUUID];
        }
        this.form.patchValue({ party });
        this.calculatePartyCredit(party?._localUUID);
        if(party?.paymentTerm) {
          this.form.patchValue({
            billingTerm: party?.paymentTerm
          });
          this.onBillingTermSelect({detail: {value: party?.paymentTerm}});
        }
        this.form.patchValue({
          deliveryProvience: party?.deliveryProvience || party?.billingProvience
        });
        if (party.runningSaleBill) {
          this.paramDocumentId = party?.runningSaleBill;
          this.populateForm();
        }
        else {
          this.itemRestaurantSelector?.openItemSelectorModal();
        }
      }
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:onPartySelect", error)
    }
  }

  onSeconadryPartySelect(party: Party) {
    try {
      if (party?._localUUID || (party?.name || party?.billingAddress) || party?.phone) {
        this.form.patchValue({
          partySecondary: party
        });
        this.calculateSubTotalAmount();
        this.calculateAdditionalDiscount();
      }
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:onSeconadryPartySelect", error)
    }
  }

  async calculatePartyCredit(partyUUID: string) {
    try {
      const ledgerCredit = Utils.capFractionsToTwo(await this.monthWisePartyCreditService?.getLedgerCredit(partyUUID));
      if(this.previousBalanceChecked) {
        this.form?.patchValue({
          partyPreviousBalance: ledgerCredit,
        });
      }
      if(this.form?.value?.party?._localUUID) {
        let party: Party = await this.allDataService.partyService.getByUUID(this.form?.value?.party?._localUUID);
        if(party?._localUUID) {
          party.credit = ledgerCredit;
          let updatedParty = await this.allDataService.partyService.update(party);
          if(updatedParty?._localUUID) {
            this.allDataService.listForceReloadSubs.next('party-list');
            // if runningSaleBill is present then in new updated party not have runningSaleBill beacuse it is deleted from server
            // this.form?.patchValue({party: updatedParty});
          }
        }
      }
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:calculatePartyCredit", error)
    }
  }

  //----------------------------------------------------------------------------

  /*
    Sale Items
  */

  async populateBillItems(billItems: BillItem[]) {
    try {
      let totalAmount = 0;
      billItems?.forEach(x => totalAmount += x.total);
      if (this.itemRestaurantSelector?.amountReceivedChecked) {
        this.amountReceivedChecked = true;
        // this.form.patchValue({ amountReceived: totalAmount });
      }
      this.form.patchValue({
        billItems,
        cashDiscount: this.fetchedSale?.cashDiscount || null,
        cashDiscountPercentage: this.fetchedSale?.cashDiscountPercentage || null,
      });
      this.recalculateBillItems();
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:populateBillItems", error)
    }
  }

  async onCartClear(event) {
    try {
      let salesOnHold = await this.allDataService.saleService.getAllByProfileWithOnlyRunningBill() || [];
      let sale = salesOnHold?.filter(sale => sale?.party?._localUUID===this.form?.value?.party?._localUUID)
          .sort((a,b)=>b.createdStamp-a.createdStamp)[0] || null;
  
      if(event && sale) {
        await this.deleteRunningSale();
        if(this.paramDocumentId) {
          this.router.navigate(['sale/form']);
        }
        this.resetBillForm();
        this.ngOnInit();
        this.ngAfterViewInit();
      }
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:onCartClear", error)
    }
  }

  //----------------------------------------------------------------------------

  onMoneyInCartUpdate(moneyInCart: MoneyIn[]) {
    try {
      let totalAmountReceived = 0;
      moneyInCart?.forEach(x => totalAmountReceived += Number(x?.totalAmount));
      this.form?.controls?.amountReceived?.setValue(totalAmountReceived);
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:onMoneyInCartUpdate", error)
    }
  }

  /*
    Transport Details
  */

  populateTransportDetails(data: any) {
    try {
      this.form.patchValue({ transportDetail: data });
      if (data?.eWayBillDate) {
        this.dpEWayBillDateValue = Utility.ionDatePickerFormattedString(data?.eWayBillDate);
      }
      if (data?.deliveryDate) {
        this.dpDeliveryDateValue = Utility.ionDatePickerFormattedString(data?.deliveryDate);
      }
      // this.setTransportDetailsCardVisibiltity();
      this.calculateSubTotalAmount();
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:populateTransportDetails", error)
    }
  }

  setTransportDetailsCardVisibiltity() {
    try {
      let data = this.form.value.transportDetail;
      if (
        data?.additionalAmount
        || data?.eWayBillNo
        || data?.eWayBillDate
        || data?.purOrderNo
        || data?.challanNo
        || data?.transporterName
        || data?.vehicleNumber
        || data?.deliveryDate
        || data?.transDistance
        || data?.deliveryLocation
        || data?.tnc
      ) {
        this.showTransportDetailsCard = true;
      } else {
        this.showTransportDetailsCard = false;
      }
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:setTransportDetailsCardVisibiltity", error)
    }
  }

  //----------------------------------------------------------------------------

  /*
    Sale Total Amount
  */

  setTotalAmount(totalAmount: number) {
    this.form.patchValue({ totalAmount });
  }

  //----------------------------------------------------------------------------

  /*
    Sale Settings
  */

  async getBillSetting() {
    return null;
  }

  //----------------------------------------------------------------------------

  amountReceivedSetBlur(keyboardEvent: any) {
    try {
      keyboardEvent = keyboardEvent as KeyboardEvent;
      keyboardEvent?.preventDefault();
      keyboardEvent?.target?.blur();
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:amountReceivedSetBlur", error)
    }
  }

  /**
   * 
   * @param event : date event
   * @description : on E way bill date select assign date value
   */
  onEwayBillDateSelect(event) {
    try {
      if(event?.detail?.value) {
        let timeStamp = +new Date(event?.detail?.value).setHours(0, 0, 0, 0);
        this.form?.get('transportDetail.eWayBillDate')?.setValue(timeStamp);
        this.dpEWayBillDateValue = Utility.ionDatePickerFormattedString(timeStamp);
        Utility.closeDatePicker(event?.target);
      }
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:onEwayBillDateSelect", error)
    }
  }
  // --------------------------------------

  /**
   * 
   * @param event : date event
   * @description : on delivery date select assign date value
   */
  onDeliveryDateSelect(event) {
    try {
      if(event?.detail?.value) {
        let timeStamp = +new Date(event?.detail?.value).setHours(0, 0, 0, 0);
        this.form?.get('transportDetail.deliveryDate')?.setValue(timeStamp);
        this.dpDeliveryDateValue = Utility.ionDatePickerFormattedString(timeStamp);
        Utility.closeDatePicker(event?.target);
      }
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:onDeliveryDateSelect", error)
    }
  }
  // ------------------------------------

  setPaymentMode(event) {
    try {
      this.paymentMode = event?.detail?.value || 'cash';
      this.onItemSelectorPaymentModeChange(this.paymentMode);
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:setPaymentMode", error)
    }
  }

  onItemSelectorPaymentModeChange(paymentMode: string) {
    try {
      this.paymentMode = paymentMode;
      if(this.paramDocumentId && this.fetchedSale?.billCompleteStamp && this.fetchedSale?.moneyIns?.length) {
        this.fetchedSale.moneyIns[0].paymentMode = paymentMode;
      }
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:onItemSelectorPaymentModeChange", error)
    }
  }

  /*
    Barcode Scanner
  */

  async populateBarcodeScannerSetting() {
    this.barcodeScannerMode = Utility.getFromLocalStorage('item_barcode_scanner');
  }

  async onBarcodeScannerModeChange(event: any) {
    try {
      this.barcodeScannerMode = event?.detail?.checked;
      // Utility.setToLocalStorage('item_barcode_scanner', this.barcodeScannerMode);
      // const message = `Barcode Scanner Mode ${this.barcodeScannerMode ? 'Enabled' : 'Disabled'}`;
      // const color = 'success';
  
      // const toast = await this.toastController.create({
      //   header: message,
      //   duration: 2000,
      //   position: 'top',
      //   mode: 'ios',
      //   icon: 'barcode-sharp',
      //   color,
      // });
      // await toast.present();
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:onBarcodeScannerModeChange", error)
    }
  }

  //----------------------------------------------------------------------------

  focusAmountReceived(event) {
    try {
      if(event) {
        this.amountReceivedEle?.setFocus();
      }
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:focusAmountReceived", error)
    }
  }

  onAmountReceivedCheckedChange(event: any) {
    try {
      if(this.amountReceivedChecked != event?.detail?.checked) {
        this.amountReceivedChecked = event?.detail?.checked;
        if (this.amountReceivedChecked) {
          this.populateAmountReceived(Number(this.form?.value?.amountReceived) || this.form?.value?.totalAmount);
        } else {
          this.populateAmountReceived(null);
        }
      }
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:onAmountReceivedCheckedChange", error)
    }
  }

  async onPreviousBalanceCheckedChange(event: any) {
    try {
      this.previousBalanceChecked = event?.detail?.checked;
      let partyLocalUUID = this.form?.value?.party?._localUUID;
      if (this.previousBalanceChecked && partyLocalUUID) {
      const ledgerCredit = Utils.capFractionsToTwo(await this.monthWisePartyCreditService?.getLedgerCredit(partyLocalUUID));
       this.form?.patchValue({
        partyPreviousBalance: ledgerCredit,
       });
      } else {
        this.form?.patchValue({
          partyPreviousBalance: null
         });
      }
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:onPreviousBalanceCheckedChange", error)
    }
  }

  populateAmountReceived(amountReceived: number) {
    this.form.patchValue({ amountReceived });
  }

  disableAmountReceived() {
    try {
      if (this.fetchedSale?.moneyIns?.length) {
        this.amountReceivedChecked = true;
        this.isDisableAmountReceived = true;
        this.form.get('amountReceived')?.disable();
      }
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:disableAmountReceived", error)
    }
  }

  /**
   * 
   * @param event : date event
   * @description : on bill date select assign date value
   */
  onBillDateSelect(event) {
    try {
      if(event?.detail?.value) {
        let timeStamp = +new Date(event?.detail?.value).setHours(0, 0, 0, 0);
        this.form?.controls?.billDateStamp?.setValue(timeStamp);
        this.dpBillDateValue = Utility.ionDatePickerFormattedString(timeStamp);
        Utility.closeDatePicker(event?.target);
        if(this.form?.value?.billingTerm) {
          this.onBillingTermSelect({detail:{value:this.form?.value?.billingTerm}});
        }
      }
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:onBillDateSelect", error)
    }
  }
  // ----------------------------------------

  /**
   * 
   * @param event : date event
   * @description : on due date select assign date value
   */
  onBillDueDateSelect(event) {
    try {
      if(event?.detail?.value) {
        let timeStamp = +new Date(event?.detail?.value).setHours(0, 0, 0, 0);
        this.form?.controls?.dueDateStamp?.setValue(timeStamp);
        this.dpDueDateValue = Utility.ionDatePickerFormattedString(timeStamp);
        Utility.closeDatePicker(event?.target);
      }
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:onBillDueDateSelect", error)
    }
  }
  // ----------------------------------------

  returnZero() {
    return 0;
  }

  onBillingTermSelect(event) {
    try {
      this.dpDueDateValue = null;
      const dueDateStamp = Utility.getTimestampByDays(this.dueNetObj[event?.detail?.value || 'net0']?.days ,this.form?.value?.billDateStamp);
      this.form?.controls?.dueDateStamp?.setValue(dueDateStamp);
      this.dueDateContentsMounted = true;
      this.dpDueDateValue = Utility.ionDatePickerFormattedString(dueDateStamp);
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:onBillingTermSelect", error)
    }
  }

  setPaymentId(value) {
    try {
      if(this.paymentIdEle) {
        (this.paymentIdEle as any)['el'].value = value;
        if(this.paramDocumentId && this.fetchedSale?.billCompleteStamp && this.fetchedSale?.moneyIns?.length) {
          this.fetchedSale.moneyIns[0].paymentId = value;
        }
      }
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:setPaymentId", error)
    }
  }

  onSaveClick() {
    try {
      if (this.paramDocumentId) {
        this.update();
      } else {
        this.save();
      }
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:onSaveClick", error)
    }
  }

  async updateOrderSaleId(sale: Sale) {
    try {
      if(this.orderLocalUUID && sale?._localUUID) {
        let order = await this.orderService.getByUUID(this.orderLocalUUID);
        if(order?._localUUID) {
          order.linkedSaleUUID = sale?._localUUID;
          this.orderService.update(order);
        }
      }
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:updateOrderSaleId", error)
    }
  }

  async addSale() {
    try {
      let checkCreateSale = await this.premiumControlService.checkCreateSale();
      if(checkCreateSale) {
        if(!this.form?.value?.party?._localUUID) {
          const toast = await this.toastController.create({
            header: 'Please Select Party to Save Bill',
            duration: 3000,
            position: 'top',
            mode: 'ios',
            color: 'danger',
          });
          await toast.present();
          this.onClickSave = false;
          this.noPartySelectError = true;
          return null;
        }else {
          this.noPartySelectError = false;
        }
    
        if (this.form.valid) {
          let sale = new Sale();
    
          sale = { ...this.form.value };
    
          let fetchedParty = await this.allDataService.partyService.getByUUID(sale?.party?._localUUID);
          if (fetchedParty?.runningSaleBill) {
            fetchedParty.runningSaleBill = null;
            fetchedParty.runningSaleBillAmount = null;
            fetchedParty.isPrintedKOT = sale?.isPrintedKOT;
            sale.party = await this.allDataService.partyService.update(fetchedParty);  
          }
    
          sale.moneyIns = [];
    
          if (Number(this.form?.value?.amountReceived)) {
            let moneyIn = new MoneyIn();
            moneyIn.party = this.form.value.party;
            moneyIn.billDateStamp = sale.billDateStamp;
            moneyIn.totalAmount = this.form.value.amountReceived;
            moneyIn.billNo = await this.allDataService.moneyInService.getNewMoneyInNo();
            moneyIn.paymentMode = this.paymentMode || 'cash';
            moneyIn.paymentId = this.paymentIdEle?.value as string || null;
            sale.moneyIns.push(moneyIn);
          }
    
          sale.billCompleteStamp = +new Date();
    
          let savedSale = await this.allDataService.saleService.save(sale);
          if(this.estimateLocalUUID){
            let estimate = this.fetchEstimate;
            let convertedStamp = sale.billCompleteStamp;
            await this.allDataService.estimateService.updateConvertedStamp(estimate,convertedStamp)
          }
    
          this.allDataService.itemService.reloadList();
          this.allDataService.partyService.reloadList();
          this.allDataService.moneyInService.reloadList();
          this.allDataService.listForceReloadSubs.next('sale-list');
          if(savedSale?.party?._localUUID) {
            let updatedParty=await this.allDataService.partyService.getByUUID(savedSale?.party?._localUUID);
            this.allDataService.partyService.updateSubs.next(updatedParty);
          }
          if(savedSale?.billItems?.length){
            for(let i=0;i<savedSale.billItems.length;i++){
              let billItem = savedSale?.billItems[i]?.item;
              if(billItem?._localUUID) {
                let updatedItem=await this.allDataService.itemService.getByUUID(billItem?._localUUID);
                this.allDataService.itemService.updateSubs.next(updatedItem);
              }
            }
          }
          if(savedSale?.moneyIns?.length){
            for(let i=0;i<savedSale?.moneyIns?.length;i++){
              let moneyIn = savedSale?.moneyIns[i];
              if(moneyIn?._localUUID) {
                let updatedMoneyIn=await this.allDataService.moneyInService.getByUUID(moneyIn?._localUUID);
                this.allDataService.moneyInService.updateSubs.next(updatedMoneyIn);
              }
            }
            this.allDataService.moneyInService.reloadList();
          }
          this.updateOrderSaleId(savedSale);
  
          setTimeout(()=> {
            this.allDataService.listForceReloadSubs.next('item-list');
            this.allDataService.listForceReloadSubs.next('party-list');
            this.allDataService.listForceReloadSubs.next('moneyin-list');
          }, 2000);
  
          return savedSale;
        } else {
          return null;
        }
      }
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:addSale", error)
      return null;
    }
  }

  async updateSale() {
    try {
      if (this.form.valid) {
        let sale = new Sale();
  
        sale = {
          ...this.fetchedSale,
          ...this.form.value
        };
  
        let fetchedParty = await this.allDataService.partyService.getByUUID(sale?.party?._localUUID);
  
        if (fetchedParty?.runningSaleBill) {
          fetchedParty.runningSaleBill = null;
          fetchedParty.runningSaleBillAmount = null;
          fetchedParty.isPrintedKOT = sale?.isPrintedKOT;
          sale.party = await this.allDataService.partyService.update(fetchedParty);
        }
  
        if(!sale?.billCompleteStamp) {
          if (Number(this.form?.value?.amountReceived)) {
            let moneyIn = new MoneyIn();
            moneyIn.party = sale.party;
            moneyIn.billDateStamp = sale.billDateStamp;
            moneyIn.totalAmount = this.form.value.amountReceived;
            moneyIn.billNo = await this.allDataService.moneyInService.getNewMoneyInNo();
            moneyIn.paymentMode = this.paymentMode || 'cash';
            moneyIn.paymentId = this.paymentIdEle?.value as string || null;
            sale.moneyIns = [moneyIn];
          }
        }else {
          sale.moneyIns = this.moneyInSelector?.getSelectedMoneyIns() || [];
        }
  
        sale.billCompleteStamp = +new Date();
        if(!this.isStaffAssignToSale) {
          sale.lastModifiedBy = this.authService.getLoginPhone();
          sale.lastModifiedByName = Utility.getCreatedByName();
        }
        let updatedSale = await this.allDataService.saleService.update(sale);
        this.allDataService.itemService.reloadList();
        this.allDataService.partyService.reloadList();
        this.allDataService.moneyInService.reloadList();
        this.allDataService.listForceReloadSubs.next('sale-list');
        if(updatedSale?.party?._localUUID) {
          let updatedParty=await this.allDataService.partyService.getByUUID(updatedSale?.party?._localUUID);
          this.allDataService.partyService.updateSubs.next(updatedParty);
        }
        if(updatedSale?.billItems?.length){
          for(let i=0;i<updatedSale.billItems.length;i++){
            let billItem = updatedSale?.billItems[i]?.item;
            if(billItem?._localUUID) {
              let updatedItem=await this.allDataService.itemService.getByUUID(billItem?._localUUID);
              this.allDataService.itemService.updateSubs.next(updatedItem);
            }
          }
        }
  
        if(updatedSale?.moneyIns?.length){
          for(let i=0;i<updatedSale.moneyIns.length;i++){
            if(updatedSale?.moneyIns[i]?._localUUID) {
              let moneyIn = updatedSale?.moneyIns[i];
              if(moneyIn?._localUUID) {
                let updatedMoneyIn=await this.allDataService.moneyInService.getByUUID(moneyIn?._localUUID);
                this.allDataService.moneyInService.updateSubs.next(updatedMoneyIn);
              }
            }
          }
        }
  
        setTimeout(()=> {
          this.allDataService.listForceReloadSubs.next('item-list');
          this.allDataService.listForceReloadSubs.next('party-list');
          this.allDataService.listForceReloadSubs.next('moneyin-list');
        }, 2000);
  
        return updatedSale;
      } else {
        return null;
      }
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:updateSale", error)
      return null;
    }
  }

  async save() {
    try {
      let savedSale = await this.addSale();
      if (savedSale?._localUUID) {
        this.allDataService.listForceReloadSubs.next('sale-list');
        this.itemRestaurantSelector?.openItemSelectorModal(false);
        this.router.navigate(['sale']);
      }
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:save", error)
    }
  }

  async onlySave() {
    try {
      if (!this.onClickSave) {
        this.onClickSave = true;
        let savedSale = null;
  
        if (this.paramDocumentId) {
          if (this.fetchedSale?.party?._localUUID == this.form?.value?.party?._localUUID) {
            savedSale = await this.updateSale();
          } else {
            let sale = await this.allDataService.saleService.getByUUID(this.paramDocumentId);
            await this.allDataService.saleService.delete(sale);
            this.form.patchValue({amountReceived: sale?.amountReceived ? sale?.amountReceived : 0});
            savedSale = await this.addSale();
          }
        } else if (this.fetchedSale?._localUUID) {
          savedSale = await this.updateSale();
        } else {
          savedSale = await this.addSale();
        } 
        // if (this.fetchedSale?._localUUID) {
        //   savedSale = await this.updateSale();
        // } else {
        //   savedSale = await this.addSale();
        // }
        if (savedSale?._localUUID) {
          this.showTransportDetailsCard = false;
          this.onClickSave = false;
          this.resetBillForm();
          this.ngOnInit();
          this.ngAfterViewInit();
          if (Utility.getFromLocalStorage('bill_amount_sound_status')) {
            Utility.playAmountSpeech(savedSale?.totalAmount);
          }
        }
      }
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:onlySave", error)
    }
  }

  async savePrint() {
    try {
      if (!this.onClickSave) {
        this.onClickSave = true;
        let savedSale: Sale = null;
  
        if (this.paramDocumentId) {
          if (this.fetchedSale?.party?._localUUID == this.form?.value?.party?._localUUID) {
            savedSale = await this.updateSale();
          } else {
            let sale = await this.allDataService.saleService.getByUUID(this.paramDocumentId);
            await this.allDataService.saleService.delete(sale);
            this.form.patchValue({amountReceived: sale?.amountReceived ? sale?.amountReceived : 0});
            savedSale = await this.addSale();
          }
        } else if (this.fetchedSale?._localUUID) {
          savedSale = await this.updateSale();
        } else {
          savedSale = await this.addSale();
        }
  
        // if (this.fetchedSale?._localUUID) {
        //   savedSale = await this.updateSale();
        // } else {
        //   savedSale = await this.addSale();
        // }
        if (savedSale?._localUUID) {
          this.onClickSave = false;
          this.showTransportDetailsCard = false;
          this.itemRestaurantSelector?.openItemSelectorModal(false);
          this.resetBillForm();
          this.partySelector?.openPartySelectorModal(false);
          this.partyRestaurantSelector?.openPartySelectorModal(false);
          setTimeout(() => {
            this.router.navigate([`/bill-view/${savedSale._localUUID}`], {
              queryParams: { isPrint: true },
            });
          }, 0);
          if (Utility.getFromLocalStorage('bill_amount_sound_status')) {
            Utility.playAmountSpeech(savedSale?.totalAmount);
          }
        }
      }
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:savePrint", error)
    }
    
  }

  async update() {
    try {
      let savedSale = await this.updateSale();
      if (savedSale?._localUUID) {
        this.allDataService.listForceReloadSubs.next('sale-list');
        this.router.navigate(['sale']);
      }
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:update", error)
    }
  }

  async addRunningSale() {
    try {
      if (this.form.valid) {
        let sale = new Sale();
  
        sale = { ...this.form.value };
  
        let fetchedParty = await this.allDataService.partyService.getByUUID(sale?.party?._localUUID);
  
        if(fetchedParty?._localUUID) {
          sale._localUUID = Utility.getUUID();
          fetchedParty.runningSaleBill = sale?._localUUID;
          fetchedParty.runningSaleBillAmount = sale?.totalAmount;
          fetchedParty.isPrintedKOT = sale?.isPrintedKOT;
    
          sale.party = await this.allDataService.partyService.update(fetchedParty);
    
          this.form.patchValue({party:sale.party});
    
          // if (this.form?.value?.amountReceived) {
          //   let moneyIn = new MoneyIn();
          //   moneyIn.party = this.form.value.party;
          //   moneyIn.billDateStamp = sale.billDateStamp;
          //   moneyIn.totalAmount = Number(this.form.value.amountReceived);
          //   moneyIn.billNo = await this.allDataService.moneyInService.getNewMoneyInNo();
          //   moneyIn.paymentMode = this.paymentMode || 'cash';
          //   sale.moneyIns = [moneyIn];
          // }
    
          let savedSale = await this.allDataService.saleService.save(sale);
          this.allDataService.listForceReloadSubs.next('sale-list');
          this.allDataService.itemService.reloadList();
          this.allDataService.partyService.reloadList();
          this.allDataService.moneyInService.reloadList();
          this.updateOrderSaleId(savedSale);
          return savedSale
        }
        return null;
      } else {
        return null;
      }
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:addRunningSale", error)
      return null;
    }
  }

  async updateRunningSale() {
    try {
      if (this.form.valid) {
  
        let sale = new Sale();
  
        sale = {
          ...this.fetchedSale,
          ...this.form.value
        };
  
        sale._localUUID = this.fetchedSale._localUUID;
  
        let fetchedParty = await this.allDataService.partyService.getByUUID(sale?.party?._localUUID);
        if(fetchedParty?._localUUID) {
          fetchedParty.runningSaleBill = sale?._localUUID;
          fetchedParty.runningSaleBillAmount = sale?.totalAmount;
          fetchedParty.isPrintedKOT = sale?.isPrintedKOT;
    
          sale.party = await this.allDataService.partyService.update(fetchedParty);
    
          this.form.patchValue({party:sale.party});
    
          // sale.moneyIns = this.moneyInSelector?.getSelectedMoneyIns();
    
          let updatedSale = await this.allDataService.saleService.update(sale);
          return updatedSale;
        }
        return null;
      } else {
        return null;
      }
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:updateRunningSale", error)
      return null;
    }
  }

  async deleteRunningSale() {
    try {
      if (this.form.valid) {
  
        let sale = new Sale();
  
        if(this.fetchedSale?._localUUID) {
          sale = {
            ...this.fetchedSale,
            ...this.form.value
          };
      
          sale._localUUID = this.fetchedSale?._localUUID;
      
          if (sale?.party?.runningSaleBill) {
            let fetchedParty = await this.allDataService.partyService.getByUUID(sale?.party?._localUUID);
            if(fetchedParty?._localUUID) {
              fetchedParty.runningSaleBill = null;
              fetchedParty.runningSaleBillAmount = null;
              fetchedParty.isPrintedKOT = false;
              sale.party = await this.allDataService.partyService.update(fetchedParty);
            }
          }
      
          this.form.patchValue({party:sale.party});
      
          let deletedSale = await this.allDataService.saleService.delete(sale);
          return deletedSale;
        }
        return null;
        } else {
        return null;
      }
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:deleteRunningSale", error)
      return null;
    }
  }

  async saveHoldNew() {
    try {
      if(!this.form?.value?.party?._localUUID) {
        const toast = await this.toastController.create({
          header: 'Please Select Party to Hold & New Bill',
          duration: 3000,
          position: 'top',
          mode: 'ios',
          color: 'danger',
        });
        await toast.present();
        this.noPartySelectError = true;
        return null;
      }else {
        this.noPartySelectError = false;
      }
      if (this.fetchedSale?._localUUID) {
        this.fetchedSale = await this.updateRunningSale();
      } else {
        this.fetchedSale = await this.addRunningSale();
      }
      if (this.fetchedSale?._localUUID) {
        this.resetBillForm();
        this.ngOnInit();
        this.ngAfterViewInit();
      }
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:saveHoldNew", error)
    }
  }

  resetBillForm() {
    try {
      this.itemRestaurantSelector?.openItemSelectorModal(false);
      this.itemRestaurantSelector?.resetCart();
      this.itemSelector?.openItemSelectorModal(false);
      this.itemSelector?.resetCart();
      if(this.listStyleView) {
        this.partySelector?.resetParty();
        this.partySelector?.enable();
      }else {
        this.partyRestaurantSelector?.resetParty();
        this.partyRestaurantSelector?.enable();
      }
  
      this.form.reset();
      this.isNgOnInitRun = false;
      this.barcodeScannerMode = false;
      this.dpDueDateValue = null;
      this.dpEWayBillDateValue = null;
      this.dpDeliveryDateValue = null;
      this.showTransportDetailsCard = false;
      this.amountReceivedChecked = null;
      this.previousBalanceChecked = false;
      this.isDisableAmountReceived = false;
      this.isOpenSecondaryPartyModal = false;

      this.subArr?.forEach(sub => {
        sub?.unsubscribe();
      })
      this.subArr = [];
  
      if(this.paramDocumentId) {
        this.fetchEstimate = null;
        this.fetchedSale = null;
        this.orderLocalUUID = null;
        this.router.navigate(['sale/form']);
        this.initializeReactiveForm();
      }else if(this.orderLocalUUID) {
        this.fetchEstimate = null;
        this.fetchedSale = null;
        this.orderLocalUUID = null;
        this.router.navigate(['sale/form']);
        this.initializeReactiveForm();
        this.openPartySelectorModal();
      }else if(this.estimateLocalUUID){
        this.fetchEstimate = null;
        this.fetchedSale = null;
        this.orderLocalUUID = null;
        this.estimateLocalUUID = null;
        this.router.navigate(['sale/form']);
        this.initializeReactiveForm();
        // this.openPartySelectorModal();
      }else {
        this.paramDocumentId = null;
        this.fetchedSale = null;
        this.estimateLocalUUID = null;
      }
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:resetBillForm", error)
    }

  }

  async printKot() {
    try {
      if(!this.form?.value?.party?._localUUID) {
        const toast = await this.toastController.create({
          header: 'Please Select Party to print KOT',
          duration: 3000,
          position: 'top',
          mode: 'ios',
          color: 'danger',
        });
        await toast.present();
        this.noPartySelectError = true;
        return null;
      }else {
        this.noPartySelectError = false;
      }
      this.form?.patchValue({
        isPrintedKOT: true,
        party: {...this.form?.value?.party,isPrintedKOT: true}
      });
      if (this.fetchedSale?._localUUID) {
        this.fetchedSale = await this.updateRunningSale();
      } else {
        this.fetchedSale = await this.addRunningSale();
      }
      if (this.fetchedSale?._localUUID) {
        let kot = await this.allDataService.kotService.addKot(this.fetchedSale);
        if(kot) {
          this.initKot(kot);
        }else {
          this.itemRestaurantSelector?.noPrintKotMessage();
        }
      }
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:printKot", error)
    }
  }

  toggleShowAllFields() {
    this.showTransportDetailsCard = !this.showTransportDetailsCard;
  }

  async initKot(kotData: Kot,openKotListModal=false) {
    try {
      let rollPreference=Utility.getFromLocalStorage('selectedTemplate');
      let kotRollSize=3;
      if(rollPreference=='temp19'){
        kotRollSize=2;
      }
      if(Utility.getFromLocalStorage('pending_kot_sound_status')) {
        if(this.kotPrintAudioEle?.nativeElement) {
          (this.kotPrintAudioEle?.nativeElement as HTMLAudioElement)?.play();
        }
      }
      this.itemRestaurantSelector.cssHideModal = true;
      let div: any = document.querySelectorAll(".kot-canvas-area")[0];
      if(div) {
        div.style.display = 'block';
        div.style.width = kotRollSize==3 ? "76mm" : "50mm";
      }
  
      let kotDate: any = document.querySelectorAll(".kot-canvas-area .kot-date")[0];
      if(kotData) {
        kotDate.innerHTML = `Date: ${Utility.getDateTimeString(new Date())}`;
      }
      let kobillNo: any = document.querySelectorAll(".kot-canvas-area .kot-billNo")[0];
      if(kobillNo) {
        kobillNo.innerHTML = `Bill No: ${this.fetchedSale?.billNo}`;
      }
      let kotparty: any = document.querySelectorAll(".kot-canvas-area .kot-party")[0];
      if(kotparty) {
        kotparty.innerHTML = `${this.fetchedSale?.party?.name || ''}`;
      }
      let documentTitle=document.title;
      document.title=`Bill No: ${this.fetchedSale?.billNo}`;
      let htmlStr = '';
      kotData?.printStateItems?.forEach(element => {
        htmlStr += `<h3 style="font-size:4mm;margin:0;padding:0 1mm;">${element?.itemName || ''} (${element?.quantity || 0})</h3>`
        if(element?.note) {
          htmlStr += `<h5 style="font-size:3mm;margin:0;padding:0 1mm;">${element?.note}</h5>`
        }
      });
      let kotItemList: any = document.querySelectorAll(".kot-canvas-area .kot-itemList")[0];
      if(kotItemList) {
        kotItemList.innerHTML = htmlStr;
      }
  
      let domContainer: any = document.getElementById('div-print-area');
      let dvApp: any = document.getElementById('dv-app');
      if(dvApp) {
        dvApp.style.display="none";
      }
      if(domContainer) {
        domContainer.appendChild(div?.cloneNode(true));
        domContainer?.classList?.add('active');
        domContainer.style.width = "100%";
      }
  
      setTimeout(() => {
        window?.print();
        if(domContainer) {
          domContainer.innerHTML = '';
          domContainer?.classList?.remove('active');
        }
        if(div) {
          div.style.display = 'none';
        }
        if(domContainer) {
          domContainer.style.width = "100%";
        }
        this.itemRestaurantSelector.cssHideModal = false;
        if(openKotListModal) {
          this.itemRestaurantSelector?.openKotListModal();
        }
        if(dvApp) {
          dvApp.style.display="block";
        }
        document.title=documentTitle;
      }, 500);
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:initKot", error)
    }

  }

  openMoneyInSelectorModal() {
    try {
      if (this.moneyInSelector) {
        this.moneyInSelector.openMoneyInSelectorModal();
      } else {
        this.moneyInSelector.openMoneyInSelectorModal(false);
      }
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:openMoneyInSelectorModal", error)
    }
  }

  // Calculations

  CalculateBillTask = CalculateBillTask;

  cashDiscountAmount = 0.0;
  cashDiscountPercentage = 0.0;

  serviceChargeAmount = 0.0;
  serviceChargePercentage = 0.0;

  unitDiscountAmountTotal = 0.0;
  unitGstAmountTotal = 0.0;
  unitCessAmountTotal = 0.0;
  unitTaxAmountTotal = 0.0;
  unitSubTotalAmountTotal = 0.0;
  unitTotalAmountTotal = 0.0;
  unitTotalSavingAmountTotal = 0.0;

  billItemsTotal = 0.0;
  wcdBillItemsTotal = 0.0;


  calculateSubTotalAmount() {
    try {
      this.form?.patchValue({
        isPrintedKOT: false,
        party: {...this.form?.value?.party,isPrintedKOT: false}
      });
  
      this.unitDiscountAmountTotal = 0.0;
      this.unitGstAmountTotal = 0.0;
      this.unitCessAmountTotal = 0.0;
      this.unitTaxAmountTotal = 0.0;
      this.unitSubTotalAmountTotal = 0.0;
      this.unitTotalAmountTotal = 0.0;
      this.unitTotalSavingAmountTotal = 0.0;
  
      this.billItemsTotal = 0.0;
      this.wcdBillItemsTotal = 0.0;
  
      let billItems = this.form?.value?.billItems as BillItem[];
  
      billItems?.forEach(billItem => {
        this.unitDiscountAmountTotal += billItem?.unitDiscountAmount || 0.0;
        this.unitGstAmountTotal += billItem?.itemTotalGstAmount || 0.0;
        this.unitCessAmountTotal += billItem?.itemTotalCessAmount || 0.0;
        this.unitTaxAmountTotal += billItem?.itemTotalTaxAmount || 0.0;
        this.unitSubTotalAmountTotal += billItem?.subTotal || 0.0;
        this.unitTotalAmountTotal += billItem?.total || 0.0;
        this.unitTotalSavingAmountTotal += billItem?.totalSaving || 0.0;
  
        this.billItemsTotal += billItem?.total || 0.0;
        this.wcdBillItemsTotal += billItem?.wcdTotal || 0.0;
      });
  
      this.form?.patchValue({
        discountAmount: this.unitDiscountAmountTotal,
        gstAmount: this.unitGstAmountTotal,
        cessAmount: this.unitCessAmountTotal,
        subTotalAmount: this.unitSubTotalAmountTotal,
        totalSaving: this.unitTotalSavingAmountTotal,
      });
  
  
      this.calculateServiceCharge(CalculateBillTask.SERVICE_CHARGE_FROM_PERCENTAGE);
  
      this.calculateTotalAmount();
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:calculateSubTotalAmount", error)
    }
  }

  calculateCashDiscount(task: CalculateBillTask = CalculateBillTask.CALCULATE) {
    try {
      this.cashDiscountAmount = 0.0;
      this.cashDiscountPercentage = 0.0;
  
      if (task == CalculateBillTask.DISCOUNT_FROM_PERCENTAGE) {
        this.cashDiscountPercentage = this.form?.value?.cashDiscountPercentage;
        if(this.wcdBillItemsTotal > 0) {
          this.cashDiscountAmount = Utils.capFractionsToSix(this.wcdBillItemsTotal * this.cashDiscountPercentage / 100);
        }
        this.form?.patchValue({ cashDiscount: this.cashDiscountAmount });
      } else if (task == CalculateBillTask.DISCOUNT_FROM_AMOUNT) {
        this.cashDiscountAmount = this.form?.value?.cashDiscount;
        if(this.wcdBillItemsTotal > 0) {
          this.cashDiscountPercentage = Utils.capFractionsToSix((this.cashDiscountAmount * 100) / this.wcdBillItemsTotal);
          if(
            this.showCashDiscountPercent && 
            this.selectedProfile.discountSoundStatus && 
            Utility.checkValidPhoneNumber(this.form?.value?.partySecondary?.phone)
            ) {
            Utility.playDiscountSpeech(Utils.capFractionsToTwo(this.showCashDiscountPercent));
          }
        }
        this.form?.patchValue({ cashDiscountPercentage: this.cashDiscountPercentage });
      }
  
      if (this.cashDiscountAmount > this.wcdBillItemsTotal || this.cashDiscountAmount < 0) {
        this.cashDiscountAmount = 0.0;
        this.cashDiscountPercentage = 0.0;
        this.form?.patchValue({
          cashDiscount: null,
          cashDiscountPercentage: null
        });
      }
  
      this.recalculateBillItems();
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:calculateCashDiscount", error)
    }
  }

  calculateServiceCharge(task: CalculateBillTask = CalculateBillTask.CALCULATE) {
    try {
      this.serviceChargeAmount = 0.0;
      this.serviceChargePercentage = 0.0;
      
      if(this.form?.value?.serviceChargePercentage < 0 || this.form?.value?.serviceChargeAmount < 0) {
        this.form?.patchValue({ serviceChargePercentage: 0, serviceChargeAmount: 0 });
      } else if (task == CalculateBillTask.SERVICE_CHARGE_FROM_PERCENTAGE) {
        this.serviceChargePercentage = this.form?.value?.serviceChargePercentage;
        if(this.unitSubTotalAmountTotal > 0) {
          this.serviceChargeAmount = Utils.capFractionsToSix(this.unitSubTotalAmountTotal * this.serviceChargePercentage / 100);
        }
        this.form?.patchValue({ serviceChargeAmount: this.serviceChargeAmount });
      } else if (task == CalculateBillTask.SERVICE_CHARGE_FROM_AMOUNT) {
        this.serviceChargeAmount = this.form?.value?.serviceChargeAmount;
        if(this.unitSubTotalAmountTotal > 0) {
          this.serviceChargePercentage = Utils.capFractionsToSix((this.serviceChargeAmount * 100) / this.unitSubTotalAmountTotal);
        }
        this.form?.patchValue({ serviceChargePercentage: this.serviceChargePercentage });
      }
  
      if (this.cashDiscountAmount < 0) {
        this.cashDiscountAmount = 0.0;
        this.cashDiscountPercentage = 0.0;
        this.form?.patchValue({
          cashDiscountAmount: null,
          cashDiscountPercentage: null
        });
      }
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:calculateServiceCharge", error)
    }

  }

  calculateTotalAmount() {
    try {
      let additionalCharges = this.form?.value?.transportDetail?.additionalAmount || 0;
      if(!Utility.isNumber(additionalCharges)) {
        additionalCharges = 0.0;
      }
  
      let serviceChargeAmount = this.form?.value?.serviceChargeAmount || 0;
      if(!Utility.isNumber(serviceChargeAmount)) {
        serviceChargeAmount = 0.0;
      }
  
      let totalAmount = this.unitTotalAmountTotal + Number(additionalCharges) + Number(serviceChargeAmount);
      totalAmount = Utils.capFractionsToSix(totalAmount);
  
      let roundOffValue = 0.0;
      if(this.selectedProfile?.iSetRoundOffTotalAmountStatus) {
        let floorDelta = (totalAmount || 0.0) - Math.floor(totalAmount || 0.0);
        let ceilDelta = Math.ceil(totalAmount || 0.0) - (totalAmount || 0.0);
  
        if(floorDelta > ceilDelta) {
          roundOffValue = ceilDelta;
        }else {
          roundOffValue = floorDelta * -1;
        }
        roundOffValue = Utils.capFractionsToSix(roundOffValue);
        totalAmount = Math.round(totalAmount + roundOffValue);
      }
  
  
      this.form?.patchValue({
        totalAmount,
        roundOffValue
      });
  
      if (this.amountReceivedChecked && !this.fetchedSale) {
        this.populateAmountReceived(this.fetchedSale ? this.fetchedSale?.amountReceived : this.form?.value?.totalAmount);
      }
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:calculateTotalAmount", error)
    }

  }

  calculateAdditionalDiscount() {
    try {
      if(this.form?.value?.partySecondary) {
  
        this.showCashDiscountPercent = 0.0;
  
        let isValidDiscountI = false;
        let isValidDiscountII = false;
  
        let discountPercentI = this.selectedProfile?.dSetDiscountPercentI;
        let discountMaximumAmountI = this.selectedProfile?.dSetDiscountMaximumAmountI;
        let discountMinimumAmountI = this.selectedProfile?.dSetDiscountMinimumAmountI;
        let discountPercentII = this.selectedProfile?.dSetDiscountPercentII;
        let discountMaximumAmountII = this.selectedProfile?.dSetDiscountMaximumAmountII;
        let discountMinimumAmountII = this.selectedProfile?.dSetDiscountMinimumAmountII;
  
        let additionalDiscountAmountI = 0.0;
        let additionalDiscountAmountII = 0.0;
  
        let lastSaleStamp = this.form?.value?.partySecondary?.lastSaleStamp || 0;
  
        // if discount-1 is on
        if(this.selectedProfile?.dSetDiscountStatusI) {
          if ((this.selectedProfile?.dSetDiscountOfferTypeI || 0) == 0) {
            isValidDiscountI = true;
          } else if (this.selectedProfile?.dSetDiscountOfferTypeI == 1) {
            let discountExpiryStamp = this.selectedProfile?.dSetDiscountExpiryDaysI * 86400000; 
            if (lastSaleStamp > 0 && discountExpiryStamp > 0) {
              if ((lastSaleStamp + discountExpiryStamp) > +new Date()) {
                isValidDiscountI = true;
              }
            }
          }
  
          if (isValidDiscountI && discountMinimumAmountI <= this.unitTotalAmountTotal) {
            additionalDiscountAmountI = (this.unitTotalAmountTotal / 100) * discountPercentI;
            if (discountMaximumAmountI && additionalDiscountAmountI > discountMaximumAmountI) {
              additionalDiscountAmountI = discountMaximumAmountI;
            }
          } else {
            isValidDiscountI = false;
          }
        }
  
        // if discount-2 is on
        if (this.selectedProfile?.dSetDiscountStatusII) {
          if ((this.selectedProfile?.dSetDiscountOfferTypeII || 0) == 0) {
            isValidDiscountII = true;
          } else if (this.selectedProfile?.dSetDiscountOfferTypeII == 1) {
            let discountExpiryStamp = this.selectedProfile?.dSetDiscountExpiryDaysII * 86400000; 
            if (lastSaleStamp > 0 && discountExpiryStamp > 0) {
              if ((lastSaleStamp + discountExpiryStamp) > +new Date()) {
                isValidDiscountII = true;
              }
            }
          }
  
          if (isValidDiscountII && discountMinimumAmountII <= this.unitTotalAmountTotal) {
            additionalDiscountAmountII = (this.unitTotalAmountTotal / 100) * discountPercentII;
            if (discountMaximumAmountII && additionalDiscountAmountII > discountMaximumAmountII) {
              additionalDiscountAmountII = discountMaximumAmountII;
            }
          } else {
            isValidDiscountII = false;
          }
        }
  
        this.eventService.setCustomerRepeated(false);
  
        this.cashDiscountAmount = 0.0;
        this.cashDiscountPercentage = 0.0;
        if(isValidDiscountI && isValidDiscountII) {
          this.cashDiscountAmount = additionalDiscountAmountI > additionalDiscountAmountII ? additionalDiscountAmountI : additionalDiscountAmountII;
          if(additionalDiscountAmountI || additionalDiscountAmountII) {
            this.showCashDiscountPercent = additionalDiscountAmountI > additionalDiscountAmountII ? discountPercentI : discountPercentII;
          }
          if(additionalDiscountAmountI > additionalDiscountAmountII && this.selectedProfile?.dSetDiscountOfferTypeI == 1) {
            this.eventService.setCustomerRepeated(true);
          } else if(additionalDiscountAmountII > additionalDiscountAmountI && this.selectedProfile?.dSetDiscountOfferTypeII == 1) {
            this.eventService.setCustomerRepeated(true);
          }
        } else if (isValidDiscountI && additionalDiscountAmountI) {
          this.cashDiscountAmount = additionalDiscountAmountI;
          this.showCashDiscountPercent = discountPercentI;
          if(this.selectedProfile?.dSetDiscountOfferTypeI == 1) {
            this.eventService.setCustomerRepeated(true);
          }
        } else if (isValidDiscountII && additionalDiscountAmountII) {
          this.cashDiscountAmount = additionalDiscountAmountII;
          this.showCashDiscountPercent = discountPercentII;
          if(this.selectedProfile?.dSetDiscountOfferTypeII == 1) {
            this.eventService.setCustomerRepeated(true);
          }
        }
  
        this.form?.patchValue({
          cashDiscount: this.cashDiscountAmount,
          cashDiscountPercentage: null
        });
        this.calculateCashDiscount(CalculateBillTask.DISCOUNT_FROM_AMOUNT);
      }
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:calculateAdditionalDiscount", error)
    }
  }

  recalculateBillItems() {
    try {
      let billItems = this.form?.value?.billItems;
      billItems?.forEach(billItem => {
        BillCalculations.calculateBillItem(billItem,BillType.PURCHASE,null,this.form?.value?.cashDiscountPercentage);
      });
      this.itemRestaurantSelector?.setBillItemsNoEmitNoCal(billItems);
      this.form?.patchValue({
        billItems
      });
      this.calculateSubTotalAmount();
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:recalculateBillItems", error)
    }
  }

  /**
   * 
   * @param party : provide party object
   * @description : store party object in partySecondary
   */
  savedSecondaryParty(party: Party) {
    try {
      if(party?._localUUID) {
        this.form?.patchValue({
          partySecondary: party,
        })
      }
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:savedSecondaryParty", error)
    }
  }
  // ----------------------------------

  /**
   * 
   * @param event : provide object of userId and name
   * @description : store value of userId, createdBy, lastModifiedBy, createdByName, lastModifiedByName
   */
  setSelectedStaff(event) {
    try {
      let userId = event?.userId || this.authService.getLoginPhone();
      let name = event?.name || event?.userId || Utility.getCreatedByName();
      this.form?.patchValue({
        lastModifiedBy: userId,
        lastModifiedByName: name,
      })
      
      if(!this.paramDocumentId) {
        this.form?.patchValue({
          createdBy: userId,
          createdByName: name,
        })
      }
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:setSelectedStaff", error)
    }
  }
  // ---------------------------------

  /**
   * @description : close date picker
   */
  closeDOBPicker() {
    try {
      this.isOpenBillDatePicker = false;
      this.isOpenDueDatePicker = false;
      this.isOpenEwayBillDatePicker = false;
      this.isOpenDeliveryDatePicker = false;
    } catch (error) {
      SentryUtilites.setLog("SaleFormPage:closeDOBPicker", error)
    }
  }
  // ---------------------------------

  runningBillHashMap(runningBillHashMap: RunningBillHashMap) {
    this.partyRunningBillHashMap = runningBillHashMap;
  }
  
}
