import { BaseModel } from "./BaseModel.model";

export class IPartyItemPriceMap {

  static SCHEMA_NAME = 'party-item-price-map';

}

export default class PartyItemPriceMap extends BaseModel {

  profileId: string;

  partyUUID: string;
  itemUUID: string;
  sellPrice : number;

}
