import {  ToastController } from '@ionic/angular';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Item } from '../../../models/Item.model';
import {  ActivatedRoute, Router } from '@angular/router';
import { ItemFormComponent } from '../../../components/item-form/item-form.component';
import { Utility } from 'src/app/utils/utility';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-item-form-page',
  templateUrl: './item-form.page.html',
  styleUrls: ['./item-form.page.scss'],
})
export class ItemFormPage implements OnInit {

  @ViewChild('itemFormEle') itemFormEle: ItemFormComponent;
  
  getHeaderColorClass = Utility.getHeaderColorClass;

  paramDocumentId: string = null;

  constructor(
    private toastController: ToastController,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.getParamDocumentId();
  }

  getParamDocumentId() {
    this.paramDocumentId = this.route.snapshot.paramMap.get('documentId');
  }

  openSettingsModal() {
    this?.itemFormEle?.openSettingsModal();
  }

  saveTrigger(item: Item) {
    this.presentSuccessToast("Item Saved Successfully");
  }

  updateTrigger(item: Item) {
    this.presentSuccessToast("Item Updated Successfully");
  }

  async presentSuccessToast(header: string) {
    try {
      const toast = await this.toastController.create({
        header,
        duration: 2000,
        position: 'top',
        mode: 'ios',
        color: 'success',
      });
      await toast.present();
      this.router.navigate(['item']);
    } catch (error) {
      SentryUtilites.setLog("ItemFormPage:presentSuccessToast", error)
    }
  }

}
