import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Sale } from '../../../models/Sale.model';
import { PinVerificationComponent } from '../../pin-verification/pin-verification.component';
import { AlertController, InfiniteScrollCustomEvent } from '@ionic/angular';
import { AllDataService } from '../../../services/all-data.service';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-sale-bulk-delete',
  templateUrl: './sale-bulk-delete.component.html',
  styleUrls: ['./sale-bulk-delete.component.scss'],
})
export class SaleBulkDeleteComponent implements OnInit {

  @Output() onSaleDelete = new EventEmitter<Sale>();
  @Output() onBulkSaleDelete = new EventEmitter<boolean>();
  @ViewChild('pinVerificationElement') pinVerificationElement: PinVerificationComponent;
  @Input() fromStamp:number = null;
  @Input() toStamp: number = null;

  // Sale
  isSaleModalOpen = false;
  sale: Sale = null;
  isDeleteSaleAttachedMoneyIns = true;
  saleDeleteStarted = false;
  saleDeleteCompleted = false;
  //----------------------------------------------------

  // Bulk Sale
  isBulkSaleModalOpen = false;
  checkAllSaleCheckboxes: boolean = false;
  bulkSales: Sale[] = [];
  selectedBulkSales: Sale[] = [];
  bulkSaleDeleteStarted = false;
  bulkSaleDeleteCompleted = false;
  //----------------------------------------------------

  // Security Pin common functionality var
  selectedVar: string = '';
  bulkSelectArray: string[] = [];
  viewFilteredList:Sale[] = [];
  // ---------------------------------

  constructor(
    private allDataService: AllDataService,
    private alertController: AlertController,
  ) { }

  ngOnInit() { }

  openTransactionPINModal() {
    this.pinVerificationElement?.openTransactionPINModal();
  }

  async verifyTransactionPIN(event) {
    try {
      if(event){
        if(this.selectedVar == 'Sale'){
         this.openSaleModal(true);
        }else if(this.selectedVar == 'BulkSale'){
          this.openBulkSaleModal(true);
          await this.onDateRangeChange();
          //edit bulk sale
          this.bulkSales?.forEach((el)=> {
            el["checked"] = false
          })
        }
      }else{
        this.openBulkSaleModal(false);
        this.openSaleModal(false);
      }
    } catch (error) {
      SentryUtilites.setLog("SaleBulkDeleteComponent:verifyTransactionPIN", error)
    }
  }

  setCheckboxVarValue(variable: string, event: any) {
    this[variable] = event?.detail?.checked;
  }

  async deleteConfirm(): Promise<boolean> {
    return new Promise(async (resolve,reject) => {
      try {
        const alert = await this.alertController.create({
          mode: 'ios',
          header: 'Please Confirm!',
          message: 'Are you sure want to delete?',
          buttons: [
            {
              text: 'No',
              role: 'cancel',
              handler: () => {
                resolve(false);
                return true;
              },
            },
            {
              text: 'Yes',
              role: 'confirm',
              handler: () => {
                resolve(true);
                return true;
              },
            },
          ],
        });
        await alert.present();
      } catch (error) {
        SentryUtilites.setLog("SaleBulkDeleteComponent:deleteConfirm", error)
        return resolve(false);
      }
    });
  }

  async deleteMoneyInConfirm(): Promise<boolean> {
    return new Promise(async (resolve,reject) => {
      try {
        const alert = await this.alertController.create({
          mode: 'ios',
          header: `Also Delete Attached Money In's`,
          message: 'Are you sure want to delete?',
          buttons: [
            {
              text: 'No',
              role: 'cancel',
              handler: () => {
                resolve(false);
                return true;
              },
            },
            {
              text: 'Yes',
              role: 'confirm',
              handler: () => {
                resolve(true);
                return true;
              },
            },
          ],
        });
        await alert.present();
      } catch (error) {
        SentryUtilites.setLog("SaleBulkDeleteComponent:deleteMoneyInConfirm", error)
        return resolve(false);
      }
    });
  }

  async onDateRangeChange(){
    try {
      this.viewFilteredList = await this.allDataService?.saleService?.getByBillDateRange(this.fromStamp, this.toStamp) || [];
      this.viewFilteredList = this.viewFilteredList?.filter(x => !x?.deletedStamp);
      this.bulkSales = this.viewFilteredList.slice(0,50);
    } catch (error) {
      SentryUtilites.setLog("SaleBulkDeleteComponent:onDateRangeChange", error)
    }
  }

  // Sale
  async openSaleModal(value: boolean = true) {
    try {
      if(value) {
        if ((await this.deleteConfirm()) === false) return;
      }
      this.isSaleModalOpen = value;
    } catch (error) {
      SentryUtilites.setLog("SaleBulkDeleteComponent:openSaleModal", error)
    }
  }

  async initDeleteSale(sale: Sale) {
    try {
      this.sale = sale;
      if(sale?.moneyIns?.length) {
        this.isDeleteSaleAttachedMoneyIns = true;
      }
      this.selectedVar = 'Sale';
      this.openTransactionPINModal();
    } catch (error) {
      SentryUtilites.setLog("SaleBulkDeleteComponent:initDeleteSale", error)
    }
  }

  async deleteSale() {
    try {
      this.saleDeleteStarted = true;
      let deletedSale = await this.allDataService.saleService.delete(this.sale);
      if (deletedSale?.deletedStamp) {
        this.sale = deletedSale;
        this.allDataService.itemService.reloadList();
        this.allDataService.partyService.reloadList();
        this.allDataService.moneyInService.reloadList();
        this.saleDeleteCompleted = true;
        setTimeout(() => {
          this.openSaleModal(false);
          this.sale = null;
          this.isDeleteSaleAttachedMoneyIns = false;
          this.saleDeleteStarted = false;
          this.saleDeleteCompleted = false;
          setTimeout(() => {
            this.onSaleDelete.emit(deletedSale);
          }, 1000);
        }, 500);
      }
    } catch (error) {
      SentryUtilites.setLog("SaleBulkDeleteComponent:deleteSale", error)
    }
  }

  //--------------------------------------------------------------------------------

  // Bulk Sale

  async openBulkSaleModal(value: boolean = true) {
    try {
      if(value) {
        if ((await this.deleteConfirm()) === false) return;
      }
      this.isBulkSaleModalOpen = value;
      this.selectedBulkSales = [];
    } catch (error) {
      SentryUtilites.setLog("SaleBulkDeleteComponent:openBulkSaleModal", error)
    }
  }

  async initDeleteBulkSale() {
    try {
      this.selectedVar = 'BulkSale';
      if(!this.bulkSelectArray.includes('BulkSale')) {
        this.bulkSelectArray.push('BulkSale');
      }
      this.openTransactionPINModal();
    } catch (error) {
      SentryUtilites.setLog("SaleBulkDeleteComponent:initDeleteBulkSale", error)
    }
  }

  /**
   * 
   * @param sale : Provide sale object
   * @param event : ionic CustomEventInit
   * @description : this method add checked sale in selectedBulkSales array 
   *                if unchecked it will that sale from selectedBulkSales array
   */
  addToSelectedBulkSaleArr(sale: Sale,event: CustomEventInit) {
    try {
      const index = this.selectedBulkSales?.findIndex(x => x?._localUUID === sale?._localUUID);
      this.bulkSales?.forEach(x => {
        if(x?._localUUID === sale?._localUUID){
          x["checked"] = event?.detail?.checked;
        }
      });
      if(event?.detail?.checked) {
        if(index == -1){
          this.selectedBulkSales.push(sale);    
        }
      }else {
        if(index != -1) {
          this.selectedBulkSales.splice(index,1);
        }
      }
    } catch (error) {
      SentryUtilites.setLog("SaleBulkDeleteComponent:addToSelectedBulkSaleArr", error)
    }
  }
  // -----------------------------

  async deleteBulkSale() {
    try {
      if(await this.deleteMoneyInConfirm()){
        this.bulkSaleDeleteStarted = true;
        if(this.selectedBulkSales?.length) {
          for (let i = 0; i < this.selectedBulkSales.length; i++) {
            let sale = this.selectedBulkSales[i];
            await this.allDataService.saleService.delete(sale);
          }
        }
        this.allDataService.itemService.reloadList();
        this.allDataService.partyService.reloadList();
        this.allDataService.moneyInService.reloadList();
        this.bulkSaleDeleteCompleted = true;
        setTimeout(() => {
          this.openBulkSaleModal(false);
          this.bulkSales = [];
          this.selectedBulkSales = [];
          this.bulkSaleDeleteStarted = false;
          this.bulkSaleDeleteCompleted = false;
          setTimeout(() => {
            this.onBulkSaleDelete.emit(true);
          }, 1000);
        }, 500);
      }
    } catch (error) {
      SentryUtilites.setLog("SaleBulkDeleteComponent:deleteBulkSale", error)
    }
  }

  /**
   * 
   * @param event : ionic CustomEventInit
   * @description : this method push all sales in selectedBulkSales if select all checkbox is checked
   */
  selectAllSaleCheckboxes(event: CustomEventInit){
    try {
      if(this.checkAllSaleCheckboxes) {
        if(event?.detail?.checked) {
          this.viewFilteredList?.forEach((el)=> {el["checked"] = true});
          this.selectedBulkSales = [...this.viewFilteredList];
        } else {
          this.bulkSales?.forEach((el)=> {el["checked"] = false});
          this.viewFilteredList?.forEach((el)=> {el["checked"] = false});
          this.selectedBulkSales = [];
        }
      }
      this.checkAllSaleCheckboxes = false;
    } catch (error) {
      SentryUtilites.setLog("SaleBulkDeleteComponent:selectAllSaleCheckboxes", error)
    }
  }
  // -----------------------------

  /**
   * @description: this method check user is click on checkbox or not.
   */
  selectAllSaleCheckboxClick() {
    this.checkAllSaleCheckboxes = true;
  }
  // -----------------------------

  //--------------------------------------------------------------------------------

  loadMoreListData(event) {
    try {
      if (this.bulkSales.length > 0 && this.bulkSales.length <= this.viewFilteredList.length) {
        let appendListLength = this.viewFilteredList.length - this.bulkSales.length;
        let lastEl = this.bulkSales[this.bulkSales.length - 1];
        let counter = 0;
        for (let i = 0; i < this.viewFilteredList.length; i++) {
          if (this.viewFilteredList[i]._localUUID == lastEl._localUUID) {
            counter = 1;
            continue;
          }
          if (counter > 0 && appendListLength >= 52) {
            if (counter <= 52) {
              this.bulkSales.push(this.viewFilteredList[i])
            } else {
              break;
            }
            counter++;
          } else if(counter > 0 && appendListLength < 52) {
            if (counter <= appendListLength) {
              this.bulkSales.push(this.viewFilteredList[i])
            } else {
              break;
            }
            counter++;
          }
        }
        (event as InfiniteScrollCustomEvent).target.complete();
      }
    } catch (error) {
      SentryUtilites.setLog("SaleBulkDeleteComponent:loadMoreListData", error)
    }
  }
}

