import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AllDataService } from '../../../services/all-data.service';
import { PinVerificationComponent } from '../../pin-verification/pin-verification.component';
import { AlertController, InfiniteScrollCustomEvent } from '@ionic/angular';
import { MoneyIn } from 'src/app/models/MoneyIn.model';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-money-in-bulk-delete',
  templateUrl: './money-in-bulk-delete.component.html',
  styleUrls: ['./money-in-bulk-delete.component.scss'],
})
export class MoneyInBulkDeleteComponent implements OnInit {
  @Output() onMoneyInDelete = new EventEmitter<MoneyIn>();
  @Output() onBulkMoneyInDelete = new EventEmitter<boolean>();
  @ViewChild('pinVerificationElement') pinVerificationElement: PinVerificationComponent;
  @Input() fromStamp:number = null;
  @Input() toStamp: number = null;

  // Money In
  isMoneyInModalOpen = false;
  moneyIn: MoneyIn = null;
  moneyInDeleteStarted = false;
  moneyInDeleteCompleted = false;
  //----------------------------------------------------

    // Bulk Money In
    isBulkMoneyInModalOpen = false;
    checkAllMoneyInCheckboxes: boolean = false;
    bulkMoneyIns: MoneyIn[] = [];
    selectedBulkMoneyIns: MoneyIn[] = [];
    bulkMoneyInDeleteStarted = false;
    bulkMoneyInDeleteCompleted = false;
    //----------------------------------------------------

    // Security Pin common functionality var
    selectedVar: string = '';
    bulkSelectArray: string[] = [];
    viewFilteredList:MoneyIn[] = [];
    // ---------------------------------


  constructor(
    private allDataService: AllDataService,
    private alertController: AlertController,
  ) { }

  ngOnInit() {}

  openTransactionPINModal() {
    this.pinVerificationElement?.openTransactionPINModal();
  }

  async verifyTransactionPIN(event) {
    try {
      if(event){
        if(this.selectedVar == 'MoneyIn'){
         this.openMoneyInModal(true);
        }else if(this.selectedVar == 'BulkMoneyIn'){
          this.openBulkMoneyInModal(true);
          await this.onDateRangeChange();
          //edit bulk sale
          this.bulkMoneyIns?.forEach((el)=> {
            el["checked"] = false
          })
        }
      }else{
        this.openBulkMoneyInModal(false);
        this.openMoneyInModal(false);
      }
    } catch (error) {
      SentryUtilites.setLog("MoneyInBulkDeleteComponent:verifyTransactionPIN", error)
    }
  }

  setCheckboxVarValue(variable: string, event: any) {
    this[variable] = event?.detail?.checked;
  }

  async deleteConfirm(): Promise<boolean> {
    return new Promise(async (resolve,reject) => {
      try {
        const alert = await this.alertController.create({
          mode: 'ios',
          header: 'Please Confirm!',
          message: 'Are you sure want to delete?',
          buttons: [
            {
              text: 'No',
              role: 'cancel',
              handler: () => {
                resolve(false);
                return true;
              },
            },
            {
              text: 'Yes',
              role: 'confirm',
              handler: () => {
                resolve(true);
                return true;
              },
            },
          ],
        });
        await alert.present();
      } catch (error) {
        SentryUtilites.setLog("MoneyInBulkDeleteComponent:deleteConfirm", error)
        return false;
      }
    });
  }

     // Money In

  async openMoneyInModal(value: boolean = true) {
    try {
      if(value) {
        if ((await this.deleteConfirm()) === false) return;
      }
      this.isMoneyInModalOpen = value;
      return;
    } catch (error) {
      SentryUtilites.setLog("MoneyInBulkDeleteComponent:openMoneyInModal", error)
    }
  }

  async initDeleteMoneyIn(moneyIn: MoneyIn) {
    try {
      this.moneyIn = moneyIn;
      this.selectedVar = 'MoneyIn';
      this.openTransactionPINModal();
    } catch (error) {
      SentryUtilites.setLog("MoneyInBulkDeleteComponent:initDeleteMoneyIn", error)
    }
  }

  async deleteMoneyIn() {
    try {
      this.moneyInDeleteStarted = true;
      let deletedMoneyIn: MoneyIn = null;
      deletedMoneyIn = await this.allDataService.moneyInService.delete(this.moneyIn);
      if (deletedMoneyIn?.deletedStamp) {
        this.moneyIn = deletedMoneyIn;
        this.allDataService.partyService.reloadList();
        this.allDataService.saleService.reloadList();
        this.allDataService.purchaseReturnService.reloadList();
        this.allDataService.listForceReloadSubs.next('moneyin-list');
        this.moneyInDeleteCompleted = true;
        setTimeout(() => {
          this.openMoneyInModal(false);
          this.moneyIn = null;
          this.moneyInDeleteStarted = false;
          this.moneyInDeleteCompleted = false;
          setTimeout(() => {
            this.onMoneyInDelete.emit(deletedMoneyIn);
          }, 1000);
        }, 500);
      }
    } catch (error) {
      SentryUtilites.setLog("MoneyInBulkDeleteComponent:deleteMoneyIn", error)
    }
  }

  //--------------------------------------------------------------------------------

   // Bulk Money In

  async openBulkMoneyInModal(value: boolean = true) {
    try {
      if(value) {
        if ((await this.deleteConfirm()) === false) return;
      }
      this.isBulkMoneyInModalOpen = value;
      return;
    } catch (error) {
      SentryUtilites.setLog("MoneyInBulkDeleteComponent:openBulkMoneyInModal", error)
    }
  }

  async initDeleteBulkMoneyIn() {
    try {
      this.selectedVar = 'BulkMoneyIn';
      if(!this.bulkSelectArray.includes('BulkMoneyIn')) {
        this.bulkSelectArray.push('BulkMoneyIn');
      }
      this.openTransactionPINModal();
    } catch (error) {
      SentryUtilites.setLog("MoneyInBulkDeleteComponent:initDeleteBulkMoneyIn", error)
    }
  }

  addToSelectedBulkMoneyInArr(moneyIn: MoneyIn,event: any) {
    try {
      const index = this.selectedBulkMoneyIns?.findIndex(x => x?._localUUID === moneyIn?._localUUID);
        this.bulkMoneyIns?.forEach(x => {
          if(x?._localUUID === moneyIn?._localUUID){
            x["checked"] = event?.detail?.checked;
          }
        });
        if(event?.detail?.checked) {
          if(index == -1){
            this.selectedBulkMoneyIns.push(moneyIn);    
          }
        }else {
          if(index != -1) {
            this.selectedBulkMoneyIns.splice(index,1);
          }
        }
    } catch (error) {
      SentryUtilites.setLog("MoneyInBulkDeleteComponent:addToSelectedBulkMoneyInArr", error)
    }
  }

  async deleteBulkMoneyIn() {
    try {
      this.bulkMoneyInDeleteStarted = true;
      if(this.selectedBulkMoneyIns?.length) {
        for (let i = 0; i < this.selectedBulkMoneyIns.length; i++) {
          let moneyIn = this.selectedBulkMoneyIns[i];
          await this.allDataService.moneyInService.delete(moneyIn);
        }
      }
  
      this.allDataService.partyService.reloadList();
      this.allDataService.saleService.reloadList();
      this.allDataService.purchaseReturnService.reloadList();
      this.allDataService.listForceReloadSubs.next('moneyin-list');
  
      this.bulkMoneyInDeleteCompleted = true;
  
      setTimeout(() => {
        this.openBulkMoneyInModal(false);
        this.bulkMoneyIns = [];
        this.selectedBulkMoneyIns = [];
        this.bulkMoneyInDeleteStarted = false;
        this.bulkMoneyInDeleteCompleted = false;
        setTimeout(() => {
          this.onBulkMoneyInDelete.emit(true);
        }, 1000);
      }, 500);
    } catch (error) {
      SentryUtilites.setLog("MoneyInBulkDeleteComponent:deleteBulkMoneyIn", error)
    }
  }
  
    //--------------------------------------------------------------------------------

    selectAllMoneyInCheckboxes(event: CustomEventInit){
      try {
        if(this.checkAllMoneyInCheckboxes) {
          if(event?.detail?.checked) {
            this.viewFilteredList?.forEach((el)=> {el["checked"] = true});
            this.selectedBulkMoneyIns = [...this.viewFilteredList];
          } else {
            this.bulkMoneyIns?.forEach((el)=> {el["checked"] = false});
            this.viewFilteredList?.forEach((el)=> {el["checked"] = false});
            this.selectedBulkMoneyIns = [];
          }
        }
  
        this.checkAllMoneyInCheckboxes = false;
      } catch (error) {
        SentryUtilites.setLog("MoneyInBulkDeleteComponent:selectAllMoneyInCheckboxes", error)
      }
    }

    selectAllMoneyInCheckboxClick() {
      this.checkAllMoneyInCheckboxes = true;
    }

    loadMoreListData(event) {
      try {
        if (this.bulkMoneyIns.length > 0 && this.bulkMoneyIns.length <= this.viewFilteredList.length) {
          let appendListLength = this.viewFilteredList.length - this.bulkMoneyIns.length;
          let lastEl = this.bulkMoneyIns[this.bulkMoneyIns.length - 1];
          let counter = 0;
          for (let i = 0; i < this.viewFilteredList.length; i++) {
            if (this.viewFilteredList[i]._localUUID == lastEl._localUUID) {
              counter = 1;
              continue;
            }
            if (counter > 0 && appendListLength >= 52) {
              if (counter <= 52) {
                this.bulkMoneyIns.push(this.viewFilteredList[i])
              } else {
                break;
              }
              counter++;
            } else if(counter > 0 && appendListLength < 52) {
              if (counter <= appendListLength) {
                this.bulkMoneyIns.push(this.viewFilteredList[i])
              } else {
                break;
              }
              counter++;
            }
          }
          (event as InfiniteScrollCustomEvent).target.complete();
        }
      } catch (error) {
        SentryUtilites.setLog("MoneyInBulkDeleteComponent:loadMoreListData", error)
      }
    }

    async onDateRangeChange(){
      try {
        this.viewFilteredList = await this.allDataService?.moneyInService?.getByBillDateRange(this.fromStamp, this.toStamp) || [];
        this.viewFilteredList = this.viewFilteredList?.filter(x => !x?.deletedStamp);
        this.bulkMoneyIns = this.viewFilteredList.slice(0,50);
      } catch (error) {
        SentryUtilites.setLog("MoneyInBulkDeleteComponent:onDateRangeChange", error)
      }
    }
}

