<ion-row class="padding-none custom-row">
  <ion-col size="6" class="txt-left remove-padding-top-bottom">
    <ion-row>
      <ion-col size="12" class="add-bottom-padding">
        <ion-button fill="clear" class="btn-txt-black padding-start-none">
          {{ purchaseReturn?.party?.name }}
        </ion-button>
      </ion-col>
      <ion-col size="12" (click)="billView(purchaseReturn)">
        <ion-label color="primary">
          {{ (purchaseReturn?.totalAmount || 0) | currency:'INR' }}
        </ion-label>
      </ion-col>
      <ion-col size="12" *ngIf="!purchaseReturn?.deletedStamp">
        <!-- <ion-button fill="outline" class="outline-border font-12px ion-button-height ion-button-bottom-padding"
          color="success" *ngIf="getBalanceAmount(purchaseReturn?.moneyIns,purchaseReturn?.totalAmount)"
          (click)="addPayment(purchaseReturn)">
          + Add Payment
        </ion-button> -->
        <ion-button fill="outline"
          class="outline-border font-12px ion-button-height ion-button-bottom-padding" color="danger"
          *ngIf="getBalanceAmount(purchaseReturn?.moneyIns,purchaseReturn?.totalAmount)">
          {{ getBalanceAmount(purchaseReturn?.moneyIns,purchaseReturn?.totalAmount) | currency:'INR' }}
          Balance
        </ion-button>
        <ion-button fill="outline" class="outline-border font-12px ion-button-bottom-padding ion-button-height"
          color="success" *ngIf="getBalanceAmount(purchaseReturn?.moneyIns,purchaseReturn?.totalAmount)===0">
          Paid
        </ion-button>
        <ion-text color="danger" class="text-extra-money-in font-12px"
          *ngIf="extraMoneyInAmount(purchaseReturn?.moneyIns,purchaseReturn?.totalAmount)">
          Extra Money In Amount is <b>{{ extraMoneyInAmount(purchaseReturn?.moneyIns,purchaseReturn?.totalAmount) | currency:
            'INR' }}</b>
        </ion-text>
        <ion-button *ngIf="purchaseReturn?.linkedPurchaseUUID" (click)="viewPurchase(purchaseReturn)" fill="outline" color="primary"
          class="font-12px ion-button-height add-margin-left ion-button-bottom-padding outline-border">
          View Purchase
        </ion-button>
      </ion-col>
      <ion-col size="6" *ngIf="!purchaseReturn?.deletedStamp && purchaseReturn?.moneyIns?.length && isRecentTransactionList">
        <ion-button [fill]="purchaseReturn?.moneyIns[0]?.paymentMode=='bank' ? 'solid' : 'outline'"
          class="outline-border font-12px ion-button-height" color="primary" (click)="setPaymentMode('bank')">
          UPI/BANK
        </ion-button>
        <ion-button [fill]="purchaseReturn?.moneyIns[0]?.paymentMode=='cash' ? 'solid' : 'outline'"
          class="outline-border font-12px ion-button-height" color="primary" (click)="setPaymentMode('cash')">
          CASH
        </ion-button>
        <ion-button [fill]="purchaseReturn?.moneyIns[0]?.paymentMode=='cheque' ? 'solid' : 'outline'"
          class="outline-border font-12px ion-button-height" color="primary" (click)="setPaymentMode('cheque')">
          CHEQUE
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-col>
  <ion-col size="6" class="txt-right remove-padding-top-bottom">
    <ion-row>
      <ion-col size="12" (click)="billView(purchaseReturn)">
        <ion-button fill="clear" class="btn-txt-black padding-end-none">
          {{ purchaseReturn?.billNo }} | {{ purchaseReturn?.billDateStamp | date: 'dd/MM/yy' }}
        </ion-button>
      </ion-col>
      <ion-col size="12" *ngIf="!purchaseReturn?.deletedStamp">
        <ion-button title="Print" color="tertiary" fill="clear" (click)="billView(purchaseReturn)">
          <ion-icon name="print"></ion-icon>
        </ion-button>
        <!-- <ion-button title="Edit" color="primary" fill="clear" (click)="edit(purchaseReturn)">
          <ion-icon name="create"></ion-icon>
        </ion-button> -->
        <ion-button title="Delete" color="danger" fill="clear" (click)="delete(purchaseReturn)">
          <ion-icon name="trash"></ion-icon>
        </ion-button>
      </ion-col>
      <ion-col size-md="11" size-xs="10">
        <ion-label class="label-note">
          {{ (purchaseReturn?.userUpdatedStamp || purchaseReturn?.updatedStamp) > purchaseReturn?.createdStamp ? 'Edited by ' : 'Created by ' }} 
          {{ (purchaseReturn?.userUpdatedStamp || purchaseReturn?.updatedStamp) > purchaseReturn?.createdStamp ? purchaseReturn?.lastModifiedByName || purchaseReturn?.lastModifiedBy :  purchaseReturn?.createdByName || purchaseReturn?.createdBy }}
          on
          {{ (purchaseReturn?.userUpdatedStamp || purchaseReturn?.updatedStamp) | date: 'dd/MM/yy h:mm:ss a' }}
        </ion-label>
      </ion-col>
      <ion-col size-md="1" size-xs="2" (click)="billView(purchaseReturn)">
        <ion-col size="auto" offset="1">
          <div *ngIf="purchaseReturn?.syncStamp > (purchaseReturn?.userUpdatedStamp || purchaseReturn?.updatedStamp); then thenBlock else elseBlock"></div>
          <ng-template #thenBlock>
            <ion-button class="btn-popover" title="Data Uploaded" fill="clear">
              <ion-icon 
                name="checkmark-done-sharp" 
                size="small"
                color="success">
              </ion-icon>
            </ion-button>
          </ng-template>
          <ng-template #elseBlock>
            <ion-button class="btn-popover" title="Warning" fill="clear">
              <ion-icon 
                name="warning-sharp" 
                color="warning" 
                size="small">
              </ion-icon>
            </ion-button>
          </ng-template>
        </ion-col>
      </ion-col>
    </ion-row>
  </ion-col>
</ion-row>

<app-pin-verification (verifyTransactionPin)="verifyTransactionPIN($event)" #pinVerificationElement></app-pin-verification>