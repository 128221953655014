<ion-modal [ngClass]="{'mobile': isMobile}" class="modal-settings" [isOpen]="isModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar color="light">
        <ion-title>Settings</ion-title>
        <ion-text class="last-sync-time" color="medium">Last Sync at {{ lastSyncTime }}</ion-text>
        <ion-text *ngIf="isTimeDifference" class="last-sync-time timezone-warning alert-border" color="warning"> <i>Time Zone
            Mismatched</i> </ion-text>
        <ion-buttons slot="end">
          <ion-button (click)="openSettingsModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">
      <!-- <ng-container>
        <input [value]="color" [(colorPicker)]="color" [style.background]="color"(colorPickerChange)="color=$event"/>

      </ng-container> -->
      <ng-container *ngFor="let section of sections">

        <ion-button *ngIf="!viewSectionIds?.length || (viewSectionIds && viewSectionIds?.includes(section?.id))"
          color="tertiary" fill="clear" class="padding-end-none">
          {{ section?.header }}
        </ion-button>

        <ng-container *ngFor="let setting of section?.settings">

          <ion-row class="row-setting"
            *ngIf="setting?.hasOwnProperty('showSetting') ? (!viewSettingKeys?.length || (viewSettingKeys && viewSettingKeys?.includes(setting?.key))) && setting?.showSetting 
            : (!viewSettingKeys?.length || (viewSettingKeys && viewSettingKeys?.includes(setting?.key)))">

            <ng-container *ngIf="setting.inputType==='Toggle'">
              <ion-col class="padding-bottom-none" size-lg="10" size-xs="8">
                <span>{{ setting.title }}</span> <br>
                <span class="grey-color" *ngIf="!setting.toggleIgnore">
                {{ (profileObj?.iSetAssignStaffToTheSale && setting?.key === 'iSetQuickSaleStatus' && isOwner) ? 'Disabled' 
                : setting.value ? 'Enabled' 
                : 'Disabled' }}
                </span>
              </ion-col>
              <ion-col class="padding-bottom-none txt-right" size-lg="2" size-xs="4" *ngIf="!setting.toggleIgnore">
                <span>
                  <ion-toggle 
                    #toggleEle1 
                    color="tertiary" 
                    slot="end" 
                    *ngIf="!(profileObj?.iSetAssignStaffToTheSale && setting?.key === 'iSetQuickSaleStatus' && isOwner)"
                    [disabled]="(setting?.key == 'aAppPinStatus' && !isUserHasAccess) || 
                      (setting?.key == 'mcSendMessageToParty' && !isUserHaSendMessageToPartyAccess) || 
                      (setting?.key == 'mcSendMessageToOwner' && !isUserHaSendMessageToOwnerAccess)
                      "
                    [checked]="setting.value"
                    (ionChange)="setting?.sharedPreference ? onSharedPreferenceUpdate(setting, $event, toggleEle1?.checked, section?.id)
                      : (setting?.key=='aAppPinStatus') ? onAppPinStatusUpdate(setting, $event, toggleEle1?.checked, section?.id) 
                      : onSettingUpdate(setting, $event, toggleEle1?.checked, section?.id)"
                    (click)="(setting?.key=='aAppPinStatus') ? onClickSetting() : ''">
                  </ion-toggle>
                  <ion-toggle 
                    color="tertiary" 
                    slot="end" 
                    *ngIf="(profileObj?.iSetAssignStaffToTheSale && setting?.key === 'iSetQuickSaleStatus' && isOwner)"
                    [disabled]="true"
                    [checked]="false">
                  </ion-toggle>
                </span>
              </ion-col>
              <ion-col class="padding-top-none" *ngIf="setting?.description" size="12">
                <ng-container *ngIf="setting.isDescriptionInTextArea">
                  <ion-textarea class="border-none" rows="8" [value]="setting?.description"
                    [readonly]="true"></ion-textarea>
                </ng-container>
                <ng-container *ngIf="!setting.isDescriptionInTextArea">
                  <ion-label color="success">{{ setting.description }}</ion-label>
                </ng-container>
              </ion-col>

              <ng-container *ngIf="setting?.settings?.length && setting.value===true">

                <ion-col size="12">

                  <ion-row class="row-setting" *ngFor="let subSetting of setting?.settings">
                    <ng-container *ngIf="subSetting?.inputType==='InputBox'">
                      <ion-col class="padding-bottom-none" size="12">
                        <span>{{ subSetting?.title }}</span> <span class="mandatory" *ngIf="subSetting?.mandatory">*</span> <br>
                      </ion-col>
                      <ion-col *ngIf="subSetting?.description" size="12" class="padding-top-none">
                        <ion-label color="success">{{ subSetting?.description }}</ion-label>
                      </ion-col>
                      <ion-col class="padding-bottom-none" size="12">
                        <ion-input [type]="subSetting.dataType=='string' ? 'text' : 'number'"
                          (ionInput)="setInputMinMax(subSetting,$event)" [value]="subSetting?.value"
                          [disabled]="subSetting?.hasOwnProperty('disableSetting') ? subSetting?.disableSetting : false"
                          (ionChange)="subSetting?.sharedPreference ? onSharedPreferenceUpdate(subSetting,$event) : onSettingUpdate(subSetting,$event)" #inpVal></ion-input>

                          <!-- input validation start -->
                          <span 
                            *ngIf="subSetting?.mandatory && !(inpVal.value >= subSetting?.inputRange?.min+'') && inpVal.value < '0'" 
                            class="mandatory-text">
                            * This feild is required
                          </span>
                          <span 
                            *ngIf="subSetting?.mandatory && !(inpVal.value >= subSetting?.inputRange?.min+'') && (inpVal.value === '0')" 
                            class="mandatory-text">
                            This field cannot be zero
                          </span>

                          <!-- input validation End -->
                          
                      </ion-col>
                    </ng-container>

                    <ng-container *ngIf="subSetting?.inputType==='Toggle'">
                      <ion-col class="padding-bottom-none" size="10">
                        <span>{{ subSetting.title }}</span> <br>
                        <span class="grey-color">{{ subSetting.value ? 'Enabled' : 'Disabled' }}</span>
                      </ion-col>
                      <ion-col class="padding-bottom-none txt-right" size="2">
                        <span>
                          <ion-toggle
                            #toggleEle2
                            color="tertiary"
                            slot="end" 
                            [checked]="subSetting.value"
                            (ionChange)="subSetting?.sharedPreference ? onSharedPreferenceUpdate(subSetting, $event, toggleEle2?.checked, section?.id) 
                            : onSettingUpdate(subSetting, $event, toggleEle2?.checked, section?.id)">
                          </ion-toggle>
                        </span>
                      </ion-col>
                      <ion-col class="padding-top-none" *ngIf="subSetting?.description" size="12">
                        <ion-label color="success">{{ subSetting.description }}</ion-label>
                      </ion-col>
                    </ng-container>
                  </ion-row>

                </ion-col>


              </ng-container>

            </ng-container>

            <ng-container *ngIf="setting.inputType==='SelectBox'">
              <ion-col class="padding-bottom-none" size-lg="10" size-xs="8">
                <span>{{ setting.title }}</span> <br>
                <span class="grey-color" *ngIf="!setting.toggleIgnore">{{ setting.value ? 'Enabled' : 'Disabled'
                  }}</span>
              </ion-col>
              <ion-col class="padding-bottom-none txt-right" size-lg="2" size-xs="4" *ngIf="!setting.toggleIgnore">
                <span>
                  <ion-checkbox 
                    #checkboxEle1 
                    color="tertiary" 
                    slot="end" 
                    [checked]="setting.value"
                    (ionChange)="setting?.sharedPreference ? onSharedPreferenceUpdate(setting, $event, checkboxEle1?.checked, section?.id)
                      : (setting?.key=='aAppPinStatus') ? onAppPinStatusUpdate(setting, $event, checkboxEle1?.checked, section?.id) 
                      : onSettingUpdate(setting, $event, checkboxEle1?.checked, section?.id)"
                    (click)="(setting?.key=='aAppPinStatus') ? onClickSetting() : ''">
                  </ion-checkbox>
                </span>
              </ion-col>
              <ion-col class="padding-top-none" *ngIf="setting?.description" size="12">
                <ng-container *ngIf="setting.isDescriptionInTextArea">
                  <ion-textarea class="border-none" rows="8" [value]="setting?.description"
                    [readonly]="true"></ion-textarea>
                </ng-container>
                <ng-container *ngIf="!setting.isDescriptionInTextArea">
                  <ion-label color="success">{{ setting.description }}</ion-label>
                </ng-container>
              </ion-col>

            </ng-container>

            <ng-container *ngIf="setting.inputType==='SelectOption'">
              <ion-col class="padding-bottom-none" size-lg="12" size-xs="8">
                <span>{{ setting.title }}</span> <br>
                <ion-item class="select-option-ion-item" lines="none">
                  <ion-select
                    #selectOptionEle 
                    interface="alert"
                    [value]="setting?.value"
                    (ionChange)="setting?.sharedPreference ? onSharedPreferenceUpdate(setting, $event, selectOptionEle?.value, section?.id)
                    : (setting?.key=='aAppPinStatus') ? onAppPinStatusUpdate(setting, $event, selectOptionEle?.value, section?.id) 
                    : onSettingUpdate(setting, $event, selectOptionEle?.value, section?.id)"
                    >
                    <ion-select-option *ngFor="let option of setting?.selectOptions" [value]="option?.value">
                      {{ option?.title }}
                    </ion-select-option>
                  </ion-select>
                </ion-item>
              </ion-col>
            </ng-container>

            <ng-container *ngIf="setting.inputType==='RadioButton'">
              <ion-col class="padding-bottom-none" size-lg="12" size-xs="8">
                <span>{{ setting.title }}</span> <br>
                <ion-item class="ion-item-radio">
                  <ion-radio-group 
                    #radioButtonEle
                    [value]="setting?.value"
                    (ionChange)="setting?.sharedPreference ? onSharedPreferenceUpdate(setting, $event, radioButtonEle?.value, section?.id)
                    : (setting?.key=='aAppPinStatus') ? onAppPinStatusUpdate(setting, $event, radioButtonEle?.value, section?.id) 
                    : onSettingUpdate(setting, $event, radioButtonEle?.value, section?.id)"
                    >
                    <ion-item *ngFor="let option of setting?.selectOptions" >
                      <ion-radio [value]="option?.value"></ion-radio>
                      <ion-label>{{option?.title}}</ion-label>
                    </ion-item>
                  </ion-radio-group>
                </ion-item>
              </ion-col>
            </ng-container>

            <ng-container *ngIf="setting.inputType==='Segment'">
              <ion-col class="padding-bottom-none" size="12">
                <span>{{ setting.title }}</span> <br>
              </ion-col>
              <ion-col class="padding-top-none" *ngIf="setting?.description" size="12">
                <ion-label color="success">{{ setting.description }} {{ getCurrentSegmentDescription(setting?.segments)
                  }}</ion-label>
              </ion-col>
              <ion-col class="padding-top-none padding-bottom-none" size="12">
                <ion-item class="segment" lines="none">
                  <ion-segment [ngClass]="{'send-reminder': setting?.key == 'mcSendReminderMarketingMessageDays'}" mode="ios" [value]="setting.value"
                    (ionChange)="setting?.sharedPreference ? onSharedPreferenceUpdate(setting,$event) : onSettingUpdate(setting,$event)">
                    <ion-segment-button [value]="segmentBtn?.value" *ngFor="let segmentBtn of setting?.segments">
                      <ion-label>{{ segmentBtn?.title }}</ion-label>
                    </ion-segment-button>
                  </ion-segment>
                </ion-item>
              </ion-col>
            </ng-container>

            <ng-container *ngIf="setting?.inputType==='TextArea'">
              <ion-col class="padding-bottom-none" size="12">
                <span>{{ setting?.title }}</span> <br>
              </ion-col>
              <ion-col *ngIf="setting?.description" size="12" class="padding-top-none padding-bottom-none">
                <ion-label color="success">{{ setting?.description }}</ion-label>
              </ion-col>
              <ion-col class="padding-top-none padding-bottom-none" size="12">
                <ion-textarea rows="5" [value]="setting?.value"
                  (ionChange)="setting?.sharedPreference ? onSharedPreferenceUpdate(setting,$event) : onSettingUpdate(setting,$event)"></ion-textarea>
              </ion-col>
            </ng-container>

            <ng-container *ngIf="setting?.inputType==='InputBox'">
              <ion-col class="padding-bottom-none" size="12">
                <span>{{ setting?.title }}</span> <br>
              </ion-col>
              <ion-col class="padding-top-none padding-bottom-none" size="12">
                <ion-input [type]="setting.dataType=='string' ? 'text' : 'number'" [value]="setting?.value"
                  (ionInput)="setInputMinMax(setting,$event)"
                  (ionChange)="setting?.sharedPreference ? onSharedPreferenceUpdate(setting,$event) : onSettingUpdate(setting,$event)"></ion-input>
              </ion-col>
              <ion-col *ngIf="setting?.description" size="12">
                <ion-label color="success">{{ setting?.description }}</ion-label>
              </ion-col>
            </ng-container>
            <ng-container *ngIf="setting?.inputType==='ColorPicker'">
              <ion-col>
                <span>{{ setting?.title }}</span> <br>
                <span class="grey-color">{{ setting?.value }}</span>
              </ion-col>
              <ng-container *ngIf="setting?.settings">
                <ion-col size="12">
                  <ion-row class="row-setting" *ngFor="let subSetting of setting.settings">
                    <ng-container *ngIf="subSetting?.inputType ==='ColorPicker'">
                      <ion-col>
                        <span>{{ subSetting?.title }}</span><br>
                        <span class="grey-color">{{ subSetting?.value }}</span>
                      </ion-col>
                      <ion-col class="padding-bottom-none txt-right" size-lg="2" size-xs="2.5">
                        <span>
                          <ion-input class="input-color-picker" type="button" [cpPositionRelativeToArrow]="true"
                            [cpPosition]="'left'" [cpOutputFormat]="'hex'" [(colorPicker)]="subSetting.value"
                            [style.background]="subSetting.value"
                            (colorPickerChange)="subSetting?.sharedPreference ? onSharedPreferenceUpdate(subSetting,$event) : onSettingUpdate(subSetting,$event)"></ion-input>
                        </span>
                      </ion-col>
                    </ng-container>
                  </ion-row>
                </ion-col>
              </ng-container>
            </ng-container>


          </ion-row>

        </ng-container>

      </ng-container>

      <hr>

      <ion-row class="row-setting">
        <ion-col size="12" (click)="setFixedOTP()">
          Set Login PIN (Fixed OTP)
        </ion-col>
      </ion-row>


      <!-- Reset Login PIn -->
      <ion-row class="row-setting">
        <ion-col size="12" (click)="openResetFixedOTPModal()">
          Reset Login PIN (Fixed OTP)
        </ion-col>
      </ion-row>

      <ion-row class="row-setting">
        <ion-col size="12" (click)="activateOnlineShop()">
          Activate Online Shop
        </ion-col>
      </ion-row>

      <ion-row class="row-setting">
        <ion-col size="12" (click)="qrOnlineShop()">
          Qr for Online Shop
        </ion-col>
      </ion-row>

      <ion-row class="row-setting">
        <ion-col size="12" (click)="getMoreCustomers()">
          Get More Customers
        </ion-col>
      </ion-row>

    </ion-content>

    <ion-footer>
      <ion-toolbar>
        <ion-button class="btn-save" expand="full" (click)="save()">
          Save
        </ion-button>
      </ion-toolbar>
    </ion-footer>
  </ng-template>
</ion-modal>

<ion-modal class="modal-pin-reset" [isOpen]="isPinResetModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-title>PIN Reset OTP</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="openPinResetModal(false, false)" color="danger">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <ion-item>
          <ion-label>Enter OTP</ion-label>
      </ion-item>

      <div class="outerPinBox">
          <div class="pinBox">
            <input 
              class="pinEntry-text" 
              #inpPinResetOTPEle 
              (keyup.enter)="verifyPinResetOTP()" 
              type="text" 
              maxlength="4">
            <span class="box1"></span>
            <span class="box2"></span>
            <span class="box3"></span>
            <span class="box4"></span>
            <span class="box5"></span>
          </div>
      </div>
    </ion-content>

    <ion-toolbar>
      <ion-button 
        class="btn-save" 
        color="primary" 
        expand="full" 
        (click)="verifyPinResetOTP()">
        Verify
      </ion-button>
    </ion-toolbar>
  </ng-template>
</ion-modal>

<ion-modal class="modal-pin-generation" [isOpen]="isPinGenerationModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-title>PIN Generation</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="openPinGenerationModal(false)" color="danger">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">

      <ion-item>
          <ion-label>Enter New PIN</ion-label>
      </ion-item>

      <div class="outerPinBox">
          <div class="pinBox">
            <input 
              class="pinEntry-text" 
              type="text" 
              (keyup.enter)="matchPin()"
              maxlength="4" 
              #inpEnterNewPinEle>
            <span class="box1"></span>
            <span class="box2"></span>
            <span class="box3"></span>
            <span class="box4"></span>
            <span class="box5"></span>
          </div>
      </div>

      <ion-item>
          <ion-label>Re-enter New PIN</ion-label>
      </ion-item>

      <div class="outerPinBox">
          <div class="pinBox">
            <input 
              class="pinEntry-text" 
              type="text" 
              (keyup.enter)="matchPin()"
              maxlength="4" 
              #inpSecondPinEle>
            <span class="box1"></span>
            <span class="box2"></span>
            <span class="box3"></span>
            <span class="box4"></span>
            <span class="box5"></span>
          </div>
      </div>

    </ion-content>

    <ion-toolbar>
      <ion-button 
        class="btn-save" 
        color="primary" 
        expand="full" 
        (click)="matchPin()">
        Proceed
      </ion-button>
    </ion-toolbar>
  </ng-template>
</ion-modal>

<ion-modal class="modal-reset-fixed-otp" [isOpen]="isResetFixedOTPModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-title> Reset Login OTP (Fixed OTP)</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="openResetFixedOTPModal(false)" color="danger">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <ion-item>
          <ion-label>Enter OTP</ion-label>
      </ion-item>

      <div class="outerPinBox">
          <div class="pinBox">
            <input 
              class="pinEntry-text" 
              #inpResetFixedOTPEle 
              (keyup.enter)="verifyResetFixedOTP()" 
              type="text"
              maxlength="4">
            <span class="box1"></span>
            <span class="box2"></span>
            <span class="box3"></span>
            <span class="box4"></span>

          </div>
      </div>
    </ion-content>

    <ion-toolbar>
      <ion-button 
        class="btn-save" 
        color="primary" 
        expand="full" 
        (click)="verifyResetFixedOTP()">
        Reset
      </ion-button>
    </ion-toolbar>
  </ng-template>
</ion-modal>

<app-pin-verification #pinVerificationElement></app-pin-verification>