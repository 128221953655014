<ion-grid>

  <ion-row class="padding-5px">

    <!-- Side Category -->

    <ion-col size="3" class="col-categories">
      <ion-item lines="none" [class.active-item]="!appliedCategory" (click)="onCategoryChange('')">
        All
      </ion-item>
      <ion-item lines="none" *ngFor="let category of categories" [class.active-item]="category === appliedCategory"
        (click)="onCategoryChange(category)">
        {{ category }} ({{ categoryWisePartyCount[category] || 0 }}) 
      </ion-item>
    </ion-col>

    <!-- ----------------- -->


    <!-- Party Cards -->

    <ion-col size="9" class="dynamic-margin-left padding-top-none">

      <ion-row>

        <ion-col size="3" class="col-custom-row col-party" [style.background-color]="
          runningBillHashMap[party?._localUUID]?._localUUID
            ? runningBillHashMap[party?._localUUID]?.isPrintedKOT ? cssColors?.runningBillColor : cssColors?.kotBillColor
            : party?._localUUID===selectedParty?._localUUID ? '#E8F5E9' : '#FFFFFF'
          " *ngFor="let party of viewFilteredList" (click)="emitElement(party,runningBillHashMap[party?._localUUID]?._localUUID)">
          <ion-row class="padding-none">

            <ion-col size="12">
              <ion-button fill="clear" class="btn-party-name btn-txt-black padding-start-none minimum-btn-txt txt-transform-normal">
                {{ shortStr(party.name,25) }}
              </ion-button>
              <ion-icon *ngIf="party?.isFavourite" class="icon-star" color="warning" name="star"></ion-icon>
            </ion-col>

            <ion-col size="12">
              <ion-label [color]="party.credit>=0 ? 'success' : 'danger'"> <ion-icon class="icon-wallet-phone"
                  name="wallet" color="primary"></ion-icon> {{ MATHABS(party.credit) | currency: 'INR' }}</ion-label>
              <ion-label *ngIf="party?.phone" class="flt-rght" color="primary"> 
                <ion-icon class="icon-wallet-phone" name="call" color="primary"></ion-icon> 
                  {{ isMaskPhone ? stringMask(party?.phone) : party?.phone }} 
              </ion-label>
            </ion-col>

            <ion-col size="12" *ngIf="runningBillHashMap[party?._localUUID]?._localUUID">
              <ion-label class="flt-rght" color="tertiary"> {{ runningBillHashMap[party?._localUUID]?.totalAmount | currency: 'INR' }} </ion-label>
              <ion-label class="flt-rght margin-inline-end-10px"> {{ runningBillHashMap[party?._localUUID]?.isPrintedKOT ? 'Running Bill' : 'Pending KOT' }} </ion-label>
            </ion-col>

          </ion-row>
        </ion-col>

      </ion-row>

    </ion-col>

     <!-- -------------- -->

  </ion-row>

</ion-grid>

<ion-infinite-scroll threshold="10px" *ngIf="viewFilteredList?.length" (ionInfinite)="loadMoreListData($event)">
  <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Loading more data...">
  </ion-infinite-scroll-content>
</ion-infinite-scroll>
