import { Component, OnInit, AfterViewInit, EventEmitter, Output, ViewChild, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AlertController, IonInput, ToastController } from '@ionic/angular';
import { Image } from '../../models/image.model';
import { Item } from '../../models/Item.model';
import ItemCategory from '../../models/ItemCategory.model';
import { AllDataService } from '../../services/all-data.service';
import { Utility } from '../../utils/utility';
import { Utils } from '../../utils/utils';
import { Profile } from '../../models/Profile.model';
import { ItemSettingsComponent } from '../item-settings/item-settings.component';
import { ItemUnit } from '../../models/ItemUnit.model';
import { Subscription } from 'rxjs';
import { BarcodeGeneratorComponent } from '../barcode-generator/barcode-generator.component';
import { AccessControlService } from '../../services/auth/access-control.service';
import { IngredientSelectorComponent } from '../ingredient-selector/ingredient-selector.component';
import { ItemIngredient } from '../../models/ItemIngredient.mode';
import { environment } from '../../../environments/environment';
import { Validator } from '../../utils/validator';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ImageBase64Service } from '../../services/image-base64.service';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-item-form',
  templateUrl: './item-form.component.html',
  styleUrls: ['./item-form.component.scss'],
})
export class ItemFormComponent implements OnInit, AfterViewInit {

  @Input() isBillform: boolean = false;

  @Output() saveTrigger = new EventEmitter<Item>();
  @Output() updateTrigger = new EventEmitter<Item>();

  @ViewChild('itemNameEle') itemNameEle: IonInput;
  @ViewChild('primaryCustomUnit') primaryCustomUnit: IonInput;
  @ViewChild('secondaryCustomUnit') secondaryCustomUnit: IonInput;
  @ViewChild('categoryEle') categoryEle: IonInput;
  @ViewChild('itemSettingsEle') itemSettingsEle: ItemSettingsComponent;
  @ViewChild('barcodeGeneratorEle') barcodeGeneratorEle: BarcodeGeneratorComponent;
  @ViewChild('barcodeInputEle1') barcodeInputEle1: IonInput;
  @ViewChild('barcodeInputEle2') barcodeInputEle2: IonInput;
  @ViewChild('barcodeInputEle3') barcodeInputEle3: IonInput;
  @ViewChild('barcodeInputEle4') barcodeInputEle4: IonInput;
  @ViewChild('barcodeInputEle5') barcodeInputEle5: IonInput;
  @ViewChild('ingredientSelector') ingredientSelector: IngredientSelectorComponent;
  subscriptions: Subscription[] = [];

  shortStr = Utility.shortStr;
  maxDate = Utility.maxDateUpto50Year();

  selectedProfileId = Utility.getFromLocalStorage('selectedProfile');
  selectedProfileUserId = Utility.getFromLocalStorage('selectedProfileUserId');

  multiBarcodeConfig: {
    [key: string]: {
       inpNativeChild: string,
    },
  } = {
    barcode: {
      inpNativeChild: 'barcodeInputEle1',
    },
    barcode2: {
      inpNativeChild: 'barcodeInputEle2',
    },
    barcode3: {
      inpNativeChild: 'barcodeInputEle3',
    },
    barcode4: {
      inpNativeChild: 'barcodeInputEle4',
    },
    barcode5: {
      inpNativeChild: 'barcodeInputEle5',
    },
  };

  currentBarcodekey: string = 'barcode';

  /*
   Reactive Form
  */

  form: FormGroup;
  taxTypeOptions = Utility.taxTypeOptions;
  unitsOptions = {...Utility.unitAbvrMap};

  selectedTaxPercentagekey: string = null;
  selectedSpIncTax: string = 'true';


  //-------------------------------

  paramDocumentId: string = null;
  checkPlatform;
  fetchedItem: Item = null;

  categories: string[] = [];

  isOpenImageCropper = false;
  keepDateContentsMounted: boolean = false;

  imagesBase64: {
    imageUUID: string;
    base64: string;
    imageUrl?: string;
  }[] = [];

  showAllFields = false;

  duplicateItemByName: Item = null;

  selectedProfile: Profile = null;

  routerSub: Subscription;

  isBarcodeGeneratorModal = false;

  isCreateItemCategoryPermission = true;

  isExpiryDateModalOpen = false;

  dpExpiryDateValue: string = null;

  isBlockSave: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private allDataService: AllDataService,
    private imageBase64Service: ImageBase64Service,
    private route: ActivatedRoute,
    private alertController: AlertController,
    private accessControlService: AccessControlService,
    private toastController: ToastController,
  ) { }

  async ngOnInit() {
    try {
      this.getSelectedProfile();
      this.getParamDocumentId();
      this.initializeReactiveForm();
      this.getCategories();
      this.getCustomUnit();
      this.populateForm();
      this.setCreateItemCategoryPermission();
      this.checkPlatform = window?.navigator;
    } catch (error) {
      SentryUtilites.setLog("ItemFormComponent:ngOnInit", error)
    }
  }

  ngAfterViewInit(): void {
    try {
      this.isBlockSave = false;
      this.activateEnterKeyListener();
      setTimeout(() => {
        this.itemNameEle?.setFocus();
      }, 200);
    } catch (error) {
      SentryUtilites.setLog("ItemFormComponent:ngAfterViewInit", error)
    }
  }

  ngOnDestroy() {
    try {
      this.deactivateEnterKeyListener();
      this.subscriptions?.forEach(sub => sub?.unsubscribe());
    } catch (error) {
      SentryUtilites.setLog("ItemFormComponent:ngOnDestroy", error)
    }
  }

  async setCreateItemCategoryPermission() {
    this.isCreateItemCategoryPermission = await this.accessControlService.isUserHasAccess({action:'createItemCategory'});
  }

  enterKeyFn = (keyboardEvent: KeyboardEvent) => {
    try {
      if(
        keyboardEvent?.code === "Enter"
        && this.form?.valid
        &&
        (
          document?.getElementById('barcodeInputEle1')?.firstChild != document?.activeElement
          && document?.getElementById('barcodeInputEle2')?.firstChild != document?.activeElement
          && document?.getElementById('barcodeInputEle3')?.firstChild != document?.activeElement
          && document?.getElementById('barcodeInputEle4')?.firstChild != document?.activeElement
          && document?.getElementById('barcodeInputEle5')?.firstChild != document?.activeElement
        )
      ) {
        this.presentSameItemNameConfirm();
      }
    } catch (error) {
      SentryUtilites.setLog("ItemFormComponent:enterKeyFn", error)
    }
  }

  activateEnterKeyListener() {
    document.addEventListener('keyup', this.enterKeyFn, true);
  }

  deactivateEnterKeyListener() {
    document.removeEventListener('keyup', this.enterKeyFn, true);
  }

  openExpiryDatePicker() {
    this.keepDateContentsMounted = true;
    this.isExpiryDateModalOpen = true;
  }

  closeDOBPicker(val) {
    this.isExpiryDateModalOpen = false;
  }

  onExpiryDateSelect(event) {
    try {
      if(event?.detail?.value) {
        this.dpExpiryDateValue = event?.detail?.value;
        this.form?.controls?.expiryDate?.setValue(
          +new Date(event?.detail?.value).setHours(0, 0, 0, 0)
        );
        Utility.closeDatePicker(event?.target);
      }
    } catch (error) {
      SentryUtilites.setLog("ItemFormComponent:onExpiryDateSelect", error)
    }
  }

  onCancelExpiryDate(event) {
    try {
      if(this.form?.controls?.expiryDate?.value) {
        event.target.reset(Utility.ionDatePickerFormattedString(this.form?.controls?.expiryDate?.value));
      } else {
        event.target.reset(null);
      }
    } catch (error) {
      SentryUtilites.setLog("ItemFormComponent:onCancelExpiryDate", error)
    }
  }

  openBarcodeGeneratorModal(currentBarcodekey: string, value: boolean = true) {
    try {
      this.currentBarcodekey = currentBarcodekey;
      this.barcodeGeneratorEle?.openModal(value);
    } catch (error) {
      SentryUtilites.setLog("ItemFormComponent:openBarcodeGeneratorModal", error)
    }
  }

  // Fetch selected profile
  async getSelectedProfile() {
    this.selectedProfile = await this.allDataService.profileService.getCurrentProfile();
  }

  getParamDocumentId() {
    this.paramDocumentId = this.route.snapshot.paramMap.get('documentId');
  }

   initializeReactiveForm() {
    try {
      this.form = this.formBuilder.group({
        itemName: [null, [Validators.required, Validators.pattern(Validator.noSpaceAtStart)]],
        sellPrice: [null, [Validators.required,Validators.pattern(/\d*[0-9]\d*/)]],
        primaryUnit: [null],
        secondaryUnit: [null],
        convertRatio: [null],
        convertRatioR: [null],
        purchasePrice: [null],
        category: [null],
        type: ['product'],
        onlineDeliverySellPrice: [null],
        onlineSellPrice: [null],
        acSellPrice: [null],
        nonAcSellPrice: [null],
        mrp: [null],
        expiryDate: [null],
        brandName: [null],
        wholesalePrice: [null],
        wholesaleMinCutoffQty: [null],
        itemCode: [null],
        barcode: [null],
        barcode2: [null],
        barcode3: [null],
        barcode4: [null],
        barcode5: [null],
        hsn: [null],
        description: [null],
        minStock: [null],
        storageLocation: [null],
        onlineDukanItem: [true],
        isTaxExempted: [0],
        isTaxZero: [0],
        isNonGstSupplies: [0],
        taxPercentage: [null],
        cessPercentage: [null],
        spIncTax: [true],
        images: [[]],
        itemIngredients: [[]],
      });
  
      // No Negative Value
  
      let arr = ["sellPrice","purchasePrice","mrp","onlineDeliverySellPrice","onlineSellPrice","acSellPrice","nonAcSellPrice","wholesalePrice","wholesaleMinCutoffQty"];
  
      arr?.forEach(key => {
        this.subscriptions.push(this.form.get(key).valueChanges.pipe(distinctUntilChanged(), debounceTime(300)).subscribe(value => {
          if(value != null && value < 0) {
            this.form.patchValue({[key]: Math.abs(value)});
          }
        }));
      });
  
      this.subscriptions.push(this.form.get('secondaryUnit').valueChanges.pipe(distinctUntilChanged(), debounceTime(300)).subscribe(async (value) => {
        if( value && !this.form.get('primaryUnit').value || value && !this.form.get('convertRatio').value) {
          this.form.get('primaryUnit').setValidators(Validators.required);
          this.form.get('primaryUnit').updateValueAndValidity();
          if(value !== 'null') {
            this.form.get('convertRatio').setValidators(Validators.required);
            this.form.get('convertRatio').updateValueAndValidity();
            this.form.get('convertRatioR').setValidators(Validators.required);
            this.form.get('convertRatioR').updateValueAndValidity();
          }
          if(value && !this.form.get('primaryUnit').value) {
            const toast = await this.toastController.create({
              message: 'Primary Unit and Secondary Unit depends on each other.',
              duration: 2000,
              color: 'danger',
            });
            await toast.present();
          }
        }
      }));
  
      this.subscriptions.push(this.form.get('primaryUnit').valueChanges.pipe(distinctUntilChanged(), debounceTime(300)).subscribe(value => {
        if(value && !this.form.get('secondaryUnit').value || this.form.get('convertRatio').value > 0 || value) {
          this.form.get('primaryUnit').clearValidators();
        } else if(value != null && value != '') {
          this.form.get('primaryUnit').setValidators([Validators.required]);
          this.form.get('primaryUnit').updateValueAndValidity();
        }
      })) ;
  
      this.subscriptions.push(this.form.get('convertRatio').valueChanges.pipe(distinctUntilChanged(), debounceTime(300)).subscribe(value => {
        if(
          this.form.get('primaryUnit').value && this.form.get('secondaryUnit').value && this.form.get('secondaryUnit').value != 'null' && this.form.get('convertRatio').value && this.form.get('convertRatioR').value == Infinity ||
          this.form.get('primaryUnit').value && this.form.get('secondaryUnit').value && this.form.get('secondaryUnit').value != 'null' && this.form.get('convertRatioR').value && this.form.get('convertRatio').value == Infinity
        ) {
        this.form.get('convertRatio').clearValidators();
        } else if(
          value == Infinity &&
          this.form.get('convertRatioR').value == null ||
          value == null &&
          this.form.get('convertRatioR').value == Infinity ||
          value != null || 
          value == Infinity
          ){
        this.form.get('convertRatio').setValidators([Validators.required]);
          this.form.get('convertRatio').updateValueAndValidity();
        }
      }));
  
      this.subscriptions.push(this.form.get('convertRatioR').valueChanges.pipe(distinctUntilChanged(), debounceTime(300)).subscribe(value => {
        if(
          this.form.get('primaryUnit').value && this.form.get('secondaryUnit').value && this.form.get('secondaryUnit').value != 'null' && this.form.get('convertRatio').value && this.form.get('convertRatioR').value == Infinity ||
          this.form.get('primaryUnit').value && this.form.get('secondaryUnit').value && this.form.get('secondaryUnit').value != 'null' && this.form.get('convertRatioR').value && this.form.get('convertRatio').value == Infinity
        ) {
        this.form.get('convertRatioR').clearValidators();
        } else if(
          value == Infinity &&
          this.form.get('convertRatio').value == null ||
          value == null &&
          this.form.get('convertRatio').value == Infinity ||
          value != null ||
          value == Infinity
        ) {
        this.form.get('convertRatioR').setValidators([Validators.required]);
          this.form.get('convertRatio').updateValueAndValidity();
        }
      }));
    } catch (error) {
      SentryUtilites.setLog("ItemFormComponent:initializeReactiveForm", error)
    }

  }

  async populateForm() {
    try {
      if (this.paramDocumentId) {
        this.fetchedItem = await this.allDataService.itemService.getByUUID(this.paramDocumentId);
        if(this.fetchedItem?._localUUID) {
          this.fetchedItem.type = this.fetchedItem?.type ? this.fetchedItem?.type?.toLowerCase() as any : '';
          this.form.patchValue(this.fetchedItem);
          if(this.fetchedItem?.expiryDate) {
            this.dpExpiryDateValue = Utility.ionDatePickerFormattedString(this.fetchedItem?.expiryDate);
          }
          this.keepDateContentsMounted = true;
          if(!this.form?.value?.taxPercentage && this.form?.value?.isTaxExempted && !this.form?.value?.isTaxZero && !this.form?.value?.isNonGstSupplies) {
            this.selectedTaxPercentagekey = 'Exempted';
          }else if(!this.form?.value?.taxPercentage && !this.form?.value?.isTaxExempted && this.form?.value?.isTaxZero && !this.form?.value?.isNonGstSupplies) {
            this.selectedTaxPercentagekey = '0%';
          }else if(!this.form?.value?.taxPercentage && !this.form?.value?.isTaxExempted && !this.form?.value?.isTaxZero && this.form?.value?.isNonGstSupplies){
            this.selectedTaxPercentagekey = 'Non-GST Supplies';
          }else {
            this.selectedTaxPercentagekey = this.form?.value?.taxPercentage + '%';
          }
          if(this.form?.value?.spIncTax === true) {
            this.selectedSpIncTax = 'true';
          }else if(this.form?.value?.spIncTax === false) {
            this.selectedSpIncTax = 'false';
          }
          if(!this.form.get('primaryUnit').value && this.form.get('secondaryUnit').value) {
            this.form.get('primaryUnit').setValidators([Validators.required]);
            this.form.get('primaryUnit').updateValueAndValidity()
          }
          this.loadImages();
        }
      }
    } catch (error) {
      SentryUtilites.setLog("ItemFormComponent:populateForm", error)
    }
  }
  
  async getCategories() {
    try {
      let itemCategories=await this.allDataService.itemCategoryService.getAllByPromise() || [];
      if (itemCategories?.length) {
        this.categories = itemCategories.map(x => x?.name);
      }
    } catch (error) {
      SentryUtilites.setLog("ItemFormComponent:getCategories", error)
    }
  }

  async getCustomUnit() {
    try {
      let itemUnits=await this.allDataService.itemUnitService.getAllByPromise() || [];
      if (itemUnits?.length) {
        itemUnits?.forEach(itemUnit => this.unitsOptions[itemUnit?.name] = itemUnit?.name);
      }
    } catch (error) {
      SentryUtilites.setLog("ItemFormComponent:getCustomUnit", error)
    }
  }

  onTaxSelect(event) {
    try {
      let value = event?.detail?.value;
      if (value == 'Exempted') {
        this.form.patchValue({
          isTaxExempted: 1,
          isTaxZero: 0,
          isNonGstSupplies: 0,
          taxPercentage: Utility.taxTypeOptions[value],
        });
      } else if (value == '0%') {
        this.form.patchValue({
          isTaxExempted: 0,
          isTaxZero: 1,
          isNonGstSupplies: 0,
          taxPercentage: Utility.taxTypeOptions[value],
        });
      }else if(value == "Non-GST Supplies"){
        this.form.patchValue({
          isTaxExempted: 0,
          isTaxZero: 0,
          isNonGstSupplies: 1,
          taxPercentage: Utility.taxTypeOptions[value],
        });
      }else {
        this.form.patchValue({
          isTaxExempted: 0,
          isTaxZero: 0,
          isNonGstSupplies: 0,
          taxPercentage: Utility.taxTypeOptions[value],
        });
      }
    } catch (error) {
      SentryUtilites.setLog("ItemFormComponent:onTaxSelect", error)
    }
    
  }

  /**
   * 
   * @param event : on segment change provide CustomEvent object
   * @description : convert string value of true, false into boolean value
   */
  onSpIncTaxChange(event: CustomEventInit) {
    try {
      if(event?.detail?.value===true || event?.detail?.value==='true') {
        this.form?.patchValue({spIncTax: true});
      }else if(event?.detail?.value===false || event?.detail?.value==='false'){
        this.form?.patchValue({spIncTax: false});
      }
    } catch (error) {
      SentryUtilites.setLog("ItemFormComponent:onSpIncTaxChange", error)
    }
  }
  // ---------------------------------------

  /**
   * 
   * @param event : on segment change provide CustomEvent object
   * @description : convert string value of true, false into boolean value
   */
  onOnlineDukanItemChange(event: CustomEventInit) {
    try {
      if(event?.detail?.value===true || event?.detail?.value==='true') {
        this.form?.patchValue({onlineDukanItem: true});
      }else if(event?.detail?.value===false || event?.detail?.value==='false'){
        this.form?.patchValue({onlineDukanItem: false});
      }
    } catch (error) {
      SentryUtilites.setLog("ItemFormComponent:onOnlineDukanItemChange", error)
    }
  }
  // ---------------------------------------

  onSaveClick() {
    try {
      if (this.paramDocumentId && !this.isBillform) {
        this.update();
      } else {
        this.save();
      }
      this.dpExpiryDateValue = null;
      this.keepDateContentsMounted = false;
    } catch (error) {
      SentryUtilites.setLog("ItemFormComponent:onSaveClick", error)
    }
  }

  async save(preventRouting: boolean = false) {
    try {
      if (this.form.valid && !this.isBlockSave) {
        this.isBlockSave = true;
        let item = new Item();
        item = { ...this.form.value };
        let savedItem = await this.allDataService.itemService.save(item);
        if (savedItem?._localUUID && !preventRouting) {
          this.allDataService.listForceReloadSubs.next('item-list');
          this.saveTrigger.emit(savedItem);
          setTimeout(() => {
            this.isBlockSave = false;
          }, 5000);
        }else {
          this.isBlockSave = false;
        }
        return savedItem;
      }
    } catch (error) {
      SentryUtilites.setLog("ItemFormComponent:save", error)
      return null;
    }
  }

  async update(preventRouting: boolean = false) {
    try {
      if (this.form.valid && !this.isBlockSave) {
        this.isBlockSave = true;
        this.fetchedItem = {
          ...this.fetchedItem,
          ...this.form.value
        }
        this.allDataService.listForceReloadSubs.next('item-list');
        let updatedItem = await this.allDataService.itemService.update(this.fetchedItem);
        if (updatedItem?._localUUID && !preventRouting) {
          this.allDataService.listForceReloadSubs.next('item-list');
          this.updateTrigger.emit(updatedItem);
          setTimeout(() => {
            this.isBlockSave = false;
          }, 5000);
        }else {
          this.isBlockSave = false;
        }
        return updatedItem;
      }
    } catch (error) {
      SentryUtilites.setLog("ItemFormComponent:update", error)
      return null;
    }
  }

  async addCategory() {
    try {
      if(this.categoryEle) {
        const categoryName: string = (this.categoryEle?.value as string).toLowerCase().trim();
        if(categoryName) {
          if(this.categories?.findIndex(x => x?.toLowerCase() === categoryName) === -1) {
            let category = new ItemCategory();
            category.name = categoryName;
            let savedCategory = await this.allDataService.itemCategoryService.save(category);
            if(savedCategory?._localUUID) {
              this.allDataService.listForceReloadSubs.next('item-category-list');
              this.categories.push(categoryName);
            }else {
              return null;
            }
          } else {
            const toast = await this.toastController.create({
              header: "Item category already exits",
              duration: 2000,
              position: 'top',
              mode: 'ios',
              color: 'warning',
            });
            await toast.present();
          }
          this.form?.patchValue({ category: categoryName });
          this.categoryEle.value = '';
        }
      }
    } catch (error) {
      SentryUtilites.setLog("ItemFormComponent:addCategory", error)
    }
  }

  returnZero = () => 0;

  openImageCropper(value: boolean = true) {
    this.isOpenImageCropper = value;
  }

  removeImage(imageUUId: string) {
    try {
      let formImages: string[] = this.form?.value?.images;
      const index = formImages.findIndex(uuid => uuid === imageUUId);
      if(index != -1) {
        formImages.splice(index,1);
        this.form?.patchValue({
          images: formImages
        });
      }
      const indx = this.imagesBase64.findIndex(imageData => imageData?.imageUUID === imageUUId);
      if(indx != -1) {
        this.imagesBase64.splice(indx,1);
      }
    } catch (error) {
      SentryUtilites.setLog("ItemFormComponent:removeImage", error)
    }
  }

  async onImageSaved(image: Image) {
    try {
      image = await this.allDataService.imageService.getByUUID(image._localUUID);
      this.form?.value?.images?.push(image._localUUID);
      if(image?.imageBase64) {
        this.imagesBase64.push({
          imageUUID: image._localUUID,
          base64: image.imageBase64,
        });
      }
    } catch (error) {
      SentryUtilites.setLog("ItemFormComponent:onImageSaved", error)
    }
  }

  async loadImages() {
    try {
      if(this.fetchedItem?.images?.length) {
        for (let i = 0; i < this.fetchedItem?.images?.length; i++) {
          const imageId = this.fetchedItem?.images[i];
          const image = await this.allDataService.imageService.getByUUID(imageId);
          let base64 = await this.imageBase64Service.getBase64FromDBorServerWithoutSave(image);
          if(base64 && image && this.imagesBase64.findIndex(x => x?.imageUUID === image?._localUUID) === -1) {
            this.imagesBase64.push({
              imageUUID: image?._localUUID,
              base64,
            });
          }
        }
      }
    } catch (error) {
      SentryUtilites.setLog("ItemFormComponent:loadImages", error)
    }
  }

  toggleShowAllFields() {
    this.showAllFields = !this.showAllFields;
  }

  openSettingsModal() {
    this.itemSettingsEle?.openSettingsModal();
  }

  onSettingSave(event) {
    event && this.getSelectedProfile();
  }

  async onItemNameChange() {
    try {
      const allItems = await this.allDataService.itemService.getAllByPromise();
      const matchedItems = allItems?.filter(item => item.itemName?.toLowerCase()===this.form?.value?.itemName?.toLowerCase());
      if(!matchedItems?.length || (this.paramDocumentId && matchedItems?.length === 1)) {
        this.duplicateItemByName = null;
      }else if(matchedItems?.length) {
        this.duplicateItemByName = matchedItems[0];
      }
    } catch (error) {
      SentryUtilites.setLog("ItemFormComponent:onItemNameChange", error)
    }
  }

  async presentSameItemNameConfirm() {
    try {
      if(!this.paramDocumentId && this.duplicateItemByName?._localUUID)  {
        const alert = await this.alertController.create({
          header: 'Warning!',
          message: 'Item with same name is already exists. Do you still want create new item with the same name?',
          mode: 'ios',
          buttons: [
            {
              text: 'No',
              role: 'cancel',
            },
            {
              text: 'Yes',
              role: 'confirm',
              handler: this.onSaveClick.bind(this),
            },
          ],
        });
  
        await alert.present();
      }else {
        this.onSaveClick();
      }
    } catch (error) {
      SentryUtilites.setLog("ItemFormComponent:presentSameItemNameConfirm", error)
    }
  }

  async populateBarcode(value:string) {
    try {
      this.form?.patchValue({[this.currentBarcodekey]: value})
      // if(this.fetchedItem?._localUUID) {
      //   this.fetchedItem = await this.update(true)
      // }else {
      //   this.fetchedItem = await this.save(true);
      //   this.paramDocumentId = this.fetchedItem?._localUUID;
      // }
    } catch (error) {
      SentryUtilites.setLog("ItemFormComponent:populateBarcode", error)
    }
  }

  preventEmptyString(formControlName: string) {
    this.form?.patchValue({[formControlName]: Utility.preventEmptyString(this.form?.value[formControlName])});
  }

  openIngredientSelector() {
    this.ingredientSelector?.openModal();
  }

  saveIngredients(itemIngredients: ItemIngredient[]) {
    this.form?.patchValue({itemIngredients});
  }

  async addCustomUnit(elementRefKey: string, formControlName: string) {
    try {
      if(this[elementRefKey]) {
        const unitName: string = this[elementRefKey]?.value as string;
        if(unitName) {
          if(this.unitsOptions[unitName]?.toLowerCase() !== unitName?.toLowerCase()) {
            let itemUnit = new ItemUnit();
            itemUnit.name = unitName;
            let savedItemUnit = await this.allDataService.itemUnitService.save(itemUnit);
            if(savedItemUnit?._localUUID) {
              this.allDataService.listForceReloadSubs.next('item-unit-list');
              this.unitsOptions[unitName] = unitName;
            }else {
              return null;
            }
          }
          this.form?.patchValue({[formControlName]: this.unitsOptions[unitName]});
          this[elementRefKey].value = '';
        }
      }
    } catch (error) {
      SentryUtilites.setLog("ItemFormComponent:addCustomUnit", error)
    }
  }

  selectedRatioInputBox: "CONVERT_RATIO" | "CONVERT_RATIO_R" = null
  currentlyActiveRatioInput(whoStarted: "CONVERT_RATIO" | "CONVERT_RATIO_R") {
    this.selectedRatioInputBox = whoStarted;
  }

  calculateBulkPurchaseRatio(whoStarted: "CONVERT_RATIO" | "CONVERT_RATIO_R",event) {
    try {
      if(whoStarted === this.selectedRatioInputBox) {
        if(whoStarted === "CONVERT_RATIO") {
          let convertRatio = this.form?.value?.convertRatio;
          this.form?.patchValue({
            convertRatioR: Utils.capFractionsToSix(1 / convertRatio)
          });
        }else if(whoStarted === "CONVERT_RATIO_R") {
          let convertRatioR = this.form?.value?.convertRatioR;
          this.form?.patchValue({
            convertRatio: Utils.capFractionsToSix(1 / convertRatioR)
          });
        }
      }
    } catch (error) {
      SentryUtilites.setLog("ItemFormComponent:calculateBulkPurchaseRatio", error)
    }
  }

}
