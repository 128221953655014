import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appHorizontalScroll]',
})
export class HorizontalScrollDirective {
  constructor(private element: ElementRef) {
    const style = document.createElement('style');
    style.textContent = `
    .inner-scroll {
      overflow-x: scroll !important;
    }
    `;
    this.element?.nativeElement?.shadowRoot?.appendChild(style);
  }
}
