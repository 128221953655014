import { BehaviorSubject } from "rxjs";
import { MoneyIn } from "../models/MoneyIn.model";
import { Utility } from "../utils/utility";
import { ExpressServerService } from "./api/express-server.service";
import { IDataService } from "../../interface/IDataService.interface";
import { PartyDao } from "./dao/party.dao";
import { MoneyInDao } from "./dao/money-in.dao";
import { SaleDao } from "./dao/sale.dao";
import { AuthService } from "./auth/auth.service";
import { MonthWisePartyCreditDao } from "./dao/month-wise-party-credit.dao";
import { SentryUtilites } from "../utils/sentryUtilites";
import { PurchaseReturnDao } from "./dao/purchase-return.dao";


export class MoneyInService implements IDataService<MoneyIn>{
  private static _instance: MoneyInService;

  public static getInstance(
    dao: MoneyInDao,
    partyDao: PartyDao,
    saleDao: SaleDao,
    purchaseReturnDeo: PurchaseReturnDao,
    expressServerService: ExpressServerService,
    authService: AuthService,
    monthWisePartyCreditDao: MonthWisePartyCreditDao,
  ) {
    if (!this._instance) {
      this._instance = new MoneyInService(
        dao, 
        partyDao, 
        saleDao, 
        purchaseReturnDeo,
        expressServerService,
        authService,
        monthWisePartyCreditDao,
        )
      this._instance.initService();
    }
    this._instance.reloadList();
    return this._instance;
  }

  constructor(
    dao: MoneyInDao,
    partyDao: PartyDao,
    saleDao: SaleDao,
    purchaseReturnDao: PurchaseReturnDao,
    expressServerService: ExpressServerService,
    authService: AuthService,
    monthWisePartyCreditDao: MonthWisePartyCreditDao,
  ) {
    this.dao = dao;
    this.partyDao = partyDao;
    this.saleDao = saleDao;
    this.purchaseReturnDao = purchaseReturnDao;
    this.expressServerService = expressServerService;
    this.authService = authService;
    this.monthWisePartyCreditDao = monthWisePartyCreditDao;
  }
  dao: MoneyInDao;
  partyDao: PartyDao;
  saleDao: SaleDao;
  purchaseReturnDao: PurchaseReturnDao;
  expressServerService: ExpressServerService;
  authService: AuthService;
  monthWisePartyCreditDao: MonthWisePartyCreditDao;

  LIST_REFRESH_RATE = 1000;
  selectedProfileId: string = null;
  selectedProfileUserId: string = null;
  updateSubs = new BehaviorSubject<MoneyIn>(null);

  lastReloadStamp: number = 0;
  isReloadPostpond = false;

  initService() {
    this.selectedProfileId = Utility.getFromLocalStorage('selectedProfile');
    this.selectedProfileUserId = Utility.getFromLocalStorage('selectedProfileUserId');
    this.reloadList();
  }

  async reloadList() {
    try {
      if (this.isReloadPostpond) {
        return;
      }
      const currentStamp = +new Date();
      if (this.lastReloadStamp < (currentStamp - this.LIST_REFRESH_RATE)) {
        this.lastReloadStamp = currentStamp;
        this.trySyncUnsynced();
      } else {
        this.isReloadPostpond = true;
        setTimeout(() => {
          this.isReloadPostpond = false;
          this.reloadList();
        }, this.LIST_REFRESH_RATE + 100);
      }
    } catch (error) {
      SentryUtilites.setLog("MoneyInService:reloadList", error)
      return null;
    }
  }

  getAll() {
    return this.dao.getAll();
  }
  
  getAllByPromise() {
    return this.dao.getAllByProfile(this.selectedProfileId);
  }

  getAllByPromiseByProfile(profileId: string) {
    return this.dao.getAllByProfile(profileId);
  }

  getAllWithDeletedByProfile() {
    return this.dao.getAllWithDeletedByProfile(this.selectedProfileId);
  }

  getByBillDateRange(startTime: number, endTime: number, profileId?: string): Promise<MoneyIn[]> {
    return new Promise(async (resolve, reject) => {
      try {
        let allDocs = await this.dao.getAllByProfile(profileId || this.selectedProfileId);
        if (allDocs != null) {
          let filteredDocs = allDocs.filter(doc => doc?.billDateStamp >= startTime && doc?.billDateStamp < endTime);
          return resolve(filteredDocs);
        } else {
          return resolve(null);
        }
      } catch (error) {
        SentryUtilites.setLog("MoneyInService:getByBillDateRange", error)
        return resolve(null);
      }
    });
  }

  getByCreatedDateRange(startTime: number, endTime: number): Promise<MoneyIn[]> {
    return new Promise(async (resolve, reject) => {
      try {
        let allDocs = await this.dao.getAllByProfile(this.selectedProfileId);
        if (allDocs != null) {
          let filteredDocs = allDocs.filter(doc => doc?.createdStamp >= startTime && doc?.createdStamp < endTime);
          return resolve(filteredDocs);
        } else {
          return resolve(null);
        }
      } catch (error) {
        SentryUtilites.setLog("MoneyInService:getByCreatedDateRange", error)
        return resolve(null);
      }
    });
  }

  getById(id: number): Promise<MoneyIn> {
    return this.dao.getById(id);
  }

  getByUUID(uuid: string): Promise<MoneyIn> {
    return this.dao.getByUUID(uuid);
  }


  save(moneyIn: MoneyIn): Promise<MoneyIn> {
    if(Utility.isTruthy(moneyIn)) {
      moneyIn.userId = this.selectedProfileUserId;
      if(!moneyIn.profileId) {
        moneyIn.profileId = this.selectedProfileId;
      }
      moneyIn.createdBy = moneyIn.lastModifiedBy = this.authService.getLoginPhone();
      moneyIn.createdByName = moneyIn.lastModifiedByName = Utility.getCreatedByName();
  
      return new Promise(async (resolve, reject) => {
        try {
          let savedMoneyIn = await this.dao.save(moneyIn);
          if (savedMoneyIn?._localUUID) {
            let totalAmount = moneyIn?.totalAmount || 0.0
            if (totalAmount > 0.0) {
              await this.partyDao.updateCredit(
                moneyIn?.party,
                -totalAmount
              )
              await this.monthWisePartyCreditDao?.modifyCredit(
                savedMoneyIn?.party?._localUUID,
                savedMoneyIn?.billDateStamp,
                -totalAmount
              )
            }
    
            if(moneyIn?.linkedSaleUUID) {
              this.saleDao.linkMoneyIn(moneyIn?.linkedSaleUUID,moneyIn);
            }
  
            if(moneyIn?.linkedPurchaseReturnUUID) {
              this.purchaseReturnDao.linkMoneyIn(moneyIn?.linkedPurchaseReturnUUID,moneyIn);
            }
    
          }
          this.reloadList();
          return resolve(savedMoneyIn);
        } catch (error) {
          SentryUtilites.setLog("MoneyInService:save", error)
          return resolve(null);
        }
      });
    } else {
      return null;
    }
  }

  update(moneyIn: MoneyIn): Promise<MoneyIn> {
    return new Promise(async (resolve, reject) => {
      try {
        if (moneyIn?._localUUID) {
          moneyIn.lastModifiedBy = this.authService.getLoginPhone();
          moneyIn.lastModifiedByName = Utility.getCreatedByName();
          let oldMoneyIn = await this.getByUUID(moneyIn._localUUID);
  
          if (
            oldMoneyIn?.party?._localUUID != null
            && moneyIn?.party?._localUUID != null
            && oldMoneyIn?.party?._localUUID != ""
            && oldMoneyIn?.party?._localUUID == moneyIn?.party?._localUUID
          ) {
            let updatedMoneyIn = await this.dao.update(moneyIn);
            let deltaPartyCredit = (moneyIn?.totalAmount || 0.0) - (oldMoneyIn?.totalAmount || 0.0);
            await this.partyDao.updateCredit(
              moneyIn?.party,
              -deltaPartyCredit
            )
            await this.monthWisePartyCreditDao?.modifyCredit(
              moneyIn?.party?._localUUID,
              moneyIn?.billDateStamp,
              -deltaPartyCredit
            )
  
            if(!oldMoneyIn?.linkedSaleUUID && updatedMoneyIn?.linkedSaleUUID) {
              await this.saleDao.linkMoneyIn(updatedMoneyIn?.linkedSaleUUID, updatedMoneyIn)
            } else if(updatedMoneyIn?.linkedSaleUUID) {
              await this.saleDao?.upLinkMoneyIn(updatedMoneyIn?.linkedSaleUUID,updatedMoneyIn);
            }

            if(updatedMoneyIn?.linkedPurchaseReturnUUID) {
              await this.purchaseReturnDao?.upLinkMoneyIn(updatedMoneyIn?.linkedPurchaseReturnUUID,updatedMoneyIn);
            }
  
            this.reloadList();
            this.updateSubs.next(updatedMoneyIn);
            return resolve(updatedMoneyIn);
          }
          return resolve(null);
        }
        return resolve(null)
      } catch (error) {
        SentryUtilites.setLog("MoneyInService:update", error)
        return resolve(null);
      }
    });
  }

  delete(moneyIn: MoneyIn): Promise<MoneyIn> {
    return new Promise(async (resolve, reject) => {
      try {
        if(moneyIn?._localUUID) {
          moneyIn.lastModifiedBy = this.authService.getLoginPhone();
          moneyIn.lastModifiedByName = Utility.getCreatedByName();
          let moneyInTobeDeleted = await this.getByUUID(moneyIn?._localUUID);
          let deletedMoneyIn = await this.dao.delete(moneyInTobeDeleted);
          await this.partyDao.updateCredit(
            moneyInTobeDeleted?.party,
            moneyInTobeDeleted?.totalAmount || 0.0
          )
          await this.monthWisePartyCreditDao?.modifyCredit(
            moneyInTobeDeleted?.party?._localUUID,
            moneyInTobeDeleted?.billDateStamp,
            moneyInTobeDeleted?.totalAmount || 0
          )
          if (moneyInTobeDeleted?.linkedSaleUUID) {
            await this.saleDao.unLinkMoneyIn(moneyInTobeDeleted?.linkedSaleUUID, moneyInTobeDeleted?._localUUID)
          }

          if (moneyInTobeDeleted?.linkedPurchaseReturnUUID) {
            await this.purchaseReturnDao.unLinkMoneyIn(moneyInTobeDeleted?.linkedPurchaseReturnUUID, moneyInTobeDeleted?._localUUID)
          }
    
          this.reloadList();
          this.updateSubs.next(deletedMoneyIn);
          return resolve(deletedMoneyIn);
        }
        return resolve(null);
      } catch (error) {
        SentryUtilites.setLog("MoneyInService:delete", error)
        return resolve(null);
      }
    });
  }

  isSyncLock = false;
  isSyncPostPond = false;
  async trySyncUnsynced(postpond?: boolean) {
    try {
      if (this.isSyncLock) {
        if (!this.isSyncPostPond) {
          setTimeout(() => {
            this.isSyncPostPond = true;
            this.trySyncUnsynced(true);
          }, 200);
        }
        return true;
      }
      if (postpond) {
        this.isSyncPostPond = false;
      }
      this.isSyncLock = true;
      let unSyncedElements: MoneyIn[] = await this.dao.getAllUnsynced(this.selectedProfileId);
      if (unSyncedElements && unSyncedElements?.length) {
        try {
  
          for (let i = 0; i < unSyncedElements?.length; i++) {
            let unSyncedElement = unSyncedElements[i];
            if(unSyncedElement?._localUUID) {
              unSyncedElement['updatedStamp'] = +new Date();
            }
          }
  
          await this.dao.bulkPut(unSyncedElements);
          await Utility.wait(1000);
          
          let chunkArr = Utility.getChunkArr(unSyncedElements);
          let chunkArrLength = chunkArr?.length;
  
          for(let i = 0; i < chunkArrLength; i++) {
            let result = await this.expressServerService.makeSyncCall('moneyIn', chunkArr[i]);
            if (result && result?.['records']?.length) {
              let arr = result?.['records'];
              for (let i = 0; i < arr?.length; i++) {
                const el = arr[i];
                await this.updateSyncStamp(el);
              }
            }
          }
        } catch (err) {
          SentryUtilites.setLog("MoneyInService:trySyncUnsynced:inner", err)
        }
      }
      this.isSyncLock = false;
    } catch (error) {
      SentryUtilites.setLog("MoneyInService:trySyncUnsynced", error)
    }
  }

  updateSyncStamp(el: MoneyIn): Promise<MoneyIn> {
    return new Promise(async (resolve, reject) => {
      try {
        let updatedEl = await this.dao.updateSyncStamp(el);
        this.updateSubs.next(updatedEl);
        return resolve(updatedEl);
      } catch (error) {
        SentryUtilites.setLog("MoneyInService:updateSyncStamp", error)
        return resolve(null);
      }
    });
  }

  async getNewMoneyInNo(): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        let moneyIns = await this.dao.getAllByProfile(this.selectedProfileId);
        let nextMoneyInNo = 'REC_001';
        if (moneyIns[0]?.billNo) {
          nextMoneyInNo = Utility.nextNo(moneyIns[0]?.billNo);
        }
        return resolve(nextMoneyInNo);
      } catch (error) {
        SentryUtilites.setLog("MoneyInService:getNewMoneyInNo", error)
        return resolve(null);
      }

    });
  }

  async getAllBySale(invoiceUUID: string): Promise<MoneyIn[]> {
    return this.dao.getAllBySale(invoiceUUID);
  }

  /**
   * 
   * @returns : return deleted moneyIns from moneyIn dao
   */
  async getAllDeleted(): Promise<MoneyIn[]> {
    try {
      let res = await this.dao.getAllDeletedByProfile(this.selectedProfileId);
      return res || [];
    } catch (error) {
      SentryUtilites.setLog("MoneyInService:getAllDeleted", error)
      return [];
    }
  }
  // -----------------------------------------------------

  copyData(fromProfileId: string, toProfileId: string): Promise<boolean> {
    return new Promise(async (resolve,reject) => {
      try {
        if(Utility.isTruthy(fromProfileId) && Utility.isTruthy(toProfileId)) {
          let fromRecords = await this.getAllByPromiseByProfile(fromProfileId);
          if(fromRecords?.length) {
            let toRecords: MoneyIn[] = [];
            for (let i = 0; i < fromRecords?.length; i++) {
              const fetchedRecord = fromRecords[i];
              if(fetchedRecord?._localUUID) {
                fetchedRecord.profileId = toProfileId;
                delete fetchedRecord?._localId;
                delete fetchedRecord?._localUUID;
                let savedRecord = await this.save(fetchedRecord);
                if(savedRecord?._localUUID) {
                  toRecords?.push(savedRecord);
                }
              }
            }
            if(fromRecords?.length === toRecords?.length) {
              return resolve(true);
            }
          }
        }
        return resolve(false);
      } catch (error) {
        SentryUtilites.setLog("MoneyInService:copyData", error)
        return resolve(false);
      }
    });
  }

}

