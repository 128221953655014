import { BaseModel } from "./BaseModel.model";

export class IItemStockAdjust {

  static SCHEMA_NAME = 'item-stock-adjust';

}
export class ItemStockAdjust extends BaseModel {

  profileId: string;
  linkedItemUUID: string;
  quantity: number;
  note: string;
  unit: string;

}
