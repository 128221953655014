import { KotItem } from './KotItem.model';
import { BaseModel } from "./BaseModel.model";

export class IKot {

  static SCHEMA_NAME = 'kot';

}

export class Kot extends BaseModel {

  profileId: string;

  linkedSaleUUID: string;
  kotNo: number;
  billStateItems: KotItem[];
  printStateItems: KotItem[];

}
