import { AllDataService } from './../../../services/all-data.service';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Utility } from '../../../utils/utility';
import { Item } from '../../../models/Item.model';
import { InfiniteScrollCustomEvent, LoadingController, ToastController } from '@ionic/angular';
import { BarcodeGeneratorComponent } from '../../../components/barcode-generator/barcode-generator.component';
import JsBarcode from 'jsbarcode';
import { DOMImplementation, XMLSerializer } from 'xmldom';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';
const xmlSerializer = new XMLSerializer();
const xmlDocument = new DOMImplementation().createDocument('http://www.w3.org/1999/xhtml', 'html', null);
@Component({
  selector: 'app-barcode-print',
  templateUrl: './barcode-print.page.html',
  styleUrls: ['./barcode-print.page.scss'],
})
export class BarcodePrintPage implements OnInit {

  @ViewChild('barcodeGeneratorEle') barcodeGeneratorEle: BarcodeGeneratorComponent;

  getHeaderColorClass = Utility.getHeaderColorClass;

  returnZero = () => 0;

  shortStr = Utility.shortStr;

  defaultCard = {
    brandName: "Brand Name",
    itemName: "Item Name",
    mrp: "200",
    barCode: "Bar Code",
    itemCode: "Item Code",
    salePrice: "100",
  };

  toggleSettings: {
    [key: string]: {
      title: string;
      value: boolean;
    }
  } = {
      brpShowBrandName: {
        title: "Brand Name",
        value: false,
      },
      brpShowItemName: {
        title: "Item Name",
        value: false,
      },
      brpShowMrp: {
        title: "MRP",
        value: false,
      },
      brpShowSalePrice: {
        title: "Sale Price",
        value: false,
      },
      brpShowItemCode: {
        title: "Item Code",
        value: false,
      },
      brpShowBarcode: {
        title: "Barcode",
        value: false,
      }
    };

  stickerSettings = {
    barcodePrintPageHeight: {
      title: "Page Height(mm)",
      value: 297,
    },
    barcodePrintPageWidth: {
      title: "Page Width(mm)",
      value: 210,
    },
    barcodePrintColumns: {
      title: "Columns",
      value: null,
    },
    barcodePrintRows: {
      title: "Rows",
      value: null,
    },
    barcodePrintSkip: {
      title: "Skip",
      value: null,
    },
  };

  brandName: string = '';

  barCodesToPrint: Item[] = [];

  marginVerticle: number = 0;
  marginHorizontal: number = 0;

  barcodeWidth = 1.4;
  rollWidth = 104.5;
  paddingLeft = "";
  paddingRight = "";
  margin = "";
  paperSize = "";

  stickerHeight = 20;
  stickerWidth = 30.30;

  isModalOpen = false;
  barcodedItems: Item[] = [];

  filteredList: Item[] = [];
  completeList: Item[] = [];
  viewFilteredList: Item[] = [];

  appliedCategory = '';
  selectedFilter = '';

  categories: string[] = [];

  cartItemsCategories: string[] = [];

  constructor(
    private allDataService: AllDataService,
    private toastController: ToastController,
    private loadingCtrl: LoadingController,
    private cd: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.populateBrandName();
    this.populateToggleSettings();
    this.populateStickerSettings();
    this.loadBarcodedItems();
  }

  ngAfterViewInit() {
    this.generateSampleTile();
  }

  async openItemSelectorModal(value: boolean = true) {
    try {
      if(await this.isValidColumnRows()) {
        this.isModalOpen = value;
      }else {
        this.isModalOpen = false;
      }
    } catch (error) {
      SentryUtilites.setLog("BarcodePrintPage:openItemSelectorModal", error)
    }
  }

  async isValidColumnRows(): Promise<boolean> {
    try {
      let barcodePrintColumns = Utility.getFromLocalStorage('barcodePrintColumns');
      let barcodePrintRows = Utility.getFromLocalStorage('barcodePrintRows');
      if(!Number(barcodePrintColumns) || !Number(barcodePrintRows)) {
        const toast = await this.toastController.create({
          message: 'Plase enter valid columns and rows to print',
          duration: 3000,
          color: 'dark',
        });
        await toast.present();
        return false;
      }
      return true;
    } catch (error) {
      SentryUtilites.setLog("BarcodePrintPage:isValidColumnRows", error)
      return false;
    }
  }

  async populateBrandName() {
    try {
      this.brandName = (await this.allDataService.profileService.getCurrentProfile())?.legalName || '';
      this.defaultCard.brandName = this.brandName;
    } catch (error) {
      SentryUtilites.setLog("BarcodePrintPage:populateBrandName", error)
    }
  }

  populateToggleSettings() {
    try {
      Object.keys(this.toggleSettings)?.forEach(key => {
        this.toggleSettings[key].value = Utility.getFromLocalStorage(key) || this.toggleSettings[key]?.value;
      });
    } catch (error) {
      SentryUtilites.setLog("BarcodePrintPage:populateToggleSettings", error)
    }
  }

  populateStickerSettings() {
    try {
      Object.keys(this.stickerSettings)?.forEach(key => {
        this.stickerSettings[key].value = Utility.getFromLocalStorage(key) || this.stickerSettings[key]?.value;
      });
      this.cssForCanvasArea();
    } catch (error) {
      SentryUtilites.setLog("BarcodePrintPage:populateStickerSettings", error)
    }
  }

  onToggleSettingChange(key: string, event: any) {
    try {
      Utility.setToLocalStorage(key, event?.detail?.checked);
      this.toggleSettings[key].value = event?.detail?.checked;
      this.generateSampleTile();
    } catch (error) {
      SentryUtilites.setLog("BarcodePrintPage:onToggleSettingChange", error)
    }
  }

  onStickerSettingChange(key: string, event: any) {
    try {
      Utility.setToLocalStorage(key, event?.detail?.value);
      if (key === 'rollWidth') {
        this.cssForCanvasArea();
      }
    } catch (error) {
      SentryUtilites.setLog("BarcodePrintPage:onStickerSettingChange", error)
    }
  }

  setBrandName(event) {
    this.brandName = event?.detail?.value;
  }

  generateSampleTile() {
    try {
      document.querySelectorAll(".ebp-canvas-tile.sample")?.forEach((tileEl: any) => {
  
        if (tileEl && tileEl?.style) {
          let text = '12345678';
          tileEl?.querySelectorAll(".text .white-bg")?.forEach((textEl: any) => {
            text = textEl?.innerText;
          })
          const svgNode = xmlDocument.createElementNS('http://www.w3.org/2000/svg', 'svg');
          JsBarcode(svgNode, text, {
            xmlDocument: xmlDocument,
            text: "_",
            width: this.barcodeWidth
          });
          const svgText = xmlSerializer.serializeToString(svgNode);
          tileEl?.querySelectorAll(".svg")?.forEach((svgEl: any) => {
            svgEl.innerHTML = svgText;
          });
  
          setTimeout(() => {
            let padding = `0 ${this.paddingRight}mm  0  ${this.paddingLeft}mm`;
            tileEl.style.padding = padding;
          }, 100);
        }
      });
    } catch (error) {
      SentryUtilites.setLog("BarcodePrintPage:generateSampleTile", error)
    }

  }
  generateStickers() {
    try {
      let barcodePrintColumns = Utility.getFromLocalStorage('barcodePrintColumns');
      let barcodePrintRows = Utility.getFromLocalStorage('barcodePrintRows');
  
      let barcodePrintPageHeight = Utility.getFromLocalStorage('barcodePrintPageHeight');
      let barcodePrintPageWidth = Utility.getFromLocalStorage('barcodePrintPageWidth');
      this.generateGridArea(
        Number(barcodePrintPageHeight) || 297,
        Number(barcodePrintPageWidth) || 210,
        Number(barcodePrintColumns),
        Number(barcodePrintRows)
      );
      setTimeout(() => {
        this.initPrint();
      }, 500)
    } catch (error) {
      SentryUtilites.setLog("BarcodePrintPage:generateStickers", error)
    }
  }


  generateGridArea(
    pageHeight: number,
    pageWidth: number,
    noOfColumns: number,
    noOfRows: number
  ) {
    try {
      let div: any = document.querySelectorAll(".bp-page")[0];
      div.style.height = pageHeight + 'mm';
      div.style.width = pageWidth + 'mm';
      div.innerHTML = '';
  
      let barcodePrintSkip = Utility.getFromLocalStorage('barcodePrintSkip');
      let skipIndex = Number(barcodePrintSkip);
      let cellWidth = Math.floor((pageWidth / noOfColumns) * 100) / 100;
      let cellHeight = Math.floor((pageHeight / noOfRows) * 100) / 100;
      for (let i = 0; i < noOfRows; i++) {
        for (let j = 0; j < noOfColumns; j++) {
          let stickerIndex = (i * noOfColumns) + j + 1;
          if (stickerIndex <= skipIndex) {
            continue;
          }
          let cellDiv = document.createElement('div')
          cellDiv.innerHTML = this.generateStickerPrint();
          cellDiv.style.textAlign = 'center';
          cellDiv.style.width = cellWidth + 'mm';
          cellDiv.style.height = cellHeight + 'mm';
          cellDiv.style.position = 'absolute';
          cellDiv.style.top = i * cellHeight + 'mm';
          cellDiv.style.left = j * cellWidth + 'mm';
          cellDiv.style.overflow = "hidden";
          div.appendChild(cellDiv);
        }
      }
      let barcodeHeight = (Math.floor((1 * cellHeight / 3) * 100) / 100) + 'mm';
      let bpStickerBarcodeAreaArr = document.querySelectorAll(".bp-sticker-barcode-area");
      bpStickerBarcodeAreaArr?.forEach((el: any) => {
        el.style.height = barcodeHeight;
      })
  
      let bpStickerTextArr = document.querySelectorAll(".bp-sticker-text");
      bpStickerTextArr?.forEach((el: any) => {
        el.style.bottom = barcodeHeight;
        el.style.left = 0;
        el.style.width = '100%'
      })
  
  
      let bpStickerPrintArr = document.querySelectorAll(".bp-sticker-print");
      bpStickerPrintArr?.forEach((el: any) => {
        el.style.width = (cellWidth - 5) + 'mm',
          el.style.height = (cellHeight - 5) + 'mm',
          el.style.position = 'absolute',
          el.style.top = '2.5mm',
          el.style.left = '2.5mm',
          el.style.overflow = 'hidden'
      })
    } catch (error) {
      SentryUtilites.setLog("BarcodePrintPage:generateGridArea", error)
    }

  }

  generateStickerPrint() {
    try {
      let item = this.barCodesToPrint.pop();
      if (!item) {
        return '';
      }
      let brandName = this.brandName;
      let itemName = item?.itemName;
      let sp = this.toggleSettings?.brpShowSalePrice?.value ? (item?.sellPrice || '') + '' : '';
      let mrp = this.toggleSettings?.brpShowMrp?.value ? (item?.mrp || '') + '' : '';
      let itemCode = (item?.itemCode || '') + '';
      let priceText = '';
      if (sp && sp.length > 0) {
        priceText += 'SP: ' + sp
      }
      if (mrp && mrp.length > 0) {
        if (priceText.length > 0) {
          priceText += ' | '
        }
        priceText += 'MRP: ' + mrp
      }
      const svgNode = xmlDocument.createElementNS('http://www.w3.org/2000/svg', 'svg');
      JsBarcode(svgNode, item?.barcode + '', {
        xmlDocument: xmlDocument,
        text: "_",
        //width:this.barcodeWidth
      });
      const svgText = xmlSerializer.serializeToString(svgNode);
      return `
          <div class='bp-sticker-print'>
              <div class="bp-sticker-text" style="text-align: center;position: absolute;">
                  <p style="font-size: 3mm;line-height:3mm;width:100%;text-align: center;margin:0 !important;">${this.toggleSettings?.brpShowBrandName?.value ? brandName : ''}</p>
                  <p style="font-size: 3mm;line-height:3mm;width:100%;text-align: center;margin:0 !important;">${this.toggleSettings?.brpShowItemName?.value ? itemName : ''}</p>
                  <p style="font-size: 4mm;line-height:4mm;width:100%;text-align: center;margin:0 !important;"><b>${priceText}</b></p>
                  <p style="font-size: 3mm;line-height:3mm;width:100%;text-align: center;margin:0 !important;">${this.toggleSettings?.brpShowItemCode?.value ? itemCode : ''}</p>
              </div>
              <div class='bp-sticker-barcode-area' style="width:100%;min-height: 5mm;position: absolute;bottom: 0;right:0;overflow: hidden;">
                  <div style="position: relative;top:-20mm">
                    ${svgText}
                  </div>
                  <div class="bp-barcode-text" style="position: absolute;bottom: 0;text-align: center;right: 0;width:100%;font-size: 2.5mm;background-color: #fff !important;display: block;print-color-adjust: exact;">${this.toggleSettings?.brpShowBarcode?.value ? item?.barcode + '' : ''}</div>
              </div>
          </div>`
    } catch (error) {
      SentryUtilites.setLog("BarcodePrintPage:generateStickerPrint", error)
    }
  }


  generateTiles() {
    try {
      document.querySelectorAll(".ebp-canvas-tile")?.forEach((tileEl: any) => {
        let classExist = tileEl?.classList?.contains('sample');
        if (classExist == false) {
  
          if (tileEl && tileEl?.style) {
            tileEl.style.height = this.stickerHeight + "mm";
            tileEl.style.width = this.stickerWidth + "mm";
            tileEl.style.margin = this.marginVerticle + "mm" + " " + this.marginHorizontal + "mm";
  
            let text = '';
            tileEl?.querySelectorAll(".text .white-bg")?.forEach((textEl: any) => {
              text = textEl?.innerText;
            })
            const svgNode = xmlDocument.createElementNS('http://www.w3.org/2000/svg', 'svg');
            JsBarcode(svgNode, text, {
              xmlDocument: xmlDocument,
              text: "_",
              width: this.barcodeWidth
            });
            const svgText = xmlSerializer.serializeToString(svgNode);
            tileEl?.querySelectorAll(".svg")?.forEach((svgEl: any) => {
              if (svgEl) {
                svgEl.innerHTML = svgText;
              }
            });
            setTimeout(() => {
              let padding = `0 ${this.paddingRight}mm  0  ${this.paddingLeft}mm`;
              tileEl.style.padding = padding;
            }, 100);
  
          }
        }
      });
  
      setTimeout(() => {
        this.initPrint();
      }, 500)
    } catch (error) {
      SentryUtilites.setLog("BarcodePrintPage:generateTiles", error)
    }

  }

  initPrint() {
    try {
      let div: any = document.querySelectorAll(".ebp-canvas-area")[0];
      let domContainer: any = document.getElementById('div-print-area');
      let dvApp: any = document.getElementById('dv-app');

      if(div && domContainer && dvApp) {
        dvApp.style.display = "none";
        div.style.display = "block";
        domContainer.appendChild(div.cloneNode(true));
        domContainer?.classList?.add('active');
        domContainer.style.width = "100%";
        let documentTitle = document.title;
        document.title = `Barcode`;
    
        setTimeout(() => {
          window?.print();
          domContainer.innerHTML = '';
          domContainer?.classList?.remove('active');
          domContainer.style.width = "100%";
          dvApp.style.display = "block";
          div.innerHTML = '';
          div.style.display = "none";
    
          document.title = documentTitle;
          window?.location?.reload();
        }, 500);
      } else {
        console.warn('One or more elemet is missing')
      }
    } catch (error) {
      SentryUtilites.setLog("BarcodePrintPage:initPrint", error)
    }
  }

  async saveAndProceed() {
    try {
      let selectedItems: Item[] = this.completeList?.filter(item => item['quantity'] > 0);
  
      if (!selectedItems?.length) {
        const toast = await this.toastController.create({
          message: 'Please select at least one item.',
          duration: 2000,
          color: 'dark',
        });
        await toast.present();
        return null;
      }
  
      this.openItemSelectorModal(false);
  
      let arr = [];
      for (let i = 0; i < selectedItems.length; i++) {
        const item = selectedItems[i];
        if (item?._localUUID) {
          let latestItem = await this.allDataService.itemService.getByUUID(item?._localUUID);
          if (latestItem?.barcode) {
            for (let i = 0; i < item['quantity']; i++) {
              arr.push(latestItem);
            }
          }
        }
      }
      this.barCodesToPrint = arr;
      setTimeout(() => {
        this.generateStickers();
      }, 1000);
    } catch (error) {
      SentryUtilites.setLog("BarcodePrintPage:saveAndProceed", error)
      return null;
    }
  }

  // generateTopText(item: Item){
  //   let topTextArr=[];
  //   if(this.brandName && this.toggleSettings.brpShowBrandName){
  //     topTextArr.push(this.brandName);
  //   }
  //   if(item.itemName && item.itemName.length>0 && this.toggleSettings.brpShowItemName){
  //     topTextArr.push(item.itemName);
  //   }
  //   let priceText='';
  //   if(item.mrp && this.toggleSettings.brpShowMrp){
  //     priceText+='MRP:'+item.mrp+" ";
  //   }
  //   if(item.sellPrice && this.toggleSettings.brpShowSalePrice){
  //     priceText+='SP:'+item.sellPrice;
  //   }
  //   if(priceText.length){
  //     topTextArr.push(priceText);
  //   }
  //   if(item.itemCode && this.toggleSettings.brpShowItemCode){
  //     topTextArr.push('IC:'+item.itemCode);
  //   }
  //   return topTextArr.join('<br/>');
  // }

  cssForCanvasArea() {
    try {
      let div = document.getElementsByClassName('ebp-canvas-area')[0] as HTMLDivElement;
      if (div) {
        div.style.width = `${this.rollWidth}mm`;
      }
    } catch (error) {
      SentryUtilites.setLog("BarcodePrintPage:cssForCanvasArea", error)
    }
  }

  increaseQty(itemUUID: string) {
    try {
      let index = this.completeList.findIndex(item => item?._localUUID === itemUUID);
      if (index != -1) {
        if (this.isQuantityThresholdMaintanined(itemUUID,1)) {
          this.completeList[index]['quantity'] += 1;
        }
      }
      this.maintainCartItemsCategory();
      this.cd.detectChanges();
    } catch (error) {
      SentryUtilites.setLog("BarcodePrintPage:increaseQty", error)
    }
  }

  decreaseQty(itemUUID: string) {
    try {
      let index = this.completeList.findIndex(item => item?._localUUID === itemUUID);
      if (index != -1) {
        this.completeList[index]['quantity'] -= 1;
      }
      this.maintainCartItemsCategory();
    } catch (error) {
      SentryUtilites.setLog("BarcodePrintPage:decreaseQty", error)
    }
  }

  onInpQtyChange(itemUUID: string, event: any) {
    try {
      let index = this.completeList.findIndex(item => item?._localUUID === itemUUID);
      if (index != -1) {
        let qty = Number(event?.detail?.target?.value);
        if (Utility.isNumber(qty)) {
          if (qty < 0) {
            qty = Math.abs(qty);
          }
        } else {
          qty = 0;
        }
        if (this.isQuantityThresholdMaintanined(itemUUID,qty)) {
          this.completeList[index]['quantity'] = qty;
        } else {
          event.detail.target.value = this.completeList[index]['quantity'];
        }
      }
      this.maintainCartItemsCategory();
    } catch (error) {
      SentryUtilites.setLog("BarcodePrintPage:onInpQtyChange", error)
    }
  }

  async generateBarcodeForAllItems() {
    try {
      let loading = await this.loadingCtrl.create({
        message: 'Adding Barcode',
        duration: 100000,
        spinner: 'circles',
        mode: 'ios',
      });
      await loading.present();
      let allItems = await this.allDataService.itemService.getAllByPromise();
      let nonBarcodedItems = allItems.filter(item => !item?.barcode);
      for (let i = 0; i < nonBarcodedItems?.length; i++) {
        let item = nonBarcodedItems[i];
        item.barcode = (await this.barcodeGeneratorEle?.generateRandomBarCodeNumber()) as any;
        if (item?.barcode) {
          await this.allDataService.itemService.update(item);
        }
      }
      await this.loadBarcodedItems();
      await loading?.dismiss();
    } catch (error) {
      SentryUtilites.setLog("BarcodePrintPage:generateBarcodeForAllItems", error)
    }
  }

  async loadBarcodedItems() {
    try {
      this.filteredList = [];
      this.completeList = [];
      this.viewFilteredList = [];
  
      this.appliedCategory = '';
      this.selectedFilter = 'SortByNameAsc';
  
      this.categories = [];
  
      let allItems = await this.allDataService.itemService.getAllByPromise() || [];
      this.barcodedItems = allItems.filter(item => item?.barcode);
      this.barcodedItems?.forEach((item, i) => {
        this.barcodedItems[i]['quantity'] = 0;
      });
  
      this.completeList = [...this.barcodedItems];
      this.onFilterChange();
      this.getCategories();
    } catch (error) {
      SentryUtilites.setLog("BarcodePrintPage:loadBarcodedItems", error)
    }
  }

  async getCategories() {
    try {
      let itemCategories = await this.allDataService.itemCategoryService.getAllByPromise();
      if (itemCategories?.length) {
        this.categories = itemCategories.map(x => x?.name);
      }
    } catch (error) {
      SentryUtilites.setLog("BarcodePrintPage:getCategories", error)
    }
  }

  isFilterPostpond = false;
  lastFilterStamp = 0;
  onFilterChange(searchKey?: string) {
    try {
      if (this.isFilterPostpond) {
        return true;
      }
      if (this.lastFilterStamp < +new Date() - 200) {
        this.lastFilterStamp = +new Date();
        return this.applyFilter({
          searchKeyword: searchKey,
          selectedFilter: this.selectedFilter,
          category: this.appliedCategory
        })
      } else {
        this.isFilterPostpond = true;
        setTimeout(() => {
          this.isFilterPostpond = false;
          this.onFilterChange(searchKey);
        }, 250);
      }
    } catch (error) {
      SentryUtilites.setLog("BarcodePrintPage:onFilterChange", error)
    }
  }

  lastSearchKey = '';
  async applyFilter(params: {
    searchKeyword?: string,
    selectedFilter?: string,
    category?: string
  }) {
    try {
      let arr: Item[] = [];
      let lsearchKey = '';
      let isSearchIncremental = false;
      let isSearchCleared = false;
      if (params?.searchKeyword?.length) {
        lsearchKey = Utility.toLowerCase(params.searchKeyword);
        if (lsearchKey.indexOf(this.lastSearchKey) == 0) {
          isSearchIncremental = true;
        }
        this.lastSearchKey = lsearchKey;
      }
      if (this.lastSearchKey.length > 0 && lsearchKey.length == 0) {
        isSearchCleared = true;
        this.lastSearchKey = '';
      }
      let completeList = [];
      if (isSearchIncremental) {
        completeList = [...this.filteredList]
      } else {
        completeList = [...this.completeList];
      }
      if (params?.category) {
        completeList?.forEach((el) => {
          if (el?.category == params?.category) {
            arr.push(el);
          }
        });
      } else if (lsearchKey.length) {
        completeList?.forEach((el) => {
          // if(lsearchKey.length<4){
          //   if(Utility.toLowerCase(el.itemName).indexOf(lsearchKey)==0){
          //     arr.push(el);
          //   }
          // }else{
          if (
            Utility.toLowerCase(el?.itemName).indexOf(lsearchKey) != -1
            || Utility.toLowerCase(el?.barcode) === lsearchKey
          ) {
            arr.push(el);
          }
          //}
        });
  
      } else {
        arr = [...completeList]
      }
  
  
      if (params.selectedFilter == 'SortByNameAsc') {
        arr.sort((a: Item, b: Item) => {
          if (a?.itemName?.toLowerCase() == b?.itemName?.toLowerCase()) {
            return a?.createdStamp - b?.createdStamp;
          }
          if (a?.itemName?.toLowerCase() > b?.itemName?.toLowerCase()) {
            return 1;
          }
          return -1;
        })
      } else if (params.selectedFilter == 'SortByNameDesc') {
        arr.sort((a: Item, b: Item) => {
          if (a?.itemName?.toLowerCase() == b?.itemName?.toLowerCase()) {
            return a?.createdStamp - b?.createdStamp;
          }
          if (b?.itemName?.toLowerCase() > a?.itemName?.toLowerCase()) {
            return 1;
          }
          return -1;
        })
      } else if (params.selectedFilter == 'SortByStockAsc') {
        arr.sort((a: Item, b: Item) => a?.stock - b?.stock);
      } else if (params.selectedFilter == 'SortByStockDesc') {
        arr.sort((a: Item, b: Item) => b?.stock - a?.stock);
      } else if (params.selectedFilter == 'lowStockByAsc') {
        arr = arr.filter(item => {
          const stock = Number(item?.stock);
          let minStock = Number(item?.minStock);
          minStock = minStock || 0;
          if (
            Utility.isNumber(stock)
            && Utility.isNumber(minStock)
            && stock <= minStock
          ) {
            return true;
          } else {
            return false;
          }
        });
        arr.sort((a: Item, b: Item) => {
          if (a?.itemName?.toLowerCase() == b?.itemName?.toLowerCase()) {
            return a?.createdStamp - b?.createdStamp;
          }
          if (b?.itemName?.toLowerCase() > a?.itemName?.toLowerCase()) {
            return 1;
          }
          return -1;
        })
      }
  
      this.filteredList = [...arr];
  
      if (lsearchKey) {
        let priorityArr: Item[] = [];
        this.filteredList?.forEach((x, i) => {
          if ((x?.itemName+'')?.startsWith(lsearchKey)) {
            priorityArr.push(x);
            this.filteredList.splice(i, 1);
          }
        });
        this.filteredList = [...priorityArr.sort(), ...this.filteredList];
      }
  
      if (lsearchKey.length || params.category || params.selectedFilter || isSearchCleared) {
        this.viewFilteredList = [...this.filteredList.slice(0, 50)];
      } else if (!this.viewFilteredList.length) {
        this.viewFilteredList = [...this.filteredList.slice(0, 50)];
      }
  
      return true;
    } catch (error) {
      SentryUtilites.setLog("BarcodePrintPage:applyFilter", error)
      return false;
    }
  }

  onCategoryChange(category: string) {
    this.appliedCategory = category;
    this.onFilterChange();
  }

  maintainCartItemsCategory() {
    try {
      this.cartItemsCategories = [];
      this.completeList?.forEach(item => {
        if(item['quantity'] > 0) {
          let index = this.viewFilteredList.findIndex(x => x?._localUUID === item?._localUUID);
          if(index != -1) {
            this.viewFilteredList.splice(index,1,item);
          }
          if (item?.category) {
            this.cartItemsCategories.push(item?.category);
          }
        }
      });
    } catch (error) {
      SentryUtilites.setLog("BarcodePrintPage:maintainCartItemsCategory", error)
    }
  }

  loadMoreListData(event) {
    try {
      if (this.viewFilteredList.length > 0 && this.viewFilteredList.length <= this.filteredList.length) {
        let appendListLength = this.filteredList.length - this.viewFilteredList.length;
        let lastEl = this.viewFilteredList[this.viewFilteredList.length - 1];
        let counter = 0;
        for (let i = 0; i < this.filteredList.length; i++) {
          if (this.filteredList[i]._localUUID == lastEl._localUUID) {
            counter = 1;
            continue;
          }
          if (counter > 0 && appendListLength >= 50) {
            if (counter <= 50) {
              this.viewFilteredList.push({ ...this.filteredList[i] })
            } else {
              break;
            }
            counter++;
          } else if(counter > 0 && appendListLength < 50) {
            if (counter <= appendListLength) {
              this.viewFilteredList.push({ ...this.filteredList[i] })
            } else {
              break;
            }
            counter++;
          }
        }
        (event as InfiniteScrollCustomEvent).target.complete();
      } 
    } catch (error) {
      SentryUtilites.setLog("BarcodePrintPage:loadMoreListData", error)
    }
  }

  isQuantityThresholdMaintanined(itemUUID:string,currentQuantity: number): boolean {
    try {
      if (Utility.isNumber(currentQuantity)) {
        let barcodePrintColumns = Utility.getFromLocalStorage('barcodePrintColumns');
        let barcodePrintRows = Utility.getFromLocalStorage('barcodePrintRows');
        let barcodePrintSkip = Utility.getFromLocalStorage('barcodePrintSkip');
  
        let quantityThreshold: number = (Number(barcodePrintColumns) * Number(barcodePrintRows)) - Number(barcodePrintSkip);
  
        let totalQuantity: number = 0;
        this.completeList?.forEach(item => {
          if (Utility.isNumber(item['quantity']) && item['quantity'] > 0) {
            if(item._localUUID!=itemUUID){
              totalQuantity += Number(item['quantity']);
            }
          }
        });
        totalQuantity += Number(currentQuantity);
        return totalQuantity <= quantityThreshold;
      }
      return false;
    } catch (error) {
      SentryUtilites.setLog("BarcodePrintPage:isQuantityThresholdMaintanined", error)
      return false;
    }
  }

}
