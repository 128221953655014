<ion-header [ngClass]="{'mobile': isMobile}">
  <ion-toolbar class="{{ getHeaderColorClass() }}">
    <app-navigation-control slot="start" type="sale-return"></app-navigation-control>
    <ion-title class="header-title" [appHeaderTitleCss]="isMobile ? 'mobile' : ''">
      <span>
        {{ isBinList ? 'Deleted' : '' }} Sale Return List
      </span>
      <ion-chip> Count: {{ filteredList?.length }} | Amount: {{
        getAddition(filteredList,'totalAmount') | currency: 'INR' }} </ion-chip></ion-title>
    <ion-text class="last-sync-time" color="medium">Last Sync at {{ lastSyncTime }}</ion-text>
    <ion-text *ngIf="isTimeDifference" class="last-sync-time timezone-warning alert-border padding-alert-boder" color="warning"> <i>Time Zone
        Mismatched</i> </ion-text>
    <!-- <ion-chip color="tertiary" class="ion-chip" slot="end" (click)="fixBillNumbers()"> Fix Bill Numbers </ion-chip> -->
    <ion-chip color="danger" class="ion-chip" slot="end" (click)="bulkDelete()"> Bulk Delete </ion-chip>
    <ion-chip class="chip-bin-list ion-chip" slot="end" color="danger">
      <span class="title">Bin List</span>
      <span>
        <ion-toggle color="danger" slot="end" [checked]="isBinList"
          (ionChange)="onBinListFilterChange($event)"></ion-toggle>
      </span>
    </ion-chip>
    <!-- <ion-chip color="tertiary" class="ion-chip" slot="end" (click)="onNewSaleReturnClick()"> + New Sale Return </ion-chip> -->
    <ion-chip color="primary" slot="end" class="chip-option" (click)="toggleOption()" (clickOutSide)="clickOutSide()">
      <ion-icon name="ellipsis-vertical"></ion-icon>
    </ion-chip>
  </ion-toolbar>
  <ion-list *ngIf="isOptionOpen" class="show-chip-option">
    <ion-item (click)="bulkDelete()">
      Bulk Delete
    </ion-item>
    <ion-item (click)="keepOpenOption()">
      <span class="title">Bin List</span>
      <span>
        <ion-toggle color="danger" slot="end" [checked]="isBinList"
          (ionChange)="onBinListFilterChange($event)"></ion-toggle>
      </span>
    </ion-item>
  </ion-list>
</ion-header>

<ion-content [ngClass]="{'mobile': isMobile}">
  <!-- <ion-button fill="clear" class="btn-add chip-option" (click)="onNewSaleReturnClick()">
    + New Sale Return
  </ion-button> -->
  <ion-toolbar class="ion-serachbar" [style.padding-bottom]="isFilterOptionOpen &&  isMobile? '32px' : '0px'">
    <ion-searchbar #searchBar placeholder="Search" [debounce]="100"
      (ionChange)="onFilterChange($event['detail']?.value)">
    </ion-searchbar>
  </ion-toolbar>

  <!-- <ion-button class="btn-show-hide" (click)="toggleFilterOptions()">
    {{isFilterOptionOpen ? 'Hide Filter' : 'Show Filter'}}
  </ion-button> -->

  <app-date-range class="date-range" listType="saleReturn" (dateRangeChange)="onDateRangeChange($event)"></app-date-range>
  <ion-grid [ngStyle]="{height: innerHeight}" class="all-list-include-date-filter">
    <ion-content>
      <ion-row class="padding-5px">
        <ion-col size="12" class="col-custom-row parent-col" *ngFor="let saleReturn of viewFilteredList">
          <app-sale-return-row [saleReturn]="saleReturn" (onDeleteClick)="delete($event)" (onAddPaymentClick)="addPayment($event)">
          </app-sale-return-row>
        </ion-col>
      </ion-row>
      <ion-infinite-scroll *ngIf="viewFilteredList?.length" threshold="10px" (ionInfinite)="loadMoreListData($event)">
        <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Loading more data...">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ion-grid>

  <app-money-out-selector #moneyOutSelector *ngIf="showMoneyOutSelector" [isSaleReturnList]="true" [saleReturn]="this.selectedSaleReturn"
    [billTotalAmount]="this.selectedSaleReturn?.totalAmount || 0" [moneyOutCart]="this.selectedSaleReturn?.moneyOuts"
    (onModelClose)="closeMoneyInSelector()">
  </app-money-out-selector>

</ion-content>

<!-- <app-delete-data #deleteDataEle (onSaleDelete)="onDelete($event)"
  (onBulkSaleDelete)="onBulkDelete($event)"></app-delete-data> -->

  <app-sale-return-bulk-delete #deleteDataEle (onSaleReturnDelete)="onDelete($event)"
  (onBulkSaleReturnDelete)="onBulkDelete($event)" [fromStamp]="fromStamp" [toStamp]="toStamp"></app-sale-return-bulk-delete>