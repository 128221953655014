import { MonthWisePartyCreditService } from './../../../services/month-wise-party-credit.service';
import { InfiniteScrollCustomEvent } from '@ionic/angular';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Party from '../../../models/Party.model';
import { AllDataService } from '../../../services/all-data.service';
import { LedgerParty } from '../../../models/LedgerParty.model';
import { MoneyIn } from '../../../models/MoneyIn.model';
import { Sale } from '../../../models/Sale.model';
import { DeleteDataComponent } from '../../../components/delete-data/delete-data.component';
import { Purchase } from '../../../models/Purchase.model';
import { MoneyOut } from '../../../models/MoneyOut.model';
import { PinVerificationComponent } from '../../../components/pin-verification/pin-verification.component';
import { AccessControlService } from '../../../services/auth/access-control.service';
import { Utility } from '../../../utils/utility';
import { Expense } from '../../../models/Expense.model';
import { Subscription } from 'rxjs';
import { CommonService } from '../../../services/common.service';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';
import { SaleReturn } from '../../../models/SaleReturn.model';
import { SaleReturnBulkDeleteComponent } from '../../../components/bulk-delete/sale-return-bulk-delete/sale-return-bulk-delete.component';
import { PurchaseReturn } from '../../../models/PurchaseReturn.model';
import { PurchaseReturnBulkDeleteComponent } from '../../../components/bulk-delete/purchase-return-bulk-delete/purchase-return-bulk-delete.component';
import { PurchaseBulkDeleteComponent } from '../../../components/bulk-delete/purchase-bulk-delete/purchase-bulk-delete.component';

@Component({
  selector: 'app-party-transaction',
  templateUrl: './party-transaction.page.html',
  styleUrls: ['./party-transaction.page.scss'],
})
export class PartyTransactionPage implements OnInit {

  @ViewChild('deleteDataEle') deleteDataEle: DeleteDataComponent;
  @ViewChild('saleReturnBulkDeleteEle') saleReturnBulkDeleteEle: SaleReturnBulkDeleteComponent;
  @ViewChild('deletePurchaseEle') deletePurchaseEle: PurchaseBulkDeleteComponent;
  @ViewChild('deletePurchaseReturnEle') deletePurchaseReturnEle: PurchaseReturnBulkDeleteComponent;
  @ViewChild('pinVerificationElement') pinVerificationElement: PinVerificationComponent;

  getHeaderColorClass = Utility.getHeaderColorClass;
  splitByCamelCase = Utility.splitByCamelCase;

  paramDocumentId: string = null;
  fetchedParty: Party = null;
  transactionsList: LedgerParty[] = [];
  filteredList: LedgerParty[] = [];
  viewFilteredList: LedgerParty[] = [];

  selectedSale: Sale = null;
  showMoneyInSelector = false;

  selectedSaleReturn: SaleReturn = null;
  showMoneyOutSelectorSaleReturn = false;

  selectedExpense: Expense = null;
  showMoneyOutSelectorExpense = false;

  selectedPurchase: Purchase = null;
  showMoneyOutSelectorPurchase = false;

  selectedPurchaseReturn: PurchaseReturn = null;
  showMoneyInSelectorPurchaseReturn = false;

  startStamp: number = +new Date().setHours(0,0,0,0);
  endStamp: number = +new Date().setHours(23,59,59,999);

  isMobile: boolean = null;

  currentCalculatedCredit: number = 0;

  ledgerCredit: number = null;

  MATHABS = Math.abs;
  subsArr: Subscription[] = [];

  isOptionOpen: boolean = false;
  innerHeight: string = '';

  loadViewTimeStamp: number = 0;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private allDataService: AllDataService,
    private accessControlService: AccessControlService,
    private commonService: CommonService,
    private monthWisePartyCreditService: MonthWisePartyCreditService,
  ) { }

  async ngOnInit() {
    try {
      this.isMobile = this.commonService.isMobile();
      if(this.isMobile) {
        this.innerHeight = `${(((window?.innerHeight - 324)/window?.innerHeight) * 100) - 2}%`;
      } else {
        this.innerHeight = `${(((window?.innerHeight - 251)/window?.innerHeight) * 100) - 2}%`;
      }
      this.reduceFunctionCall();
      this.subsArr.push(
        this.allDataService.listForceReloadSubs.subscribe(async (listName: string) => {
          if (
            listName == 'party-list'
            || listName == 'moneyout-list'
            || listName == 'moneyin-list'
            || listName == 'purchase-list'
            || listName == 'purchase-return-list'
            || listName == 'sale-list'
            || listName == 'sale-return-list'
            || listName == 'expense-list'
            ) {
            // wait for save response to database
            await Utility.wait(2000);
            this.reduceFunctionCall();
          }
        })
      );
  
      this.updateSubs();
    } catch (error) {
      SentryUtilites.setLog("PartyTransactionPage:ngOnInit", error)
    }
  }

  ionViewWillLeave() {
    this.ngOnDestroy();
  }

  ngOnDestroy() {
    try {
      this.subsArr?.forEach(sub => {
        sub?.unsubscribe();
      })
    } catch (error) {
      SentryUtilites.setLog("PartyTransactionPage:ngOnDestroy", error)
    }
  }

  updateSubs() {
    try {
      this.subsArr.push(this.allDataService.saleService.updateSubs
        .subscribe((sale: Sale) => {
          if (sale) {
            let transactionsListIndex = this.transactionsList.findIndex(el => el?.docLocalUUID === sale?._localUUID);
            if(transactionsListIndex != -1) {
              if(sale?.deletedStamp) {
                this.transactionsList.splice(transactionsListIndex, 1);
              } else {
                this.transactionsList[transactionsListIndex].sale = sale;
              }
            }
            let filteredListIndex = this.filteredList.findIndex(el => el?.docLocalUUID === sale?._localUUID);
            if(filteredListIndex != -1) {
              if(sale?.deletedStamp) {
                this.filteredList.splice(filteredListIndex, 1);
              } else {
                this.filteredList[filteredListIndex].sale = sale;
              }
            }
            let viewFilteredListIndex = this.viewFilteredList.findIndex(el => el?.docLocalUUID === sale?._localUUID);
            if(viewFilteredListIndex != -1) {
              if(sale?.deletedStamp) {
                this.viewFilteredList.splice(viewFilteredListIndex, 1);
              } else {
                this.viewFilteredList[viewFilteredListIndex].sale = sale;
              }
            }
          }
        })
      )

      this.subsArr.push(this.allDataService.saleReturnService.updateSubs
        .subscribe((saleReturn: SaleReturn) => {
          if (saleReturn) {
            let transactionsListIndex = this.transactionsList.findIndex(el => el?.docLocalUUID === saleReturn?._localUUID);
            if(transactionsListIndex != -1) {
              if(saleReturn?.deletedStamp) {
                this.transactionsList.splice(transactionsListIndex, 1);
              } else {
                this.transactionsList[transactionsListIndex].saleReturn = saleReturn;
              }
            }
            let filteredListIndex = this.filteredList.findIndex(el => el?.docLocalUUID === saleReturn?._localUUID);
            if(filteredListIndex != -1) {
              if(saleReturn?.deletedStamp) {
                this.filteredList.splice(filteredListIndex, 1);
              } else {
                this.filteredList[filteredListIndex].saleReturn = saleReturn;
              }
            }
            let viewFilteredListIndex = this.viewFilteredList.findIndex(el => el?.docLocalUUID === saleReturn?._localUUID);
            if(viewFilteredListIndex != -1) {
              if(saleReturn?.deletedStamp) {
                this.viewFilteredList.splice(viewFilteredListIndex, 1);
              } else {
                this.viewFilteredList[viewFilteredListIndex].saleReturn = saleReturn;
              }
            }
          }
        })
      )
  
      this.subsArr.push(this.allDataService.purchaseService.updateSubs
        .subscribe((purchase: Purchase) => {
          if (purchase) {
            let transactionsListIndex = this.transactionsList.findIndex(el => el?.docLocalUUID === purchase?._localUUID);
            if(transactionsListIndex != -1) {
              if(purchase?.deletedStamp) {
                this.transactionsList.splice(transactionsListIndex, 1);
              } else {
                this.transactionsList[transactionsListIndex].purchase = purchase;
              }
            }
            let filteredListIndex = this.filteredList.findIndex(el => el?.docLocalUUID === purchase?._localUUID);
            if(filteredListIndex != -1) {
              if(purchase?.deletedStamp) {
                this.filteredList.splice(filteredListIndex, 1);
              } else {
                this.filteredList[filteredListIndex].purchase = purchase;
              }
            }
            let viewFilteredListIndex = this.viewFilteredList.findIndex(el => el?.docLocalUUID === purchase?._localUUID);
            if(viewFilteredListIndex != -1) {
              if(purchase?.deletedStamp) {
                this.viewFilteredList.splice(viewFilteredListIndex, 1);
              } else {
                this.viewFilteredList[viewFilteredListIndex].purchase = purchase;
              }
            }
          }
        })
      )

      this.subsArr.push(this.allDataService.purchaseReturnService.updateSubs
        .subscribe((purchaseReturn: PurchaseReturn) => {
          if (purchaseReturn) {
            let transactionsListIndex = this.transactionsList.findIndex(el => el?.docLocalUUID === purchaseReturn?._localUUID);
            if(transactionsListIndex != -1) {
              if(purchaseReturn?.deletedStamp) {
                this.transactionsList.splice(transactionsListIndex, 1);
              } else {
                this.transactionsList[transactionsListIndex].purchaseReturn = purchaseReturn;
              }
            }
            let filteredListIndex = this.filteredList.findIndex(el => el?.docLocalUUID === purchaseReturn?._localUUID);
            if(filteredListIndex != -1) {
              if(purchaseReturn?.deletedStamp) {
                this.filteredList.splice(filteredListIndex, 1);
              } else {
                this.filteredList[filteredListIndex].purchaseReturn = purchaseReturn;
              }
            }
            let viewFilteredListIndex = this.viewFilteredList.findIndex(el => el?.docLocalUUID === purchaseReturn?._localUUID);
            if(viewFilteredListIndex != -1) {
              if(purchaseReturn?.deletedStamp) {
                this.viewFilteredList.splice(viewFilteredListIndex, 1);
              } else {
                this.viewFilteredList[viewFilteredListIndex].purchaseReturn = purchaseReturn;
              }
            }
          }
        })
      )
  
      this.subsArr.push(this.allDataService.expenseService.updateSubs
        .subscribe((expense: Expense) => {
          if (expense) {
            let transactionsListIndex = this.transactionsList.findIndex(el => el?.docLocalUUID === expense?._localUUID);
            if(transactionsListIndex != -1) {
              if(expense?.deletedStamp) {
                this.transactionsList.splice(transactionsListIndex, 1);
              } else {
                this.transactionsList[transactionsListIndex].expense = expense;
              }
            }
            let filteredListIndex = this.filteredList.findIndex(el => el?.docLocalUUID === expense?._localUUID);
            if(filteredListIndex != -1) {
              if(expense?.deletedStamp) {
                this.filteredList.splice(filteredListIndex, 1);
              } else {
                this.filteredList[filteredListIndex].expense = expense;
              }
            }
            let viewFilteredListIndex = this.viewFilteredList.findIndex(el => el?.docLocalUUID === expense?._localUUID);
            if(viewFilteredListIndex != -1) {
              if(expense?.deletedStamp) {
                this.viewFilteredList.splice(viewFilteredListIndex, 1);
              } else {
                this.viewFilteredList[viewFilteredListIndex].expense = expense;
              }
            }
          }
        })
      )
  
      this.subsArr.push(this.allDataService.moneyInService.updateSubs
        .subscribe((moneyIn: MoneyIn) => {
          if (moneyIn) {
            let transactionsListIndex = this.transactionsList.findIndex(el => el?.docLocalUUID === moneyIn?._localUUID);
            if(transactionsListIndex != -1) {
              if(moneyIn?.deletedStamp) {
                this.transactionsList.splice(transactionsListIndex, 1);
              } else {
                this.transactionsList[transactionsListIndex].moneyIn = moneyIn;
              }
            }
            let filteredListIndex = this.filteredList.findIndex(el => el?.docLocalUUID === moneyIn?._localUUID);
            if(filteredListIndex != -1) {
              if(moneyIn?.deletedStamp) {
                this.filteredList.splice(filteredListIndex, 1);
              } else {
                this.filteredList[filteredListIndex].moneyIn = moneyIn;
              }
            }
            let viewFilteredListIndex = this.viewFilteredList.findIndex(el => el?.docLocalUUID === moneyIn?._localUUID);
            if(viewFilteredListIndex != -1) {
              if(moneyIn?.deletedStamp) {
                this.viewFilteredList.splice(viewFilteredListIndex, 1);
              } else {
                this.viewFilteredList[viewFilteredListIndex].moneyIn = moneyIn;
              }
            }
          }
        })
      )
  
      this.subsArr.push(this.allDataService.moneyOutService.updateSubs
        .subscribe((moneyOut: MoneyOut) => {
          if (moneyOut) {
            let transactionsListIndex = this.transactionsList.findIndex(el => el?.docLocalUUID === moneyOut?._localUUID);
            if(transactionsListIndex != -1) {
              if(moneyOut?.deletedStamp) {
                this.transactionsList.splice(transactionsListIndex, 1);
              } else {
                this.transactionsList[transactionsListIndex].moneyOut = moneyOut;
              }
            }
            let filteredListIndex = this.filteredList.findIndex(el => el?.docLocalUUID === moneyOut?._localUUID);
            if(filteredListIndex != -1) {
              if(moneyOut?.deletedStamp) {
                this.filteredList.splice(filteredListIndex, 1);
              } else {
                this.filteredList[filteredListIndex].moneyOut = moneyOut;
              }
            }
            let viewFilteredListIndex = this.viewFilteredList.findIndex(el => el?.docLocalUUID === moneyOut?._localUUID);
            if(viewFilteredListIndex != -1) {
              if(moneyOut?.deletedStamp) {
                this.viewFilteredList.splice(viewFilteredListIndex, 1);
              } else {
                this.viewFilteredList[viewFilteredListIndex].moneyOut = moneyOut;
              }
            }
          }
        })
      )
    } catch (error) {
      SentryUtilites.setLog("PartyTransactionPage:updateSubs", error)
    }
  }

  loadMoreListData(event) {
    try {
      if (this.viewFilteredList.length > 0 && this.viewFilteredList.length <= this.filteredList.length) {
        let appendListLength = this.filteredList.length - this.viewFilteredList.length;
        let lastEl = this.viewFilteredList[this.viewFilteredList.length - 1];
        let counter = 0;
        for (let i = 0; i < this.filteredList.length; i++) {
          if (this.filteredList[i].docLocalUUID == lastEl.docLocalUUID) {
            counter = 1;
            continue;
          } 
          if (counter > 0 && appendListLength >= 50) {
            if (counter <= 50) {
              this.viewFilteredList.push({ ...this.filteredList[i] })
            } else {
              break;
            }
            counter++;
          } else if(counter > 0 && appendListLength < 50) {
            if (counter <= appendListLength) {
              this.viewFilteredList.push({ ...this.filteredList[i] })
            } else {
              break;
            }
            counter++;
          }
        }
        (event as InfiniteScrollCustomEvent).target.complete();
      }
    } catch (error) {
      SentryUtilites.setLog("PartyTransactionPage:loadMoreListData", error)
    }
  }

  getParamDocumentId() {
    this.paramDocumentId = this.route.snapshot.paramMap.get('documentId');
  }

  async populateParty(): Promise<boolean> {
    try {
      if (this.paramDocumentId) {
        this.fetchedParty = await this.allDataService.partyService.getByUUID(this.paramDocumentId);
        return true;
      }
    } catch (error) {
      SentryUtilites.setLog("PartyTransactionPage:populateParty", error)
      return false;
    }
  }

  async getTransactions() {
    try {
      let completeListHashMap = {};
  
      let completeListLength = this.transactionsList.length;
      for (let i = 0; i < completeListLength; i++) {
        let key = JSON.stringify(this.transactionsList[i]);
        completeListHashMap[key] = true;
      }
  
      await this.populateParty();
      let newTransactionsList = (await this.allDataService.partyService.calculateRunningCredit(this.paramDocumentId))?.transactionsList;

      let differentObjects = [];
  
      // storing credit and find out differentObjects from transactionsList
      if(this.transactionsList?.length) {
        let newTransactionsListLength = newTransactionsList?.length;
        for (let i = 0; i < newTransactionsListLength; i++) {
          let newCompleteObj = newTransactionsList[i];
          let key = JSON.stringify(newCompleteObj);
          if (!completeListHashMap[key]) {
            differentObjects.push(newCompleteObj);
          }
        }
      }
  
      this.ledgerCredit = Number((await this.monthWisePartyCreditService.getLedgerCredit(this.paramDocumentId))?.toFixed(6));
  
      if(
        newTransactionsList && (this.transactionsList?.length != newTransactionsList?.length
          || JSON.stringify(this.transactionsList) != JSON.stringify(newTransactionsList))
      ) {
  
        if(this.transactionsList?.length) {
          let differentObjectsLength = differentObjects?.length;
          if (differentObjectsLength) {
            for (let i = 0; i < differentObjectsLength; i++) {
              let differentObject = differentObjects[i];
              let index = this.transactionsList.findIndex(
                (el) => el?.docLocalUUID === differentObject?.docLocalUUID
              );
              if (index != -1) {
                this.transactionsList[index] = differentObject;
              } else {
                this.transactionsList.push(differentObject);
              }
            }
          }
        } else {
          this.transactionsList = newTransactionsList;
        }
  
        this.filteredList = this.transactionsList.filter(x => x.billDateStamp >= this.startStamp && x.billDateStamp < this.endStamp);
        this.filteredList.sort((a, b) => {
          if (b.billDateStamp == a.billDateStamp) {
            return b.createdStamp - a.createdStamp;
          }
          return b.billDateStamp - a.billDateStamp;
        });
        this.viewFilteredList = this.viewFilteredList?.length > 50 ? this.filteredList.slice(0, this.viewFilteredList?.length) : this.filteredList.slice(0, 50);
      }
    } catch (error) {
      SentryUtilites.setLog("PartyTransactionPage:getTransactions", error)
    }

  }

  reduceFunctionCall() {
    try {
      if(+new Date() > this.loadViewTimeStamp) {
        this.loadViewTimeStamp = +new Date() + 5000;
        this.getParamDocumentId();
        this.getTransactions();
      }
    } catch (error) {
      SentryUtilites.setLog("PartyTransactionPage:reduceFunctionCall", error)
    }
  }

  async editParty() {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({action:'editParty'});
      if(!isPermit) {
        return alert("Permission: You don't have permission to edit party. Please contact to your owner.");
      }
      this.openTransactionPINModal();
    } catch (error) {
      SentryUtilites.setLog("PartyTransactionPage:editParty", error)
      alert("Something went wrong.");
    }
  }

  async deleteParty(ignoreCashSale?:boolean) {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({action:'deleteParty'});
      if(!isPermit) {
        return alert("Permission: You don't have permission to delete party. Please contact to your owner.");
      }
      if(!ignoreCashSale && this.fetchedParty?.isCashSaleParty){
        alert('Cash sale Party cannot be deleted.');
        return;
      }
      this.deleteDataEle?.initDeleteParty(this.fetchedParty);
    } catch (error) {
      SentryUtilites.setLog("PartyTransactionPage:deleteParty", error)
      alert("Something went wrong.");
    }
  }

  addPayment(sale: Sale) {
    this.selectedSale = sale;
    this.showMoneyInSelector = true;
  }

  addPaymentSaleReturn(saleReturn: SaleReturn) {
    this.selectedSaleReturn = saleReturn;
    this.showMoneyOutSelectorSaleReturn = true;
  }

  addPaymentExpense(expense: Expense) {
    this.selectedExpense = expense;
    this.showMoneyOutSelectorExpense = true;
  }

  addPaymentPurchase(purchase: Purchase) {
    this.selectedPurchase = purchase;
    this.showMoneyOutSelectorPurchase = true;
  }

  addPaymentPurchaseReturn(purchaseReturn: PurchaseReturn) {
    this.selectedPurchaseReturn = purchaseReturn;
    this.showMoneyInSelectorPurchaseReturn = true;
  }

  async onMoneyOutCartUpdateSaleReturn(moneyOuts: MoneyOut[]) {
    this.selectedSaleReturn.moneyOuts = moneyOuts;
    this.allDataService.listForceReloadSubs.next('sale-return-list');
    let updatedSaleReturn = await this.allDataService.saleReturnService.update(this.selectedSaleReturn);
    this.allDataService.moneyOutService.reloadList();
  }

  async onMoneyOutCartUpdateExpense(moneyOuts: MoneyOut[]) {
    this.selectedExpense.moneyOuts = moneyOuts;
    this.allDataService.listForceReloadSubs.next('expense-list');
    let updatedExpense = await this.allDataService.expenseService.update(this.selectedExpense);
    this.allDataService.moneyOutService.reloadList();
  }

  async onMoneyOutCartUpdatePurchase(moneyOuts: MoneyOut[]) {
    this.selectedPurchase.moneyOuts = moneyOuts;
    this.allDataService.listForceReloadSubs.next('purchase-list');
    let updatedPurchase = await this.allDataService.purchaseService.update(this.selectedPurchase);
    this.allDataService.moneyOutService.reloadList();
  }

  resetSelectedSale() {
    this.selectedSale = null;
  }

  onDateRangeChange(data:{fromStamp:number,toStamp:number}) {
    try {
      if(this.startStamp === data.fromStamp && this.endStamp === data.toStamp) {
        return null;
      }else {
        this.startStamp = data.fromStamp;
        this.endStamp = data.toStamp;
        this.filteredList = [...this.transactionsList.filter(x => x?.billDateStamp >= this.startStamp && x?.billDateStamp < this.endStamp)];
        this.filteredList.sort((a, b) => {
          if (b?.billDateStamp == a?.billDateStamp) {
            return b?.createdStamp - a?.createdStamp;
          }
          return b?.billDateStamp - a?.billDateStamp;
        });
        this.viewFilteredList = this.viewFilteredList?.length > 50 ? [...this.filteredList.slice(0, this.viewFilteredList?.length)] : [...this.filteredList.slice(0, 50)];
      }
    } catch (error) {
      SentryUtilites.setLog("PartyTransactionPage:onDateRangeChange", error)
    }
  }

  deleteSale(sale: Sale) {
    this.deleteDataEle?.initDeleteSale(sale);
  }

  deleteSaleReturn(saleReturn: SaleReturn) {
    this.saleReturnBulkDeleteEle?.initDeleteSaleReturn(saleReturn);
  }

  deletePurchase(purchase: Purchase) {
    this.deletePurchaseEle?.initDeletePurchase(purchase);
  }

  deletePurchaseReturn(purchaseReturn: PurchaseReturn) {
    this.deletePurchaseReturnEle?.initDeletePurchaseReturn(purchaseReturn);
  }

  deleteExpense(expense: Expense) {
    this.deleteDataEle?.initDeleteExpense(expense);
  }

  deleteMoneyIn(moneyIn: MoneyIn) {
    this.deleteDataEle?.initDeleteMoneyIn(moneyIn);
  }

  deleteMoneyOut(moneyOut: MoneyOut) {
    this.deleteDataEle?.initDeleteMoneyOut(moneyOut);
  }

  onPartyDelete(event) {
    if(event) {
      this.router.navigate(['party']);
    }
  }

  openTransactionPINModal() {
    this.pinVerificationElement?.openTransactionPINModal();
  }

  verifyTransactionPIN(event) {
    if(event) {
      this.router.navigate([`party/form/${this.paramDocumentId}`]);
    }
  }

  openReportView() {
    if(this.fetchedParty?._localUUID) {
      this.router.navigate(['reports'], { queryParams: { type: 'PartyReport', localUUID: this.fetchedParty?._localUUID } });
    }
  }

  toggleOption() {
    this.isOptionOpen = !this.isOptionOpen;
  }

  clickOutSide() {
    this.isOptionOpen = false;
  }

  @HostListener('window:resize', ['$event'])
  OnResize() {
    try {
      setTimeout(() => {
        this.isMobile = this.commonService.isMobile();
        if(this.isMobile) {
          this.innerHeight = `${(((window?.innerHeight - 324)/window?.innerHeight) * 100) - 2}%`;
        } else {
          this.innerHeight = `${(((window?.innerHeight - 251)/window?.innerHeight) * 100) - 2}%`;
        }
      }, 50)
    } catch (error) {
      SentryUtilites.setLog("PartyTransactionPage:OnResize", error)
    }
  }

}
