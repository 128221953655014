import { BaseModel } from "./BaseModel.model";
import Party from "./Party.model";

export class IMoneyOut {

	static SCHEMA_NAME = 'moneyOut';

}

export class MoneyOut extends BaseModel {

	profileId: string;

	billNo: string;
	billDateStamp: number;
	party: Party;
	totalAmount: number;
	paymentMode: string;
	paymentId:string;
	linkedPurchaseUUID: string;
	linkedExpenseUUID: string;
	linkedSaleReturnUUID: string;

}
