import { Component, OnInit } from '@angular/core';
import { Utility } from '../../utils/utility';
import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'app-marketing',
  templateUrl: './marketing.page.html',
  styleUrls: ['./marketing.page.scss'],
})
export class MarketingPage implements OnInit {

  getHeaderColorClass = Utility.getHeaderColorClass;

  constructor(
    private authservice: AuthService,
  ) { }

  ngOnInit() {
  }

  orderReviewCard() {
    let phone = this.authservice.getLoginPhone();
    window.open(`https://db.ezobooks.in/kappa/nfc/order/${phone}`)
  }

  influencer() {
    let phone = this.authservice.getLoginPhone();
    window.open(`https://db.ezobooks.in/kappa/influencer/order/${phone}`)
  }

  marketingChat() {
    let phone = this.authservice.getLoginPhone();
    window.open(`https://db.ezobooks.in/kappa/marketing/chat/${phone}`)
  }

}
