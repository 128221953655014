import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import Party from '../../models/Party.model';
import { AlertController, InfiniteScrollCustomEvent, IonCheckbox, IonFab } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { AllDataService } from '../../services/all-data.service';
import { Sale } from '../../models/Sale.model';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { CommonService } from '../../services/common.service';
import { Utility } from '../../utils/utility';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-whatapp-party-selector',
  templateUrl: './whatapp-party-selector.component.html',
  styleUrls: ['./whatapp-party-selector.component.scss'],
})
export class WhatappPartySelectorComponent implements OnInit {
  @Input() isModal: boolean = false;
  @Input() variables: {
    key: string;
    value: string;
  }[] = [];

  @Output() nextClick = new EventEmitter<Party[]>();

  @ViewChild('searchBar') searchBar;
  @ViewChild('categoryFab') categoryFab: IonFab;
  @ViewChild('satisfactionFab') satisfactionFab: IonFab;
  @ViewChild('sortByFab') sortByFab: IonFab;

  isMobile: boolean = null;

  filteredList: Party[] = [];
  completeList: Party[] = [];
  viewFilteredList: Party[] = [];
  getAllParties: Party[] = [];

  getAllSales: Sale[] = [];

  appliedCategory: string = '';
  appliedSatisfaction: string = '';
  selectedFilter: string = 'SortByFavAsc';

  isFilterPostpond: boolean = false;
  lastFilterStamp: number = 0;

  isFilterOptionOpen: boolean = false;
  isSelectAll: boolean = false;
  isPillsSelectedVal: 'show-all' | 'active-30-days' | 'active-60-days' | 'in-active-30-days' | 'in-active-60-days' = null;

  lastSearchKey: string = '';

  innerWidth: number;

  categories: string[] = [];

  subsArr: Subscription[] = [];

  disableObjectKey: string[] = [];
  verifiedPartyCount: number = 0;

  returnZero = () => {
    return 0;
  };

  selectedParties: Party[] = [];

  satisfactionObj = {
    Delighted: '5',
    Satisfied: '4',
    Unsatisfied: '3',
  };

  sortObj = {
    SortByNameAsc: 'A to Z',
    SortByNameDesc: 'Z to A',
    SortByCreditAsc: 'Credit Ascending',
    SortByCreditDesc: 'Credit Descending',
    SortByFavAsc: 'Favorite Party by Ascending',
  };

  MATHABS = Math.abs;

  runningBillHashMap: {
    [key: string]: Sale;
  } = {};

  constructor(
    private allDataService: AllDataService,
    private router: Router,
    private commonService: CommonService,
    private alertController: AlertController
  ) {}

  ngOnInit() {
    try {
      this.isMobile = this.commonService.isMobile();
      this.routerSubscription();
      this.variables?.forEach((vars) => {
        if (vars?.value.split('.')[0].toLowerCase() == 'party') {
          this.disableObjectKey.push(vars?.value.split('.')[1]);
        }
      });
      this.loadView();
      this.subsArr.push(
        this.allDataService.listForceReloadSubs.subscribe((listName: string) => {
          if (listName == 'party-list') {
            this.loadView();
          }
        })
      );
  
      this.subsArr.push(
        this.allDataService.partyService.updateSubs.subscribe((party: Party) => {
          if (party) {
            for (let i = 0; i < this.viewFilteredList.length; i++) {
              if (party._localUUID == this.viewFilteredList[i]._localUUID) {
                this.viewFilteredList[i] = party;
                break;
              }
            }
            for (let i = 0; i < this.filteredList.length; i++) {
              if (party._localUUID == this.filteredList[i]._localUUID) {
                this.filteredList[i] = party;
                break;
              }
            }
          }
        })
      );
    } catch (error) {
      SentryUtilites.setLog("WhatappPartySelectorComponent:ngOnInit", error)
    }
  }

  ngAfterViewInit() {
    try {
      setTimeout(() => {
        this.searchBar?.setFocus();
      }, 500);
      this.innerWidth = window?.innerWidth;
      this.checkScreenWidth(window?.innerWidth);
    } catch (error) {
      SentryUtilites.setLog("WhatappPartySelectorComponent:ngAfterViewInit", error)
    }
  }

  @HostListener('window:resize', ['$event'])
  OnResize() {
    try {
      this.innerWidth = window?.innerWidth;
      this.checkScreenWidth(window?.innerWidth);
    } catch (error) {
      SentryUtilites.setLog("WhatappPartySelectorComponent:OnResize", error)
    }
  }

  checkScreenWidth(width: number) {
    try {
      if (width <= 1080) {
        this.isFilterOptionOpen = false;
      } else {
        this.isFilterOptionOpen = true;
      }
    } catch (error) {
      SentryUtilites.setLog("WhatappPartySelectorComponent:checkScreenWidth", error)
    }
  }

  routerSubscription() {
    try {
      this.subsArr.push(
        this.router.events.subscribe((event: RouterEvent) => {
          if (event instanceof NavigationEnd) {
            event?.url === '/party' && this.loadView();
          }
        })
      );
    } catch (error) {
      SentryUtilites.setLog("WhatappPartySelectorComponent:routerSubscription", error)
    }
  }

  async getCategories() {
    try {
      let partyCategories =
        await this.allDataService.partyCategoryService.getAllByPromise() || [];
      if (partyCategories?.length) {
        this.categories = partyCategories?.map((x) => x?.name);
      }
    } catch (error) {
      SentryUtilites.setLog("WhatappPartySelectorComponent:getCategories", error)
    }
  }

  async loadView() {
    try {
      // this.filteredList = [];
      // this.completeList = [];
      // this.viewFilteredList = [];
  
      // this.appliedCategory = '';
      // this.selectedFilter = 'SortByFavAsc';
  
      this.categories = [];
  
      this.completeList =
        await this.allDataService.partyService.getAllByPromise() || [];
  
      this.getAllSales = await this.allDataService.saleService.getAllByPromise() || [];
  
      this.onFilterChange();
      this.getCategories();
  
      let salesOnHold =
        await this.allDataService.saleService.getAllByProfileWithOnlyRunningBill();
      this.completeList?.forEach((party) => {
        let checkPartys: boolean = this.checkDisableKeys(party);
        if (checkPartys && party?.phone && !party?.isCashSaleParty) {
          party['checked'] = false;
          this.verifiedPartyCount++;
        }
        this.runningBillHashMap[party._localUUID] =
          salesOnHold
            .filter((sale) => sale?.party?._localUUID === party?._localUUID)
            .sort((a, b) => b?.createdStamp - a?.createdStamp)[0] || null;
      });
  
      // this.getAllParties = {...this.completeList};
    } catch (error) {
      SentryUtilites.setLog("WhatappPartySelectorComponent:loadView", error)
    }
  }

  checkDisableKeys(party: Party) {
    try {
      let checkValue: boolean = true;
      for (let i = 0; i < this.disableObjectKey?.length; i++) {
        let key = this.disableObjectKey[i];
        if (!party?.[key]) {
          checkValue = false;
        }
      }
      return checkValue;
    } catch (error) {
      SentryUtilites.setLog("WhatappPartySelectorComponent:checkDisableKeys", error)
      return false;
    }
  }

  checkErrorMsg(party: Party) {
    try {
      let errorMsg = '';
      if (!party?.phone) {
        errorMsg = errorMsg + ' Phone no.';
      }
      for (let i = 0; i < this.disableObjectKey?.length; i++) {
        let key = this.disableObjectKey[i];
        if (!party[key]) {
          errorMsg = `${errorMsg}, ${Utility.splitByCamelCase(
            key
          ).toLowerCase()}`;
        }
      }
      if (errorMsg) {
        errorMsg = errorMsg + ' is missing';
        return errorMsg;
      } else {
        return '';
      }
    } catch (error) {
      SentryUtilites.setLog("WhatappPartySelectorComponent:checkErrorMsg", error)
      return '';
    }
  }

  toggleFilterOptions() {
    this.isFilterOptionOpen = !this.isFilterOptionOpen;
  }

  closeclickOutSideCategory() {
    this.categoryFab.close();
  }

  closeclickOutSideSatisfaction() {
    this.satisfactionFab.close();
  }

  closeclickOutSideSort() {
    this.sortByFab.close();
  }

  closeSatisfactionSort() {
    this.satisfactionFab.close();
    this.sortByFab.close();
  }

  closeCategorySort() {
    this.categoryFab.close();
    this.sortByFab.close();
  }

  closeCategorySatisfaction() {
    this.categoryFab.close();
    this.satisfactionFab.close();
  }

  onCategoryChange(category: string) {
    this.appliedCategory = category;
    this.onFilterChange();
  }

  onSatisfactionChange(satisfactionIndex: string) {
    this.appliedSatisfaction = satisfactionIndex;
    this.onFilterChange();
  }

  onSortChnage(selectedFilter: string) {
    this.selectedFilter = selectedFilter;
    this.onFilterChange();
  }

  onFilterChange(searchKey?: string) {
    try {
      if (this.isFilterPostpond) {
        return true;
      }
      if (this.lastFilterStamp < +new Date() - 200) {
        this.lastFilterStamp = +new Date();
        this.applyFilter({
          searchKeyword: searchKey,
          selectedFilter: this.selectedFilter,
          category: this.appliedCategory,
          satisfactionIndex: this.appliedSatisfaction,
        });
      } else {
        this.isFilterPostpond = true;
        setTimeout(() => {
          this.isFilterPostpond = false;
          this.onFilterChange(searchKey);
        }, 250);
      }
    } catch (error) {
      SentryUtilites.setLog("WhatappPartySelectorComponent:onFilterChange", error)
    }
  }

  applyFilter(params: {
    searchKeyword?: string;
    selectedFilter?: string;
    category?: string;
    satisfactionIndex?: string;
  }) {
    try {
      this.verifiedPartyCount = 0;
      this.selectedParties = [];
      this.viewFilteredList?.forEach((party: Party) => {
        if (party.hasOwnProperty('checked')) {
          party['checked'] = false;
        }
      });
      let arr = [];
      let lsearchKey = '';
      let isSearchIncremental = false;
      let isSearchCleared = false;
      if (params?.searchKeyword?.length) {
        lsearchKey = Utility.toLowerCase(params.searchKeyword);
        if (lsearchKey.indexOf(this.lastSearchKey) == 0) {
          isSearchIncremental = true;
        }
        this.lastSearchKey = lsearchKey;
      }
      if (this.lastSearchKey.length > 0 && lsearchKey.length == 0) {
        isSearchCleared = true;
        this.lastSearchKey = '';
      }
      let completeList = [];
      if (isSearchIncremental) {
        completeList = [...this.filteredList];
      } else {
        completeList = [...this.completeList];
      }
  
      if (params?.satisfactionIndex) {
        completeList?.forEach((el) => {
          if (params?.satisfactionIndex == el?.satisfactionIndex) {
            arr.push(el);
          }
        });
      } else if (params?.category && lsearchKey.length) {
        if (params?.category == 'payable') {
          completeList?.forEach((el) => {
            const credit = Number(el?.credit);
            if (Utility.isNumber(credit) && credit < 0) {
              arr.push(el);
            }
          });
        } else if (params?.category == 'receivable') {
          completeList?.forEach((el) => {
            const credit = Number(el?.credit);
            if (Utility.isNumber(credit) && credit > 0) {
              arr.push(el);
            }
          });
        } else {
          completeList?.forEach((el) => {
            if (el?.category == params.category) {
              arr.push(el);
            }
          });
        }
        arr = arr.filter((el: Party) => {
          if (lsearchKey.length < 4) {
            if (Utility.isNumber(lsearchKey)) {
              if (Utility.toLowerCase(el?.phone).indexOf(lsearchKey) != -1) {
                return el;
              }
            } else if (Utility.toLowerCase(el?.name).indexOf(lsearchKey) == 0) {
              return el;
            }
          } else if (
            Utility.toLowerCase(el?.phone).indexOf(lsearchKey) != -1 &&
            Utility.isNumber(lsearchKey)
          ) {
            return el;
          } else {
            if (Utility.toLowerCase(el?.name).indexOf(lsearchKey) != -1) {
              return el;
            }
          }
        });
      } else if (params?.category) {
        if (params?.category == 'payable') {
          completeList?.forEach((el) => {
            const credit = Number(el?.credit);
            if (Utility.isNumber(credit) && credit < 0) {
              arr.push(el);
            }
          });
        } else if (params?.category == 'receivable') {
          completeList?.forEach((el) => {
            const credit = Number(el?.credit);
            if (Utility.isNumber(credit) && credit > 0) {
              arr.push(el);
            }
          });
        } else {
          completeList?.forEach((el) => {
            if (el?.category == params.category) {
              arr.push(el);
            }
          });
        }
      } else if (lsearchKey.length) {
        completeList?.forEach((el: Party) => {
          if (lsearchKey.length < 4) {
            if (Utility.isNumber(lsearchKey)) {
              if (Utility.toLowerCase(el?.phone).indexOf(lsearchKey) != -1) {
                arr.push(el);
              }
            } else if (Utility.toLowerCase(el?.name).indexOf(lsearchKey) == 0) {
              arr.push(el);
            }
          } else if (
            Utility.toLowerCase(el?.phone).indexOf(lsearchKey) != -1 &&
            Utility.isNumber(lsearchKey)
          ) {
            arr.push(el);
          } else {
            if (Utility.toLowerCase(el?.name).indexOf(lsearchKey) != -1) {
              arr.push(el);
            }
          }
        });
      } else {
        arr = [...completeList];
      }
  
      if (params.selectedFilter == 'SortByNameAsc') {
        arr.sort((a: Party, b: Party) => {
          if (a?.name?.toLowerCase() == b?.name?.toLowerCase()) {
            return a?.createdStamp - b?.createdStamp;
          }
          if (a?.name?.toLowerCase() > b?.name?.toLowerCase()) {
            return 1;
          }
          return -1;
        });
      } else if (params.selectedFilter == 'SortByNameDesc') {
        arr.sort((a: Party, b: Party) => {
          if (a?.name?.toLowerCase() == b?.name?.toLowerCase()) {
            return a?.createdStamp - b?.createdStamp;
          }
          if (b?.name?.toLowerCase() > a?.name?.toLowerCase()) {
            return 1;
          }
          return -1;
        });
      } else if (params.selectedFilter == 'SortByCreditAsc') {
        arr.sort((a: Party, b: Party) => a?.credit - b?.credit);
      } else if (params.selectedFilter == 'SortByCreditDesc') {
        arr.sort((a: Party, b: Party) => b?.credit - a?.credit);
      } else if (params.selectedFilter == 'SortByFavAsc') {
        arr.sort((a: Party, b: Party) => {
          if (a?.name?.toLowerCase() == b?.name?.toLowerCase()) {
            return a?.createdStamp - b?.createdStamp;
          }
          if (a?.name?.toLowerCase() > b?.name?.toLowerCase()) {
            return 1;
          }
          return -1;
        });
        let favParties: Party[] = [];
        let nonFavParties: Party[] = [];
        arr?.filter((party) => {
          if (party?.isFavourite) {
            favParties.push(party);
          } else {
            nonFavParties.push(party);
          }
        });
        arr = favParties.concat(nonFavParties);
      }
  
      this.filteredList = [...arr];
  
      if (lsearchKey) {
        let priorityArr: Party[] = [];
        this.filteredList?.forEach((x, i) => {
          if ((x?.name+'')?.startsWith(lsearchKey) || (x?.phone+'')?.startsWith(lsearchKey)) {
            priorityArr.push(x);
            this.filteredList.splice(i, 1);
          }
        });
        this.filteredList = [...priorityArr.sort(), ...this.filteredList];
      }
  
      if (
        lsearchKey.length ||
        params.category ||
        params.selectedFilter ||
        isSearchCleared
      ) {
        this.viewFilteredList = [...this.filteredList.slice(0, 50)];
      } else if (!this.viewFilteredList.length) {
        this.viewFilteredList = [...this.filteredList.slice(0, 50)];
      }
  
      this.viewFilteredList?.forEach((party: Party) => {
        if (party.hasOwnProperty('checked')) {
          this.verifiedPartyCount++;
        }
      });
    } catch (error) {
      SentryUtilites.setLog("WhatappPartySelectorComponent:applyFilter", error)
    }
  }

  loadMoreListData(event: any) {
    try {
      if (
        this.viewFilteredList.length > 0 &&
        this.viewFilteredList.length <= this.filteredList.length
      ) {
        let appendListLength =
          this.filteredList.length - this.viewFilteredList.length;
        let lastEl = this.viewFilteredList[this.viewFilteredList.length - 1];
        let counter = 0;
        for (let i = 0; i < this.filteredList.length; i++) {
          if (this.filteredList[i]._localUUID == lastEl._localUUID) {
            counter = 1;
            continue;
          }
          if (counter > 0 && appendListLength >= 50) {
            if (counter <= 50) {
              this.viewFilteredList.push({ ...this.filteredList[i] });
            } else {
              break;
            }
            counter++;
          } else if (counter > 0 && appendListLength < 50) {
            if (counter <= appendListLength) {
              this.viewFilteredList.push({ ...this.filteredList[i] });
            } else {
              break;
            }
            counter++;
          }
        }
        (event as InfiniteScrollCustomEvent)?.target?.complete();
      } 
    } catch (error) {
      SentryUtilites.setLog("WhatappPartySelectorComponent:loadMoreListData", error)
    }
  }

  /**
   *
   * @param label {string} : Provide selected pill option name
   * @description : select those check box which pill option you choose
   */
  selectedPills(label: 'show-all' | 'active-30-days' | 'active-60-days' | 'in-active-30-days' | 'in-active-60-days') {
    try {
      this.selectedParties = [];
      this.completeList?.forEach((party: Party) => {
        if (
          this.checkDisableKeys(party) &&
          party?.phone &&
          !party?.isCashSaleParty
        ) {
          party['checked'] = false;
        }
      });
      if (this.isPillsSelectedVal === label) {
        this.isPillsSelectedVal = null;
      } else {
        this.isPillsSelectedVal = label;
        if (label === 'show-all') {
          this.completeList?.forEach((party: Party) => {
            if (
              this.checkDisableKeys(party) &&
              party?.phone &&
              !party?.isCashSaleParty
            ) {
              this.selectedParties.push(party);
              party['checked'] = true;
            }
          });
        } else if (label === 'active-30-days') {
          let startStamp = +new Date().setHours(0, 0, 0, 0) - 86400000 * 30;
          let endStamp = +new Date().setHours(23, 59, 59, 999);
          this.getAllSales?.forEach((sale: Sale) => {
            if (
              sale?.updatedStamp > startStamp &&
              sale?.updatedStamp < endStamp
            ) {
              let index = this.completeList?.findIndex(
                (party: Party) => party?._localUUID === sale?.party?._localUUID
              );
              if (index != -1) {
                let party = this.completeList[index];
                let selectedIndex = this.selectedParties?.findIndex((sparty: Party) => party?._localUUID === sparty?._localUUID );
                if (
                  this.checkDisableKeys(party) &&
                  party?.phone &&
                  !party?.isCashSaleParty &&
                  selectedIndex == -1
                ) {
                  this.selectedParties.push(party);
                  party['checked'] = true;
                }
              }
            }
          });
        } else if (label === 'active-60-days') {
          let startStamp = +new Date().setHours(0, 0, 0, 0) - 86400000 * 60;
          let endStamp = +new Date().setHours(23, 59, 59, 999);
          this.getAllSales?.forEach((sale: Sale) => {
            if (
              sale?.updatedStamp > startStamp &&
              sale?.updatedStamp < endStamp
            ) {
              let index = this.completeList?.findIndex(
                (party: Party) => party?._localUUID === sale?.party?._localUUID
              );
              if (index != -1) {
                let party = this.completeList[index];
                let selectedIndex = this.selectedParties?.findIndex((sparty: Party) => party?._localUUID === sparty?._localUUID );
                if (
                  this.checkDisableKeys(party) &&
                  party?.phone &&
                  !party?.isCashSaleParty &&
                  selectedIndex == -1
                ) {
                  this.selectedParties.push(party);
                  party['checked'] = true;
                }
              }
            }
          });
        } else if (label === 'in-active-30-days') {
          let startStamp = +new Date().setHours(0, 0, 0, 0) - 86400000 * 30;
          let endStamp = +new Date().setHours(23, 59, 59, 999);
          this.getAllSales?.forEach((sale: Sale) => {
            if (
              sale?.updatedStamp > startStamp &&
              sale?.updatedStamp < endStamp
            ) {
              let index = this.completeList?.findIndex(
                (party: Party) => party?._localUUID !== sale?.party?._localUUID
              );
              if (index != -1) {
                let party = this.completeList[index];
                let selectedIndex = this.selectedParties?.findIndex((sparty: Party) => party?._localUUID === sparty?._localUUID );
                if (
                  this.checkDisableKeys(party) &&
                  party?.phone &&
                  !party?.isCashSaleParty &&
                  selectedIndex == -1
                ) {
                  this.selectedParties.push(party);
                  party['checked'] = true;
                }
              }
            }
          });
        } else if (label === 'in-active-60-days') {
          let startStamp = +new Date().setHours(0, 0, 0, 0) - 86400000 * 60;
          let endStamp = +new Date().setHours(23, 59, 59, 999);
          this.getAllSales?.forEach((sale: Sale) => {
            if (
              sale?.updatedStamp > startStamp &&
              sale?.updatedStamp < endStamp
            ) {
              let index = this.completeList?.findIndex(
                (party: Party) => party?._localUUID !== sale?.party?._localUUID
              );
              if (index != -1) {
                let party = this.completeList[index];
                let selectedIndex = this.selectedParties?.findIndex((sparty: Party) => party?._localUUID === sparty?._localUUID );
                if (
                  this.checkDisableKeys(party) &&
                  party?.phone &&
                  !party?.isCashSaleParty &&
                  selectedIndex == -1
                ) {
                  this.selectedParties.push(party);
                  party['checked'] = true;
                }
              }
            }
          });
        }
      }
    } catch (error) {
      SentryUtilites.setLog("WhatappPartySelectorComponent:selectedPills", error)
    }
  }

  // --------------------------------------

  selectAllParty(event) {
    try {
      let checked = event?.detail?.checked;
      this.selectedParties = [];
      if (checked) {
        this.viewFilteredList?.forEach((party: Party) => {
          if (
            this.checkDisableKeys(party) &&
            party?.phone &&
            !party?.isCashSaleParty
          ) {
            this.selectedParties.push(party);
          }
        });
      }
      this.viewFilteredList?.forEach((party: Party) => {
        if (
          this.checkDisableKeys(party) &&
          party?.phone &&
          !party?.isCashSaleParty
        ) {
          party['checked'] = checked;
        }
      });
    } catch (error) {
      SentryUtilites.setLog("WhatappPartySelectorComponent:selectAllParty", error)
    }
  }

  selectParty(selectedParty: Party, checked: boolean) {
    try {
      let partyIndex = this.selectedParties.findIndex(
        (party) => party?._localUUID == selectedParty?._localUUID
      );
      if (checked && partyIndex == -1) {
        this.selectedParties['checked'] = checked;
        this.selectedParties.push(selectedParty);
      }
      if (!checked && partyIndex >= 0) {
        this.selectedParties['checked'] = checked;
        this.selectedParties.splice(partyIndex, 1);
      }
      if (this.selectedParties.length == this.verifiedPartyCount) {
        this.isPillsSelectedVal = 'show-all';
      } else {
        this.isPillsSelectedVal = null;
      }
    } catch (error) {
      SentryUtilites.setLog("WhatappPartySelectorComponent:selectParty", error)
    }
  }

  ngOnDestroy() {
    try {
      this.subsArr?.forEach((el) => {
        el.unsubscribe();
      });
    } catch (error) {
      SentryUtilites.setLog("WhatappPartySelectorComponent:ngOnDestroy", error)
    }
  }

  async presentErrorConfirm(textMsg: string) {
    try {
      const alert = await this.alertController.create({
        header: 'Alert!',
        message: `${textMsg}`,
        mode: 'ios',
        buttons: [
          {
            text: 'Okay',
            role: 'cancel',
            handler: () => {},
          },
        ],
      });
  
      await alert.present();
    } catch (error) {
      SentryUtilites.setLog("WhatappPartySelectorComponent:presentErrorConfirm", error)
    }
  }

  onNextClick() {
    try {
      if (this.selectedParties.length) {
        this.nextClick.emit(this.selectedParties);
      } else {
        this.presentErrorConfirm('Please select atleast one party.');
      }
    } catch (error) {
      SentryUtilites.setLog("WhatappPartySelectorComponent:onNextClick", error)
    }
  }
}
