import { BehaviorSubject } from "rxjs";
import { MoneyOut } from "../models/MoneyOut.model";
import { Utility } from "../utils/utility";
import { ExpressServerService } from "./api/express-server.service";
import { IDataService } from "../../interface/IDataService.interface";
import { PartyDao } from "./dao/party.dao";
import { MoneyOutDao } from "./dao/money-out.dao";
import { PurchaseDao } from "./dao/purchase.dao";
import { AuthService } from "./auth/auth.service";
import { ExpenseDao } from "./dao/expense.dao";
import { MonthWisePartyCreditDao } from "./dao/month-wise-party-credit.dao";
import { SentryUtilites } from "../utils/sentryUtilites";
import { SaleReturnDao } from "./dao/sale-return.dao";

export class MoneyOutService implements IDataService<MoneyOut>{
  private static _instance: MoneyOutService;

  public static getInstance(
    dao: MoneyOutDao, 
    partyDao: PartyDao, 
    purchaseDao: PurchaseDao, 
    expenseDao: ExpenseDao, 
    saleReturnDao: SaleReturnDao,
    expressServerService: ExpressServerService, 
    authService: AuthService,
    monthWisePartyCreditDao: MonthWisePartyCreditDao,
    ) {
    if (!this._instance) {
      this._instance = new MoneyOutService(
        dao, 
        partyDao, 
        purchaseDao, 
        expenseDao, 
        saleReturnDao,
        expressServerService,
        authService,
        monthWisePartyCreditDao,
        )
      this._instance.initService();
    }
    this._instance.reloadList();
    return this._instance;
  }

  constructor(
    dao: MoneyOutDao, 
    partyDao: PartyDao, 
    purchaseDao: PurchaseDao, 
    expenseDao: ExpenseDao, 
    saleReturnDao: SaleReturnDao,
    expressServerService: ExpressServerService, 
    authService: AuthService,
    monthWisePartyCreditDao: MonthWisePartyCreditDao,
    ) {
    this.dao = dao;
    this.partyDao = partyDao;
    this.purchaseDao = purchaseDao;
    this.expenseDao = expenseDao;
    this.saleReturnDao = saleReturnDao;
    this.expressServerService = expressServerService;
    this.authService = authService;
    this.monthWisePartyCreditDao = monthWisePartyCreditDao;
  }
  dao: MoneyOutDao;
  partyDao: PartyDao;
  purchaseDao: PurchaseDao;
  expenseDao: ExpenseDao;
  saleReturnDao: SaleReturnDao;
  expressServerService: ExpressServerService;
  authService: AuthService;
  monthWisePartyCreditDao: MonthWisePartyCreditDao;

  LIST_REFRESH_RATE = 1000;
  selectedProfileId: string = null;
  selectedProfileUserId: string = null;
  updateSubs = new BehaviorSubject<MoneyOut>(null);

  lastReloadStamp: number = 0;
  isReloadPostpond = false;

  initService() {
    this.selectedProfileId = Utility.getFromLocalStorage('selectedProfile');
    this.selectedProfileUserId = Utility.getFromLocalStorage('selectedProfileUserId');
    this.reloadList();
  }


  async reloadList() {
    try {
      if (this.isReloadPostpond) {
        return;
      }
      const currentStamp = +new Date();
      if (this.lastReloadStamp < (currentStamp - this.LIST_REFRESH_RATE)) {
        this.lastReloadStamp = currentStamp;
        this.trySyncUnsynced();
      } else {
        this.isReloadPostpond = true;
        setTimeout(() => {
          this.isReloadPostpond = false;
          this.reloadList();
        }, this.LIST_REFRESH_RATE + 100);
      }
    } catch (error) {
      SentryUtilites.setLog("MoneyOutService:reloadList", error)
      return null;
    }
  }

  getAll() {
    return this.dao.getAll();
  }

  getAllByPromise() {
    return this.dao.getAllByProfile(this.selectedProfileId);
  }

  getAllByPromiseByProfile(profileId: string) {
    return this.dao.getAllByProfile(profileId);
  }

  getAllWithDeletedByProfile() {
    return this.dao.getAllWithDeletedByProfile(this.selectedProfileId);
  }

  getByBillDateRange(startTime: number, endTime: number, profileId?: string): Promise<MoneyOut[]> {
    return new Promise(async (resolve, reject) => {
      try {
        let allDocs = await this.dao.getAllByProfile(profileId || this.selectedProfileId);
        if (allDocs != null) {
          let filteredDocs = allDocs.filter(doc => doc?.billDateStamp >= startTime && doc?.billDateStamp < endTime);
          return resolve(filteredDocs);
        } else {
          return resolve(null);
        }
      } catch (error) {
        SentryUtilites.setLog("MoneyOutService:getByBillDateRange", error)
        return resolve(null);
      }
    });
  }

  getByCreatedDateRange(startTime: number, endTime: number): Promise<MoneyOut[]> {
    return new Promise(async (resolve, reject) => {
      try {
        let allDocs = await this.dao.getAllByProfile(this.selectedProfileId);
        if (allDocs != null) {
          let filteredDocs = allDocs.filter(doc => doc?.createdStamp >= startTime && doc?.createdStamp < endTime);
          return resolve(filteredDocs);
        } else {
          return resolve(null);
        }
      } catch (error) {
        SentryUtilites.setLog("MoneyOutService:getByCreatedDateRange", error)
        return resolve(null);
      }
    });
  }

  getById(id: number): Promise<MoneyOut> {
    return this.dao.getById(id);
  }

  getByUUID(uuid: string): Promise<MoneyOut> {
    return this.dao.getByUUID(uuid);
  }

  save(moneyOut: MoneyOut): Promise<MoneyOut> {
    if(Utility.isTruthy(moneyOut)) {
      moneyOut.userId = this.selectedProfileUserId;
      if(!moneyOut?.profileId) {
        moneyOut.profileId = this.selectedProfileId;
      }
      moneyOut.createdBy = moneyOut.lastModifiedBy = this.authService.getLoginPhone();
      moneyOut.createdByName = moneyOut.lastModifiedByName = Utility.getCreatedByName();
  
      return new Promise(async (resolve, reject) => {
        try {
          let savedMoneyOut = await this.dao.save(moneyOut);
          if (savedMoneyOut?._localUUID) {
            let totalAmount = moneyOut?.totalAmount || 0.0
            if (totalAmount > 0.0) {
              await this.partyDao.updateCredit(
                moneyOut?.party,
                totalAmount
              )
              await this.monthWisePartyCreditDao?.modifyCredit(
                savedMoneyOut?.party?._localUUID,
                savedMoneyOut?.billDateStamp,
                totalAmount
              )
            }
          }
    
          if(moneyOut?.linkedPurchaseUUID) {
            await this.purchaseDao.linkMoneyOut(moneyOut?.linkedPurchaseUUID,moneyOut);
          }
    
          if(moneyOut?.linkedExpenseUUID) {
            await this.expenseDao.linkMoneyOut(moneyOut?.linkedExpenseUUID,moneyOut);
          }
          
          if(moneyOut?.linkedSaleReturnUUID) {
            await this.saleReturnDao.linkMoneyOut(moneyOut?.linkedSaleReturnUUID,moneyOut);
          }
  
          this.reloadList();
          return resolve(savedMoneyOut);
        } catch (error) {
          SentryUtilites.setLog("MoneyOutService:save", error)
          return resolve(null);
        }
      });
    } else {
      return null;
    }
  }

  update(moneyOut: MoneyOut): Promise<MoneyOut> {
    return new Promise(async (resolve, reject) => {
      try {
        if (moneyOut?._localUUID) {
          moneyOut.lastModifiedBy = this.authService.getLoginPhone();
          moneyOut.lastModifiedByName = Utility.getCreatedByName();
          let oldMoneyOut = await this.getByUUID(moneyOut._localUUID);
  
          if (
            oldMoneyOut?.party?._localUUID != null
            && moneyOut?.party?._localUUID != null
            && oldMoneyOut?.party?._localUUID != ""
            && oldMoneyOut?.party?._localUUID == moneyOut?.party?._localUUID
          ) {
            let updatedMoneyOut = await this.dao.update(moneyOut);
            let deltaPartyCredit = (moneyOut?.totalAmount || 0.0) - (oldMoneyOut?.totalAmount || 0.0);
            await this.partyDao.updateCredit(
              moneyOut?.party,
              deltaPartyCredit
            )
            await this.monthWisePartyCreditDao?.modifyCredit(
              moneyOut?.party?._localUUID,
              moneyOut?.billDateStamp,
              deltaPartyCredit
            )
  
            if(!oldMoneyOut?.linkedPurchaseUUID && updatedMoneyOut?.linkedPurchaseUUID) {
              await this.purchaseDao.linkMoneyOut(updatedMoneyOut?.linkedPurchaseUUID, updatedMoneyOut)
            } else if(updatedMoneyOut?.linkedPurchaseUUID) {
              await this.purchaseDao.upLinkMoneyOut(updatedMoneyOut?.linkedPurchaseUUID,updatedMoneyOut);
            }
  
            if(!oldMoneyOut?.linkedExpenseUUID && updatedMoneyOut?.linkedExpenseUUID) {
              await this.expenseDao.linkMoneyOut(updatedMoneyOut?.linkedExpenseUUID, updatedMoneyOut)
            } else if(updatedMoneyOut?.linkedExpenseUUID) {
              await this.expenseDao?.upLinkMoneyOut(updatedMoneyOut?.linkedExpenseUUID,updatedMoneyOut);
            }

            if(updatedMoneyOut?.linkedSaleReturnUUID) {
              await this.saleReturnDao.upLinkMoneyOut(updatedMoneyOut?.linkedSaleReturnUUID,updatedMoneyOut);
            }
  
            this.reloadList();
            this.updateSubs.next(updatedMoneyOut);
            return resolve(updatedMoneyOut);
          }
          return resolve(null);
        }
        return resolve(null)
      } catch (error) {
        SentryUtilites.setLog("MoneyOutService:update", error)
        return resolve(null);
      }
    });
  }

  delete(moneyOut: MoneyOut): Promise<MoneyOut> {
    return new Promise(async (resolve, reject) => {
      try {
        if(moneyOut?._localUUID) {
          moneyOut.lastModifiedBy = this.authService.getLoginPhone();
          moneyOut.lastModifiedByName = Utility.getCreatedByName();
          let moneyOutTobeDeleted = await this.getByUUID(moneyOut?._localUUID);
          let deletedMoneyOut = await this.dao.delete(moneyOutTobeDeleted);
          await this.partyDao.updateCredit(
            moneyOutTobeDeleted?.party,
            -(moneyOutTobeDeleted.totalAmount || 0.0)
          )
          await this.monthWisePartyCreditDao?.modifyCredit(
            moneyOutTobeDeleted?.party?._localUUID,
            moneyOutTobeDeleted?.billDateStamp,
            -(moneyOutTobeDeleted?.totalAmount || 0)
          )
    
          if (moneyOutTobeDeleted?.linkedPurchaseUUID) {
            this.purchaseDao.unLinkMoneyOut(moneyOutTobeDeleted?.linkedPurchaseUUID, moneyOutTobeDeleted?._localUUID)
          }
    
          if (moneyOutTobeDeleted?.linkedExpenseUUID) {
            await this.expenseDao.unLinkMoneyOut(moneyOutTobeDeleted?.linkedExpenseUUID, moneyOutTobeDeleted?._localUUID)
          }

          if (moneyOutTobeDeleted?.linkedSaleReturnUUID) {
            await this.saleReturnDao.unLinkMoneyOut(moneyOutTobeDeleted?.linkedSaleReturnUUID, moneyOutTobeDeleted?._localUUID)
          }
    
          this.reloadList();
          this.updateSubs.next(deletedMoneyOut);
          return resolve(deletedMoneyOut);
        }
        return resolve(null);
      } catch (error) {
        SentryUtilites.setLog("MoneyOutService:delete", error)
        return resolve(null);
      }
    });
  }

  isSyncLock = false;
  isSyncPostPond = false;
  async trySyncUnsynced(postpond?: boolean) {
    try {
      if (this.isSyncLock) {
        if (!this.isSyncPostPond) {
          setTimeout(() => {
            this.isSyncPostPond = true;
            this.trySyncUnsynced(true);
          }, 200);
        }
        return true;
      }
      if (postpond) {
        this.isSyncPostPond = false;
      }
      this.isSyncLock = true;
      let unSyncedElements: MoneyOut[] = await this.dao.getAllUnsynced(this.selectedProfileId);
      if (unSyncedElements && unSyncedElements?.length) {
        try {
  
          for (let i = 0; i < unSyncedElements?.length; i++) {
            let unSyncedElement = unSyncedElements[i];
            if(unSyncedElement?._localUUID) {
              unSyncedElement['updatedStamp'] = +new Date();
            }
          }
  
          await this.dao.bulkPut(unSyncedElements);
          await Utility.wait(1000);
          
          let chunkArr = Utility.getChunkArr(unSyncedElements);
          let chunkArrLength = chunkArr?.length;
  
          for(let i = 0; i < chunkArrLength; i++) {
            let result = await this.expressServerService.makeSyncCall('moneyOut', chunkArr[i]);
            if (result && result?.['records']?.length) {
              let arr = result?.['records'];
              for (let i = 0; i < arr?.length; i++) {
                const el = arr[i];
                await this.updateSyncStamp(el);
              }
            }
          }
        } catch (err) {
          SentryUtilites.setLog("MoneyOutService:trySyncUnsynced:inner", err)
        }
      }
      this.isSyncLock = false;
    } catch (error) {
      SentryUtilites.setLog("MoneyOutService:trySyncUnsynced", error)
    }
  }

  updateSyncStamp(el: MoneyOut): Promise<MoneyOut> {
    return new Promise(async (resolve, reject) => {
      try {
        let updatedEl = await this.dao.updateSyncStamp(el);
        this.updateSubs.next(updatedEl);
        return resolve(updatedEl);
      } catch (error) {
        SentryUtilites.setLog("MoneyOutService:updateSyncStamp", error)
        return resolve(null);
      }
    });
  }

  async getNewMoneyOutNo(): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        let moneyOuts = await this.dao.getAllByProfile(this.selectedProfileId);
        let nextMoneyOutNo = 'REC_001';
        if (moneyOuts[0]?.billNo) {
          nextMoneyOutNo = Utility.nextNo(moneyOuts[0]?.billNo);
        }
        return resolve(nextMoneyOutNo);
      } catch (error) {
        SentryUtilites.setLog("MoneyOutService:getNewMoneyOutNo", error)
        return resolve(null);
      }

    });
  }

  async getAllByPurchase(purchaseUUID: string): Promise<MoneyOut[]> {
    return this.dao.getAllByPurchase(purchaseUUID);
  }

  /**
   * 
   * @returns : return deleted moneyOuts from moneyOut dao
   */
  async getAllDeleted(): Promise<MoneyOut[]> {
    try {
      let res = await this.dao.getAllDeletedByProfile(this.selectedProfileId);
      return res || [];
    } catch (error) {
      SentryUtilites.setLog("MoneyOutService:getAllDeleted", error)
      return [];
    }
  }
  // ----------------------------------------------

  copyData(fromProfileId: string, toProfileId: string): Promise<boolean> {
    return new Promise(async (resolve,reject) => {
      try {
        if(Utility.isTruthy(fromProfileId) && Utility.isTruthy(toProfileId)) {
          let fromRecords = await this.getAllByPromiseByProfile(fromProfileId);
          if(fromRecords?.length) {
            let toRecords: MoneyOut[] = [];
            for (let i = 0; i < fromRecords.length; i++) {
              const fetchedRecord = fromRecords[i];
              if(fetchedRecord?._localUUID) {
                fetchedRecord.profileId = toProfileId;
                delete fetchedRecord?._localId;
                delete fetchedRecord?._localUUID;
                let savedRecord = await this.save(fetchedRecord);
                if(savedRecord?._localUUID) {
                  toRecords?.push(savedRecord);
                }
              }
            }
            if(fromRecords?.length === toRecords?.length) {
              return resolve(true);
            }
          }
        }
        return resolve(false);
      } catch (error) {
        SentryUtilites.setLog("MoneyOutService:copyData", error)
        return resolve(false);
      }
    });
  }

}

