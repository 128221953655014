<ion-modal class="full-width-modal" id="item-bulk-edit-modal" [isOpen]="isModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar class="{{ getHeaderColorClass() }}">
        <app-navigation-control slot="start" type="bulk-item"
          (click)="closeItemBulkEditPage()">
        </app-navigation-control>
        <ion-title>Bulk Edit</ion-title>
        <ion-chip color="tertiary" slot="end">
          <input type="text" #customPrimarySecondaryUnitEle>
          <button (click)="addCustomUnit(customPrimarySecondaryUnitEle?.value)">Add Custom Primary / Secondary</button>
        </ion-chip>
        <ion-chip color="tertiary" slot="end">
          <input type="text" #customCategoryEle>
          <button (click)="addCategory(customCategoryEle?.value)">Add Custom Category</button>
        </ion-chip>
      </ion-toolbar>
    </ion-header>
    <ion-content appHorizontalScroll>
      <ion-grid>
        <!-- Item bulk edit header row -->
        <ion-row class="row-header-titles">
          <ion-col [class]="title.key" *ngFor="let title of itemFieldTitles|keyvalue : returnZero">
            {{ title.value }}
            <span *ngIf="title.key == 'col-item-name' || title.key == 'col-sell-price'" class="span-mandatory">*</span>
          </ion-col>
        </ion-row>
        <!-- ----------------------------------------------------------------------------------------- -->

        <!-- Item bulk edit body row -->
        <ion-row class="row-items" [ngClass]="{'row-alt-color':i%2==0}"
          *ngFor="let item of viewFilteredList; let i=index;">
          <!-- Sr No. -->
          <ion-col class="col-sr">
            {{ i + 1 }}
          </ion-col>
          <!-- ---------------------------------------------------------------------------------------- -->

          <!-- Item Name -->
          <ion-col class="col-item-name" [ngClass]="{'col-alt-color':i%2==0, 'col-default-color': i%2 == 1}">
            <input #itemNameEle [ngClass]="{'error': !itemNameEle?.value || noSpaceAtStartValidator(itemNameEle.value)}"
              type="text" [value]="item?.itemName"
              (change)="onItemEdit(item?._localUUID,'itemName',itemNameEle?.value)">
          </ion-col>
          <!-- ---------------------------------------------------------------------------------------- -->

          <!-- Type -->
          <ion-col class="col-type">
            <select name="type" [value]="item?.type" #typeEle
              (change)="onItemEdit(item?._localUUID,'type',typeEle?.value)">
              <option value="product">Product</option>
              <option value="service">Service</option>
            </select>
          </ion-col>
          <!-- ---------------------------------------------------------------------------------------- -->

          <!-- Sell Price -->
          <ion-col class="col-sell-price">
            <input #sellPriceEle type="number" min="0"
              [value]="item?.sellPrice || 0" (change)="onItemEdit(item?._localUUID,'sellPrice',sellPriceEle?.value)">
          </ion-col>
          <!-- ---------------------------------------------------------------------------------------- -->

          <!-- Primary Unit -->
          <ion-col class="col-primary-unit">
            <select name="primaryUnit" [value]="item?.primaryUnit" #primaryUnitEle
              (change)="onItemEdit(item?._localUUID,'primaryUnit',primaryUnitEle?.value)">
              <option [value]="null">Select unit</option>
              <option [value]="unit.key" [selected]="unit.key == item?.primaryUnit"
                *ngFor="let unit of unitsOptions|keyvalue : returnZero">
                {{ unit.value }}
              </option>
            </select>
          </ion-col>
          <!-- ---------------------------------------------------------------------------------------- -->

          <!-- Category -->
          <ion-col class="col-category">
            <select name="category" [value]="item?.category" #categoryEle
              (change)="onItemEdit(item?._localUUID,'category',categoryEle?.value)">
              <option [value]="null">Select category</option>
              <option [value]="category" [selected]="category == item?.category" *ngFor="let category of categories">
                {{ category }}
              </option>
            </select>
          </ion-col>
          <!-- ---------------------------------------------------------------------------------------- -->

          <!-- Purchase Price -->
          <ion-col class="col-purchase-price">
            <input type="number" min="0" [value]="item?.purchasePrice || 0" #purchasePriceEle
              (change)="onItemEdit(item?._localUUID,'purchasePrice',purchasePriceEle?.value)">
          </ion-col>
          <!-- ---------------------------------------------------------------------------------------- -->

          <!-- MRP -->
          <ion-col class="col-mrp">
            <input type="number" min="0" [value]="item?.mrp" #mrpEle
              (change)="onItemEdit(item?._localUUID,'mrp',mrpEle?.value)">
          </ion-col>
          <!-- ---------------------------------------------------------------------------------------- -->

          <!-- Online Delivery Sell Price -->
          <ion-col class="col-online-delivery-sell-price">
            <input type="number" min="0" [value]="item?.onlineDeliverySellPrice" #onlineDeliverySellPriceEle
              (change)="onItemEdit(item?._localUUID,'onlineDeliverySellPrice',onlineDeliverySellPriceEle?.value)">
          </ion-col>
          <!-- ---------------------------------------------------------------------------------------- -->

          <!-- Online Sell Price -->
          <ion-col class="col-online-sell-price">
            <input type="number" min="0" [value]="item?.onlineSellPrice" #onlineSellPriceEle
              (change)="onItemEdit(item?._localUUID,'onlineSellPrice',onlineSellPriceEle?.value)">
          </ion-col>
          <!-- ---------------------------------------------------------------------------------------- -->

          <!-- AC Sell Price -->
          <ion-col class="col-ac-sell-price">
            <input type="number" min="0" [value]="item?.acSellPrice" #acSellPriceEle
              (change)="onItemEdit(item?._localUUID,'acSellPrice',acSellPriceEle?.value)">
          </ion-col>
          <!-- ---------------------------------------------------------------------------------------- -->

          <!-- Non AC Sell Price -->
          <ion-col class="col-non-ac-sell-price">
            <input type="number" min="0" [value]="item?.nonAcSellPrice" #nonAcSellPriceEle
              (change)="onItemEdit(item?._localUUID,'nonAcSellPrice',nonAcSellPriceEle?.value)">
          </ion-col>
          <!-- ---------------------------------------------------------------------------------------- -->

          <!-- Tax Percentage -->
          <ion-col class="col-tax-percentage">
            <select #taxPercentageEle name="taxPercentage" [value]="getTaxPercentageValue(item)"
              (change)="onItemEditTaxSelect(item?._localUUID, taxPercentageEle?.value)">
              <option [value]="null">Select Tax</option>
              <option *ngFor="let tax of taxTypeOptions|keyvalue : returnZero" [value]="tax.key"
                [selected]="tax.key == getTaxPercentageValue(item)">
                {{ tax.key }}
              </option>
            </select>
          </ion-col>
          <!-- ---------------------------------------------------------------------------------------- -->

          <!-- Cess Percentage -->
          <ion-col class="col-cess-percentage">
            <input type="number" min="0" [value]="item?.cessPercentage" #cessPercentageEle
              (change)="onItemEdit(item?._localUUID,'cessPercentage',cessPercentageEle?.value)">
          </ion-col>
          <!-- ---------------------------------------------------------------------------------------- -->

          <!-- Tax (Inclusive | Exclusive) -->
          <ion-col class="col-spinc-tax">
            <select name="spIncTax" [value]="item?.spIncTax" #spIncTaxEle
              (change)="onItemEdit(item?._localUUID,'spIncTax',spIncTaxEle?.value=='true' ? true : false )">
              <option value="true">Prices are with Tax</option>
              <option value="false">Prices are without Tax</option>
            </select>
          </ion-col>
          <!-- ---------------------------------------------------------------------------------------- -->

          <!-- Actual Cost to Customer -->
          <ion-col class="col-actual-cost">
            <input readonly="true" *ngIf="!item?.sellPrice && !item?.taxPercentage"
              placeholder="Please Add Sell Price and Tax to Calculate Actual Cost" class="info-input no-drop">
            <input readonly="true" *ngIf="item?.sellPrice && item?.taxPercentage && (item?.spIncTax == false)"
              value="₹{{+item?.sellPrice || 0}} * ({{(+item?.taxPercentage || 0) + (+item?.cessPercentage || 0)}}% tax) = ₹{{(+item?.sellPrice || 0) * (100 + (+item?.taxPercentage || 0) + (+item?.cessPercentage || 0)) / 100}}{{unitsOptions[item?.primaryUnit] ? ' / '+unitsOptions[item?.primaryUnit] : ''}}"
              class="info-input no-drop">
            <input readonly="true" *ngIf="item?.sellPrice && item?.taxPercentage && (item?.spIncTax == true)"
              value="₹{{((+item?.sellPrice || 0) * 100) / (100 + (+item?.taxPercentage || 0) + (+item?.cessPercentage || 0)) | number : '1.2-2' }} * ({{(+item?.taxPercentage || 0) + (+item?.cessPercentage || 0)}}% tax) = ₹{{+item?.sellPrice || 0}}{{unitsOptions[item?.primaryUnit] ? ' / '+unitsOptions[item?.primaryUnit] : ''}}"
              class="info-input no-drop">
          </ion-col>
          <!-- ---------------------------------------------------------------------------------------- -->

          <!-- Brand Name -->
          <ion-col class="col-brand-name">
            <input type="text" [value]="item?.brandName ? item?.brandName : ''" #brandNameEle
              (change)="onItemEdit(item?._localUUID,'brandName',brandNameEle?.value)">
          </ion-col>
          <!-- ---------------------------------------------------------------------------------------- -->

          <!-- Expiry Date -->
          <ion-col class="col-expiry-Date">
            <input type="button" class="new-ion-chip" [value]="item?.expiryDate ?
            setDateValue(item?.expiryDate) : 'Select Date'"
              (click)="openExpiryDatePicker(item?._localUUID, item?.expiryDate)">
          </ion-col>
          <!-- ---------------------------------------------------------------------------------------- -->

          <!-- Item Code -->
          <ion-col class="col-itemCode">
            <input type="text" [value]="item?.itemCode ? item?.itemCode : ''" #itemCodeEle
              (change)="onItemEdit(item?._localUUID,'itemCode',itemCodeEle?.value)">
          </ion-col>
          <!-- ---------------------------------------------------------------------------------------- -->

          <!-- Barcode -->
          <ion-col class="col-barcode-1">
            <input [type]="selectedProfile?.iSetAlphaNumericBarcodeStatus ? 'text' : 'number'"
              [value]="item?.barcode ? item?.barcode : ''" #barcodeEle (change)="onItemEdit(item?._localUUID,'barcode',barcodeEle?.value)">
            <ion-button (click)="openBarcodeGeneratorModal(item?._localUUID, barcodeEle?.value, 'barcode')">Generate</ion-button>
          </ion-col>
          <!-- ---------------------------------------------------------------------------------------- -->

          <!-- Barcode2 -->
          <ion-col class="col-barcode-2">
            <input [type]="selectedProfile?.iSetAlphaNumericBarcodeStatus ? 'text' : 'number'"
              [value]="item?.barcode2 ? item?.barcode2 : ''" #barcodeEle2 (change)="onItemEdit(item?._localUUID,'barcode2',barcodeEle2?.value)">
            <ion-button (click)="openBarcodeGeneratorModal(item?._localUUID, barcodeEle2?.value, 'barcode2')">Generate</ion-button>
          </ion-col>
          <!-- ---------------------------------------------------------------------------------------- -->

          <!-- Barcode3 -->
          <ion-col class="col-barcode-3">
            <input [type]="selectedProfile?.iSetAlphaNumericBarcodeStatus ? 'text' : 'number'"
              [value]="item?.barcode3 ? item?.barcode3 : ''" #barcodeEle3 (change)="onItemEdit(item?._localUUID,'barcode3',barcodeEle3?.value)">
            <ion-button (click)="openBarcodeGeneratorModal(item?._localUUID, barcodeEle3?.value, 'barcode3')">Generate</ion-button>
          </ion-col>
          <!-- ---------------------------------------------------------------------------------------- -->

          <!-- Barcode4 -->
          <ion-col class="col-barcode-4">
            <input [type]="selectedProfile?.iSetAlphaNumericBarcodeStatus ? 'text' : 'number'"
              [value]="item?.barcode4 ? item?.barcode4 : ''" #barcodeEle4 (change)="onItemEdit(item?._localUUID,'barcode4',barcodeEle4?.value)">
            <ion-button (click)="openBarcodeGeneratorModal(item?._localUUID, barcodeEle4?.value, 'barcode4')">Generate</ion-button>
          </ion-col>
          <!-- ---------------------------------------------------------------------------------------- -->

          <!-- Barcode5 -->
          <ion-col class="col-barcode-5">
            <input [type]="selectedProfile?.iSetAlphaNumericBarcodeStatus ? 'text' : 'number'"
              [value]="item?.barcode5 ? item?.barcode5 : ''" #barcodeEle5 (change)="onItemEdit(item?._localUUID,'barcode5',barcodeEle5?.value)">
            <ion-button (click)="openBarcodeGeneratorModal(item?._localUUID, barcodeEle5?.value, 'barcode5')">Generate</ion-button>
          </ion-col>
          <!-- ---------------------------------------------------------------------------------------- -->

          <!-- HSN -->
          <ion-col class="col-hsn">
            <input type="text" [value]="item?.hsn ? item?.hsn : ''" #hsnEle
              (change)="onItemEdit(item?._localUUID,'hsn',hsnEle?.value)">
          </ion-col>
          <!-- ---------------------------------------------------------------------------------------- -->

          <!-- Low sStock Alert -->
          <ion-col class="col-low-stock-alert">
            <input type="number" min="0" [value]="item?.minStock" #minStockEle
              (change)="onItemEdit(item?._localUUID,'minStock',minStockEle?.value)">
          </ion-col>
          <!-- ---------------------------------------------------------------------------------------- -->

          <!-- Storage Location -->
          <ion-col class="col-storage-location">
            <input type="text" [value]="item?.storageLocation ? item?.storageLocation : ''" #storageLocationEle
              (change)="onItemEdit(item?._localUUID,'storageLocation',storageLocationEle?.value)">
          </ion-col>
          <!-- ---------------------------------------------------------------------------------------- -->

          <!-- Online Dukan Item -->
          <ion-col class="col-online-dukan-item">
            <select name="onlineDukanItem" [value]="item?.onlineDukanItem === false ? 'false' : 'true'"
              #onlineDukanItemEle (change)="onItemEdit(item?._localUUID,'onlineDukanItem',onlineDukanItemEle?.value)">
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
          </ion-col>
          <!-- ---------------------------------------------------------------------------------------- -->

          <!-- Secondary Unit -->
          <ion-col class="col-secondary-unit">
            <select name="secondaryUnit" [value]="item?.secondaryUnit" #secondaryUnitEle
              (change)="onItemEdit(item?._localUUID,'secondaryUnit',secondaryUnitEle?.value)">
              <option [value]="null">Select unit</option>
              <option [value]="unit.key" [selected]="unit.key == item?.secondaryUnit"
                *ngFor="let unit of unitsOptions|keyvalue : returnZero">
                {{ unit.value }}
              </option>
            </select>
          </ion-col>
          <!-- ---------------------------------------------------------------------------------------- -->

          <!-- Retail Sale Unit PER 1 Bulk Purchase Unit -->
          <ion-col class="col-convert-ratio">
            <input type="number" min="0" [ngClass]="{'error': item?.secondaryUnit && item?.secondaryUnit != 'null' && !item?.convertRatio}" [value]="item?.convertRatio" #convertRatioEle
              (change)="onItemEdit(item?._localUUID,'convertRatio',convertRatioEle?.value);calculateBulkPurchaseRatio(item?._localUUID,'convertRatioR', convertRatioEle?.value)">
          </ion-col>
          <!-- ---------------------------------------------------------------------------------------- -->

          <!-- Bulk Purchase Unit PER 1 Retail Sale Unit -->
          <ion-col class="col-convert-ratioR">
            <input type="number" min="0" [ngClass]="{'error': item?.secondaryUnit && item?.secondaryUnit != 'null' && !item?.convertRatioR}" [value]="item?.convertRatioR" #convertRatioREle
              (change)="onItemEdit(item?._localUUID,'convertRatioR',convertRatioREle?.value);calculateBulkPurchaseRatio(item?._localUUID,'convertRatio', convertRatioEle?.value)">
          </ion-col>
          <!-- ---------------------------------------------------------------------------------------- -->

          <!-- Images -->
          <ion-col class="col-images">
            <span *ngFor="let imageData of imagesBase64">
              <img *ngIf="imageData?._localUUID === item?._localUUID" [src]="imageData?.base64">
            </span>
          </ion-col>
          <!-- ---------------------------------------------------------------------------------------- -->

          <!-- Add Image -->
          <ion-col class="col-add-image">
            <ion-button expand="full" (click)="openImageCropper(item?._localUUID, true)"> Add Image </ion-button>
          </ion-col>
          <!-- ---------------------------------------------------------------------------------------- -->

          <!-- Stock -->
          <ion-col class="col-current-stock">
            <input readonly="true" type="number" [value]="capFractionsToTwo(item?.stock)">
          </ion-col>
          <!-- ---------------------------------------------------------------------------------------- -->

          <!-- Adjust Stock -->
          <ion-col class="col-adjust-stock">
            <input type="number" #adjustStockEle (change)="adjustStock(item?._localUUID,+adjustStockEle?.value)">
          </ion-col>
          <!-- ---------------------------------------------------------------------------------------- -->

        </ion-row>
        <!-- --------------------------------------------------------------------------------------------- -->

        <!-- Update Bulk Items -->
        <ion-row>
          <ion-col class="exclude-col">
            <ion-button class="btn-save" [disabled]="isDisabledItemBulkUpdateBtn" (click)="updateEditedItems()">
              Update Bulk Items
            </ion-button>
          </ion-col>
        </ion-row>
        <!-- ---------------------------------------------------------------------------------------- -->

      </ion-grid>

      <ion-infinite-scroll threshold="10px" *ngIf="viewFilteredList?.length" (ionInfinite)="loadMoreListData($event)">
        <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Loading more data...">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>

      <app-barcode-generator #barcodeGeneratorEle [item]="selectedBarcodeItem"
        [companyName]="selectedProfile?.legalName" [barcode]="selectedBarcodeItem?.[selectedBarcode]"
        (barcodeChange)="populateBarcode($event, selectedBarcodeItem?._localUUID)">
      </app-barcode-generator>

      <app-image-cropper *ngIf="isOpenImageCropper" (imageSaved)="onImageSaved($event)" #imageCropperEle
        (modalClosed)="openImageCropper(selectedAddImage?._localUUID, $event ? false : true)">
      </app-image-cropper>

      <!-- <app-ingredient-selector #ingredientSelectorEle [cartIngredients]="selectedItemForm?.value?.itemIngredients"
        (saveTrigger)="saveIngredients($event)">
      </app-ingredient-selector> -->

    </ion-content>
  </ng-template>
</ion-modal>

<!-- Expiry Date Modal -->

<ion-modal class="modal-expiry-date" [isOpen]="isExpiryDateModalOpen" [keepContentsMounted]="keepDateContentsMounted"
  (willDismiss)="closeDOBPicker($event)">
  <ng-template>
    <ion-datetime appYearDefaultBtn [(ngModel)]="dpExpiryDateValue" [showDefaultButtons]="true" presentation="date"
      (ionChange)="onExpiryDateSelect($event)" (ionCancel)="onCancelExpiryDate($event)" [max]="maxDate">
      <span slot="title">Select a Expiry Date</span>
    </ion-datetime>
  </ng-template>
</ion-modal>

<!-- ---------------------------------------------------------------------------------------- -->