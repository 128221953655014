import { NgxIndexedDBService } from "ngx-indexed-db";
import { IDataRepo } from "../../../interface/IDataRepo.interface";
import { ISale, Sale } from "../../models/Sale.model";
import { MoneyIn } from "../../models/MoneyIn.model";
import { Utility } from "src/app/utils/utility";
import { Router } from "@angular/router";
import { SentryUtilites } from "src/app/utils/sentryUtilites";
import { TraceClass } from "@sentry/angular";

export class SaleDao implements IDataRepo<Sale>{
  private static _instance: SaleDao;

  public static getInstance(
    ngxIndexedDBService: NgxIndexedDBService,
    router: Router,
  ) {
    if (!this._instance) {
      this._instance = new SaleDao(ngxIndexedDBService, router)
    }
    return this._instance;
  }

  constructor(
    ngxIndexedDBService: NgxIndexedDBService,
    router: Router,
    ) {
    this.ngxIndexedDBService = ngxIndexedDBService;
    this.router = router;
  }

  ngxIndexedDBService: NgxIndexedDBService;
  router: Router;

  save(data: Sale): Promise<Sale> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && Utility.isTruthy(data)) {
          let timeStamp = +new Date();
          data.createdStamp = timeStamp
          data.updatedStamp = timeStamp
          data.userUpdatedStamp = timeStamp
          data.deletedStamp = 0
          data.syncStamp = 0
  
          this.ngxIndexedDBService
            .add(ISale.SCHEMA_NAME, data)
            .subscribe((savedSale: Sale) => {
              return resolve(savedSale);
            },
            err => {
              console.error(err);
              if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                this.router.navigate(['idbx-error']);
              } else if (typeof err?.target?.error == 'object') {
                SentryUtilites.setLog("SaleDao:save", err?.target?.error)
              } else {
                SentryUtilites.setLog("SaleDao:save", err)
              }
            });
        } else {
          return resolve(null)
        }
      } catch (err) {
        SentryUtilites.setLog("SaleDao:save", err)
        return resolve(null);
      }
    });
  }


  saveDb(data: Sale): Promise<Sale> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && Utility.isTruthy(data)) {
          this.ngxIndexedDBService
            .add(ISale.SCHEMA_NAME, data)
            .subscribe((savedRecord: Sale) => {
              return resolve(savedRecord);
            },
            err => {
              console.error(err);
              if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                  this.router.navigate(['idbx-error']);
                } else if (typeof err?.target?.error == 'object') {
                  SentryUtilites.setLog("SaleDao:saveDb", err?.target?.error)
                } else {
                  SentryUtilites.setLog("SaleDao:saveDb", err)
                }
            });
        } else {
          return resolve(null)
        }
      } catch (error) {
        SentryUtilites.setLog("SaleDao:saveDb", error)
        return resolve(null);
      }
    })
  }

  update(data: Sale): Promise<Sale> {
    return new Promise(async (resolve, reject) => {
      try {
        if (Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?._localUUID) {
          let oldData = await this.getByUUID(data?._localUUID);
          if(oldData?._localUUID) {
            data._localId = oldData?._localId;
            let timeStamp = +new Date();
            data.updatedStamp = timeStamp
            data.userUpdatedStamp = timeStamp
            this.ngxIndexedDBService
              .update(ISale.SCHEMA_NAME, data)
              .subscribe((updatedSale: Sale) => {
                return resolve(updatedSale);
              },
              err => {
                console.error(err);
                if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                  this.router.navigate(['idbx-error']);
                } else if (typeof err?.target?.error == 'object') {
                  SentryUtilites.setLog("SaleDao:update", err?.target?.error)
                } else {
                  SentryUtilites.setLog("SaleDao:update", err)
                }
              });
          } else {
            return resolve(null);
          }
        } else {
          return resolve(null);
        }
      } catch (err) {
        SentryUtilites.setLog("SaleDao:update", err)
        return resolve(null);
      }
    })
  }

  updateDb(data: Sale): Promise<Sale> {
    return new Promise(async (resolve, reject) => {
      try {
        if (Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?._localUUID) {
          let oldData = await this.getByUUID(data?._localUUID);
          if(oldData?._localUUID) {
            data._localId = oldData?._localId;
            this.ngxIndexedDBService
              .update(ISale.SCHEMA_NAME, data)
              .subscribe((updatedRecord: Sale) => {
                return resolve(updatedRecord);
              },
              err => {
                console.error(err);
                if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                  this.router.navigate(['idbx-error']);
                } else if (typeof err?.target?.error == 'object') {
                  SentryUtilites.setLog("SaleDao:updateDb", err?.target?.error)
                } else {
                  SentryUtilites.setLog("SaleDao:updateDb", err)
                }
              });
          } else {
            return resolve(null);
          }
        } else {
          return resolve(null);
        }
      } catch (err) {
        SentryUtilites.setLog("SaleDao:updateDb", err)
        return resolve(null);
      }
    })
  }

  bulkPut(data: Sale[]): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?.length) {
          this.ngxIndexedDBService
            .bulkPut(ISale.SCHEMA_NAME, data)
            .subscribe((data) => {
              return resolve(true);
            },
            err => {
              console.error(err);
              if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                  this.router.navigate(['idbx-error']);
                } else if (typeof err?.target?.error == 'object') {
                  SentryUtilites.setLog("SaleDao:bulkPut", err?.target?.error)
                } else {
                  SentryUtilites.setLog("SaleDao:bulkPut", err)
                }
            })
        } else {
          return resolve(false)
        }
      } catch (error) {
        SentryUtilites.setLog("SaleDao:bulkPut", error)
        return resolve(false);
      }
    })
  }

  delete(data: Sale): Promise<Sale> {
    return new Promise(async (resolve, reject) => {
      try {
        if (Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?._localUUID) {
          let oldData = await this.getByUUID(data?._localUUID);
          if(oldData?._localUUID) {
            data._localId = oldData?._localId;
            let timeStamp = +new Date();
            data.updatedStamp = timeStamp;
            data.userUpdatedStamp = timeStamp;
            data.deletedStamp = timeStamp;
            this.ngxIndexedDBService
              .update(ISale.SCHEMA_NAME, data)
              .subscribe((updatedSale: Sale) => {
                return resolve(updatedSale);
              },
              err => {
                console.error(err);
                if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                  this.router.navigate(['idbx-error']);
                } else if (typeof err?.target?.error == 'object') {
                  SentryUtilites.setLog("SaleDao:delete", err?.target?.error)
                } else {
                  SentryUtilites.setLog("SaleDao:delete", err)
                }
              });
          } else {
            return resolve(null);
          }
        } else {
          return resolve(null);
        }
      } catch (err) {
        SentryUtilites.setLog("SaleDao:delete", err)
        return resolve(null);
      }
    });
  }
  getById(id: number): Promise<Sale> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && id) {
          this.ngxIndexedDBService
            .getByKey(ISale.SCHEMA_NAME, id)
            .subscribe((profile: Sale) => {
              return resolve(profile);
            },
            err => {
              console.error(err);
              if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                this.router.navigate(['idbx-error']);
              } else if (typeof err?.target?.error == 'object') {
                SentryUtilites.setLog("SaleDao:getById", err?.target?.error)
              } else {
                SentryUtilites.setLog("SaleDao:getById", err)
              }
            });
        } else {
          return resolve(null)
        }
      } catch (err) {
        SentryUtilites.setLog("SaleDao:getById", err)
        return resolve(null)
      }
    })
  }
  getByUUID(uuid: string): Promise<Sale> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && uuid) {
          this.ngxIndexedDBService
            .getByIndex(ISale.SCHEMA_NAME, '_localUUID', uuid)
            .subscribe((profile: Sale) => {
              return resolve(profile);
            },
            err => {
              console.error(err);
              if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                this.router.navigate(['idbx-error']);
              } else if (typeof err?.target?.error == 'object') {
                SentryUtilites.setLog("SaleDao:getByUUID", err?.target?.error)
              } else {
                SentryUtilites.setLog("SaleDao:getByUUID", err)
              }
            });
        } else {
          return resolve(null)
        }
      } catch (err) {
        SentryUtilites.setLog("SaleDao:getByUUID", err)
        return resolve(null)
      }
    })
  }
  getAll(): Promise<Sale[]> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token'))) {
          this.ngxIndexedDBService.getAll(ISale.SCHEMA_NAME).subscribe((docs: Sale[]) => {
            docs = docs?.filter(x => !x?.deletedStamp && x?.billCompleteStamp);
            docs?.sort((a, b) => b?.createdStamp - a?.createdStamp);
            return resolve(docs)
          },
          err => {
            console.error(err);
            if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                this.router.navigate(['idbx-error']);
              } else if (typeof err?.target?.error == 'object') {
                SentryUtilites.setLog("SaleDao:gatAll", err?.target?.error)
              } else {
                SentryUtilites.setLog("SaleDao:gatAll", err)
              }
          });
        } else {
          return resolve([])
        }
      } catch (err) {
        SentryUtilites.setLog("SaleDao:getAll", err)
        return resolve([])
      }
    })
  }
  getAllByProfile(profileId: string): Promise<Sale[]> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && profileId) {
          this.ngxIndexedDBService.getAll(ISale.SCHEMA_NAME).subscribe((docs: Sale[]) => {
            docs = docs?.filter(x => !x?.deletedStamp && x?.profileId == profileId && x?.billCompleteStamp);
            docs?.sort((a, b) => b?.createdStamp - a?.createdStamp);
            return resolve(docs)
          },
          err => {
            console.error(err);
            if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                this.router.navigate(['idbx-error']);
              } else if (typeof err?.target?.error == 'object') {
                SentryUtilites.setLog("SaleDao:getAllByProfile", err?.target?.error)
              } else {
                SentryUtilites.setLog("SaleDao:getAllByProfile", err)
              }
          });
        } else {
          return resolve([])
        }
      } catch (err) {
        SentryUtilites.setLog("SaleDao:getAllByProfile", err)
        return resolve([])
      }
    })
  }

  getAllByProfileWithOnlyRunningBill(profileId: string): Promise<Sale[]> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && profileId) {
          this.ngxIndexedDBService.getAll(ISale.SCHEMA_NAME).subscribe((docs: Sale[]) => {
            docs = docs?.filter(x => !x?.deletedStamp && x?.profileId == profileId && !x?.billCompleteStamp);
            docs?.sort((a, b) => b?.createdStamp - a?.createdStamp);
            return resolve(docs)
          },
          err => {
            console.error(err);
            if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                this.router.navigate(['idbx-error']);
              } else if (typeof err?.target?.error == 'object') {
                SentryUtilites.setLog("SaleDao:getAllByProfileWithOnlyRunningBill", err?.target?.error)
              } else {
                SentryUtilites.setLog("SaleDao:getAllByProfileWithOnlyRunningBill", err)
              }
          });
        } else {
          return resolve([])
        }
      } catch (err) {
        SentryUtilites.setLog("SaleDao:getAllByProfileWithOnlyRunningBill", err)
        return resolve([])
      }
    })
  }

  getAllByProfileWithRunningBill(profileId: string): Promise<Sale[]> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && profileId) {
          this.ngxIndexedDBService.getAll(ISale.SCHEMA_NAME).subscribe((docs: Sale[]) => {
            docs = docs?.filter(x => !x?.deletedStamp && x?.profileId == profileId);
            docs?.sort((a, b) => b?.createdStamp - a?.createdStamp);
            return resolve(docs)
          },
          err => {
            console.error(err);
            if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                this.router.navigate(['idbx-error']);
              } else if (typeof err?.target?.error == 'object') {
                SentryUtilites.setLog("SaleDao:getAllByProfileWithRunningBill", err?.target?.error)
              } else {
                SentryUtilites.setLog("SaleDao:getAllByProfileWithRunningBill", err)
              }
          });
        } else {
          return resolve([])
        }
      } catch (err) {
        SentryUtilites.setLog("SaleDao:getAllByProfileWithRunningBill", err)
        return resolve([])
      }
    })
  }

  getAllWithDeletedByProfile(profileId: string): Promise<Sale[]> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && profileId) {
          this.ngxIndexedDBService.getAll(ISale.SCHEMA_NAME).subscribe((docs: Sale[]) => {
            docs = docs?.filter(x => x?.profileId == profileId && x?.billCompleteStamp);
            docs?.sort((a, b) => b?.createdStamp - a?.createdStamp);
            resolve(docs)
          },
          err => {
            console.error(err);
            if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                this.router.navigate(['idbx-error']);
              } else if (typeof err?.target?.error == 'object') {
                SentryUtilites.setLog("SaleDao:getAllWithDeletedByProfile", err?.target?.error)
              } else {
                SentryUtilites.setLog("SaleDao:getAllWithDeletedByProfile", err)
              }
          });
        } else {
          return resolve([])
        }
      } catch (err) {
        SentryUtilites.setLog("SaleDao:getAllWithDeletedByProfile", err)
        return resolve([])
      }
    })
  }

  /**
   * 
   * @param profileId : provide profile ID
   * @returns : return deleted sales
   */
  getAllDeletedByProfile(profileId: string): Promise<Sale[]> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && profileId) {
          this.ngxIndexedDBService.getAll(ISale.SCHEMA_NAME).subscribe((docs: Sale[]) => {
            docs = docs?.filter(x => x?.profileId == profileId && x?.billCompleteStamp && x?.deletedStamp);
            docs?.sort((a, b) => b?.createdStamp - a?.createdStamp);
            return resolve(docs)
          },
          err => {
            console.error(err);
            if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                this.router.navigate(['idbx-error']);
              } else if (typeof err?.target?.error == 'object') {
                SentryUtilites.setLog("SaleDao:getAllDeletedByProfile", err?.target?.error)
              } else {
                SentryUtilites.setLog("SaleDao:getAllDeletedByProfile", err)
              }
          });
        } else {
          return resolve([])
        }
      } catch (err) {
        SentryUtilites.setLog("SaleDao:getAllDeletedByProfile", err)
        return resolve([])
      }
    })
  }
  // -----------------------------------------------------

  getAllUnsynced(profileId: string): Promise<Sale[]> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token'))) {
          let unSyncedElements: Sale[] = [];
          this.ngxIndexedDBService.getAll(ISale.SCHEMA_NAME).subscribe(async (elArr: Sale[]) => {
            for (let i = 0; i < elArr?.length; i++) {
              const el = elArr[i];
              if (el?.updatedStamp > el?.syncStamp || !el?._serverIdRef) {
                unSyncedElements.push(el);
              }
            }
            return resolve(unSyncedElements)
          },
          err => {
            console.error(err);
            if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                this.router.navigate(['idbx-error']);
              } else if (typeof err?.target?.error == 'object') {
                SentryUtilites.setLog("SaleDao:getAllUnsynced", err?.target?.error)
              } else {
                SentryUtilites.setLog("SaleDao:getAllUnsynced", err)
              }
          });
        } else {
          return resolve([])
        }
      } catch (err) {
        SentryUtilites.setLog("SaleDao:getAllUnsynced", err)
        return resolve([])
      }
    })
  }
  updateSyncStamp(data: Sale): Promise<Sale> {
    return new Promise(async (resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?._localUUID) {
          this.ngxIndexedDBService
            .getByIndex(ISale.SCHEMA_NAME, '_localUUID', data?._localUUID)
            .subscribe((dbEl: Sale) => {
              if(dbEl) {
                dbEl.syncStamp = data?.syncStamp || 0;
                dbEl._serverIdRef = data?._serverIdRef;
                this.ngxIndexedDBService
                  .update(ISale.SCHEMA_NAME, dbEl)
                  .subscribe((updatedEl: Sale) => {
                    return resolve(updatedEl);
                  },
                  err => {
                    console.error(err);
                    if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                      this.router.navigate(['idbx-error']);
                    } else if (typeof err?.target?.error == 'object') {
                      SentryUtilites.setLog("SaleDao:updateSyncStamp", err?.target?.error)
                    } else {
                      SentryUtilites.setLog("SaleDao:updateSyncStamp", err)
                    }
                  });
              } else {
                return resolve(null);
              }
            },
            err => {
              console.error(err);
              if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                this.router.navigate(['idbx-error']);
              } else if (typeof err?.target?.error == 'object') {
                SentryUtilites.setLog("SaleDao:updateSyncStamp", err?.target?.error)
              } else {
                SentryUtilites.setLog("SaleDao:updateSyncStamp", err)
              }
            })
        } else {
          return resolve(null);
        }
      } catch (err) {
        SentryUtilites.setLog("SaleDao:updateSyncStamp", err)
        return resolve(null)
      }
    })
  }

  async linkMoneyIn(saleUUID: string, moneyIn: MoneyIn): Promise<Boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        let sale = await this.getByUUID(saleUUID);

        if(sale?._localUUID) {
          sale?.moneyIns?.push(moneyIn)
          sale.amountReceived = (sale?.amountReceived || 0.0) + (moneyIn?.totalAmount || 0.0);
  
          sale.lastModifiedBy = moneyIn?.lastModifiedBy;
          sale.lastModifiedByName = moneyIn?.lastModifiedByName;
  
          await this.update(sale);
  
          return resolve(true);
        } 
        return resolve(false);
      } catch (err) {
        SentryUtilites.setLog("SaleDao:linkMoneyIn", err)
        return resolve(false);
      }
    })
  }

  async unLinkMoneyIn(saleUUID: string, moneyInUUID: string): Promise<Boolean> {
    return new Promise(async (resolve, reject) => {
      try {

        let sale = await this.getByUUID(saleUUID);

        if(sale?._localUUID) {
          let newMoneyInList: MoneyIn[] = [];
          let newReceivedAmount: number = 0.0;
  
          sale?.moneyIns?.forEach(oldMoneyIn => {
            if(oldMoneyIn?._localUUID !== moneyInUUID) {
              newReceivedAmount += oldMoneyIn?.totalAmount || 0.0;
              newMoneyInList.push(oldMoneyIn);
            }
          });
          sale.moneyIns = newMoneyInList;
          sale.amountReceived = newReceivedAmount;
  
          await this.update(sale);

          return resolve(true);
        } 
        return resolve(false);

      } catch (err) {
        SentryUtilites.setLog("SaleDao:unLinkMoneyIn", err)
        return resolve(false);
      }
    })
  }

  async upLinkMoneyIn(saleUUID: string, moneyIn: MoneyIn): Promise<Boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        let sale = await this.getByUUID(saleUUID);

        if(sale?._localUUID) {
          let newMoneyInList: MoneyIn[] = [];
          let newReceivedAmount: number = 0.0;
  
          sale?.moneyIns?.forEach(oldMoneyIn => {
            if(oldMoneyIn?._localUUID === moneyIn?._localUUID) {
              newReceivedAmount += moneyIn?.totalAmount || 0.0;
              newMoneyInList.push(moneyIn);
            }else {
              newReceivedAmount += oldMoneyIn?.totalAmount || 0.0;
              newMoneyInList.push(oldMoneyIn);
            }
          });
          sale.moneyIns = newMoneyInList;
          sale.amountReceived = newReceivedAmount;
  
          sale.lastModifiedBy = moneyIn?.lastModifiedBy;
          sale.lastModifiedByName = moneyIn?.lastModifiedByName;
  
          await this.update(sale);

          return resolve(true);
        }
        return resolve(false);

      } catch (err) {
        SentryUtilites.setLog("SaleDao:upLinkMoneyIn", err)
        return resolve(false);
      }
    })
  }

  /**
   * 
   * @param saleUUID : provide sale _localUUID
   * @param saleReturnUUID : provide sale return _localUUID
   * @returns : return true if successfully update sale
   * @description : link sale return with sale
   */
  linkSaleReturn(saleUUID: string, saleReturnUUID: string): Promise<Boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        let sale = await this.getByUUID(saleUUID);
        if(sale?._localUUID) {
          sale.linkedSaleReturnUUID = saleReturnUUID;
          await this.update(sale);
          return resolve(true);
        }
        return resolve(false);
      } catch (error) {
        SentryUtilites.setLog("SaleDao:linkSaleReturn", error)
        return resolve(false);
      }
    })
  }
  // ---------------------------------------------------

  /**
   * 
   * @param saleUUID : provide sale _localUUID
   * @param saleReturnUUID : provide sale return _localUUID
   * @returns : return true if successfully update sale
   * @description : unlink sale return with sale
   */
  unLinkSaleReturn(saleUUID: string, saleReturnUUID: string): Promise<Boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        let sale = await this.getByUUID(saleUUID);
        if(sale?._localUUID && !sale?.deletedStamp) {
          sale.linkedSaleReturnUUID = null;
          await this.update(sale);
          return resolve(true);
        }
        return resolve(false);
      } catch (error) {
        SentryUtilites.setLog("SaleDao:unLinkSaleReturn", error)
        return resolve(false);
      }
    })
  }
  // ---------------------------------------------------

}
