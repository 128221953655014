<ion-header>
  <ion-toolbar class="{{ getHeaderColorClass() }}">
    <app-navigation-control slot="start" type="staff/form"></app-navigation-control>
    <ion-title>Add Staff</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>

  <ion-grid>

    <form [formGroup]="form">

      <ion-row class="custom-row">

        <!-- Name -->

        <ion-col class="custom-col" size="3">
          <ion-button fill="clear" class="btn-title btn-txt-black">
            Name
          </ion-button>
          <ion-input
            class="new-ion-input"
            placeholder="Example: John Doe"
            formControlName="name"
          ></ion-input>
        </ion-col>

        <!-- -------------------------------------------------------------------------------- -->

        <!-- Phone -->

        <ion-col class="custom-col" size="3">
          <ion-button fill="clear" class="btn-title btn-txt-black">
            Phone
          </ion-button>
          <ion-input
            class="new-ion-input"
            [readonly]="userId!=null"
            placeholder="Example: 0000000000"
            formControlName="userId"
          ></ion-input>
        </ion-col>

        <!-- -------------------------------------------------------------------------------- -->

        <!-- Access Type -->

        <ion-col class="custom-col" size="5">
          <ion-button fill="clear" class="btn-title btn-txt-black">
            Access Type
          </ion-button>
          <ion-segment mode="ios" [value]="selectedAccessType" (ionChange)="onAccessTypeChange($event)">
            <ion-segment-button value="SaleAccess" (click)="checkSelectAll()">
              <ion-label>Sale Access</ion-label>
            </ion-segment-button>
            <ion-segment-button value="FullAccess" (click)="checkSelectAll()">
              <ion-label>Full Access</ion-label>
            </ion-segment-button>
            <ion-segment-button value="CustomAccess" (click)="checkSelectAll()">
              <ion-label>Custom Access</ion-label>
            </ion-segment-button>
          </ion-segment>

        </ion-col>

        <!-- -------------------------------------------------------------------------------- -->

      </ion-row>

      <ion-row>
        <ion-col size="12">
          <ion-button
            fill="outline"
            class="dashed-border txt-transform-normal"
            (click)="toggleShowAllFields()"
          >
            Advanced Options
            <ion-icon *ngIf="!showAllFields" name="chevron-down-outline"></ion-icon>
            <ion-icon *ngIf="showAllFields" name="chevron-up-outline"></ion-icon>
          </ion-button>
        </ion-col>
      </ion-row>

      <!-- Profile -->

      <ion-row class="custom-row" *ngIf="showAllFields">
        <ion-col class="custom-col" size="6">
          <ion-button fill="clear" class="btn-title btn-txt-black">
            Profile <span class="sp-select-all">Select All</span> <ion-checkbox #selectAllProfile class="cb-select-all" (click)="selectAllCheckboxClick()" (ionChange)="enableDisableAll($event,'Profile')"></ion-checkbox>
          </ion-button> <br>
          <ion-chip color="tertiary">
            <span class="title">View</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="viewProfile" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>
          <ion-chip color="tertiary">
            <span class="title">Edit</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="editProfile" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>
        </ion-col>
        <ion-col class="custom-col" size="6">
          <ion-button fill="clear" class="btn-title btn-txt-black">
            Expense <span class="sp-select-all">Select All</span> <ion-checkbox #selectAllExpense class="cb-select-all" (click)="selectAllCheckboxClick()" (ionChange)="enableDisableAll($event,'Expense')"></ion-checkbox>
          </ion-button> <br>
          <ion-chip color="tertiary">
            <span class="title">Create</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="createExpense" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">Edit</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="editExpense" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">View</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="viewExpense" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">Delete</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="deleteExpense" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

        </ion-col>
      </ion-row>

      <!-- Select Party Page Permissions -->

      <ion-row class="custom-row" *ngIf="showAllFields">
        <ion-col class="custom-col" size="6">
          <ion-button fill="clear" class="btn-title btn-txt-black">
            Select Party Page Permissions
          </ion-button> <br>
          <ion-chip color="tertiary">
            <span class="title">Hide Phone</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="hidePhone" (ionChange)="togglePartyView($event)"></ion-toggle>
            </span>
          </ion-chip>
        </ion-col>
      </ion-row>

      <!-- Party -->

      <ion-row class="custom-row" *ngIf="showAllFields">
        <ion-col class="custom-col" size="6">
          <ion-button fill="clear" class="btn-title btn-txt-black">
            Party  <span class="sp-select-all">Select All</span> <ion-checkbox #selectAllParty class="cb-select-all" (click)="selectAllCheckboxClick()" (ionChange)="enableDisableAll($event,'Party')"></ion-checkbox>
          </ion-button> <br>
          <ion-chip color="tertiary">
            <span class="title">Create</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="createParty" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>
          <ion-chip color="tertiary">
            <span class="title">Edit</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="editParty" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>
          <ion-chip color="tertiary">
            <span class="title">View</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="viewParty" (click)="checkSelectAll()" (ionChange)="toggleHidePhone($event)"></ion-toggle>
            </span>
          </ion-chip>
          <ion-chip color="tertiary">
            <span class="title">Delete</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="deleteParty" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>
        </ion-col>
        <ion-col class="custom-col" size="6">
          <ion-button fill="clear" class="btn-title btn-txt-black">
            Party Category  <span class="sp-select-all">Select All</span> <ion-checkbox #selectAllPartyCategory class="cb-select-all" (click)="selectAllCheckboxClick()" (ionChange)="enableDisableAll($event,'PartyCategory')"></ion-checkbox>
          </ion-button> <br>
          <ion-chip color="tertiary">
            <span class="title">Create</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="createPartyCategory" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">Edit</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="editPartyCategory" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">View</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="viewPartyCategory" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">Delete</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="deletePartyCategory" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>
        </ion-col>
      </ion-row>

      <!-- Item -->

      <ion-row class="custom-row" *ngIf="showAllFields">
        <ion-col class="custom-col" size="6">
          <ion-button fill="clear" class="btn-title btn-txt-black">
            Item  <span class="sp-select-all">Select All</span> <ion-checkbox #selectAllItem class="cb-select-all" (click)="selectAllCheckboxClick()" (ionChange)="enableDisableAll($event,'Item')"></ion-checkbox>
          </ion-button> <br>
          <ion-chip color="tertiary">
            <span class="title">Create</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="createItem" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>
          <ion-chip color="tertiary">
            <span class="title">Edit</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="editItem" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>
          <ion-chip color="tertiary">
            <span class="title">View</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="viewItem" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>
          <ion-chip color="tertiary">
            <span class="title">Delete</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="deleteItem" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>
        </ion-col>
        <ion-col class="custom-col" size="6">
          <ion-button fill="clear" class="btn-title btn-txt-black">
            Item Category  <span class="sp-select-all">Select All</span> <ion-checkbox #selectAllItemCategory class="cb-select-all" (click)="selectAllCheckboxClick()" (ionChange)="enableDisableAll($event,'ItemCategory')"></ion-checkbox>
          </ion-button> <br>
          <ion-chip color="tertiary">
            <span class="title">Create</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="createItemCategory" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>
          <ion-chip color="tertiary">
            <span class="title">Edit</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="editItemCategory" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>
          <ion-chip color="tertiary">
            <span class="title">View</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="viewItemCategory" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>
          <ion-chip color="tertiary">
            <span class="title">Delete</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="deleteItemCategory" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>
        </ion-col>
      </ion-row>

      <!-- ------------------------------------------ -->

      <!-- Money In -->

      <ion-row class="custom-row" *ngIf="showAllFields">
        <ion-col class="custom-col" size="6">
          <ion-button fill="clear" class="btn-title btn-txt-black">
            Money In <span class="sp-select-all">Select All</span> <ion-checkbox #selectAllMoneyIn class="cb-select-all" (click)="selectAllCheckboxClick()" (ionChange)="enableDisableAll($event,'MoneyIn')"></ion-checkbox>
          </ion-button> <br>
          <ion-chip color="tertiary">
            <span class="title">Create</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="createMoneyIn" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">Edit</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="editMoneyIn" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">View</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="viewMoneyIn" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">Delete</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="deleteMoneyIn" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>
        </ion-col>
        <ion-col class="custom-col" size="6">
          <ion-button fill="clear" class="btn-title btn-txt-black">
            Money Out <span class="sp-select-all">Select All</span> <ion-checkbox #selectAllMoneyOut class="cb-select-all" (click)="selectAllCheckboxClick()" (ionChange)="enableDisableAll($event,'MoneyOut')"></ion-checkbox>
          </ion-button> <br>
          <ion-chip color="tertiary">
            <span class="title">Create</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="createMoneyOut" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">Edit</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="editMoneyOut" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">View</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="viewMoneyOut" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">Delete</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="deleteMoneyOut" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>
        </ion-col>
      </ion-row>

      <ion-row class="custom-row" *ngIf="showAllFields">
        <ion-col class="custom-col" size="6">
          <ion-button fill="clear" class="btn-title btn-txt-black">
            Sale <span class="sp-select-all">Select All</span> <ion-checkbox #selectAllSale class="cb-select-all" (click)="selectAllCheckboxClick()" (ionChange)="enableDisableAll($event,'Sale')"></ion-checkbox>
          </ion-button> <br>
          <ion-chip color="tertiary">
            <span class="title">Create</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="createSale" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">Edit</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="editSale" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">View</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="viewSale" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">Delete</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="deleteSale" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>
        </ion-col>
        <ion-col class="custom-col" size="6">
          <ion-button fill="clear" class="btn-title btn-txt-black">
            Sale Return <span class="sp-select-all">Select All</span> <ion-checkbox #selectAllSaleReturn class="cb-select-all" (click)="selectAllCheckboxClick()" (ionChange)="enableDisableAll($event,'SaleReturn')"></ion-checkbox>
          </ion-button> <br>
          <ion-chip color="tertiary">
            <span class="title">Create</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="createSaleReturn" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <!-- <ion-chip color="tertiary">
            <span class="title">Edit</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="editSaleReturn" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip> -->

          <ion-chip color="tertiary">
            <span class="title">View</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="viewSaleReturn" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">Delete</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="deleteSaleReturn" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>
        </ion-col>
      </ion-row>

      <ion-row class="custom-row" *ngIf="showAllFields">
        <ion-col class="custom-col" size="6">
          <ion-button fill="clear" class="btn-title btn-txt-black">
            Purchase <span class="sp-select-all">Select All</span> <ion-checkbox #selectAllPurchase class="cb-select-all" (click)="selectAllCheckboxClick()" (ionChange)="enableDisableAll($event,'Purchase')"></ion-checkbox>
          </ion-button> <br>
          <ion-chip color="tertiary">
            <span class="title">Create</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="createPurchase" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">Edit</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="editPurchase" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">View</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="viewPurchase" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">Delete</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="deletePurchase" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

        </ion-col>
        <ion-col class="custom-col" size="6">
          <ion-button fill="clear" class="btn-title btn-txt-black">
            Purchase Return <span class="sp-select-all">Select All</span> <ion-checkbox #selectAllPurchaseReturn class="cb-select-all" (click)="selectAllCheckboxClick()" (ionChange)="enableDisableAll($event,'PurchaseReturn')"></ion-checkbox>
          </ion-button> <br>
          <ion-chip color="tertiary">
            <span class="title">Create</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="createPurchaseReturn" (ionChange)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <!-- <ion-chip color="tertiary">
            <span class="title">Edit</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="editPurchaseReturn" (ionChange)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip> -->

          <ion-chip color="tertiary">
            <span class="title">View</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="viewPurchaseReturn" (ionChange)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">Delete</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="deletePurchaseReturn" (ionChange)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

        </ion-col>
      </ion-row>

      <ion-row class="custom-row" *ngIf="showAllFields">
        <ion-col class="custom-col" size="6">
          <ion-button fill="clear" class="btn-title btn-txt-black">
            Estimate <span class="sp-select-all">Select All</span> <ion-checkbox #selectAllEstimate class="cb-select-all" (click)="selectAllCheckboxClick()" (ionChange)="enableDisableAll($event,'Estimate')"></ion-checkbox>
          </ion-button> <br>
          <ion-chip color="tertiary">
            <span class="title">Create</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="createEstimate" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">Edit</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="editEstimate" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">View</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="viewEstimate" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">Delete</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="deleteEstimate" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>
        </ion-col>
        <ion-col class="custom-col" size="6">
          <ion-button fill="clear" class="btn-title btn-txt-black">
            MISC <span class="sp-select-all">Select All</span> <ion-checkbox #selectAllMisc class="cb-select-all" (click)="selectAllCheckboxClick()" (ionChange)="enableDisableAll($event,'MISC')"></ion-checkbox>
          </ion-button> <br>
          <ion-chip color="tertiary">
            <span class="title">Adjust Stock</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="adjustStock" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">View KOT</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="viewKot" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>
        </ion-col>
      </ion-row>

      <!-- Raw Material Starts -->

      <ion-row class="custom-row" *ngIf="showAllFields">
        <ion-col class="custom-col" size="3">
          <ion-button fill="clear" class="btn-title btn-txt-black">
            Raw Material Purchase Entry
          </ion-button> <br>
          <ion-chip color="tertiary">
            <span class="title">Create</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="createRawMaterialPurchaseEntry"></ion-toggle>
            </span>
          </ion-chip>
        </ion-col>
        <ion-col class="custom-col" size="3">
          <ion-button fill="clear" class="btn-title btn-txt-black">
            Raw Material Scrap Entry
          </ion-button> <br>
          <ion-chip color="tertiary">
            <span class="title">Create</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="createRawMaterialScrapEntry"></ion-toggle>
            </span>
          </ion-chip>
        </ion-col>
        <ion-col class="custom-col" size="3">
          <ion-button fill="clear" class="btn-title btn-txt-black">
            Raw Material Transfer Entry
          </ion-button> <br>
          <ion-chip color="tertiary">
            <span class="title">Create</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="createRawMaterialTransferEntry"></ion-toggle>
            </span>
          </ion-chip>
        </ion-col>
        <ion-col class="custom-col" size="3">
          <ion-button fill="clear" class="btn-title btn-txt-black">
            Raw Material Return Entry
          </ion-button> <br>
          <ion-chip color="tertiary">
            <span class="title">Create</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="createRawMaterialReturnEntry"></ion-toggle>
            </span>
          </ion-chip>
        </ion-col>
      </ion-row>

      <ion-row class="custom-row" *ngIf="showAllFields">
        <ion-col class="custom-col" size="3">
          <ion-button fill="clear" class="btn-title btn-txt-black">
            Raw Material EOD Entry
          </ion-button> <br>
          <ion-chip color="tertiary">
            <span class="title">Create</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="createRawMaterialEODEntry"></ion-toggle>
            </span>
          </ion-chip>
        </ion-col>
        <ion-col class="custom-col" size="6">
          <ion-button fill="clear" class="btn-title btn-txt-black">
            Add Raw Material <span class="sp-select-all">Select All</span> <ion-checkbox #selectAllAddRawMaterial class="cb-select-all" (click)="selectAllCheckboxClick()" (ionChange)="enableDisableAll($event,'AddRawMaterial')"></ion-checkbox>
          </ion-button> <br>
          <ion-chip color="tertiary">
            <span class="title">Create</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="createRawMaterial" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">Edit</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="editRawMaterial" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">Delete</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="deleteRawMaterial" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>
        </ion-col>
        <ion-col class="custom-col" size="3">
          <ion-button fill="clear" class="btn-title btn-txt-black">
            Raw Material Purchase Bulk Edit
          </ion-button> <br>
          <ion-chip color="tertiary">
            <span class="title">Create</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="rawMaterialPurchaseBulkEdit"></ion-toggle>
            </span>
          </ion-chip>
        </ion-col>
      </ion-row>

      <!-- Raw Material Ends -->

      <ion-row class="custom-row" *ngIf="showAllFields">
        <ion-col class="custom-col" size="12">
          <ion-button fill="clear" class="btn-title btn-txt-black">
            Dashboard <span class="sp-select-all">Select All</span> <ion-checkbox #selectAllDashboard class="cb-select-all" (click)="selectAllCheckboxClick()" (ionChange)="enableDisableAll($event,'Dashboard')"></ion-checkbox>
          </ion-button> <br>
          <ion-chip color="tertiary">
            <span class="title">View Sales Total</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="viewDashboardSalesTotal" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">View Money Ins Total</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="viewDashboardMoneyInsTotal" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">View Purchases Total</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="viewDashboardPurchasesTotal" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">View Money Outs Total</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="viewDashboardMoneyOutsTotal" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">Party Payable Total</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="viewDashboardPayableTotal" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">Party Receivable Total</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="viewDashboardReceivableTotal" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">Item Low Stock Count</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="viewDashboardLowStockCount" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">Today's Customer</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="viewTodaysCustomer" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">Today's Loyal Customer</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="viewTodaysLoyalCustomer" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">Today's New Customer</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="viewTodaysNewCustomer" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>
        </ion-col>
      </ion-row>

      <!-- Report Start -->
      <ion-row class="custom-row" *ngIf="showAllFields">
        <ion-col class="custom-col" size="12">
          <ion-button fill="clear" class="btn-title btn-txt-black">
            Report <span class="sp-select-all">Select All</span> <ion-checkbox #selectAllReport class="cb-select-all" (click)="selectAllCheckboxClick()" (ionChange)="enableDisableAll($event,'Report')"></ion-checkbox>
          </ion-button> <br>
          <ion-chip color="tertiary">
            <span class="title">View Sales Report</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="viewSaleReport" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">View Sale Wise Profie & Loss Report</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="viewSaleWiseProfitAndLossReport" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">View Purchases Report</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="viewPurchaseReport" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">View Money In Report</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="viewMoneyInReport" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">View Money Out Report</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="viewMoneyOutReport" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">View Expense Report</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="viewExpenseReport" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">View Party Ledger Report</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="viewPartyLedgerReport" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">View Party Detail Report</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="viewPartyDetailReport" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">View Party Receivable/Payable Report</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="viewPartyReceivablePayableReport" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">View Stock Summary Report</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="viewStockSummaryReport" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">View Item Sale Report</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="viewItemSaleReport" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">View Item Report</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="viewItemReport" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">View Item Detail Report</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="viewItemDetailReport" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">Daybook Report</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="viewDayBookReport" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>
          
          <ion-chip color="tertiary">
            <span class="title">All Profile Day Book Report </span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="viewAllProfilesDayBookReport" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">Cut Off Day Report </span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="viewCutOffDayReport" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">Raw Material Cost Report</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="viewRawMaterialCostReport" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">Raw Material Consumption Report</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="viewRawMaterialConsumptionReport" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">Raw Material Purchase Report</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="viewRawMaterialPurchaseReport" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>
        </ion-col>
      </ion-row>
      <!-- Report End -->

      <!-- Setting Start -->
      <ion-row class="custom-row" *ngIf="showAllFields">
        <ion-col class="custom-col" size="12">
          <ion-button fill="clear" class="btn-title btn-txt-black">
            Setting <span class="sp-select-all">Select All</span> <ion-checkbox #selectAllSetting class="cb-select-all" (click)="selectAllCheckboxClick()" (ionChange)="enableDisableAll($event,'Setting')"></ion-checkbox>
          </ion-button> <br>

          <ion-chip color="tertiary">
            <span class="title">Pin Access</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="viewPinAccess" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">Set Login PIN</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="viewSetLoginPin" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">Reset Login PIN</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="viewResetLoginPin" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">Activate Online Shop</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="viewActiveOnlineShop" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">Qr for Online Shop</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="viewQrForOnlineShop" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">Send Bill To Party on Whatsapp</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="viewSendBillToParty" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>

          <ion-chip color="tertiary">
            <span class="title">Send Bill To Owner on Whatsapp</span>
            <span>
              <ion-toggle color="tertiary" slot="end" formControlName="viewSendBillToOwner" (click)="checkSelectAll()"></ion-toggle>
            </span>
          </ion-chip>


        </ion-col>
      </ion-row>
      <!-- Setting End -->
      <ion-row>
        <ion-col size="12">
          <ion-button
            class="btn-save"
            expand="full"
            [disabled]="this?.form?.invalid"
            (click)="onSaveClick()"
          >
            Save Staff
          </ion-button>
        </ion-col>
      </ion-row>

    </form>


  </ion-grid>

</ion-content>
