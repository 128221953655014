<ion-header>
  <ion-toolbar class="{{ getHeaderColorClass() }}">
    <app-navigation-control slot="start" type="item"></app-navigation-control>
    <ion-title class="header-title">Item List <ion-chip> Total Count: {{ itemListEle?.filteredList?.length }}</ion-chip>
    </ion-title>
    <ion-text class="last-sync-time" color="medium">Last Sync at {{ lastSyncTime }}</ion-text>
    <ion-text *ngIf="isTimeDifference" class="last-sync-time timezone-warning alert-border padding-alert-boder" color="warning"> <i>Time Zone
        Mismatched</i> </ion-text>
    <ion-chip class="ion-chip" color="tertiary" slot="end" (click)="onPrintBarcodeClick()"> Print Barcode
    </ion-chip>
    <ion-chip class="ion-chip" color="tertiary" slot="end" (click)="bulkUpload()"> Bulk Upload </ion-chip>
    <ion-chip class="ion-chip" color="danger" slot="end" (click)="bulkDelete()"> Bulk Delete </ion-chip>
    <ion-chip class="ion-chip" color="tertiary" slot="end" (click)="bulkEdit()">Bulk Edit</ion-chip>
    <ion-chip class="ion-chip" color="tertiary" slot="end" (click)="onNewItemClick()"> + New Item </ion-chip>
    <ion-chip color="primary" slot="end" class="chip-option" (click)="toggleOption()" (clickOutSide)="clickOutSide()">
      <ion-icon name="ellipsis-vertical"></ion-icon>
    </ion-chip>
  </ion-toolbar>
  <ion-list *ngIf="isOptionOpen" class="show-chip-option">
    <ion-item (click)="onPrintBarcodeClick()">
      Print Barcode
    </ion-item>
    <ion-item (click)="bulkUpload()">
      Bulk Upload
    </ion-item>
    <ion-item (click)="bulkEdit()">
      Bulk Edit
    </ion-item>
    <ion-item (click)="bulkDelete()">
      Bulk Delete
    </ion-item>
  </ion-list>
</ion-header>

<ion-content>
  <ion-button fill="clear" class="btn-add chip-option" (click)="onNewItemClick()">
    + New Item
  </ion-button>
  <app-item-list #itemListEle [isSalePurchasePrice]="true" (itemSelectedEvent)="onItemSelectedEvent($event)">
  </app-item-list>
</ion-content>

<app-pin-verification (verifyTransactionPin)="verifyTransactionPIN($event)" #pinVerificationElement></app-pin-verification>