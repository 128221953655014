import { NgxIndexedDBService } from "ngx-indexed-db";
import { IDataRepo } from "../../../interface/IDataRepo.interface";
import { IPurchase, Purchase } from "../../models/Purchase.model";
import { MoneyOut } from "../../models/MoneyOut.model";
import { Utility } from "src/app/utils/utility";
import { Router } from "@angular/router";
import { SentryUtilites } from "src/app/utils/sentryUtilites";

export class PurchaseDao implements IDataRepo<Purchase>{
  private static _instance: PurchaseDao;

  public static getInstance(
    ngxIndexedDBService: NgxIndexedDBService,
    router: Router,
  ) {
    if (!this._instance) {
      this._instance = new PurchaseDao(ngxIndexedDBService, router)
    }
    return this._instance;
  }

  constructor(
    ngxIndexedDBService: NgxIndexedDBService,
    router: Router,
    ) {
    this.ngxIndexedDBService = ngxIndexedDBService;
    this.router = router;
  }

  ngxIndexedDBService: NgxIndexedDBService;
  router: Router;

  save(data: Purchase): Promise<Purchase> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && Utility.isTruthy(data)) {
          let timeStamp = +new Date();
          data.createdStamp = timeStamp
          data.updatedStamp = timeStamp
          data.userUpdatedStamp = timeStamp
          data.deletedStamp = 0
          data.syncStamp = 0
  
  
          this.ngxIndexedDBService
            .add(IPurchase.SCHEMA_NAME, data)
            .subscribe((savedPurchase: Purchase) => {
              return resolve(savedPurchase);
            },
            err => {
              console.error(err);
              if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                this.router.navigate(['idbx-error']);
              } else if (typeof err?.target?.error == 'object') {
                SentryUtilites.setLog("PurchaseDao:save", err?.target?.error)
              } else {
                SentryUtilites.setLog("PurchaseDao:save", err)
              }
            });
        } else {
          return resolve(null);
        }
      } catch (err) {
        SentryUtilites.setLog("PurchaseDao:save", err)
        return resolve(null);
      }
    });
  }

  saveDb(data: Purchase): Promise<Purchase> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && Utility.isTruthy(data)) {
          this.ngxIndexedDBService
            .add(IPurchase.SCHEMA_NAME, data)
            .subscribe((savedRecord: Purchase) => {
              return resolve(savedRecord);
            },
            err => {
              console.error(err);
              if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                  this.router.navigate(['idbx-error']);
                } else if (typeof err?.target?.error == 'object') {
                  SentryUtilites.setLog("PurchaseDao:saveDb", err?.target?.error)
                } else {
                  SentryUtilites.setLog("PurchaseDao:saveDb", err)
                }
            });
        } else {
          return resolve(null);
        }
      } catch (error) {
        SentryUtilites.setLog("PurchaseDao:saveDb", error)
        return resolve(null);
      }
    })
  }

  update(data: Purchase): Promise<Purchase> {
    return new Promise(async (resolve, reject) => {
      try {
        if (Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?._localUUID) {
          let oldData = await this.getByUUID(data?._localUUID);
          if(oldData?._localUUID) {
            data._localId = oldData?._localId;
            let timeStamp = +new Date();
            data.updatedStamp = timeStamp
            data.userUpdatedStamp = timeStamp
            this.ngxIndexedDBService
              .update(IPurchase.SCHEMA_NAME, data)
              .subscribe((updatedPurchase: Purchase) => {
                return resolve(updatedPurchase);
              },
              err => {
                console.error(err);
                if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                  this.router.navigate(['idbx-error']);
                } else if (typeof err?.target?.error == 'object') {
                  SentryUtilites.setLog("PurchaseDao:update", err?.target?.error)
                } else {
                  SentryUtilites.setLog("PurchaseDao:update", err)
                }
              });
          } else {
            return resolve(null);
          }
        }else {
          return resolve(null);
        }
      } catch (err) {
        SentryUtilites.setLog("PurchaseDao:update", err)
        return resolve(null);
      }
    })
  }

  updateDb(data: Purchase): Promise<Purchase> {
    return new Promise(async (resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?._localUUID) {
          let oldData = await this.getByUUID(data?._localUUID);
          if(oldData?._localUUID) {
            data._localId = oldData?._localId;
            this.ngxIndexedDBService
              .update(IPurchase.SCHEMA_NAME, data)
              .subscribe((updatedRecord: Purchase) => {
                return resolve(updatedRecord);
              },
              err => {
                console.error(err);
                if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                  this.router.navigate(['idbx-error']);
                } else if (typeof err?.target?.error == 'object') {
                  SentryUtilites.setLog("PurchaseDao:updateDb", err?.target?.error)
                } else {
                  SentryUtilites.setLog("PurchaseDao:updateDb", err)
                }
              });
          } else {
            return resolve(null);
          }
        } else {
          return resolve(null);
        }
      } catch (err) {
        SentryUtilites.setLog("PurchaseDao:updateDb", err)
        return resolve(null);
      }
    })
  }

  bulkPut(data: Purchase[]): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?.length) {
          this.ngxIndexedDBService
            .bulkPut(IPurchase.SCHEMA_NAME, data)
            .subscribe((data) => {
              return resolve(true);
            },
            err => {
              console.error(err);
              if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                  this.router.navigate(['idbx-error']);
                } else if (typeof err?.target?.error == 'object') {
                  SentryUtilites.setLog("PurchaseDao:bulkPut", err?.target?.error)
                } else {
                  SentryUtilites.setLog("PurchaseDao:bulkPut", err)
                }
            })
        } else {
          return resolve(false);
        }
      } catch (error) {
        SentryUtilites.setLog("PurchaseDao:bulkPut", error)
        return resolve(false);
      }
    })
  }

  delete(data: Purchase): Promise<Purchase> {
    return new Promise(async (resolve, reject) => {
      try {
        if (this,Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?._localUUID) {
          let oldData = await this.getByUUID(data?._localUUID);
          if(oldData?._localUUID) {
            data._localId = oldData?._localId;
            let timeStamp = +new Date();
            data.updatedStamp = timeStamp;
            data.userUpdatedStamp = timeStamp;
            data.deletedStamp = timeStamp;
            this.ngxIndexedDBService
              .update(IPurchase.SCHEMA_NAME, data)
              .subscribe((updatedPurchase: Purchase) => {
                return resolve(updatedPurchase);
              },
              err => {
                console.error(err);
                if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                  this.router.navigate(['idbx-error']);
                } else if (typeof err?.target?.error == 'object') {
                  SentryUtilites.setLog("PurchaseDao:delete", err?.target?.error)
                } else {
                  SentryUtilites.setLog("PurchaseDao:delete", err)
                }
              });
          } else {
            return resolve(null);
          }
        } else {
          return resolve(null);
        }
      } catch (err) {
        SentryUtilites.setLog("PurchaseDao:delete", err)
        return resolve(null);
      }
    });
  }
  getById(id: number): Promise<Purchase> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && id) {
          this.ngxIndexedDBService
            .getByKey(IPurchase.SCHEMA_NAME, id)
            .subscribe((profile: Purchase) => {
              return resolve(profile);
            },
            err => {
              console.error(err);
              if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                this.router.navigate(['idbx-error']);
              } else if (typeof err?.target?.error == 'object') {
                SentryUtilites.setLog("PurchaseDao:getById", err?.target?.error)
              } else {
                SentryUtilites.setLog("PurchaseDao:getById", err)
              }
            });
        } else {
          return resolve(null);
        }
      } catch (err) {
        SentryUtilites.setLog("PurchaseDao:getById", err)
        return resolve(null)
      }
    })
  }
  getByUUID(uuid: string): Promise<Purchase> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && uuid) {
          this.ngxIndexedDBService
            .getByIndex(IPurchase.SCHEMA_NAME, '_localUUID', uuid)
            .subscribe((profile: Purchase) => {
              return resolve(profile);
            },
            err => {
              console.error(err);
              if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                this.router.navigate(['idbx-error']);
              } else if (typeof err?.target?.error == 'object') {
                SentryUtilites.setLog("PurchaseDao:getByUUID", err?.target?.error)
              } else {
                SentryUtilites.setLog("PurchaseDao:getByUUID", err)
              }
            });
        } else {
          return resolve(null);
        }
      } catch (err) {
        SentryUtilites.setLog("PurchaseDao:getByUUID", err)
        return resolve(null)
      }
    })
  }
  getAll(): Promise<Purchase[]> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token'))) {
          this.ngxIndexedDBService.getAll(IPurchase.SCHEMA_NAME).subscribe((docs: Purchase[]) => {
            docs = docs?.filter(x => !x?.deletedStamp);
            docs?.sort((a, b) => b?.createdStamp - a?.createdStamp);
            return resolve(docs)
          },
          err => {
            console.error(err);
            if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                this.router.navigate(['idbx-error']);
              } else if (typeof err?.target?.error == 'object') {
                SentryUtilites.setLog("PurchaseDao:getAll", err?.target?.error)
              } else {
                SentryUtilites.setLog("PurchaseDao:getAll", err)
              }
          });
        } else {
          return resolve([]);
        }
      } catch (err) {
        SentryUtilites.setLog("PurchaseDao:getAll", err)
        return resolve([])
      }
    })
  }
  getAllByProfile(profileId: string): Promise<Purchase[]> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && profileId) {
          this.ngxIndexedDBService.getAll(IPurchase.SCHEMA_NAME).subscribe((docs: Purchase[]) => {
            docs = docs?.filter(x => !x?.deletedStamp && x?.profileId == profileId);
            docs?.sort((a, b) => b?.createdStamp - a?.createdStamp);
            return resolve(docs)
          },
          err => {
            console.error(err);
            if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                this.router.navigate(['idbx-error']);
              } else if (typeof err?.target?.error == 'object') {
                SentryUtilites.setLog("PurchaseDao:getAllByProfile", err?.target?.error)
              } else {
                SentryUtilites.setLog("PurchaseDao:getAllByProfile", err)
              }
          });
        } else {
          return resolve([]);
        }
      } catch (err) {
        SentryUtilites.setLog("PurchaseDao:getAllByProfile", err)
        return resolve([])
      }
    })
  }

  getAllWithDeletedByProfile(profileId: string): Promise<Purchase[]> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && profileId) {
          this.ngxIndexedDBService.getAll(IPurchase.SCHEMA_NAME).subscribe((docs: Purchase[]) => {
            docs = docs?.filter(x => x?.profileId == profileId);
            docs?.sort((a, b) => b?.createdStamp - a?.createdStamp);
            return resolve(docs)
          },
          err => {
            console.error(err);
            if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                this.router.navigate(['idbx-error']);
              } else if (typeof err?.target?.error == 'object') {
                SentryUtilites.setLog("PurchaseDao:getAllWithDeletedByProfile", err?.target?.error)
              } else {
                SentryUtilites.setLog("PurchaseDao:getAllWithDeletedByProfile", err)
              }
          });
        } else {
          return resolve([]);
        }
      } catch (err) {
        SentryUtilites.setLog("PurchaseDao:getAllWithDeletedByProfile", err)
        return resolve([])
      }
    })
  }

  /**
   * 
   * @param profileId : provide profile ID
   * @returns : return deleted purchases
   */
  getAllDeletedByProfile(profileId: string): Promise<Purchase[]> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && profileId) {
          this.ngxIndexedDBService.getAll(IPurchase.SCHEMA_NAME).subscribe((docs: Purchase[]) => {
            docs = docs?.filter(x => x?.profileId == profileId && x?.deletedStamp);
            docs?.sort((a, b) => b?.createdStamp - a?.createdStamp);
            return resolve(docs)
          },
          err => {
            console.error(err);
            if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                this.router.navigate(['idbx-error']);
              } else if (typeof err?.target?.error == 'object') {
                SentryUtilites.setLog("PurchaseDao:getAllDeletedByProfile", err?.target?.error)
              } else {
                SentryUtilites.setLog("PurchaseDao:getAllDeletedByProfile", err)
              }
          });
        } else {
          return resolve([])
        }
      } catch (err) {
        SentryUtilites.setLog("PurchaseDao:getAllDeletedByProfile", err)
        return resolve([])
      }
    })
  }
  // -----------------------------------------------------

  getAllUnsynced(profileId: string): Promise<Purchase[]> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token'))) {
          let unSyncedElements: Purchase[] = [];
          this.ngxIndexedDBService.getAll(IPurchase.SCHEMA_NAME).subscribe(async (elArr: Purchase[]) => {
            for (let i = 0; i < elArr?.length; i++) {
              const el = elArr[i];
              if (el?.updatedStamp > el?.syncStamp || !el?._serverIdRef) {
                unSyncedElements.push(el);
              }
            }
            return resolve(unSyncedElements)
          },
          err => {
            console.error(err);
            if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                this.router.navigate(['idbx-error']);
              } else if (typeof err?.target?.error == 'object') {
                SentryUtilites.setLog("PurchaseDao:getAllUnsynced", err?.target?.error)
              } else {
                SentryUtilites.setLog("PurchaseDao:getAllUnsynced", err)
              }
          });
        } else {
          return resolve([])
        }
      } catch (err) {
        SentryUtilites.setLog("PurchaseDao:getAllUnsynced", err)
        return resolve([])
      }
    })
  }
  updateSyncStamp(data: Purchase): Promise<Purchase> {
    return new Promise(async (resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?._localUUID) {
          this.ngxIndexedDBService
          .getByIndex(IPurchase.SCHEMA_NAME, '_localUUID', data?._localUUID)
          .subscribe((dbEl: Purchase) => {
            if(dbEl) {
                dbEl.syncStamp = data?.syncStamp || 0;
                dbEl._serverIdRef = data?._serverIdRef;
                this.ngxIndexedDBService
                  .update(IPurchase.SCHEMA_NAME, dbEl)
                  .subscribe((updatedEl: Purchase) => {
                    return resolve(updatedEl);
                  },
                  err => {
                    console.error(err);
                    if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                      this.router.navigate(['idbx-error']);
                    } else if (typeof err?.target?.error == 'object') {
                      SentryUtilites.setLog("PurchaseDao:updateSyncStamp", err?.target?.error)
                    } else {
                      SentryUtilites.setLog("PurchaseDao:updateSyncStamp", err)
                    }
                  });
              } else {
                return resolve(null);
              }
            },
            err => {
              console.error(err);
              if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                this.router.navigate(['idbx-error']);
              } else if (typeof err?.target?.error == 'object') {
                SentryUtilites.setLog("PurchaseDao:updateSyncStamp", err?.target?.error)
              } else {
                SentryUtilites.setLog("PurchaseDao:updateSyncStamp", err)
              }
            })
        } else {
          return resolve(null);
        }
      } catch (err) {
        SentryUtilites.setLog("PurchaseDao:updateSyncStamp", err)
        return resolve(null)
      }
    })
  }

  async linkMoneyOut(purchaseUUID: string, moneyOut: MoneyOut): Promise<Boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        let purchase = await this.getByUUID(purchaseUUID);

        if(purchase?._localUUID) {
          purchase?.moneyOuts?.push(moneyOut)
          purchase.amountPaid = (purchase?.amountPaid || 0.0) + (moneyOut?.totalAmount || 0.0);
  
          purchase.lastModifiedBy = moneyOut?.lastModifiedBy;
          purchase.lastModifiedByName = moneyOut?.lastModifiedByName;
          await this.update(purchase);
  
          return resolve(true);
        }
        return resolve(false);
      } catch (err) {
        SentryUtilites.setLog("PurchaseDao:linkMoneyOut", err)
        return resolve(false);
      }
    })
  }

  async unLinkMoneyOut(purchaseUUID: string, moneyInUUID: string): Promise<Boolean> {
    return new Promise(async (resolve, reject) => {
      try {

        let purchase = await this.getByUUID(purchaseUUID);

        if(purchase?._localUUID) {
          let newMoneyOutList: MoneyOut[] = [];
          let newPaidAmount: number = 0.0;
  
          purchase?.moneyOuts?.forEach(oldMoneyOut => {
            if(oldMoneyOut?._localUUID !== moneyInUUID) {
              newPaidAmount += oldMoneyOut?.totalAmount || 0.0;
              newMoneyOutList.push(oldMoneyOut);
            }
          });
  
          purchase.moneyOuts = newMoneyOutList;
          purchase.amountPaid = newPaidAmount;
          await this.update(purchase);
  
          return resolve(true);
        }
        return resolve(false);
      } catch (err) {
        SentryUtilites.setLog("PurchaseDao:unLinkMoneyOut", err)
        return resolve(false);
      }
    })
  }

  async upLinkMoneyOut(purchaseUUID: string, moneyOut: MoneyOut): Promise<Boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        let purchase = await this.getByUUID(purchaseUUID);

        if(purchase?._localUUID) {
          let newMoneyOutList: MoneyOut[] = [];
          let newPaidAmount: number = 0.0;
  
          purchase?.moneyOuts?.forEach(oldMoneyOut => {
            if(oldMoneyOut?._localUUID === moneyOut?._localUUID) {
              newPaidAmount += moneyOut?.totalAmount || 0.0;
              newMoneyOutList.push(moneyOut);
            }else {
              newPaidAmount += oldMoneyOut?.totalAmount || 0.0;
              newMoneyOutList.push(oldMoneyOut);
            }
          });
  
          purchase.moneyOuts = newMoneyOutList;
          purchase.amountPaid = newPaidAmount;
  
          purchase.lastModifiedBy = moneyOut?.lastModifiedBy;
          purchase.lastModifiedByName = moneyOut?.lastModifiedByName;
  
          await this.update(purchase);
  
          return resolve(true);
        }
        return resolve(false);
      } catch (err) {
        SentryUtilites.setLog("PurchaseDao:upLinkMoneyOut", err)
        return resolve(false);
      }
    })
  }

  /**
   * 
   * @param purchaseUUID : provide purchase _localUUID
   * @param purchaseReturnUUID : provide purchase return _localUUID
   * @returns : return true if successfully update purchase
   * @description : link purchase return with purchase
   */
  linkPurchaseReturn(purchaseUUID: string, purchaseReturnUUID: string): Promise<Boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        let purchase = await this.getByUUID(purchaseUUID);
        if(purchase?._localUUID) {
          purchase.linkedPurchaseReturnUUID = purchaseReturnUUID;
          await this.update(purchase);
          return resolve(true);
        }
        return resolve(false);
      } catch (error) {
        SentryUtilites.setLog("PurchaseDao:linkPurchaseReturn", error)
        return resolve(false);
      }
    })
  }
  // ---------------------------------------------------

  /**
   * 
   * @param purchaseUUID : provide purchase _localUUID
   * @param purchaseReturnUUID : provide purchase return _localUUID
   * @returns : return true if successfully update purchase
   * @description : unlink purchase return with purchase
   */
  unLinkPurchaseReturn(purchaseUUID: string, purchaseReturnUUID: string): Promise<Boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        let purchase = await this.getByUUID(purchaseUUID);
        if(purchase?._localUUID && !purchase?.deletedStamp) {
          purchase.linkedPurchaseReturnUUID = null;
          await this.update(purchase);
          return resolve(true);
        }
        return resolve(false);
      } catch (error) {
        SentryUtilites.setLog("PurchaseDao:unLinkPurchaseReturn", error)
        return resolve(false);
      }
    })
  }
  // ---------------------------------------------------

}
