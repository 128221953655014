import { ImageBase64Service } from './../../services/image-base64.service';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Profile } from '../../models/Profile.model';
import { Utility } from '../../utils/utility';
import { AllDataService } from '../../services/all-data.service';
import { Validator } from '../../utils/validator';
import { Image } from '../../models/image.model';
import { AlertController, ToastController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ImageBase64 } from '../../models/ImageBase64.model';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-profile-form',
  templateUrl: './profile-form.component.html',
  styleUrls: ['./profile-form.component.scss'],
})
export class ProfileFormComponent implements OnInit {

  @Input('isFocusLegalName') isFocusLegalName = false;
  @Input('isFocusBankAccountDetails') isFocusBankAccountDetails = false;
  @Input('readonly') readonly = false;

  @Output('onUpdate') onUpdate = new EventEmitter<boolean>();

  industryList = Utility.industryList;

  form: FormGroup;
  fetchedProfile: Profile = null;
  states = Utility.states;
  isOpenImageCropper = false;
  isOpenSignatureCropper = false;
  logoBase64: string = null;
  signatureBase64: string = null;
  changeIndustry: boolean = true;

  hideInputs = false;

  isMatchedBankAccount = false;
  isMatchedIFSC = false;

  subscriptions: Subscription[] = [];

  isDisabledState = Utility.isDisabledState;

  constructor(
    private formBuilder: FormBuilder,
    private allDataService: AllDataService,
    private toastController: ToastController,
    private imageBase64Service: ImageBase64Service,
  ) { }

  ngOnInit() {
    this.initializeReactiveForm();
    this.populateForm();
  }

  ngAfterViewInit() {
    this.activateEnterKeyListener();
  }

  initializeReactiveForm() {
    try {
      this.form = this.formBuilder.group({
        ezoIndustry: [null],
        profileName: [null],
        legalName: [null],
        contactPersonPhone: [null, Validators.pattern(Validator.phone)],
        contactPersonName: [null],
        contactPersonEmail: [null, Validators.pattern(Validator.email)],
        addressLine1: [null],
        addressLine2: [null],
        addressCity: [null],
        addressProvience: [null],
        addressCountry: [null],
        addressPostalCode: [null, Validators.pattern(Validator.pincode)],
        gstin: [null, Validators.pattern(Validator.gstin)],
        fssaiNumber: [null],
        licenseNumber: [null],
        bankAccountNo: [null, Validators.pattern(Validator.bankAccountNo)],
        reEnterBankAccountNo: [null],
        bankName: [null],
        bankIFSC: [null, Validators.pattern(Validator.bankIFSC)],
        reEnterBankIFSC: [null],
        bankAccountType: [null],
        bankUPI: [null, Validators.pattern(Validator.upi)],
  
        additionalInputFieldTitle1: [null],
        additionalInputFieldValue1: [null],
        additionalInputFieldTitle2: [null],
        additionalInputFieldValue2: [null],
        additionalInputFieldTitle3: [null],
        additionalInputFieldValue3: [null],
        additionalInputFieldTitle4: [null],
        additionalInputFieldValue4: [null],
        additionalInputFieldTitle5: [null],
        additionalInputFieldValue5: [null],
      });
      this.subscriptions.push(this.form.get("gstin").valueChanges.pipe(distinctUntilChanged(), debounceTime(300)).subscribe(async gstin => {
        if((gstin+'')?.length>=15 && !(gstin+'')?.match(Validator.gstin)) {
          const toast = await this.toastController.create({
            message: 'You have entered wrong format of GSTIN',
            duration: 2000,
            color: 'dark',
          });
          await toast.present();
        }
      }));
      this.subscriptions.push(this.form.get("bankAccountNo").valueChanges.pipe(distinctUntilChanged(), debounceTime(300)).subscribe(bankAccountNo => {
        this.isMatchedBankAccount = (!bankAccountNo || this.fetchedProfile?.bankAccountNo === bankAccountNo);
      }));
      this.subscriptions.push(this.form.get("bankIFSC").valueChanges.pipe(distinctUntilChanged(), debounceTime(300)).subscribe(bankIFSC => {
        this.isMatchedIFSC = (!bankIFSC || this.fetchedProfile?.bankIFSC === bankIFSC);
      }));
    } catch (error) {
      SentryUtilites.setLog("ProfileFormComponent:initializeReactiveForm", error)
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.hideInputs = (this.isFocusLegalName || this.isFocusBankAccountDetails);
  }

  ngOnDestroy() {
    try {
      this.deactivateEnterKeyListener();
      this.subscriptions?.forEach(sub => {sub?.unsubscribe()});
      this.changeIndustry = true;
    } catch (error) {
      SentryUtilites.setLog("ProfileFormComponent:ngOnDestroy", error)
    }
  }

  activateEnterKeyListener() {
    try {
      document.addEventListener('keyup', (keyboardEvent: KeyboardEvent) => {
        if(keyboardEvent?.code === "Enter" && this.form?.valid) {
          this.update();
        }
      });
    } catch (error) {
      SentryUtilites.setLog("ProfileFormComponent:activateEnterKeyListener", error)
    }
  }

  deactivateEnterKeyListener() {
    document.removeEventListener('keyup', () => {});
  }

  openImageCropper(value: boolean = true) {
    this.isOpenImageCropper = value;
  }

  openSignatureCropper(value: boolean = true) {
    this.isOpenSignatureCropper = value;
  }

  async populateForm() {
    try {
      let selectedProfileId = Utility.getFromLocalStorage('selectedProfile');
      if (selectedProfileId) {
        this.fetchedProfile = await this.allDataService.profileService.getByUUID(selectedProfileId);
        if (this.fetchedProfile) {
          this.fetchedProfile.bankAccountNo = this.fetchedProfile?.bankAccountNo || null;
          this.fetchedProfile.bankIFSC = this.fetchedProfile?.bankIFSC || null;
          this.form.patchValue(this.fetchedProfile);
          if(this.fetchedProfile?.bankAccountNo) {
            this.form.patchValue({reEnterBankAccountNo: this.fetchedProfile?.bankAccountNo})
          }
          if(this.fetchedProfile?.bankIFSC) {
            this.form.patchValue({reEnterBankIFSC: this.fetchedProfile?.bankIFSC})
          }
          this.populateLogo();
          this.populateSignature();
        }
      }
    } catch (error) {
      SentryUtilites.setLog("ProfileFormComponent:populateForm", error)
    }
  }

  async onImageSaved(image: Image) {
    try {
      this.fetchedProfile.logoLink = image._localUUID;
  
      let url = `${environment.ezoOfServerImage}image/getbase64/${image?.userId}/${image?.profileId}/${image?._localUUID}`;
      let imageBase64 = new ImageBase64();
      imageBase64.url = url;
      imageBase64.imageBase64 = image?.imageBase64;
      await this.imageBase64Service.update(imageBase64);
  
      this.allDataService.listForceReloadSubs.next('profile-list');
  
      this.populateLogo();
    } catch (error) {
      SentryUtilites.setLog("ProfileFormComponent:onImageSaved", error)
    }
  }

  removeLogo() {
    try {
      if(!this.readonly) {
        this.fetchedProfile.logoLink = this.logoBase64 = '';
      }
    } catch (error) {
      SentryUtilites.setLog("ProfileFormComponent:removeLogo", error)
    }
  }

  async populateLogo() {
    try {
      if(this.fetchedProfile?.logoLink) {
        const image = await this.allDataService.imageService.getByUUID(this.fetchedProfile?.logoLink);
        let base64 = await this.imageBase64Service.getBase64FromDBorServerWithoutSave(image);
        if(base64 && image?._localUUID) {
          this.logoBase64 = base64;
        }
      }
    } catch (error) {
      SentryUtilites.setLog("ProfileFormComponent:populateLogo", error)
    }
  }

  removeSignature() {
    try {
      if(!this.readonly) {
        this.fetchedProfile.signatureLink = this.signatureBase64 = '';
      }
    } catch (error) {
      SentryUtilites.setLog("ProfileFormComponent:removeSignature", error)
    }
  }

  async populateSignature() {
    try {
      if(this.fetchedProfile?.signatureLink) {
        const image = await this.allDataService.imageService.getByUUID(this.fetchedProfile?.signatureLink);
        let base64 = await this.imageBase64Service.getBase64FromDBorServerWithoutSave(image);
        if(base64 && image?._localUUID) {
          this.signatureBase64 = base64;
        }
      }
    } catch (error) {
      SentryUtilites.setLog("ProfileFormComponent:populateSignature", error)
    }
  }

  async onSignatureSaved(image: Image) {
    try {
      this.fetchedProfile.signatureLink = image._localUUID;
  
      let url = `${environment.ezoOfServerImage}image/getbase64/${image?.userId}/${image?.profileId}/${image?._localUUID}`;
      let imageBase64 = new ImageBase64();
      imageBase64.url = url;
      imageBase64.imageBase64 = image.imageBase64;
      await this.imageBase64Service.update(imageBase64);
  
      this.populateSignature();
    } catch (error) {
      SentryUtilites.setLog("ProfileFormComponent:onSignatureSaved", error)
    }
  }

  matchBankAccount(event) {
    this.isMatchedBankAccount = (this.form?.value?.bankAccountNo === event?.target?.value);
  }

  matchIFSC(event) {
    this.isMatchedIFSC = (this.form?.value?.bankIFSC === event?.target?.value);
  }

  async update() {
    try {
      if(this.readonly || this.checkCustomFeildValidation()) {
        return false;
      }
      let form = {...this.form.value};
      this.fetchedProfile = { ...this.fetchedProfile, ...form };
      const updatedProfile = await this.allDataService.profileService.update(this.fetchedProfile);
      if (updatedProfile?._localUUID) {
        this.allDataService.listForceReloadSubs.next('profile-list');
        this.onUpdate.emit(true);
      }
    } catch (error) {
      SentryUtilites.setLog("ProfileFormComponent:update", error)
      return false;
    }
  }

  getValueByFormControlName(control: string,postfix: any =''): any {
    try {
      control = control + postfix;
      return this.form?.value[control] || null;
    } catch (error) {
      SentryUtilites.setLog("ProfileFormComponent:getValueByFormControlName", error)
    }
  }

  returnZero = () => 0;

  /**
   * 
   * @returns : return boolean value if custom feild is valid with title and value
   */
  checkCustomFeildValidation() {
    if(!(this.form.value?.['additionalInputFieldValue1']?.length && !this.form.value?.['additionalInputFieldTitle1']?.length ||
    this.form.value?.['additionalInputFieldValue2']?.length && !this.form.value?.['additionalInputFieldTitle2']?.length ||
    this.form.value?.['additionalInputFieldValue3']?.length && !this.form.value?.['additionalInputFieldTitle3']?.length ||
    this.form.value?.['additionalInputFieldValue4']?.length && !this.form.value?.['additionalInputFieldTitle4']?.length ||
    this.form.value?.['additionalInputFieldValue5']?.length && !this.form.value?.['additionalInputFieldTitle5']?.length ||
    this.form.value?.['additionalInputFieldTitle1']?.length && !this.form.value?.['additionalInputFieldValue1']?.length ||
    this.form.value?.['additionalInputFieldTitle2']?.length && !this.form.value?.['additionalInputFieldValue2']?.length ||
    this.form.value?.['additionalInputFieldTitle3']?.length && !this.form.value?.['additionalInputFieldValue3']?.length ||
    this.form.value?.['additionalInputFieldTitle4']?.length && !this.form.value?.['additionalInputFieldValue4']?.length ||
    this.form.value?.['additionalInputFieldTitle5']?.length && !this.form.value?.['additionalInputFieldValue5']?.length)) {
      return false;
    } else {
      return true;
    }
  }
  // ----------------------------------------

}
