import { Injectable } from "@angular/core";
import { Utility } from "../utils/utility";
import { MonthWiseItemStock } from "../models/MonthWiseItemStock.model";
import { MonthWiseItemStockDao } from "./dao/month-wise-item-stock.dao";
import { Utils } from "../utils/utils";
import { ItemStockAdjust } from "../models/ItemStockAdjust.model";
import { SentryUtilites } from "../utils/sentryUtilites";

@Injectable({
    providedIn: 'root',
})
export class MonthWiseItemStockService {

    constructor(
        private dao: MonthWiseItemStockDao,
    ) { }

    /**
     * 
     * @returns : return all monthWiseItemStock array of object from monthWiseItemStock dao
     */
    getAll(): Promise<MonthWiseItemStock[]> {
        return this.dao.getAll();
    }
    // -----------------------------------------------------------------------------------------

    /**
     * 
     * @param itemLocalUUID : provide item _localUUID
     * @returns : return monthWiseItemStock object from monthWiseItemStock dao
     */
    getByItemLocalUUID(itemLocalUUID: string): Promise<MonthWiseItemStock> {
        return this.dao.getByItemLocalUUID(itemLocalUUID);
    }
    // ---------------------------------------------------------------------------------------------

    /**
     * 
     * @param itemLocalUUID : provide item _localUUID
     * @returns : return saved monthWiseItemStock object from monthWiseItemStock dao
     */
    save(itemLocalUUID: string): Promise<MonthWiseItemStock> {
        return new Promise(async (resolve, reject) => {
            try {
                let savedMonthWiseItemStock = await this.dao.save(itemLocalUUID);
                return resolve(savedMonthWiseItemStock);
            } catch (err) {
                SentryUtilites.setLog("MonthWiseItemStockService:save", err)
                return resolve(null)
            }

        });
    }
    // ------------------------------------------------------------------------------------------------------

    /**
     * 
     * @param monthWiseItemStocks : provide monthWiseItemStock array of object
     * @returns : return true if successfully execute code from monthWiseItemStock dao
     */
    bulkAdd(monthWiseItemStocks: MonthWiseItemStock[]): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            try {
                let isSuccess = await this.dao.bulkAdd(monthWiseItemStocks);
                return resolve(isSuccess);
            } catch (err) {
                SentryUtilites.setLog("MonthWiseItemStockService:bulkAdd", err)
                return resolve(false);
            }
        })
    }
    // -------------------------------------------------------------------------------------

    /**
     * 
     * @param monthWiseItemStock : provide monthWiseItemStock object
     * @returns : return updated monthWiseItemStock object from monthWiseItemStock dao
     */
    update(monthWiseItemStock: MonthWiseItemStock): Promise<MonthWiseItemStock> {
        return new Promise(async (resolve, reject) => {
            try {
                let updatedMonthWiseItemStock = await this.dao.update(monthWiseItemStock);
                return resolve(updatedMonthWiseItemStock)
            } catch (err) {
                SentryUtilites.setLog("MonthWiseItemStockService:update", err)
                return resolve(null)
            }
        });
    }
    // --------------------------------------------------------------------------------------------------------

    /**
     * 
     * @param monthWiseItemStocks : provide monthWiseItemStock array of object
     * @returns : return true if successfully execute code from monthWiseItemStock dao
     */
    bulkPut(monthWiseItemStocks: MonthWiseItemStock[]): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            try {
                let isSuccess = await this.dao.bulkPut(monthWiseItemStocks);
                return resolve(isSuccess);
            } catch (err) {
                SentryUtilites.setLog("MonthWiseItemStockService:bulkPut", err)
                return resolve(false);
            }
        })
    }
    // -------------------------------------------------------------------------------------

    /**
     * 
     * @param itemLocalUUID : provide party _localUUID
     * @returns : return stock value from monthWiseItemStock ledger
     */
    calculateStock(itemLocalUUID: string): Promise<number> {
        return new Promise(async (resolve, reject) => {
            try {
                let monthWiseItemStock = await this.getByItemLocalUUID(itemLocalUUID);
                let itemLedger = monthWiseItemStock?.ledger;
                let stock = 0;
                if (itemLedger) {
                    for (let prop in itemLedger) {
                        stock += itemLedger[prop];
                    }
                }
                return resolve(stock);
            } catch (err) {
                SentryUtilites.setLog("MonthWiseItemStockService:calculateStock", err)
                return resolve(null);
            }
        })
    }
    // ---------------------------------------------------------------------------------------------------------

    /**
     * 
     * @param itemLocalUUID : provide item _localUUID
     * @returns : return monthWiseItemStock object 
     */
    getLedgerStock(itemLocalUUID: string): Promise<number> {
        return new Promise(async (resolve, reject) => {
            try {
                let monthWiseItemStock = await this.getByItemLocalUUID(itemLocalUUID);
                return resolve(monthWiseItemStock?.ledgerStock || 0);
            } catch (error) {
                SentryUtilites.setLog("MonthWiseItemStockService:getLedgerStock", error)
                return resolve(null);
            }
        })
    }
    // ---------------------------------------------------------------------------------------------------------

    /**
     * 
     * @returns : return ledgerStock HashMap
     */
    getLedgerStockHashMap(): Promise<{[key:string]:number}> {
        return new Promise(async (resolve, reject) => {
            try {
                let monthWiseItemStocks = await this.getAll();
                const ledgerStockHashMap = {};
                monthWiseItemStocks?.forEach(monthWiseItemStock => {
                    ledgerStockHashMap[monthWiseItemStock?.itemLocalUUID] = monthWiseItemStock?.ledgerStock;
                });
                return resolve(ledgerStockHashMap);
            } catch (error) {
                SentryUtilites.setLog("MonthWiseItemStockService:getLedgerStockHashMap", error)
                return resolve(null);
            }
        })
    }
    // --------------------------------------------------------------------------------------------------

    /**
     * 
     * @param itemLocalUUID : provide item localUUID
     * @param billDateStamp : provide billDateStamp
     * @param totalAmount : totalAmount
     * @returns : return true if successfully execute code
     */
    modifyStock(
        itemLocalUUID: string,
        billDateStamp: number,
        quantity: number
    ): Promise<boolean> {
        return new Promise(async (resolve, rejects) => {
            try {
                let isSuccess = this.dao.modifyStock(itemLocalUUID, billDateStamp, quantity);
                return resolve(isSuccess);
            } catch (err) {
                SentryUtilites.setLog("MonthWiseItemStockService:modifyStock", err)
                return resolve(false);
            }
        })
    }
    // --------------------------------------------------------------------
    
}
