import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Utility } from '../utils/utility';
import { SentryUtilites } from '../utils/sentryUtilites';

@Injectable({
  providedIn: 'root'
})
export class GoogleMybusinessService {

  constructor(
    private httpClient: HttpClient,
  ) { }

  /**
   * 
   * @returns : return Location Metrics using userId and profileId
   */
  getLocationMetrics() {
    return new Promise((resolve, reject) => {
      try {
        let userId =  Utility.getFromLocalStorage('selectedProfileUserId');
        let profileId =  Utility.getFromLocalStorage('selectedProfile');
        this.httpClient.post(`${environment.ezoOfServerGmb}getLocationMetrics`, {
          userId,
          profileId,
        }).subscribe(res => {
          return resolve(res);
        }, 
        err => {
          return resolve(err);
        })
      } catch (error) {
        SentryUtilites.setLog("GoogleMybusinessService:getLocationMetrics", error)
        return resolve(null);
      }
    })
  }
  // ----------------------------------------------

  /**
   * 
   * @returns : return Locations using userId and profileId
   */
  getLocationsV2() {
    return new Promise((resolve, reject) => {
      try {
        let userId =  Utility.getFromLocalStorage('selectedProfileUserId');
        let profileId =  Utility.getFromLocalStorage('selectedProfile');
        this.httpClient.post(`${environment.ezoOfServerGmb}getLocationsV2`, {
          userId,
          profileId,
        }).subscribe(res => {
          return resolve(res);
        }, 
        err => {
          return resolve(err);
        })
      } catch (error) {
        SentryUtilites.setLog("GoogleMybusinessService:getLocationsV2", error)
        return resolve(null);
      }
    })
  }
  // ----------------------------------------------

  /**
   * 
   * @param locationName : provide locationName
   * @returns : return set Location using userId and profileId status
   * @description : set location as per provided locationName
   */
  setLocation(locationName: string) {
    return new Promise((resolve, reject) => {
      try {
        let userId =  Utility.getFromLocalStorage('selectedProfileUserId');
        let profileId =  Utility.getFromLocalStorage('selectedProfile');
        this.httpClient.post(`${environment.ezoOfServerGmb}setLocation`, {
          userId,
          profileId,
          locationName,
        }).subscribe(res => {
          return resolve(res);
        }, 
        err => {
          return resolve(err);
        })
      } catch (error) {
        SentryUtilites.setLog("GoogleMybusinessService:setLocation", error)
        return resolve(null);
      }
    })
  }
  // ----------------------------------------------

}
