import { MonthWisePartyCreditService } from './../../services/month-wise-party-credit.service';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { AllDataService } from './../../services/all-data.service';
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import Party from '../../models/Party.model';
import { Utility } from '../../utils/utility';
import { InfiniteScrollCustomEvent, IonFab } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { IListView } from '../../../interface/IListView.interface';
import { Sale } from 'src/app/models/Sale.model';
import { CommonService } from 'src/app/services/common.service';
import { AccessControlService } from '../../services/auth/access-control.service';
import { AuthService } from '../../services/auth/auth.service';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';
import PartyCategory from '../../models/PartyCategory.model';

@Component({
  selector: 'app-party-list',
  templateUrl: './party-list.component.html',
  styleUrls: ['./party-list.component.scss'],
})
export class PartyListComponent implements OnInit, IListView<Party> {
  @Input() defaultCashParty = false;
  @Input() searchText = '';
  @Input() isModal = false;
  @Input() isSecondaryPartyView: boolean = false;
  @Input() isSale = false;

  @Output() partySelectedEvent = new EventEmitter<Party>();
  @Output() addNewPartyEvent = new EventEmitter<string>();

  @ViewChild('searchBar') searchBar;
  @ViewChild('categoryFab') categoryFab: IonFab;
  @ViewChild('satisfactionFab') satisfactionFab: IonFab;
  @ViewChild('sortByFab') sortByFab: IonFab;

  isMobile: boolean = null;

  filteredList: Party[] = [];
  completeList: Party[] = [];
  viewFilteredList: Party[] = [];

  appliedCategory = '';
  appliedSatisfaction = '';
  selectedFilter: string = 'SortByFavAsc';

  isFilterOptionOpen = false;

  innerWidth: number;

  isMaskPhone: boolean = false;

  categories: string[] = [];

  subsArr: Subscription[] = [];

  categoryWisePartyCount = {};

  loadViewTimeStamp: number = 0;

  fabListHeight: string = '';

  returnZero = () => {
    return 0;
  };

  satisfactionObj = {
    Delighted: '5',
    Satisfied: '4',
    Unsatisfied: '3',
  };

  sortObj = {
    SortByNameAsc: 'A to Z',
    SortByNameDesc: 'Z to A',
    SortByCreditAsc: 'Credit Ascending',
    SortByCreditDesc: 'Credit Descending',
    SortByFavAsc: 'Favorite Party by Ascending',
  };

  MATHABS = Math.abs;
  stringMask = Utility.stringMask;

  runningBillHashMap: {
    [key: string]: Sale
  } = { };

  constructor(
    private allDataService: AllDataService,
    private router: Router,
    private commonService: CommonService,
    private monthWisePartyCreditService: MonthWisePartyCreditService,
    private accessControlService: AccessControlService,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    try {
      this.fabListHeight = `${Math.floor((window?.innerHeight - 105) * 0.85)}px`;
      this.isMobile = this.commonService.isMobile();
      this.routerSubscription();
      this.reduceFunctionCall();
      this.subsArr.push(
        this.allDataService.listForceReloadSubs.subscribe(async (listName: string) => {
          if (listName == 'party-list') {
            this.allDataService.partyService.reloadList();
            this.reduceFunctionCall();
          }
        })
      );
  
      this.subsArr.push(
        this.allDataService.partyService.updateSubs.subscribe(async (party: Party) => {
          if (party) {
            party.credit = await this.monthWisePartyCreditService.getLedgerCredit(party?._localUUID);
            let completeListIndex = this.completeList.findIndex(el => el?._localUUID === party?._localUUID);
            if(completeListIndex != -1 && party?.deletedStamp) {
              this.completeList.splice(completeListIndex, 1);
            } else if(completeListIndex != -1) {
              this.completeList[completeListIndex] = party;
            }
            let filteredListIndex = this.filteredList.findIndex(el => el?._localUUID === party?._localUUID);
            if(filteredListIndex != -1 && party?.deletedStamp) {
              this.filteredList.splice(filteredListIndex, 1);
            } else if(filteredListIndex != -1) {
              this.filteredList[filteredListIndex] = party;
            }
            let viewFilteredListIndex = this.viewFilteredList.findIndex(el => el?._localUUID === party?._localUUID);
            if(viewFilteredListIndex != -1 && party?.deletedStamp) {
              this.viewFilteredList.splice(viewFilteredListIndex, 1);
            } else if(viewFilteredListIndex != -1) {
              this.viewFilteredList[viewFilteredListIndex] = party;
            }
          }
        })
      );
    } catch (error) {
      SentryUtilites.setLog("PartyListComponent:ngOnInit", error)
    }
  }

  ngAfterViewInit() {
    try {
      setTimeout(() => {
        this.searchBar?.setFocus();
        this.selectDefaultCashParty();
      }, 500);
      this.innerWidth = window?.innerWidth;
      this.checkScreenWidth(window?.innerWidth);
    } catch (error) {
      SentryUtilites.setLog("PartyListComponent:ngAfterViewInit", error)
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    try {
      if (changes?.defaultCashParty?.currentValue != changes?.defaultCashParty?.previousValue) {
        setTimeout(() => {
          this.selectDefaultCashParty();
        }, 500);
      }
    } catch (error) {
      SentryUtilites.setLog("PartyListComponent:ngOnChanges", error)
    }
  }

  @HostListener('window:resize', ['$event'])
  OnResize() {
    try {
      this.innerWidth = window?.innerWidth;
      this.checkScreenWidth(window?.innerWidth);
    } catch (error) {
      SentryUtilites.setLog("PartyListComponent:OnResize", error)
    }
  }

  checkScreenWidth(width: number) {
    try {
      if(width <= 1080) {
        this.isFilterOptionOpen = false;
      } else {
        this.isFilterOptionOpen = true;
      }
    } catch (error) {
      SentryUtilites.setLog("PartyListComponent:checkScreenWidth", error)
    }
  }

  routerSubscription() {
    try {
      this.subsArr.push(
        this.router.events.subscribe((event: RouterEvent) => {
          if (event instanceof NavigationEnd) {
            event?.url === '/party' && this.reduceFunctionCall();
          }
        })
      );
    } catch (error) {
      SentryUtilites.setLog("PartyListComponent:routerSubscription", error)
    }
  }

  async selectDefaultCashParty() {
    try {
      if (this.defaultCashParty) {
        await this.reduceFunctionCall();
        let result = this.completeList.filter((x) => x.isCashSaleParty);
        if (result && result?.length && result[0]._localUUID) {
  
          // Temp fix
          result[0].runningSaleBill = this.runningBillHashMap[result[0]?._localUUID]?._localUUID;
          this.emitElement(result[0]);
        }
      }
    } catch (error) {
      SentryUtilites.setLog("PartyListComponent:selectDefaultCashParty", error)
    }
  }

  async getCategories() {
    try {
      let partyCategories =
        await this.allDataService.partyCategoryService.getAllByPromise();
        partyCategories.sort((a: PartyCategory, b: PartyCategory) => {
          if(a?.savedPosition || b?.savedPosition) {
            return a?.savedPosition - b?.savedPosition;
          }
          if (a?.name?.toLowerCase() === b?.name?.toLowerCase()) {
            return a?.createdStamp - b?.createdStamp;
          }
          if (a?.name?.toLowerCase() > b?.name?.toLowerCase()) {
            return 1;
          }
          return -1;
        });
      if (partyCategories?.length) {
        this.categories = partyCategories.map((x) => x.name);
        if(this.completeList?.length) {
          this.categoryWisePartyCount = {};
          this.categories?.forEach(category => {
            if(category) {
              this.categoryWisePartyCount['All'] = this.completeList?.length
              this.categoryWisePartyCount[category] = this.completeList?.filter(party => party?.category ? party?.category?.toLowerCase()==category?.toLowerCase() : false)?.length;
            }
          });
        }
      }
    } catch (error) {
      SentryUtilites.setLog("PartyListComponent:getCategories", error)
    }
  }

  async loadView() {
    try {
      // this.filteredList = [];
      // this.completeList = [];
      // this.viewFilteredList = [];
  
      let phone = Utility.getFromLocalStorage('selectedProfileUserId');
      let isOwner = phone === this.authService.getLoginPhone();
      this.isMaskPhone = !isOwner && await this.accessControlService.isUserHasAccess({action: 'hidePhone'});
  
      // this.appliedCategory = '';
      this.selectedFilter = 'SortByFavAsc';
  
      this.categories = [];
  
      let completeListHashMap = {};
  
      let completeListLength = this.completeList.length;
      for(let i = 0; i < completeListLength; i++) {
        let key = JSON.stringify(this.completeList[i]);
        completeListHashMap[key] = true;
      }
  
      let newCompleteList = await this.allDataService.partyService.getAllByPromise() || [];
  
      const ledgerCreditHashMap = await this.monthWisePartyCreditService.getLedgerCreditHashMap();
  
      let differentObjects = [];
  
      // storing credit and find out differentObjects from old completeList
      let newCompleteListLength = newCompleteList?.length;
      for(let i = 0; i < newCompleteListLength; i++) {
        let newCompleteObj = newCompleteList[i];
        newCompleteObj.credit = ledgerCreditHashMap[newCompleteObj?._localUUID] || 0;
  
        if(this.completeList?.length) {
          let key = JSON.stringify(newCompleteObj);
          if(!completeListHashMap[key]) {
            differentObjects.push(newCompleteObj);
          }
        }
      }
  
      if(
        newCompleteList?.length != this.completeList?.length ||
        JSON.stringify(newCompleteList) != JSON.stringify(this.completeList)
        ) { 
  
          if(this.completeList?.length) {
            let differentObjectsLength = differentObjects?.length;
            if(differentObjectsLength) {
              for(let i=0; i < differentObjectsLength; i++) {
                let differentObject = differentObjects[i];
                let index = this.completeList.findIndex(el => el?._localUUID === differentObject?._localUUID);
                if(index != -1) {
                  this.completeList[index] = differentObject;
                } else {
                  this.completeList.push(differentObject);
                }
              }
            }
          } else {
            this.completeList = newCompleteList;
          }
      
          this.onFilterChange(this.lastSearchKey);
          this.getCategories();
        
          let salesOnHold = await this.allDataService.saleService.getAllByProfileWithOnlyRunningBill();
          if(this.isSale){
              this.completeList?.forEach(party => {
              this.runningBillHashMap[party?._localUUID]
                = salesOnHold.filter(sale => sale?.party?._localUUID===party?._localUUID)
                  .sort((a,b)=>b?.createdStamp-a?.createdStamp)[0] || null;
            });
          }
        }
    } catch (error) {
      SentryUtilites.setLog("PartyListComponent:loadView", error)
    }

  }

  async reduceFunctionCall() {
    try {
      if(+new Date() > this.loadViewTimeStamp) {
        this.loadViewTimeStamp = +new Date() + 5000;
        await this.loadView();
      }
    } catch (error) {
      SentryUtilites.setLog("PartyListComponent:reduceFunctionCall", error)
    }
  }

  toggleFilterOptions() {
    this.isFilterOptionOpen = !this.isFilterOptionOpen;
  }

  closeclickOutSideCategory() {
    this.categoryFab.close();
    let scrollCategoryCintainer = document.getElementById('scroll-category-container');
    if(scrollCategoryCintainer) {
      scrollCategoryCintainer.scrollTop = 0;
    }
  }

  closeclickOutSideSatisfaction() {
    this.satisfactionFab.close();
  }

  closeclickOutSideSort() {
    this.sortByFab.close();
  }

  closeSatisfactionSort() {
    this.satisfactionFab.close();
    this.sortByFab.close();
  }

  closeCategorySort() {
    this.categoryFab.close();
    this.sortByFab.close();
  }

  closeCategorySatisfaction() {
    this.categoryFab.close();
    this.satisfactionFab.close();
  }

  onCategoryChange(category: string) {
    this.appliedCategory = category;
    this.onFilterChange();
  }

  onSatisfactionChange(satisfactionIndex: string) {
    this.appliedSatisfaction = satisfactionIndex;
    this.onFilterChange();
  }

  onSortChnage(selectedFilter: string) {
    this.selectedFilter = selectedFilter;
    this.onFilterChange();
  }

  isFilterPostpond = false;
  lastFilterStamp = 0;
  onFilterChange(searchKey?: string) {
    try {
      if (this.isFilterPostpond) {
        return true;
      }
      if (this.lastFilterStamp < +new Date() - 200) {
        this.lastFilterStamp = +new Date();
        this.applyFilter({
          searchKeyword: searchKey,
          selectedFilter: this.selectedFilter,
          category: this.appliedCategory,
          satisfactionIndex: this.appliedSatisfaction,
        });
      } else {
        this.isFilterPostpond = true;
        setTimeout(() => {
          this.isFilterPostpond = false;
          this.onFilterChange(searchKey);
        }, 250);
      }
    } catch (error) {
      SentryUtilites.setLog("PartyListComponent:onFilterChange", error)
    }
  }

  lastSearchKey = '';
  applyFilter(params: {
    searchKeyword?: string;
    selectedFilter?: string;
    category?: string;
    satisfactionIndex?: string;
  }) {
    try {
      let arr = [];
      let lsearchKey = '';
      let isSearchIncremental = false;
      let isSearchCleared = false;
      if (params?.searchKeyword?.length) {
        lsearchKey = Utility.toLowerCase(params.searchKeyword);
        if (lsearchKey.indexOf(this.lastSearchKey) == 0) {
          isSearchIncremental = true;
        }
        this.lastSearchKey = lsearchKey;
      }
      if (this.lastSearchKey.length > 0 && lsearchKey.length == 0) {
        isSearchCleared = true;
        this.lastSearchKey = '';
      }
      if(!this.filteredList?.length && isSearchIncremental) {
        this.filteredList = [...this.completeList];
      }
      let completeList = [];
      if (isSearchIncremental) {
        completeList = [...this.filteredList];
      } else {
        completeList = [...this.completeList];
      }
  
      if (params?.satisfactionIndex) {
        completeList?.forEach((el) => {
          if (params?.satisfactionIndex == el?.satisfactionIndex) {
            arr.push(el);
          }
        });
      } else if (params?.category && lsearchKey.length) {
        if (params?.category == 'payable') {
          completeList?.forEach((el) => {
            const credit = Number(el?.credit);
            if (Utility.isNumber(credit) && credit < 0) {
              arr.push(el);
            }
          });
        } else if (params?.category == 'receivable') {
          completeList?.forEach((el) => {
            const credit = Number(el.credit);
            if (Utility.isNumber(credit) && credit > 0) {
              arr.push(el);
            }
          });
        } else {
          completeList?.forEach((el) => {
            if (el?.category == params?.category) {
              arr.push(el);
            }
          });
        }
        arr = arr.filter((el: Party) => {
          if (lsearchKey.length < 4) {
            if (Utility.isNumber(lsearchKey)) {
              if (Utility.toLowerCase(el?.phone).indexOf(lsearchKey) != -1) {
                return el;
              }
            } else if (Utility.toLowerCase(el?.name).indexOf(lsearchKey) == 0) {
              return el;
            }
          } else if (
            Utility.toLowerCase(el?.phone).indexOf(lsearchKey) != -1 &&
            Utility.isNumber(lsearchKey)
          ) {
            return el;
          } else {
            if (Utility.toLowerCase(el?.name).indexOf(lsearchKey) != -1) {
              return el;
            }
          }
        });
      } else if (params?.category) {
        if (params?.category == 'payable') {
          completeList?.forEach((el) => {
            const credit = Number(el?.credit);
            if (Utility.isNumber(credit) && credit < 0) {
              arr.push(el);
            }
          });
        } else if (params?.category == 'receivable') {
          completeList?.forEach((el) => {
            const credit = Number(el.credit);
            if (Utility.isNumber(credit) && credit > 0) {
              arr.push(el);
            }
          });
        } else {
          completeList?.forEach((el) => {
            if (el?.category == params.category) {
              arr.push(el);
            }
          });
        }
      } else if (lsearchKey.length) {
        completeList?.forEach((el: Party) => {
          if (lsearchKey.length < 4) {
            if (Utility.isNumber(lsearchKey)) {
              if (Utility.toLowerCase(el?.phone).indexOf(lsearchKey) != -1) {
                arr.push(el);
              }
            } else if (Utility.toLowerCase(el?.name).indexOf(lsearchKey) == 0 ||
            Utility.toLowerCase(el?.name).includes(lsearchKey)) {
              arr.push(el);
            }
          } else if (
            Utility.toLowerCase(el?.phone).indexOf(lsearchKey) != -1 &&
            Utility.isNumber(lsearchKey)
          ) {
            arr.push(el);
          } else {
            if (Utility.toLowerCase(el?.name).indexOf(lsearchKey) != -1) {
              arr.push(el);
            }
          }
        });
      } else {
        arr = [...completeList];
      }
  
      if (params.selectedFilter == 'SortByNameAsc') {
        arr.sort((a: Party, b: Party) => {
          if (a?.name?.toLowerCase() == b?.name?.toLowerCase()) {
            return a?.createdStamp - b?.createdStamp;
          }
          if (a?.name?.toLowerCase() > b?.name?.toLowerCase()) {
            return 1;
          }
          return -1;
        });
      } else if (params.selectedFilter == 'SortByNameDesc') {
        arr.sort((a: Party, b: Party) => {
          if (a?.name?.toLowerCase() == b?.name?.toLowerCase()) {
            return a?.createdStamp - b?.createdStamp;
          }
          if (b?.name?.toLowerCase() > a?.name?.toLowerCase()) {
            return 1;
          }
          return -1;
        });
      } else if (params.selectedFilter == 'SortByCreditAsc') {
        arr.sort((a: Party, b: Party) => a?.credit - b?.credit);
      } else if (params.selectedFilter == 'SortByCreditDesc') {
        arr.sort((a: Party, b: Party) => b?.credit - a?.credit);
      } else if (params.selectedFilter == 'SortByFavAsc') {
        arr.sort((a: Party, b: Party) => {
          if (a?.name?.toLowerCase() == b?.name?.toLowerCase()) {
            return a?.createdStamp - b?.createdStamp;
          }
          if (a?.name?.toLowerCase() > b?.name?.toLowerCase()) {
            return 1;
          }
          return -1;
        });
        let favParties: Party[] = [];
        let nonFavParties: Party[] = [];
        arr.filter((party) => {
          if (party?.isFavourite) {
            favParties.push(party);
          } else {
            nonFavParties.push(party);
          }
        });
        arr = favParties.concat(nonFavParties);
      }
  
      this.filteredList = [...arr];
  
      if (lsearchKey) {
        let priorityArr: Party[] = [];
        this.filteredList?.forEach((x, i) => {
          if ((x?.name+'')?.startsWith(lsearchKey) || (x?.phone+'')?.startsWith(lsearchKey)) {
            priorityArr.push(x);
            this.filteredList.splice(i, 1);
          }
        });
        this.filteredList = [...priorityArr.sort(), ...this.filteredList];
      }
  
      this.viewFilteredList = this.viewFilteredList?.length > 50 ? this.filteredList.slice(0, this.viewFilteredList?.length) : this.filteredList.slice(0, 50);
  
      if (
        this.isSecondaryPartyView &&
        Utility.isNumber(lsearchKey) &&
        lsearchKey?.length === 10 &&
        this.viewFilteredList?.length === 1
      ) {
        this.emitElement(this.viewFilteredList[0]);
      }
    } catch (error) {
      SentryUtilites.setLog("PartyListComponent:applyFilter", error)
    }
  }

  loadMoreListData(event: any) {
    try {
      if (
        this.viewFilteredList.length > 0 &&
        this.viewFilteredList.length <= this.filteredList.length
      ) {
        let appendListLength = this.filteredList.length - this.viewFilteredList.length;
        let lastEl = this.viewFilteredList[this.viewFilteredList.length - 1];
        let counter = 0;
        for (let i = 0; i < this.filteredList.length; i++) {
          if (this.filteredList[i]._localUUID == lastEl._localUUID) {
            counter = 1;
            continue;
          }
          if (counter > 0 && appendListLength >= 50) {
            if (counter <= 50) {
              this.viewFilteredList.push({ ...this.filteredList[i] });
            } else {
              break;
            }
            counter++;
          } else if(counter > 0 && appendListLength < 50) {
            if (counter <= appendListLength) {
              this.viewFilteredList.push({ ...this.filteredList[i] })
            } else {
              break;
            }
            counter++;
          }
        }
        (event as InfiniteScrollCustomEvent)?.target?.complete();
      }
    } catch (error) {
      SentryUtilites.setLog("PartyListComponent:loadMoreListData", error)
    }
  }


  emitElementExtended(party: Party, runningSaleBill: string) {
    try {
      this.completeList = [];
      if(party?._localUUID) {
        party.runningSaleBill = runningSaleBill;
        this.partySelectedEvent.emit(party);
      }
    } catch (error) {
      SentryUtilites.setLog("PartyListComponent:emitElementExtended", error)
    }
  }

  emitElement(party: Party) {
    this.partySelectedEvent.emit(party);
  }

  addNewParty() {
    this.addNewPartyEvent.emit(this.lastSearchKey);
  }

  resetCategory() {
    this.appliedCategory = '';
    this.toggleFilterOptions();
    this.onFilterChange();
  }

  resetSort() {
    this.selectedFilter = '';
    this.toggleFilterOptions();
    this.onFilterChange();
  }

  async makeFavourite(party: Party) {
    try {
      let fetchedParty = await this.allDataService?.partyService?.getByUUID(
        party?._localUUID
      );
  
      if (fetchedParty?._localUUID) {
        fetchedParty.isFavourite = !fetchedParty?.isFavourite ? true : false;
        await this.allDataService?.partyService?.update(fetchedParty);
      }
  
      this.loadView();
    } catch (error) {
      SentryUtilites.setLog("PartyListComponent:makeFavourite", error)
    }
  }

  // async removeFavourite(party: Party) {
  //   let fetchedParty = await this.allDataService?.partyService?.getByUUID(
  //     party?._localUUID
  //   );
  //   if (fetchedParty?._localUUID && fetchedParty?.isFavourite) {
  //     fetchedParty.isFavourite = false;
  //     await this.allDataService?.partyService?.update(fetchedParty);
  //   }
  // }

  getSatisfactionIndexImagePath(satisfactionIndex: number) {
    try {
      return {
        3: '../../../assets/images/rage.png',
        4: '../../../assets/images/slightly_smiling_face.png',
        5: '../../../assets/images/smile.png',
      }[satisfactionIndex];
    } catch (error) {
      SentryUtilites.setLog("PartyListComponent:getSatisfactionIndexImagePath", error)
      return '';
    }
  }

  ngOnDestroy() {
    try {
      this.subsArr?.forEach((el) => {
        el.unsubscribe();
      });
    } catch (error) {
      SentryUtilites.setLog("PartyListComponent:ngOnDestroy", error)
    }
  }
}
