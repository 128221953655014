<ion-header [ngClass]="{'mobile': isMobile}">
  <ion-toolbar class="{{ getHeaderColorClass() }} ion-toolbar-item-transaction">
    <app-navigation-control slot="start" type="ingredient/transaction"></app-navigation-control>
    <ion-title class="header-title" [appHeaderTitleCss]="isMobile ? 'mobile' : ''">
      <span>
        Raw Material Transaction
      </span>
      <ion-chip> Count: {{ transactionsList?.length }} </ion-chip>
    </ion-title>
    <ion-chip class="ion-chip" color="tertiary" slot="end" (click)="purchaseBulkEdit()">Purchase Bulk Edit</ion-chip>
  </ion-toolbar>
</ion-header>

<ion-content [ngClass]="{'mobile': isMobile}">
  <ion-grid>
    <ion-row class="item-custom-row row-raw-material-list card-info card-info-transaction">
      <ion-col size="7" class="item-name">
        <ion-button fill="clear" class="btn-item-name">
          {{ shortStr(ingredient?.name,85) }}
        </ion-button>
      </ion-col>
  
      <ion-col size="5">
        <ion-row class="data">
          <ion-col size="4">
            <span class="opening-stock">
              Opening Stock
            </span>
          </ion-col>
          <ion-col size="1">
            :
          </ion-col>
          <ion-col size="4">
            {{ ingredient?.yesterdayEOD?.quantity ? ((ingredient?.yesterdayEOD?.quantity || 0) | number: '1.2-2') : '-' }}
          </ion-col>
          <ion-col size="3">
            {{ ingredient?.yesterdayEOD?.unit || '-' }}
          </ion-col>
          <ion-col size="4">
            <ion-button class="live-stock" fill="outline" shape="round" (click)="getTransactions()">
              Live Stock
            </ion-button>
          </ion-col>
          <ion-col size="1" class="colon">
            <span>
              :
            </span>
          </ion-col>
          <ion-col size="4">
            {{ ingredient?.stock ? ((ingredient?.stock || 0) | number: '1.2-2') : '-' }}
          </ion-col>
          <ion-col size="3">
            {{ ingredient?.stock ? ingredient?.unit || '-' : '-' }}
          </ion-col>
        </ion-row>
      </ion-col>
  
    </ion-row>
  </ion-grid>

  <ion-grid
  class="all-list-include-date-filter-ingredient-transaction"
  [ngStyle]="{height: innerHeight}"
  >
    <ion-content>
      <ion-row class="transaction-holder padding-5px" *ngFor="let transaction of viewFilteredList;">
        <ion-col class="col-custom-row padding-none">
          <ion-row>
            <ion-col size="7" class="padding-none">
              <ion-row>
                <ion-col size="12">
                  <ion-row>
                    <ion-col size="2" class="actions">
                      {{splitByCamelCase(transaction?.actionType) || '-'}}
                    </ion-col>
                    <ion-col size="10" class="label-note">
                      {{transaction?.isSaleUpdate ? 'Edited' : ''}}
                    </ion-col>
                    <ion-col size="12" class="date">
                      {{transaction?.createdStamp | date: 'dd/MM/yy h:mm:ss a'}}
                    </ion-col>
                  </ion-row>
                </ion-col> 
              </ion-row>
            </ion-col>
            <ion-col size="5" class="padding-bottom">
              <ion-row>
                <ion-col size="5">
    
                </ion-col>
                <ion-col size="4" [ngClass]="getTransactionTypeColor(transaction?.actionType)">
                  {{MATHABS(transaction?.quantity)}} 
                </ion-col>
                <ion-col size="3">
                  {{transaction?.unit}}
                </ion-col>
                <ion-col size="12">
                  <ion-label class="label-note flt-rght">
                    {{ (transaction?.userUpdatedStamp || transaction?.updatedStamp) > transaction?.createdStamp ? 'Edited by ' : 'Created by ' }} 
                    {{ (transaction?.userUpdatedStamp || transaction?.updatedStamp) > transaction?.createdStamp ? transaction?.lastModifiedByName || transaction?.lastModifiedBy :  transaction?.createdByName || transaction?.createdBy }}
                    on
                    {{ (transaction?.userUpdatedStamp || transaction?.updatedStamp) | date: 'dd/MM/yy h:mm:ss a' }}
                  </ion-label>
                </ion-col>
              </ion-row>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>
      <ion-infinite-scroll threshold="10px" *ngIf="viewFilteredList?.length" (ionInfinite)="loadMoreListData($event)">
        <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Loading more data...">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ion-grid>
</ion-content>
