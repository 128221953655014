import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Validator } from '../../../utils/validator';
import { IonCheckbox, ToastController } from '@ionic/angular';
import { AccessTo } from '../../../models/AccessTo.model';
import { AllDataService } from '../../../services/all-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Utility } from 'src/app/utils/utility';
import { AuthService } from '../../../services/auth/auth.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-staff-form',
  templateUrl: './staff-form.page.html',
  styleUrls: ['./staff-form.page.scss'],
})
export class StaffFormPage implements OnInit {

  @ViewChild('selectAllProfile') selectAllProfile: IonCheckbox;
  @ViewChild('selectAllExpense') selectAllExpense: IonCheckbox;
  @ViewChild('selectAllParty') selectAllParty: IonCheckbox;
  @ViewChild('selectAllPartyCategory') selectAllPartyCategory: IonCheckbox;
  @ViewChild('selectAllItem') selectAllItem: IonCheckbox;
  @ViewChild('selectAllItemCategory') selectAllItemCategory: IonCheckbox;
  @ViewChild('selectAllMoneyIn') selectAllMoneyIn: IonCheckbox;
  @ViewChild('selectAllMoneyOut') selectAllMoneyOut: IonCheckbox;
  @ViewChild('selectAllSale') selectAllSale: IonCheckbox;
  @ViewChild('selectAllSaleReturn') selectAllSaleReturn: IonCheckbox;
  @ViewChild('selectAllPurchase') selectAllPurchase: IonCheckbox;
  @ViewChild('selectAllPurchaseReturn') selectAllPurchaseReturn: IonCheckbox;
  @ViewChild('selectAllEstimate') selectAllEstimate: IonCheckbox;
  @ViewChild('selectAllMisc') selectAllMisc: IonCheckbox;
  @ViewChild('selectAllAddRawMaterial') selectAllAddRawMaterial: IonCheckbox;
  @ViewChild('selectAllDashboard') selectAllDashboard: IonCheckbox;
  @ViewChild('selectAllReport') selectAllReport: IonCheckbox;
  @ViewChild('selectAllSetting') selectAllSetting: IonCheckbox;

  getHeaderColorClass = Utility.getHeaderColorClass;

  form: FormGroup;
  userId: string = null;

  showAllFields: boolean = false;
  isSelectAllCheckboxClick: boolean = false;
  staffAccessObj: AccessTo = null;
  selectedAccessType: 'SaleAccess' | 'FullAccess' | 'CustomAccess' = 'SaleAccess';

  subArr: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private toastController: ToastController,
    private allDataService: AllDataService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    this.initializeReactiveForm();
    this.populateForm();
  }

  initializeReactiveForm() {
    this.form = this.formBuilder.group({
      userId: [null, [Validators.required,Validators.pattern(Validator.phone)]],
      name: [null],

      // PROFILE
      viewProfile: [false], // Page
      editProfile: [false], // Action

      // Mask Phone
      hidePhone: [true],

      // COLLECTIONS

      //Party
      createParty: [false], // Page + Action
      editParty: [false], // Page + Action
      viewParty: [false], // Page (list + transaction)
      deleteParty: [false], // Action

      // Party Category
      createPartyCategory: [false], // Action
      editPartyCategory: [false], // Action
      viewPartyCategory: [false], // Action
      deletePartyCategory: [false], // Action

      // Item
      createItem: [false], // Page + Action
      editItem: [false], // Page + Action
      viewItem: [false], // Page (list + transaction)
      deleteItem: [false], // Action

      // Item Category
      createItemCategory: [false], // Action
      editItemCategory: [false], // Action
      viewItemCategory: [false], // Action
      deleteItemCategory: [false], // Action

      // Money In
      createMoneyIn: [false], // Page + Action
      editMoneyIn: [false], // Page + Action
      viewMoneyIn: [false], // Page (list)
      deleteMoneyIn: [false], // Action

      // Money Out
      createMoneyOut: [false], // Page + Action
      editMoneyOut: [false], // Page + Action
      viewMoneyOut: [false], // Page (list)
      deleteMoneyOut: [false], // Action

      // Estimate
      createEstimate: [false], // Page + Action
      editEstimate: [false], // Page + Action
      viewEstimate: [false], // Page (list)
      deleteEstimate: [false], // Action

      // Purchase
      createPurchase: [false], // Page + Action
      editPurchase: [false], // Page + Action
      viewPurchase: [false], // Page (list)
      deletePurchase: [false], // Action

      // Purchase Return
      createPurchaseReturn: [false], // Page + Action
      // editPurchaseReturn: [false], // Page + Action
      viewPurchaseReturn: [false], // Page (list)
      deletePurchaseReturn: [false], // Action

      // Expense
      createExpense: [false], // Page + Action
      editExpense: [false], // Page + Action
      viewExpense: [false], // Page (list)
      deleteExpense: [false], // Action

      // Sale
      createSale: [true], // Page + Action
      editSale: [true], // Page + Action
      viewSale: [true], // Page + Action (list + bill view)
      deleteSale: [false], // Action

      // Sale Return
      createSaleReturn: [false], // Page + Action
      // editSaleReturn: [false], // Page + Action
      viewSaleReturn: [false], // Page + Action (list + bill view)
      deleteSaleReturn: [false], // Action

      // MISC
      adjustStock: [false], // Action
      viewKot: [false], // Page

      // Raw Material
      createRawMaterialPurchaseEntry: [false],
      createRawMaterialScrapEntry: [false],
      createRawMaterialTransferEntry: [false],
      createRawMaterialReturnEntry: [false],
      createRawMaterialEODEntry: [false],
      createRawMaterial: [false],
      editRawMaterial: [false],
      deleteRawMaterial: [false],
      rawMaterialPurchaseBulkEdit: [false],

      // DASHBOARD
      viewDashboardSalesTotal: [false], // Action
      viewDashboardMoneyInsTotal: [false], // Action
      viewDashboardPurchasesTotal: [false], // Action
      viewDashboardMoneyOutsTotal: [false], // Action
      viewDashboardPayableTotal: [false], // Action
      viewDashboardReceivableTotal: [false], // Action
      viewDashboardLowStockCount: [false], // Action
      viewTodaysCustomer: [false], //Action
      viewTodaysLoyalCustomer: [false], //Action
      viewTodaysNewCustomer: [false], //Action

      // REPORTS
      viewSaleReport: [false], // Action
      viewSaleWiseProfitAndLossReport: [false], // Action
      viewPurchaseReport: [false], // Action
      viewMoneyInReport: [false], // Action
      viewMoneyOutReport: [false], // Action
      viewExpenseReport: [false], // Action
      viewPartyLedgerReport: [false], // Action
      viewPartyDetailReport: [false], //Action
      viewPartyReceivablePayableReport: [false], // Action
      viewStockSummaryReport: [false], // Action
      viewItemSaleReport: [false], // Action
      viewItemReport: [false], // Action
      viewItemDetailReport: [false], //Action
      viewDayBookReport: [false], // Action
      viewRawMaterialCostReport: [false],
      viewRawMaterialConsumptionReport: [false],
      viewRawMaterialPurchaseReport: [false],
      viewStaffWiseSaleReport: [false],
      viewAllProfilesDayBookReport: [false], // All Profile Day Book Report
      viewCutOffDayReport: [false], // Cut Off Day Report

      //SETTING
      viewPinAccess: [false], //Pin Access
      viewSetLoginPin: [false], //Set Login Pin
      viewResetLoginPin: [false], // Reset Login Pin
      viewActiveOnlineShop: [false], // Active Online Shop
      viewQrForOnlineShop: [false], // Qr For Online Shop
      viewSendBillToParty: [false], // Send Bill To party on whatsapp
      viewSendBillToOwner:[false] // send Bill To owner on whatsapp
    });

    this.subArr.push(this.form.valueChanges.pipe(distinctUntilChanged(), debounceTime(500)).subscribe(res => {
      this.checkAccessType(res);
    }));
  }

  /**
   * @description : this method call on toggle change
   */
  checkSelectAll() {
    if(this.showAllFields) {
      setTimeout(() => {
        this.checkEachSelectAllValue(this.form.value);
      }, 200);
    }
  }
  // -----------------------------

  /**
   * @description: toggle of view Party
   */
  togglePartyView(event) {
    this.form.patchValue({
      viewParty: !event?.detail?.checked,
    })
    if(this.selectAllParty) {
      this.selectAllParty.checked = !event?.detail?.checked;
    }
  }
  // -----------------------------

  /**
   * @description : toggle of hide Phone
   */
  toggleHidePhone(event) {
    this.form.patchValue({
      hidePhone: !event?.detail?.checked,
    })
  }
  // -----------------------------

  /**
   * @description : this method use for enabling disabling checkbox of select all 
   *                if specific group completely ON or OFF 
   */
  checkEachSelectAllValue(res) {
    // selectAllProfile
    if(this.selectAllProfile){
      if(res?.viewProfile && res?.editProfile) {
        this.selectAllProfile.checked = true;
      } else {
        this.selectAllProfile.checked = false;
      }
    }

    // selectAllExpense
    if(this.selectAllExpense){
      if(res?.createExpense && res?.editExpense && res?.viewExpense && res?.deleteExpense) {
        this.selectAllExpense.checked = true;
      } else {
        this.selectAllExpense.checked = false;
      }
    }

    // selectAllParty
    if(this.selectAllParty){
      if(res?.createParty && res?.editParty && res?.viewParty && res?.deleteParty) {
        this.selectAllParty.checked = true;
      } else {
        this.selectAllParty.checked = false;
      }
    }

    // selectAllPartyCategory
    if(this.selectAllPartyCategory){
      if(res?.createPartyCategory && res?.editPartyCategory && res?.viewPartyCategory && res?.deletePartyCategory) {
        this.selectAllPartyCategory.checked = true;
      } else {
        this.selectAllPartyCategory.checked = false;
      }
    }

    // selectAllItem
    if(this.selectAllItem){
      if(res?.createItem && res?.editItem && res?.viewItem && res?.deleteItem) {
        this.selectAllItem.checked = true;
      } else {
        this.selectAllItem.checked = false;
      }
    }

    // selectAllItemCategory
    if(this.selectAllItemCategory){
      if(res?.createItemCategory && res?.editItemCategory && res?.viewItemCategory && res?.deleteItemCategory) {
        this.selectAllItemCategory.checked = true;
      } else {
        this.selectAllItemCategory.checked = false;
      }
    }

    // selectAllMoneyIn
    if(this.selectAllMoneyIn){
      if(res?.createMoneyIn && res?.editMoneyIn && res?.viewMoneyIn && res?.deleteMoneyIn) {
        this.selectAllMoneyIn.checked = true;
      } else {
        this.selectAllMoneyIn.checked = false;
      }
    }

    // selectAllMoneyOut
    if(this.selectAllMoneyOut){
      if(res?.createMoneyOut && res?.editMoneyOut && res?.viewMoneyOut && res?.deleteMoneyOut) {
        this.selectAllMoneyOut.checked = true;
      } else {
        this.selectAllMoneyOut.checked = false;
      }
    }

    // selectAllSale
    if(this.selectAllSale){
      if(res?.createSale && res?.editSale && res?.viewSale && res?.deleteSale) {
        this.selectAllSale.checked = true;
      } else {
        this.selectAllSale.checked = false;
      }
    }

    // selectAllSaleReturn
    if(this.selectAllSaleReturn){
      if(res?.createSaleReturn && res?.viewSaleReturn && res?.deleteSaleReturn) {
        this.selectAllSaleReturn.checked = true;
      } else {
        this.selectAllSaleReturn.checked = false;
      }
    }

    // selectAllPurchase
    if(this.selectAllPurchase){
      if(res?.createPurchase && res?.editPurchase && res?.viewPurchase && res?.deletePurchase) {
        this.selectAllPurchase.checked = true;
      } else {
        this.selectAllPurchase.checked = false;
      }
    }

    // selectAllPurchaseReturn
    if(this.selectAllPurchaseReturn){
      if(res?.createPurchaseReturn && res?.viewPurchaseReturn && res?.deletePurchaseReturn) {
        this.selectAllPurchaseReturn.checked = true;
      } else {
        this.selectAllPurchaseReturn.checked = false;
      }
    }

    // selectAllEstimate
    if(this.selectAllEstimate){
      if(res?.createEstimate && res?.editEstimate && res?.viewEstimate && res?.deleteEstimate) {
        this.selectAllEstimate.checked = true;
      } else {
        this.selectAllEstimate.checked = false;
      }
    }

    // selectAllMisc
    if(this.selectAllMisc){
      if(res?.adjustStock && res?.viewKot) {
        this.selectAllMisc.checked = true;
      } else {
        this.selectAllMisc.checked = false;
      }
    }

    // selectAllAddRawMaterial
    if(this.selectAllAddRawMaterial){
      if(res?.createRawMaterial && res?.editRawMaterial && res?.deleteRawMaterial) {
        this.selectAllAddRawMaterial.checked = true;
      } else {
        this.selectAllAddRawMaterial.checked = false;
      }
    }

    // selectAllDashboard
    if(this.selectAllDashboard){
      if(
        res?.viewDashboardSalesTotal &&
        res?.viewDashboardMoneyInsTotal &&
        res?.viewDashboardPurchasesTotal &&
        res?.viewDashboardMoneyOutsTotal &&
        res?.viewDashboardPayableTotal &&
        res?.viewDashboardReceivableTotal &&
        res?.viewDashboardLowStockCount &&
        res?.viewTodaysCustomer &&
        res?.viewTodaysLoyalCustomer &&
        res?.viewTodaysNewCustomer
      ) {
          this.selectAllDashboard.checked = true;
      } else {
          this.selectAllDashboard.checked = false;
      }
    }
    

    // selectAllReport
    if(this.selectAllReport){
      if(
        res?.viewSaleReport &&
        res?.viewSaleWiseProfitAndLossReport &&
        res?.viewPurchaseReport &&
        res?.viewMoneyInReport &&
        res?.viewMoneyOutReport &&
        res?.viewExpenseReport &&
        res?.viewPartyLedgerReport &&
        res?.viewPartyReceivablePayableReport &&
        res?.viewStockSummaryReport &&
        res?.viewItemSaleReport &&
        res?.viewItemReport &&
        res?.viewDayBookReport &&
        res?.viewPartyDetailReport &&
        res?.viewItemDetailReport &&
        res?.viewRawMaterialCostReport &&
        res?.viewRawMaterialConsumptionReport &&
        res?.viewRawMaterialPurchaseReport &&
        res?.viewAllProfilesDayBookReport &&
        res?.viewCutOffDayReport
      ) {
        this.selectAllReport.checked = true;
      } else {
        this.selectAllReport.checked = false;
      }
    }

    //Setting
    if(this.selectAllSetting){
      if(res?.viewPinAccess &&
        res?.viewSetLoginPin &&
        res?.viewResetLoginPin &&
        res?.viewActiveOnlineShop &&
        res?.viewQrForOnlineShop &&
        res?.viewSendBillToParty &&
        res?.viewSendBillToOwner
        ){
        this.selectAllSetting.checked =true;
      }else{
        this.selectAllSetting.checked = false;
      }
    }

  }
  // -----------------------------

  toggleShowAllFields() {
    this.showAllFields = !this.showAllFields;
    if(this.showAllFields && this.form.value) {
      setTimeout(() => {
        this.checkEachSelectAllValue(this.form.value);
      })
    }
  }

  onAccessTypeChange(event: any) {
    const type = event?.detail?.value;
    let accessObj = {};
    if(type==='SaleAccess') {
      accessObj = {
        viewProfile: false,
        editProfile: false,
        hidePhone: true,
        createParty: false,
        editParty: false,
        viewParty: false,
        deleteParty: false,
        createPartyCategory: false,
        editPartyCategory: false,
        viewPartyCategory: false,
        deletePartyCategory: false,
        createItem: false,
        editItem: false,
        viewItem: false,
        deleteItem: false,
        createItemCategory: false,
        editItemCategory: false,
        viewItemCategory: false,
        deleteItemCategory: false,
        createMoneyIn: false,
        editMoneyIn: false,
        viewMoneyIn: false,
        deleteMoneyIn: false,
        createMoneyOut: false,
        editMoneyOut: false,
        viewMoneyOut: false,
        deleteMoneyOut: false,
        createEstimate: false,
        editEstimate: false,
        viewEstimate: false,
        deleteEstimate: false,
        createPurchase: false,
        editPurchase: false,
        viewPurchase: false,
        deletePurchase: false,
        createPurchaseReturn: false,
        // editPurchaseReturn: false,
        viewPurchaseReturn: false,
        deletePurchaseReturn: false,
        createExpense: false,
        editExpense: false,
        viewExpense: false,
        deleteExpense: false,
        createSale: true,
        editSale: true,
        viewSale: true,
        deleteSale: false,
        createSaleReturn: false,
        // editSaleReturn: false,
        viewSaleReturn: false,
        deleteSaleReturn: false,
        adjustStock: false,
        viewKot: false,
        createRawMaterialPurchaseEntry: false,
        createRawMaterialScrapEntry: false,
        createRawMaterialTransferEntry: false,
        createRawMaterialReturnEntry: false,
        createRawMaterialEODEntry: false,
        createRawMaterial: false,
        editRawMaterial: false,
        deleteRawMaterial: false,
        rawMaterialPurchaseBulkEdit: false,
        viewDashboardSalesTotal: false,
        viewDashboardMoneyInsTotal: false,
        viewDashboardPurchasesTotal: false,
        viewDashboardMoneyOutsTotal: false,
        viewDashboardPayableTotal: false,
        viewDashboardReceivableTotal: false,
        viewDashboardLowStockCount: false,
        viewTodaysCustomer:false,
        viewTodaysLoyalCustomer:false,
        viewTodaysNewCustomer:false,
        viewSaleReport: false,
        viewStaffWiseSaleReport: false,
        viewSaleWiseProfitAndLossReport: false,
        viewPurchaseReport: false,
        viewMoneyInReport: false,
        viewMoneyOutReport: false,
        viewExpenseReport: false,
        viewPartyLedgerReport: false,
        viewPartyReceivablePayableReport: false,
        viewStockSummaryReport: false,
        viewItemSaleReport: false,
        viewItemReport: false,
        viewDayBookReport: false,
        viewPartyDetailReport: false,
        viewItemDetailReport: false,
        viewRawMaterialCostReport: false,
        viewRawMaterialConsumptionReport: false,
        viewRawMaterialPurchaseReport: false,
        viewAllProfilesDayBookReport:false,
        viewCutOffDayReport:false,
        //Setting
        viewPinAccess:false,
        viewSetLoginPin: false, 
        viewResetLoginPin: false,
        viewActiveOnlineShop: false, 
        viewQrForOnlineShop: false,
        viewSendBillToParty:false,
        viewSendBillToOwner:false
      };
    }
    if(type==='FullAccess') {
      accessObj = {
        viewProfile: true,
        editProfile: true,
        hidePhone: false,
        createParty: true,
        editParty: true,
        viewParty: true,
        deleteParty: true,
        createPartyCategory: true,
        editPartyCategory: true,
        viewPartyCategory: true,
        deletePartyCategory: true,
        createItem: true,
        editItem: true,
        viewItem: true,
        deleteItem: true,
        createItemCategory: true,
        editItemCategory: true,
        viewItemCategory: true,
        deleteItemCategory: true,
        createMoneyIn: true,
        editMoneyIn: true,
        viewMoneyIn: true,
        deleteMoneyIn: true,
        createMoneyOut: true,
        editMoneyOut: true,
        viewMoneyOut: true,
        deleteMoneyOut: true,
        createEstimate: true,
        editEstimate: true,
        viewEstimate: true,
        deleteEstimate: true,
        createPurchase: true,
        editPurchase: true,
        viewPurchase: true,
        deletePurchase: true,
        createPurchaseReturn: true,
        // editPurchaseReturn: true,
        viewPurchaseReturn: true,
        deletePurchaseReturn: true,
        createExpense: true,
        editExpense: true,
        viewExpense: true,
        deleteExpense: true,
        createSale: true,
        editSale: true,
        viewSale: true,
        deleteSale: true,
        createSaleReturn: true,
        // editSaleReturn: true,
        viewSaleReturn: true,
        deleteSaleReturn: true,
        adjustStock: true,
        viewKot: true,
        createRawMaterialPurchaseEntry: true,
        createRawMaterialScrapEntry: true,
        createRawMaterialTransferEntry: true,
        createRawMaterialReturnEntry: true,
        createRawMaterialEODEntry: true,
        createRawMaterial: true,
        editRawMaterial: true,
        deleteRawMaterial: true,
        rawMaterialPurchaseBulkEdit: true,
        viewDashboardSalesTotal: true,
        viewDashboardMoneyInsTotal: true,
        viewDashboardPurchasesTotal: true,
        viewDashboardMoneyOutsTotal: true,
        viewDashboardPayableTotal: true,
        viewDashboardReceivableTotal: true,
        viewDashboardLowStockCount: true,
        viewTodaysCustomer:true,
        viewTodaysLoyalCustomer:true,
        viewTodaysNewCustomer:true,
        viewSaleReport: true,
        viewStaffWiseSaleReport : false,
        viewSaleWiseProfitAndLossReport: true,
        viewPurchaseReport: true,
        viewMoneyInReport: true,
        viewMoneyOutReport: true,
        viewExpenseReport: true,
        viewPartyLedgerReport: true,
        viewPartyReceivablePayableReport: true,
        viewStockSummaryReport: true,
        viewItemSaleReport: true,
        viewItemReport: true,
        viewDayBookReport: true,
        viewPartyDetailReport: true,
        viewItemDetailReport: true,
        viewRawMaterialCostReport: true,
        viewRawMaterialConsumptionReport: true,
        viewRawMaterialPurchaseReport: true,
        viewAllProfilesDayBookReport:true,
        viewCutOffDayReport:true,
        //Setting
        viewPinAccess:true,
        viewSetLoginPin: true, 
        viewResetLoginPin: true,
        viewActiveOnlineShop: true, 
        viewQrForOnlineShop: true,
        viewSendBillToParty:true,
        viewSendBillToOwner:true
      };
    }
    this.form?.patchValue(accessObj);
    if(this.showAllFields) {
      this.checkEachSelectAllValue(accessObj);
    }
  }

  async populateForm() {
    this.userId = this.activatedRoute.snapshot.paramMap.get('userId');
    if(this.userId) {
      const profile = await this.allDataService.profileService.getCurrentProfile();
      if(profile?.accessTo?.length) {
        let filteredStaff = profile.accessTo.filter(x => x.userId === this.userId);
        if(filteredStaff?.length) {
          this.form.patchValue(filteredStaff[0]);
          this.staffAccessObj = filteredStaff[0];
        }
      }
    }
  }

  onSaveClick() {
    this.userId ? this.update() : this.save();
  }

  async save() {
    if(this.form?.valid && await this.validateStaffPhone()) {
      let accessTo = new AccessTo();
      accessTo = {...this.form?.value};

      const profile = await this.allDataService.profileService.getCurrentProfile();
      if(profile?._localUUID) {
        if(profile?.accessTo?.length) {
          let filteredStaff = profile.accessTo.filter(x => x?.userId === accessTo?.userId);
          if(filteredStaff?.length) {
            const toast = await this.toastController.create({
              message: 'This staff is already exits',
              duration: 2000,
              color: 'danger',
            });
            await toast.present();
            return false;
          }else {
            profile.accessTo.push(accessTo);
          }
        }else {
          profile.accessTo = [accessTo];
        }
      }

      const updatedProfile = await this.allDataService.profileService.update(profile);
      if(updatedProfile?.accessTo?.length) {
        if(updatedProfile?.accessTo?.filter(x => x?.userId === accessTo?.userId)?.length) {
          const toast = await this.toastController.create({
            message: 'Staff added successfully',
            duration: 2000,
            color: 'success',
          });
          await toast.present();
          this.router.navigate(['staff']);
          return true;
        }
      }
      const toast = await this.toastController.create({
        message: 'Something went wrong',
        duration: 2000,
        color: 'danger',
      });
      await toast.present();
      return false;
    }
  }

  async update() {
    if(this.form?.valid && await this.validateStaffPhone()) {
      
      const profile = await this.allDataService.profileService.getCurrentProfile();
      if(profile?.accessTo?.length) {
        let filteredStaff = profile.accessTo.filter(x => x?.userId === this.userId);
        if(filteredStaff?.length) {
          let accessTo = filteredStaff[0];
          accessTo = {...this.form?.value};
          accessTo.userId = this.userId;
          let index = profile?.accessTo?.findIndex(x => x?.userId === this.userId);
          if(index != -1) {
            profile.accessTo.splice(index,1,accessTo);
            const updatedProfile = await this.allDataService.profileService.update(profile);
            if(updatedProfile?.accessTo?.length) {
              if(updatedProfile?.accessTo?.filter(x => x?.userId === this.userId)?.length) {
                const toast = await this.toastController.create({
                  message: 'Staff Updated successfully',
                  duration: 2000,
                  color: 'success',
                });
                await toast.present();
                this.router.navigate(['staff']);
                return true;
              }
            }
          }
        }
      }
      const toast = await this.toastController.create({
        message: 'Something went wrong',
        duration: 2000,
        color: 'danger',
      });
      await toast.present();
      return false;
    }
  }

  validateStaffPhone() {
    return new Promise(async (resolve,reject) => { 
      try {
        let loginPhone = this.authService.getLoginPhone();
        if(this.form?.value?.userId == loginPhone) {
          const toast = await this.toastController.create({
            message: 'Staff phone number should not be same as owner phone number',
            duration: 2000,
            color: 'danger',
          });
          await toast.present();
          return resolve(false);
        }
        return resolve(true);
      } catch (error) {
        return resolve(false);
      }
    });
  }

  /**
   * @description : this method check user is click on checkbox or not.
   */
  selectAllCheckboxClick() {
    this.isSelectAllCheckboxClick = true;
  }
  // -----------------------------

  enableDisableAll(event: any, type: 'Profile' | 'Party' | 'PartyCategory' | 'Item' | 'ItemCategory' | 'Sale' | 'SaleReturn' | 'Purchase' | 'PurchaseReturn' | 'Expense' | 'MoneyIn' | 'MoneyOut' | 'Estimate' | 'MISC' | 'AddRawMaterial' | 'Dashboard' | 'Report' | 'Setting') {
    if(this.isSelectAllCheckboxClick) {
      let checked = event?.detail?.checked;
      let obj = {};
      if(type == 'Profile') {
        obj['viewProfile'] = checked;
        obj['editProfile'] = checked;
      }else if(type == 'Party') {
        obj['viewParty'] = checked;
        obj['editParty'] = checked;
        obj['createParty'] = checked;
        obj['deleteParty'] = checked;
      }else if(type == 'PartyCategory') {
        obj['viewPartyCategory'] = checked;
        obj['editPartyCategory'] = checked;
        obj['createPartyCategory'] = checked;
        obj['deletePartyCategory'] = checked;
      }else if(type == 'Item') {
        obj['viewItem'] = checked;
        obj['editItem'] = checked;
        obj['createItem'] = checked;
        obj['deleteItem'] = checked;
      }else if(type == 'ItemCategory') {
        obj['viewItemCategory'] = checked;
        obj['editItemCategory'] = checked;
        obj['createItemCategory'] = checked;
        obj['deleteItemCategory'] = checked;
      }else if(type == 'Sale') {
        obj['viewSale'] = checked;
        obj['editSale'] = checked;
        obj['createSale'] = checked;
        obj['deleteSale'] = checked;
      }else if(type == 'SaleReturn') {
        obj['viewSaleReturn'] = checked;
        // obj['editSaleReturn'] = checked;
        obj['createSaleReturn'] = checked;
        obj['deleteSaleReturn'] = checked;
      }else if(type == 'Purchase') {
        obj['viewPurchase'] = checked;
        obj['editPurchase'] = checked;
        obj['createPurchase'] = checked;
        obj['deletePurchase'] = checked;
      }else if(type == 'PurchaseReturn') {
        obj['viewPurchaseReturn'] = checked;
        // obj['editPurchaseReturn'] = checked;
        obj['createPurchaseReturn'] = checked;
        obj['deletePurchaseReturn'] = checked;
      }else if(type == 'Expense') {
        obj['viewExpense'] = checked;
        obj['editExpense'] = checked;
        obj['createExpense'] = checked;
        obj['deleteExpense'] = checked;
      }else if(type == 'MoneyIn') {
        obj['viewMoneyIn'] = checked;
        obj['editMoneyIn'] = checked;
        obj['createMoneyIn'] = checked;
        obj['deleteMoneyIn'] = checked;
      }else if(type == 'MoneyOut') {
        obj['viewMoneyOut'] = checked;
        obj['editMoneyOut'] = checked;
        obj['createMoneyOut'] = checked;
        obj['deleteMoneyOut'] = checked;
      }else if(type == 'Estimate') {
        obj['viewEstimate'] = checked;
        obj['editEstimate'] = checked;
        obj['createEstimate'] = checked;
        obj['deleteEstimate'] = checked;
      }else if(type == 'MISC') {
        obj['adjustStock'] = checked;
        obj['viewKot'] = checked;
      }else if(type == 'AddRawMaterial') {
        obj['createRawMaterial'] = checked;
        obj['editRawMaterial'] = checked;
        obj['deleteRawMaterial'] = checked;
      }else if(type == 'Dashboard') {
        obj['viewDashboardSalesTotal'] = checked;
        obj['viewDashboardMoneyInsTotal'] = checked;
        obj['viewDashboardPurchasesTotal'] = checked;
        obj['viewDashboardMoneyOutsTotal'] = checked;
        obj['viewDashboardPayableTotal'] = checked;
        obj['viewDashboardReceivableTotal'] = checked;
        obj['viewDashboardLowStockCount'] = checked;
        obj['viewTodaysCustomer']=checked;
        obj['viewTodaysLoyalCustomer']=checked;
        obj['viewTodaysNewCustomer']=checked;
      }else if(type == 'Report') {
        obj['viewSaleReport'] = checked;
        obj['viewSaleWiseProfitAndLossReport'] = checked;
        obj['viewPurchaseReport'] = checked;
        obj['viewMoneyInReport'] = checked;
        obj['viewMoneyOutReport'] = checked;
        obj['viewExpenseReport'] = checked;
        obj['viewPartyLedgerReport'] = checked;
        obj['viewPartyReceivablePayableReport'] = checked;
        obj['viewStockSummaryReport'] = checked;
        obj['viewItemSaleReport'] = checked;
        obj['viewItemReport'] = checked;
        obj['viewDayBookReport'] = checked;
        obj['viewPartyDetailReport'] = checked;
        obj['viewItemDetailReport'] = checked;
        obj['viewRawMaterialCostReport'] = checked;
        obj['viewRawMaterialConsumptionReport'] = checked;
        obj['viewRawMaterialPurchaseReport'] = checked;
        obj['viewAllProfilesDayBookReport'] = checked;
        obj['viewCutOffDayReport'] = checked;
      }else if(type == 'Setting'){
        obj['viewPinAccess'] = checked;
        obj['viewSetLoginPin'] = checked;
        obj['viewResetLoginPin'] = checked;
        obj['viewActiveOnlineShop'] =checked;
        obj['viewQrForOnlineShop'] = checked;
        obj['viewSendBillToParty'] = checked;
        obj['viewSendBillToOwner'] = checked;
      }
      this.form?.patchValue(obj);
    }
    this.isSelectAllCheckboxClick = false;
  }

  checkAccessType(staffAccessObj: AccessTo) {
    if(
      (staffAccessObj?.viewProfile || false) == false &&
      (staffAccessObj?.editProfile || false) == false &&
      (staffAccessObj?.hidePhone || false) == true &&
      (staffAccessObj?.createParty || false) == false &&
      (staffAccessObj?.editParty || false) == false &&
      (staffAccessObj?.viewParty || false) == false &&
      (staffAccessObj?.deleteParty || false) == false &&
      (staffAccessObj?.createPartyCategory || false) == false &&
      (staffAccessObj?.editPartyCategory || false) == false &&
      (staffAccessObj?.viewPartyCategory || false) == false &&
      (staffAccessObj?.deletePartyCategory || false) == false &&
      (staffAccessObj?.createItem || false) == false &&
      (staffAccessObj?.editItem || false) == false &&
      (staffAccessObj?.viewItem || false) == false &&
      (staffAccessObj?.deleteItem || false) == false &&
      (staffAccessObj?.createItemCategory || false) == false &&
      (staffAccessObj?.editItemCategory || false) == false &&
      (staffAccessObj?.viewItemCategory || false) == false &&
      (staffAccessObj?.deleteItemCategory || false) == false &&
      (staffAccessObj?.createMoneyIn || false) == false &&
      (staffAccessObj?.editMoneyIn || false) == false &&
      (staffAccessObj?.viewMoneyIn || false) == false &&
      (staffAccessObj?.deleteMoneyIn || false) == false &&
      (staffAccessObj?.createMoneyOut || false) == false &&
      (staffAccessObj?.editMoneyOut || false) == false &&
      (staffAccessObj?.viewMoneyOut || false) == false &&
      (staffAccessObj?.deleteMoneyOut || false) == false &&
      (staffAccessObj?.createEstimate || false) == false &&
      (staffAccessObj?.editEstimate || false) == false &&
      (staffAccessObj?.viewEstimate || false) == false &&
      (staffAccessObj?.deleteEstimate || false) == false &&
      (staffAccessObj?.createPurchase || false) == false &&
      (staffAccessObj?.editPurchase || false) == false &&
      (staffAccessObj?.viewPurchase || false) == false &&
      (staffAccessObj?.deletePurchase || false) == false &&
      (staffAccessObj?.createPurchaseReturn || false) == false &&
      // (staffAccessObj?.editPurchaseReturn || false) == false &&
      (staffAccessObj?.viewPurchaseReturn || false) == false &&
      (staffAccessObj?.deletePurchaseReturn || false) == false &&
      (staffAccessObj?.createExpense || false) == false &&
      (staffAccessObj?.editExpense || false) == false &&
      (staffAccessObj?.viewExpense || false) == false &&
      (staffAccessObj?.deleteExpense || false) == false &&
      (staffAccessObj?.createSale || false) == true &&
      (staffAccessObj?.editSale || false) == true &&
      (staffAccessObj?.viewSale || false) == true &&
      (staffAccessObj?.deleteSale || false) == false &&
      (staffAccessObj?.createSaleReturn || false) == false &&
      // (staffAccessObj?.editSaleReturn || false) == false &&
      (staffAccessObj?.viewSaleReturn || false) == false &&
      (staffAccessObj?.deleteSaleReturn || false) == false &&
      (staffAccessObj?.adjustStock || false) == false &&
      (staffAccessObj?.viewKot || false) == false &&
      (staffAccessObj?.createRawMaterialPurchaseEntry || false) == false &&
      (staffAccessObj?.createRawMaterialScrapEntry || false) == false &&
      (staffAccessObj?.createRawMaterialTransferEntry || false) == false &&
      (staffAccessObj?.createRawMaterialReturnEntry || false) == false &&
      (staffAccessObj?.createRawMaterialEODEntry || false) == false &&
      (staffAccessObj?.createRawMaterial || false) == false &&
      (staffAccessObj?.editRawMaterial || false) == false &&
      (staffAccessObj?.deleteRawMaterial || false) == false &&
      (staffAccessObj?.rawMaterialPurchaseBulkEdit || false) == false &&
      (staffAccessObj?.viewDashboardSalesTotal || false) == false &&
      (staffAccessObj?.viewDashboardMoneyInsTotal || false) == false &&
      (staffAccessObj?.viewDashboardPurchasesTotal || false) == false &&
      (staffAccessObj?.viewDashboardMoneyOutsTotal || false) == false &&
      (staffAccessObj?.viewDashboardPayableTotal || false) == false &&
      (staffAccessObj?.viewDashboardReceivableTotal || false) == false &&
      (staffAccessObj?.viewDashboardLowStockCount || false) == false &&
      (staffAccessObj?.viewTodaysCustomer || false) ==false &&
      (staffAccessObj?.viewTodaysLoyalCustomer || false) ==false &&
      (staffAccessObj?.viewTodaysNewCustomer || false) ==false &&
      (staffAccessObj?.viewSaleReport || false) == false &&
      // (staffAccessObj?.viewStaffWiseSaleReport || false) == false &&
      (staffAccessObj?.viewSaleWiseProfitAndLossReport || false) == false &&
      (staffAccessObj?.viewPurchaseReport || false) == false &&
      (staffAccessObj?.viewMoneyInReport || false) == false &&
      (staffAccessObj?.viewMoneyOutReport || false) == false &&
      (staffAccessObj?.viewExpenseReport || false) == false &&
      (staffAccessObj?.viewPartyLedgerReport || false) == false &&
      (staffAccessObj?.viewPartyReceivablePayableReport || false) == false &&
      (staffAccessObj?.viewStockSummaryReport || false) == false &&
      (staffAccessObj?.viewItemSaleReport || false) == false &&
      (staffAccessObj?.viewItemReport || false) == false &&
      (staffAccessObj?.viewDayBookReport || false) == false &&
      (staffAccessObj?.viewPartyDetailReport || false) == false &&
      (staffAccessObj?.viewItemDetailReport || false) == false &&
      (staffAccessObj?.viewRawMaterialCostReport || false) == false &&
      (staffAccessObj?.viewRawMaterialConsumptionReport || false) == false &&
      (staffAccessObj?.viewRawMaterialPurchaseReport || false) == false &&
      (staffAccessObj?.viewAllProfilesDayBookReport || false) ==false &&
      (staffAccessObj?.viewCutOffDayReport || false) ==false &&
      //Setting
      (staffAccessObj?.viewPinAccess || false) ==false &&
      (staffAccessObj?.viewSetLoginPin || false) == false && 
      (staffAccessObj?.viewResetLoginPin || false) == false &&
      (staffAccessObj?.viewActiveOnlineShop || false) == false && 
      (staffAccessObj?.viewQrForOnlineShop || false) == false &&
      (staffAccessObj?.viewSendBillToParty || false) ==false &&
      (staffAccessObj?.viewSendBillToOwner || false) ==false
    ) {
      this.selectedAccessType = 'SaleAccess';
    } else if(
      (staffAccessObj?.viewProfile || false) == true &&
      (staffAccessObj?.editProfile || false) == true &&
      (staffAccessObj?.hidePhone || false) == false &&
      (staffAccessObj?.createParty || false) == true &&
      (staffAccessObj?.editParty || false) == true &&
      (staffAccessObj?.viewParty || false) == true &&
      (staffAccessObj?.deleteParty || false) == true &&
      (staffAccessObj?.createPartyCategory || false) == true &&
      (staffAccessObj?.editPartyCategory || false) == true &&
      (staffAccessObj?.viewPartyCategory || false) == true &&
      (staffAccessObj?.deletePartyCategory || false) == true &&
      (staffAccessObj?.createItem || false) == true &&
      (staffAccessObj?.editItem || false) == true &&
      (staffAccessObj?.viewItem || false) == true &&
      (staffAccessObj?.deleteItem || false) == true &&
      (staffAccessObj?.createItemCategory || false) == true &&
      (staffAccessObj?.editItemCategory || false) == true &&
      (staffAccessObj?.viewItemCategory || false) == true &&
      (staffAccessObj?.deleteItemCategory || false) == true &&
      (staffAccessObj?.createMoneyIn || false) == true &&
      (staffAccessObj?.editMoneyIn || false) == true &&
      (staffAccessObj?.viewMoneyIn || false) == true &&
      (staffAccessObj?.deleteMoneyIn || false) == true &&
      (staffAccessObj?.createMoneyOut || false) == true &&
      (staffAccessObj?.editMoneyOut || false) == true &&
      (staffAccessObj?.viewMoneyOut || false) == true &&
      (staffAccessObj?.deleteMoneyOut || false) == true &&
      (staffAccessObj?.createEstimate || false) == true &&
      (staffAccessObj?.editEstimate || false) == true &&
      (staffAccessObj?.viewEstimate || false) == true &&
      (staffAccessObj?.deleteEstimate || false) == true &&
      (staffAccessObj?.createPurchase || false) == true &&
      (staffAccessObj?.editPurchase || false) == true &&
      (staffAccessObj?.viewPurchase || false) == true &&
      (staffAccessObj?.deletePurchase || false) == true &&
      (staffAccessObj?.createPurchaseReturn || false) == true &&
      // (staffAccessObj?.editPurchaseReturn || false) == true &&
      (staffAccessObj?.viewPurchaseReturn || false) == true &&
      (staffAccessObj?.deletePurchaseReturn || false) == true &&
      (staffAccessObj?.createExpense || false) == true &&
      (staffAccessObj?.editExpense || false) == true &&
      (staffAccessObj?.viewExpense || false) == true &&
      (staffAccessObj?.deleteExpense || false) == true &&
      (staffAccessObj?.createSale || false) == true &&
      (staffAccessObj?.editSale || false) == true &&
      (staffAccessObj?.viewSale || false) == true &&
      (staffAccessObj?.deleteSale || false) == true &&
      (staffAccessObj?.createSaleReturn || false) == true &&
      // (staffAccessObj?.editSaleReturn || false) == true &&
      (staffAccessObj?.viewSaleReturn || false) == true &&
      (staffAccessObj?.deleteSaleReturn || false) == true &&
      (staffAccessObj?.adjustStock || false) == true &&
      (staffAccessObj?.viewKot || false) == true &&
      (staffAccessObj?.createRawMaterialPurchaseEntry || false) == true &&
      (staffAccessObj?.createRawMaterialScrapEntry || false) == true &&
      (staffAccessObj?.createRawMaterialTransferEntry || false) == true &&
      (staffAccessObj?.createRawMaterialReturnEntry || false) == true &&
      (staffAccessObj?.createRawMaterialEODEntry || false) == true &&
      (staffAccessObj?.createRawMaterial || false) == true &&
      (staffAccessObj?.editRawMaterial || false) == true &&
      (staffAccessObj?.deleteRawMaterial || false) == true &&
      (staffAccessObj?.rawMaterialPurchaseBulkEdit || false) == true &&
      (staffAccessObj?.viewDashboardSalesTotal || false) == true &&
      (staffAccessObj?.viewDashboardMoneyInsTotal || false) == true &&
      (staffAccessObj?.viewDashboardPurchasesTotal || false) == true &&
      (staffAccessObj?.viewDashboardMoneyOutsTotal || false) == true &&
      (staffAccessObj?.viewDashboardPayableTotal || false) == true &&
      (staffAccessObj?.viewDashboardReceivableTotal || false) == true &&
      (staffAccessObj?.viewDashboardLowStockCount || false) == true &&
      (staffAccessObj?.viewTodaysCustomer || false) ==true &&
      (staffAccessObj?.viewTodaysLoyalCustomer || false) ==true &&
      (staffAccessObj?.viewTodaysNewCustomer || false) ==true &&
      (staffAccessObj?.viewSaleReport || false) == true &&
      // (staffAccessObj?.viewStaffWiseSaleReport || false) == false &&
      (staffAccessObj?.viewSaleWiseProfitAndLossReport || false) == true &&
      (staffAccessObj?.viewPurchaseReport || false) == true &&
      (staffAccessObj?.viewMoneyInReport || false) == true &&
      (staffAccessObj?.viewMoneyOutReport || false) == true &&
      (staffAccessObj?.viewExpenseReport || false) == true &&
      (staffAccessObj?.viewPartyLedgerReport || false) == true &&
      (staffAccessObj?.viewPartyReceivablePayableReport || false) == true &&
      (staffAccessObj?.viewStockSummaryReport || false) == true &&
      (staffAccessObj?.viewItemSaleReport || false) == true &&
      (staffAccessObj?.viewItemReport || false) == true &&
      (staffAccessObj?.viewDayBookReport || false) == true &&
      (staffAccessObj?.viewPartyDetailReport || false) == true &&
      (staffAccessObj?.viewItemDetailReport || false) == true &&
      (staffAccessObj?.viewRawMaterialCostReport || false) == true &&
      (staffAccessObj?.viewRawMaterialConsumptionReport || false) == true &&
      (staffAccessObj?.viewRawMaterialPurchaseReport || false) == true &&
      (staffAccessObj?.viewAllProfilesDayBookReport || false) ==true &&
      (staffAccessObj?.viewCutOffDayReport || false) ==true &&
      //Setting
      (staffAccessObj?.viewPinAccess || false) ==true &&
      (staffAccessObj?.viewSetLoginPin || false) == true && 
      (staffAccessObj?.viewResetLoginPin || false) == true &&
      (staffAccessObj?.viewActiveOnlineShop || false) == true && 
      (staffAccessObj?.viewQrForOnlineShop || false) == true &&
      (staffAccessObj?.viewSendBillToParty || false) ==true &&
      (staffAccessObj?.viewSendBillToOwner || false) ==true
    ) {
      this.selectedAccessType = 'FullAccess';
    } else {
      this.selectedAccessType = 'CustomAccess';
    }

  }

  ionViewWillLeave() {
    this.subArr?.forEach(sub => sub?.unsubscribe());
  }

}
