import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { AllDataService } from '../../services/all-data.service';
import { ExpressServerService } from '../../services/api/express-server.service';
import { Profile } from '../../models/Profile.model';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-pin-verification',
  templateUrl: './pin-verification.component.html',
  styleUrls: ['./pin-verification.component.scss'],
})
export class PinVerificationComponent implements OnInit {

  @ViewChild('inpPinResetOTPEle') inpPinResetOTPEle: ElementRef;
  @ViewChild('inpTransactionPINEle') inpTransactionPINEle: ElementRef;
  @ViewChild('inpEnterNewPinEle') inpEnterNewPinEle: ElementRef;
  @ViewChild('inpSecondPinEle') inpSecondPinEle: ElementRef;

  @Output() verifyTransactionPin = new EventEmitter<boolean>();

  isPinResetModalOpen: boolean = false;
  isPinGenerationModalOpen: boolean = false;
  isTransactionPINModalOpen: boolean = false;

  constructor(
    private allDataService: AllDataService,
    private expressServerService: ExpressServerService,
    private toastController: ToastController
  ) { }

  ngOnInit() {}

  async openTransactionPINModal(isOpen: boolean = true) {
    try {
      const profile: Profile = await this.allDataService?.profileService?.getCurrentProfile();
      if(isOpen) {
        setTimeout(() => {
          this.inpTransactionPINEle?.nativeElement?.focus();
        }, 500);
      }
      if(!profile?.aAppPinStatus) {
        this.verifyTransactionPin?.emit(true);
      } else {
        this.isTransactionPINModalOpen = isOpen;
      }
    } catch (error) {
      SentryUtilites.setLog("PinVerificationComponent:openTransactionPINModal", error)
    }
  }

  async forgetPIN() {
    try {
      this.openTransactionPINModal(false);
      this.showToast('OTP send Process Initiated', 'success');
      let res = await this.expressServerService?.sendPinResetOTP();
      if (res?.['result']) {
        this.showToast('OTP send successfully', 'success');
        this.openPinResetModal();
      } else {
        this.showToast('Failed to send OTP', 'danger');
      }
    } catch (error) {
      SentryUtilites.setLog("PinVerificationComponent:forgetPIN", error)
    }
  }

  async verifyTransactionPIN(){
    try {
      const profile: Profile = await this.allDataService?.profileService?.getCurrentProfile();
      let inputVal: string = this.inpTransactionPINEle?.nativeElement?.value as string;
      if(inputVal === profile?.aAppPin) {
        this.openTransactionPINModal(false);
        this.verifyTransactionPin.emit(true);
      } else if(inputVal?.length < 4) {
        this.showToast('Enter 4 digit PIN', 'danger');
        this.verifyTransactionPin.emit(false);
      } else {
        this.showToast('Enter valid PIN', 'danger');
        this.verifyTransactionPin.emit(false);
      }
    } catch (error) {
      SentryUtilites.setLog("PinVerificationComponent:verifyTransactionPIN", error)
    }
  }

  openPinGenerationModal(isOpen: boolean = true) {
    try {
      this.isPinGenerationModalOpen = isOpen;
      if(isOpen) {
        setTimeout(() => {
          this.inpEnterNewPinEle?.nativeElement?.focus();
        }, 500);
      }
    } catch (error) {
      SentryUtilites.setLog("PinVerificationComponent:openPinGenerationModal", error)
    }
  }

  openPinResetModal(isOpen: boolean = true) {
    try {
      this.isPinResetModalOpen = isOpen;
      if(isOpen) {
        setTimeout(() => {
          this.inpPinResetOTPEle?.nativeElement?.focus();
        }, 500);
      }
    } catch (error) {
      SentryUtilites.setLog("PinVerificationComponent:openPinResetModal", error)
    }
  }

  async verifyPinResetOTP() {
    try {
      let otp: string = this.inpPinResetOTPEle?.nativeElement.value as string;
      if (otp) {
        let res = await this.expressServerService?.verifyPinResetOTP(otp + '');
        if (res?.['result']) {
          this.openPinResetModal(false);
          const profile = await this.allDataService?.profileService?.getCurrentProfile();
          if(profile?._localUUID) {
            profile.aAppPinStatus = false;
            profile.aAppPin = null;
            await this.allDataService?.profileService?.update(profile);
            this.allDataService?.listForceReloadSubs?.next('profile-list');
            this.showToast('Verify OTP successfully & disable security setting', 'success');
            this.openPinGenerationModal();
          }
        } else {
          this.showToast('Enter valid OTP', 'danger');
        }
      } else if(otp?.length < 4) {
        this.showToast('Enter 4 digit OTP', 'danger');
        this.verifyTransactionPin?.emit(false);
      } else {
        this.showToast('Enter valid OTP', 'danger');
        this.verifyTransactionPin?.emit(false);
      }
    } catch (error) {
      SentryUtilites.setLog("PinVerificationComponent:verifyPinResetOTP", error)
    }
  }

  async matchPin() {
    try {
      let firstPin: string = this.inpEnterNewPinEle?.nativeElement?.value;
      let secondPin: string = this.inpSecondPinEle?.nativeElement?.value;
      if (firstPin === secondPin && firstPin?.length === 4 && secondPin?.length === 4) {
        const profile: Profile = await this.allDataService?.profileService?.getCurrentProfile();
        if(profile?._localUUID) {
          profile.aAppPinStatus = true;
          profile.aAppPin = firstPin;
          await this.allDataService?.profileService?.update(profile);
          this.showToast('PIN set successfully', 'success');
          this.allDataService?.listForceReloadSubs?.next('profile-list');
          this.openPinGenerationModal(false);
        }
      } else if(firstPin?.length > 0 && secondPin?.length > 0 && firstPin !== secondPin) {
        this.showToast('PIN does not match', 'danger');
      } else if(firstPin?.length < 4 || secondPin?.length < 4) {
        this.showToast('Enter 4 digit pin', 'danger');
      }
    } catch (error) {
      SentryUtilites.setLog("PinVerificationComponent:matchPin", error)
    }
  }

  /**
   * 
   * @param msg - message which will show in toast
   * @param color - background color of toast
   * @description 
   * It is use to show toast at position top right corner
   */
  async showToast(msg: string, color: string) {
    try {
      const toast = await this.toastController?.create({
        header: msg,
        duration: 2000,
        position: 'top',
        mode: 'ios',
        color: color,
      });
      await toast.present();
    } catch (error) {
      SentryUtilites.setLog("PinVerificationComponent:showToast", error)
    }
  }
  // ------------------------------------------------------

}
