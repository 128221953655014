import { Directive, ElementRef, Input } from '@angular/core';
import { SentryUtilites } from '../utils/sentryUtilites';

@Directive({
  selector: '[appHeaderTitleCss]'
})
export class HeaderTitleCssDirective {
  @Input('appHeaderTitleCss') className: string;
  
  constructor(private element: ElementRef) { }

  ngOnChanges(): void {
    try {
      const style = document.createElement('style');
      if(this.className == 'mobile') {
        style.textContent = `
        .toolbar-title {
          display: flex !important;
          flex-direction: column !important;
        }`;
      } else {
        style.textContent = `
        .toolbar-title {
          display: block !important;
        }`;
      }
      this.element?.nativeElement?.shadowRoot?.appendChild(style);
    } catch (error) {
      SentryUtilites.setLog("HeaderTitleCssDirective:ngOnChanges", error)
    }
  }

}
