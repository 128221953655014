import { TransportDetail } from './TransportDetail.model';
import { BaseModel } from "./BaseModel.model";
import { BillItem } from "./BillItem.model";
import Party from "./Party.model";

export class IEstimate {

  static SCHEMA_NAME = 'estimate';

}

export class Estimate extends BaseModel {

  profileId: string;

  billNo: string;

  billDateStamp: number;

  dueDateStamp: number;

  billConvertedStamp?: number;

  billingTerm: string;

  party: Party;

  partySecondary?: Party;

  billItems: BillItem[];

  transportDetail: TransportDetail;

  deliveryProvience: string;

  senderProvience: string;

  note: string;

  cashDiscount: number;

  cashDiscountPercentage: number;

  additionalDiscount: number;

  // Calculation Keys

  discountAmount: number;

  gstAmount: number;

  cessAmount: number;

  subTotalAmount: number;

  totalAmount: number;

  totalSaving: number;

  roundOffValue: number;

  serviceChargePercentage: number;

  serviceChargeAmount: number;

}
