<ion-header>
  <ion-toolbar class="{{ getHeaderColorClass() }}">
    <app-navigation-control slot="start" type="sale"></app-navigation-control>
    <ion-title> Orders  <ion-chip> Count: {{ completeList?.length }} </ion-chip></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>

  <ion-grid>

    <app-date-range listType="sale" (dateRangeChange)="onDateRangeChange($event)"></app-date-range>

    <ion-row>
      <ion-col class="custom-col" size="12">
        <ion-segment mode="ios" [value]="selectedStatus" (ionChange)="onStatusFilterChange($event)">
          <ion-segment-button value="Placed">
            <ion-label>New Orders</ion-label>
          </ion-segment-button>
          <ion-segment-button value="Accepted">
            <ion-label>Accepted</ion-label>
          </ion-segment-button>
          <ion-segment-button value="Rejected">
            <ion-label>Rejected</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-col>
    </ion-row>

    <ion-row class="item-custom-row" *ngFor="let order of viewFilteredList">
  
      <ion-col size="8">
        <ion-button fill="clear" class="btn-item-name">
          {{ order?.party?.name }}
        </ion-button>
      </ion-col>

      <ion-col size="4" *ngIf="order?.status==OrderStatus.Placed">
        <ion-button size="small" color="danger flt-rght" (click)="reject(order)">Reject</ion-button>
        <ion-button size="small" color="success flt-rght" (click)="accept(order)">Accept</ion-button>
      </ion-col>

      <ion-col size="4" *ngIf="order?.status==OrderStatus.Rejected">
        <ion-button size="small" color="success flt-rght" (click)="accept(order)">Accept</ion-button>
      </ion-col>

      <ion-col size="4" *ngIf="order?.status==OrderStatus.Accepted">
        <ion-button *ngIf="!order?.linkedSaleUUID" size="small" color="danger flt-rght" (click)="reject(order)">Reject</ion-button>
        <ion-button *ngIf="!order?.linkedSaleUUID" size="small" color="tertiary flt-rght" (click)="toSale(order)">To Sale</ion-button>
        <ion-button *ngIf="order?.linkedSaleUUID" size="small" color="tertiary flt-rght" (click)="viewSale(order)">View Sale</ion-button>
      </ion-col>
  
      <ion-col size="12">
        <ion-button fill="clear" class="btn-stock txt-transform-normal">
          {{ order?.orderNo }} | {{ order?.createdStamp | date: 'dd/MM/yy' }}
        </ion-button>
      </ion-col>
  
     
    </ion-row>

    <ion-infinite-scroll
      threshold="10px" 
      *ngIf="viewFilteredList?.length"
      (ionInfinite)="loadMoreListData($event)">
      <ion-infinite-scroll-content
        loadingSpinner="bubbles"
        loadingText="Loading more data...">
      </ion-infinite-scroll-content>
    </ion-infinite-scroll>
  
  </ion-grid>

</ion-content>
