<ion-content *ngIf="isLoadingContent">
  <ion-list>
    <ion-item lines="none">
      <img alt="ezo" src="../../../assets/images/ezo.png" />
    </ion-item>
    <ng-container *ngFor="let type of collectionTypes|keyvalue : returnZero">
      <ion-item lines="none" *ngIf="type.value.activated">
        <ion-spinner *ngIf="type.value.activated && !type.value.loaded && !type.value.failed" color="primary"
          name="lines-sharp-small"></ion-spinner>
        <ion-icon *ngIf="type.value.loaded" color="success" name="checkmark-done-circle-outline"></ion-icon>
        <ion-icon *ngIf="type.value.failed" color="danger" name="close-circle-outline"></ion-icon>
        <ion-label *ngIf="type.value.activated">{{ type.key }} <span *ngIf="type?.value?.path">({{ type.value.count }})</span></ion-label>
      </ion-item>
    </ng-container>
  </ion-list>
</ion-content>

<ion-modal #ProfileModalSelectorEle [isOpen]="isProfileSelectorModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-title>Select Profile</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding profile-selector">

      <ion-grid>
        <ion-row>
          <ion-col size="12">
            <ion-item lines="none" class="custom-row" *ngFor="let profile of profileList"
              (click)="onProfileSelect(profile)">
              <ion-grid>
                <ion-row>
                  <ion-col size="11">
                    <span> {{ profile?.profileName || '' }} | {{ profile?.legalName || '' }} | ( ID: {{
                      profile?._localUUID?.substr(-4) }} ) </span>
                    <br>
                    <span class="grey-color"> Owner Phone: {{ profile?.contactPersonPhone }} </span>
                    <span class="span-access-from" *ngIf="profile?.userId && ownerUserId!=profile?.userId"> Access From
                      {{ profile?.userId }} </span>
                  </ion-col>
                  <ion-col size="1">
                    <div *ngIf="profile?.syncStamp > profile?.updatedStamp; then thenBlock else elseBlock"></div>
                    <ng-template #thenBlock>
                      <ion-icon class="icon-sync" name="checkmark-done-sharp" color="success"></ion-icon>
                    </ng-template>
                    <ng-template #elseBlock>
                      <ion-icon class="icon-sync" name="warning-sharp" color="warning"></ion-icon>
                    </ng-template>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-item>
          </ion-col>
        </ion-row>
      </ion-grid>

    </ion-content>
  </ng-template>
</ion-modal>