import { PartyRestaurantListComponent, RunningBillHashMap } from './../party-restaurant-list/party-restaurant-list.component';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AlertController, IonSearchbar } from '@ionic/angular';
import Party from '../../models/Party.model';
import { AllDataService } from '../../services/all-data.service';
import { Validator } from '../../utils/validator';
import { Utility } from '../../utils/utility';
import { AccessControlService } from '../../services/auth/access-control.service';
import { AuthService } from '../../services/auth/auth.service';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-party-restaurant-selector',
  templateUrl: './party-restaurant-selector.component.html',
  styleUrls: ['./party-restaurant-selector.component.scss'],
})
export class PartyRestaurantSelectorComponent implements OnInit {

  @Input() defaultCashParty = false;
  @Input() noPartySelectError = false;
  @Input() isSale = false;

  @Output() partySelectedEvent = new EventEmitter<Party>();
  @Output() runningBillHashMap = new EventEmitter<RunningBillHashMap>();

  @ViewChild('partyRestaurantList') partyRestaurantList: PartyRestaurantListComponent;
  @ViewChild('searchBar') searchBar: IonSearchbar;

  getHeaderColorClass = Utility.getHeaderColorClass;
  
  isPartySelected = false;
  selectedParty: Party = null;
  isModalOpen = false;
  isEditable = true;
  isMaskPhone: boolean = false;

  MATHABS = Math.abs;

  shortStr = Utility.shortStr;
  stringMask = Utility.stringMask;

  previouslyCashSaleAutoSelected = false;     

  constructor(
    private alertController: AlertController,
    private allDataService: AllDataService,
    private accessControlService: AccessControlService,
    private authService: AuthService,
  ) { }

  ngOnInit() { }

  ngAfterViewInit() { }

  ngDestory() { }

  async openPartySelectorModal(isOpen: boolean = true) {
    try {
      this.checkMaskPhone();
      if (this.isEditable) {
        this.isModalOpen = isOpen;
        if(isOpen) {
          setTimeout(() => {
            this.searchBar?.setFocus();
          }, 2000);
        }
      }
    } catch (error) {
      SentryUtilites.setLog("PartyRestaurantSelectorComponent:openPartySelectorModal", error)
    }
  }

  onPartySelect(party: Party) {
    try {
      this.previouslyCashSaleAutoSelected = true;
      this.isPartySelected = true;
      this.selectedParty = party;
      this.partySelectedEvent.emit(party);
      this.openPartySelectorModal(false);
    } catch (error) {
      SentryUtilites.setLog("PartyRestaurantSelectorComponent:onPartySelect", error)
    }
  }

  partyRunningBillHashMap(runningBillHashMap: RunningBillHashMap) {
    this.runningBillHashMap.emit(runningBillHashMap);
  }

  enable() {
    this.isEditable = true;
  }

  disable() {
    this.isEditable = false;
  }

  getParty() {
    return this.selectedParty;
  }

  resetParty() {
    try {
      this.selectedParty = null;
      this.isPartySelected = false;
      this.previouslyCashSaleAutoSelected = false;
    } catch (error) {
      SentryUtilites.setLog("PartyRestaurantSelectorComponent:resetParty", error)
    }
  }

  setSelectedParty(party: Party) {
    this.selectedParty = party;
    this.checkMaskPhone();
  }

  async checkMaskPhone() {
    try {
      let phone = Utility.getFromLocalStorage('selectedProfileUserId');
      let isOwner = phone === this.authService.getLoginPhone();
      this.isMaskPhone = !isOwner && await this.accessControlService.isUserHasAccess({action: 'hidePhone'});
    } catch (error) {
      SentryUtilites.setLog("PartyRestaurantSelectorComponent:checkMaskPhone", error)
    }
  }

  async presentAddPartyAlert(value?: string) {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({action:'createParty'});
      if(!isPermit) {
        return alert("Permission: You don't have permission to create party. Please contact to your owner.");
      }
      let phone = null;
      let name = '';
      if (value) {
        if (value?.length == 10 && isNaN(Number(value)) == false) {
          phone = Number(value);
        } else {
          name = value;
        }
      }
      const alertControl = await this.alertController.create({
        header: 'Party Details',
        buttons: [
          {
            text: 'Save',
            handler: async (value) => {
              if(value?.partyName && !value?.partyName?.match(Validator.noSpaceAtStart) || !value?.partyName?.trim()) {
                Utility.changeBorderColor('party-name', false);
                return false;
              }
              Utility.changeBorderColor('party-name', true);
              if(value?.partyPhone) {
                if(!value?.partyPhone?.match(Validator.phone)) {
                  Utility.changeBorderColor('party-phone', false);
                  return false;
                }
                Utility.changeBorderColor('party-phone', true);
              }
              let result = await this.saveParty(value);
              return result ? true : false;
            },
          }
        ],
        mode: 'ios',
        inputs: [
          {
            type: 'text',
            placeholder: 'Customer / Supplier Name',
            name: 'partyName',
            value: name || '',
            cssClass: 'party-name',
          },
          {
            type: 'number',
            placeholder: 'Phone Number',
            name: 'partyPhone',
            value: phone || '',
            attributes: {
              pattern: Validator.phone,
            },
            cssClass: 'party-phone',
          },
        ],
      });
  
      await alertControl.present();
    } catch (error) {
      SentryUtilites.setLog("PartyRestaurantSelectorComponent:presentAddPartyAlert", error)
    }
  }

  async saveParty(value: { partyName: string, partyPhone: number }) {
    try {
      if(value?.partyName) {
        let party = new Party();
        party.name = value.partyName;
        party.phone = value.partyPhone.toString();
        let result = await this.allDataService.partyService.save(party);
        result && this.onPartySelect(party);
        this.allDataService.listForceReloadSubs.next('party-list');
        return result;
      }else {
        return null;
      }
    } catch (error) {
      SentryUtilites.setLog("PartyRestaurantSelectorComponent:saveParty", error)
      return null;
    }
  }

  onFilterChange(value: string) {
    this.partyRestaurantList?.onFilterChange(value);
  }

}
