import { BehaviorSubject } from "rxjs";
import { Utility } from "../utils/utility";
import { ExpressServerService } from "./api/express-server.service";
import { IDataService } from "../../interface/IDataService.interface";
import { Image } from "../models/image.model";
import { ImageDao } from "./dao/image.dao";
import { AuthService } from "./auth/auth.service";
import { environment } from "../../environments/environment";
import { SentryUtilites } from "../utils/sentryUtilites";

export class ImageService implements IDataService<Image>{
  private static _instance: ImageService;

  public static getInstance(dao: ImageDao, expressServerService: ExpressServerService, authService: AuthService) {
    if (!this._instance) {
      this._instance = new ImageService(dao, expressServerService, authService)
      this._instance.initService();
    }
    this._instance.reloadList();
    return this._instance;
  }

  constructor(dao: ImageDao, expressServerService: ExpressServerService, authService: AuthService) {
    this.dao = dao;
    this.expressServerService = expressServerService;
    this.authService = authService;
  }
  dao: ImageDao;
  expressServerService: ExpressServerService;
  authService: AuthService;

  LIST_REFRESH_RATE = 1000;
  selectedProfileId: string = null;
  selectedProfileUserId: string = null;
  updateSubs = new BehaviorSubject<Image>(null);


  lastReloadStamp: number = 0;
  isReloadPostpond = false;

  initService() {
    this.selectedProfileId = Utility.getFromLocalStorage('selectedProfile');
    this.selectedProfileUserId = Utility.getFromLocalStorage('selectedProfileUserId');
    this.reloadList();
  }

  async reloadList() {
    try {
      if (this.isReloadPostpond) {
        return;
      }
      const currentStamp = +new Date();
      if (this.lastReloadStamp < (currentStamp - this.LIST_REFRESH_RATE)) {
        this.lastReloadStamp = currentStamp;
        this.trySyncUnsynced();
      } else {
        this.isReloadPostpond = true;
        setTimeout(() => {
          this.isReloadPostpond = false;
          this.reloadList();
        }, this.LIST_REFRESH_RATE + 100);
      }
    } catch (error) {
      SentryUtilites.setLog("ImageService:reloadList", error)
      return null;
    }
  }

  getAllByPromise() {
    return this.dao.getAllByProfile(this.selectedProfileId);
  }

  getAllByPromiseByProfile(profileId: string) {
    return this.dao.getAllByProfile(profileId);
  }

  async getById(id: number): Promise<Image> {
    try {
      let image = await this.dao.getById(id);
      if(image != null) {
        image.imageBase64 = 'data:image/png;base64,' + image?.imageBase64;
      }
      return image;
    } catch (error) {
      SentryUtilites.setLog("ImageService:getById", error)
      return null;
    }
  }

  async getByUUID(uuid: string): Promise<Image> {
    try {
      let image = await this.dao.getByUUID(uuid);
      if(image != null) {
        image.imageBase64 = 'data:image/png;base64,' + image?.imageBase64;
        return image;
      }
      return null;
    } catch (error) {
      SentryUtilites.setLog("ImageService:getByUUID", error)
      return null;
    }
  }

  save(image: Image): Promise<Image> {
    if(Utility.isTruthy(image)) {
      image.userId = this.selectedProfileUserId;
      if(!image?.profileId) {
        image.profileId = this.selectedProfileId;
      }
      image.createdBy = image.lastModifiedBy = this.authService.getLoginPhone();
      image.createdByName = image.lastModifiedByName = Utility.getCreatedByName();
      
      return new Promise(async (resolve, reject) => {
        try {
          let savedImage = await this.dao.save(image);
          this.reloadList();
          return resolve(savedImage);
        } catch (error) {
          SentryUtilites.setLog("ImageService:save", error)
          return resolve(null);
        }
      });
    } else {
      return null;
    }
  }

  update(image: Image): Promise<Image> {
    return new Promise(async (resolve, reject) => {
      try {
        if(image?._localUUID) {
          image.lastModifiedBy = this.authService.getLoginPhone();
          image.lastModifiedByName = Utility.getCreatedByName();
          let updatedImage = await this.dao.update(image);
          this.reloadList();
          this.updateSubs.next(updatedImage);
          return resolve(updatedImage);
        }
        return resolve(null);
      } catch (error) {
        SentryUtilites.setLog("ImageService:update", error)
        return resolve(null);
      }
    });
  }

  updateDb(image: Image): Promise<Image> {
    return new Promise(async (resolve, reject) => {
      try {
        let updatedImage = await this.dao.updateDb(image);
        this.reloadList();
        this.updateSubs.next(updatedImage);
        return resolve(updatedImage); 
      } catch (error) {
        SentryUtilites.setLog("ImageService:updateDb", error)
        return resolve(null);
      }
    });
  }

  delete(image: Image): Promise<Image> {
    return new Promise(async (resolve, reject) => {
      try {
        if(image?._localUUID) {
          image.lastModifiedBy = this.authService.getLoginPhone();
          image.lastModifiedByName = Utility.getCreatedByName();
          let deletedImage = await this.dao.delete(image);
          this.reloadList();
          this.updateSubs.next(deletedImage);
          return resolve(deletedImage);
        }
        return resolve(null);
      } catch (error) {
        SentryUtilites.setLog("ImageService:delete", error)
        return resolve(null);
      }
    });
  }

  updateSyncStamp(el: Image): Promise<Image> {
    return new Promise(async (resolve, reject) => {
      try {
        let updatedEl = await this.dao.updateSyncStamp(el);
        this.updateSubs.next(updatedEl);
        return resolve(updatedEl);
      } catch (error) {
        SentryUtilites.setLog("ImageService:updateSyncStamp", error)
        return resolve(null);
      }
    });
  }

  isSyncLock = false;
  isSyncPostPond = false;
  async trySyncUnsynced(postpond?: boolean) {
    try {
      if (this.isSyncLock) {
        if (!this.isSyncPostPond) {
          setTimeout(() => {
            this.isSyncPostPond = true;
            this.trySyncUnsynced(true);
          }, 200);
        }
        return true;
      }
      if (postpond) {
        this.isSyncPostPond = false;
      }
      this.isSyncLock = true;
      let unSyncedElements: Image[] = await this.dao.getAllUnsynced(this.selectedProfileId);
      if (unSyncedElements && unSyncedElements?.length) {
        try {
  
          for (let i = 0; i < unSyncedElements?.length; i++) {
            let unSyncedElement = unSyncedElements[i];
            if(unSyncedElement?._localUUID) {
              unSyncedElements[i]['updatedStamp'] = +new Date();
            }
          }
  
          await this.dao.bulkPut(unSyncedElements);
          await Utility.wait(1000);
          
          let chunkArr = Utility.getChunkArr(unSyncedElements);
          let chunkArrLength = chunkArr?.length;
  
          for(let i = 0; i < chunkArrLength; i++) {
            let result = await this.expressServerService.makeSyncCall('image', chunkArr[i]);
            if (result && result?.['records']?.length) {
              let arr = result?.['records'];
              for (let i = 0; i < arr?.length; i++) {
                const el = arr[i];
                await this.updateSyncStamp(el);
              }
            }
          }
        } catch (err) {
          SentryUtilites.setLog("ImageService:trySyncUnsynced:inner", err)
        }
      }
      this.isSyncLock = false;
    } catch (error) {
      SentryUtilites.setLog("ImageService:trySyncUnsynced", error)
    }
  }

  isBroadCastLock = false;
  isBroadCastPostPond = false;

  async handleBroadcast(savedArray: Image[], postpond?: boolean) {


    if (this.isBroadCastLock) {
      if (!this.isBroadCastPostPond) {
        setTimeout(() => {
          this.isBroadCastPostPond = true;
          this.handleBroadcast(savedArray, true);
        }, 200);
      }
      return true;
    }
    if (postpond) {
      this.isBroadCastPostPond = false;
    }
    this.isBroadCastLock = true;


    for (let i = 0; i < savedArray?.length; i++) {
      try {
        let obj = await this.getByUUID(savedArray[i]?._localUUID);
        if (obj && obj?.syncStamp == savedArray[i]?.syncStamp) {
          continue;
        }
        if (!obj) {
          await this.dao.saveDb(savedArray[i])
        } else if (obj?.syncStamp < savedArray[i]?.syncStamp) {
          await this.dao.updateDb(savedArray[i])
        }
      } catch (err) {
        SentryUtilites.setLog("ImageService:handleBroadcast", err)
        return null;
      }
    }

    this.reloadList();
    for (let i = 0; i < savedArray?.length; i++) {
      try {
        let obj = await this.getByUUID(savedArray[i]?._localUUID);
        this.updateSubs.next(obj);

      } catch (err) {
        SentryUtilites.setLog("ImageService:handleBroadcast", err)
        return null;
      }
    }

    this.isSyncLock = false;

  }

  async getAll() {
    return await this.dao.getAll()
  }

  copyData(fromProfileId: string, toProfileId: string): Promise<boolean> {
    return new Promise(async (resolve,reject) => {
      try {
        if(Utility.isTruthy(fromProfileId) && Utility.isTruthy(toProfileId)) {
          let fromRecords = await this.getAllByPromiseByProfile(fromProfileId);
          if(fromRecords?.length) {
            let toRecords: Image[] = [];
            for (let i = 0; i < fromRecords?.length; i++) {
              const fetchedRecord = fromRecords[i];
              if(fetchedRecord?._localUUID) {
                fetchedRecord.profileId = toProfileId;
                delete fetchedRecord?._localId;
                delete fetchedRecord?._localUUID;
                let savedRecord = await this.save(fetchedRecord);
                if(savedRecord?._localUUID) {
                  toRecords?.push(savedRecord);
                }
              }
            }
            if(fromRecords?.length === toRecords?.length) {
              return resolve(true);
            }
          }
        }
        return resolve(false);
      } catch (error) {
        SentryUtilites.setLog("ImageService:copyData", error)
        return resolve(false);
      }
    });
  }

}
