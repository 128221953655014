import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Utility } from '../../utils/utility';
import { Ingredient } from '../../models/Ingredient.model';
import { AllDataService } from '../../services/all-data.service';
import { AlertController, InfiniteScrollCustomEvent, IonFab, LoadingController, ToastController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { IngredientStockAdjustComponent } from '../../components/ingredient-stock-adjust/ingredient-stock-adjust.component';
import { IngredientService } from '../../services/ingredient.service';
import { IngredientStockAdjustService } from '../../services/ingredient-stock-adjust.service';
import { IngredientStockAdjust } from '../../models/IngredientStockAdjust.model';
import { CommonService } from '../../services/common.service';
import { AccessControlService } from '../../services/auth/access-control.service';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-ingredient',
  templateUrl: './ingredient.page.html',
  styleUrls: ['./ingredient.page.scss'],
})
export class IngredientPage implements OnInit {

  @Input() searchText = '';
  
  @ViewChild('searchBar') searchBar;
  @ViewChild('ingredientStockAdjustEle') ingredientStockAdjustEle: IngredientStockAdjustComponent;
  @ViewChild('filterByFab') filterByFab: IonFab;
  @ViewChild('sortByFab') sortByFab: IonFab;

  getHeaderColorClass = Utility.getHeaderColorClass;

  filteredList: Ingredient[] = [];
  completeList: Ingredient[] = [];
  viewFilteredList: Ingredient[] = [];

  loading: HTMLIonLoadingElement = null;

  appliedCategory = '';
  selectedFilter = '';

  isFilterOptionOpen = false;

  categories: string[] = [];
  categoryWiseIngredientCount = {};

  isOpenImageCropper = false;

  imageUploadItemUUID: string = null;

  subsArr: Subscription[] = [];

  isActionBtnsEnabled: boolean = false;
  selectedIngredient: Ingredient = null;

  shortStr = Utility.shortStr;

  returnZero = Utility.returnZero;
  returnReverse = () => -1;

  innerHeight: string = '';
  isMobile: boolean = null;

  sortObj = {
    "SortByNameAsc": "A to Z",
    "SortByNameDesc": "Z to A",
    "SortByStockAsc": "Stock Ascending",
    "SortByStockDesc": "Stock Descending",
    "lowStockByAsc": "Low Stock By Ascending",
  };

  constructor(
    private allDataService: AllDataService,
    private router: Router,
    private ingredientService: IngredientService,
    private ingredientStockAdjustService: IngredientStockAdjustService,
    private commonService: CommonService,
    private loadingCtrl: LoadingController,
    private alertController: AlertController,
    private toastController: ToastController,
    private accessControlService: AccessControlService,
  ) { }

  async ngOnInit() {
    try {
      this.isMobile = this.commonService.isMobile();
      await this.showLoading('Loading Raw Material list...', this.isMobile ? 'mobile-raw-material-loader' : 'raw-material-loader');
      this.innerHeight = `${(((window?.innerHeight - 210)/window?.innerHeight) * 100)}%`;
      this.routerSubscription();
      this.loadView();
    } catch (error) {
      SentryUtilites.setLog("IngredientPage:ngOnInit", error)
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.searchBar.setFocus();
    }, 500);
  }

  ionViewWillLeave() {
    this.loading?.dismiss();
  }

  async onNewIngredientClick() {
    // let isPermit = await this.accessControlService.isUserHasAccess({action:'createItem'});
    // if(!isPermit) {
    //   return alert("Permission: You don't have permission to create new ingredient. Please contact to your owner.");
    // }
    this.router.navigate([`ingredient/form`]);
  }
   

  routerSubscription() {
    try {
      this.subsArr.push(this.router.events.subscribe((event: RouterEvent) => {
        if(event instanceof NavigationEnd) {
          event?.url==='/ingredient' && this.loadView();
        }
      }));
    } catch (error) {
      SentryUtilites.setLog("IngredientPage:routerSubscription", error)
    }
  }

  async getCategories() {
    try {
      let profile = await this.allDataService.profileService.getCurrentProfile();
      if(profile?.ingredientCategories?.length && Array.isArray(profile?.ingredientCategories)) {
        this.categories = profile?.ingredientCategories;
        this.categories?.sort();
        this.categoryWiseIngredientCount = {};
        this.categories?.forEach(category => {
          this.categoryWiseIngredientCount[category] = this.completeList?.filter(ingredient => ingredient?.category?.toLowerCase()==category?.toLowerCase())?.length;
        });
      }
    } catch (error) {
      SentryUtilites.setLog("IngredientPage:getCategories", error)
    }
  }

  closeclickOutSideSort() {
    this.sortByFab.close()
  }

  closeclickOutSideFilter() {
    this.filterByFab.close()
  }

  async loadView() {
    this.filteredList = [];
    this.completeList = [];
    this.viewFilteredList = [];

    this.appliedCategory = '';
    this.selectedFilter = 'SortByNameAsc';

    this.isFilterOptionOpen = false;

    this.categories = [];

    try {
      this.completeList = await this.ingredientService.getAllWithOpeningStockByProfile() || [];
    } catch (error) {
      this.loading?.dismiss();
      await this.showToast('Something went wrong, Please try again later.', 'danger');
      SentryUtilites.setLog("IngredientPage:loadView", error)
    }

    this.onFilterChange();
    this.getCategories();

  }

  toggleFilterOptions() {
    this.isFilterOptionOpen = !this.isFilterOptionOpen;
  }

  //TODOI
  async openStockAdjustModal(ingredient: Ingredient) {
    try {
      // let isPermit = await this.accessControlService.isUserHasAccess({action:'adjustStock'});
      // if(!isPermit) {
      //   return alert("Permission: You don't have permission to delete adjust stock. Please contact to your owner.");
      // }
      if (ingredient?._localUUID) {
        this.ingredientStockAdjustEle?.setIngredient(ingredient);
        this.ingredientStockAdjustEle?.openStockAdjustModal();
      }
    } catch (error) {
      SentryUtilites.setLog("IngredientPage:openStockAdjustModal", error)
    }
  }

  onCategoryChange(category: string) {
    this.appliedCategory = category;
    this.onFilterChange();
  }

  onSortChnage(selectedFilter: string) {
    this.selectedFilter = selectedFilter;
    this.onFilterChange();
  }

  isFilterPostpond = false;
  lastFilterStamp = 0;
  onFilterChange(searchKey?: string) {
    try {
      if (this.isFilterPostpond) {
        return true;
      }
      if (this.lastFilterStamp < +new Date() - 200) {
        this.lastFilterStamp = +new Date();
        return this.applyFilter({
          searchKeyword: searchKey,
          selectedFilter: this.selectedFilter,
          category: this.appliedCategory
        })
      } else {
        this.isFilterPostpond = true;
        setTimeout(() => {
          this.isFilterPostpond = false;
          this.onFilterChange(searchKey);
        }, 250);
      }
    } catch (error) {
      SentryUtilites.setLog("IngredientPage:onFilterChange", error)
    }
  }

  lastSearchKey = '';
  async applyFilter(params: {
    searchKeyword?: string,
    selectedFilter?: string,
    category?: string
  }) {
    try {
      let arr: Ingredient[] = [];
      let lsearchKey = '';
      let isSearchIncremental = false;
      let isSearchCleared = false;
      if (params?.searchKeyword?.length) {
        lsearchKey = Utility.toLowerCase(params.searchKeyword);
        if (lsearchKey.indexOf(this.lastSearchKey) == 0) {
          // isSearchIncremental = true;
          // TODO
          isSearchIncremental = false;
        }
        this.lastSearchKey = lsearchKey;
      }
      if (this.lastSearchKey.length > 0 && lsearchKey.length == 0) {
        isSearchCleared = true;
        this.lastSearchKey = '';
      }
      let completeList = [];
      if (isSearchIncremental) {
        completeList = [...this.filteredList]
      } else {
        completeList = [...this.completeList];
      }
  
      if (params?.category) {
        completeList?.forEach((el) => {
          if (el?.category == params?.category) {
            arr.push(el);
          }
        });
        arr = arr.filter((el) => {
          if (Utility.toLowerCase(el?.name).indexOf(lsearchKey) != -1) {
            return el;
          }
        });
      } else if (params?.category) {
        completeList?.forEach((el) => {
          if (el?.category == params?.category) {
            arr.push(el);
          }
        });
      } else if (lsearchKey.length) {
        completeList?.forEach((el) => {
          if (Utility.toLowerCase(el?.name).indexOf(lsearchKey) != -1) {
            arr.push(el);
          }
        });
  
      } else {
        arr = [...completeList]
      }
  
  
      if (params.selectedFilter == 'SortByNameAsc') {
        arr.sort((a: Ingredient, b: Ingredient) => {
          if (a?.name?.toLowerCase() == b?.name?.toLowerCase()) {
            return a?.createdStamp - b?.createdStamp;
          }
          if (a?.name?.toLowerCase() > b?.name?.toLowerCase()) {
            return 1;
          }
          return -1;
        })
      } else if (params.selectedFilter == 'SortByNameDesc') {
        arr.sort((a: Ingredient, b: Ingredient) => {
          if (a?.name?.toLowerCase() == b?.name?.toLowerCase()) {
            return a?.createdStamp - b?.createdStamp;
          }
          if (b?.name?.toLowerCase() > a?.name?.toLowerCase()) {
            return 1;
          }
          return -1;
        })
      } else if (params.selectedFilter == 'SortByStockAsc') {
        arr.sort((a: Ingredient, b: Ingredient) => a?.stock-b?.stock);
      } else if (params.selectedFilter == 'SortByStockDesc') {
        arr.sort((a: Ingredient, b: Ingredient) => b?.stock-a?.stock);
      } else if (params.selectedFilter == 'lowStockByAsc') {
        arr = arr.filter(item => {
          const stock = Number(item?.stock);
          let minStock = Number(item?.minStock);
          minStock = minStock || 0;
          if (
            Utility.isNumber(stock)
            && Utility.isNumber(minStock)
            && stock <= minStock
          ) {
            return true;
          }else {
            return false;
          }
        });
        arr.sort((a: Ingredient, b: Ingredient) => {
          if (a?.name?.toLowerCase() == b?.name?.toLowerCase()) {
            return a?.createdStamp - b?.createdStamp;
          }
          if (b?.name?.toLowerCase() > a?.name?.toLowerCase()) {
            return 1;
          }
          return -1;
        })
      }
  
      this.filteredList = [...arr];
  
      if (lsearchKey.length || params.category || params.selectedFilter || isSearchCleared) {
        this.viewFilteredList = [...this.filteredList.slice(0, 50)];
      } else if (!this.viewFilteredList.length) {
        this.viewFilteredList = [...this.filteredList.slice(0, 50)];
      }
      this.loading?.dismiss();
      return true;
    } catch (error) {
      SentryUtilites.setLog("IngredientPage:applyFilter", error)
      return false;
    }
  }


  loadMoreListData(event) {
    try {
      if (this.viewFilteredList.length > 0 && this.viewFilteredList.length <= this.filteredList.length) {
        let appendListLength = this.filteredList.length - this.viewFilteredList.length;
        let lastEl = this.viewFilteredList[this.viewFilteredList.length - 1];
        let counter = 0;
        for (let i = 0; i < this.filteredList.length; i++) {
          if (this.filteredList[i]._localUUID == lastEl._localUUID) {
            counter = 1;
            continue;
          }
          if (counter > 0 && appendListLength >= 50) {
            if (counter <= 50) {
              this.viewFilteredList.push({ ...this.filteredList[i] })
            } else {
              break;
            }
            counter++;
          } else if(counter > 0 && appendListLength < 50) {
            if (counter <= appendListLength) {
              this.viewFilteredList.push({ ...this.filteredList[i] })
            } else {
              break;
            }
            counter++;
          }
        }
        (event as InfiniteScrollCustomEvent).target.complete();
      }
    } catch (error) {
      SentryUtilites.setLog("IngredientPage:loadMoreListData", error)
    }
  }

  async emitElement() {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({action:'createRawMaterial'});
      if(!isPermit) {
        return alert("Permission: You don't have permission to create new raw material. Please contact to your owner.");
      }
      this.disableActionBtns();
      this.router.navigate([`ingredient/form`]);
    } catch (error) {
      SentryUtilites.setLog("IngredientPage:emitElement", error)
    }
  }

  autoSearch(value: string) {
    try {
      if (value && this.searchBar?.el) {
        this.searchBar.el.value = value;
      }
    } catch (error) {
      SentryUtilites.setLog("IngredientPage:autoSearch", error)
    }
  }

  resetCategory() {
    this.appliedCategory = '';
    this.toggleFilterOptions();
    this.onFilterChange();
  }

  resetSort() {
    this.selectedFilter = '';
    this.toggleFilterOptions();
    this.onFilterChange();
  }

  ngOnDestroy() {
    this.subsArr?.forEach((el) => {
      el.unsubscribe();
    })
  }

  enableActionBtns(ingredient: Ingredient) {
    this.isActionBtnsEnabled = true;
    this.selectedIngredient = ingredient;
  }

  disableActionBtns() {
    this.isActionBtnsEnabled = false;
  }

  async navigateAction(action: 'purchase' | 'scrap' | 'transfer' | 'return' | 'eod') {
    try {
      if(action === 'purchase') {
        let isPermit = await this.accessControlService.isUserHasAccess({action:'createRawMaterialPurchaseEntry'});
        if(!isPermit) {
          return alert("Permission: You don't have permission to create purchase entry of raw material. Please contact to your owner.");
        }
      } else if(action === 'scrap') {
        let isPermit = await this.accessControlService.isUserHasAccess({action:'createRawMaterialScrapEntry'});
        if(!isPermit) {
          return alert("Permission: You don't have permission to create scrap entry of raw material. Please contact to your owner.");
        }
      } else if(action === 'transfer') {
        let isPermit = await this.accessControlService.isUserHasAccess({action:'createRawMaterialTransferEntry'});
        if(!isPermit) {
          return alert("Permission: You don't have permission to create transfer entry of raw material. Please contact to your owner.");
        }
      } else if(action === 'return') {
        let isPermit = await this.accessControlService.isUserHasAccess({action:'createRawMaterialReturnEntry'});
        if(!isPermit) {
          return alert("Permission: You don't have permission to create return entry of raw material. Please contact to your owner.");
        }
      } else if(action === 'eod') {
        let isPermit = await this.accessControlService.isUserHasAccess({action:'createRawMaterialEODEntry'});
        if(!isPermit) {
          return alert("Permission: You don't have permission to create / update EOD entry of raw material. Please contact to your owner.");
        }
      }
      this.disableActionBtns();
      this.router.navigate([`ingredient/edit/${action}/${this.selectedIngredient?._localUUID || ''}`]);
    } catch (error) {
      SentryUtilites.setLog("IngredientPage:navigateAction", error)
    }
  }

  navigateToTransaction(uuid: string) {
    this.router.navigate([`ingredient/transaction/${uuid}`]);
  }

  async getLiveStock(localUUID: string) {
    try {
      let res = await this.ingredientStockAdjustService.getTransactions(localUUID);
      let ingredient = res?.ingredient || null;
      let transactionsList = res?.ingredientStockAdjusts || [];
      let index = this.completeList.findIndex(x => x?._localUUID == localUUID);
      if (index != -1) {
        this.completeList.splice(index, 1, ingredient);
      }
      index = this.viewFilteredList.findIndex(x => x?._localUUID == localUUID);
      if (index != -1) {
        this.viewFilteredList.splice(index, 1, ingredient);
      }
    } catch (error) {
      await this.showToast('Something went wrong, Please try again later.', 'danger');
      SentryUtilites.setLog("IngredientPage:getLiveStock", error)
    }
  }

  async showLoading(msg: string, css: string) {
    try {
      this.loading = await this.loadingCtrl.create({ 
        message: msg,
        cssClass: css
      });
      this.loading?.present();
    } catch (error) {
      SentryUtilites.setLog("IngredientPage:showLoading", error)
    }
  }

  async editRawMaterial(uuid: string) {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({action:'editRawMaterial'});
      if(!isPermit) {
        return alert("Permission: You don't have permission to edit raw material. Please contact to your owner.");
      }
      this.router.navigate([`ingredient/form/${uuid}`]);
    } catch (error) {
      SentryUtilites.setLog("IngredientPage:editRawMaterial", error)
      alert("Something went wrong.");
    }
  }

  async deleteRawMaterial(ingredient: Ingredient) {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({action:'deleteRawMaterial'});
      if(!isPermit) {
        return alert("Permission: You don't have permission to delete raw material. Please contact to your owner.");
      }
      const alertcont = await this.alertController.create({
        mode: 'ios',
        header: 'Please Confirm!',
        message: 'Are you sure want to delete?',
        buttons: [
          {
            text: 'No',
            role: 'cancel',
            handler: () => {
              return false;
            },
          },
          {
            text: 'Yes',
            role: 'confirm',
            handler: async () => {
              ingredient.deletedStamp = +new Date();
              try {
                let record: Ingredient = await this.ingredientService.save(ingredient);
                if(record?._localUUID) {
                  await this.allDataService.itemService.updateItemOnIngredientUpdate(ingredient);
                  this.loadView();
                }
              } catch (error) {
                await this.showToast('Something went wrong, Please try again later.', 'danger');
                SentryUtilites.setLog("IngredientPage:deleteRawMaterial:inner", error)
              }
              return true;
            },
          },
        ],
      });
      await alertcont.present();
    } catch (error) {
      SentryUtilites.setLog("IngredientPage:deleteRawMaterial", error)
      alert("Something went wrong.");
    }
  }

  

  @HostListener('window:resize', ['$event'])
  OnResize() {
    try {
      setTimeout(() => {
        this.isMobile = this.commonService.isMobile();
        this.innerHeight = `${(((window.innerHeight - 210)/window.innerHeight) * 100)}%`;
      }, 50)
    } catch (error) {
      SentryUtilites.setLog("IngredientPage:OnResize", error)
    }
  }

  async showToast(msg: string, toastColor: string) {
    try {
      const toast = await this.toastController.create({
        message: msg,
        duration: 2000,
        color: toastColor,
      });
      await toast.present();
    } catch (error) {
      SentryUtilites.setLog("IngredientPage:showToast", error)
    }
  }

}
