import { MonthWiseItemStockService } from './month-wise-item-stock.service';
import { BehaviorSubject } from "rxjs";
import { Item } from "../models/Item.model";
import { Utility } from "../utils/utility";
import { ExpressServerService } from "./api/express-server.service";
import { IDataService } from "../../interface/IDataService.interface";
import { ItemDao } from "./dao/item.dao";
import { AuthService } from "./auth/auth.service";
import { Ingredient } from "../models/Ingredient.model";
import { SentryUtilites } from '../utils/sentryUtilites';

export class ItemService implements IDataService<Item>{
  private static _instance: ItemService;

  public static getInstance(
    dao: ItemDao, 
    expressServerService: ExpressServerService, 
    authService: AuthService,
    monthWiseItemStockService: MonthWiseItemStockService,
    ) {
    if (!this._instance) {
      this._instance = new ItemService(
        dao, 
        expressServerService,
        authService,
        monthWiseItemStockService,
        )
      this._instance.initService();
    }
    this._instance.reloadList();
    return this._instance;
  }

  constructor(
    dao: ItemDao, 
    expressServerService: ExpressServerService, 
    authService: AuthService,
    monthWiseItemStockService: MonthWiseItemStockService,
    ) {
    this.dao = dao;
    this.expressServerService = expressServerService;
    this.authService = authService;
    this.monthWiseItemStockService = monthWiseItemStockService;
  }
  dao: ItemDao;
  expressServerService: ExpressServerService;
  authService: AuthService;
  monthWiseItemStockService: MonthWiseItemStockService;

  LIST_REFRESH_RATE = 1000;
  selectedProfileId: string = null;
  selectedProfileUserId: string = null;
  updateSubs = new BehaviorSubject<Item>(null);


  lastReloadStamp: number = 0;
  isReloadPostpond = false;

  initService() {
    this.selectedProfileId = Utility.getFromLocalStorage('selectedProfile');
    this.selectedProfileUserId = Utility.getFromLocalStorage('selectedProfileUserId');
    this.reloadList();
  }

  async reloadList() {
    try {
      if (this.isReloadPostpond) {
        return;
      }
      const currentStamp = +new Date();
      if (this.lastReloadStamp < (currentStamp - this.LIST_REFRESH_RATE)) {
        this.lastReloadStamp = currentStamp;
        this.trySyncUnsynced();
      } else {
        this.isReloadPostpond = true;
        setTimeout(() => {
          this.isReloadPostpond = false;
          this.reloadList();
        }, this.LIST_REFRESH_RATE + 100);
      }
    } catch (error) {
      SentryUtilites.setLog("ItemService:reloadList", error)
      return null;
    }
  }

  getAll() {
    return this.dao.getAll();
  }
  
  getAllByPromise() {
    return this.dao.getAllByProfile(this.selectedProfileId);
  }

  getAllByPromiseByProfile(profileId: string) {
    return this.dao.getAllByProfile(profileId);
  }

  getById(id: number): Promise<Item> {
    return this.dao.getById(id);
  }

  getByUUID(uuid: string): Promise<Item> {
    return this.dao.getByUUID(uuid);
  }


  save(item: Item): Promise<Item> {
    if(Utility.isTruthy(item)) {
      item.userId = this.selectedProfileUserId;
      if(!item.profileId) {
        item.profileId = this.selectedProfileId;
      }
      item.createdBy = item.lastModifiedBy = this.authService.getLoginPhone();
      item.createdByName = item.lastModifiedByName = Utility.getCreatedByName();
  
      return new Promise(async (resolve, reject) => {
        try {
          let savedItem = await this.dao.save(item);
    
          if(savedItem?._localUUID) {
            await this.monthWiseItemStockService.save(savedItem?._localUUID);
          }
    
          this.reloadList();
          return resolve(savedItem);
        } catch (error) {
          SentryUtilites.setLog("ItemService:save", error)
          return resolve(null);
        }
      });
    } else {
      return null;
    }
  }

  update(item: Item): Promise<Item> {
    return new Promise(async (resolve, reject) => {
      try {
        if(item?._localUUID) {
          item.lastModifiedBy = this.authService.getLoginPhone();
          item.lastModifiedByName = Utility.getCreatedByName();
          let updatedItem = await this.dao.update(item);
          this.reloadList();
          this.updateSubs.next(updatedItem);
          return resolve(updatedItem);
        }
        return resolve(null);
      } catch (error) {
        SentryUtilites.setLog("ItemService:update", error)
        return resolve(null);
      }
    });
  }

  delete(item: Item): Promise<Item> {
    return new Promise(async (resolve, reject) => {
      try {
        if(item?._localUUID) {
          item.lastModifiedBy = this.authService.getLoginPhone();
          item.lastModifiedByName = Utility.getCreatedByName();
          let deletedItem = await this.dao.delete(item);
          this.reloadList();
          this.updateSubs.next(deletedItem);
          return resolve(deletedItem);
        }
        return resolve(null);
      } catch (error) {
        SentryUtilites.setLog("ItemService:delete", error)
        return resolve(null);
      }
    });
  }

  updateSyncStamp(el: Item): Promise<Item> {
    return new Promise(async (resolve, reject) => {
      try {
        let updatedEl = await this.dao.updateSyncStamp(el);
        this.updateSubs.next(updatedEl);
        return resolve(updatedEl);
      } catch (error) {
        SentryUtilites.setLog("ItemService:updateSyncStamp", error)
        return resolve(null);
      }
    });
  }

  isSyncLock = false;
  isSyncPostPond = false;
  async trySyncUnsynced(postpond?: boolean) {
    try {
      if (this.isSyncLock) {
        if (!this.isSyncPostPond) {
          setTimeout(() => {
            this.isSyncPostPond = true;
            this.trySyncUnsynced(true);
          }, 200);
        }
        return true;
      }
      if (postpond) {
        this.isSyncPostPond = false;
      }
      this.isSyncLock = true;
      let unSyncedElements: Item[] = await this.dao.getAllUnsynced(this.selectedProfileId);
      if (unSyncedElements && unSyncedElements.length) {
        try {
  
          for (let i = 0; i < unSyncedElements.length; i++) {
            let unSyncedElement = unSyncedElements[i];
            if(unSyncedElement?._localUUID) {
              unSyncedElement['updatedStamp'] = +new Date();
            }
          }
  
          await this.dao.bulkPut(unSyncedElements);
          await Utility.wait(1000);
          
          let chunkArr = Utility.getChunkArr(unSyncedElements);
          let chunkArrLength = chunkArr?.length;
  
          for(let i = 0; i < chunkArrLength; i++) {
            let result = await this.expressServerService.makeSyncCall('item', chunkArr[i]);
  
            if (result && result?.['records']?.length) {
              let arr = result?.['records'];
              for (let i = 0; i < arr?.length; i++) {
                const el = arr[i];
                await this.updateSyncStamp(el);
              }
            }
          }
        } catch (err) {
          SentryUtilites.setLog("ItemService:trySyncUnsynced:inner", err)
        }
      }
      this.isSyncLock = false;
    } catch (error) {
      SentryUtilites.setLog("ItemService:trySyncUnsynced", error)
    }
  }


  async updateItemStock(item: Item, quantity: number): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        await this.dao.updateStock(item, quantity);
        this.reloadList();
        return resolve(true)
      } catch (error) {
        SentryUtilites.setLog("ItemService:updateItemStock", error)
        return resolve(false);
      }
    });
  }

  isBroadCastLock = false;
  isBroadCastPostPond = false;

  async handleBroadcast(savedArray: Item[], postpond?: boolean) {


    if (this.isBroadCastLock) {
      if (!this.isBroadCastPostPond) {
        setTimeout(() => {
          this.isBroadCastPostPond = true;
          this.handleBroadcast(savedArray, true);
        }, 200);
      }
      return true;
    }
    if (postpond) {
      this.isBroadCastPostPond = false;
    }
    this.isBroadCastLock = true;


    for (let i = 0; i < savedArray?.length; i++) {
      try {
        let obj = await this.getByUUID(savedArray[i]?._localUUID);
        if (obj && obj?.syncStamp == savedArray[i]?.syncStamp) {
          continue;
        }
        if (!obj) {
          await this.dao.saveDb(savedArray[i])
        } else if (obj?.syncStamp < savedArray[i]?.syncStamp) {
          await this.dao.updateDb(savedArray[i])
        }
      } catch (err) {
        SentryUtilites.setLog("ItemService:handleBroadcast", err)
        return null;
      }
    }

    this.reloadList();
    for (let i = 0; i < savedArray?.length; i++) {
      try {
        let obj = await this.getByUUID(savedArray[i]?._localUUID);
        this.updateSubs.next(obj);

      } catch (err) {
        SentryUtilites.setLog("ItemService:handleBroadcast", err)
        return null;
      }
    }

    this.isSyncLock = false;

  }

  async getMasterItem(barcode: string): Promise<Item> {
    try {
      let res = await this.expressServerService.masterItemCall(barcode);
      if(res?.status == 'success') {
				let masterItem = {...res.item};
				if(masterItem?.id) {
          let item = new Item();
          item.itemName = masterItem?.itemName;
          item.brandName = masterItem?.brandName;
          item.mrp = masterItem?.mrp;
          item.sellPrice = masterItem?.mrp;
          item.spIncTax = true;
          item.category = masterItem?.category;
          item.description = masterItem?.itemDes;
          item.barcode = masterItem?.barCode;
          item.hsn = masterItem?.hsn;
          item.taxPercentage = masterItem?.taxPercentage;
          item.cessPercentage = masterItem?.cessPercentage;
          item.type = "product";

          return await this.save(item);
        }
      }
      return null;
    } catch (error) {
      SentryUtilites.setLog("ItemService:getMasterItem", error)
      return null;
    }
  }

  copyData(fromProfileId: string, toProfileId: string): Promise<boolean> {
    return new Promise(async (resolve,reject) => {
      try {
        if(Utility.isTruthy(fromProfileId) && Utility.isTruthy(toProfileId)) {
          let fromRecords = await this.getAllByPromiseByProfile(fromProfileId);
          if(fromRecords?.length) {
            let toRecords: Item[] = [];
            for (let i = 0; i < fromRecords?.length; i++) {
              const fetchedRecord = fromRecords[i];
              if(fetchedRecord?.itemName?.toLowerCase() != 'samosa' && fetchedRecord?._localUUID) { 
                fetchedRecord.profileId = toProfileId;
                delete fetchedRecord?._localId;
                delete fetchedRecord?._localUUID;
                let savedRecord = await this.save(fetchedRecord);
                if(savedRecord?._localUUID) {
                  toRecords?.push(savedRecord);
                }
              }
            }
            return resolve(true);
          }
        }
        return resolve(false);
      } catch (error) {
        SentryUtilites.setLog("ItemService:copyData", error)
        return resolve(false);
      }
    });
  }

  async updateItemOnIngredientUpdate(ingredient: Ingredient) {
    try {
      let allItems = await this.getAllByPromise();
      if(allItems?.length) {
        for (let i = 0; i < allItems?.length; i++) {
          let item = allItems[i];
          if(item?.itemIngredients?.length) {
            let index = item?.itemIngredients?.findIndex(x => x?.ingredient?._localUUID === ingredient?._localUUID);
            if(index != -1) {
              if(ingredient?.deletedStamp) {
                item.itemIngredients.splice(index,1);
              }else {
                item.itemIngredients[index].ingredient.name = ingredient?.name; 
              }
              await this.update(item);
            }
          }
        }
      } 
      return true;
    } catch (error) {
      SentryUtilites.setLog("ItemService:updateItemOnIngredientUpdate", error)
      return false;
    }   
  }

}

//  Master Barcodes
//  8901015231002
//  8904323319028
//  8906084440836
//  8901571006908
//  8901138819712
//  8901138509026
//  8908001705448
//  8901972053525
