import { ActivatedRoute, Router } from '@angular/router';
import { Utility } from '../../utils/utility';
import { ExpressServerService } from '../../services/api/express-server.service';
import { Validator } from '../../utils/validator';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth/auth.service';
import { IonInput } from '@ionic/angular';
import { CommonService } from '../../services/common.service';
import { LogoutService } from '../../services/logout.service';
import { EventService } from '../../services/event.service';
import { LoginTokenService } from '../../services/login-token.service';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.page.html',
  styleUrls: ['./signin.page.scss'],
})
export class SigninPage implements OnInit {

  @ViewChild('phoneEle') phoneEle: IonInput;
  @ViewChild('otpEle') otpEle: IonInput;

  signInForm: FormGroup = null;

  isDirectSignIn = true;
  isOTPSent = false;

  showBtnLoader = false;

  constructor(
    private formBuilder: FormBuilder,
    private cd: ChangeDetectorRef,
    private router: Router,
    private expressServerService: ExpressServerService,
    private authService: AuthService,
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private logoutService: LogoutService,
    private eventService: EventService,
    private loginTokenService: LoginTokenService,
  ) {
    if (this.authService.isUserLoggedIn) {
      if(Utility.getFromLocalStorage('Initial_data_download_complete')) {
        this.router.navigate(['dashboard']);
      } else {
        this.router.navigate(['fetch-initial-data-v2']);
      }
    }

  }

  async ngOnInit() {
    let token = this.activatedRoute?.snapshot?.params?.token;
    if(token && this.authService.validateToken(token)) {
      this.authService.getToken() && await this.logoutService.logout(false);
      this.authService.setToken(token);
      this.router.navigate(['fetch-initial-data-v2']);
    }else {
      this.initializeReactiveForm();
    }
  }

  ngAfterViewInit() {
    try {
      this.eventService.setShowContactSupportPopup(false);
      this.eventService.setShowPremiumPopup(false);
      const paramPhone = this.activatedRoute?.snapshot?.queryParamMap?.get('phone');
      if(paramPhone){
        this.signInForm.patchValue({phone:paramPhone});
        this.getOTP();
      }
    } catch (error) {
      SentryUtilites.setLog("SigninPage:ngAfterViewInit", error)
    }
  }

  initializeReactiveForm() {
    try {
      this.signInForm = this.formBuilder.group({
        phone: [null, [Validators.required, Validators.pattern(Validator.phone)]],
        termsAndCondition: [null, Validators.requiredTrue],
        otp: [null, Validators.pattern(Validator.otp)]
      });
    } catch (error) {
      SentryUtilites.setLog("SigninPage:initializeReactiveForm", error)
    }
  }


  changeMobile(phone: string, termsAndCondition: boolean) {
    try {
      this.isOTPSent = false;
      this.signInForm = null;
      this.initializeReactiveForm();
      this.signInForm.patchValue({phone, termsAndCondition});
      setTimeout(() => {
        this.phoneEle?.setFocus();
        this.cd.detectChanges();
      }, 200);
    } catch (error) {
      SentryUtilites.setLog("SigninPage:changeMobile", error)
    }
  }

  async getOTP() {
    try {
      // if (this.isDirectSignIn) {
      //   return await this.directSignIn();
      // }
  
      // if (!navigator?.onLine) {
      //   return alert('It seems like internet is not connected.');
      // }
  
      this.showBtnLoader = true;
      const getOTPRes = await this.expressServerService.sendOtpCall(this.signInForm?.value?.phone?.toString());
      this.showBtnLoader = false;
  
      this.isOTPSent = (getOTPRes?.status === 'success');
  
      if (this.isOTPSent) {
        this.signInForm.get('otp').addValidators(Validators.required);
        await this.commonService.generateToast('tertiary', "OTP Sent Successfully");
        setTimeout(() => {
          this.otpEle?.setFocus();
          this.cd.detectChanges();
        }, 200);
      }else {
        return await this.commonService.generateToast('warning', "Something went wrong.");
      }
  
      this.cd?.detectChanges();
    } catch (error) {
      SentryUtilites.setLog("SigninPage:getOTP", error)
      return false;
    }



  }

  async verifyOTP() {
    // if (!navigator?.onLine) {
    //   return alert('It seems like internet is not connected.');
    // }

    if(Utility.getFromLocalStorage('_ezo_login_token')) {
      this.loginTokenService.completeLogout(this.authService.getTokenUUID());
      await this.authService.logout();
    }

    let location = await Utility.getCurrentLongLat();
    let device = this.commonService.getDevice();

    this.showBtnLoader = true;
    const verifyOTPRes = await this.expressServerService.verifyOtpCall(
      this.signInForm?.value?.phone?.toString(),
      this.signInForm?.value?.otp?.toString(),
      location?.lat,
      location?.long,
      device
    );
    this.showBtnLoader = false;

    const otpVerified=verifyOTPRes.otpVerified;

    if(otpVerified){
      await this.commonService.generateToast('tertiary', "OTP Verified Successfully");
      const token = verifyOTPRes?.tokenDetails?.token;
      this.authService.setToken(token);
      
      if(verifyOTPRes?.isFetchAllZip) {
        this.router.navigate(['fetch-initial-data-v2']);
      } else {
        this.router.navigate(['fetch-initial-data']);
      }
    }else{
      await this.commonService.generateToast('warning', "Wrong otp");
    }


  }

  async setSignInDataToLocalStorage(param: {
    token: string;
  }) {
    //Utility.setToLocalStorage('ezo-token', data?.token);
    Utility.setToLocalStorage('fireBaseUserToken', param?.token);
    //Utility.setToLocalStorage('isSignUp', data?.isSignUp);
  }

  async directSignIn() {
    try {
      if (!navigator?.onLine) {
        return alert('It seems like internet is not connected.');
      }
  
      let location = await Utility.getCurrentLongLat();
      let device = this.commonService.getDevice();
  
      const getOTPRes = await this.expressServerService.sendOtpCall(this.signInForm?.value?.phone);
      const verifyOTPRes = await this.expressServerService.verifyOtpCall(
        this.signInForm?.value?.phone,
        "0000",
        location?.lat,
        location?.long,
        device
      );
      const token = verifyOTPRes?.tokenDetails?.token;
  
      this.authService.setToken(token);
      // this.directSignInOld();
      this.router.navigate(['fetch-initial-data-v2']);
    } catch (error) {
      SentryUtilites.setLog("SigninPage:directSignIn", error)
    }
  }

  async directSignInOld() {
    try {
      if (!navigator?.onLine) {
        return alert('It seems like internet is not connected.');
      }
  
      const verifyOTPRes = await this.expressServerService.makePostCall(
        'user/directSignIn',
        {
          phone: this.signInForm?.value?.phone,
          otp: '0000',
        }
      );
  
      if (verifyOTPRes?.status === 'success' && verifyOTPRes?.data?.fbToken) {
        //this.setSignInDataToLocalStorage(verifyOTPRes.data);
  
        //@Temporary
        let userId = verifyOTPRes?.data?.user?.uid;
        let profileId = verifyOTPRes?.data?.user?.profileId;
        let masterUserId = verifyOTPRes?.data?.user?.uid;
  
        Utility.setToLocalStorage("_userId", userId);
        Utility.setToLocalStorage("_profileId", profileId);
        Utility.setToLocalStorage("_masterUserId", masterUserId);
      } else {
        return alert('Something went wrong.');
      }
    } catch (error) {
      SentryUtilites.setLog("SigninPage:directSignInOld", error)
    }
  }

}
