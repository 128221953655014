import { BaseModel } from "./BaseModel.model";

export class IPartyCategory {

  static SCHEMA_NAME = 'party-category';

}

export default class PartyCategory extends BaseModel {

  profileId: string;
  name: string;
  savedPosition: number;

}
