import { MoneyOut } from './MoneyOut.model';
import { BaseModel } from "./BaseModel.model";
import Party from "./Party.model";

export class IExpense {

  static SCHEMA_NAME = 'expense';

}
export class Expense extends BaseModel {

  profileId: string;

  billNo: string;

  billDateStamp: number;

  party: Party;

  moneyOuts: MoneyOut[];

  moneyOut: MoneyOut;

  note: string;

  totalAmount: number;

  amountPaid: number;

  category: string;

}
