<app-pin-verification (verifyTransactionPin)="verifyTransactionPIN($event)"  #pinVerificationElement></app-pin-verification>

<!-- Bulk Purchase -->

<ion-modal class="modal-bulk-purchase" [isOpen]="isBulkPurchaseModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar color="light">
        <ion-grid>
          <ion-row class="ion-align-items-center">
            <ion-col size="auto">
              <ion-title>Delete Purchases</ion-title>
            </ion-col>
            <ion-col class="ion-text-center">
              <ion-label class="font-size-16">From {{fromStamp | date:'dd/MM/yyyy'}} </ion-label>
              <ion-label class="font-size-16">To {{toStamp | date:'dd/MM/yyyy'}}</ion-label>
            </ion-col>
            <ion-col size="auto" class="ion-text-end">
              <ion-buttons>
                <ion-button (click)="openBulkPurchaseModal(false)">Close</ion-button>
              </ion-buttons>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">

      <ion-grid>

        <ion-row class="fixed-row">
          <ion-col size="12">
            <!-- Select all Purchase-->
            <ion-chip class="select-all-checkbox" color="tertiary" *ngIf="bulkPurchases?.length">
              <ion-item>
                <ion-checkbox
                  slot="start"
                  [checked]="selectedBulkPurchases.length === viewFilteredList?.length"
                  (ionChange)="selectAllPurchaseCheckboxes($event)"
                  (click)="selectAllPurchaseCheckboxClick()"
                ></ion-checkbox>
                <ion-label>Select All</ion-label>
              </ion-item>
            </ion-chip>

            <ion-chip color="tertiary">
              <ion-label>Total Count : </ion-label>
              <ion-label>&nbsp;&nbsp; </ion-label>
              <ion-label>{{viewFilteredList.length}}</ion-label>
            </ion-chip>

            <ion-chip color="tertiary">
              <ion-label>Selected Purchase Count : </ion-label>
              <ion-label>&nbsp;&nbsp; </ion-label>
              <ion-label>{{selectedBulkPurchases?.length}}</ion-label>
            </ion-chip>

          </ion-col>
        </ion-row>

        <ion-row class="floating-list">
          <ion-col size="12">
            <ion-item lines="none" *ngFor="let purchase of bulkPurchases">
              <ion-checkbox
                slot="start"
                (ionChange)="addToSelectedBulkPurchaseArr(purchase,$event)"
                [checked]="purchase['checked']"
              ></ion-checkbox>
              <ion-label>
                <ion-chip color="primary">{{ purchase.billNo }} | {{ purchase.billDateStamp | date: 'dd/MM/yy' }}</ion-chip>
                <ion-chip color="primary">{{ purchase?.party?.name }}</ion-chip>
                <ion-chip color="primary">{{ purchase?.totalAmount | currency: 'INR' }}</ion-chip>
              </ion-label>
            </ion-item>
            <ion-infinite-scroll threshold="10px" *ngIf="bulkPurchases?.length" (ionInfinite)="loadMoreListData($event)">
              <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Loading more data...">
              </ion-infinite-scroll-content>
            </ion-infinite-scroll>
          </ion-col>
        </ion-row>

      </ion-grid>

    </ion-content>

    <ion-toolbar>
      <ion-button
        class="btn-save"
        expand="full"
        color="danger"
        (click)="deleteBulkPurchase()"
      >
        <span *ngIf="!bulkPurchaseDeleteStarted">
          Delete
        </span>
        <span *ngIf="bulkPurchaseDeleteStarted && !bulkPurchaseDeleteCompleted">
          <ion-spinner name="lines-sharp-small"></ion-spinner>
        </span>
        <span *ngIf="bulkPurchaseDeleteCompleted">
          Deleted
        </span>
      </ion-button>
    </ion-toolbar>
  </ng-template>
</ion-modal>

<!-- -------------------------------------------------------------------------------------- -->

<!-- Purchase -->

<ion-modal class="modal-purchase" [isOpen]="isPurchaseModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar color="light">
        <ion-title>Delete <ion-chip color="tertiary"> {{ purchase?.billNo }} </ion-chip> </ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="openPurchaseModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">

      <ion-grid>

        <ion-row>
          <ion-col class="txt-center" size="12">
            <ion-chip color="tertiary" *ngIf="purchase?.moneyOuts?.length">
              <span>
                <ion-checkbox
                  slot="start"
                  [disabled]="true"
                  [checked]="isDeletePurchaseAttachedMoneyOuts"
                  (ionChange)="setCheckboxVarValue('isDeletePurchaseAttachedMoneyOuts',$event)"
                ></ion-checkbox>
              </span>
              <ion-label>Also Delete Attached Money Out's</ion-label>
            </ion-chip>
            <ion-chip color="tertiary" *ngIf="!purchase?.moneyOuts?.length">
              <ion-label>There is no Money Out is Attached with this Purchase</ion-label>
            </ion-chip>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col class="txt-center" size="12">
            <ion-button fill="outline" class="dashed-border txt-transform-normal">
              Purchase Delete
              <ion-icon *ngIf="purchase?.deletedStamp" color="success" slot="end" name="checkmark-done"></ion-icon>
            </ion-button>
          </ion-col>
        </ion-row>

        <ion-row *ngIf="isDeletePurchaseAttachedMoneyOuts">
          <ion-col class="txt-center" size="12">
            <ion-button fill="outline" class="dashed-border txt-transform-normal">
              All Money Out's Delete
            </ion-button>
            <ion-chip color="tertiary" *ngFor="let moneyOut of purchase?.moneyOuts">
              {{ moneyOut?.billNo }}
              <span>
                <ion-icon *ngIf="purchase?.deletedStamp" color="success" slot="end" name="checkmark-done"></ion-icon>
              </span>
            </ion-chip>
          </ion-col>
        </ion-row>

      </ion-grid>

    </ion-content>

    <ion-toolbar>
      <ion-button
        class="btn-save"
        expand="full"
        color="danger"
        (click)="deletePurchase()"
      >
        <span *ngIf="!purchaseDeleteStarted">
          Delete
        </span>
        <span *ngIf="purchaseDeleteStarted && !purchaseDeleteCompleted">
          <ion-spinner name="lines-sharp-small"></ion-spinner>
        </span>
        <span *ngIf="purchaseDeleteCompleted">
          Deleted
        </span>
      </ion-button>
    </ion-toolbar>
  </ng-template>
</ion-modal>

<!-- -------------------------------------------------------------------------------------- -->
