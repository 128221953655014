import { PremiumControlService } from 'src/app/services/auth/premium-control.service';
import { IBillView } from "src/interface/IBillView.interface";
import { Utility } from "../utils/utility";
import { ActivatedRoute, Router } from "@angular/router";
import { AllDataService } from "../services/all-data.service";
import { AuthService } from "../services/auth/auth.service";
import { IBillingMode, Sale } from "../models/Sale.model";
import Party from "../models/Party.model";
import { HtmlTemplates } from "@makarandkate/invoice-templates";
import { MoneyIn } from "../models/MoneyIn.model";
import { Profile } from "../models/Profile.model";
import { BillItem } from "../models/BillItem.model";
import { TransportDetail } from "../models/TransportDetail.model";
import { BaseModel } from "../models/BaseModel.model";
import { Arrays } from "@makarandkate/invoice-templates/lib/utils/ReferenceData";
import { ImageBase64Service } from '../services/image-base64.service';
import { SentryUtilites } from '../utils/sentryUtilites';

export abstract class BaseBillView<T extends BaseBillViewModel, K extends BaseBillPrintModel, M extends MoneyInOutModel> implements IBillView<BaseBillViewModel, BaseBillPrintModel, MoneyInOutModel> {

    abstract selectedTemplate: string;
    abstract billPrint: K;
    abstract record: T;

    getHeaderColorClass = Utility.getHeaderColorClass;
    selectedProfileId = Utility.getFromLocalStorage('selectedProfile');

    isProfileFormModalOpen = false;
    isFocusLegalName = false;
    isFocusBankAccountDetails = false;

    isDefaultBillPrintCalled = false;
    isPartyDeleted = false;
    isPremiumAccess = false;
    billDownloadEndpoint: string = '';

    moneyInOut: M = null;

    abstract setRecord(paramDocumentId: string): Promise<T>;
    abstract getMoneyInOut(): Promise<M>;
    abstract setBillPrint(tokenDetails: any,profile: Profile,signature: any,logo: any,party: Party,secondaryParty: Party): void;
    abstract openTransactionPINModal(): void;
    abstract verifyTransactionPIN(event): void;
    abstract edit(): void;
    abstract delete(): void;
    abstract setTemplateToHtmlDiv(htmlString: any): void;
    abstract openTNCSetting(): void;
    abstract navigateToBill(): Promise<void>;
    abstract setTemplate(e:any): void;
    abstract setTermsAndConditions(profile:Profile):void;

    constructor(
        protected route: ActivatedRoute,
        protected allDataService: AllDataService,
        protected authService: AuthService,
        protected premiumControlService: PremiumControlService,
        protected imageBase64Service: ImageBase64Service,
    ) { }

    async baseNgOnInit() {
        try {
            let res = await this.generateBillViewObject();
            if(res) {
                this.populateTemplate();
            }
        } catch (error) {
            SentryUtilites.setLog("BaseBillView:baseNgOnInit", error)
        }
    }

    async baseIonViewWillEnter() {
        try {
            this.isDefaultBillPrintCalled = false;
            this.isPremiumAccess = await this.premiumControlService.isPremiumAccess();
            this.baseNgOnInit();
        } catch (error) {
            SentryUtilites.setLog("BaseBillView:baseIonViewWillEnter", error)
        }
    }

    baseNgOnDestroy() {
        this.deactivateBillViewListeners();
    }

    openProfileFormModal = (value:boolean = true) => {
        this.isProfileFormModalOpen = value;
    }

    activateBillViewListeners() {
        try {
        // Profile Info Edit
        document.getElementsByClassName('addProfileInfo')[0]?.addEventListener('click',(event) => {
            this.isFocusLegalName = true;
            this.isFocusBankAccountDetails = false;
            this.openProfileFormModal();
        });

        // Bank Details Edit
        document.getElementsByClassName('addBankDetails')[0]?.addEventListener('click',(event) => {
            this.isFocusBankAccountDetails = true;
            this.isFocusLegalName = false;
            this.openProfileFormModal();
        });

        // Add Tnc Edit
        document.getElementsByClassName('addTNC')[0]?.addEventListener('click',(event) => {
            this.openTNCSetting();
        });
        } catch (error) { 
            SentryUtilites.setLog("BaseBillView:activateBillViewListeners", error)
        }
    }

    deactivateBillViewListeners() {
        try {
            document.getElementsByClassName('addProfileInfo')[0]?.removeEventListener('click',() => {});
            document.getElementsByClassName('addBankDetails')[0]?.removeEventListener('click',() => {});
            document.getElementsByClassName('addTNC')[0]?.removeEventListener('click',() => {});
        } catch (error) {
            SentryUtilites.setLog("BaseBillView:deactivateBillViewListeners", error)
        }
    }

    onProfileUpdate = (event) => {
        try {
            if(event) {
            this.refreshTemplate();
            this.openProfileFormModal(false);
            setTimeout(() => {
                this.isFocusLegalName = false;
                this.isFocusBankAccountDetails = false;
            }, 500);
            }
        } catch (error) {
            SentryUtilites.setLog("BaseBillView:onProfileUpdate", error)
        }
    }

    /**
     * 
     * @returns : return terms and condition for sale
     */
    async getDiscountTNC(): Promise<string> {
        try {
            if(
                this.selectedTemplate === 'temp1' ||
                this.selectedTemplate === 'temp17' ||
                this.selectedTemplate === 'temp18' ||
                this.selectedTemplate === 'temp19' ||
                this.selectedTemplate === 'temp20'
                ) {
                const profile = await this.allDataService.profileService.getByUUID(this.selectedProfileId);
                if(profile?.dSetDiscountStatusI && profile?.dSetDiscountPrintStatus) {
                    let discountPercent = profile?.dSetDiscountPercentI;
                    let discountMaximumAmount = profile?.dSetDiscountMaximumAmountI;
                    let discountMinimumAmount = profile?.dSetDiscountMinimumAmountI;
                    let discountExpiryDays = profile?.dSetDiscountExpiryDaysI;
                    let discountName = profile?.dSetDiscountNameI;
        
                    return `
                        Get ${discountPercent}% ${discountName || ''} Discount
                        1. Get ${discountPercent}% discount on next visit
                        2. Minimum Bill Amount Rs. ${discountMinimumAmount}
                        3. Maximum discount Rs. ${discountMaximumAmount}
                        4. Next visit should be in ${discountExpiryDays} days
                        5. Offer subject to scheme available, please check with us before availing discount
                    `;
                }
            } else {
                return '';
            }
        } catch (error) {
            SentryUtilites.setLog("BaseBillView:getDiscountTNC", error)
            return '';
        }
    }
    // --------------------------------------

    async generateBillViewObject() {
        try {
            const paramDocumentId: string = this.route.snapshot.paramMap.get('documentId');
            let res = await this.setRecord(paramDocumentId);
    
            if(res) {
                const tokenDetails = this.authService.getDecodedAccessToken();
        
                const profile = await this.allDataService.profileService.getByUUID(this.selectedProfileId);
                
                let logo = {imageBase64: null};
                let signature = {imageBase64: null};
                
                if(profile?.logoLink) {
                    const image = await this.allDataService.imageService.getByUUID(profile?.logoLink);
                    logo.imageBase64 = await this.imageBase64Service.getBase64FromDBorServerWithoutSave(image);
                }
        
                if(profile?.signatureLink) {
                    const image = await this.allDataService.imageService.getByUUID(profile?.signatureLink);
                    signature.imageBase64 = await this.imageBase64Service.getBase64FromDBorServerWithoutSave(image);
                }
        
                let party = await this.allDataService.partyService.getByUUID(this.record?.party?._localUUID);
        
                if(!party){
                    party =  this.record?.party;
                    this.isPartyDeleted = true;
                }
        
                let secondaryParty:Party = null;
                if(this.record?.partySecondary?._localUUID) {
                    secondaryParty = await this.allDataService.partyService.getByUUID(this.record?.partySecondary?._localUUID);
                }else if(this.record?.partySecondary?.name || this.record?.partySecondary?.billingAddress) {
                    secondaryParty = this.record?.partySecondary;
                    secondaryParty._localUUID = '-';
                }
        
                this.moneyInOut = await this.getMoneyInOut();
        
                if(profile?._localUUID) {
                    profile.pSetTermsAndConditions = (profile?.pSetTermsAndConditions || '') + (await this.getDiscountTNC() || '');
                }
        
                 this.setTermsAndConditions(profile);
        
                //Intentional Log Do not remove
                console.info({
                    user:{
                        isPro: tokenDetails?.isPro,
                        registrationStamp: tokenDetails?.createdStamp
                    },
                    profile,
                    signature,
                    logo,
                    party,
                    secondaryParty,
                    record: this.record,
                    moneyInOut: this.moneyInOut,
                    isPartyDeleted:this.isPartyDeleted,
                });
                
                this.setBillPrint(tokenDetails,profile,signature,logo,party,secondaryParty);
        
                if(!this.billPrint.settings){
                    this.billPrint.settings={}
                }
        
                this.billPrint.settings.iSetPrintTimeOnBill=profile?.iSetPrintTimeOnBill;
                this.billPrint.settings.pSetTaxMrpHsnPrintStatus=profile?.pSetTaxMrpHsnPrintStatus;
                this.billPrint.settings.showPreviousAmount = profile?.iSetAutoPreviousBalanceStatus;
                this.billPrint.settings.billSavingsAmount=true;
        
                //Intentional Log Do not remove
                console.info("this.billPrint",JSON.stringify(this.billPrint));
                return this.billPrint;
            }
            return null;
        } catch (error) {
            SentryUtilites.setLog("BaseBillView:generateBillViewObject", error)
            return null;
        }
    }

    async populateTemplate() {
        try {
            let htmlString = await HtmlTemplates.getBillTemplateHtml(this.billPrint,this.selectedTemplate);
            this.setTemplateToHtmlDiv(htmlString);
            setTimeout(() => { this.activateBillViewListeners(); }, 1000);
            const isPrint = this.route.snapshot.queryParamMap.get('isPrint');
            if(isPrint && !this.isDefaultBillPrintCalled && this.isPremiumAccess) {
                this.isDefaultBillPrintCalled = true;
                this.printBill();
            }
        } catch (error) {
            SentryUtilites.setLog("BaseBillView:populateTemplate", error)
        }
    }

    async refreshTemplate() {
        await this.generateBillViewObject();
        this.populateTemplate();
    }

    printBill() {
        try {
            let div: any = document.querySelectorAll("#htmlInvoiceTemplate")[0];
    
            let domContainer: any = document.getElementById('div-print-area');
            let dvApp: any = document.getElementById('dv-app');
    
            if(div && domContainer && dvApp){
                dvApp.style.display="none";
                div.style.margin = "0";
                domContainer.appendChild(div.cloneNode(true));
                domContainer?.classList?.add('active');
                domContainer.style.width = "100%";
                let documentTitle=document.title;
                document.title=this.billPrint?.bill?.billNo || 'Bill';
    
                setTimeout(() => {
                    window?.print();
                    domContainer.innerHTML = '';
                    domContainer?.classList?.remove('active');
                    domContainer.style.width = "100%";
                    dvApp.style.display="block";
                    div.style.margin = "auto";
                    document.title=documentTitle;
                }, 500);
            }else{
                console.warn('One or more elemet is missing')
            }
        } catch (error) {
            SentryUtilites.setLog("BaseBillView:printBill", error)
        }
    }

    downloadPDF() {
        try {
            let loginPhone = this.authService.getLoginPhone();
            let url = `${this.billDownloadEndpoint}/${loginPhone}/${this.record?._localUUID}`;
            let aTag = document.createElement('a');
            aTag.href = url;
            aTag.target = '_blank';
            aTag.click();
            setTimeout(() => {
                aTag.remove();
            }, 100);
        } catch (error) {
            SentryUtilites.setLog("BaseBillView:downloadPDF", error)
         }
    }

}

class BaseBillViewModel extends BaseModel {
    profileId: string;

    billNo: string;

    billCompleteStamp?: number;

    billDateStamp: number;

    dueDateStamp?: number;

    billingTerm?: string;

    party: Party;

    partySecondary?: Party;

    billItems?: BillItem[];

    moneyIns?: MoneyIn[];

    transportDetail?: TransportDetail;

    deliveryProvience?: string;

    senderProvience?: string;

    note?: string;

    cashDiscount?: number;

    cashDiscountPercentage?: number;

    additionalDiscount?: number;

    isPrintedKOT?: boolean;

    discountAmount?: number;

    gstAmount?: number;

    cessAmount?: number;

    subTotalAmount?: number;

    totalAmount: number;

    totalSaving?: number;

    amountReceived?: number;

    roundOffValue?: number;

    partyPreviousBalance?: number;

    serviceChargePercentage?: number;

    serviceChargeAmount?: number;

    billingMode?: IBillingMode;

    paymentMode?: string;

    paymentId?: string;

    linkedPurchaseUUID?: string;

    linkedExpenseUUID?: string;

    amountPaid?: number;

    category?: string;

    billConvertedStamp?: number;

}

class BaseBillPrintModel {
    user: {
        isPro?: number;
        registrationStamp?: number;
    };

    profile: {
        addressProvience?: string;
        legalName?: string;
        addressLine1?: string;
        addressLine2?: string;
        addressPostalCode?: string;
        contactPersonPhone?: string;
        contactPersonEmail?: string;
        gstin?: string;
        fssaiNumber?: string;
        licenseNumber?: string;
        bankAccountNo?: string;
        bankName?: string;
        bankIFSC?: string;
        bankAccountType?: string;
        bankUPI?: string;
        additionalInputFieldTitle?: string;
        additionalInputFieldValue?: string;
        additionalDateFieldValue?: string; // Not Avail
        additionalDateFieldTitle?: string; // Not Avail
        signatureLink?: string;
        logoLink?: string;
    };

    bill?: {
        partyData: {
            ledgerCredit?: number;
            profileData: {
                legalName?: string;
                gstNumber?: string;
                contactPersonName?: string;
                contactPersonPhone?: string;
                contactPersonEmail?: string;
                addressLine1?: string;
                addressState?: string;
                addressPincode?: string;
                addressOneLine1?: string;
                addressOneState?: string;
                addressOnePincode?: string;
                udf1T?: string;
                udf1V?: string;
                udf2T?: string;
                udf2V?: string;
                udf3T?: string;
                udf3V?: string;
                additionalInputFieldTitle?: string;
                additionalInputFieldValue?: string;
                additionalDateFieldValue?: string; // Not Avail
                additionalDateFieldTitle?: string; // Not Avail
            };
        };
        secondaryPartyData?: {
            ledgerCredit?: number;
            profileData: {
                legalName?: string;
                gstNumber?: string;
                contactPersonName?: string;
                contactPersonPhone?: string;
                contactPersonEmail?: string;
                addressLine1?: string;
                addressState?: string;
                addressPincode?: string;
                addressOneLine1?: string;
                addressOneState?: string;
                addressOnePincode?: string;
                udf1T?: string;
                udf1V?: string;
                udf2T?: string;
                udf2V?: string;
                udf3T?: string;
                udf3V?: string;
                additionalInputFieldTitle?: string;
                additionalInputFieldValue?: string;
                additionalDateFieldValue?: string; // Not Avail
                additionalDateFieldTitle?: string; // Not Avail
            };
        };
        moneyIn?: {
            billNo?: string;
            dateStamp?: number;
            amount?: number;
            txnMode?: string;
            txnRef?: string;
        },
        moneyOut?: {
            billNo?: string;
            dateStamp?: number;
            amount?: number;
            txnMode?: string;
            txnRef?: string;
        },
        txnMode?: string;
        txnRef?: string;
        isPartyDeleted?:boolean;
        roundOffValue?: number;
        billNo?: string;
        deliveryState?: keyof typeof Arrays.states;
        billDateStamp?: number;
        dueDateStamp?: number;
        purOrderNo?: string;
        eWayBillNo?: string;
        additionalAmount?: number;
        discountAmount?: number;
        gstAmount?: number;
        cessAmount?: number;
        totalAmount?: number;
        createdStamp?: number;
        transporterName?: string;
        vehicleNumber?: string;
        deliveryDate?: number;
        color: string;
        billItems?: {
            itemDes?: string;
            itemSrl?: string;
            hsn?: string;
            price: number;
            incTax?: boolean;
            taxPercentage: number;
            cessPercentage: number;
            unit: string;
            quantity: number;
            discountPercentage?: number;
            discount?: number;
            unitGstAmount?: number;
            unitCessAmount?: number;
            unitDiscountAmount?: number;
            unitTaxAmount?: number;
            itemTotalCessAmount?: number;
            itemTotalGstAmount?: number;
            itemTotalTaxAmount?: number;
            effectiveTaxPercentage?: number;
            billCashDiscountPercentage?: number;
            total: number;
            createdStamp?: number;
            item?: {
                itemName?: string;
                hsn?: string;
                mrp?: number;
                expiryDate?: number;
            };
            additionalInputFieldValue?: string;
            additionalDateFieldValue?: number;
            additionalInputFieldTitle?: string;
            additionalDateFieldTitle?: string;
            mrp?: number;
            variant?: string;
        }[];
        eWayBillDate?: number;
        note?: string;
        billTitle?: string;
        tnc?: string;
        cashDiscount?: number;
        amountReceived?: number;
        cashDiscountPercentage?: number;
        challanNo?: any;
        totalSaving?: number;
        subTotalAmount?: number;
        partyPreviousBalance?: number;
        billCompleteStamp?: number;
        serviceChargePercentage?: number;
        serviceChargeAmount?: number;
        deliveryProvience?: string;
    };
    
    settings?: {
        showSignatureNotRequiredText?: boolean;
        showSignatureInTemplates?: boolean;
        makeItemNameBold?: boolean;
        showPreviousAmount?: boolean;
        showTaxAndDiscAmt?: boolean;
        billSavingsAmount?: boolean;
        include3InchOnlineDukanLink?: boolean;
        iSetPrintTimeOnBill?: boolean;
        pSetTaxMrpHsnPrintStatus?: boolean;
    };

    onlineDukan?: {
        domain: string;
    };

    extra?: {
        thermalTncTemplate?: string;
    };

    qrPayLink?: string;
    addressTo?: string;
    pageWidth?: number;
    category?:string;
}

class MoneyInOutModel extends BaseModel{
    profileId: string;
    
	billNo: string;

	billDateStamp: number;

	party: Party;

	totalAmount: number;

	paymentMode: string;

	paymentId:string;

	linkedSaleUUID?: string;

	linkedPurchaseUUID?: string;
    
	linkedExpenseUUID?: string;
}