import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { MonthWisePartyCreditService } from '../month-wise-party-credit.service';
import { MonthWiseItemStockService } from '../month-wise-item-stock.service';
import { AllDataService } from '../all-data.service';
import { Utility } from '../../utils/utility';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Injectable({
  providedIn: 'root'
})
export class LedgerCheckService implements CanActivate {

  constructor(
    private monthWisePartyCreditService: MonthWisePartyCreditService,
    private monthWiseItemStockService: MonthWiseItemStockService,
    private router: Router,
    private allDataService: AllDataService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      try {
        let isLedgerRecordsPresent: boolean = false;
  
        const DBOpenRequest = window.indexedDB.open(environment.indexedDbName, environment.indexedDbVersion);
        
        DBOpenRequest.onerror = (event) => {
          console.log(event)
        };
    
        DBOpenRequest.onsuccess = async (event) => {
          let db = DBOpenRequest?.result;
          let objectStoreNames = db?.objectStoreNames;
          if(
            Object?.values(objectStoreNames).includes('month-wise-party-credit') &&
            Object?.values(objectStoreNames).includes('month-wise-item-stock')
            ) {
            
            if(!Utility.getFromLocalStorage('has_party_or_item')) {
              let parties = await this.allDataService.partyService.getAll();
              let items = await this.allDataService.itemService.getAll();
  
              if(parties?.length || items?.length) {
                Utility.setToLocalStorage('has_party_or_item', true);
              } else {
                isLedgerRecordsPresent = true;
              }
            }
  
            if(!Utility.getFromLocalStorage('has_partyCredit_or_itemStock')) {
              let getAllPartyCreditData = await this.monthWisePartyCreditService.getAll();
              let getAllItemStockData = await this.monthWiseItemStockService.getAll();
  
              if(getAllPartyCreditData?.length || getAllItemStockData?.length) {
                Utility.setToLocalStorage('has_partyCredit_or_itemStock', true);
              } 
            }
  
            if(Utility.getFromLocalStorage('has_party_or_item') && Utility.getFromLocalStorage('has_partyCredit_or_itemStock')) {
              isLedgerRecordsPresent = true;
            }
  
          }
          db?.close();
          
          if(!isLedgerRecordsPresent) {
            this.router.navigate(['ledger-loading']);
          }
          return isLedgerRecordsPresent;
        }
        
        return true;
      } catch (error) {
        SentryUtilites.setLog("LedgerCheckService:canActivate", error)
        return false;
      }
  }
  
}
