<ion-header>
  <ion-toolbar class="{{ getHeaderColorClass() }}">
    <app-navigation-control slot="start" type="category"></app-navigation-control>
    <ion-title>Categories
      <ion-chip>
        Total Count:
        {{ selectedView==='PartyCategoryView' ? partyCatListEle?.partyCategories?.length : '' }}
        {{ selectedView==='ItemCategoryView' ? itemCatListEle?.itemCategories?.length : '' }}
      </ion-chip>
    </ion-title>
    <ion-text class="last-sync-time" color="medium">Last Sync at {{ lastSyncTime }}</ion-text>
    <ion-text *ngIf="isTimeDifference" class="last-sync-time timezone-warning alert-border padding-alert-boder" color="warning"> <i>Time Zone Mismatched</i> </ion-text>
    <ion-chip color="danger" slot="end" (click)="bulkDelete()"> Bulk Delete </ion-chip>
    <ion-chip color="tertiary" slot="end" (click)="presentAddCategoryAlert()"> Add New Category </ion-chip>
  </ion-toolbar>
</ion-header>

<ion-content>

  <ion-grid>

    <ion-row>
      <ion-col size="12">
        <ion-item lines="none">
          <ion-segment mode="ios" [value]="selectedView" (ionChange)="onSelectedViewChange($event)">
            <ion-segment-button value="PartyCategoryView">
              <ion-label>Party Categories</ion-label>
            </ion-segment-button>
            <ion-segment-button value="ItemCategoryView">
              <ion-label>Item Categories</ion-label>
            </ion-segment-button>
          </ion-segment>
        </ion-item>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="12">
        <app-party-category-list #partyCatListEle *ngIf="selectedView==='PartyCategoryView'"></app-party-category-list>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="12">
        <app-item-category-list #itemCatListEle *ngIf="selectedView==='ItemCategoryView'"></app-item-category-list>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="12">
        <ion-button class="btn-save" expand="full" (click)="presentSuccessToast()">Save Sequence</ion-button>
      </ion-col>
    </ion-row>

  </ion-grid>

</ion-content>
