<ion-header>
  <ion-toolbar class="{{ getHeaderColorClass() }}">
    <app-navigation-control slot="start" type="profile/form"></app-navigation-control>
    <ion-title>Edit Profile Details</ion-title>
    <ion-text class="last-sync-time" color="medium">Last Sync at {{ lastSyncTime }}</ion-text>
    <ion-text *ngIf="isTimeDifference" class="last-sync-time timezone-warning alert-border padding-alert-boder" color="warning"> <i>Time Zone Mismatched</i> </ion-text>
  </ion-toolbar>
</ion-header>

<ion-content>

  <app-profile-form [readonly]="!isEditProfileAccess" #profileFormComponentEle (onUpdate)="onUpdate($event)"></app-profile-form>

</ion-content>
