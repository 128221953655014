import { AllDataService } from '../../services/all-data.service';
import { AuthService } from '../../services/auth/auth.service';
import { MonthWiseItemStockService } from '../../services/month-wise-item-stock.service';
import { MonthWisePartyCreditService } from './../../services/month-wise-party-credit.service';
import { Component, OnInit } from '@angular/core';
import { EventService } from '../../services/event.service';
import { IMonthWisePartyCredit } from '../../models/MonthWisePartyCredit.model';
import { IMonthWiseItemStock } from '../../models/MonthWiseItemStock.model';
import { Router } from '@angular/router';
import { LedgerService } from '../../services/ledger.service';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-ledger-loading',
  templateUrl: './ledger-loading.page.html',
  styleUrls: ['./ledger-loading.page.scss'],
})
export class LedgerLoadingPage implements OnInit {

  constructor(
    private router: Router,
    private ledgerService: LedgerService,
  ) { }

  ngOnInit() { }

  async ionViewWillEnter() {
    try {
      if(await this.ledgerService.maintainLedger()) {
        this.router.navigate(['dashboard']);
      }  
    } catch (error) {
      SentryUtilites.setLog("LedgerLoadingPage:ionViewWillEnter", error)
    }
  }
}
