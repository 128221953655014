import { NgxIndexedDBService } from "ngx-indexed-db";
import { IDataRepo } from "../../../interface/IDataRepo.interface";
import { IKot, Kot } from "../../models/Kot.model";
import { Utility } from "../../utils/utility";
import { Router } from "@angular/router";
import { SentryUtilites } from "src/app/utils/sentryUtilites";

export class KotDao implements IDataRepo<Kot>{
  private static _instance: KotDao;

  public static getInstance(
    ngxIndexedDBService: NgxIndexedDBService,
    router: Router,
    ) {
    if (!this._instance) {
      this._instance = new KotDao(ngxIndexedDBService, router)
    }
    return this._instance;
  }

  constructor(
    ngxIndexedDBService: NgxIndexedDBService,
    router: Router,
    ) {
    this.ngxIndexedDBService = ngxIndexedDBService;
    this.router = router;
  }

  ngxIndexedDBService: NgxIndexedDBService;
  router: Router;

  save(data: Kot): Promise<Kot> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && Utility.isTruthy(data)) {
          let timeStamp = +new Date();
          data.createdStamp = timeStamp
          data.updatedStamp = timeStamp
          data.userUpdatedStamp = timeStamp
          data.deletedStamp = 0
          data.syncStamp = 0
  
          if(!data?._localUUID) {
            data._localUUID = Utility.getUUID();
          }
  
          this.ngxIndexedDBService
            .add(IKot.SCHEMA_NAME, data)
            .subscribe((savedKot: Kot) => {
              return resolve(savedKot);
            },
            err => {
              console.error(err);
              if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                this.router.navigate(['idbx-error']);
              } else if (typeof err?.target?.error == 'object') {
                SentryUtilites.setLog("KotDao:save", err?.target?.error)
              } else {
                SentryUtilites.setLog("KotDao:save", err)
              }
            });
        } else {
          return resolve(null);
        }
      } catch (err) {
        SentryUtilites.setLog("KotDao:save", err)
        return resolve(null);
      }
    });
  }

  saveDb(data: Kot): Promise<Kot> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && Utility.isTruthy(data)) {
          this.ngxIndexedDBService
            .add(IKot.SCHEMA_NAME, data)
            .subscribe((savedRecord: Kot) => {
              return resolve(savedRecord);
            },
            err => {
              console.error(err);
              if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                  this.router.navigate(['idbx-error']);
                } else if (typeof err?.target?.error == 'object') {
                  SentryUtilites.setLog("KotDao:saveDb", err?.target?.error)
                } else {
                  SentryUtilites.setLog("KotDao:saveDb", err)
                }
            });
        } else {
          return resolve(null);
        }
      } catch (error) {
        SentryUtilites.setLog("KotDao:saveDb", error)
        return resolve(null);
      }
    })
  }

  update(data: Kot): Promise<Kot> {
    return new Promise(async (resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?._localUUID) {
          let oldData = await this.getByUUID(data?._localUUID);
          if(oldData?._localUUID) {
            data._localId = oldData?._localId;
            let timeStamp = +new Date();
            data.updatedStamp = timeStamp
            data.userUpdatedStamp = timeStamp
            this.ngxIndexedDBService
              .update(IKot.SCHEMA_NAME, data)
              .subscribe((updatedKot: Kot) => {
                return resolve(updatedKot);
              },
              err => {
                console.error(err);
                if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                  this.router.navigate(['idbx-error']);
                } else if (typeof err?.target?.error == 'object') {
                  SentryUtilites.setLog("KotDao:update", err?.target?.error)
                } else {
                  SentryUtilites.setLog("KotDao:update", err)
                }
              });
          } else {
            return resolve(null);
          }
        } else {
          return resolve(null);
        }
      } catch (err) {
        SentryUtilites.setLog("KotDao:update", err)
        return resolve(null);
      }
    })
  }

  updateDb(data: Kot): Promise<Kot> {
    return new Promise(async (resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?._localUUID) {
          let oldData = await this.getByUUID(data?._localUUID);
          if(oldData?._localUUID) {
            data._localId = oldData?._localId;
            this.ngxIndexedDBService
              .update(IKot.SCHEMA_NAME, data)
              .subscribe((updatedRecord: Kot) => {
                return resolve(updatedRecord);
              },
              err => {
                console.error(err);
                if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                  this.router.navigate(['idbx-error']);
                } else if (typeof err?.target?.error == 'object') {
                  SentryUtilites.setLog("KotDao:updateDb", err?.target?.error)
                } else {
                  SentryUtilites.setLog("KotDao:updateDb", err)
                }
              });
          } else {
            return resolve(null);
          }
        } else {
          return resolve(null);
        }
      } catch (err) {
        SentryUtilites.setLog("KotDao:updateDb", err)
        return resolve(null);
      }
    })
  }

  bulkPut(data: Kot[]): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?.length) {
          this.ngxIndexedDBService
            .bulkPut(IKot.SCHEMA_NAME, data)
            .subscribe((data) => {
              resolve(true);
            },
            err => {
              console.error(err);
              if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                this.router.navigate(['idbx-error']);
              } else if (typeof err?.target?.error == 'object') {
                SentryUtilites.setLog("KotDao:bulkPut", err?.target?.error)
              } else {
                SentryUtilites.setLog("KotDao:bulkPut", err)
              }
            })
        } else {
          return resolve(false);
        }
      } catch (error) {
        SentryUtilites.setLog("KotDao:bulkPut", error)
        return resolve(false);
      }
    })
  }

  delete(data: Kot): Promise<Kot> {
    return new Promise(async (resolve, reject) => {
      try {
        if (Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?._localUUID) {
          let oldData = await this.getByUUID(data?._localUUID);
          data._localId = oldData?._localId;
          let timeStamp = +new Date();
          data.updatedStamp = timeStamp;
          data.userUpdatedStamp = timeStamp;
          data.deletedStamp = timeStamp;
          this.ngxIndexedDBService
            .update(IKot.SCHEMA_NAME, data)
            .subscribe((updatedKot: Kot) => {
              return resolve(updatedKot);
            },
            err => {
              console.error(err);
              if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                this.router.navigate(['idbx-error']);
              } else if (typeof err?.target?.error == 'object') {
                SentryUtilites.setLog("KotDao:delete", err?.target?.error)
              } else {
                SentryUtilites.setLog("KotDao:delete", err)
              }
            });
        } else {
          return resolve(null);
        }
      } catch (err) {
        SentryUtilites.setLog("KotDao:delete", err)
        return resolve(null);
      }
    });
  }

  getById(id: number): Promise<Kot> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && id) {
          this.ngxIndexedDBService
            .getByKey(IKot.SCHEMA_NAME, id)
            .subscribe((data: Kot) => {
              return resolve(data);
            },
            err => {
              console.error(err);
              if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                this.router.navigate(['idbx-error']);
              } else if (typeof err?.target?.error == 'object') {
                SentryUtilites.setLog("KotDao:getById", err?.target?.error)
              } else {
                SentryUtilites.setLog("KotDao:getById", err)
              }
            });
        } else {
          return resolve(null);
        }
      } catch (err) {
        SentryUtilites.setLog("KotDao:getById", err)
        return resolve(null)
      }
    })
  }

  getByUUID(uuid: string): Promise<Kot> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && uuid) {
          this.ngxIndexedDBService
            .getByIndex(IKot.SCHEMA_NAME, '_localUUID', uuid)
            .subscribe((data: Kot) => {
              return resolve(data);
            },
            err => {
              console.error(err);
              if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                this.router.navigate(['idbx-error']);
              } else if (typeof err?.target?.error == 'object') {
                SentryUtilites.setLog("KotDao:getByUUID", err?.target?.error)
              } else {
                SentryUtilites.setLog("KotDao:getByUUID", err)
              }
            });
        } else {
          return resolve(null);
        }
      } catch (err) {
        SentryUtilites.setLog("KotDao:getByUUID", err)
        return resolve(null)
      }
    })
  }

  getAll(): Promise<Kot[]> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token'))) {
          this.ngxIndexedDBService.getAll(IKot.SCHEMA_NAME).subscribe((docs: Kot[]) => {
            docs = docs.filter(x => !x.deletedStamp);
            docs.sort((a, b) => b.createdStamp - a.createdStamp);
            return resolve(docs)
          },
          err => {
            console.error(err);
            if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
              this.router.navigate(['idbx-error']);
            } else if (typeof err?.target?.error == 'object') {
              SentryUtilites.setLog("KotDao:getAll", err?.target?.error)
            } else {
              SentryUtilites.setLog("KotDao:getAll", err)
            }
          });
        } else {
          return resolve([]);
        }
      } catch (err) {
        SentryUtilites.setLog("KotDao:getAll", err)
        return resolve([])
      }
    })
  }

  getAllByProfile(profileId: string): Promise<Kot[]> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && profileId) {
          this.ngxIndexedDBService.getAll(IKot.SCHEMA_NAME).subscribe((docs: Kot[]) => {
            docs = docs.filter(x => !x.deletedStamp && x.profileId == profileId);
            docs.sort((a, b) => b.createdStamp - a.createdStamp);
            return resolve(docs)
          },
          err => {
            console.error(err);
            if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
              this.router.navigate(['idbx-error']);
            } else if (typeof err?.target?.error == 'object') {
              SentryUtilites.setLog("KotDao:getAllByProfile", err?.target?.error)
            } else {
              SentryUtilites.setLog("KotDao:getAllByProfile", err)
            }
          });
        } else {
          return resolve([]);
        }
      } catch (err) {
        SentryUtilites.setLog("KotDao:getAllByProfile", err)
        return resolve([])
      }
    })
  }

  getAllUnsynced(profileId: string): Promise<Kot[]> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token'))) {
          let unSyncedElements: Kot[] = [];
          this.ngxIndexedDBService.getAll(IKot.SCHEMA_NAME).subscribe(async (elArr: Kot[]) => {
            for (let i = 0; i < elArr?.length; i++) {
              const el = elArr[i];
              if (el?.updatedStamp > el?.syncStamp || !el?._serverIdRef) {
                unSyncedElements.push(el);
              }
            }
            return resolve(unSyncedElements)
          },
          err => {
            console.error(err);
            if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
              this.router.navigate(['idbx-error']);
            } else if (typeof err?.target?.error == 'object') {
              SentryUtilites.setLog("KotDao:getAllUnsynced", err?.target?.error)
            } else {
              SentryUtilites.setLog("KotDao:getAllUnsynced", err)
            }
          });
        } else {
          return resolve([]);
        }
      } catch (err) {
        SentryUtilites.setLog("KotDao:getAllUnsynced", err)
        return resolve([])
      }
    })
  }

  updateSyncStamp(data: Kot): Promise<Kot> {
    return new Promise(async (resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?._localUUID) {
          this.ngxIndexedDBService
          .getByIndex(IKot.SCHEMA_NAME, '_localUUID', data?._localUUID)
          .subscribe((dbEl: Kot) => {
            if(dbEl) {
                dbEl.syncStamp = data?.syncStamp || 0;
                dbEl._serverIdRef = data?._serverIdRef;
                this.ngxIndexedDBService
                  .update(IKot.SCHEMA_NAME, dbEl)
                  .subscribe((updatedEl: Kot) => {
                    return resolve(updatedEl);
                  },
                  err => {
                    console.error(err);
                    if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                      this.router.navigate(['idbx-error']);
                    } else if (typeof err?.target?.error == 'object') {
                      SentryUtilites.setLog("KotDao:updateSyncStamp", err?.target?.error)
                    } else {
                      SentryUtilites.setLog("KotDao:updateSyncStamp", err)
                    }
                  });
              } else {
                return resolve(null);
              }
            },
            err => {
              console.error(err);
              if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                this.router.navigate(['idbx-error']);
              } else if (typeof err?.target?.error == 'object') {
                SentryUtilites.setLog("KotDao:updateSyncStamp", err?.target?.error)
              } else {
                SentryUtilites.setLog("KotDao:updateSyncStamp", err)
              }
            })
        } else {
          return resolve(null);
        }
      } catch (err) {
        SentryUtilites.setLog("KotDao:updateSyncStamp", err)
        return resolve(null)
      }
    })
  }

  getAllBySaleUUID(saleUUID: string): Promise<Kot[]> {
    return new Promise(async (resolve, reject) => {
      try {
        let kots = await this.getAll();
        let filteredKots = kots.filter(x => x?.linkedSaleUUID === saleUUID);
        return resolve(filteredKots);
      } catch (error) {
        SentryUtilites.setLog("KotDao:getAllBySaleUUID", error)
        return resolve(null);
      }
    });
  }

  getLatestKotBySaleUUID(saleUUID: string): Promise<Kot> {
    return new Promise(async (resolve, reject) => {
      try {
        let kots = await this.getAll();
        let filteredKots = kots.filter(x => x?.linkedSaleUUID === saleUUID);
        filteredKots.sort((a: Kot, b: Kot) => b?.createdStamp - a.createdStamp);
        if (filteredKots?.length) {
          return resolve(filteredKots[0]);
        } else {
          return resolve(null);
        }
      } catch (error) {
        SentryUtilites.setLog("KotDao:getLatestKotBySaleUUID", error)
        return resolve(null);
      }
    });
  }

}
