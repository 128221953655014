<ion-modal 
  #modalEle
  class="non-premium-modal" 
  [ngStyle]="{'left': positionLeft ? '0px' : '289px'}" 
  [backdropDismiss]="isBackdropDismiss"
>
  <ng-template>
    <ion-header class="non-premium-modal-header">
      <ion-toolbar color="danger">
        <ion-title>{{ showMax3Creditmsg ? '3 free sale credits are used. to activate account' : 'Premium Expired' }}</ion-title>
        <!-- <ion-buttons slot="end">
          <ion-button (click)="openModal(false)">Close</ion-button>
        </ion-buttons> -->
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div class="non-premium-modal-content">
        <ion-button (click)="contactWithSales()">
          Please contact with sales
        </ion-button>
      </div>
    </ion-content>
  </ng-template>
</ion-modal>