import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Directive, ElementRef, Inject, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subscription, fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SentryUtilites } from '../utils/sentryUtilites';

@Directive({
  selector: '[clickOutSide]'
})
export class ClickOutsideDirective implements AfterViewInit, OnDestroy {

  @Output() clickOutSide = new EventEmitter();
  documentClickSub: Subscription | undefined;

  constructor(private element: ElementRef, @Inject(DOCUMENT) private document: Document) { }

  ngAfterViewInit(): void {
    try {
      // On click event check element ref and filter event are check if match clickOutSide event is not call and vice versa
      this.documentClickSub = fromEvent(this.document, 'click').pipe(filter((event) => {
        return !this.isInside(event.target as HTMLElement)
      })
      ).subscribe(() => {
        this.clickOutSide.emit(null);
      })
    } catch (error) {
      SentryUtilites.setLog("ClickOutsideDirective:ngAfterViewInit", error)
    }
    
  }

  ngOnDestroy() {
    this.documentClickSub?.unsubscribe()
  }

  isInside(elementCheck: HTMLElement) {
    return elementCheck === this.element.nativeElement || this.element.nativeElement.contains(elementCheck);
  }

}
