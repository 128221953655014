<app-pin-verification (verifyTransactionPin)="verifyTransactionPIN($event)"  #pinVerificationElement></app-pin-verification>

<!-- Sale -->

<ion-modal class="modal-sale" [isOpen]="isSaleModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar color="light">
        <ion-title>Delete <ion-chip color="tertiary"> {{ sale?.billNo }} </ion-chip> </ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="openSaleModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">

      <ion-grid>

        <ion-row>
          <ion-col class="txt-center" size="12">
            <ion-chip color="tertiary" *ngIf="sale?.moneyIns?.length">
              <span>
                <ion-checkbox
                  slot="start"
                  [disabled]="true"
                  [checked]="isDeleteSaleAttachedMoneyIns"
                  (ionChange)="setCheckboxVarValue('isDeleteSaleAttachedMoneyIns',$event)"
                ></ion-checkbox>
              </span>
              <ion-label>Also Delete Attached Money In's</ion-label>
            </ion-chip>
            <ion-chip color="tertiary" *ngIf="!sale?.moneyIns?.length">
              <ion-label>There is no Money In is Attached with this Sale</ion-label>
            </ion-chip>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col class="txt-center" size="12">
            <ion-button fill="outline" class="dashed-border txt-transform-normal">
              Sale Delete
              <ion-icon *ngIf="sale?.deletedStamp" color="success" slot="end" name="checkmark-done"></ion-icon>
            </ion-button>
          </ion-col>
        </ion-row>

        <ion-row *ngIf="isDeleteSaleAttachedMoneyIns">
          <ion-col class="txt-center" size="12">
            <ion-button fill="outline" class="dashed-border txt-transform-normal">
              All Money In's Delete
            </ion-button>
            <ion-chip color="tertiary" *ngFor="let moneyIn of sale?.moneyIns">
              {{ moneyIn?.billNo }}
              <span>
                <ion-icon *ngIf="sale?.deletedStamp" color="success" slot="end" name="checkmark-done"></ion-icon>
              </span>
            </ion-chip>
          </ion-col>
        </ion-row>

      </ion-grid>

    </ion-content>

    <ion-toolbar>
      <ion-button
        class="btn-save"
        expand="full"
        color="danger"
        (click)="deleteSale()"
      >
        <span *ngIf="!saleDeleteStarted">
          Delete
        </span>
        <span *ngIf="saleDeleteStarted && !saleDeleteCompleted">
          <ion-spinner name="lines-sharp-small"></ion-spinner>
        </span>
        <span *ngIf="saleDeleteCompleted">
          Deleted
        </span>
      </ion-button>
    </ion-toolbar>
  </ng-template>
</ion-modal>

<!-- -------------------------------------------------------------------------------------- -->

<!-- Estimate -->

<ion-modal class="modal-sale" [isOpen]="isEstimateModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar color="light">
        <ion-title>Delete <ion-chip color="tertiary"> {{ estimate?.billNo }} </ion-chip> </ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="openEstimateModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">

      <ion-grid>
        <ion-row>
          <ion-col class="txt-center" size="12">
            <ion-button fill="outline" class="dashed-border txt-transform-normal">
              Estimate Delete
              <ion-icon *ngIf="estimate?.deletedStamp" color="success" slot="end" name="checkmark-done"></ion-icon>
            </ion-button>
          </ion-col>
        </ion-row>

      </ion-grid>

    </ion-content>

    <ion-toolbar>
      <ion-button
        class="btn-save"
        expand="full"
        color="danger"
        (click)="deleteEstimate()"
      >
        <span *ngIf="!estimateDeleteStarted">
          Delete
        </span>
        <span *ngIf="estimateDeleteStarted && !estimateDeleteCompleted">
          <ion-spinner name="lines-sharp-small"></ion-spinner>
        </span>
        <span *ngIf="estimateDeleteCompleted">
          Deleted
        </span>
      </ion-button>
    </ion-toolbar>
  </ng-template>
</ion-modal>

<!-- -------------------------------------------------------------------------------------- -->

<!-- Expense -->

<ion-modal class="modal-expense" [isOpen]="isExpenseModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar color="light">
        <ion-title>Delete <ion-chip color="tertiary"> {{ expense?.billNo }} </ion-chip> </ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="openExpenseModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">

      <ion-grid>

        <ion-row>
          <ion-col class="txt-center" size="12">
            <ion-chip color="tertiary" *ngIf="expense?.moneyOuts?.length">
              <span>
                <ion-checkbox
                  slot="start"
                  [disabled]="true"
                  [checked]="isDeleteExpenseAttachedMoneyOuts"
                  (ionChange)="setCheckboxVarValue('isDeleteExpenseAttachedMoneyOuts',$event)"
                ></ion-checkbox>
              </span>
              <ion-label>Also Delete Attached Money Out's</ion-label>
            </ion-chip>
            <ion-chip color="tertiary" *ngIf="!expense?.moneyOuts?.length">
              <ion-label>There is no Money Out is Attached with this Expense</ion-label>
            </ion-chip>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col class="txt-center" size="12">
            <ion-button fill="outline" class="dashed-border txt-transform-normal">
              Expense Delete
              <ion-icon *ngIf="expense?.deletedStamp" color="success" slot="end" name="checkmark-done"></ion-icon>
            </ion-button>
          </ion-col>
        </ion-row>

        <ion-row *ngIf="isDeleteExpenseAttachedMoneyOuts">
          <ion-col class="txt-center" size="12">
            <ion-button fill="outline" class="dashed-border txt-transform-normal">
              All Money Out's Delete
            </ion-button>
            <ion-chip color="tertiary" *ngFor="let moneyOut of expense?.moneyOuts">
              {{ moneyOut?.billNo }}
              <span>
                <ion-icon *ngIf="moneyOut?.deletedStamp" color="success" slot="end" name="checkmark-done"></ion-icon>
              </span>
            </ion-chip>
          </ion-col>
        </ion-row>

      </ion-grid>

    </ion-content>

    <ion-toolbar>
      <ion-button
        class="btn-save"
        expand="full"
        color="danger"
        (click)="deleteExpense()"
      >
        <span *ngIf="!expenseDeleteStarted">
          Delete
        </span>
        <span *ngIf="expenseDeleteStarted && !expenseDeleteCompleted">
          <ion-spinner name="lines-sharp-small"></ion-spinner>
        </span>
        <span *ngIf="expenseDeleteCompleted">
          Deleted
        </span>
      </ion-button>
    </ion-toolbar>
  </ng-template>
</ion-modal>

<!-- -------------------------------------------------------------------------------------- -->

<!-- Purchase -->

<ion-modal class="modal-purchase" [isOpen]="isPurchaseModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar color="light">
        <ion-title>Delete <ion-chip color="tertiary"> {{ purchase?.billNo }} </ion-chip> </ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="openPurchaseModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">

      <ion-grid>

        <ion-row>
          <ion-col class="txt-center" size="12">
            <ion-chip color="tertiary" *ngIf="purchase?.moneyOuts?.length">
              <span>
                <ion-checkbox
                  slot="start"
                  [disabled]="true"
                  [checked]="isDeletePurchaseAttachedMoneyOuts"
                  (ionChange)="setCheckboxVarValue('isDeletePurchaseAttachedMoneyOuts',$event)"
                ></ion-checkbox>
              </span>
              <ion-label>Also Delete Attached Money Out's</ion-label>
            </ion-chip>
            <ion-chip color="tertiary" *ngIf="!purchase?.moneyOuts?.length">
              <ion-label>There is no Money Out is Attached with this Purchase</ion-label>
            </ion-chip>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col class="txt-center" size="12">
            <ion-button fill="outline" class="dashed-border txt-transform-normal">
              Purchase Delete
              <ion-icon *ngIf="purchase?.deletedStamp" color="success" slot="end" name="checkmark-done"></ion-icon>
            </ion-button>
          </ion-col>
        </ion-row>

        <ion-row *ngIf="isDeletePurchaseAttachedMoneyOuts">
          <ion-col class="txt-center" size="12">
            <ion-button fill="outline" class="dashed-border txt-transform-normal">
              All Money Out's Delete
            </ion-button>
            <ion-chip color="tertiary" *ngFor="let moneyOut of purchase?.moneyOuts">
              {{ moneyOut?.billNo }}
              <span>
                <ion-icon *ngIf="purchase?.deletedStamp" color="success" slot="end" name="checkmark-done"></ion-icon>
              </span>
            </ion-chip>
          </ion-col>
        </ion-row>

      </ion-grid>

    </ion-content>

    <ion-toolbar>
      <ion-button
        class="btn-save"
        expand="full"
        color="danger"
        (click)="deletePurchase()"
      >
        <span *ngIf="!purchaseDeleteStarted">
          Delete
        </span>
        <span *ngIf="purchaseDeleteStarted && !purchaseDeleteCompleted">
          <ion-spinner name="lines-sharp-small"></ion-spinner>
        </span>
        <span *ngIf="purchaseDeleteCompleted">
          Deleted
        </span>
      </ion-button>
    </ion-toolbar>
  </ng-template>
</ion-modal>

<!-- -------------------------------------------------------------------------------------- -->

<!-- Money In -->

<ion-modal class="modal-money-in" [isOpen]="isMoneyInModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar color="light">
        <ion-title>Delete <ion-chip color="tertiary"> {{ moneyIn?.billNo }} </ion-chip> </ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="openMoneyInModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">

      <ion-grid>

        <ion-row>
          <ion-col class="txt-center" size="12">
            <ion-button fill="outline" class="dashed-border txt-transform-normal">
              Money In Delete
              <ion-icon *ngIf="moneyIn?.deletedStamp" color="success" slot="end" name="checkmark-done"></ion-icon>
            </ion-button>
          </ion-col>
        </ion-row>

      </ion-grid>

    </ion-content>

    <ion-toolbar>
      <ion-button
        class="btn-save"
        expand="full"
        color="danger"
        (click)="deleteMoneyIn()"
      >
        <span *ngIf="!moneyInDeleteStarted">
          Delete
        </span>
        <span *ngIf="moneyInDeleteStarted && !moneyInDeleteCompleted">
          <ion-spinner name="lines-sharp-small"></ion-spinner>
        </span>
        <span *ngIf="moneyInDeleteCompleted">
          Deleted
        </span>
      </ion-button>
    </ion-toolbar>
  </ng-template>
</ion-modal>

<!-- -------------------------------------------------------------------------------------- -->

<!-- Money Out -->

<ion-modal class="modal-money-out" [isOpen]="isMoneyOutModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar color="light">
        <ion-title>Delete <ion-chip color="tertiary"> {{ moneyOut?.billNo }} </ion-chip> </ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="openMoneyOutModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">

      <ion-grid>

        <ion-row>
          <ion-col class="txt-center" size="12">
            <ion-button fill="outline" class="dashed-border txt-transform-normal">
              Money Out Delete
              <ion-icon *ngIf="moneyOut?.deletedStamp" color="success" slot="end" name="checkmark-done"></ion-icon>
            </ion-button>
          </ion-col>
        </ion-row>

      </ion-grid>

    </ion-content>

    <ion-toolbar>
      <ion-button
        class="btn-save"
        expand="full"
        color="danger"
        (click)="deleteMoneyOut()"
      >
        <span *ngIf="!moneyOutDeleteStarted">
          Delete
        </span>
        <span *ngIf="moneyOutDeleteStarted && !moneyOutDeleteCompleted">
          <ion-spinner name="lines-sharp-small"></ion-spinner>
        </span>
        <span *ngIf="moneyOutDeleteCompleted">
          Deleted
        </span>
      </ion-button>
    </ion-toolbar>
  </ng-template>
</ion-modal>

<!-- -------------------------------------------------------------------------------------- -->

<!-- Party -->

<ion-modal class="modal-party" [isOpen]="isPartyModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar color="light">
        <ion-title>Delete Party <ion-chip color="tertiary"> {{ party?.name }} | {{ party?.phone }} </ion-chip> </ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="openPartyModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">

      <ion-grid>

        <ion-row>
          <ion-col class="txt-center" size="12">
            <ion-chip color="tertiary">
              <span>
                <ion-checkbox
                  slot="start"
                  [checked]="isDeletePartyAttachedSales"
                  (ionChange)="setCheckboxVarValue('isDeletePartyAttachedSales',$event)"
                ></ion-checkbox>
              </span>
              <ion-label>Delete All Sale's Attached with Party</ion-label>
            </ion-chip>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col class="txt-center" size="12">
            <ion-chip color="tertiary">
              <span>
                <ion-checkbox
                  slot="start"
                  [checked]="isDeletePartyAttachedMoneyIns"
                  (ionChange)="setCheckboxVarValue('isDeletePartyAttachedMoneyIns',$event)"
                ></ion-checkbox>
              </span>
              <ion-label>Delete All Money In's Attached with Party</ion-label>
            </ion-chip>
          </ion-col>
        </ion-row>

      </ion-grid>

    </ion-content>

    <ion-toolbar>
      <ion-button
        class="btn-save"
        expand="full"
        color="danger"
        (click)="deleteParty()"
      >
        <span *ngIf="!partyDeleteStarted">
          Delete
        </span>
        <span *ngIf="partyDeleteStarted && !partyDeleteCompleted">
          <ion-spinner name="lines-sharp-small"></ion-spinner>
        </span>
        <span *ngIf="partyDeleteCompleted">
          Deleted
        </span>
      </ion-button>
    </ion-toolbar>
  </ng-template>
</ion-modal>

<!-- -------------------------------------------------------------------------------------- -->

<!-- Money In -->

<ion-modal class="modal-item" [isOpen]="isItemModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar color="light">
        <ion-title>Delete <ion-chip color="tertiary"> {{ item?.itemName }} </ion-chip> </ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="openItemModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">

      <ion-grid>

        <ion-row>
          <ion-col class="txt-center" size="12">
            <ion-button fill="outline" class="dashed-border txt-transform-normal">
              Item Delete
              <ion-icon *ngIf="item?.deletedStamp" color="success" slot="end" name="checkmark-done"></ion-icon>
            </ion-button>
          </ion-col>
        </ion-row>

      </ion-grid>

    </ion-content>

    <ion-toolbar>
      <ion-button
        class="btn-save"
        expand="full"
        color="danger"
        (click)="deleteItem()"
      >
        <span *ngIf="!itemDeleteStarted">
          Delete
        </span>
        <span *ngIf="itemDeleteStarted && !itemDeleteCompleted">
          <ion-spinner name="lines-sharp-small"></ion-spinner>
        </span>
        <span *ngIf="itemDeleteCompleted">
          Deleted
        </span>
      </ion-button>
    </ion-toolbar>
  </ng-template>
</ion-modal>

<!-- -------------------------------------------------------------------------------------- -->

<!-- Bulk Sale -->

<ion-modal class="modal-bulk-sale" [isOpen]="isBulkSaleModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar color="light">
        <ion-title>Delete Sales</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="openBulkSaleModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">

      <ion-grid>

        <ion-row>
          <ion-col size="12">
            <ion-chip color="tertiary" *ngIf="bulkSales?.length">
              <span>
                <ion-checkbox
                  slot="start"
                  disabled="true"
                  [checked]="isDeleteBulkSaleAttachedMoneyIns"
                  (ionChange)="setCheckboxVarValue('isDeleteBulkSaleAttachedMoneyIns',$event)"
                ></ion-checkbox>
              </span>
              <ion-label>Also Delete Attached Money In's</ion-label>
            </ion-chip>

            <!-- Select all sales -->
            <ion-chip class="select-all-checkbox" color="tertiary" *ngIf="bulkSales?.length">
              <ion-item>
                <ion-checkbox
                  slot="start"
                  [checked]="selectedBulkSales.length === bulkSales.length"
                  (ionChange)="selectAllSaleCheckboxes($event)"
                  (click)="selectAllSaleCheckboxClick()"
                ></ion-checkbox>
                <ion-label>Select All</ion-label>
              </ion-item>
            </ion-chip>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col size="12">
            <ion-item lines="none" *ngFor="let sale of bulkSales">
              <ion-checkbox
                slot="start"
                #bulkSalesCheckedEle
                (ionChange)="addToSelectedBulkSaleArr(sale,$event)"
                [checked]="sale['checked']"
                ></ion-checkbox>
              <ion-label>
                <ion-chip color="primary">{{ sale.billNo }} | {{ sale.billDateStamp | date: 'dd/MM/yy' }}</ion-chip>
                <ion-chip color="primary">{{ sale?.party?.name }}</ion-chip>
                <ion-chip color="primary">{{ sale?.totalAmount | currency: 'INR' }}</ion-chip>
              </ion-label>
            </ion-item>
          </ion-col>
        </ion-row>

      </ion-grid>

    </ion-content>

    <ion-toolbar>
      <ion-button
        class="btn-save"
        expand="full"
        color="danger"
        (click)="deleteBulkSale()"
      >
        <span *ngIf="!bulkSaleDeleteStarted">
          Delete
        </span>
        <span *ngIf="bulkSaleDeleteStarted && !bulkSaleDeleteCompleted">
          <ion-spinner name="lines-sharp-small"></ion-spinner>
        </span>
        <span *ngIf="bulkSaleDeleteCompleted">
          Deleted
        </span>
      </ion-button>
    </ion-toolbar>
  </ng-template>
</ion-modal>

<!-- -------------------------------------------------------------------------------------- -->

<!-- Bulk Expense -->

<ion-modal class="modal-bulk-sale" [isOpen]="isBulkExpenseModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar color="light">
        <ion-title>Delete Expenses</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="openBulkExpenseModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">

      <ion-grid>

        <ion-row>
          <ion-col size="12">
            <ion-chip color="tertiary" *ngIf="bulkExpenses?.length">
              <span>
                <ion-checkbox
                  slot="start"
                  disabled="true"
                  [checked]="isDeleteBulkExpenseAttachedMoneyOuts"
                  (ionChange)="setCheckboxVarValue('isDeleteBulkExpenseAttachedMoneyOuts',$event)"
                ></ion-checkbox>
              </span>
              <ion-label>Also Delete Attached Money Out's</ion-label>
            </ion-chip>

            <!-- Select all Expenses-->
            <ion-chip class="select-all-checkbox" color="tertiary" *ngIf="bulkExpenses?.length">
              <ion-item>
                <ion-checkbox
                  slot="start"
                  [checked]="selectedBulkExpenses.length === bulkExpenses.length"
                  (ionChange)="selectAllExpenseCheckboxes($event)"
                  (click)="selectAllExpenseCheckboxClick()"
                ></ion-checkbox>
                <ion-label>Select All</ion-label>
              </ion-item>
            </ion-chip>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col size="12">
            <ion-item lines="none" *ngFor="let expense of bulkExpenses">
              <ion-checkbox
                slot="start"
                (ionChange)="addToSelectedBulkExpenseArr(expense,$event)"
                [checked]="expense['checked']"
              ></ion-checkbox>
              <ion-label>
                <ion-chip color="primary">{{ expense.billNo }} | {{ expense.billDateStamp | date: 'dd/MM/yy' }}</ion-chip>
                <ion-chip color="primary">{{ expense?.party?.name }}</ion-chip>
                <ion-chip color="primary">{{ expense?.totalAmount | currency: 'INR' }}</ion-chip>
              </ion-label>
            </ion-item>
          </ion-col>
        </ion-row>

      </ion-grid>

    </ion-content>

    <ion-toolbar>
      <ion-button
        class="btn-save"
        expand="full"
        color="danger"
        (click)="deleteBulkExpense()"
      >
        <span *ngIf="!bulkExpenseDeleteStarted">
          Delete
        </span>
        <span *ngIf="bulkExpenseDeleteStarted && !bulkExpenseDeleteCompleted">
          <ion-spinner name="lines-sharp-small"></ion-spinner>
        </span>
        <span *ngIf="bulkExpenseDeleteCompleted">
          Deleted
        </span>
      </ion-button>
    </ion-toolbar>
  </ng-template>
</ion-modal>

<!-- -------------------------------------------------------------------------------------- -->

<!-- Bulk Purchase -->

<ion-modal class="modal-bulk-purchase" [isOpen]="isBulkPurchaseModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar color="light">
        <ion-title>Delete Purchases</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="openBulkPurchaseModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">

      <ion-grid>

        <ion-row>
          <ion-col size="12">
            <ion-chip color="tertiary" *ngIf="bulkPurchases?.length">
              <span>
                <ion-checkbox
                  slot="start"
                  [disabled]="true"
                  [checked]="isDeleteBulkPurchaseAttachedMoneyOuts"
                  (ionChange)="setCheckboxVarValue('isDeleteBulkPurchaseAttachedMoneyOuts',$event)"
                ></ion-checkbox>
              </span>
              <ion-label>Also Delete Attached Money Out's</ion-label>
            </ion-chip>
            <!-- Select all Purchase-->
            <ion-chip class="select-all-checkbox" color="tertiary" *ngIf="bulkPurchases?.length">
              <ion-item>
                <ion-checkbox
                  slot="start"
                  [checked]="selectedBulkPurchases.length === bulkPurchases?.length"
                  (ionChange)="selectAllPurchaseCheckboxes($event)"
                  (click)="selectAllPurchaseCheckboxClick()"
                ></ion-checkbox>
                <ion-label>Select All</ion-label>
              </ion-item>
            </ion-chip>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col size="12">
            <ion-item lines="none" *ngFor="let purchase of bulkPurchases">
              <ion-checkbox
                slot="start"
                (ionChange)="addToSelectedBulkPurchaseArr(purchase,$event)"
                [checked]="purchase['checked']"
              ></ion-checkbox>
              <ion-label>
                <ion-chip color="primary">{{ purchase.billNo }} | {{ purchase.billDateStamp | date: 'dd/MM/yy' }}</ion-chip>
                <ion-chip color="primary">{{ purchase?.party?.name }}</ion-chip>
                <ion-chip color="primary">{{ purchase?.totalAmount | currency: 'INR' }}</ion-chip>
              </ion-label>
            </ion-item>
          </ion-col>
        </ion-row>

      </ion-grid>

    </ion-content>

    <ion-toolbar>
      <ion-button
        class="btn-save"
        expand="full"
        color="danger"
        (click)="deleteBulkPurchase()"
      >
        <span *ngIf="!bulkPurchaseDeleteStarted">
          Delete
        </span>
        <span *ngIf="bulkPurchaseDeleteStarted && !bulkPurchaseDeleteCompleted">
          <ion-spinner name="lines-sharp-small"></ion-spinner>
        </span>
        <span *ngIf="bulkPurchaseDeleteCompleted">
          Deleted
        </span>
      </ion-button>
    </ion-toolbar>
  </ng-template>
</ion-modal>

<!-- -------------------------------------------------------------------------------------- -->

<!-- Bulk Money In -->

<ion-modal class="modal-bulk-money-in" [isOpen]="isBulkMoneyInModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar color="light">
        <ion-title>Delete Money In's</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="openBulkMoneyInModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">

      <ion-grid>

        <ion-row>
          <ion-col size="12">
            <ion-item lines="none" *ngFor="let moneyIn of bulkMoneyIns">
              <ion-checkbox
                slot="start"
                (ionChange)="addToSelectedBulkMoneyInArr(moneyIn,$event)"
              ></ion-checkbox>
              <ion-label>
                <ion-chip color="primary">{{ moneyIn.billNo }} | {{ moneyIn.billDateStamp | date: 'dd/MM/yy' }}</ion-chip>
                <ion-chip color="primary">{{ moneyIn?.party?.name }}</ion-chip>
                <ion-chip color="primary">{{ moneyIn?.totalAmount | currency: 'INR' }}</ion-chip>
              </ion-label>
            </ion-item>
          </ion-col>
        </ion-row>

      </ion-grid>

    </ion-content>

    <ion-toolbar>
      <ion-button
        class="btn-save"
        expand="full"
        color="danger"
        (click)="deleteBulkMoneyIn()"
      >
        <span *ngIf="!bulkMoneyInDeleteStarted">
          Delete
        </span>
        <span *ngIf="bulkMoneyInDeleteStarted && !bulkMoneyInDeleteCompleted">
          <ion-spinner name="lines-sharp-small"></ion-spinner>
        </span>
        <span *ngIf="bulkMoneyInDeleteCompleted">
          Deleted
        </span>
      </ion-button>
    </ion-toolbar>
  </ng-template>
</ion-modal>

<!-- -------------------------------------------------------------------------------------- -->

<!-- Bulk Money Out -->

<ion-modal class="modal-bulk-money-in" [isOpen]="isBulkMoneyOutModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar color="light">
        <ion-title>Delete Money Out's</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="openBulkMoneyOutModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">

      <ion-grid>

        <ion-row>
          <ion-col size="12">
            <ion-item lines="none" *ngFor="let moneyOut of bulkMoneyOuts">
              <ion-checkbox
                slot="start"
                (ionChange)="addToSelectedBulkMoneyOutArr(moneyOut,$event)"
              ></ion-checkbox>
              <ion-label>
                <ion-chip color="primary">{{ moneyOut.billNo }} | {{ moneyOut.billDateStamp | date: 'dd/MM/yy' }}</ion-chip>
                <ion-chip color="primary">{{ moneyOut?.party?.name }}</ion-chip>
                <ion-chip color="primary">{{ moneyOut?.totalAmount | currency: 'INR' }}</ion-chip>
              </ion-label>
            </ion-item>
          </ion-col>
        </ion-row>

      </ion-grid>

    </ion-content>

    <ion-toolbar>
      <ion-button
        class="btn-save"
        expand="full"
        color="danger"
        (click)="deleteBulkMoneyOut()"
      >
        <span *ngIf="!bulkMoneyOutDeleteStarted">
          Delete
        </span>
        <span *ngIf="bulkMoneyOutDeleteStarted && !bulkMoneyOutDeleteCompleted">
          <ion-spinner name="lines-sharp-small"></ion-spinner>
        </span>
        <span *ngIf="bulkMoneyOutDeleteCompleted">
          Deleted
        </span>
      </ion-button>
    </ion-toolbar>
  </ng-template>
</ion-modal>

<!-- -------------------------------------------------------------------------------------- -->
