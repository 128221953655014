<ion-header>
  <ion-toolbar class="{{ getHeaderColorClass() }}">
    <app-navigation-control slot="start" type="party/form" (click)="dpDobValue = null; keepDateContentsMounted = false;"
      [_localUUID]="paramDocumentId"></app-navigation-control>
    <ion-title>Create Party</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>

  <ion-grid style="padding-bottom: 32px;">

    <form [formGroup]="form" class="form-custom-css">
      <ion-row class="party-form-col-reverse">

        <!-- Customer/Supplier Name -->

        <ion-col size-md="8" size-xs="12">
          <ion-button fill="clear" class="btn-title btn-txt-black">
            Customer/Supplier Name<span class="span-mandatory">*</span>
          </ion-button>
          <ion-input 
            appColorFormValidation 
            controlName="noSpaceAtStart" 
            inputDesign="box" 
            #partyNameEle 
            placeholder="Customer/Supplier Name" 
            formControlName="name"
            (ionBlur)="onPartyNameChange()"></ion-input>
        </ion-col>

        <!-- --------------------------------------------------- -->

        <!-- Item Type Customer/Supplier -->

        <ion-col size-md="4" size-xs="12">
          <ion-button fill="clear" class="btn-title btn-txt-black">
            Type
          </ion-button>
          <ion-segment mode="ios" formControlName="type">
            <ion-segment-button value="customer">
              <ion-label>Customer</ion-label>
            </ion-segment-button>
            <ion-segment-button value="supplier">
              <ion-label>Supplier</ion-label>
            </ion-segment-button>
          </ion-segment>
        </ion-col>

        <!-- ------------------------------------------------------------- -->

      </ion-row>

      <ion-row>

        <!-- Phone number -->

        <ion-col size-md="3" size-xs="12">
          <ion-button fill="clear" class="btn-title btn-txt-black">
            Phone Number
          </ion-button>
          <ion-input 
            appColorFormValidation 
            controlName="phone" 
            inputDesign="box" 
            formControlName="phone" 
            placeholder="Phone" 
            (ionBlur)="onPartyPhoneChange()">
          </ion-input>
        </ion-col>

        <!-- ------------------------------------------------ -->

        <!-- Category -->

        <ion-col size-md="4.5" size-xs="12">
          <ion-button fill="clear" class="btn-title btn-txt-black">
            Category
          </ion-button>
          <ion-select 
            [disabled]="!categories?.length"
            [interfaceOptions]="{header: 'Category'}" 
            interface="alert" 
            placeholder="Select" 
            formControlName="category" 
            class="new-ion-select">
            <ion-select-option *ngFor="let category of categories" [value]="category">
              {{ category }}
            </ion-select-option>
          </ion-select>
        </ion-col>

        <!-- -------------------------------------------------- -->

        <!-- Add new category -->

        <ion-col size-md="4.5" size-xs="12">
          <ion-button fill="clear" class="btn-title btn-txt-black">
            Add New Category
          </ion-button>
          <ion-input #addNewCategoryEle placeholder="Example: Vegetarian" [disabled]="!isCreatePartyCategoryPermission">
          </ion-input>
          <ion-button class="inp-insider-btn" [disabled]="!addNewCategoryEle?.value" size="small" color="primary"
            (click)="addCategory()">Add</ion-button>
        </ion-col>

        <!-- ---------------------------------------------------- -->

      </ion-row>

      <ion-row class="exclude-form-custom-css">
        <ion-col size-md="12">
          <ion-button fill="outline" class="dashed-border" (click)="toggleShowAllFields()">
            GST & Address
            <ion-icon *ngIf="!showAllFields" name="chevron-down-outline"></ion-icon>
            <ion-icon *ngIf="showAllFields" name="chevron-up-outline"></ion-icon>
          </ion-button>
        </ion-col>
      </ion-row>

      <ion-row *ngIf="showAllFields">

        <!-- GSTIN -->

        <ion-col size-md="3">
          <ion-button fill="clear" class="btn-title btn-txt-black">
            GSTIN (If Applicable)
          </ion-button>
          <ion-input 
            appColorFormValidation 
            controlName="gstin" 
            inputDesign="box" 
            placeholder="GSTIN" 
            formControlName="gstin">
          </ion-input>
        </ion-col>

        <!-- ---------------------------------------------------------------- -->

        <!-- Bill Type -->

        <ion-col size-md="4" size-xs="12">
          <ion-button fill="clear" class="btn-title btn-txt-black">
            Bill Type
          </ion-button>
          <ion-select [interfaceOptions]="{header: 'Bill Type'}" interface="alert" class="new-ion-select" placeholder="Select" formControlName="billingType">
            <ion-select-option *ngFor="let type of billingType|keyvalue : returnZero" [value]="type.key">
              {{ type.value }}
            </ion-select-option>
          </ion-select>
        </ion-col>

        <!-- ---------------------------------------------------------- -->

        <!-- Billing Term -->

        <ion-col size-md="3" size-xs="12">
          <ion-button fill="clear" class="btn-title btn-txt-black">
            Billing Term
          </ion-button>
          <ion-select [interfaceOptions]="{header: 'Billing Term'}" class="new-ion-select" interface="alert" placeholder="Select" formControlName="paymentTerm">
            <ion-select-option *ngFor="let term of dueNetObj|keyvalue : returnZero" [value]="term.key">
              {{ term.value.title }}
            </ion-select-option>
          </ion-select>
        </ion-col>

        <!-- ---------------------------------------------------------- -->

        <!-- Date of Birth -->

        <ion-col size-md="2" size-xs="12">
          <ion-button fill="clear" class="btn-title btn-txt-black">
            Date of Birth
          </ion-button>
          <ion-label>
            <ion-chip style="margin: 0;" class="new-ion-select" *ngIf="!form?.value?.dateOfBirth"
              (click)="openDOBPicker()">Select
              Date</ion-chip>
          </ion-label>
          <ion-label>
            <div class="new-ion-select date-of-birth" *ngIf="form?.value?.dateOfBirth">
              <ion-input type="button" [value]="showDayMonthValue(form?.value?.dateOfBirth)" (click)="openDOBPicker()">
              </ion-input>
            </div>
          </ion-label>
        </ion-col>

        <!-- ------------------------------------------------------------- -->

      </ion-row>

      <ion-row *ngIf="showAllFields">

        <!-- Customer Business Name -->

        <ion-col size-md="6" size-xs="12">
          <ion-button fill="clear" class="btn-title btn-txt-black">
            Customer Business Name
          </ion-button>
          <ion-input placeholder="Business Name" formControlName="businessName"></ion-input>
        </ion-col>

        <!-- ------------------------------------------------------------- -->

        <!-- Customer Email -->

        <ion-col size-md="6" size-xs="12">
          <ion-button fill="clear" class="btn-title btn-txt-black">
            Customer Email
          </ion-button>
          <ion-input 
            appColorFormValidation 
            controlName="email" 
            inputDesign="box" 
            placeholder="Email" 
            formControlName="email">
          </ion-input>
        </ion-col>

        <!-- -------------------------------------------------------- -->

      </ion-row>

      <ion-row *ngIf="showAllFields">
        <!-- Billing Address -->
        <ion-col size-md="12">
          <ion-button fill="clear" class="btn-title btn-txt-black">
            Billing Address
          </ion-button>
          <ion-textarea rows="4" class="new-ion-textarea" placeholder="Billing Address"
            formControlName="billingAddress"></ion-textarea>
        </ion-col>
        <!-- --------------------------------------------------------- -->

        <!-- Billing State -->

        <ion-col size-md="3" size-xs="12">
          <ion-button fill="clear" class="btn-title btn-txt-black">
            Billing State
          </ion-button>
          <ion-select [interfaceOptions]="{header: 'Billing State'}" class="new-ion-select" interface="alert" placeholder="Select" formControlName="billingProvience">
            <ion-select-option 
              *ngFor="let state of states|keyvalue : returnZero" 
              [value]="state.key"
              [disabled]="isDisabledState(state?.value)">
              {{ state.value }}
            </ion-select-option>
          </ion-select>
        </ion-col>

        <!-- --------------------------------------------------------------- -->

        <!-- Billing Pincode -->

        <ion-col size-md="2" size-xs="12">
          <ion-button fill="clear" class="btn-title btn-txt-black">
            Billing Pincode
          </ion-button>
          <ion-input 
            appColorFormValidation 
            controlName="pincode" 
            inputDesign="box" 
            placeholder="Billing Pincode" 
            formControlName="billingPostalCode">
          </ion-input>
        </ion-col>

        <!-- --------------------------------------------------------- -->

      </ion-row>

      <ion-row *ngIf="showAllFields">

        <!-- Same as Billing Address -->

        <ion-col class="check-box-with-title" size="12">
          <ion-checkbox labelPlacement="end" (ionChange)="onClickedSBA($event)"></ion-checkbox>
          <ion-button fill="clear" class="btn-title btn-txt-black">
            Same as Billing Address
          </ion-button>
        </ion-col>

        <!-- --------------------------------------------------------------- -->

      </ion-row>

      <ion-row *ngIf="showAllFields">

        <!-- Delivery Address -->

        <ion-col size-md="12">
          <ion-button class="btn-title btn-txt-black" fill="clear">
            Delivery Address
          </ion-button>
          <ion-textarea rows="4" class="new-ion-textarea" placeholder="Delivery Address"
            formControlName="deliveryAddress"></ion-textarea>
        </ion-col>

        <!-- ---------------------------------------------------------- -->

        <!-- Delivery State -->

        <ion-col size-md="3" size-xs="12">
          <ion-button class="btn-title btn-txt-black" fill="clear">
            Delivery State
          </ion-button>
          <ion-select [interfaceOptions]="{header: 'Delivery State'}" class="new-ion-select" interface="alert" placeholder="Select" formControlName="deliveryProvience">
            <ion-select-option 
              *ngFor="let state of states|keyvalue : returnZero" 
              [value]="state.key"
              [disabled]="isDisabledState(state?.value)">
              {{ state.value }}
            </ion-select-option>
          </ion-select>
        </ion-col>

        <!-- ----------------------------------------------------------- -->

        <!-- Delivery Pincode -->

        <ion-col size-md="2.5" size-xl="2" size-xs="12">
          <ion-button class="btn-title btn-txt-black" fill="clear">
            Delivery Pincode
          </ion-button>
          <ion-input 
            appColorFormValidation 
            controlName="pincode" 
            inputDesign="box" 
            placeholder="Delivery Pincode" 
            formControlName="deliveryPostalCode">
          </ion-input>
        </ion-col>

        <!-- ------------------------------------------------------------ -->

      </ion-row>

      <ion-row *ngIf="showAllFields">

        <!-- Send Whatsapp Alerts -->

        <ion-col size-md="3" size-xs="12">
          <ion-button fill="clear" class="btn-title btn-txt-black">
            Send Whatsapp Alerts
          </ion-button>
          <ion-segment mode="ios" formControlName="sendAlerts" (ionChange)="onSendAlertsChange($event)">
            <ion-segment-button value="true">
              <ion-label>Yes</ion-label>
            </ion-segment-button>
            <ion-segment-button value="false">
              <ion-label>No</ion-label>
            </ion-segment-button>
          </ion-segment>
        </ion-col>

        <!-- ------------------------------------------------------------ -->

      </ion-row>

      <ion-row class="exclude-form-custom-css">
        <ion-col size-md="12">
          <ion-button
            [ngStyle]="checkPlatform?.userAgentData?.platform == 'Android' ? {'width': '100%', 'right': '0'} : {}"
            class="btn-save-party" expand="full" [disabled]="form?.invalid"
            (click)="presentSamePartyNamePhoneConfirm()">Save Party</ion-button>
        </ion-col>
      </ion-row>

    </form>


  </ion-grid>

</ion-content>

<ion-modal class="modal-dob" [isOpen]="isDOBDateModalOpen" [keepContentsMounted]="keepDateContentsMounted"
  (willDismiss)="closeDOBPicker($event)">
  <ng-template>
    <ion-datetime appYearDefaultBtn appHideYear id="dateOfBirth" [showDefaultButtons]="true" presentation="date" [yearValues]="2000" [preferWheel]="true"
      [value]="dpDobValue" (ionChange)="onDateOfBirthSelect($event)" (ionCancel)="onCancelDateOfBirth($event)">
      <span slot="title">Select a Date of Birth</span>
    </ion-datetime>
  </ng-template>
</ion-modal>