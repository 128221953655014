<ion-header>
  <ion-toolbar>
    <app-navigation-control slot="start" type="expense/form"></app-navigation-control>
    <ion-title>Expense Form</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>

  <!-- bill date modal start -->
  <ion-modal 
    class="date-modal" 
    [keepContentsMounted]="keepContentsMounted" 
    [isOpen]="isOpenBillDatePicker" 
    (willDismiss)="closeDOBPicker()">
    <ng-template>
      <ion-datetime
        appYearDefaultBtn   
        [showDefaultButtons]="true"
        [max]="maxDate"
        [value]="dpBillDateValue"
        (ionChange)="onBillDateSelect($event)"
        presentation="date"
      >
        <span slot="title">Select a Expense Date</span>
      </ion-datetime>
    </ng-template>
  </ion-modal>
  <!-- bill date modal end -->

  <ion-grid>

    <form [formGroup]="form">
  
      <ion-row>
        <ion-col size-lg="6">
          <ion-item lines="none">
            <ion-label>Expense No</ion-label>
            <ion-input
              class="ion-input-class"
              [disabled]="paramDocumentId ? true : false"
              placeholder="Expense No"
              formControlName="billNo"
            ></ion-input>
          </ion-item>
        </ion-col>
        <ion-col size-lg="6">
          <ion-item lines="none">
            <ion-label>Expense Date</ion-label>

            <!-- bill date input start -->
            <input 
              type="button" 
              class="date-input"
              [value]="setDateValue(form?.value?.billDateStamp || todayDate)"
              (click)="isOpenBillDatePicker = true;">
            <!-- bill date input end -->

          </ion-item>
        </ion-col>
      </ion-row>
  
      <ion-row>
        <ion-col size-xs="12" size-lg="12">
          <app-party-selector
            #partySelector
            (partySelectedEvent)="onPartySelect($event)"
          >
          </app-party-selector>
        </ion-col>
      </ion-row>
  
      <ion-row>
        <ion-col size-lg="4">
          <ion-item lines="none">
            <ion-label>
              Category<span class="span-mandatory">*</span>
            </ion-label>
            <ion-select
              class="new-ion-select"
              interface="alert"
              placeholder="Select Category"
              formControlName="category"
            >
              <ion-select-option
                *ngFor="let category of categories"
                [value]="category"
              >
                {{ category }}
              </ion-select-option>
            </ion-select>
          </ion-item>
        </ion-col>

        <!-- Add Custom Category -->

        <ion-col class="custom-col" size="4">
          <ion-button fill="clear" class="btn-title btn-txt-black">
            Add Custom Category
          </ion-button>
          <ion-input
            #categoryEle
            class="new-ion-input add-custom-unit "
            placeholder="Example: Vegetarian"
          ></ion-input>
          <ion-icon class="icon-save" color="primary" name="save" (click)="addCategory()"></ion-icon>
        </ion-col>

        <!-- -------------------------------------------------------------------------------- -->
  
        <ion-col size-lg="4">
          <ion-item lines="none">
            <ion-label>
              Total Amount<span class="span-mandatory">*</span>
            </ion-label>
            <ion-input
              class="ion-input-class"
              formControlName="totalAmount"
              type="number"
            ></ion-input>
          </ion-item>
          <ion-item lines="none" *ngIf="!paramDocumentId">
            <ion-label>Amount Paid</ion-label>
            <ion-checkbox
              class="cb-amount-received"
              [checked]="amountPaidChecked"
              (ionChange)="onAmountPaidCheckedChange($event)"
            >
            </ion-checkbox>
            <ion-input
              #amountReceivedEle
              class="ion-input-class"
              formControlName="amountPaid"
              type="number"
            ></ion-input>
          </ion-item>
        </ion-col>

        <ion-col size="4" *ngIf="!paramDocumentId && amountPaidChecked">
          <ion-item lines="none">
            <ion-segment mode="ios" [value]="paymentMode" (ionChange)="setPaymentMode($event)">
              <ion-segment-button value="cash">
                <ion-label>Cash</ion-label>
              </ion-segment-button>
              <ion-segment-button value="cheque">
                <ion-label>Cheque</ion-label>
              </ion-segment-button>
              <ion-segment-button value="bank">
                <ion-label>Bank</ion-label>
              </ion-segment-button>
            </ion-segment>
          </ion-item>
          <ion-item lines="none" *ngIf="paymentMode=='cheque' || paymentMode=='bank'">
            <ion-input
              #paymentIdEle
              class="input-payment-id ion-input-class"
              placeholder="Enter Payment Refrence Id"
              (ionChange)="setPaymentId($event['detail']['value'])"
            ></ion-input>
          </ion-item>
        </ion-col>

      </ion-row>

      <ion-row>
        <ion-col size-xs="12" size-lg="12">
          <ion-item lines="none">
            <ion-label>Note</ion-label>
          </ion-item>
          <ion-item lines="none">
            <ion-textarea
              rows="2"
              placeholder="Write Your Note Here"
              formControlName="note"
            ></ion-textarea>
          </ion-item>
        </ion-col>
      </ion-row>

      <ion-row *ngIf="paramDocumentId">
        <ion-col size-xs="12" size-lg="12">
          <app-money-out-selector
            *ngIf="fetchedExpense"
            #moneyOutSelector
            [expense]="fetchedExpense"
            [isExpenseForm]="true"
            [billTotalAmount]="this.form.value.totalAmount"
            [moneyOutCart]="this.fetchedExpense?.moneyOuts"
            (moneyOutsSelectedEvent)="onMoneyOutCartUpdate($event)"
          >
          </app-money-out-selector>
        </ion-col>
      </ion-row>
  
      <ion-row>
        <ion-col size="12">
          <ion-button
            class="btn-save-money-in"
            expand="full"
            [disabled]="this?.form?.invalid"
            (click)="onSaveClick()"
          >
            Save Expense
          </ion-button>
        </ion-col>
      </ion-row>
  
    </form>
  
  </ion-grid>

</ion-content>
