import { KotItem } from './../models/KotItem.model';
import { Sale } from './../models/Sale.model';
import { KotDao } from './dao/kot.dao';
import { BehaviorSubject } from "rxjs";
import { Kot } from "../models/Kot.model";
import { Utility } from "../utils/utility";
import { IDataService } from "../../interface/IDataService.interface";
import { ExpressServerService } from './api/express-server.service';
import { AuthService } from './auth/auth.service';
import { SentryUtilites } from '../utils/sentryUtilites';

export class KotService implements IDataService<Kot>{
  private static _instance: KotService;

  public static getInstance(
    kotDao: KotDao,
    expressServerService: ExpressServerService,
    authService: AuthService
  ) {
    if (!this._instance) {
      this._instance = new KotService(
        kotDao,
        expressServerService,
        authService
      )
      this._instance.initService();
    }
    this._instance.reloadList();
    return this._instance;
  }

  constructor(
    dao: KotDao,
    expressServerService: ExpressServerService,
    authService: AuthService
  ) {
    this.dao = dao;
    this.expressServerService = expressServerService;
    this.authService = authService;
  }

  dao: KotDao;
  expressServerService: ExpressServerService;
  authService: AuthService;

  LIST_REFRESH_RATE = 1000;
  selectedProfileId: string = null;
  selectedProfileUserId: string = null;
  updateSubs = new BehaviorSubject<Kot>(null);


  lastReloadStamp: number = 0;
  isReloadPostpond = false;

  initService() {
    this.selectedProfileId = Utility.getFromLocalStorage('selectedProfile');
    this.selectedProfileUserId = Utility.getFromLocalStorage('selectedProfileUserId');
    this.reloadList();
  }


  async reloadList() {
    try {
      if (this.isReloadPostpond) {
        return;
      }
      const currentStamp = +new Date();
      if (this.lastReloadStamp < (currentStamp - this.LIST_REFRESH_RATE)) {
        this.lastReloadStamp = currentStamp;
        this.trySyncUnsynced();
      } else {
        this.isReloadPostpond = true;
        setTimeout(() => {
          this.isReloadPostpond = false;
          this.reloadList();
        }, this.LIST_REFRESH_RATE + 100);
      }
    } catch (error) {
      SentryUtilites.setLog("KotService:reloadList", error)
      return null;
    }
  }

  getAllByPromise() {
    return this.dao.getAllByProfile(this.selectedProfileId);
  }

  getAllByPromiseByProfile(profileId: string) {
    return this.dao.getAllByProfile(profileId);
  }

  getById(id: number): Promise<Kot> {
    return this.dao.getById(id);
  }

  getByUUID(uuid: string): Promise<Kot> {
    return this.dao.getByUUID(uuid);
  }

  addKot(sale: Sale): Promise<Kot> {
    return new Promise(async (resolve, reject) => {
      try {
        if (sale?._localUUID) {
          let kotNo: number = 0;
          let billStateItems: KotItem[] = [];
          let printStateItems: KotItem[] = [];

          let kotItems: KotItem[] = [];
          let latestKot = await this.dao.getLatestKotBySaleUUID(sale?._localUUID);
          if (latestKot != null) {
            kotNo = latestKot?.kotNo;
            kotItems = latestKot?.billStateItems;
          }

          if (sale?.billItems?.length) {
            sale?.billItems?.forEach(billItem => {
              let billStateItem = new KotItem();
              billStateItem.itemName = billItem?.item?.itemName;
              billStateItem.itemUUID = billItem?.item?._localUUID;
              billStateItem.quantity = billItem?.quantity;
              billStateItem.note = billItem?.kotNote;

              billStateItems.push(billStateItem);

              let isMatched = false;

              kotItems?.forEach(kotItem => {
                if (billItem?.item?._localUUID === kotItem?.itemUUID) {
                  isMatched = true;
                  let deltaQuantity = billItem?.quantity - kotItem?.quantity;
                  if (deltaQuantity > 0) {
                    kotItem.quantity = deltaQuantity;
                    printStateItems.push(kotItem);
                  }
                  kotItem.note = billItem?.kotNote;
                }
              });

              if (!isMatched) {
                printStateItems.push(billStateItem);
              }

            });

            if (printStateItems?.length) {
              let kot = new Kot();
              kot._localUUID = Utility.getUUID();
              kot.linkedSaleUUID = sale?._localUUID;
              kot.profileId = this.selectedProfileId;
              kot.userId = this.selectedProfileUserId;
              kot.kotNo = kotNo + 1;
              kot.billStateItems = billStateItems;
              kot.printStateItems = printStateItems;

              kot.createdBy = kot.lastModifiedBy = this.authService.getLoginPhone();
              kot.createdByName = kot.lastModifiedByName = Utility.getCreatedByName();

              let savedKot = await this.dao.save(kot);
              this.reloadList();
              return resolve(savedKot);
            } else {
              return resolve(null);
            }
          }
        } else {
          return resolve(null);
        }
      } catch (error) {
        SentryUtilites.setLog("KotService:addKot", error)
        return resolve(null);
      }
    });
  }

  save(kot: Kot): Promise<Kot> {
    if(Utility.isTruthy(kot)) {
      kot.userId = this.selectedProfileUserId;
      if(!kot?.profileId) {
        kot.profileId = this.selectedProfileId;
      }
      kot.createdBy = kot.lastModifiedBy = this.authService.getLoginPhone();
      kot.createdByName = kot.lastModifiedByName = Utility.getCreatedByName();
      return new Promise(async (resolve, reject) => {
        try {
          let savedKot = await this.dao.save(kot);
          this.reloadList();
          return resolve(savedKot);
        } catch (error) {
          SentryUtilites.setLog("KotService:save", error)
          return resolve(null);
        }
      });
    } else {
      return null;
    }
  }

  update(kot: Kot): Promise<Kot> {
    kot.lastModifiedBy = this.authService.getLoginPhone();
    kot.lastModifiedByName = Utility.getCreatedByName();
    return new Promise(async (resolve, reject) => {
      return resolve(null);
    });
  }

  delete(kot: Kot): Promise<Kot> {
    kot.lastModifiedBy = this.authService.getLoginPhone();
    kot.lastModifiedByName = Utility.getCreatedByName();
    return new Promise(async (resolve, reject) => {
      resolve(null)
    });
  }

  isSyncLock = false;
  isSyncPostPond = false;
  async trySyncUnsynced(postpond?: boolean) {
    try {
      if (this.isSyncLock) {
        if (!this.isSyncPostPond) {
          setTimeout(() => {
            this.isSyncPostPond = true;
            this.trySyncUnsynced(true);
          }, 200);
        }
        return true;
      }
      if (postpond) {
        this.isSyncPostPond = false;
      }
      this.isSyncLock = true;
      let unSyncedElements: Kot[] = await this.dao.getAllUnsynced(this.selectedProfileId);
      if (unSyncedElements && unSyncedElements?.length) {
        try {
  
          for (let i = 0; i < unSyncedElements?.length; i++) {
            let unSyncedElement = unSyncedElements[i];
            if(unSyncedElement?._localUUID) {
              unSyncedElement['updatedStamp'] = +new Date();
            }
          }
  
          await this.dao.bulkPut(unSyncedElements);
          await Utility.wait(1000);
          
          let chunkArr = Utility.getChunkArr(unSyncedElements);
          let chunkArrLength = chunkArr?.length;
  
          for(let i = 0; i < chunkArrLength; i++) {
            let result = await this.expressServerService.makeSyncCall('kot', chunkArr[i]);
            if (result && result?.['records']?.length) {
              let arr = result?.['records'];
              for (let i = 0; i < arr?.length; i++) {
                const el = arr[i];
                await this.updateSyncStamp(el);
              }
            }
          }
        } catch (err) {
          SentryUtilites.setLog("KotService:trySyncUnsynced", err)
        }
      }
      this.isSyncLock = false;
    } catch (error) {
      SentryUtilites.setLog("KotService:trySyncUnsynced", error)
    }
  }

  updateSyncStamp(el: Kot): Promise<Kot> {
    return new Promise(async (resolve, reject) => {
      try {
        let updatedEl = await this.dao.updateSyncStamp(el);
        this.updateSubs.next(updatedEl);
        return resolve(updatedEl);
      } catch (error) {
        SentryUtilites.setLog("KotService:updateSyncStamp", error)
        return resolve(null);
      }
    });
  }

  async getNewSaleNo(): Promise<string> {
    return new Promise(async (resolve, reject) => {
      return resolve(null);
    });
  }

  getNewBillNo() {

  }

  copyData(fromProfileId: string, toProfileId: string): Promise<boolean> {
    return new Promise(async (resolve,reject) => {
      try {
        if(Utility.isTruthy(fromProfileId) && Utility.isTruthy(toProfileId)) {
          let fromRecords = await this.getAllByPromiseByProfile(fromProfileId);
          if(fromRecords?.length) {
            let toRecords: Kot[] = [];
            for (let i = 0; i < fromRecords.length; i++) {
              const fetchedRecord = fromRecords[i];
              if(fetchedRecord?._localUUID) {
                fetchedRecord.profileId = toProfileId;
                delete fetchedRecord?._localId;
                delete fetchedRecord?._localUUID;
                let savedRecord = await this.save(fetchedRecord);
                if(savedRecord?._localUUID) {
                  toRecords?.push(savedRecord);
                }
              }
            }
            if(fromRecords?.length === toRecords?.length) {
              return resolve(true);
            }
          }
        }
        return resolve(false);
      } catch (error) {
        SentryUtilites.setLog("KotService:copyData", error)
        return resolve(false);
      }
    });
  }

}
