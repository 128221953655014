<ion-grid>
  <ion-row *ngIf="!moneyInCart?.length && !isSaleList && !isPurchaseReturnList">
    <ion-col offset="9">
      <ion-button
        fill="outline"
        class="dashed-border"
        (click)="openMoneyInSelectorModal()"
      >
        Modify Amount Received
      </ion-button>
    </ion-col>
  </ion-row>
  <ion-row *ngIf="moneyInCart?.length && !isSaleList && !isPurchaseReturnList">
    <ion-col size="3">
      <ion-card color="success" mode="ios">
        <ion-card-header>
          <ion-card-title>{{ getTotal(moneyInCart) | currency: 'INR' }}</ion-card-title>
          <ion-card-subtitle>Total Amount Received</ion-card-subtitle>
        </ion-card-header>
      </ion-card>
    </ion-col>
    <ion-col class="money-in-cart" size="3" *ngFor="let moneyIn of moneyInCart">
      <ion-card color="light" mode="ios" [disabled]="purchaseReturn">
        <ion-card-header>
          <ion-card-title>
            {{ moneyIn?.totalAmount | currency: 'INR' }}
          </ion-card-title>
          <ion-card-subtitle>
            {{ moneyIn.billNo }}
          </ion-card-subtitle>
          <ion-chip class="chip-remove" color="danger" (click)="deleteMoneyIn(moneyIn)">Remove</ion-chip>
        </ion-card-header>
      </ion-card>
    </ion-col>
    <ion-col size="3" *ngIf="getTotal(moneyInCart) < billTotalAmount">
      <ion-button
        fill="outline"
        class="dashed-border btn-add-money-in"
        (click)="openMoneyInSelectorModal()"
        [disabled]="purchaseReturn"
      >
        <ion-icon name="add-outline"></ion-icon>
        Add Money In
      </ion-button>
    </ion-col>
    <ion-col size="3" *ngIf="getTotal(moneyInCart) > billTotalAmount">
      <ion-text color="danger" class="text-extra-money-in">
        Extra Money In Amount is <b>{{ getTotal(moneyInCart) - billTotalAmount | currency: 'INR' }}</b>
      </ion-text>
    </ion-col>
  </ion-row>
</ion-grid>

<!-- <ion-modal [isOpen]="isModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-title>
          Select Money In for <ion-chip color="tertiary">{{ sale?.billNo }}</ion-chip>
        </ion-title>
        <ion-buttons slot="end">
          <ion-button
            class="btn-add-party"
            fill="outline"
            color="primary"
            (click)="toggleMoneyInForm(true)"
          >
            Add New Money In
          </ion-button>
        </ion-buttons>
        <ion-buttons slot="end">
          <ion-button (click)="openMoneyInSelectorModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">

      TODO LIST WITH SHRIDHAR
      <app-money-in-list
        [sale]="sale"
        [billTotalAmount]="billTotalAmount"
        [moneyInCart]="moneyInCart"
        (moneyInSelectedEvent)="onMoneyInSelect($event)"
      >
      </app-money-in-list>

    </ion-content>
  </ng-template>
</ion-modal> -->

<ion-modal [isOpen]="isOpenMoneyInForm" [backdropDismiss]="false" animated="false">
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-title>
          Add Payment for Bill <ion-chip color="tertiary">{{ isSaleList ? sale?.billNo : purchaseReturn?.billNo }}</ion-chip>
        </ion-title>
        <ion-buttons slot="end">
          <ion-button 
            (click)="toggleMoneyInForm(false)" 
            color="danger">
            Close
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">

      <app-money-in-form
        #moenyInFormEle
        *ngIf="isOpenMoneyInForm"
        [sale]="sale"
        [purchaseReturn]="purchaseReturn"
        [billTotalAmount]="billTotalAmount"
        [moneyInCart]="moneyInCart"
        (savedMoneyIn)="onMoneyInSelect($event)"
      ></app-money-in-form>

      <ion-grid *ngIf="!purchaseReturn">
        <ion-row>
          <ion-col>
            <ion-button 
              class="link-money-in-btn"
              (click)="toggleLinkMoneyInList(true)"
              fill="clear" 
              expand="block"
              color="dark">
              Show Unused Money Ins
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>

    </ion-content>
  </ng-template>
</ion-modal>

<!-- Attach Money In list Modal Start -->
<ion-modal 
  [isOpen]="isOpenLinkMoneyInList"
  [backdropDismiss]="false"
  animated="false">
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-title>
          Attach Payment for Bill <ion-chip color="tertiary">{{ sale?.billNo }}</ion-chip>
        </ion-title>
        <ion-buttons slot="end">
          <ion-button 
            (click)="toggleLinkMoneyInList(false)" 
            color="danger">
            Back
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-grid *ngIf="viewPartyWiseMoneyInList?.length; else elseBlock" class="padding-top-none">
        <ion-row class="sticky-row">
          <ion-col class="selected-amount">
            <ion-label class="unused-list-label">
              Unused Money In List
            </ion-label>
            <ion-label>
              Required MoneyIn Amount: <span>{{ totalMoneyInPending | currency:'INR' }}</span>
            </ion-label>
            <ion-label>
              Selected MoneyIn Amount: <span>{{totalSelectedMoneyInAmount | currency: 'INR'}}</span>
            </ion-label>
            <span
             class="greater-money-in-error"
             *ngIf="totalSelectedMoneyInAmount > totalMoneyInPending">
             Money in/payment amount should not be greater than balance amount
            </span>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-item
             *ngFor="let moneyIn of viewPartyWiseMoneyInList" 
             lines="none"
             [disabled]="!checkboxEle?.checked && totalMoneyInPending - totalSelectedMoneyInAmount < moneyIn?.totalAmount">
              <ion-checkbox
                #checkboxEle
                slot="start"
                (ionChange)="selectedAttachMoneyIn($event, moneyIn)">
              </ion-checkbox>
              <ion-label>
                <ion-chip color="primary">{{ moneyIn.billNo }} | {{ moneyIn.billDateStamp | date: 'dd/MM/yy' }}</ion-chip>
                <ion-chip color="primary">{{ moneyIn?.party?.name }}</ion-chip>
                <ion-chip color="primary">{{ moneyIn?.totalAmount | currency: 'INR' }}</ion-chip>
              </ion-label>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-button 
              class="btn-attch-money-in"
              [disabled]="totalSelectedMoneyInAmount > totalMoneyInPending"
              (click)="attachMoneyIns()">
              Attach Money Ins
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-infinite-scroll *ngIf="viewPartyWiseMoneyInList?.length" threshold="10px" (ionInfinite)="loadMorePartyWiseMoneyInListData($event)">
          <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Loading more data...">
          </ion-infinite-scroll-content>
        </ion-infinite-scroll>
      </ion-grid>
      <ng-template #elseBlock>
        <div class="no-records-found">
          <h4>
            No records Found
          </h4>
        </div>
      </ng-template>
    </ion-content>
  </ng-template>
</ion-modal>
<!-- Attach Money In list Modal End -->