import { EstimatePage } from './pages/estimate/estimate.page';
import { ProfileFormPage } from './pages/profile/profile-form/profile-form.page';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, Router } from '@angular/router';
import { DBConfig, NgxIndexedDBModule } from 'ngx-indexed-db';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppUpdateComponent } from './components/app-update/app-update.component';
import { environment } from '../environments/environment';
import { AppUpdateService } from './services/app-update.service';
import { SharedModule } from './shared.module';
import { BuildInfoComponent } from './build-info/build-info.component';
import { SalePage } from './pages/sale/sale.page';
import { SaleFormPage } from './pages/sale/sale-form/sale-form.page';
import { PurchasePage } from './pages/purchase/purchase.page';
import { PurchaseFormPage } from './pages/purchase/purchase-form/purchase-form.page';
import { MoneyInPage } from './pages/money-in/money-in.page';
import { MoneyInFormPage } from './pages/money-in/money-in-form/money-in-form.page';
import { MoneyOutPage } from './pages/money-out/money-out.page';
import { MoneyOutFormPage } from './pages/money-out/money-out-form/money-out-form.page';
import { DashboardPage } from './pages/dashboard/dashboard.page';
import { ProfilePage } from './pages/profile/profile.page';
import { PartyPage } from './pages/party/party.page';
import { PartyFormPage } from './pages/party/party-form/party-form.page';
import { PartyTransactionPage } from './pages/party/party-transaction/party-transaction.page';
import { ItemPage } from './pages/item/item.page';
import { ItemFormPage } from './pages/item/item-form/item-form.page';
import { ItemTransactionPage } from './pages/item/item-transaction/item-transaction.page';
import { ReportsPage } from './pages/reports/reports.page';
import { SigninPage } from './pages/signin/signin.page';
import { ErrorNotFoundPage } from './pages/error-not-found/error-not-found.page';
import { LogoutPage } from './pages/logout/logout.page';
import { FetchInitialDataPage } from './pages/fetch-initial-data/fetch-initial-data.page';
import { BillViewPage } from './pages/bill-view/bill-view.page';
import { CategoriesPage } from './pages/categories/categories.page';
import { BarcodePrintPage } from './pages/item/barcode-print/barcode-print.page';
import { StaffPage } from './pages/staff/staff.page';
import { StaffFormPage } from './pages/staff/staff-form/staff-form.page';
import { NoAccessPage } from './pages/no-access/no-access.page';
import { ExpensePage } from './pages/expense/expense.page';
import { ExpenseFormPage } from './pages/expense/expense-form/expense-form.page';
import { OrderPage } from './pages/order/order.page';
import { KotPage } from './pages/kot/kot.page';
import { IngredientPage } from './pages/ingredient/ingredient.page';
import { IngredientFormPage } from './pages/ingredient/ingredient-form/ingredient-form.page';
import { IngredientTransactionPage } from './pages/ingredient/ingredient-transaction/ingredient-transaction.page';
import { ItemBulkEditPage } from './pages/item/item-bulk-edit/item-bulk-edit.page';
import { SignInV2Page } from './pages/sign-in-v2/sign-in-v2.page';
import { WhatappBulkMsgPage } from './pages/whatapp-bulk-msg/whatapp-bulk-msg.page';
import { BillViewPurchasePage } from './pages/bill-view-purchase/bill-view-purchase.page';
import { BillViewMoneyInPage } from './pages/bill-view-money-in/bill-view-money-in.page';
import { BillViewMoneyOutPage } from './pages/bill-view-money-out/bill-view-money-out.page';
import { BillViewExpensePage } from './pages/bill-view-expense/bill-view-expense.page';
import { EstimateFormPage } from './pages/estimate/estimate-form/estimate-form.page';
import { BillViewEstimatePage } from './pages/bill-view-estimate/bill-view-estimate.page';
import { IngredientEditPage } from './pages/ingredient/ingredient-edit/ingredient-edit.page';
import { IdbxErrorPage } from './pages/idbx-error/idbx-error.page';
import { RawMaterialPurchaseBulkEditPage } from './pages/raw-material-purchase-bulk-edit/raw-material-purchase-bulk-edit.page';
import { MarketingPage } from './pages/marketing/marketing.page';
import { LedgerLoadingPage } from './pages/ledger-loading/ledger-loading.page';
import { GoogleMybusinessPage } from './pages/google-mybusiness/google-mybusiness.page';
import { FetchInitialDataV2Page } from './pages/fetch-initial-data-v2/fetch-initial-data-v2.page';
import * as Sentry from "@sentry/angular";
import { SaleReturnPage } from './pages/sale-return/sale-return.page';
import { SaleReturnFormPage } from './pages/sale-return/sale-return-form/sale-return-form.page';
import { BillViewSaleReturnPage } from './pages/bill-view-sale-return/bill-view-sale-return.page';
import { PurchaseReturnPage } from './pages/purchase-return/purchase-return.page';
import { PurchaseReturnFormPage } from './pages/purchase-return/purchase-return-form/purchase-return-form.page';
import { BillViewPurchaseReturnPage } from './pages/bill-view-purchase-return/bill-view-purchase-return.page';
import { SignInWithGmailPage } from './pages/sign-in-with-gmail/sign-in-with-gmail.page';

const dbConfig: DBConfig = {
  name: environment.indexedDbName,
  version: environment.indexedDbVersion,
  objectStoresMeta: [
    {
      store: 'profile',
      storeConfig: { keyPath: '_localId', autoIncrement: true },
      storeSchema: [
        {
          name: '_localUUID',
          keypath: '_localUUID',
          options: { unique: true },
        },
      ],
    },
    {
      store: 'licence',
      storeConfig: { keyPath: '_localId', autoIncrement: true },
      storeSchema: [
        {
          name: '_localUUID',
          keypath: '_localUUID',
          options: { unique: true },
        },
      ],
    },
    {
      store: 'party',
      storeConfig: { keyPath: '_localId', autoIncrement: true },
      storeSchema: [
        {
          name: '_localUUID',
          keypath: '_localUUID',
          options: { unique: true },
        },
      ],
    },
    {
      store: 'item',
      storeConfig: { keyPath: '_localId', autoIncrement: true },
      storeSchema: [
        {
          name: '_localUUID',
          keypath: '_localUUID',
          options: { unique: true },
        },
      ],
    },
    {
      store: 'item-stock-adjust',
      storeConfig: { keyPath: '_localId', autoIncrement: true },
      storeSchema: [
        {
          name: '_localUUID',
          keypath: '_localUUID',
          options: { unique: true },
        },
        {
          name: 'linkedItemUUID',
          keypath: 'linkedItemUUID',
          options: { unique: false },
        },
      ],
    },
    {
      store: 'image',
      storeConfig: { keyPath: '_localId', autoIncrement: true },
      storeSchema: [
        {
          name: '_localUUID',
          keypath: '_localUUID',
          options: { unique: true },
        },
      ],
    },
    {
      store: 'sale',
      storeConfig: { keyPath: '_localId', autoIncrement: true },
      storeSchema: [
        {
          name: '_localUUID',
          keypath: '_localUUID',
          options: { unique: true },
        },
      ],
    },
    {
      store: 'sale-return',
      storeConfig: { keyPath: '_localId', autoIncrement: true },
      storeSchema: [
        {
          name: '_localUUID',
          keypath: '_localUUID',
          options: { unique: true },
        },
      ],
    },
    {
      store: 'estimate',
      storeConfig: { keyPath: '_localId', autoIncrement: true },
      storeSchema: [
        {
          name: '_localUUID',
          keypath: '_localUUID',
          options: { unique: true },
        },
      ],
    },
    {
      store: 'moneyIn',
      storeConfig: { keyPath: '_localId', autoIncrement: true },
      storeSchema: [
        {
          name: '_localUUID',
          keypath: '_localUUID',
          options: { unique: true },
        },
        {
          name: 'linkedSaleUUID',
          keypath: 'linkedSaleUUID',
          options: { unique: false },
        },
      ],
    },
    {
      store: 'moneyOut',
      storeConfig: { keyPath: '_localId', autoIncrement: true },
      storeSchema: [
        {
          name: '_localUUID',
          keypath: '_localUUID',
          options: { unique: true },
        },
      ],
    },
    {
      store: 'purchase',
      storeConfig: { keyPath: '_localId', autoIncrement: true },
      storeSchema: [
        {
          name: '_localUUID',
          keypath: '_localUUID',
          options: { unique: true },
        },
      ],
    },
    {
      store: 'purchase-return',
      storeConfig: { keyPath: '_localId', autoIncrement: true },
      storeSchema: [
        {
          name: '_localUUID',
          keypath: '_localUUID',
          options: { unique: true },
        },
      ],
    },
    {
      store: 'expense',
      storeConfig: { keyPath: '_localId', autoIncrement: true },
      storeSchema: [
        {
          name: '_localUUID',
          keypath: '_localUUID',
          options: { unique: true },
        },
      ],
    },
    {
      store: 'party-category',
      storeConfig: { keyPath: '_localId', autoIncrement: true },
      storeSchema: [
        {
          name: '_localUUID',
          keypath: '_localUUID',
          options: { unique: true },
        },
      ],
    },
    {
      store: 'party-item-price-map',
      storeConfig: { keyPath: '_localId', autoIncrement: true },
      storeSchema: [
        {
          name: '_localUUID',
          keypath: '_localUUID',
          options: { unique: true },
        },
      ],
    },
    {
      store: 'item-category',
      storeConfig: { keyPath: '_localId', autoIncrement: true },
      storeSchema: [
        {
          name: '_localUUID',
          keypath: '_localUUID',
          options: { unique: true },
        },
      ],
    },
    {
      store: 'item-unit',
      storeConfig: { keyPath: '_localId', autoIncrement: true },
      storeSchema: [
        {
          name: '_localUUID',
          keypath: '_localUUID',
          options: { unique: true },
        },
      ],
    },
    {
      store: 'kot',
      storeConfig: { keyPath: '_localId', autoIncrement: true },
      storeSchema: [
        {
          name: '_localUUID',
          keypath: '_localUUID',
          options: { unique: true },
        },
      ],
    },
    {
      store: 'cutOffDay',
      storeConfig: { keyPath: '_localId', autoIncrement: true },
      storeSchema: [
        {
          name: '_localUUID',
          keypath: '_localUUID',
          options: { unique: true },
        },
      ],
    },
    {
      store: 'month-wise-party-credit',
      storeConfig: { keyPath: '_localId', autoIncrement: true },
      storeSchema: [
        {
          name: 'partyLocalUUID',
          keypath: 'partyLocalUUID',
          options: { unique: true },
        },
      ],
    },
    {
      store: 'month-wise-item-stock',
      storeConfig: { keyPath: '_localId', autoIncrement: true },
      storeSchema: [
        {
          name: 'itemLocalUUID',
          keypath: 'itemLocalUUID',
          options: { unique: true },
        },
      ],
    },
    {
      store: 'image-base64',
      storeConfig: { keyPath: '_localId', autoIncrement: true },
      storeSchema: [
        {
          name: 'url',
          keypath: 'url',
          options: { unique: true },
        },
      ],
    },
  ],
  // migrationFactory: function () {
  //   return {
  //     2: (db, transaction) => {
  //       const storeProfile = transaction.objectStore('profile');
  //       storeProfile.createIndex('_localUUID', '_localUUID', { unique: false });
  //       const storeParty = transaction.objectStore('party');
  //       storeParty.createIndex('_localUUID', '_localUUID', { unique: false });
  //       const storeItem = transaction.objectStore('item');
  //       storeItem.createIndex('_localUUID', '_localUUID', { unique: false });
  //       const storeSale = transaction.objectStore('sale');
  //       storeSale.createIndex('_localUUID', '_localUUID', { unique: false });
  //       const storePurchase = transaction.objectStore('purchase');
  //       storePurchase.createIndex('_localUUID', '_localUUID', { unique: false });
  //       const storeMoneyIn = transaction.objectStore('moneyIn');
  //       storeMoneyIn.createIndex('_localUUID', '_localUUID', { unique: false });
  //       const storeMoneyOut = transaction.objectStore('moneyOut');
  //       storeMoneyOut.createIndex('_localUUID', '_localUUID', { unique: false });
  //       const storePartyCategory = transaction.objectStore('party-category');
  //       storePartyCategory.createIndex('_localUUID', '_localUUID', { unique: false });
  //       const storeItemCategory = transaction.objectStore('item-category');
  //       storeItemCategory.createIndex('_localUUID', '_localUUID', { unique: false });
  //     },
  //   };
  // },
};

@NgModule({
  declarations: [
    AppComponent,
    BuildInfoComponent,
    AppUpdateComponent,
    SalePage,
    SaleFormPage,
    SaleReturnPage,
    SaleReturnFormPage,
    KotPage,
    OrderPage,
    EstimatePage,
    EstimateFormPage,
    PurchasePage,
    PurchaseFormPage,
    PurchaseReturnPage,
    PurchaseReturnFormPage,
    ExpensePage,
    ExpenseFormPage,
    MoneyInPage,
    MoneyInFormPage,
    MoneyOutPage,
    MoneyOutFormPage,
    DashboardPage,
    ProfilePage,
    ProfileFormPage,
    PartyPage,
    PartyFormPage,
    PartyTransactionPage,
    ItemPage,
    ItemFormPage,
    ItemTransactionPage,
    ItemBulkEditPage,
    IngredientPage,
    IngredientFormPage,
    IngredientTransactionPage,
    IngredientEditPage,
    RawMaterialPurchaseBulkEditPage,
    BarcodePrintPage,
    CategoriesPage,
    BillViewPage,
    BillViewSaleReturnPage,
    BillViewPurchasePage,
    BillViewPurchaseReturnPage,
    BillViewMoneyInPage,
    BillViewMoneyOutPage,
    BillViewExpensePage,
    BillViewEstimatePage,
    ReportsPage,
    SigninPage,
    LogoutPage,
    FetchInitialDataPage,
    StaffPage,
    StaffFormPage,
    MarketingPage,
    ErrorNotFoundPage,
    NoAccessPage,
    SignInV2Page,
    WhatappBulkMsgPage,
    IdbxErrorPage,
    LedgerLoadingPage,
    GoogleMybusinessPage,
    FetchInitialDataV2Page,
    SignInWithGmailPage,
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: 'md'
    }),
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    NgxIndexedDBModule.forRoot(dbConfig),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    SharedModule,
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    AppUpdateService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },

  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(trace: Sentry.TraceService) {}
}
