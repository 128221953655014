<ion-row class="padding-none custom-row">
  <ion-col size="6" class="txt-left remove-padding-top-bottom">
    <ion-row>
      <ion-col size="12" class="add-bottom-padding">
        <ion-button fill="clear" class="btn-txt-black padding-start-none">
          {{ moneyOut?.party?.name }}
        </ion-button>
      </ion-col>
      <ion-col size="12" (click)="billView(moneyOut)" class="remove-bottom-padding">
        <ion-label color="primary">
          {{ (moneyOut?.totalAmount || 0) | currency:'INR' }}
        </ion-label>
      </ion-col>
      <ion-col size="12" >
        <ion-button (click)="billView(moneyOut)"
        fill="clear" class="btn-txt-black padding-start-none add-bottom-padding button-bottom-padding">
          Txn Mode: {{ moneyOut?.paymentMode }}
        </ion-button>
        <!-- view purchase -->
        <ion-button *ngIf="moneyOut?.linkedPurchaseUUID" (click)="viewPurchase(moneyOut)" fill="outline" color="primary"
        class="font-12px ion-button-height add-bottom-padding ion-button-bottom-padding outline-border">
        View Purchase
      </ion-button>
      <!-- view Expense -->
      <ion-button *ngIf="moneyOut?.linkedExpenseUUID" (click)="viewExpense(moneyOut)" fill="outline" color="primary"
        class="font-12px ion-button-height add-bottom-padding ion-button-bottom-padding outline-border">
        View Expense
      </ion-button>
      <!-- view Sale Return -->
      <ion-button *ngIf="moneyOut?.linkedSaleReturnUUID" (click)="viewSaleReturn(moneyOut)" fill="outline" color="primary"
        class="font-12px ion-button-height add-bottom-padding ion-button-bottom-padding outline-border">
        View Sale Return
      </ion-button>
      </ion-col>
    </ion-row>
  </ion-col>
  <ion-col size="6" class="txt-right remove-padding-top-bottom">
    <ion-row>
      <ion-col size="12" (click)="billView(moneyOut)">
        <ion-button fill="clear" class="btn-txt-black padding-end-none">
          {{ moneyOut?.billNo }} | {{ moneyOut?.billDateStamp | date: 'dd/MM/yy' }}
        </ion-button>
      </ion-col>
      <ion-col size="12" *ngIf="!moneyOut?.deletedStamp">

        <ion-button title="Print" color="tertiary" fill="clear" (click)="billView(moneyOut)">
          <ion-icon name="print"></ion-icon>
        </ion-button>
        
        <ion-button title="Edit" color="primary" fill="clear" (click)="edit(moneyOut)">
          <ion-icon name="create"></ion-icon>
        </ion-button>
        <ion-button title="Delete" color="danger" fill="clear" (click)="delete(moneyOut)">
          <ion-icon name="trash"></ion-icon>
        </ion-button>
      </ion-col>
      <ion-col size-md="11" size-xs="10">
        <ion-label class="label-note">
          {{ (moneyOut?.userUpdatedStamp || moneyOut?.updatedStamp) > moneyOut?.createdStamp ? 'Edited by ' : 'Created by ' }} 
          {{ (moneyOut?.userUpdatedStamp || moneyOut?.updatedStamp) > moneyOut?.createdStamp ? moneyOut?.lastModifiedByName || moneyOut?.lastModifiedBy :  moneyOut?.createdByName || moneyOut?.createdBy }}
          on
          {{ (moneyOut?.userUpdatedStamp || moneyOut?.updatedStamp) | date: 'dd/MM/yy h:mm:ss a' }}
        </ion-label>
      </ion-col>
      <ion-col size-md="1" size-xs="2" (click)="billView(moneyOut)">
        <ion-col size="auto" offset="1">
          <div *ngIf="moneyOut?.syncStamp > moneyOut?.updatedStamp; then thenBlock else elseBlock"></div>
          <ng-template #thenBlock>
            <ion-button class="btn-popover" title="Data Uploaded" fill="clear">
              <ion-icon 
                name="checkmark-done-sharp" 
                size="small"
                color="success">
              </ion-icon>
            </ion-button>
          </ng-template>
          <ng-template #elseBlock>
            <ion-button class="btn-popover" title="Warning" fill="clear">
              <ion-icon 
                name="warning-sharp" 
                color="warning" 
                size="small">
              </ion-icon>
            </ion-button>
          </ng-template>
        </ion-col>
      </ion-col>
    </ion-row>
  </ion-col>
</ion-row>
<app-pin-verification (verifyTransactionPin)="verifyTransactionPIN($event)" #pinVerificationElement></app-pin-verification>