<ion-header [ngClass]="{'mobile': isMobile}">
  <ion-toolbar class="{{ getHeaderColorClass() }}">
    <app-navigation-control slot="start" type="staff"></app-navigation-control>
    <ion-title class="header-title">Staff List</ion-title>
    <ion-text class="last-sync-time" color="medium">Last Sync at {{ lastSyncTime }}</ion-text>
    <ion-text *ngIf="isTimeDifference" class="last-sync-time timezone-warning alert-border padding-alert-boder" color="warning"> <i>Time Zone Mismatched</i> </ion-text>
    <ion-chip class="chip-new-staff ion-chip" color="tertiary" slot="end" (click)="onNewStaffClick()"> New Staff </ion-chip>
  </ion-toolbar>
</ion-header>

<ion-content [ngClass]="{'mobile': isMobile}">
  <ion-button fill="clear" class="btn-add chip-option" (click)="onNewStaffClick()">
    + New Staff
  </ion-button>

  <ion-grid>
    
    <!-- Assign Staff to the Invoice Setting start -->
    <ion-row class="row-setting">
      <ion-col class="padding-bottom-none" size="10">
        <span>Assign Staff to the Invoice</span> <br>
        <span class="grey-color">{{ isAssignStaff ? 'Enabled' : 'Disabled' }}</span>
      </ion-col>
      <ion-col class="padding-bottom-none txt-right" size="2">
        <span>
          <ion-toggle
            #toggleEle2
            color="tertiary"
            slot="end" 
            [checked]="isAssignStaff"
            (click)="clickAssignStaffToggle()"
            (ionChange)="assignStaff($event)">
          </ion-toggle>
        </span>
      </ion-col>
      <ion-col class="padding-top-none">
        <ion-label color="success">This will enable admin users to assign staff to the invoice for sales tracking</ion-label>
      </ion-col>
    </ion-row>
    <!-- Assign Staff to the Invoice Setting end -->

    <ion-row class="row-access-to" *ngFor="let accessTo of accessToList">
      <ion-col size-lg="9.5" size-xs="9"(click)="edit(accessTo?.userId)">
        <ion-icon class="icon-person" color="primary" name="person"></ion-icon>
        <ion-button fill="clear" (click)="edit(accessTo?.userId)" class="btn-phone txt-transform-normal">
          {{ accessTo?.name ? accessTo?.name + ' | ' : '' }} {{ accessTo?.userId }}
        </ion-button>
      </ion-col>
      <ion-col size-lg="2" size-xs="2">
        <ion-button class="ion-icon-btn" title="Edit" color="primary" fill="clear" (click)="edit(accessTo?.userId)">
          <ion-icon name="create"></ion-icon>
        </ion-button>
        <ion-button class="ion-icon-btn" title="Delete" color="danger" fill="clear" (click)="delete(accessTo?.userId)">
          <ion-icon name="trash"></ion-icon>
        </ion-button>
      </ion-col>
      <ion-col size-lg="0.5" size-xs="1" (click)="edit(accessTo?.userId)">
        <div *ngIf="selectedProfile?.syncStamp > selectedProfile?.updatedStamp; then thenBlock else elseBlock"></div>
        <ng-template #thenBlock>
          <ion-button class="btn-popover icon-sync" title="Data Uploaded" fill="clear">
            <ion-icon 
              name="checkmark-done-sharp" 
              size-lg="small"
              color="success">
            </ion-icon>
          </ion-button>
        </ng-template>
        <ng-template #elseBlock>
          <ion-button class="btn-popover icon-sync" title="Warning" fill="clear">
            <ion-icon 
              name="warning-sharp" 
              color="warning" 
              size-lg="small">
            </ion-icon>
          </ion-button>
        </ng-template>
      </ion-col>
    </ion-row>

  </ion-grid>

</ion-content>

<app-pin-verification (verifyTransactionPin)="verifyTransactionPIN($event)" #pinVerificationElement></app-pin-verification>
