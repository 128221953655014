import { BehaviorSubject } from "rxjs";
import { ExpressServerService } from "./api/express-server.service";
import { IDataService } from "../../interface/IDataService.interface";
import { LicenceDao } from './dao/licence.dao';
import { Utility } from "../utils/utility";
import { AuthService } from "./auth/auth.service";
import { Licence } from "../models/Licence.model";
import { SentryUtilites } from "../utils/sentryUtilites";

export class LicenceService implements IDataService<Licence>{
  private static _instance: LicenceService;

  public static getInstance(dao: LicenceDao, expressServerService: ExpressServerService, authService: AuthService) {
    if (!this._instance) {
      this._instance = new LicenceService(dao, expressServerService,authService)
    }
    this._instance.reloadList();
    return this._instance;
  }

  constructor(licenseDao: LicenceDao, expressServerService: ExpressServerService, authService: AuthService) {
    this.dao = licenseDao;
    this.expressServerService = expressServerService;
    this.authService = authService;
  }
  dao: LicenceDao;
  expressServerService: ExpressServerService;
  authService: AuthService;

  LIST_REFRESH_RATE = 1000;
  selectedProfileId: string = null;
  list: Licence[] = [];
  listSubs = new BehaviorSubject<Licence[]>([]);
  updateSubs = new BehaviorSubject<Licence>(null);

  lastReloadStamp: number = 0;
  isReloadPostpond = false;

  initService() {
    this.list = [];
    this.reloadList();
  }

  reloadList = async () => {
    try {
      if (this.isReloadPostpond) {
        return;
      }
      const currentStamp = +new Date();
      if (this.lastReloadStamp < (currentStamp - this.LIST_REFRESH_RATE)) {
        this.lastReloadStamp = currentStamp;
        let docs = await this.dao.getAll();
        this.list = docs;
        this.listSubs.next(this.list);
        this.trySyncUnsynced();
      } else {
        this.isReloadPostpond = true;
        setTimeout(() => {
          this.isReloadPostpond = false;
          this.reloadList();
        }, this.LIST_REFRESH_RATE + 100);
      }
    } catch (error) {
      SentryUtilites.setLog("LicenceService:reloadList", error)
      return null;
    }
  }

  getAllByPromise(): Promise<Licence[]> {
    return this.dao.getAll();
  }

  getAllByPromiseByProfile(profileId: string) {
    return this.dao.getAllByProfile(profileId);
  }

  getById(id: number): Promise<Licence> {
    return this.dao.getById(id)
  }

  getByUUID(uuid: string): Promise<Licence> {
    return this.dao.getByUUID(uuid);
  }

  save(licence: Licence): Promise<Licence> {
    if(Utility.isTruthy(licence)) {
      licence.userId = this.authService.getLoginPhone();
      licence.createdBy = licence.lastModifiedBy = this.authService.getLoginPhone();
      licence.createdByName = licence.lastModifiedByName = Utility.getCreatedByName();
  
      return new Promise(async (resolve, reject) => {
        try {
          let savedProfile = await this.dao.save(licence);
          this.reloadList();
          return resolve(savedProfile);
        } catch (error) {
          SentryUtilites.setLog("LicenceService:save", error)
          return resolve(null);
        }
      });
    } else {
      return null;
    }
  }

  update(licence: Licence): Promise<Licence> {
    return new Promise(async (resolve, reject) => {
      try {
        if(licence?._localUUID) {
          licence.lastModifiedBy = this.authService.getLoginPhone();
          licence.lastModifiedByName = Utility.getCreatedByName();
          let updatedProfile = await this.dao.update(licence);
          this.reloadList();
          return resolve(updatedProfile);
        }
        return resolve(null);
      } catch (error) {
        SentryUtilites.setLog("LicenceService:update", error)
        return resolve(null);
      }
    });
  }

  delete(licence: Licence): Promise<Licence> {
    return new Promise(async (resolve, reject) => {
      try {
        if(licence?._localUUID) {
          licence.lastModifiedBy = this.authService.getLoginPhone();
          licence.lastModifiedByName = Utility.getCreatedByName();
          let deletedProfile = await this.dao.delete(licence);
          this.reloadList();
          return resolve(deletedProfile);
        }
        return resolve(null);
      } catch (error) {
        SentryUtilites.setLog("LicenceService:delete", error)
        return resolve(null);
      }
    });
  }

  isSyncLock = false;
  isSyncPostPond = false;
  async trySyncUnsynced(postpond?: boolean) {
    return null;
  }

  updateSyncStamp(el: Licence): Promise<Licence> {
    return new Promise(async (resolve, reject) => {
      try {
        let updatedEl = await this.dao.updateSyncStamp(el);
        this.updateSubs.next(updatedEl);
        return resolve(updatedEl);
      } catch (error) {
        SentryUtilites.setLog("LicenceService:updateSyncStamp", error)
        return resolve(null);
      }
    });
  }

  copyData(fromProfileId: string, toProfileId: string): Promise<boolean> {
    return new Promise(async (resolve,reject) => {
      try {
        if(Utility.isTruthy(fromProfileId) && Utility.isTruthy(toProfileId)) {
          let fromRecords = await this.getAllByPromiseByProfile(fromProfileId);
          if(fromRecords?.length) {
            let toRecords: Licence[] = [];
            for (let i = 0; i < fromRecords.length; i++) {
              const fetchedRecord = fromRecords[i];
              if(fetchedRecord?._localUUID) {
                fetchedRecord.profileId = toProfileId;
                delete fetchedRecord?._localId;
                delete fetchedRecord?._localUUID;
                let savedRecord = await this.save(fetchedRecord);
                if(savedRecord?._localUUID) {
                  toRecords?.push(savedRecord);
                }
              }
            }
            if(fromRecords?.length === toRecords?.length) {
              return resolve(true);
            }
          }
        }
        return resolve(false);
      } catch (error) {
        SentryUtilites.setLog("LicenceService:copyData", error)
        return resolve(false);
      }
    });
  }
}

