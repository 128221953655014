import { BaseModel } from "./BaseModel.model";
import Party from "./Party.model";
import { KotItem } from './KotItem.model';

export enum OrderStatus {
  Placed="Placed",
  Accepted="Accepted",
  InPreparation="InPreparation",
  InTransit="InTransit",
  Delivered="Delivered",
  Cancelled="Cancelled",
  Rejected="Rejected"
}

export class Order extends BaseModel {

  profileId: string;
  linkedSaleUUID: string;
  orderNo: number
  orderItems: KotItem[];
  party: any;
  status: OrderStatus;

}
