import { IPurchase } from './../../models/Purchase.model';
import { ISale } from '../../models/Sale.model';
import { IParty } from './../../models/Party.model';
import { IProfile, Profile } from './../../models/Profile.model';
import { Utility } from './../../utils/utility';
import { IItem } from './../../models/Item.model';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AllDataService } from '../../services/all-data.service';
import { ExpressServerService } from '../../services/api/express-server.service';
import { IItemCategory } from '../../models/ItemCategory.model';
import { IPartyCategory } from '../../models/PartyCategory.model';
import { IMoneyIn } from '../../models/MoneyIn.model';
import { IMoneyOut } from '../../models/MoneyOut.model';
import { Router } from '@angular/router';
import { AlertController, IonModal, ToastController } from '@ionic/angular';
import { IItemStockAdjust } from '../../models/ItemStockAdjust.model';
import { IImage } from '../../models/image.model';
import { IItemUnit } from '../../models/ItemUnit.model';
import { AuthService } from '../../services/auth/auth.service';
import { ILicence } from '../../models/Licence.model';
import { IExpense } from 'src/app/models/Expense.model';
import { IKot } from 'src/app/models/Kot.model';
import { ICutOffDay } from 'src/app/models/CutOffDay.model';
import { IEstimate } from 'src/app/models/Estimate.model';
import { EventService } from '../../services/event.service';
import { LedgerService } from '../../services/ledger.service';
import { LogoutService } from '../../services/logout.service';
import { ImageBase64Service } from '../../services/image-base64.service';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';
import { ISaleReturn } from '../../models/SaleReturn.model';
import { IPurchaseReturn } from '../../models/PurchaseReturn.model';

@Component({
  selector: 'app-fetch-initial-data',
  templateUrl: './fetch-initial-data.page.html',
  styleUrls: ['./fetch-initial-data.page.scss'],
})
export class FetchInitialDataPage implements OnInit {

  @ViewChild('ProfileModalSelectorEle') ProfileModalSelectorEle: IonModal 

  ownerUserId: string = null;

  collectionTypes: {
    [key: string]: {
      activated: boolean,
      loaded: boolean,
      failed: boolean,
      path: string,
      schemaName: string,
      count: number,
    }
  } = {
      'Items': {
        activated: false,
        loaded: false,
        failed: false,
        path: 'item/fetchAllv3',
        schemaName: IItem.SCHEMA_NAME,
        count: 0,
      },
      'ItemCategories': {
        activated: false,
        loaded: false,
        failed: false,
        path: 'itemCategory/fetchAllv3',
        schemaName: IItemCategory.SCHEMA_NAME,
        count: 0,
      },
      'ItemStockAdjust': {
        activated: false,
        loaded: false,
        failed: false,
        path: 'itemStockAdjust/fetchAllv3',
        schemaName: IItemStockAdjust.SCHEMA_NAME,
        count: 0,
      },
      'ItemUnit': {
        activated: false,
        loaded: false,
        failed: false,
        path: 'itemUnit/fetchAllv3',
        schemaName: IItemUnit.SCHEMA_NAME,
        count: 0,
      },
      'Parties': {
        activated: false,
        loaded: false,
        failed: false,
        path: 'party/fetchAllv3',
        schemaName: IParty.SCHEMA_NAME,
        count: 0,
      },
      'PartyCategories': {
        activated: false,
        loaded: false,
        failed: false,
        path: 'partyCategory/fetchAllv3',
        schemaName: IPartyCategory.SCHEMA_NAME,
        count: 0,
      },
      'Money Ins': {
        activated: false,
        loaded: false,
        failed: false,
        path: 'moneyIn/fetchAllv3',
        schemaName: IMoneyIn.SCHEMA_NAME,
        count: 0,
      },
      'Money Outs': {
        activated: false,
        loaded: false,
        failed: false,
        path: 'moneyOut/fetchAllv3',
        schemaName: IMoneyOut.SCHEMA_NAME,
        count: 0,
      },
      'Sales': {
        activated: false,
        loaded: false,
        failed: false,
        path: 'sale/fetchAllv3',
        schemaName: ISale.SCHEMA_NAME,
        count: 0,
      },
      'Sale Return': {
        activated: false,
        loaded: false,
        failed: false,
        path: 'saleReturn/fetchAllv3',
        schemaName: ISaleReturn.SCHEMA_NAME,
        count: 0,
      },
      'Estimate': {
        activated: false,
        loaded: false,
        failed: false,
        path: 'estimate/fetchAllv3',
        schemaName: IEstimate.SCHEMA_NAME,
        count: 0,
      },
      'Kot': {
        activated: false,
        loaded: false,
        failed: false,
        path: 'kot/fetchAllv3',
        schemaName: IKot.SCHEMA_NAME,
        count: 0,
      },
      'Purchase': {
        activated: false,
        loaded: false,
        failed: false,
        path: 'purchase/fetchAllv3',
        schemaName: IPurchase.SCHEMA_NAME,
        count: 0,
      },
      'Purchase Return': {
        activated: false,
        loaded: false,
        failed: false,
        path: 'purchaseReturn/fetchAllv3',
        schemaName: IPurchaseReturn.SCHEMA_NAME,
        count: 0,
      },
      'Expense': {
        activated: false,
        loaded: false,
        failed: false,
        path: 'expense/fetchAllv3',
        schemaName: IExpense.SCHEMA_NAME,
        count: 0,
      },
      'Image': {
        activated: false,
        loaded: false,
        failed: false,
        path: 'image/fetchAllv3',
        schemaName: IImage.SCHEMA_NAME,
        count: 0,
      },
      'Licence': {
        activated: false,
        loaded: false,
        failed: false,
        path: 'licence/fetchAllv3',
        schemaName: ILicence.SCHEMA_NAME,
        count: 0,
      },
      'Cut Off Day': {
        activated: false,
        loaded: false,
        failed: false,
        path: 'cutOffDay/fetchAllv3',
        schemaName: ICutOffDay.SCHEMA_NAME,
        count: 0,
      },
      'Maintaining ledger': {
        activated: false,
        loaded: false,
        failed: false,
        path: null,
        schemaName: null,
        count: 0,
      },
    };

  isLoadingContent = false;
  isProfileSelectorModalOpen = false;
  profileList: Profile[] = [];

  constructor(
    private allDataService: AllDataService,
    private authService: AuthService,
    private ngxIndexedDBService: NgxIndexedDBService,
    private expressServerService: ExpressServerService,
    private router: Router,
    private toastController: ToastController,
    private eventService: EventService,
    private ledgerService: LedgerService,
    private logoutService: LogoutService,
    private imageBase64Service: ImageBase64Service,
    private alertController: AlertController
  ) { }
  newProfileGeneratedUUID='';

  async ngOnInit() {
    try {
      this.eventService.setShowSideNav(false);
      this.setOwnerUserId();
      if(Utility.getFromLocalStorage('Initial_data_download_complete')) {
        this.router.navigate(['dashboard']);
        this.eventService.setShowSideNav(true);
        return null;
      }
  
      this.profileList = await this.loadProfiles();
      let selfProfiles = this.profileList?.filter(profile => profile?.userId == this.ownerUserId);
      if(selfProfiles?.length) {
        if(Utility.getFromLocalStorage('selectedProfile')) {
          this.allDataService.initService();
          this.isLoadingContent = true;
          this.startDataLoading();
        }else {
          this.profileList = this.profileList.filter(x => !x?.deletedStamp);
          let accessProfiles = this.profileList.filter(x => x?.userId!=this.ownerUserId);
          if(accessProfiles?.length) {
            accessProfiles?.forEach(x => {
              let index = this.profileList.findIndex(y => y?._localUUID==x?._localUUID);
              if(index != -1) {
                this.profileList.splice(index,1);
                this.profileList.unshift(x);
              }
            });
          }
          if (this.profileList?.length >= 2) {
            this.openProfileSelectorModal();
          } else if (this.profileList?.length === 1) {
            this.onProfileSelect(this.profileList[0]);
          }
        }
      }else {
        let result = await this.allDataService.profileService.createProfile('Bill Book');
        if (result?._localUUID) {
          this.newProfileGeneratedUUID=result?._localUUID;
          this.allDataService.listForceReloadSubs.next('profile-list');
          this.onProfileSelect(result);
        }
      }
    } catch (error) {
      SentryUtilites.setLog("FetchInitialDataPage:ngOnInit", error)
    }
  }

  ionViewWillLeave() {
    try {
      if(!Utility.getFromLocalStorage('Initial_data_download_complete')) {
        this.ProfileModalSelectorEle?.dismiss();
      }
    } catch (error) {
      SentryUtilites.setLog("FetchInitialDataPage:ionViewWillLeave", error)
    }
  }

  setOwnerUserId() {
    this.ownerUserId = this.authService.getLoginPhone();
  }

  async startDataLoading() {
    try {
      for (const type in this.collectionTypes) {
  
        if(type == 'Maintaining ledger') {
          this.collectionTypes[type].activated = true;
          let isSuccess = await this.ledgerService.maintainLedger();
          if(isSuccess) {
            this.collectionTypes[type].loaded = true;
            this.collectionTypes[type].failed = false;
          }else {
            this.collectionTypes[type].loaded = false;
            this.collectionTypes[type].failed = true;
          }
        }else {
          let currentResponseRecordLength: number = 0;
          do {
            if (Object.prototype.hasOwnProperty.call(this.collectionTypes, type)) {
              const element = this.collectionTypes[type];
              element.activated = true;
  
              let profiles = await this.allDataService.profileService.getAllByPromise() || [];
              let ownerUserId = this.authService.getLoginPhone();
  
              let accessProfiles:{
                profileId:string,
                userId:string,
                lastSyncStamp:number
              }[] = [];
  
              let accessProfilesIdArr: string[] = [];
              let accessProfilesUserIdArr: string[] = [];
  
              profiles?.forEach(profile => {
                if(profile?.userId != ownerUserId) {
                  accessProfilesIdArr.push(profile?._localUUID);
                  accessProfilesUserIdArr.push(profile?.userId);
                  accessProfiles.push({
                    profileId: profile?._localUUID,
                    userId: profile?.userId,
                    lastSyncStamp: Utility.getCollectionSyncStamp(element.schemaName, type=='Licence' ? profile?.userId : profile?._localUUID)
                  });
                }
              });
  
              let body = {
                lastSyncStamp:Utility.getCollectionSyncStamp(element.schemaName),
                accessProfiles
              };
  
              element.count = (await this.getAllIndexedDbData(element.schemaName))?.length || 0;
  
              let clearIndexedDB = false;
              if(currentResponseRecordLength == 0 && body.lastSyncStamp == 0) {
                clearIndexedDB = true;
                element.count = 0;
              }
  
              let result = await this.loadCollection(element.path, element.schemaName, body, clearIndexedDB);
              currentResponseRecordLength = result?.records?.length || 0;
              element.count += currentResponseRecordLength;
  
              if (result?.result) {
                if (this.authService.isUserLoggedIn) {
                  Utility.setCollectionLastRespSyncStamp(element.schemaName, +new Date());
                }
  
                let resAccessProfilesIdArr:string[] = [];
                let resAccessUserIdArr:string[] = [];
                let latestUpdateStamp = 0;
  
                for (let i = 0; i < result?.records.length; i++) {
                  let record = result?.records[i];
  
                  if(accessProfilesIdArr.indexOf(record?.profileId) != -1) {
                    if(resAccessProfilesIdArr.indexOf(record?.profileId) === -1) {
                      resAccessProfilesIdArr.push(record?.profileId);
                    }
                  }
  
                  if(type == 'Licence') {
                    if(accessProfilesUserIdArr.indexOf(record?.userId) != -1) {
                      if(resAccessUserIdArr.indexOf(record?.userId) === -1) {
                        resAccessUserIdArr.push(record?.userId);
                      }
                    }
                  }
  
                  if (Number(record.updatedStamp) > latestUpdateStamp) {
                    latestUpdateStamp = Number(record.updatedStamp)
                  }
                }
  
                latestUpdateStamp = latestUpdateStamp > 0 ? latestUpdateStamp : +new Date();
  
                if (this.authService.isUserLoggedIn) {
                  Utility.setCollectionSyncStamp(element.schemaName, latestUpdateStamp);
                  accessProfilesIdArr?.forEach(profileId => {
                    Utility.setCollectionSyncStamp(element.schemaName, latestUpdateStamp, profileId);
                  });
                  resAccessProfilesIdArr?.forEach(profileId => {
                    Utility.setCollectionSyncStamp(element.schemaName, latestUpdateStamp, profileId);
                  });
  
                  if(type == 'Licence') {
                    resAccessUserIdArr?.forEach(userId => {
                      Utility.setCollectionSyncStamp(element.schemaName, latestUpdateStamp, userId);
                    });
                  }
  
                  if(currentResponseRecordLength < 500 && type!='Licence') {
                    accessProfilesIdArr?.forEach(profileId => {
                      let currentLastSyncStamp =  Utility.getCollectionSyncStamp(element.schemaName,profileId);
                      if(!currentLastSyncStamp && !currentResponseRecordLength) {
                        Utility.setCollectionSyncStamp(element.schemaName, latestUpdateStamp, profileId);
                      }
                    });
                  }
                }
  
                element.loaded = true;
                element.failed = false;
              } else if (result?.records == null) {
                element.loaded = false;
                element.failed = true;
                let loadFailAlert = await this.alertController.create({
                  header: 'Connection Fail Alert!',
                  message: 'Please check your internet connection and retry',
                  buttons: [
                    {
                      text: 'Retry',
                      role: 'cancel',
                      handler: () => {
                        this.startDataLoading();
                        loadFailAlert.dismiss();
                      },
                    },
                    {
                      text: 'Log Out',
                      role: 'confirm',
                      handler: async () => {
                        await this.logoutService.logout();
                        loadFailAlert.dismiss();
                      },
                    },
                  ],
                  mode: 'ios',
                });
                await loadFailAlert.present();
                return
              } else {
                element.loaded = true;
                element.failed = false;
              }
            }
          }while(currentResponseRecordLength == 500)
  
          this.collectionTypes[type].loaded = true;
          this.collectionTypes[type].failed = false;
        }
      }
      const failedCollection = Object.keys(this.collectionTypes).filter(key => this.collectionTypes[key].failed);
      if (failedCollection?.length) {
        const toast = await this.toastController.create({
          header: `Collection loading Error`,
          duration: 300000,
          position: 'top',
          mode: 'ios',
          color: 'danger',
        });
        await toast.present();
      } else {
        if(this.newProfileGeneratedUUID!=''){
          await this.allDataService.profileService.addSampleDataToProfile(this.newProfileGeneratedUUID);
          this.allDataService.itemService.reloadList();
          this.allDataService.partyService.reloadList();
          this.allDataService.listForceReloadSubs.next('party-list');
          this.allDataService.listForceReloadSubs.next('item-list');
  
        }
        if (this.authService.isUserLoggedIn) {
          this.imageBase64Service.initiateImageBase64BackgroundProcess();
          Utility.setToLocalStorage('Initial_data_download_complete',+new Date())
          this.allDataService.profileService.setCreatedByName();
          this.allDataService.socketConnect();
          this.allDataService.listForceReloadSubs.next('licence-list');
          this.eventService.setShowSideNav(true);
          this.router.navigate(['dashboard']);
        }
      }
    } catch (error) {
      SentryUtilites.setLog("FetchInitialDataPage:startDataLoading", error)
    }

  }

  async loadCollection(path: string, schemaName: string, body: any, isClearIndexedDB: boolean): Promise<{
    records: any[],
    result: boolean
  }> {
    return new Promise(async (resolve, reject) => {
      try {
        if(!isClearIndexedDB || (isClearIndexedDB && await this.clearIndexedDB(schemaName))) {
          let res = await this.expressServerService.makeFetchAllCall(path,body);
          if (res) {
            if (res['records']) {

              let rawRecords: any[] = res['records'];
              let recordMaps:any={};
              for(let i=0;i<rawRecords?.length;i++){
                recordMaps[rawRecords[i]?._localUUID]=rawRecords[i];
              }
              let records=[];
              for(let k in recordMaps){
                records.push(recordMaps[k])
              }
              records?.forEach(record => {
                if(record?.syncStamp <= record?.updatedStamp) {
                  record.syncStamp = record?.updatedStamp + 10;
                }
              });

              let saveArr: any[] = [];
              let updateArr: any[] = [];
              let successArr: any[] = [];

              for(let i = 0; i < records?.length; i++) {
                let record = records[i];
                let savedRecord = await this.getByUUID(schemaName,record?._localUUID);
                if (savedRecord && savedRecord?.updatedStamp < record?.updatedStamp) {
                  record._localId = savedRecord?._localId;
                  updateArr.push(record);
                }else if(!savedRecord) {
                  saveArr.push(record);
                }
              }

              if(updateArr?.length) {
                for(let i = 0; i < updateArr?.length; i++) {
                  let res = await this.updateDb(schemaName,updateArr[i]);
                  if(!res?._localUUID) {
                    successArr.push(res);
                  }
                }
              }

              if(saveArr?.length) {
                let x = await this.bulkAdd(schemaName,saveArr);
                return resolve({
                  records: res['records'],
                  result: (x?.length + successArr?.length) === records?.length ? true : false
                });
              }else {
                return resolve({
                  records: res['records'],
                  result: (successArr?.length) === records?.length ? true : false
                });
              }


            } else {
              resolve({
                records: [],
                result: false
              });
            }
          } else {
            resolve({
              records: null,
              result: false
            });
          }
        }
      } catch (error) {
        SentryUtilites.setLog("FetchInitialDataPage:loadCollection", error)
        return resolve({
          records: null,
          result: false
        });
      }

    });
  }

  async loadProfiles(): Promise<Profile[]> {
    return new Promise(async (resolve, reject) => {
      try {
        this.ngxIndexedDBService.clear(IProfile.SCHEMA_NAME).subscribe(async (_res) => {
          let res = await this.expressServerService.makeFetchAllCall('profile/fetchAllv3',{});
          if (res) {
            if (res['records']) {
              let rawRecords: any[] = res['records'];
              let recordMaps:any={};
              for(let i=0;i<rawRecords?.length;i++){
                recordMaps[rawRecords[i]?._localUUID]=rawRecords[i];
              }
              let records=[];
              for(let k in recordMaps){
                records.push(recordMaps[k])
              }
              records?.forEach(record => {
                if(record?.syncStamp <= record?.updatedStamp) {
                  record.syncStamp = record?.updatedStamp + 10;
                }
              });
              // records = records?.filter(profile => !profile.deletedStamp);
              this.ngxIndexedDBService.bulkAdd(IProfile.SCHEMA_NAME, records).subscribe(x => {
                return resolve(x?.length === records?.length ? records : null);
              },
              err => {
                if(this.authService.isUserLoggedIn) {
                  console.error(err);
                  this.router.navigate(['idbx-error']);
                }
              });
            } else {
              return resolve(null);
            }
          } else {
            return resolve(null);
          }
        },
        err => {
          if(this.authService.isUserLoggedIn) {
            console.error(err);
            this.router.navigate(['idbx-error']);
          }
        });
      } catch (error) {
        SentryUtilites.setLog("FetchInitialDataPage:loadProfiles", error)
        return resolve(null);
      }
    });
  }

  returnZero = () => -1;

  setActiveProfile(profile: Profile): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        if (profile?._localUUID) {
          if (this.authService.isUserLoggedIn) {
            Utility.setToLocalStorage('selectedProfile', profile?._localUUID);
            Utility.setToLocalStorage('selectedProfileUserId', profile?.userId);
          }
          profile = Utility.settingDefaultValue(profile);
          this.allDataService.profileService.update(profile);
          this.allDataService.initService();
        }
        return resolve(true);
      } catch (error) {
        SentryUtilites.setLog("FetchInitialDataPage:setActiveProfile", error)
        return resolve(false);
      }
    });
  }

  openProfileSelectorModal(value: boolean = true) {
    this.isProfileSelectorModalOpen = value;
  }

  onProfileSelect(profile: Profile) {
    try {
      this.openProfileSelectorModal(false);
      this.setActiveProfile(profile);
      this.isLoadingContent = true;
      this.startDataLoading();
    } catch (error) {
      SentryUtilites.setLog("FetchInitialDataPage:onProfileSelect", error)
    }
  }

  clearIndexedDB(schemaName) {
    return new Promise(async (resolve, reject) => {
      try {
        this.ngxIndexedDBService.clear(schemaName).subscribe((_res) => {
          return resolve(true);
        },
        err => {
          resolve(false);
          console.error(err);
          if(this.authService.isUserLoggedIn && typeof err == 'string' && err?.includes('objectStore does not exists')) {
            this.router.navigate(['idbx-error']);
          } else if (typeof err?.target?.error == 'object') {
            SentryUtilites.setLog("FetchInitialDataPage:clearIndexedDB", err?.target?.error)
          } else {
            SentryUtilites.setLog("FetchInitialDataPage:clearIndexedDB", err)
          }
        });
      } catch (error) {
        SentryUtilites.setLog("FetchInitialDataPage:clearIndexedDB", error)
        return resolve(false);
      }
    });
  }

  getAllIndexedDbData(schemaName: string): Promise<any[]> {
    console.log(schemaName)
    return new Promise((resolve, reject) => {
      try {
        this.ngxIndexedDBService.getAll(schemaName).subscribe((docs: any[]) => {
          return resolve(docs)
        },
        err => {
          console.error(err);
          if(this.authService.isUserLoggedIn && typeof err == 'string' && err?.includes('objectStore does not exists')) {
            this.router.navigate(['idbx-error']);
          } else if (typeof err?.target?.error == 'object') {
            SentryUtilites.setLog("FetchInitialDataPage:getAllIndexedDbData", err?.target?.error)
          } else {
            SentryUtilites.setLog("FetchInitialDataPage:getAllIndexedDbData", err)
          }
        });
      } catch (err) {
        SentryUtilites.setLog("FetchInitialDataPage:getAllIndexedDbData", err)
        return resolve(null)
      }
    });
  }

  getByUUID(schemaName: string,uuid: string): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        this.ngxIndexedDBService
          .getByIndex(schemaName, '_localUUID', uuid)
          .subscribe((records: any) => {
            return resolve(records);
          },
          err => {
            console.error(err);
            if(this.authService.isUserLoggedIn && typeof err == 'string' && err?.includes('objectStore does not exists')) {
              this.router.navigate(['idbx-error']);
            } else if (typeof err?.target?.error == 'object') {
              SentryUtilites.setLog("FetchInitialDataPage:getByUUID", err?.target?.error)
            } else {
              SentryUtilites.setLog("FetchInitialDataPage:getByUUID", err)
            }
          });
      } catch (err) {
        SentryUtilites.setLog("FetchInitialDataPage:getByUUID", err)
        return resolve(null)
      }
    })
  }

  updateDb(schemaName: string,data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        this.ngxIndexedDBService
          .update(schemaName, data)
          .subscribe((updatedRecord: any) => {
            return resolve(updatedRecord);
          },
          err => {
            console.error(err);
            if(this.authService.isUserLoggedIn && typeof err == 'string' && err?.includes('objectStore does not exists')) {
              this.router.navigate(['idbx-error']);
            } else if (typeof err?.target?.error == 'object') {
              SentryUtilites.setLog("FetchInitialDataPage:updateDb", err?.target?.error)
            } else {
              SentryUtilites.setLog("FetchInitialDataPage:updateDb", err)
            }
          });
        return resolve(null);
      } catch (err) {
        SentryUtilites.setLog("FetchInitialDataPage:updateDb", err)
        return resolve(null);
      }
    })
  }

  saveDb(schemaName: string,data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        this.ngxIndexedDBService
          .add(schemaName, data)
          .subscribe((savedRecord: any) => {
            return resolve(savedRecord);
          },
          err => {
            if(this.authService.isUserLoggedIn && typeof err == 'string' && err?.includes('objectStore does not exists')) {
              console.error(err);
              this.router.navigate(['idbx-error']);
            } else if (typeof err?.target?.error == 'object') {
              SentryUtilites.setLog("FetchInitialDataPage:saveDb", err?.target?.error)
            } else {
              SentryUtilites.setLog("FetchInitialDataPage:saveDb", err)
            }
          });
      } catch (error) {
        SentryUtilites.setLog("FetchInitialDataPage:saveDb", error)
        return resolve(null);
      }
    })
  }

  bulkAdd(schemaName: string,records: any): Promise<any[]> {
    return new Promise((resolve, reject) => {
      try {
        this.ngxIndexedDBService.bulkAdd(schemaName, records).subscribe(x => {
          return resolve(x);
        }, (error) => {
          console.error(schemaName, error);
          resolve(null);
          if(this.authService.isUserLoggedIn && typeof error == 'string' && error?.includes('objectStore does not exists')) {
            this.router.navigate(['idbx-error']);
          } else if (typeof error?.target?.error == 'object') {
            SentryUtilites.setLog("FetchInitialDataPage:bulkAdd", error?.target?.error)
          } else {
            SentryUtilites.setLog("FetchInitialDataPage:bulkAdd", error)
          }
        }, () => {
          console.info(`${schemaName} completed`)
          return resolve(null);
        });
      } catch (error) {
        SentryUtilites.setLog("FetchInitialDataPage:bulkAdd", error)
        return resolve(null);
      }
    })
  }

}
