import { PurchaseReturnDao } from './dao/purchase-return.dao';
import { MonthWisePartyCreditService } from './month-wise-party-credit.service';
import { BehaviorSubject } from "rxjs";
import Party from "../models/Party.model";
import { Utility } from "../utils/utility";
import { ExpressServerService } from "./api/express-server.service";
import { IDataService } from "../../interface/IDataService.interface";
import { PartyDao } from "./dao/party.dao";
import { LedgerParty } from "../models/LedgerParty.model";
import { SaleDao } from "./dao/sale.dao";
import { PurchaseDao } from "./dao/purchase.dao";
import { MoneyInDao } from "./dao/money-in.dao";
import { MoneyOutDao } from "./dao/money-out.dao";
import { AuthService } from "./auth/auth.service";
import { ExpenseDao } from "./dao/expense.dao";
import { SentryUtilites } from '../utils/sentryUtilites';
import { SaleReturnDao } from './dao/sale-return.dao';

export class PartyService implements IDataService<Party>{
  private static _instance: PartyService;

  public static getInstance(
    dao: PartyDao,
    saleDao: SaleDao,
    saleReturnDao: SaleReturnDao,
    purchaseDao: PurchaseDao,
    purchaseReturnDao: PurchaseReturnDao,
    expenseDao: ExpenseDao,
    moneyInDao: MoneyInDao,
    moneyOutDao: MoneyOutDao,
    expressServerService: ExpressServerService,
    authService: AuthService,
    monthWisePartyCreditService: MonthWisePartyCreditService,
  ) {
    if (!this._instance) {
      this._instance = new PartyService(
        dao,
        saleDao,
        saleReturnDao,
        purchaseDao,
        purchaseReturnDao,
        expenseDao,
        moneyInDao,
        moneyOutDao,
        expressServerService,
        authService,
        monthWisePartyCreditService,
      )
      this._instance.initService();
    }
    return this._instance;
  }

  constructor(
    dao: PartyDao,
    saleDao: SaleDao,
    saleReturnDao: SaleReturnDao,
    purchaseDao: PurchaseDao,
    purchaseReturnDao: PurchaseReturnDao,
    expenseDao: ExpenseDao,
    moneyInDao: MoneyInDao,
    moneyOutDao: MoneyOutDao,
    expressServerService: ExpressServerService,
    authService: AuthService,
    monthWisePartyCreditService: MonthWisePartyCreditService,
  ) {
    this.dao = dao;
    this.saleDao = saleDao;
    this.saleReturnDao = saleReturnDao;
    this.purchaseDao = purchaseDao;
    this.purchaseReturnDao = purchaseReturnDao;
    this.expenseDao = expenseDao;
    this.moneyInDao = moneyInDao;
    this.moneyOutDao = moneyOutDao;
    this.expressServerService = expressServerService;
    this.authService = authService;
    this.monthWisePartyCreditService = monthWisePartyCreditService;
  }
  dao: PartyDao;
  saleDao: SaleDao;
  saleReturnDao: SaleReturnDao;
  purchaseDao: PurchaseDao;
  purchaseReturnDao: PurchaseReturnDao;
  expenseDao: ExpenseDao;
  moneyInDao: MoneyInDao;
  moneyOutDao: MoneyOutDao;
  expressServerService: ExpressServerService;
  authService: AuthService;
  monthWisePartyCreditService: MonthWisePartyCreditService;

  LIST_REFRESH_RATE = 1000;
  selectedProfileId: string = null;
  selectedProfileUserId: string = null;
  list: Party[] = [];
  listSubs = new BehaviorSubject<Party[]>([]);
  updateSubs = new BehaviorSubject<Party>(null);

  lastReloadStamp: number = 0;
  isReloadPostpond = false;

  initService() {
    this.selectedProfileId = Utility.getFromLocalStorage('selectedProfile');
    this.selectedProfileUserId = Utility.getFromLocalStorage('selectedProfileUserId');
    this.list = [];
    this.reloadList();
  }

  reloadList = async () => {
    try {
      if (this.isReloadPostpond) {
        return;
      }
      const currentStamp = +new Date();
      if (this.lastReloadStamp < (currentStamp - this.LIST_REFRESH_RATE)) {
        this.lastReloadStamp = currentStamp;
        let docs = await this.dao.getAllByProfile(this.selectedProfileId);
        this.list = docs;
        this.listSubs.next(this.list);
        this.trySyncUnsynced();
      } else {
        this.isReloadPostpond = true;
        setTimeout(() => {
          this.isReloadPostpond = false;
          this.reloadList();
        }, this.LIST_REFRESH_RATE + 100);
      }
    } catch (error) {
      SentryUtilites.setLog("PartyService:reloadList", error)
      return null;
    }
  }

  getAll() {
    return this.dao.getAll();
  }

  getAllByPromise() {
    return this.dao.getAllByProfile(this.selectedProfileId);
  }

  getAllByPromiseByProfile(profileId: string) {
    return this.dao.getAllByProfile(profileId);
  }

  getById(id: number): Promise<Party> {
    return this.dao.getById(id);
  }

  getByUUID(uuid: string): Promise<Party> {
    return this.dao.getByUUID(uuid);
  }

  /**
   * 
   * @param startTime : provide start time stamp
   * @returns : filter party created stamp less than start time and lastSaleStamp greater than start time stamp
   */
  getByBillDateRange(startTime: number): Promise<Party[]> {
    return new Promise(async (resolve, reject) => {
      try {
        let allDocs = await this.dao.getAllByProfile(this.selectedProfileId);
        if (allDocs != null) {
          let filteredDocs = allDocs.filter(doc => doc?.createdStamp < startTime && doc?.lastSaleStamp > startTime);
          return resolve(filteredDocs);
        } else {
          return resolve(null);
        }
      } catch (error) {
        SentryUtilites.setLog("PartyService:getByBillDateRange", error)
        return resolve(null);
      }
    });
  }
  // ------------------------------------

  /**
   * 
   * @param startTime : provide start time stamp
   * @returns : filter party created stamp less than start time and lastSaleStamp greater than start time stamp
   */
  getByCreatedDateRange(startTime: number): Promise<Party[]> {
    return new Promise(async (resolve, reject) => {
      try {
        let allDocs = await this.dao.getAllByProfile(this.selectedProfileId);
        if (allDocs != null) {
          let filteredDocs = allDocs.filter(doc => doc?.createdStamp < startTime && doc?.lastSaleStamp > startTime);
          return resolve(filteredDocs);
        } else {
          return resolve(null);
        }
      } catch (error) {
        SentryUtilites.setLog("PartyService:getByCreatedDateRange", error)
        return resolve(null);
      }
    });
  }
  // ------------------------------------

  async addDummyParties(n: number = 50) {
    for (let i = 0; i < n; i++) {
      let party = new Party();
      party.name = Utility.generateName();
      party.phone = Utility.generateRandomPhone();
      await this.save(party);
    }
  }

  save(party: Party): Promise<Party> {
    return new Promise(async (resolve, reject) => {
      try {
        if(Utility.isTruthy(party)) {
          if (!party.profileId) {
            party.profileId = this.selectedProfileId;
          }
          party.userId = this.selectedProfileUserId;
          party.createdBy = party.lastModifiedBy = this.authService.getLoginPhone();
          party.createdByName = party.lastModifiedByName = Utility.getCreatedByName();
    
          let savedParty = await this.dao.save(party);
    
          if(savedParty?._localUUID) {
            await this.monthWisePartyCreditService.save(savedParty?._localUUID);
          }
    
          this.reloadList();
          return resolve(savedParty);
        } else {
          return resolve(null);
        }
      } catch (error) {
        SentryUtilites.setLog("PartyService:save", error)
        return resolve(null);
      }
    });
  }

  update(party: Party): Promise<Party> {
    return new Promise(async (resolve, reject) => {
      try {
        if(party?._localUUID) { 
          party.lastModifiedBy = this.authService.getLoginPhone();
          party.lastModifiedByName = Utility.getCreatedByName();
      
          let updatedParty = await this.dao.update(party);
          this.reloadList();
          return resolve(updatedParty);
        } else {
          return resolve(null);
        }
      } catch (error) {
        SentryUtilites.setLog("PartyService:update", error)
        return resolve(null);
      }
    });
  }

  delete(party: Party): Promise<Party> {
    return new Promise(async (resolve, reject) => {
      try {
        if(party?._localUUID) {
          party.lastModifiedBy = this.authService.getLoginPhone();
          party.lastModifiedByName = Utility.getCreatedByName();
      
          let deletedParty = await this.dao.delete(party);
          this.reloadList();
          return resolve(deletedParty);
        } else {
          return resolve(null);
        }
      } catch (error) {
        SentryUtilites.setLog("PartyService:delete", error)
        return resolve(null);
      }
    });
  }

  isSyncLock = false;
  isSyncPostPond = false;
  async trySyncUnsynced(postpond?: boolean) {
    try {
      if (this.isSyncLock) {
        if (!this.isSyncPostPond) {
          setTimeout(() => {
            this.isSyncPostPond = true;
            this.trySyncUnsynced(true);
          }, 200);
        }
        return true;
      }
      if (postpond) {
        this.isSyncPostPond = false;
      }
      this.isSyncLock = true;
      let unSyncedElements: Party[] = await this.dao.getAllUnsynced(this.selectedProfileId);
      if (unSyncedElements && unSyncedElements?.length) {
        try {
  
          for (let i = 0; i < unSyncedElements.length; i++) {
            let unSyncedElement = unSyncedElements[i];
            if(unSyncedElement?._localUUID) {
              unSyncedElement['updatedStamp'] = +new Date();
            }
          }
  
          await this.dao.bulkPut(unSyncedElements);
          await Utility.wait(1000);
  
          let chunkArr = Utility.getChunkArr(unSyncedElements);
          let chunkArrLength = chunkArr?.length;
  
          for(let i = 0; i < chunkArrLength; i++) {
            let result = await this.expressServerService.makeSyncCall('party', chunkArr[i]);
            if (result && result?.['records']?.length) {
              let arr = result?.['records'];
              for (let i = 0; i < arr?.length; i++) {
                const el = arr[i];
                await this.updateSyncStamp(el);
              }
            }
          }
        } catch (err) {
          SentryUtilites.setLog("PartyService:trySyncUnsynced:inner", err)
        }
      }
      this.isSyncLock = false;
    } catch (error) {
      SentryUtilites.setLog("PartyService:trySyncUnsynced", error)
    }
  }

  updateSyncStamp(el: Party): Promise<Party> {
    return new Promise(async (resolve, reject) => {
      try {
        let updatedEl = await this.dao.updateSyncStamp(el);
        this.updateSubs.next(updatedEl);
        return resolve(updatedEl);
      } catch (error) {
        SentryUtilites.setLog("PartyService:updateSyncStamp", error)
        return resolve(null);
      }
    });
  }

  async updatePartyCredit(party: Party, amount: number): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        await this.dao.updateCredit(party, amount);
        this.reloadList()
        return resolve(true);
      } catch (error) {
        SentryUtilites.setLog("PartyService:updatePartyCredit", error)
        return resolve(false);
      }
    });
  }

  async calculateRunningCredit(partyUUID: string): Promise<{ transactionsList: LedgerParty[], credit: number }> {
    try {
      let transactionsList: LedgerParty[] = [];

      let allSales = await this.saleDao.getAllByProfile(this.selectedProfileId);
      let allSaleReturns = await this.saleReturnDao.getAllByProfile(this.selectedProfileId);
      let allMoneyIns = await this.moneyInDao.getAllByProfile(this.selectedProfileId);
      let allPurchases = await this.purchaseDao.getAllByProfile(this.selectedProfileId);
      let allPurchaseReturns = await this.purchaseReturnDao.getAllByProfile(this.selectedProfileId);
      let allExpenses = await this.expenseDao.getAllByProfile(this.selectedProfileId);
      let allMoneyOuts = await this.moneyOutDao.getAllByProfile(this.selectedProfileId);

      allSales?.forEach(sale => {
        if (sale?.party?._localUUID == partyUUID) {
          transactionsList.push({
            type: "Sale",
            createdStamp: sale?.createdStamp,
            billDateStamp: sale?.billDateStamp,
            sale,
            runningBalance: 0,
            docLocalUUID: sale?._localUUID,
          });
        }
      });
      allSaleReturns?.forEach(saleReturn => {
        if (saleReturn?.party?._localUUID == partyUUID) {
          transactionsList.push({
            type: "SaleReturn",
            createdStamp: saleReturn?.createdStamp,
            billDateStamp: saleReturn?.billDateStamp,
            saleReturn,
            runningBalance: 0,
            docLocalUUID: saleReturn?._localUUID,
          });
        }
      });
      allMoneyIns?.forEach(moneyIn => {
        if (moneyIn.party?._localUUID == partyUUID) {
          transactionsList.push({
            type: "MoneyIn",
            createdStamp: moneyIn?.createdStamp,
            billDateStamp: moneyIn?.billDateStamp,
            moneyIn,
            runningBalance: 0,
            docLocalUUID: moneyIn?._localUUID,
          });
        }
      });
      allPurchases?.forEach(purchase => {
        if (purchase?.party?._localUUID == partyUUID) {
          transactionsList.push({
            type: "Purchase",
            createdStamp: purchase?.createdStamp,
            billDateStamp: purchase?.billDateStamp,
            purchase,
            runningBalance: 0,
            docLocalUUID: purchase?._localUUID,
          });
        }
      });
      allPurchaseReturns?.forEach(purchaseReturn => {
        if (purchaseReturn?.party?._localUUID == partyUUID) {
          transactionsList.push({
            type: "PurchaseReturn",
            createdStamp: purchaseReturn?.createdStamp,
            billDateStamp: purchaseReturn?.billDateStamp,
            purchaseReturn,
            runningBalance: 0,
            docLocalUUID: purchaseReturn?._localUUID,
          });
        }
      });
      allExpenses?.forEach(expense => {
        if (expense?.party?._localUUID == partyUUID) {
          transactionsList.push({
            type: "Expense",
            createdStamp: expense?.createdStamp,
            billDateStamp: expense.billDateStamp,
            expense,
            runningBalance: 0,
            docLocalUUID: expense?._localUUID,
          });
        }
      });
      allMoneyOuts?.forEach(moneyOut => {
        if (moneyOut?.party?._localUUID == partyUUID) {
          transactionsList.push({
            type: "MoneyOut",
            createdStamp: moneyOut?.createdStamp,
            billDateStamp: moneyOut?.billDateStamp,
            moneyOut,
            runningBalance: 0,
            docLocalUUID: moneyOut?._localUUID,
          });
        }
      });

      transactionsList.sort((a, b) => {
        if (b?.billDateStamp == a?.billDateStamp) {
          return b?.createdStamp - a.createdStamp;
        }
        return b?.billDateStamp - a?.billDateStamp;
      });

      let credit = 0;

      transactionsList.reverse()?.forEach(transaction => {
        if (transaction?.type === 'Sale' && Utility.isNumber(transaction?.sale?.totalAmount)) {
          credit += Number(transaction?.sale?.totalAmount);
        }
        if (transaction?.type === 'SaleReturn' && Utility.isNumber(transaction?.saleReturn?.totalAmount)) {
          credit -= Number(transaction?.saleReturn?.totalAmount);
        }
        if (transaction?.type === 'MoneyOut' && Utility.isNumber(transaction?.moneyOut?.totalAmount)) {
          credit += Number(transaction?.moneyOut?.totalAmount);
        }
        if (transaction?.type === 'Purchase' && Utility.isNumber(transaction?.purchase?.totalAmount)) {
          credit -= Number(transaction?.purchase?.totalAmount);
        }
        if (transaction?.type === 'PurchaseReturn' && Utility.isNumber(transaction?.purchaseReturn?.totalAmount)) {
          credit += Number(transaction?.purchaseReturn?.totalAmount);
        }
        if (transaction?.type === 'Expense' && Utility.isNumber(transaction?.expense?.totalAmount)) {
          credit -= Number(transaction?.expense?.totalAmount);
        }
        if (transaction?.type === 'MoneyIn' && Utility.isNumber(transaction?.moneyIn?.totalAmount)) {
          credit -= Number(transaction?.moneyIn?.totalAmount);
        }
        transaction.runningBalance = credit;
      });

      transactionsList.reverse();

      // Party Credit Update
      const fetchedParty = await this.getByUUID(partyUUID);
      if(fetchedParty?._localUUID) {
        fetchedParty.credit = credit;
        this.update(fetchedParty);
      }

      return {transactionsList,credit};

    } catch (error) {
      SentryUtilites.setLog("PartyService:calculateRunningCredit", error)
      return null;
    }
  }

  copyData(fromProfileId: string, toProfileId: string): Promise<boolean> {
    return new Promise(async (resolve,reject) => {
      try {
        if(Utility.isTruthy(fromProfileId) && Utility.isTruthy(toProfileId)) {
          let fromRecords = await this.getAllByPromiseByProfile(fromProfileId);
          if(fromRecords?.length) {
            let toRecords: Party[] = [];
            for (let i = 0; i < fromRecords?.length; i++) {
              const fetchedRecord = fromRecords[i];
              if(!fetchedRecord.isCashSaleParty && fetchedRecord?._localUUID) { 
                fetchedRecord.profileId = toProfileId;
                delete fetchedRecord?._localId;
                delete fetchedRecord?._localUUID;
                let savedRecord = await this.save(fetchedRecord);
                if(savedRecord?._localUUID) {
                  toRecords?.push(savedRecord);
                }
              }
            }
            return resolve(true);
          }
        }
        return resolve(false);
      } catch (error) {
        SentryUtilites.setLog("PartyService:copyData", error)
        return resolve(false);
      }
    });
  }

}

