<ion-header>
  <ion-toolbar class="{{ getHeaderColorClass() }}">
    <app-navigation-control slot="start" type="money-in/form"></app-navigation-control>
    <ion-title>Money In Form</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">

  <app-money-in-form
    [paramDocumentId]="paramDocumentId"
    (savedMoneyIn)="savedMoneyIn($event)"
    (updatedMoneyIn)="updatedMoneyIn($event)"
  >
  </app-money-in-form>

</ion-content>
