import { BaseModel } from "./BaseModel.model";

export class IItemUnit {

  static SCHEMA_NAME = 'item-unit';

}
export class ItemUnit extends BaseModel {

  profileId: string;
  name: string;
}
