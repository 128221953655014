<ion-modal class="modal-from-to-date" [keepContentsMounted]="keepContentsMounted" [isOpen]="openFromDatePicker" (willDismiss)="closeDOBPicker()">
  <ng-template>
    <ion-datetime appYearDefaultBtn #fromDateEle id="fromDate" [showDefaultButtons]="true" presentation="date" [value]="dpFromDateValue" (ionChange)="onFromToDateSelect($event)"
      [disabled]="selectedDateRange!='custom'" [max]="maxDate">
      <span slot="title">Select a From Date</span>
    </ion-datetime>
  </ng-template>
</ion-modal>

<ion-modal class="modal-from-to-date" [keepContentsMounted]="keepContentsMounted" [isOpen]="openToDatePicker" (willDismiss)="closeDOBPicker()">
  <ng-template>
    <ion-datetime appYearDefaultBtn #toDateEle id="toDate" [showDefaultButtons]="true" presentation="date" [value]="dpToDateValue" (ionChange)="onFromToDateSelect($event)"
      [disabled]="isTodaysEndDate || selectedDateRange!='custom'" [min]="minToDate" [max]="maxDate">
      <span slot="title">Select a To Date</span>
    </ion-datetime>
  </ng-template>
</ion-modal>

<ion-grid [ngClass]="{'mobile': isMobile}">
  <ion-row>
    <ion-col size-lg="6" size-sm="12">
      <ion-item class="segment" lines="none">
        <ion-segment mode="ios" [value]="selectedDateRange" (ionChange)="onDateRangeChange($event)">
          <ion-segment-button value="today">
            <ion-label>Today</ion-label>
          </ion-segment-button>
          <ion-segment-button value="7days">
            <ion-label>7 Days</ion-label>
          </ion-segment-button>
          <ion-segment-button value="30days">
            <ion-label>30 Days</ion-label>
          </ion-segment-button>
          <ion-segment-button value="month">
            <ion-label>Month</ion-label>
          </ion-segment-button>
          <ion-segment-button value="year" [disabled]="disableYearCustomFilter">
            <ion-label>Year</ion-label>
          </ion-segment-button>
          <ion-segment-button value="custom" [disabled]="disableYearCustomFilter">
            <ion-label>Custom</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-item>
    </ion-col>
    <ion-col size-md="3" size-sm="6">
      <ion-item lines="none" class="date-picker-button-from">
        <ion-label>From</ion-label>
        <input type="button" class="new-ion-chip" 
          [value]="setDateValue(fromDateValue)"
          (click)="openFromDatePicker = true;">
      </ion-item>
    </ion-col>
    <ion-col size-md="3" size-sm="6">
      <ion-item class="to-date-picker" lines="none">
        <ion-label>To</ion-label>
        <input type="button" class="new-ion-chip" 
          [value]="setDateValue(toDateValue)"
          (click)="openToDatePicker = true;">
      </ion-item>
    </ion-col>
  </ion-row>
</ion-grid>