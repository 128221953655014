import { Kot } from '../../../models/Kot.model';
import { ItemRestaurantSelectorComponent } from '../../../components/item-restaurant-selector/item-restaurant-selector.component';
import { Utility } from '../../../utils/utility';
import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PartySelectorComponent } from '../../../components/party-selector/party-selector.component';
import { ItemSelectorComponent } from '../../../components/item-selector/item-selector.component';
import { IonInput, ToastController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { AllDataService } from '../../../services/all-data.service';
import Party from '../../../models/Party.model';
import { BillItem } from '../../../models/BillItem.model';
import { MoneyIn } from '../../../models/MoneyIn.model';
import { BillCalculations } from '../../../utils/BillCalculations';
import { BillType } from '../../../utils/enums/BillType';
import { CalculateBillTask } from '../../../utils/enums/CalculateBillTask';
import { Profile } from '../../../models/Profile.model';
import { Utils } from '../../../utils/utils';
import { SettingsComponent } from '../../../components/settings/settings.component';
import { OrderService } from '../../../services/order.service';
import { Estimate } from 'src/app/models/Estimate.model';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-estimate-form',
  templateUrl: './estimate-form.page.html',
  styleUrls: ['./estimate-form.page.scss'],
})
export class EstimateFormPage implements OnInit {

  
  /*
    Native Element Ref
  */

    @ViewChild('partySelector', { static: false }) partySelector: PartySelectorComponent;
    @ViewChild('partyRestaurantSelector', { static: false }) partyRestaurantSelector: PartySelectorComponent;
    @ViewChild('itemSelector', { static: false }) itemSelector: ItemSelectorComponent;
    @ViewChild('itemRestaurantSelector', { static: false }) itemRestaurantSelector: ItemRestaurantSelectorComponent;
    @ViewChild('settingsModal') settingsModal: SettingsComponent;
    @ViewChild('amountReceivedEle') amountReceivedEle: IonInput;
    @ViewChild('paymentIdEle') paymentIdEle: IonInput;
    @ViewChild('kotPrintAudioEle') kotPrintAudioEle: ElementRef;
  
    getHeaderColorClass = Utility.getHeaderColorClass;
    maxDate = Utility.maxDateUpto50Year();
    states = Utility.states;
    isDisabledState = Utility.isDisabledState;
  
    /*
     Reactive Form
    */
  
    form: FormGroup;
  
    barcodeScannerMode = false;
    dueNetObj = { ...Utility.dueNetObj };
  
    dpBillDateValue: string = null;
    dpDueDateValue: string = null;
    dpEWayBillDateValue: string = null;
    dpDeliveryDateValue: string = null;
    minDateTransport: string = Utility.ionDatePickerFormattedString(+new Date().setHours(0, 0, 0, 0));
  
    showTransportDetailsCard = false;
    paramDocumentId: string = null;
    orderLocalUUID: string = null;
    fetchedEstimate: Estimate = null;
  
    dueDateContentsMounted: boolean = true;
  
    onClickSave: boolean = false;
  
    selectedProfile: Profile = null;
  
    paymentMode: string = 'cash';
  
    listStyleView = false;
  
    noPartySelectError = false;
  
    iSetItemPriceHistoryStatus = false;
  
    iSetOutOfStockHideStatus = false;

    isOpenSecondaryPartyModal: boolean = false;
  
    minDueDate: string = Utility.ionDatePickerFormattedString(+new Date().setHours(0, 0, 0, 0));

    showCashDiscountPercent: number = 0;

    isOpenBillDatePicker: boolean = false;

    isOpenDueDatePicker: boolean = false;

    isOpenEwayBillDatePicker: boolean = false;

    isOpenDeliveryDatePicker: boolean = false;

    keepContentsMounted: boolean = false;

    setDateValue = Utility.setDateValue;

    todayDate: number = +new Date().setHours(0, 0, 0, 0);

    isNgOnInitRun: boolean = false;

    subArr: Subscription[] = [];
  
    constructor(
      private formBuilder: FormBuilder,
      private toastController: ToastController,
      private allDataService: AllDataService,
      private route: ActivatedRoute,
      private router: Router,
      private orderService: OrderService,
    ) { }
  
    /*
      Life Cycle Hooks
    */
  
    ngOnInit() {
      try {
        this.isNgOnInitRun = true;
        this.getParamDocumentId();
        this.getOrderLocalUUID();
        this.initializeReactiveForm();
        this.populateBarcodeScannerSetting();
        this.subArr.push(this.allDataService.listForceReloadSubs.subscribe((listName: string) => {
          if (listName == 'profile-list') {
            this.getSelectedProfile();
          }
        }));
      } catch (error) {
        SentryUtilites.setLog("EstimateFormPage:ngOnInit", error)
      }
    }
  
    async ngAfterViewInit() {
      try {
        await this.getSelectedProfile();
        setTimeout(() => {
          this.populateForm();
        }, 500);
      } catch (error) {
        SentryUtilites.setLog("EstimateFormPage:ngAfterViewInit", error)
      }
    }

    ionViewWillEnter() {
      if(!this.isNgOnInitRun) {
        this.ngOnInit();
        this.ngAfterViewInit();
      }
    }

    ionViewWillLeave() {
      this.ngOnDestroy();
    }
    
    ngOnDestroy() {
      try {
        this.paramDocumentId = null;
        this.orderLocalUUID = null;
        this.resetBillForm();
        this.keepContentsMounted = false;
      } catch (error) {
        SentryUtilites.setLog("EstimateFormPage:ngOnDestroy", error)
      }
    }
  
    onSettingSave() {
      this.populateBarcodeScannerSetting();
    }
  
    getParamDocumentId() {
      this.paramDocumentId = this.route.snapshot.paramMap.get('documentId');
    }
  
    getOrderLocalUUID() {
      this.orderLocalUUID = this.route.snapshot.queryParamMap.get('orderLocalUUID');
    }
  
    populateSettings() {
      try {
        this.listStyleView = (this.selectedProfile?.iSetItemSelectorStyleWeb === 'List');
        this.iSetItemPriceHistoryStatus = this.selectedProfile?.iSetItemPriceHistoryStatus;
        if(this.selectedProfile?.iSetServiceChargePercentage) {
          this.form?.patchValue({serviceChargePercentage: this.selectedProfile?.iSetServiceChargePercentage});
        }
      } catch (error) {
        SentryUtilites.setLog("EstimateFormPage:populateSettings", error)
      }
    }
  
    // Fetch selected profile
    async getSelectedProfile() {
      try {
        this.selectedProfile = await this.allDataService.profileService.getCurrentProfile();
        this.populateSettings();
        return true;
      } catch (error) {
        SentryUtilites.setLog("EstimateFormPage:getSelectedProfile", error)
        return false;
      }
    }
  
    async populateForm() {
      try {
        if (this.paramDocumentId) {
          this.fetchedEstimate = await this.allDataService.estimateService.getByUUID(this.paramDocumentId);
          if(this.fetchedEstimate?._localUUID && !this.fetchedEstimate?.deletedStamp) {
            this.form.patchValue({ ...this.fetchedEstimate });
            this.dpBillDateValue = Utility.ionDatePickerFormattedString(this.fetchedEstimate?.billDateStamp);
            if(this.fetchedEstimate?.dueDateStamp) {
              this.dpDueDateValue = Utility.ionDatePickerFormattedString(this.fetchedEstimate?.dueDateStamp);
            }
            // this.dueDateContentsMounted = true;
    
            if(this.listStyleView) {
              this.partySelector?.setSelectedParty(this.fetchedEstimate?.party);
              if(this.paramDocumentId ) {
                this.partySelector?.disable();
              }
            }else {
              this.partyRestaurantSelector?.setSelectedParty(this.fetchedEstimate?.party);
              if(this.paramDocumentId ) {
                this.partyRestaurantSelector?.disable();
              }
            }
            this.itemSelector?.setBillItems(this.fetchedEstimate?.billItems);
            this.itemRestaurantSelector?.setBillItems(this.fetchedEstimate?.billItems);
            this.itemRestaurantSelector?.openItemSelectorModal();
            this.populateTransportDetails(this.fetchedEstimate?.transportDetail);
            this.keepContentsMounted = true;
            // this.setTransportDetailsCardVisibiltity();
            setTimeout(() => {
              this.form.patchValue({
                cashDiscount: this.fetchedEstimate?.cashDiscount,
                cashDiscountPercentage: this.fetchedEstimate?.cashDiscountPercentage,
              });
              this.recalculateBillItems()
            }, 1000);
          }
        } if(this.orderLocalUUID) {
          let order = await this.orderService.getByUUID(this.orderLocalUUID);
          if(order?._localUUID) {
            this.populateBillNumber();
            let latestParty = await this.allDataService.partyService.getByUUID(order?.party?._localUUID);
            if(latestParty?._localUUID ) {
              this.form?.get('party')?.setValue(latestParty);
              if(this.listStyleView) {
                this.partySelector?.setSelectedParty(latestParty);
                this.partySelector?.disable();
              }else {
                this.partyRestaurantSelector?.setSelectedParty(latestParty);
                this.partyRestaurantSelector?.disable();
              }
            }else {
              alert('Party not found');
            }
            if(order?.orderItems?.length) {
              if(this.listStyleView) {
                this.itemSelector?.openItemSelectorModal();
                setTimeout(() => {
                  this.itemSelector?.selectOrderItem(order?.orderItems);
                }, 500);
              }else {
                this.itemRestaurantSelector?.openItemSelectorModal();
                setTimeout(() => {
                  this.itemRestaurantSelector?.selectOrderItem(order?.orderItems);
                }, 500);
              }
            }else {
              alert('Items not found');
            }
            return;
          }else {
            alert('Order not found');
          }
        } 
    
        !this.fetchedEstimate && this.populateBillNumber();
        this.openPartySelectorModal();
      } catch (error) {
        SentryUtilites.setLog("EstimateFormPage:populateForm", error)
      }
    }
  
    //----------------------------------------------------------------------------
  
    /*
      Reactive Form Initialization
    */
  
    initializeReactiveForm() {
      try {
        this.form = this.formBuilder.group({
          billNo: ['', Validators.required],
          billDateStamp: [+new Date().setHours(0, 0, 0, 0), Validators.required],
          dueDateStamp: [null],
          billingTerm: [null],
          party: [null, Validators.required],
          partySecondary: [null],
          billItems: [null],
          transportDetail: this.formBuilder.group({
            additionalAmount: [null],
            purOrderNo: [null],
            challanNo: [null],
            eWayBillNo: [null],
            eWayBillDate: [],
            transporterName: [null],
            vehicleNumber: [null],
            transDistance: [null, Validators.maxLength(5)],
            deliveryLocation: [null],
            deliveryDate: [],
            tnc: [null],
          }),
          gstAmount: [0],
          cessAmount: [0],
          discountAmount: [0],
          cashDiscount: [null],
          cashDiscountPercentage: [null],
          totalAmount: [null],
          amountReceived: [null],
          subTotalAmount: [null],
          totalSaving: [null],
          note: [null],
          roundOffValue: [null],
          serviceChargePercentage: [null],
          serviceChargeAmount: [null],
          deliveryProvience: [null]
        });
        this.subArr.push(this.form.get("billDateStamp").valueChanges.pipe(distinctUntilChanged(), debounceTime(300)).subscribe(billDateStamp => {
          this.minDueDate = Utility.ionDatePickerFormattedString(billDateStamp);
        }));
        this.subArr.push(this.form.get("serviceChargePercentage").valueChanges.pipe(distinctUntilChanged(), debounceTime(300)).subscribe(serviceChargePercentage => {
          setTimeout(() => {
            this.calculateSubTotalAmount();
          }, 500);
        }));
      } catch (error) {
        SentryUtilites.setLog("EstimateFormPage:initializeReactiveForm", error)
      }
    }
  
    //----------------------------------------------------------------------------
  
    openSettingsModal() {
      this.settingsModal?.openSettingsModal();
    }
  
    /*
      Estimate Number
    */
  
    async populateBillNumber() {
      try {
        this.form.patchValue({
          billNo: await this.allDataService.estimateService.getNewEstimateNo()
        });
      } catch (error) {
        SentryUtilites.setLog("EstimateFormPage:populateBillNumber", error)
      }
    }
  
    //----------------------------------------------------------------------------
  
    /*
      PartyV0 Selector functons
    */
  
    openPartySelectorModal() {
      try {
        if (
          this.listStyleView
          && this.partySelector
          && !this.partySelector?.isPartySelected
        ) {
          this.partySelector?.openPartySelectorModal();
        } else {
          this.partySelector?.openPartySelectorModal(false);
        }
        if (
          !this.listStyleView
          && this.partyRestaurantSelector
          && !this.partyRestaurantSelector?.isPartySelected
        ) {
          this.partyRestaurantSelector?.openPartySelectorModal();
        } else {
          this.partyRestaurantSelector?.openPartySelectorModal(false);
        }
      } catch (error) {
        SentryUtilites.setLog("EstimateFormPage:openPartySelectorModal", error)
      }
    }

  /**
   * @description : open secondary party modal
   */
  async openSeconadryPartyModal() {
    try {
      this.isOpenSecondaryPartyModal = true;
      await Utility.wait(500)
      this.itemRestaurantSelector?.openSeconadryPartyModal();
    } catch (error) {
      SentryUtilites.setLog("EstimateFormPage:openSeconadryPartyModal", error)
    }
  }
  // ---------------------------------
  
    onPartySelect(party: Party) {
      try {
        if (party?._localUUID) {
          this.form.patchValue({ party });
          this.form.patchValue({
            deliveryProvience: party?.deliveryProvience || party?.billingProvience
          });
          this.itemRestaurantSelector?.openItemSelectorModal();
        }
      } catch (error) {
        SentryUtilites.setLog("EstimateFormPage:onPartySelect", error)
      }
    }
  
    onSeconadryPartySelect(party: Party) {
      try {
        if (party?._localUUID || (party?.name || party?.billingAddress)) {
          this.form.patchValue({
            partySecondary: party
          });
          this.calculateSubTotalAmount();
          this.calculateAdditionalDiscount();
        }
      } catch (error) {
        SentryUtilites.setLog("EstimateFormPage:onSeconadryPartySelect", error)
      }
    }
  
    //----------------------------------------------------------------------------
  
    /*
      Estimate Items
    */
  
    async populateBillItems(billItems: BillItem[]) {
      try {
        let totalAmount = 0;
        billItems?.forEach(x => totalAmount += x?.total);
        this.form.patchValue({
          billItems,
          cashDiscount: null,
          cashDiscountPercentage: null,
        });
        this.recalculateBillItems();
      } catch (error) {
        SentryUtilites.setLog("EstimateFormPage:populateBillItems", error)
      }
    }
  
    async onCartClear(event) {
      try {
        if(event && this.form?.value?.party?.runningSaleBill) {
          if(this.paramDocumentId) {
            this.resetBillForm();
            this.router.navigate(['estimate/form']);
          }else {
            this.resetBillForm();
          }
        }
      } catch (error) {
        SentryUtilites.setLog("EstimateFormPage:onCartClear", error)
      }
    }
  
    //----------------------------------------------------------------------------

  
    /*
      Transport Details
    */
  
    populateTransportDetails(data: any) {
      try {
        this.form.patchValue({ transportDetail: data });
        if (data?.eWayBillDate) {
          this.dpEWayBillDateValue = Utility.ionDatePickerFormattedString(data.eWayBillDate);
        }
        if (data?.deliveryDate) {
          this.dpDeliveryDateValue = Utility.ionDatePickerFormattedString(data.deliveryDate);
        }
        // this.setTransportDetailsCardVisibiltity();
        this.calculateSubTotalAmount();
      } catch (error) {
        SentryUtilites.setLog("EstimateFormPage:populateTransportDetails", error)
      }
    }
  
    setTransportDetailsCardVisibiltity() {
      try {
        let data = this.form.value.transportDetail;
        if (
          data?.additionalAmount
          || data?.eWayBillNo
          || data?.eWayBillDate
          || data?.purOrderNo
          || data?.challanNo
          || data?.transporterName
          || data?.vehicleNumber
          || data?.deliveryDate
          || data?.transDistance
          || data?.deliveryLocation
          || data?.tnc
        ) {
          this.showTransportDetailsCard = true;
        } else {
          this.showTransportDetailsCard = false;
        }
      } catch (error) {
        SentryUtilites.setLog("EstimateFormPage:setTransportDetailsCardVisibiltity", error)
      }
    }
  
    //----------------------------------------------------------------------------
  
    /*
      Estimate Total Amount
    */
  
    setTotalAmount(totalAmount: number) {
      this.form.patchValue({ totalAmount });
    }
  
    //----------------------------------------------------------------------------
  
    /*
      Estimate Settings
    */
  
    async getBillSetting() {
      return null;
    }
  
    //----------------------------------------------------------------------------
  
    amountReceivedSetBlur(keyboardEvent: any) {
      try {
        keyboardEvent = keyboardEvent as KeyboardEvent;
        keyboardEvent?.preventDefault();
        keyboardEvent?.target?.blur();
      } catch (error) {
        SentryUtilites.setLog("EstimateFormPage:amountReceivedSetBlur", error)
      }
    }
  
    /**
     * 
     * @param event : date event
     * @description : on E way bill date select assign date value
     */
    onEwayBillDateSelect(event) {
      try {
        if(event?.detail?.value) {
          let timeStamp = +new Date(event?.detail?.value).setHours(0, 0, 0, 0);
          this.form?.get('transportDetail.eWayBillDate')?.setValue(timeStamp);
          this.dpEWayBillDateValue = Utility.ionDatePickerFormattedString(timeStamp);
          Utility.closeDatePicker(event?.target);
        }
      } catch (error) {
        SentryUtilites.setLog("EstimateFormPage:onEwayBillDateSelect", error)
      }
    }
    // ----------------------------------
  
    /**
     * 
     * @param event : date event
     * @description : on delivery date select assign date value
     */
    onDeliveryDateSelect(event) {
      try {
        if(event?.detail?.value) {
          let timeStamp = +new Date(event?.detail?.value).setHours(0, 0, 0, 0);
          this.form?.get('transportDetail.deliveryDate')?.setValue(timeStamp);
          this.dpDeliveryDateValue = Utility.ionDatePickerFormattedString(timeStamp);
          Utility.closeDatePicker(event?.target);
        }
      } catch (error) {
        SentryUtilites.setLog("EstimateFormPage:onDeliveryDateSelect", error)
      }
    }
    // ----------------------------------

    /*
      Barcode Scanner
    */
  
    async populateBarcodeScannerSetting() {
      this.barcodeScannerMode = Utility.getFromLocalStorage('item_barcode_scanner');
    }
  
    async onBarcodeScannerModeChange(event: any) {
      try {
        this.barcodeScannerMode = event?.detail?.checked;
        // Utility.setToLocalStorage('item_barcode_scanner', this.barcodeScannerMode);
        // const message = `Barcode Scanner Mode ${this.barcodeScannerMode ? 'Enabled' : 'Disabled'}`;
        // const color = 'success';
    
        // const toast = await this.toastController.create({
        //   header: message,
        //   duration: 2000,
        //   position: 'top',
        //   mode: 'ios',
        //   icon: 'barcode-sharp',
        //   color,
        // });
        // await toast.present();
      } catch (error) {
        SentryUtilites.setLog("EstimateFormPage:onBarcodeScannerModeChange", error)
      }
    }
  
    //----------------------------------------------------------------------------
  
    focusAmountReceived(event) {
      try {
        if(event) {
          this.amountReceivedEle?.setFocus();
        }
      } catch (error) {
        SentryUtilites.setLog("EstimateFormPage:focusAmountReceived", error)
      }
    }
  
    /**
     * 
     * @param event : date event
     * @description : on bill date select assign date value
     */
    onBillDateSelect(event) {
      try {
        if(event?.detail?.value) {
          let timeStamp = +new Date(event?.detail?.value).setHours(0, 0, 0, 0);
          this.form?.controls?.billDateStamp?.setValue(timeStamp);
          this.dpBillDateValue = Utility.ionDatePickerFormattedString(timeStamp);
          Utility.closeDatePicker(event?.target);
          if(this.form?.value?.billingTerm) {
            this.onBillingTermSelect({detail:{value:this.form?.value?.billingTerm}});
          }
        }
      } catch (error) {
        SentryUtilites.setLog("EstimateFormPage:onBillDateSelect", error)
      }
    }
    // --------------------------------------
  
    /**
     * 
     * @param event : date event
     * @description : on due date select assign date value
     */
    onBillDueDateSelect(event) {
      try {
        if(event?.detail?.value) {
          let timeStamp = +new Date(event?.detail?.value).setHours(0, 0, 0, 0);
          this.form?.controls?.dueDateStamp?.setValue(
            +new Date(event?.detail?.value).setHours(0, 0, 0, 0)
          );
          this.dpDueDateValue = Utility.ionDatePickerFormattedString(timeStamp);
          Utility.closeDatePicker(event?.target);
        }
      } catch (error) {
        SentryUtilites.setLog("EstimateFormPage:onBillDueDateSelect", error)
      }
    }
    // -----------------------------------------
  
    returnZero() {
      return 0;
    }
  
    onBillingTermSelect(event) {
      try {
        this.dpDueDateValue = null;
        const dueDateStamp = Utility.getTimestampByDays(this.dueNetObj[event?.detail?.value || 'net0']?.days ,this.form?.value?.billDateStamp);
        this.form?.controls?.dueDateStamp?.setValue(dueDateStamp);
        this.dueDateContentsMounted = true;
        this.dpDueDateValue = Utility.ionDatePickerFormattedString(dueDateStamp);
      } catch (error) {
        SentryUtilites.setLog("EstimateFormPage:onBillingTermSelect", error)
      }
    }
  
    onSaveClick() {
      try {
        if (this.paramDocumentId) {
          this.update();
        } else {
          this.save();
        }
      } catch (error) {
        SentryUtilites.setLog("EstimateFormPage:onSaveClick", error)
      }
    }
  
    async updateOrderSaleId(estimate: Estimate) {
      try {
        if(this.orderLocalUUID && estimate?._localUUID) {
          let order = await this.orderService.getByUUID(this.orderLocalUUID);
          if(order?._localUUID) {
            order.linkedSaleUUID = estimate?._localUUID;
            this.orderService.update(order);
          }
        }
      } catch (error) {
        SentryUtilites.setLog("EstimateFormPage:updateOrderSaleId", error)
      }
    }
  
    async addEstimate() {
      try {
        if(!this.form?.value?.party?._localUUID) {
          const toast = await this.toastController.create({
            header: 'Please Select Party to Save Bill',
            duration: 3000,
            position: 'top',
            mode: 'ios',
            color: 'danger',
          });
          await toast.present();
          this.onClickSave = false;
          this.noPartySelectError = true;
          return null;
        }else {
          this.noPartySelectError = false;
        }
    
        if (this.form.valid) {
          let estimate = new Estimate();
    
          estimate = { ...this.form.value };
    
          let fetchedParty = await this.allDataService.partyService.getByUUID(estimate?.party?._localUUID);
          estimate.party = fetchedParty;  
    
          let savedEstimate = await this.allDataService.estimateService.save(estimate);
          this.allDataService.itemService.reloadList();
          this.allDataService.partyService.reloadList();
          this.allDataService.moneyInService.reloadList();
          this.allDataService.listForceReloadSubs.next('estimate-list');
          if(savedEstimate?.party?._localUUID) {
            let updatedParty=await this.allDataService.partyService.getByUUID(savedEstimate?.party?._localUUID);
            this.allDataService.partyService.updateSubs.next(updatedParty);
          }
          if(savedEstimate?.billItems?.length){
            for(let i=0;i<savedEstimate.billItems.length;i++){
              let billItem = savedEstimate?.billItems[i]?.item;
              if(billItem?._localUUID) {
                let updatedItem=await this.allDataService.itemService.getByUUID(billItem?._localUUID);
                this.allDataService.itemService.updateSubs.next(updatedItem);
              }
            }
          }
          this.updateOrderSaleId(savedEstimate);
          return savedEstimate;
        } else {
          return null;
        }
      } catch (error) {
        SentryUtilites.setLog("EstimateFormPage:addEstimate", error)
        return null;
      }
    }
  
    async updateEstimate() {
      try {
        if (this.form.valid) {
          let estimate = new Estimate();
    
          estimate = {
            ...this.fetchedEstimate,
            ...this.form.value
          };
    
          let fetchedParty = await this.allDataService.partyService.getByUUID(estimate?.party?._localUUID);
          estimate.party = fetchedParty;
    
          let updatedEstimate = await this.allDataService.estimateService.update(estimate);
          this.allDataService.itemService.reloadList();
          this.allDataService.partyService.reloadList();
          this.allDataService.moneyInService.reloadList();
          this.allDataService.listForceReloadSubs.next('estimate-list');
          if(updatedEstimate?.party?._localUUID) {
            let updatedParty=await this.allDataService.partyService.getByUUID(updatedEstimate?.party?._localUUID);
            this.allDataService.partyService.updateSubs.next(updatedParty);
          }
          if(updatedEstimate?.billItems?.length){
            for(let i=0;i<updatedEstimate.billItems.length;i++){
              let billItem = updatedEstimate?.billItems[i]?.item;
              if(billItem?._localUUID) {
                let updatedItem=await this.allDataService.itemService.getByUUID(billItem?._localUUID);
                this.allDataService.itemService.updateSubs.next(updatedItem);
              }
            }
          }
    
          return updatedEstimate;
        } else {
          return null;
        }
      } catch (error) {
        SentryUtilites.setLog("EstimateFormPage:updateEstimate", error)
        return null;
      }
    }
  
    async save() {
      try {
        let savedEstimate = await this.addEstimate();
        if (savedEstimate?._localUUID) {
          this.allDataService.listForceReloadSubs.next('estimate-list');
          this.itemRestaurantSelector?.openItemSelectorModal(false);
          this.router.navigate(['estimate']);
        }
      } catch (error) {
        SentryUtilites.setLog("EstimateFormPage:save", error)
      }
    }
  
    async onlySave() {
      try {
        if (!this.onClickSave) {
          this.onClickSave = true;
          let savedEstimate = null;
          if (this.fetchedEstimate?._localUUID) {
            savedEstimate = await this.updateEstimate();
          } else {
            savedEstimate = await this.addEstimate();
          }
          if (savedEstimate?._localUUID) {
            this.showTransportDetailsCard = false;
            this.onClickSave = false;
            this.resetBillForm();
            this.ngOnInit();
            this.ngAfterViewInit();
            if (Utility.getFromLocalStorage('bill_amount_sound_status')) {
              Utility.playAmountSpeech(savedEstimate?.totalAmount);
            }
          }
        }
      } catch (error) {
        SentryUtilites.setLog("EstimateFormPage:onlySave", error)
      }
    }
  
    async savePrint() {
      try {
        if (!this.onClickSave) {
          this.onClickSave = true;
          let savedEstimate: Estimate = null;
          if (this.fetchedEstimate?._localUUID) {
            savedEstimate = await this.updateEstimate();
          } else {
            savedEstimate = await this.addEstimate();
          }
          if (savedEstimate?._localUUID) {
            this.onClickSave = false;
            this.showTransportDetailsCard = false;
            this.itemRestaurantSelector?.openItemSelectorModal(false);
            this.resetBillForm();
            this.partySelector?.openPartySelectorModal(false);
            this.partyRestaurantSelector?.openPartySelectorModal(false);
            setTimeout(() => {
              this.router.navigate([`/bill-view-estimate/${savedEstimate._localUUID}`], {
                queryParams: { isPrint: true },
              });
            }, 0);
            if (Utility.getFromLocalStorage('bill_amount_sound_status')) {
              Utility.playAmountSpeech(savedEstimate?.totalAmount);
            }
          }
        }
      } catch (error) {
        SentryUtilites.setLog("EstimateFormPage:savePrint", error)
      }
      
    }
  
    async update() {
      try {
        let savedSale = await this.updateEstimate();
        if (savedSale?._localUUID) {
          this.allDataService.listForceReloadSubs.next('estimate-list');
          this.router.navigate(['estimate']);
        }
      } catch (error) {
        SentryUtilites.setLog("EstimateFormPage:update", error)
      }
    }
  
    resetBillForm() {
      try {
        this.itemRestaurantSelector?.openItemSelectorModal(false);
        this.itemRestaurantSelector?.resetCart();
        this.itemSelector?.openItemSelectorModal(false);
        this.itemSelector?.resetCart();
        if(this.listStyleView) {
          this.partySelector?.resetParty();
          this.partySelector?.enable();
        }else {
          this.partyRestaurantSelector?.resetParty();
          this.partyRestaurantSelector?.enable();
        }
    
        this.form.reset();
        this.isNgOnInitRun = false;
        this.barcodeScannerMode = false;
        this.dpDueDateValue = null;
        this.dpEWayBillDateValue = null;
        this.dpDeliveryDateValue = null;
        this.showTransportDetailsCard = false;
        this.isOpenSecondaryPartyModal = false;

        this.subArr?.forEach(sub => {
          sub?.unsubscribe();
        })
        this.subArr = [];
    
        if(this.paramDocumentId ) {
          this.fetchedEstimate = null;
          this.orderLocalUUID = null;
          this.router.navigate(['estimate/form']);
          this.initializeReactiveForm();
        }else if(this.orderLocalUUID) {
          this.fetchedEstimate = null;
          this.orderLocalUUID = null;
          this.router.navigate(['estimate/form']);
          this.initializeReactiveForm();
          this.openPartySelectorModal();
        }else {
          this.paramDocumentId = null;
          this.fetchedEstimate = null;
        }
      } catch (error) {
        SentryUtilites.setLog("EstimateFormPage:resetBillForm", error)
      }
  
    }
  
  
    toggleShowAllFields() {
      this.showTransportDetailsCard = !this.showTransportDetailsCard;
    }
  
    // Calculations
  
    CalculateBillTask = CalculateBillTask;
  
    cashDiscountAmount = 0.0;
    cashDiscountPercentage = 0.0;
  
    serviceChargeAmount = 0.0;
    serviceChargePercentage = 0.0;
  
    unitDiscountAmountTotal = 0.0;
    unitGstAmountTotal = 0.0;
    unitCessAmountTotal = 0.0;
    unitTaxAmountTotal = 0.0;
    unitSubTotalAmountTotal = 0.0;
    unitTotalAmountTotal = 0.0;
    unitTotalSavingAmountTotal = 0.0;
  
    billItemsTotal = 0.0;
    wcdBillItemsTotal = 0.0;
  
  
    calculateSubTotalAmount() {
      try {
        this.form?.patchValue({
          isPrintedKOT: false,
          party: {...this.form?.value?.party,isPrintedKOT: false}
        });
    
        this.unitDiscountAmountTotal = 0.0;
        this.unitGstAmountTotal = 0.0;
        this.unitCessAmountTotal = 0.0;
        this.unitTaxAmountTotal = 0.0;
        this.unitSubTotalAmountTotal = 0.0;
        this.unitTotalAmountTotal = 0.0;
        this.unitTotalSavingAmountTotal = 0.0;
    
        this.billItemsTotal = 0.0;
        this.wcdBillItemsTotal = 0.0;
    
        let billItems = this.form?.value?.billItems as BillItem[];
    
        billItems?.forEach(billItem => {
          this.unitDiscountAmountTotal += billItem?.unitDiscountAmount || 0.0;
          this.unitGstAmountTotal += billItem?.itemTotalGstAmount || 0.0;
          this.unitCessAmountTotal += billItem?.itemTotalCessAmount || 0.0;
          this.unitTaxAmountTotal += billItem?.itemTotalTaxAmount || 0.0;
          this.unitSubTotalAmountTotal += billItem?.subTotal || 0.0;
          this.unitTotalAmountTotal += billItem?.total || 0.0;
          this.unitTotalSavingAmountTotal += billItem?.totalSaving || 0.0;
    
          this.billItemsTotal += billItem?.total || 0.0;
          this.wcdBillItemsTotal += billItem?.wcdTotal || 0.0;
        });
    
        this.form?.patchValue({
          discountAmount: this.unitDiscountAmountTotal,
          gstAmount: this.unitGstAmountTotal,
          cessAmount: this.unitCessAmountTotal,
          subTotalAmount: this.unitSubTotalAmountTotal,
          totalSaving: this.unitTotalSavingAmountTotal,
        });
    
    
        this.calculateServiceCharge(CalculateBillTask.SERVICE_CHARGE_FROM_PERCENTAGE);
    
        this.calculateTotalAmount();
      } catch (error) {
        SentryUtilites.setLog("EstimateFormPage:calculateSubTotalAmount", error)
      }
    }
  
    calculateCashDiscount(task: CalculateBillTask = CalculateBillTask.CALCULATE) {
      try {
        this.cashDiscountAmount = 0.0;
        this.cashDiscountPercentage = 0.0;
    
        if (task == CalculateBillTask.DISCOUNT_FROM_PERCENTAGE) {
          this.cashDiscountPercentage = this.form?.value?.cashDiscountPercentage;
          if(this.wcdBillItemsTotal > 0) {
            this.cashDiscountAmount = Utils.capFractionsToSix(this.wcdBillItemsTotal * this.cashDiscountPercentage / 100);
          }
          this.form?.patchValue({ cashDiscount: this.cashDiscountAmount });
        } else if (task == CalculateBillTask.DISCOUNT_FROM_AMOUNT) {
          this.cashDiscountAmount = this.form?.value?.cashDiscount;
          if(this.wcdBillItemsTotal > 0) {
            this.cashDiscountPercentage = Utils.capFractionsToSix((this.cashDiscountAmount * 100) / this.wcdBillItemsTotal);
          }
          this.form?.patchValue({ cashDiscountPercentage: this.cashDiscountPercentage });
        }
    
        if (this.cashDiscountAmount > this.wcdBillItemsTotal || this.cashDiscountAmount < 0) {
          this.cashDiscountAmount = 0.0;
          this.cashDiscountPercentage = 0.0;
          this.form?.patchValue({
            cashDiscount: null,
            cashDiscountPercentage: null
          });
        }
    
        this.recalculateBillItems();
      } catch (error) {
        SentryUtilites.setLog("EstimateFormPage:calculateCashDiscount", error)
      }
    }
  
    calculateServiceCharge(task: CalculateBillTask = CalculateBillTask.CALCULATE) {
      try {
        this.serviceChargeAmount = 0.0;
        this.serviceChargePercentage = 0.0;
    
        if(this.form?.value?.serviceChargePercentage < 0 || this.form?.value?.serviceChargeAmount < 0) {
          this.form?.patchValue({ serviceChargePercentage: 0, serviceChargeAmount: 0 });
        } else if (task == CalculateBillTask.SERVICE_CHARGE_FROM_PERCENTAGE) {
          this.serviceChargePercentage = this.form?.value?.serviceChargePercentage;
          if(this.unitSubTotalAmountTotal > 0) {
            this.serviceChargeAmount = Utils.capFractionsToSix(this.unitSubTotalAmountTotal * this.serviceChargePercentage / 100);
          }
          this.form?.patchValue({ serviceChargeAmount: this.serviceChargeAmount });
        } else if (task == CalculateBillTask.SERVICE_CHARGE_FROM_AMOUNT) {
          this.serviceChargeAmount = this.form?.value?.serviceChargeAmount;
          if(this.unitSubTotalAmountTotal > 0) {
            this.serviceChargePercentage = Utils.capFractionsToSix((this.serviceChargeAmount * 100) / this.unitSubTotalAmountTotal);
          }
          this.form?.patchValue({ serviceChargePercentage: this.serviceChargePercentage });
        }
    
        if (this.cashDiscountAmount < 0) {
          this.cashDiscountAmount = 0.0;
          this.cashDiscountPercentage = 0.0;
          this.form?.patchValue({
            cashDiscountAmount: null,
            cashDiscountPercentage: null
          });
        }
      } catch (error) {
        SentryUtilites.setLog("EstimateFormPage:calculateServiceCharge", error)
      }
  
    }
  
    calculateTotalAmount() {
      try {
        let additionalCharges = this.form?.value?.transportDetail?.additionalAmount || 0;
        if(!Utility.isNumber(additionalCharges)) {
          additionalCharges = 0.0;
        }
    
        let serviceChargeAmount = this.form?.value?.serviceChargeAmount || 0;
        if(!Utility.isNumber(serviceChargeAmount)) {
          serviceChargeAmount = 0.0;
        }
    
        let totalAmount = this.unitTotalAmountTotal + Number(additionalCharges) + Number(serviceChargeAmount);
        totalAmount = Utils.capFractionsToSix(totalAmount);
    
        let roundOffValue = 0.0;
        if(this.selectedProfile?.iSetRoundOffTotalAmountStatus) {
          let floorDelta = (totalAmount || 0.0) - Math.floor(totalAmount || 0.0);
          let ceilDelta = Math.ceil(totalAmount || 0.0) - (totalAmount || 0.0);
    
          if(floorDelta > ceilDelta) {
            roundOffValue = ceilDelta;
          }else {
            roundOffValue = floorDelta * -1;
          }
          roundOffValue = Utils.capFractionsToSix(roundOffValue);
          totalAmount = Math.round(totalAmount + roundOffValue);
        }
    
    
        this.form?.patchValue({
          totalAmount,
          roundOffValue
        });
      } catch (error) {
        SentryUtilites.setLog("EstimateFormPage:calculateTotalAmount", error)
      }
  
  
    }
  
    calculateAdditionalDiscount() {
      try {
        if(this.form?.value?.partySecondary) {
    
          this.showCashDiscountPercent = 0.0;
    
          let isValidDiscountI = false;
          let isValidDiscountII = false;
    
          let discountPercentI = this.selectedProfile?.dSetDiscountPercentI;
          let discountMaximumAmountI = this.selectedProfile?.dSetDiscountMaximumAmountI;
          let discountMinimumAmountI = this.selectedProfile?.dSetDiscountMinimumAmountI;
          let discountPercentII = this.selectedProfile?.dSetDiscountPercentII;
          let discountMaximumAmountII = this.selectedProfile?.dSetDiscountMaximumAmountII;
          let discountMinimumAmountII = this.selectedProfile?.dSetDiscountMinimumAmountII;
    
          let additionalDiscountAmountI = 0.0;
          let additionalDiscountAmountII = 0.0;
    
          let lastSaleStamp = this.form?.value?.partySecondary?.lastSaleStamp || 0;
    
          // if discount-1 is on
          if(this.selectedProfile?.dSetDiscountStatusI) {
            if ((this.selectedProfile?.dSetDiscountOfferTypeI || 0) == 0) {
              isValidDiscountI = true;
            } else if (this.selectedProfile?.dSetDiscountOfferTypeI == 1) {
              let discountExpiryStamp = this.selectedProfile?.dSetDiscountExpiryDaysI * 86400000; 
              if (lastSaleStamp > 0 && discountExpiryStamp > 0) {
                if ((lastSaleStamp + discountExpiryStamp) > +new Date()) {
                  isValidDiscountI = true;
                }
              }
            }
    
            if (isValidDiscountI && discountMinimumAmountI <= this.unitTotalAmountTotal) {
              additionalDiscountAmountI = (this.unitTotalAmountTotal / 100) * discountPercentI;
              if (discountMaximumAmountI && additionalDiscountAmountI > discountMaximumAmountI) {
                additionalDiscountAmountI = discountMaximumAmountI;
              }
            } else {
              isValidDiscountI = false;
            }
          }
    
          // if discount-2 is on
          if (this.selectedProfile?.dSetDiscountStatusII) {
            if ((this.selectedProfile?.dSetDiscountOfferTypeII || 0) == 0) {
              isValidDiscountII = true;
            } else if (this.selectedProfile?.dSetDiscountOfferTypeII == 1) {
              let discountExpiryStamp = this.selectedProfile?.dSetDiscountExpiryDaysII * 86400000; 
              if (lastSaleStamp > 0 && discountExpiryStamp > 0) {
                if ((lastSaleStamp + discountExpiryStamp) > +new Date()) {
                  isValidDiscountII = true;
                }
              }
            }
    
            if (isValidDiscountII && discountMinimumAmountII <= this.unitTotalAmountTotal) {
              additionalDiscountAmountII = (this.unitTotalAmountTotal / 100) * discountPercentII;
              if (discountMaximumAmountII && additionalDiscountAmountII > discountMaximumAmountII) {
                additionalDiscountAmountII = discountMaximumAmountII;
              }
            } else {
              isValidDiscountII = false;
            }
          }
    
          this.cashDiscountAmount = 0.0;
          this.cashDiscountPercentage = 0.0;
          if(isValidDiscountI && isValidDiscountII) {
            this.cashDiscountAmount = additionalDiscountAmountI > additionalDiscountAmountII ? additionalDiscountAmountI : additionalDiscountAmountII;
            if(additionalDiscountAmountI || additionalDiscountAmountII) {
              this.showCashDiscountPercent = additionalDiscountAmountI > additionalDiscountAmountII ? discountPercentI : discountPercentII;
            }
          } else if (isValidDiscountI && additionalDiscountAmountI) {
            this.cashDiscountAmount = additionalDiscountAmountI;
            this.showCashDiscountPercent = discountPercentI;
          } else if (isValidDiscountII && additionalDiscountAmountII) {
            this.cashDiscountAmount = additionalDiscountAmountII;
            this.showCashDiscountPercent = discountPercentII;
          }
    
          this.form?.patchValue({
            cashDiscount: this.cashDiscountAmount,
            cashDiscountPercentage: null
          });
          this.calculateCashDiscount(CalculateBillTask.DISCOUNT_FROM_AMOUNT);
        }
      } catch (error) {
        SentryUtilites.setLog("EstimateFormPage:calculateAdditionalDiscount", error)
      }
    }
  
    recalculateBillItems() {
      try {
        let billItems = this.form?.value?.billItems;
        billItems?.forEach(billItem => {
          BillCalculations.calculateBillItem(billItem,BillType.PURCHASE,null,this.form?.value?.cashDiscountPercentage);
        });
        this.itemRestaurantSelector?.setBillItemsNoEmitNoCal(billItems);
        this.form?.patchValue({
          billItems
        });
        this.calculateSubTotalAmount();
      } catch (error) {
        SentryUtilites.setLog("EstimateFormPage:recalculateBillItems", error)
      }
    }

    /**
     * @description : close date picker
     */
    closeDOBPicker() {
      try {
        this.isOpenBillDatePicker = false;
        this.isOpenDueDatePicker = false;
        this.isOpenEwayBillDatePicker = false;
        this.isOpenDeliveryDatePicker = false;
      } catch (error) {
        SentryUtilites.setLog("EstimateFormPage:closeDOBPicker", error)
      }
    }
    // -----------------------------
    
}
