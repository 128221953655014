import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Utility } from '../../utils/utility';
import { AuthService } from '../auth/auth.service';
import { environment } from './../../../environments/environment';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import * as pako from 'pako';

@Injectable({
  providedIn: 'root'
})
export class ExpressServerService {

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private router: Router,
    private _zone: NgZone,
  ) { }

  makePostCall(route: string, body: object) {
    return new Promise<any>((resolve, reject) => {
      const url = environment.expressServerV2 + route;
      this.http.post(url, body)
        .subscribe(
          (res: any) => { resolve(res); },
          (err: any) => { resolve(null); }
        );
    });
  }

  sendOtpCall(phone: String) {
    return new Promise<any>((resolve, reject) => {
      const url = environment.ezoOfLoginServer + 'login/sendOtp';
      this.http.post(url, { phone })
        .subscribe(
          (res: any) => { resolve(res); },
          (err: any) => { resolve(err); }
      );
    });
  }

  sendOtpWithEmailCall(phone: String, token: String) {
    return new Promise<any>((resolve, reject) => {
      const url = environment.ezoOfLoginServer + 'login/sendOtpWithEmail';
      this.http.post(url, { phone, token })
        .subscribe(
          (res: any) => { resolve(res); },
          (err: any) => { resolve(err); }
      );
    });
  }

  sendOtpCallV2(phone: String) {
    return new Promise<any>((resolve, reject) => {
      const url = environment.ezoOfLoginServer + 'login/sendOtp2';
      this.http.post(url, { phone })
        .subscribe(
          (res: any) => { resolve(res); },
          (err: any) => { resolve(err); }
        );
    });
  }

  verifyOtpCall(phone: String, otp: string, lat: number, long: number, device: string) {
    device = device.substring(0,100);
    return new Promise<any>((resolve, reject) => {
      const url = environment.ezoOfLoginServer + 'login/verifyOtp';
      this.http.post(url, { phone, otp, lat, long, device })
        .subscribe(
          (res: any) => { resolve(res); },
          (err: any) => { resolve(err); }
        );
    });
  }

  makeSyncCall(route: string, array: any[]) {
    let token = this.authService.getToken();
    array?.forEach(el => {
      if(el) {
        el.deviceSyncStartStamp = +new Date()
      }
    })
    return new Promise<any>((resolve, reject) => {
      let url = '';
      if(route === 'image') {
        url = environment.ezoOfServerImage + `api/${route}/saveAll`;
      } else {
        url = environment.ezoOfServer + `${route}/saveAll`;
      }
      this.http.post(url, {
        array,
        serverSyncStamp: Utility.getCollectionSyncStamp(route)
      }, {
        headers: {
          'Authorization': `${token}`
        }
      })
        .subscribe(
          (res: any) => {
            if (res.err) {
              if (res.err.indexOf('Unauthorized Access:') != -1) {
                // this.authService.logout();
              }
            }
            resolve(res);
          },
          (err: any) => { resolve(null); }
        );
    });
  }

  makeGetAllCall(path: string) {
    let token = this.authService.getToken();
    return new Promise<any>((resolve, reject) => {
      const url = environment.ezoOfServer + path;
      this.http.get(url, {
        headers: {
          'Authorization': `${token}`
        }
      })
        .subscribe(
          (res: any) => { resolve(res); },
          (err: any) => { resolve(null); }
        );
    });
  }

  getEventSource(url:string):EventSource{
    return new EventSource(url);
  }

  getServerSentEvent(url: string){
    console.log("getServerSentEvent",url);
    return new Observable<any>((observer)=>{
      const eventSource = this.getEventSource(url);

      eventSource.onmessage = event =>{
        this._zone?.run(()=>{
          observer.next(event);
        })
      }

      eventSource.onerror = error =>{
        this._zone?.run(()=>{
          observer.next(error);
        })
      }

    })
  }

  makeConnectionCall(path: string) {
    console.log("makeConnectionCall::",path);
    return new Promise<any>((resolve, reject) => {
      const url =  path;
      this.http.get(url, {

      })
        .subscribe(
          (res: any) => { console.log("success"); resolve(res); },
          (err: any) => { console.log("reject");resolve(null); }
        );
    });
  }

  makeEzoOfServerPostCall(path: string,body:any) {
    let token = this.authService.getToken();
    return new Promise<any>((resolve, reject) => {
      const url = environment.ezoOfServer + path;
      this.http.post(url, body, {
        headers: {
          'Authorization': `${token}`
        }
      })
        .subscribe(
          (res: any) => { resolve(res); },
          (err: any) => { resolve(null); }
        );
    });
  }

  makeFetchAllCall(path: string,body:any) {
    let token = this.authService.getToken();
    if(token && token.length){
      if(body) {
        body['initialDataDownloadComplete'] = Utility.getFromLocalStorage('Initial_data_download_complete') || 0;
      }
      return new Promise<any>((resolve, reject) => {
        const url = environment.ezoOfServer + path;
        this.http.post(url, body, {
          headers: {
            'Authorization': `${token}`
          }
        })
          .subscribe(
            (res: any) => { resolve(res); },
            (err: any) => { resolve(null); }
          );
      });
    }else{
      return null;
    }
  }

  makeFetchZipData(path: string,body:any) {
    let token = this.authService.getToken();
    if(token && token.length){
      if(body) {
        body['initialDataDownloadComplete'] = Utility.getFromLocalStorage('Initial_data_download_complete') || 0;
      }
      return new Promise<any>((resolve, reject) => {
        const url = environment.ezoOfServer + path;
        this.http.post(url, body, {
          headers: {
            'Authorization': `${token}`
          },
          responseType: 'arraybuffer'
        })
          .subscribe(
            (res: any) => { 
              const unzippedData = pako.ungzip(res, { to: 'string' });
              let object = JSON.parse(unzippedData);
              return resolve(object);
             },
            (err: any) => { resolve(null); }
          );
      });
    }else{
      return null;
    }
  }

  makeImageBase64Call(url:string) {
    let token = this.authService.getToken();
    if(token && token.length){
      try {
        return new Promise<any>((resolve, reject) => {
          this.http.get(url)
            .subscribe(
              (res: any) => { resolve(res); },
              (err: any) => { console.log(err),resolve(err); }
            );
        });
      } catch (error) {
        console.log(error)
      }
    }else{
      return null;
    }
  }

  masterItemCall(barcode: string) {
    return new Promise<any>((resolve, reject) => {
      const url = environment.ezoOfServer + `masterItem/getByBarCode?barCode=${barcode}`;
      this.http.get(url)
        .subscribe(
          (res: any) => { resolve(res); },
          (err: any) => { resolve(null); }
        );
    });
  }

  setFixedOTP(fixedOtp: string) {
    let token = this.authService.getToken();
    let phone = this.authService.getLoginPhone();
    return new Promise<any>((resolve, reject) => {
      const url = environment.ezoOfServer + 'auth/setFixedOtp';
      this.http.post(url, { phone, fixedOtp },{
        headers: {
          'Authorization': `${token}`
        }
      })
        .subscribe(
          (res: any) => { resolve(res); },
          (err: any) => { resolve(null); }
        );
    });
  }

  sendPinResetOTP() {
    let token = this.authService.getToken();
    let phone = this.authService.getLoginPhone();
    return new Promise<any>((resolve, reject) => {
      const url = environment.ezoOfServer + 'auth/sendPinResetOTP';
      this.http.post(url, { phone },{
        headers: {
          'Authorization': `${token}`
        }
      })
        .subscribe(
          (res: any) => { resolve(res); },
          (err: any) => { resolve(null); }
        );
    });
  }

  resetFixedOtp(otp: string){
    let token = this.authService.getToken();
    let phone = this.authService.getLoginPhone();
    return new Promise<any>((resolve, reject) => {
      const url = environment.ezoOfServer + 'auth/resetFixedOtp';
      this.http.post(url, { phone, otp },{
        headers: {
          'Authorization': `${token}`
        }
      })
        .subscribe(
          (res: any) => { resolve(res); },
          (err: any) => { resolve(null); }
        );
    });
  }

  verifyPinResetOTP(otp: string) {
    let token = this.authService.getToken();
    let phone = this.authService.getLoginPhone();

    return new Promise<any>((resolve, reject) => {
      const url = environment.ezoOfServer + 'auth/verifyPinResetOTP';
      this.http.post(url, { phone, otp },{
        headers: {
          'Authorization': `${token}`
        }
      })
        .subscribe(
          (res: any) => { resolve(res); },
          (err: any) => { resolve(null); }
        );
    });
  }

  sendWhatappTemplateToParties(body) {
    let token = this.authService.getToken();
    return new Promise<any>((resolve, reject) => {
      const url = environment.ezoOfLoginServer + 'bulkWaParty/send';
      this.http.post(url, body ,{
        headers: {
          'Authorization': `${token}`
        }
      })
        .subscribe(
          (res: any) => { resolve(res?.message); },
          (err: any) => { resolve(err); }
        );
    });
  }

  fetchWhatappTemplateData() {
    let token = this.authService.getToken();
    return new Promise<any>((resolve, reject) => {
      const url = environment.ezoOfServer + 'bulkWaParty/getTemplates';
      this.http.get(url, {
        headers: {
          'Authorization': `${token}`
        }
      })
        .subscribe(
          (res: any) => { resolve(res?.msgs || []) },
          (err: any) => { resolve([]) }
        );
    });
  }

}
