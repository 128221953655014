<ion-row class="padding-none custom-row">
  <ion-col size="6" class="txt-left remove-padding-top-bottom">
    <ion-row>
      <ion-col size="12" class="add-bottom-padding">
        <ion-button fill="clear" class="btn-txt-black padding-start-none">
          {{ moneyIn?.party?.name }}
        </ion-button>
      </ion-col>
      <ion-col size="12" (click)="billView(moneyIn)" class="remove-bottom-padding">
        <ion-label color="primary">
          {{ (moneyIn?.totalAmount || 0) | currency:'INR' }}
        </ion-label>
      </ion-col>
      <ion-col size="12">
        <ion-button (click)="billView(moneyIn)" fill="clear"
          class="btn-txt-black padding-start-none add-bottom-padding ion-button-bottom-padding">
          Txn Mode: {{ moneyIn?.paymentMode }}
        </ion-button>
        <ion-button *ngIf="moneyIn?.linkedSaleUUID" (click)="viewSale(moneyIn)" fill="outline" color="primary"
          class="font-12px ion-button-height add-bottom-padding ion-button-bottom-padding outline-border">
          View Sale
        </ion-button>
        <ion-button *ngIf="moneyIn?.linkedPurchaseReturnUUID" (click)="viewPurchaseReturn(moneyIn)" fill="outline" color="primary"
          class="font-12px ion-button-height add-bottom-padding ion-button-bottom-padding outline-border">
          View Purchase Return
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-col>
  <ion-col size="6" class="txt-right remove-padding-top-bottom">
    <ion-row>
      <ion-col size="12" (click)="billView(moneyIn)">
        <ion-button fill="clear" class="btn-txt-black padding-end-none">
          {{ moneyIn?.billNo }} | {{ moneyIn?.billDateStamp | date: 'dd/MM/yy' }}
        </ion-button>
      </ion-col>
      <ion-col size="12" *ngIf="!moneyIn?.deletedStamp">

        <ion-button title="Print" color="tertiary" fill="clear" (click)="billView(moneyIn)">
          <ion-icon name="print"></ion-icon>
        </ion-button>

        <ion-button title="Edit" color="primary" fill="clear" (click)="edit(moneyIn)">
          <ion-icon name="create"></ion-icon>
        </ion-button>
        <ion-button title="Delete" color="danger" fill="clear" (click)="delete(moneyIn)">
          <ion-icon name="trash"></ion-icon>
        </ion-button>
      </ion-col>
      <ion-col size-md="11" size-xs="10">
        <ion-label class="label-note">
          {{ (moneyIn?.userUpdatedStamp || moneyIn?.updatedStamp) > moneyIn?.createdStamp ? 'Edited by ' : 'Created by ' }} 
          {{ (moneyIn?.userUpdatedStamp || moneyIn?.updatedStamp) > moneyIn?.createdStamp ? moneyIn?.lastModifiedByName || moneyIn?.lastModifiedBy :  moneyIn?.createdByName || moneyIn?.createdBy }}
          on
          {{ (moneyIn?.userUpdatedStamp || moneyIn?.updatedStamp) | date: 'dd/MM/yy h:mm:ss a' }}
        </ion-label>
      </ion-col>
      <ion-col size-md="1" size-xs="2" (click)="billView(moneyIn)">
        <ion-col size="auto" offset="1">
          <div *ngIf="moneyIn?.syncStamp > moneyIn?.updatedStamp; then thenBlock else elseBlock"></div>
          <ng-template #thenBlock>
            <ion-button class="btn-popover" title="Data Uploaded" fill="clear">
              <ion-icon 
                name="checkmark-done-sharp" 
                size="small"
                color="success">
              </ion-icon>
            </ion-button>
          </ng-template>
          <ng-template #elseBlock>
            <ion-button class="btn-popover" title="Warning" fill="clear">
              <ion-icon 
                name="warning-sharp" 
                color="warning" 
                size="small">
              </ion-icon>
            </ion-button>
          </ng-template>
        </ion-col>
      </ion-col>
    </ion-row>
  </ion-col>
</ion-row>

<app-pin-verification (verifyTransactionPin)="verifyTransactionPIN($event)" #pinVerificationElement></app-pin-verification>