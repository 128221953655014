import { MoneyOutRowComponent } from './components/rows/money-out-row/money-out-row.component';
import { NavigationControlComponent } from './components/navigation-control/navigation-control.component';
import { PartyRestaurantListComponent } from './components/party-restaurant-list/party-restaurant-list.component';
import { ItemFormComponent } from './components/item-form/item-form.component';
import { ItemRestaurantSelectorComponent } from './components/item-restaurant-selector/item-restaurant-selector.component';
import { ItemRestaurantListComponent } from './components/item-restaurant-list/item-restaurant-list.component';
import { ItemListComponent } from './components/item-list/item-list.component';
import { PartyListComponent } from './components/party-list/party-list.component';
import { MoneyInFormComponent } from './components/money-in-form/money-in-form.component';
import { TransportDetailsComponent } from './components/transport-details/transport-details.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ItemSelectorComponent } from './components/item-selector/item-selector.component';
import { PartySelectorComponent } from './components/party-selector/party-selector.component';
import { RemoveTimeButtonDirective } from './directives/remove-time-button.directive';
import { MoneyInSelectorComponent } from './components/money-in-selector/money-in-selector.component';
import { MoneyInListComponent } from './components/money-in-list/money-in-list.component';
import { ItemStockAdjustComponent } from './components/item-stock-adjust/item-stock-adjust.component';
import { SettingsComponent } from './components/settings/settings.component';
import { PartyRestaurantSelectorComponent } from './components/party-restaurant-selector/party-restaurant-selector.component';
import { ImageCropperComponent } from './components/image-cropper/image-cropper.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { DateRangeComponent } from './components/date-range/date-range.component';
import { PaymentReminderComponent } from './components/payment-reminder/payment-reminder.component';
import { SaleRowComponent } from './components/rows/sale-row/sale-row.component';
import { MoneyInRowComponent } from './components/rows/money-in-row/money-in-row.component';
import { PurchaseRowComponent } from './components/rows/purchase-row/purchase-row.component';
import { ReportDateRangeModalDirective } from './directives/report-date-range-modal.directive';
import { PinVerificationComponent } from './components/pin-verification/pin-verification.component';
import { DeleteDataComponent } from './components/delete-data/delete-data.component';
import { PartyCategoryListComponent } from './components/party-category-list/party-category-list.component';
import { ItemCategoryListComponent } from './components/item-category-list/item-category-list.component';
import { ProfileFormComponent } from './components/profile-form/profile-form.component';
import { ItemSettingsComponent } from './components/item-settings/item-settings.component';
import { MoneyOutFormComponent } from './components/money-out-form/money-out-form.component';
import { MoneyOutSelectorComponent } from './components/money-out-selector/money-out-selector.component';
import { DateTimeCssDirective } from './directives/date-time-css.directive';
import { BarcodeGeneratorComponent } from './components/barcode-generator/barcode-generator.component';
import { LicenceInfoComponent } from './components/licence-info/licence-info.component';
import { ExpenseRowComponent } from './components/rows/expense-row/expense-row.component';
import { KotListComponent } from './components/kot-list/kot-list.component';
import { IngredientFormComponent } from './components/ingredient-form/ingredient-form.component';
import { IngredientStockAdjustComponent } from './components/ingredient-stock-adjust/ingredient-stock-adjust.component';
import { IngredientSelectorComponent } from './components/ingredient-selector/ingredient-selector.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { HorizontalScrollDirective } from './directives/content-css.directive';
import { LoginTokenInfoComponent } from './components/login-token-info/login-token-info.component';
import { HeaderTitleCssDirective } from './directives/header-title-css.directive';
import { WhatappPartySelectorComponent } from './components/whatapp-party-selector/whatapp-party-selector.component';
import { EstimateRowComponent } from './components/rows/estimate-row/estimate-row.component';
import { NewFeatureSlidesComponent } from './components/new-feature-slides/new-feature-slides.component';
import { YearDefaultBtnDirective } from './directives/year-default-btn.directive';
import { StaffListComponent } from './components/staff-list/staff-list.component';
import { NonPremiumModalComponent } from './components/non-premium-modal/non-premium-modal.component';
import { ColorFormValidationDirective } from './directives/color-form-validation.directive';
import { SaleBulkDeleteComponent } from './components/bulk-delete/sale-bulk-delete/sale-bulk-delete.component';
import { MoneyOutBulkDeleteComponent } from './components/bulk-delete/money-out-bulk-delete/money-out-bulk-delete.component';
import { MoneyInBulkDeleteComponent } from './components/bulk-delete/money-in-bulk-delete/money-in-bulk-delete.component';
import { PurchaseBulkDeleteComponent } from './components/bulk-delete/purchase-bulk-delete/purchase-bulk-delete.component';
import { ExpenseBulkDeleteComponent } from './components/bulk-delete/expense-bulk-delete/expense-bulk-delete.component';
import { SaleReturnRowComponent } from './components/rows/sale-return-row/sale-return-row.component';
import { SaleReturnBulkDeleteComponent } from './components/bulk-delete/sale-return-bulk-delete/sale-return-bulk-delete.component';
import { PurchaseReturnRowComponent } from './components/rows/purchase-return-row/purchase-return-row.component';
import { PurchaseReturnBulkDeleteComponent } from './components/bulk-delete/purchase-return-bulk-delete/purchase-return-bulk-delete.component';
import { HideYearDirective } from './directives/hide-year.directive';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    ImageCropperModule,
    ColorPickerModule,
  ],
  declarations: [
    PartyListComponent,
    PartySelectorComponent,
    ItemListComponent,
    ItemRestaurantListComponent,
    ItemSelectorComponent,
    ItemRestaurantSelectorComponent,
    TransportDetailsComponent,
    RemoveTimeButtonDirective,
    DateTimeCssDirective,
    ReportDateRangeModalDirective,
    ClickOutsideDirective,
    HorizontalScrollDirective,
    HeaderTitleCssDirective,
    YearDefaultBtnDirective,
    HideYearDirective,
    ColorFormValidationDirective,
    MoneyInFormComponent,
    MoneyInListComponent,
    MoneyInSelectorComponent,
    ItemFormComponent,
    ItemStockAdjustComponent,
    SettingsComponent,
    StaffListComponent,
    PartyRestaurantSelectorComponent,
    PartyRestaurantListComponent,
    ImageCropperComponent,
    NavigationControlComponent,
    DateRangeComponent,
    PaymentReminderComponent,
    SaleRowComponent,
    SaleReturnRowComponent,
    EstimateRowComponent,
    MoneyInRowComponent,
    PurchaseRowComponent,
    PurchaseReturnRowComponent,
    MoneyOutRowComponent,
    PinVerificationComponent,
    DeleteDataComponent,
    SaleBulkDeleteComponent,
    SaleReturnBulkDeleteComponent,
    MoneyOutBulkDeleteComponent,
    MoneyInBulkDeleteComponent,
    PurchaseBulkDeleteComponent,
    PurchaseReturnBulkDeleteComponent,
    ExpenseBulkDeleteComponent,
    PartyCategoryListComponent,
    ItemCategoryListComponent,
    ProfileFormComponent,
    ItemSettingsComponent,
    MoneyOutFormComponent,
    MoneyOutSelectorComponent,
    BarcodeGeneratorComponent,
    LicenceInfoComponent,
    ExpenseRowComponent,
    KotListComponent,
    IngredientFormComponent,
    IngredientStockAdjustComponent,
    IngredientSelectorComponent,
    LoginTokenInfoComponent,
    WhatappPartySelectorComponent,
    NewFeatureSlidesComponent,
    NonPremiumModalComponent,
  ],
  exports: [
    PartyListComponent,
    PartySelectorComponent,
    ItemListComponent,
    ItemRestaurantListComponent,
    ItemSelectorComponent,
    ItemRestaurantSelectorComponent,
    TransportDetailsComponent,
    RemoveTimeButtonDirective,
    DateTimeCssDirective,
    ReportDateRangeModalDirective,
    ClickOutsideDirective,
    HorizontalScrollDirective,
    HeaderTitleCssDirective,
    YearDefaultBtnDirective,
    HideYearDirective,
    ColorFormValidationDirective,
    MoneyInFormComponent,
    MoneyInListComponent,
    MoneyInSelectorComponent,
    ItemFormComponent,
    ItemStockAdjustComponent,
    SettingsComponent,
    PartyRestaurantSelectorComponent,
    PartyRestaurantListComponent,
    ImageCropperComponent,
    NavigationControlComponent,
    DateRangeComponent,
    PaymentReminderComponent,
    SaleRowComponent,
    SaleReturnRowComponent,
    StaffListComponent,
    EstimateRowComponent,
    MoneyInRowComponent,
    PurchaseRowComponent,
    PurchaseReturnRowComponent,
    MoneyOutRowComponent,
    PinVerificationComponent,
    DeleteDataComponent,
    SaleBulkDeleteComponent,
    SaleReturnBulkDeleteComponent,
    MoneyOutBulkDeleteComponent,
    MoneyInBulkDeleteComponent,
    PurchaseBulkDeleteComponent,
    PurchaseReturnBulkDeleteComponent,
    ExpenseBulkDeleteComponent,
    PartyCategoryListComponent,
    ItemCategoryListComponent,
    ProfileFormComponent,
    ItemSettingsComponent,
    MoneyOutFormComponent,
    MoneyOutSelectorComponent,
    BarcodeGeneratorComponent,
    LicenceInfoComponent,
    ExpenseRowComponent,
    KotListComponent,
    IngredientFormComponent,
    IngredientStockAdjustComponent,
    IngredientSelectorComponent,
    LoginTokenInfoComponent,
    WhatappPartySelectorComponent,
    NewFeatureSlidesComponent,
    NonPremiumModalComponent,
  ],
})
export class SharedModule {}
