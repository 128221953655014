import { BaseModel } from "./BaseModel.model";

export class IImageBase64 {

  static SCHEMA_NAME = 'image-base64';

}
export class ImageBase64 {

  url: string;
  imageBase64: string;

}
