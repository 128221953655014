import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { InfiniteScrollCustomEvent, ToastController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { BillItem } from '../../models/BillItem.model';
import { Item } from '../../models/Item.model';
import { AllDataService } from '../../services/all-data.service';
import { Utility } from '../../utils/utility';
import ItemCategory from '../../models/ItemCategory.model';
import { Image } from '../../models/image.model';
import Party, { BillingType } from '../../models/Party.model';
import { KotItem } from 'src/app/models/KotItem.model';
import { environment } from '../../../environments/environment';
import { Utils } from '../../utils/utils';
import { MonthWiseItemStockService } from '../../services/month-wise-item-stock.service';
import { SearchFilterService } from '../../services/search-filter.service';
import { ImageService } from '../../services/image.service';
import { ImageBase64Service } from '../../services/image-base64.service';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-item-restaurant-list',
  templateUrl: './item-restaurant-list.component.html',
  styleUrls: ['./item-restaurant-list.component.scss'],
})
export class ItemRestaurantListComponent implements OnInit {

  @Input() isPurchase: boolean = false;
  @Input() isPurchaseReturn: boolean = false;
  @Input() isEstimate: boolean = false;
  @Input() cartItems: BillItem[] = [];
  @Input() sortByItemCodeAsc: boolean = false;
  @Input() showBookmarkList: boolean = false;
  @Input() negativeStockLock: boolean = false;
  @Input() barcodeScannerMode: boolean = false;
  @Input() party: Party = null;
  @Input() paramDocumentId: string = null;
  @Input() iSetItemPriceHistoryStatus: boolean = false;
  @Input() iSetOutOfStockHideStatus: boolean = false;
  @Input() iSetShowStockStatus: boolean = false;
  @Input() iSetItemSelectorColumns: number = 4;

  @Output() updateCart = new EventEmitter<BillItem[]>();
  @Output() setBillItemsNoEmitNoCal = new EventEmitter<BillItem[]>();
  @Output() openBillItemForm = new EventEmitter<BillItem>();
  @Output() openQuantityForm = new EventEmitter<BillItem>();
  @Output() onPriceShowStatusChange = new EventEmitter<BillItem>();

  selectedProfileId = Utility.getFromLocalStorage('selectedProfile');
  selectedProfileUserId = Utility.getFromLocalStorage('selectedProfileUserId');
  capFractionsToTwo = Utils.capFractionsToTwo;

  filteredList: BillItem[] = [];
  completeList: BillItem[] = [];
  viewFilteredList: BillItem[] = [];

  appliedCategory = '';
  selectedFilter = '';

  isFilterOptionOpen = false;

  categories: string[] = [];
  cartItemsCategories: string[] = [];

  subsArr: Subscription[] = [];

  imagesBase64: {[key:string]: string} = {};

  categoryWiseItemCount = {};
  favItemCount:number = 0

  itemMap: Item[] = [];

  isAbbrivationSearchEnabled: boolean = false;
  isStartsWithSearchPrioritized: boolean = false;

  allImages: Image[] = [];

  loadViewTimeStamp: number = 0;

  shortStr = Utility.shortStr;

  cssColors = {
    selectedItemColor: '#ADF7B6',
  };

  constructor(
    private allDataService: AllDataService,
    private toastController: ToastController,
    private monthWiseItemStockService: MonthWiseItemStockService,
    private searchFilterService: SearchFilterService,
    private imageBase64Service: ImageBase64Service,
  ) { }

  ngOnInit() {
    try {
      this.setCssColor();
      this.reduceFunctionCall();
      this.subsArr.push(this.allDataService.listForceReloadSubs
        .subscribe(async (listName: string) => {
          if (listName == 'item-list' || listName == 'profile-list') {
            this.reduceFunctionCall();
          }
        }))
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantListComponent:ngOnInit", error)
    }
  }

  async getAllImages() {
    this.allImages = await this.allDataService.imageService.getAll();
  }

  ngAfterViewInit() { }

  ngOnChanges(changes: SimpleChanges) {
    try {
      if (Boolean(changes?.sortByItemCodeAsc?.currentValue) != Boolean(changes?.sortByItemCodeAsc?.previousValue)) {
        this.onSortChnage(this.sortByItemCodeAsc ? 'SortByItemCodeAsc' : 'SortByNameAsc');
      }
  
      if (changes?.negativeStockLock?.currentValue != changes?.negativeStockLock?.previousValue && changes?.negativeStockLock?.currentValue === true) {
        this.completeList.map(billItem => {
          if(billItem?.item?.stock<=0) {
            billItem.quantity = 0;
          }
        });
        this.updateCartEmit();
      }
  
      if(changes?.party && (changes?.party?.currentValue?.billingType != changes?.party?.previousValue?.billingType)) {
        this.reduceFunctionCall();
      }
  
      if(
        (changes?.iSetOutOfStockHideStatus?.currentValue==false || changes?.iSetOutOfStockHideStatus?.currentValue==true)
        && (changes?.iSetOutOfStockHideStatus?.currentValue != changes?.iSetOutOfStockHideStatus?.previousValue)
      ) {
        this.reduceFunctionCall();
      }
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantListComponent:ngOnChanges", error)
    }

  }

  async getCategories() {
    try {
      let itemCategories=await this.allDataService.itemCategoryService.getAllByPromise() || [];
      itemCategories.sort((a: ItemCategory, b: ItemCategory) => {
        if(a?.savedPosition || b?.savedPosition) {
          return a?.savedPosition - b?.savedPosition;
        }
        if (a?.name?.toLowerCase() === b?.name?.toLowerCase()) {
          return a?.createdStamp - b?.createdStamp;
        }
        if (a?.name?.toLowerCase() > b?.name?.toLowerCase()) {
          return 1;
        }
        return -1;
      });
      if (itemCategories?.length) {
        this.categories = itemCategories.map(x => x?.name);
      }
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantListComponent:getCategories", error)
    }
  }

  async setCssColor() {
    try {
      let profile = await this.allDataService.profileService.getCurrentProfile();
      this.cssColors.selectedItemColor = profile?.iSetColorItemSelectorSelectedItem || '#ADF7B6';
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantListComponent:setCssColor", error)
    }
  }

  async loadView() {
    try {
      // this.filteredList = [];
      // this.completeList = [];
      // this.viewFilteredList = [];
  
      // this.appliedCategory = '';
      // this.selectedFilter = '';
  
      this.isFilterOptionOpen = false;
  
      // this.categories = [];
      this.getCategories();
  
      let completeListHashMap = {};
  
      let completeListLength = this.completeList?.length;
      for (let i = 0; i < completeListLength; i++) {
        let key = JSON.stringify(this.completeList[i]);
        completeListHashMap[key] = true;
      }
  
      let differentItemObjects = [];
  
      let items = await this.allDataService.itemService.getAllByPromise() || [];
  
      items?.sort((a: Item, b: Item) => { 
        if (a?.itemName?.toLowerCase() == b?.itemName?.toLowerCase()) {
          return a?.createdStamp - b?.createdStamp;
        } 
        if (a?.itemName?.toLowerCase() > b?.itemName?.toLowerCase()) {
          return 1;
        } 
        return -1;
      })
  
      const ledgerStockHashMap = await this.monthWiseItemStockService.getLedgerStockHashMap();
  
      // storing stock and find out differentItemObjects from old itemMap
      let itemsLength = items?.length;
      for (let i = 0; i < itemsLength; i++) {
        items[i].stock = ledgerStockHashMap[items[i]?._localUUID] || 0;
  
        if(this.itemMap?.length) {
          let key = JSON.stringify(items[i]);
          if(!completeListHashMap[key]) {
            differentItemObjects.push(items[i]);
          }
        }
      }
  
      if (
        this.itemMap?.length != items?.length ||
        JSON.stringify(this.itemMap) != JSON.stringify(items)
      ) {
  
        let differentObjectsLength = differentItemObjects?.length;
  
        if (differentObjectsLength) {
          for (let i = 0; i < differentObjectsLength; i++) {
            let differentObject = differentItemObjects[i];
            let index = this.itemMap.findIndex(el => el?._localUUID === differentObject?._localUUID);
            if (index != -1) {
              this.itemMap[index] = differentObject;
            } else {
              this.itemMap.push(differentObject);
            }
          }
        } else {
          this.itemMap = [...items];
        }
  
      }
  
      this.favItemCount = items.filter((el) => el?.isFavourite)?.length || 0;
  
      if (this.iSetOutOfStockHideStatus) {
        // filter stock item and remove negative stock item
        items = items.filter(x => {
          if(x?.stock > 0) {
            return x;
          } else {
            let index = this.completeList.findIndex(el => el?.item?.stock <= 0)
            if(index != -1) {
              this.completeList.splice(index, 1);
            }
          }
        });
      }
      if (items.length) {
        this.categoryWiseItemCount = {};
        this.categories?.forEach(category => {
          if(category) {
            this.categoryWiseItemCount[category] = items?.filter(item => item?.category ? item?.category?.toLowerCase() == category?.toLowerCase() : false)?.length;
          }
        });
      }
  
      let differentCompleteListObjects = [];
  
      let newCompleteList = items.map((item: Item) => {
        let billItem = this.itemToBillItem(item);
  
        if(this.completeList?.length) {
          let key = JSON.stringify(billItem);
          if (!completeListHashMap[key]) {
            differentCompleteListObjects.push(billItem);
          }
        }
        return billItem
      });
  
      if(
        newCompleteList?.length != this.completeList?.length ||
        JSON.stringify(newCompleteList) != JSON.stringify(this.completeList)
        ) {
      
          let differentObjectsLength = differentCompleteListObjects?.length;
          if(differentObjectsLength) {
            for(let i=0; i < differentObjectsLength; i++) {
              let differentObject = differentCompleteListObjects[i];
              let index = this.completeList.findIndex(el => el?.item?._localUUID === differentObject?.item?._localUUID);
              if(index != -1) {
                this.completeList[index] = differentObject;
              } else {
                this.completeList.push(differentObject);
              }
            }
          } else {
            this.completeList = [...newCompleteList];
          }
          
          if (this.paramDocumentId && Array.isArray(this.cartItems)) {
            //remove duplicate items & update the quantity in single item - for clothing style
            this.cartItems = this.cartItems.reduce((acc, item) => {
              let index = acc.findIndex(x => x?.item?._localUUID === item?.item?._localUUID);
              if (index != -1) {
                acc[index].quantity = acc[index].quantity + item?.quantity;
              } else {
                acc.push({ ...item });
              }
              return acc
            }, [])
      
          }
  
          if(Array.isArray(this.cartItems)) {
            // 2.26. Party Wise Item Price 
            for(let i = 0; i < this.cartItems?.length; i++) {
              let billItem = this.cartItems[i];
              if (this.party?._localUUID && this.iSetItemPriceHistoryStatus && !this.isPurchase) {
                let priceMap = await this.allDataService.partyItemPriceMapService.getByPartyUUIDItemUUID(this.party?._localUUID, billItem?.item?._localUUID);
    
                let index = this.completeList.findIndex(el => el?.item?._localUUID === billItem?.item?._localUUID);
                let images = this.completeList[index]?.item?.images;
                this.completeList[index] = { ...this.completeList[index], ...billItem };
                this.completeList[index].item.images = images;
                if(!priceMap) {
                  this.completeList[index].price = this.completeList[index].effectivePrice = this.completeList[index]?.item?.sellPrice;
                } else {
                  this.completeList[index].price = priceMap?.sellPrice || billItem?.price || this.getPriceByBillingType(this.completeList[index]?.item);
                  this.completeList[index].effectivePrice = priceMap?.sellPrice || billItem?.price || this.getPriceByBillingType(this.completeList[index]?.item);
                }
              } else {
                const index = this.completeList.findIndex(x => x.item?._localUUID == billItem?.item?._localUUID);
                let images = this.completeList[index]?.item?.images;
                this.completeList[index] = { ...this.completeList[index], ...billItem };
                this.completeList[index].item.images = images;
                this.completeList[index].price = billItem?.price || this.getPriceByBillingType(this.completeList[index]?.item);
                this.completeList[index].effectivePrice = billItem?.price || this.getPriceByBillingType(this.completeList[index]?.item);
              }
            }
          }
  
          this.updateCartEmit(true);
      
          this.onSortChnage(this.sortByItemCodeAsc ? 'SortByItemCodeAsc' : 'SortByNameAsc');
          await this.getAllImages();
          this.loadImages();
        }
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantListComponent:loadView", error)
    }

  }

  reduceFunctionCall() {
    try {
      if(+new Date() > this.loadViewTimeStamp) {
        this.loadViewTimeStamp = +new Date() + 5000;
        this.loadView();
        this.loadCurrentProfile();
      }
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantListComponent:reduceFunctionCall", error)
    }
  }

  async loadCurrentProfile() {
    try {
      let profile = await this.allDataService.profileService.getCurrentProfile();
      this.isAbbrivationSearchEnabled = profile?.isAbbrivationSearchEnabled;
      this.isStartsWithSearchPrioritized = profile?.isStartsWithSearchPrioritized === false ? false : true;
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantListComponent:loadCurrentProfile", error)
    }
  }

  getPriceByBillingType(item: Item): number {
    if(this.isPurchase || this.isPurchaseReturn) {
      return item?.purchasePrice || 0.0;
    }
    if(this.party?.billingType == BillingType.OnlineDeliverySellPrice && item?.onlineDeliverySellPrice!=null && item?.onlineDeliverySellPrice > -1) {
      return item?.onlineDeliverySellPrice;
    }else if(this.party?.billingType == BillingType.AcSellPrice && item?.acSellPrice!=null && item?.acSellPrice > -1) {
      return item?.acSellPrice;
    }else if(this.party?.billingType == BillingType.NonAcSellPrice && item?.nonAcSellPrice!=null && item?.nonAcSellPrice > -1) {
      return item?.nonAcSellPrice;
    }else if(this.party?.billingType == BillingType.SellPrice && item?.sellPrice!=null && item?.sellPrice > -1) {
      return item?.sellPrice;
    }
    return item?.sellPrice;
  }

  itemToBillItem(item: Item): BillItem {
    try {
      return {
        item,
        quantity: 0,
        effectiveQuantity: 0,
        unit: item?.primaryUnit || null,
        convertRatioMultiplier: 1.0,
        price: this.getPriceByBillingType(item) || 0.0,
        effectivePrice: this.getPriceByBillingType(item) || 0.0,
        effectiveMrp: null,
        discount: null,
        discountPercentage: item?.discountPercent || 0.0,
        billCashDiscountPercentage: null,
        effectiveDiscountPercentage: null,
        incTax: item?.spIncTax===false ? false : true,
        taxPercentage: item?.taxPercentage || 0.0,
        cessPercentage: item?.cessPercentage || 0.0,
        isTaxExempted: item?.isTaxExempted || 0,
        isTaxZero: item?.isTaxZero || 0,
        isNonGstSupplies: item?.isNonGstSupplies || 0,
        effectiveTaxPercentage: null,
        basePrice: null,
        subTotal: null,
        total: null,
        totalSaving: null,
        billNote: null,
        kotNote: null,
        unitDiscountAmount: null,
        unitGstAmount: null,
        unitCessAmount: null,
        unitTaxAmount: null,
        itemTotalGstAmount: null,
        itemTotalCessAmount: null,
        itemTotalTaxAmount: null,
        wcdBasePrice: null,
        wcdUnitTaxAmount: null,
        wcdTotal: null,
      };
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantListComponent:itemToBillItem", error)
      return null;
    }
  }

  toggleFilterOptions() {
    this.isFilterOptionOpen = !this.isFilterOptionOpen;
  }

  onCategoryChange(category: string) {
    try {
      this.appliedCategory = category;
      this.onFilterChange(this.lastSearchKey);
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantListComponent:onCategoryChange", error)
    }
  }

  onSortChnage(selectedFilter: string) {
    try {
      this.selectedFilter = selectedFilter;
      this.onFilterChange(this.lastSearchKey);
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantListComponent:onSortChnage", error)
    }
  }

  isFilterPostpond = false;
  lastFilterStamp = 0;
  onFilterChange(searchKey?: string) {
    try {
      if (this.isFilterPostpond) {
        return true;
      }
      if (this.lastFilterStamp < +new Date() - 200) {
        this.lastFilterStamp = +new Date();
        this.applyFilterV2(searchKey);
        // this.applyFilter({
        //   searchKeyword: searchKey,
        //   selectedFilter: this.selectedFilter,
        //   category: this.appliedCategory,
        // })
      } else {
        this.isFilterPostpond = true;
        setTimeout(() => {
          this.isFilterPostpond = false;
          this.onFilterChange(searchKey);
        }, 250);
      }
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantListComponent:onFilterChange", error)
    }
  }

  lastSearchKey = '';
  async applyFilter(params: {
    searchKeyword?: string,
    selectedFilter?: string,
    category?: string,
  }) {
    try {
      let arr = [];
      let lsearchKey = '';
      let isSearchIncremental = false;
      let isSearchCleared = false;
      if (params?.searchKeyword?.length) {
        lsearchKey = Utility.toLowerCase(params.searchKeyword);
        if (lsearchKey.indexOf(this.lastSearchKey) == 0) {
          // isSearchIncremental = true;
          // TODO
          isSearchIncremental = false;
        }
        this.lastSearchKey = lsearchKey;
      }
      if (this.lastSearchKey.length > 0 && lsearchKey.length == 0) {
        isSearchCleared = true;
        this.lastSearchKey = '';
      }
      if(!this.filteredList?.length && isSearchIncremental) {
        this.filteredList = [...this.completeList];
      }
      let completeList = [];
      if (isSearchIncremental) {
        completeList = [...this.filteredList]
      } else {
        completeList = [...this.completeList];
      }
      if (params?.category?.length) {
        if (params.category === 'FilterByFav') {
          arr = completeList.filter(el => el?.item?.isFavourite && 
            (lsearchKey?.length ? Utility.toLowerCase(el?.item?.itemName)?.includes(lsearchKey) : true)
            );
        } else {
          arr = completeList.filter(el =>
            el?.item?.category === params?.category &&
            (lsearchKey?.length ? Utility.toLowerCase(el?.item?.itemName)?.includes(lsearchKey) : true)
          );
        }
      }
       else if (lsearchKey.length) {
        completeList?.forEach((el) => {
          if(
            Utility.toLowerCase(el?.item?.itemName).indexOf(lsearchKey) != -1
            || Utility.toLowerCase(el?.item?.barcode) === lsearchKey
            || Utility.toLowerCase(el?.item?.barcode2) === lsearchKey
            || Utility.toLowerCase(el?.item?.barcode3) === lsearchKey
            || Utility.toLowerCase(el?.item?.barcode4) === lsearchKey
            || Utility.toLowerCase(el?.item?.barcode5) === lsearchKey
            || Utility.toLowerCase(el.item?.itemCode) === lsearchKey
            || Utility.toLowerCase(el?.item?.itemName).includes(lsearchKey)
            || Utility.toLowerCase(el?.item?.barcode).includes(lsearchKey)
            || Utility.toLowerCase(el?.item?.barcode2).includes(lsearchKey)
            || Utility.toLowerCase(el?.item?.barcode3).includes(lsearchKey)
            || Utility.toLowerCase(el?.item?.barcode4).includes(lsearchKey)
            || Utility.toLowerCase(el?.item?.barcode5).includes(lsearchKey)
            || Utility.toLowerCase(el?.item?.itemCode).includes(lsearchKey)
          ) {
            arr.push(el);
          }
        });
      } else {
        arr = [...completeList]
      }
      
  
      if (params.selectedFilter == 'SortByNameAsc') {
        arr.sort((a: BillItem, b: BillItem) => {
          if (a?.item?.itemName == b?.item?.itemName) {
            return a?.item?.createdStamp - b?.item?.createdStamp;
          }
          if (a?.item?.itemName > b?.item?.itemName) {
            return 1;
          }
          return -1;
        });
      } else if (params.selectedFilter == 'SortByNameDesc') {
        arr.sort((a: BillItem, b: BillItem) => {
          if (a?.item?.itemName == b?.item?.itemName) {
            return a?.item?.createdStamp - b?.item?.createdStamp;
          }
          if (b?.item?.itemName > a?.item?.itemName) {
            return 1;
          }
          return -1;
        });
      } else if (params.selectedFilter == 'SortByItemCodeAsc') {
        let hasItemCodeArray = [];
        let notHaveItemCodeArray = [];
        arr?.forEach(billItem => {
          if(billItem?.item?.itemCode) {
            hasItemCodeArray.push(billItem);
          } else {
            notHaveItemCodeArray.push(billItem);
          }
        })
        hasItemCodeArray.sort((a: BillItem, b: BillItem) => {
            if (a?.item?.itemCode == b?.item?.itemCode) {
              return a?.item?.createdStamp - b?.item?.createdStamp;
            }
            return Number(a?.item?.itemCode) - Number(b?.item?.itemCode)
        });
        notHaveItemCodeArray.sort((a: BillItem, b: BillItem) => {
          return a?.item?.itemName.localeCompare(b?.item?.itemName);
        });
        arr = [...hasItemCodeArray, ...notHaveItemCodeArray];
      } else if (params.selectedFilter == 'SortByStockAsc') {
  
      } else if (params.selectedFilter == 'SortByStockDesc') {
      }
  
      this.filteredList = [...arr];
  
      // this.categories?.forEach(category => {
      //   this.categoryWiseItemCount[category] = this.filteredList?.filter(item => item?.item?.category?.toLowerCase()==category?.toLowerCase())?.length;
      // });
  
      // if(lsearchKey) {
      //   let priorityArr: BillItem[] = [];
      //   this.filteredList?.forEach((x,i) => {
      //     if(x?.item?.itemName?.startsWith(lsearchKey)) {
      //       priorityArr.push(x);
      //       this.filteredList.splice(i,1);
      //     }
      //   });
      //   this.filteredList  = [...priorityArr.sort(),...this.filteredList];
      // }
  
      if (lsearchKey.length || isSearchCleared || params.selectedFilter) {
        this.viewFilteredList = this.filteredList.slice(0, 52);
      } else {
        this.viewFilteredList = this.filteredList.slice(0, 52);
      }
  
      if(!this.viewFilteredList?.length) {
        if(lsearchKey) {
          let index = this.completeList?.findIndex(billItem => billItem?.item?.barcode==lsearchKey);
          if(index == -1){
            index = this.completeList?.findIndex(billItem => billItem?.item?.barcode2==lsearchKey);
          }
          if(index == -1){
            index = this.completeList?.findIndex(billItem => billItem?.item?.barcode3==lsearchKey);
          }
          if(index == -1){
            index = this.completeList?.findIndex(billItem => billItem?.item?.barcode4==lsearchKey);
          }
          if(index == -1){
            index = this.completeList?.findIndex(billItem => billItem?.item?.barcode5==lsearchKey);
          }
          if(index == -1) {
            let item = await this.allDataService.itemService.getMasterItem(lsearchKey);
            if(item?._localUUID) {
              let billItem = this.itemToBillItem(item);
              this.completeList.unshift(billItem);
              const toast = await this.toastController.create({
                header: `${item.itemName} added for barcode ${lsearchKey} from Global Database`,
                duration: 5000,
                position: 'top',
                mode: 'ios',
                color: 'success',
              });
              await toast.present();
            }
          }
        }
      }
  
      // this.updateCartEmit(true);
      return true;
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantListComponent:applyFilter", error)
      return false;
    }
  }

  /**
   * 
   * @param inpSearchStr : provide input search result
   */
  async applyFilterV2(inpSearchStr?: string) {
    try {
      inpSearchStr = this.lastSearchKey = inpSearchStr?.toLowerCase();
  
      let arr = [...this.completeList];
  
      if (this.appliedCategory) {
        if (this.appliedCategory == 'FilterByFav') {
          arr = this.completeList.filter((el: BillItem) => {
            if(el?.item?.isFavourite) {
              return el
            }
          })
        } else {
          arr = this.completeList.filter((el: BillItem) => {
            if (el?.item?.category == this.appliedCategory) {
              return el
            }
          });
        }
      }
  
      let finalArray = [];
  
      if(inpSearchStr) {
        let abbrivationSearchResultArr = [];
        if(this.isAbbrivationSearchEnabled) {
          abbrivationSearchResultArr = this.searchFilterService.abbrivationSearch(inpSearchStr, arr);
        }
        let startsWithSearchResultArr = this.searchFilterService.startsWithSearch(inpSearchStr, arr);
        let containsInSearchResultArr = this.searchFilterService.containsInSearch(inpSearchStr, arr);
        let barcodeSearchArr = this.searchFilterService.barcodeSearch(inpSearchStr, arr);
        let itemCodeSearchArr = this.searchFilterService.itemCodeSearch(inpSearchStr, arr);
    
        let rawArray = []
    
    
        let hashMap = {};
    
        if(this.isAbbrivationSearchEnabled && this.isStartsWithSearchPrioritized) {
          rawArray = [
            ...abbrivationSearchResultArr,
            ...startsWithSearchResultArr,
            ...containsInSearchResultArr,
          ]
        } else if(this.isAbbrivationSearchEnabled && !this.isStartsWithSearchPrioritized) {
          rawArray = [
            ...abbrivationSearchResultArr,
            ...containsInSearchResultArr,
            ...startsWithSearchResultArr,
          ]
        } else if(!this.isAbbrivationSearchEnabled && this.isStartsWithSearchPrioritized) {
          rawArray = [
            ...startsWithSearchResultArr,
            ...containsInSearchResultArr,
          ]
        } else if(!this.isAbbrivationSearchEnabled && !this.isStartsWithSearchPrioritized) {
          rawArray = [
            ...containsInSearchResultArr,
            ...startsWithSearchResultArr,
          ]
        }
    
        rawArray = [
          ...rawArray,
          ...barcodeSearchArr,
          ...itemCodeSearchArr,
        ]
    
        // To remove duplicates item
        rawArray?.forEach((el:BillItem) => {
          hashMap[el?.item?._localUUID] = el;
        })
    
        for(let key in hashMap) {
          finalArray.push(hashMap[key]);
        }
      } else {
        finalArray = arr;
      }
  
      if(!inpSearchStr) {
        if (this.selectedFilter == 'SortByNameAsc') {
          finalArray.sort((a: BillItem, b: BillItem) => {
            if (a?.item?.itemName == b?.item?.itemName) {
              return a?.item?.createdStamp - b?.item?.createdStamp;
            }
            if (a?.item?.itemName > b?.item?.itemName) {
              return 1;
            }
            return -1;
          });
        } else if (this.selectedFilter == 'SortByNameDesc') {
          finalArray.sort((a: BillItem, b: BillItem) => {
            if (a?.item?.itemName == b?.item?.itemName) {
              return a?.item?.createdStamp - b?.item?.createdStamp;
            }
            if (b?.item?.itemName > a?.item?.itemName) {
              return 1;
            }
            return -1;
          });
        } else if (this.selectedFilter == 'SortByItemCodeAsc') {
          let hasItemCodeArray = [];
          let notHaveItemCodeArray = [];
          finalArray?.forEach(billItem => {
            if(billItem?.item?.itemCode) {
              hasItemCodeArray.push(billItem);
            } else {
              notHaveItemCodeArray.push(billItem);
            }
          })
          hasItemCodeArray.sort((a: BillItem, b: BillItem) => {
              if (a?.item?.itemCode == b?.item?.itemCode) {
                return a?.item?.createdStamp - b?.item?.createdStamp;
              }
              return Number(a?.item?.itemCode) - Number(b?.item?.itemCode)
          });
          notHaveItemCodeArray.sort((a: BillItem, b: BillItem) => {
            return a?.item?.itemName.localeCompare(b?.item?.itemName);
          });
          finalArray = [...hasItemCodeArray, ...notHaveItemCodeArray];
        }
      }
  
      finalArray = this.searchFilterService.sortByFavItem(finalArray);
  
      this.filteredList = finalArray;
  
      this.viewFilteredList = this.viewFilteredList?.length > 50 ? [...this.filteredList.slice(0, this.viewFilteredList?.length)] : [...this.filteredList.slice(0, 50)];
  
      if(!this.viewFilteredList?.length) {
        if(inpSearchStr) {
          let index = this.completeList?.findIndex(billItem => billItem?.item?.barcode==inpSearchStr);
          if(index == -1){
            index = this.completeList?.findIndex(billItem => billItem?.item?.barcode2==inpSearchStr);
          }
          if(index == -1){
            index = this.completeList?.findIndex(billItem => billItem?.item?.barcode3==inpSearchStr);
          }
          if(index == -1){
            index = this.completeList?.findIndex(billItem => billItem?.item?.barcode4==inpSearchStr);
          }
          if(index == -1){
            index = this.completeList?.findIndex(billItem => billItem?.item?.barcode5==inpSearchStr);
          }
          if(index == -1) {
            let item = await this.allDataService.itemService.getMasterItem(inpSearchStr);
            if(item?._localUUID) {
              let billItem = this.itemToBillItem(item);
              this.completeList.unshift(billItem);
              const toast = await this.toastController.create({
                header: `${item.itemName} added for barcode ${inpSearchStr} from Global Database`,
                duration: 5000,
                position: 'top',
                mode: 'ios',
                color: 'success',
              });
              await toast.present();
            }
          }
        }
      }
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantListComponent:applyFilterV2", error)
    }


  }
  // -------------------------------------------------------

  loadMoreListData(event) {
    try {
      if (this.viewFilteredList.length > 0 && this.viewFilteredList.length <= this.filteredList.length) {
        let appendListLength = this.filteredList.length - this.viewFilteredList.length;
        let lastEl = this.viewFilteredList[this.viewFilteredList.length - 1];
        let counter = 0;
        for (let i = 0; i < this.filteredList.length; i++) {
          if (this.filteredList[i].item?._localUUID == lastEl.item?._localUUID) {
            counter = 1;
            continue;
          }
          if (counter > 0 && appendListLength >= 52) {
            if (counter <= 52) {
              this.viewFilteredList.push(this.filteredList[i])
            } else {
              break;
            }
            counter++;
          } else if(counter > 0 && appendListLength < 52) {
            if (counter <= appendListLength) {
              this.viewFilteredList.push(this.filteredList[i])
            } else {
              break;
            }
            counter++;
          }
        }
        (event as InfiniteScrollCustomEvent).target.complete();
      }
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantListComponent:loadMoreListData", error)
    }
  }

  updateCartEmit(preventEmit:boolean=false) {
    try {
      if(this.completeList?.length) {
        setTimeout(() => {
          const cartItems = this.completeList.filter(billItem => billItem?.quantity);
          if(preventEmit) {
            this.setBillItemsNoEmitNoCal.emit(cartItems);
          }else {
            this.updateCart.emit(cartItems);
          }
          this.maintainCartItemsCategory(cartItems);
        }, 500);
      }
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantListComponent:updateCartEmit", error)
    }
  }

  maintainCartItemsCategory(cartItems: BillItem[]) {
    try {
      this.cartItemsCategories = [];
      if(Array.isArray(cartItems)) {
        cartItems?.forEach(billItem => {
          if(billItem?.item?.category) {
            this.cartItemsCategories.push(billItem?.item?.category);
          }
        });
      }
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantListComponent:maintainCartItemsCategory", error)
    }
  }

  ngOnDestroy() {
    try {
      this.subsArr?.forEach((el) => {
        el?.unsubscribe();
      })
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantListComponent:ngOnDestroy", error)
    }
  }

  barcodeItemSequenceUUIDArr: string[] = [];

  maintainSequenceForBarcode(billItems: BillItem[]) {
    try {
      if(this.barcodeScannerMode) {
        let cartArr: BillItem[] = [];
        this.barcodeItemSequenceUUIDArr?.forEach(uuid => {
          let billItem = billItems.filter(x => x?.item?._localUUID===uuid)[0];
          if(billItem) {
            cartArr.push(billItem);
          }
        });
        billItems = [...cartArr];
      }
      return billItems;
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantListComponent:maintainSequenceForBarcode", error)
    }
  }

  selectOrderItem(orderItems: KotItem[]) {
    try {
      for (let i = 0; i < orderItems.length; i++) {
        const orderItem = orderItems[i];
        let index = this.completeList.findIndex(billItem => billItem?.item?._localUUID==orderItem?.itemUUID)
        if(index != -1) {
          this.completeList[index].kotNote = orderItem.note;
          this.increaseQty(this.completeList[index],orderItem?.quantity);
        }
      }
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantListComponent:selectOrderItem", error)
    }
  }

  async increaseQty(billItem: BillItem,customQty?: number) {
    try {
      if((this.isPurchase || this.isPurchaseReturn) && !billItem?.price) {
        this.onPriceShowStatusChange.emit(billItem)
      }else {
        this.onPriceShowStatusChange.emit(null)
      }
      if(!this.isPurchase && !this.isPurchaseReturn && !this.isEstimate && this.negativeStockLock && billItem?.item?.stock > 0 && billItem?.item?.stock == billItem.quantity) {
        const toast = await this.toastController.create({
          message: 'Quantity Exhausted',
          duration: 2000,
          color: 'dark',
        });
        await toast.present();
        return;
      }
  
      // (this.isPurchase && this.negativeStockLock) for purchase case it will select item if stock is negative for 2.11 setting (Negative stock lock);
      // (this.isEstimate && this.negativeStockLock) for estimate case it will select item if stock is negative for 2.11 setting (Negative stock lock);
      // !this.negativeStockLock if 2.11 setting is disable
      // (this.negativeStockLock && billItem?.item?.stock>0) check 2.11 setting enable and item stock is greater than zero
      // (this.iSetOutOfStockHideStatus && billItem?.item?.stock>0) if 2.27 setting enable and item stock is greater than zero
      if(!this.negativeStockLock || (this.negativeStockLock && billItem?.item?.stock>0) || (this.iSetOutOfStockHideStatus && billItem?.item?.stock>0) || ((this.isPurchase || this.isPurchaseReturn) && this.negativeStockLock) || (this.isEstimate && this.negativeStockLock)) {
        billItem.quantity += customQty || 1;
        if(billItem.quantity == 1 && this.party?._localUUID && this.iSetItemPriceHistoryStatus && !this.isPurchase && !this.isPurchaseReturn) {
          let priceMap = await this.allDataService.partyItemPriceMapService.getByPartyUUIDItemUUID(this.party?._localUUID,billItem?.item?._localUUID);
          if(priceMap?._localUUID && priceMap?.sellPrice) {
            billItem.price = priceMap.sellPrice;
          }
        }
        this.completeList?.forEach(x => {
          if (billItem?.item?._localUUID === x?.item?._localUUID) {
            x.quantity = billItem.quantity;
            let index = this.barcodeItemSequenceUUIDArr?.findIndex(uuid => billItem?.item?._localUUID===uuid);
            if(index != -1) {
              this.barcodeItemSequenceUUIDArr.splice(index,1);
            }
            this.barcodeItemSequenceUUIDArr.unshift(billItem?.item?._localUUID);
          }
        });
        this.updateCartEmit();
      }
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantListComponent:increaseQty", error)
    }
  }

  decreaseQty(billItem: BillItem) {
    try {
      billItem.quantity -= 1;
      this.completeList?.forEach(x => {
        if (billItem?.item?._localUUID === x?.item?._localUUID) {
          x.quantity = billItem?.quantity;
          let index = this.barcodeItemSequenceUUIDArr?.findIndex(uuid => billItem?.item?._localUUID===uuid);
          if(index != -1) {
            this.barcodeItemSequenceUUIDArr.splice(index,1);
          }
          this.barcodeItemSequenceUUIDArr.unshift(billItem?.item?._localUUID);
        }
      });
      this.updateCartEmit();
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantListComponent:decreaseQty", error)
    }
  }

  replaceBillItem(billItem: BillItem) {
    try {
      let index = this.completeList.findIndex(x => billItem?.item?._localUUID == x?.item?._localUUID);
      if (index != -1) {
        this.completeList.splice(index, 1, billItem);
      }
      index = this.viewFilteredList.findIndex(x => billItem?.item?._localUUID == x?.item?._localUUID);
      if (index != -1) {
        this.viewFilteredList.splice(index, 1, billItem);
      }
      this.updateCartEmit();
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantListComponent:replaceBillItem", error)
    }
  }

  reset() {
    try {
      this.ngOnDestroy();
      this.cartItems = [];
      this.loadView();
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantListComponent:reset", error)
    }
  }

  

  async onBarcodeSearchEnter(barcode: string) {
    try {
      barcode = barcode?.trim();
      let index = this.completeList.findIndex(billItem => billItem?.item?.barcode==barcode);
      if(index==-1){
        index = this.completeList.findIndex(billItem => billItem?.item?.barcode2==barcode);
      }
      if(index==-1){
        index = this.completeList.findIndex(billItem => billItem?.item?.barcode3==barcode);
      }
      if(index==-1){
        index = this.completeList.findIndex(billItem => billItem?.item?.barcode4==barcode);
      }
      if(index==-1){
        index = this.completeList.findIndex(billItem => billItem?.item?.barcode5==barcode);
      }
      if(index != -1) {
        this.increaseQty(this.completeList[index]);
      } else if (index == -1) {
        let item = await this.allDataService.itemService.getMasterItem(barcode);
        if(item?._localUUID) {
          let billItem = this.itemToBillItem(item);
          this.completeList.unshift(billItem);
          const toast = await this.toastController.create({
            header: `${item.itemName} added for barcode ${barcode} from Global Database`,
            duration: 5000,
            position: 'top',
            mode: 'ios',
            color: 'success',
          });
          await toast.present();
          this.increaseQty(billItem);
        }
      }
      return true;
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantListComponent:onBarcodeSearchEnter", error)
      return false;
    }
  }

  getCardColSize() {
    try {
      if(this.iSetItemSelectorColumns == 4) {
        return 3;
      }else if(this.iSetItemSelectorColumns == 3) {
        return 4;
      }else if(this.iSetItemSelectorColumns == 2) {
        return 6;
      }else {
        return 3;
      }
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantListComponent:getCardColSize", error)
    }
  }

  async loadImages(){
    try {
      let getImage = (images:string[]) => {
        let image = null;
        for(let i = 0; i < images?.length; i++) {
          image = this.allImages?.find(x => x?._localUUID == images[i]);
          if(image) {
            return image;
          }
        }
        return null;
      }
      for(let i = 0; i < this.completeList?.length; i++) {
        let item = this.completeList[i]?.item;
        if(item?.images?.length){
          let image = getImage(item?.images);
          let base64 = await this.imageBase64Service.getBase64FromDBorServerWithoutSave(image);
          if(base64 && !base64.includes('undefined') && image) {
            this.imagesBase64[item?._localUUID] = base64;
          }
        }
      } 
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantListComponent:loadImages", error)
    }
  }

  getItemImageSrc(localUUID:string){
    try {
      if(this.imagesBase64[localUUID]) {
        return this.imagesBase64[localUUID];
      }
      return "../../../assets/images/shopping.png" 
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantListComponent:getItemImageSrc", error)
    }
  }

  /**
   * 
   * @param localUUID : provide selected item _localUUID
   * @description : updated quantity to zero to billItem for selected cancel item 
   */
  clearSelectedItem(localUUID: string) {
    try {
      let index = this.completeList.findIndex(billItem=> billItem?.item?._localUUID===localUUID);
      this.completeList[index].quantity = 0;
      this.updateCartEmit();
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantListComponent:clearSelectedItem", error)
    }
  }
  // -------------------------------
}
