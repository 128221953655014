<ion-grid>

  <form [formGroup]="form" class="form-custom-css">

    <ion-row class="party-form-col-reverse">

      <!-- Item Name -->

      <ion-col size-md="8" size-xs="12">
        <ion-button fill="clear" class="btn-title btn-txt-black">
          Item Name <span class="span-mandatory">*</span>
        </ion-button>
        <ion-input 
          appColorFormValidation 
          controlName="noSpaceAtStart" 
          inputDesign="box" 
          #itemNameEle 
          placeholder="Example: Samosa" 
          formControlName="itemName"
          (ionBlur)="onItemNameChange()">
        </ion-input>
      </ion-col>

      <!-- -------------------------------------------------------------------------------- -->



      <!-- Item Type Product/Service -->

      <ion-col size-md="4" size-xs="12">
        <ion-button fill="clear" class="btn-title btn-txt-black">
          Type
        </ion-button>
        <ion-segment mode="ios" formControlName="type">
          <ion-segment-button value="product">
            <ion-label>Product</ion-label>
          </ion-segment-button>
          <ion-segment-button value="service">
            <ion-label>Service</ion-label>
          </ion-segment-button>
        </ion-segment>

      </ion-col>

      <!-- -------------------------------------------------------------------------------- -->

    </ion-row>

    <ion-row>

      <!-- Sell Price -->

      <ion-col size-md="4" size-xl="2" size-xs="12">
        <ion-button fill="clear" class="btn-title btn-txt-black">
          Sell Price <span class="span-mandatory">*</span>
        </ion-button>
        <ion-input placeholder="Example: ₹25.00" type="number" formControlName="sellPrice"></ion-input>
      </ion-col>

      <!-- -------------------------------------------------------------------------------- -->

      <!-- Primary Unit -->

      <ion-col size-md="4" size-xl="2" size-xs="12">
        <ion-button fill="clear" class="btn-title btn-txt-black">
          Primary Unit
        </ion-button>
        <ion-select [interfaceOptions]="{header: 'Primary Unit'}" class="new-ion-select" interface="alert" placeholder="Select Unit" formControlName="primaryUnit">
          <ion-select-option *ngFor="let unit of unitsOptions|keyvalue : returnZero" [value]="unit.key">
            {{ unit.value }}
          </ion-select-option>
        </ion-select>
      </ion-col>

      <!-- -------------------------------------------------------------------------------- -->

      <!-- Add Custom Unit : Primary -->

      <ion-col size-md="4" size-xl="2" size-xs="12">
        <ion-button fill="clear" class="btn-title btn-txt-black">
          Add Custom Unit
        </ion-button>
        <ion-input #primaryCustomUnit class="add-custom-unit " placeholder="Example: Grams"></ion-input>
        <ion-button class="inp-insider-btn" [disabled]="!primaryCustomUnit?.value" size="small" color="primary"
          (click)="addCustomUnit('primaryCustomUnit','primaryUnit')">Add</ion-button>
      </ion-col>

      <!-- -------------------------------------------------------------------------------- -->

      <!-- Category -->

      <ion-col class="form-custom-col left-devider" size-xl="2.5" size-md="6" size-xs="12">
        <ion-button fill="clear" class="btn-title btn-txt-black">
          Category
        </ion-button>
        <ion-select 
          [disabled]="!categories?.length"
          [interfaceOptions]="{header: 'Category'}" 
          class="new-ion-select" 
          interface="alert" 
          placeholder="Select Category" 
          formControlName="category">
          <ion-select-option *ngFor="let category of categories" [value]="category">
            {{ category }}
          </ion-select-option>
        </ion-select>
      </ion-col>

      <!-- -------------------------------------------------------------------------------- -->

      <!-- Add Custom Category -->

      <ion-col size-xl="3.5" size-md="6" size-xs="12">
        <ion-button fill="clear" class="btn-title btn-txt-black">
          Add Custom Category
        </ion-button>
        <ion-input #categoryEle class="add-custom-unit " placeholder="Example: Vegetarian"
          [disabled]="!isCreateItemCategoryPermission"></ion-input>
        <ion-button class="inp-insider-btn" size="small" color="primary" [disabled]="!categoryEle?.value"
          (click)="addCategory()">Add</ion-button>
      </ion-col>

      <!-- -------------------------------------------------------------------------------- -->

    </ion-row>

    <ion-row class="exclude-form-custom-css">
      <ion-col size-lg="12" size-xs="12">
        <ion-button fill="outline" class="dashed-border" (click)="toggleShowAllFields()">
          {{ showAllFields ? 'Less Info' : 'More Info' }}
          <ion-icon *ngIf="!showAllFields" name="chevron-down-outline"></ion-icon>
          <ion-icon *ngIf="showAllFields" name="chevron-up-outline"></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="showAllFields">

      <!-- Purchase Price -->

      <ion-col size-xl="2" size-md="4" size-xs="12">
        <ion-button fill="clear" class="btn-title btn-txt-black">
          Purchase Price
        </ion-button>
        <ion-input placeholder="Example: ₹22.00" type="number" formControlName="purchasePrice"></ion-input>
      </ion-col>

      <!-- -------------------------------------------------------------------------------- -->

      <!-- MRP -->

      <ion-col size-xl="2" size-md="4" size-xs="12">
        <ion-button fill="clear" class="btn-title btn-txt-black">
          MRP
        </ion-button>
        <ion-input placeholder="Example: ₹25.00" type="number" formControlName="mrp"></ion-input>
      </ion-col>

      <!-- -------------------------------------------------------------------------------- -->

      <!-- Online Delivery Sell Price -->

      <ion-col size-xl="2" size-md="4" size-xs="12">
        <ion-button fill="clear" class="btn-title btn-txt-black">
          Online Delivery Sell Price
        </ion-button>
        <ion-input placeholder="Example: ₹28.00" type="number" formControlName="onlineDeliverySellPrice"></ion-input>
      </ion-col>

      <!-- -------------------------------------------------------------------------------- -->

      <!-- Online Sell Price -->

      <ion-col size-xl="2" size-md="4" size-xs="12">
        <ion-button fill="clear" class="btn-title btn-txt-black">
          Online Sell Price
        </ion-button>
        <ion-input placeholder="Example: ₹22.50" type="number" formControlName="onlineSellPrice"></ion-input>
      </ion-col>

      <!-- -------------------------------------------------------------------------------- -->

      <!-- AC Sell Price -->

      <ion-col size-xl="2" size-md="4" size-xs="12">
        <ion-button fill="clear" class="btn-title btn-txt-black">
          AC Sell Price
        </ion-button>
        <ion-input placeholder="Example: ₹25.00" type="number" formControlName="acSellPrice"></ion-input>
      </ion-col>

      <!-- -------------------------------------------------------------------------------- -->

      <!-- Non AC Sell Price -->

      <ion-col size-xl="2" size-md="4" size-xs="12">
        <ion-button fill="clear" class="btn-title btn-txt-black">
          Non AC Sell Price
        </ion-button>
        <ion-input placeholder="Example: ₹25.00" type="number" formControlName="nonAcSellPrice"></ion-input>
      </ion-col>

      <!-- -------------------------------------------------------------------------------- -->


    </ion-row>

    <ion-row *ngIf="showAllFields">

      <!-- Tax Percentage -->

      <ion-col size-md="2" size-xs="12">
        <ion-button fill="clear" class="btn-title btn-txt-black">
          Tax Percentage
        </ion-button>
        <ion-select [interfaceOptions]="{header: 'Tax Percentage'}" #sbTax class="new-ion-select" interface="alert" placeholder="Select Tax"
          (ionChange)="onTaxSelect($event)" [value]="selectedTaxPercentagekey">
          <ion-select-option *ngFor="let unit of taxTypeOptions|keyvalue : returnZero" [value]="unit.key">
            {{ unit.key }}
          </ion-select-option>
        </ion-select>
      </ion-col>

      <!-- -------------------------------------------------------------------------------- -->

      <!-- Cess Percentage -->

      <ion-col size-lg="2" size-xs="12" *ngIf="selectedProfile?.itSetEnableCessTax">
        <ion-button fill="clear" class="btn-title btn-txt-black">
          Cess Percentage
        </ion-button>
        <ion-input placeholder="Example: 5" type="number" formControlName="cessPercentage"></ion-input>
      </ion-col>

      <!-- -------------------------------------------------------------------------------- -->

      <!-- Tax Inclusive/Exclusive -->

      <ion-col size-lg="4" size-xs="12" *ngIf="selectedProfile?.itSetEnableTaxExclusiveSellPrice">
        <ion-button fill="clear" class="btn-title btn-txt-black">
          Tax (Inclusive | Exclusive)
        </ion-button>
        <ion-segment mode="ios" [value]="selectedSpIncTax" (ionChange)="onSpIncTaxChange($event)">
          <ion-segment-button value="true">
            <ion-label>Prices are with Tax</ion-label>
          </ion-segment-button>
          <ion-segment-button value="false">
            <ion-label>Prices are without Tax</ion-label>
          </ion-segment-button>
        </ion-segment>

      </ion-col>

      <!-- -------------------------------------------------------------------------------- -->

      <!-- Tax Inclusive/Exclusive -->

      <ion-col size-md="4" size-xs="12">
        <ion-button fill="clear" class="btn-title btn-txt-black">
          Actual Cost to Customer
        </ion-button>
        <ion-input readonly="true" *ngIf="!form?.value?.sellPrice && !form?.value?.taxPercentage"
          placeholder="Please Add Sell Price and Tax to Calculate Actual Cost" class="new-ion-input info-input no-drop">
        </ion-input>
        <ion-input readonly="true"
          *ngIf="form?.value?.sellPrice && form?.value?.taxPercentage && (form?.value?.spIncTax == false) && form?.value?.sellPrice && form?.value?.taxPercentage"
          value="₹{{form?.value?.sellPrice}} * ({{form?.value?.taxPercentage + +form?.value?.cessPercentage}}% tax) = ₹{{form?.value?.sellPrice * (100 + form?.value?.taxPercentage + form?.value?.cessPercentage) / 100}}{{form?.value?.primaryUnit ? ' / '+form?.value?.primaryUnit : ''}}"
          class="new-ion-input  info-input no-drop"></ion-input>
        <ion-input readonly="true"
          *ngIf="form?.value?.sellPrice && form?.value?.taxPercentage && (form?.value?.spIncTax == true) && form?.value?.sellPrice && form?.value?.taxPercentage"
          value="₹{{(form?.value?.sellPrice * 100) / (100 + form?.value?.taxPercentage + form?.value?.cessPercentage) | number : '1.2-2' }} * ({{form?.value?.taxPercentage + +form?.value?.cessPercentage}}% tax) = ₹{{form?.value?.sellPrice}}{{form?.value?.primaryUnit ? ' / '+form?.value?.primaryUnit : ''}}"
          class="new-ion-input  info-input no-drop"></ion-input>
      </ion-col>

      <!-- -------------------------------------------------------------------------------- -->

    </ion-row>

    <ion-row *ngIf="showAllFields">

      <!-- Brand Name -->

      <ion-col size-lg="6" size-xs="12">
        <ion-button fill="clear" class="btn-title btn-txt-black">
          Brand Name
        </ion-button>
        <ion-input #itemNameEle placeholder="Example: Nestle" formControlName="brandName"
          (ionBlur)="preventEmptyString('brandName')"></ion-input>
      </ion-col>

      <!-- -------------------------------------------------------------------------------- -->

      <!-- Expiry Date -->

      <ion-col size-lg="3" size-xs="12">
        <ion-button fill="clear" class="btn-title btn-txt-black">
          Expiry Date
        </ion-button>
        <ion-chip *ngIf="!form?.value?.expiryDate" class="new-ion-select new-ion-chip"
          (click)="openExpiryDatePicker()">Select
          Date</ion-chip>
        <ion-datetime-button *ngIf="form?.value?.expiryDate" class="new-ion-select" datetime="expiryDate"
          appRmTimeBtn></ion-datetime-button>
      </ion-col>

      <!-- -------------------------------------------------------------------------------- -->

      <!-- Wholesale Price -->

      <!-- <ion-col size-lg="3" size-xs="12" *ngIf="selectedProfile?.itSetEnableWholesaleRate">
        <ion-button fill="clear" class="btn-title btn-txt-black">
          Wholesale Price
        </ion-button>
        <ion-input placeholder="Example: ₹25.00" type="number" formControlName="wholesalePrice"></ion-input>
      </ion-col> -->

      <!-- -------------------------------------------------------------------------------- -->

      <!-- Minimum Cutoff Quantity -->

      <!-- <ion-col size-lg="3" size-xs="12" *ngIf="selectedProfile?.itSetEnableWholesaleRate">
        <ion-button fill="clear" class="btn-title btn-txt-black">
          Wholesale Minimum Cutoff Quantity
        </ion-button>
        <ion-input placeholder="Example: 5" type="number" formControlName="wholesaleMinCutoffQty"></ion-input>
      </ion-col> -->

      <!-- -------------------------------------------------------------------------------- -->

    </ion-row>

    <ion-row *ngIf="showAllFields">

      <!-- Item Code -->

      <ion-col size-lg="4" size-xs="12" *ngIf="selectedProfile?.itSetEnableItemCode">
        <ion-button fill="clear" class="btn-title btn-txt-black">
          Item Code
        </ion-button>
        <ion-input placeholder="Example: XYZ12345" formControlName="itemCode"></ion-input>
      </ion-col>

      <!-- -------------------------------------------------------------------------------- -->

      <!-- Barcode -->

      <ion-col size-md="4" size-xs="12" *ngFor="let barcode of multiBarcodeConfig|keyvalue;let i = index;">
        <ion-button fill="clear" class="btn-title btn-txt-black">
          Barcode {{i+1}}
        </ion-button>
        <ion-input #barcodeInputEle{{i+1}} id="barcodeInputEle{{i+1}}" placeholder="Example: 8901015231002" [formControlName]="barcode.key"
          [type]="selectedProfile?.iSetAlphaNumericBarcodeStatus ? 'text' : 'number'"></ion-input>
        <ion-button class="inp-insider-btn" size="small" color="primary"
          (click)="openBarcodeGeneratorModal(barcode.key)">Generate</ion-button>
      </ion-col>

      <!-- -------------------------------------------------------------------------------- -->

      <!-- HSN -->

      <ion-col size-md="4" size-xs="12">
        <ion-button fill="clear" class="btn-title btn-txt-black">
          HSN
        </ion-button>
        <ion-input placeholder="Example: 07031010" formControlName="hsn"></ion-input>
      </ion-col>

      <!-- -------------------------------------------------------------------------------- -->

    </ion-row>

    <ion-row *ngIf="showAllFields">

      <!-- Description -->

      <!-- <ion-col size-lg="12" size-xs="12" *ngIf="selectedProfile?.itSetEnableItemDescription">
        <ion-button fill="clear" class="btn-title btn-txt-black">
          Description
        </ion-button>
        <ion-textarea
          class="new-ion-textarea "
          rows="3"
          placeholder="Example: A fried South Asian pastry with a savoury filling, including ingredients such as spiced potatoes, onions, peas, meat or fish."
          formControlName="description"
        ></ion-textarea>
      </ion-col> -->

      <!-- -------------------------------------------------------------------------------- -->

    </ion-row>

    <ion-row *ngIf="showAllFields">

      <!-- Low Stock Alert -->

      <ion-col size-lg="3" size-xs="12" *ngIf="selectedProfile?.itSetEnableLowStockAlert">
        <ion-button fill="clear" class="btn-title btn-txt-black">
          Low Stock Alert
        </ion-button>
        <ion-input placeholder="Example: 10" type="number" formControlName="minStock"></ion-input>
      </ion-col>

      <!-- -------------------------------------------------------------------------------- -->

      <!-- Storage Location -->

      <ion-col size-lg="5" size-xs="12" *ngIf="selectedProfile?.itSetEnableStotageLocation">
        <ion-button fill="clear" class="btn-title btn-txt-black">
          Storage Location
        </ion-button>
        <ion-input placeholder="Example: Rack 10, Shelf 4" formControlName="storageLocation"></ion-input>
      </ion-col>

      <!-- -------------------------------------------------------------------------------- -->

      <!-- Show Product in Online Dukkan -->

      <ion-col size-lg="4" size-xs="12" *ngIf="selectedProfile?.itSetEnableOnlineDukkanItem">
        <ion-button fill="clear" class="btn-title btn-txt-black">
          Show Product in Online Dukkan
        </ion-button>
        <ion-segment mode="ios" formControlName="onlineDukanItem" (ionChange)="onOnlineDukanItemChange($event)">
          <ion-segment-button value="true">
            <ion-label>Yes</ion-label>
          </ion-segment-button>
          <ion-segment-button value="false">
            <ion-label>No</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-col>

      <!-- -------------------------------------------------------------------------------- -->

    </ion-row>

    <ion-row *ngIf="showAllFields">

      <!-- Secondary Unit -->

      <ion-col size-lg="2" size-xs="12" *ngIf="selectedProfile?.itSetEnableBulkUnit">
        <ion-button fill="clear" class="btn-title btn-txt-black">
          Secondary Unit
        </ion-button>
        <ion-select [interfaceOptions]="{header: 'Secondary Unit'}" class="new-ion-select" interface="alert" placeholder="Select Unit" formControlName="secondaryUnit">
          <ion-select-option *ngFor="let unit of unitsOptions|keyvalue : returnZero" [value]="unit.key">
            {{ unit.value }}
          </ion-select-option>
        </ion-select>
      </ion-col>

      <!-- -------------------------------------------------------------------------------- -->

      <!-- Add Custom Unit : Secondary -->

      <ion-col size-lg="2" size-xs="12" *ngIf="selectedProfile?.itSetEnableBulkUnit">
        <ion-button fill="clear" class="btn-title btn-txt-black">
          Add Custom Unit
        </ion-button>
        <ion-input #secondaryCustomUnit class="new-ion-input" placeholder="Example: Grams"></ion-input>
        <ion-button class="inp-insider-btn" size="small" color="primary" [disabled]="!secondaryCustomUnit?.value"
          (click)="addCustomUnit('secondaryCustomUnit','secondaryUnit')">Add</ion-button>
      </ion-col>

      <!-- -------------------------------------------------------------------------------- -->

      <!-- Retail Sale Unit PER 1 Bulk Purchase Unit -->

      <ion-col size-lg="4" size-xs="12" *ngIf="form?.value?.secondaryUnit && form?.value?.secondaryUnit!='null' && selectedProfile?.itSetEnableBulkUnit">
        <ion-button fill="clear" class="btn-title btn-txt-black">
          Retail Sale Unit PER 1 Bulk Purchase Unit<span class="span-mandatory">*</span>
        </ion-button>
        <ion-input placeholder="" type="number" formControlName="convertRatio"
          (ionChange)="calculateBulkPurchaseRatio('CONVERT_RATIO',$event)"
          (ionFocus)="currentlyActiveRatioInput('CONVERT_RATIO')"></ion-input>
      </ion-col>

      <!-- -------------------------------------------------------------------------------- -->

      <!-- Bulk Purchase Unit PER 1 Retail Sale Unit -->

      <ion-col size-lg="4" size-xs="12" *ngIf="form?.value?.secondaryUnit && form?.value?.secondaryUnit!='null' && selectedProfile?.itSetEnableBulkUnit">
        <ion-button fill="clear" class="btn-title btn-txt-black">
          Bulk Purchase Unit PER 1 Retail Sale Unit<span class="span-mandatory">*</span>
        </ion-button>
        <ion-input placeholder="" type="number" formControlName="convertRatioR"
          (ionChange)="calculateBulkPurchaseRatio('CONVERT_RATIO_R',$event)"
          (ionFocus)="currentlyActiveRatioInput('CONVERT_RATIO_R')"></ion-input>
      </ion-col>

      <!-- -------------------------------------------------------------------------------- -->

    </ion-row>

    <ion-row *ngIf="showAllFields && selectedProfile?.itSetEnableImages && imagesBase64?.length">

      <!-- Add Image -->

      <ion-col size-lg="12" size-xs="12">
        <ion-button fill="clear" class="btn-title btn-txt-black">
          Images
        </ion-button>
      </ion-col>
      <ion-col size-lg="2" size-xs="12" *ngFor="let imageData of imagesBase64">
        <ion-item class="item-image" lines="none">
          <img class="img-logo" [src]=" imageData?.base64 || imageData?.imageUrl">
          <ion-icon class="img-remove-icon" color="danger" name="close-circle"
            (click)="removeImage(imageData?.imageUUID)"></ion-icon>
        </ion-item>
      </ion-col>

      <!-- -------------------------------------------------------------------------------- -->

    </ion-row>

    <ion-row class="exclude-form-custom-css" *ngIf="showAllFields && selectedProfile?.itSetEnableImages">
      <ion-col size-lg="2" size-xs="12">
        <ion-button expand="full" (click)="openImageCropper()"> Add Image </ion-button>
      </ion-col>
    </ion-row>

    <ion-row class="exclude-form-custom-css" *ngIf="showAllFields && selectedProfile?.itSetEnableIngredient">
      <ion-col size-lg="3" size-xs="12">
        <ion-button expand="full" (click)="openIngredientSelector()"> Add/Remove Ingredients </ion-button>
      </ion-col>
    </ion-row>

    <ng-container
      *ngIf="showAllFields && form?.value?.itemIngredients?.length && selectedProfile?.itSetEnableIngredient">
      <ion-row class="item-form-custom-row" *ngFor="let itemIngredients of form?.value?.itemIngredients">

        <ion-col size-lg="12" size-xs="5">
          <ion-button fill="clear" class="btn-item-name">
            {{ shortStr(itemIngredients?.ingredient?.name,85) }}
          </ion-button>
        </ion-col>

        <ion-col size-lg="9" size-xs="4">
          <ion-button fill="clear" color="primary" class="btn-item-name txt-transform-normal category-title">
            Tag: {{ itemIngredients?.ingredient?.category || 'N/A' }}
          </ion-button>
        </ion-col>

        <ion-col size-lg="3" size-xs="3">
          <ion-button color="success" fill="clear" class="btn-item-name txt-transform-normal flt-rght">
            Qty: {{ itemIngredients?.quantity }} {{ itemIngredients?.ingredient?.unit || '' }}
          </ion-button>
        </ion-col>

      </ion-row>
    </ng-container>

    <ion-row class="exclude-form-custom-css">
      <ion-col size-lg="12">
        <ion-button
          [ngStyle]="checkPlatform?.userAgentData?.platform == 'Android' ? {'width': '100%', 'right': '0'} : {}"
          class="btn-save-item" expand="full" [disabled]="form?.invalid || isBlockSave" (click)="presentSameItemNameConfirm()">Save
          Item</ion-button>
      </ion-col>
    </ion-row>

  </form>


</ion-grid>

<app-item-settings #itemSettingsEle (onSave)="onSettingSave($event)"></app-item-settings>

<app-image-cropper *ngIf="isOpenImageCropper" (imageSaved)="onImageSaved($event)"
  (modalClosed)="openImageCropper($event ? false : true)">
</app-image-cropper>

<app-barcode-generator #barcodeGeneratorEle [item]="form?.value" [companyName]="selectedProfile?.legalName"
  [barcode]="form?.value?.[currentBarcodekey]" (barcodeChange)="populateBarcode($event)">
</app-barcode-generator>

<ion-modal class="modal-expiry-date" [isOpen]="isExpiryDateModalOpen" [keepContentsMounted]="keepDateContentsMounted"
  (willDismiss)="closeDOBPicker($event)">
  <ng-template>
    <ion-datetime appYearDefaultBtn id="expiryDate" [showDefaultButtons]="true" presentation="date" [value]="dpExpiryDateValue"
      (ionChange)="onExpiryDateSelect($event)" (ionCancel)="onCancelExpiryDate($event)" [max]="maxDate">
      <span slot="title">Select a Expiry Date</span>
    </ion-datetime>
  </ng-template>
</ion-modal>

<app-ingredient-selector #ingredientSelector [cartIngredients]="form?.value?.itemIngredients"
  (saveTrigger)="saveIngredients($event)">
</app-ingredient-selector>
