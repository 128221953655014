<ion-row class="padding-none custom-row">
  <ion-col size="6" class="txt-left remove-padding-top-bottom">
    <ion-row>
      <ion-col size="12" class="add-bottom-padding">
        <ion-button fill="clear" class="btn-txt-black padding-start-none">
          {{ purchase?.party?.name }}
        </ion-button>
      </ion-col>
      <ion-col size="12" (click)="billView(purchase)">
        <ion-label color="primary">
          {{ (purchase?.totalAmount || 0) | currency:'INR' }}
        </ion-label>
      </ion-col>
      <ion-col size="12" *ngIf="!purchase?.deletedStamp">
        <ion-button fill="outline" class="outline-border font-12px ion-button-height ion-button-bottom-padding"
          color="success" *ngIf="getBalanceAmount(purchase?.moneyOuts,purchase?.totalAmount)"
          (click)="addPayment(purchase)">
          + Add Payment
        </ion-button>
        <ion-button fill="outline"
          class="outline-border font-12px add-margin-left ion-button-height ion-button-bottom-padding" color="danger"
          *ngIf="getBalanceAmount(purchase?.moneyOuts,purchase?.totalAmount)">
          {{ getBalanceAmount(purchase?.moneyOuts,purchase?.totalAmount) | currency:'INR' }}
          Balance
        </ion-button>
        <ion-button fill="outline" class="outline-border font-12px ion-button-bottom-padding ion-button-height"
          color="success" *ngIf="getBalanceAmount(purchase?.moneyOuts,purchase?.totalAmount)===0">
          Paid
        </ion-button>
        <ion-text color="danger" class="text-extra-money-in font-12px"
          *ngIf="extraMoneyInAmount(purchase?.moneyOuts,purchase?.totalAmount)">
          Extra Money Out Amount is <b>{{ extraMoneyInAmount(purchase?.moneyOuts,purchase?.totalAmount) | currency:
            'INR' }}</b>
        </ion-text>
        <ion-button *ngIf="purchase?.linkedPurchaseReturnUUID" (click)="viewPurchaseReturn(purchase)" fill="outline" color="primary"
          class="font-12px ion-button-height add-margin-left ion-button-bottom-padding outline-border">
          View Purchase Return
        </ion-button>
      </ion-col>
      <ion-col size="6" *ngIf="!purchase?.deletedStamp && purchase?.moneyOuts?.length && isRecentTransactionList">
        <ion-button [fill]="purchase?.moneyOuts[0]?.paymentMode=='bank' ? 'solid' : 'outline'"
          class="outline-border font-12px ion-button-height" color="primary" (click)="setPaymentMode('bank')">
          UPI/BANK
        </ion-button>
        <ion-button [fill]="purchase?.moneyOuts[0]?.paymentMode=='cash' ? 'solid' : 'outline'"
          class="outline-border font-12px ion-button-height" color="primary" (click)="setPaymentMode('cash')">
          CASH
        </ion-button>
        <ion-button [fill]="purchase?.moneyOuts[0]?.paymentMode=='cheque' ? 'solid' : 'outline'"
          class="outline-border font-12px ion-button-height" color="primary" (click)="setPaymentMode('cheque')">
          CHEQUE
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-col>
  <ion-col size="6" class="txt-right remove-padding-top-bottom">
    <ion-row>
      <ion-col size="12" (click)="billView(purchase)">
        <ion-button fill="clear" class="btn-txt-black padding-end-none">
          {{ purchase?.billNo }} | {{ purchase?.billDateStamp | date: 'dd/MM/yy' }}
        </ion-button>
      </ion-col>
      <ion-col size="12" *ngIf="!purchase?.deletedStamp">
        <ion-button *ngIf="!purchase?.linkedPurchaseReturnUUID" class="font-12px add-margin-right outline-border ion-button-height ion-button-bottom-padding" title="Purchase Return" color="tertiary" fill="outline" (click)="createPurchaseReturn(purchase)">
          Purchase Return
        </ion-button>
        <ion-button title="Print" color="tertiary" fill="clear" (click)="billView(purchase)">
          <ion-icon name="print"></ion-icon>
        </ion-button>
        <ion-button title="Edit" color="primary" fill="clear" (click)="edit(purchase)">
          <ion-icon name="create"></ion-icon>
        </ion-button>
        <ion-button title="Delete" color="danger" fill="clear" (click)="delete(purchase)">
          <ion-icon name="trash"></ion-icon>
        </ion-button>
      </ion-col>
      <ion-col size-md="11" size-xs="10">
        <ion-label class="label-note">
          {{ (purchase?.userUpdatedStamp || purchase?.updatedStamp) > purchase?.createdStamp ? 'Edited by ' : 'Created by ' }} 
          {{ (purchase?.userUpdatedStamp || purchase?.updatedStamp) > purchase?.createdStamp ? purchase?.lastModifiedByName || purchase?.lastModifiedBy :  purchase?.createdByName || purchase?.createdBy }}
          on
          {{ (purchase?.userUpdatedStamp || purchase?.updatedStamp) | date: 'dd/MM/yy h:mm:ss a' }}
        </ion-label>
      </ion-col>
      <ion-col size-md="1" size-xs="2" (click)="billView(purchase)">
        <ion-col size="auto" offset="1">
          <div *ngIf="purchase?.syncStamp > (purchase?.userUpdatedStamp || purchase?.updatedStamp); then thenBlock else elseBlock"></div>
          <ng-template #thenBlock>
            <ion-button class="btn-popover" title="Data Uploaded" fill="clear">
              <ion-icon 
                name="checkmark-done-sharp" 
                size="small"
                color="success">
              </ion-icon>
            </ion-button>
          </ng-template>
          <ng-template #elseBlock>
            <ion-button class="btn-popover" title="Warning" fill="clear">
              <ion-icon 
                name="warning-sharp" 
                color="warning" 
                size="small">
              </ion-icon>
            </ion-button>
          </ng-template>
        </ion-col>
      </ion-col>
    </ion-row>
  </ion-col>
</ion-row>

<app-pin-verification (verifyTransactionPin)="verifyTransactionPIN($event)" #pinVerificationElement></app-pin-verification>