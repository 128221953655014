<ion-header>
  <ion-toolbar class="{{ getHeaderColorClass() }}">
    <ion-title>Barcode Print</ion-title>
    <ion-chip slot="end" color="primary" (click)="generateBarcodeForAllItems()">Generate Barcode for All Items</ion-chip>
  </ion-toolbar>
</ion-header>

<ion-content>

  <ion-grid>

    <ion-row>
      <ion-col size="12">

        <div class="barcode-printer-label-preview">
          <div class="barcode-sample-holder ebp-canvas-tile sample">
            <div class="text-top">
              <span *ngIf="toggleSettings?.brpShowBrandName?.value">{{ defaultCard?.brandName }}<br></span>
              <span *ngIf="toggleSettings?.brpShowItemName?.value">{{ defaultCard?.itemName }}<br></span>
              <span *ngIf="toggleSettings?.brpShowMrp?.value">MRP: {{ defaultCard?.mrp }}</span>&nbsp;
              <span *ngIf="toggleSettings?.brpShowSalePrice?.value">SP: {{ defaultCard?.salePrice }}<br></span>
              <span *ngIf="toggleSettings?.brpShowItemCode?.value">{{ defaultCard?.itemCode }}<br></span>
            </div>

            <div class="svg-holder">
              <div class="svg"></div>
            </div>
            <div class="text"><span class="white-bg" *ngIf="toggleSettings?.brpShowBarcode?.value">12345678</span></div>
          </div>
        </div>

      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="6">

        <ion-row class="row-setting" *ngFor="let setting of toggleSettings|keyvalue: returnZero">
          <ion-col class="padding-bottom-none" size="10">
            <span>{{ setting?.value?.title }}</span>
            <ion-input
              *ngIf="setting?.key==='brpShowBrandName'"
              class="inp-brand-name"
              [value]="brandName"
              (ionChange)="setBrandName($event)"
            >
            </ion-input>
            <br>
            <span class="grey-color">{{ setting?.value?.value ? 'Enabled' : 'Disabled' }}</span>
          </ion-col>
          <ion-col class="padding-bottom-none txt-right" size="2">
            <span>
              <ion-toggle color="tertiary" slot="end" [checked]="setting?.value?.value" (ionChange)="onToggleSettingChange(setting?.key,$event)">
              </ion-toggle>
            </span>
          </ion-col>
        </ion-row>

      </ion-col>
      <ion-col size="3">

        <ng-container *ngFor="let setting of stickerSettings|keyvalue: returnZero;let i = index">
          <ion-row class="row-setting" *ngIf="i < 4">
            <ion-col class="padding-bottom-none" size="12">
              <span>{{ setting?.value?.title }}</span>
              <ion-input
                class="inp-sticker"
                type="number"
                [value]="setting?.value?.value"
                (ionChange)="onStickerSettingChange(setting?.key,$event)"
              >
              </ion-input>
            </ion-col>
          </ion-row>
        </ng-container>
      </ion-col>


      <ion-col size="3">
        <ng-container *ngFor="let setting of stickerSettings|keyvalue: returnZero;let i = index">
          <ion-row class="row-setting" *ngIf="i >= 4">
            <ion-col class="padding-bottom-none" size="12">
              <span>{{ setting?.value?.title }}</span>
              <ion-input
                class="inp-sticker"
                type="number"
                [value]="setting?.value?.value"
                (ionChange)="onStickerSettingChange(setting?.key,$event)"
              >
              </ion-input>
            </ion-col>
          </ion-row>
        </ng-container>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="12">
        <ion-button (click)="openItemSelectorModal()">Next</ion-button>
      </ion-col>
    </ion-row>

  </ion-grid>


  <ion-modal class="modal-item-selector" [isOpen]="isModalOpen" [backdropDismiss]="false" animated="false">
    <ng-template>
      <ion-header>
        <ion-toolbar>
          <ion-chip slot="start" color="tertiary" class="btn-back" (click)="openItemSelectorModal(false)">
            <ion-icon class="ion-icon-36px" name="arrow-back-sharp"></ion-icon>
          </ion-chip>
          <ion-searchbar
            #searchBar
            placeholder="Search by item name, item code..."
            slot="start"
            [debounce]="100"
            (ionChange)="onFilterChange($event['detail'].value)"
          >
          </ion-searchbar>
          <ion-chip slot="end" color="primary" (click)="generateBarcodeForAllItems()">Generate Barcode for All Items</ion-chip>
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-padding">

        <ion-grid>

          <ion-row>

            <ion-col size="3">

              <ion-row>
                <ion-col class="col-categories scroll-y" size="12">
                  <ion-item
                    lines="none"
                    [class.active-item]="!appliedCategory"
                    (click)="onCategoryChange('')"
                  >
                    All
                  </ion-item>
                  <ion-item
                    lines="none"
                    *ngFor="let category of categories"
                    [class.active-item]="category === appliedCategory"
                    [class.cart-item-category]="cartItemsCategories?.includes(category)"
                    (click)="onCategoryChange(category)"
                  >
                    {{ category }}
                  </ion-item>
                </ion-col>
              </ion-row>

            </ion-col>

            <ion-col size="9">
              <ion-row>
                <ion-col class="col-barcoded-item" size="4" [ngClass]="{'active': item['quantity']>0}" *ngFor="let item of viewFilteredList">
                  <ion-row>
                    <ion-col size="12" (click)="increaseQty(item?._localUUID)">
                      <ion-button fill="clear" class="btn-item-name">
                        {{ shortStr(item?.itemName,30) }}
                      </ion-button>
                    </ion-col>
                    <ion-col size="2" (click)="increaseQty(item?._localUUID)">
                      <ion-button fill="clear" color="primary" class="btn-qty">Add</ion-button>
                    </ion-col>
                    <ion-col size="7">
                      <ion-button fill="clear" class="btn-item-name" *ngIf="item['quantity']>0">
                        Qty: <ion-input type="number" [value]="item['quantity']" (ionBlur)="onInpQtyChange(item?._localUUID,$event)"></ion-input>
                      </ion-button>
                    </ion-col>
                    <ion-col size="3">
                      <ion-button fill="clear" color="danger" class="btn-qty" [disabled]="item['quantity']<0" (click)="decreaseQty(item?._localUUID)">Reduce</ion-button>
                    </ion-col>
                  </ion-row>
                </ion-col>
              </ion-row>
            </ion-col>

          </ion-row>

        </ion-grid>

        <ion-infinite-scroll
          threshold="10px"
          *ngIf="viewFilteredList?.length"
          (ionInfinite)="loadMoreListData($event)">
          <ion-infinite-scroll-content
            loadingSpinner="bubbles"
            loadingText="Loading more data...">
          </ion-infinite-scroll-content>
        </ion-infinite-scroll>

      </ion-content>
      <ion-footer>
        <ion-toolbar>
          <ion-button
            class="flt-rght btn-save"
            color="primary"
            (click)="saveAndProceed()"
          >
            Print Barcode
          </ion-button>
        </ion-toolbar>
      </ion-footer>
    </ng-template>
  </ion-modal>

  <app-barcode-generator  #barcodeGeneratorEle></app-barcode-generator>

  <div class="ebp-canvas-area">
    <div class="bp-page" style="margin: auto;background: #fff;box-shadow: 0 0 5px rgba(0,0,0,0.5);position: relative;"></div>
  </div>

</ion-content>
