import { Component, OnInit } from '@angular/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Utility } from '../../utils/utility';
import { Router } from '@angular/router';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from '../../app.module';
import { environment } from '../../../environments/environment';
import { EventService } from '../../services/event.service';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-idbx-error',
  templateUrl: './idbx-error.page.html',
  styleUrls: ['./idbx-error.page.scss'],
})
export class IdbxErrorPage implements OnInit {

  progress: number = 0;
  progressText: string = '';

  constructor(
    private router: Router,
    private eventService: EventService,
  ) { }

  ngOnInit() { }

  async ionViewWillEnter() {
    try {
      this.eventService.setShowPremiumPopup(false);
      let status = localStorage.getItem('idb-error-state');
      if (status == 'reload') {
        this.progress = 0.8;
        this.progressText = "80%";
        localStorage.setItem('idb-error-state','reset');
        await Utility.wait(5000);
        this.progress = 1;
        this.progressText = "100%";
        setTimeout(() => {
          this.router.navigate(['fetch-initial-data', {
            queryParams: {
              isReload: true
            }
          }]);
        }, 1000);
      }else {
        setTimeout(() => {
          window?.location?.reload();
        }, 1000 * 60 * 2);
        try {
          const DBOpenRequest = window?.indexedDB?.open(environment.indexedDbName, environment.indexedDbVersion);
  
          DBOpenRequest.onerror = (event) => {
            console.log(event)
          };
  
          DBOpenRequest.onsuccess = (event) => {
            this.progress = 0.2;
            this.progressText = "20%";
  
            console.log(event)
            let db = DBOpenRequest.result;
            db.close();
            let dbRef = window?.indexedDB?.deleteDatabase(environment.indexedDbName);
  
            dbRef.onerror = (event) => {
              console.error("Error deleting database.");
            };
  
            dbRef.onsuccess = async (event) => {
              this.progress = 0.5;
              this.progressText = "50%";
  
              console.log("Database deleted successfully");
              localStorage.setItem('idb-error-state','reload');
              await Utility.wait(5000)
  
              this.progress = 0.8;
              this.progressText = "80%";
  
              window?.location?.reload();
            };
          };
        } catch (error) {
          SentryUtilites.setLog("IdbxErrorPage:ionViewWillEnter", error)
          console.log(error)
        }
      }
    } catch (error) {
      SentryUtilites.setLog("IdbxErrorPage:ionViewWillEnter", error)
    }
  }

  whatsappSupport() {
    window.open(
      `https://api.whatsapp.com/send?phone=9167134134&text=${encodeURIComponent(
        'Hi, I need help with Ezo'
      )}`,
      '_blank'
    );
  }

}
