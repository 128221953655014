import { Component, HostListener, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { IonModal } from '@ionic/angular';
import { EventService } from '../../services/event.service';
import { Router } from '@angular/router';
import { distinctUntilChanged } from 'rxjs/operators';
import { AllDataService } from '../../services/all-data.service';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-non-premium-modal',
  templateUrl: './non-premium-modal.component.html',
  styleUrls: ['./non-premium-modal.component.scss'],
})
export class NonPremiumModalComponent implements OnInit {

  @ViewChild('modalEle') modalEle: IonModal;

  @Input() positionLeft: boolean = false;

  showMax3Creditmsg: boolean = false;

  // Remove for max 3 sale credit
  isBackdropDismiss: boolean = false;

  constructor(
    private eventService: EventService,
    private router: Router,
    private allDataService: AllDataService,
  ) { }

  ngOnInit() {
    try {
      this.isBackdropDismiss = this.positionLeft;
      this.eventService.showMax3Creditmsg.pipe(distinctUntilChanged()).subscribe(showMax3Creditmsg => {
        this.showMax3Creditmsg = showMax3Creditmsg;
      })
    } catch (error) {
      SentryUtilites.setLog("NonPremiumModalComponent:ngOnInit", error)
    }
  }

  @HostListener('window:resize', ['$event'])
  OnResize() {
    try {
      setTimeout(()=> {
        this.isBackdropDismiss = this.positionLeft;
      }, 20)
    } catch (error) {
      SentryUtilites.setLog("NonPremiumModalComponent:OnResize", error)
    }
  }

  openModal(isOpen: boolean = true) {
    try {
      if(isOpen) {
        this.modalEle.present();
        this.allDataService.checkPro();
      } else {
        this.modalEle.dismiss();
      }
      // For 3 sale credit comment below code
      if(isOpen) {
        this.router.navigate(['dashboard']);
      }
    } catch (error) {
      SentryUtilites.setLog("NonPremiumModalComponent:openModal", error)
    }
  }

  contactWithSales() {
    this.eventService.setShowContactSupportPopup(true);
  }

}
