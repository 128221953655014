import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Ingredient } from '../../models/Ingredient.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Profile } from '../../models/Profile.model';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { IonInput, LoadingController, ToastController } from '@ionic/angular';
import { AllDataService } from '../../services/all-data.service';
import { Image } from '../../models/image.model';
import { Utility } from '../../utils/utility';
import { ItemUnit } from '../../models/ItemUnit.model';
import { IngredientService } from '../../services/ingredient.service';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-ingredient-form',
  templateUrl: './ingredient-form.component.html',
  styleUrls: ['./ingredient-form.component.scss'],
})
export class IngredientFormComponent implements OnInit {

  //? Event Emitters
  @Output() saveTrigger = new EventEmitter<Ingredient>();
  @Output() updateTrigger = new EventEmitter<Ingredient>();
  ////--------------------------------------------------------------------------------------------------------------------

  //? ViewChild References
  @ViewChild('customUnit') customUnit: IonInput;
  @ViewChild('categoryEle') categoryEle: IonInput;
  ////--------------------------------------------------------------------------------------------------------------------

  //? Global Variables
  returnZero = Utility.returnZero;
  unitsOptions = {...Utility.unitAbvrMap};
  form: FormGroup;
  paramDocumentId: string = null;
  fetchedIngredient: Ingredient = null;
  selectedProfile: Profile = null;
  categories: string[] = [];
  isOpenImageCropper = false;
  isDisableSave = false;
  imagesBase64: {
    imageUUID: string;
    base64: string;
  }[] = [];
  subscriptions: Subscription[] = [];
  loading: HTMLIonLoadingElement = null;
  ////--------------------------------------------------------------------------------------------------------------------


  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private allDataService: AllDataService,
    private ingredientService: IngredientService,
    private toastController: ToastController,
    private loadingCtrl: LoadingController,
  ) { }

  //? Life Cycle Hooks

  async ngOnInit() {
    try {
      this.getSelectedProfile();
      this.getParamDocumentId();
      this.initializeReactiveForm();
      this.getCustomUnit();
      this.subscriptions.push(
        this.allDataService.listForceReloadSubs.subscribe((listName: string) => {
          if (listName == 'image-list') {
            this.getSelectedProfile();
            this.getParamDocumentId();
            this.initializeReactiveForm();
            this.getCustomUnit();
            this.populateForm();
          }
        })
      );
    } catch (error) {
      SentryUtilites.setLog("IngredientFormComponent:ngOnInit", error)
    }
  }

  async ngAfterViewInit() {
    try {
      await this.showLoading('Loading Raw Material list...', 'raw-material-loader');
      this.populateForm();
    } catch (error) {
      SentryUtilites.setLog("IngredientFormComponent:ngAfterViewInit", error)
    }
  }

  ngOnDestroy() {
    try {
      this.subscriptions?.forEach(sub => {sub?.unsubscribe()});
    } catch (error) {
      SentryUtilites.setLog("IngredientFormComponent:ngOnDestroy", error)
    }
   }

  ////--------------------------------------------------------------------------------------------------------------------

  //? Initialize Form, Fetch Data & Populate Functions

  async getSelectedProfile() {
    try {
      this.selectedProfile = await this.allDataService.profileService.getCurrentProfile();
      return this.selectedProfile;
    } catch (error) {
      SentryUtilites.setLog("IngredientFormComponent:getSelectedProfile", error)
      return null;
    }
  }

  getParamDocumentId() {
    this.paramDocumentId = this.route.snapshot.paramMap.get('documentId');
  }

  initializeReactiveForm() {
    try {
      this.form = this.formBuilder.group({
        name: [null, Validators.required],
        unit: [null, Validators.required],
        category: [null],
        description: [null],
        minStock: [null],
        images: [[]],
      });
      this.form.get('minStock').valueChanges.pipe(distinctUntilChanged(), debounceTime(300)).subscribe(value => {
        if(value < 0 && value != this.form?.value?.minStock) {
          this.form.patchValue({minStock: Math.abs(value)});
        }
      });
    } catch (error) {
      SentryUtilites.setLog("IngredientFormComponent:initializeReactiveForm", error)
    }
  }

  async populateForm() {
    try {
      await this.getCategories();
      if(!this.paramDocumentId) {
        this.loading?.dismiss();
      }
      if (this.paramDocumentId) {
        try {
          this.fetchedIngredient = await this.ingredientService.getByUUID(this.paramDocumentId);
        } catch (error) {
          this.loading?.dismiss();
          await this.showToast('Something went wrong, Please try again later.', 'danger');
        }
        if(this.fetchedIngredient?._localUUID) {
          this.loading?.dismiss();
          this.form.patchValue(this.fetchedIngredient);
          this.loadImages();
        }
      }
    } catch (error) {
      SentryUtilites.setLog("IngredientFormComponent:populateForm", error)
    }
  }

  ////--------------------------------------------------------------------------------------------------------------------

  //? Custom Unit Functiions

  async getCustomUnit() {
    try {
      let itemUnits=await this.allDataService.itemUnitService.getAllByPromise() || [];
      if (itemUnits?.length) {
        itemUnits?.forEach(itemUnit => this.unitsOptions[itemUnit?.name] = itemUnit?.name);
      }
    } catch (error) {
      SentryUtilites.setLog("IngredientFormComponent:getCustomUnit", error)
    }
  }

  async addCustomUnit() {
    try {
      if(this.customUnit) {
        const unitName: string = this.customUnit?.value as string;
        if(unitName) {
          if(this.unitsOptions[unitName]?.toLowerCase() !== unitName?.toLowerCase()) {
            let itemUnit = new ItemUnit();
            itemUnit.name = unitName;
            let savedItemUnit = await this.allDataService.itemUnitService.save(itemUnit);
            if(savedItemUnit?._localUUID) {
              this.allDataService.listForceReloadSubs.next('item-unit-list');
              this.unitsOptions[unitName] = unitName;
            }else {
              return null;
            }
          }
          this.form?.patchValue({unit: this.unitsOptions[unitName]});
          this.customUnit.value = '';
        }
      }
    } catch (error) {
      SentryUtilites.setLog("IngredientFormComponent:addCustomUnit", error)
    }
  }

  ////--------------------------------------------------------------------------------------------------------------------

  //? Category Functiions

  async getCategories() {
    try {
      await this.getSelectedProfile();
      if(Array.isArray(this.selectedProfile?.ingredientCategories) && this.selectedProfile?.ingredientCategories?.length) {
        this.categories = this.selectedProfile?.ingredientCategories;
      }
      return true;
    } catch (error) {
      SentryUtilites.setLog("IngredientFormComponent:getCategories", error)
      return false;
    }
  }

  async addCategory() {
    try {
      if(this.categoryEle) {
        const categoryName: string = this.categoryEle?.value as string;
        if(categoryName) {
          if(this.categories?.findIndex(x => x?.toLowerCase() === categoryName?.toLowerCase()) === -1) {
            await this.getSelectedProfile();
            if(this.selectedProfile?.ingredientCategories?.length && Array.isArray(this.selectedProfile?.ingredientCategories)) {
              this.selectedProfile?.ingredientCategories?.push(categoryName);
            }else {
              this.selectedProfile.ingredientCategories = [categoryName];
            }
            await this.allDataService.profileService.update(this.selectedProfile);
            this.categories.push(categoryName);
          }
          this.form?.patchValue({ category: categoryName });
          this.categoryEle.value = '';
        }
      }
    } catch (error) {
      SentryUtilites.setLog("IngredientFormComponent:addCategory", error)
    }
  }

  ////--------------------------------------------------------------------------------------------------------------------

  //? Image Cropper Functions

  openImageCropper(value: boolean = true) {
    this.isOpenImageCropper = value;
  }

  removeImage(imageUUId: string) {
    try {
      let formImages: string[] = this.form?.value?.images;
      if(formImages?.length) {
        const index = formImages.findIndex(uuid => uuid === imageUUId);
        if(index != -1) {
          formImages.splice(index,1);
          this.form?.patchValue({
            images: formImages
          });
          const indx = this.imagesBase64.findIndex(imageData => imageData?.imageUUID === imageUUId);
          if(indx != -1) {
            this.imagesBase64.splice(index,1);
          }
        }
      }
    } catch (error) {
      SentryUtilites.setLog("IngredientFormComponent:removeImage", error)
    }
  }

  async onImageSaved(image: Image) {
    try {
      image = await this.allDataService.imageService.getByUUID(image._localUUID);
      this.form?.value?.images?.push(image._localUUID);
      if(image?.imageBase64) {
        this.imagesBase64.push({
          imageUUID: image._localUUID,
          base64: image.imageBase64,
        });
      }
    } catch (error) {
      SentryUtilites.setLog("IngredientFormComponent:onImageSaved", error)
    }
  }

  async loadImages() {
    try {
      if(this.fetchedIngredient?.images?.length) {
        for (let i = 0; i < this.fetchedIngredient?.images?.length; i++) {
          const imageId = this.fetchedIngredient?.images[i];
          const image = await this.allDataService.imageService.getByUUID(imageId);
          if(image?.imageBase64) {
            this.imagesBase64.push({
              imageUUID: image._localUUID,
              base64: image.imageBase64,
            });
          }
        }
      }
    } catch (error) {
      SentryUtilites.setLog("IngredientFormComponent:loadImages", error)
    }
  }

  ////--------------------------------------------------------------------------------------------------------------------

  //? Utils Functions

  preventEmptyString(formControlName: string) {
    this.form?.patchValue({[formControlName]: Utility.preventEmptyString(this.form?.value[formControlName])});
  }

  ////--------------------------------------------------------------------------------------------------------------------

  //? Save & Update Functions

  async onSaveClick() {
    try {
      this.isDisableSave = true;
      if (this.paramDocumentId) {
        await this.update();
      } else {
        await this.save();
      }
      this.isDisableSave = false;
    } catch (error) {
      SentryUtilites.setLog("IngredientFormComponent:onSaveClick", error)
    }
  }

  async save(preventRouting: boolean = false) {
    if (this.form.valid) {
      let ingredient = new Ingredient();
      ingredient = { ...this.form.value };
      let savedIngredient:Ingredient;
      try {
        savedIngredient = await this.ingredientService.save(ingredient);
        if (savedIngredient?._localUUID && !preventRouting) {
          // this.allDataService.listForceReloadSubs.next('');
          this.form.reset();
          this.saveTrigger.emit(savedIngredient);
        }
      } catch (error) {
        SentryUtilites.setLog("IngredientFormComponent:save", error)
        await this.showToast('Something went wrong, Please try again later.', 'danger');
      }
      return savedIngredient;
    }
  }

  async update(preventRouting: boolean = false) {
    if (this.form.valid) {
      this.fetchedIngredient = {
        ...this.fetchedIngredient,
        ...this.form.value
      }
      let updatedIngredient: Ingredient;
      try {
        updatedIngredient = await this.ingredientService.update(this.fetchedIngredient);
        if (updatedIngredient?._localUUID && !preventRouting) {
          // this.allDataService.listForceReloadSubs.next('')
          this.form.reset();
          this.updateTrigger.emit(updatedIngredient);
        }
      } catch (error) {
        SentryUtilites.setLog("IngredientFormComponent:update", error)
        await this.showToast('Something went wrong, Please try again later.', 'danger');
      }
      await this.allDataService.itemService.updateItemOnIngredientUpdate(updatedIngredient);
      return updatedIngredient;
    }
  }

  ////--------------------------------------------------------------------------------------------------------------------
  
  async showToast(msg: string, toastColor: string) {
    try {
      const toast = await this.toastController.create({
        message: msg,
        duration: 2000,
        color: toastColor,
      });
      await toast.present();
    } catch (error) {
      SentryUtilites.setLog("IngredientFormComponent:showToast", error)
    }
  }

  async showLoading(msg: string, css: string) {
    try {
      this.loading = await this.loadingCtrl.create({ 
        message: msg,
        cssClass: css
      });
      this.loading?.present();
    } catch (error) {
      SentryUtilites.setLog("IngredientFormComponent:showLoading", error)
    }
  }

}
