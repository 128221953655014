<app-image-cropper *ngIf="isOpenImageCropper" [maintainAspectRatio]="true" (imageSaved)="onImageSaved($event)"
  (modalClosed)="openImageCropper($event ? false : true)">
</app-image-cropper>

<app-image-cropper *ngIf="isOpenSignatureCropper" (imageSaved)="onSignatureSaved($event)"
  (modalClosed)="openSignatureCropper($event ? false : true)">
</app-image-cropper>

<ion-grid>
  <form [formGroup]="form">

    <ion-row *ngIf="logoBase64 && !hideInputs">
      <ion-col size="3">
        <ion-item lines="none">
          <img class="img-logo" [src]="logoBase64">
          <ion-icon class="img-remove-icon" color="danger" name="close-circle" (click)="removeLogo()"></ion-icon>
        </ion-item>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="!hideInputs">
      <ion-col size="3">
        <ion-chip [disabled]="readonly" color="tertiary" (click)="openImageCropper()"> {{ logoBase64 ? 'Change' : 'Add'
          }} Logo </ion-chip>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="!hideInputs">
      <ion-col size="12">
        <ion-item lines="none">
          <ion-label position="floating">Select Industry</ion-label>
          <ion-select 
            interface="alert" 
            [disabled]="form.get('ezoIndustry').value ? form.get('ezoIndustry').value && changeIndustry : false" 
            placeholder="Select Industry"
            formControlName="ezoIndustry">
            <ion-select-option *ngFor="let industry of industryList" [value]="industry">
              {{ industry }}
            </ion-select-option>
          </ion-select>
        </ion-item>
        <ion-button 
          *ngIf="form.get('ezoIndustry').value"
          class="change-industry" 
          color="tertiary" 
          fill="clear" 
          size="small" 
          (click)="changeIndustry = false">
          Change
        </ion-button>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="!hideInputs">
      <ion-col size="12">
        <ion-item lines="none">
          <ion-label position="floating">Profile Name</ion-label>
          <ion-input [readonly]="readonly" formControlName="profileName"></ion-input>
        </ion-item>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="isFocusLegalName || !hideInputs">
      <ion-col size="12">
        <ion-item lines="none">
          <ion-label position="floating">Legal Name</ion-label>
          <ion-input [readonly]="readonly" [autofocus]="isFocusLegalName" formControlName="legalName"></ion-input>
        </ion-item>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="!hideInputs">
      <ion-col size="12">
        <ion-item lines="none">
          <ion-label position="floating">Contact Person Phone</ion-label>
          <ion-input 
            appColorFormValidation 
            controlName="phone" 
            [readonly]="readonly" 
            formControlName="contactPersonPhone">
          </ion-input>
        </ion-item>
      </ion-col>
      <ion-col size="12">
        <ion-item lines="none">
          <ion-label position="floating">Contact Person Name</ion-label>
          <ion-input [readonly]="readonly" formControlName="contactPersonName"></ion-input>
        </ion-item>
      </ion-col>
      <ion-col size="12">
        <ion-item lines="none">
          <ion-label position="floating">Contact Person Email</ion-label>
          <ion-input 
            appColorFormValidation 
            controlName="email" 
            [readonly]="readonly" 
            formControlName="contactPersonEmail">
          </ion-input>
        </ion-item>
      </ion-col>
      <ion-col size="12">
        <ion-item lines="none">
          <ion-label position="floating">Address Line 1</ion-label>
          <ion-input [readonly]="readonly" formControlName="addressLine1"></ion-input>
        </ion-item>
      </ion-col>
      <ion-col size="12">
        <ion-item lines="none">
          <ion-label position="floating">Address Line 2</ion-label>
          <ion-input [readonly]="readonly" formControlName="addressLine2"></ion-input>
        </ion-item>
      </ion-col>
      <ion-col size="12">
        <ion-item lines="none">
          <ion-label position="floating">Address City</ion-label>
          <ion-input [readonly]="readonly" formControlName="addressCity"></ion-input>
        </ion-item>
      </ion-col>
      <ion-col size="12">
        <ion-item lines="none">
          <ion-label position="floating">Address Provience</ion-label>
          <ion-select [disabled]="readonly" interface="alert" placeholder="Select" formControlName="addressProvience">
            <ion-select-option 
              *ngFor="let state of states|keyvalue : returnZero" 
              [value]="state.key"
              [disabled]="isDisabledState(state?.value)">
              {{ state.value }}
            </ion-select-option>
          </ion-select>
        </ion-item>
      </ion-col>
      <ion-col size="12">
        <ion-item lines="none">
          <ion-label position="floating">addressPostalCode</ion-label>
          <ion-input 
            appColorFormValidation 
            controlName="pincode"
            [readonly]="readonly" 
            formControlName="addressPostalCode">
          </ion-input>
        </ion-item>
      </ion-col>
      <ion-col size="12">
        <ion-item lines="none">
          <ion-label position="floating">Address Country</ion-label>
          <ion-input [readonly]="readonly" formControlName="addressCountry"></ion-input>
        </ion-item>
      </ion-col>
      <ion-col size="12">
        <ion-item lines="none">
          <ion-label position="floating">GSTIN</ion-label>
          <ion-input 
            appColorFormValidation 
            controlName="gstin"
            [readonly]="readonly" 
            formControlName="gstin">
          </ion-input>
        </ion-item>
      </ion-col>
      <ion-col size="12">
        <ion-item lines="none">
          <ion-label position="floating">FSSAI Number</ion-label>
          <ion-input [readonly]="readonly" formControlName="fssaiNumber"></ion-input>
        </ion-item>
      </ion-col>
      <ion-col size="12">
        <ion-item lines="none">
          <ion-label position="floating">License Number</ion-label>
          <ion-input [readonly]="readonly" formControlName="licenseNumber"></ion-input>
        </ion-item>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="isFocusBankAccountDetails || !hideInputs">
      <ion-col size="12">
        <ion-item lines="none" [ngClass]="{'danger-border':!isMatchedBankAccount}">
          <ion-label position="floating">Bank Account No.</ion-label>
          <ion-input 
            appColorFormValidation 
            controlName="bankAccountNo"
            [readonly]="readonly"  
            [autofocus]="isFocusBankAccountDetails"
            formControlName="bankAccountNo"></ion-input>
        </ion-item>
      </ion-col>
      <ion-col size="12">
        <ion-item lines="none" [ngClass]="{'danger-border':!isMatchedBankAccount}">
          <ion-label position="floating">Re-enter Bank Account No.</ion-label>
          <ion-input 
            appColorFormValidation 
            controlName="bankAccountNo"
            [readonly]="readonly" 
            (keyup)="matchBankAccount($event)"
            formControlName="reEnterBankAccountNo">
          </ion-input>
        </ion-item>
      </ion-col>
      <ion-col size="12">
        <ion-item lines="none">
          <ion-label position="floating">Bank Name</ion-label>
          <ion-input [readonly]="readonly" formControlName="bankName"></ion-input>
        </ion-item>
      </ion-col>
      <ion-col size="12">
        <ion-item lines="none" [ngClass]="{'danger-border':!isMatchedIFSC}">
          <ion-label position="floating">Bank IFSC</ion-label>
          <ion-input 
            appColorFormValidation 
            controlName="bankIFSC"
            [readonly]="readonly" 
            formControlName="bankIFSC">
          </ion-input>
        </ion-item>
      </ion-col>
      <ion-col size="12">
        <ion-item lines="none" [ngClass]="{'danger-border':!isMatchedIFSC}">
          <ion-label position="floating">Re-enter Bank IFSC</ion-label>
          <ion-input 
            appColorFormValidation 
            controlName="bankIFSC"
            [readonly]="readonly" 
            (keyup)="matchIFSC($event)"
            formControlName="reEnterBankIFSC">
          </ion-input>
        </ion-item>
      </ion-col>
      <ion-col size="12">
        <ion-item lines="none">
          <ion-label position="floating">Bank Account Type</ion-label>
          <ion-select interface="alert" placeholder="Select Bank Account Type" formControlName="bankAccountType">
            <ion-select-option [value]="'current'">
              Current
            </ion-select-option>
            <ion-select-option [value]="'saving'">
              Saving
            </ion-select-option>
          </ion-select>
        </ion-item>
      </ion-col>
      <ion-col size="12">
        <ion-item lines="none">
          <ion-label position="floating">Bank UPI</ion-label>
          <ion-input 
            appColorFormValidation 
            controlName="upi"
            [readonly]="readonly" 
            formControlName="bankUPI">
          </ion-input>
        </ion-item>
      </ion-col>

    </ion-row>

    <ion-row *ngIf="!hideInputs">
      <ion-col size="12">
        <span class="sp-custom-fields-title">Custom Fields</span>
      </ion-col>

      <ng-container *ngFor="let count of [1,2,3,4,5]">
        <ion-col size="6">
          <ion-item lines="none" [ngClass]="{'danger-border':form.value?.['additionalInputFieldValue'+count]?.length && !form.value?.['additionalInputFieldTitle'+count]?.length}">
            <ion-label position="stacked">Field Title {{ count }}</ion-label>
            <ion-input placeholder="Field Title {{count}}" [formControlName]="'additionalInputFieldTitle'+count"></ion-input>
          </ion-item>
          <ion-item lines="none" [ngClass]="{'danger-border':form.value?.['additionalInputFieldTitle'+count]?.length && !form.value?.['additionalInputFieldValue'+count]?.length}">
            <ion-label position="stacked">Field Value {{ count }}</ion-label>
            <ion-input placeholder="Field Value {{count}}" [formControlName]="'additionalInputFieldValue'+count"></ion-input>
          </ion-item>
        </ion-col> 
      </ng-container>

    </ion-row>

    <ion-row>
      <ion-col size="12">
        <ion-button 
          class="btn-save-profile" 
          expand="full"
          [disabled]="
            form?.invalid || 
            !isMatchedBankAccount || 
            !isMatchedIFSC || 
            readonly || 
            checkCustomFeildValidation()
          " 
          (click)="update()">
          Update Profile
        </ion-button>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="signatureBase64 && !hideInputs">
      <ion-col size="3">
        <ion-item lines="none">
          <img class="signature-logo" [src]="signatureBase64">
          <ion-icon class="img-remove-icon" color="danger" name="close-circle" (click)="removeSignature()"></ion-icon>
        </ion-item>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="!hideInputs">
      <ion-col size="3">
        <ion-chip [disabled]="readonly" color="tertiary" (click)="openSignatureCropper()"> {{ signatureBase64 ? 'Change'
          : 'Add' }} Signature
        </ion-chip>
      </ion-col>
    </ion-row>

  </form>
</ion-grid>