<ion-header>
  <ion-toolbar class="{{ getHeaderColorClass() }}">
    <app-navigation-control slot="start" type="reports"></app-navigation-control>
    <ion-title>Reports</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>

  <table id="table" #table></table>

  <ion-grid>

    <ion-row>
      <ion-col size="12">
        <ion-chip color="primary" (click)="openBusinessReport()">
          Business Report
        </ion-chip>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="12">
        <ion-button fill="clear" class="btn-txt-black padding-start-none width-full bg-light txt-left">
          GSTR Reports
        </ion-button>
      </ion-col>
      <ion-col size="12">
        <ion-chip color="primary" (click)="openDateRangeModal(true,'GSTR1Report')">
          GSTR-1 Report
        </ion-chip>
        <!-- <ion-chip color="primary" (click)="openDateRangeModal(true,'GSTR2Report')">
          GSTR-2 Report
        </ion-chip>
        <ion-chip color="primary" (click)="openDateRangeModal(true,'GSTR3bReport')">
          GSTR-3b Report
        </ion-chip> -->
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="12">
        <ion-button fill="clear" class="btn-txt-black padding-start-none width-full bg-light txt-left">
          Transaction Reports
        </ion-button>
      </ion-col>
      <ion-col size="12">
        <ion-chip color="primary" (click)="openDateRangeModal(true,'SaleReport')">
          Sale Report
        </ion-chip>

        <!-- Staff Wise Sale Report -->
        <ion-chip color="primary" (click)="openStaffListModal(true,'StaffWiseSaleReport')">
          Staff Wise Sale Report
        </ion-chip>

        <ion-chip color="primary" (click)="openDateRangeModal(true,'SaleWisePnlReport')">
          Sale Wise Profit & Loss Statement
        </ion-chip>
        <ion-chip color="primary" (click)="openDateRangeModal(true,'PurchaseReport')">
          Purchase Report
        </ion-chip>
        <ion-chip color="primary" (click)="openDateRangeModal(true,'MoneyInReport')">
          Money In Report
        </ion-chip>
        <ion-chip color="primary" (click)="openDateRangeModal(true,'MoneyOutReport')">
          Money Out Report
        </ion-chip>
        <ion-chip color="primary" (click)="openDateRangeModal(true,'ExpenseReport')">
          Expense Report
        </ion-chip>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="12">
        <ion-button fill="clear" class="btn-txt-black padding-start-none width-full bg-light txt-left">
          Party Reports
        </ion-button>
      </ion-col>
      <ion-col size="12">
        <ion-chip color="primary" (click)="openPartySelectorModal(true)">
          Party Report
        </ion-chip>
        <ion-chip color="primary" (click)="directOpen('PartyDetailReport')">
          Party Details Report
        </ion-chip>
        <ion-chip color="primary" (click)="directOpen('PartyReceivablePayableReport')">
          Party Receivable/Payable Report
        </ion-chip>
        <!-- <ion-chip color="primary" (click)="openCampaignSelectorModal(true)">
          Campaign Report
        </ion-chip> -->
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="12">
        <ion-button fill="clear" class="btn-txt-black padding-start-none width-full bg-light txt-left">
          Item/Stock Reports
        </ion-button>
      </ion-col>
      <ion-col size="12">
        <ion-chip color="primary" (click)="directOpen('StockSummaryReport')">
          Stock Summary Report
        </ion-chip>
        <ion-chip color="primary" (click)="openDateRangeModal(true,'ItemSaleReport')">
          Item Sale Report
        </ion-chip>
        <ion-chip color="primary" (click)="openItemSelectorModal(true)">
          Item Report
        </ion-chip>
        <ion-chip color="primary" (click)="directOpen('ItemDetailReport')">
          Item Details Report
        </ion-chip>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="12">
        <ion-button fill="clear" class="btn-txt-black padding-start-none width-full bg-light txt-left">
          Business Reports
        </ion-button>
      </ion-col>
      <ion-col size="12">
        <ion-chip color="primary" (click)="openDateRangeModal(true,'DayBookReport','today')">
          Day Book Report
        </ion-chip>
        <ion-chip  color="primary"
          (click)="openDateRangeModal(true,'AllProfilesDayBookReport','today')">
          All Profiles Day Book Report
        </ion-chip>
        <ion-chip color="primary" (click)="openCutOffDayDatesModal(true,'CutOffDayBookReport')">
          Cut Off Day Report
        </ion-chip>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="selectedProfile?.itSetEnableIngredient">
      <ion-col size="12">
        <ion-button fill="clear" class="btn-txt-black padding-start-none width-full bg-light txt-left">
          Raw Material Reports
        </ion-button>
      </ion-col>
      <ion-col size="12">
        <!-- <ion-chip color="primary" (click)="openDateRangeModal(true,'IngredientReport','today')">
          Ingredient Report
        </ion-chip>
        <ion-chip color="primary" (click)="openDateRangeModal(true,'IngredientDetailReport','today')">
          Ingredient Detail Report
        </ion-chip>
        <ion-chip color="primary" (click)="openDateRangeModal(true,'SaleWiseIngredientReport','today')">
          Sale Wise Ingredient Report
        </ion-chip> -->
        <!-- <ion-chip color="primary" (click)="openDateRangeModal(true,'RawMaterialReport','month')">
          Raw Material Report
        </ion-chip> -->
        <ion-chip color="primary" (click)="openDateRangeModal(true,'RawMaterialCostReport','month')">
          Raw Material Cost Report
        </ion-chip>
        <ion-chip color="primary" (click)="openDateRangeModal(true,'RawMaterialConsumptionReport','month')">
          Raw Material Consumption Report
        </ion-chip>
        <ion-chip color="primary" (click)="openDateRangeModal(true,'RawMaterialPurchaseReport','month')">
          Raw Material Purchase Report
        </ion-chip>
      </ion-col>
    </ion-row>

  </ion-grid>

</ion-content>

<ion-modal class="modal-party-selector" [isOpen]="isPartyListModalOpen" [backdropDismiss]="false" animated="false">
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-title>Select Party</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="openPartySelectorModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <app-party-list [isModal]="true" (partySelectedEvent)="onPartySelected($event)">
      </app-party-list>
    </ion-content>
  </ng-template>
</ion-modal>

<!-- staff-list modal -->
<ion-modal class="modal-party-selector" [isOpen]="isStaffWiseSaleReportModal" [backdropDismiss]="false"
  animated="false">
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-title>Select Staff</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="openStaffListModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <app-staff-list [isModal]="true" (selectedStaff)="onStaffSelected($event)">
      </app-staff-list>
    </ion-content>
  </ng-template>
</ion-modal>

<ion-modal class="modal-item-selector" [isOpen]="isItemListModalOpen" [backdropDismiss]="false" animated="false">
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-title>Select Item</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="openItemSelectorModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <app-item-list (itemSelectedEvent)="onItemSelected($event)">
      </app-item-list>
    </ion-content>
  </ng-template>
</ion-modal>

<ion-modal class="modal-campaign-selector" [isOpen]="isCampaignListModalOpen" [backdropDismiss]="false"
  animated="false">
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-title>Select Campaign</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="openCampaignSelectorModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <ion-grid class="all-list">

        <ion-row class="campaign-custom-row col-custom-row" (click)="directOpen('CampaignReport')"
          *ngFor="let campaign of campaignList">

          <ion-col size="12">
            <ion-button fill="clear" class="btn-party-item-name">
              {{ shortStr(campaign?.name,85) }}
            </ion-button>
          </ion-col>

        </ion-row>

      </ion-grid>
    </ion-content>
  </ng-template>
</ion-modal>

<ion-modal appReportDateRangeModal class="modal-date-range" [isOpen]="isDateRangeModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar color="light">
        <ion-title>Please Select Date Range</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="openDateRangeModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">

      <app-date-range [selectedDateRange]="selectedDateRange" [isTodaysEndDate]="selectedReport=='IngredientReport'"
        [disableYearCustomFilter]="disableYearCustomFilter"
        (dateRangeChange)="onDateRangeChange($event)"></app-date-range>

      <ion-grid>
        <ion-row>
          <ion-col size="12">

            <ion-button expand="full" (click)="generateReport()">Generate Report</ion-button>

          </ion-col>
        </ion-row>
      </ion-grid>


    </ion-content>
  </ng-template>
</ion-modal>

<ion-modal appReportDateRangeModal class="cutOffDay-modal-date-range" [isOpen]="isCutOffDayDatesModalOpen"
  [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar color="light">
        <ion-title>Please Select Date Range</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="openCutOffDayDatesModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">

      <ion-grid>
        <ion-row>
          <ion-col class="custom-col" size="6">
            <ion-button fill="clear" class="btn-title btn-txt-black">
              Start Time
            </ion-button>
            <ion-select [interfaceOptions]="{header: 'Start Time'}" class="new-ion-select" interface="alert"
              placeholder="Select Start Time" (ionChange)="setStartStamp($event)">
              <ion-select-option *ngFor="let startTime of startTimeCutOffArr" [value]="startTime">
                {{ stampTo12HrFormatDateTime(startTime) }}
              </ion-select-option>
            </ion-select>
          </ion-col>
          <ion-col class="custom-col" size="6">
            <ion-button fill="clear" class="btn-title btn-txt-black">
              End Time
            </ion-button>
            <ion-select [interfaceOptions]="{header: 'End Time'}" class="new-ion-select" interface="alert"
              placeholder="Select End Time" (ionChange)="setEndStamp($event)">
              <ion-select-option *ngFor="let endTime of endTimeCutOffArr" [value]="endTime">
                {{ stampTo12HrFormatDateTime(endTime) }}
              </ion-select-option>
            </ion-select>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <ion-button expand="full" (click)="generateReport()">Generate Report</ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>

    </ion-content>
  </ng-template>
</ion-modal>

<ion-modal class="full-page-modal" [isOpen]="isSaleReportModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar color="light">
        <ion-title>Sale Report</ion-title>
        <ion-buttons slot="end">
          <ion-chip color="success" (click)="saleReportData?.downloadExcel()">Download Excel</ion-chip>
          <ion-chip color="tertiary" (click)="saleReportData?.download()">Download PDF</ion-chip>
          <ion-button (click)="openSaleReportModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">

      <ion-grid class="width-100-percent">

        <div class="container">
          <p class="margin-bottom10 font-size20">
            <img [src]="logoBase64" width="80" height="80" style="border-radius: 50%;">
            {{ selectedProfile?.legalName || selectedProfile?.profileName }}
          </p>

          <p class="header-wrapper margin-bottom10">Sale Report</p>

          <div class="center1">
            <p>Duration : {{ saleReportData?.duration }}</p><br>
            <p>Total Sales : {{ saleReportData?.sales?.length }}</p>
            <p>Total Sale Quantity : {{ saleReportData?.totalSalesQuantity }}</p>
            <p>Total Sale Amount : {{ saleReportData?.totalSalesAmount | currency: 'INR' }}</p>
          </div>

          <div class="container padding-left-right-none width-100-percent">
            <h1>Sale Report</h1>

            <table class="main-table" width="100%">
              <thead>
                <tr>
                  <th>Sr No</th>
                  <th>Date</th>
                  <th>TXN No</th>
                  <th>Party Name</th>
                  <th>Party Phone</th>
                  <th>Secondary Party Name</th>
                  <th>Secondary Party Phone</th>
                  <th>Total Quantity</th>
                  <th>Total Tax</th>
                  <th>Total Amount<br> ( inc taxes )</th>
                  <th>Created By</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let record of viewFilteredList;let i = index">
                  <tr>
                    <td>{{ i + 1 }}</td>
                    <td>{{ record?.sale?.billDateStampString }}</td>
                    <td>{{ record?.sale?.billNo }}</td>
                    <td>{{ record?.sale?.party?.name || '-' }}</td>
                    <td>{{ record?.sale?.party?.phone || '-' }}</td>
                    <td>{{ record?.sale?.partySecondary?.name || '-' }}</td>
                    <td>{{ record?.sale?.partySecondary?.phone || '-' }}</td>
                    <td>{{ record?.totalBillItemQuantity }}</td>
                    <td>{{ record?.totalTax | currency: 'INR' }}</td>
                    <td>{{ record?.sale?.totalAmount | currency: 'INR' }}</td>
                    <td>{{ record?.sale?.createdByName || record?.sale?.createdBy }}</td>
                  </tr>
                  <tr *ngIf="record?.sale?.billItems?.length">
                    <td width="10%"></td>
                    <td width="90%" colspan="10">
                      <table class="sub-table" width="100%">
                        <thead>
                          <th>#</th>
                          <th>Item Name</th>
                          <th>Qty</th>
                          <th>Unit</th>
                          <th>Rs/Unit</th>
                          <th>Amount</th>
                        </thead>
                        <tbody>
                          <tr *ngFor="let billItem of record?.sale?.billItems;let j = index">
                            <td>{{ j + 1 }}</td>
                            <td>{{ billItem?.item?.itemName }}</td>
                            <td>{{ billItem?.quantity }}</td>
                            <td>{{ billItem?.unit }}</td>
                            <td>{{ billItem?.price| currency: 'INR' }}</td>
                            <td>{{ billItem?.total | currency: 'INR' }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </ng-container>
                <ion-infinite-scroll threshold="10px" *ngIf="viewFilteredList?.length" (ionInfinite)="loadMoreSaleReportListData($event)">
                  <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Loading more data...">
                  </ion-infinite-scroll-content>
                </ion-infinite-scroll>

              </tbody>
            </table>


          </div>
        </div>
      </ion-grid>

    </ion-content>
  </ng-template>
</ion-modal>

<!-- staffWiseSaleReport Modal -->
<ion-modal class="full-page-modal" [isOpen]="isStaffWiseSaleReportDetailModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar color="light">
        <ion-title>Staff Wise Sale Report</ion-title>
        <ion-buttons slot="end">
          <ion-chip color="success" (click)="staffWiseSaleReportData?.downloadExcel()">Download Excel</ion-chip>
          <ion-chip color="tertiary" (click)="staffWiseSaleReportData?.download()">Download PDF</ion-chip>
          <ion-button (click)="openStaffWiseSaleReportModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">

      <ion-grid class="width-100-percent">

        <div class="container">
          <p class="margin-bottom10 font-size20">
            <img [src]="logoBase64" width="80" height="80" style="border-radius: 50%;">
            {{ selectedProfile?.legalName || selectedProfile?.profileName }}
          </p>

          <p class="header-wrapper margin-bottom10">Staff Wise Sale Report</p>
          <div class="blue-color">
            <p class="margin-bottom10 font-size18">{{ selectedStaff.name }}</p>
          </div>
          <div class="center1">
            <p>Duration : {{ staffWiseSaleReportData?.duration }}</p><br>
            <p>Total Sales : {{ staffWiseSaleReportData?.sales?.length }}</p>
            <p>Total Sale Quantity : {{ staffWiseSaleReportData?.totalSalesQuantity }}</p>
            <p>Total Sale Amount : {{ staffWiseSaleReportData?.totalSalesAmount | currency: 'INR' }}</p>
          </div>

          <div class="container padding-left-right-none width-100-percent">
            <h1>Staff Wise Sale Report</h1>

            <table class="main-table" width="100%">
              <thead>
                <tr>
                  <th>Sr No</th>
                  <th>Date</th>
                  <th>TXN No</th>
                  <th>Party Name</th>
                  <th>Party Phone</th>
                  <th>Secondary Party Name</th>
                  <th>Secondary Party Phone</th>
                  <th>Total Quantity</th>
                  <th>Total Tax</th>
                  <th>Total Amount<br> ( inc taxes )</th>
                  <th>Created By</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let record of staffWiseSaleReportData?.sales;let i = index">
                  <tr>
                    <td>{{ i + 1 }}</td>
                    <td>{{ dateToDDMMYYY(record?.sale?.billDateStamp) }}</td>
                    <td>{{ record?.sale?.billNo }}</td>
                    <td>{{ record?.sale?.party?.name || '-' }}</td>
                    <td>{{ record?.sale?.party?.phone || '-' }}</td>
                    <td>{{ record?.sale?.partySecondary?.name || '-' }}</td>
                    <td>{{ record?.sale?.partySecondary?.phone || '-' }}</td>
                    <td>{{ record?.totalBillItemQuantity }}</td>
                    <td>{{ record?.totalTax | currency: 'INR' }}</td>
                    <td>{{ record?.sale?.totalAmount | currency: 'INR' }}</td>
                    <td>{{ record?.sale?.createdByName || record?.sale?.createdBy }}</td>
                  </tr>
                  <tr *ngIf="record?.sale?.billItems?.length">
                    <td width="10%"></td>
                    <td width="90%" colspan="10">
                      <table class="sub-table" width="100%">
                        <thead>
                          <th>#</th>
                          <th>Item Name</th>
                          <th>Qty</th>
                          <th>Unit</th>
                          <th>Rs/Unit</th>
                          <th>Amount</th>
                        </thead>
                        <tbody>
                          <tr *ngFor="let billItem of record?.sale?.billItems;let j = index">
                            <td>{{ j + 1 }}</td>
                            <td>{{ billItem?.item?.itemName }}</td>
                            <td>{{ billItem?.quantity }}</td>
                            <td>{{ billItem?.unit }}</td>
                            <td>{{ billItem?.price| currency: 'INR' }}</td>
                            <td>{{ billItem?.total | currency: 'INR' }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </ng-container>

              </tbody>
            </table>


          </div>
        </div>

      </ion-grid>

    </ion-content>
  </ng-template>
</ion-modal>
<!-- end of staffWiseSaleReport Modal -->

<ion-modal class="full-page-modal" [isOpen]="isSaleWisePnlReportModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar color="light">
        <ion-title>Sale Wise Profit and Loss Report</ion-title>
        <ion-buttons slot="end">
          <ion-chip color="success" (click)="saleWisePnlReportData?.downloadExcel()">Download Excel</ion-chip>
          <ion-chip color="tertiary" (click)="saleWisePnlReportData?.download()">Download PDF</ion-chip>
          <ion-button (click)="openSaleWisePnlReportModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">

      <ion-grid class="width-100-percent">

        <div class="container">
          <p class="margin-bottom10 font-size20">
            <img [src]="logoBase64" width="80" height="80" style="border-radius: 50%;">
            {{ selectedProfile?.legalName || selectedProfile?.profileName }}
          </p>
          <p class="header-wrapper margin-bottom10">Sale Wise Profit and Loss Report</p>

          <div class="center1">
            <p>Duration : {{ saleWisePnlReportData?.duration }}</p><br>
            <p>Total Sale Amount : {{ saleWisePnlReportData?.totalSalesAmount | currency: 'INR' }}</p>
            <p>Total Purchase Amount : {{ saleWisePnlReportData?.totalPurchasesAmount | currency: 'INR' }}</p>
            <p>Total Profit : {{ saleWisePnlReportData?.totalSalesProfit | currency: 'INR' }}</p>
          </div>
          <div class="container width-100-percent padding-left-right-none">
            <h1>Sale Wise Profit and Loss Report</h1>

            <table class="main-table" width="100%">
              <thead>
                <th>Sr No</th>
                <th>Date</th>
                <th>Bill No</th>
                <th>Party</th>
                <th>Sale Amount</th>
                <th>Purchase Amount</th>
                <th>Profit</th>
              </thead>
              <tbody>
                <tr *ngFor="let record of saleWisePnlReportData?.sales;let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ dateToDDMMYYY(record?.sale?.billDateStamp) }}</td>
                  <td>{{ record?.sale?.billNo }}</td>
                  <td>{{ record?.sale?.party?.name }}</td>
                  <td>{{ record?.sale?.totalAmount }}</td>
                  <td>{{ record?.totalPurchaseAmount }} {{ record?.purchaseAmountNote ? '(Note: ' +
                    record?.purchaseAmountNote + ')' : '' }}</td>
                  <td>{{ record?.totalSaleProfit }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </ion-grid>

    </ion-content>
  </ng-template>
</ion-modal>


<ion-modal class="full-page-modal" [isOpen]="isPurchaseReportModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar color="light">
        <ion-title>Purchase Report</ion-title>
        <ion-buttons slot="end">
          <ion-chip color="success" (click)="purchaseReportData?.downloadExcel()">Download Excel</ion-chip>
          <ion-chip color="tertiary" (click)="purchaseReportData?.download()">Download PDF</ion-chip>
          <ion-button (click)="openPurchaseReportModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">

      <ion-grid class="width-100-percent">

        <div class="container">
          <p class="margin-bottom10 font-size20">
            <img [src]="logoBase64" width="80" height="80" style="border-radius: 50%;">
            {{ selectedProfile?.legalName || selectedProfile?.profileName }}
          </p>
          <p class="header-wrapper margin-bottom10">Purchase Report</p>

          <div class="center1">
            <p>Duration : {{ purchaseReportData?.duration }}</p><br>
            <p>Total Purchases : {{ purchaseReportData?.purchases?.length }} </p>
            <p>Total Purchase Quantity : {{ purchaseReportData?.totalPurchasesQuantity }}</p>
            <p>Total Purchase Amount : {{ purchaseReportData?.totalPurchasesAmount | currency: 'INR' }} </p>
          </div>

          <div class="container width-100-percent padding-left-right-none">
            <h1>Purchase Report</h1>
            <table class="main-table" width="100%">
              <thead>
                <th>Sr No</th>
                <th>Date</th>
                <th>Txn No</th>
                <th>Party Name</th>
                <th>Total Quantity</th>
                <th>Total Tax</th>
                <th>Total Amount <br> ( inc taxes ) </th>
              </thead>
              <tbody>
                <ng-container *ngFor="let record of purchaseReportData?.purchases;let i = index">
                  <tr>
                    <td>{{ i + 1 }}</td>
                    <td>{{ dateToDDMMYYY(record?.purchase?.billDateStamp) }}</td>
                    <td>{{ record?.purchase?.billNo }}</td>
                    <td>{{ record?.purchase?.party?.name }}</td>
                    <td>{{ record?.totalBillItemQuantity }}</td>
                    <td>{{ record?.totalTax | currency: 'INR' }}</td>
                    <td>{{ record?.purchase?.totalAmount| currency: 'INR' }}</td>
                  </tr>
                  <tr *ngIf="record?.purchase?.billItems?.length">
                    <td width="10%"></td>
                    <td width="90%" colspan="6">
                      <table class="sub-table" width="100%">
                        <thead>
                          <th>#</th>
                          <th>Item Name</th>
                          <th>Qty</th>
                          <th>Unit</th>
                          <th>Rs/Unit<br> ( inc taxes )</th>
                          <th>Tax Amount</th>
                          <th>Amount<br> ( inc taxes )</th>
                        </thead>
                        <tbody>
                          <tr *ngFor="let billItem of record?.purchase?.billItems;let j = index">
                            <td>{{ i + 1 }}</td>
                            <td>{{ billItem?.item?.itemName }}</td>
                            <td>{{ billItem?.quantity }}</td>
                            <td>{{ billItem?.unit }}</td>
                            <td>{{ billItem?.price | currency: 'INR' }}</td>
                            <td>{{ billItem?.unitTaxAmount | currency: 'INR' }}</td>
                            <td>{{ billItem?.total | currency: 'INR' }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>

      </ion-grid>

    </ion-content>
  </ng-template>
</ion-modal>

<ion-modal class="full-page-modal" [isOpen]="isMoneyInReportModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar color="light">
        <ion-title>Money In Report</ion-title>
        <ion-buttons slot="end">
          <ion-chip color="success" (click)="moneyInReportData?.downloadExcel()">Download Excel</ion-chip>
          <ion-chip color="tertiary" (click)="moneyInReportData?.download()">Download PDF</ion-chip>
          <ion-button (click)="openMoneyInReportModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">

      <ion-grid class="width-100-percent">

        <div class="container">
          <p class="margin-bottom10 font-size20">
            <img [src]="logoBase64" width="80" height="80" style="border-radius: 50%;">
            {{ selectedProfile?.legalName || selectedProfile?.profileName }}
          </p>
          <p class="header-wrapper margin-bottom10">Money In Report</p>

          <div class="center1">
            <p>Duration : {{ moneyInReportData?.duration }}</p><br>
            <p>Total MoneyIns : {{ moneyInReportData?.moneyIns?.length }}</p>
            <p>Total MoneyIn Amount : &#8377;{{ moneyInReportData?.totalMoneyInsAmount }}</p>
          </div>

          <div class="container width-100-percent padding-left-right-none">
            <h1>Money In Report</h1>
            <table class="main-table" width="100%">
              <thead>
                <th>Sr No</th>
                <th>Date</th>
                <th>Party Name</th>
                <th>Txn Type</th>
                <th>Txn No</th>
                <th>Total Amount</th>
                <th>Linked Sale</th>
              </thead>
              <tbody>
                <tr *ngFor="let record of moneyInReportData?.moneyIns;let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ dateToDDMMYYY(record?.moneyIn?.billDateStamp) }}</td>
                  <td>{{ record?.moneyIn?.party?.name }}</td>
                  <td>{{ record?.moneyIn?.paymentMode }}</td>
                  <td>{{ record?.moneyIn?.billNo }}</td>
                  <td>{{ record?.moneyIn?.totalAmount }}</td>
                  <td>{{ record?.moneyIn['linkedSaleInfo'] || '-' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </ion-grid>

    </ion-content>
  </ng-template>
</ion-modal>

<ion-modal class="full-page-modal" [isOpen]="isMoneyOutReportModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar color="light">
        <ion-title>Money Out Report</ion-title>
        <ion-buttons slot="end">
          <ion-chip color="success" (click)="moneyOutReportData?.downloadExcel()">Download Excel</ion-chip>
          <ion-chip color="tertiary" (click)="moneyOutReportData?.download()">Download PDF</ion-chip>
          <ion-button (click)="openMoneyOutReportModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">

      <ion-grid class="width-100-percent">

        <div class="container">
          <p class="margin-bottom10 font-size20">
            <img [src]="logoBase64" width="80" height="80" style="border-radius: 50%;">
            {{ selectedProfile?.legalName || selectedProfile?.profileName }}
          </p>
          <p class="header-wrapper margin-bottom10">Money Out Report</p>

          <div class="center1">
            <p>Duration : {{ moneyOutReportData?.duration }}</p><br>
            <p>Total MoneyOuts : {{ moneyOutReportData?.moneyOuts?.length }}</p>
            <p>Total MoneyOut Amount : &#8377;{{ moneyOutReportData?.totalMoneyOutsAmount }}</p>
          </div>

          <div class="container width-100-percent padding-left-right-none">
            <h1>Money Out Report</h1>
            <table class="main-table" width="100%">
              <thead>
                <th>Sr No</th>
                <th>Date</th>
                <th>Party Name</th>
                <th>Txn Type</th>
                <th>Txn No</th>
                <th>Total Amount</th>
                <th>Linked Purchase / Expense</th>
              </thead>
              <tbody>
                <tr *ngFor="let record of moneyOutReportData?.moneyOuts;let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ dateToDDMMYYY(record?.moneyOut?.billDateStamp) }}</td>
                  <td>{{ record?.moneyOut?.party?.name }}</td>
                  <td>{{ record?.moneyOut?.paymentMode }}</td>
                  <td>{{ record?.moneyOut?.billNo }}</td>
                  <td>{{ record?.moneyOut?.totalAmount }}</td>
                  <td>{{ record?.moneyOut['linkedPurchaseExpenseInfo'] || '-' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </ion-grid>

    </ion-content>
  </ng-template>
</ion-modal>


<ion-modal class="full-page-modal" [isOpen]="isExpenseReportModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar color="light">
        <ion-title>Expense Report</ion-title>
        <ion-buttons slot="end">
          <ion-chip color="success" (click)="expenseReportData?.downloadExcel()">Download Excel</ion-chip>
          <ion-chip color="tertiary" (click)="expenseReportData?.download()">Download PDF</ion-chip>
          <ion-button (click)="openExpenseReportModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">

      <ion-grid class="width-100-percent">

        <div class="container">
          <p class="margin-bottom10 font-size20">
            <img [src]="logoBase64" width="80" height="80" style="border-radius: 50%;">
            {{ selectedProfile?.legalName || selectedProfile?.profileName }}
          </p>
          <p class="header-wrapper margin-bottom10">Expense Report</p>

          <div class="center1">
            <p>Duration : {{ expenseReportData?.duration }}</p><br>
            <p>Total Expenses : {{ expenseReportData?.expenses?.length }}</p>
            <p>Total Expenses Amount : &#8377;{{ expenseReportData?.totalExpensesAmount }}</p>
          </div>

          <div class="container width-100-percent padding-left-right-none">
            <h1>Expense Report</h1>
            <table class="main-table" width="100%">
              <thead>
                <th>Sr No</th>
                <th>Date</th>
                <th>Party Name</th>
                <th>Expense Category</th>
                <th>Expense No</th>
                <th>Total Amount</th>
                <th>Amount Paid</th>
                <th>Txn Type</th>
              </thead>
              <tbody>
                <tr *ngFor="let record of expenseReportData?.expenses;let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ dateToDDMMYYY(record?.expense?.billDateStamp) }}</td>
                  <td>{{ record?.expense?.party?.name }}</td>
                  <td>{{ record?.expense?.category }}</td>
                  <td>{{ record?.expense?.billNo }}</td>
                  <td>{{ record?.expense?.totalAmount }}</td>
                  <td>{{ record?.expense?.amountPaid }}</td>
                  <td>{{ record?.expense?.['paymentMode'] }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </ion-grid>

    </ion-content>
  </ng-template>
</ion-modal>

<ion-modal class="full-page-modal party-report-modal" [isOpen]="isPartyReportModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar color="light">
        <ion-title>Party Report</ion-title>
        <ion-buttons slot="end">
          <ion-chip color="success" (click)="partyReportData?.downloadExcel()">Download Excel</ion-chip>
          <ion-chip color="tertiary" (click)="partyReportData?.download()">Download PDF</ion-chip>
          <ion-button (click)="openPartyReportModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">

      <ion-grid class="width-100-percent">

        <div class="container">
          <div class="card-wrapper">
            <div class="business-logo">
              <img [src]="logoBase64">
              <p>{{ selectedProfile?.legalName || selectedProfile?.profileName }}</p>
            </div>
            <p class="header-wrapper">Party Statement Report</p>

            <div class="blue-color">
              <p>To</p>
              <p class="margin-bottom10 font-size18">{{ partyReportData?.party?.name }}</p>
            </div>
            <div class="center1">
              <div class="flex space-b">
                <span>Start Date <br>{{ partyReportData?.startTime }}</span>
                <span>End Date <br>{{ partyReportData?.endTime }}</span>
              </div>
              <div class="flex d-col">
                <div class="r-c">
                  <span>Total Sale Amount</span>
                  <p class="amt">{{ partyReportData?.totalSalesAmount }}</p>
                </div>
                <div class="r-c">
                  <span>Total Purchase Amount</span>
                  <p class="amt">{{ partyReportData?.totalPurchasesAmount }}</p>
                </div>
                <div class="r-c">
                  <span>Total Money In Amount</span>
                  <p class="amt">{{ partyReportData?.totalMoneyInsAmount }}</p>
                </div>
                <div class="r-c">
                  <span>Total Money Out Amount</span>
                  <p class="amt">{{ partyReportData?.totalMoneyOutsAmount }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="container width-100-percent padding-left-right-none">
            <h1 style="color: white;">Party Statement Report</h1>
            <table class="main-table" width="100%">
              <thead>
                <th>Date</th>
                <th>TXN Type</th>
                <th>Invoice/Bill</th>
                <th>Credit</th>
                <th>Debit</th>
              </thead>
              <tbody>
                <ng-container *ngFor="let record of partyReportData?.records;let i = index">
                  <tr>
                    <td>{{ dateToDDMMYYY(record?.record?.billDateStamp) }}</td>
                    <td>{{ record?.txnType }}</td>
                    <td>{{ record?.record?.billNo }}</td>
                    <td>{{ record?.txnType==='Purchase' || record?.txnType==='Money In' ? record?.record?.totalAmount :
                      0 }}</td>
                    <td>{{ record?.txnType==='Sale' || record?.txnType==='Money Out' ? record?.record?.totalAmount : 0
                      }}</td>
                  </tr>
                  <tr *ngIf="record?.record?.billItems?.length">
                    <td width="10%"></td>
                    <td width="90%" colspan="4">
                      <table class="sub-table" width="100%">
                        <thead>
                          <th>#</th>
                          <th>Item Name</th>
                          <th>Qty</th>
                          <th>Unit</th>
                          <th>Rs/Unit</th>
                          <th>Amount</th>
                        </thead>
                        <tbody>
                          <tr *ngFor="let billItem of record?.record?.billItems;let i = index">
                            <td>{{ i + 1 }}</td>
                            <td>{{ billItem?.item?.itemName }}</td>
                            <td>{{ billItem?.quantity }}</td>
                            <td>{{ billItem?.unit }}</td>
                            <td>{{ billItem?.price }}</td>
                            <td>{{ billItem?.total }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>

      </ion-grid>

    </ion-content>
  </ng-template>
</ion-modal>

<ion-modal class="full-page-modal" [isOpen]="isPartyDetailReportModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar color="light">
        <ion-title>Party Details Report</ion-title>
        <ion-buttons slot="end">
          <ion-chip color="success" (click)="partyDetailReportData?.downloadExcel()">Download Excel</ion-chip>
          <!-- <ion-chip color="tertiary" (click)="partyDetailReportData?.download()">Download PDF</ion-chip> -->
          <ion-button (click)="openPartyDetailReportModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">

      <ion-grid class="width-100-percent">

        <div class="container">
          <p class="margin-bottom10 font-size20">
            <img [src]="logoBase64" width="80" height="80" style="border-radius: 50%;">
            {{ selectedProfile?.legalName || selectedProfile?.profileName }}
          </p>
          <p class="header-wrapper margin-bottom10">Party Details Report</p>

          <div class="center1">
            <p>Duration : {{ partyDetailReportData?.duration }}</p><br>
          </div>

          <div class="container horizontal-scroll width-100-percent padding-left-right-none">
            <h1>Party Details Report</h1>
            <table class="main-table" width="100%">
              <thead>
                <th>Sr No</th>
                <th>Party Name</th>
                <th>Phone</th>
                <th>Category</th>
                <th>Credit</th>
                <th>Type</th>
                <th>GSTIN</th>
                <th>Billing Type</th>
                <th>DOB</th>
                <th>Business Name</th>
                <th>Email</th>
                <th>Billing Address</th>
                <th>Billing Provience</th>
                <th>Billing Postal Code</th>
                <th>Delivery Address</th>
                <th>Delivery Provience</th>
                <th>Delivery Postal Code</th>
                <th>Payment Term</th>
                <th>Send Alerts</th>
                <th>Favourite Party</th>
              </thead>
              <tbody>
                <tr *ngFor="let party of partyDetailReportData?.parties;let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ party?.name }}</td>
                  <td>{{ party?.phone }}</td>
                  <td>{{ party?.category }}</td>
                  <td>{{ party?.credit }}</td>
                  <td>{{ party?.type }}</td>
                  <td>{{ party?.gstin }}</td>
                  <td>{{ party?.billingType }}</td>
                  <td>{{ dateToDDMMYYY(party?.dateOfBirth) }}</td>
                  <td>{{ party?.businessName }}</td>
                  <td>{{ party?.email }}</td>
                  <td>{{ party?.billingAddress }}</td>
                  <td>{{ party?.billingProvience }}</td>
                  <td>{{ party?.billingPostalCode }}</td>
                  <td>{{ party?.deliveryAddress }}</td>
                  <td>{{ party?.deliveryProvience }}</td>
                  <td>{{ party?.deliveryPostalCode }}</td>
                  <td>{{ party?.paymentTerm }}</td>
                  <td>{{ party?.sendAlerts ? 'Yes' : 'No' }}</td>
                  <td>{{ party?.isFavourite ? 'Yes' : 'No' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </ion-grid>

    </ion-content>
  </ng-template>
</ion-modal>

<ion-modal class="full-page-modal" [isOpen]="isPartyReceivablePayableReportModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar color="light">
        <ion-title>Payable Receivable Report</ion-title>
        <ion-buttons slot="end">
          <ion-chip color="success" (click)="partyReceivablePayableReportData?.downloadExcel()">Download
            Excel</ion-chip>
          <ion-chip color="tertiary" (click)="partyReceivablePayableReportData?.download()">Download PDF</ion-chip>
          <ion-button (click)="openPartyReceivablePayableReportModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">

      <ion-grid class="width-100-percent">

        <div class="container">
          <p class="margin-bottom10 font-size20">
            <img [src]="logoBase64" width="80" height="80" style="border-radius: 50%;">
            {{ selectedProfile?.legalName || selectedProfile?.profileName }}
          </p>
          <p class="header-wrapper margin-bottom10">Payable Receivable Report</p>

          <div class="center1">
            <p>Total Parties : {{ partyReceivablePayableReportData?.customerRecords?.length +
              partyReceivablePayableReportData?.supplierRecords?.length }}</p>
            <p>Total Payable Amount : {{ partyReceivablePayableReportData?.totalPayableAmount | currency: 'INR' }}</p>
            <p>Total Receivable Amount : {{ partyReceivablePayableReportData?.totalReceivableAmount | currency: 'INR' }}
            </p>
          </div>

          <div class="container width-100-percent padding-left-right-none">
            <h1>Payable Receivable Report</h1>
            <table class="main-table" width="100%">
              <thead>
                <th>Sr No</th>
                <th>Customer Name</th>
                <th>Customer Phone</th>
                <th>Payable Amount</th>
                <th>Receivable Amount</th>
              </thead>
              <tbody>
                <tr *ngFor="let record of partyReceivablePayableReportData?.customerRecords;let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ record?.party?.name }}</td>
                  <td>{{ record?.party?.phone }}</td>
                  <td>{{ record?.payableAmount }}</td>
                  <td>{{ record?.receivableAmount }}</td>
                </tr>
              </tbody>
            </table>
            <hr>
            <table class="main-table" width="100%">
              <thead>
                <th>Sr No</th>
                <th>Supplier Name</th>
                <th>Supplier Phone</th>
                <th>Payable Amount</th>
                <th>Receivable Amount</th>
              </thead>
              <tbody>
                <tr *ngFor="let record of partyReceivablePayableReportData?.supplierRecords;let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ record?.party?.name }}</td>
                  <td>{{ record?.party?.phone }}</td>
                  <td>{{ record?.payableAmount }}</td>
                  <td>{{ record?.receivableAmount }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </ion-grid>

    </ion-content>
  </ng-template>
</ion-modal>

<ion-modal class="full-page-modal" [isOpen]="isCampaignReportModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar color="light">
        <ion-title>Campaign Report</ion-title>
        <ion-buttons slot="end">
          <ion-chip color="success" (click)="partyCampaignReportData?.downloadExcel()">Download
            Excel</ion-chip>
          <ion-chip color="tertiary" (click)="partyCampaignReportData?.download()">Download PDF</ion-chip>
          <ion-button (click)="openCampaignReportModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">

      <ion-grid class="width-100-percent">

        <div class="container">
          <p class="margin-bottom10 font-size20">
            <img [src]="logoBase64" width="80" height="80" style="border-radius: 50%;">
            {{ selectedProfile?.legalName || selectedProfile?.profileName }}
          </p>
          <p class="header-wrapper margin-bottom10">Campaign Report</p>

          <div class="center1">
            <p>Campaign Id : {{ partyCampaignReportData?.campaignId }}</p>
            <p>Campaign Date : {{ partyCampaignReportData?.campaigeDate }}</p>
            <p>Campaign Message : {{ partyCampaignReportData?.campaignMessage }}</p>
            <p>Custom Campaign Value : {{ partyCampaignReportData?.campaignCustomInputs }}</p>
            <p>Total Send : {{ partyCampaignReportData?.campaignTotalSend }}</p>
            <p>Total Delivered : {{ partyCampaignReportData?.campaignTotalDelivered }}</p>
            <p>Total Read : {{ partyCampaignReportData?.campaignTotalRead }}</p>
            <p>Total Replies : {{ partyCampaignReportData?.campaignTotalReplies }}</p>


          </div>

          <div class="container width-100-percent padding-left-right-none">
            <h1>Campaign Report</h1>
            <table class="main-table" width="100%">
              <thead>
                <th>Sr No</th>
                <th>Name</th>
                <th>Phone</th>
                <th style="width: 400px;">Message</th>
                <th>Send</th>
                <th>Delivered</th>
                <th>Read</th>
                <th>Reply</th>
                <th>Reply Time</th>

              </thead>
              <tbody>
                <tr *ngFor="let record of partyCampaignReportData?.campaignReportData;let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ record?.name }}</td>
                  <td>{{ record?.phone }}</td>
                  <td>{{ record?.messageBody }}</td>
                  <td>{{ record?.send }}</td>
                  <td>{{ record?.delivered }}</td>
                  <td>{{ record?.read }}</td>
                  <td>{{ record?.reply }}</td>
                  <td>{{ record?.replyTime }}</td>

                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </ion-grid>

    </ion-content>
  </ng-template>
</ion-modal>

<ion-modal class="full-page-modal" [isOpen]="isStockSummaryReportModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar color="light">
        <ion-title>Stock Value Report</ion-title>
        <ion-buttons slot="end">
          <ion-chip color="success" (click)="stockSummaryReportData?.downloadExcel()">Download Excel</ion-chip>
          <ion-chip color="tertiary" (click)="stockSummaryReportData?.download()">Download PDF</ion-chip>
          <ion-button (click)="openStockSummaryReportModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">

      <ion-grid class="width-100-percent">

        <div class="container">
          <p class="margin-bottom10 font-size20">
            <img [src]="logoBase64" width="80" height="80" style="border-radius: 50%;">
            {{ selectedProfile?.legalName || selectedProfile?.profileName }}
          </p>
          <p class="header-wrapper margin-bottom10">Stock Value Report</p>

          <div class="center1">
            <p>Date : {{ stockSummaryReportData?.duration }}</p><br>
            <p>Total unique items : {{ stockSummaryReportData?.items?.length }} </p>
            <p>Total low stock items : {{ stockSummaryReportData?.totalLowStockItems }} </p>
            <p>Total stock value : {{ stockSummaryReportData?.totalStocksValue | currency: 'INR' }}</p>
            <p>Total stock valuation : {{ stockSummaryReportData?.totalStocksValuation | currency: 'INR' }}</p>
          </div>

          <div class="container width-100-percent padding-left-right-none">
            <h1>Stock Value Report</h1>
            <table class="main-table" width="100%">
              <thead>
                <th>Sr No</th>
                <th>Item Name</th>
                <th>Item Category</th>
                <th>Current Stock</th>
                <th>Minimum Stock</th>
                <th>Sale Price</th>
                <th>Purchase Price</th>
                <th>Stock Value</th>
                <th>Stock Valuation</th>
              </thead>
              <tbody>
                <tr *ngFor="let record of stockSummaryReportData?.items;let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ record?.item?.itemName }}</td>
                  <td>{{ record?.item?.category }}</td>
                  <td>{{ record?.item?.stock }}</td>
                  <td>{{ record?.item?.minStock || 0 }}</td>
                  <td>{{ record?.item?.sellPrice }}</td>
                  <td>{{ record?.item?.purchasePrice }}</td>
                  <td>{{ record?.stockValue }}</td>
                  <td>{{ record?.stockValuation }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </ion-grid>

    </ion-content>
  </ng-template>
</ion-modal>


<ion-modal class="full-page-modal" [isOpen]="isItemSaleReportModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar color="light">
        <ion-title>Item Sale Report</ion-title>
        <ion-buttons slot="end">
          <ion-chip color="success" (click)="itemSaleReportData?.downloadExcel()">Download Excel</ion-chip>
          <ion-chip color="tertiary" (click)="itemSaleReportData?.download()">Download PDF</ion-chip>
          <ion-button (click)="openItemSaleReportModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">

      <ion-grid class="width-100-percent">

        <div class="background-gradient" style="padding: 16px;">
          <div class="container">
            <p class="margin-bottom10 font-size20">
              <img [src]="logoBase64" width="80" height="80" style="border-radius: 50%;">
              {{ selectedProfile?.legalName || selectedProfile?.profileName }}
            </p>
            <p class="header-wrapper margin-bottom10">Item Sale Report</p>

            <div class="center1">
              <p>Duration: {{ itemSaleReportData?.duration }}</p>
              <p>Total Sale Quantity: {{ itemSaleReportData?.totalSalesQuantity }}</p>
              <p>Total Sale Amount: {{ itemSaleReportData?.totalSalesAmount | currency: 'INR' }}</p>
            </div>

            <div class="container width-100-percent padding-left-right-none">
              <h1>Item Sale Report</h1>
              <table class="main-table" width="100%">
                <thead>
                  <th>Sr No</th>
                  <th>Item Name</th>
                  <th>Item Category</th>
                  <th>Total Sale Quantity</th>
                  <th>Total Sale Amount</th>
                </thead>
                <tbody>
                  <tr *ngFor="let record of itemSaleReportData?.records;let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>{{ record?.itemName }}</td>
                    <td>{{ record?.category }}</td>
                    <td>{{ record?.saleQuantity }}</td>
                    <td>{{ record?.saleAmount | currency: 'INR' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </ion-grid>

    </ion-content>
  </ng-template>
</ion-modal>

<ion-modal class="full-page-modal" [isOpen]="isItemReportModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar color="light">
        <ion-title>Item Report</ion-title>
        <ion-buttons slot="end">
          <ion-chip color="success" (click)="itemReportData?.downloadExcel()">Download Excel</ion-chip>
          <ion-chip color="tertiary" (click)="itemReportData?.download()">Download PDF</ion-chip>
          <ion-button (click)="openItemReportModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">

      <ion-grid class="width-100-percent">

        <div class="container">
          <p class="margin-bottom10 font-size20">
            <img [src]="logoBase64" width="80" height="80" style="border-radius: 50%;">
            {{ selectedProfile?.legalName || selectedProfile?.profileName }}
          </p>
          <p class="header-wrapper margin-bottom10">Item Report</p>

          <div class="center1">
            <p>Item Name: {{ itemReportData?.item?.itemName }}</p>
            <p>Item Category: {{ itemReportData?.item?.category }}</p>
            <p class="margin-bottom10">Duration: {{ itemReportData?.duration }} </p>

            <p *ngIf="itemReportData?.totalSalesQuantity">Total Sale Quantity: {{ itemReportData?.totalSalesQuantity }}
            </p>
            <p *ngIf="itemReportData?.totalSalesAmount" class="margin-bottom10">Total Sale Amount: {{
              itemReportData?.totalSalesAmount | currency: 'INR' }}</p>

            <p *ngIf="itemReportData?.totalPurchasesQuantity">Total Purchase Quantity: {{
              itemReportData?.totalPurchasesQuantity }}</p>
            <p *ngIf="itemReportData?.totalPurchasesAmount" class="margin-bottom10">Total Purchase Amount: {{
              itemReportData?.totalPurchasesAmount | currency: 'INR' }}</p>

            <p *ngIf="itemReportData?.totalAddedQuantity">Quantity Added: {{ itemReportData?.totalAddedQuantity }}</p>
            <p *ngIf="itemReportData?.totalReducedQuantity">Quantity Reduced: {{ itemReportData?.totalReducedQuantity }}
            </p>

          </div>

          <div class="container width-100-percent padding-left-right-none">
            <h1>Item Report</h1>
            <table class="main-table" width="100%">
              <thead>
                <th>Sr No</th>
                <th>Date</th>
                <th>Txn Type</th>
                <th>Txn No</th>
                <th>Party Name</th>
                <th>Qty/Unit</th>
                <th>Sale Price</th>
                <th>Purchase Price</th>
                <th>Discount</th>
                <th>Tax</th>
                <th>Total Amount</th>
              </thead>
              <tbody>
                <tr *ngFor="let record of itemReportData?.records;let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ dateToDDMMYYY(record?.record?.billDateStamp || record?.record?.updatedStamp ||
                    record?.record?.createdStamp) }}</td>
                  <td>{{ record?.txnType }}</td>
                  <td>{{ record?.record?.billNo || '-'}}</td>
                  <td>{{ record?.record?.party?.name || '-' }}</td>
                  <td>{{ record?.billItem?.quantity || MATHABS(record?.record?.quantity) }}</td>
                  <td *ngIf="record?.txnType==='Sale'">{{ record?.billItem?.price || '-' }}</td>
                  <td *ngIf="record?.txnType==='Purchase'">{{ record?.billItem?.item?.sellPrice || '-' }}</td>
                  <td *ngIf="record?.txnType==='Purchase'">{{ record?.billItem?.price || '-' }}</td>
                  <td *ngIf="record?.txnType==='Sale'">{{ record?.billItem?.item?.purchasePrice || '-' }}</td>
                  <td>{{ record?.billItem?.discount || '-' }}</td>
                  <td>{{ record?.billItem?.itemTotalTaxAmount || '-' }}</td>
                  <td>{{ record?.billItem?.total || '-' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </ion-grid>

    </ion-content>
  </ng-template>
</ion-modal>

<ion-modal class="full-page-modal" [isOpen]="isItemDetailReportModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar color="light">
        <ion-title>Item Details Report</ion-title>
        <ion-buttons slot="end">
          <ion-chip color="success" (click)="itemDetailReportData?.downloadExcel()">Download Excel</ion-chip>
          <!-- <ion-chip color="tertiary" (click)="itemDetailReportData?.download()">Download PDF</ion-chip> -->
          <ion-button (click)="openItemDetailReportModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">

      <ion-grid class="width-100-percent">

        <div class="container">
          <p class="margin-bottom10 font-size20">
            <img [src]="logoBase64" width="80" height="80" style="border-radius: 50%;">
            {{ selectedProfile?.legalName || selectedProfile?.profileName }}
          </p>
          <p class="header-wrapper margin-bottom10">Item Details Report</p>

          <div class="center1">
            <p>Duration : {{ itemDetailReportData?.duration }}</p><br>
          </div>

          <div class="container horizontal-scroll width-100-percent padding-left-right-none">
            <h1>Item Details Report</h1>
            <table class="main-table" width="100%">
              <thead>
                <th>Sr No</th>
                <th>Item Name</th>
                <th>Sell Price</th>
                <th>Purchase Price</th>
                <th>Category</th>
                <th>Stock</th>
                <th>Type</th>
                <th>MRP</th>
                <th>Expiry Date</th>
                <th>Brand Name</th>
                <th>Wholesale Price</th>
                <th>Wholesale Min CutOff Qty</th>
                <th>Item Code</th>
                <th>Barcode</th>
                <th>Description</th>
                <th>Minimum Stock</th>
                <th>Storage Location</th>
                <th>Online DukKan Item</th>
                <th>Tax Percentage</th>
                <th>cess Percentage</th>
                <th>Sell Price Inclusive Tax</th>
                <th>Primary Unit</th>
                <th>Secondary Unit</th>
                <th>HSN</th>
                <th>Convert Ratio</th>
                <th>Convert Ratio R</th>
                <th>Note</th>
                <th>Discount</th>
                <th>Discount Percentage</th>
              </thead>
              <tbody>
                <tr *ngFor="let record of itemDetailReportData?.items;let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ record?.item?.itemName }}</td>
                  <td>{{ record?.item?.sellPrice }}</td>
                  <td>{{ record?.item?.purchasePrice }}</td>
                  <td>{{ record?.item?.category }}</td>
                  <td>{{ record?.item?.stock }}</td>
                  <td>{{ record?.item?.type }}</td>
                  <td>{{ record?.item?.mrp }}</td>
                  <td>{{ dateToDDMMYYY(record?.item?.expiryDate) }}</td>
                  <td>{{ record?.item?.brandName }}</td>
                  <td>{{ record?.item?.wholesalePrice }}</td>
                  <td>{{ record?.item?.wholesaleMinCutOffQty }}</td>
                  <td>{{ record?.item?.itemCode }}</td>
                  <td>{{ record?.item?.barcode }}</td>
                  <td>{{ record?.item?.description }}</td>
                  <td>{{ record?.item?.minStock }}</td>
                  <td>{{ record?.item?.storageLocation }}</td>
                  <td>{{ record?.item?.onlineDukanItem ? 'Yes' : 'No' }}</td>
                  <td>{{ record?.item?.taxPercentage }}</td>
                  <td>{{ record?.item?.cessPercentage }}</td>
                  <td>{{ record?.item?.spIncTax ? 'Yes' : 'No' }}</td>
                  <td>{{ record?.item?.primaryUnit }}</td>
                  <td>{{ record?.item?.secondaryUnit }}</td>
                  <td>{{ record?.item?.hsn }}</td>
                  <td>{{ record?.item?.convertRatio }}</td>
                  <td>{{ record?.item?.convertRatioR }}</td>
                  <td>{{ record?.item?.note }}</td>
                  <td>{{ record?.discount}}</td>
                  <td>{{ record?.discountPercentage }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </ion-grid>

    </ion-content>
  </ng-template>
</ion-modal>

<ion-modal class="full-page-modal" [isOpen]="isDayBookReportModalOpen" [backdropDismiss]="false"
  (willDismiss)="closeDayBookReport()">
  <ng-template>
    <ion-header class="day-book-report-header">
      <ion-toolbar color="light">
        <ion-title>{{selectedReport=='CutOffDayBookReport' ? 'Cut Off Day Report' : 'Day Book Report'}}</ion-title>
        <ion-buttons slot="end">
          <ion-chip color="primary" (click)="dayBookReportData?.print()">Print</ion-chip>
          <ion-button (click)="openDayBookReportModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding day-book-report-content">

      <ion-grid class="width-100-percent">

        <div class="container-chart">

          <div>
            {{ dayBookReportData?.duration }}
          </div>

          <div class="section-left header day-book-chart-header">
            Particulars
          </div>

          <div class="section-right header">
            Amount
          </div>

          <br>
          <div class="section-left">
            Sale
          </div>

          <div class="section-right">
            {{ dayBookReportData?.totalSalesAmount | currency: 'INR' }}
          </div>

          <br>
          <div class="section-left">
            Purchase
          </div>

          <div class="section-right">
            {{ dayBookReportData?.totalPurchasesAmount | currency: 'INR' }}
          </div>

          <br>
          <div class="section-left">
            Money In
          </div>

          <div class="section-right success">
            {{ dayBookReportData?.totalMoneyInsAmount | currency: 'INR' }}
          </div>

          <br>
          <div class="section-left">
            Money In Cash
          </div>

          <div class="section-right success">
            {{ dayBookReportData?.totalMoneyInsCashAmount | currency: 'INR' }}
          </div>

          <br>
          <div class="section-left">
            Money In UPI
          </div>

          <div class="section-right success">
            {{ dayBookReportData?.totalMoneyInsUPIAmount | currency: 'INR' }}
          </div>

          <br>
          <div class="section-left">
            Money In Cheque
          </div>

          <div class="section-right danger">
            {{ dayBookReportData?.totalMoneyInsChequeAmount | currency: 'INR' }}
          </div>

          <br>
          <div class="section-left">
            Money Out
          </div>

          <div class="section-right success">
            {{ dayBookReportData?.totalMoneyOutsAmount | currency: 'INR' }}
          </div>

          <br>
          <div class="section-left">
            Money Out Cash
          </div>

          <div class="section-right success">
            {{ dayBookReportData?.totalMoneyOutsCashAmount | currency: 'INR' }}
          </div>

          <br>
          <div class="section-left">
            Money Out UPI
          </div>

          <div class="section-right success">
            {{ dayBookReportData?.totalMoneyOutsUPIAmount | currency: 'INR' }}
          </div>

          <br>
          <div class="section-left">
            Money Out Cheque
          </div>

          <div class="section-right danger">
            {{ dayBookReportData?.totalMoneyOutsChequeAmount | currency: 'INR' }}
          </div>

        </div>

        <div class="container-rows" *ngIf="dayBookReportData?.records?.length">
          <div class="record-row" *ngFor="let record of dayBookReportData?.records">
            <span class="float-left">{{ record?.txnType }}</span>
            <span class="float-right">{{ record?.record?.totalAmount | currency: 'INR' }}</span>
            <br>
            <span class="float-left light-color">{{ dateToDDMMYYY(record?.record?.billDateStamp) }}</span>
            <span class="float-right light-color">{{ record?.record?.billNo }}</span>
          </div>
          <br>
        </div>

      </ion-grid>

    </ion-content>
  </ng-template>
</ion-modal>

<ion-modal class="full-page-modal" [isOpen]="isAllProfilesDayBookReportModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar color="light">
        <ion-title>All Profiles Day Book Report</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="openAllProfilesDayBookReportModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">

      <ion-grid class="width-100-percent">

        <ng-container *ngFor="let profile of allProfilesDayBookReportData?.profiles">


          <div class="container-chart">
            <div>
              <ion-button fill="clear">{{ profile?.profile?.legalName || profile?.profile?.profileName }} ( {{
                profile?.profile?._localUUID?.substr(-4) }} )</ion-button>
            </div>

            <div>
              {{ dayBookReportData?.duration }}
            </div>

            <div class="section-left header">
              Particulars
            </div>

            <div class="section-right header">
              Amount
            </div>

            <br>
            <div class="section-left">
              Sale
            </div>

            <div class="section-right">
              {{ profile?.totalSalesAmount | currency: 'INR' }}
            </div>

            <br>
            <div class="section-left">
              Purchase
            </div>

            <div class="section-right">
              {{ profile?.totalPurchasesAmount | currency: 'INR' }}
            </div>

            <br>
            <div class="section-left">
              Money In
            </div>

            <div class="section-right success">
              {{ profile?.totalMoneyInsAmount | currency: 'INR' }}
            </div>

            <br>
            <div class="section-left">
              Money In Cash
            </div>

            <div class="section-right success">
              {{ profile?.totalMoneyInsCashAmount | currency: 'INR' }}
            </div>

            <br>
            <div class="section-left">
              Money In UPI
            </div>

            <div class="section-right success">
              {{ profile?.totalMoneyInsUPIAmount | currency: 'INR' }}
            </div>

            <br>
            <div class="section-left">
              Money In Cheque
            </div>

            <div class="section-right danger">
              {{ profile?.totalMoneyInsChequeAmount | currency: 'INR' }}
            </div>

            <br>
            <div class="section-left">
              Money Out
            </div>

            <div class="section-right success">
              {{ profile?.totalMoneyOutsAmount | currency: 'INR' }}
            </div>

            <br>
            <div class="section-left">
              Money Out Cash
            </div>

            <div class="section-right success">
              {{ profile?.totalMoneyOutsCashAmount | currency: 'INR' }}
            </div>

            <br>
            <div class="section-left">
              Money Out UPI
            </div>

            <div class="section-right success">
              {{ profile?.totalMoneyOutsUPIAmount | currency: 'INR' }}
            </div>

            <br>
            <div class="section-left">
              Money Out Cheque
            </div>

            <div class="section-right danger">
              {{ profile?.totalMoneyOutsChequeAmount | currency: 'INR' }}
            </div>

          </div>

        </ng-container>


      </ion-grid>

    </ion-content>
  </ng-template>
</ion-modal>

<ion-modal class="full-page-modal" [isOpen]="isIngredientReportModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar color="light">
        <ion-title>Ingredient Report</ion-title>
        <ion-buttons slot="end">
          <ion-chip color="success" (click)="ingredientReportData?.downloadExcel()">Download Excel</ion-chip>
          <ion-chip color="tertiary" (click)="ingredientReportData?.download()">Download PDF</ion-chip>
          <ion-button (click)="openIngredientReportModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">

      <ion-grid class="width-100-percent">

        <div class="container">
          <p class="margin-bottom10 font-size20">
            <img [src]="logoBase64" width="80" height="80" style="border-radius: 50%;">
            {{ selectedProfile?.legalName || selectedProfile?.profileName }}
          </p>

          <p class="header-wrapper margin-bottom10">Ingredient Report</p>

          <div class="center1">
            <p>Duration : {{ ingredientReportData?.duration }}</p><br>
            <p>Total Ingredients : {{ ingredientReportData?.records?.length }}</p>
          </div>

          <div class="container width-100-percent padding-left-right-none">
            <h1>Ingredient Report</h1>

            <table class="main-table" width="100%">
              <thead>
                <tr>
                  <th>Sr No</th>
                  <th>Ingradient Name</th>
                  <th>Category</th>
                  <th>Opening Stock</th>
                  <th>Closing Stock</th>
                  <th>Unit</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let record of ingredientReportData?.records;let i = index">
                  <tr>
                    <td>{{ i + 1 }}</td>
                    <td>{{ record?.ingredient?.name }}</td>
                    <td>{{ record?.ingredient?.category }}</td>
                    <td>{{ record?.ingredient?.stock + record?.totalConsumedQuantity }}</td>
                    <td>{{ record?.ingredient?.stock }}</td>
                    <td>{{ record?.ingredient?.unit }}</td>
                  </tr>
                </ng-container>

              </tbody>
            </table>


          </div>
        </div>

      </ion-grid>

    </ion-content>
  </ng-template>
</ion-modal>

<ion-modal class="full-page-modal" [isOpen]="isSaleWiseIngredientReportModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar color="light">
        <ion-title>Sale Wise Ingredient Report</ion-title>
        <ion-buttons slot="end">
          <ion-chip color="success" (click)="saleWiseIngredientReportData?.downloadExcel()">Download Excel</ion-chip>
          <ion-chip color="tertiary" (click)="saleWiseIngredientReportData?.download()">Download PDF</ion-chip>
          <ion-button (click)="openSaleWiseIngredientReportModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">

      <ion-grid class="width-100-percent">

        <div class="container">
          <p class="margin-bottom10 font-size20">
            <img [src]="logoBase64" width="80" height="80" style="border-radius: 50%;">
            {{ selectedProfile?.legalName || selectedProfile?.profileName }}
          </p>

          <p class="header-wrapper margin-bottom10">Sale Wise Ingredient Report</p>

          <div class="center1">
            <p>Duration : {{ saleWiseIngredientReportData?.duration }}</p><br>
            <p>Total Sales : {{ saleWiseIngredientReportData?.sales?.length }}</p>
            <p>Total Sale Quantity : {{ saleWiseIngredientReportData?.totalSalesQuantity }}</p>
            <p>Total Sale Amount : {{ saleWiseIngredientReportData?.totalSalesAmount | currency: 'INR' }}</p>
          </div>

          <div class="container width-100-percent padding-left-right-none">
            <h1>Sale Wise Ingredient Report </h1>

            <table class="main-table" width="100%">
              <thead>
                <tr>
                  <th>Sr No</th>
                  <th>Date</th>
                  <th>TXN No</th>
                  <th>Party Name</th>
                  <th>Party Phone</th>
                  <th>Total Quantity</th>
                  <th>Total Tax</th>
                  <th>Total Amount<br> ( inc taxes )</th>
                  <th>Created By</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let record of saleWiseIngredientReportData?.sales;let i = index">
                  <tr>
                    <td>{{ i + 1 }}</td>
                    <td>{{ dateToDDMMYYY(record?.sale?.billDateStamp) }}</td>
                    <td>{{ record?.sale?.billNo }}</td>
                    <td>{{ record?.sale?.party?.name }}</td>
                    <td>{{ record?.sale?.party?.phone }}</td>
                    <td>{{ record?.totalBillItemQuantity }}</td>
                    <td>{{ record?.totalTax | currency: 'INR' }}</td>
                    <td>{{ record?.sale?.totalAmount | currency: 'INR' }}</td>
                    <td>{{ record?.sale?.createdByName || record?.sale?.createdBy }}</td>
                  </tr>
                  <tr *ngIf="record?.sale?.billItems?.length">
                    <td width="10%"></td>
                    <td width="90%" colspan="8">
                      <table class="sub-table" width="100%">
                        <thead>
                          <th>#</th>
                          <th>Item Name</th>
                          <th>Qty</th>
                          <th>Unit</th>
                          <th>Rs/Unit</th>
                          <th>Amount</th>
                        </thead>
                        <tbody>
                          <ng-container *ngFor="let billItem of record?.sale?.billItems;let j = index">
                            <tr>
                              <td>{{ j + 1 }}</td>
                              <td>{{ billItem?.item?.itemName }}</td>
                              <td>{{ billItem?.quantity }}</td>
                              <td>{{ billItem?.unit }}</td>
                              <td>{{ billItem?.price| currency: 'INR' }}</td>
                              <td>{{ billItem?.total | currency: 'INR' }}</td>
                            </tr>
                            <tr *ngIf="billItem?.item?.itemIngredients?.length">
                              <td width="10%"></td>
                              <td width="90%" colspan="5">
                                <table class="sub-table" width="100%">
                                  <thead>
                                    <th>#</th>
                                    <th>Ingredient Name</th>
                                    <th>Consumed Qty</th>
                                    <th>Unit</th>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let itemIngredient of billItem?.item?.itemIngredients;let k = index">
                                      <td>{{ k + 1 }}</td>
                                      <td>{{ itemIngredient?.ingredient?.name }}</td>
                                      <td>{{ capFractionsToSix((itemIngredient?.quantity || 0) * (billItem?.quantity ||
                                        0)) }}</td>
                                      <td>{{ itemIngredient?.ingredient?.unit }}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </ng-container>
                        </tbody>
                      </table>
                    </td>
                  </tr>

                </ng-container>
              </tbody>
            </table>


          </div>
        </div>

      </ion-grid>

    </ion-content>
  </ng-template>
</ion-modal>

<!-- Raw Material Modal Starts -->

<ion-modal class="full-page-modal" [isOpen]="isRawMaterialReportModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar color="light">
        <ion-title>Raw Material Report</ion-title>
        <ion-buttons slot="end">
          <ion-chip color="success" [hidden]="!loadingRawMaterialCalculation"
            (click)="rawMaterialReportData?.downloadExcel()">Download Excel</ion-chip>
          <ion-button (click)="openRawMaterialReportModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding" [hidden]="!loadingRawMaterialCalculation">

      <ion-grid class="width-100-percent">

        <div class="container">
          <p class="margin-bottom10 font-size20">
            <img [src]="logoBase64" width="80" height="80" style="border-radius: 50%;">
            {{ selectedProfile?.legalName || selectedProfile?.profileName }}
          </p>

          <p class="header-wrapper margin-bottom10">Raw Material Report</p>

          <div class="center1">
            <p>Duration : {{ rawMaterialReportData?.duration }}</p>
          </div>

          <div class="info-center">
            <div>
              <strong>Metric Information:</strong><br>
              <p><span>Sale :</span> This value indicates total sale of corresponding date.</p>
              <p><span>Cost of Raw Material :</span> This value indicates total cost incurred on corresponding date. It
                is calculated by considering average price of raw material in past 30days.</p>
              <p><span>% Cost of Raw Material :</span> This value indicates percentage cost of material w.r.t total
                sale.</p>
              <p><span>Opening Stock :</span> This value indicates stock available at the start of the day.</p>
              <p><span>Sales Consumption :</span> This value indicates how much quantity of raw material is consumed in
                preparing the items sold (as per quantities set by you for every item)</p>
              <p><span>Actual Consumption :</span> This value indicates how much quantity is actually consumed in a day.
                It is calculated based on EOD stock enteries.</p>
            </div>
          </div>

          <div class="container width-100-percent raw-material-table scroll-x padding-left-right-none">
            <h1>Raw Material Report </h1>

            <table class="main-table" width="100%">
              <thead>
                <tr>
                  <th>Sr. No.</th>
                  <th>Overview / Item / Transactions</th>
                  <ng-container *ngFor="let day of rawMaterialReportData?.days|keyvalue : returnZero; let i= index;">
                    <th>{{dateToDDMMYYY(+day?.key)}}</th>
                  </ng-container>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td></td>
                  <td>Sale</td>
                  <td *ngFor="let day of rawMaterialReportData?.days|keyvalue : returnZero;">
                    {{day?.value?.totalSaleAmount?.displayValue || 'NA'}}</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Cost of Raw Material</td>
                  <td *ngFor="let day of rawMaterialReportData?.days|keyvalue : returnZero;">
                    {{day?.value?.averageCostOfRawMaterial?.displayValue || 'NA'}}</td>
                </tr>
                <tr>
                  <td></td>
                  <td>%of Cost of Raw Material</td>
                  <td *ngFor="let day of rawMaterialReportData?.days|keyvalue : returnZero;">
                    {{day?.value?.averageCostPercentageOfRawMaterial?.displayValue || 'NA'}}</td>
                </tr>
                <ng-container
                  *ngFor="let ingredient of rawMaterialReportData?.ingredients|keyvalue : returnZero;let i = index;">
                  <tr>
                    <td>{{i+1}}</td>
                    <td>
                      {{rawMaterialReportData?.name[ingredient?.key]}}
                    </td>
                  </tr>
                  <tr *ngFor="let operation of ingredient?.value|keyvalue : returnZero;">
                    <td></td>
                    <td>{{checkOperation(operation?.key)}}</td>
                    <td *ngFor="let qty of operation?.value|keyvalue: returnZero;">
                      {{qty?.value?.displayValue}}
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>

          </div>
        </div>

      </ion-grid>

    </ion-content>
  </ng-template>
</ion-modal>

<!-- Raw Material Modal Ends -->

<!-- Raw Material Cost Modal Starts -->

<ion-modal class="full-page-modal" [isOpen]="isRawMaterialCostReportModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar color="light">
        <ion-title>Raw Material Cost Report</ion-title>
        <ion-buttons slot="end">
          <ion-chip color="success" [hidden]="!loadingRawMaterialCostCalculation"
            (click)="rawMaterialCostReportData?.downloadExcel()">Download Excel</ion-chip>
          <ion-button (click)="openRawMaterialCostReportModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding" [hidden]="!loadingRawMaterialCostCalculation">

      <ion-grid class="width-100-percent">

        <div class="container">
          <p class="margin-bottom10 font-size20">
            <img [src]="logoBase64" width="80" height="80" style="border-radius: 50%;">
            {{ selectedProfile?.legalName || selectedProfile?.profileName }}
          </p>

          <p class="header-wrapper margin-bottom10">Raw Material Cost Report</p>

          <div class="center1">
            <p>Duration : {{ rawMaterialCostReportData?.duration }}</p>
          </div>

          <div class="info-center">
            <div>
              <strong>Metric Information:</strong><br>
              <p><span>Total Sale :</span> This value indicates total sale of corresponding date.</p>
              <p><span>Cost of Raw Material (Type: Ideal) :</span> This value indicates ideal cost of raw material
                incurred in generating total sale. It is calculated based on the consumption set by you in the software.
              </p>
              <p><span>% Cost of Raw Material (Type: Ideal):</span> This value indicates ideal percentage cost of raw
                material w.r.t total sale.</p>
              <p><span>Cost of Raw Material (Type: Actual) :</span> This value indicates actual cost of raw material
                incurred in generating total sale. It is calculated based on the EOD enteries.</p>
              <p><span>% Cost of Raw Material (Type: Actual):</span> This value indicates actual percentage cost of raw
                material w.r.t total sale.</p>
            </div>
          </div>

          <div class="container width-100-percent raw-material-cost-table scroll-x padding-left-right-none">
            <h1>Raw Material Cost Report </h1>

            <table class="main-table" width="100%">
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Components</th>
                  <ng-container
                    *ngFor="let day of rawMaterialCostReportData?.days|keyvalue : returnZero; let i= index;">
                    <th>{{dateToDDMMYYY(+day?.key)}}</th>
                  </ng-container>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td></td>
                  <td>Total Sale (in &#8377;)</td>
                  <td *ngFor="let day of rawMaterialCostReportData?.days|keyvalue : returnZero;">
                    {{day?.value?.totalSaleAmount?.displayValue || 'NA'}}</td>
                </tr>
                <tr>
                  <td rowspan="2">Ideal</td>
                  <td>Cost of Raw Material (in &#8377;)</td>
                  <td *ngFor="let day of rawMaterialCostReportData?.days|keyvalue : returnZero;">
                    {{day?.value?.averageIdealCostOfRawMaterial?.displayValue || 'NA'}}</td>
                </tr>
                <tr class="joint-row">
                  <td>%of Cost of Raw Material</td>
                  <td *ngFor="let day of rawMaterialCostReportData?.days|keyvalue : returnZero;">
                    {{day?.value?.averageIdealCostPercentageOfRawMaterial?.displayValue || 'NA'}}</td>
                </tr>
                <tr>
                  <td rowspan="2">Actual</td>
                  <td>Cost of Raw Material (in &#8377;)</td>
                  <td *ngFor="let day of rawMaterialCostReportData?.days|keyvalue : returnZero;">
                    {{day?.value?.averageActualCostOfRawMaterial?.displayValue || 'NA'}}</td>
                </tr>
                <tr class="joint-row">
                  <td>%of Cost of Raw Material</td>
                  <td *ngFor="let day of rawMaterialCostReportData?.days|keyvalue : returnZero;">
                    {{day?.value?.averageActualCostPercentageOfRawMaterial?.displayValue || 'NA'}}</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Cost Difference (Actual Cost - Ideal Cost) (in &#8377;)</td>
                  <td *ngFor="let day of rawMaterialCostReportData?.days|keyvalue : returnZero;">
                    {{day?.value?.averageCostDifferenceOfRawMaterial?.displayValue || 'NA'}}</td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>

      </ion-grid>

    </ion-content>
  </ng-template>
</ion-modal>

<!-- Raw Material Cost Modal Ends -->

<!-- Raw Material Consumption Modal Starts -->

<ion-modal class="full-page-modal" [isOpen]="isRawMaterialConsumptionReportModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar color="light">
        <ion-title>Raw Material Consumption Report</ion-title>
        <ion-buttons slot="end">
          <ion-chip color="success" [hidden]="!loadingRawMaterialConsumptionCalculation"
            (click)="rawMaterialConsumptionReportData?.downloadExcel()">Download Excel</ion-chip>
          <ion-button (click)="openRawMaterialConsumptionReportModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding" [hidden]="!loadingRawMaterialConsumptionCalculation">

      <ion-grid class="width-100-percent">

        <div class="container">
          <p class="margin-bottom10 font-size20">
            <img [src]="logoBase64" width="80" height="80" style="border-radius: 50%;">
            {{ selectedProfile?.legalName || selectedProfile?.profileName }}
          </p>

          <p class="header-wrapper margin-bottom10">Raw Material Consumption Report</p>

          <div class="center1">
            <p>Duration : {{ rawMaterialConsumptionReportData?.duration }}</p>
          </div>

          <div class="info-center">
            <div>
              <strong>Metric Information:</strong><br>
              <p><span>Transfer :</span> This value indicates how much quantity is transfered on the corresponding date.
              </p>
              <p><span>Return :</span> This value indicates how much quantity is returned on the corresponding date.</p>
              <p><span>Scrap :</span> This value indicates how much quantity is scrapped (wasted) on the corresponding
                date.</p>
              <p><span>Purchase :</span> This value indicates how much quantity is purchased on the corresponding date.
              </p>
              <p><span>Opening Stock :</span> This value indicates how much stock available at the start of the day. It
                is calculated based on EOD enteries.</p>
              <p><span>Sales Consumption :</span> This value indicates how much quantity of raw material is consumed in
                preparing the items (as per quantities set by you for every item).</p>
              <p><span>Actual Consumption :</span> This value indicates how much quantity of raw material is actually
                consumed in a day. It is calculated based on EOD stock, Transfer, Return & Scrap enteries.</p>
            </div>
          </div>

          <div class="container width-100-percent raw-material-consumption-table scroll-x padding-left-right-none">
            <h1>Raw Material Consumption Report </h1>
            <div class="raw-material-consumption-table-container">
              <table class="main-table" width="100%">
                <thead>
                  <tr>
                    <th>Sr. No.</th>
                    <th>Raw Material/ Transactions</th>
                    <ng-container *ngFor="let day of rawMaterialConsumptionReportData?.days">
                      <th>{{dateToDDMMYYY(+day)}}</th>
                    </ng-container>
                  </tr>
                </thead>
                <tbody>
                  <ng-container
                    *ngFor="let ingredient of rawMaterialConsumptionReportData?.ingredients|keyvalue : returnZero;let i = index;">
                    <tr class="raw-material-name">
                      <td>{{i+1}}</td>
                      <td>
                        {{rawMaterialConsumptionReportData?.name[ingredient?.key]}}
                      </td>
                      <td *ngFor="let day of rawMaterialConsumptionReportData?.days"></td>
                    </tr>
                    <tr *ngFor="let operation of ingredient?.value|keyvalue : returnZero;">
                      <td></td>
                      <td>{{checkOperation(operation?.key)}}</td>
                      <td *ngFor="let qty of operation?.value|keyvalue: returnZero;">
                        {{qty?.value?.displayValue}}
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </ion-grid>

    </ion-content>
  </ng-template>
</ion-modal>

<!-- Raw Material Consumption Modal Ends -->

<!-- Raw Material Purchase Modal Starts -->

<ion-modal class="full-page-modal" [isOpen]="isRawMaterialPurchaseReportModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar color="light">
        <ion-title>Raw Material Purchase Report</ion-title>
        <ion-buttons slot="end">
          <ion-chip color="success" [hidden]="!loadingRawMaterialPurchaseCalculation"
            (click)="rawMaterialPurchaseReportData?.downloadExcel()">Download Excel</ion-chip>
          <ion-button (click)="openRawMaterialPurchaseReportModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding" [hidden]="!loadingRawMaterialPurchaseCalculation">

      <ion-grid class="width-100-percent">

        <div class="container">
          <p class="margin-bottom10 font-size20">
            <img [src]="logoBase64" width="80" height="80" style="border-radius: 50%;">
            {{ selectedProfile?.legalName || selectedProfile?.profileName }}
          </p>

          <p class="header-wrapper margin-bottom10">Raw Material Purchase Report</p>

          <div class="center1">
            <p>Duration : {{ rawMaterialPurchaseReportData?.duration }}</p>
          </div>

          <div class="container width-100-percent raw-material-purchase-table scroll-x padding-left-right-none">
            <h1>Raw Material Purchase Report </h1>
            <div class="raw-material-purchase-table-container">
              <table class="main-table" width="100%">
                <thead>
                  <tr>
                    <th>Sr. No.</th>
                    <th>Raw Materials</th>
                    <th>Quantity / Price</th>
                    <ng-container *ngFor="let day of rawMaterialPurchaseReportData?.days">
                      <th>{{dateToDDMMYYY(+day)}}</th>
                    </ng-container>
                  </tr>
                </thead>
                <tbody>
                  <ng-container
                    *ngFor="let ingredient of rawMaterialPurchaseReportData?.ingredients|keyvalue : returnZero;let i = index;">
                    <tr class="raw-material-name">
                      <td rowspan="3">{{i+1}}</td>
                      <td rowspan="3">
                        {{rawMaterialPurchaseReportData?.name[ingredient?.key]}}
                      </td>
                      <td>
                        Quantity (in {{rawMaterialPurchaseReportData?.unit[ingredient?.key]}})
                      </td>
                      <ng-container *ngIf="ingredient?.value.totalQuantity">
                        <td *ngFor="let day of rawMaterialPurchaseReportData?.days">
                          {{ingredient?.value?.totalQuantity[day].displayValue}}</td>
                      </ng-container>
                    </tr>
                    <ng-container *ngFor="let operation of ingredient?.value|keyvalue : returnZero;">
                      <tr *ngIf="operation.key !== 'totalQuantity'" class="joint-row"
                        [ngClass]="operation.key === 'perUnitPrice' ? 'raw-material-per-unit-price' : ''">
                        <td>{{operation.key === 'totalPrice' ? 'Price (in ₹)' : 'Per Unit Price (in ₹ /
                          '+rawMaterialPurchaseReportData?.unit[ingredient?.key]+')'}}</td>
                        <td *ngFor="let qty of operation?.value|keyvalue: returnZero;">
                          {{qty?.value?.displayValue}}
                        </td>
                      </tr>
                    </ng-container>
                  </ng-container>
                </tbody>
              </table>
            </div>

          </div>
        </div>

      </ion-grid>

    </ion-content>
  </ng-template>
</ion-modal>

<!-- Raw Material Purchase Modal Ends -->

<ion-modal class="full-page-modal" [isOpen]="isIngredientDetailReportModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar color="light">
        <ion-title>Ingredient Detail Report</ion-title>
        <ion-buttons slot="end">
          <ion-chip color="success" (click)="ingredientDetailReportData?.downloadExcel()">Download Excel</ion-chip>
          <ion-chip color="tertiary" (click)="ingredientDetailReportData?.download()">Download PDF</ion-chip>
          <ion-button (click)="openIngredientDetailReportModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">

      <ion-grid class="width-100-percent">

        <div class="container">
          <p class="margin-bottom10 font-size20">
            <img [src]="logoBase64" width="80" height="80" style="border-radius: 50%;">
            {{ selectedProfile?.legalName || selectedProfile?.profileName }}
          </p>

          <p class="header-wrapper margin-bottom10">Ingredient Detail Report</p>

          <div class="center1">
            <p>Duration : {{ ingredientDetailReportData?.duration }}</p><br>
            <p>Total Ingredients : {{ ingredientDetailReportData?.records?.length }}</p>
          </div>

          <div class="container width-100-percent padding-left-right-none">
            <h1>Ingredient Detail Report</h1>

            <table class="main-table" width="100%">
              <thead>
                <tr>
                  <th>Sr No</th>
                  <th>Ingradient Name</th>
                  <th>Category</th>
                  <th>Current Stock</th>
                  <th>Unit</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let record of ingredientDetailReportData?.records;let i = index">
                  <tr>
                    <td>{{ i + 1 }}</td>
                    <td>{{ record?.ingredient?.name }}</td>
                    <td>{{ record?.ingredient?.category }}</td>
                    <td>{{ record?.ingredient?.stock }}</td>
                    <td>{{ record?.ingredient?.unit }}</td>
                  </tr>
                  <tr *ngIf="record?.transactions?.length">
                    <td width="10%"></td>
                    <td width="90%" colspan="5">
                      <table class="sub-table" width="100%">
                        <thead>
                          <th>#</th>
                          <th>Transaction Type</th>
                          <th>Date</th>
                          <th>Quantity</th>
                          <th>Unit</th>
                        </thead>
                        <tbody>
                          <tr *ngFor="let transaction of record?.transactions;let k = index">
                            <td>{{ k + 1 }}</td>
                            <td>{{ transaction?.type }}</td>
                            <td>{{ dateToDDMMYYY(transaction?.sortStamp) }}</td>
                            <td>{{ transaction?.quantity }}</td>
                            <td>{{ transaction?.unit }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>

      </ion-grid>

    </ion-content>
  </ng-template>
</ion-modal>