<ion-header>
  <ion-toolbar class="{{ getHeaderColorClass() }}">
    <app-navigation-control slot="start" type="google-myBusiness"></app-navigation-control>
    <ion-title>Google MyBusiness</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>

  <ion-row>
    <ion-col size="12" class="authenticate-with-google-col" *ngIf="!this.locationData?.locations?.length && isShowAuthenticateUserBtn">
      <ion-button (click)="authenticateWithGoogle()">
        Authenticate With Google
      </ion-button>
    </ion-col>
    <ion-col size="12" *ngIf="this.locationData?.locations?.length">
      <h4>
        Choose Location to Connect with profile :
      </h4>
    </ion-col>
    <ng-container *ngIf="this.locationData?.locations?.length">
      <ion-col size="12" *ngFor="let location of locationData?.locations">
        <ion-card (click)="setLocation(location?.name, location?.verificationStatus === 'COMPLETED')">
          <ion-card-header>
            <ion-card-title>{{location?.title}}</ion-card-title>
          </ion-card-header>
          
          <ion-card-content>
            <ion-card-subtitle>
              {{location?.address}}
            </ion-card-subtitle>
            <ion-card-subtitle [ngClass]="location?.verificationStatus === 'COMPLETED' ? 'verify' : 'unverify'">
              {{location?.verificationStatus === 'COMPLETED' ? 'Verified' : 'Not Verified'}}
            </ion-card-subtitle>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ng-container>
  </ion-row>
</ion-content>
