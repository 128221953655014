<ion-header>
  <ion-toolbar class="{{ getHeaderColorClass() }}">
    <app-navigation-control slot="start" type="item/form"
      (click)="itemFormEle.dpExpiryDateValue = null; itemFormEle.keepDateContentsMounted = false;"
      [_localUUID]="paramDocumentId"></app-navigation-control>
    <ion-title>Create Item</ion-title>
    <ion-chip slot="end" fill="clear" class="btn-item-name padding-start-none" color="primary"
      (click)="openSettingsModal()">
      <ion-icon name="settings-sharp"></ion-icon>
    </ion-chip>
  </ion-toolbar>
</ion-header>

<ion-content>

  <app-item-form #itemFormEle (saveTrigger)="saveTrigger($event)" (updateTrigger)="updateTrigger($event)">
  </app-item-form>

</ion-content>