<ion-header>
  <ion-toolbar class="{{ getHeaderColorClass() }}">
    <app-navigation-control slot="start" type="purchase-return/form"></app-navigation-control>
    <ion-title slot="start">Bill-view Purchase Return</ion-title>
    <ion-segment class="segment-template" slot="start" mode="ios" [value]="this.selectedPurchaseemplate" (ionChange)="setTemplate($event)">
      <ion-segment-button value="temp1PurchaseReturn">
        <ion-label> Modern </ion-label>
      </ion-segment-button>
      <ion-segment-button value="temp17PurchaseReturn">
        <ion-label> 3 Inch A</ion-label>
      </ion-segment-button>
      <ion-segment-button value="temp18PurchaseReturn">
        <ion-label> 3 Inch B </ion-label>
      </ion-segment-button>
      <ion-segment-button value="temp19PurchaseReturn">
        <ion-label> 2 Inch A</ion-label>
      </ion-segment-button>
      <ion-segment-button value="temp20PurchaseReturn">
        <ion-label> 2 Inch B</ion-label>
      </ion-segment-button>
    </ion-segment>

    <ion-chip slot="end" color="danger" (click)="delete()">Delete</ion-chip>
    <!-- <ion-chip slot="end" color="tertiary" (click)="edit()">Edit</ion-chip> -->

  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-row>
    <ion-col size="12">
      <div #div id="htmlInvoiceTemplate"></div>
    </ion-col>
  </ion-row>

  <ion-chip class="chip-download" color="success" [disabled]="!isPremiumAccess" (click)="downloadPDF()">
    <ion-icon name="arrow-down-circle"></ion-icon>
    Download PDF
  </ion-chip>
  <ion-chip class="chip-print" color="tertiary" [disabled]="!isPremiumAccess" (click)="printBill()">
    <ion-icon name="print"></ion-icon>
    Print
  </ion-chip>
  <!-- <ion-chip class="chip-add-bill" color="tertiary" (click)="navigateToBill()">
    <ion-icon name="add-outline"></ion-icon> Create Purchase Return
  </ion-chip> -->
</ion-content>

<app-purchase-return-bulk-delete #deleteDataEle [navigateTo]="'purchase-return'"></app-purchase-return-bulk-delete>

<app-settings #settingsModal (onSave)="refreshTemplate()"></app-settings>

<ion-modal [isOpen]="isProfileFormModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-title>Profile Form</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="openProfileFormModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">

      <app-profile-form
        [isFocusLegalName]="isFocusLegalName"
        [isFocusBankAccountDetails]="isFocusBankAccountDetails"
        (onUpdate)="onProfileUpdate($event)"
      ></app-profile-form>

    </ion-content>
  </ng-template>
</ion-modal>

<app-pin-verification (verifyTransactionPin)="verifyTransactionPIN($event)" #pinVerificationElement></app-pin-verification>
