import { Item } from "./Item.model";

export class BillItem {

  item: Item;

  quantity: number;

  effectiveQuantity: number;

  unit: string;

  // THIS IS USED TO GET EFFECTIVE PRICE, MRP & QUANTITY FROM MULTIPLE UNITS
  convertRatioMultiplier: number;

  // THIS IS DISPLAY SELL PRICE WHICH CAN BE INCLUSIVE OR EXCLUSIVE OF TAX, THIS IS NOT ACTUAL SELL PRICE
  price: number;

  // PRICE + TAX SLAB AMOUNT + CESS AMOUNT
  effectivePrice: number;

  // MRP CALCULATED BASE ON MRP, PRICE & INC TAX STATUS
  effectiveMrp: number;

  // ITEM DISCOUT AMOUNT
  discount: number;

  // ITEM DISCOUT PERCENTAGE
  discountPercentage: number;

  // CASH DISCOUNT PERCENTAGE
  billCashDiscountPercentage: number;

  // ITEM DISCOUNT PERCENTAGE + CASH DISCOUNT PERCENTAGE
  effectiveDiscountPercentage: number;

  // true = PRICE IS INCLUSIVE OF TAX, false = PRICE IS EXCLUSIVE OF TAX
  incTax: boolean;

  // TAX SLAB
  taxPercentage: number;

  // CESS
  cessPercentage: number;

  // THIS IS (TAX SLAB + CESS)
  effectiveTaxPercentage: number;

  //Non Gst Supplies
  isNonGstSupplies: number;
  
  isTaxExempted: number;

  isTaxZero: number;

  basePrice: number;

  subTotal: number;

  total: number;

  totalSaving: number;

  billNote: string;

  kotNote: string;

  unitDiscountAmount: number;

  unitGstAmount: number;

  unitCessAmount: number;

  unitTaxAmount: number;

  itemTotalGstAmount: number;

  itemTotalCessAmount: number;

  itemTotalTaxAmount: number;

  // WCD - WITHOUT CALCULATED DISCOUNT
  wcdBasePrice: number;

  wcdUnitTaxAmount: number;

  wcdTotal: number;

}
