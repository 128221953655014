<ion-header>
    <ion-toolbar class="{{ getHeaderColorClass() }}">
      <app-navigation-control slot="start" type="reports"></app-navigation-control>
      <ion-title>Logout</ion-title>
      <ion-text class="last-sync-time" color="medium">Last Sync at {{ lastSyncTime }}</ion-text>
    <ion-text *ngIf="isTimeDifference" class="last-sync-time timezone-warning alert-border padding-alert-boder" color="warning"> <i>Time Zone Mismatched</i> </ion-text>
    </ion-toolbar>
</ion-header>

<ion-content *ngIf="!isLoggedOut">
    <ion-card *ngIf="unsyncedRecordsCount">
        <ion-card-header>
          <h4>Data Upload Pending</h4>
        </ion-card-header>
        <ion-card-content>
             Records need to be uploaded
        </ion-card-content>
        <ion-card-content>
          <ion-list>
            <div class="button-container" *ngFor="let item of unsyncedCollections | keyvalue">
              <section class="pending-list" *ngIf="item?.value?.count">
                <p class="pending-items">{{ item.key }}</p>
                <p class="pending-count">{{ item?.value?.count }}</p>
              </section>
            </div>
          </ion-list>
        </ion-card-content>
    </ion-card>

    <div>
      <span class="hidden-logout" (click)="logoutInitiator()">.</span>
        <ion-card>
          <ion-card-content class="button-container">
            <p>{{unsyncedRecordsCount ?'Records which are not uploaded yet, will be lost.': ""}} Are you sure you want to logout?</p>
          </ion-card-content>
            <div class="button-container">
            <ion-button (click)="cancelLogout()">Cancel</ion-button>
            <ion-button (click)="confirmLogout()">Confirm</ion-button>
          </div>
        </ion-card>
      </div>

      
</ion-content>