import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appHideYear]'
})
export class HideYearDirective {

  constructor(private ionDatetimeButtonEle: ElementRef) {
    const style = (document.createElement('style'));
    style.textContent = `
      .year-column {
        display: none;
      }
    `;
    this.ionDatetimeButtonEle?.nativeElement?.shadowRoot?.appendChild(style);
  }

}
