<ion-grid>
  <ion-row *ngIf="!moneyOutCart?.length && !isPurchaseList && !isExpenseList && !isSaleReturnList">
    <ion-col offset="9">
      <ion-button
        fill="outline"
        class="dashed-border"
        (click)="openMoneyOutSelectorModal()"
      >
        Modify Amount Paid
      </ion-button>
    </ion-col>
  </ion-row>
  <ion-row *ngIf="moneyOutCart?.length && !isPurchaseList && !isExpenseList && !isSaleReturnList && (isPurchaseForm || isExpenseForm || isSaleReturnForm)">
    <ion-col size="3">
      <ion-card color="success" mode="ios">
        <ion-card-header>
          <ion-card-title>{{ getTotal(moneyOutCart) | currency: 'INR' }}</ion-card-title>
          <ion-card-subtitle>Total Amount Paid</ion-card-subtitle>
        </ion-card-header>
      </ion-card>
    </ion-col>
    <ion-col class="money-in-cart" size="3" *ngFor="let moneyOut of moneyOutCart">
      <ion-card color="light" mode="ios">
        <ion-card-header>
          <ion-card-title>
            {{ moneyOut?.totalAmount | currency: 'INR' }}
          </ion-card-title>
          <ion-card-subtitle>
            {{ moneyOut.billNo }}
          </ion-card-subtitle>
          <ion-chip class="chip-remove" color="danger" (click)="deleteMoneyOut(moneyOut)">Remove</ion-chip>
        </ion-card-header>
      </ion-card>
    </ion-col>
    <ion-col size="3" *ngIf="getTotal(moneyOutCart) < billTotalAmount">
      <ion-button
        fill="outline"
        class="dashed-border btn-add-money-in"
        (click)="openMoneyOutSelectorModal()"
      >
        <ion-icon name="add-outline"></ion-icon>
        Add Money Out
      </ion-button>
    </ion-col>
  </ion-row>
</ion-grid>

<!-- <ion-modal [isOpen]="isModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-title>
          Select Money Out for <ion-chip color="tertiary">{{ purchase?.billNo || expense?.billNo }}</ion-chip>
        </ion-title>
        <ion-buttons slot="end">
          <ion-button
            class="btn-add-party"
            fill="outline"
            color="primary"
            (click)="toggleMoneyOutForm(true)"
          >
            Add New Money Out
          </ion-button>
        </ion-buttons>
        <ion-buttons slot="end">
          <ion-button (click)="openMoneyOutSelectorModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">

    </ion-content>
  </ng-template>
</ion-modal> -->

<ion-modal [isOpen]="isOpenMoneyOutForm" [backdropDismiss]="false" animated="false">
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-title>
          Add Payment for Bill <ion-chip color="tertiary">{{ purchase?.billNo || expense?.billNo || saleReturn?.billNo }}</ion-chip>
        </ion-title>
        <ion-buttons slot="end" class="money-out-form-btn-grp">
          <ion-button 
            (click)="toggleMoneyOutForm(false)" 
            color="danger">
            Close
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">

      <app-money-out-form
        #moenyOutFormEle
        *ngIf="isOpenMoneyOutForm"
        [purchase]="purchase"
        [expense]="expense"
        [saleReturn]="saleReturn"
        [billTotalAmount]="billTotalAmount"
        [moneyOutCart]="moneyOutCart"
        (savedMoneyOut)="onMoneyOutSelect($event)"
      ></app-money-out-form>

      <ion-grid *ngIf="!saleReturn">
        <ion-row>
          <ion-col>
            <ion-button 
              class="link-money-out-btn"
              (click)="toggleLinkMoneyOutList(true)"
              fill="clear" 
              expand="block"
              color="dark">
              Show Unused Money Outs
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>

    </ion-content>
  </ng-template>
</ion-modal>

<!-- Attach Money Out list Modal Start -->
<ion-modal 
  [isOpen]="isOpenLinkMoneyOutList"
  [backdropDismiss]="false"
  animated="false">
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-title>
          Attach Payment for Bill <ion-chip color="tertiary">{{ purchase?.billNo || expense?.billNo }}</ion-chip>
        </ion-title>
        <ion-buttons slot="end">
          <ion-button 
            (click)="toggleLinkMoneyOutList(false)" 
            color="danger">
            Back
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-grid *ngIf="viewPartyWiseMoneyOutList?.length; else elseBlock" class="padding-top-none">
        <ion-row class="sticky-row">
          <ion-col class="selected-amount">
            <ion-label class="unused-list-label">
              Unused Money Out List
            </ion-label>
            <ion-label>
              Required MoneyOut Amount: <span>{{ totalMoneyOutPending | currency:'INR' }}</span>
            </ion-label>
            <ion-label>
              Selected MoneyOut Amount: <span>{{totalSelectedMoneyOutAmount | currency: 'INR'}}</span>
            </ion-label>
            <span
             class="greater-money-in-error"
             *ngIf="totalSelectedMoneyOutAmount > totalMoneyOutPending">
             Money out/payment amount should not be greater than balance amount
            </span>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-item
             *ngFor="let moneyOut of viewPartyWiseMoneyOutList" 
             lines="none"
             [disabled]="!checkboxEle?.checked && totalMoneyOutPending - totalSelectedMoneyOutAmount < moneyOut?.totalAmount">
              <ion-checkbox
                #checkboxEle
                slot="start"
                (ionChange)="selectedAttachMoneyOut($event, moneyOut)">
              </ion-checkbox>
              <ion-label>
                <ion-chip color="primary">{{ moneyOut.billNo }} | {{ moneyOut.billDateStamp | date: 'dd/MM/yy' }}</ion-chip>
                <ion-chip color="primary">{{ moneyOut?.party?.name }}</ion-chip>
                <ion-chip color="primary">{{ moneyOut?.totalAmount | currency: 'INR' }}</ion-chip>
              </ion-label>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-button 
              class="btn-attch-money-out"
              [disabled]="totalSelectedMoneyOutAmount > totalMoneyOutPending"
              (click)="attachMoneyOuts()">
              Attach Money Outs
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-infinite-scroll *ngIf="viewPartyWiseMoneyOutList?.length" threshold="10px" (ionInfinite)="loadMorePartyWiseMoneyOutListData($event)">
          <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Loading more data...">
          </ion-infinite-scroll-content>
        </ion-infinite-scroll>
      </ion-grid>
      <ng-template #elseBlock>
        <div class="no-records-found">
          <h4>
            No records Found
          </h4>
        </div>
      </ng-template>
    </ion-content>
  </ng-template>
</ion-modal>
<!-- Attach Money Out list Modal End -->