import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseBillView } from '../../base/base-bill-view';
import { PurchaseReturn } from '../../models/PurchaseReturn.model';
import { MoneyIn } from '../../models/MoneyIn.model';
import { PinVerificationComponent } from '../../components/pin-verification/pin-verification.component';
import { SettingsComponent } from '../../components/settings/settings.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AccessControlService } from '../../services/auth/access-control.service';
import { AlertController } from '@ionic/angular';
import { AllDataService } from '../../services/all-data.service';
import { AuthService } from '../../services/auth/auth.service';
import { PremiumControlService } from '../../services/auth/premium-control.service';
import { ImageBase64Service } from '../../services/image-base64.service';
import { Utility } from '../../utils/utility';
import { SentryUtilites } from '../../utils/sentryUtilites';
import { PurchaseReturnBillPrint } from '@makarandkate/invoice-templates/lib/models/purchase-return2.model';
import { PurchaseReturnBulkDeleteComponent } from '../../components/bulk-delete/purchase-return-bulk-delete/purchase-return-bulk-delete.component';
import { Profile } from '../../models/Profile.model';
import Party from '../../models/Party.model';
import { HtmlTemplates } from '@makarandkate/invoice-templates';

@Component({
  selector: 'app-bill-view-purchase-return',
  templateUrl: './bill-view-purchase-return.page.html',
  styleUrls: ['./bill-view-purchase-return.page.scss'],
})
export class BillViewPurchaseReturnPage extends BaseBillView<PurchaseReturn, PurchaseReturnBillPrint, MoneyIn> implements OnInit {

  @ViewChild('pinVerificationElement') pinVerificationElement: PinVerificationComponent;
  @ViewChild('settingsModal') settingsModal: SettingsComponent;

  @ViewChild('deleteDataEle') deleteDataEle: PurchaseReturnBulkDeleteComponent;
  @ViewChild('div', { static: true }) div: any;

  selectedTemplate: string;

  record: PurchaseReturn;

  selectedPurchaseemplate: 'temp1Purchase' | 'temp17Purchase' | 'temp18PurchaseReturn' | 'temp19Purchase' | 'temp20Purchase' = Utility.getFromLocalStorage('selectedPurchaseReturnTemplate') || 'temp18PurchaseReturn';

  billPrint = new PurchaseReturnBillPrint();

  purchaseReturn: PurchaseReturn = null;

  billDownloadEndpoint: string = 'https://db.ezobooks.in/kappa/billView/purchaseReturn';
  constructor(
    private router: Router,
    private accessControlService: AccessControlService,
    private alertController: AlertController,
    allDataService: AllDataService,
    authService: AuthService,
    route: ActivatedRoute,
    premiumControlService: PremiumControlService,
    imageBase64Service: ImageBase64Service,
  ) {
    super(
      route,
      allDataService,
      authService,
      premiumControlService,
      imageBase64Service,
    );
  }

  ngOnInit = async () => {
    this.baseNgOnInit();
  }

  async ionViewWillEnter() {
    this.selectedTemplate = Utility.getFromLocalStorage('selectedPurchaseReturnTemplate') || 'temp18PurchaseReturn';
    this.baseIonViewWillEnter();
    this.isPremiumAccess = await this.premiumControlService.isPremiumAccess();
  }

  ionViewWillLeave() {
    this.ngOnDestroy();
  }

  ngOnDestroy() {
    this.baseNgOnDestroy();
  }

  openTransactionPINModal() {
    this.pinVerificationElement?.openTransactionPINModal();
  }

  verifyTransactionPIN(event) {
    try {
      if (event) {
        this.router.navigate([`purchase-return/form/${this.record?._localUUID}`]);
      }
    } catch (error) {
      SentryUtilites.setLog("BillViewPurchaseReturnPage:verifyTransactionPIN", error)
    }
  }

  setTemplate(event) {
    try {
      this.selectedTemplate = event?.detail?.value;
      Utility.setToLocalStorage('selectedPurchaseReturnTemplate', this.selectedTemplate);
      this.populateTemplate();
    } catch (error) {
      SentryUtilites.setLog("BillViewPurchaseReturnPage:setTemplate", error)
    }
  }

  async edit() {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({ action: 'editPurchaseReturn' });
      if (!isPermit) {
        return alert("Permission: You don't have permission to edit purchase return. Please contact to your owner.");
      }
      this.openTransactionPINModal();
    } catch (error) {
      SentryUtilites.setLog("BillViewPurchaseReturnPage:edit", error)
      alert("Something went wrong.");
    }
  }

  async delete() {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({ action: 'deletePurchaseReturn' });
      if (!isPermit) {
        return alert("Permission: You don't have permission to delete purchase return. Please contact to your owner.");
      }
      this.deleteDataEle?.initDeletePurchaseReturn(this.record);
    } catch (error) {
      SentryUtilites.setLog("BillViewPurchaseReturnPage:delete", error)
      alert("Something went wrong.");
    }
  }

  async navigateToBill(): Promise<void> {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({ action: 'createPurchaseReturn' });
      if (!isPermit) {
        return alert("Permission: You don't have permission to create new purchase return. Please contact to your owner.");
      }
      this.router.navigate([`purchase-return/form`]);
    } catch (error) {
      SentryUtilites.setLog("BillViewPurchaseReturnPage:navigateToBill", error)
      alert("Something went wrong.");
    }
  }

  openTNCSetting(): void {
    try {
      if (this.settingsModal) {
        this.settingsModal.openSettingsModal();
        this.settingsModal.viewSectionIds = [5];
        this.settingsModal.viewSettingKeys = ['pSetTermsAndConditions'];
      }
    } catch (error) {
      SentryUtilites.setLog("BillViewPurchaseReturnPage:openTNCSetting", error)
    }
  }

  async setTermsAndConditions(profile: Profile) {
    // if (profile?.iSetPrintMBMA !== false) {
    //   profile.pSetTermsAndConditions = (profile?.pSetTermsAndConditions || '') + `
    //     <b>Mera Bill Mera Adhikar</b>
    //     Win 1 Crore with this Bill
    //     1. Download Mera Bill Mera Adhikar app
    //     2. Register your self
    //     3. Add photo of this bill
    //     4. Lucky Customer will get upto 1 crore from government
    //     5. Lucky Shopkeeper will get upto 1 Crore from EZO.
    //     `;
    // }
  }

  async setRecord(paramDocumentId: string): Promise<PurchaseReturn> {
    try {
      this.record = await this.allDataService.purchaseReturnService.getByUUID(paramDocumentId);
      if(this.record?.deletedStamp) {
        const alert = await this.alertController.create({
            header: 'Alert!',
            message: `Bill Deleted by ${this.record?.lastModifiedByName || this.record?.lastModifiedBy} on ${Utility.setDateValue(this.record?.deletedStamp)}`,
            mode: 'ios',
            buttons: [
              {
                text: 'Go Back',
                role: 'cancel',
                handler: () => {
                    this.router.navigate(['purchase-return']);
                    return null
                 },
              },
            ],
        });
        await alert.present();
      } else {
          return this.record;
      }
    } catch (error) {
      SentryUtilites.setLog("BillViewPurchaseReturnPage:setRecord", error)
      return this.record;
    }
  }

  async getMoneyInOut(): Promise<MoneyIn> {
    try {
      if (this.record?.moneyIns?.length && this.record?.moneyIns[0]?._localUUID) {
        return await this.allDataService.moneyInService.getByUUID(this.record?.moneyIns[0]?._localUUID);
      }
      return null;
    } catch (error) {
      SentryUtilites.setLog("BillViewPurchaseReturnPage:getMoneyIn", error)
      return null;
    }
  }

  setBillPrint(
    tokenDetails: any,
    profile: Profile,
    signature: any,
    logo: any,
    party: Party,
    secondaryParty: Party,
  ): void {
    try {
      this.billPrint = HtmlTemplates.generatePurchaseReturnBillObject({
        user: {
          isPro: tokenDetails?.isPro,
          registrationStamp: tokenDetails?.createdStamp
        },
        profile,
        signature,
        logo,
        party,
        purchaseReturn: this.record,
        moneyIn: this.moneyInOut,
        isPartyDeleted: this.isPartyDeleted,
      });
    } catch (error) {
      SentryUtilites.setLog("BillViewPurchaseReturnPage:setBillPrint", error)
    }
  }

  setTemplateToHtmlDiv(htmlString: any): void {
    try {
      this.div.nativeElement.innerHTML = htmlString;
    } catch (error) {
      SentryUtilites.setLog("BillViewPurchaseReturnPage:setTemplateToHtmlDiv", error)
    }
  }

}
