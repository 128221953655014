<ion-row class="padding-none cursor-pointer custom-row">
  <ion-col size="6" class="txt-left remove-padding-top-bottom">
    <ion-row>
      <ion-col size="12" class="add-bottom-padding">
        <ion-button fill="clear" class="btn-txt-black padding-start-none">
          {{ expense?.party?.name }}
        </ion-button>
      </ion-col>
      <ion-col size="12">
        <ion-label color="primary">
          {{ (expense?.totalAmount || 0) | currency:'INR' }}
        </ion-label>
      </ion-col>
      <ion-col size="12" *ngIf="!expense?.deletedStamp">
        <ion-button fill="outline" class="outline-border font-12px ion-button-height ion-button-bottom-padding"
          color="success" *ngIf="getBalanceAmount(expense?.moneyOuts,expense?.totalAmount)"
          (click)="addPayment(expense)">
          + Add Payment
        </ion-button>
        <ion-button fill="outline"
          class="outline-border font-12px add-margin-left ion-button-bottom-padding ion-button-height" color="danger"
          *ngIf="getBalanceAmount(expense?.moneyOuts,expense?.totalAmount)">
          {{ getBalanceAmount(expense?.moneyOuts,expense?.totalAmount) | currency:'INR' }}
          Balance
        </ion-button>
        <ion-button fill="outline" class="outline-border font-12px ion-button-bottom-padding ion-button-height"
          color="success" *ngIf="getBalanceAmount(expense?.moneyOuts,expense?.totalAmount)===0">
          Paid
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-col>
  <ion-col size="6" class="txt-right remove-padding-top-bottom">
    <ion-row>
      <ion-col size="12">
        <ion-button fill="clear" class="btn-txt-black padding-end-none">
          {{ expense?.billNo }} | {{ expense?.billDateStamp | date: 'dd/MM/yy' }}
        </ion-button>
      </ion-col>
      <ion-col size="12" *ngIf="!expense?.deletedStamp">
        <ion-button title="Print" color="tertiary" fill="clear" (click)="billView(expense)">
          <ion-icon name="print"></ion-icon>
        </ion-button>
        <ion-button title="Edit" color="primary" fill="clear" (click)="edit(expense)">
          <ion-icon name="create"></ion-icon>
        </ion-button>
        <ion-button title="Delete" color="danger" fill="clear" (click)="delete(expense)">
          <ion-icon name="trash"></ion-icon>
        </ion-button>
      </ion-col>
      <ion-col size-md="11" size-xs="10">
        <ion-label class="label-note">
          {{ (expense?.userUpdatedStamp || expense?.updatedStamp) > expense?.createdStamp ? 'Edited by ' : 'Created by ' }} 
          {{ (expense?.userUpdatedStamp || expense?.updatedStamp) > expense?.createdStamp ? expense?.lastModifiedByName || expense?.lastModifiedBy :  expense?.createdByName || expense?.createdBy }}
          on
          {{ (expense?.userUpdatedStamp || expense?.updatedStamp) | date: 'dd/MM/yy h:mm:ss a' }}
        </ion-label>
      </ion-col>
      <ion-col size-md="1" size-xs="2">
        <ion-col size="auto" offset="1">
          <div *ngIf="expense?.syncStamp > expense?.updatedStamp; then thenBlock else elseBlock"></div>
          <ng-template #thenBlock>
            <ion-button class="btn-popover" title="Data Uploaded" fill="clear">
              <ion-icon 
                name="checkmark-done-sharp" 
                size="small"
                color="success">
              </ion-icon>
            </ion-button>
          </ng-template>
          <ng-template #elseBlock>
            <ion-button class="btn-popover" title="Warning" fill="clear">
              <ion-icon 
                name="warning-sharp" 
                color="warning" 
                size="small">
              </ion-icon>
            </ion-button>
          </ng-template>
        </ion-col>
      </ion-col>
    </ion-row>
  </ion-col>
</ion-row>

<app-pin-verification (verifyTransactionPin)="verifyTransactionPIN($event)" #pinVerificationElement></app-pin-verification>