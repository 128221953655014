import { Router } from '@angular/router';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ISale, Sale } from '../../models/Sale.model';
import { AllDataService } from '../../services/all-data.service';
import { Utility } from '../../utils/utility';
import { AlertController, InfiniteScrollCustomEvent, IonSearchbar } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { IListView } from '../../../interface/IListView.interface';
import { DeleteDataComponent } from '../../components/delete-data/delete-data.component';
import { AccessControlService } from '../../services/auth/access-control.service';
import { CommonService } from '../../services/common.service';
import { PremiumControlService } from '../../services/auth/premium-control.service';
import { EventService } from '../../services/event.service';
import { SaleBulkDeleteComponent } from '../../components/bulk-delete/sale-bulk-delete/sale-bulk-delete.component';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-sale',
  templateUrl: './sale.page.html',
  styleUrls: ['./sale.page.scss'],
})
export class SalePage implements OnInit, IListView<Sale> {

  // @ViewChild('deleteDataEle') deleteDataEle: DeleteDataComponent;
  @ViewChild('deleteDataEle') deleteDataEle: SaleBulkDeleteComponent;
  @ViewChild('searchBar') searchBar: IonSearchbar;

  getHeaderColorClass = Utility.getHeaderColorClass;

  lastSyncTime = Utility.getCollectionLastRespSyncTime(ISale.SCHEMA_NAME);
  lastSyncTimeSub: Subscription;
  isTimeDifference: boolean = false;

  filteredList: Sale[] = [];
  completeList: Sale[] = [];
  viewFilteredList: Sale[] = [];

  appliedCategory: string = '';
  selectedFilter: string = '';
  isMobile: boolean = false;
  innerHeight: string = '';

  isFilterOptionOpen: boolean = false;
  isOptionOpen: boolean = false;

  subsArr: Subscription[] = [];

  selectedSale: Sale = null;
  showMoneyInSelector: boolean = false;

  fromStamp: number = null;
  toStamp: number = null;

  isBinList: boolean = false;

  loadViewTimeStamp: number = 0;

  isNgOnInitRun: boolean = false;

  getAddition = Utility.getAddition;

  constructor(
    private allDataService: AllDataService,
    private router: Router,
    private accessControlService: AccessControlService,
    private commonService: CommonService,
    private premiumControlService: PremiumControlService,
    private eventService: EventService,
    private alertController: AlertController,
  ) { }

  ngOnInit() {
    try {
      this.isNgOnInitRun = true;
      this.isMobile = this.commonService.isMobile();
      if(this.isMobile) {
        this.innerHeight = `${(((window?.innerHeight - 268)/window?.innerHeight) * 100)}%`;
      } else {
        this.innerHeight = `${(((window?.innerHeight - 174)/window?.innerHeight) * 100)}%`;
      }
      this.subsArr.push(this.allDataService.lastSyncSubs.subscribe(x => {
        this.lastSyncTime = Utility.getCollectionLastRespSyncTime(ISale.SCHEMA_NAME);
        this.isTimeDifference = this.allDataService.isTimeDifference;
      }));
      
      this.subsArr.push(this.allDataService.listForceReloadSubs.subscribe(async (listName: string) => {
        if (listName == 'sale-list') {
          await Utility.wait(2000)
          this.reduceFunctionCall();
        }
      }))
      
      this.subsArr.push(this.allDataService.saleService.updateSubs
        .subscribe((sale: Sale) => {
          if (sale) {
            let completeListIndex = this.completeList.findIndex(el => el?._localUUID === sale?._localUUID);
            if(completeListIndex != -1) {
              if(sale?.deletedStamp) {
                this.completeList.splice(completeListIndex, 1);
              } else {
                this.completeList[completeListIndex] = sale;
              }
            }
            let filteredListIndex = this.filteredList.findIndex(el => el?._localUUID === sale?._localUUID);
            if(filteredListIndex != -1) {
              if(sale?.deletedStamp) {
                this.filteredList.splice(filteredListIndex, 1);
              } else {
                this.filteredList[filteredListIndex] = sale;
              }
            }
            let viewFilteredListIndex = this.viewFilteredList.findIndex(el => el?._localUUID === sale?._localUUID);
            if(viewFilteredListIndex != -1) {
              if(sale?.deletedStamp) {
                this.viewFilteredList.splice(viewFilteredListIndex, 1);
              } else {
                this.viewFilteredList[viewFilteredListIndex] = sale;
              }
            }
          }
        })
      )
    } catch (error) {
      SentryUtilites.setLog("SalePage:ngOnInit", error)
    }
  }

  ngAfterViewInit() { }

  async ionViewWillEnter() {
    this.reduceFunctionCall();
    if(!this.isNgOnInitRun) {
      this.ngOnInit();
    }
  }

  async loadView() {
    try {
      // this.filteredList = [];
      // this.completeList = [];
      // this.viewFilteredList = [];
      
      this.appliedCategory = '';
      this.selectedFilter = '';
  
      this.isFilterOptionOpen = false;
  
      let completeListHashMap = {};
  
      let completeListLength = this.completeList.length;
      for (let i = 0; i < completeListLength; i++) {
        let key = JSON.stringify(this.completeList[i]);
        completeListHashMap[key] = true;
      }
  
      let newCompleteList = await this.allDataService.saleService.getAllWithDeletedByProfile() || [];
  
      let differentObjects = [];
  
      // storing credit and find out differentObjects from transactionsList
      if(this.completeList?.length) {
        let newcompleteListLength = newCompleteList?.length;
        for (let i = 0; i < newcompleteListLength; i++) {
          let newCompleteObj = newCompleteList[i];
          let key = JSON.stringify(newCompleteObj);
          if (!completeListHashMap[key]) {
            differentObjects.push(newCompleteObj);
          }
        }
      }
  
      if(this.completeList?.length != newCompleteList?.length ||
        JSON.stringify(this.completeList) != JSON.stringify(newCompleteList)) {
  
          if(this.completeList?.length) {
            let differentObjectsLength = differentObjects?.length;
            if (differentObjectsLength) {
              for (let i = 0; i < differentObjectsLength; i++) {
                let differentObject = differentObjects[i];
                let index = this.completeList.findIndex(
                  (el) => el?._localUUID === differentObject?._localUUID
                );
                if (index != -1) {
                  this.completeList[index] = differentObject;
                } else {
                  this.completeList.push(differentObject);
                }
              }
            }
          } else {
            this.completeList = newCompleteList;
          }
  
          this.onFilterChange(this.lastSearchKey);
        }
    } catch (error) {
      SentryUtilites.setLog("SalePage:loadView", error)
    }

  }

  reduceFunctionCall() {
    try {
      if(+new Date() > this.loadViewTimeStamp) {
        this.loadViewTimeStamp = +new Date() + 5000;
        this.loadView();
      }
    } catch (error) {
      SentryUtilites.setLog("SalePage:reduceFunctionCall", error)
    }
  }

  onBinListFilterChange(event) {
    this.isBinList = event?.detail?.checked;
    if(this.searchBar) {
      this.searchBar.value = ''
    }
    this.applyFilter({
      searchKeyword: '',
      selectedFilter: this.selectedFilter,
      category: this.appliedCategory
    })
  }

  toggleFilterOptions() {
    this.isFilterOptionOpen = !this.isFilterOptionOpen;
  }

  onCategoryChange(category: string) {
    this.appliedCategory = category;
    this.onFilterChange();
  }

  onSortChnage(selectedFilter: string) {
    this.selectedFilter = selectedFilter;
    this.onFilterChange();
  }

  isFilterPostpond = false;
  lastFilterStamp = 0;
  onFilterChange(searchKey?: string) {
    try {
      if (this.isFilterPostpond) {
        return true;
      }
      if (this.lastFilterStamp < +new Date() - 200) {
        this.lastFilterStamp = +new Date();
        this.applyFilter({
          searchKeyword: searchKey,
          selectedFilter: this.selectedFilter,
          category: this.appliedCategory
        })
      } else {
        this.isFilterPostpond = true;
        setTimeout(() => {
          this.isFilterPostpond = false;
          this.onFilterChange(searchKey);
        }, 250);
      }
    } catch (error) {
      SentryUtilites.setLog("SalePage:onFilterChange", error)
    }
  }


  lastSearchKey = '';
  async applyFilter(params: {
    searchKeyword?: string,
    selectedFilter?: string,
    category?: string
  }) {
    try {
      let arr = [];
      let lsearchKey = '';
      let isSearchIncremental = false;
      let isSearchCleared = false;
      if (params?.searchKeyword?.length) {
        lsearchKey = Utility.toLowerCase(params.searchKeyword);
        if (lsearchKey.indexOf(this.lastSearchKey) == 0) {
          isSearchIncremental = true;
        }
        this.lastSearchKey = lsearchKey;
      }
      if (this.lastSearchKey.length > 0 && lsearchKey.length == 0) {
        isSearchCleared = true;
        this.lastSearchKey = '';
      }
      if(!this.filteredList?.length && isSearchIncremental) {
        this.filteredList = this.completeList;
      }
      let completeList = [];
      if (isSearchIncremental) {
        completeList = this.filteredList
      } else {
        completeList = this.completeList;
      }
  
      if(this.isBinList) {
        completeList = await this.allDataService.saleService.getAllDeleted() || [];
      }else {
        completeList = completeList?.filter(x => !x?.deletedStamp);
      }
  
      if (lsearchKey.length) {
        completeList?.forEach((el) => {
          if (
            Utility.toLowerCase(el?.billNo).indexOf(lsearchKey) != -1
            || Utility.toLowerCase(el?.party?.name).indexOf(lsearchKey) != -1
            // || Utility.toLowerCase(el.party.phone).indexOf(lsearchKey) != -1
          ) {
            arr.push(el);
          }
        });
  
      } else {
        arr = completeList
      }
  
  
      if (params.selectedFilter == 'SortByNameAsc') {
  
      } else if (params.selectedFilter == 'SortByNameDesc') {
  
      } else if (params.selectedFilter == 'SortByCreditAsc') {
  
      } else if (params.selectedFilter == 'SortByCreditDesc') {
  
      }
  
      if(this.fromStamp && this.toStamp) {
        arr = arr.filter(sale => sale?.billDateStamp>=this.fromStamp && sale?.billDateStamp<=this.toStamp);
      }
  
      this.filteredList = arr.sort((a,b)=>b?.createdStamp-a?.createdStamp);
  
      this.viewFilteredList = this.viewFilteredList?.length > 50 ? this.filteredList.slice(0, this.viewFilteredList?.length) : this.filteredList.slice(0, 50);
    } catch (error) {
      SentryUtilites.setLog("SalePage:applyFilter", error)
    }

  }


  loadMoreListData(event) {
    try {
      if (this.viewFilteredList?.length > 0 && this.viewFilteredList?.length <= this.filteredList?.length) {
        let appendListLength = this.filteredList?.length - this.viewFilteredList?.length;
        let lastEl = this.viewFilteredList[this.viewFilteredList?.length - 1];
        let counter = 0;
        for (let i = 0; i < this.filteredList?.length; i++) {
          if (this.filteredList[i]?._localUUID == lastEl?._localUUID) {
            counter = 1;
            continue;
          }
          if (counter > 0 && appendListLength >= 50) {
            if (counter <= 50) {
              this.viewFilteredList.push(this.filteredList[i])
            } else {
              break;
            }
            counter++;
          } else if(counter > 0 && appendListLength < 50) {
            if (counter <= appendListLength) {
              this.viewFilteredList.push(this.filteredList[i])
            } else {
              break;
            }
            counter++;
          }
        }
        (event as InfiniteScrollCustomEvent)?.target?.complete();
      }
    } catch (error) {
      SentryUtilites.setLog("SalePage:loadMoreListData", error)
    }
  }

  async onNewSaleClick() {
    try {
      let checkCreateSale = await this.premiumControlService.checkCreateSale();
      if(checkCreateSale) {
        let isPermit = await this.accessControlService.isUserHasAccess({action:'createSale'});
        if(!isPermit) {
          return alert("Permission: You don't have permission to create new sale. Please contact to your owner.");
        }
        this.router.navigate([`sale/form`]);
      }
    } catch (error) {
      SentryUtilites.setLog("SalePage:onNewSaleClick", error)
      alert("Something went wrong.");
    }
  }

  ionViewWillLeave() {
    this.ngOnDestroy();
  }

  ngOnDestroy() {
    try {
      this.isNgOnInitRun = false;
      this.subsArr?.forEach((el) => {
        el?.unsubscribe();
      })
      this.lastSyncTimeSub?.unsubscribe();
    } catch (error) {
      SentryUtilites.setLog("SalePage:ngOnDestroy", error)
    }
  }

  emitElement(sale: Sale) {
    this.router.navigate([`sale/form/${sale?._localUUID}`]);
  }

  onDateRangeChange(data:{fromStamp:number,toStamp:number}) {
    this.fromStamp = data.fromStamp;
    this.toStamp = data.toStamp;
    this.onFilterChange();
  }

  async delete(sale: Sale) {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({action:'deleteSale'});
      if(!isPermit) {
        return alert("Permission: You don't have permission to delete sale. Please contact to your owner.");
      }
      this.deleteDataEle?.initDeleteSale(sale);
    } catch (error) {
      SentryUtilites.setLog("SalePage:delete", error)
      alert("Something went wrong.");
    }
  }

  onDelete(sale: Sale) {
    try {
      if(sale?._localUUID && sale?.deletedStamp) {
        let index1 = this.completeList.findIndex(x => x?._localUUID===sale?._localUUID);
        index1 != -1 && this.completeList.splice(index1,1);
        let index2 = this.viewFilteredList.findIndex(x => x?._localUUID===sale?._localUUID);
        index2 != -1 && this.viewFilteredList.splice(index2,1);
      }
    } catch (error) {
      SentryUtilites.setLog("SalePage:onDelete", error)
    }
  }

  async bulkDelete() {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({action:'deleteSale'});
      if(!isPermit) {
        return alert("Permission: You don't have permission to delete Sale. Please contact to your owner.");
      }
      this.deleteDataEle?.initDeleteBulkSale();
    } catch (error) {
      SentryUtilites.setLog("SalePage:bulkDelete", error)
      alert("Something went wrong.");
    }
  }

  onBulkDelete(success: boolean) {
    success && this.reduceFunctionCall();
  }

  resetSelectedSale() {
    this.selectedSale = null;
  }

  addPayment(sale: Sale) {
    this.selectedSale = sale;
    this.showMoneyInSelector = true;
  }

  toggleOption() {
    this.isOptionOpen = !this.isOptionOpen;
  }

  clickOutSide() {
    this.isOptionOpen = false;
  }

  keepOpenOption() {
    setTimeout(() => {
      this.isOptionOpen = true;
    }, 0)
  }

  closeMoneyInSelector() {
    this.showMoneyInSelector = false;
    this.reduceFunctionCall();
  }

  @HostListener('window:resize', ['$event'])
  OnResize() {
    try {
      setTimeout(() => {
        this.isMobile = this.commonService.isMobile();
        if(this.isMobile) {
          this.innerHeight = `${(((window?.innerHeight - 268)/window?.innerHeight) * 100)}%`;
        } else {
          this.innerHeight = `${(((window?.innerHeight - 174)/window?.innerHeight) * 100)}%`;
        }
      }, 50)
    } catch (error) {
      SentryUtilites.setLog("SalePage:OnResize", error)
    }
  }

  async fixBillNumbersConfirm(): Promise<boolean> {
    return new Promise(async (resolve,reject) => {
      try {
        const alert = await this.alertController.create({
          mode: 'ios',
          header: 'Warning',
          message: 'This will update all sales to make bill numbers in sequence. This action is not reversible. Please confirm to proceed.',
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              handler: () => {
                resolve(false);
                return true;
              },
            },
            {
              text: 'Proceed',
              role: 'confirm',
              handler: () => {
                resolve(true);
                return true;
              },
            },
          ],
        });
        await alert.present();
      } catch (error) {
        SentryUtilites.setLog("SalePage:fixBillNumbersConfirm", error)
      }
    });
  }

  async fixBillNumbers() {
    try {
      const confirm = await this.fixBillNumbersConfirm();
      if(confirm) {
        let allSales = await this.allDataService.saleService.getAllByProfileWithRunningBill() || [];
        allSales.sort((a,b)=>a?.createdStamp-b?.createdStamp);
        for (let i = 0; i < allSales?.length; i++) {
          const sale = allSales[i];
          if(sale?._localUUID) {
            if(i != 0) {
              sale.previousBillNo = sale?.billNo;
              sale.billNo = Utility.nextNo(allSales[i-1]?.billNo);
              if(sale?.billNo == sale?.previousBillNo) {
                sale.previousBillNo = null;
              }else {
                await this.allDataService.saleService.update(sale);
              }
            }
          }
        }
        alert('Success');
      }
    } catch (error) {
      alert("Something went wrong.");
      SentryUtilites.setLog("SalePage:fixBillNumbers", error)
    }
  }

  async onMoneyInsUpdateCart() {
    this.allDataService.saleService.reloadList();
    this.allDataService.partyService.reloadList();
  }

}
