import { BaseModel } from "./BaseModel.model";
import { Ingredient } from "./Ingredient.model";
import { ItemIngredient } from "./ItemIngredient.mode";

export class IItem {

  static SCHEMA_NAME = 'item';

}
export class Item extends BaseModel {

  profileId: string;

  itemName: string;

  sellPrice: number;

  purchasePrice: number;

  category: string;

  stock: number;

  type: 'product' | 'service';

  onlineDeliverySellPrice: number;

  onlineSellPrice: number;

  acSellPrice: number;

  nonAcSellPrice: number;

  mrp: number;

  expiryDate: number;

  brandName: string;

  wholesalePrice: number;

  wholesaleMinCutOffQty: number;

  itemCode: string;

  barcode: string;

  barcode2: string;

  barcode3: string;

  barcode4: string;

  barcode5: string;

  description: string;

  minStock: number;

  storageLocation: string;

  onlineDukanItem: boolean;
  
  //Non Gst Supplies
  isNonGstSupplies: number;

  isTaxExempted: number;

  isTaxZero: number;

  taxPercentage: number;

  cessPercentage: number;

  spIncTax: boolean;

  primaryUnit: string = '';

  secondaryUnit: string = '';

  images: string[];

  hsn:string = '';

  convertRatio: number = 0;

  convertRatioR: number = 0;

  discountFlat:number = 0;

  discountPercent:number = 0;

  note:string

  ppIncTax: boolean;

  itemIngredients: ItemIngredient[];

  isFavourite: boolean;

}
