<ion-header [ngClass]="{'mobile': isMobile}">
  <ion-toolbar class="{{ getHeaderColorClass() }} ion-toolbar-party-transaction">
    <app-navigation-control slot="start" type="party/transaction"></app-navigation-control>
    <ion-title class="header-title" [appHeaderTitleCss]="isMobile ? 'mobile' : ''">
      <span>
        Party Transaction
      </span>
      <ion-chip> Count: {{ filteredList?.length }} </ion-chip></ion-title>
    <ion-chip color="primary" class="btn-edit-party ion-chip" slot="end" (click)="editParty()"> Edit </ion-chip>
    <ion-chip color="danger" class="btn-delete-party ion-chip" slot="end" (click)="deleteParty()"> Delete </ion-chip>
    <ion-chip color="primary" slot="end" class="chip-option" (click)="toggleOption()" (clickOutSide)="clickOutSide()">
      <ion-icon name="ellipsis-vertical"></ion-icon>
    </ion-chip>
  </ion-toolbar>
  <ion-list *ngIf="isOptionOpen" class="show-chip-option-party-transaction">
    <ion-item (click)="editParty()">
      Edit
    </ion-item>
    <ion-item (click)="deleteParty()">
      Delete
    </ion-item>
  </ion-list>
</ion-header>

<ion-content 
  [ngClass]="{'mobile': isMobile}"
  >

  <span class="sp-delete-party" (click)="deleteParty(true)">.</span>
  <ion-card class="card-info card-info-transaction" mode="ios" [color]="ledgerCredit >= 0 ? 'success' : 'danger'">
    <ion-card-header>
      <ion-card-title>{{ this.fetchedParty?.name }}</ion-card-title>
      <ion-card-subtitle>
        {{ this.fetchedParty?.phone }}
        <ion-chip class="chip-report" (click)="openReportView()">Report</ion-chip>
      </ion-card-subtitle>
    </ion-card-header>

    <ion-card-content>
      Credit: 
      <ion-label>
        {{ ledgerCredit === null ? '' : (MATHABS(ledgerCredit) | currency: 'INR') }}
      </ion-label>
    </ion-card-content>
  </ion-card>

  <app-date-range 
    class="date-range-transaction" 
    listType="ledgerParty" 
    (dateRangeChange)="onDateRangeChange($event)"
    >
  </app-date-range>

  <ion-grid 
    class="all-list-include-date-filter-party-transaction"
    [ngStyle]="{height: innerHeight}"
    >
    <ion-content>
      <ion-row class="transaction-holder padding-5px">
        <ion-col size="12" class="col-custom-row parent-col" *ngFor="let transaction of viewFilteredList">
  
          <ion-button fill="clear" class="txt-transform-normal transaction-button">{{ splitByCamelCase(transaction?.type) }}</ion-button>
          <ion-button fill="clear" class="txt-transform-normal flt-rght transaction-button"> Running Bal: {{
            transaction.runningBalance |
            currency: 'INR' }}</ion-button>
  
          <app-sale-row *ngIf="transaction.type==='Sale'" [sale]="transaction.sale" (onDeleteClick)="deleteSale($event)"
            (onAddPaymentClick)="addPayment($event)">
          </app-sale-row>

          <app-sale-return-row *ngIf="transaction.type==='SaleReturn'" [saleReturn]="transaction.saleReturn" (onDeleteClick)="deleteSaleReturn($event)"
            (onAddPaymentClick)="addPaymentSaleReturn($event)">
          </app-sale-return-row>
  
          <app-purchase-row *ngIf="transaction.type==='Purchase'" [purchase]="transaction.purchase"
            (onDeleteClick)="deletePurchase($event)" (onAddPaymentClick)="addPaymentPurchase($event)">
          </app-purchase-row>

          <app-purchase-return-row *ngIf="transaction.type==='PurchaseReturn'" [purchaseReturn]="transaction.purchaseReturn"
            (onDeleteClick)="deletePurchaseReturn($event)" (onAddPaymentClick)="addPaymentPurchaseReturn($event)">
          </app-purchase-return-row>
  
          <app-expense-row *ngIf="transaction.type==='Expense'" [expense]="transaction.expense"
            (onDeleteClick)="deleteExpense($event)" (onAddPaymentClick)="addPaymentExpense($event)">
          </app-expense-row>
  
          <app-money-in-row *ngIf="transaction.type==='MoneyIn'" [moneyIn]="transaction.moneyIn"
            (onDeleteClick)="deleteMoneyIn($event)">
          </app-money-in-row>
  
          <app-money-out-row *ngIf="transaction.type==='MoneyOut'" [moneyOut]="transaction.moneyOut"
            (onDeleteClick)="deleteMoneyOut($event)">
          </app-money-out-row>
  
        </ion-col>
      </ion-row>
      <ion-infinite-scroll threshold="10px" *ngIf="viewFilteredList?.length" (ionInfinite)="loadMoreListData($event)">
        <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Loading more data...">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ion-grid>


  <app-money-in-selector #moneyInSelector *ngIf="showMoneyInSelector" [isSaleList]="true" [sale]="this.selectedSale"
    [billTotalAmount]="this.selectedSale.totalAmount" [moneyInCart]="this.selectedSale?.moneyIns" 
    (onModelClose)="showMoneyInSelector=false">
  </app-money-in-selector>

  <app-money-in-selector #moneyInSelector *ngIf="showMoneyInSelectorPurchaseReturn" [isPurchaseReturnList]="true" [purchaseReturn]="this.selectedPurchaseReturn"
    [billTotalAmount]="this.selectedPurchaseReturn.totalAmount" [moneyInCart]="this.selectedPurchaseReturn?.moneyIns" 
    (onModelClose)="showMoneyInSelectorPurchaseReturn=false">
  </app-money-in-selector>

  <app-money-out-selector #moneyOutSelectorSaleReturn *ngIf="showMoneyOutSelectorSaleReturn" [isSaleReturnList]="true"
    [saleReturn]="this.selectedSaleReturn" [billTotalAmount]="this.selectedSaleReturn.totalAmount"
    [moneyOutCart]="this.selectedSaleReturn?.moneyOuts" (moneyOutsSelectedEvent)="onMoneyOutCartUpdateSaleReturn($event)"
    (onModelClose)="showMoneyOutSelectorSaleReturn=false">
  </app-money-out-selector>
  
  <app-money-out-selector #moneyOutSelectorExpense *ngIf="showMoneyOutSelectorExpense" [isExpenseList]="true"
    [expense]="this.selectedExpense" [billTotalAmount]="this.selectedExpense.totalAmount"
    [moneyOutCart]="this.selectedExpense?.moneyOuts" (moneyOutsSelectedEvent)="onMoneyOutCartUpdateExpense($event)"
    (onModelClose)="showMoneyOutSelectorExpense=false">
  </app-money-out-selector>

  <app-money-out-selector #moneyOutSelectorPurchase *ngIf="showMoneyOutSelectorPurchase" [isPurchaseList]="true"
    [purchase]="this.selectedPurchase" [billTotalAmount]="this.selectedPurchase.totalAmount"
    [moneyOutCart]="this.selectedPurchase?.moneyOuts" (moneyOutsSelectedEvent)="onMoneyOutCartUpdatePurchase($event)"
    (onModelClose)="showMoneyOutSelectorPurchase=false">
  </app-money-out-selector>

</ion-content>

<app-delete-data #deleteDataEle (onPartyDelete)="onPartyDelete($event)"></app-delete-data>
<app-purchase-bulk-delete #deletePurchaseEle></app-purchase-bulk-delete>
<app-purchase-return-bulk-delete #deletePurchaseReturnEle></app-purchase-return-bulk-delete>
<app-sale-return-bulk-delete #saleReturnBulkDeleteEle (onPartyDelete)="onPartyDelete($event)"></app-sale-return-bulk-delete>
<app-pin-verification (verifyTransactionPin)="verifyTransactionPIN($event)" #pinVerificationElement></app-pin-verification>