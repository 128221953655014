import { AccessControlService } from './../../../services/auth/access-control.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MoneyIn } from '../../../models/MoneyIn.model';
import { Sale } from '../../../models/Sale.model';
import { AllDataService } from '../../../services/all-data.service';
import { PinVerificationComponent } from '../../pin-verification/pin-verification.component';
import { Estimate } from 'src/app/models/Estimate.model';
import { EventService } from '../../../services/event.service';
import { PremiumControlService } from '../../../services/auth/premium-control.service';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-estimate-row',
  templateUrl: './estimate-row.component.html',
  styleUrls: ['./estimate-row.component.scss'],
})
export class EstimateRowComponent implements OnInit {

  @Input() estimate: Estimate = null;
  @Input() isRecentTransactionList: boolean = false;

  @Output() onDeleteClick = new EventEmitter<Estimate>();
  @Output() onAddPaymentClick = new EventEmitter<Estimate>();

  @ViewChild('pinVerificationElement') pinVerificationElement: PinVerificationComponent;

  constructor(
    private router: Router,
    private accessControlService: AccessControlService,
    private eventService: EventService,
    private premiumControlService: PremiumControlService,
  ) { }

  ngOnInit() { }

  async billView(estimate: Estimate) {
    try {
      if(!estimate?.deletedStamp) {
        let isPermit = await this.accessControlService.isUserHasAccess({action:'viewEstimate'});
        if(!isPermit) {
          return alert("Permission: You don't have permission to view bill. Please contact to your owner.");
        }
        this.router.navigate([`bill-view-estimate/${estimate?._localUUID}`]);
      }
    } catch (error) {
      SentryUtilites.setLog("EstimateRowComponent:billView", error)
      alert("Something went wrong.");
    }
  }

  openTransactionPINModal() {
    this.pinVerificationElement?.openTransactionPINModal();
  }

  verifyTransactionPIN(event) {
    try {
      if(event) {
        this.router.navigate([`estimate/form/${this.estimate?._localUUID}`]);
      }
    } catch (error) {
      SentryUtilites.setLog("EstimateRowComponent:verifyTransactionPIN", error)
    }
  }

  async edit(estimate: Estimate) {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({action:'editEstimate'});
      if(!isPermit) {
        return alert("Permission: You don't have permission to edit Estimate. Please contact to your owner.");
      }
      this.openTransactionPINModal();
    } catch (error) {
      SentryUtilites.setLog("EstimateRowComponent:edit", error)
      alert("Something went wrong.");
    }
  }

  delete(estimate: Estimate) {
    this.onDeleteClick.emit(estimate);
  }

  //TODO replace with create sale
  async navigateToSale() {
    try {
      let isPermission = await this.premiumControlService.isPremiumAccess();
      if(!isPermission) {
        return this.eventService.setShowPremiumPopup(true, true);
      }
       let isPermit = await this.accessControlService.isUserHasAccess({action:'createSale'});
      if(!isPermit) {
        return alert("Permission: You don't have permission to create money in. Please contact to your owner.");
      }
      // this.router.navigate([`sale/form`]);
      this.router.navigate([`sale/form`],{
        queryParams : {
          estimateLocalUUID : this.estimate?._localUUID
        },
      });
    } catch (error) {
      SentryUtilites.setLog("EstimateRowComponent:navigateToSale", error)
      alert("Something went wrong.");
    }
  }

}
