import { BaseModel } from "./BaseModel.model";

export class IImage {

  static SCHEMA_NAME = 'image';

}
export class Image extends BaseModel {

  profileId: string;
  imageBase64: string;

}
