import { BaseModel } from "./BaseModel.model";

export class ICutOffDay {
  static SCHEMA_NAME = 'cutOffDay';
}

export class CutOffDay extends BaseModel {
  profileId: string;

  startStamp: number;
  endStamp: number;
  note: string;
}