import { commonTempData } from "./commonTempData";
import { MoneyInBillPrint } from "src/models/MoneyInBillPrint.model";

export class Temp1MoneyIn{

    constructor(
      private data: MoneyInBillPrint,
      ){
      }

    async main(){
      return `
      <style>
      ${commonTempData.tallyTemplateCss()}
      </style>
      <span class="st-inv-mobi-screen">
        <style>${commonTempData.mobileCss()}</style>
      </span>
      <span class="invoice-template-main">
        <div 
          class="container-new arial inv-body-holder"
          style="width:${this.data.pageWidth || 210}mm;background: white;padding: 0;visibility: visible;-webkit-print-color-adjust: exact !important;color-adjust: exact !important;">
          ${this.header()}
          ${this.templateBody()}
          ${await this.footer()}
          <div class="inv-bottom-wrapper" style="margin:0 !important; padding:0 !important">
            <div style="height: 1px; width: 100%;">
            </div>
            <div style="height: 9px;display: flow-root;width:100%;text-align:right;font-size: 12px;color: #d3d3d3;" class="f-16 inv-bottom">
            </div>
          </div>
        </div>
      </span> 
      `
    }
    header(){
      return `
        ${this.sellerDetails()}
        ${this.invoiceTitle()}
        ${this.partyDetails()} 
      `
    }

    async footer(){    
      let amountCalculation = this.footerCalculationForMoneyIn();
      return `
        ${amountCalculation}
        ${await this.footerBankDetails()}
        ${this.footerTNCAndNotes()}
        `
    }

    sellerDetails(){
      // S: OWNER DETAILS
        let html = "";
        let el=this.data?.profile || null;
        // close these two divs in last
        html += `
        <div class="inv-header-wrapper" style="margin:0 !important; padding:0 !important">
        <div style="height: 1px; width: 100%;"></div>
        <div style="display: flex;flex-wrap:wrap;width: 100%;align-items: flex-start;background: white;padding:0 25px;"
        class="top padding-002510-new inv-header">
        <div style="display: flow-root;width:100%;text-align:right;font-size: 12px;color: #d3d3d3;" class="f-16">
          ${this.data?.addressTo || ''}
        </div>
        <div style="display: flow-root;width: 80%;">`

        if(el?.legalName){
            html += `
            <div style="display: flow-root;font-weight: bold;color: ${this.data?.bill?.color}" class="font-24 f-b">
                ${el?.legalName}
                <img class="addProfileInfo icons-height no-print" src="/assets/icons/edit-inv.svg">
            </div>`
        }

        if(el?.addressLine1 || el?.addressProvience){
            html +=`
            <div style="display: flow-root;font-size: 16px;color: #000;" class="f-16">
                ${el?.addressLine1},${el?.addressCity || ''}${el?.addressProvience?', '+el?.addressProvience:''}${el?.addressPostalCode?', '+el?.addressPostalCode:''}
            </div>
            `
        }

        if(el?.contactPersonPhone && el?.contactPersonPhone!='0'){
            html += `
            <div style="display: flow-root;font-size: 16px;color: #000;" class="f-16">
                ${el?.contactPersonPhone}
            </div>
            `
        }

        if(el?.contactPersonEmail){
            html += `
            <div style="display: flow-root;font-size: 16px;color: #000;" class="f-16">
                ${el?.contactPersonEmail}
            </div>
            `
        }

        if(el?.gstin){
          html += `
          <div style="display: flow-root;font-size: 16px;color: #000;font-weight: bold;" class="f-16">
              ${el?.gstin}
          </div>
          `
      }


      if(el?.fssaiNumber){
        html += `
        <div style="display: flow-root;font-size: 16px;color: #000;font-weight: bold;" class="f-16">
            FSSAI No. : ${el?.fssaiNumber}
        </div>
        `
      }


      if(el?.licenseNumber){
        html += `
        <div style="display: flow-root;font-size: 16px;color: #000;font-weight: bold;" class="f-16">
            License No. : ${el?.licenseNumber}
        </div>
        `
      }

        if(
          el?.additionalDateFieldTitle 
          && ((el.additionalDateFieldTitle +"").trim()).length>0 
          && (el?.additionalDateFieldValue)
          && ((el?.additionalDateFieldValue + "").trim()).length>0
          ){
            html += `
            <div style="display: flow-root;font-size: 14px;color: #000;" class="f-14 m-5-new">
              <b>${el.additionalDateFieldTitle}:</b> ${el?.additionalDateFieldValue}
            </div>
            `
        }

        if(
          el?.additionalInputFieldTitle 
          && ((el?.additionalInputFieldTitle +"").trim()).length>0 
          && (el?.additionalInputFieldValue)
          && ((el?.additionalInputFieldValue + "").trim()).length>0
          ){
            html += `
            <div style="display: flow-root;font-size: 14px;color: #000;" class="f-14 m-5-new">
              <b>${el.additionalInputFieldTitle}:</b> ${el?.additionalInputFieldValue}
            </div>
            `
        }

        if(
          el?.additionalInputFieldTitle1 
          && ((el?.additionalInputFieldTitle1 +"").trim()).length>0 
          && (el?.additionalInputFieldValue1)
          && ((el?.additionalInputFieldValue1 + "").trim()).length>0
          ){
            html += `
            <div style="display: flow-root;font-size: 14px;color: #000; font-weight: bold;" class="f-14 m-5-new">
              ${el?.additionalInputFieldTitle1}: ${el?.additionalInputFieldValue1}
            </div>
            `
        }

        if(
          el?.additionalInputFieldTitle2 
          && ((el?.additionalInputFieldTitle2 +"").trim()).length>0 
          && (el?.additionalInputFieldValue2)
          && ((el?.additionalInputFieldValue2 + "").trim()).length>0
          ){
            html += `
            <div style="display: flow-root;font-size: 14px;color: #000; font-weight: bold;" class="f-14 m-5-new">
              ${el?.additionalInputFieldTitle2}: ${el?.additionalInputFieldValue2}
            </div>
            `
        }

        if(
          el?.additionalInputFieldTitle3 
          && ((el?.additionalInputFieldTitle3 +"").trim()).length>0 
          && (el?.additionalInputFieldValue3)
          && ((el?.additionalInputFieldValue3 + "").trim()).length>0
          ){
            html += `
            <div style="display: flow-root;font-size: 14px;color: #000; font-weight: bold;" class="f-14 m-5-new">
              ${el?.additionalInputFieldTitle3}: ${el?.additionalInputFieldValue3}
            </div>
            `
        }

        if(
          el?.additionalInputFieldTitle4 
          && ((el?.additionalInputFieldTitle4 +"").trim()).length>0 
          && (el?.additionalInputFieldValue4)
          && ((el?.additionalInputFieldValue4 + "").trim()).length>0
          ){
            html += `
            <div style="display: flow-root;font-size: 14px;color: #000; font-weight: bold;" class="f-14 m-5-new">
              ${el?.additionalInputFieldTitle4}: ${el?.additionalInputFieldValue4}
            </div>
            `
        }

        if(
          el?.additionalInputFieldTitle5 
          && ((el?.additionalInputFieldTitle5 +"").trim()).length>0 
          && (el?.additionalInputFieldValue5)
          && ((el?.additionalInputFieldValue5 + "").trim()).length>0
          ){
            html += `
            <div style="display: flow-root;font-size: 14px;color: #000; font-weight: bold;" class="f-14 m-5-new">
              ${el?.additionalInputFieldTitle5}: ${el?.additionalInputFieldValue5}
            </div>
            `
        }

        //closing one div out of two
        html+=`</div>`;

        if(this.data?.profile?.logoLink){
            html += `
            <div style="display: flow-root;width: 20%;">
              <img src="${this.data?.profile?.logoLink}" style="display: block;margin-left: auto;width:70%;">
            </div>
            `
        }

        //closing last div
        html+=`</div>`;
        // E: OWNER DETAILS
        // wrapper div
        html+=`</div>`;

        return html;

    }

    templateBody(){

      let html = '';
      let dynamicColumnCount = 0;
      let dynamicColWidthCss;

      if(dynamicColumnCount == 1){
        dynamicColWidthCss = `extraColOne`
      }
      else if(dynamicColumnCount == 2){
        dynamicColWidthCss = `extraColTwo`
      }
      else if(dynamicColumnCount == 3){
        dynamicColWidthCss = `extraColThree`
      }
      else if(dynamicColumnCount == 4){
        dynamicColWidthCss = `extraColFour`
      }

      //wrapper class 
      html+=`<div class="inv-item-list-holder-wrapper" style="margin:0 !important; padding:0 !important">
              <div style="height:1px; width: 100%;"></div>`
      // Main div , close it in last
      html += `<div style="width: 100%;padding:0px 25px;" class="padding-102525-new inv-item-list-holder">`;
      html += `</div>`
      html += `</div>`
      return html;
    }

    invoiceTitle(){
      let html = '';
      let invoiceTitle = commonTempData.getInvoiceTitle(this.data);

      let editIcon='';  

      if(invoiceTitle){
        html += `
        <div class="inv-title-holder-wrapper" style="margin:0 !important; padding:0 !important">
        <div style="height: 1px; width: 100%;"></div>
          <div style="display: flex;width: 100%;align-items: flex-start;background: white;padding:0 25px 5px;"
          class="padding-002510-new inv-title-holder">
              <div style="width: 100%;text-align: center;font-size: 16px;color: ${this.data?.bill?.color};font-weight: bold;" class="f-20 f-b">
              ${invoiceTitle} ${editIcon}
              </div>
          </div>
        </div>
        `
      }
      else {
        html += `
        <div class="inv-title-holder-wrapper" style="margin:0 !important; padding:0 !important">
        <div style="height: 1px; width: 100%;"></div>
          <div style="display: flex;width: 100%;align-items: flex-start;background: white;padding:0 25px 0px;"
          class="padding-002510-new inv-title-holder">
              <div style="width: 100%;text-align: center;font-size: 16px;color: ${this.data?.bill?.color};font-weight: bold;" class="f-20 f-b">
                Payment Receipt  ${editIcon}
              </div>
          </div>
        </div>
        `
      }
      return html;
    }

    partyDetails(){
      let el = this.data?.bill?.partyData?.profileData || null;

      let isParty = this?.data?.bill?.isPartyDeleted;
      
      let html ='';

      if(!el) return '' ;

      // close this div in last
      html +=`
      <div class="inv-bill-details-wrapper" style="margin:0 !important; padding:0 !important">
      <div style="height: 1px; width: 100%;"></div>
        <div style="display: flex;width: 100%;align-items: flex-start;background: white;padding:0 25px 0px;"
        class="padding-002510-new inv-bill-details">
      `
      //Naming this div as div1, close it after applying conditions
      html +=`
        <div style="display: flow-root;width: 58%;">
      `
      let billTitle =  'Received From';
      html +=`
        <div style="display: flow-root;font-weight: bold;font-size: 14px;color: #000;" class="f-14 f-b">${billTitle}</div>
        
      `
      if(el?.legalName){
        html +=`
         <div style="display: flow-root;font-size: 14px;color: #000;font-weight: bold;" class="f-14">
         ${el?.legalName}
         </div>
        `
      }

      if(el?.contactPersonName == el?.contactPersonPhone){
        html +=this.commonLeftSideFormatForPartyDetails(el?.contactPersonPhone)
      } 

      if(el?.contactPersonName){
        html+=`
      <div style="display: flow-root;font-size: 14px;color: #000;font-weight:bold;" class="f-14 m-5-new">
          ${el?.contactPersonName}
      </div>`
      }
      
      if(isParty){
            html+=`<div style="display: flow-root;font-size: 14px;color: red;font-weight:bold;" class="f-14 m-5-new">
                      Party Has Been Deleted
                  </div>`
      }
      if(el?.contactPersonPhone && el?.contactPersonPhone !='0'){
          html +=this.commonLeftSideFormatForPartyDetails(el?.contactPersonPhone)
      }

      
      if(el?.contactPersonEmail){
        html +=`
        <div style="display: flow-root;font-size: 14px;color: #000;" class="f-14 m-5-new">
          ${el?.contactPersonEmail}
        </div>`
      }

      if(el?.addressLine1 || el?.addressState){
        html +=`
        <div style="display: flow-root;font-weight: bold;font-size: 14px;color: #000;" class="f-14 f-b">
         Billing Address
      </div>
        <div style="display: flow-root;font-size: 14px;color: #000;" class="f-14 m-5-new">
          ${el?.addressLine1|| ''}${el?.addressState? ', '+el?.addressState:''}${el?.addressPincode?', '+el?.addressPincode:''}
        </div>`
      }

      if(el?.additionalDateFieldTitle
        && ((el?.additionalDateFieldTitle)+"").trim().length>0
        && el?.additionalDateFieldValue
        && ((el?.additionalDateFieldValue)+"").trim().length>0
        ){
        html +=`
        <div style="display: flow-root;font-size: 14px;color: #000;" class="f-14 m-5-new">
          <b>${el.additionalDateFieldTitle}:</b> ${el?.additionalDateFieldValue}
        </div>`
      }

      if(el?.additionalInputFieldTitle
        && ((el?.additionalInputFieldTitle)+"").trim().length>0 
        && el?.additionalInputFieldValue
        && ((el?.additionalInputFieldValue)+"").trim().length>0
        ){
        html +=`
        <div style="display: flow-root;font-size: 14px;color: #000;" class="f-14 m-5-new">
          <b>${el.additionalInputFieldTitle}:</b> ${el?.additionalInputFieldValue}
        </div>`
      }

      if(el?.gstNumber){
        html +=`
        <div style="display: flow-root;font-size: 14px;color: #000;font-weight: bold;" class="f-14 m-5-new">
            ${el?.gstNumber}
        </div>`
      }
      if(el?.udf1T && el?.udf1V){
        let text = `${el?.udf1T|| ''}${el?.udf1V? ' : '+el?.udf1V:''}`
        html +=this.commonLeftSideFormatForPartyDetails(text)
      }
      if(el?.udf2T && el?.udf2V){
        let text = `${ el?.udf2T || ''}${el?.udf2V? ' : '+el?.udf2V:''}`
        html +=this.commonLeftSideFormatForPartyDetails(text)
      }
      if(el?.udf3T && el?.udf3V){
        let text = `${el?.udf3T || ''}${el?.udf3V? ' : '+el?.udf3V:''}`
        html +=this.commonLeftSideFormatForPartyDetails(text)
      }


      // div1 closed
      html +=`</div>`

    // This is Right side of party Details
    //Naming this div as div2, close it after applying conditions
      html +=`<div style="display: flow-root;width: 42%;align-items: flex-start;flex-wrap: wrap;">`

     
        if(this.data?.bill?.billNo){
          let title = `Receipt No`
          html += this.commonRightSideFormatForPartyDetails(title, this.data?.bill.billNo);
        }
      
            html+=`<div
            style="display: flow-root;font-weight: bold;font-size: 14px;color: #000;width: 50%;float: left;"
            class="f-14 f-b m-5-new">
              Created On :
            </div>
      
            <div
            style="display: flow-root;font-size: 14px;color: #000;width: 50%;float: left;text-align: right;"
            class="f-14 m-5-new">
               ${commonTempData.dateToDDMMYYYHHMMAP(this.data?.bill?.createdStamp)}
            </div>`
      let isBillDateDiff=commonTempData.isBillDateDiff(this.data?.bill?.createdStamp,this.data?.bill?.billDateStamp);

      if(isBillDateDiff && this.data?.bill?.billDateStamp){
        html += this.commonDateFormat('Date', this.data?.bill?.billDateStamp);
      }

     
     
      // div2 closed
      html +=`</div>`
      // main div closed
      html +=`</div>`

      // wrapper div
      html+=`</div>`

      return html;

    }


    footerCalculationForMoneyIn(){
      let html = '';
      // close this main div in last
      html += `
      <div style="display: flex;width: 100%;align-items: flex-start;background: #ffffff;padding:0 25px 0;"
        class="padding-002500">`;

      //Naming this div as div1, close it after applying conditions
      html += `<div style="display: flow-root;width: 50%;padding: 0 10px 0 0;" class="padding-00100000">`;

      html += `
      <div style="display: flow-root;font-weight: bold;font-size: 14px;color: #000;text-transform: capitalize;"
      class="f-14 f-b">
        Amount in Words
      </div>
      <div style="display: flow-root;font-size: 14px;color: #000;text-transform: capitalize;" class="f-14">
       ${commonTempData.convertToIndianCurrency((this.data?.bill?.totalAmount).toString())}
        </div>`

      //closing div1
      html += `</div>`;

       //Naming this div as div2, close it after applying conditions
      html += ` <div style="display: flow-root;width: 50%;align-items: flex-start;flex-wrap: wrap;">`

      html +=this.commonAmountCalculationFormatForFooterForMoneyIn('Received:', commonTempData.unitAdjuster(this.data?.bill?.totalAmount||0));
      html +=this.commonAmountCalculationFormatForFooterForMoneyIn('Mode:',this.data?.bill?.txnMode);
      if(this.data?.bill?.txnRef){
        html +=this.commonAmountCalculationFormatForFooterForMoneyIn('Cheque No:',this.data?.bill?.txnRef);
      }
      
      html += `</div>`;
      //closing main div
      html += `</div>`;

      return html;
    }

    async footerBankDetails(){

      let obj = this.data?.profile || null;
      let html = '';

      // close this div in last
      html +=`
      <div class="inv-footer-wrapper" style="margin:0 !important; padding:0 !important"> 
        <div style="height: 1px; width: 100%;"></div>
        <div style="display: flex;width: 100%;align-items: flex-start;background: #ffffff;padding:0 25px 0;"
        class="padding-002500 inv-footer">`

      //Naming this div as div1, close it after applying conditions
      html += `
      <div style="display: flex;width: 46%;float: left;" class="m-5-new">
      `

      
      if(obj && (obj?.bankName || obj?.bankAccountNo  || obj?.bankIFSC || obj?.bankUPI)){
        if(obj?.bankUPI && this.data.qrPayLink){
          let qrCodeBase64 = await commonTempData.generateQR(this.data.qrPayLink);
    
          html += `
          <div style="width: 30%;float: left;" class="m-l-neg-10 m-l-neg-2">
            <img style="width: 100%;" src="${qrCodeBase64}">
          </div>`
        }
      //Naming this div as div2, close it after applying conditions
        html +=`
        <div style="width: 70%;float: left;padding: 0;" class="">
        <div style="font-weight: bold;font-size: 12px;width: 100%;color: #000;" class="f-16 f-b">Bank Details</div>
        `
        if(obj?.bankName){
          html += this.commonBankDetailsFormatForFooter(obj?.bankName)
        }

        if(obj?.bankAccountNo){
          html += this.commonBankDetailsFormatForFooter('Acc No : '+obj?.bankAccountNo)
        }
        if(obj?.bankAccountNo && obj?.bankAccountType){
          html += this.commonBankDetailsFormatForFooter('Acc Type : '+obj?.bankAccountType)
        }
        if(obj?.bankIFSC){
          html += this.commonBankDetailsFormatForFooter('IFSC : '+obj?.bankIFSC)
        }
        if(obj?.bankUPI){
          html += this.commonBankDetailsFormatForFooter(obj?.bankUPI)
        }
        else if(!obj?.bankUPI && obj?.bankAccountNo && obj?.bankIFSC){
          html += this.commonBankDetailsFormatForFooter(obj?.bankAccountNo+'@'+obj?.bankIFSC+'.ifsc.npci');
        }
      // div2 closed
        html += `</div>`
      }else{
        html+=`
        <div style="width: 70%;float: left;padding: 0;" class="addBankDetails no-print">
        <div style="font-weight: bold;font-size: 12px;width: 100%;" class="f-16 f-b">
         <a> Add Bank Details <img src="/assets/icons/edit-inv.svg" class="icons-height">
         </a>
        </div>
        </div>
        `
      }
      // div1 closed
      html +=`</div>`;
    // Main div closed
      html +=`</div>`;
    // wrapper div  
      html+=`</div>`;
      return html;

    }
    footerTNCAndNotes(){
 
      //TODO:Terms and Condition not showing properly while downloading.
      let html = '';

      if(this.data?.bill?.note){
        html += this.commmonTNCAndNotesFormat('Notes',this.data?.bill?.note,'inv-section-notes');
      }

      html+=`
        <div class="inv-no-signature-wrapper" style="margin:0 !important; padding:0 !important"> 
          <div style="height: 1px; width: 100%;"></div>
          <div style="display: flex;width: 100%;align-items: flex-start;background: white;padding:0 25px 0px;"
          class="padding-002510-new inv-no-signature">
              <div style="width: 100%;text-align: center;font-size: 12px;" class="f-14">
                This is computer generated Bill. Signature Not Required
              </div>
          </div>
        </div>  
        `
      
      return html;
    }

    commmonTNCAndNotesFormat(title, value,sectionClassName){
     return `
        <div class="${sectionClassName}-wrapper" style="margin:0 !important; padding:0 !important;"> 
          <div style="height: 1px; width: 100%;"></div>
          <div
          style="display: flex;flex-wrap:wrap;width: 100%;background: #ffffff;padding:0 25px 0px;"
          class="padding-002525-new m-5-new ${sectionClassName}">
            <div style="font-weight: bold;width: 100%;color: #000;" class="f-14 f-b">${title}</div>
            <div style="width: 100%;" class="f-14">
              ${value}
            </div>
          </div>
        </div>
        `
    }

    commonBankDetailsFormatForFooter(text){
      return ` <div style="font-size: 12px;width: 100%;" class="f-16">${text}</div>`
    }

    commonSignatureFormatForFooter(imgBase64, title){
      if(imgBase64.indexOf(".png")!=-1 || imgBase64.indexOf(".jpg")!=-1 || imgBase64.indexOf(".jpeg")!=-1 || imgBase64.indexOf(".webp")!=-1){
        
        imgBase64=`https://img.ezobanks.com/${imgBase64}`
      }
      
      //TODO: Add css and If condition if no img
      return `
      <div style="display: flow-root;width: 27%;float: left;padding: 0 20px 0;" class="padding-16-new m-5-new">
      <img style="width: 100%;object-fit: contain;object-position: bottom;margin-bottom:5px;" class="m-5-new imageBase64" src="${imgBase64}">
      <div style="width: 100%;text-align: center;font-size: 12px;" class="f-16">${title}</div>
      </div>
      `
    }

    commonAmountCalculationFormatForFooter(title, value){
      return `
      <div class="flex" style="display:flex;flex-wrap:wrap;justify-content:flex-start;width:100%;">
      <div style="display: flow-root;font-size: 14px;color: #000;width: 50%;float: left;"
      class="f-14">
        ${title}
      </div>
      <div
      style="display: flow-root;font-size: 14px;color: #000;width: 50%;float: left;text-align: right;"
      class="f-14">
         ₹${value}
      </div>
      </div>
      `
    }

    commonAmountCalculationFormatForFooterForMoneyIn(title, value){
      return `
      <div class="flex" style="display:flex;flex-wrap:wrap;justify-content:flex-start;width:100%;">
      <div style="display: flow-root;font-size: 14px;color: #000;width: 50%;float: left; font-weight:bold"
      class="f-14 m-5-new">
        ${title}
      </div>
      <div
      style="display: flow-root;font-size: 14px;color: #000;width: 50%;float: left;text-align: right;"
      class="f-14 m-5-new">
         ${value}
      </div>
      </div>
      `
    }

    commonLeftSideFormatForPartyDetails(text){
      return `
      <div style="display: flow-root;font-size: 14px;color: #000;" class="f-14 m-5-new">
          ${text} 
      </div>`
    }

    commonRightSideFormatForPartyDetails(title, value){
      return `
      <div class="flex" style="display:flex;flex-wrap:wrap;justify-content:flex-start;width:100%;">
      <div
      style="display: flow-root;font-weight: bold;font-size: 14px;color: #000;width: 50%;float: left;"
      class="f-14 f-b">
        ${title} :
      </div>

      <div
      style="display: flow-root;font-size: 14px;color: #000;width: 50%;float: left;text-align: right;"
      class="f-14">
         ${value}
      </div></div>`
    }

    commonDateFormat(dateName, timeStamp){
      return `
        <div
          style="display: flow-root;font-weight: bold;font-size: 14px;color: #000;width: 50%;float: left;"
          class="f-14 f-b m-5-new">
            ${dateName} :
          </div>

          <div
          style="display: flow-root;font-size: 14px;color: #000;width: 50%;float: left;text-align: right;"
          class="f-14 m-5-new">
             ${commonTempData.dateToDDMMYYY(timeStamp)}
        </div>
      `
    }
    
    commonDateTimeFormat(dateName, timeStamp){
      return `
        <div
          style="display: flow-root;font-weight: bold;font-size: 14px;color: #000;width: 50%;float: left;"
          class="f-14 f-b m-5-new">
            ${dateName} :
          </div>

          <div
          style="display: flow-root;font-size: 14px;color: #000;width: 50%;float: left;text-align: right;"
          class="f-14 m-5-new">
             ${commonTempData.dateToDDMMYYYHHMMAP(timeStamp)}
        </div>
      `
    }


}

