export class Validator {
  static phone = /^[123456789][0-9]{9}$/;
  static parcelPhone = /^(\d{10}|\d{12})$/;
  static otp = /^[0-9]{4}$/;
  static positiveNumber = /^\b[0-9]*\.?[0-9]+$/;
  static gstin = /^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$/;
  static email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  static pincode = /^[1-9][0-9]{5}$/;
  static upi = /^[\w.-]+@[\w.-]+$/;
  static bankAccountNo = /^[0-9]{5,22}$/;
  static bankIFSC = /^[a-zA-Z]{4}0[a-zA-Z0-9]{6}$/;
  static onlyWhiteSpaces = /^\s*$/;
  static _localUUID = /[0-9a-z]{8}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{4}-[0-9a-z]{12}/g;
  static noSpaceAtStart = /^(?!\s)/;
}
