import { AccessControlService } from './../../services/auth/access-control.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { IItem, Item } from '../../models/Item.model';
import { ItemListComponent } from '../../components/item-list/item-list.component';
import { AuthService } from '../../services/auth/auth.service';
import { Utility } from '../../utils/utility';
import { Subscription } from 'rxjs';
import { AllDataService } from '../../services/all-data.service';
import { DeleteDataComponent } from '../../components/delete-data/delete-data.component';
import { PinVerificationComponent } from '../../components/pin-verification/pin-verification.component';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-item',
  templateUrl: './item.page.html',
  styleUrls: ['./item.page.scss'],
})
export class ItemPage implements OnInit, AfterViewInit {
  @ViewChild('itemListEle') itemListEle: ItemListComponent;
  @ViewChild('pinVerificationElement') pinVerificationElement: PinVerificationComponent;

  getHeaderColorClass = Utility.getHeaderColorClass;

  lastSyncTime = Utility.getCollectionLastRespSyncTime(IItem.SCHEMA_NAME);
  subArr: Subscription[] = [];
  isTimeDifference = false;
  isOptionOpen: boolean = false;
  selectedOption: string = '';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private accessControlService: AccessControlService,
    private allDataService: AllDataService
  ) {}

  ngOnInit() {
    try {
      this.subArr.push(this.allDataService.lastSyncSubs.subscribe((x) => {
        this.lastSyncTime = Utility.getCollectionLastRespSyncTime(
          IItem.SCHEMA_NAME
        );
        this.isTimeDifference = this.allDataService.isTimeDifference;
      }));
    } catch (error) {
      SentryUtilites.setLog("ItemPage:ngOnInit", error)
    }
  }

  ngAfterViewInit() {
    try {
      let isLowStockListFilter = this.activatedRoute.snapshot.queryParamMap.get(
        'isLowStockListFilter'
      );
      if (isLowStockListFilter && this.itemListEle) {
        this.itemListEle.onSortChnage('lowStockByAsc');
      }
    } catch (error) {
      SentryUtilites.setLog("ItemPage:ngAfterViewInit", error)
    }
  }

  ngOnDestroy() {
    this.subArr?.forEach(sub => sub?.unsubscribe());
  }

  clickOutSide() {
    this.isOptionOpen = false;
  }

  async onNewItemClick() {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({
        action: 'createItem',
      });
      if (!isPermit) {
        return alert(
          "Permission: You don't have permission to create new item. Please contact to your owner."
        );
      }
      this.router.navigate([`item/form`]);
    } catch (error) {
      SentryUtilites.setLog("ItemPage:onNewItemClick", error)
      alert("Something went wrong.");
    }
  }

  onItemSelectedEvent(item: Item) {
    this.router.navigate([`item/transaction/${item?._localUUID}`]);
  }

  async bulkUpload() {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({
        action: 'createItem',
      });
      if (!isPermit) {
        return alert(
          "Permission: You don't have permission to create new item. Please contact to your owner."
        );
      }
      let phone = Utility.getFromLocalStorage('selectedProfileUserId');
      let profileId = Utility.getFromLocalStorage('selectedProfile');
      window.open(
        `https://db.ezobooks.in/kappa/excel/upload?userId=${phone}&profileId=${profileId}`
      );
    } catch (error) {
      SentryUtilites.setLog("ItemPage:bulkUpload", error)
      alert("Something went wrong.");
    }
  }

  async bulkDelete() {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({
        action: 'deleteItem',
      });
      if (!isPermit) {
        return alert(
          "Permission: You don't have permission to delete item. Please contact to your owner."
        );
      }
      this.selectedOption = 'bulkDelete';
      this.openTransactionPINModal();
    } catch (error) {
      SentryUtilites.setLog("ItemPage:bulkDelete", error)
      alert("Something went wrong.");
    }
  }

  async onPrintBarcodeClick() {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({
        action: 'editItem',
      });
      if (!isPermit) {
        return alert(
          "Permission: You don't have permission to edit item. Please contact to your owner."
        );
      }
      this.router.navigate([`item/barcode-print`]);
    } catch (error) {
      SentryUtilites.setLog("ItemPage:onPrintBarcodeClick", error)
    }
  }

  bulkEdit() {
    this.selectedOption = 'bulkEdit';
    this.openTransactionPINModal();
  }

  toggleOption() {
    this.isOptionOpen = !this.isOptionOpen;
  }

  openTransactionPINModal() {
    this.pinVerificationElement?.openTransactionPINModal();
  }

  verifyTransactionPIN(event) {
    try {
      if(event && this.selectedOption == 'bulkDelete') {
        let phone = Utility.getFromLocalStorage('selectedProfileUserId');
        let profileId = Utility.getFromLocalStorage('selectedProfile');
        window.open(
          `https://db.ezobooks.in/kappa/bulk/list?userId=${phone}&profileId=${profileId}`
        );
      } else if(event && this.selectedOption == 'bulkEdit') {
        this.router.navigate(['item/bulk-edit']);
      }
    } catch (error) {
      SentryUtilites.setLog("ItemPage:verifyTransactionPIN", error)
    }
  }
}
