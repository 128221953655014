<ion-modal class="modal-ingredient-selector" [isOpen]="isModalOpen" [backdropDismiss]="false" animated="false">
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-chip slot="start" color="tertiary" class="btn-back" (click)="openModal(false)">
          <ion-icon class="ion-icon-36px" name="arrow-back-sharp"></ion-icon>
        </ion-chip>
        <ion-searchbar
          #searchBar
          placeholder="Search by ingredient name..."
          slot="start"
          [debounce]="100"
          (ionChange)="onFilterChange($event['detail'].value)"
        >
        </ion-searchbar>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">

      <ion-grid>

        <ion-row>

          <ion-col size="3" *ngIf="!isMobile">

            <ion-row>
              <ion-col class="col-categories scroll-y" size="12">
                <ion-item
                  lines="none"
                  [class.active-item]="!appliedCategory"
                  (click)="onCategoryChange('')"
                >
                  All
                </ion-item>
                <ion-item
                  lines="none"
                  *ngFor="let category of categories"
                  [class.active-item]="category === appliedCategory"
                  [class.cart-item-category]="cartItemsCategories?.includes(category)"
                  (click)="onCategoryChange(category)"
                >
                  {{ category }} ({{ categoryWiseItemCount[category] || 0 }}) 
                </ion-item>
              </ion-col>
            </ion-row>

          </ion-col>

          <ion-col size-lg="9" size-xs="12">
            <ion-row>
              <ion-col class="col-ingredient" size-lg="3" size-xs="6" [ngClass]="{'active': itemIngredient['quantity']>0}" *ngFor="let itemIngredient of viewFilteredList">
                <ion-row>
                  <ion-col size="12" (click)="increaseQty(itemIngredient?.ingredient?._localUUID)">
                    <ion-button fill="clear" class="btn-item-name">
                      {{ shortStr(itemIngredient?.ingredient?.name,30) }}
                    </ion-button>
                  </ion-col>
                  <ion-col size="6" (click)="increaseQty(itemIngredient?.ingredient?._localUUID)">
                    <ion-button fill="clear" color="primary" class="btn-qty">Add</ion-button>
                  </ion-col>
                  <ion-col size="6">
                    <ion-button fill="clear" color="danger" class="btn-qty" [disabled]="itemIngredient['quantity']<0" (click)="decreaseQty(itemIngredient?.ingredient?._localUUID)">Reduce</ion-button>
                  </ion-col>
                  <ion-col size="12">
                    <ion-button fill="clear" class="btn-item-name" *ngIf="itemIngredient['quantity']>0">
                      Qty: <ion-input type="number" [value]="itemIngredient['quantity']" (ionBlur)="onInpQtyChange(itemIngredient?.ingredient?._localUUID,$event)"></ion-input> {{ itemIngredient?.ingredient?.unit }}
                    </ion-button>
                  </ion-col>
                </ion-row>
              </ion-col>
            </ion-row>
          </ion-col>

        </ion-row>

      </ion-grid>

      <ion-infinite-scroll
        threshold="10px"
        *ngIf="viewFilteredList?.length"
        (ionInfinite)="loadMoreListData($event)">
        <ion-infinite-scroll-content
          loadingSpinner="bubbles"
          loadingText="Loading more data...">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>

    </ion-content>
    <ion-footer>
      <ion-toolbar>
        <ion-button
          class="flt-rght btn-save"
          color="primary"
          (click)="saveAndProceed()"
        >
          Proceed
        </ion-button>
      </ion-toolbar>
    </ion-footer>
  </ng-template>
</ion-modal>
