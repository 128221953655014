<div #printBodyDivRef class="div-print-body section-to-print container-new" >

</div>

<ion-modal [isOpen]="isModalOpen" [backdropDismiss]="false" animated="false">
  <ng-template>
    <ion-header>
      <ion-toolbar class="{{ getHeaderColorClass() }}">
        <ion-title>Barcode Generator</ion-title>
        <ion-buttons class="barcode-btn" slot="end">
          <ion-button (click)="openModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">

      <ion-grid>
        <ion-row>
          <ion-col size="12">
            <div id="display-card">
              <table class="card-table">
                  <tr>
                      <td>
                          <div class="bar-card" id="first">
                              <div class="bar-title">
                                  {{item?.itemName}}
                                  <div class="company-name">
                                      {{companyName}}
                                  </div>
                              </div>
                              <div class="bar-price">
                                  <span *ngIf="item?.mrp">MRP: &#8377; {{item?.mrp}}</span>  <span *ngIf="item?.sellPrice" style="margin-left:5px;"> Sale:&#8377; {{item?.sellPrice}}</span>
                              </div>
                              <div class="bar-code">
                                  <svg #barcodeSvg></svg>
                              </div>
                          </div>
                      </td>
                  </tr>
              </table>
          </div>
          </ion-col>
        </ion-row>
      </ion-grid>

    </ion-content>
    <ion-footer>
      <ion-toolbar>
        <ion-button
          color="primary"
          expand="full"
          (click)="print()"
        >
          Print
        </ion-button>
      </ion-toolbar>
    </ion-footer>
  </ng-template>
</ion-modal>
