import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AllDataService } from '../../services/all-data.service';
import { AlertController} from '@ionic/angular';
import { Utility } from '../../utils/utility';
import { IProfile } from '../../models/Profile.model';
import { Subscription, fromEvent } from 'rxjs';
import { LogoutService } from '../../services/logout.service';
import { EventService } from '../../services/event.service';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.page.html',
  styleUrls: ['./logout.page.scss'],
})
export class LogoutPage implements OnInit {
  
  getHeaderColorClass = Utility.getHeaderColorClass;
  lastSyncTime = Utility.getCollectionLastRespSyncTime(IProfile.SCHEMA_NAME);
  lastSyncTimeSub: Subscription;
  isTimeDifference = false;
  
  countDown:number = 10;
  countDownInterval:any;
  isLoggedOut: boolean = false;

  unsyncedCollections: {
    [key: string]: {
      count: number,
      getAllUnsynced:Function, 
    }
  } = {};
  unsyncedRecordsCount:number = 0;

  listenSub: Subscription;

  constructor(
    private router: Router,
    private alertController: AlertController,
    private allDataService: AllDataService,
    private logoutService: LogoutService,
    private eventService: EventService,
  ) { }

  ngOnInit() { 
    try {
      this.allDataService.lastSyncSubs.subscribe(x => {
        this.lastSyncTime = Utility.getCollectionLastRespSyncTime(IProfile.SCHEMA_NAME);
        this.isTimeDifference = this.allDataService.isTimeDifference;
      });
      this.getAllUnsyncData();
      this.listen();
    } catch (error) {
      SentryUtilites.setLog("LogoutPage:ngOnInit", error)
    }
  }

  ionViewWillEnter() {
    this.eventService.setShowPremiumPopup(false);
  }


  async logout() {
    try {
      await this.logoutService.logout();
      this.isLoggedOut = true;
    } catch (error) {
      SentryUtilites.setLog("LogoutPage:logout", error)
    }
  }

  cancelLogout(){
    this.router.navigate(['dashboard']);
  }

  confirmLogout(){
    this.logoutConform();
  }

  async logoutConform(){
    try {
      const alert = await this.alertController.create({
        header: 'Alert!',
        message: 'Are you sure you want to Logout?',
        mode: 'ios',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
                this.resetCountdown();
                this.router.navigate(['dashboard']);
             },
          },
          {
            text: `Logout`,
            role: 'confirm',
            cssClass : "alert-button-pending",
            handler: () => {
              if(this.countDown > 0){
                return false;
              }
                  this.logout();
            },
          },
        ],
      });
  
      this.countDownInterval = setInterval(() => {
        this.countDown--;
        if (this.countDown === -1) {
          clearInterval(this.countDownInterval);
        } else {
          alert.message = `Are you sure you want to Logout? <span class="hideCounter">${this.countDown} </span>`;
          alert.buttons[1]["text"] = this.countDown > 0 ?`Logout (${this.countDown})` : 'Logout',
          alert.buttons[1]["cssClass"] = this.countDown > 0 ? 'alert-button-pending' :'alert-button-confirm' 
        }
      }, 1000);
  
      await alert.present();
    } catch (error) {
      SentryUtilites.setLog("LogoutPage:logoutConform", error)
    }
  }

  resetCountdown() {
    clearInterval(this.countDownInterval);
    this.countDown = 10; 
  }

  async getAllUnsyncData(){
    let unsynedData = await this.allDataService.getAllUnsyncData();
    this.unsyncedCollections = unsynedData.unsyncedCollections;
    this.unsyncedRecordsCount = unsynedData.totalUnsyncedRecords;
  } 

  logoutInitiateAt: number = 0;
  logoutInitiator() {
    this.logoutInitiateAt = +new Date();
  }

  listen() {
    try {
      if(!this.listenSub || this.listenSub?.closed) {
        this.listenSub = fromEvent(document,'keyup')?.subscribe((event:KeyboardEvent) => {
          if(event?.keyCode === 76 && (+new Date() - this.logoutInitiateAt) < 1000) {
            this.logout();
          }
        });
      }
    } catch (error) {
      SentryUtilites.setLog("LogoutPage:listen", error)
    }
  }

}
