import { PremiumControlService } from './../../services/auth/premium-control.service';
import { ExpressServerService } from './../../services/api/express-server.service';
import { AlertController, ToastController } from '@ionic/angular';
import { IProfile, Profile } from '../../models/Profile.model';
import { Utility } from '../../utils/utility';
import { PinVerificationComponent } from '../pin-verification/pin-verification.component';
import { AllDataService } from './../../services/all-data.service';
import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  ElementRef,
  HostListener,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';
import { CommonService } from '../../services/common.service';
import { AccessControlService } from '../../services/auth/access-control.service';
import { Router } from '@angular/router';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

export type Section = {
  id: number;
  header: string;
  settings: Setting[];
};

export type Setting = {
  title: string;
  description: string;
  isDescriptionInTextArea?: boolean;
  key: string;
  value: any;
  dataType: 'boolean' | 'string' | 'number';
  inputType:
    | 'Toggle'
    | 'Segment'
    | 'InputBox'
    | 'SelectBox'
    | 'ColorPicker'
    | 'TextArea'
    | 'SelectOption'
    | 'RadioButton';
  inputRange?: {
    min?: number;
    max?: number;
  };
  toggleIgnore?: boolean;
  sharedPreference?: boolean;
  mandatory?: boolean;
  showSetting?: boolean;
  disableSetting?: boolean;
  segments?: {
    title: string;
    value: any;
    description?: string;
  }[];
  selectOptions?: {
    title: string;
    value: any;
  } [];
  settings?: Setting[];
};

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  @Output('onSave') onSave = new EventEmitter<boolean>();

  @ViewChild('pinVerificationElement') pinVerificationElement: PinVerificationComponent;
  @ViewChild('inpPinResetOTPEle') inpPinResetOTPEle: ElementRef;
  @ViewChild('inpEnterNewPinEle') inpEnterNewPinEle: ElementRef;
  @ViewChild('inpSecondPinEle') inpSecondPinEle: ElementRef;
  @ViewChild('inpResetFixedOTPEle') inpResetFixedOTPEle:ElementRef;

  lastSyncTime = Utility.getCollectionLastRespSyncTime(IProfile.SCHEMA_NAME);
  lastSyncTimeSub: Subscription;
  isTimeDifference = false;

  isModalOpen = false;
  isPinGenerationModalOpen = false;
  isPinResetModalOpen = false;
  isResetFixedOTPModalOpen =false;
  falseCodeExecuted = false;

  isPremiumAccess = false;
  isUserHasAccess: boolean = false;
  isUserHaSendMessageToPartyAccess = false;
  isUserHaSendMessageToOwnerAccess = false;

  isMobile: boolean = null;

  viewSectionIds: number[] = [];
  viewSettingKeys: string[] = [];

  isManuallyChangeToggle: boolean = false;

  profileObj: Profile = null;

  isOwner: boolean = false;

  sections: Section[] = [
    {
      id: 2,
      header: '2. BILL SETTINGS',
      settings: [
        {
          title: '2.1 Item Selector Style',
          description: null,
          key: 'iSetItemSelectorStyleWeb',
          value: 'RestaurantImage',
          dataType: 'string',
          inputType: 'Segment',
          segments: [
            {
              title: 'List Style',
              value: 'List',
            },
            // {
            //   title: 'Restaurant Style',
            //   value: 'Restaurant',
            // },
            {
              title: 'Restaurant Image Style',
              value: 'RestaurantImage',
            },
          ],
        },
        {
          title: '2.2 Item Barcode Scanner',
          description: null,
          key: 'item_barcode_scanner',
          value: false,
          sharedPreference: true,
          dataType: 'boolean',
          inputType: 'Toggle',
        },
        // {
        //   title: '2.3 Calculator Billing for Retail',
        //   description: 'Billing based on item codes.',
        //   key: 'iSetCalculatorBillingStatus',
        //   value: false,
        //   dataType: 'boolean',
        //   inputType: 'Toggle',
        //   settings: [
        //     {
        //       title: 'Step: Sell Price',
        //       description: null,
        //       key: 'iSetCalculatorBillingSellPriceStatus',
        //       value: false,
        //       dataType: 'boolean',
        //       inputType: 'Toggle',
        //     }, {
        //       title: 'Step: Quantity',
        //       description: null,
        //       key: 'iSetCalculatorBillingQuantityStatus',
        //       value: false,
        //       dataType: 'boolean',
        //       inputType: 'Toggle',
        //     },
        //   ],
        // },
        {
          title: '2.4 Cash Sale by Default',
          description: 'No need to select party for new bills.',
          key: 'iSetAutoCashSaleStatus',
          value: false,
          dataType: 'boolean',
          inputType: 'Toggle',
        },
        {
          title: '2.5 Amount Received by Default',
          description: 'No need to manually enter Money In for new bills.',
          key: 'iSetAutoMoneyInStatus',
          value: false,
          dataType: 'boolean',
          inputType: 'Toggle',
        },
        // {
        //   title: '2.6 Next Bill after Save',
        //   description: 'App will be ready for next bill as soon as last bill is created or printed.',
        //   key: 'iSetAutoNextBillStatus',
        //   value: false,
        //   dataType: 'boolean',
        //   inputType: 'Toggle',
        // },
        // {
        //   title: '2.7 Auto Select Previous Sell Price',
        //   description: null,
        //   key: 'iSetAutoPreviousSellPriceStatus',
        //   value: false,
        //   dataType: 'boolean',
        //   inputType: 'Toggle',
        // },
        {
          title: '2.8 Sort by Item Code (Item Selector)',
          description: null,
          key: 'iSetItemsSortByCodeStatus',
          value: false,
          dataType: 'boolean',
          inputType: 'Toggle',
        },
        {
          title: '2.9 Show Bookmark List (Item Selector)',
          description:
            'Categories will be shown on left side for easy selection.',
          key: 'iSetShowItemCategoryListStatus',
          value: false,
          dataType: 'boolean',
          inputType: 'Toggle',
        },
        {
          title: '2.10 Sell Price Lock (Item Selector)',
          description:
            'Sell price will not be able change while creating Bills. It can be done from item list only.',
          key: 'iSetLockSellPriceStatus',
          value: false,
          dataType: 'boolean',
          inputType: 'Toggle',
        },
        {
          title: '2.11 Negative Stock Lock (Item Selector)',
          description:
            'You will not be able select items where quantity is less than zero.',
          key: 'iSetLockNegativeStockStatus',
          value: false,
          dataType: 'boolean',
          inputType: 'Toggle',
        },
        // {
        //   title: '2.12 Bill by Sale Person',
        //   description: 'Track sales person for each sell that you are making.',
        //   key: 'iSetBillBySalePersonStatus',
        //   value: false,
        //   dataType: 'boolean',
        //   inputType: 'Toggle',
        // },
        // {
        //   title: '2.13 Restaurant Item Selector Columns',
        //   description: null,
        //   key: 'iSetItemSelectorColumns',
        //   value: '3',
        //   dataType: 'string',
        //   inputType: 'Segment',
        //   segments: [
        //     {
        //       title: '2',
        //       value: '2',
        //     }, {
        //       title: '3',
        //       value: '3',
        //     }, {
        //       title: '4',
        //       value: '4',
        //     }
        //   ],
        // }, {
        //   title: '2.16 Item Category List Width (Item Selector)',
        //   description: null,
        //   key: 'iSetItemCategoryListWidth',
        //   value: '20%',
        //   dataType: 'string',
        //   inputType: 'Segment',
        //   segments: [
        //     {
        //       title: '10%',
        //       value: '10%',
        //     }, {
        //       title: '15%',
        //       value: '15%',
        //     }, {
        //       title: '20%',
        //       value: '20%',
        //     }, {
        //       title: '25%',
        //       value: '25%',
        //     }, {
        //       title: '30%',
        //       value: '30%',
        //     }, {
        //       title: '35%',
        //       value: '35%',
        //     }, {
        //       title: '40%',
        //       value: '40%',
        //     }, {
        //       title: '45%',
        //       value: '45%',
        //     }, {
        //       title: '50%',
        //       value: '50%',
        //     },
        //   ],
        // }, {
        //   title: '2.17 Item Price - Multiple Options',
        //   description: null,
        //   key: 'iSetItemPriceVariationsStatus',
        //   value: false,
        //   dataType: 'boolean',
        //   inputType: 'Toggle',
        // },
        {
          title: '2.17 Restaurant Item Selector Columns',
          description: null,
          key: 'iSetItemSelectorColumns',
          value: '4',
          dataType: 'number',
          inputType: 'Segment',
          segments: [
            {
              title: '2',
              value: '2',
            },
            {
              title: '3',
              value: '3',
            },
            {
              title: '4',
              value: '4',
            },
          ],
        },
        {
          title: '2.18 Alpha Numeric Barcodes',
          description: null,
          key: 'iSetAlphaNumericBarcodeStatus',
          value: false,
          dataType: 'boolean',
          inputType: 'Toggle',
        },
        {
          title: '2.23 Round Off Total Amount',
          description: null,
          key: 'iSetRoundOffTotalAmountStatus',
          value: false,
          dataType: 'boolean',
          inputType: 'Toggle',
        },
        {
          title: '2.24 Show Previous Balance',
          description: null,
          key: 'iSetAutoPreviousBalanceStatus',
          value: false,
          dataType: 'boolean',
          inputType: 'Toggle',
        },
        {
          title: '2.25 Service Charge (In %)',
          description: '',
          key: 'iSetServiceChargePercentage',
          value: null,
          dataType: 'number',
          inputType: 'InputBox',
          inputRange: {
            min: 0
          },
        },
        {
          title: '2.26 Party Wise Item Price',
          description: null,
          key: 'iSetItemPriceHistoryStatus',
          value: false,
          dataType: 'boolean',
          inputType: 'Toggle',
        },
        {
          title: '2.27 Hide Out Of Stock Items',
          description: null,
          key: 'iSetOutOfStockHideStatus',
          value: false,
          dataType: 'boolean',
          inputType: 'Toggle',
        },
        {
          title: '2.28 Bill Quick Save',
          description: null,
          key: 'iSetQuickSaleStatus',
          value: false,
          dataType: 'boolean',
          inputType: 'Toggle',
        },
        {
          title: '2.29 Show Current Stock in Bill',
          description: null,
          key: 'iSetShowStockStatus',
          value: false,
          dataType: 'boolean',
          inputType: 'Toggle',
        },
        {
          title: '2.30 Abbreviation Match',
          description: null,
          key: 'isAbbrivationSearchEnabled',
          value: false,
          dataType: 'boolean',
          inputType: 'SelectBox',
        },
        {
          title: '2.31 Select Item Filter Priority',
          description: null,
          key: 'isStartsWithSearchPrioritized',
          value: true,
          dataType: 'boolean',
          inputType: 'RadioButton',
          selectOptions: [
            {
              title: 'Starts With',
              value: true
            },
            {
              title: 'Contains In',
              value: false
            }
          ]
        },
        // {
        //   title: '2.30 Mera Bill Mera Adhikar',
        //   description: null,
        //   key: 'iSetPrintMBMA',
        //   value: true,
        //   dataType: 'boolean',
        //   inputType: 'Toggle',
        // },
        // {
        //   title: '2.30 Print Time on Bill',
        //   description: null,
        //   key: 'iSetPrintTimeOnBill',
        //   value: true,
        //   dataType: 'boolean',
        //   inputType: 'Toggle',
        // },
      ],
    },
    {
      id: 5,
      header: '5. PRINT SETTINGS',
      settings: [
        // {
        //   title: '5.1 Auto Print Sale',
        //   description: 'Bills will be auto printed after save.',
        //   key: 'pSetAutoPrintStatus',
        //   value: false,
        //   dataType: 'boolean',
        //   inputType: 'Toggle',
        // },
        {
          title: '5.2 Print Logo',
          description:
            'Upload logo from profile and it will be printed on the bills.',
          key: 'pSetLogoPrintStatus',
          value: false,
          dataType: 'boolean',
          inputType: 'Toggle',
        },
        {
          title: '5.3 Print QR',
          description:
            'UPI QR code will be printed on bill which customer can scan to pay the bills.',
          key: 'pSetQrPrintStatus',
          value: false,
          dataType: 'boolean',
          inputType: 'Toggle',
          settings : [
            {
              title: '5.3.1 Always Print QR',
              description:
                'UPI QR code will be always print on a bill, Even if bill amount is fully paid.',
              key: 'pSetAlwaysQrPrintStatus',
              value: false,
              dataType: 'boolean',
              inputType: 'Toggle',
            }
          ]
        },
        // {
        //   title: '5.4 Print Token No',
        //   description: 'Token number will be on bill.',
        //   key: 'pSetTokenNoPrintStatus',
        //   value: false,
        //   dataType: 'boolean',
        //   inputType: 'Toggle',
        // },
        {
          title: '5.5 Print TAX, MRP, HSN',
          description: null,
          key: 'pSetTaxMrpHsnPrintStatus',
          value: false,
          dataType: 'boolean',
          inputType: 'Toggle',
        },
        {
          title: 'Print Footer',
          description: 'Print impressive mesaage on the bills.',
          key: 'pSetTermsAndConditions',
          value: 'Thank You! Visit Again!',
          dataType: 'string',
          inputType: 'TextArea',
        },
      ],
    },
    {
      id: 6,
      header: '6. SECURITY SETTINGS',
      settings: [
        {
          title: 'Pin Access',
          description:
            'Safeguard EZO Transaction with PIN. Edit and Delete of each transaction will need PIN. Do not share with others.',
          key: 'aAppPinStatus',
          value: false,
          dataType: 'boolean',
          inputType: 'Toggle',
        },
      ],
    },
    {
      id: 7,
      header: '7. APP SETTINGS',
      settings: [
        {
          title: '7.3 Powered by Ezo',
          description: 'Show Your Love for EZO',
          key: 'pSetPoweredByEzoStatus',
          value: true,
          dataType: 'boolean',
          inputType: 'Toggle',
        },
        {
          title: '7.6 Bill Amount Sound',
          description: null,
          key: 'bill_amount_sound_status',
          value: false,
          sharedPreference: true,
          dataType: 'boolean',
          inputType: 'Toggle',
        },
        {
          title: '7.7 Pending KOT Sound',
          description: null,
          key: 'pending_kot_sound_status',
          value: false,
          sharedPreference: true,
          dataType: 'boolean',
          inputType: 'Toggle',
        },
        {
          title: '7.8 Discount Sound',
          description: null,
          key: 'discountSoundStatus',
          value: true,
          dataType: 'boolean',
          inputType: 'Toggle',
        },
        {
          title: '7.13 Configure App Color',
          description: null,
          key: '',
          value: 'Choose App Color',
          dataType: 'string',
          inputType: 'ColorPicker',
          settings: [
            {
              title: 'Sales On Hold | Party Selector',
              description: null,
              key: 'iSetColorPartySelectorSaleHold',
              value: '#E8F5E9',
              dataType: 'string',
              inputType: 'ColorPicker',
            },
            {
              title: 'Sales Kot Pending | Party Selector',
              description: null,
              key: 'iSetColorPartySelectorSaleKotPending',
              value: '#FFEBEE',
              dataType: 'string',
              inputType: 'ColorPicker',
            },
            {
              title: 'Selected Item Color | Item Selector',
              description: null,
              key: 'iSetColorItemSelectorSelectedItem',
              value: '#ADF7B6',
              dataType: 'string',
              inputType: 'ColorPicker',
            },
          ],
        },
        {
          title: '7.14 Cut Off Day Status',
          description: null,
          key: 'aAppCutOffDayStatus',
          value: false,
          dataType: 'boolean',
          inputType: 'Toggle',
        },
        {
          title: '7.15 Enable Online Billing Only',
          description: null,
          key: 'aAppEnableOnlineBillingStatus',
          value: false,
          dataType: 'boolean',
          inputType: 'Toggle',
        },
        // {
        //   title: 'Domain by Ezo',
        //   description: null,
        //   key: 'pSetDomainByEzoStatus',
        //   value: false,
        //   dataType: 'boolean',
        //   inputType: 'Toggle',
        // },
        {
          title: '7.16 Enable Raw Material',
          description: null,
          key: 'itSetEnableIngredient',
          value: false,
          sharedPreference: false,
          dataType: 'boolean',
          inputType: 'Toggle',
        },
      ],
    },
    {
      id: 8,
      header: '8. Messaging',
      settings: [
        {
          title: '8.1 Send Bill to Party on WhatsApp',
          description: null,
          key: 'mcSendMessageToParty',
          value: null,
          dataType: 'boolean',
          inputType: 'Toggle',
        },
        {
          title: '8.2 Send Bill to Owner on WhatsApp',
          description: null,
          key: 'mcSendMessageToOwner',
          value: null,
          dataType: 'boolean',
          inputType: 'Toggle',
        },
        {
          title: '8.3 Choose days to Send Reminder Message to Party',
          description: null,
          key: 'mcSendReminderMarketingMessageDays',
          value: null,
          dataType: 'number',
          inputType: 'Segment',
          segments: [
            {
              title: 'Deactivate',
              value: 0,
            },
            {
              title: '7',
              value: 7,
            },
            {
              title: '10',
              value: 10,
            },
            {
              title: '15',
              value: 15,
            },
            {
              title: '30',
              value: 30,
            },
          ],
        },
        //Birthday Reminder
        {
          title: '8.4 Show Upcoming Birthday ',
          description: null,
          key: 'aSetBirthdayReminderStatus',
          value: null,
          dataType: 'boolean',
          inputType: 'Toggle',
        },
      ],
    },
    {
      id: 9,
      header: '9. LOYALTY DISCOUNT-1',
      settings: [
        {
          title: '9.1 Discount-1 Status',
          description: null,
          key: 'dSetDiscountStatusI',
          value: true,
          dataType: 'boolean',
          inputType: 'Toggle',
        },
        {
          title: '9.2 Offer Discount-1 to Customers',
          description: null,
          key: 'dSetDiscountOfferTypeI',
          value: 1,
          dataType: 'number',
          inputType: 'Segment',
          showSetting: true,
          segments: [
            {
              title: 'REPEAT',
              value: 1,
              description: 'Returning Customers will get this Discount',
            },
            {
              title: 'ALL',
              value: 0,
              description: 'All Customers will get this Discount',
            },
          ],
        },
        {
          title: '9.3 Discount-1 Details',
          description: null,
          key: null,
          value: true,
          dataType: null,
          inputType: 'Toggle',
          toggleIgnore: true,
          showSetting: true,
          settings: [
            {
              title: 'Discount-1 Name',
              description: null,
              key: 'dSetDiscountNameI',
              value: 'Basic Discount',
              dataType: 'string',
              inputType: 'InputBox',
            },
            {
              title: 'Discount-1 Percent %',
              description: null,
              key: 'dSetDiscountPercentI',
              value: 2,
              dataType: 'number',
              inputType: 'InputBox',
              mandatory: true,
              inputRange: {
                min: 0.01,
                max: 100,
              },
            },
            {
              title: 'Discount-1 Maximum Amount',
              description: null,
              key: 'dSetDiscountMaximumAmountI',
              value: 20,
              dataType: 'number',
              inputType: 'InputBox',
              mandatory: true,
              inputRange: {
                min: 0.01,
              },
            },
            {
              title: 'Minimum Bill Amount for Discount-1',
              description: null,
              key: 'dSetDiscountMinimumAmountI',
              value: 1,
              dataType: 'number',
              inputType: 'InputBox',
              mandatory: true,
              inputRange: {
                min: 0,
              },
            },
            {
              title: 'Set Repeat Cycle (in days)',
              description: 'If the customer places their next order within specified days, loyalty discount will be applied',
              key: 'dSetDiscountExpiryDaysI',
              value: 365,
              dataType: 'number',
              inputType: 'InputBox',
              mandatory: true,
              disableSetting: false,
              inputRange: {
                min: 0.01,
              },
            },
          ],
        },
        {
          title: '9.4 Print Discount-1 TNC',
          description: `Example:
Get 10% Discount
1. Get 10% discount on next visit
2. Minimum Bill Amount Rs. 1000
3. Maximum discount Rs. 100
4. Next visit should be in 10 days
5. Offer subject to scheme available, please check with us before availing discount
`,
          isDescriptionInTextArea: true,
          key: 'dSetDiscountPrintStatus',
          value: false,
          dataType: 'boolean',
          inputType: 'Toggle',
        },
      ],
    },
    {
      id: 10,
      header: '10. LOYALTY DISCOUNT-2',
      settings: [
        {
          title: '10.1 Discount-2 Status',
          description: null,
          key: 'dSetDiscountStatusII',
          value: false,
          dataType: 'boolean',
          inputType: 'Toggle',
        },
        {
          title: '10.2 Offer Discount-2 to Customers',
          description: null,
          key: 'dSetDiscountOfferTypeII',
          value: 1,
          dataType: 'number',
          inputType: 'Segment',
          showSetting: true,
          segments: [
            {
              title: 'REPEAT',
              value: 1,
              description: 'Returning Customers will get this Discount',
            },
            {
              title: 'ALL',
              value: 0,
              description: 'All Customers will get this Discount',
            },
          ],
        },
        {
          title: '10.3 Discount-2 Details',
          description: null,
          key: null,
          value: true,
          dataType: null,
          inputType: 'Toggle',
          toggleIgnore: true,
          showSetting: true,
          settings: [
            {
              title: 'Discount-2 Name',
              description: null,
              key: 'dSetDiscountNameII',
              value: '',
              dataType: 'string',
              inputType: 'InputBox',
            },
            {
              title: 'Discount-2 Percent %',
              description: null,
              key: 'dSetDiscountPercentII',
              value: '',
              dataType: 'number',
              inputType: 'InputBox',
              mandatory: true,
              inputRange: {
                min: 0.01,
                max: 100,
              },
            },
            {
              title: 'Discount-2 Maximum Amount',
              description: null,
              key: 'dSetDiscountMaximumAmountII',
              value: '',
              dataType: 'number',
              inputType: 'InputBox',
              mandatory: true,
              inputRange: {
                min: 0.01,
              },
            },
            {
              title: 'Minimum Bill Amount for Discount-2',
              description: null,
              key: 'dSetDiscountMinimumAmountII',
              value: '',
              dataType: 'number',
              inputType: 'InputBox',
              mandatory: true,
              inputRange: {
                min: 0,
              },
            },
            {
              title: 'Set Repeat Cycle (in days)',
              description: 'If the customer places their next order within specified days, loyalty discount will be applied',
              key: 'dSetDiscountExpiryDaysII',
              value: '',
              dataType: 'number',
              inputType: 'InputBox',
              mandatory: true,
              disableSetting: false,
              inputRange: {
                min: 0.01,
              },
            },
          ],
        },
      ],
    },
  ];

  constructor(
    private allDataService: AllDataService,
    private toastController: ToastController,
    private alertController: AlertController,
    private expressServerService: ExpressServerService,
    private premiumControlService: PremiumControlService,
    private authService: AuthService,
    private commonService: CommonService,
    private accessControlService: AccessControlService,
    private router: Router,
  ) {}

  ngOnInit() {
    try {
      this.isMobile = this.commonService.isMobile();
      this.allDataService.lastSyncSubs.subscribe((x) => {
        this.lastSyncTime = Utility.getCollectionLastRespSyncTime(
          IProfile.SCHEMA_NAME
        );
        this.isTimeDifference = this.allDataService.isTimeDifference;
      });
    } catch (error) {
      SentryUtilites.setLog("SettingsComponent:ngOnInit", error)
    }
  }

  ngOnDestroy() {
    this.lastSyncTimeSub?.unsubscribe();
  }

  async openSettingsModal(isOpen: boolean = true) {
    try {
      this.isModalOpen = isOpen;
      if (this.isModalOpen) {

        const accessKeys = ['viewPinAccess', 'viewSendBillToParty', 'viewSendBillToOwner'];
        const accesses = await Promise.all(accessKeys.map(key => this.checkUserAccess(key)));
        [this.isUserHasAccess, this.isUserHaSendMessageToPartyAccess, this.isUserHaSendMessageToOwnerAccess] = accesses;

        this.isPremiumAccess = await this.premiumControlService.isPremiumAccess({alert:true});
        if(this.isPremiumAccess) {
          this.populateSettings();
          setTimeout(() => {
            this.falseCodeExecuted = true;
          }, 2000);
          } else {
          setTimeout(() => {
            this.viewSectionIds = [];
            this.viewSettingKeys = [];
          }, 500);
        }
      }
    } catch (error) {
      SentryUtilites.setLog("SettingsComponent:openSettingsModal", error)
    }
  }

   async openPinGenerationModal(isOpen: boolean = true) {
    try {
      if(isOpen) {
        setTimeout(() => {
          this.inpEnterNewPinEle?.nativeElement?.focus();
        }, 500);
      }
      this.isManuallyChangeToggle = false;
      if(!isOpen) {
        const profile =  await this.allDataService.profileService.getCurrentProfile();
        let securitySctionSetting = this.sections.filter(section => section?.id == 6);
        if(securitySctionSetting?.length) {
          securitySctionSetting[0].settings[0].value = profile?.aAppPinStatus
        }
      }
      this.isPinGenerationModalOpen = isOpen;
    } catch (error) {
      SentryUtilites.setLog("SettingsComponent:openPinGenerationModal", error)
    }
  }

  async openPinResetModal(isOpen: boolean = true, manuallyClose?: boolean) {
    try {
      this.isManuallyChangeToggle = false;
      if(isOpen) {
        setTimeout(() => {
          this.inpPinResetOTPEle?.nativeElement?.focus();
        }, 500)
      }
      if(!isOpen) {
        const profile =  await this.allDataService.profileService.getCurrentProfile();
        let securitySctionSetting = this.sections.filter(section => section?.id == 6);
        if(securitySctionSetting?.length) {
          securitySctionSetting[0].settings[0].value = profile?.aAppPinStatus
        }
      }
      this.isPinResetModalOpen = isOpen;
      if (manuallyClose) {
        this.openSettingsModal(false);
        this.showToast('Operation Cancelled', 'dark');
      }
    } catch (error) {
      SentryUtilites.setLog("SettingsComponent:openPinResetModal", error)
    }
  }

  async populateSettings() {
    try {
      const profile =
        await this.allDataService.profileService.getCurrentProfile();
      if (profile) {
        this.profileObj = profile;
        let phone = Utility.getFromLocalStorage('selectedProfileUserId');
        this.isOwner = phone === this.authService.getLoginPhone();
        this.sections?.forEach((section) => {
          section.settings.map((setting) => {
            this.applySetting(setting, profile);
            setting?.settings?.map((subSetting) => {
              this.applySetting(subSetting, profile);
            });
          });
        });
      }
      this;
    } catch (error) {
      SentryUtilites.setLog("SettingsComponent:populateSettings", error)
    }
  }

  applySetting(setting: Setting, profile: Profile) {
    try {
      if(profile?._localUUID) {
        if (setting?.sharedPreference) {
          setting.value = Utility.getFromLocalStorage(setting.key);
        } else if (profile[setting.key] != undefined) {
          setting.value = profile[setting.key];
        } else
        // for profile change it will set default value
        if (profile[setting.key] == undefined && setting?.inputType == 'Toggle' && setting.key != null) {
          setting.value = false;
          if(
            setting?.key == 'dSetDiscountStatusI' ||
            setting?.key == 'pSetPoweredByEzoStatus' ||
            setting?.key == 'discountSoundStatus' ||
            setting?.key == 'mcSendMessageToParty'
            ) {
            setting.value = true;
          }
        } else if (profile[setting.key] == undefined && setting?.inputType == 'InputBox' && setting.key != null) {
            setting.value = '';
            if(setting?.key == 'dSetDiscountNameI') {
              setting.value = 'Basic Discount';
            }
            if(setting?.key == 'dSetDiscountPercentI') {
              setting.value = 2;
            }
            if(setting?.key == 'dSetDiscountMaximumAmountI') {
              setting.value = 20;
            }
            if(setting?.key == 'dSetDiscountMinimumAmountI') {
              setting.value = 1;
            }
            if(setting?.key == 'dSetDiscountExpiryDaysI') {
              setting.value = 365;
            }
        } else if (profile[setting.key] == undefined && setting?.inputType == 'Segment' && setting.key != null) {
          if(setting?.key == 'dSetDiscountOfferTypeI') {
            setting.value = 1;
          }
          if(setting?.key == 'dSetDiscountOfferTypeII') {
            setting.value = 1;
          }
        }
      }

      if(this.profileObj){
        // set default value if key are not exist in profile object
        if(
          setting?.key == 'dSetDiscountPercentI' ||
          setting?.key == 'dSetDiscountMaximumAmountI' ||
          setting?.key == 'dSetDiscountExpiryDaysI' ||
          setting?.key == 'dSetDiscountOfferTypeI' ||
          setting?.key == 'dSetDiscountOfferTypeII'
        ) {
          if(!profile[setting?.key]) {
            this.profileObj[setting?.key] = setting?.value;
          }
        }

        if(setting?.key == 'dSetDiscountMinimumAmountI') {
          if(!(profile[setting?.key] >= 0)) {
            this.profileObj[setting?.key] = setting?.value;
          }
        }

        if(
          setting?.key == 'dSetDiscountStatusI' ||
          setting?.key == 'pSetPoweredByEzoStatus' ||
          setting?.key == 'discountSoundStatus'
        ) {
          if(!profile[setting?.key]) {
            this.profileObj[setting?.key] = setting?.value;
          }
        }

        if(setting?.key == 'dSetDiscountNameI' && !this.profileObj.hasOwnProperty('dSetDiscountNameI')) {
          this.profileObj[setting?.key] = 'Basic Discount';
        }
      }
      // else if (
      //   profile[setting.key] == null &&
      //   setting?.inputType == 'InputBox'
      // ) {
      //   setting.value = '';
      // }

      // In Loyalty Discount Setting hide sub settings when the Dicount-1 / Discount-2 feature is off.
      if(setting?.key == "dSetDiscountStatusI") {
        let index = this.sections.findIndex(section => section?.id === 9);
        this.sections[index].settings?.forEach(subSetting=> {
          if(subSetting.hasOwnProperty('showSetting')) {
            subSetting.showSetting = profile?.dSetDiscountStatusI;
          }
        })
      } else if (setting?.key == "dSetDiscountStatusII") {
        let index = this.sections.findIndex(section => section?.id === 10);
        this.sections[index].settings?.forEach(subSetting=> {
          if(subSetting.hasOwnProperty('showSetting')) {
            subSetting.showSetting = profile?.dSetDiscountStatusII;
          }
        })
      }

      // Disable the Set Repeat Cycle when users chooses ALL instead of Repeat
      if(setting?.key == 'dSetDiscountOfferTypeI') {
        let index = this.sections.findIndex(section => section?.id === 9);
        this.sections[index].settings?.forEach(subSetting=> {
          if(subSetting.title == '9.3 Discount-1 Details') {
            subSetting?.settings?.forEach(subSet => {
              if(subSet.hasOwnProperty('disableSetting')) {
                subSet.disableSetting = profile?.dSetDiscountOfferTypeI == 0;
              }
            })
          }
        })
      } else if(setting?.key == 'dSetDiscountOfferTypeII') {
        let index = this.sections.findIndex(section => section?.id === 10);
        this.sections[index].settings?.forEach(subSetting=> {
          if(subSetting.title == '10.3 Discount-2 Details') {
            subSetting?.settings?.forEach(subSet => {
              if(subSet.hasOwnProperty('disableSetting')) {
                subSet.disableSetting = profile?.dSetDiscountOfferTypeII == 0;
              }
            })
          }
        })
      }
    } catch (error) {
      SentryUtilites.setLog("SettingsComponent:applySetting", error)
    }


  }

  async onSettingUpdate(setting: Setting, event: any, checked?: boolean, id?: number) {
    try {
      if(id) {
        this.sections.map(settings => {
          if(id == settings.id) {
            settings.settings.map(subSetting => {
              if(subSetting?.title == setting?.title && subSetting?.inputType == 'Toggle') {
                subSetting.value = checked;
              }
            })
          }
        })
      }

      const profile = await this.allDataService.profileService.getCurrentProfile();

      let value;

      if (this.isUserHasPremimumAccess()) {
        if (setting.inputType === 'Toggle') {
          value = event?.detail?.checked;
        } else if (setting.inputType === 'InputBox') {
          if (setting.dataType === 'number') {
            if (
                (event?.detail?.value === '' ||
                event?.detail?.value == null ||
                !Utility.isNumber(event?.detail?.value) ||
                event?.detail?.value === '0') &&
                setting?.key !== 'dSetDiscountMinimumAmountI' &&
                setting?.key !== 'dSetDiscountMinimumAmountII'
                ) {
              if(setting?.mandatory) {
                this.profileObj[setting?.key] = value == undefined ? -1 : 0;
              } else if(setting?.key == 'iSetServiceChargePercentage') {
                value = 0;
              } else {
                value = null;
              }
            } else {
              value = Number(event?.detail?.value);
            }
          }
          if (setting.dataType === 'string') {
            value = event?.detail?.value;
          }
        } else if (
          setting.inputType === 'Segment' ||
          setting.inputType === 'TextArea' ||
          setting.inputType === 'SelectOption' ||
          setting.inputType === 'RadioButton'
        ) {
          value = event?.detail?.value;
        } else if (setting.inputType === 'SelectBox') {
          value = event?.detail?.checked;
        } else if (setting.inputType === 'ColorPicker') {
          value = event;
        }

        if (profile[setting.key] != value) {
          profile[setting.key] = value;
          if(this.profileObj){
            this.profileObj[setting?.key] = value;
          }
          // await this.allDataService.profileService.update(profile);
          // this.allDataService.listForceReloadSubs.next('profile-list');
        } else {
          if(this.profileObj){
            this.profileObj[setting?.key] = value;
          }
        }
      } else {
        setTimeout(() => {
          this.applySetting(setting, profile);
        }, 2000);
      }

      // In Loyalty Discount Setting hide sub settings when the Dicount-1 / Discount-2 feature is off.
      if(setting?.key == "dSetDiscountStatusI") {
        let index = this.sections.findIndex(section => section?.id === 9);
        this.sections[index].settings?.forEach(subSetting=> {
          if(subSetting.hasOwnProperty('showSetting')) {
            subSetting.showSetting = value;
          }
        })
      } else if (setting?.key == "dSetDiscountStatusII") {
        let index = this.sections.findIndex(section => section?.id === 10);
        this.sections[index].settings?.forEach(subSetting=> {
          if(subSetting.hasOwnProperty('showSetting')) {
            subSetting.showSetting = value;
          }
        })
      }

      // Disable the Set Repeat Cycle when users chooses ALL instead of Repeat
      if(setting?.key == 'dSetDiscountOfferTypeI') {
        let index = this.sections.findIndex(section => section?.id === 9);
        this.sections[index].settings?.forEach(subSetting=> {
          if(subSetting.title == '9.3 Discount-1 Details') {
            subSetting?.settings?.forEach(subSet => {
              if(subSet.hasOwnProperty('disableSetting')) {
                subSet.disableSetting = value == 0;
              }
            })
          }
        })
      } else if(setting?.key == 'dSetDiscountOfferTypeII') {
        let index = this.sections.findIndex(section => section?.id === 10);
        this.sections[index].settings?.forEach(subSetting=> {
          if(subSetting.title == '10.3 Discount-2 Details') {
            subSetting?.settings?.forEach(subSet => {
              if(subSet.hasOwnProperty('disableSetting')) {
                subSet.disableSetting = value == 0;
              }
            })
          }
        })
      }
    } catch (error) {
      SentryUtilites.setLog("SettingsComponent:onSettingUpdate", error)
      return false;
    }

  }

  async onSharedPreferenceUpdate(setting: Setting, event: any, checked?:boolean, id?:number) {
    try {
      if(id) {
        this.sections.map(settings => {
          if(id == settings.id) {
            settings.settings.map(subSetting => {
              if(subSetting?.title == setting?.title && subSetting?.inputType == 'Toggle') {
                subSetting.value = checked;
              }
            })
          }
        })
      }
      if (this.isUserHasPremimumAccess()) {
        let value;
        if (setting.inputType === 'Toggle') {
          value = event?.detail?.checked;
        } else if (setting.inputType === 'InputBox') {
          if (setting.dataType === 'number') {
            value = Number(event?.detail?.value);
          }
          if (setting.dataType === 'string') {
            value = event?.detail?.value;
          }
        } else if (setting.inputType === 'Segment') {
          value = event?.detail?.value;
        } else if (setting.inputType === 'TextArea') {
          value = event?.detail?.value;
        } else if (setting.inputType === 'ColorPicker') {
          value = event;
        }
        Utility.setToLocalStorage(setting?.key, value);
      } else {
        const profile =
          await this.allDataService.profileService.getCurrentProfile();
        this.applySetting(setting, profile);
      }
    } catch (error) {
      SentryUtilites.setLog("SettingsComponent:onSharedPreferenceUpdate", error)
    }
  }

  getInputType(setting: Setting) {
    try {
      if (setting.dataType === 'string') {
        return 'text';
      }
      if (setting.dataType === 'number') {
        return 'number';
      }
    } catch (error) {
      SentryUtilites.setLog("SettingsComponent:getInputType", error)
      return '';
    }
  }

  setInputMinMax(setting: Setting, event: any) {
    try {
      if(
        setting?.key != 'dSetDiscountExpiryDaysI' &&
        setting?.key != 'dSetDiscountMaximumAmountI' &&
        setting?.key != 'dSetDiscountPercentI' &&
        setting?.key != 'dSetDiscountExpiryDaysII' &&
        setting?.key != 'dSetDiscountMaximumAmountII' &&
        setting?.key != 'dSetDiscountPercentII'
        ) {
        if (
          setting?.dataType === 'number' &&
          (setting?.inputRange?.min ||
            setting?.inputRange?.min == 0 ||
            setting?.inputRange?.max)
        ) {
          let value = null;
          if (
            event?.target?.value != '' &&
            event?.target?.value != null &&
            event?.target?.value != undefined
          ) {
            value = Number(event?.target?.value);
            if (Utility.isNumber(value) == false) {
              value = setting?.inputRange?.min || 0;
            } else if (
              (setting?.inputRange?.min || setting?.inputRange?.min == 0) &&
              value < setting?.inputRange?.min
            ) {
              value = setting?.inputRange?.min;
            } else if (value > setting?.inputRange?.max) {
              value = setting?.inputRange?.max;
            }
          }
          if (value != null) {
            event.target.value = value;
          }
        }
      } else if(
        setting?.dataType === 'number' &&
        event?.target?.value != '' &&
        event?.target?.value != null &&
        event?.target?.value != undefined
        ) {
          if(+event?.target?.value < 0) {
            event.target.value = 0;
          }
      }
    } catch (error) {
      SentryUtilites.setLog("SettingsComponent:setInputMinMax", error)
    }
  }

  getCurrentSegmentDescription(
    segments: {
      title: string;
      value: any;
      description?: string;
    }[]
  ) {
    return '';
  }

  async onAppPinStatusUpdate(setting: Setting, event: any, checked?: boolean, id?: number) {
    try {
      if(id) {
        this.sections.map(settings => {
          if(id == settings.id) {
            settings.settings.map(subSetting => {
              if(subSetting?.title == setting?.title && subSetting?.inputType == 'Toggle') {
                subSetting.value = checked;
              }
            })
          }
        })
      }
      if (this.isUserHasPremimumAccess()) {
        if (this.falseCodeExecuted && this.isManuallyChangeToggle) {
          let isToggleOn = event?.detail?.checked;
          if (isToggleOn) {
            this.openPinGenerationModal();
          } else {
            this.showToast('OTP send Process Initiated', 'success');
            let res = await this.expressServerService.sendPinResetOTP();
            if (res?.['result']) {
              this.showToast('OTP send successfully.', 'success');
              this.openPinResetModal();
            } else {
              this.isManuallyChangeToggle = false;
              this.showToast('Failed to send OTP.', 'danger');
              setting.value = true;
            }
          }
        }
      } else {
        const profile =
          await this.allDataService.profileService.getCurrentProfile();
        this.applySetting(setting, profile);
      }
    } catch (error) {
      SentryUtilites.setLog("SettingsComponent:onAppPinStatusUpdate", error)
    }
  }

  onClickSetting() {
    this.isManuallyChangeToggle = true;
  }

  async matchPin() {
    try {
      let firstPin: string = this.inpEnterNewPinEle?.nativeElement?.value;
      let secondPin: string = this.inpSecondPinEle?.nativeElement?.value;
      if (firstPin === secondPin && firstPin?.length === 4 && secondPin?.length === 4) {
        const profile: Profile =
          await this.allDataService?.profileService?.getCurrentProfile();
        if(profile?._localUUID) {
          profile.aAppPinStatus = this.profileObj.aAppPinStatus = true;
          profile.aAppPin = this.profileObj.aAppPin = firstPin;
          await this.allDataService?.profileService?.update(profile);
          this.showToast('PIN set successfully.', 'success');
          this.allDataService?.listForceReloadSubs?.next('profile-list');
          this.sections?.forEach((section) => {
            section?.settings?.map((localSetting) => {
              if (localSetting.key === 'aAppPinStatus') {
                localSetting.value = true;
              }
            });
          });
          this.openPinGenerationModal(false);
        }
      } else if(firstPin?.length === 4 && secondPin?.length === 4 && firstPin !== secondPin) {
        this.showToast('PIN does not match', 'danger');
      } else if(firstPin?.length < 4 || secondPin?.length < 4) {
        this.showToast('Enter 4 digit pin', 'danger');
      }
    } catch (error) {
      SentryUtilites.setLog("SettingsComponent:matchPin", error)
    }
  }

  async save() {
    try {
      if (this.isUserHasPremimumAccess()) {
        if(await this.checkSaveDiscountValidation()) {
         await this.allDataService.profileService.update(this.profileObj);
          this.allDataService.listForceReloadSubs.next('profile-list');
          this.showToast('Success', 'success');
          this.onSave.emit(true);
          this.openSettingsModal(false);
        }
      }
    } catch (error) {
      SentryUtilites.setLog("SettingsComponent:save", error)
    }
  }

  /**
   *
   * @returns : validate discount mendatory feild
   */
  async checkSaveDiscountValidation(): Promise<boolean> {
    return new Promise((resolve, reject)=> {
      try {
        if(this.profileObj?.dSetDiscountStatusI && this.profileObj?.dSetDiscountStatusII) {
          if(+this.profileObj?.dSetDiscountOfferTypeI == 1 && +this.profileObj?.dSetDiscountOfferTypeII == 1) {
            if(
              !(this.profileObj?.dSetDiscountExpiryDaysI > 0 &&
                this.profileObj?.dSetDiscountPercentI > 0 &&
                this.profileObj?.dSetDiscountMaximumAmountI > 0 &&
                this.profileObj?.dSetDiscountMinimumAmountI+'' >= 0+'')
            ) {
              this.showToast('Fields with * mark are required', 'danger');
              return resolve(false);
            }
            if(
              !(this.profileObj?.dSetDiscountExpiryDaysII > 0 &&
                this.profileObj?.dSetDiscountPercentII > 0 &&
                this.profileObj?.dSetDiscountMaximumAmountII > 0 &&
                this.profileObj?.dSetDiscountMinimumAmountII+'' >= 0+'')
              ) {
              this.showToast('Fields with * mark are required', 'danger');
              return resolve(false);
            }
          } else if(+this.profileObj?.dSetDiscountOfferTypeI == 1 && !+this.profileObj?.dSetDiscountOfferTypeII) {
            if(
              !(this.profileObj?.dSetDiscountExpiryDaysI > 0 &&
                this.profileObj?.dSetDiscountPercentI > 0 &&
                this.profileObj?.dSetDiscountMaximumAmountI > 0 &&
                this.profileObj?.dSetDiscountMinimumAmountI+'' >= 0+'')
            ) {
              this.showToast('Fields with * mark are required', 'danger');
              return resolve(false);
            }
            if(
              !(this.profileObj?.dSetDiscountMaximumAmountII > 0 &&
                this.profileObj?.dSetDiscountPercentII > 0 &&
                this.profileObj?.dSetDiscountMinimumAmountII+'' >= 0+'')
              ) {
              this.showToast('Fields with * mark are required', 'danger');
              return resolve(false);
            }
          } else if(!+this.profileObj?.dSetDiscountOfferTypeI && +this.profileObj?.dSetDiscountOfferTypeII == 1) {
            if(

              !(this.profileObj?.dSetDiscountMaximumAmountI > 0 &&
                this.profileObj?.dSetDiscountPercentI > 0 &&
                this.profileObj?.dSetDiscountMinimumAmountI+'' >= 0+'')
              ) {
              this.showToast('Fields with * mark are required', 'danger');
              return resolve(false);
            }
            if(
              !(this.profileObj?.dSetDiscountExpiryDaysII > 0 &&
                this.profileObj?.dSetDiscountPercentII > 0 &&
                this.profileObj?.dSetDiscountMaximumAmountII > 0 &&
                this.profileObj?.dSetDiscountMinimumAmountII+'' >= 0+'')
              ) {
              this.showToast('Fields with * mark are required', 'danger');
              return resolve(false);
            }
          }
        } else if (this.profileObj?.dSetDiscountStatusI) {
          if(+this.profileObj?.dSetDiscountOfferTypeI == 1) {
            if(
              !(this.profileObj?.dSetDiscountExpiryDaysI > 0 &&
                this.profileObj?.dSetDiscountPercentI > 0 &&
                this.profileObj?.dSetDiscountMaximumAmountI > 0 &&
                this.profileObj?.dSetDiscountMinimumAmountI+'' >= 0+'')
            ) {
              this.showToast('Fields with * mark are required', 'danger');
              return resolve(false);
            }
          } else {
            if(
              !(this.profileObj?.dSetDiscountMaximumAmountI > 0 &&
                this.profileObj?.dSetDiscountPercentI > 0 &&
                this.profileObj?.dSetDiscountMinimumAmountI+'' >= 0+'')
            ) {
              this.showToast('Fields with * mark are required', 'danger');
              return resolve(false);
            }
          }
        } else if (this.profileObj?.dSetDiscountStatusII) {
          if(+this.profileObj?.dSetDiscountOfferTypeII == 1) {
            if(
              !(this.profileObj?.dSetDiscountExpiryDaysII > 0 &&
                this.profileObj?.dSetDiscountPercentII > 0 &&
                this.profileObj?.dSetDiscountMaximumAmountII > 0 &&
                this.profileObj?.dSetDiscountMinimumAmountII+'' >= 0+'')
            ) {
              this.showToast('Fields with * mark are required', 'danger');
              return resolve(false);
            }
          } else {
            if(
              !(this.profileObj?.dSetDiscountMaximumAmountII > 0 &&
                this.profileObj?.dSetDiscountPercentII > 0 &&
                this.profileObj?.dSetDiscountMinimumAmountII+'' >= 0+'')
            ) {
              this.showToast('Fields with * mark are required', 'danger');
              return resolve(false);
            }
          }
        }
        return resolve(true)
      } catch (error) {
        SentryUtilites.setLog("SettingsComponent:checkSaveDiscountValidation", error)
        return resolve(false)
      }
    })
  }
  // ---------------------------------------------------

  async setFixedOTP() {
    try {
      if(await this.checkUserAccess('viewSetLoginPin')){
        if (this.isUserHasPremimumAccess()) {
          let result = await this.presentLoginPinPrompt();
          if (result) {
            this.showToast('Success','success');
          } else {
            this.showToast('Failed', 'danger');
          }
        }
      }else{
        this.showToast("You don't have permission, please contact to your owner",'danger')
      }
    } catch (error) {
      SentryUtilites.setLog("SettingsComponent:setFixedOTP", error)
    }

  }

  async presentLoginPinPrompt(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        const alert = await this.alertController.create({
          backdropDismiss: false,
          header: 'Enter 4 Digit Fixed PIN',
          buttons: [
            {
              text: 'Cancel',
              handler: async () => {
                resolve(false);
                return true;
              },
            },
            {
              text: 'Set',
              handler: async (value) => {
                if (value?.pin?.length === 4) {
                  let res = await this.expressServerService.setFixedOTP(
                    value?.pin
                  );
                  if (res?.result) {
                    resolve(res.result ? true : false);
                  }
                } else {
                  resolve(false);
                }
                return true;
              },
            },
          ],
          mode: 'ios',
          inputs: [
            {
              type: 'text',
              placeholder: '4 Digit Login PIN',
              name: 'pin',
              attributes: {
                autoFocus: true,
              },
            },
          ],
        });
        await alert.present();
      } catch (error) {
        SentryUtilites.setLog("SettingsComponent:presentLoginPinPrompt", error)
        return false;
      }
    });
  }

  async verifyPinResetOTP() {
    try {
      this.isManuallyChangeToggle = false;
      let otp: string = this.inpPinResetOTPEle?.nativeElement?.value as string;
      if (otp) {
        let res = await this.expressServerService?.verifyPinResetOTP(otp + '');
        if (res?.['result']) {
          this.openPinResetModal(false);
          const profile: Profile =
            await this.allDataService?.profileService?.getCurrentProfile();
          if(profile?._localUUID) {
            profile.aAppPinStatus = this.profileObj.aAppPinStatus = false;
            profile.aAppPin = null;
            let securitySctionSetting = this.sections?.filter(section => section?.id == 6);
            securitySctionSetting[0].settings[0].value = profile?.aAppPinStatus
            await this.allDataService?.profileService?.update(profile);
            this.allDataService?.listForceReloadSubs?.next('profile-list');
            this.showToast('Verify OTP successfully & disable security setting', 'success');
          }
        } else {
          this.showToast('Enter valid OTP', 'danger');
        }
      } else if(otp?.length < 4) {
        this.showToast('Enter 4 digit OTP', 'danger');
      } else {
        this.showToast('Enter valid OTP', 'danger');
      }
    } catch (error) {
      SentryUtilites.setLog("SettingsComponent:verifyPinResetOTP", error)
    }
  }

  async verifyResetFixedOTP(){
    let otp: string = this.inpResetFixedOTPEle?.nativeElement?.value as string;
    otp = otp+'';
    try {
      if(otp?.length < 4){
        this.showToast('Enter 4 digit OTP', 'danger');
      }else if(otp?.length == 4){
        let res = await this.expressServerService?.resetFixedOtp(otp);
        if (res?.status == "success") {
          this.openResetFixedOTPModal(false);
          this.showToast('Reset OTP successfully', 'success');
        } else {
          this.showToast('Reset OTP Unsuccessfull', 'danger');
        }
      }else {
        this.showToast('Enter valid OTP', 'danger');
      }
    }catch (error) {
      SentryUtilites.setLog("SettingsComponent:verifyResetFixedOTP", error)
      this.showToast('Something Went wrong', 'danger');
    }
  }

  isUserHasPremimumAccess(): boolean {
    try {
      if (this.isPremiumAccess) {
        return true;
      } else if (this.falseCodeExecuted) {
        this.showToast('Premium Permission: Your account is not premium', 'warning');
        return false;
      } else {
        return false;
      }
    } catch (error) {
      SentryUtilites.setLog("SettingsComponent:isUserHasPremimumAccess", error)
      return false;
    }
  }

  async activateOnlineShop() {
    try {
      if(await this.checkUserAccess('viewActiveOnlineShop')){
        let phone = this.authService?.getLoginPhone();
        let profileId = Utility?.getFromLocalStorage('selectedProfile');
        window.open(`https://ezoshop.in/register/${phone}/${profileId}`);
      }else{
        this.showToast("You don't have permission, please contact to your owner",'danger');
      }
    } catch (error) {
      SentryUtilites.setLog("SettingsComponent:activateOnlineShop", error)
    }
  }

  async qrOnlineShop() {
    try {
      if(await this.checkUserAccess('viewQrForOnlineShop')){
        let phone = this.authService?.getLoginPhone();
        let profileId = Utility?.getFromLocalStorage('selectedProfile');
        window.open(`https://ezoshop.in/register/qr/${phone}/${profileId}`);
      }else{
        this.showToast("You don't have permission, please contact to your owner",'danger');
      }
    } catch (error) {
      SentryUtilites.setLog("SettingsComponent:qrOnlineShop", error)
    }
  }

  async openResetFixedOTPModal(isOpen: boolean = true) {
    try {
      if(await this.checkUserAccess('viewResetLoginPin')){
        if (this.isUserHasPremimumAccess()){
          if(isOpen){
            let res = await this.expressServerService?.sendPinResetOTP();
            if (res?.['result']) {
              this.showToast('OTP send successfully', 'success');
              this.isResetFixedOTPModalOpen = isOpen;
              setTimeout(() => {
                this.inpResetFixedOTPEle?.nativeElement?.focus();
              }, 200);
            } else {
              this.showToast('Failed to send OTP', 'danger');
            }
          }else{
            this.isResetFixedOTPModalOpen = isOpen;
          }
        }
      }else{
        this.showToast("You don't have permission, please contact to your owner",'danger');
      }
    } catch (error) {
      SentryUtilites.setLog("SettingsComponent:openResetFixedOTPModal", error)
    }
  }

  @HostListener('window:resize', ['$event'])
  OnResize() {
    try {
      setTimeout(()=> {
      this.isMobile = this.commonService.isMobile()
      }, 20)
    } catch (error) {
      SentryUtilites.setLog("SettingsComponent:OnResize", error)
    }
  }

  async showToast(msg: string, color: string) {
    try {
      const toast = await this.toastController?.create({
        header: msg,
        duration: 2000,
        position: 'top',
        mode: 'ios',
        color: color,
      });
      await toast?.present();
    } catch (error) {
      SentryUtilites.setLog("SettingsComponent:showToast", error)
    }
  }

  async checkUserAccess(key:any):Promise<boolean>{
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({action: key});

      if(key === ''){
        console.log(isPermit)
      }
       return isPermit;
    } catch (error) {
      SentryUtilites.setLog("SettingsComponent:checkUserAccess", error)
      return false;
    }
  }

  getMoreCustomers() {
    this.isModalOpen = false;
    //this.router.navigate(['google-mybusiness']);
    window.open(
      `https://gmc.ezobooks.in/`,
      '_blank'
    );
  }
}
