import { BaseModel } from "./BaseModel.model";

export class IItemCategory {

  static SCHEMA_NAME = 'item-category';

}

export default class ItemCategory extends BaseModel {

  profileId: string;
  name: string;
  savedPosition: number;

}
