import { Router } from '@angular/router';
import { AllDataService } from './../../../services/all-data.service';
import { IProfile, Profile } from './../../../models/Profile.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Utility } from '../../../utils/utility';
import { Validator } from '../../../utils/validator';
import { Image } from '../../../models/image.model';
import { AccessControlService } from '../../../services/auth/access-control.service';
import { Subscription } from 'rxjs';
import { ProfileFormComponent } from '../../../components/profile-form/profile-form.component';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-profile-form-page',
  templateUrl: './profile-form.page.html',
  styleUrls: ['./profile-form.page.scss'],
})
export class ProfileFormPage implements OnInit {

  @ViewChild('profileFormComponentEle') profileFormComponentEle: ProfileFormComponent;

  getHeaderColorClass = Utility.getHeaderColorClass;
  
  lastSyncTime = Utility.getCollectionLastRespSyncTime(IProfile.SCHEMA_NAME);
  lastSyncTimeSub: Subscription;
  isTimeDifference = false;

  isEditProfileAccess = true;

  constructor(
    private router: Router,
    private accessControlService: AccessControlService,
    private allDataService: AllDataService,
  ) { }

  ngOnInit() {
    try {
      this.allDataService.lastSyncSubs.subscribe(x => {
        this.lastSyncTime = Utility.getCollectionLastRespSyncTime(IProfile.SCHEMA_NAME);
        this.isTimeDifference = this.allDataService.isTimeDifference;
      });
    } catch (error) {
      SentryUtilites.setLog("ProfileFormPage:ngOnInit", error)
    }
  }

  ngAfterViewInit() { }

  async ionViewWillEnter() {
    this.isEditProfileAccess = await this.accessControlService.isUserHasAccess({action:'editProfile'});
  }

  ngOnDestroy() {
    this.lastSyncTimeSub?.unsubscribe();
  }

  onUpdate = (event) => {
    event && this.router.navigate(['dashboard']);
  }

}
