import { Router } from '@angular/router';
import { Utility } from '../../utils/utility';
import { ExpressServerService } from '../../services/api/express-server.service';
import { Validator } from '../../utils/validator';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth/auth.service';
import { IonInput } from '@ionic/angular';
import { CommonService } from 'src/app/services/common.service';
import { EventService } from '../../services/event.service';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-sign-in-v2',
  templateUrl: './sign-in-v2.page.html',
  styleUrls: ['./sign-in-v2.page.scss'],
})
export class SignInV2Page implements OnInit {

  @ViewChild('phoneEle') phoneEle: IonInput;
  @ViewChild('otpEle') otpEle: IonInput;

  signInForm: FormGroup = null;

  isDirectSignIn = true;
  isOTPSent = false;

  showBtnLoader = false;

  constructor(
    private formBuilder: FormBuilder,
    private cd: ChangeDetectorRef,
    private router: Router,
    private expressServerService: ExpressServerService,
    private authService: AuthService,
    private commonService: CommonService,
    private eventService: EventService,
  ) {
    if (this.authService.isUserLoggedIn && Utility.getFromLocalStorage('Initial_data_download_complete')) {
      this.router.navigate(['dashboard']);
    }
  }

  ngOnInit() {
    this.initializeReactiveForm();
  }

  ngAfterViewInit() {
    this.eventService.setShowContactSupportPopup(false);
    this.eventService.setShowPremiumPopup(false);
  }

  initializeReactiveForm() {
    this.signInForm = this.formBuilder.group({
      phone: [null, [Validators.required, Validators.pattern(Validator.phone)]],
      termsAndCondition: [null, Validators.requiredTrue],
      otp: [null, Validators.pattern(Validator.otp)]
    });
  }

  changeMobile(phone: string, termsAndCondition: boolean) {
    this.isOTPSent = false;
    this.signInForm = null;
    this.initializeReactiveForm();
    this.signInForm.patchValue({phone, termsAndCondition});
    setTimeout(() => {
      this.phoneEle?.setFocus();
      this.cd.detectChanges();
    }, 200);
  }

  async getOTP() {
    try {
      // if (this.isDirectSignIn) {
      //   return await this.directSignIn();
      // }
  
      if (!navigator?.onLine) {
        return alert('It seems like internet is not connected.');
      }
  
      this.showBtnLoader = true;
      const getOTPRes = await this.expressServerService.sendOtpCallV2(this.signInForm?.value?.phone?.toString());
      this.showBtnLoader = false;
  
      this.isOTPSent = (getOTPRes?.status === 'success');
  
      if (this.isOTPSent) {
        this.signInForm.get('otp').addValidators(Validators.required);
        await this.commonService.generateToast('tertiary', "OTP Sent Successfully");
        setTimeout(() => {
          this.otpEle?.setFocus();
          this.cd.detectChanges();
        }, 200);
      }else {
        return await this.commonService.generateToast('warning', "Something went wrong."); 
      }
  
      this.cd?.detectChanges();
    } catch (error) {
      SentryUtilites.setLog("SignInV2Page:getOTP", error)
      return false;
    }



  }

  async verifyOTP() {
    try {
      if (!navigator?.onLine) {
        return alert('It seems like internet is not connected.');
      }
  
      let location = await Utility.getCurrentLongLat();
      let device = this.commonService.getDevice();
  
      this.showBtnLoader = true;
      const verifyOTPRes = await this.expressServerService.verifyOtpCall(
        this.signInForm?.value?.phone?.toString(),
        this.signInForm?.value?.otp?.toString(),
        location?.lat,
        location?.long,
        device
      );
      this.showBtnLoader = false;
  
      const otpVerified=verifyOTPRes?.otpVerified;
      
      if(otpVerified){
        await this.commonService.generateToast('tertiary', "OTP Verified Successfully");
        const token = verifyOTPRes?.tokenDetails?.token;
        this.authService.setToken(token);
        if(verifyOTPRes?.isFetchAllZip) {
          this.router.navigate(['fetch-initial-data-v2']);
        } else {
          this.router.navigate(['fetch-initial-data']);
        }
      }else{
        await this.commonService.generateToast('warning', "Wrong otp"); 
      }
    } catch (error) {
      SentryUtilites.setLog("SignInV2Page:verifyOTP", error)
    }


  }

  async setSignInDataToLocalStorage(param: {
    token: string;
  }) {
    //Utility.setToLocalStorage('ezo-token', data?.token);
    Utility.setToLocalStorage('fireBaseUserToken', param?.token);
    //Utility.setToLocalStorage('isSignUp', data?.isSignUp);
  }

  async directSignIn() {
    try {
      if (!navigator?.onLine) {
        return alert('It seems like internet is not connected.');
      }
  
      let location = await Utility.getCurrentLongLat();
      let device = this.commonService.getDevice();
  
      const getOTPRes = await this.expressServerService.sendOtpCall(this.signInForm?.value?.phone);
      const verifyOTPRes = await this.expressServerService.verifyOtpCall(
        this.signInForm?.value?.phone, 
        "0000",
        location?.lat,
        location?.long,
        device
      );
      const token = verifyOTPRes?.tokenDetails?.token;
  
      this.authService.setToken(token);
      // this.directSignInOld();

      this.router.navigate(['fetch-initial-data-v2']);
    } catch (error) {
      SentryUtilites.setLog("SignInV2Page:directSignIn", error)
    }
  }

  async directSignInOld() {
    try {
      if (!navigator?.onLine) {
        return alert('It seems like internet is not connected.');
      }
  
      const verifyOTPRes = await this.expressServerService.makePostCall(
        'user/directSignIn',
        {
          phone: this.signInForm?.value?.phone,
          otp: '0000',
        }
      );
  
      if (verifyOTPRes?.status === 'success' && verifyOTPRes?.data?.fbToken) {
        //this.setSignInDataToLocalStorage(verifyOTPRes.data);
  
  
        //@Temporary
        let userId = verifyOTPRes?.data?.user?.uid;
        let profileId = verifyOTPRes?.data?.user?.profileId;
        let masterUserId = verifyOTPRes?.data?.user?.uid;
  
        Utility.setToLocalStorage("_userId", userId);
        Utility.setToLocalStorage("_profileId", profileId);
        Utility.setToLocalStorage("_masterUserId", masterUserId);
      } else {
        return alert('Something went wrong.');
      }
    } catch (error) {
      SentryUtilites.setLog("SignInV2Page:directSignInOld", error)
    }
  }


}
