import { Component, OnInit } from '@angular/core';
import { Utility } from '../../utils/utility';
import { CommonService } from '../../services/common.service';
import Party from '../../models/Party.model';
import { AllDataService } from '../../services/all-data.service';
import { AlertController, LoadingController, ToastController } from '@ionic/angular';
import { ExpressServerService } from 'src/app/services/api/express-server.service';
import { Profile } from 'src/app/models/Profile.model';
import { Router } from '@angular/router';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

export type BulkWaTemplates = {
  templateId: string;
  msgBody: string;
  variables: {
      key: string;
      value: string;
  }[];
}

@Component({
  selector: 'app-whatapp-bulk-msg',
  templateUrl: './whatapp-bulk-msg.page.html',
  styleUrls: ['./whatapp-bulk-msg.page.scss'],
})
export class WhatappBulkMsgPage implements OnInit {

  getHeaderColorClass = Utility.getHeaderColorClass;

  openPartyModal: boolean = false;
  openSampleTemplate: boolean = false;
  openCustomFieldTitle: boolean = false;
  selectedTemplate: BulkWaTemplates = null;
  selectedPartyList: Party[] = [];
  customFieldArray = [];
  userEnterCustomFieldValueObj = {};

  getCurrentProfile: Profile = null;
  loading: HTMLIonLoadingElement = null;

  templates: BulkWaTemplates[] = [];

  isMobile: boolean = null;

  constructor(
    private commonService: CommonService,
    private allDataService: AllDataService,
    private alertController: AlertController,
    private expressServerService: ExpressServerService,
    private router: Router,
    private loadingCtrl: LoadingController
  ) {}

  async ngOnInit() {
    try {
      this.isMobile = this.commonService.isMobile();
      this.getData();
      this.getCurrentProfile = await this.allDataService.profileService.getCurrentProfile();
    } catch (error) {
      SentryUtilites.setLog("WhatappBulkMsgPage:ngOnInit", error)
    }
  }

  async getData() {
    try {
      await this.showLoading();
      this.templates= await this.expressServerService.fetchWhatappTemplateData();
      if(this.templates?.length && Array.isArray(this.templates)) {
        this.loading?.dismiss();
      } else {
        this.loading?.dismiss();
        this.presentSuccessConfirm('Fail to load data');
      }
    } catch (error) {
      SentryUtilites.setLog("WhatappBulkMsgPage:getData", error)
    }
  }

  async showLoading() {
    try {
      this.loading = await this.loadingCtrl.create({ 
        message: 'Loading Templates...'
      });
      this.loading.present();
    } catch (error) {
      SentryUtilites.setLog("WhatappBulkMsgPage:showLoading", error)
    }
  }

  // RTODO : Need Code Cleaning
  selectTemplate(selectedTemplate: BulkWaTemplates) {
    try {
      let missingProfileDetails: string = '';
      selectedTemplate?.variables?.forEach((vars) => {
        if (vars?.value?.split('.')[0].toLowerCase() == 'profile') {
          if (missingProfileDetails && !this.getCurrentProfile[vars?.value?.split('.')[1]]) {
            missingProfileDetails = `${missingProfileDetails}, ${Utility.splitByCamelCase(vars?.value?.split('.')[1]).toLowerCase()}`;
          } else if(!this.getCurrentProfile[vars?.value?.split('.')[1]]) {
            missingProfileDetails = missingProfileDetails + `${Utility.splitByCamelCase(vars?.value?.split('.')[1]).toLowerCase()}`;
          }
        }
      });
      if (missingProfileDetails) {
        missingProfileDetails = 'Profile '+ missingProfileDetails+' missing';
      }
      if (missingProfileDetails) {
        this.presentSelectTemplateError(missingProfileDetails);
        return;
      }
      this.selectedTemplate = { ...selectedTemplate };
      this.openPartySelectorModal();
    } catch (error) {
      SentryUtilites.setLog("WhatappBulkMsgPage:selectTemplate", error)
    }
  }

  dismissPartyModal(event) {
    this.openPartyModal = false;
  }

  openPartySelectorModal(isOpen: boolean = true) {
    this.openPartyModal = isOpen;
  }

  // RTODO : Need Code Cleaning
  async selectedParties(parties: Party[]) {
    try {
      if (parties?.length) {
        this.customFieldArray = [];
        this.openPartySelectorModal(false);
        this.selectedPartyList = parties;
        for (let i = 0; i < this.selectedTemplate?.variables?.length; i++) {
          let template = this.selectedTemplate?.variables[i];
          let key = template?.key;
          let val = template?.value;
          let targetModal = val?.split('.')[0]?.toLowerCase();
          let targetKey = val?.split('.')[1];
          if (targetModal == 'profile') {
            if (this.getCurrentProfile[targetKey]) {
              this.selectedTemplate.msgBody = this.selectedTemplate?.msgBody?.replace(
                  key,
                  this.getCurrentProfile[targetKey]
                );
            }
          } else if (targetModal == 'party') {
            if (this.selectedPartyList[0][targetKey]) {
              this.selectedTemplate.msgBody = this.selectedTemplate?.msgBody?.replace(
                  key,
                  this.selectedPartyList[0][targetKey]
                );
            }
          } else if (targetModal == 'custom') {
            this.customFieldArray?.push(targetKey);
          }
        }
        if(this.customFieldArray?.length) {
          this.openCustomFieldTitle = true;
        } else {
          this.openSampleTemplate = true;
        }
      }
    } catch (error) {
      SentryUtilites.setLog("WhatappBulkMsgPage:selectedParties", error)
    }
  }

  async presentSelectTemplateError(textMsg: string) {
    try {
      const alert = await this.alertController.create({
        header: 'Alert!',
        message: `${textMsg}`,
        mode: 'ios',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {},
          },
          {
            text: 'Profile Form',
            role: 'confirm',
            handler: () => {
              this.router.navigate(['profile/form']);
            },
          },
        ],
      });
  
      await alert.present();
    } catch (error) {
      SentryUtilites.setLog("WhatappBulkMsgPage:presentSelectTemplateError", error)
    }
  }

  async presentSuccessConfirm(textMsg: string) {
    try {
      const alert = await this.alertController.create({
        header:`${textMsg}`,
        mode: 'ios',
        buttons: [
          {
            text: 'Okay',
            role: 'cancel',
            handler: () => {},
          },
        ],
      });
  
      await alert.present();
    } catch (error) {
      SentryUtilites.setLog("WhatappBulkMsgPage:presentSuccessConfirm", error)
    }
  }

  addCustomValue(title, value) {
    this.userEnterCustomFieldValueObj[title] = value;
  }

  setCustomFieldTitle() {
    try {
      for (let i = 0; i < this.selectedTemplate?.variables?.length; i++) {
        let template = this.selectedTemplate?.variables[i];
        let key = template?.key;
        let val = template?.value;
        let targetModal = val?.split('.')[0]?.toLowerCase();
        let targetKey = val?.split('.')[1];
        if (targetModal == 'custom') {
          this.selectedTemplate.msgBody = this.selectedTemplate?.msgBody?.replace(
            key,
            this.userEnterCustomFieldValueObj[targetKey]
          );
        }
      }
      this.openCustomFieldTitleModal();
      this.openSampleTemplate = true;
    } catch (error) {
      SentryUtilites.setLog("WhatappBulkMsgPage:setCustomFieldTitle", error)
    }
  }

  async sendTemplatesToParties() {
    try {
      this.openSampleTemplateModal();
      let bodyObj = {
        templateId: this.selectedTemplate?.templateId,
        profileId: Utility.getFromLocalStorage('selectedProfile'),
        userId: Utility.getFromLocalStorage('selectedProfileUserId'),
        partyUUIDArr: this.selectedPartyList?.map((party) => party?._localUUID),
        custom: this.userEnterCustomFieldValueObj
      };
      let message = await this.expressServerService.sendWhatappTemplateToParties(
        bodyObj
      );
      this.presentSuccessConfirm(message);
    } catch (error) {
      SentryUtilites.setLog("WhatappBulkMsgPage:sendTemplatesToParties", error)
    }
  }

  openSampleTemplateModal() {
    this.openSampleTemplate = false;
  }

  openCustomFieldTitleModal() {
    this.openCustomFieldTitle = false;
  }
  
}
