import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { BehaviorSubject, concat, interval } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { ToastController } from '@ionic/angular';
import { environment } from '../../environments/environment';
import { SentryUtilites } from '../utils/sentryUtilites';

@Injectable()
export class AppUpdateService {

  public appUpdateAvailableEvent: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private appRef: ApplicationRef,
    private updates: SwUpdate,
    private toastController: ToastController,

  ) {
    if (!environment.production) {
      return;
    }
    const appIsStable$ = this.appRef?.isStable.pipe(first(isStable => isStable === true));
    const everyHour$ = interval(1 * 60 * 60 * 1000);
    const everyHoursOnceAppIsStable$ = concat(appIsStable$, everyHour$);

    everyHoursOnceAppIsStable$.subscribe(async () => {
      try {
        const updateFound = await updates.checkForUpdate();
        if (updateFound) {
          this.appUpdateAvailableEvent.next(true);
        }
      } catch (err) {
        SentryUtilites.setLog("AllDataService:everyHoursOnceAppIsStable", err)
        console.error('Failed to check for updates:', err);
      }
    });

    updates.versionUpdates.subscribe(evt => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          console.info(`Downloading new app version: ${evt?.version?.hash}`);
          break;
        case 'VERSION_READY':
          console.info(`Current app version: ${evt?.currentVersion?.hash}`);
          console.info(`New app version ready for use: ${evt?.latestVersion?.hash}`);
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.info(`Failed to install app version '${evt?.version?.hash}': ${evt?.error}`);
          break;
      }
    });

    updates.versionUpdates
      .pipe(filter((evt): evt is VersionReadyEvent => evt?.type === 'VERSION_READY'))
      .subscribe(evt => {
        this.appUpdateAvailableEvent.next(true);
      });

    updates.unrecoverable.subscribe(event => {
      alert(
        'An error occurred that we cannot recover from:\n' +
        event.reason +
        '\n\nPlease reload the page.'
      );
    });

  }

  checkForAppUpdates() {
    try {
      if (!environment.production) {
        return;
      }
      this.updates
        .checkForUpdate()
        .then(async () => {
          const toast = await this.toastController.create({
            message: 'Update Check Success',
            duration: 1000,
            position: 'bottom'
          });
          await toast.present();
        })
        .catch(async err => {
          const toast = await this.toastController.create({
            message: 'Update Check Failed',
            duration: 1000,
            position: 'bottom'
          });
          await toast.present();
        });
      } catch (error: any) {
        SentryUtilites.setLog("AllDataService:checkForAppUpdates", error)
      }
  }
}
