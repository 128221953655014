import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appRmTimeBtn]'
})
export class RemoveTimeButtonDirective {

  constructor(private ionDatetimeButtonEle: ElementRef) {
    const style = (document.createElement('style'));
    style.textContent = `#time-button {
      display:none;
    }
    #date-button {
      border-radius: 0px !important;
      width: 100%;
      justify-content: left;
      display: flex;
      background: #F5F5F5;
      font-family: monospace;
    }
    `;
    this.ionDatetimeButtonEle?.nativeElement?.shadowRoot?.appendChild(style);
  }

}
