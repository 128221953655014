import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Utility } from 'src/app/utils/utility';

@Component({
  selector: 'app-error-not-found',
  templateUrl: './error-not-found.page.html',
  styleUrls: ['./error-not-found.page.scss'],
})
export class ErrorNotFoundPage implements OnInit {

  getHeaderColorClass = Utility.getHeaderColorClass;

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

  goToDashboard() {
    this.router.navigate(['/']);
  }

}
