import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AllDataService } from '../../services/all-data.service';
import { Sale } from '../../models/Sale.model';
import { PinVerificationComponent } from '../pin-verification/pin-verification.component';
import { Purchase } from '../../models/Purchase.model';
import { MoneyIn } from '../../models/MoneyIn.model';
import { MoneyOut } from '../../models/MoneyOut.model';
import Party from '../../models/Party.model';
import { Item } from '../../models/Item.model';
import { AlertController } from '@ionic/angular';
import { Expense } from '../../models/Expense.model';
import { Estimate } from 'src/app/models/Estimate.model';
import { Router } from '@angular/router';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-delete-data',
  templateUrl: './delete-data.component.html',
  styleUrls: ['./delete-data.component.scss'],
})
export class DeleteDataComponent implements OnInit {

  @Input() navigateTo: string = '';

  @Output() onPartyDelete = new EventEmitter<Party>();
  @Output() onItemDelete = new EventEmitter<Item>();
  @Output() onSaleDelete = new EventEmitter<Sale>();
  @Output() onEstimateDelete = new EventEmitter<Estimate>();
  @Output() onExpenseDelete = new EventEmitter<Expense>();
  @Output() onPurchaseDelete = new EventEmitter<Purchase>();
  @Output() onMoneyInDelete = new EventEmitter<MoneyIn>();
  @Output() onMoneyOutDelete = new EventEmitter<MoneyOut>();
  @Output() onBulkSaleDelete = new EventEmitter<boolean>();
  @Output() onBulkExpenseDelete = new EventEmitter<boolean>();
  @Output() onBulkPurchaseDelete = new EventEmitter<boolean>();
  @Output() onBulkMoneyInDelete = new EventEmitter<boolean>();
  @Output() onBulkMoneyOutDelete = new EventEmitter<boolean>();

  @ViewChild('pinVerificationElement') pinVerificationElement: PinVerificationComponent;

  // Sale
  isSaleModalOpen = false;
  sale: Sale = null;
  isDeleteSaleAttachedMoneyIns = true;
  saleDeleteStarted = false;
  saleDeleteCompleted = false;
  //----------------------------------------------------
  // Estimate
  isEstimateModalOpen = false;
  estimate: Estimate = null;
  estimateDeleteStarted = false;
  estimateDeleteCompleted = false;
  //----------------------------------------------------

  // Expense
  isExpenseModalOpen = false;
  expense: Expense = null;
  isDeleteExpenseAttachedMoneyOuts = true;
  expenseDeleteStarted = false;
  expenseDeleteCompleted = false;
  //----------------------------------------------------

  // Purchase
  isPurchaseModalOpen = false;
  purchase: Purchase = null;
  isDeletePurchaseAttachedMoneyOuts = true;
  purchaseDeleteStarted = false;
  purchaseDeleteCompleted = false;
  //----------------------------------------------------

  // Money In
  isMoneyInModalOpen = false;
  moneyIn: MoneyIn = null;
  moneyInDeleteStarted = false;
  moneyInDeleteCompleted = false;
  //----------------------------------------------------

  // Money Out
  isMoneyOutModalOpen = false;
  moneyOut: MoneyOut = null;
  moneyOutDeleteStarted = false;
  moneyOutDeleteCompleted = false;
  //----------------------------------------------------

  // Party
  isPartyModalOpen = false;
  party: Party = null;
  isDeletePartyAttachedSales = false;
  isDeletePartyAttachedMoneyIns = false;
  partyDeleteStarted = false;
  partyDeleteCompleted = false;
  //----------------------------------------------------

  // Item
  isItemModalOpen = false;
  item: Item = null;
  itemDeleteStarted = false;
  itemDeleteCompleted = false;
  //----------------------------------------------------

  // Bulk Sale
  isBulkSaleModalOpen = false;
  checkAllSaleCheckboxes: boolean = false;
  bulkSales: Sale[] = [];
  selectedBulkSales: Sale[] = [];
  isDeleteBulkSaleAttachedMoneyIns = true;
  bulkSaleDeleteStarted = false;
  bulkSaleDeleteCompleted = false;
  //----------------------------------------------------

  // Bulk Expense
  isBulkExpenseModalOpen = false;
  checkAllExpenseCheckboxes: boolean = false;
  bulkExpenses: Expense[] = [];
  selectedBulkExpenses: Expense[] = [];
  isDeleteBulkExpenseAttachedMoneyOuts = true;
  bulkExpenseDeleteStarted = false;
  bulkExpenseDeleteCompleted = false;
  //----------------------------------------------------

  // Bulk Purchase
  isBulkPurchaseModalOpen = false;
  checkAllPurchaseCheckboxes: boolean = false;
  bulkPurchases: Purchase[] = [];
  selectedBulkPurchases: Purchase[] = [];
  isDeleteBulkPurchaseAttachedMoneyOuts = true;
  bulkPurchaseDeleteStarted = false;
  bulkPurchaseDeleteCompleted = false;
  //----------------------------------------------------

  // Bulk Money In
  isBulkMoneyInModalOpen = false;
  bulkMoneyIns: MoneyIn[] = [];
  selectedBulkMoneyIns: MoneyIn[] = [];
  bulkMoneyInDeleteStarted = false;
  bulkMoneyInDeleteCompleted = false;
  //----------------------------------------------------

  // Bulk Money Out
  isBulkMoneyOutModalOpen = false;
  bulkMoneyOuts: MoneyOut[] = [];
  selectedBulkMoneyOuts: MoneyOut[] = [];
  bulkMoneyOutDeleteStarted = false;
  bulkMoneyOutDeleteCompleted = false;
  //----------------------------------------------------

  // Security Pin common functionality var
  selectedVar: string = '';
  fullToLowercaseArray: string[] = [];
  notfullToLowercaseArray: string[] = [];
  notfullToLowercaseObj = {
    'MoneyIn': 'moneyIn',
    'MoneyOut': 'moneyOut',
    'BulkSale': 'bulkSale', 
    'BulkExpense': 'bulkExpense',
    'BulkPurchase': 'bulkPurchase',
    'BulkMoneyIn': 'bulkMoneyIn',
    'BulkMoneyOut': 'bulkMoneyOut'
  }
  bulkSelectArray: string[] = [];
  // ---------------------------------

  constructor(
    private allDataService: AllDataService,
    private alertController: AlertController,
    private router: Router,
  ) { }

  ngOnInit() { }

  openTransactionPINModal() {
    this.pinVerificationElement?.openTransactionPINModal();
  }

  async verifyTransactionPIN(event) {
    try {
      if(this.fullToLowercaseArray?.includes(this.selectedVar) && Array?.isArray(this.fullToLowercaseArray)) {
        if(event == false) {
          this[`${this.selectedVar?.toLowerCase()}`] = null;
          this[`open${this.selectedVar}Modal`](false);
        } else {
          this[`open${this.selectedVar}Modal`]();
        }
      } else if(this.bulkSelectArray?.includes(this.selectedVar) && Array?.isArray(this.bulkSelectArray)) {
        if(event == false) {
          this[`${this.notfullToLowercaseObj[this.selectedVar]}`] = [];
          this[`selected${this.selectedVar}s`] = [];
          this[`open${this.selectedVar}Modal`](false);
        } else {
          if(this.notfullToLowercaseArray?.includes(this.selectedVar) && Array?.isArray(this.notfullToLowercaseArray)) {
            await this[`open${this.selectedVar}Modal`]();
            this[`${this.notfullToLowercaseObj[this.selectedVar]}s`] = await this.allDataService[`${this.notfullToLowercaseObj[this.selectedVar.replace('Bulk','')]}Service`]?.getAllByPromise();
          } else {
            this[`open${this.selectedVar}Modal`]();
            this[`${this.notfullToLowercaseObj[this.selectedVar]}s`] = await this.allDataService[`${this.selectedVar.replace('Bulk','').toLowerCase()}Service`]?.getAllByPromise();
          }
        }
      } else if(this.notfullToLowercaseArray?.includes(this.selectedVar) && Array?.isArray(this.notfullToLowercaseArray)) {
        if(event == false) {
          this[`${this.notfullToLowercaseObj[this.selectedVar]}`] = null;
          this[`open${this.selectedVar}Modal`](false);
        } else {
          await this[`open${this.selectedVar}Modal`]();
          // this[`delete${this.selectedVar}`]();
        }
      }
      //edit bulk sale
      this.bulkSales?.forEach((el)=> {
        el["checked"] = false
      }) 
    } catch (error) {
      SentryUtilites.setLog("DeleteDataComponent:verifyTransactionPIN", error)
    }
  }

  setCheckboxVarValue(variable: string, event: any) {
    this[variable] = event?.detail?.checked;
  }

  async deleteConfirm(): Promise<boolean> {
    return new Promise(async (resolve,reject) => {
      try {
        const alert = await this.alertController.create({
          mode: 'ios',
          header: 'Please Confirm!',
          message: 'Are you sure want to delete?',
          buttons: [
            {
              text: 'No',
              role: 'cancel',
              handler: () => {
                resolve(false);
                return true;
              },
            },
            {
              text: 'Yes',
              role: 'confirm',
              handler: () => {
                resolve(true);
                return true;
              },
            },
          ],
        });
        await alert.present();
      } catch (error) {
        SentryUtilites.setLog("DeleteDataComponent:deleteConfirm", error)
        return resolve(false);
      }
    });
  }

  // Sale

  async openSaleModal(value: boolean = true) {
    try {
      if(value) {
        if ((await this.deleteConfirm()) === false) return;
      }
      this.isSaleModalOpen = value;
    } catch (error) {
      SentryUtilites.setLog("DeleteDataComponent:openSaleModal", error)
    }
  }

  async initDeleteSale(sale: Sale) {
    try {
      this.sale = sale;
      if(sale?.moneyIns?.length) {
        this.isDeleteSaleAttachedMoneyIns = true;
      }
      this.selectedVar = 'Sale';
      if(!this.fullToLowercaseArray.includes('Sale')) {
        this.fullToLowercaseArray.push('Sale');
      }
      this.openTransactionPINModal();
    } catch (error) {
      SentryUtilites.setLog("DeleteDataComponent:initDeleteSale", error)
    }
  }

  async deleteSale() {
    try {
      this.saleDeleteStarted = true;
      let deletedSale = await this.allDataService.saleService.delete(this.sale);
      if (deletedSale?.deletedStamp) {
        this.sale = deletedSale;
        this.allDataService.itemService.reloadList();
        this.allDataService.partyService.reloadList();
        this.allDataService.moneyInService.reloadList();
        this.allDataService.listForceReloadSubs.next('sale-list');
        this.allDataService.listForceReloadSubs.next('moneyin-list');
        this.saleDeleteCompleted = true;
        setTimeout(()=> {
          this.allDataService.listForceReloadSubs.next('item-list');
          this.allDataService.listForceReloadSubs.next('party-list');
          this.allDataService.listForceReloadSubs.next('moneyin-list');
        }, 2000);
        setTimeout(() => {
          this.openSaleModal(false);
          this.sale = null;
          this.isDeleteSaleAttachedMoneyIns = false;
          this.saleDeleteStarted = false;
          this.saleDeleteCompleted = false;
          if(this.navigateTo) {
            this.router.navigate([this.navigateTo]);
          }
          setTimeout(() => {
            this.onSaleDelete.emit(deletedSale);
          }, 1000);
        }, 500);
      }
    } catch (error) {
      SentryUtilites.setLog("DeleteDataComponent:deleteSale", error)
    }
  }

  //--------------------------------------------------------------------------------

    // Estimate

    async openEstimateModal(value: boolean = true) {
      try {
        if(value) {
          if ((await this.deleteConfirm()) === false) return;
        }
        this.isEstimateModalOpen = value;
      } catch (error) {
        SentryUtilites.setLog("DeleteDataComponent:openEstimateModal", error)
      }
    }
  
    async initDeleteEstimate(estimate: Estimate) {
      try {
        this.estimate = estimate;
        this.selectedVar = 'Estimate';
        if(!this.fullToLowercaseArray.includes('Estimate')) {
          this.fullToLowercaseArray.push('Estimate');
        }
        this.openTransactionPINModal();
      } catch (error) {
        SentryUtilites.setLog("DeleteDataComponent:initDeleteEstimate", error)
      }
    }
  
    async deleteEstimate() {
      try {
        this.estimateDeleteStarted = true;
        let deletedEstimate = await this.allDataService.estimateService.delete(this.estimate);
        if (deletedEstimate?.deletedStamp) {
          this.estimate = deletedEstimate;
          this.allDataService.partyService.reloadList();
          this.allDataService.itemService.reloadList();
          this.allDataService.listForceReloadSubs.next('estimate-list');
          this.estimateDeleteCompleted = true;
          setTimeout(() => {
            this.openEstimateModal(false);
            this.estimate = null;
            this.estimateDeleteStarted = false;
            this.estimateDeleteCompleted = false;
            if(this.navigateTo) {
              this.router.navigate([this.navigateTo]);
            }
            setTimeout(() => {
              this.onEstimateDelete.emit(deletedEstimate);
            }, 1000);
          }, 500);
        }
      } catch (error) {
        SentryUtilites.setLog("DeleteDataComponent:deleteEstimate", error)
      }
    }
  
    //--------------------------------------------------------------------------------

  // Expense

  async openExpenseModal(value: boolean = true) {
    try {
      if(value) {
        if ((await this.deleteConfirm()) === false) return;
      }
      this.isExpenseModalOpen = value;
    } catch (error) {
      SentryUtilites.setLog("DeleteDataComponent:openExpenseModal", error)
    }
  }

  async initDeleteExpense(expense: Expense) {
    try {
      this.expense = expense;
      this.selectedVar = 'Expense';
      if(!this.fullToLowercaseArray.includes('Expense')) {
        this.fullToLowercaseArray.push('Expense');
      }
      this.openTransactionPINModal();
    } catch (error) {
      SentryUtilites.setLog("DeleteDataComponent:initDeleteExpense", error)
    }
  }

  async deleteExpense() {
    try {
      this.expenseDeleteStarted = true;
      let deleteExpense = await this.allDataService.expenseService.delete(this.expense);
      if (deleteExpense?.deletedStamp) {
        this.expense = deleteExpense;
        this.expenseDeleteCompleted = true;
        this.allDataService.moneyOutService.reloadList();
        this.allDataService.partyService.reloadList();
        this.allDataService.listForceReloadSubs.next('expense-list');
        setTimeout(() => {
          this.openExpenseModal(false);
          this.expense = null;
          this.isDeleteExpenseAttachedMoneyOuts = false;
          this.expenseDeleteStarted = false;
          this.expenseDeleteCompleted = false;
          if(this.navigateTo) {
            this.router.navigate([this.navigateTo]);
          }
          setTimeout(() => {
            this.onExpenseDelete.emit(deleteExpense);
          }, 1000);
        }, 500);
      }
    } catch (error) {
      SentryUtilites.setLog("DeleteDataComponent:deleteExpense", error)
    }
  }

  //--------------------------------------------------------------------------------

  // Purchase

  async openPurchaseModal(value: boolean = true) {
    try {
      if(value) {
        if ((await this.deleteConfirm()) === false) return;
      }
      this.isPurchaseModalOpen = value;
    } catch (error) {
      SentryUtilites.setLog("DeleteDataComponent:openPurchaseModal", error)
    }
  }

  async initDeletePurchase(purchase: Purchase) {
    try {
      this.purchase = purchase;
      this.selectedVar = 'Purchase';
      if(!this.fullToLowercaseArray.includes('Purchase')) {
        this.fullToLowercaseArray.push('Purchase');
      }
      this.openTransactionPINModal();
    } catch (error) {
      SentryUtilites.setLog("DeleteDataComponent:initDeletePurchase", error)
    }
  }

  async deletePurchase() {
    try {
      this.purchaseDeleteStarted = true;
      let deletedPurchase = await this.allDataService.purchaseService.delete(this.purchase);
      if (deletedPurchase?.deletedStamp) {
        this.purchase = deletedPurchase;
        this.allDataService.partyService.reloadList();
        this.allDataService.itemService.reloadList();
        this.allDataService.moneyOutService.reloadList();
        this.allDataService.listForceReloadSubs.next('purchase-list');
        this.purchaseDeleteCompleted = true;
        setTimeout(() => {
          this.openPurchaseModal(false);
          this.purchase = null;
          this.isDeletePurchaseAttachedMoneyOuts = false;
          this.purchaseDeleteStarted = false;
          this.purchaseDeleteCompleted = false;
          if(this.navigateTo) {
            this.router.navigate([this.navigateTo]);
          }
          setTimeout(() => {
            this.onPurchaseDelete.emit(deletedPurchase);
          }, 1000);
        }, 500);
      }
    } catch (error) {
      SentryUtilites.setLog("DeleteDataComponent:deletePurchase", error)
    }
  }

  //--------------------------------------------------------------------------------

  // Money In

  async openMoneyInModal(value: boolean = true) {
    try {
      if(value) {
        if ((await this.deleteConfirm()) === false) return;
      }
      this.isMoneyInModalOpen = value;
      return;
    } catch (error) {
      SentryUtilites.setLog("DeleteDataComponent:openMoneyInModal", error)
    }
  }

  async initDeleteMoneyIn(moneyIn: MoneyIn) {
    try {
      this.moneyIn = moneyIn;
      this.selectedVar = 'MoneyIn';
      if(!this.notfullToLowercaseArray.includes('MoneyIn')) {
        this.notfullToLowercaseArray.push('MoneyIn');
      }
      this.openTransactionPINModal();
    } catch (error) {
      SentryUtilites.setLog("DeleteDataComponent:initDeleteMoneyIn", error)
    }
  }

  async deleteMoneyIn() {
    try {
      this.moneyInDeleteStarted = true;
      let deletedMoneyIn: MoneyIn = null;
      deletedMoneyIn = await this.allDataService.moneyInService.delete(this.moneyIn);
      if (deletedMoneyIn?.deletedStamp) {
        this.moneyIn = deletedMoneyIn;
        this.allDataService.partyService.reloadList();
        this.allDataService.saleService.reloadList();
        this.allDataService.listForceReloadSubs.next('moneyin-list');
        this.moneyInDeleteCompleted = true;
        setTimeout(() => {
          this.openMoneyInModal(false);
          this.moneyIn = null;
          this.moneyInDeleteStarted = false;
          this.moneyInDeleteCompleted = false;
          if(this.navigateTo) {
            this.router.navigate([this.navigateTo]);
          }
          setTimeout(() => {
            this.onMoneyInDelete.emit(deletedMoneyIn);
          }, 1000);
        }, 500);
      }
    } catch (error) {
      SentryUtilites.setLog("DeleteDataComponent:deleteMoneyIn", error)
    }
  }

  //--------------------------------------------------------------------------------

  // Money Out

  async openMoneyOutModal(value: boolean = true) {
    try {
      if(value) {
        if ((await this.deleteConfirm()) === false) return;
      }
      this.isMoneyOutModalOpen = value;
      return;
    } catch (error) {
      SentryUtilites.setLog("DeleteDataComponent:openMoneyOutModal", error)
    }
  }

  async initDeleteMoneyOut(moneyOut: MoneyOut) {
    try {
      this.moneyOut = moneyOut;
      this.selectedVar = 'MoneyOut';
      if(!this.notfullToLowercaseArray.includes('MoneyOut')) {
        this.notfullToLowercaseArray.push('MoneyOut');
      }
      this.openTransactionPINModal();
    } catch (error) {
      SentryUtilites.setLog("DeleteDataComponent:initDeleteMoneyOut", error)
    }
  }

  async deleteMoneyOut() {
    try {
      this.moneyOutDeleteStarted = true;
      let deletedMoneyOut = await this.allDataService.moneyOutService.delete(this.moneyOut);
      if (deletedMoneyOut?.deletedStamp) {
        this.moneyOut = deletedMoneyOut;
        this.allDataService.partyService.reloadList();
        this.allDataService.purchaseService.reloadList();
        this.allDataService.expenseService.reloadList();
        this.allDataService.listForceReloadSubs.next('moneyout-list');
        this.moneyOutDeleteCompleted = true;
        setTimeout(() => {
          this.openMoneyOutModal(false);
          this.moneyOut = null;
          this.moneyOutDeleteStarted = false;
          this.moneyOutDeleteCompleted = false;
          if(this.navigateTo) {
            this.router.navigate([this.navigateTo]);
          }
          setTimeout(() => {
            this.onMoneyOutDelete.emit(deletedMoneyOut);
          }, 1000);
        }, 500);
      }
    } catch (error) {
      SentryUtilites.setLog("DeleteDataComponent:deleteMoneyOut", error)
    }
  }

  //--------------------------------------------------------------------------------

  // Party

  async openPartyModal(value: boolean = true) {
    try {
      if(value) {
        if ((await this.deleteConfirm()) === false) return;
      }
      this.isPartyModalOpen = value;
    } catch (error) {
      SentryUtilites.setLog("DeleteDataComponent:openPartyModal", error)
    }
  }

  async initDeleteParty(party: Party) {
    try {
      this.party = party;
      this.selectedVar = 'Party';
      if(!this.fullToLowercaseArray.includes('Party')) {
        this.fullToLowercaseArray.push('Party');
      }
      this.openTransactionPINModal();
    } catch (error) {
      SentryUtilites.setLog("DeleteDataComponent:initDeleteParty", error)
    }
  }

  async deleteParty() {
    try {
      this.partyDeleteStarted = true;
      let deletedParty = await this.allDataService.partyService.delete(this.party);
      if (deletedParty?.deletedStamp) {
        this.party = deletedParty;
        this.allDataService.listForceReloadSubs.next('party-list');
  
        if (this.isDeletePartyAttachedSales) {
          let sales = (await this.allDataService?.saleService?.getAllByPromise())?.filter(sale => sale?.party?._localUUID === this.party?._localUUID);
          if (sales?.length) {
            for (let i = 0; i < sales.length; i++) {
              const sale = sales[i];
              if (sale?._localUUID) {
                await this.allDataService?.saleService?.delete(sale);
              }
            }
          }
        }else if (this.isDeletePartyAttachedMoneyIns) {
          let moneyIns = (await this.allDataService?.moneyInService?.getAllByPromise())?.filter(moneyIn => moneyIn?.party?._localUUID === this.party?._localUUID);
          if (moneyIns?.length) {
            for (let i = 0; i < moneyIns.length; i++) {
              let moneyIn = moneyIns[i];
              await this.allDataService.moneyInService.delete(moneyIn);
            }
          }
        }
  
        this.partyDeleteCompleted = true;
        this.allDataService.listForceReloadSubs.next('sale-list');
        this.allDataService.listForceReloadSubs.next('moneyin-list');
  
        setTimeout(() => {
          this.openPartyModal(false);
          this.party = null;
          this.isDeletePartyAttachedSales = false;
          this.isDeletePartyAttachedMoneyIns = false;
          this.partyDeleteStarted = false;
          this.partyDeleteCompleted = false;
          setTimeout(() => {
            this.onPartyDelete.emit(deletedParty);
          }, 1000);
        }, 500);
      }
    } catch (error) {
      SentryUtilites.setLog("DeleteDataComponent:deleteParty", error)
    }
  }

  //--------------------------------------------------------------------------------

  // Item

  async openItemModal(value: boolean = true) {
    try {
      if(value) {
        if ((await this.deleteConfirm()) === false) return;
      }
      this.isItemModalOpen = value;
    } catch (error) {
      SentryUtilites.setLog("DeleteDataComponent:openItemModal", error)
    }
  }

  async initDeleteItem(item: Item) {
    try {
      this.item = item;
      this.selectedVar = 'Item';
      if(!this.fullToLowercaseArray.includes('Item')) {
        this.fullToLowercaseArray.push('Item');
      }
      this.openTransactionPINModal();
    } catch (error) {
      SentryUtilites.setLog("DeleteDataComponent:initDeleteItem", error)
    }
  }

  async deleteItem() {
    try {
      this.itemDeleteStarted = true;
      let deletedItem = await this.allDataService.itemService.delete(this.item);
      if (deletedItem?.deletedStamp) {
        this.item = deletedItem;
        this.allDataService.listForceReloadSubs.next('item-list');
        this.itemDeleteCompleted = true;
        setTimeout(() => {
          this.openItemModal(false);
          this.item = null;
          this.itemDeleteStarted = false;
          this.itemDeleteCompleted = false;
          setTimeout(() => {
            this.onItemDelete.emit(deletedItem);
          }, 1000);
        }, 500);
      }
    } catch (error) {
      SentryUtilites.setLog("DeleteDataComponent:deleteItem", error)
    }
  }

  //--------------------------------------------------------------------------------

  // Bulk Sale

  async openBulkSaleModal(value: boolean = true) {
    try {
      if(value) {
        if ((await this.deleteConfirm()) === false) return;
      }
      this.isBulkSaleModalOpen = value;
      this.selectedBulkSales = [];
    } catch (error) {
      SentryUtilites.setLog("DeleteDataComponent:openBulkSaleModal", error)
    }
  }

  async initDeleteBulkSale() {
    try {
      this.selectedVar = 'BulkSale';
      if(!this.bulkSelectArray.includes('BulkSale')) {
        this.bulkSelectArray.push('BulkSale');
      }
      this.openTransactionPINModal();
    } catch (error) {
      SentryUtilites.setLog("DeleteDataComponent:initDeleteBulkSale", error)
    }
  }

  /**
   * 
   * @param sale : Provide sale object
   * @param event : ionic CustomEventInit
   * @description : this method add checked sale in selectedBulkSales array 
   *                if unchecked it will that sale from selectedBulkSales array
   */
  addToSelectedBulkSaleArr(sale: Sale,event: CustomEventInit) {
    try {
      const index = this.selectedBulkSales?.findIndex(x => x?._localUUID === sale?._localUUID);
      this.bulkSales?.forEach(x => {
        if(x?._localUUID === sale?._localUUID){
          x["checked"] = event?.detail?.checked;
        }
      });
      if(event?.detail?.checked) {
        if(index == -1){
          this.selectedBulkSales.push(sale);    
        }
      }else {
        if(index != -1) {
          this.selectedBulkSales.splice(index,1);
        }
      }
    } catch (error) {
      SentryUtilites.setLog("DeleteDataComponent:addToSelectedBulkSaleArr", error)
    }
  }
  // -----------------------------

  async deleteBulkSale() {
    try {
      this.bulkSaleDeleteStarted = true;
      if(this.selectedBulkSales?.length) {
        for (let i = 0; i < this.selectedBulkSales.length; i++) {
          let sale = this.selectedBulkSales[i];
          await this.allDataService.saleService.delete(sale);
        }
      }
  
      this.allDataService.itemService.reloadList();
      this.allDataService.partyService.reloadList();
      this.allDataService.moneyInService.reloadList();
      this.allDataService.listForceReloadSubs.next('sale-list');
      this.allDataService.listForceReloadSubs.next('moneyin-list');
      this.saleDeleteCompleted = true;
      setTimeout(()=> {
        this.allDataService.listForceReloadSubs.next('item-list');
        this.allDataService.listForceReloadSubs.next('party-list');
        this.allDataService.listForceReloadSubs.next('moneyin-list');
      }, 2000);
  
      this.bulkSaleDeleteCompleted = true;
  
      setTimeout(() => {
        this.openBulkSaleModal(false);
        this.bulkSales = [];
        this.selectedBulkSales = [];
        this.isDeleteBulkSaleAttachedMoneyIns = false;
        this.bulkSaleDeleteStarted = false;
        this.bulkSaleDeleteCompleted = false;
        setTimeout(() => {
          this.onBulkSaleDelete.emit(true);
        }, 1000);
      }, 500);
    } catch (error) {
      SentryUtilites.setLog("DeleteDataComponent:deleteBulkSale", error)
    }
  }

  /**
   * 
   * @param event : ionic CustomEventInit
   * @description : this method push all sales in selectedBulkSales if select all checkbox is checked
   */
  selectAllSaleCheckboxes(event: CustomEventInit){
    try {
      if(this.checkAllSaleCheckboxes) {
        if(event?.detail?.checked) {
          this.bulkSales?.forEach((el)=> {el["checked"] = true});
          this.selectedBulkSales = [...this.bulkSales];
        } else {
          this.bulkSales?.forEach((el)=> {el["checked"] = false});
          this.selectedBulkSales = [];
        }
      }
      this.checkAllSaleCheckboxes = false;
    } catch (error) {
      SentryUtilites.setLog("DeleteDataComponent:selectAllSaleCheckboxes", error)
    }
  }
  // -----------------------------

  /**
   * @description: this method check user is click on checkbox or not.
   */
  selectAllSaleCheckboxClick() {
    this.checkAllSaleCheckboxes = true;
  }
  // -----------------------------

  //--------------------------------------------------------------------------------

  // Bulk Expense

  async openBulkExpenseModal(value: boolean = true) {
    try {
      if(value) {
        if ((await this.deleteConfirm()) === false) return;
      }
      this.isBulkExpenseModalOpen = value
      this.selectedBulkExpenses = [];
    } catch (error) {
      SentryUtilites.setLog("DeleteDataComponent:openBulkExpenseModal", error)
    }
  }

  async initDeleteBulkExpense() {
    try {
      this.selectedVar = 'BulkExpense';
      if(!this.bulkSelectArray.includes('BulkExpense')) {
        this.bulkSelectArray.push('BulkExpense');
      }
      this.openTransactionPINModal();
    } catch (error) {
      SentryUtilites.setLog("DeleteDataComponent:initDeleteBulkExpense", error)
    }
  }

  /**
   * 
   * @param expense : Provide Expense object
   * @param event : ionic CustomEventInit
   * @description : this method add checked Expense in selectedBulkExpenses array 
   *                if unchecked it will that Expense from selectedBulkExpenses array
   */
  addToSelectedBulkExpenseArr(expense: Expense,event: CustomEventInit) {
    try {
      const index = this.selectedBulkExpenses?.findIndex(x => x?._localUUID === expense?._localUUID);
      this.bulkExpenses?.forEach(x => {
        if(x?._localUUID === expense?._localUUID){
          x["checked"] = event?.detail?.checked;
        }
      });
      if(event?.detail?.checked) {
        if(index == -1){
          this.selectedBulkExpenses.push(expense);    
        }
      }else {
        if(index != -1) {
          this.selectedBulkExpenses.splice(index,1);
        }
      }
    } catch (error) {
      SentryUtilites.setLog("DeleteDataComponent:addToSelectedBulkExpenseArr", error)
    }
  }
  // --------------------------------------------

  async deleteBulkExpense() {
    try {
      this.bulkExpenseDeleteStarted = true;
      if(this.selectedBulkExpenses?.length) {
        for (let i = 0; i < this.selectedBulkExpenses.length; i++) {
          let expense = this.selectedBulkExpenses[i];
          await this.allDataService.expenseService.delete(expense);
        }
      }
  
      this.bulkExpenseDeleteCompleted = true;
      this.allDataService.moneyOutService.reloadList();
      this.allDataService.partyService.reloadList();
      this.allDataService.listForceReloadSubs.next('expense-list');
  
      setTimeout(() => {
        this.openBulkExpenseModal(false);
        this.bulkExpenses = [];
        this.selectedBulkExpenses = [];
        this.isDeleteBulkExpenseAttachedMoneyOuts = false;
        this.bulkExpenseDeleteStarted = false;
        this.bulkExpenseDeleteCompleted = false;
        setTimeout(() => {
          this.onBulkExpenseDelete.emit(true);
        }, 1000);
      }, 500);
    } catch (error) {
      SentryUtilites.setLog("DeleteDataComponent:deleteBulkExpense", error)
    }
  }

  /**
   * 
   * @param event : ionic CustomEventInit
   * @description : this method push all Expenses in selectedBulkExpenses if select all checkbox is checked
   */
  selectAllExpenseCheckboxes(event: CustomEventInit){
    try {
      if(this.checkAllExpenseCheckboxes) {
        if(event?.detail?.checked) {
          this.bulkExpenses?.forEach((el)=> {el["checked"] = true});
          this.selectedBulkExpenses = [...this.bulkExpenses];
        } else {
          this.bulkExpenses?.forEach((el)=> {el["checked"] = false});
          this.selectedBulkExpenses = [];
        }
      }
      this.checkAllExpenseCheckboxes = false;
    } catch (error) {
      SentryUtilites.setLog("DeleteDataComponent:selectAllExpenseCheckboxes", error)
    }
  }
  // --------------------------------------

  /**
   * @description: this method check user is click on checkbox or not.
   */
  selectAllExpenseCheckboxClick() {
    this.checkAllExpenseCheckboxes = true;
  }

  //--------------------------------------------------------------------------------

  // Bulk Purchase

  async openBulkPurchaseModal(value: boolean = true) {
    try {
      if(value) {
        if ((await this.deleteConfirm()) === false) return;
      }
      this.isBulkPurchaseModalOpen = value
      this.selectedBulkPurchases = [];
    } catch (error) {
      SentryUtilites.setLog("DeleteDataComponent:openBulkPurchaseModal", error)
    }
  }

  async initDeleteBulkPurchase() {
    try {
      this.selectedVar = 'BulkPurchase';
      if(!this.bulkSelectArray.includes('BulkPurchase')) {
        this.bulkSelectArray.push('BulkPurchase');
      }
      this.openTransactionPINModal();
    } catch (error) {
      SentryUtilites.setLog("DeleteDataComponent:initDeleteBulkPurchase", error)
    }
  }

  /**
   * 
   * @param purchase : Provide Purchase object
   * @param event : ionic CustomEventInit
   * @description : this method add checked Purchase in selectedBulkPurchases array 
   *                if unchecked it will that Purchase from selectedBulkPurchases array
   */
  addToSelectedBulkPurchaseArr(purchase: Purchase,event: CustomEventInit) {
    try {
      const index = this.selectedBulkPurchases?.findIndex(x => x?._localUUID === purchase?._localUUID);
      this.bulkPurchases?.forEach(x => {
        if(x?._localUUID === purchase?._localUUID){
          x["checked"] = event?.detail?.checked;
        }
      });
      if(event?.detail?.checked) {
        if(index == -1){
          this.selectedBulkPurchases.push(purchase);    
        }
      }else {
        if(index != -1) {
          this.selectedBulkPurchases.splice(index,1);
        }
      }
    } catch (error) {
      SentryUtilites.setLog("DeleteDataComponent:addToSelectedBulkPurchaseArr", error)
    }
  }
  // --------------------------------------------

  async deleteBulkPurchase() {
    try {
      this.bulkPurchaseDeleteStarted = true;
      if(this.selectedBulkPurchases?.length) {
        for (let i = 0; i < this.selectedBulkPurchases.length; i++) {
          let purchase = this.selectedBulkPurchases[i];
          await this.allDataService.purchaseService.delete(purchase);
        }
      }
  
      this.allDataService.moneyOutService.reloadList();
      this.allDataService.partyService.reloadList();
      this.allDataService.itemService.reloadList();
      this.allDataService.listForceReloadSubs.next('purchase-list');
  
      this.bulkPurchaseDeleteCompleted = true;
  
      setTimeout(() => {
        this.openBulkPurchaseModal(false);
        this.bulkPurchases = [];
        this.selectedBulkPurchases = [];
        this.isDeleteBulkPurchaseAttachedMoneyOuts = false;
        this.bulkPurchaseDeleteStarted = false;
        this.bulkPurchaseDeleteCompleted = false;
        setTimeout(() => {
          this.onBulkPurchaseDelete.emit(true);
        }, 1000);
      }, 500);
    } catch (error) {
      SentryUtilites.setLog("DeleteDataComponent:deleteBulkPurchase", error)
    }
  }
  /**
   * 
   * @param event : ionic CustomEventInit
   * @description : this method push all Purchases in selectedBulkPurchases if select all checkbox is checked
   */
  selectAllPurchaseCheckboxes(event: CustomEventInit){
    try {
      if(this.checkAllPurchaseCheckboxes) {
        if(event?.detail?.checked) {
          this.bulkPurchases?.forEach((el)=> {el["checked"] = true});
          this.selectedBulkPurchases = [...this.bulkPurchases];
        } else {
          this.bulkPurchases?.forEach((el)=> {el["checked"] = false});
          this.selectedBulkPurchases = [];
        }
      }
      this.checkAllPurchaseCheckboxes = false;
    } catch (error) {
      SentryUtilites.setLog("DeleteDataComponent:selectAllPurchaseCheckboxes", error)
    }
  }
  // -----------------------------------------

  /**
   * @description: this method check user is click on checkbox or not.
   */
  selectAllPurchaseCheckboxClick() {
    this.checkAllPurchaseCheckboxes = true;
  }

  //--------------------------------------------------------------------------------

  // Bulk Money In

  async openBulkMoneyInModal(value: boolean = true) {
    try {
      if(value) {
        if ((await this.deleteConfirm()) === false) return;
      }
      this.isBulkMoneyInModalOpen = value;
      return;
    } catch (error) {
      SentryUtilites.setLog("DeleteDataComponent:openBulkMoneyInModal", error)
    }
  }

  async initDeleteBulkMoneyIn() {
    try {
      this.selectedVar = 'BulkMoneyIn';
      if(!this.bulkSelectArray.includes('BulkMoneyIn')) {
        this.bulkSelectArray.push('BulkMoneyIn');
      }
      if(!this.notfullToLowercaseArray.includes('BulkMoneyIn')) {
        this.notfullToLowercaseArray.push('BulkMoneyIn');
      }
      this.openTransactionPINModal();
    } catch (error) {
      SentryUtilites.setLog("DeleteDataComponent:initDeleteBulkMoneyIn", error)
    }
  }

  addToSelectedBulkMoneyInArr(moneyIn: MoneyIn,event: any) {
    try {
      if(event?.detail?.checked) {
        this.selectedBulkMoneyIns.push(moneyIn);
      }else {
        const index = this.selectedBulkMoneyIns?.findIndex(x => x?._localUUID === moneyIn?._localUUID);
        if(index != -1) {
          this.selectedBulkMoneyIns.splice(index,1);
        }
      }
    } catch (error) {
      SentryUtilites.setLog("DeleteDataComponent:addToSelectedBulkMoneyInArr", error)
    }
  }

  async deleteBulkMoneyIn() {
    try {
      this.bulkMoneyInDeleteStarted = true;
      if(this.selectedBulkMoneyIns?.length) {
        for (let i = 0; i < this.selectedBulkMoneyIns.length; i++) {
          let moneyIn = this.selectedBulkMoneyIns[i];
          await this.allDataService.moneyInService.delete(moneyIn);
        }
      }
  
      this.allDataService.partyService.reloadList();
      this.allDataService.saleService.reloadList();
      this.allDataService.listForceReloadSubs.next('moneyin-list');
  
      this.bulkMoneyInDeleteCompleted = true;
  
      setTimeout(() => {
        this.openBulkMoneyInModal(false);
        this.bulkMoneyIns = [];
        this.selectedBulkMoneyIns = [];
        this.bulkMoneyInDeleteStarted = false;
        this.bulkMoneyInDeleteCompleted = false;
        setTimeout(() => {
          this.onBulkMoneyInDelete.emit(true);
        }, 1000);
      }, 500);
    } catch (error) {
      SentryUtilites.setLog("DeleteDataComponent:deleteBulkMoneyIn", error)
    }
  }

  //--------------------------------------------------------------------------------

  // Bulk Money Out

  async openBulkMoneyOutModal(value: boolean = true) {
    try {
      if(value) {
        if ((await this.deleteConfirm()) === false) return;
      }
      this.isBulkMoneyOutModalOpen = value;
      return;
    } catch (error) {
      SentryUtilites.setLog("DeleteDataComponent:openBulkMoneyOutModal", error)
    }
  }

  async initDeleteBulkMoneyOut() {
    try {
      this.selectedVar = 'BulkMoneyOut';
      if(!this.bulkSelectArray.includes('BulkMoneyOut')) {
        this.bulkSelectArray.push('BulkMoneyOut');
      }
      if(!this.notfullToLowercaseArray.includes('BulkMoneyOut')) {
        this.notfullToLowercaseArray.push('BulkMoneyOut');
      }
      this.openTransactionPINModal();
    } catch (error) {
      SentryUtilites.setLog("DeleteDataComponent:initDeleteBulkMoneyOut", error)
    }
  }

  addToSelectedBulkMoneyOutArr(moneyOut: MoneyOut,event: any) {
    try {
      if(event?.detail?.checked) {
        this.selectedBulkMoneyOuts.push(moneyOut);
      }else {
        const index = this.selectedBulkMoneyOuts?.findIndex(x => x?._localUUID === moneyOut?._localUUID);
        if(index != -1) {
          this.selectedBulkMoneyOuts.splice(index,1);
        }
      }
    } catch (error) {
      SentryUtilites.setLog("DeleteDataComponent:addToSelectedBulkMoneyOutArr", error)
    }
  }

  async deleteBulkMoneyOut() {
    try {
      this.bulkMoneyOutDeleteStarted = true;
      if(this.selectedBulkMoneyOuts?.length) {
        for (let i = 0; i < this.selectedBulkMoneyOuts?.length; i++) {
          let moneyOut = this.selectedBulkMoneyOuts[i];
          await this.allDataService.moneyOutService.delete(moneyOut);
        }
      }
  
      this.allDataService.purchaseService.reloadList();
      this.allDataService.expenseService.reloadList();
      this.allDataService.partyService.reloadList();
      this.allDataService.listForceReloadSubs.next('moneyout-list');
  
      this.bulkMoneyOutDeleteCompleted = true;
  
      setTimeout(() => {
        this.openBulkMoneyOutModal(false);
        this.bulkMoneyOuts = [];
        this.selectedBulkMoneyOuts = [];
        this.bulkMoneyOutDeleteStarted = false;
        this.bulkMoneyOutDeleteCompleted = false;
        setTimeout(() => {
          this.onBulkMoneyOutDelete.emit(true);
        }, 1000);
      }, 500);
    } catch (error) {
      SentryUtilites.setLog("DeleteDataComponent:deleteBulkMoneyOut", error)
    }
  }

  //--------------------------------------------------------------------------------

}
