import { BehaviorSubject } from 'rxjs';
import { Utility } from '../utils/utility';
import { ExpressServerService } from './api/express-server.service';
import { IDataService } from '../../interface/IDataService.interface';
import { PartyDao } from './dao/party.dao';
import { MoneyOutDao } from './dao/money-out.dao';
import { MoneyOut } from '../models/MoneyOut.model';
import { AuthService } from './auth/auth.service';
import { Expense } from '../models/Expense.model';
import { ExpenseDao } from './dao/expense.dao';
import { MonthWisePartyCreditDao } from './dao/month-wise-party-credit.dao';
import { SentryUtilites } from '../utils/sentryUtilites';

export class ExpenseService implements IDataService<Expense> {
  private static _instance: ExpenseService;

  public static getInstance(
    expenseDao: ExpenseDao,
    partyDao: PartyDao,
    moneyOutDao: MoneyOutDao,
    expressServerService: ExpressServerService,
    authService: AuthService,
    monthWisePartyCreditDao: MonthWisePartyCreditDao,
  ) {
    if (!this._instance) {
      this._instance = new ExpenseService(
        expenseDao,
        partyDao,
        moneyOutDao,
        expressServerService,
        authService,
        monthWisePartyCreditDao,
      );
      this._instance.initService();
    }
    this._instance.reloadList();
    return this._instance;
  }

  constructor(
    dao: ExpenseDao,
    partyDao: PartyDao,
    moneyOutDao: MoneyOutDao,
    expressServerService: ExpressServerService,
    authService: AuthService,
    monthWisePartyCreditDao: MonthWisePartyCreditDao,
  ) {
    this.dao = dao;
    this.expressServerService = expressServerService;
    this.partyDao = partyDao;
    this.moneyOutDao = moneyOutDao;
    this.authService = authService;
    this.monthWisePartyCreditDao = monthWisePartyCreditDao;
  }
  dao: ExpenseDao;
  partyDao: PartyDao;
  moneyOutDao: MoneyOutDao;
  expressServerService: ExpressServerService;
  authService: AuthService;
  monthWisePartyCreditDao: MonthWisePartyCreditDao;

  LIST_REFRESH_RATE = 1000;
  selectedProfileId: string = null;
  selectedProfileUserId: string = null;
  updateSubs = new BehaviorSubject<Expense>(null);

  lastReloadStamp: number = 0;
  isReloadPostpond = false;

  initService() {
    this.selectedProfileId = Utility.getFromLocalStorage('selectedProfile');
    this.selectedProfileUserId = Utility.getFromLocalStorage(
      'selectedProfileUserId'
    );
    this.reloadList();
  }

  async reloadList() {
    try {
      if (this.isReloadPostpond) {
        return;
      }
      const currentStamp = +new Date();
      if (this.lastReloadStamp < currentStamp - this.LIST_REFRESH_RATE) {
        this.lastReloadStamp = currentStamp;
        this.trySyncUnsynced();
      } else {
        this.isReloadPostpond = true;
        setTimeout(() => {
          this.isReloadPostpond = false;
          this.reloadList();
        }, this.LIST_REFRESH_RATE + 100);
      }
    } catch (error) {
      SentryUtilites.setLog("ExpenseService:reloadList", error)
      return null;
    }
  }

  getAll() {
    return this.dao.getAll();
  }

  getAllByPromise() {
    return this.dao.getAllByProfile(this.selectedProfileId);
  }

  getAllByPromiseByProfile(profileId: string) {
    return this.dao.getAllByProfile(profileId);
  }

  getAllWithDeletedByProfile() {
    return this.dao.getAllWithDeletedByProfile(this.selectedProfileId);
  }

  getByBillDateRange(
    startTime: number,
    endTime: number,
    profileId?: string
  ): Promise<Expense[]> {
    return new Promise(async (resolve, reject) => {
      try {
        let allDocs = await this.dao.getAllByProfile(
          profileId || this.selectedProfileId
        );
        if (allDocs != null) {
          let filteredDocs = allDocs.filter(
            (doc) =>
              doc?.billDateStamp >= startTime && doc?.billDateStamp < endTime
          );
          return resolve(filteredDocs);
        } else {
          return resolve(null);
        }
      } catch (error) {
        SentryUtilites.setLog("ExpenseService:getByBillDateRange", error)
        return resolve(null);
      }
    });
  }

  getByCreatedDateRange(startTime: number, endTime: number): Promise<Expense[]> {
    return new Promise(async (resolve, reject) => {
      try {
        let allDocs = await this.dao.getAllByProfile(this.selectedProfileId);
        if (allDocs != null) {
          let filteredDocs = allDocs.filter(doc => doc?.createdStamp >= startTime && doc?.createdStamp < endTime);
          return resolve(filteredDocs);
        } else {
          return resolve(null);
        }
      } catch (error) {
        SentryUtilites.setLog("ExpenseService:getByCreatedDateRange", error)
        return resolve(null);
      }
    });
  }

  getById(id: number): Promise<Expense> {
    return this.dao.getById(id);
  }

  getByUUID(uuid: string): Promise<Expense> {
    return this.dao.getByUUID(uuid);
  }

  save(expense: Expense): Promise<Expense> {
    return new Promise(async (resolve, reject) => {
      try {
        if(Utility.isTruthy(expense)) {
          let currentProfile = this.selectedProfileId;
          expense.userId = this.selectedProfileUserId;
          if(!expense.profileId) {
            expense.profileId = currentProfile;
          }
          expense.createdBy = expense.lastModifiedBy =
            this.authService.getLoginPhone();
            expense.createdByName = expense.lastModifiedByName =
            Utility.getCreatedByName();
  
          if (!expense?._localUUID) {
            expense._localUUID = Utility.getUUID();
          }
  
          if (!expense?.amountPaid) {
            expense.amountPaid = 0.0;
          }
  
  
          if (expense?.moneyOuts?.length === 1) {
            expense.moneyOuts[0].userId = this.selectedProfileUserId;
            expense.moneyOuts[0].profileId = this.selectedProfileId;
            expense.moneyOuts[0].createdBy = expense.moneyOuts[0].lastModifiedBy =
              this.authService.getLoginPhone();
              expense.moneyOuts[0].createdByName = expense.moneyOuts[0].lastModifiedByName =
              Utility.getCreatedByName();
            expense.moneyOuts[0].linkedExpenseUUID = expense?._localUUID;
            expense.moneyOuts[0]._localUUID = Utility.getUUID();
            expense.amountPaid = expense?.moneyOuts[0]?.totalAmount || 0.0;
          }
  
          let savedExpense = await this.dao.save(expense);
  
          //MoneyOut
          if (savedExpense?.moneyOuts?.length === 1) {
            let savedMoneyOut = await this.moneyOutDao.save(
              savedExpense?.moneyOuts[0]
            );
            let totalAmount = savedMoneyOut?.totalAmount || 0.0;
            if (totalAmount > 0) {
              await this.partyDao.updateCredit(
                savedMoneyOut?.party,
                totalAmount
              );
              await this.monthWisePartyCreditDao?.modifyCredit(
                savedMoneyOut?.party?._localUUID,
                savedMoneyOut?.billDateStamp,
                totalAmount
              )
            }
          }
          //----------------------------------------
  
          //Party
          savedExpense.party.lastModifiedBy = savedExpense?.lastModifiedBy;
          savedExpense.party.lastModifiedByName = savedExpense?.lastModifiedByName;
          await this.partyDao.updateCredit(
            savedExpense?.party,
            -savedExpense?.totalAmount
          );
          await this.monthWisePartyCreditDao?.modifyCredit(
            savedExpense?.party?._localUUID,
            savedExpense?.billDateStamp,
            -savedExpense?.totalAmount
          )
          //----------------------------------------
  
          this.reloadList();
          return resolve(savedExpense);
        } else {
          return resolve(null);
        }
      } catch (err) {
        SentryUtilites.setLog("ExpenseService:save", err)
        return resolve(null);
      }
    });
  }

  update(expense: Expense): Promise<Expense> {
    return new Promise(async (resolve, reject) => {
      try {
        if (expense?._localUUID) {
          expense.lastModifiedBy = this.authService.getLoginPhone();
          expense.lastModifiedByName = Utility.getCreatedByName();
          let oldExpense = await this.getByUUID(expense?._localUUID);

          if (
            oldExpense?.party?._localUUID != null &&
            oldExpense?.party?._localUUID != '' &&
            expense?.party?._localUUID != '' &&
            oldExpense?.party?._localUUID == expense?.party?._localUUID
          ) {
            if (expense?.moneyOuts?.length) {
              let totalAmountReceived = 0.0;
              expense?.moneyOuts?.forEach(
                (x) => (totalAmountReceived += Number(x?.totalAmount))
              );
              expense.amountPaid = totalAmountReceived;
            } else {
              expense.amountPaid = 0.0;
            }

            let addMoneyOuts: MoneyOut[] = [];
            let updateMoneyOuts: MoneyOut[] = [];
            let deleteMoneyOuts: MoneyOut[] = [];

            expense?.moneyOuts?.forEach((newMoneyOut) => {
              let isMatched = false;
              oldExpense?.moneyOuts?.forEach((oldMoneyOut) => {
                if (oldMoneyOut?._localUUID === newMoneyOut?._localUUID) {
                  isMatched = true;
                  return;
                }
              });
              if (isMatched) {
                // ConstraintError: Unable to add key to index '_localUUID': at least one key does not satisfy the uniqueness requirements.
                // getting this error if _localId is not match with your current indexedDb _localId at time of update and delete.
                delete newMoneyOut?._localId;
                updateMoneyOuts.push(newMoneyOut);
              } else {
                newMoneyOut._localUUID = Utility.getUUID();
                newMoneyOut.profileId = expense?.profileId;
                newMoneyOut.userId = expense?.userId;
                newMoneyOut.createdBy = newMoneyOut.lastModifiedBy =
                  this.authService.getLoginPhone();
                newMoneyOut.createdByName = newMoneyOut.lastModifiedByName =
                  Utility.getCreatedByName();
                newMoneyOut.linkedExpenseUUID = expense?._localUUID;
                newMoneyOut.party = expense?.party;
                addMoneyOuts.push(newMoneyOut);
              }
            });

            oldExpense?.moneyOuts?.forEach((oldMoneyOut) => {
              if(oldMoneyOut?._localUUID) {
                let shouldDelete = true;
                expense?.moneyOuts?.forEach((newMoneyOut) => {
                  if (oldMoneyOut?._localUUID === newMoneyOut?._localUUID) {
                    shouldDelete = false;
                  }
                });
                if (shouldDelete) {
                  deleteMoneyOuts.push(oldMoneyOut);
                }
              }
            });

            expense.moneyOuts = [...addMoneyOuts, ...updateMoneyOuts];

            let updatedExpense = await this.dao.update(expense);

            //MoneyOut
            if (addMoneyOuts?.length) {
              for (let i = 0; i < addMoneyOuts?.length; i++) {
                let moneyOut = addMoneyOuts[i];
                let savedMoneyOut = await this.moneyOutDao.save(moneyOut);
                if(savedMoneyOut?._localUUID) {
                  savedMoneyOut.party.lastModifiedBy = updatedExpense?.lastModifiedBy;
                  savedMoneyOut.party.lastModifiedByName = updatedExpense?.lastModifiedByName;
                  await this.partyDao.updateCredit(
                    savedMoneyOut?.party,
                    savedMoneyOut?.totalAmount
                  );
                  await this.monthWisePartyCreditDao?.modifyCredit(
                    savedMoneyOut?.party?._localUUID,
                    savedMoneyOut?.billDateStamp,
                    savedMoneyOut?.totalAmount
                  )
                }
              }
            }

            if (updateMoneyOuts?.length) {
              for (let i = 0; i < updateMoneyOuts?.length; i++) {
                let moneyOut = updateMoneyOuts[i];
                let oldMoneyOut = await this.moneyOutDao.getByUUID(
                  moneyOut?._localUUID
                );
                if(oldMoneyOut?._localUUID) {
                  moneyOut._localId = oldMoneyOut?._localId;
                  moneyOut.createdStamp = oldMoneyOut?.createdStamp;
                  let savedMoneyOut = await this.moneyOutDao.update(moneyOut);
                  if(savedMoneyOut?._localUUID) {
                    let deltaAmount = savedMoneyOut?.totalAmount - oldMoneyOut?.totalAmount;
                    savedMoneyOut.party.lastModifiedBy = updatedExpense?.lastModifiedBy;
                    savedMoneyOut.party.lastModifiedByName = updatedExpense.lastModifiedByName;
                    await this.partyDao.updateCredit(
                      savedMoneyOut?.party,
                      deltaAmount
                    );
                    await this.monthWisePartyCreditDao?.modifyCredit(
                      savedMoneyOut?.party?._localUUID,
                      savedMoneyOut?.billDateStamp,
                      deltaAmount
                    )
                  }
                }
              }
            }

            if (deleteMoneyOuts?.length) {
              for (let i = 0; i < deleteMoneyOuts.length; i++) {
                let moneyOut = deleteMoneyOuts[i];
                let oldMoneyOut = await this.moneyOutDao.getByUUID(
                  moneyOut._localUUID
                );
                if(oldMoneyOut?._localUUID) {
                  moneyOut._localId = oldMoneyOut?._localId;
                  let savedMoneyOut = await this.moneyOutDao.delete(moneyOut);
                  if(savedMoneyOut?._localUUID) {
                    savedMoneyOut.party.lastModifiedBy = updatedExpense?.lastModifiedBy;
                    savedMoneyOut.party.lastModifiedByName = updatedExpense?.lastModifiedByName;
                    await this.partyDao.updateCredit(
                      savedMoneyOut?.party,
                      -savedMoneyOut?.totalAmount
                    );
                    await this.monthWisePartyCreditDao?.modifyCredit(
                      savedMoneyOut?.party?._localUUID,
                      savedMoneyOut?.billDateStamp,
                      -savedMoneyOut?.totalAmount
                    )
                  }
                }
              }
            }

            //------------------------------------------------------------------

            //Party
            //------------------------------------------------------------------
            let deltaPartyCredit: number = 0;
            deltaPartyCredit = (updatedExpense?.totalAmount || 0.0) - (oldExpense?.totalAmount || 0.0);
            updatedExpense.party.lastModifiedBy = updatedExpense?.lastModifiedBy;
            updatedExpense.party.lastModifiedByName = updatedExpense?.lastModifiedByName;
            await this.partyDao.updateCredit(
              updatedExpense?.party,
              -deltaPartyCredit
            );
            await this.monthWisePartyCreditDao?.modifyCredit(
              updatedExpense?.party?._localUUID,
              updatedExpense?.billDateStamp,
              -deltaPartyCredit
            )

            this.reloadList();
            this.updateSubs.next(updatedExpense);
            return resolve(updatedExpense);
          }
        }
        return resolve(null);
      } catch (err) {
        SentryUtilites.setLog("ExpenseService:update", err)
        return resolve(null);
      }
    });
  }

  delete(expense: Expense): Promise<Expense> {
    return new Promise(async (resolve, reject) => {
      try {
        if(expense?._localUUID) {
          expense.lastModifiedBy = this.authService.getLoginPhone();
          expense.lastModifiedByName = Utility.getCreatedByName();
          let expenseTobeDeleted = await this.getByUUID(expense?._localUUID)
          let deletedExpense = await this.dao.delete(expenseTobeDeleted);
  
          //MoneyOut
  
          if (expenseTobeDeleted?.moneyOuts?.length) {
            for (let i = 0; i < expenseTobeDeleted?.moneyOuts?.length; i++) {
              const moneyOut = expenseTobeDeleted?.moneyOuts[i];
              let fetchedMoneyOut = await this.moneyOutDao.getByUUID(moneyOut?._localUUID);
              if(fetchedMoneyOut?._localUUID) {
                fetchedMoneyOut.lastModifiedBy = this.authService.getLoginPhone();
                fetchedMoneyOut.lastModifiedByName = Utility.getCreatedByName();
    
                let deletedMoneyOut = await this.moneyOutDao?.delete(fetchedMoneyOut);
                if(deletedMoneyOut?._localUUID) {
                  let totalAmount = deletedMoneyOut?.totalAmount || 0.0;
                  deletedExpense.party.lastModifiedBy = deletedExpense?.lastModifiedBy;
                  deletedExpense.party.lastModifiedByName = deletedExpense?.lastModifiedByName;
                  if (totalAmount > 0) {
                    await this.partyDao.updateCredit(
                      deletedExpense?.party,
                      -totalAmount
                    )
                    await this.monthWisePartyCreditDao?.modifyCredit(
                      deletedExpense?.party?._localUUID,
                      deletedExpense?.billDateStamp,
                      -totalAmount
                    )
                  }
                }
              }
            }
          }
  
          //----------------------------------------
  
          //Party
          deletedExpense.party.lastModifiedBy = deletedExpense?.lastModifiedBy;
          deletedExpense.party.lastModifiedByName = deletedExpense?.lastModifiedByName;
          await this.partyDao.updateCredit(
            deletedExpense?.party,
            (deletedExpense?.totalAmount || 0.0)
          )
          await this.monthWisePartyCreditDao?.modifyCredit(
            deletedExpense?.party?._localUUID,
            deletedExpense?.billDateStamp,
            (deletedExpense?.totalAmount || 0)
          )
            
          //----------------------------------------
          
          this.reloadList();
          this.updateSubs.next(deletedExpense);
  
          return resolve(deletedExpense);
        } 
        return resolve(null);

      } catch (err) {
        SentryUtilites.setLog("ExpenseService:delete", err)
        return resolve(null);
      }

    });
  }

  isSyncLock = false;
  isSyncPostPond = false;
  async trySyncUnsynced(postpond?: boolean) {
    try {
      if (this.isSyncLock) {
        if (!this.isSyncPostPond) {
          setTimeout(() => {
            this.isSyncPostPond = true;
            this.trySyncUnsynced(true);
          }, 200);
        }
        return true;
      }
      if (postpond) {
        this.isSyncPostPond = false;
      }
      this.isSyncLock = true;
      let unSyncedElements: Expense[] = await this.dao.getAllUnsynced(
        this.selectedProfileId
      );
      if (unSyncedElements && unSyncedElements?.length) {
        try {
          
          for (let i = 0; i < unSyncedElements?.length; i++) {
            let unSyncedElement = unSyncedElements[i];
            if(unSyncedElement?._localUUID) {
              unSyncedElements[i]['updatedStamp'] = +new Date();
            }
          }
  
          await this.dao.bulkPut(unSyncedElements);
          await Utility.wait(1000);
  
          let chunkArr = Utility.getChunkArr(unSyncedElements);
          let chunkArrLength = chunkArr?.length;
  
          for(let i = 0; i < chunkArrLength; i++) {
            let result = await this.expressServerService.makeSyncCall(
              'expense',
              chunkArr[i]
            );
            if (result && result?.['records']?.length) {
              let arr = result?.['records'];
              for (let i = 0; i < arr?.length; i++) {
                const el = arr[i];
                await this.updateSyncStamp(el);
              }
            }
          }
        } catch (err) {
          SentryUtilites.setLog("ExpenseService:trySyncUnsynced:inner", err)
        }
      }
      this.isSyncLock = false;
    } catch (error) {
      SentryUtilites.setLog("ExpenseService:trySyncUnsynced", error)
    }
  }

  updateSyncStamp(el: Expense): Promise<Expense> {
    return new Promise(async (resolve, reject) => {
      try {
        let updatedEl = await this.dao.updateSyncStamp(el);
        this.updateSubs.next(updatedEl);
        return resolve(updatedEl); 
      } catch (error) {
        SentryUtilites.setLog("ExpenseService:updateSyncStamp", error)
        return resolve(null);
      }
    });
  }

  async getNewExpenseNo(): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        let expense = await this.dao.getAllByProfile(this.selectedProfileId);
        let nextExpenseNo = 'EXP_001';
        if (expense[0]?.billNo) {
          nextExpenseNo = Utility.nextNo(expense[0]?.billNo);
        }
        return resolve(nextExpenseNo);
      } catch (error) {
        SentryUtilites.setLog("ExpenseService:getNewExpenseNo", error)
        return resolve(null);
      }
    });
  }

  getNewBillNo() {}

  /**
   * 
   * @returns : return deleted expenses from expense dao
   */
  async getAllDeleted(): Promise<Expense[]> {
    try {
      let res = await this.dao.getAllDeletedByProfile(this.selectedProfileId);
      return res || [];
    } catch (error) {
      SentryUtilites.setLog("ExpenseService:getAllDeleted", error)
      return [];
    }
  }
  // -------------------------------------------

  copyData(fromProfileId: string, toProfileId: string): Promise<boolean> {
    return new Promise(async (resolve,reject) => {
      try {
        if(Utility.isTruthy(fromProfileId) && Utility.isTruthy(toProfileId)) {
          let fromRecords = await this.getAllByPromiseByProfile(fromProfileId);
          if(fromRecords?.length) {
            let toRecords: Expense[] = [];
            for (let i = 0; i < fromRecords?.length; i++) {
              const fetchedRecord = fromRecords[i];
              if(fetchedRecord?._localUUID) {
                fetchedRecord.profileId = toProfileId;
                delete fetchedRecord?._localId;
                delete fetchedRecord?._localUUID;
                let savedRecord = await this.save(fetchedRecord);
                if(savedRecord?._localUUID) {
                  toRecords?.push(savedRecord);
                }
              }
            }
            if(fromRecords?.length === toRecords?.length) {
              return resolve(true);
            }
          }
        }
        return resolve(false);
      } catch (error) {
        SentryUtilites.setLog("ExpenseService:copyData", error)
        return resolve(false);
      }
    });
  }

}
