import { Component, OnInit, ViewChild } from '@angular/core';
import { SentryUtilites } from '../../../utils/sentryUtilites';
import { PartySelectorComponent } from '../../../components/party-selector/party-selector.component';
import { ItemSelectorComponent } from '../../../components/item-selector/item-selector.component';
import { ItemRestaurantSelectorComponent } from '../../../components/item-restaurant-selector/item-restaurant-selector.component';
import { MoneyInSelectorComponent } from '../../../components/money-in-selector/money-in-selector.component';
import { SettingsComponent } from '../../../components/settings/settings.component';
import { IonInput, ToastController } from '@ionic/angular';
import { Utility } from '../../../utils/utility';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PurchaseReturn } from '../../../models/PurchaseReturn.model';
import { Profile } from '../../../models/Profile.model';
import { Subscription } from 'rxjs';
import { AllDataService } from '../../../services/all-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import Party from '../../../models/Party.model';
import { BillItem } from '../../../models/BillItem.model';
import { MoneyIn } from '../../../models/MoneyIn.model';
import { CalculateBillTask } from '../../../utils/enums/CalculateBillTask';
import { Utils } from '../../../utils/utils';
import { BillCalculations } from '../../../utils/BillCalculations';
import { BillType } from '../../../utils/enums/BillType';
import { Purchase } from '../../../models/Purchase.model';
import { PartyRestaurantSelectorComponent } from '../../../components/party-restaurant-selector/party-restaurant-selector.component';

@Component({
  selector: 'app-purchase-return-form',
  templateUrl: './purchase-return-form.page.html',
  styleUrls: ['./purchase-return-form.page.scss'],
})
export class PurchaseReturnFormPage implements OnInit {

  /*
    Native Element Ref
  */

    @ViewChild('partySelector', { static: false }) partySelector: PartySelectorComponent;
    @ViewChild('partyRestaurantSelector', { static: false }) partyRestaurantSelector: PartyRestaurantSelectorComponent;
    @ViewChild('itemSelector', { static: false }) itemSelector: ItemSelectorComponent;
    @ViewChild('itemRestaurantSelector', { static: false }) itemRestaurantSelector: ItemRestaurantSelectorComponent;
    @ViewChild('moneyInSelector', { static: false }) moneyInSelector: MoneyInSelectorComponent;
    @ViewChild('settingsModal') settingsModal: SettingsComponent;
    @ViewChild('amountReceivedEle') amountReceivedEle: IonInput;
    @ViewChild('paymentIdEle') paymentIdEle: IonInput;
  
    getHeaderColorClass = Utility.getHeaderColorClass;
    maxDate = Utility.maxDateUpto50Year();
    states = Utility.states;
    setDateValue = Utility.setDateValue;
    isTrue = Boolean;
  
    /*
       Reactive Form
      */
  
    form: FormGroup;
  
    barcodeScannerMode = false;
  
    dpBillDateValue: string = null;
    dpPurchaseBillDateValue: string = null;
    dpEWayBillDateValue: string = null;
    dpDeliveryDateValue: string = null;
    minDateTransport: string = Utility.ionDatePickerFormattedString(+new Date().setHours(0, 0, 0, 0));
  
    showTransportDetailsCard = false;
    paramDocumentId: string = null;
    queryParamPurchaseUUID: string = null;
    fetchedPurchaseReturn: PurchaseReturn = null;
    fetchPurchase: Purchase = null;
  
    amountReceivedChecked: boolean = null;
    isDisableAmountReceived = false;
  
    selectedProfile: Profile = null;
  
    paymentMode: string = 'cash';
  
    listStyleView = false;
  
    noPartySelectError = false;
  
    iSetItemPriceHistoryStatus = false;
  
    iSetOutOfStockHideStatus = false;
  
    isOpenBillDatePicker: boolean = false;
    isOpenPurchaseBillDatePicker: boolean = false;
    isOpenEwayBillDatePicker: boolean = false;
    isOpenDeliveryDatePicker: boolean = false;
    keepContentsMounted: boolean = false;
  
    todayDate: number = +new Date().setHours(0, 0, 0, 0);
  
    isNgOnInitRun: boolean = false;
  
    subArr: Subscription[] = [];
  
    constructor(
      private formBuilder: FormBuilder,
      private toastController: ToastController,
      private allDataService: AllDataService,
      private route: ActivatedRoute,
      private router: Router
    ) {}
  
    /*
        Life Cycle Hooks
      */
  
    ngOnInit() {
      try {
        this.isNgOnInitRun = true;
        this.getParamDocumentId();
        this.getQueryPurchaseLocalUUID();
        this.initializeReactiveForm();
        this.populateBarcodeScannerSetting();
        this.subArr.push(this.allDataService.listForceReloadSubs.subscribe((listName: string) => {
          if (listName == 'profile-list') {
            this.getSelectedProfile();
          }
        }));
      } catch (error) {
        SentryUtilites.setLog("PurchaseReturnFormPage:ngOnInit", error)
      }
    }
  
    async ngAfterViewInit() {
      try {
        await this.getSelectedProfile();
        setTimeout(() => {
          this.populateForm();
        }, 500);
      } catch (error) {
        SentryUtilites.setLog("PurchaseReturnFormPage:ngAfterViewInit", error)
      }
    }
  
    ionViewWillEnter() {
      if(!this.isNgOnInitRun) {
        this.ngOnInit();
        this.ngAfterViewInit();
      }
    }
  
    ionViewWillLeave() {
      try {
        this.paramDocumentId = null;
        this.queryParamPurchaseUUID = null;
        this.resetBillForm();
      } catch (error) {
        SentryUtilites.setLog("PurchaseReturnFormPage:addCationViewWillLeaveegory", error)
      }
    }
  
    onSettingSave() {
      this.populateBarcodeScannerSetting();
    }
  
    getParamDocumentId() {
      this.paramDocumentId = this.route.snapshot.paramMap.get('documentId');
    }

    getQueryPurchaseLocalUUID() {
      // to recieved updated value
      this.subArr.push(this.route.queryParams.subscribe(query => {
        this.queryParamPurchaseUUID = query['purchaseLocalUUID'];
      }));
    }
  
    populateSettings() {
      try {
        this.listStyleView = this.selectedProfile?.iSetItemSelectorStyleWeb === 'List';
        if(this.amountReceivedChecked === null) {
          this.amountReceivedChecked = this.selectedProfile?.iSetAutoMoneyInStatus;
        }
        this.iSetItemPriceHistoryStatus = this.selectedProfile?.iSetItemPriceHistoryStatus;
        this.iSetOutOfStockHideStatus = this.selectedProfile?.iSetOutOfStockHideStatus;
        if (this.selectedProfile?.iSetServiceChargePercentage) {
          this.form?.patchValue({
            serviceChargePercentage: this.selectedProfile?.iSetServiceChargePercentage,
          });
        }
      } catch (error) {
        SentryUtilites.setLog("PurchaseReturnFormPage:populateSettings", error)
      }
    }
  
    // Fetch selected profile
    async getSelectedProfile() {
      try {
        this.selectedProfile =
          await this.allDataService.profileService.getCurrentProfile();
        this.populateSettings();
        return true;
      } catch (error) {
        SentryUtilites.setLog("PurchaseReturnFormPage:getSelectedProfile", error)
        return false;
      }
    }
  
    async populateForm() {
      try {
        if (this.paramDocumentId) {
          this.fetchedPurchaseReturn = await this.allDataService.purchaseReturnService.getByUUID(
            this.paramDocumentId
          );
          if(this.fetchedPurchaseReturn?._localUUID) {
            if (this.fetchedPurchaseReturn?.deletedStamp) {
              this.form.patchValue({ ...this.fetchedPurchaseReturn });
              this.dpBillDateValue = Utility.ionDatePickerFormattedString(this.fetchedPurchaseReturn?.billDateStamp);
      
              if (this.listStyleView) {
                this.partySelector?.setSelectedParty(this.fetchedPurchaseReturn?.party);
                this.paramDocumentId && this.partySelector?.disable();
                this.itemSelector?.setBillItems(this.fetchedPurchaseReturn?.billItems);
                this.itemSelector.disable();
              } else {
                this.partyRestaurantSelector?.setSelectedParty(this.fetchedPurchaseReturn?.party);
                this.paramDocumentId && this.partyRestaurantSelector?.disable();
                this.itemRestaurantSelector?.setBillItems(this.fetchedPurchaseReturn.billItems);
                this.itemRestaurantSelector.disable();
                // this.itemRestaurantSelector?.openItemSelectorModal();
              }
      
      
              this.populateTransportDetails(this.fetchedPurchaseReturn.transportDetail);
              // this.setTransportDetailsCardVisibiltity();
      
              this.disableAmountReceived();
              if (this.fetchedPurchaseReturn?.moneyIns?.length) {
                this.paymentMode = this.fetchedPurchaseReturn?.moneyIns[0]?.paymentMode;
                setTimeout(() => {
                  if (this.paymentIdEle) {
                    (this.paymentIdEle as any)['el'].value =
                      this.fetchedPurchaseReturn?.moneyIns[0]?.paymentId;
                    this.paymentIdEle.value = this.fetchedPurchaseReturn?.moneyIns[0]?.paymentId;
                  }
                  if (this.itemRestaurantSelector) {
                    this.itemRestaurantSelector.paymentId =
                      this.fetchedPurchaseReturn?.moneyIns[0]?.paymentId;
                  }
                }, 1000);
              }
            }
      
            setTimeout(() => {
              this.form.patchValue({
                cashDiscount: this.fetchedPurchaseReturn?.cashDiscount,
                cashDiscountPercentage: this.fetchedPurchaseReturn?.cashDiscountPercentage,
              });
              this.recalculateBillItems()
            }, 1000);
          }
        } else if (this.queryParamPurchaseUUID) {
          this.fetchPurchase = await this.allDataService.purchaseService.getByUUID(this.queryParamPurchaseUUID);
          if(this.fetchPurchase?._localUUID) {
            if(!this.fetchPurchase?.deletedStamp) {
              this.form.patchValue({ 
                ...this.fetchPurchase, 
                purchaseBillNo: this.fetchPurchase?.billNo, 
                purchaseBillDateStamp: this.fetchPurchase?.billDateStamp, 
                purchaseCreatedStamp: this.fetchPurchase?.createdStamp,
                billDateStamp: +new Date().setHours(0,0,0,0),
                amountReceived: this.fetchPurchase?.amountPaid,
              });
              this.populateBillNumber();
              this.dpBillDateValue = Utility.ionDatePickerFormattedString(+new Date());
              this.dpPurchaseBillDateValue = Utility.ionDatePickerFormattedString(this.fetchPurchase?.billDateStamp)
      
              if (this.listStyleView) {
                this.partySelector?.setSelectedParty(this.fetchPurchase?.party);
                this.queryParamPurchaseUUID && this.partySelector?.disable();
                this.itemSelector?.setBillItems(this.fetchPurchase?.billItems);
                this.itemSelector.disable();
              } else {
                this.partyRestaurantSelector?.setSelectedParty(this.fetchPurchase?.party);
                this.queryParamPurchaseUUID && this.partyRestaurantSelector?.disable();
                this.itemRestaurantSelector?.setBillItems(this.fetchPurchase?.billItems);
                this.itemRestaurantSelector.disable();
                // this.itemRestaurantSelector?.openItemSelectorModal();
              }
              
              this.populateTransportDetails(this.fetchPurchase?.transportDetail);
              // this.setTransportDetailsCardVisibiltity();
  
              if (this.fetchPurchase?.moneyOuts?.length) {
                this.paymentMode = this.fetchPurchase?.moneyOuts[0]?.paymentMode;
                setTimeout(() => {
                  if (this.paymentIdEle) {
                    (this.paymentIdEle as any)['el'].value =
                      this.fetchPurchase?.moneyOuts[0]?.paymentId;
                    this.paymentIdEle.value = this.fetchPurchase?.moneyOuts[0]?.paymentId;
                  }
                  if (this.itemRestaurantSelector) {
                    this.itemRestaurantSelector.paymentId =
                      this.fetchPurchase?.moneyOuts[0]?.paymentId;
                  }
                }, 1000);
              }
            }
  
            setTimeout(() => {
              this.form.patchValue({
                cashDiscount: this.fetchPurchase?.cashDiscount,
                cashDiscountPercentage: this.fetchPurchase?.cashDiscountPercentage,
              });
              this.recalculateBillItems()
            }, 1000);
          } 

        } else {
          this.populateBillNumber();
          this.openPartySelectorModal();
        }
      } catch (error) {
        SentryUtilites.setLog("PurchaseReturnFormPage:populateForm", error)
      }
    }
  
    //----------------------------------------------------------------------------
  
    /*
        Reactive Form Initialization
      */
  
    initializeReactiveForm() {
      try {
        this.form = this.formBuilder.group({
          billNo: ['', Validators.required],
          billDateStamp: [+new Date().setHours(0, 0, 0, 0), Validators.required],
          purchaseBillNo: [''],
          purchaseBillDateStamp: [+new Date().setHours(0, 0, 0, 0)],
          purchaseCreatedStamp: [null],
          party: [null, Validators.required],
          billItems: [null],
          transportDetail: this.formBuilder.group({
            additionalAmount: [null],
            purOrderNo: [null],
            challanNo: [null],
            eWayBillNo: [null],
            eWayBillDate: [],
            transporterName: [null],
            vehicleNumber: [null],
            transDistance: [null, Validators.maxLength(5)],
            deliveryLocation: [null],
            deliveryDate: [],
            tnc: [null],
          }),
          gstAmount: [0],
          cessAmount: [0],
          discountAmount: [0],
          totalAmount: [null],
          amountReceived: [null],
          subTotalAmount: [null],
          note: [null],
          cashDiscount: [null],
          cashDiscountPercentage: [null],
          deliveryProvience: [null],
        });
        this.subArr.push(this.form.get('amountReceived').valueChanges.pipe(distinctUntilChanged(), debounceTime(300)).subscribe((amountReceived) => {
          if (+amountReceived && !this.amountReceivedChecked) {
            this.amountReceivedChecked = true;
          }
          if (amountReceived < 0) {
            this.form?.patchValue({ amountReceived: Math.abs(amountReceived) });
          }
          if (amountReceived > this.form?.value?.totalAmount) {
            this.form?.patchValue({
              amountReceived: this.form?.value?.totalAmount,
            });
          }
        }));
        this.subArr.push(this.form.get('totalAmount').valueChanges.pipe(distinctUntilChanged(), debounceTime(300)).subscribe((totalAmount) => {
          if (this.amountReceivedChecked) {
            this.form?.patchValue({ amountReceived: this.fetchPurchase?.amountPaid || totalAmount });
          }
        }));
        this.subArr.push(this.form.get("cashDiscountPercentage").valueChanges.pipe(distinctUntilChanged(), debounceTime(300)).subscribe(cashDiscountPercentage => {
          if(cashDiscountPercentage < 0 || cashDiscountPercentage > 100) {
            this.form.patchValue({cashDiscountPercentage: Math.abs(cashDiscountPercentage>100 ? 100 : cashDiscountPercentage)});
          }else if(cashDiscountPercentage!=null && !Number.isInteger(cashDiscountPercentage)) {
            let arr:string[] = (''+cashDiscountPercentage).split('.');
            if(arr[1]?.length > 3) {
              this.form.patchValue({cashDiscountPercentage: Number(arr[0]+'.'+arr[1].substr(0,3))});
            }
          }
        }));
      } catch (error) {
        SentryUtilites.setLog("PurchaseReturnFormPage:initializeReactiveForm", error)
      }
    }
  
    //----------------------------------------------------------------------------
  
    openSettingsModal() {
      this.settingsModal?.openSettingsModal();
    }
  
    /*
        Sale Number
      */
  
    async populateBillNumber() {
      try {
        this.form.patchValue({
          billNo: await this.allDataService.purchaseReturnService.getNewPurchaseReturnNo(),
        });
      } catch (error) {
        SentryUtilites.setLog("PurchaseReturnFormPage:populateBillNumber", error)
      }
    }
  
    //----------------------------------------------------------------------------
  
    /*
        PartyV0 Selector functons
      */
  
    openPartySelectorModal() {
      try {
        if (
          this.listStyleView &&
          this.partySelector &&
          !this.partySelector?.isPartySelected
        ) {
          this.partySelector?.openPartySelectorModal();
        } else {
          this.partySelector?.openPartySelectorModal(false);
        }
        if (
          !this.listStyleView &&
          this.partyRestaurantSelector &&
          !this.partyRestaurantSelector?.isPartySelected
        ) {
          this.partyRestaurantSelector?.openPartySelectorModal();
        } else {
          this.partyRestaurantSelector?.openPartySelectorModal(false);
        }
      } catch (error) {
        SentryUtilites.setLog("PurchaseReturnFormPage:openPartySelectorModal", error)
      }
    }
  
    onPartySelect(party: Party) {
      try {
        if (party?._localUUID) {
          this.form.patchValue({ party });
          this.form.patchValue({
            deliveryProvience: party?.deliveryProvience || party?.billingProvience
          });
          this.itemRestaurantSelector?.openItemSelectorModal();
        }
      } catch (error) {
        SentryUtilites.setLog("PurchaseReturnFormPage:onPartySelect", error)
      }
    }
  
    //----------------------------------------------------------------------------
  
    /*
        Sale Items
      */
  
    async populateBillItems(billItems: BillItem[]) {
      try {
        let totalAmount = 0;
        billItems?.forEach((x) => (totalAmount += x?.total));
        if (this.itemRestaurantSelector?.amountReceivedChecked) {
          this.amountReceivedChecked = true;
        }
        this.form.patchValue({
          billItems,
          cashDiscount: null,
          cashDiscountPercentage: null,
        });
        this.recalculateBillItems();
      } catch (error) {
        SentryUtilites.setLog("PurchaseReturnFormPage:populateBillItems", error)
      }
    }
  
    //----------------------------------------------------------------------------
  
    onMoneyInCartUpdate(moneyInCart: MoneyIn[]) {
      try {
        let totalAmountReceived= 0;
        moneyInCart?.forEach(
          (x) => (totalAmountReceived+= Number(x?.totalAmount))
        );
        this.form?.controls?.amountReceived?.setValue(totalAmountReceived);
      } catch (error) {
        SentryUtilites.setLog("PurchaseReturnFormPage:onMoneyInCartUpdate", error)
      }
    }
  
    /*
        Transport Details
      */
  
    populateTransportDetails(data: any) {
      try {
        this.form.patchValue({ transportDetail: data });
        if (data?.eWayBillDate) {
          this.dpEWayBillDateValue = Utility.ionDatePickerFormattedString(
            data?.eWayBillDate
          );
        }
        if (data?.deliveryDate) {
          this.dpDeliveryDateValue = Utility.ionDatePickerFormattedString(
            data?.deliveryDate
          );
        }
        // this.setTransportDetailsCardVisibiltity();
        this.calculateSubTotalAmount();
      } catch (error) {
        SentryUtilites.setLog("PurchaseReturnFormPage:populateTransportDetails", error)
      }
    }
  
    setTransportDetailsCardVisibiltity() {
      try {
        let data = this.form.value.transportDetail;
        if (
          data?.additionalAmount ||
          data?.eWayBillNo ||
          data?.eWayBillDate ||
          data?.purOrderNo ||
          data?.challanNo ||
          data?.transporterName ||
          data?.vehicleNumber ||
          data?.deliveryDate ||
          data?.transDistance ||
          data?.deliveryLocation ||
          data?.tnc
        ) {
          this.showTransportDetailsCard = true;
        } else {
          this.showTransportDetailsCard = false;
        }
      } catch (error) {
        SentryUtilites.setLog("PurchaseReturnFormPage:setTransportDetailsCardVisibiltity", error)
      }
    }
  
    //----------------------------------------------------------------------------
  
    /*
        Sale Total Amount
      */
  
    setTotalAmount(totalAmount: number) {
      this.form.patchValue({ totalAmount });
    }
  
    //----------------------------------------------------------------------------
  
    /*
        Sale Settings
      */
  
    async getBillSetting() {
      return null;
    }
  
    //----------------------------------------------------------------------------
  
    amountReceivedSetBlur(keyboardEvent: any) {
      try {
        keyboardEvent = keyboardEvent as KeyboardEvent;
        keyboardEvent?.preventDefault();
        keyboardEvent?.target?.blur();
      } catch (error) {
        SentryUtilites.setLog("PurchaseReturnFormPage:amountReceivedSetBlur", error)
      }
    }
  
    setPaymentMode(event) {
      try {
        this.paymentMode = event?.detail?.value || 'cash';
        this.onItemSelectorPaymentModeChange(this.paymentMode);
      } catch (error) {
        SentryUtilites.setLog("PurchaseReturnFormPage:setPaymentMode", error)
      }
    }
  
    onItemSelectorPaymentModeChange(paymentMode: string) {
      try {
        this.paymentMode = paymentMode;
        if (
          this.paramDocumentId &&
          this.fetchedPurchaseReturn?.moneyIns?.length
        ) {
          this.fetchedPurchaseReturn.moneyIns[0].paymentMode = paymentMode;
        }
      } catch (error) {
        SentryUtilites.setLog("PurchaseReturnFormPage:onItemSelectorPaymentModeChange", error)
      }
    }
  
    /*
        Barcode Scanner
      */
  
    async populateBarcodeScannerSetting() {
      try {
        this.barcodeScannerMode = Utility.getFromLocalStorage(
          'item_barcode_scanner'
        );
      } catch (error) {
        SentryUtilites.setLog("PurchaseReturnFormPage:populateBarcodeScannerSetting", error)
      }
    }
  
    async onBarcodeScannerModeChange(event: any) {
      try {
        this.barcodeScannerMode = event?.detail?.checked;
        // Utility.setToLocalStorage('item_barcode_scanner', this.barcodeScannerMode);
      } catch (error) {
        SentryUtilites.setLog("PurchaseReturnFormPage:onBarcodeScannerModeChange", error)
      }
    }
  
    //----------------------------------------------------------------------------
  
    focusAmountReceived(event) {
      try {
        if (event) {
          this.amountReceivedEle?.setFocus();
        }
      } catch (error) {
        SentryUtilites.setLog("PurchaseReturnFormPage:focusAmountReceived", error)
      }
    }
  
    onAmountReceivedCheckedChange(event: any) {
      try {
        if (this.amountReceivedChecked != event?.detail?.checked) {
          this.amountReceivedChecked = event?.detail?.checked;
          if (this.amountReceivedChecked) {
            
            this.populateAmountReceived(
              Number(this.form?.value?.amountReceived) || this.form?.value?.totalAmount
            );
          } else {
            this.populateAmountReceived(null);
          }
        }
      } catch (error) {
        SentryUtilites.setLog("PurchaseReturnFormPage:onAmountReceivedCheckedChange", error)
      }
    }
  
    populateAmountReceived(amountReceived: number) {
      this.form.patchValue({ amountReceived });
    }
  
    disableAmountReceived() {
      try {
        if (this.fetchedPurchaseReturn?.moneyIns?.length) {
          this.amountReceivedChecked = true;
          this.isDisableAmountReceived = true;
          this.form.get('amountReceived')?.disable();
        }
      } catch (error) {
        SentryUtilites.setLog("PurchaseReturnFormPage:disableAmountReceived", error)
      }
    }
  
    /**
     * 
     * @param event : date event
     * @description : bill date select assign date value
     */
    onBillDateSelect(event) {
      try {
        if (event?.detail?.value) {
          let timeStamp = +new Date(event?.detail?.value).setHours(0, 0, 0, 0);
          this.form?.controls?.billDateStamp?.setValue(timeStamp);
          this.dpBillDateValue = Utility.ionDatePickerFormattedString(timeStamp);
          Utility.closeDatePicker(event?.target);
        }
      } catch (error) {
        SentryUtilites.setLog("PurchaseReturnFormPage:onBillDateSelect", error)
      }
    }
    // --------------------------------

    /**
     * 
     * @param event : date event
     * @description : bill date select assign date value
     */
    onPurchaseBillDateSelect(event) {
      try {
        if (event?.detail?.value) {
          let timeStamp = +new Date(event?.detail?.value).setHours(0, 0, 0, 0);
          this.form?.controls?.purchaseBillDateStamp?.setValue(timeStamp);
          this.dpPurchaseBillDateValue = Utility.ionDatePickerFormattedString(timeStamp);
          Utility.closeDatePicker(event?.target);
        }
      } catch (error) {
        SentryUtilites.setLog("PurchaseReturnFormPage:onPurchaseBillDateSelect", error)
      }
    }
    // --------------------------------
  
    /**
     * 
     * @param event : date event
     * @description : E way bill date select assign date value
     */
    onEwayBillDateSelect(event) {
      try {
        if(event?.detail?.value) {
          let timeStamp = +new Date(event?.detail?.value).setHours(0, 0, 0, 0);
          this.form?.get('transportDetail.eWayBillDate')?.setValue(timeStamp);
          this.dpEWayBillDateValue = Utility.ionDatePickerFormattedString(timeStamp);
          Utility.closeDatePicker(event?.target);
        }
      } catch (error) {
        SentryUtilites.setLog("PurchaseReturnFormPage:onEwayBillDateSelect", error)
      }
    }
    // --------------------------------
  
    /**
     * 
     * @param event : date event
     * @description : delivery date select assign date value
     */
    onDeliveryDateSelect(event) {
      try {
        if(event?.detail?.value) {
          let timeStamp = +new Date(event?.detail?.value).setHours(0, 0, 0, 0);
          this.form?.get('transportDetail.deliveryDate')?.setValue(timeStamp);
          this.dpDeliveryDateValue = Utility.ionDatePickerFormattedString(timeStamp);
          Utility.closeDatePicker(event?.target);
        }
      } catch (error) {
        SentryUtilites.setLog("PurchaseReturnFormPage:onDeliveryDateSelect", error)
      }
    }
    // --------------------------------
  
    returnZero() {
      return 0;
    }
  
    setPaymentId(value) {
      try {
        if (this.paymentIdEle) {
          (this.paymentIdEle as any)['el'].value = value;
          if (
            this.paramDocumentId &&
            this.fetchedPurchaseReturn?.moneyIns?.length
          ) {
            this.fetchedPurchaseReturn.moneyIns[0].paymentId = value;
          }
        }
      } catch (error) {
        SentryUtilites.setLog("PurchaseReturnFormPage:setPaymentId", error)
      }
    }
  
    onSaveClick() {
      try {
        if (this.paramDocumentId) {
          this.update();
        } else {
          this.save();
        }
      } catch (error) {
        SentryUtilites.setLog("PurchaseReturnFormPage:onSaveClick", error)
      }
    }
  
    async addPurchaseReturn() {
      try {
        if (!this.form?.value?.party?._localUUID) {
          const toast = await this.toastController.create({
            header: 'Please Select Party to Save Bill',
            duration: 3000,
            position: 'top',
            mode: 'ios',
            color: 'danger',
          });
          await toast.present();
          this.noPartySelectError = true;
          return null;
        } else {
          this.noPartySelectError = false;
        }
    
        if (this.form.valid) {
          let purchaseReturn = new PurchaseReturn();
    
          purchaseReturn = { ...this.form.value };
    
          purchaseReturn.moneyIns = [];
    
          if (Number(this.form?.value?.amountReceived)) {
            let moneyIn = new MoneyIn();
            moneyIn.party = this.form.value.party;
            moneyIn.billDateStamp = purchaseReturn.billDateStamp;
            moneyIn.totalAmount = this.form.value.amountReceived;
            moneyIn.billNo =
              await this.allDataService.moneyInService.getNewMoneyInNo();
            moneyIn.paymentMode = this.paymentMode || 'cash';
            moneyIn.paymentId = (this.paymentIdEle?.value as string) || null;
            purchaseReturn.moneyIns.push(moneyIn);
          }

          if(this.queryParamPurchaseUUID) {
            purchaseReturn.linkedPurchaseUUID = this.queryParamPurchaseUUID;
          }
    
          return this.allDataService.purchaseReturnService.save(purchaseReturn);
        } else {
          return null;
        }
      } catch (error) {
        SentryUtilites.setLog("PurchaseReturnFormPage:addPurchaseReturn", error)
        return null;
      }
    }
  
    async updatePurchaseReturn() {
      try {
        if (this.form.valid) {
          let purchaseReturn = new PurchaseReturn();
    
          purchaseReturn = {
            ...this.fetchedPurchaseReturn,
            ...this.form.value,
          };
    
          // -- not required for update purchaseReturn because here not have kot and hold -- //
          // if (!purchaseReturn?.moneyIns?.length) {
          //   if (Number(this.form?.value?.amountReceived)) {
          //     let moneyIn = new MoneyIn();
          //     moneyIn.party = purchaseReturn.party;
          //     moneyIn.billDateStamp = purchaseReturn.billDateStamp;
          //     moneyIn.totalAmount = this.form.value.amountReceived;
          //     moneyIn.billNo =
          //       await this.allDataService.moneyInService.getNewMoneyInNo();
          //     moneyIn.paymentMode = this.paymentMode || 'cash';
          //     moneyIn.paymentId = (this.paymentIdEle?.value as string) || null;
          //     purchaseReturn.moneyIns = [moneyIn];
          //   }
          // } else {
          //   purchaseReturn.moneyIns = this.moneyInSelector?.getSelectedMoneyIns() || [];
          // }
    
          purchaseReturn.moneyIns = this.moneyInSelector?.getSelectedMoneyIns() || [];
    
          return await this.allDataService.purchaseReturnService.update(purchaseReturn);
        } else {
          return null;
        }
      } catch (error) {
        SentryUtilites.setLog("PurchaseReturnFormPage:updatePurchaseReturn", error)
        return null;
      }
    }
  
    async save() {
      try {
        let savedPurchaseReturn = await this.addPurchaseReturn();
        if (savedPurchaseReturn?._localUUID) {
          this.listForceList();
          this.itemRestaurantSelector?.openItemSelectorModal(false);
          this.router.navigate(['purchase-return']);
        }
      } catch (error) {
        SentryUtilites.setLog("PurchaseReturnFormPage:save", error)
      }
    }
  
    async onlySave() {
      try {
        let savedPurchaseReturn = null;
        if (this.fetchedPurchaseReturn?._localUUID) {
          savedPurchaseReturn = await this.updatePurchaseReturn();
        } else {
          savedPurchaseReturn = await this.addPurchaseReturn();
        }
        this.listForceList();
        if (savedPurchaseReturn?._localUUID) {
          this.resetBillForm();
          this.ngOnInit();
          this.ngAfterViewInit();
        }
      } catch (error) {
        SentryUtilites.setLog("PurchaseReturnFormPage:onlySave", error)
      }
    }
  
    async savePrint() {
      try {
        let savedPurchaseReturn: PurchaseReturn = null;
        if (this.fetchedPurchaseReturn?._localUUID) {
          savedPurchaseReturn = await this.updatePurchaseReturn();
        } else {
          savedPurchaseReturn = await this.addPurchaseReturn();
        }
        this.listForceList();
        if (savedPurchaseReturn?._localUUID) {
          this.itemRestaurantSelector?.openItemSelectorModal(false);
          this.resetBillForm();
          setTimeout(() => {
            this.router.navigate([`/bill-view-purchase-return/${savedPurchaseReturn?._localUUID}`], {
              queryParams: { isPrint: true },
            });
          }, 0);
          if (Utility.getFromLocalStorage('bill_amount_sound_status')) {
            Utility.playAmountSpeech(savedPurchaseReturn?.totalAmount);
          }
        }
      } catch (error) {
        SentryUtilites.setLog("PurchaseReturnFormPage:savePrint", error)
      }
    }
  
    async update() {
      try {
        let savedPurchaseReturn = await this.updatePurchaseReturn();
        if (savedPurchaseReturn?._localUUID) {
          this.listForceList();
          this.itemRestaurantSelector?.openItemSelectorModal(false);
          this.router.navigate(['purchase-return']);
        }
      } catch (error) {
        SentryUtilites.setLog("PurchaseReturnFormPage:update", error)
      }
    }
  
    resetBillForm() {
      try {
        this.itemRestaurantSelector?.openItemSelectorModal(false);
        this.itemRestaurantSelector?.resetCart();
        this.itemSelector?.openItemSelectorModal(false);
        this.itemSelector?.resetCart();
        if (this.listStyleView) {
          this.partySelector?.resetParty();
          this.partySelector?.enable();
          this.itemSelector.enable();
        } else {
          this.partyRestaurantSelector?.resetParty();
          this.partyRestaurantSelector?.enable();
          this.itemRestaurantSelector.enable();
        }
    
        this.form.reset();
        this.isNgOnInitRun = false;
        this.barcodeScannerMode = false;
        this.dpEWayBillDateValue = null;
        this.dpDeliveryDateValue = null;
        this.showTransportDetailsCard = false;
        this.amountReceivedChecked = null;
        this.isDisableAmountReceived = false;
  
        this.subArr?.forEach(sub => {
          sub?.unsubscribe();
        })
        this.subArr = [];
    
        if (this.paramDocumentId) {
          this.fetchedPurchaseReturn = null;
          this.router.navigate(['purchase-return/form']);
          this.initializeReactiveForm();
        } else if (this.queryParamPurchaseUUID) {
          this.router.navigate(['purchase-return']);
        } else {
          this.paramDocumentId = null;
          this.fetchedPurchaseReturn = null;
          this.fetchPurchase = null;
          this.queryParamPurchaseUUID = null;
        }
      } catch (error) {
        SentryUtilites.setLog("PurchaseReturnFormPage:resetBillForm", error)
      }
    }
  
    openMoneyInSelectorModal() {
      try {
        if (this.moneyInSelector) {
          this.moneyInSelector.openMoneyInSelectorModal();
        } else {
          this.moneyInSelector.openMoneyInSelectorModal(false);
        }
      } catch (error) {
        SentryUtilites.setLog("PurchaseReturnFormPage:openMoneyInSelectorModal", error)
      }
    }
  
    async listForceList() {
      try {
        this.allDataService.purchaseService.reloadList();
        this.allDataService.itemService.reloadList();
        this.allDataService.partyService.reloadList();
        this.allDataService.moneyInService.reloadList();
        await Utility.wait(2000);
        this.allDataService.listForceReloadSubs.next('moneyin-list');
        this.allDataService.listForceReloadSubs.next('purchase-return-list');
        this.allDataService.listForceReloadSubs.next('party-list');
      } catch (error) {
        SentryUtilites.setLog("PurchaseReturnFormPage:listForceList", error)
      }
    }
  
    // Calculations
  
    CalculateBillTask = CalculateBillTask;
  
    cashDiscountAmount = 0.0;
    cashDiscountPercentage = 0.0;
  
    unitDiscountAmountTotal = 0.0;
    unitGstAmountTotal = 0.0;
    unitCessAmountTotal = 0.0;
    unitTaxAmountTotal = 0.0;
    unitSubTotalAmountTotal = 0.0;
    unitTotalAmountTotal = 0.0;
    unitTotalSavingAmountTotal = 0.0;
  
    billItemsTotal = 0.0;
    wcdBillItemsTotal = 0.0;
  
    calculateSubTotalAmount() {
      try {
        this.unitDiscountAmountTotal = 0.0;
        this.unitGstAmountTotal = 0.0;
        this.unitCessAmountTotal = 0.0;
        this.unitTaxAmountTotal = 0.0;
        this.unitSubTotalAmountTotal = 0.0;
        this.unitTotalAmountTotal = 0.0;
        this.unitTotalSavingAmountTotal = 0.0;
    
        this.billItemsTotal = 0.0;
        this.wcdBillItemsTotal = 0.0;
    
        let billItems = this.form?.value?.billItems as BillItem[];
    
        billItems?.forEach((billItem) => {
          this.unitDiscountAmountTotal += billItem?.unitDiscountAmount || 0.0;
          this.unitGstAmountTotal += billItem?.itemTotalGstAmount || 0.0;
          this.unitCessAmountTotal += billItem?.itemTotalCessAmount || 0.0;
          this.unitTaxAmountTotal += billItem?.itemTotalTaxAmount || 0.0;
          this.unitSubTotalAmountTotal += billItem?.subTotal || 0.0;
          this.unitTotalAmountTotal += billItem?.total || 0.0;
          this.unitTotalSavingAmountTotal += billItem?.totalSaving || 0.0;
    
          this.billItemsTotal += billItem?.total || 0.0;
          this.wcdBillItemsTotal += billItem?.wcdTotal || 0.0;
        });
    
        this.form?.patchValue({
          discountAmount: this.unitDiscountAmountTotal,
          gstAmount: this.unitGstAmountTotal,
          cessAmount: this.unitCessAmountTotal,
          subTotalAmount: this.unitSubTotalAmountTotal,
          totalSaving: this.unitTotalSavingAmountTotal,
        });
        this.calculateTotalAmount();
      } catch (error) {
        SentryUtilites.setLog("PurchaseReturnFormPage:calculateSubTotalAmount", error)
      }
    }
  
    calculateCashDiscount(task: CalculateBillTask = CalculateBillTask.CALCULATE) {
      try {
        this.cashDiscountAmount = 0.0;
        this.cashDiscountPercentage = 0.0;
    
        if (task == CalculateBillTask.DISCOUNT_FROM_PERCENTAGE) {
          this.cashDiscountPercentage = this.form?.value?.cashDiscountPercentage;
          this.cashDiscountAmount = Utils.capFractionsToSix(this.wcdBillItemsTotal * this.cashDiscountPercentage / 100);
          this.form?.patchValue({
            cashDiscount: this.cashDiscountAmount
          });
        } else if (task == CalculateBillTask.DISCOUNT_FROM_AMOUNT) {
          this.cashDiscountAmount = this.form?.value?.cashDiscount;
          if(this.wcdBillItemsTotal > 0) {
            this.cashDiscountPercentage = Utils.capFractionsToSix((this.cashDiscountAmount * 100) / this.wcdBillItemsTotal);
          } else {
            this.cashDiscountPercentage = 0.0;
          }
          this.form?.patchValue({
            cashDiscountPercentage: this.cashDiscountPercentage
          });
        }
    
        if (this.cashDiscountAmount > this.wcdBillItemsTotal || this.cashDiscountAmount < 0) {
          this.cashDiscountAmount = 0.0;
          this.cashDiscountPercentage = 0.0;
          this.form?.patchValue({
            cashDiscountAmount: null,
            cashDiscountPercentage: null
          });
        }
    
        this.recalculateBillItems();
      } catch (error) {
        SentryUtilites.setLog("PurchaseReturnFormPage:calculateCashDiscount", error)
      }
  
    }
  
    calculateTotalAmount() {
      try {
        let additionalCharges =
          this.form?.value?.transportDetail?.additionalAmount || 0;
        if (!Utility.isNumber(additionalCharges)) {
          additionalCharges = 0.0;
        }
    
        let serviceChargeAmount = this.form?.value?.serviceChargeAmount || 0;
        if (!Utility.isNumber(serviceChargeAmount)) {
          serviceChargeAmount = 0.0;
        }
    
        let totalAmount =
          this.unitTotalAmountTotal +
          Number(additionalCharges) +
          Number(serviceChargeAmount);
        totalAmount = Utils.capFractionsToSix(totalAmount);
    
        let roundOffValue = 0.0;
        if (this.selectedProfile?.iSetRoundOffTotalAmountStatus) {
          let floorDelta = (totalAmount || 0.0) - Math.floor(totalAmount || 0.0);
          let ceilDelta = Math.ceil(totalAmount || 0.0) - (totalAmount || 0.0);
    
          if (floorDelta > ceilDelta) {
            roundOffValue = ceilDelta;
          } else {
            roundOffValue = floorDelta * -1;
          }
          roundOffValue = Utils.capFractionsToSix(roundOffValue);
          totalAmount = Math.round(totalAmount + roundOffValue);
        }
    
        this.form?.patchValue({
          totalAmount,
          roundOffValue,
        });
    
        if (this.amountReceivedChecked && !this.fetchedPurchaseReturn && !this.fetchPurchase) {
          this.populateAmountReceived(
            this.fetchedPurchaseReturn || this.fetchPurchase
              ? this.fetchedPurchaseReturn?.amountReceived || this.fetchPurchase?.amountPaid
              : this.form?.value?.totalAmount
          );
        }
      } catch (error) {
        SentryUtilites.setLog("PurchaseReturnFormPage:calculateTotalAmount", error)
      }
    }
  
    recalculateBillItems() {
      try {
        let billItems = this.form?.value?.billItems;
        billItems?.forEach((billItem) => {
          BillCalculations.calculateBillItem(
            billItem,
            BillType.PURCHASERETURN,
            null,
            this.form?.value?.cashDiscountPercentage
          );
        });
        this.itemRestaurantSelector?.setBillItemsNoEmitNoCal(billItems);
        this.form?.patchValue({
          billItems,
        });
        this.calculateSubTotalAmount();
      } catch (error) {
        SentryUtilites.setLog("PurchaseReturnFormPage:recalculateBillItems", error)
      }
    }
  
    /**
     * @description : close date picker
     */
    closeDOBPicker() {
      try {
        this.isOpenBillDatePicker = false;
        this.isOpenEwayBillDatePicker = false;
        this.isOpenDeliveryDatePicker = false;
        this.isOpenPurchaseBillDatePicker = false;
      } catch (error) {
        SentryUtilites.setLog("PurchaseReturnFormPage:closeDOBPicker", error)
      }
    }
    // ----------------------------------
  
    /**
     * @description : toggle for show/hide Transport Details
     */
    toggleTransportDetailsFields() {
      this.showTransportDetailsCard = !this.showTransportDetailsCard;
    }
    // -----------------------------------

}
