
export class Arrays {

    static states = {
        'ANDAMAN AND NICOBAR': 'ANDAMAN AND NICOBAR',
        'ANDHRA PRADESH BEFORE DIVISION' : 'ANDHRA PRADESH BEFORE DIVISION',
        'ANDHRA PRADESH NEW': 'ANDHRA PRADESH NEW',
        'ARUNACHAL PRADESH': 'ARUNACHAL PRADESH',
        'ASSAM': 'ASSAM',
        'BIHAR': 'BIHAR',
        'CHANDIGARH': 'CHANDIGARH',
        'CHATTISGARH': 'CHATTISGARH',
        'DADRA AND NAGAR HAVELI': 'DADRA AND NAGAR HAVELI',
        'DAMAN AND DIU': 'DAMAN AND DIU',
        'DELHI': 'DELHI',
        'GOA': 'GOA',
        'GUJARAT': 'GUJARAT',
        'HARYANA': 'HARYANA',
        'HIMACHAL PRADESH': 'HIMACHAL PRADESH',
        'JAMMU AND KASHMIR': 'JAMMU AND KASHMIR',
        'JHARKHAND': 'JHARKHAND',
        'KARNATAKA': 'KARNATAKA',
        'KERALA': 'KERALA',
        'LADAKH': 'LADAKH',
        'LAKSHADWEEP': 'LAKSHADWEEP',
        'MADHYA PRADESH': 'MADHYA PRADESH',
        'MAHARASHTRA': 'MAHARASHTRA',
        'MANIPUR': 'MANIPUR',
        'MEGHLAYA': 'MEGHLAYA',
        'MIZORAM': 'MIZORAM',
        'NAGALAND': 'NAGALAND',
        'ODISHA': 'ODISHA',
        'OUT OF INDIA Exempt': 'OUT OF INDIA Exempt',
        'OUT OF INDIA Taxable': 'OUT OF INDIA Taxable',
        'PUDUCHERRY': 'PUDUCHERRY',
        'PUNJAB': 'PUNJAB',
        'RAJASTHAN': 'RAJASTHAN',
        'SALE SEZ Exempt': 'SALE SEZ Exempt',
        'SALE SEZ Taxable': 'SALE SEZ Taxable',
        'SIKKIM': 'SIKKIM',
        'TAMIL NADU': 'TAMIL NADU',
        'TELANGANA': 'TELANGANA',
        'TRIPURA': 'TRIPURA',
        'UTTARAKHAND': 'UTTARAKHAND',
        'UTTAR PRADESH': 'UTTAR PRADESH',
        'WEST BENGAL': 'WEST BENGAL',
    };

    static UTS=['ANDAMAN AND NICOBAR','CHANDIGARH','DADRA AND NAGAR HAVELI',
                'DAMAN AND DIU','LAKSHADWEEP'];

    static UTSC=['35','04','26','25','31'];
    static units = [
        'Bags',
        'BALE',
        'Biliion of unit',
        'Bottles',
        'Box',
        'Buckles',
        'Bunches',
        'Bundels',
        'Cans',
        'Cartons',
        'Centimeters',
        'Cubic Centimeters',
        'Cubic Meters',
        'Days',
        'Dozens',
        'Drums',
        'Grammes',
        'Great Gross',
        'Gross Yards',
        'Kilograms',
        'Kilolitre',
        'Kilometer',
        'Litre',
        'Meters',
        'Metric Ton',
        'MilliGram',
        'Millilitre',
        'Numbers',
        'Others',
        'Packs',
        'Pairs',
        'Pieces',
        'Quintal',
        'Rolls',
        'Sets',
        'Sqare Feet',
        'Sqare Yards',
        'Sq. Feet',
        'Sq. Meters',
        'tablets',
        'Ten gross',
        'Thousand',
        'Tonnes',
        'Tubes',
        'Units',
        'US Gallons',
        'Yards',
    ];

    static unitAbbriviation = [
        'BAG',
        'BAL',
        'BOU',
        'BTL',
        'BOX',
        'BKL',
        'BUN',
        'BDL',
        'CAN',
        'CTN',
        'CMS',
        'CCM',
        'CBM',
        'DAY',
        'DOZ',
        'DRM',
        'GMS',
        'GGK',
        'GYD',
        'KGS',
        'KLR',
        'KME',
        'LTR',
        'MTR',
        'MTS',
        'MGS',
        'MLT',
        'NOS',
        'OTH',
        'PAC',
        'PRS',
        'PCS',
        'QTL',
        'ROL',
        'SET',
        'SQF',
        'SQY',
        'TBS',
        'TGM',
        'THD',
        'TON',
        'TUB',
        'UNT',
        'UGS',
        'YDS',

    ];

    static gstTypes = [
        '--Select--',
        'composite',
        'normal',
    ];


    static accountTypes = [
        '--Select--',
        'current',
        'saving',
    ];

    static txnTypes = [
        '--Select--',
        'cash',
        'bank transfer',
        'Cheque',
    ];


    static unitAbvrMap = {
        'BAG': 'Bags',
        'BAL': 'BALE',
        'BOU': 'Biliion of unit',
        'BTL': 'Bottles',
        'BOX': 'Box',
        'BKL': 'Buckles',
        'BUN': 'Bunches',
        'BDL': 'Bundels',
        'CAN': 'Cans',
        'CTN': 'Cartons',
        'CMS': 'Centimeters',
        'CCM': 'Cubic Centimeters',
        'CBM': 'Cubic Meters',
        'DAY': 'Days',
        'DOZ': 'Dozens',
        'DRM': 'Drums',
        'GMS': 'Grammes',
        'GGK': 'Great Gross',
        'GYD': 'Gross Yards',
        'KGS': 'Kilograms',
        'KLR': 'Kilolitre',
        'KME': 'Kilometer',
        'LTR': 'Litre',
        'MTR': 'Meters',
        'MTS': 'Metric Ton',
        'MGS' : 'MilliGram',
        'MLT': 'Millilitre',
        'NOS': 'Numbers',
        'OTH':'Others',
        'PAC': 'Packs',
        'PRS': 'Pairs',
        'PCS': 'Pieces',
        'QTL': 'Quintal',
        'ROL': 'Rolls',
        'SET': 'Sets',
        'SQF': 'Sq. Feet',
        'SQY': 'Sq. Yards',
        'SQM': 'Sq. Meters',
        'TBS': 'tablets',
        'TGM': 'Ten gross',
        'THD': 'Thousand',
        'TON': 'Tonnes',
        'TUB': 'Tubes',
        'UNT': 'Units',
        'UGS': 'US Gallons',
        'YDS': 'Yards',
    }

    static item_type = {
        'PRODUCT':'Product',
        'SERVICE':'Service',
    }

    static legal_entity={
        "Proprietor":"Proprietor",
        "Partnership":"Partnership",
        "Private Limited":"Private Limited",
        "LLP":"LLP",
        "OPC":"OPC",
        "Others":"Others"
    }

    static business_type={
        "retailer": 'Retailer/Trader',
        "wholesaler": 'Wholesaler',
        "manufacturer": 'Manufacturer',
        "services": 'Services',
        "freelancer": 'Freelancer',
        "others": 'Others',
    }

    static billCategory = {
         'Trasfer to own account': 'Transfer to own account',
         'Accounting Fees': 'Accounting Fees',
         'Annual Maintenance charge': 'Annual Maintenance charge',
         'Cosulting Charges': 'Consulting Charges',
         'Refund from vendor': 'Refund from vendor',
         'Subcriptions': 'Subscriptions',
         'Education and Training': 'Education and Training',
         'Employee Reebursement': 'Employee Salary',
         'Fuel': 'Fuel',
         'Interest Expense': 'Interest Expense',
         'Insurance': 'Insurance',
         'Legal Charges': 'Legal Charges',
         'Marketing': 'Marketing',
         'Meals and Entertainment': 'Meals and Entertainment',
         'Miscellaneous Expense': 'Miscellaneous Expense',
         'Office Supply': 'Office Supply',
         'Payment Processing Fees': 'Payment Processing Fees',
         'Phone and Internet': 'Phone and Internet',
         'Power and Utility': 'Power and Utility',
         'Rent': 'Rent',
         'Repair and Maintenance': 'Repair and Maintenance',
         'others': 'Others'
        }


}


