<ion-header [ngClass]="{'mobile': isMobile}">
  <ion-toolbar class="{{ getHeaderColorClass() }}">
    <app-navigation-control slot="start" type="kot"></app-navigation-control>
    <ion-title class="header-title">Kot List <ion-chip> Total Count: {{ kotListEle?.completeList?.length }}</ion-chip> </ion-title>
    <ion-text class="last-sync-time" color="medium">Last Sync at {{ lastSyncTime }}</ion-text>
  </ion-toolbar>
</ion-header>

<ion-content [ngClass]="{'mobile': isMobile}">
  <app-kot-list #kotListEle [viewSaleBtn]="true" [innerHeight]="innerHeight" [isMobile]="isMobile" (onRowClickTrigger)="onRowClickTrigger($event)">
  </app-kot-list>
  <ion-row >
    <ion-col size="12">
      <div class="kot-canvas-area" style="display:none;">
        <p class="kot-date"></p>
        <h2 class="kot-billNo"></h2>
        <h2 class="kot-party"></h2>
        <br/>
        <span class="kot-itemList"> </span>
      </div>
    </ion-col>
  </ion-row>
</ion-content>

<audio #kotPrintAudioEle>
  <source src="../../../assets/sounds/kot-print.mpeg" type="audio/mpeg">
</audio>
