<ion-header>
  <ion-toolbar class="{{ getHeaderColorClass() }} ion-toolbar-item-transaction">
    <app-navigation-control slot="start" type="ingredient/transaction/raw-material-purchase-bulk-edit" [_localUUID]="paramDocumentId"></app-navigation-control>
    <ion-title class="header-title">
      <span>
        Purchase Bulk Edit
      </span>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content appHorizontalScroll *ngIf="showContent">

  <!-- Raw material name -->

  <div class="title">
    <h3>{{rawMaterialName}} Purchase Enteries of past 30 days</h3>
  </div>

  <!-- ----------------------------------------------------------------------------------------- -->

<ion-grid>

  <!-- Purhase bulk edit header row -->

  <ion-row class="row-header-titles">
    <ion-col [class]="title.key" *ngFor="let title of purchaseFieldTitles|keyvalue : returnZero">
      {{ title.value }}
      <span *ngIf="title.key == 'col-quantity' || title.key == 'col-price'" class="span-mandatory">*</span>
    </ion-col>
  </ion-row>

  <!-- ----------------------------------------------------------------------------------------- -->

  <!-- Purhase bulk edit header row -->

  <ion-row class="row-items" [ngClass]="{'row-alt-color':i%2!==0}"
  *ngFor="let rawMaterial of viewFilteredList; let i=index;">

    <!-- Date & Time -->

    <ion-col class="col-date-time">
      {{rawMaterial?.createdStamp | date: 'dd/MM/yy h:mm:ss a'}}
    </ion-col>

    <!-- ----------------------------------------------------------------------------------------- -->

    <!-- Quantity -->

    <ion-col class="col-quantity">
      <input #rawMaterialQuantity [ngClass]="{'error': +rawMaterialQuantity?.value <= 0}" type="number" min="0" 
      [value]="rawMaterial?.quantity" (change)="onRawMaterialEdit(rawMaterial?._localUUID, 'quantity', rawMaterialQuantity?.value)">
    </ion-col>

    <!-- ----------------------------------------------------------------------------------------- -->

    <!-- Price -->

    <ion-col class="col-price">
      <input #rawMaterialPrice [ngClass]="{'error': +rawMaterialPrice?.value <= 0}" type="number" min="0" 
      [value]="rawMaterial?.price" (change)="onRawMaterialEdit(rawMaterial?._localUUID, 'price', rawMaterialPrice?.value)">
    </ion-col>

    <!-- ----------------------------------------------------------------------------------------- -->

    <!-- Created / Edited by -->

    <ion-col class="col-created-edited">
      {{ (rawMaterial?.userUpdatedStamp || rawMaterial?.updatedStamp) > rawMaterial?.createdStamp ? 'Edited by ' : 'Created by ' }} 
      {{ (rawMaterial?.userUpdatedStamp || rawMaterial?.updatedStamp) > rawMaterial?.createdStamp ? rawMaterial?.lastModifiedByName || rawMaterial?.lastModifiedBy :  rawMaterial?.createdByName || rawMaterial?.createdBy }}
      on
      {{ (rawMaterial?.userUpdatedStamp || rawMaterial?.updatedStamp) | date: 'dd/MM/yy h:mm:ss a' }}
    </ion-col>

    <!-- ----------------------------------------------------------------------------------------- -->

  </ion-row>

  <!-- ----------------------------------------------------------------------------------------- -->

  <!-- Update Bulk Items -->
  <ion-row>
    <ion-col class="exclude-col">
      <ion-button class="btn-save" (click)="updateEditedRawMaterial()">
        Update Bulk Purchase
      </ion-button>
    </ion-col>
  </ion-row>
  <!-- ---------------------------------------------------------------------------------------- -->

</ion-grid>

<ion-infinite-scroll threshold="10px" *ngIf="viewFilteredList?.length" (ionInfinite)="loadMoreListData($event)">
  <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Loading more data...">
  </ion-infinite-scroll-content>
</ion-infinite-scroll>

</ion-content>
