export class IMonthWiseItemStock {
    static SCHEMA_NAME = 'month-wise-item-stock';
}

export class MonthWiseItemStock {
    _localId: number;
    itemLocalUUID: string;
    ledgerStock: number;
    ledger: {
        [key: string]: number;
    };
}
