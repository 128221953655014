import { NgxIndexedDBService } from "ngx-indexed-db";
import { IDataRepo } from "../../../interface/IDataRepo.interface";
import { IItem, Item } from "../../models/Item.model";
import { Utility } from "../../utils/utility";
import { Router } from "@angular/router";
import { SentryUtilites } from "src/app/utils/sentryUtilites";

export class ItemDao implements IDataRepo<Item>{
  private static _instance: ItemDao;

  public static getInstance(
    ngxIndexedDBService: NgxIndexedDBService,
    router: Router,
  ) {
    if (!this._instance) {
      this._instance = new ItemDao(ngxIndexedDBService, router)
    }
    return this._instance;
  }

  constructor(
    ngxIndexedDBService: NgxIndexedDBService,
    router: Router,
    ) {
    this.ngxIndexedDBService = ngxIndexedDBService;
    this.router = router;
  }

  ngxIndexedDBService: NgxIndexedDBService;
  router: Router;

  save(data: Item): Promise<Item> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && Utility.isTruthy(data)) {
          let timeStamp = +new Date();
          data.createdStamp = timeStamp
          data.updatedStamp = timeStamp
          data.userUpdatedStamp = timeStamp
          data.deletedStamp = 0
          data.syncStamp = 0
          data.stock = 0.0
  
          data._localUUID = Utility.getUUID();
  
          this.ngxIndexedDBService
            .add(IItem.SCHEMA_NAME, data)
            .subscribe((savedItem: Item) => {
              return resolve(savedItem);
            },
            err => {
              console.error(err);
              if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                this.router.navigate(['idbx-error']);
              } else if (typeof err?.target?.error == 'object') {
                SentryUtilites.setLog("ItemDao:save", err?.target?.error)
              } else {
                SentryUtilites.setLog("ItemDao:save", err)
              }
            });
        } else {
          return resolve(null)
        }
      } catch (err) {
        SentryUtilites.setLog("ItemDao:save", err)
        return resolve(null);
      }
    });
  }

  saveDb(data: Item): Promise<Item> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && Utility.isTruthy(data)) {
          this.ngxIndexedDBService
            .add(IItem.SCHEMA_NAME, data)
            .subscribe((savedItem: Item) => {
              return resolve(savedItem);
            },
            err => {
              console.error(err);
              if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                this.router.navigate(['idbx-error']);
              } else if (typeof err?.target?.error == 'object') {
                SentryUtilites.setLog("ItemDao:saveDb", err?.target?.error)
              } else {
                SentryUtilites.setLog("ItemDao:saveDb", err)
              }
            });
        } else {
          return resolve(null)
        }
      } catch (error) {
        SentryUtilites.setLog("ItemDao:saveDb", error)
        return resolve(null);
      }
    })
  }

  update(data: Item): Promise<Item> {
    return new Promise(async (resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?._localUUID) {
          let oldData = await this.getByUUID(data?._localUUID);
          if(oldData?._localUUID) {
            data._localId = oldData?._localId;
            let timeStamp = +new Date();
            data.updatedStamp = timeStamp
            data.userUpdatedStamp = timeStamp
    
            this.ngxIndexedDBService
              .update(IItem.SCHEMA_NAME, data)
              .subscribe((updatedItem: Item) => {
                return resolve(updatedItem);
              },
              err => {
                console.error(err);
                if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                  this.router.navigate(['idbx-error']);
                } else if (typeof err?.target?.error == 'object') {
                  SentryUtilites.setLog("ItemDao:update", err?.target?.error)
                } else {
                  SentryUtilites.setLog("ItemDao:update", err)
                }
              });
          } else {
            return resolve(null);
          }
        } else {
          return resolve(null)
        }
      } catch (err) {
        SentryUtilites.setLog("ItemDao:update", err)
        return resolve(null);
      }
    })
  }
  updateDb(data: Item): Promise<Item> {
    return new Promise(async (resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?._localUUID) {
          let oldData = await this.getByUUID(data?._localUUID);
          if(oldData?._localUUID) {
            data._localId = oldData?._localId;
            this.ngxIndexedDBService
              .update(IItem.SCHEMA_NAME, data)
              .subscribe((updatedRecord: Item) => {
                return resolve(updatedRecord);
              },
              err => {
                console.error(err);
                if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                  this.router.navigate(['idbx-error']);
                } else if (typeof err?.target?.error == 'object') {
                  SentryUtilites.setLog("ItemDao:updateDb", err?.target?.error)
                } else {
                  SentryUtilites.setLog("ItemDao:updateDb", err)
                }
              });
          } else {
            return resolve(null);
          }
        } else {
          return resolve(null)
        }
      } catch (err) {
        SentryUtilites.setLog("ItemDao:updateDb", err)
        return resolve(null);
      }
    })
  }

  bulkPut(data: Item[]): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?.length) {
          this.ngxIndexedDBService
            .bulkPut(IItem.SCHEMA_NAME, data)
            .subscribe((data) => {
              resolve(true);
            },
            err => {
              console.error(err);
              if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                this.router.navigate(['idbx-error']);
              } else if (typeof err?.target?.error == 'object') {
                SentryUtilites.setLog("ItemDao:bulkPut", err?.target?.error)
              } else {
                SentryUtilites.setLog("ItemDao:bulkPut", err)
              }
            })
        } else {
          return resolve(false)
        }
      } catch (error) {
        SentryUtilites.setLog("ItemDao:bulkPut", error)
        return resolve(false);
      }
    })
  }

  delete(data: Item): Promise<Item> {
    return new Promise(async (resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?._localUUID) {
          let oldData = await this.getByUUID(data?._localUUID);
          if(oldData?._localUUID) {
            data._localId = oldData?._localId;
            let timeStamp = +new Date();
            data.updatedStamp = timeStamp;
            data.userUpdatedStamp = timeStamp;
            data.deletedStamp = timeStamp;
    
            this.ngxIndexedDBService
              .update(IItem.SCHEMA_NAME, data)
              .subscribe((updatedItem: Item) => {
                return resolve(updatedItem);
              },
              err => {
                console.error(err);
                if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                  this.router.navigate(['idbx-error']);
                } else if (typeof err?.target?.error == 'object') {
                  SentryUtilites.setLog("ItemDao:delete", err?.target?.error)
                } else {
                  SentryUtilites.setLog("ItemDao:delete", err)
                }
              });
          } else {
            return resolve(null);
          }
        } else {
          return resolve(null);
        }
      } catch (err) {
        SentryUtilites.setLog("ItemDao:delete", err)
        return resolve(null);
      }
    });
  }
  getById(id: number): Promise<Item> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && id) {
          this.ngxIndexedDBService
            .getByKey(IItem.SCHEMA_NAME, id)
            .subscribe((profile: Item) => {
              return resolve(profile);
            },
            err => {
              console.error(err);
              if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                this.router.navigate(['idbx-error']);
              } else if (typeof err?.target?.error == 'object') {
                SentryUtilites.setLog("ItemDao:getById", err?.target?.error)
              } else {
                SentryUtilites.setLog("ItemDao:getById", err)
              }
            });
        } else {
          return resolve(null)
        }
      } catch (err) {
        SentryUtilites.setLog("ItemDao:getById", err)
        return resolve(null)
      }
    })
  }
  getByUUID(uuid: string): Promise<Item> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && uuid) {
          this.ngxIndexedDBService
            .getByIndex(IItem.SCHEMA_NAME, '_localUUID', uuid)
            .subscribe((profile: Item) => {
              return resolve(profile);
            },
            err => {
              console.error(err);
              if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                this.router.navigate(['idbx-error']);
              } else if (typeof err?.target?.error == 'object') {
                SentryUtilites.setLog("ItemDao:getByUUID", err?.target?.error)
              } else {
                SentryUtilites.setLog("ItemDao:getByUUID", err)
              }
            });
        } else {
          return resolve(null)
        }
      } catch (err) {
        SentryUtilites.setLog("ItemDao:getByUUID", err)
        return resolve(null)
      }
    })
  }
  getAll(): Promise<Item[]> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token'))) {
          this.ngxIndexedDBService.getAll(IItem.SCHEMA_NAME).subscribe((docs: Item[]) => {
            docs = docs?.filter(x => !x?.deletedStamp);
            docs?.sort((a, b) => b?.createdStamp - a?.createdStamp);
            return resolve(docs)
          },
          err => {
            console.error(err);
            if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
              this.router.navigate(['idbx-error']);
            } else if (typeof err?.target?.error == 'object') {
              SentryUtilites.setLog("ItemDao:getAll", err?.target?.error)
            } else {
              SentryUtilites.setLog("ItemDao:getAll", err)
            }
          });
        } else {
          return resolve([])
        }
      } catch (err) {
        SentryUtilites.setLog("ItemDao:getAll", err)
        return resolve([])
      }
    })
  }
  getAllByProfile(profileId: string): Promise<Item[]> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && profileId) {
          this.ngxIndexedDBService.getAll(IItem.SCHEMA_NAME).subscribe((docs: Item[]) => {
            docs = docs?.filter(x => !x?.deletedStamp && x?.profileId == profileId);
            docs?.sort((a, b) => b?.createdStamp - a?.createdStamp);
  
            return resolve(docs)
          },
          err => {
            console.error(err);
            if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
              this.router.navigate(['idbx-error']);
            } else if (typeof err?.target?.error == 'object') {
              SentryUtilites.setLog("ItemDao:getAllByProfile", err?.target?.error)
            } else {
              SentryUtilites.setLog("ItemDao:getAllByProfile", err)
            }
          });
        } else {
          return resolve([])
        }
      } catch (err) {
        SentryUtilites.setLog("ItemDao:getAllByProfile", err)
        return resolve([])
      }
    })
  }
  getAllUnsynced(profileId: string): Promise<Item[]> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token'))) {
          let unSyncedElements: Item[] = [];
  
          this.ngxIndexedDBService.getAll(IItem.SCHEMA_NAME).subscribe(async (elArr: Item[]) => {
  
            for (let i = 0; i < elArr?.length; i++) {
              const el = elArr[i];
              if (el?.updatedStamp > el?.syncStamp || !el?._serverIdRef) {
                unSyncedElements.push(el);
              }
            }
            return resolve(unSyncedElements)
          },
          err => {
            console.error(err);
            if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
              this.router.navigate(['idbx-error']);
            } else if (typeof err?.target?.error == 'object') {
              SentryUtilites.setLog("ItemDao:getAllUnsynced", err?.target?.error)
            } else {
              SentryUtilites.setLog("ItemDao:getAllUnsynced", err)
            }
          });
        } else {
          return resolve([])
        }
      } catch (err) {
        SentryUtilites.setLog("ItemDao:getAllUnsynced", err)
        return resolve([])
      }
    })
  }
  updateSyncStamp(data: Item): Promise<Item> {
    return new Promise(async (resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?._localUUID) {
          this.ngxIndexedDBService
          .getByIndex(IItem.SCHEMA_NAME, '_localUUID', data._localUUID)
          .subscribe((dbEl: Item) => {
            if(dbEl) {
                dbEl.syncStamp = data?.syncStamp || 0;
                dbEl._serverIdRef = data?._serverIdRef;
                this.ngxIndexedDBService
                  .update(IItem.SCHEMA_NAME, dbEl)
                  .subscribe((updatedEl: Item) => {
                    return resolve(updatedEl);
                  },
                  err => {
                    console.error(err);
                    if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                      this.router.navigate(['idbx-error']);
                    } else if (typeof err?.target?.error == 'object') {
                      SentryUtilites.setLog("ItemDao:updateSyncStamp", err?.target?.error)
                    } else {
                      SentryUtilites.setLog("ItemDao:updateSyncStamp", err)
                    }
                  });
              } else {
                return resolve(null);
              }
            },
            err => {
              console.error(err);
              if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                this.router.navigate(['idbx-error']);
              } else if (typeof err?.target?.error == 'object') {
                SentryUtilites.setLog("ItemDao:updateSyncStamp", err?.target?.error)
              } else {
                SentryUtilites.setLog("ItemDao:updateSyncStamp", err)
              }
            })
        } else {
          return resolve(null)
        }
      } catch (err) {
        SentryUtilites.setLog("ItemDao:updateSyncStamp", err)
        return resolve(null)
      }
    })
  }

  updateStock(item: Item, quantity: number): Promise<Item> {
    return new Promise(async (resolve, reject) => {
      try {
        let itemUUID: string = item?._localUUID; 
        let dbItem = await this.getByUUID(itemUUID);
        if(dbItem) {
          dbItem.stock = (Number(dbItem?.stock) || 0) + Number(quantity);
          dbItem.updatedStamp = +new Date();
          dbItem.userUpdatedStamp = +new Date();
          dbItem.lastModifiedBy = item?.lastModifiedBy;
          dbItem.lastModifiedByName = item?.lastModifiedByName;
          let updatedItem = await this.update(dbItem);
          return resolve(updatedItem);
        } else {
          return resolve(null);
        }
      } catch (err) {
        SentryUtilites.setLog("ItemDao:updateStock", err)
        return resolve(null);
      }
    })

  }

  async addSamosaItem(profileId: string) {
    try {
      let item = new Item();
      item.itemName = 'Samosa';
      item.sellPrice = 15;
      item.profileId = profileId;
      item.spIncTax = true;
      item.type = 'product';
      return await this.save(item);
      
    } catch (error) {
      SentryUtilites.setLog("ItemDao:addSamosaItem", error)
      return null;
    }
  }

}
