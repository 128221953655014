import { Injectable } from '@angular/core';
import { BillItem } from '../models/BillItem.model';
import { Item } from '../models/Item.model';
import { Utility } from '../utils/utility';
import { SentryUtilites } from '../utils/sentryUtilites';

@Injectable({
  providedIn: 'root'
})
export class SearchFilterService {

  constructor() { }

  /**
   * 
   * @param inpSearchStr : provide input search value
   * @param completeList : provide complete list
   * @returns : return abbrivation search result
   */
  abbrivationSearch(inpSearchStr: string, completeList: any) {
    try {
      if(inpSearchStr?.length === 2 || inpSearchStr?.length === 3) {
        let arrWithOutExactMatch = completeList.filter((el: any) => {
          if(
            Utility.getAbbrivation(el?.itemName).startsWith(inpSearchStr) ||
            Utility.getAbbrivation(el?.item?.itemName).startsWith(inpSearchStr)
            ) {
            return el
          }
        })
  
        let arrWithExactMatch = arrWithOutExactMatch.filter((el: any) => {
          if(
            Utility.getAbbrivation(el?.itemName) === inpSearchStr ||
            Utility.getAbbrivation(el?.item?.itemName) === inpSearchStr
            ) {
            return el
          }
        })
    
        return [...arrWithExactMatch, ...arrWithOutExactMatch];
      }
      return [];
    } catch (error) {
      SentryUtilites.setLog("SearchFilterService:abbrivationSearch", error)
      return [];
    }
  }
  // -------------------------------------------------------

  /**
   * 
   * @param inpSearchStr : provide input search value
   * @param completeList : provide complete list
   * @returns : return start with match search result
   */
  startsWithSearch(inpSearchStr: string, completeList: any) {
    try {
      if(inpSearchStr) {
        return completeList.filter((el: any) => {
          if(
            Utility.toLowerCase(el?.itemName)?.startsWith(inpSearchStr) ||
            Utility.toLowerCase(el?.item?.itemName)?.startsWith(inpSearchStr)
          ) {
            return el
          }
        })
      }
      return [];
    } catch (error) {
      SentryUtilites.setLog("SearchFilterService:startsWithSearch", error)
      return [];
    }
  }
  // -------------------------------------------------------

  /**
   * 
   * @param inpSearchStr : provide input search value
   * @param completeList : provide complete list
   * @returns : return contain in search result
   */
  containsInSearch(inpSearchStr: string, completeList: any) {
    try {
      if(inpSearchStr) {
        return completeList.filter((el: any) => {
          let itemName = Utility.toLowerCase(el?.itemName) || Utility.toLowerCase(el?.item?.itemName);
          if(
            itemName?.includes(inpSearchStr) && itemName?.indexOf(inpSearchStr) > 0
          ) {
            return el
          }
        })
      }
      return [];
    } catch (error) {
      SentryUtilites.setLog("SearchFilterService:containsInSearch", error)
      return [];
    }
  }
  // -------------------------------------------------------

  /**
   * 
   * @param inpSearchStr : provide input search value
   * @param completeList : provide complete list
   * @returns : return barcode search result
   */
  barcodeSearch(inpSearchStr: string, completeList: any) {
    try {
      if(inpSearchStr) {
        return completeList.filter((el: any) => {
          if(
            Utility.toLowerCase(el?.barcode) == inpSearchStr
            || Utility.toLowerCase(el?.barcode2) == inpSearchStr
            || Utility.toLowerCase(el?.barcode3) == inpSearchStr
            || Utility.toLowerCase(el?.barcode4) == inpSearchStr
            || Utility.toLowerCase(el?.barcode5) == inpSearchStr
            || Utility.toLowerCase(el?.item?.barcode) == inpSearchStr
            || Utility.toLowerCase(el?.item?.barcode2) == inpSearchStr
            || Utility.toLowerCase(el?.item?.barcode3) == inpSearchStr
            || Utility.toLowerCase(el?.item?.barcode4) == inpSearchStr
            || Utility.toLowerCase(el?.item?.barcode5) == inpSearchStr
          ) {
            return el
          }
        })
      }
      return [];
    } catch (error) {
      SentryUtilites.setLog("SearchFilterService:barcodeSearch", error)
      return [];
    }
  }
  // -------------------------------------------------------

  /**
   * 
   * @param inpSearchStr : provide input search value
   * @param completeList : provide complete list
   * @returns : return item code search result
   */
  itemCodeSearch(inpSearchStr: string, completeList: any) {
    try {
      if(inpSearchStr) {
        return completeList.filter((el: any) => {
          if(
            Utility.toLowerCase(el?.itemCode) == inpSearchStr
            || Utility.toLowerCase(el?.item?.itemCode) == inpSearchStr
          ) {
            return el
          }
        })
      }
      return [];
    } catch (error) {
      SentryUtilites.setLog("SearchFilterService:itemCodeSearch", error)
      return [];
    }
  }
  // -------------------------------------------------------

  /**
   * 
   * @param finalArray : provide final array
   * @returns : add favorite item at top of array
   */
  sortByFavItem(finalArray: Item[] | BillItem[]) {
    try {
      let favItemArr = [];
      let otherItemArr = [];
      finalArray?.forEach(el => {
        let item = el?.item || el;
        item?.isFavourite ? favItemArr?.push(el) : otherItemArr?.push(el)
        })
      finalArray = [
        ...favItemArr,
        ...otherItemArr,
      ]
      return finalArray;
    } catch (error) {
      SentryUtilites.setLog("SearchFilterService:sortByFavItem", error)
      return [];
    }
  }
  // -------------------------------------------------------

}
