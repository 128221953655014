import { SentryUtilites } from "./sentryUtilites";

export class Utils {

  static roundAmount(amount: any, decimalDigits: number): number {
    try {
      let str: string = amount?.toString();
  
      if (str === '' || str === '.' || str === 'null') {
        str = '0';
      }
  
      if (str?.includes('.')) {
        const beforeDecimal = str?.split('.')[0];
        let afterDecimal = str?.split('.')[1];
        if (afterDecimal.length > decimalDigits) {
          afterDecimal = afterDecimal?.substring(0, decimalDigits);
        }
        if (afterDecimal === '0') {
          return Number(beforeDecimal);
        } else {
          return Number(`${beforeDecimal}.${afterDecimal}`);
        }
      } else {
        return Number(str);
      }
    } catch (error) {
      SentryUtilites.setLog("Utils:roundAmount", error)
      return 0;
    }
  }

  static roundString(amount: any, decimalDigits: number): string {
    try {
      const str: string = amount?.toString();
  
      if (str?.includes('.')) {
        const beforeDecimal = str?.split('.')[0];
        let afterDecimal = str?.split('.')[1];
        if (afterDecimal.length > decimalDigits) {
          afterDecimal = afterDecimal?.substring(0, decimalDigits);
        }
  
        return (afterDecimal === '0' || afterDecimal === '00') ? beforeDecimal : `${beforeDecimal}.${afterDecimal}`;
      } else {
        return str;
      }
    } catch (error) {
      SentryUtilites.setLog("Utils:roundString", error)
      return '';
    }
  }

  static chooseSellPrice(priceSecondary?: number, pricePrimary?: number): number {
    try {
      if (priceSecondary != null && priceSecondary > 0.0) {
        return priceSecondary;
      } else {
        return pricePrimary || 0.0;
      }
    } catch (error) {
      SentryUtilites.setLog("Utils:chooseSellPrice", error)
      return 0;
    }
  }

  static capFractionsToSix(fractionNumber){
    try {
      if(Number(fractionNumber)>1){
        return Math.round((this.capFractionsToFour(fractionNumber))*1000000)/1000000;
      }
      return Math.round(fractionNumber*1000000)/1000000;
    } catch (error) {
      SentryUtilites.setLog("Utils:capFractionsToSix", error)
      return 0;
    }
  }

  private static capFractionsToFour(fractionNumber){
    return Math.round((Number(fractionNumber)||0)*10000)/10000;
  }

  static capFractionsToTwo(fractionNumber){
    return Math.round((Number(fractionNumber)||0)*100)/100;
  }

}

