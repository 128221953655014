import { Component, OnInit } from '@angular/core';
import { InfiniteScrollCustomEvent, LoadingController, ToastController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { IngredientService } from './../../services/ingredient.service';
import { IngredientStockAdjustService } from './../../services/ingredient-stock-adjust.service';
import { ActionType, IngredientStockAdjust } from '../../models/IngredientStockAdjust.model';
import { Utility } from '../../utils/utility';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-raw-material-purchase-bulk-edit',
  templateUrl: './raw-material-purchase-bulk-edit.page.html',
  styleUrls: ['./raw-material-purchase-bulk-edit.page.scss'],
})
export class RawMaterialPurchaseBulkEditPage implements OnInit {

  // Utility
  getHeaderColorClass = Utility.getHeaderColorClass;
  returnZero = Utility.returnZero;
  // ---------------------------------------------------

  // Globle variables
  completeList: IngredientStockAdjust[] = [];
  viewFilteredList: IngredientStockAdjust[] = [];

  editedRawMaterial: IngredientStockAdjust[] = [];

  purchaseFieldTitles: {
    [key:string]:string
  } = {
    'col-date-time':'Date & Time',
    'col-quantity':'Quantity',
    'col-price':'Price (in ₹)',
    'col-created-edited': 'Created / Edited by'
  };

  paramDocumentId: string = null;
  rawMaterialName: string = '';
  loading: HTMLIonLoadingElement = null;
  showContent: boolean = false;
  // ---------------------------------------------------

  constructor(
    private route: ActivatedRoute,
    private ingredientStockAdjustService: IngredientStockAdjustService,
    private ingredientService: IngredientService,
    private toastController: ToastController,
    private router: Router,
    private loadingCtrl: LoadingController,
  ) { }

  /**
   * @description : is a lifecycle hook that is called after Angular has 
   * initialized all data-bound properties of a directive.
   */
  async ngOnInit() {
    try {
      await this.showLoading('Loading Raw Material Purchase list...', 'raw-material-loader');
      this.getParamDocumentId();
      this.loadView();
    } catch (error) {
      SentryUtilites.setLog("RawMaterialPurchaseBulkEditPage:ngOnInit", error)
    }
  }
  // ---------------------------------------------------

  /**
   * @description : get param data of variable documentId
   */
  getParamDocumentId() {
    this.paramDocumentId = this.route.snapshot.paramMap.get('documentId');
  }
  // ---------------------------------------------------
 
  /**
   * @description : get data from server side and store data in viewFilteredList variable
   */
  async loadView() {
    try {
      this.completeList = [];
      this.viewFilteredList = [];
  
      let selectedRawMaterial;
      let getAllRawMaterial;
      try {
        selectedRawMaterial = await this.ingredientService.getByUUID(this.paramDocumentId);   
        getAllRawMaterial = await this.ingredientStockAdjustService.getAll();
        this.loading?.dismiss();
        this.showContent = true;
      } catch (error) {
        this.presentToast('Fail to load data from server.', 'danger');
        this.loading?.dismiss();
      }
      this.purchaseFieldTitles['col-quantity'] = `${this.purchaseFieldTitles['col-quantity']} (in ${selectedRawMaterial.unit})`;
      this.rawMaterialName = selectedRawMaterial?.name;
      let date = +new Date();
      let startStamp = new Date(date - 86400000 * 30).setHours(0,0,0,0);
      let endStamp = new Date().setHours(23,59,59,59);
      this.completeList = getAllRawMaterial.filter(ingredient => 
        ingredient?.actionType === ActionType.Purchase 
        && ingredient?.linkedIngredientUUID === this.paramDocumentId
        && ingredient?.createdStamp  > startStamp 
        && ingredient?.createdStamp <= endStamp);
      this.completeList.sort((a, b) => b?.createdStamp - a?.createdStamp);
      this.viewFilteredList = this.completeList.slice(0,51);
    } catch (error) {
      SentryUtilites.setLog("RawMaterialPurchaseBulkEditPage:loadView", error)
    }
  }
  // ---------------------------------------------------

  /**
   * 
   * @param _localUUID : ingredient stock adjust _localUUID
   * @param key : ingredient stock adjust key value update
   * @param value : ingredient stock adjust input value
   * @returns : nothing
   * @description : store or update edited purchase ingredient quantity, price
   */
  onRawMaterialEdit(_localUUID: string, key: string, value: any) {
    try {
      let priceArr = ["quantity", "price"];
      if(this.editedRawMaterial?.length) {
        let index = this.editedRawMaterial.findIndex(ingredient => ingredient?._localUUID===_localUUID);
        if(index != -1) {
          if(priceArr?.includes(key)) {
            this.editedRawMaterial[index][key] = Math.abs(value);
          }else {
            this.editedRawMaterial[index][key] = value;
          }
          this.checkPriceQuantityValue(this.editedRawMaterial[index]);
          return;
        }
      }
      let index = this.viewFilteredList.findIndex(ingredient => ingredient?._localUUID===_localUUID);
      if(index != -1) {
        let ingredient = this.viewFilteredList[index];
        if(priceArr?.includes(key)) {
          ingredient[key] = Math.abs(value);
        }else {
          ingredient[key] = value;
        }
        this.checkPriceQuantityValue(ingredient);
        this.editedRawMaterial.push(ingredient);
      }
    } catch (error) {
      SentryUtilites.setLog("RawMaterialPurchaseBulkEditPage:onRawMaterialEdit", error)
    }
  }
  // ---------------------------------------------------

  /**
   * 
   * @param ingredient : IngredientStockAdjust object
   * @description: check quantity, price value exits or not
   */
  checkPriceQuantityValue(ingredient: IngredientStockAdjust) {
    try {
      if(
        !ingredient?.quantity
        || !ingredient?.price
      ) {
        this.presentToast(`Raw Material quantity & price are mandatory. Please provide valid details to update.`, 'danger');
      }
    } catch (error) {
      SentryUtilites.setLog("RawMaterialPurchaseBulkEditPage:checkPriceQuantityValue", error)
    }
  }
  // ---------------------------------------------------

  /**
   * 
   * @param header : text msg
   * @param color : provide color ionic default color
   * @description : show toast msg at top right corner
   */
  async presentToast(header: string, color: string) {
    try {
      const toast = await this.toastController.create({
        header,
        duration: 2000,
        position: 'top',
        mode: 'ios',
        color: color,
      });
      await toast.present();
    } catch (error) {
      SentryUtilites.setLog("RawMaterialPurchaseBulkEditPage:presentToast", error)
    }
  }
  // ---------------------------------------------------

  /**
   * 
   * @param msg : provide loading msg
   * @param css : provide custom css class
   * @description : use to show loading
   */
  async showLoading(msg: string, css: string) {
    try {
      this.loading = await this.loadingCtrl.create({ 
        message: msg,
        cssClass: css
      });
      this.loading?.present();
    } catch (error) {
      SentryUtilites.setLog("RawMaterialPurchaseBulkEditPage:showLoading", error)
    }
  }
  // ---------------------------------------------------

  /**
   * 
   * @returns : nothing
   * @description : updated edited ingredient stock adjust purchase material value at server side
   */
  async updateEditedRawMaterial() {
    try {
      this.editedRawMaterial?.forEach(rawMaterial => {
        let index = this.completeList?.findIndex(x => x?._localUUID===rawMaterial?._localUUID);
        if(index != -1) {
          this.completeList.splice(index,1,rawMaterial);
        }
      });
  
      let invalidEditedRawMaterial = this.editedRawMaterial.filter(rawMaterial => !rawMaterial?.price || !rawMaterial?.quantity);
      let invalidCompleteRawMaterials = this.completeList.filter(rawMaterial => !rawMaterial?.price || !rawMaterial?.quantity);
      if(invalidEditedRawMaterial?.length || invalidCompleteRawMaterials?.length) {
        this.presentToast(`Raw Material quantity & price are mandatory for all Items. Please provide valid details to update.`, 'danger');
        return null;
      }
  
      let updatedIngredient: IngredientStockAdjust[] = await this.ingredientStockAdjustService.saveAll(this.editedRawMaterial);
      if(updatedIngredient?.length) {
        this.presentToast(`Raw Material quantity & price updated successfully`, 'success');
        this.router.navigate([`ingredient/transaction/${this.paramDocumentId}`]);
      }
    } catch (error) {
      SentryUtilites.setLog("RawMaterialPurchaseBulkEditPage:OnResize", error)
    }
  }
  // ---------------------------------------------------

  /**
   * 
   * @param event : InfiniteScrollCustomEvent
   * @description : load list more 50 as you scroll upto last element in viewFilteredList array
   */
  loadMoreListData(event) {
    try {
      if (this.viewFilteredList.length > 0 && this.viewFilteredList.length <= this.completeList.length) {
        let appendListLength = this.completeList.length - this.viewFilteredList.length;
        let lastEl = this.viewFilteredList[this.viewFilteredList.length - 1];
        let counter = 0;
        for (let i = 0; i < this.completeList.length; i++) {
          if (this.completeList[i]._localUUID == lastEl._localUUID) {
            counter = 1;
            continue;
          }
          if (counter > 0 && appendListLength >= 50) {
            if (counter <= 50) {
              this.viewFilteredList.push({ ...this.completeList[i] })
            } else {
              break;
            }
            counter++;
          } else if(counter > 0 && appendListLength < 50) {
            if (counter <= appendListLength) {
              this.viewFilteredList.push({ ...this.completeList[i] })
            } else {
              break;
            }
            counter++;
          }
        }
        (event as InfiniteScrollCustomEvent).target.complete();
      } 
    } catch (error) {
      SentryUtilites.setLog("RawMaterialPurchaseBulkEditPage:loadMoreListData", error)
    }
  }
  // ---------------------------------------------------

}
