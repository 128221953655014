import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Purchase } from '../../models/Purchase.model';
import { MoneyOut } from '../../models/MoneyOut.model';
import { PartySelectorComponent } from '../party-selector/party-selector.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AllDataService } from '../../services/all-data.service';
import { Validator } from '../../utils/validator';
import Party from '../../models/Party.model';
import { Utility } from '../../utils/utility';
import { Expense } from '../../models/Expense.model';
import { Utils } from '../../utils/utils';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { IonInput } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';
import { SaleReturn } from '../../models/SaleReturn.model';

@Component({
  selector: 'app-money-out-form',
  templateUrl: './money-out-form.component.html',
  styleUrls: ['./money-out-form.component.scss'],
})
export class MoneyOutFormComponent implements OnInit {

  @Input() purchase: Purchase = null;
  @Input() expense: Expense = null;
  @Input() saleReturn: SaleReturn = null;
  @Input() billTotalAmount: number = null;
  @Input() moneyOutCart: MoneyOut[] = [];
  @Input() paramDocumentId: string = null;

  @Output() savedMoneyOut = new EventEmitter<MoneyOut>();
  @Output() updatedMoneyOut = new EventEmitter<MoneyOut>();

  @ViewChild('partySelector') partySelector: PartySelectorComponent;
  @ViewChild('saveButton', {read: ElementRef}) saveButton: ElementRef;
  @ViewChild('inpTotalAmountEle') inpTotalAmountEle: IonInput;

  maxDate = Utility.maxDateUpto50Year();
  setDateValue = Utility.setDateValue;

  capFractionsToTwo = Utils.capFractionsToTwo;

  todayDate: number = +new Date().setHours(0, 0, 0, 0);

  isOpenBillDatePicker: boolean = false;

  keepContentsMounted: boolean = false;

  dpBillDateValue: string = null;

  form: FormGroup;
  fetchedMoneyOut: MoneyOut = null;
  saveLoader = false;

  subArr: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private allDataService: AllDataService,
  ) { }

  ngOnInit() {
    this.initializeReactiveForm();
    this.populateForm();
  }

  ngAfterViewInit() { }

  ngOnChanges(changes: SimpleChanges) {
    try {
      if (changes?.paramDocumentId?.currentValue != changes?.paramDocumentId?.previousValue) {
        this.populateForm();
      }
      if (changes?.purchase?.currentValue != changes?.purchase?.previousValue) {
        setTimeout(() => {
          this.populateParty();
        }, 500);
      }
      if (changes?.expense?.currentValue != changes?.expense?.previousValue) {
        setTimeout(() => {
          this.populateParty();
        }, 500);
      }
      if (changes?.saleReturn?.currentValue != changes?.saleReturn?.previousValue) {
        setTimeout(() => {
          this.populateParty();
        }, 500);
      }
    } catch (error) {
      SentryUtilites.setLog("MoneyOutFormComponent:ngOnChanges", error)
    }
  }

  ngDestory() { 
    try {
      this.keepContentsMounted = false;
      this.subArr?.forEach(sub => sub?.unsubscribe());
    } catch (error) {
      SentryUtilites.setLog("MoneyOutFormComponent:ngDestory", error)
    }
  }

  /*
    Reactive Form Initialization
  */

  initializeReactiveForm() {
    try {
      this.form = this.formBuilder.group({
        billNo: [null, Validators.required],
        billDateStamp: [+new Date().setHours(0, 0, 0, 0), Validators.required],
        party: [null, Validators.required],
        totalAmount: [null, [Validators.required, Validators.pattern(Validator.positiveNumber)]],
        paymentMode: ['cash'],
        paymentId: [null],
      });
      this.form.patchValue({totalAmount: this.maxAllowedMoneyOutInAmount()});
      this.subArr.push(this.form.get("totalAmount").valueChanges.pipe(distinctUntilChanged(), debounceTime(300)).subscribe(totalAmount => {
        if(totalAmount != null && totalAmount < 0) {
          this.form.patchValue({totalAmount: Math.abs(totalAmount===0 ? 1 : totalAmount)});
        } else {
          this.form.patchValue({totalAmount: totalAmount !== null ? Utils.capFractionsToTwo(totalAmount) : null});
        }
      }));
    } catch (error) {
      SentryUtilites.setLog("MoneyOutFormComponent:initializeReactiveForm", error)
    }
  }

  async populateForm() {
    try {
      if (this.paramDocumentId) {
        this.fetchedMoneyOut = await this.allDataService.moneyOutService.getByUUID(this.paramDocumentId);
        this.dpBillDateValue = Utility.ionDatePickerFormattedString(this.fetchedMoneyOut?.billDateStamp);
        this.form.patchValue(this.fetchedMoneyOut);
        this.keepContentsMounted = true;
        this.partySelector.setSelectedParty(this.fetchedMoneyOut?.party);
      } else {
        this.populateBillNumber();
      }
      this.populateParty();
    } catch (error) {
      SentryUtilites.setLog("MoneyOutFormComponent:populateForm", error)
    }
  }

  populateParty() {
    try {
      let party = this.purchase?.party || this.expense?.party || this.saleReturn?.party;
      if (party ) {
        this.partySelector?.setSelectedParty(party);
        this.partySelector?.disable();
        this.form?.controls?.party?.setValue(party);
      }else if(this.paramDocumentId) {
        this.partySelector?.setSelectedParty(this.fetchedMoneyOut?.party);
        this.partySelector?.disable();
        this.form?.controls?.party?.setValue(this.fetchedMoneyOut?.party);
      }
    } catch (error) {
      SentryUtilites.setLog("MoneyOutFormComponent:populateParty", error)
    }
  }

  onPartySelect(party: Party) {
    try {
      if (party?._localUUID) {
        this.form.patchValue({
          party: party
        });
      }
    } catch (error) {
      SentryUtilites.setLog("MoneyOutFormComponent:onPartySelect", error)
    }
  }

  /**
   * 
   * @param event : date event
   * @description : on bill date select assign date value
   */
  onBillDateSelect(event) {
    try {
      if(event?.detail?.value) {
        let timeStamp = +new Date(event?.detail?.value).setHours(0, 0, 0, 0);
        this.form?.controls?.billDateStamp?.setValue(timeStamp);
        this.dpBillDateValue = Utility.ionDatePickerFormattedString(timeStamp);
        Utility.closeDatePicker(event?.target);
      }
    } catch (error) {
      SentryUtilites.setLog("MoneyOutFormComponent:onBillDateSelect", error)
    }
  }
  // -------------------------------

  /*
    Bill Number
  */

  async populateBillNumber() {
    try {
      this.form.patchValue({
        billNo: await this.allDataService.moneyOutService.getNewMoneyOutNo()
      });
    } catch (error) {
      SentryUtilites.setLog("MoneyOutFormComponent:populateBillNumber", error)
    }
  }

  //----------------------------------------------------------------------------

  getMonayOutCartTotal(moneyOutCart: MoneyOut[]) {
    try {
      let totalAmount: number = 0;
      moneyOutCart?.forEach(moneyIn => totalAmount += Number(moneyIn?.totalAmount));
      return totalAmount;
    } catch (error) {
      SentryUtilites.setLog("MoneyOutFormComponent:getMonayOutCartTotal", error)
      return 0;
    }
  }

  maxAllowedMoneyOutInAmount() {
    try {
      let totalAmount: number = 0;
      this.moneyOutCart?.forEach(moneyIn => totalAmount += Number(moneyIn?.totalAmount));
      return this.capFractionsToTwo(this.billTotalAmount - (totalAmount || 0.0));
    } catch (error) {
      SentryUtilites.setLog("MoneyOutFormComponent:maxAllowedMoneyOutInAmount", error)
      return 0;
    }
  }

  onSaveClick() {
    try {
      if(this.saveLoader) {
        return false;
      }
      if (this.paramDocumentId) {
        this.update();
      } else {
        this.save();
      }
    } catch (error) {
      SentryUtilites.setLog("MoneyOutFormComponent:onSaveClick", error)
    }
  }

  async save() {
    try {
      if (this.form.valid) {
        this.saveLoader = true;
        let moneyOut = new MoneyOut();
        moneyOut = { ...this.form.value };
  
        let savedMoneyOut:MoneyOut = null;
  
        if(this.purchase?._localUUID) {
          moneyOut.linkedPurchaseUUID = this.purchase?._localUUID;
          moneyOut._localUUID = Utility.getUUID();
          savedMoneyOut = await this.allDataService.moneyOutService.save(moneyOut);
        }else if(this.expense?._localUUID) {
          moneyOut.linkedExpenseUUID = this.expense?._localUUID;
          moneyOut._localUUID = Utility.getUUID();
          savedMoneyOut = await this.allDataService.moneyOutService.save(moneyOut);
        }else if(this.saleReturn?._localUUID) {
          moneyOut.linkedSaleReturnUUID = this.saleReturn?._localUUID;
          moneyOut._localUUID = Utility.getUUID();
          savedMoneyOut = await this.allDataService.moneyOutService.save(moneyOut);
        }else {
          savedMoneyOut = await this.allDataService.moneyOutService.save(moneyOut);
        }
        if (savedMoneyOut?._localUUID) {
          this.allDataService.partyService.reloadList();
          this.allDataService.expenseService.reloadList();
          this.allDataService.purchaseService.reloadList();
          this.allDataService.moneyOutService.reloadList();
          this.allDataService.saleReturnService.reloadList();
          this.savedMoneyOut.emit(savedMoneyOut);
          await Utility.wait(2000);
          this.allDataService.listForceReloadSubs.next('moneyout-list');
          this.allDataService.listForceReloadSubs.next('purchase-list');
          this.allDataService.listForceReloadSubs.next('expense-list');
          this.allDataService.listForceReloadSubs.next('sale-return-list');
          this.allDataService.listForceReloadSubs.next('party-list');
          this.saveLoader = false;
        }
      }
    } catch (error) {
      SentryUtilites.setLog("MoneyOutFormComponent:save", error)
    }
  }

  async update() {
    try {
      if (this.form.valid) {
        this.saveLoader = true;
        let updatedMoneyOut = {
          ...this.fetchedMoneyOut,
          ...this.form.value
        }
        updatedMoneyOut = await this.allDataService.moneyOutService.update(updatedMoneyOut);
        if (updatedMoneyOut?._localUUID) {
          this.allDataService.partyService.reloadList();
          this.allDataService.expenseService.reloadList();
          this.allDataService.purchaseService.reloadList();
          this.allDataService.moneyOutService.reloadList();
          this.allDataService.saleReturnService.reloadList();
          this.allDataService.listForceReloadSubs.next('moneyout-list');
          this.updatedMoneyOut.emit(updatedMoneyOut);
          await Utility.wait(2000);
          this.saveLoader = false;
        }
      }
    } catch (error) {
      SentryUtilites.setLog("MoneyOutFormComponent:update", error)
    }
  }

  onMoneyOutDateSelect(event) {
    try {
      this.form?.controls?.billDateStamp?.setValue(
        +new Date(event?.detail?.value).setHours(0, 0, 0, 0)
      );
      Utility.closeDatePicker(event?.target);
    } catch (error) {
      SentryUtilites.setLog("MoneyOutFormComponent:onMoneyOutDateSelect", error)
    }
  }

  /**
   * @description : close date picker
   */
  closeDOBPicker() {
    this.isOpenBillDatePicker = false;
  }
  // -------------------------------

}
