import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Utility } from '../../utils/utility';
import { AllDataService } from '../../services/all-data.service';
import { AuthService } from '../../services/auth/auth.service';
import { HtmlTemplates } from '@makarandkate/invoice-templates/lib';
import { Sale } from '../../models/Sale.model';
import { DeleteDataComponent } from '../../components/delete-data/delete-data.component';
import Party from '../../models/Party.model';
import { SettingsComponent } from '../../components/settings/settings.component';
import { PinVerificationComponent } from '../../components/pin-verification/pin-verification.component';
import { MoneyIn } from '../../models/MoneyIn.model';
import { AccessControlService } from '../../services/auth/access-control.service';
import { Profile } from '../../models/Profile.model';
import { SaleBillPrint } from '@makarandkate/invoice-templates/lib/models/SaleBillPrint.model';
import { BaseBillView } from 'src/app/base/base-bill-view';
import { PremiumControlService } from '../../services/auth/premium-control.service';
import { AlertController } from '@ionic/angular';
import { ImageBase64Service } from '../../services/image-base64.service';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
    selector: 'app-bill-view',
    templateUrl: './bill-view.page.html',
    styleUrls: ['./bill-view.page.scss'],
})
export class BillViewPage extends BaseBillView<Sale, SaleBillPrint, MoneyIn> implements OnInit {

    @ViewChild('pinVerificationElement') pinVerificationElement: PinVerificationComponent;
    @ViewChild('settingsModal') settingsModal: SettingsComponent;

    @ViewChild('deleteDataEle') deleteDataEle: DeleteDataComponent;
    @ViewChild('div', { static: true }) div: any;


    selectedTemplate: 'temp1' | 'temp17' | 'temp18' | 'temp19' | 'temp20' = Utility.getFromLocalStorage('selectedTemplate') || 'temp18';
    billPrint = new SaleBillPrint();
    record: Sale = null;
    billDownloadEndpoint: string = 'https://db.ezobooks.in/kappa/billView/sale';

    constructor(
        private router: Router,
        private accessControlService: AccessControlService,
        private alertController: AlertController,
        allDataService: AllDataService,
        authService: AuthService,
        route: ActivatedRoute,
        premiumControlService: PremiumControlService,
        imageBase64Service: ImageBase64Service,
    ) {
        super(
            route,
            allDataService,
            authService,
            premiumControlService,
            imageBase64Service,
        );
    }

    ngOnInit = async () => {
        this.baseNgOnInit();
    }

    async ionViewWillEnter() {
        this.selectedTemplate = Utility.getFromLocalStorage('selectedTemplate') || 'temp18';
        this.baseIonViewWillEnter();
    }

    ionViewWillLeave() {
        this.ngOnDestroy();
    }

    ngOnDestroy() {
        this.baseNgOnDestroy();
    }

    openTransactionPINModal() {
        this.pinVerificationElement?.openTransactionPINModal();
    }

    verifyTransactionPIN(event) {
        try {
            if (event) {
                this.router.navigate([`sale/form/${this.record?._localUUID}`]);
            }
        } catch (error) {
            SentryUtilites.setLog("BillViewPage:verifyTransactionPIN", error)
        }
    }

    setTemplate(event) {
        try {
            this.selectedTemplate = event?.detail?.value;
            Utility.setToLocalStorage('selectedTemplate', this.selectedTemplate);
            this.populateTemplate();
        } catch (error) {
            SentryUtilites.setLog("BillViewPage:setTemplate", error)
        }
    }

    async edit() {
        try {
            let isPermit = await this.accessControlService.isUserHasAccess({ action: 'editSale' });
            if (!isPermit) {
                return alert("Permission: You don't have permission to edit sale. Please contact to your owner.");
            }
            this.openTransactionPINModal();
        } catch (error) {
            SentryUtilites.setLog("BillViewPage:edit", error)
            alert("Something went wrong.");
        }
    }

    async delete() {
        try {
            let isPermit = await this.accessControlService.isUserHasAccess({ action: 'deleteSale' });
            if (!isPermit) {
                return alert("Permission: You don't have permission to delete sale. Please contact to your owner.");
            }
            this.deleteDataEle?.initDeleteSale(this.record);
        } catch (error) {
            SentryUtilites.setLog("BillViewPage:delete", error)
            alert("Something went wrong.");
        }
    }

    async navigateToBill() {
        try {
            let checkCreateSale = await this.premiumControlService.checkCreateSale();
            if(checkCreateSale) {
                let isPermit = await this.accessControlService.isUserHasAccess({ action: 'createSale' });
                if (!isPermit) {
                    return alert("Permission: You don't have permission to create new sale. Please contact to your owner.");
                }
                this.router.navigate([`sale/form`]);
            }
        } catch (error) {
            SentryUtilites.setLog("BillViewPage:navigateToBill", error)
            alert("Something went wrong.");
        }
    }

    openTNCSetting(): void {
        try {
            if (this.settingsModal) {
                this.settingsModal.openSettingsModal();
                this.settingsModal.viewSectionIds = [5];
                this.settingsModal.viewSettingKeys = ['pSetTermsAndConditions'];
            }
        } catch (error) {
            SentryUtilites.setLog("BillViewPage:openTNCSetting", error)
        }
    }

    async setTermsAndConditions(profile: Profile) {
        // if (profile?.iSetPrintMBMA !== false) {
        //     profile.pSetTermsAndConditions = (profile?.pSetTermsAndConditions || '') + `
        //     <b>Mera Bill Mera Adhikar</b>
        //     Win 1 Crore with this Bill
        //     1. Download Mera Bill Mera Adhikar app
        //     2. Register your self
        //     3. Add photo of this bill
        //     4. Lucky Customer will get upto 1 crore from government
        //     5. Lucky Shopkeeper will get upto 1 Crore from EZO.
        //     `;
        // }
    }

    async setRecord(paramDocumentId: string): Promise<Sale> {
        try {
            this.record = await this.allDataService.saleService.getByUUID(paramDocumentId);
            if(this.record?.deletedStamp) {
                const alert = await this.alertController.create({
                    header: 'Alert!',
                    message: `Bill Deleted by ${this.record?.lastModifiedByName || this.record?.lastModifiedBy} on ${Utility.setDateValue(this.record?.deletedStamp)}`,
                    mode: 'ios',
                    buttons: [
                      {
                        text: 'Go Back',
                        role: 'cancel',
                        handler: () => {
                            this.router.navigate(['sale']);
                            return null
                         },
                      },
                    ],
                });
                await alert.present();
            } else {
                return this.record;
            }
        } catch (error) {
            SentryUtilites.setLog("BillViewPage:setRecord", error)
            return this.record;
        }
    }

    async getMoneyInOut(): Promise<MoneyIn> {
        try {
            if (this.record?.moneyIns?.length && this.record?.moneyIns[0]?._localUUID) {
                return await this.allDataService.moneyInService.getByUUID(this.record?.moneyIns[0]?._localUUID);
            }
            return null;
        } catch (error) {
            SentryUtilites.setLog("BillViewPage:getMoneyInOut", error)
            return null;
        }
    }

    setBillPrint(
        tokenDetails: any,
        profile: Profile,
        signature: any,
        logo: any,
        party: Party,
        secondaryParty: Party,
    ): void {
        try {
            this.billPrint = HtmlTemplates.generateSaleBillObject({
                user: {
                    isPro: tokenDetails?.isPro,
                    registrationStamp: tokenDetails?.createdStamp
                },
                profile,
                signature,
                logo,
                party,
                secondaryParty,
                sale: this.record,
                moneyIn: this.moneyInOut,
                isPartyDeleted: this.isPartyDeleted,
            });
        } catch (error) {
            SentryUtilites.setLog("BillViewPage:setBillPrint", error)
        }
    }

    setTemplateToHtmlDiv(htmlString: any): void {
        try {
            this.div.nativeElement.innerHTML = htmlString;
        } catch (error) {
            SentryUtilites.setLog("BillViewPage:setTemplateToHtmlDiv", error)
        }
    }

}
