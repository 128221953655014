<ion-modal [isOpen]="isModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar color="primary">
        <ion-title>Ingredient Stock Adjustment</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="openStockAdjustModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <ion-grid>

        <ion-row class="row-item-info">
          <ion-col size="12">
            <ion-button
              fill="clear"
              class="btn-item-name btn-txt-black padding-start-none"
            >
              Ingredient Name: {{ ingredient?.name }}
            </ion-button>
            <ion-button
              fill="clear"
              class="btn-item-name btn-txt-black padding-start-none"
            >
              Category: {{ ingredient?.category }}
            </ion-button>
            <ion-button
              fill="clear"
              class="btn-item-name btn-txt-black padding-start-none"
            >
              Current Stock: {{ ingredient?.stock }}
            </ion-button>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col size="6">
            <ion-item lines="none">
              <ion-label>{{ addReduceOperation }} Quantity: </ion-label>
              <ion-input type="number" [min]="1" (ionInput)="setQuantity($event)"></ion-input>
            </ion-item>
          </ion-col>
          <ion-col size="6">
            <ion-item lines="none">
              <ion-segment mode="ios" [value]="addReduceOperation" (ionSelect)="onAddReduceOperationSelect($event)">
                <ion-segment-button value="Add">
                  <ion-label>Add</ion-label>
                </ion-segment-button>
                <ion-segment-button value="Reduce">
                  <ion-label>Reduce</ion-label>
                </ion-segment-button>
              </ion-segment>
            </ion-item>
          </ion-col>
          <ion-col size="12">
            <ion-item lines="none">
              <ion-label>Note: </ion-label>
              <ion-textarea rows="3" (ionInput)="setNote($event)"></ion-textarea>
            </ion-item>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col size="12">
            <ion-button
              class="btn-save"
              expand="full"
              [color]="addReduceOperation==='Add' ? 'success' : 'danger'"
              [disabled]="!quantity || isDisableSave"
              (click)="save()"
            >
              {{ addReduceOperation }}
            </ion-button>
          </ion-col>
        </ion-row>

      </ion-grid>
    </ion-content>
  </ng-template>
</ion-modal>
