import { MonthWiseItemStockService } from './../../services/month-wise-item-stock.service';
import { MonthWisePartyCreditService } from './../../services/month-wise-party-credit.service';
import { Subscription } from 'rxjs';
import { Utility } from './../../utils/utility';
import { Sale } from './../../models/Sale.model';
import { AllDataService } from './../../services/all-data.service';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Purchase } from '../../models/Purchase.model';
import { MoneyIn } from '../../models/MoneyIn.model';
import { MoneyOut } from '../../models/MoneyOut.model';
import { Item } from '../../models/Item.model';
import Party from '../../models/Party.model';
import { BillItem } from '../../models/BillItem.model';
import { Profile } from '../../models/Profile.model';
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';
import { ActivatedRoute } from '@angular/router';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { AccessControlService } from '../../services/auth/access-control.service';
import { AuthService } from '../../services/auth/auth.service';
import { Ingredient } from '../../models/Ingredient.model';
import { IngredientService } from '../../services/ingredient.service';
import { Utils } from '../../utils/utils';
import { IngredientStockAdjustService } from '../../services/ingredient-stock-adjust.service';
import { Expense } from '../../models/Expense.model';
import { Fonts } from '../../utils/fonts';
import { ActionType, IngredientStockAdjust } from '../../models/IngredientStockAdjust.model';
import { InfiniteScrollCustomEvent, LoadingController } from '@ionic/angular';
import { ImageBase64Service } from '../../services/image-base64.service';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

type RawMaterialIngredient = {
    [key:string]: {
        transferSum: {
            [key: string]: {
                actualValue: number;
                displayValue: string;
            }
        };
        returnSum: {
            [key: string]: {
                actualValue: number;
                displayValue: string;
            }
        };
        scrapSum: {
            [key: string]: {
                actualValue: number;
                displayValue: string;
            }
        };
        purchaseSum: {
            [key: string]: {
                actualValue: number;
                displayValue: string;
            }
        };
        openingStock: {
            [key: string]: {
                actualValue: number;
                displayValue: string;
            }
        };
        currentDayEODStock: {
            [key: string]: {
                actualValue: number;
                displayValue: string;
            }
        };
        totalSalesConsumption: {
            [key: string]: {
                actualValue: number;
                displayValue: string;
            }
        };
        totalActualConsumption: {
            [key: string]: {
                actualValue: number;
                displayValue: string;
            }
        };
        totalConsumptionDifference: {
            [key: string]: {
                actualValue: number;
                displayValue: string;
            };
        };
    }
};

@Component({
    selector: 'app-reports',
    templateUrl: './reports.page.html',
    styleUrls: ['./reports.page.scss'],
})
export class ReportsPage implements OnInit, AfterViewInit {

    viewFilteredList: any = [];
    filteredList: any = [];

    getHeaderColorClass = Utility.getHeaderColorClass;
    capFractionsToSix = (value) => Utils.capFractionsToSix(value);
    capFractionsToTwo = (value) => Utils.capFractionsToTwo(value);
    MATHABS = Math.abs;
    stampTo12HrFormatDateTime = Utility.stampTo12HrFormatDateTime;
    dateToDDMMYYY = Utility.dateToDDMMYYY;
    returnNeg1 = () => -1;
    returnZero = Utility?.returnZero;
    isObject = (val) => typeof val === 'object';
    shortStr = Utility.shortStr;

    selectedDateRange: 'today' | '7days' | '30days' | 'month' | 'year' | 'custom' = 'year';
    startStamp: number = null;
    endStamp: number = null;

    isDateRangeModalOpen = false;
    isCutOffDayDatesModalOpen = false;

    isPartyListModalOpen = false;
    isItemListModalOpen = false;

    isSaleReportModalOpen = false;
    isStaffWiseSaleReportModal = false;
    isStaffWiseSaleReportDetailModalOpen = false;
    isSaleWisePnlReportModalOpen = false;
    isPurchaseReportModalOpen = false;
    isMoneyInReportModalOpen = false;
    isMoneyOutReportModalOpen = false;
    isExpenseReportModalOpen = false;
    isPartyReportModalOpen = false;
    isPartyDetailReportModalOpen = false;
    isPartyReceivablePayableReportModalOpen = false;
    isStockSummaryReportModalOpen = false;
    isItemSaleReportModalOpen = false;
    isItemReportModalOpen = false;
    isItemDetailReportModalOpen = false;
    isDayBookReportModalOpen = false;
    isAllProfilesDayBookReportModalOpen = false;
    isIngredientReportModalOpen = false;
    isSaleWiseIngredientReportModalOpen = false;
    isRawMaterialReportModalOpen: boolean = false;
    isRawMaterialCostReportModalOpen: boolean = false;
    isRawMaterialConsumptionReportModalOpen: boolean = false;
    isRawMaterialPurchaseReportModalOpen: boolean = false;
    disableYearCustomFilter: boolean = false;
    isIngredientDetailReportModalOpen = false;
    isCampaignListModalOpen: boolean = false;
    isCampaignReportModalOpen: boolean = false;

    campaignList = [];

    selectedProfile: Profile = null;
    logoBase64: string = null;

    profileSub: Subscription;

    selectedParty: Party = null;
    selectedItem: Item = null;
    selectedStaff: any;

    startTimeCutOffArr: number[] = [];
    endTimeCutOffArr: number[] = [];

    loading: HTMLIonLoadingElement = null;
    loadingRawMaterialCalculation: boolean = false;
    loadingRawMaterialCostCalculation: boolean = false;
    loadingRawMaterialConsumptionCalculation: boolean = false;
    loadingRawMaterialPurchaseCalculation: boolean = false;

    selectedReport: 'SaleReport' | 'StaffWiseSaleReport' |'SaleWisePnlReport' | 'PurchaseReport'
        | 'MoneyInReport' | 'MoneyOutReport' | 'PartyReport' | 'PartyDetailReport'
        | 'PartyReceivablePayableReport' | 'CampaignReport' | 'StockSummaryReport'
        | 'ItemSaleReport' | 'ItemReport' | 'ItemDetailReport' | 'DayBookReport'
        | 'AllProfilesDayBookReport' | 'IngredientReport' | 'SaleWiseIngredientReport' 
        | 'RawMaterialReport' | 'RawMaterialCostReport' | 'RawMaterialConsumptionReport' | 'RawMaterialPurchaseReport'
        | 'IngredientDetailReport' | 'GSTR1Report' | 'GSTR2Report' | 'GSTR3bReport'
        | 'ExpenseReport'
        | 'CutOffDayBookReport'
        = null;


    saleReportData: {
        sales: {
            sale: Sale;
            totalBillItemQuantity: number;
            totalTax: number;
        }[];
        duration: string;
        totalSalesQuantity: number;
        totalSalesAmount: number;
        download: Function;
        downloadExcel: Function;
    } = null;

    staffWiseSaleReportData: {
        sales: {
            sale: Sale;
            totalBillItemQuantity: number;
            totalTax: number;
        }[];
        duration: string;
        totalSalesQuantity: number;
        totalSalesAmount: number;
        download: Function;
        downloadExcel: Function;
    } = null;

    saleWisePnlReportData: {
        sales: {
            sale: Sale;
            totalPurchaseAmount: number;
            totalSaleProfit: number;
            purchaseAmountNote: string;
        }[];
        duration: string;
        totalSalesAmount: number;
        totalPurchasesAmount: number;
        totalSalesProfit: number;
        download: Function;
        downloadExcel: Function;
    } = null;

    purchaseReportData: {
        purchases: {
            purchase: Purchase;
            totalBillItemQuantity: number;
            totalTax: number;
        }[];
        duration: string;
        totalPurchasesQuantity: number;
        totalPurchasesAmount: number;
        download: Function;
        downloadExcel: Function;
    } = null;

    moneyInReportData: {
        moneyIns: {
            moneyIn: MoneyIn;
        }[];
        duration: string;
        totalMoneyInsAmount: number;
        download: Function;
        downloadExcel: Function;
    } = null;

    moneyOutReportData: {
        moneyOuts: {
            moneyOut: MoneyOut;
        }[];
        duration: string;
        totalMoneyOutsAmount: number;
        download: Function;
        downloadExcel: Function;
    } = null;


    expenseReportData: {
        expenses: {
            expense: Expense;
        }[];
        duration: string;
        totalExpensesAmount: number;
        download: Function;
        downloadExcel: Function;
    } = null;

    partyReportData: {
        party: Party;
        records: {
            txnType: string;
            record: any;
        }[];
        startTime: string;
        endTime: string;
        totalSalesAmount: number;
        totalMoneyInsAmount: number;
        totalPurchasesAmount: number;
        totalMoneyOutsAmount: number;
        download: Function;
        downloadExcel: Function;
    } = null;

    partyDetailReportData: {
        parties: Party[];
        duration: string;
        download: Function;
        downloadExcel: Function;
    } = null;

    partyReceivablePayableReportData: {
        customerRecords: {
            party: Party;
            payableAmount: number;
            receivableAmount: number;
        }[];
        supplierRecords: {
            party: Party;
            payableAmount: number;
            receivableAmount: number;
        }[];
        totalPayableAmount: number;
        totalReceivableAmount: number;
        download: Function;
        downloadExcel: Function;
    } = null;

    partyCampaignReportData: {
        campaignId: string;
        campaigeDate: string;
        campaignMessage: string;
        campaignCustomInputs: string;
        campaignTotalSend: number;
        campaignTotalDelivered: number;
        campaignTotalRead: number;
        campaignTotalReplies: number;
        campaignReportData: any[];
        download: Function;
        downloadExcel: Function;
    }

    stockSummaryReportData: {
        items: {
            item: Item;
            stockValue: number;
            stockValuation: number;
        }[];
        duration: string;
        totalLowStockItems: number;
        totalStocksValue: number;
        totalStocksValuation: number;
        download: Function;
        downloadExcel: Function;
    } = null;

    itemSaleReportData: {
        records: {
            _localUUID: string;
            itemName: string;
            category: string;
            saleAmount: number;
            saleQuantity: number;
        }[];
        duration: string;
        totalSalesAmount: number;
        totalSalesQuantity: number;
        download: Function;
        downloadExcel: Function;
    } = null;

    itemReportData: {
        item: Item;
        records: {
            txnType: string;
            billItem: BillItem;
            record: any;
        }[];
        duration: string;
        totalSalesAmount: number;
        totalSalesQuantity: number;
        totalPurchasesAmount: number;
        totalPurchasesQuantity: number;
        totalAddedQuantity: number;
        totalReducedQuantity: number;
        download: Function;
        downloadExcel: Function;
    } = null;

    itemDetailReportData: {
        items: {
            item: Item;
            discount: number;
            discountPercentage: number;
        }[];
        duration: string;
        download: Function;
        downloadExcel: Function;
    } = null;

    dayBookReportData: {
        records: {
            txnType: string;
            record: any;
        }[];
        duration: string;
        totalSalesAmount: number;
        totalPurchasesAmount: number;
        totalMoneyInsAmount: number;
        totalMoneyInsUPIAmount: number;
        totalMoneyInsChequeAmount: number;
        totalMoneyInsCashAmount: number;
        totalMoneyOutsAmount: number;
        totalMoneyOutsUPIAmount: number;
        totalMoneyOutsChequeAmount: number;
        totalMoneyOutsCashAmount: number;
        print: Function;
    } = null;

    allProfilesDayBookReportData: {
        duration: string;
        profiles: {
            profile: Profile;
            totalSalesAmount: number;
            totalPurchasesAmount: number;
            totalMoneyInsAmount: number;
            totalMoneyInsUPIAmount: number;
            totalMoneyInsChequeAmount: number;
            totalMoneyInsCashAmount: number;
            totalMoneyOutsAmount: number;
            totalMoneyOutsUPIAmount: number;
            totalMoneyOutsChequeAmount: number;
            totalMoneyOutsCashAmount: number;
        }[];
    } = null;

    ingredientReportData: {
        records: {
            ingredient: Ingredient,
            totalConsumedQuantity: number,
        }[];
        duration: string;
        download: Function;
        downloadExcel: Function;
    } = null;

    saleWiseIngredientReportData: {
        sales: {
            sale: Sale;
            totalBillItemQuantity: number;
            totalTax: number;
        }[];
        duration: string;
        totalSalesQuantity: number;
        totalSalesAmount: number;
        download: Function;
        downloadExcel: Function;
    } = null;

    rawMaterialReportData: {
        duration: string;
        days: {
            [key: string]: {
                totalSaleAmount: {
                    actualValue: number;
                    displayValue: string;
                };
                averageCostOfRawMaterial: {
                    actualValue: number;
                    displayValue: string;
                };
                averageCostPercentageOfRawMaterial: {
                    actualValue: number;
                    displayValue: string;
                };
            };
        };
        name: {
            [key: string] : string;
        };
        ingredients: RawMaterialIngredient;
        downloadExcel: Function;
    } = null;

    rawMaterialCostReportData: {
        duration: string;
        days: {
            [key: string]: {
                totalSaleAmount: {
                    actualValue: number;
                    displayValue: string;
                };
                averageIdealCostOfRawMaterial: {
                    actualValue: number;
                    displayValue: string;
                };
                averageIdealCostPercentageOfRawMaterial: {
                    actualValue: number;
                    displayValue: string;
                };
                averageActualCostOfRawMaterial: {
                    actualValue: number;
                    displayValue: string;
                };
                averageActualCostPercentageOfRawMaterial: {
                    actualValue: number;
                    displayValue: string;
                };
                averageCostDifferenceOfRawMaterial: {
                    actualValue: number;
                    displayValue: string;
                };
            };
        };
        ingredients: {
            [key:string]: {
                purchaseSum: {
                    [key: string]: {
                        actualValue: number;
                        displayValue: string;
                    }
                };
                openingStock: {
                    [key: string]: {
                        actualValue: number;
                        displayValue: string;
                    }
                };
                currentDayEODStock: {
                    [key: string]: {
                        actualValue: number;
                        displayValue: string;
                    }
                };
                totalSalesConsumption: {
                    [key: string]: {
                        actualValue: number;
                        displayValue: string;
                    }
                };
                totalActualConsumption: {
                    [key: string]: {
                        actualValue: number;
                        displayValue: string;
                    }
                };
            }
        };
        downloadExcel: Function;
    } = null;

    rawMaterialConsumptionReportData: {
        duration: string;
        days: number[];
        name: {
            [key: string] : string;
        };
        ingredients: RawMaterialIngredient;
        downloadExcel: Function;
    } = null;

    rawMaterialPurchaseReportData: {
        duration: string;
        days: number[];
        name: {
            [key: string] : string;
        };
        unit: {
            [key: string] : string;
        };
        ingredients: {
            [key:string]: {
                totalQuantity: {
                    [key: string]: {
                        actualValue: number;
                        displayValue: string;
                    }
                };
                totalPrice: {
                    [key: string]: {
                        actualValue: number;
                        displayValue: string;
                    }
                };
                perUnitPrice: {
                    [key: string]: {
                        actualValue: number;
                        displayValue: string;
                    }
                };
            }
        };
        downloadExcel: Function;
    }

    ingredientDetailReportData: {
        records: {
            ingredient: Ingredient,
            transactions: {
                type: 'Sale' | 'Add' | 'Reduce',
                sortStamp: number;
                createdStamp: number;
                quantity: number,
                unit: string,
            }[],
        }[];
        duration: string;
        download: Function;
        downloadExcel: Function;
    } = null;

    accessType = {
        'SaleReport': 'viewSaleReport',
        'StaffWiseSaleReport': 'viewStaffWiseSaleReport',
        'SaleWisePnlReport': 'viewSaleWiseProfitAndLossReport',
        'PurchaseReport': 'viewPurchaseReport',
        'MoneyInReport': 'viewMoneyInReport',
        'MoneyOutReport': 'viewMoneyOutReport',
        'PartyReport': 'viewPartyLedgerReport',
        'PartyDetailReport': 'viewPartyDetailReport',
        'PartyReceivablePayableReport': 'viewPartyReceivablePayableReport',
        'StockSummaryReport': 'viewStockSummaryReport',
        'ItemSaleReport': 'viewItemSaleReport',
        'ItemReport': 'viewItemReport',
        'ItemDetailReport': 'viewItemDetailReport',
        'DayBookReport': 'viewDayBookReport',
        'AllProfilesDayBookReport': 'viewAllProfilesDayBookReport',
        'CutOffDayBookReport': 'viewCutOffDayReport',
        'ExpenseReport': 'viewExpenseReport',
        'RawMaterialCostReport': 'viewRawMaterialCostReport',
        'RawMaterialConsumptionReport': 'viewRawMaterialConsumptionReport',
        'RawMaterialPurchaseReport': 'viewRawMaterialPurchaseReport',
    };

    constructor(
        private allDataService: AllDataService,
        private activatedRoute: ActivatedRoute,
        private accessControlService: AccessControlService,
        private authService: AuthService,
        private ingredientService: IngredientService,
        private ingredientStockAdjustService: IngredientStockAdjustService,
        private loadingCtrl: LoadingController,
        private monthWisePartyCreditService: MonthWisePartyCreditService,
        private monthWiseItemStockService: MonthWiseItemStockService,
        private imageBase64Service: ImageBase64Service,
    ) { }

    ngOnInit() {
        try {
            this.getSelectedProfile();
            this.getStartEndCutOffDayTime();
            this.profileSub = this.allDataService.listForceReloadSubs.subscribe((listName: string) => {
                if (listName == 'profile-list') {
                    this.getSelectedProfile();
                }
            });
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:ngOnInit", error)
        }
    }

    async ngAfterViewInit() {
        try {
            let type = this.activatedRoute?.snapshot?.queryParamMap.get('type');
            let localUUID = this.activatedRoute?.snapshot?.queryParamMap.get('localUUID');
            if (type === 'ItemReport' && localUUID) {
                let item = await this.allDataService.itemService.getByUUID(localUUID);
                item?._localUUID && this.onItemSelected(item);
            } else if (type === 'PartyReport' && localUUID) {
                let party = await this.allDataService.partyService.getByUUID(localUUID);
                party?._localUUID && this.onPartySelected(party);
            }
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:ngAfterViewInit", error)
        }
    }

    ionViewWillLeave() {
        this.ngOnDestroy();
    }

    ngOnDestroy() {
        this.profileSub?.unsubscribe();
    }

    openBusinessReport() {
        try {
            let startTime = +new Date().setHours(0, 0, 0, 0) - 86400000;
            let endTime = +new Date().setHours(0, 0, 0, 0) - 1;
            let loginPhone = this.authService.getLoginPhone();
            let profileId = Utility.getFromLocalStorage('selectedProfile');
            let url = `https://db.ezobooks.in/kappa/reports/dayBookReport/${loginPhone}/${profileId}/${startTime}/${endTime}`;
            let aTag = document.createElement('a');
            aTag.href = url;
            aTag.target = '_blank';
            aTag.click();
            setTimeout(() => {
                aTag.remove();
            }, 100);
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:openBusinessReport", error)
        }
    }

    async getSelectedProfile() {
        try {
            let selectedProfileId = Utility.getFromLocalStorage('selectedProfile');
            if (selectedProfileId) {
                this.selectedProfile = await this.allDataService.profileService.getByUUID(selectedProfileId);
                this.populateLogo(this.selectedProfile?.logoLink);
            }
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:getSelectedProfile", error)
        }
    }

    async populateLogo(logoLink: string) {
        try {
            if (logoLink) {
                const image = await this.allDataService.imageService.getByUUID(logoLink);
                let base64 = await this.imageBase64Service.getBase64FromDBorServerWithoutSave(image);
                if (image?._localUUID && base64) {
                    this.logoBase64 = base64;
                }
            }
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:populateLogo", error)
        }
    }

    onDateRangeChange(data: { fromStamp: number, toStamp: number }) {
        this.startStamp = data?.fromStamp;
        this.endStamp = data?.toStamp;
    }

    setStartStamp(event) {
        this.startStamp = event?.detail?.value || this.startStamp;
    }

    setEndStamp(event) {
        this.endStamp = event?.detail?.value || this.endStamp;
    }

    async openDateRangeModal(isOpen: boolean, selectedReport?: string, selectedDateRange: 'today' | '7days' | '30days' | 'month' | 'year' | 'custom' = 'year') {
        try {
            // this.selectedDateRange = selectedDateRange;
            this.disableYearCustomFilter = false;
            if (selectedReport) {
                let isPermit = await this.accessControlService.isUserHasAccess({ action: this.accessType[selectedReport] });
                if (!isPermit) {
                    return alert(`Permission: You don't have permission to view ${selectedReport}. Please contact to your owner.`);
                }
            }
    
            if (
                selectedReport === 'RawMaterialReport' ||
                selectedReport === 'RawMaterialCostReport' ||
                selectedReport === 'RawMaterialConsumptionReport' ||
                selectedReport === 'RawMaterialPurchaseReport'
                ) {
                this.disableYearCustomFilter = true;
                this.selectedDateRange = selectedDateRange;
            }
    
            this.isDateRangeModalOpen = isOpen;
            if (selectedReport) {
                this.selectedReport = selectedReport as any;
            }
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:openDateRangeModal", error)
        }
    }

    async openStaffListModal(value:boolean,selectedReport?: string){
        try {
            let currentProfile = await this.allDataService.profileService.getCurrentProfile();
            let ownerUserId = this.authService.getLoginPhone();
            if (ownerUserId === currentProfile?.userId) {
                this.isStaffWiseSaleReportModal = value;
            } else {
                return alert(`Permission: You don't have permission to view Staff Wise Sale Report`);
            }
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:openStaffListModal", error)
        }
    }

    async openCutOffDayDatesModal(isOpen: boolean, selectedReport?: string) {
        try {
            if (selectedReport) {
              let isPermit = await this.accessControlService.isUserHasAccess({ action: this.accessType[selectedReport] });
              if (!isPermit) {
                return alert(`Permission: You don't have permission to view ${selectedReport}. Please contact to your owner.`);
              }
            }
    
            this.isCutOffDayDatesModalOpen = isOpen;
            if (selectedReport) {
                this.selectedReport = selectedReport as any;
            }
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:openCutOffDayDatesModal", error)
        }
    }

    async openPartySelectorModal(value: boolean) {
        try {
            let isPermit = await this.accessControlService.isUserHasAccess({ action: 'viewPartyLedgerReport' });
            if (!isPermit) {
                return alert(`Permission: You don't have permission to view PartyReport. Please contact to your owner.`);
            }
            this.isPartyListModalOpen = value;
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:openPartySelectorModal", error)
        }
    }

    onPartySelected(party: Party) {
        this.selectedParty = party;
        this.openPartySelectorModal(false);
        this.openDateRangeModal(true, 'PartyReport')
    }

    onStaffSelected(staff:any){
        this.selectedStaff = staff;
        this.openStaffListModal(false);
        this.openDateRangeModal(true,"StaffWiseSaleReport")
    }


    async openItemSelectorModal(value: boolean) {
        try {
            let isPermit = await this.accessControlService.isUserHasAccess({ action: 'viewItemReport' });
            if (!isPermit) {
                return alert(`Permission: You don't have permission to view ItemReport. Please contact to your owner.`);
            }
            this.isItemListModalOpen = value;
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:openItemSelectorModal", error)
        }
    }

    async openCampaignSelectorModal(value: boolean) {
        try {
            let isPermit = await this.accessControlService.isUserHasAccess({ action: 'viewItemReport' });
            if (!isPermit) {
              return alert(`Permission: You don't have permission to view ItemReport. Please contact to your owner.`);
            }
            this.campaignList = [
              {name: 'A1', _localUUID: 'dfdsadascsax'},
              {name: 'A2', _localUUID: 'dvdsdcdcdcvf'},
              {name: 'A3', _localUUID: 'sdfsdfcdacad'},
            ]
            this.isCampaignListModalOpen = value;
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:openCampaignSelectorModal", error)  
        }
    }

    openCampaignReportModal(value: boolean = true) {
      this.isCampaignReportModalOpen = value;
    }

    onItemSelected(item: Item) {
        try {
            this.selectedItem = item;
            this.openItemSelectorModal(false);
            this.openDateRangeModal(true, 'ItemReport')
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:onItemSelected", error)  
        }
    }

    async directOpen(selectedReport?: string) {
        try {
            if (selectedReport) {
                let isPermit = await this.accessControlService.isUserHasAccess({ action: this.accessType[selectedReport] });
                if (!isPermit) {
                    return alert(`Permission: You don't have permission to view ${selectedReport}. Please contact to your owner.`);
                }
                this.selectedReport = selectedReport as any;
            }
            this.generateReport();
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:directSignInOld", error)  
        }
    }

    async generateReport() {
        try {
            this.openDateRangeModal(false);
            let success: boolean = null;
            if (this.selectedReport === 'SaleReport') {
                this.openSaleReportModal();
                success = await this.getSaleReportData();
            }else if(this.selectedReport === 'StaffWiseSaleReport'){
                this.openStaffWiseSaleReportModal();
                success = await this.getStaffWiseSaleReportData();
            } else if (this.selectedReport === 'SaleWisePnlReport') {
                this.openSaleWisePnlReportModal();
                success = await this.getSaleWisePnlReportData();
            } else if (this.selectedReport === 'PurchaseReport') {
                this.openPurchaseReportModal();
                success = await this.getPurchaseReportData();
            } else if (this.selectedReport === 'MoneyInReport') {
                this.openMoneyInReportModal();
                success = await this.getMoneyInReportData();
            } else if (this.selectedReport === 'MoneyOutReport') {
                this.openMoneyOutReportModal();
                success = await this.getMoneyOutReportData();
            } else if (this.selectedReport === 'ExpenseReport') {
                this.openExpenseReportModal();
                success = await this.getExpenseReportData();
            } else if (this.selectedReport === 'PartyReport') {
                this.openPartyReportModal();
                success = await this.getPartyReportData();
            } else if (this.selectedReport === 'PartyDetailReport') {
                this.openPartyDetailReportModal();
                success = await this.getPartyDetailReportData();
            } else if (this.selectedReport === 'PartyReceivablePayableReport') {
                this.openPartyReceivablePayableReportModal();
                success = await this.getPartyReceivablePayableReportData();
            } else if (this.selectedReport === 'CampaignReport') {
                this.openCampaignReportModal();
                success = await this.getCampaignReportData();
            }else if (this.selectedReport === 'StockSummaryReport') {
                this.openStockSummaryReportModal();
                success = await this.getStockSummaryReportData();
            } else if (this.selectedReport === 'ItemSaleReport') {
                this.openItemSaleReportModal();
                success = await this.getItemSaleReportData();
            } else if (this.selectedReport === 'ItemReport') {
                this.openItemReportModal();
                success = await this.getItemReportData();
            } else if (this.selectedReport === 'ItemDetailReport') {
                this.openItemDetailReportModal();
                success = await this.getItemDetailReportData();
            } else if (this.selectedReport === 'DayBookReport') {
                this.openDayBookReportModal();
                success = await this.getDayBookReportData();
            } else if (this.selectedReport === 'CutOffDayBookReport') {
                if (!this.startStamp || !this.endStamp && !(this.startStamp >= this.endStamp)) {
                    return alert("Please Select Valid Date Range");
                }
                this.openDayBookReportModal();
                success = await this.getDayBookReportData();
            } else if (this.selectedReport === 'AllProfilesDayBookReport') {
                this.openAllProfilesDayBookReportModal();
                success = await this.getAllProfilesDayBookReportData();
            } else if (this.selectedReport === 'IngredientReport') {
                this.openIngredientReportModal();
                success = await this.getIngredientReportData();
            } else if (this.selectedReport === 'SaleWiseIngredientReport') {
                this.openSaleWiseIngredientReportModal();
                success = await this.getSaleWiseIngredientReportData();
            } else if (this.selectedReport === 'RawMaterialReport') {
                this.openRawMaterialReportModal();
                this.loading = await this.loadingCtrl.create({
                    message: 'Loading Raw Material Report',
                  });
                this.loading?.present();
                success = await this.getRawMaterialReportData();
                this.loading?.dismiss();
                if(success) {
                    this.loadingRawMaterialCalculation = true;
                } else {
                    this.loading = await this.loadingCtrl.create({
                        message: 'Fail to load data from server',
                        duration: 2000
                      });
                    this.loading?.present();
                }
            } else if (this.selectedReport === 'RawMaterialCostReport') {
                this.openRawMaterialCostReportModal();
                this.loading = await this.loadingCtrl.create({
                    message: 'Loading Raw Material Cost Report',
                  });
                this.loading?.present();
                success = await this.getRawMaterialCostReportData();
                this.loading?.dismiss();
                if(success) {
                    this.loadingRawMaterialCostCalculation = true;
                } else {
                    this.loading = await this.loadingCtrl.create({
                        message: 'Fail to load data from server',
                        duration: 2000
                      });
                    this.loading?.present();
                }
            } else if (this.selectedReport === 'RawMaterialConsumptionReport') {
                this.openRawMaterialConsumptionReportModal();
                this.loading = await this.loadingCtrl.create({
                    message: 'Loading Raw Material Consumption Report',
                  });
                this.loading?.present();
                success = await this.getRawMaterialConsumptionReportData();
                this.loading?.dismiss();
                if(success) {
                    this.loadingRawMaterialConsumptionCalculation = true;
                } else {
                    this.loading = await this.loadingCtrl.create({
                        message: 'Fail to load data from server',
                        duration: 2000
                      });
                    this.loading?.present();
                }
            } else if (this.selectedReport === 'RawMaterialPurchaseReport') {
                this.openRawMaterialPurchaseReportModal();
                this.loading = await this.loadingCtrl.create({ 
                    message: 'Loading Raw Material Purchase Report',
                  });
                this.loading?.present();
                success = await this.getRawMaterialPurchaseReportData();
                this.loading?.dismiss();
                if(success) {
                    this.loadingRawMaterialPurchaseCalculation = true;
                } else {
                    this.loading = await this.loadingCtrl.create({ 
                        message: 'Fail to load data from server',
                        duration: 2000
                      });
                    this.loading?.present();
                }
            } else if (this.selectedReport === 'IngredientDetailReport') {
                this.openIngredientDetailReportModal();
                success = await this.getIngredientDetailReportData();
            } else if (this.selectedReport === 'GSTR1Report') {
                success = await this.getGSTR1V1ReportData();
            } else if (this.selectedReport === 'GSTR2Report') {
                success = await this.getGSTR1V1ReportData();
            } else if (this.selectedReport === 'GSTR3bReport') {
                success = await this.getGSTR1V1ReportData();
            }
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:generateReport", error)  
        }
    }

    openSaleReportModal(value: boolean = true) {
        this.isSaleReportModalOpen = value;
    }

    openStaffWiseSaleReportModal(value:boolean = true){
        this.isStaffWiseSaleReportDetailModalOpen = value;
    }

    openSaleWisePnlReportModal(value: boolean = true) {
        this.isSaleWisePnlReportModalOpen = value;
    }

    openPurchaseReportModal(value: boolean = true) {
        this.isPurchaseReportModalOpen = value;
    }

    openMoneyInReportModal(value: boolean = true) {
        this.isMoneyInReportModalOpen = value;
    }

    openMoneyOutReportModal(value: boolean = true) {
        this.isMoneyOutReportModalOpen = value;
    }

    openExpenseReportModal(value: boolean = true) {
        this.isExpenseReportModalOpen = value;
    }

    openPartyReportModal(value: boolean = true) {
        try {
            this.isPartyReportModalOpen = value;
            if (!this.isPartyReportModalOpen) {
                this.selectedParty = null;
            }
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:openPartyReportModal", error)  
        }
    }

    openPartyDetailReportModal(value: boolean = true) {
        this.isPartyDetailReportModalOpen = value;
    }

    openPartyReceivablePayableReportModal(value: boolean = true) {
        this.isPartyReceivablePayableReportModalOpen = value;
    }

    openStockSummaryReportModal(value: boolean = true) {
        this.isStockSummaryReportModalOpen = value;
    }

    openItemSaleReportModal(value: boolean = true) {
        this.isItemSaleReportModalOpen = value;
    }

    openItemReportModal(value: boolean = true) {
        try {
            this.isItemReportModalOpen = value;
            if (!this.isItemReportModalOpen) {
                this.selectedItem = null;
            }
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:openItemReportModal", error)  
        }
    }

    openItemDetailReportModal(value: boolean = true) {
        this.isItemDetailReportModalOpen = value;
    }

    openDayBookReportModal(value: boolean = true) {
        this.isDayBookReportModalOpen = value;
    }

    openAllProfilesDayBookReportModal(value: boolean = true) {
        this.isAllProfilesDayBookReportModalOpen = value;
    }

    openIngredientReportModal(value: boolean = true) {
        this.isIngredientReportModalOpen = value;
    }

    openSaleWiseIngredientReportModal(value: boolean = true) {
        this.isSaleWiseIngredientReportModalOpen = value;
    }

    openRawMaterialReportModal(value: boolean = true) {
        try {
            this.isRawMaterialReportModalOpen = value;
            if(!value) {
                this.loadingRawMaterialCalculation = value;
            }
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:openRawMaterialReportModal", error)  
        }
    }

    openRawMaterialCostReportModal(value: boolean = true) {
        try {
            this.isRawMaterialCostReportModalOpen = value;
            if(!value) {
                this.loadingRawMaterialCostCalculation = value;
            }
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:openRawMaterialCostReportModal", error)  
        }
    }

    openRawMaterialConsumptionReportModal(value: boolean = true) {
        try {
            this.isRawMaterialConsumptionReportModalOpen = value;
            if(!value) {
                this.loadingRawMaterialConsumptionCalculation = value;
            }
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:openRawMaterialConsumptionReportModal", error)  
        }
    }

    openRawMaterialPurchaseReportModal(value: boolean = true) {
        try {
            this.isRawMaterialPurchaseReportModalOpen = value;
            if(!value) {
                this.loadingRawMaterialPurchaseCalculation = value;
            }
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:openRawMaterialPurchaseReportModal", error)  
        }
    }

    openIngredientDetailReportModal(value: boolean = true) {
        this.isIngredientDetailReportModalOpen = value;
    }

    async getSaleReportData(): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            try {
                this.viewFilteredList = [];
                this.filteredList = [];
                this.saleReportData = null;
                this.saleReportData = {
                    sales: [],
                    duration: `From ${Utility.dateToDDMMYYY(this.startStamp)} To ${Utility.dateToDDMMYYY(this.endStamp)}`,
                    totalSalesQuantity: 0,
                    totalSalesAmount: 0,
                    download: this.saleReportDownload.bind(this),
                    downloadExcel: this.saleReportDownloadExcel.bind(this)
                };

                const sales = await this.allDataService.saleService.getByBillDateRange(this.startStamp, this.endStamp) || [];

                sales?.forEach(sale => {
                    let totalBillItemQuantity: number = 0;
                    sale?.billItems?.forEach(billItem => {
                        if(Utility.isNumber(billItem?.quantity)) {
                            totalBillItemQuantity += Number(billItem?.quantity);
                        }
                    });
                    this.saleReportData.totalSalesQuantity += totalBillItemQuantity;

                    if(Utility.isNumber(sale?.totalAmount)) {
                        this.saleReportData.totalSalesAmount += Number(sale?.totalAmount);
                    }
                    
                    let gstAmount = Utility.isNumber(sale?.gstAmount) ? Number(sale?.gstAmount) : 0;
                    let cessAmount = Utility.isNumber(sale?.cessAmount) ? Number(sale?.cessAmount) : 0;
                    let totalTax = gstAmount + cessAmount;
                    sale['billDateStampString'] = this.dateToDDMMYYY(sale?.billDateStamp);
                    sale['billNameWithSecondaryPartyName'] = (sale?.party?.name || '-')+('\n' + (sale?.partySecondary?.name || '-'));
                    sale['billPhoneWithSecondaryPartyPhone'] = (sale?.party?.phone || '-')+('\n' + (sale?.partySecondary?.phone || '-'));

                    this.saleReportData.sales.push({
                        sale,
                        totalBillItemQuantity,
                        totalTax
                    });
                });

                this.saleReportData.sales?.sort((a, b) => {
                    if (a?.sale?.billDateStamp == b?.sale?.billDateStamp) {
                        return a?.sale?.createdStamp - b?.sale?.createdStamp;
                    }
                    return a?.sale?.billDateStamp - b?.sale?.billDateStamp;
                });

                this.filteredList = this.saleReportData?.sales;
                this.viewFilteredList = this.saleReportData?.sales?.slice(0, 50);

                return resolve(true);
            } catch (error) {
                SentryUtilites.setLog("ReportsPage:getSaleReportData", error)  
                return resolve(false);
            }
        });
    }

    loadMoreSaleReportListData(event) {
        try {
          if (this.viewFilteredList.length > 0 && this.viewFilteredList.length <= this.filteredList.length) {
            let appendListLength = this.filteredList.length - this.viewFilteredList.length;
            let lastEl = this.viewFilteredList[this.viewFilteredList.length - 1];
            let counter = 0;
            for (let i = 0; i < this.filteredList.length; i++) {
              if (this.filteredList[i].sale._localUUID == lastEl.sale._localUUID) {
                counter = 1;
                continue;
              }
              if (counter > 0 && appendListLength >= 50) {
                if (counter <= 50) {
                  this.viewFilteredList.push({ ...this.filteredList[i] })
                } else {
                  break;
                }
                counter++;
              } else if(counter > 0 && appendListLength < 50) {
                if (counter <= appendListLength) {
                  this.viewFilteredList.push({ ...this.filteredList[i] })
                } else {
                  break;
                }
                counter++;
              }
            }
            (event as InfiniteScrollCustomEvent).target.complete();
          }
        } catch (error) {
          SentryUtilites.setLog("ItemListComponent:loadMoreListData", error)
        }
      }

    async saleReportDownload() {
        try {
            const doc: jsPDF = new jsPDF();
    
            //https://github.com/parallax/jsPDF#use-of-unicode-characters--utf-8
    
            doc.addFileToVFS("hindi.ttf", Fonts.hindi);
            doc.addFont("hindi.ttf", "hindi", "normal");
            doc.setFont("hindi");
    
            doc.setFontSize(18).setTextColor(56, 103, 214).text("Sale Report", 15, 10);
            doc.setFontSize(12).setTextColor(116, 125, 140).text(`${this.selectedProfile?.legalName || this.selectedProfile?.profileName} (${this.selectedProfile?._localUUID?.substr(-4)})`, 15, 20);
            doc.setFontSize(11).setTextColor(116, 125, 140).text(this.authService.getLoginPhone(), 15, 26);
    
            doc.setFontSize(11).setTextColor(116, 125, 140).text("Total Sales: " + this.saleReportData?.sales?.length, 15, 35);
            doc.setFontSize(11).setTextColor(116, 125, 140).text("Total Sale Quantity: " + this.saleReportData?.totalSalesQuantity, 15, 41);
            doc.setFontSize(11).setTextColor(116, 125, 140).text("Total Sale Amount: " + this.saleReportData?.totalSalesAmount, 15, 47);
            doc.setFontSize(12).setTextColor(116, 125, 140).text(this.saleReportData?.duration, 135, 47);

            const startX = 10, startY = 60;
            let rowHeight = 10;
            const columnWidths = [15, 25, 45, 65, 85, 105, 125, 145, 175];
            const maxWidths = [8, 15, 15, 15, 15, 15, 15, 25, 15];

            // Draw headers
            let currentY = startY;

            doc.setFillColor(21, 96, 189).rect(10, 55, 190, 12, 'F');

            ['Sr No', 'Date', 'Txn No', 'Party Name', 'Party Phone', 'Total Quantity', 'Total Tax', 'Total Amount (inc taxes)', 'Created By'].forEach((header, i) => {
                doc.setFontSize(10).setTextColor(250, 250, 250).text(header, columnWidths[i], currentY, { maxWidth : maxWidths[i]});
            });

            currentY += 12;

            let rowIndex = 0;
    
            this.saleReportData?.sales.forEach((x, index) => {
                let sale = x?.sale;

                let saleData = [
                    (index + 1)+'',
                    sale?.['billDateStampString'],
                    sale?.billNo+'',
                    sale?.['billNameWithSecondaryPartyName'],
                    sale?.['billPhoneWithSecondaryPartyPhone'],
                    (x?.totalBillItemQuantity || '') +'',
                    (x?.totalTax || '') +'',
                    (sale?.totalAmount || '') +'',
                    (sale?.createdByName || sale?.createdBy)+'',
                ];

                let saleSplitLineLength = 0;

                saleData.forEach((body, i) => {
                    let newSaleSplitLineLength = doc.splitTextToSize(body, maxWidths[i])?.length;
                    if(newSaleSplitLineLength > saleSplitLineLength) {
                        saleSplitLineLength = newSaleSplitLineLength;
                        if(newSaleSplitLineLength === 1) {
                            rowHeight = 8;
                        } else {
                            rowHeight = 6 * saleSplitLineLength;
                        }
                    }
                })

                if(rowIndex%2 === 0) {
                    doc.setFillColor(245, 245, 245).rect(10, currentY - 5, 190, rowHeight, 'F');
                }

                saleData.forEach((body, i) => {
                    doc.setFontSize(10).setTextColor(116, 125, 140).text(body, columnWidths[i], currentY, { maxWidth : maxWidths[i]});
                });

                if(currentY > 265) {
                    currentY = 15;
                    rowIndex = 0;

                    doc.addPage();

                    doc.setFillColor(21, 96, 189).rect(10, 10, 190, 12, 'F');

                    ['Sr No', 'Date', 'Txn No', 'Party Name', 'Party Phone', 'Total Quantity', 'Total Tax', 'Total Amount (inc taxes)', 'Created By'].forEach((header, i) => {
                        doc.setFontSize(10).setTextColor(250, 250, 250).text(header, columnWidths[i], currentY, { maxWidth : maxWidths[i]});
                    });

                    currentY += 12;
                } else {
                    currentY += rowHeight;
                    rowIndex += 1;
                }

                if (sale?.billItems?.length) {

                    if(rowIndex%2 === 0) {
                        doc.setFillColor(245, 245, 245).rect(10, currentY - 5, 190, 12, 'F');
                    }
    
                    doc.setFillColor(37, 150, 190).rect(40, currentY - 5, 160, 12, 'F');

                    [
                        '',
                        '',
                        '#',
                        'Item Name',
                        'Qty',
                        'Unit',
                        'Rs/Unit',
                        'Amount',
                    ].forEach((header, i) => {
                        doc.setFontSize(10).setTextColor(250, 250, 250).text(header, columnWidths[i], currentY, { maxWidth : maxWidths[i]});
                    });

                    if(currentY > 265) {
                        currentY = 15;
                        rowIndex = 0;

                        doc.addPage();

                        doc.setFillColor(21, 96, 189).rect(10, 10, 190, 12, 'F');

                        ['Sr No', 'Date', 'Txn No', 'Party Name', 'Party Phone', 'Total Quantity', 'Total Tax', 'Total Amount (inc taxes)', 'Created By'].forEach((header, i) => {
                            doc.setFontSize(10).setTextColor(250, 250, 250).text(header, columnWidths[i], currentY, { maxWidth : maxWidths[i]});
                        });

                        currentY += 12;
                    } else {
                        currentY += 12;
                        rowIndex += 1;
                    }

                    sale?.billItems?.forEach((billItem, billItemIndex) => {

                        let billItemData = [
                            '',
                            '',
                            (billItemIndex + 1)+'',
                            (billItem?.item?.itemName || '') +'',
                            (billItem?.quantity || '') +'',
                            billItem?.unit || '',
                            (billItem?.price || '') +'',
                            (billItem?.total || '') +'',
                        ];

                        let billItemSplitLineLength = 0;

                        billItemData.forEach((body, i) => {
                            let newBillItemSplitLineLength = doc.splitTextToSize(body, maxWidths[i])?.length;
                            if(newBillItemSplitLineLength > billItemSplitLineLength) {
                                billItemSplitLineLength = newBillItemSplitLineLength;
                                if(newBillItemSplitLineLength === 1) {
                                    rowHeight = 8;
                                } else {
                                    rowHeight = 6 * billItemSplitLineLength;
                                }
                            }
                        })

                        if(rowIndex % 2 === 0) {
                            doc.setFillColor(245, 245, 245).rect(10, currentY - 5, 190, rowHeight, 'F');
                        }
                        
                        billItemData.forEach((body, i) => {
                            doc.setFontSize(10).setTextColor(116, 125, 140).text(body, columnWidths[i], currentY, { maxWidth : maxWidths[i]});
                        });

                        if(currentY > 265) {
                            currentY = 15;
                            rowIndex = 0;

                            doc.addPage();

                            doc.setFillColor(21, 96, 189).rect(10, 10, 190, 12, 'F');

                            ['Sr No', 'Date', 'Txn No', 'Party Name', 'Party Phone', 'Total Quantity', 'Total Tax', 'Total Amount (inc taxes)', 'Created By'].forEach((header, i) => {
                                doc.setFontSize(10).setTextColor(250, 250, 250).text(header, columnWidths[i], currentY, { maxWidth : maxWidths[i]});
                            });

                            currentY += 12;
                        } else {
                            currentY += rowHeight;
                            rowIndex += 1;
                        }
            
                    });
                }
            })
    
            doc.save('Sale_Report.pdf');
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:saleReportDownload", error)  
        }
    }

    async saleReportDownloadExcel() {
        try {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Sale Report With Items');
            const worksheet2 = workbook.addWorksheet('Sale Report');
    
            worksheet.columns = [
                { header: 'Sr No', key: 'srNo', width: 8, alignment: { horizontal: "left" } },
                { header: 'Date', key: 'date', width: 13, alignment: { horizontal: "left" } },
                { header: 'Txn No', key: 'txnNo', width: 13, alignment: { horizontal: "left" } },
                { header: 'Party Name', key: 'partyName', width: 30, alignment: { horizontal: "left" } },
                { header: 'Party Phone', key: 'partyPhone', width: 30, alignment: { horizontal: "left" } },
                { header: 'Secondary Party Name', key: 'secondaryPartyName', width: 30, alignment: { horizontal: "left" } },
                { header: 'Secondary Party Phone', key: 'secondaryPartyPhone', width: 30, alignment: { horizontal: "left" } },
                { header: 'Total Quantity', key: 'totalQuantity', width: 15, alignment: { horizontal: "right" } },
                { header: 'Total Tax', key: 'totalTax', width: 15, alignment: { horizontal: "right" } },
                { header: 'Total Amount (inc taxes)', key: 'totalAmount', width: 25, alignment: { horizontal: "right" } },
                { header: 'Created By', key: 'createdBy', width: 15, alignment: { horizontal: "left" } },
            ];
    
            worksheet2.columns = [
                { header: 'Sr No', key: 'srNo', width: 8, alignment: { horizontal: "left" } },
                { header: 'Date', key: 'date', width: 13, alignment: { horizontal: "left" } },
                { header: 'Txn No', key: 'txnNo', width: 13, alignment: { horizontal: "left" } },
                { header: 'Party Name', key: 'partyName', width: 30, alignment: { horizontal: "left" } },
                { header: 'Party Phone', key: 'partyPhone', width: 30, alignment: { horizontal: "left" } },
                { header: 'Secondary Party Name', key: 'secondaryPartyName', width: 30, alignment: { horizontal: "left" } },
                { header: 'Secondary Party Phone', key: 'secondaryPartyPhone', width: 30, alignment: { horizontal: "left" } },
                { header: 'Total Quantity', key: 'totalQuantity', width: 15, alignment: { horizontal: "right" } },
                { header: 'Total Tax', key: 'totalTax', width: 15, alignment: { horizontal: "right" } },
                { header: 'Total Amount (inc taxes)', key: 'totalAmount', width: 25, alignment: { horizontal: "right" } },
                { header: 'Created By', key: 'createdBy', width: 15, alignment: { horizontal: "left" } },
            ];
    
            worksheet.getRow(1).eachCell(cell => {
                cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FF3E80ED' },
                };
            });
    
            worksheet2.getRow(1).eachCell(cell => {
                cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FF3E80ED' },
                };
            });
    
            this.saleReportData?.sales.forEach((x, index) => {
                let sale = x?.sale;
                let r = worksheet.addRow([
                    index + 1,
                    sale?.['billDateStampString'],
                    sale?.billNo || '',
                    sale?.party?.name || '',
                    sale?.party?.phone || '',
                    sale?.partySecondary?.name || '',
                    sale?.partySecondary?.phone || '',
                    x?.totalBillItemQuantity || '',
                    x?.totalTax || '',
                    sale?.totalAmount || '',
                    sale?.createdByName || sale?.createdBy || '',
                ]);
                r.eachCell((cell, cellNo) => {
    
                    cell.border = {
                        top: { style: 'thick', color: { argb: 'FF3E80ED' } }
                    };
    
                });
                //r.outlineLevel=1;
    
                worksheet2.addRow([
                    index + 1,
                    this.dateToDDMMYYY(sale?.billDateStamp),
                    sale?.billNo || '',
                    sale?.party?.name || '',
                    sale?.party?.phone || '',
                    sale?.partySecondary?.name || '',
                    sale?.partySecondary?.phone || '',
                    x?.totalBillItemQuantity || '',
                    x?.totalTax || '',
                    sale?.totalAmount || '',
                    sale?.createdByName || sale?.createdBy || '',
                ]);
    
                if (sale?.billItems?.length) {
                    worksheet.addRow([
                        '',
                        '',
                        '#',
                        'Item Name',
                        'Qty',
                        'Unit',
                        'Rs/Unit',
                        'Amount',
                        '',
                    ]).eachCell((cell, cellNo) => {
                        if (cellNo != 1 && cellNo != 2) {
                            cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FF3E80ED' },
                            };
                        }
                    });
    
                    sale?.billItems?.forEach((billItem, billItemIndex) => {
                        worksheet.addRow([
                            '',
                            '',
                            billItemIndex + 1 || '',
                            billItem?.item?.itemName || '',
                            billItem?.quantity || '',
                            billItem?.unit || '',
                            billItem?.price || '',
                            billItem?.total || '',
                            '',
                        ]).eachCell((cell, cellNo) => {
                            if (cellNo != 1 && cellNo != 2) {
                                cell.fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'FFEEEEEE' },
                                };
                                cell.border = {
                                    bottom: {
                                        style: 'thin',
                                        color: { argb: 'FF999999' }
                                    },
                                    right: {
                                        style: 'thin',
                                        color: { argb: 'FF999999' }
                                    }
                                }
                            }
                        });
                    });
                }
            });
    
            workbook.xlsx.writeBuffer().then(buffer => {
                const blob = new Blob([buffer], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                })
                saveAs(blob, 'Sale_Report.xlsx');
            });
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:saleReportDownloadExcel", error)  
        }
    }

    async getStaffWiseSaleReportData(): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            try {
                this.staffWiseSaleReportData = null;
                this.staffWiseSaleReportData = {
                    sales: [],
                    duration: `From ${Utility.dateToDDMMYYY(this.startStamp)} To ${Utility.dateToDDMMYYY(this.endStamp)}`,
                    totalSalesQuantity: 0,
                    totalSalesAmount: 0,
                    download: this.staffWiseSaleReportDownload.bind(this), 
                    downloadExcel: this.staffWiseSaleReportDownloadExcel.bind(this) 
                };

                const sales = await this.allDataService.saleService.getByBillDateRange(this.startStamp, this.endStamp) || [];
                const staffWiseSale = sales?.filter((sale)=> sale?.createdBy === this?.selectedStaff?.userId);

                staffWiseSale?.forEach(sale => {
                    let totalBillItemQuantity: number = 0;
                    sale.billItems?.forEach(billItem => {
                        if(Utility.isNumber(billItem?.quantity)) {
                            totalBillItemQuantity += Number(billItem?.quantity);
                        }
                    });
                    this.staffWiseSaleReportData.totalSalesQuantity += totalBillItemQuantity;

                    if(Utility.isNumber(sale?.totalAmount)) {
                        this.staffWiseSaleReportData.totalSalesAmount += Number(sale?.totalAmount);
                    }

                    let gstAmount = Utility.isNumber(sale?.gstAmount) ? Number(sale?.gstAmount) : 0;
                    let cessAmount = Utility.isNumber(sale?.cessAmount) ? Number(sale?.cessAmount) : 0;
                    let totalTax = gstAmount + cessAmount;

                    this.staffWiseSaleReportData.sales.push({
                        sale,
                        totalBillItemQuantity,
                        totalTax,
                    });
                });

                this.staffWiseSaleReportData.sales.sort((a, b) => {
                    if (a?.sale?.billDateStamp == b?.sale?.billDateStamp) {
                        return a?.sale?.createdStamp - b?.sale?.createdStamp;
                    }
                    return a?.sale?.billDateStamp - b?.sale?.billDateStamp;
                });

                return resolve(true);
            } catch (error) {
                SentryUtilites.setLog("ReportsPage:getStaffWiseSaleReportData", error)  
                return resolve(false);
            }
        });
    }

    async staffWiseSaleReportDownload() {
        try {
            const doc: jsPDF = new jsPDF();
    
            //https://github.com/parallax/jsPDF#use-of-unicode-characters--utf-8
    
            doc.addFileToVFS("hindi.ttf", Fonts.hindi);
            doc.addFont("hindi.ttf", "hindi", "normal");
            doc.setFont("hindi");
    
            doc.setFontSize(18).setTextColor(56, 103, 214).text("Staff Wise Sale Report", 15, 10);
            doc.setFontSize(12).setTextColor(116, 125, 140).text(`${this.selectedStaff?.name }`, 15, 20);
            doc.setFontSize(11).setTextColor(116, 125, 140).text(`${this.selectedStaff?.userId}`, 15, 26);
    
            doc.setFontSize(11).setTextColor(116, 125, 140).text("Total Sales: " + this.staffWiseSaleReportData?.sales?.length, 15, 35);
            doc.setFontSize(11).setTextColor(116, 125, 140).text("Total Sale Quantity: " + this.staffWiseSaleReportData?.totalSalesQuantity, 15, 41);
            doc.setFontSize(11).setTextColor(116, 125, 140).text("Total Sale Amount: " + this.staffWiseSaleReportData?.totalSalesAmount, 15, 47);
            doc.setFontSize(12).setTextColor(116, 125, 140).text(this.staffWiseSaleReportData?.duration, 135, 47);
    
            const getPartyName = (sale: Sale) => {
                let partyName: string = '';
                partyName += sale?.party?.name || '-';
                partyName += '\n' + (sale?.partySecondary?.name || '-');
                return partyName;
            }
    
            const getPartyPhone = (sale: Sale) => {
                let partyPhone: string = '';
                partyPhone += sale?.party?.phone || '-';
                partyPhone += '\n' + (sale?.partySecondary?.phone || '-');
                return partyPhone;
            }
    
            let body = [];
    
            this.staffWiseSaleReportData?.sales?.forEach((x, index) => {
                let sale = x?.sale;
                // add sale row
                body.push([
                    index + 1,
                    this.dateToDDMMYYY(sale?.billDateStamp),
                    sale?.billNo,
                    getPartyName(sale),
                    getPartyPhone(sale),
                    x?.totalBillItemQuantity,
                    x?.totalTax,
                    sale?.totalAmount,
                    sale?.createdByName || sale?.createdBy,
                ]);
                if (sale?.billItems?.length) {
                    // add billItem header
                    body.push([
                        '',
                        '',
                        '#',
                        'Item Name',
                        'Qty',
                        'Unit',
                        'Rs/Unit',
                        'Amount',
                    ]);
    
                    sale?.billItems?.forEach((billItem, billItemIndex) => {
                        // add billItem
                        body.push([
                            '',
                            '',
                            billItemIndex + 1,
                            billItem?.item?.itemName,
                            billItem?.quantity,
                            billItem?.unit,
                            billItem?.price,
                            billItem?.total,
                        ]);
                    });
                }
            })
    
            autoTable(doc, {
                head: [['Sr No', 'Date', 'Txn No', 'Party Name', 'Party Phone', 'Total Quantity', 'Total Tax', 'Total Amount (inc taxes)', 'Created By']],
                body,
                didParseCell: (data) => {
                    if (data.section === 'body' && data.cell.raw == `#`) {
                        Object.keys(data?.row?.cells || {})?.forEach(cellIndex => {
                            if (data.row.cells[cellIndex]?.raw != '') {
                                data.row.cells[cellIndex].styles.textColor = '#FFFFFF';
                                data.row.cells[cellIndex].styles.fillColor = '#2596be';
                            }
                        });
                    }
                },
                margin: {
                    top: 55
                },
                styles: {
                    font: 'hindi'
                }
            });
    
            doc.save('StaffWiseSale_Report.pdf');
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:staffWiseSaleReportDownload", error)  
        }
    }

    async staffWiseSaleReportDownloadExcel() {
        try {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Staff Wise Sale Report ');
    
            worksheet.addRow([`${this.selectedStaff?.name}`]);
            worksheet.addRow([`${this.selectedStaff?.userId}`]);
            worksheet.addRow(["Total Sales: " + this.staffWiseSaleReportData?.sales?.length]);
            worksheet.addRow(["Total Sale Quantity: " + this.staffWiseSaleReportData?.totalSalesQuantity]);
            worksheet.addRow(["Total Sale Amount: " + this.staffWiseSaleReportData?.totalSalesAmount]);
            worksheet.addRow([this.staffWiseSaleReportData?.duration]);
            worksheet.addRow([``]);
    
            worksheet.addRow(["Sr No","Date","Txn No","Party Name","Party Phone","Secondary Party Name","Secondary Party Phone","Total Quantity","Total Tax","Total Amount (inc taxes)","Created By"]);
            let width = [8,13,13,30,30,30,30,15,15,25,15];
    
            worksheet.columns?.forEach((column,i)=>{
                column.width = width[i];
            })
    
            worksheet.getRow(8).eachCell(cell => {
                cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FF3E80ED' },
                };
            });
    
            this.staffWiseSaleReportData?.sales.map((x, index) => {
                let sale = x?.sale;
                let r = worksheet.addRow([
                    index + 1,
                    this.dateToDDMMYYY(sale?.billDateStamp),
                    sale?.billNo || '',
                    sale?.party?.name || '',
                    sale?.party?.phone || '',
                    sale?.partySecondary?.name || '',
                    sale?.partySecondary?.phone || '',
                    x?.totalBillItemQuantity || '',
                    x?.totalTax || '',
                    sale?.totalAmount || '',
                    sale?.createdByName || sale?.createdBy || '',
                ]);
                r.eachCell((cell, cellNo) => {
    
                    cell.border = {
                        top: { style: 'thick', color: { argb: 'FF3E80ED' } }
                    };
    
                });
                //r.outlineLevel=1;
    
                if (sale?.billItems?.length) {
                    worksheet.addRow([
                        '',
                        '',
                        '#',
                        'Item Name',
                        'Qty',
                        'Unit',
                        'Rs/Unit',
                        'Amount',
                        '',
                    ]).eachCell((cell, cellNo) => {
                        if (cellNo != 1 && cellNo != 2) {
                            cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FF3E80ED' },
                            };
                        }
                    });
    
                    sale?.billItems?.forEach((billItem, billItemIndex) => {
                        worksheet.addRow([
                            '',
                            '',
                            billItemIndex + 1 || '',
                            billItem?.item?.itemName || '',
                            billItem?.quantity || '',
                            billItem?.unit || '',
                            billItem?.price || '',
                            billItem?.total || '',
                            '',
                        ]).eachCell((cell, cellNo) => {
                            if (cellNo != 1 && cellNo != 2) {
                                cell.fill = {
                                    type: 'pattern',
                                    pattern: 'solid',
                                    fgColor: { argb: 'FFEEEEEE' },
                                };
                                cell.border = {
                                    bottom: {
                                        style: 'thin',
                                        color: { argb: 'FF999999' }
                                    },
                                    right: {
                                        style: 'thin',
                                        color: { argb: 'FF999999' }
                                    }
                                }
                            }
                        });
                    });
                }
            });
    
            for(let i=1; i<=7 ;i++){
                worksheet.mergeCells(`A${i}:K${i}`);
            }
     
    
            workbook.xlsx.writeBuffer().then(buffer => {
                const blob = new Blob([buffer], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                })
                saveAs(blob, 'StaffWiseSale_Report.xlsx');
            });
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:staffWiseSaleReportDownloadExcel", error)  
        }
    }
    

    async getSaleWisePnlReportData(): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            try {
                this.saleWisePnlReportData = null;

                this.saleWisePnlReportData = {
                    sales: [],
                    duration: `From ${Utility.dateToDDMMYYY(this.startStamp)} To ${Utility.dateToDDMMYYY(this.endStamp)}`,
                    totalSalesAmount: 0,
                    totalPurchasesAmount: 0,
                    totalSalesProfit: 0,
                    download: this.saleWisePnlReportDownload.bind(this),
                    downloadExcel: this.saleWisePnlReportDownloadExcel.bind(this)
                };

                const sales = await this.allDataService.saleService.getByBillDateRange(this.startStamp, this.endStamp) || [];

                sales?.forEach(sale => {
                    let totalPurchaseAmount = 0;
                    let purchaseAmountNote: string = null;
                    sale.billItems?.forEach(billItem => {
                        let purchasePrice = Utility.isNumber(billItem?.item?.purchasePrice) ? Number(billItem?.item?.purchasePrice) : 0;
                        let quantity = Utility.isNumber(billItem?.quantity) ? Number(billItem?.quantity) : 0;
                        totalPurchaseAmount += purchasePrice * quantity;
                        if (purchasePrice <= 0) {
                            purchaseAmountNote = 'One or more items in this bill have 0 purchase amount';
                        }
                    });

                    this.saleWisePnlReportData.totalPurchasesAmount += totalPurchaseAmount;
                    
                    let totalSaleProfit = 0;

                    if(Utility.isNumber(sale?.totalAmount)) {
                        this.saleWisePnlReportData.totalSalesAmount += Number(sale?.totalAmount);
                        totalSaleProfit = Number(sale?.totalAmount) - totalPurchaseAmount;
                    }

                    this.saleWisePnlReportData.sales.push({
                        sale,
                        totalPurchaseAmount,
                        totalSaleProfit,
                        purchaseAmountNote,
                    });
                });

                this.saleWisePnlReportData.totalSalesProfit = this.saleWisePnlReportData?.totalSalesAmount - this.saleWisePnlReportData?.totalPurchasesAmount;

                this.saleWisePnlReportData.sales.sort((a, b) => {
                    if (a?.sale?.billDateStamp == b?.sale?.billDateStamp) {
                        return a?.sale?.createdStamp - b?.sale?.createdStamp;
                    }
                    return a?.sale?.billDateStamp - b?.sale?.billDateStamp;
                });

                return resolve(true);
            } catch (error) {
                SentryUtilites.setLog("ReportsPage:getSaleWisePnlReportData", error)  
                return resolve(false);
            }
        });
    }

    async saleWisePnlReportDownload() {
        try {
            const doc = new jsPDF();
    
            doc.addFileToVFS("hindi.ttf", Fonts.hindi);
            doc.addFont("hindi.ttf", "hindi", "normal");
            doc.setFont("hindi");
    
            doc.setFontSize(18).setTextColor(56, 103, 214).text("Sale Wise Profit and Loss Report", 15, 10);
            doc.setFontSize(12).setTextColor(116, 125, 140).text(`${this.selectedProfile?.legalName || this.selectedProfile?.profileName} (${this.selectedProfile?._localUUID?.substr(-4)})`, 15, 20);
            doc.setFontSize(11).setTextColor(116, 125, 140).text(this.authService.getLoginPhone(), 15, 26);
    
            doc.setFontSize(11).setTextColor(116, 125, 140).text("Total Sale Amount: " + this.saleWisePnlReportData?.totalSalesAmount, 15, 35);
            doc.setFontSize(11).setTextColor(116, 125, 140).text("Total Purchase Amount: " + this.saleWisePnlReportData?.totalPurchasesAmount, 15, 41);
            doc.setFontSize(11).setTextColor(116, 125, 140).text("Total Profit: " + this.saleWisePnlReportData?.totalSalesProfit, 15, 47);
            doc.setFontSize(12).setTextColor(116, 125, 140).text(this.saleWisePnlReportData?.duration, 135, 47);
    
            autoTable(doc, {
                head: [['Sr No', 'Date', 'Bill No', 'Party', 'Sale Amount', 'Purchase Amount', 'Profit']],
                body: this.saleWisePnlReportData?.sales.map((x, index) => {
                    let sale = x?.sale;
                    return [
                        index + 1,
                        this.dateToDDMMYYY(sale?.billDateStamp),
                        sale?.billNo,
                        sale?.party?.name,
                        sale?.totalAmount,
                        x?.totalPurchaseAmount,
                        x?.totalSaleProfit
                    ];
                }),
                margin: {
                    top: 55
                },
                styles: {
                    font: 'hindi'
                }
            });
    
            doc.save('Sale_Wise_Profit_and_Loss_Report.pdf');
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:saleWisePnlReportDownload", error)  
        }
    }

    async saleWisePnlReportDownloadExcel() {
        try {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Sale Wise Pnl');
    
            worksheet.columns = [
                { header: 'Sr No', key: 'srNo', width: 8, alignment: { horizontal: "left" } },
                { header: 'Date', key: 'date', width: 13, alignment: { horizontal: "left" } },
                { header: 'Bill No', key: 'billNo', width: 13, alignment: { horizontal: "left" } },
                { header: 'Party Name', key: 'partyName', width: 30, alignment: { horizontal: "left" } },
                { header: 'Sale Amount', key: 'saleAmount', width: 15, alignment: { horizontal: "right" } },
                { header: 'Purchase Amount', key: 'purchaseAmount', width: 18, alignment: { horizontal: "right" } },
                { header: 'Profit', key: 'profit', width: 15, alignment: { horizontal: "right" } },
            ];
    
            worksheet.getRow(1).eachCell(cell => {
                cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FF3E80ED' },
                };
            });
    
            this.saleWisePnlReportData?.sales.map((x, index) => {
                let sale = x?.sale;
                worksheet.addRow([
                    index + 1,
                    this.dateToDDMMYYY(sale?.billDateStamp),
                    sale?.billNo,
                    sale?.party?.name,
                    sale?.totalAmount,
                    x?.totalPurchaseAmount,
                    x?.totalSaleProfit
                ]);
            });
    
            workbook.xlsx.writeBuffer().then(buffer => {
                const blob = new Blob([buffer], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                })
                saveAs(blob, 'Sale_Wise_Profit_and_Loss_Report.xlsx');
            });
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:saleWisePnlReportDownloadExcel", error)  
        }
    }

    async getPurchaseReportData(): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            try {
                this.purchaseReportData = null;

                this.purchaseReportData = {
                    purchases: [],
                    duration: `From ${Utility.dateToDDMMYYY(this.startStamp)} To ${Utility.dateToDDMMYYY(this.endStamp)}`,
                    totalPurchasesQuantity: 0,
                    totalPurchasesAmount: 0,
                    download: this.purchaseReportDownload.bind(this),
                    downloadExcel: this.purchaseReportDownloadExcel.bind(this)
                };

                const purchases = await this.allDataService.purchaseService.getByBillDateRange(this.startStamp, this.endStamp) || [];

                purchases?.forEach(purchase => {
                    let totalBillItemQuantity = 0;
                    purchase.billItems?.forEach(billItem => {
                        if(Utility.isNumber(billItem?.quantity)) {
                            totalBillItemQuantity += Number(billItem?.quantity);
                        }
                    });

                    this.purchaseReportData.totalPurchasesQuantity += totalBillItemQuantity;

                    if(Utility.isNumber(purchase?.totalAmount)) {
                        this.purchaseReportData.totalPurchasesAmount += Number(purchase.totalAmount);
                    }

                    let gstAmount = Utility.isNumber(purchase?.gstAmount) ? Number(purchase?.gstAmount) : 0;
                    let cessAmount = Utility.isNumber(purchase?.cessAmount) ? Number(purchase?.cessAmount) : 0;
                    let totalTax = gstAmount + cessAmount;

                    this.purchaseReportData.purchases.push({
                        purchase,
                        totalBillItemQuantity,
                        totalTax,
                    });
                });

                this.purchaseReportData.purchases.sort((a, b) => {
                    if (a?.purchase?.billDateStamp == b?.purchase?.billDateStamp) {
                        return a?.purchase?.createdStamp - b?.purchase?.createdStamp;
                    }
                    return a?.purchase?.billDateStamp - b?.purchase?.billDateStamp;
                });

                return resolve(true);
            } catch (error) {
                SentryUtilites.setLog("ReportsPage:getPurchaseReportData", error)  
                return resolve(false);
            }
        });
    }

    async purchaseReportDownload() {
        try {
            const doc = new jsPDF();
    
            doc.addFileToVFS("hindi.ttf", Fonts.hindi);
            doc.addFont("hindi.ttf", "hindi", "normal");
            doc.setFont("hindi");
    
            doc.setFontSize(18).setTextColor(56, 103, 214).text("Purchase Report", 15, 10);
            doc.setFontSize(12).setTextColor(116, 125, 140).text(`${this.selectedProfile?.legalName || this.selectedProfile?.profileName} (${this.selectedProfile?._localUUID?.substr(-4)})`, 15, 20);
            doc.setFontSize(11).setTextColor(116, 125, 140).text(this.authService.getLoginPhone(), 15, 26);
    
            doc.setFontSize(11).setTextColor(116, 125, 140).text("Total Purchases: " + this.purchaseReportData?.purchases?.length, 15, 35);
            doc.setFontSize(11).setTextColor(116, 125, 140).text("Total Purchase Quantity: " + this.purchaseReportData?.totalPurchasesQuantity, 15, 41);
            doc.setFontSize(11).setTextColor(116, 125, 140).text("Total Purchase Amount: " + this.purchaseReportData?.totalPurchasesAmount, 15, 47);
            doc.setFontSize(12).setTextColor(116, 125, 140).text(this.purchaseReportData?.duration, 135, 47);
    
            let body = [];
    
            this.purchaseReportData?.purchases?.forEach((x, index) => {
                let purchase = x?.purchase;
                // add purchase row
                body.push([
                    index + 1,
                    this.dateToDDMMYYY(purchase?.billDateStamp),
                    purchase?.billNo,
                    purchase?.party?.name,
                    x?.totalBillItemQuantity,
                    x?.totalTax,
                    purchase?.totalAmount,
                ]);
                if (purchase?.billItems?.length) {
                    // add billItem header
                    body.push([
                        '',
                        '#',
                        'Item Name',
                        'Qty',
                        'Unit',
                        'Rs/Unit (inc Taxes )',
                        'Tax Amount',
                        'Amount ( inc taxes )'
                    ]);
    
                    purchase?.billItems?.forEach((billItem, billItemIndex) => {
                        // add billItem
                        body.push([
                            '',
                            billItemIndex + 1,
                            billItem?.item?.itemName,
                            billItem?.quantity,
                            billItem?.unit,
                            billItem?.price,
                            billItem?.unitTaxAmount,
                            billItem?.total,
                        ]);
                    });
                }
            })
    
            autoTable(doc, {
                head: [['Sr No', 'Date', 'Txn No', 'Party Name', 'Total Quantity', 'Total Tax', 'Total Amount (inc taxes)']],
                body,
                didParseCell: (data) => {
                    if (data.section === 'body' && data.cell.raw == `#`) {
                        Object.keys(data?.row?.cells || {})?.forEach(cellIndex => {
                            if (data.row.cells[cellIndex]?.raw != '') {
                                data.row.cells[cellIndex].styles.textColor = '#FFFFFF';
                                data.row.cells[cellIndex].styles.fillColor = '#2596be';
                            }
                        });
                    }
                },
                margin: {
                    top: 55
                },
                styles: {
                    font: 'hindi'
                }
            });
    
            doc.save('Purchase_Report.pdf');
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:purchaseReportDownload", error)  
        }
    }

    async purchaseReportDownloadExcel() {
        try {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Purchase Report');
    
            worksheet.columns = [
                { header: 'Sr No', key: 'srNo', width: 8, alignment: { horizontal: "left" } },
                { header: 'Date', key: 'date', width: 13, alignment: { horizontal: "left" } },
                { header: 'Txn No', key: 'txnNo', width: 13, alignment: { horizontal: "left" } },
                { header: 'Party Name', key: 'partyName', width: 30, alignment: { horizontal: "left" } },
                { header: 'Total Quantity', key: 'totalQuantity', width: 15, alignment: { horizontal: "right" } },
                { header: 'Total Tax', key: 'totalTax', width: 15, alignment: { horizontal: "right" } },
                { header: 'Total Amount (inc taxes)', key: 'totalAmount', width: 25, alignment: { horizontal: "right" } },
            ];
    
            worksheet.getRow(1).eachCell(cell => {
                cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FF3E80ED' },
                };
            });
    
            this.purchaseReportData?.purchases.map((x, index) => {
                let purchase = x?.purchase;
                worksheet.addRow([
                    index + 1,
                    this.dateToDDMMYYY(purchase?.billDateStamp),
                    purchase?.billNo,
                    purchase?.party?.name,
                    x?.totalBillItemQuantity,
                    x?.totalTax,
                    purchase?.totalAmount,
                ]);
                if (purchase?.billItems?.length) {
                    worksheet.addRow([
                        '',
                        '#',
                        'Item Name',
                        'Qty',
                        'Unit',
                        'Rs/Unit (inc Taxes )',
                        'Tax Amount',
                        'Amount ( inc taxes )'
                    ]).eachCell((cell, cellNo) => {
                        if (cellNo != 1) {
                            cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FF3E80ED' },
                            };
                        }
                    });
    
                    purchase?.billItems?.forEach((billItem, billItemIndex) => {
                        worksheet.addRow([
                            '',
                            billItemIndex + 1,
                            billItem?.item?.itemName,
                            billItem?.quantity,
                            billItem?.unit,
                            billItem?.price,
                            billItem?.unitTaxAmount,
                            billItem?.total,
                        ]);
                    });
                }
            });
    
            workbook.xlsx.writeBuffer().then(buffer => {
                const blob = new Blob([buffer], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                })
                saveAs(blob, 'Purchase_Report.xlsx');
            });
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:purchaseReportDownloadExcel", error)  
        }
    }

    async getMoneyInReportData(): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            try {
                this.moneyInReportData = null;

                this.moneyInReportData = {
                    moneyIns: [],
                    duration: `From ${Utility.dateToDDMMYYY(this.startStamp)} To ${Utility.dateToDDMMYYY(this.endStamp)}`,
                    totalMoneyInsAmount: 0,
                    download: this.moneyInReportDownload.bind(this),
                    downloadExcel: this.moneyInReportDownloadExcel.bind(this),
                };

                const moneyIns = await this.allDataService.moneyInService.getByBillDateRange(this.startStamp, this.endStamp) || [];
                moneyIns?.sort((a, b) => b?.createdStamp - a?.createdStamp);
                for (let i = 0; i < moneyIns?.length; i++) {
                    let moneyIn = moneyIns[i];
                    let sale: Sale = null;
                    if (moneyIn?.linkedSaleUUID) {
                        sale = await this.allDataService.saleService.getByUUID(moneyIn?.linkedSaleUUID);
                    }
                    moneyIn['linkedSaleInfo'] = sale ? `${sale?.billNo} | ${this.stampTo12HrFormatDateTime(sale?.createdStamp)}` : '-';

                    if(Utility.isNumber(moneyIn?.totalAmount)) {
                        this.moneyInReportData.totalMoneyInsAmount += Number(moneyIn?.totalAmount);
                    }

                    this.moneyInReportData.moneyIns.push({ moneyIn });
                }

                return resolve(true);
            } catch (error) {
                SentryUtilites.setLog("ReportsPage:getMoneyInReportData", error)  
                return resolve(false);
            }
        });
    }

    async moneyInReportDownload() {
        try {
            const doc = new jsPDF();
    
            doc.addFileToVFS("hindi.ttf", Fonts.hindi);
            doc.addFont("hindi.ttf", "hindi", "normal");
            doc.setFont("hindi");
    
            doc.setFontSize(18).setTextColor(56, 103, 214).text("Money In Report", 15, 10);
            doc.setFontSize(12).setTextColor(116, 125, 140).text(`${this.selectedProfile?.legalName || this.selectedProfile?.profileName} (${this.selectedProfile?._localUUID?.substr(-4)})`, 15, 20);
            doc.setFontSize(11).setTextColor(116, 125, 140).text(this.authService.getLoginPhone(), 15, 26);
    
            doc.setFontSize(11).setTextColor(116, 125, 140).text("Total MoneyIns: " + this.moneyInReportData?.moneyIns?.length, 15, 35);
            doc.setFontSize(11).setTextColor(116, 125, 140).text("Total MoneyIn Amount: " + this.moneyInReportData?.totalMoneyInsAmount, 15, 41);
            doc.setFontSize(12).setTextColor(116, 125, 140).text(this.moneyInReportData?.duration, 135, 41);
    
            autoTable(doc, {
                head: [['Sr No', 'Date', 'Party Name', 'Txn Type', 'Txn No', 'Total Amount', 'Linked Sale',]],
                body: this.moneyInReportData?.moneyIns.map((x, index) => {
                    let moneyIn = x?.moneyIn;
                    return [
                        index + 1,
                        this.dateToDDMMYYY(moneyIn?.billDateStamp),
                        moneyIn?.party?.name,
                        moneyIn?.paymentMode,
                        moneyIn?.billNo,
                        moneyIn?.totalAmount,
                        moneyIn?.['linkedSaleInfo'] || '-',
                    ];
                }),
                margin: {
                    top: 49
                },
                styles: {
                    font: 'hindi'
                }
            });
    
            doc.save('Money_In_Report.pdf');
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:moneyInReportDownload", error)  
        }
    }

    async moneyInReportDownloadExcel() {
        try {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Money In Report');
    
            worksheet.columns = [
                { header: 'Sr No', key: 'srNo', width: 8, alignment: { horizontal: "left" } },
                { header: 'Date', key: 'date', width: 13, alignment: { horizontal: "left" } },
                { header: 'Party Name', key: 'partyName', width: 30, alignment: { horizontal: "left" } },
                { header: 'Txn Type', key: 'txnType', width: 13, alignment: { horizontal: "left" } },
                { header: 'Txn No', key: 'txnNo', width: 13, alignment: { horizontal: "left" } },
                { header: 'Total Amount', key: 'totalAmount', width: 15, alignment: { horizontal: "right" } },
                { header: 'Linked Sale', key: 'linkedSaleInfo', width: 30, alignment: { horizontal: "left" } },
            ];
    
            worksheet.getRow(1).eachCell(cell => {
                cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FF3E80ED' },
                };
            });
    
            this.moneyInReportData?.moneyIns.map((x, index) => {
                let moneyIn = x?.moneyIn;
                worksheet.addRow([
                    index + 1,
                    this.dateToDDMMYYY(moneyIn?.billDateStamp),
                    moneyIn?.party?.name,
                    moneyIn?.paymentMode,
                    moneyIn?.billNo,
                    Number(moneyIn?.totalAmount),
                    moneyIn?.['linkedSaleInfo'] || '-',
                ]);
            });
    
            workbook.xlsx.writeBuffer().then(buffer => {
                const blob = new Blob([buffer], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                })
                saveAs(blob, 'Money_In_Report.xlsx');
            });
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:moneyInReportDownloadExcel", error)  
        }
    }

    async getMoneyOutReportData(): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            try {
                this.moneyOutReportData = null;

                this.moneyOutReportData = {
                    moneyOuts: [],
                    duration: `From ${Utility.dateToDDMMYYY(this.startStamp)} To ${Utility.dateToDDMMYYY(this.endStamp)}`,
                    totalMoneyOutsAmount: 0,
                    download: this.moneyOutReportDownload.bind(this),
                    downloadExcel: this.moneyOutReportDownloadExcel.bind(this),
                };

                const moneyOuts = await this.allDataService.moneyOutService.getByBillDateRange(this.startStamp, this.endStamp) || [];
                moneyOuts?.sort((a, b) => b?.createdStamp - a?.createdStamp);
                for (let i = 0; i < moneyOuts?.length; i++) {
                    let moneyOut = moneyOuts[i];
                    let purchase: Purchase = null;
                    let expense: Expense = null;
                    if (moneyOut?.linkedPurchaseUUID) {
                        purchase = await this.allDataService.purchaseService.getByUUID(moneyOut?.linkedPurchaseUUID);
                    } else if (moneyOut?.linkedExpenseUUID) {
                        expense = await this.allDataService.expenseService.getByUUID(moneyOut?.linkedExpenseUUID);
                    }
                    moneyOut['linkedPurchaseExpenseInfo'] = purchase ? `${purchase?.billNo} | ${this.stampTo12HrFormatDateTime(purchase?.createdStamp)}` :
                        expense ? `${expense?.billNo} | ${this.dateToDDMMYYY(expense?.billDateStamp)}` : '-';

                    if(Utility.isNumber(moneyOut?.totalAmount)) {
                        this.moneyOutReportData.totalMoneyOutsAmount += Number(moneyOut?.totalAmount) ;
                    }

                    this.moneyOutReportData.moneyOuts.push({ moneyOut });
                }

                return resolve(true);
            } catch (error) {
                SentryUtilites.setLog("ReportsPage:getMoneyOutReportData", error)  
                return resolve(false);
            }
        });
    }

    async getExpenseReportData(): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            try {
                this.expenseReportData = null;

                this.expenseReportData = {
                    expenses: [],
                    duration: `From ${Utility.dateToDDMMYYY(this.startStamp)} To ${Utility.dateToDDMMYYY(this.endStamp)}`,
                    totalExpensesAmount: 0,
                    download: this.expenseReportDownload.bind(this),
                    downloadExcel: this.expenseReportDownloadExcel.bind(this),
                };

                const expenses = await this.allDataService.expenseService.getByBillDateRange(this.startStamp, this.endStamp) || [];

                expenses?.forEach(expense => {

                    if(Utility.isNumber(expense?.totalAmount)) {
                        this.expenseReportData.totalExpensesAmount += Number(expense?.totalAmount);
                    }

                    // mode of transaction logic
                    let moneyOutLength = expense?.moneyOuts?.length;
                    if(moneyOutLength) {
                        expense?.moneyOuts?.sort((a,b) => a?.updatedStamp - b?.updatedStamp);
                        expense['paymentMode'] = expense?.moneyOuts[0]?.paymentMode;
                    }

                    this.expenseReportData.expenses.push({ expense });
                });

                this.expenseReportData.expenses.sort((a, b) => {
                    if (b?.expense?.billDateStamp == a?.expense?.billDateStamp) {
                        return b?.expense?.createdStamp - a?.expense?.createdStamp;
                    }
                    return b?.expense?.billDateStamp - a?.expense?.billDateStamp;
                });

                return resolve(true);
            } catch (error) {
                SentryUtilites.setLog("ReportsPage:getExpenseReportData", error)  
                return resolve(false);
            }
        });
    }
    async moneyOutReportDownload() {
        try {
            const doc = new jsPDF();
    
            doc.addFileToVFS("hindi.ttf", Fonts.hindi);
            doc.addFont("hindi.ttf", "hindi", "normal");
            doc.setFont("hindi");
    
            doc.setFontSize(18).setTextColor(56, 103, 214).text("Money Out Report", 15, 10);
            doc.setFontSize(12).setTextColor(116, 125, 140).text(`${this.selectedProfile?.legalName || this.selectedProfile?.profileName} (${this.selectedProfile?._localUUID?.substr(-4)})`, 15, 20);
            doc.setFontSize(11).setTextColor(116, 125, 140).text(this.authService.getLoginPhone(), 15, 26);
    
            doc.setFontSize(11).setTextColor(116, 125, 140).text("Total MoneyOuts: " + this.moneyOutReportData?.moneyOuts?.length, 15, 35);
            doc.setFontSize(11).setTextColor(116, 125, 140).text("Total MoneyOut Amount: " + this.moneyOutReportData?.totalMoneyOutsAmount, 15, 41);
            doc.setFontSize(12).setTextColor(116, 125, 140).text(this.moneyOutReportData?.duration, 135, 41);
    
    
            autoTable(doc, {
                head: [['Sr No', 'Date', 'Party Name', 'Txn Type', 'Txn No', 'Total Amount', 'Linked Purchase / Expense']],
                body: this.moneyOutReportData?.moneyOuts.map((x, index) => {
                    let moneyOut = x?.moneyOut;
                    return [
                        index + 1,
                        this.dateToDDMMYYY(moneyOut?.billDateStamp),
                        moneyOut?.party?.name,
                        moneyOut?.paymentMode,
                        moneyOut?.billNo,
                        moneyOut?.totalAmount,
                        moneyOut?.['linkedPurchaseExpenseInfo'] || '-'
                    ];
                }),
                margin: {
                    top: 49
                },
                styles: {
                    font: 'hindi'
                }
            });
    
            doc.save('Money_Out_Report.pdf');
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:moneyOutReportDownload", error)  
        }
    }

    async moneyOutReportDownloadExcel() {
        try {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Money Out Report');
    
            worksheet.columns = [
                { header: 'Sr No', key: 'srNo', width: 8, alignment: { horizontal: "left" } },
                { header: 'Date', key: 'date', width: 13, alignment: { horizontal: "left" } },
                { header: 'Party Name', key: 'partyName', width: 30, alignment: { horizontal: "left" } },
                { header: 'Txn Type', key: 'txnType', width: 13, alignment: { horizontal: "left" } },
                { header: 'Txn No', key: 'txnNo', width: 13, alignment: { horizontal: "left" } },
                { header: 'Total Amount', key: 'totalAmount', width: 15, alignment: { horizontal: "right" } },
                { header: 'Linked Purchase / Expense', key: 'linkedPurchaseExpenseInfo', width: 30, alignment: { horizontal: "left" } },
            ];
    
            worksheet.getRow(1).eachCell(cell => {
                cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FF3E80ED' },
                };
            });
    
            this.moneyOutReportData?.moneyOuts.map((x, index) => {
                let moneyOut = x?.moneyOut;
                worksheet.addRow([
                    index + 1,
                    this.dateToDDMMYYY(moneyOut?.billDateStamp),
                    moneyOut?.party?.name,
                    moneyOut?.paymentMode,
                    moneyOut?.billNo,
                    moneyOut?.totalAmount,
                    moneyOut?.['linkedPurchaseExpenseInfo'] || '-'
                ]);
            });
    
            workbook.xlsx.writeBuffer().then(buffer => {
                const blob = new Blob([buffer], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                })
                saveAs(blob, 'Money_Out_Report.xlsx');
            });
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:moneyOutReportDownloadExcel", error)  
        }
    }

    async expenseReportDownload() {
        try {
            const doc = new jsPDF();
    
            doc.addFileToVFS("hindi.ttf", Fonts.hindi);
            doc.addFont("hindi.ttf", "hindi", "normal");
            doc.setFont("hindi");
    
            doc.setFontSize(18).setTextColor(56, 103, 214).text("Expense Report", 15, 10);
            doc.setFontSize(12).setTextColor(116, 125, 140).text(`${this.selectedProfile?.legalName || this.selectedProfile?.profileName} (${this.selectedProfile?._localUUID?.substr(-4)})`, 15, 20);
            doc.setFontSize(11).setTextColor(116, 125, 140).text(this.authService.getLoginPhone(), 15, 26);
    
            doc.setFontSize(11).setTextColor(116, 125, 140).text("Total Expenses: " + this.expenseReportData?.expenses?.length, 15, 35);
            doc.setFontSize(11).setTextColor(116, 125, 140).text("Total Expenses Amount: " + this.expenseReportData?.totalExpensesAmount, 15, 41);
            doc.setFontSize(12).setTextColor(116, 125, 140).text(this.expenseReportData?.duration, 135, 41);
    
    
            autoTable(doc, {
                head: [['Sr No', 'Date', 'Party Name', 'Expense Category', 'Expense No', 'Total Amount', 'Amount Paid', 'Txn Types']],
                body: this.expenseReportData?.expenses.map((x, index) => {
                    let expense = x?.expense;
                    return [
                        index + 1,
                        this.dateToDDMMYYY(expense?.billDateStamp),
                        expense?.party?.name,
                        expense?.category,
                        expense?.billNo,
                        expense?.totalAmount,
                        expense?.amountPaid,
                        expense?.['paymentMode'],
                    ];
                }),
                margin: {
                    top: 49
                },
                styles: {
                    font: 'hindi'
                }
            });
    
            doc.save('Expense_Report.pdf');
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:expenseReportDownload", error)  
        }
    }

    async expenseReportDownloadExcel() {
        try {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Expense Report');
    
            worksheet.columns = [
                { header: 'Sr No', key: 'srNo', width: 8, alignment: { horizontal: "left" } },
                { header: 'Date', key: 'date', width: 13, alignment: { horizontal: "left" } },
                { header: 'Party Name', key: 'partyName', width: 30, alignment: { horizontal: "left" } },
                { header: 'Expense Category', key: 'category', width: 13, alignment: { horizontal: "left" } },
                { header: 'Expense No', key: 'txnNo', width: 13, alignment: { horizontal: "left" } },
                { header: 'Total Amount', key: 'totalAmount', width: 15, alignment: { horizontal: "right" } },
                { header: 'Amount Paid', key: 'amountPaid', width: 15, alignment: { horizontal: "right" } },
                { header: 'Txn Types', key: 'paymentMode', width: 20, alignment: { horizontal: "right" } },      
            ];
    
            worksheet.getRow(1).eachCell(cell => {
                cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FF3E80ED' },
                };
            });
    
            this.expenseReportData?.expenses.map((x, index) => {
                let expense = x?.expense;
                worksheet.addRow([
                    index + 1,
                    this.dateToDDMMYYY(expense?.billDateStamp),
                    expense?.party?.name,
                    expense?.category,
                    expense?.billNo,
                    expense?.totalAmount,
                    expense?.amountPaid,
                    expense?.['paymentMode'],
                ]);
            });
    
            workbook.xlsx.writeBuffer().then(buffer => {
                const blob = new Blob([buffer], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                })
                saveAs(blob, 'Expense_Report.xlsx');
            });
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:expenseReportDownloadExcel", error)  
        }
    }

    async getPartyReportData(): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            if (this.selectedParty?._localUUID) {
                try {
                    this.partyReportData = null;

                    this.partyReportData = {
                        party: null,
                        records: [],
                        startTime: `${Utility.dateToDDMMYYY(this.startStamp)}`,
                        endTime: `${Utility.dateToDDMMYYY(this.endStamp)}`,
                        totalSalesAmount: 0,
                        totalMoneyInsAmount: 0,
                        totalPurchasesAmount: 0,
                        totalMoneyOutsAmount: 0,
                        download: this.partyReportDownload.bind(this),
                        downloadExcel: this.partyReportDownloadExcel.bind(this),
                    };

                    let party = await this.allDataService.partyService.getByUUID(this.selectedParty?._localUUID);
                    let sales = await this.allDataService.saleService.getByBillDateRange(this.startStamp, this.endStamp) || [];
                    let moneyIns = await this.allDataService.moneyInService.getByBillDateRange(this.startStamp, this.endStamp) || [];
                    let purchases = await this.allDataService.purchaseService.getByBillDateRange(this.startStamp, this.endStamp) || [];
                    let moneyOuts = await this.allDataService.moneyOutService.getByBillDateRange(this.startStamp, this.endStamp) || [];


                    // Note - Add in future -  saleReturn, purchaseReturn & expense

                    this.partyReportData.party = party;

                    sales?.forEach(sale => {
                        if (sale?.party?._localUUID === this.selectedParty?._localUUID) {
                            this.partyReportData.records.push({
                                txnType: 'Sale',
                                record: sale,
                            });
                            if(Utility.isNumber(sale?.totalAmount)) {
                                this.partyReportData.totalSalesAmount += Number(sale?.totalAmount);
                            }
                        }
                    });

                    moneyIns?.forEach(moneyIn => {
                        if (moneyIn?.party?._localUUID === this.selectedParty?._localUUID) {
                            this.partyReportData.records.push({
                                txnType: 'Money In',
                                record: moneyIn,
                            });
                            if(Utility.isNumber(moneyIn?.totalAmount)) {
                                this.partyReportData.totalMoneyInsAmount += Number(moneyIn?.totalAmount);
                            }
                        }
                    });

                    purchases?.forEach(purchase => {
                        if (purchase?.party?._localUUID === this.selectedParty?._localUUID) {
                            this.partyReportData.records.push({
                                txnType: 'Purchase',
                                record: purchase,
                            });
                            if(Utility.isNumber(purchase?.totalAmount)) {
                                this.partyReportData.totalPurchasesAmount += Number(purchase?.totalAmount);
                            }
                        }
                    });

                    moneyOuts?.forEach(moneyOut => {
                        if (moneyOut?.party?._localUUID === this.selectedParty?._localUUID) {
                            this.partyReportData.records.push({
                                txnType: 'Money Out',
                                record: moneyOut,
                            });
                            if(Utility.isNumber(moneyOut?.totalAmount)) {
                                this.partyReportData.totalMoneyOutsAmount += Number(moneyOut?.totalAmount);
                            }
                        }
                    });

                    this.partyReportData.records?.sort((a: any, b: any) => {
                        if (b?.record?.billDateStamp == a?.record?.billDateStamp) {
                            return b?.record?.createdStamp - a?.record?.createdStamp;
                        }
                        return b?.record?.billDateStamp - a?.record?.billDateStamp;
                    });

                    return resolve(true);
                } catch (error) {
                    SentryUtilites.setLog("ReportsPage:getPartyReportData", error)  
                    return resolve(false);
                }
            } else {
                return resolve(false);
            }
        });
    }

    async partyReportDownload() {
        try {
            const doc = new jsPDF();
    
            doc.addFileToVFS("hindi.ttf", Fonts.hindi);
            doc.addFont("hindi.ttf", "hindi", "normal");
            doc.setFont("hindi");
    
            doc.setFontSize(18).setTextColor(56, 103, 214).text("Party Statement Report", 15, 10);
            doc.setFontSize(12).setTextColor(116, 125, 140).text("To,",15,18)
            doc.setFontSize(12).setTextColor(116, 125, 140).text(`${this.selectedParty?.name}`, 15, 23);
    
            doc.setFontSize(12).setTextColor(116, 125, 140).text(`${this.selectedProfile?.legalName || this.selectedProfile?.profileName} (${this.selectedProfile?._localUUID?.substr(-4)})`, 15, 30);
            doc.setFontSize(11).setTextColor(116, 125, 140).text(this.authService.getLoginPhone(), 15, 36);
            doc.setFontSize(12).setTextColor(116, 125, 140).text(`From ${this.partyReportData?.startTime} To ${this.partyReportData?.endTime}`, 135, 46);
    
            doc.setFontSize(11).setTextColor(116, 125, 140).text("Total Sale Amount: " + this.partyReportData?.totalSalesAmount, 15, 44);
            doc.setFontSize(11).setTextColor(116, 125, 140).text("Total Purchase Amount: " + this.partyReportData?.totalPurchasesAmount, 15, 50); 
            doc.setFontSize(11).setTextColor(116, 125, 140).text("Total Money In Amount: " + this.partyReportData?.totalMoneyInsAmount, 15, 56); 
            doc.setFontSize(11).setTextColor(116, 125, 140).text("Total Money Out Amount: " + this.partyReportData?.totalMoneyOutsAmount, 15, 62); 
    
            let body = [];
    
            this.partyReportData?.records?.forEach((record, index) => {
                // add purchase row
                body.push([
                    this.dateToDDMMYYY(record?.record?.billDateStamp),
                    record?.txnType,
                    record?.record?.billNo,
                    (record?.txnType === 'Purchase' || record?.txnType === 'Money In') ? record?.record?.totalAmount : 0,
                    (record?.txnType === 'Sale' || record?.txnType === 'Money Out') ? record?.record?.totalAmount : 0,
                    '',
                ]);
                if (record?.record && record?.record['billItems'] && record?.record['billItems']?.length) {
                    // add billItem header
                    body.push([
                        '',
                        '#',
                        'Item Name',
                        'Qty',
                        'Unit',
                        'Rs/Unit',
                        'Amount'
                    ]);
    
                    record?.record['billItems']?.forEach((billItem, billItemIndex) => {
                        // add billItem
                        body.push([
                            '',
                            billItemIndex + 1,
                            billItem?.item?.itemName,
                            billItem?.quantity,
                            billItem?.unit,
                            billItem?.price,
                            billItem?.total,
                        ]);
                    });
                }
            })
    
            autoTable(doc, {
                head: [['Date', 'TXN Type', 'Invoice/Bill', 'Credit', 'Debit', '','']],
                body,
                didParseCell: (data) => {
                    if (data.section === 'body' && data.cell.raw == `#`) {
                        Object.keys(data?.row?.cells || {})?.forEach(cellIndex => {
                            if (data.row.cells[cellIndex]?.raw != '') {
                                data.row.cells[cellIndex].styles.textColor = '#FFFFFF';
                                data.row.cells[cellIndex].styles.fillColor = '#2596be';
                            }
                        });
                    }
                },
                margin: {
                    top: 70
                },
                styles: {
                    font: 'hindi'
                }
            });
    
            doc.save('Party_Statement_Report.pdf');
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:partyReportDownload", error)  
        }
    }

    async partyReportDownloadExcel() {
        try {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Party Statement Report');
    
            worksheet.addRow(["To: "]);
            worksheet.addRow([`${this.selectedParty?.name}`]);
            worksheet.addRow([`${this.selectedProfile?.legalName || this.selectedProfile?.profileName} (${this.selectedProfile?._localUUID?.substr(-4)})`]);
            worksheet.addRow([this.authService.getLoginPhone()]);
            worksheet.addRow([`From ${this.partyReportData?.startTime} To ${this.partyReportData?.endTime}`]);
            worksheet.addRow(['']);
            worksheet.addRow(["Total Sale Amount: " + this.partyReportData?.totalSalesAmount]);
            worksheet.addRow(["Total Purchase Amount: " + this.partyReportData?.totalPurchasesAmount]);
            worksheet.addRow(["Total Money In Amount: " + this.partyReportData?.totalMoneyInsAmount]);
            worksheet.addRow(["Total Money Out Amount: " + this.partyReportData?.totalMoneyOutsAmount]);
            worksheet.addRow([``]);
    
            worksheet.addRow(["Date","Txn Type","Invoice/Bill","Credit","Debit","",""]);
            let width = [13,13,30,15,15,10];
    
            worksheet.columns?.forEach((column,i)=>{
                column.width = width[i];
            })
    
            worksheet.getRow(12).eachCell(cell => {
                cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FF3E80ED' },
                };
                
            });
    
            this.partyReportData?.records.map((record, index) => {
                worksheet.addRow([
                    this.dateToDDMMYYY(record?.record?.billDateStamp),
                    record?.txnType,
                    record?.record?.billNo,
                    (record?.txnType === 'Purchase' || record?.txnType === 'Money In') ? Number(record?.record?.totalAmount) : 0,
                    (record?.txnType === 'Sale' || record?.txnType === 'Money Out') ? Number(record?.record?.totalAmount) : 0,
                    '',
                ]);
                if (record?.record && record?.record['billItems'] && record?.record['billItems']?.length) {
                    worksheet.addRow([
                        '',
                        '#',
                        'Item Name',
                        'Qty',
                        'Unit',
                        'Rs/Unit',
                        'Amount'
                    ]).eachCell((cell, colNo) => {
                        if (colNo != 1) {
                            cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FF3E80ED' },
                            };
                        }
                    });
    
                    record?.record['billItems']?.forEach((billItem, billItemIndex) => {
                        worksheet.addRow([
                            '',
                            billItemIndex + 1,
                            billItem?.item?.itemName,
                            billItem?.quantity,
                            billItem?.unit,
                            billItem?.price,
                            billItem?.total,
                        ]);
                    });
                }
            });
    
            for(let i=1; i<=11 ;i++){
                worksheet.mergeCells(`A${i}:G${i}`);
            }
    
            workbook.xlsx.writeBuffer().then(buffer => {
                const blob = new Blob([buffer], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                })
                saveAs(blob, 'Party_Statement_Report.xlsx');
            });
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:partyReportDownloadExcel", error)  
        }
    }

    async getPartyDetailReportData(): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            try {
                this.partyDetailReportData = null;

                this.partyDetailReportData = {
                    parties: await this.allDataService.partyService.getAllByPromise(),
                    duration: `${Utility.dateToDDMMYYY(+new Date())}`,
                    download: this.partyDetailReportDownload.bind(this),
                    downloadExcel: this.partyDetailReportDownloadExcel.bind(this)
                };

                const ledgerCreditHashMap = await this.monthWisePartyCreditService.getLedgerCreditHashMap();

                this.partyDetailReportData?.parties?.forEach((party) => {
                    party.credit = ledgerCreditHashMap[party?._localUUID];
                })

                this.partyDetailReportData?.parties.sort((a: any, b: any) => {
                    if (a?.name?.toLowerCase() == b?.name?.toLowerCase()) {
                        return a?.createdStamp - b?.createdStamp;
                    }
                    if (b?.name?.toLowerCase() > a?.name?.toLowerCase()) {
                        return 1;
                    }
                    return -1;
                });

                return resolve(true);
            } catch (error) {
                SentryUtilites.setLog("ReportsPage:getPartyDetailReportData", error)  
                return resolve(false);
            }
        });
    }

    async partyDetailReportDownload() {
        try {
            const doc = new jsPDF();
    
            doc.addFileToVFS("hindi.ttf", Fonts.hindi);
            doc.addFont("hindi.ttf", "hindi", "normal");
            doc.setFont("hindi");
    
            doc.setFontSize(18).setTextColor(56, 103, 214).text("Item Detail Report", 15, 10);
            doc.setFontSize(12).setTextColor(116, 125, 140).text(`${this.selectedProfile?.legalName || this.selectedProfile?.profileName} (${this.selectedProfile?._localUUID?.substr(-4)})`, 15, 20);
            doc.setFontSize(11).setTextColor(116, 125, 140).text(this.authService.getLoginPhone(), 15, 26);
            doc.setFontSize(12).setTextColor(116, 125, 140).text(this.itemReportData?.duration, 135, 26);
    
            autoTable(doc, {
                head: [['Sr No', 'Date', 'Txn Type', 'Txn No', 'Party Name', 'Qty/Unit', 'Sale Price', 'Purchase Price', 'Discount', 'Tax', 'Total Amount']],
                body: this.itemReportData?.records.map((x, index) => {
                    return [
                        index + 1,
                        this.dateToDDMMYYY(x?.record?.billDateStamp || x?.record?.updatedStamp || x?.record?.createdStamp),
                        x?.txnType,
                        x?.record?.billNo || '-',
                        x?.record?.party?.name || '-',
                        x?.billItem?.quantity || this.MATHABS(x?.record?.quantity),
                        x?.billItem?.price || '-',
                        x?.billItem?.item?.purchasePrice || '-',
                        x?.billItem?.unitDiscountAmount || '-',
                        x?.billItem?.unitTaxAmount || '-',
                        x?.billItem?.total || '-',
                    ];
                }),
                margin: {
                    top: 35
                },
                styles: {
                    font: 'hindi'
                }
            });
    
            doc.save('Item_Detail_Report.pdf');
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:partyDetailReportDownload", error)  
        }
    }

    async partyDetailReportDownloadExcel() {
        try {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Party Details Report');
    
            worksheet.columns = [
                { header: 'Sr No', key: 'srNo', width: 8, alignment: { horizontal: "left" } },
                { header: 'Party Name', key: 'partyName', width: 13, alignment: { horizontal: "left" } },
                { header: 'Phone', key: 'phone', width: 13, alignment: { horizontal: "left" } },
                { header: 'Category', key: 'category', width: 13, alignment: { horizontal: "left" } },
                { header: 'Credit', key: 'credit', width: 13, alignment: { horizontal: "left" } },
                { header: 'Type', key: 'type', width: 13, alignment: { horizontal: "left" } },
                { header: 'GSTIN', key: 'gstin', width: 13, alignment: { horizontal: "left" } },
                { header: 'Billing Type', key: 'billingType', width: 13, alignment: { horizontal: "left" } },
                { header: 'DOB', key: 'dob', width: 13, alignment: { horizontal: "left" } },
                { header: 'Business Name', key: 'businessName', width: 13, alignment: { horizontal: "left" } },
                { header: 'Email', key: 'email', width: 13, alignment: { horizontal: "left" } },
                { header: 'Billing Address', key: 'billingAddress', width: 13, alignment: { horizontal: "left" } },
                { header: 'Billing Provience', key: 'billingProvience', width: 13, alignment: { horizontal: "left" } },
                { header: 'Billing Postal Code', key: 'billingPostalCode', width: 13, alignment: { horizontal: "left" } },
                { header: 'Delivery Address', key: 'deliveryAddress', width: 13, alignment: { horizontal: "left" } },
                { header: 'Delivery Provience', key: 'deliveryProvience', width: 13, alignment: { horizontal: "left" } },
                { header: 'Delivery Postal Code', key: 'deliveryPostalCode', width: 13, alignment: { horizontal: "left" } },
                { header: 'Payment Term', key: 'paymentTerm', width: 13, alignment: { horizontal: "left" } },
                { header: 'Send Alerts', key: 'sendAlerts', width: 13, alignment: { horizontal: "left" } },
                { header: 'Favourite Party', key: 'favouriteParty', width: 13, alignment: { horizontal: "left" } },
            ];
    
            worksheet.getRow(1).eachCell(cell => {
                cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FF3E80ED' },
                };
            });
    
            this.partyDetailReportData?.parties?.map((party, index) => {
                worksheet.addRow([
                    index + 1,
                    party?.name,
                    party?.phone,
                    party?.category,
                    party?.credit,
                    party?.type,
                    party?.gstin,
                    party?.billingType,
                    this.dateToDDMMYYY(party?.dateOfBirth),
                    party?.businessName,
                    party?.email,
                    party?.billingAddress,
                    party?.billingProvience,
                    party?.billingPostalCode,
                    party?.deliveryAddress,
                    party?.deliveryProvience,
                    party?.deliveryPostalCode,
                    party?.paymentTerm,
                    party?.sendAlerts ? 'Yes' : 'No',
                    party?.isFavourite ? 'Yes' : 'No',
                ]);
            });
    
            workbook.xlsx.writeBuffer().then(buffer => {
                const blob = new Blob([buffer], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                })
                saveAs(blob, 'Party_Details_Report.xlsx');
            });
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:partyDetailReportDownloadExcel", error)  
        }
    }

    async getPartyReceivablePayableReportData(): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            try {
                this.partyReceivablePayableReportData = null;

                this.partyReceivablePayableReportData = {
                    customerRecords: [],
                    supplierRecords: [],
                    totalPayableAmount: 0,
                    totalReceivableAmount: 0,
                    download: this.partyReceivablePayableReportDownload.bind(this),
                    downloadExcel: this.partyReceivablePayableReportDownloadExcel.bind(this),
                };

                let parties = await this.allDataService.partyService.getAllByPromise();

                const ledgerCreditHashMap = await this.monthWisePartyCreditService.getLedgerCreditHashMap();

                parties?.forEach(party => {

                    if(party?._localUUID) {
                        party.credit = ledgerCreditHashMap[party?._localUUID];
    
                        let payableAmount = 0;
                        let receivableAmount = 0;
    
                        if (party?.credit) {
                            if (Number(party?.credit) < 0) {
                                payableAmount = Number(party?.credit) * -1;
                            } else {
                                receivableAmount = Number(party?.credit);
                            }
                        }
    
                        if (payableAmount || receivableAmount) {
                            if (party.type?.toLowerCase() === 'supplier') {
                                this.partyReceivablePayableReportData.supplierRecords.push({
                                    party,
                                    payableAmount,
                                    receivableAmount
                                });
                            } else {
                                this.partyReceivablePayableReportData.customerRecords.push({
                                    party,
                                    payableAmount,
                                    receivableAmount
                                });
                            }
                        }
    
                        this.partyReceivablePayableReportData.totalPayableAmount += payableAmount;
                        this.partyReceivablePayableReportData.totalReceivableAmount += receivableAmount;
                    }

                });

                return resolve(true);
            } catch (error) {
                SentryUtilites.setLog("ReportsPage:getPartyReceivablePayableReportData", error)  
                return resolve(false);
            }
        });
    }

    async partyReceivablePayableReportDownload() {
        try {
            const doc = new jsPDF();
    
            doc.addFileToVFS("hindi.ttf", Fonts.hindi);
            doc.addFont("hindi.ttf", "hindi", "normal");
            doc.setFont("hindi");
    
            doc.setFontSize(18).setTextColor(56, 103, 214).text("Payable Receivable Report", 15, 10);
            doc.setFontSize(12).setTextColor(116, 125, 140).text(`${this.selectedProfile?.legalName || this.selectedProfile?.profileName} (${this.selectedProfile?._localUUID?.substr(-4)})`, 15, 20);
            doc.setFontSize(11).setTextColor(116, 125, 140).text(this.authService.getLoginPhone(), 15, 26);
    
            doc.setFontSize(11).setTextColor(116, 125, 140).text("Total Parties: " + this.partyReceivablePayableReportData?.customerRecords?.length + this.partyReceivablePayableReportData?.customerRecords?.length, 15, 35);
            doc.setFontSize(11).setTextColor(116, 125, 140).text("Total Payable Amount: " + this.partyReceivablePayableReportData?.totalPayableAmount, 15, 41);
            doc.setFontSize(11).setTextColor(116, 125, 140).text("Total Receivable Amount: " + this.partyReceivablePayableReportData?.totalReceivableAmount, 15, 47);
    
            autoTable(doc, {
                head: [['Sr No', 'Customer Name', 'Customer Phone', 'Payable Amount', 'Receivable Amount']],
                body: this.partyReceivablePayableReportData?.customerRecords?.map((x, index) => {
                    let party = x?.party;
                    return [
                        index + 1,
                        party?.name,
                        party?.phone,
                        x?.payableAmount,
                        x?.receivableAmount
                    ];
                }),
                margin: {
                    top: 55
                },
                styles: {
                    font: 'hindi'
                }
            });
    
            autoTable(doc, {
                head: [['Sr No', 'Supplier Name', 'Supplier Phone', 'Payable Amount', 'Receivable Amount']],
                body: this.partyReceivablePayableReportData?.supplierRecords?.map((x, index) => {
                    let party = x?.party;
                    return [
                        index + 1,
                        party?.name,
                        party?.phone,
                        x?.payableAmount,
                        x?.receivableAmount
                    ];
                }),
                styles: {
                    font: 'hindi'
                }
            });
    
            doc.save('Payable_Receivable_Report.pdf');
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:partyReceivablePayableReportDownload", error)  
        }
    }

    async partyReceivablePayableReportDownloadExcel() {
        try {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Payable Receivable Report');
    
            worksheet.columns = [
                { header: 'Sr No', key: 'srNo', width: 8, alignment: { horizontal: "left" } },
                { header: 'Customer Name', key: 'customerName', width: 30, alignment: { horizontal: "left" } },
                { header: 'Customer Phone', key: 'customerPhone', width: 15, alignment: { horizontal: "left" } },
                { header: 'Payable Amount', key: 'payableAmount', width: 15, alignment: { horizontal: "left" } },
                { header: 'Receivable Amount', key: 'receivableAmount', width: 15, alignment: { horizontal: "right" } },
            ];
    
            worksheet.getRow(1).eachCell(cell => {
                cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FF3E80ED' },
                };
            });
    
            this.partyReceivablePayableReportData?.customerRecords?.map((x, index) => {
                let party = x?.party;
                worksheet.addRow([
                    index + 1,
                    party?.name,
                    party?.phone,
                    x?.payableAmount,
                    x?.receivableAmount
                ]);
            })
    
            worksheet.addRow(['', '', '', '', '']);
            worksheet.addRow(['', '', '', '', '']);
    
            worksheet.addRow(['Sr No', 'Supplier Name', 'Supplier Phone', 'Payable Amount', 'Receivable Amount'])
                .eachCell(cell => {
                    cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FF3E80ED' },
                    };
                });
    
            this.partyReceivablePayableReportData?.supplierRecords?.map((x, index) => {
                let party = x?.party;
                worksheet.addRow([
                    index + 1,
                    party?.name,
                    party?.phone,
                    x?.payableAmount,
                    x?.receivableAmount
                ]);
            })
    
            workbook.xlsx.writeBuffer().then(buffer => {
                const blob = new Blob([buffer], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                })
                saveAs(blob, 'Payable_Receivable_Report.xlsx');
            });
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:partyReceivablePayableReportDownloadExcel", error)  
        }
    }

    getCampaignReportData(): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
          try {
            this.partyCampaignReportData = null;

            this.partyCampaignReportData = {
              campaignId: '',
              campaigeDate: '',
              campaignMessage: '',
              campaignCustomInputs: '',
              campaignTotalSend: 0,
              campaignTotalDelivered: 0,
              campaignTotalRead: 0,
              campaignTotalReplies: 0,
              campaignReportData: [],
              download: this.partyCampaignReportDownload.bind(this),
              downloadExcel: this.partyCampaignReportDownloadExcel.bind(this),
            };

            let campaignData = {
              _localUUID: 'fgfvdfvz',
              updatedStamp: 435435353535,
              messageBody: `Hi PARTY_NAME
                            you are invited to BUSINESS_NAME for grand diwali dhamaka offer get CUSTOM_OFFER Off
                            contact us on - CONTACT_PERSON_PHONE`,
              variables:[
                {
                    key: `PARTY_NAME`,
                    value: `party.name`
                },
                {
                    key: `BUSINESS_NAME`,
                    value: `profile.legalName`
                },
                {
                    key: `CONTACT_PERSON_PHONE`,
                    value: `profile.contactPersonPhone`
                },
                {
                    key: `CUSTOM_OFFER`,
                    value: `custom.offer`
                },
              ],
              customInputsValue: {
                offer: "30"
              },
              parties: ["16e281df-4ff0-4239-8f17-03505d14f237", "75f473fe-3442-40ea-a66b-fe4e61bfa265", "3d14a34b-0435-4989-964c-f729313b34b7"]
            }

            this.partyCampaignReportData.campaignId = campaignData?._localUUID;
            this.partyCampaignReportData.campaigeDate = Utility?.dateToDDMMYYY(campaignData?.updatedStamp);
            this.partyCampaignReportData.campaignMessage = campaignData?.messageBody;

            let customVal = '( ';

            for(let key in campaignData?.customInputsValue) {
              customVal = `${customVal} ${key}: ${campaignData?.customInputsValue[key]}, `;
            };

            this.partyCampaignReportData.campaignCustomInputs = `${customVal} )`;

            let currentProfile: Profile = await this.allDataService.profileService.getCurrentProfile();
            let allParties: Party[] = await this.allDataService.partyService.getAllByPromise();

            campaignData?.parties?.forEach((x: string, index: number) => {
              let parties: Party[] = allParties.filter((party: Party) => party?._localUUID === x);
              let party: Party = parties[0];
              let msgBody: string = campaignData?.messageBody;
              for (let i = 0; i < campaignData?.variables?.length; i++) {
                let template = campaignData?.variables[i];
                let key = template?.key;
                let val = template?.value;
                let targetModal = val?.split('.')[0]?.toLowerCase();
                let targetKey = val?.split('.')[1];
                if (targetModal == 'profile') {
                  if (currentProfile[targetKey]) {
                    msgBody = msgBody.replace(
                        key,
                        currentProfile[targetKey]
                      );
                  }
                } else if (targetModal == 'party') {
                  if (party[targetKey]) {
                    msgBody = msgBody.replace(
                        key,
                        party[targetKey]
                      );
                  }
                } else if (targetModal == 'custom') {
                  msgBody = msgBody.replace(
                    key,
                    campaignData?.customInputsValue[targetKey]
                  );
                }
              }
              this.partyCampaignReportData?.campaignReportData.push({
                name: party?.name,
                phone: party?.phone,
                messageBody: msgBody,
                send: Utility?.dateToDDMMYYY(campaignData?.updatedStamp),
                delivered: Utility?.dateToDDMMYYY(campaignData?.updatedStamp),
                read: Utility?.dateToDDMMYYY(campaignData?.updatedStamp),
                reply: 'Great',
                replyTime: Utility?.dateToDDMMYYY(campaignData?.updatedStamp),
              })
            })

            return resolve(true);
          } catch (error) {
            SentryUtilites.setLog("ReportsPage:getCampaignReportData", error)  
            return resolve(false);
          }
        });
      }

      partyCampaignReportDownload() {
        try {
            const doc = new jsPDF();
    
            doc.addFileToVFS("hindi.ttf", Fonts.hindi);
            doc.addFont("hindi.ttf", "hindi", "normal");
            doc.setFont("hindi");
    
            doc.setFontSize(18).setTextColor(56, 103, 214).text("Campaign Report", 15, 10);
            doc.setFontSize(12).setTextColor(116, 125, 140).text(`${this.selectedProfile?.legalName || this.selectedProfile?.profileName} (${this.selectedProfile?._localUUID?.substr(-4)})`, 15, 20);
            doc.setFontSize(11).setTextColor(116, 125, 140).text(this.authService.getLoginPhone(), 15, 26);
    
            doc.setFontSize(11).setTextColor(116, 125, 140).text("Campaign Id: " + this.partyCampaignReportData?.campaignId, 15, 35);
            doc.setFontSize(11).setTextColor(116, 125, 140).text("Campaign Date: " + this.partyCampaignReportData?.campaigeDate, 15, 41);
            doc.setFontSize(11).setTextColor(116, 125, 140).text("Campaign Message: " + this.partyCampaignReportData?.campaignMessage, 15, 47);
            doc.setFontSize(11).setTextColor(116, 125, 140).text("Custom Campaign Value: " + this.partyCampaignReportData?.campaignCustomInputs, 15, 62);
            doc.setFontSize(11).setTextColor(116, 125, 140).text("Total Send: " + this.partyCampaignReportData?.campaignTotalSend, 15, 68);
            doc.setFontSize(11).setTextColor(116, 125, 140).text("Total Delivered: " + this.partyCampaignReportData?.campaignTotalDelivered, 15, 74);
            doc.setFontSize(11).setTextColor(116, 125, 140).text("Total Read: " + this.partyCampaignReportData?.campaignTotalRead, 15, 80);
            doc.setFontSize(11).setTextColor(116, 125, 140).text("Total Replies: " + this.partyCampaignReportData?.campaignTotalReplies, 15, 88);
    
            autoTable(doc, {
              head: [['Sr No', 'Name', 'Phone', 'Message', 'Send', 'Delivered', 'Read', 'Reply', 'Reply Time']],
              body: this.partyCampaignReportData?.campaignReportData.map((x, index) => {
                return [
                  index + 1,
                  x?.name,
                  x?.phone,
                  x?.messageBody,
                  x?.send,
                  x?.delivered,
                  x?.read,
                  x?.reply,
                  x?.replyTime,
                ];
              }),
              margin: {
                top: 96
              },
              styles: {
                font: 'hindi'
              }
            });
    
            doc.save('Campaign_Report.pdf');
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:partyCampaignReportDownload", error)  
        }

      }

      partyCampaignReportDownloadExcel() {
        try {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Payable Receivable Report');
    
            worksheet.columns = [
              { header: 'Sr No', key: 'srNo', width: 8, alignment: { horizontal: "left" } },
              { header: 'Name', key: 'Name', width: 30,  alignment: { horizontal: "left" } },
              { header: 'Phone', key: 'Phone', width: 15, alignment: { horizontal: "left" } },
              { header: 'Message', key: 'payableAmount', width: 50, alignment: { horizontal: "left" } },
              { header: 'Send', key: 'receivableAmount', width: 15, alignment: { horizontal: "left" } },
              { header: 'Delivered', key: 'receivableAmount', width: 15, alignment: { horizontal: "left" } },
              { header: 'Read', key: 'receivableAmount', width: 15, alignment: { horizontal: "left" } },
              { header: 'Reply', key: 'receivableAmount', width: 15, alignment: { horizontal: "left" } },
              { header: 'Reply Time', key: 'receivableAmount', width: 15, alignment: { horizontal: "left" } },
            ];
    
            worksheet.getRow(1).eachCell(cell => {
              cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FF3E80ED' },
              };
            });
    
            this.partyCampaignReportData?.campaignReportData.map((x, index) => {
              worksheet.addRow([
                index + 1,
                x?.name,
                x?.phone,
                x?.messageBody,
                x?.send,
                x?.delivered,
                x?.read,
                x?.reply,
                x?.replyTime,
              ]);
            })
    
            workbook.xlsx.writeBuffer().then(buffer => {
              const blob = new Blob([buffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              })
              saveAs(blob, 'Campaign_Report.xlsx');
            });
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:partyCampaignReportDownloadExcel", error)  
        }

      }

    async getStockSummaryReportData(): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            try {
                this.stockSummaryReportData = null;

                this.stockSummaryReportData = {
                    items: [],
                    duration: `${Utility.dateToDDMMYYY(+new Date().setHours(0, 0, 0, 0))}`,
                    totalLowStockItems: 0,
                    totalStocksValue: 0,
                    totalStocksValuation: 0,
                    download: this.stockSummaryReportDownload.bind(this),
                    downloadExcel: this.stockSummaryReportDownloadExcel.bind(this),
                };

                const items = await this.allDataService.itemService.getAllByPromise();

                const ledgerStockHashMap = await this.monthWiseItemStockService.getLedgerStockHashMap();
                
                items?.forEach(item => {
                    if(item?._localUUID) {
                        item.stock = ledgerStockHashMap[item?._localUUID];
                        let stockValue = Number((item?.stock) || 0) * (Number(item?.purchasePrice) || 0);
                        let stockValuation = (Number(item?.stock) || 0) * (Number(item?.sellPrice) || 0);
    
                        this.stockSummaryReportData.items.push({
                            item,
                            stockValue,
                            stockValuation,
                        });
    
                        this.stockSummaryReportData.totalStocksValue += stockValue;
                        this.stockSummaryReportData.totalStocksValuation += stockValuation;
                        this.stockSummaryReportData.totalLowStockItems += (item?.stock <= (item?.minStock || 0)) ? 1 : 0;
                    }
                });

                return resolve(true);
            } catch (error) {
                SentryUtilites.setLog("ReportsPage:getStockSummaryReportData", error)  
                return resolve(false);
            }
        });
    }

    async stockSummaryReportDownload() {
        try {
            const doc = new jsPDF();
    
            doc.addFileToVFS("hindi.ttf", Fonts.hindi);
            doc.addFont("hindi.ttf", "hindi", "normal");
            doc.setFont("hindi");
    
            doc.setFontSize(18).setTextColor(56, 103, 214).text("Stock Value Report", 15, 10);
            doc.setFontSize(12).setTextColor(116, 125, 140).text(`${this.selectedProfile?.legalName || this.selectedProfile?.profileName} (${this.selectedProfile?._localUUID?.substr(-4)})`, 15, 20);
            doc.setFontSize(11).setTextColor(116, 125, 140).text(this.authService.getLoginPhone(), 15, 26);
    
            doc.setFontSize(11).setTextColor(116, 125, 140).text("Total Unique Items: " + this.stockSummaryReportData?.items?.length, 15, 35);
            doc.setFontSize(11).setTextColor(116, 125, 140).text("Total Low Stock Items: " + this.stockSummaryReportData?.totalLowStockItems, 15, 41);
            doc.setFontSize(11).setTextColor(116, 125, 140).text("Total Stock Value: " + this.stockSummaryReportData?.totalStocksValue, 15, 47);
            doc.setFontSize(11).setTextColor(116, 125, 140).text("Total Stock Valuation: " + this.stockSummaryReportData?.totalStocksValuation, 15, 53);
            doc.setFontSize(12).setTextColor(116, 125, 140).text(`Date: ${this.stockSummaryReportData?.duration}`, 163, 53);
    
            autoTable(doc, {
                head: [['Sr No', 'Item Name', 'Item Category', 'Current Stock', 'Minimum Stock', 'Sale Price', 'Purchase Price', 'Stock Value', 'Stock Valuation']],
                body: this.stockSummaryReportData?.items.map((x, index) => {
                    let item = x?.item;
                    return [
                        index + 1,
                        item?.itemName,
                        item?.category,
                        item?.stock,
                        item?.minStock || 0,
                        item?.sellPrice,
                        item?.purchasePrice,
                        x?.stockValue,
                        x?.stockValuation,
                    ];
                }),
                margin: {
                    top: 61
                },
                styles: {
                    font: 'hindi'
                }
            });
    
            doc.save('Stock_Value_Report.pdf');
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:stockSummaryReportDownload", error)  
        }
    }

    async stockSummaryReportDownloadExcel() {
        try {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Stock Value Report');
    
            worksheet.columns = [
                { header: 'Sr No', key: 'srNo', width: 8, alignment: { horizontal: "left" } },
                { header: 'Item Name', key: 'itemName', width: 30, alignment: { horizontal: "left" } },
                { header: 'Item Category', key: 'itemCategory', width: 20, alignment: { horizontal: "left" } },
                { header: 'Current Stock', key: 'currentStock', width: 13, alignment: { horizontal: "left" } },
                { header: 'Minimum Stock', key: 'minimumStock', width: 13, alignment: { horizontal: "left" } },
                { header: 'Sale Price', key: 'salePrice', width: 15, alignment: { horizontal: "right" } },
                { header: 'Purchase Price', key: 'purchasePrice', width: 15, alignment: { horizontal: "right" } },
                { header: 'Stock Value', key: 'stockValue', width: 15, alignment: { horizontal: "right" } },
                { header: 'Stock Valuation', key: 'stockValuation', width: 15, alignment: { horizontal: "right" } },
            ];
    
            worksheet.getRow(1).eachCell(cell => {
                cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FF3E80ED' },
                };
            });
    
            this.stockSummaryReportData?.items?.map((x, index) => {
                let item = x?.item;
                worksheet.addRow([
                    index + 1,
                    item?.itemName,
                    item?.category,
                    item?.stock,
                    item?.minStock || 0,
                    item?.sellPrice,
                    item?.purchasePrice,
                    x?.stockValue,
                    x?.stockValuation,
                ]);
            })
    
            workbook.xlsx.writeBuffer().then(buffer => {
                const blob = new Blob([buffer], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                })
                saveAs(blob, 'Stock_Value_Report.xlsx');
            });
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:stockSummaryReportDownloadExcel", error)  
        }
    }

    async getItemSaleReportData(): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            try {
                this.itemSaleReportData = null;

                this.itemSaleReportData = {
                    records: [],
                    duration: `From ${Utility.dateToDDMMYYY(this.startStamp)} To ${Utility.dateToDDMMYYY(this.endStamp)}`,
                    totalSalesQuantity: 0,
                    totalSalesAmount: 0,
                    download: this.itemSaleReportDownload.bind(this),
                    downloadExcel: this.itemSaleReportDownloadExcel.bind(this),
                };

                const sales = await this.allDataService.saleService.getByBillDateRange(this.startStamp, this.endStamp) || [];

                sales?.forEach(sale => {
                    sale?.billItems?.forEach(billItem => {
                        const index = this.itemSaleReportData.records.findIndex(x => x?._localUUID === billItem?.item?._localUUID);
                        if (index !== -1) {

                            if(Utility.isNumber(billItem?.total)) {
                                this.itemSaleReportData.records[index].saleAmount += Number(billItem?.total);
                            }

                            if(Utility.isNumber(billItem?.quantity)) {
                                this.itemSaleReportData.records[index].saleQuantity += Number(billItem?.quantity);
                            }

                        } else {
                            this.itemSaleReportData.records.push({
                                _localUUID: billItem?.item?._localUUID,
                                itemName: billItem?.item?.itemName,
                                category: billItem?.item?.category,
                                saleAmount: Utility.isNumber(billItem?.total) ? billItem?.total : 0 ,
                                saleQuantity: billItem?.quantity
                            });
                        }
                    });
                });

                this.itemSaleReportData.records?.forEach(record => {

                    if(Utility.isNumber(record?.saleAmount)) {
                        this.itemSaleReportData.totalSalesAmount += Number(record?.saleAmount);
                    }

                    if(Utility.isNumber(record?.saleQuantity)) {
                        this.itemSaleReportData.totalSalesQuantity += Number(record?.saleQuantity);
                    }
                });

                return resolve(true);
            } catch (error) {
                SentryUtilites.setLog("ReportsPage:getItemSaleReportData", error)  
                return resolve(false);
            }
        });
    }

    async itemSaleReportDownload() {
        try {
            const doc = new jsPDF();
    
            doc.addFileToVFS("hindi.ttf", Fonts.hindi);
            doc.addFont("hindi.ttf", "hindi", "normal");
            doc.setFont("hindi");
    
            doc.setFontSize(18).setTextColor(56, 103, 214).text("Item Sale Report", 15, 10);
            doc.setFontSize(12).setTextColor(116, 125, 140).text(`${this.selectedProfile?.legalName || this.selectedProfile?.profileName} (${this.selectedProfile?._localUUID?.substr(-4)})`, 15, 20);
            doc.setFontSize(11).setTextColor(116, 125, 140).text(this.authService.getLoginPhone(), 15, 26);
    
            doc.setFontSize(11).setTextColor(116, 125, 140).text("Total Sale Quantity: " + this.itemSaleReportData?.totalSalesQuantity, 15, 35);
            doc.setFontSize(11).setTextColor(116, 125, 140).text("Total Sale Amount: " + this.itemSaleReportData?.totalSalesAmount, 15, 41);
            doc.setFontSize(12).setTextColor(116, 125, 140).text(this.itemSaleReportData?.duration, 135, 41);
    
            autoTable(doc, {
                head: [['Sr No', 'Item Name', 'Item Category', 'Item Sale Quantity', 'Total Sale Amount']],
                body: this.itemSaleReportData?.records?.map((x, index) => {
                    return [
                        index + 1,
                        x?.itemName,
                        x?.category,
                        x?.saleQuantity,
                        Utility.isNumber(x?.saleAmount) ? x?.saleAmount : 0
                    ];
                }),
                margin: {
                    top: 49
                },
                styles: {
                    font: 'hindi'
                }
            });
    
            doc.save('Item_Sale_Report.pdf');
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:itemSaleReportDownload", error)  
        }
    }

    async itemSaleReportDownloadExcel() {
        try {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Item Sale Report');
    
            worksheet.columns = [
                { header: 'Sr No', key: 'srNo', width: 8, alignment: { horizontal: "left" } },
                { header: 'Item Name', key: 'itemName', width: 30, alignment: { horizontal: "left" } },
                { header: 'Item Category', key: 'itemCategory', width: 20, alignment: { horizontal: "left" } },
                { header: 'Item Sale Quantity', key: 'itemSaleQuantity', width: 13, alignment: { horizontal: "right" } },
                { header: 'Total Sale Amount', key: 'itemSaleAmount', width: 15, alignment: { horizontal: "right" } },
            ];
    
            worksheet.getRow(1).eachCell(cell => {
                cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FF3E80ED' },
                };
            });
    
            this.itemSaleReportData?.records?.map((x, index) => {
                worksheet.addRow([
                    index + 1,
                    x?.itemName,
                    x?.category,
                    x?.saleQuantity,
                    Utility.isNumber(x?.saleAmount) ? x?.saleAmount : 0
                ]);
            })
    
            workbook.xlsx.writeBuffer().then(buffer => {
                const blob = new Blob([buffer], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                })
                saveAs(blob, 'Item_Sale_Report.xlsx');
            });
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:itemSaleReportDownloadExcel", error)  
        }
    }

    async getItemReportData(): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            if (this.selectedItem?._localUUID) {
                try {
                    this.itemReportData = null;

                    this.itemReportData = {
                        item: null,
                        records: [],
                        duration: `From ${Utility.dateToDDMMYYY(this.startStamp)} To ${Utility.dateToDDMMYYY(this.endStamp)}`,
                        totalSalesAmount: 0,
                        totalSalesQuantity: 0,
                        totalPurchasesAmount: 0,
                        totalPurchasesQuantity: 0,
                        totalAddedQuantity: 0,
                        totalReducedQuantity: 0,
                        download: this.itemReportDownload.bind(this),
                        downloadExcel: this.itemReportDownloadExcel.bind(this)
                    };

                    let item = await this.allDataService.itemService.getByUUID(this.selectedItem?._localUUID);
                    let sales = await this.allDataService.saleService.getByBillDateRange(this.startStamp, this.endStamp) || [];
                    let purchases = await this.allDataService.purchaseService.getByBillDateRange(this.startStamp, this.endStamp) || [];
                    let allStockAdjust = await this.allDataService.itemStockAdjustService.getAllByLinkedItemUUIDByDateRange(this.selectedItem?._localUUID, this.startStamp, this.endStamp) || [];


                    // Note - Add in future -  saleReturn, purchaseReturn & expense

                    this.itemReportData.item = item;

                    sales?.forEach(sale => {
                        if (sale?.billItems?.length) {
                            sale?.billItems?.forEach(billItem => {
                                if (billItem?.item?._localUUID === this.selectedItem?._localUUID) {

                                    if(Utility.isNumber(billItem?.total)) {
                                        this.itemReportData.totalSalesAmount += Number(billItem?.total);
                                    }

                                    if(Utility.isNumber(billItem?.quantity)) {
                                        this.itemReportData.totalSalesQuantity += Number(billItem?.quantity);
                                    }

                                    this.itemReportData?.records?.push({
                                        txnType: 'Sale',
                                        billItem,
                                        record: sale,
                                    });
                                }
                            });
                        }
                    });

                    purchases?.forEach(purchase => {
                        if (purchase?.billItems?.length) {
                            purchase?.billItems?.forEach(billItem => {
                                if (billItem?.item?._localUUID === this.selectedItem?._localUUID) {

                                    if(Utility.isNumber(billItem?.total)) {
                                        this.itemReportData.totalPurchasesAmount += Number(billItem?.total);
                                    }

                                    if(Utility.isNumber(billItem?.quantity)) {
                                        this.itemReportData.totalPurchasesQuantity += Number(billItem?.quantity);
                                    }

                                    this.itemReportData?.records?.push({
                                        txnType: 'Purchase',
                                        billItem,
                                        record: purchase,
                                    });
                                }
                            });
                        }
                    });

                    allStockAdjust?.forEach(stockAdjust => {
                        stockAdjust['billDateStamp'] = stockAdjust?.createdStamp;
                        if (stockAdjust?.quantity > 0) {
                            this.itemReportData.totalAddedQuantity += Math.abs(stockAdjust?.quantity);
                            this.itemReportData.records.push({
                                txnType: 'Item Add',
                                billItem: null,
                                record: stockAdjust,
                            });
                        } else {
                            this.itemReportData.totalReducedQuantity += Math.abs(stockAdjust?.quantity);
                            this.itemReportData.records.push({
                                txnType: 'Item Reduce',
                                billItem: null,
                                record: stockAdjust,
                            });
                        }
                    });

                    this.itemReportData.records?.sort((a, b) => {
                        if (a?.record?.billDateStamp == b?.record?.billDateStamp) {
                            return a?.record?.createdStamp - b?.record?.createdStamp;
                        }
                        return a?.record?.billDateStamp - b?.record?.billDateStamp;
                    });

                    return resolve(true);
                } catch (error) {
                    SentryUtilites.setLog("ReportsPage:getItemReportData", error)  
                    return resolve(false);
                }
            } else {
                return resolve(false);
            }
        });
    }

    async itemReportDownload() {
        try {
            const doc = new jsPDF();
    
            doc.addFileToVFS("hindi.ttf", Fonts.hindi);
            doc.addFont("hindi.ttf", "hindi", "normal");
            doc.setFont("hindi");
    
            doc.setFontSize(18).setTextColor(56, 103, 214).text("Item Report", 15, 10);
            doc.setFontSize(12).setTextColor(116, 125, 140).text(`${this.selectedProfile?.legalName || this.selectedProfile?.profileName} (${this.selectedProfile?._localUUID?.substr(-4)})`, 15, 20);
            doc.setFontSize(11).setTextColor(116, 125, 140).text(this.authService.getLoginPhone(), 15, 26);
    
            doc.setFontSize(11).setTextColor(116, 125, 140).text("Item Name: " + this.itemReportData?.item?.itemName, 15, 35);
            doc.setFontSize(11).setTextColor(116, 125, 140).text("Item Category: " + this.itemReportData?.item?.category, 15, 41);
            doc.setFontSize(11).setTextColor(116, 125, 140).text("Quantity Added: " + this.itemReportData?.totalAddedQuantity, 15, 47);
            doc.setFontSize(12).setTextColor(116, 125, 140).text(this.itemReportData?.duration, 135, 47);
    
            autoTable(doc, {
                head: [['Sr No', 'Date', 'Txn Type', 'Txn No', 'Party Name', 'Qty/Unit', 'Sale Price', 'Purchase Price', 'Discount', 'Tax', 'Total Amount']],
                body: this.itemReportData?.records.map((x, index) => {
                    return [
                        index + 1,
                        this.dateToDDMMYYY(x?.record?.billDateStamp || x?.record?.updatedStamp || x?.record?.createdStamp),
                        x?.txnType,
                        x?.record?.billNo || '-',
                        x?.record?.party?.name || '-',
                        x?.billItem?.quantity || this.MATHABS(x?.record?.quantity),
                        x?.billItem?.price || '-',
                        x?.billItem?.item?.purchasePrice || '-',
                        x?.billItem?.unitDiscountAmount || '-',
                        x?.billItem?.unitTaxAmount || '-',
                        x?.billItem?.total || '-',
                    ];
                }),
                margin: {
                    top: 55
                },
                styles: {
                    font: 'hindi'
                }
            });
    
            doc.save('Item_Report.pdf');
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:itemReportDownload", error)  
        }
    }

    async itemReportDownloadExcel() {
        try {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Item Sale Report');
    
            worksheet.columns = [
                { header: 'Sr No', key: 'srNo', width: 8, alignment: { horizontal: "left" } },
                { header: 'Date', key: 'date', width: 13, alignment: { horizontal: "left" } },
                { header: 'Txn Type', key: 'txnType', width: 13, alignment: { horizontal: "left" } },
                { header: 'Txn No', key: 'txnNo', width: 13, alignment: { horizontal: "left" } },
                { header: 'Party Name', key: 'partyName', width: 30, alignment: { horizontal: "left" } },
                { header: 'Qty/Unit', key: 'quantity', width: 15, alignment: { horizontal: "left" } },
                { header: 'Sale Price', key: 'itemSaleAmount', width: 15, alignment: { horizontal: "right" } },
                { header: 'Purchase Price', key: 'salePrice', width: 15, alignment: { horizontal: "right" } },
                { header: 'Discount', key: 'discount', width: 15, alignment: { horizontal: "right" } },
                { header: 'Tax', key: 'tax', width: 15, alignment: { horizontal: "right" } },
                { header: 'Total Amount', key: 'totalAmount', width: 15, alignment: { horizontal: "right" } },
            ];
    
            worksheet.getRow(1).eachCell(cell => {
                cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FF3E80ED' },
                };
            });
    
            this.itemReportData?.records.map((x, index) => {
                worksheet.addRow([
                    index + 1,
                    this.dateToDDMMYYY(x?.record?.billDateStamp || x?.record?.updatedStamp || x?.record?.createdStamp),
                    x?.txnType,
                    x?.record?.billNo || '-',
                    x?.record?.party?.name || '-',
                    x?.billItem?.quantity || this.MATHABS(x?.record?.quantity),
                    x?.billItem?.price || '-',
                    x?.billItem?.item?.purchasePrice || '-',
                    x?.billItem?.unitDiscountAmount || '-',
                    x?.billItem?.unitTaxAmount || '-',
                    x?.billItem?.total || '-',
                ]);
            });
    
            workbook.xlsx.writeBuffer().then(buffer => {
                const blob = new Blob([buffer], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                })
                saveAs(blob, 'Item_Sale_Report.xlsx');
            });
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:itemReportDownloadExcel", error)  
        }
    }

    async getItemDetailReportData(): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            try {
                this.itemDetailReportData = null;

                this.itemDetailReportData = {
                    items: [],
                    duration: `${Utility.dateToDDMMYYY(+new Date())}`,
                    download: this.itemDetailReportDownload.bind(this),
                    downloadExcel: this.itemDetailReportDownloadExcel.bind(this)
                };

                let items = await this.allDataService.itemService.getAllByPromise();

                const ledgerStockHashMap = await this.monthWiseItemStockService.getLedgerStockHashMap();

                items?.forEach(item => {
                    if(item?._localUUID) {
                        item.stock = ledgerStockHashMap[item?._localUUID];
                        let discount: number = Number(item?.mrp || 0) - Number(item?.sellPrice || 0);
                        discount = discount > 0 ? discount : 0;
                        let discountPercentage = ((Number(item?.mrp || 0) - Number(item?.sellPrice || 0)) / Number(item?.mrp || 0)) * 100;
                        discountPercentage = discountPercentage > 0 ? discountPercentage : 0;
                        this.itemDetailReportData?.items?.push({
                            item,
                            discount,
                            discountPercentage
                        })
                    }
                });

                this.itemDetailReportData?.items?.sort((a: any, b: any) => {
                    if (a?.item?.itemName?.toLowerCase() == b?.item?.itemName?.toLowerCase()) {
                        return a?.item?.createdStamp - b?.item?.createdStamp;
                    }
                    if (b?.item?.itemName?.toLowerCase() > a?.item?.itemName?.toLowerCase()) {
                        return -1;
                    }
                    return 1;
                });

                return resolve(true);
            } catch (error) {
                SentryUtilites.setLog("ReportsPage:getItemDetailReportData", error)  
                return resolve(false);
            }
        });
    }

    async itemDetailReportDownload() {
        try {
            const doc = new jsPDF();
    
            doc.addFileToVFS("hindi.ttf", Fonts.hindi);
            doc.addFont("hindi.ttf", "hindi", "normal");
            doc.setFont("hindi");
    
            doc.setFontSize(18).setTextColor(56, 103, 214).text("Item Detail Report", 15, 10);
            doc.setFontSize(12).setTextColor(116, 125, 140).text(`${this.selectedProfile?.legalName || this.selectedProfile?.profileName} (${this.selectedProfile?._localUUID?.substr(-4)})`, 15, 20);
            doc.setFontSize(11).setTextColor(116, 125, 140).text(this.authService.getLoginPhone(), 15, 26);
            doc.setFontSize(12).setTextColor(116, 125, 140).text(this.itemReportData?.duration, 135, 26);
    
            autoTable(doc, {
                head: [['Sr No', 'Date', 'Txn Type', 'Txn No', 'Party Name', 'Qty/Unit', 'Sale Price', 'Purchase Price', 'Discount', 'Tax', 'Total Amount']],
                body: this.itemReportData?.records?.map((x, index) => {
                    return [
                        index + 1,
                        this.dateToDDMMYYY(x?.record?.billDateStamp || x?.record?.updatedStamp || x?.record?.createdStamp),
                        x?.txnType,
                        x?.record?.billNo || '-',
                        x?.record?.party?.name || '-',
                        x?.billItem?.quantity || this.MATHABS(x?.record?.quantity),
                        x?.billItem?.price || '-',
                        x?.billItem?.item?.purchasePrice || '-',
                        x?.billItem?.unitDiscountAmount || '-',
                        x?.billItem?.unitTaxAmount || '-',
                        x?.billItem?.total || '-',
                    ];
                }),
                margin: {
                    top: 35
                },
                styles: {
                    font: 'hindi'
                }
            });
    
            doc.save('Item_Detail_Report.pdf');
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:itemDetailReportDownload", error)  
        }
    }

    async itemDetailReportDownloadExcel() {
        try {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Item Details Report');
    
            worksheet.columns = [
                { header: 'Sr No', key: 'srNo', width: 8, alignment: { horizontal: "left" } },
                { header: 'Item Name', key: 'itemName', width: 13, alignment: { horizontal: "left" } },
                { header: 'Sell Price', key: 'sellPrice', width: 13, alignment: { horizontal: "left" } },
                { header: 'Purchase Price', key: 'purchasePrice', width: 13, alignment: { horizontal: "left" } },
                { header: 'Category', key: 'category', width: 13, alignment: { horizontal: "left" } },
                { header: 'Stock', key: 'stock', width: 13, alignment: { horizontal: "left" } },
                { header: 'Type', key: 'type', width: 13, alignment: { horizontal: "left" } },
                { header: 'MRP', key: 'mrp', width: 13, alignment: { horizontal: "left" } },
                { header: 'Expiry Date', key: 'expiryDate', width: 13, alignment: { horizontal: "left" } },
                { header: 'Brand Name', key: 'brandName', width: 13, alignment: { horizontal: "left" } },
                { header: 'Wholesale Price', key: 'wholesalePrice', width: 13, alignment: { horizontal: "left" } },
                { header: 'Wholesale Min CutOff Qty', key: 'wholesaleMinCutOffQty', width: 13, alignment: { horizontal: "left" } },
                { header: 'Item Code', key: 'itemCode', width: 13, alignment: { horizontal: "left" } },
                { header: 'Barcode', key: 'barcode', width: 13, alignment: { horizontal: "left" } },
                { header: 'Description', key: 'description', width: 13, alignment: { horizontal: "left" } },
                { header: 'Minimum Stock', key: 'minimumStock', width: 13, alignment: { horizontal: "left" } },
                { header: 'Storage Location', key: 'storageLocation', width: 13, alignment: { horizontal: "left" } },
                { header: 'Online DukKan Item', key: 'onlineDukKanItem', width: 13, alignment: { horizontal: "left" } },
                { header: 'Tax Percentage', key: 'taxPercentage', width: 13, alignment: { horizontal: "left" } },
                { header: 'Cess Percentage', key: 'cessPercentage', width: 13, alignment: { horizontal: "left" } },
                { header: 'Sell Price Inclusive Tax', key: 'sellPriceInclusiveTax', width: 13, alignment: { horizontal: "left" } },
                { header: 'Primary Unit', key: 'primaryUnit', width: 13, alignment: { horizontal: "left" } },
                { header: 'Secondary Unit', key: 'secondaryUnit', width: 13, alignment: { horizontal: "left" } },
                { header: 'HSN', key: 'hsn', width: 13, alignment: { horizontal: "left" } },
                { header: 'Convert Ratio', key: 'convertRatio', width: 13, alignment: { horizontal: "left" } },
                { header: 'Convert Ratio R', key: 'convertRatioR', width: 13, alignment: { horizontal: "left" } },
                { header: 'Note', key: 'note', width: 13, alignment: { horizontal: "left" } },
                { header: 'Discount', key: 'discount', width: 13, alignment: { horizontal: "left" } },
                { header: 'Discount Percentage', key: 'discountPercentage', width: 13, alignment: { horizontal: "left" } },
            ];
    
            worksheet.getRow(1).eachCell(cell => {
                cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FF3E80ED' },
                };
            });
    
            this.itemDetailReportData?.items.map((record, index) => {
                worksheet.addRow([
                    index + 1,
                    record?.item?.itemName,
                    record?.item?.sellPrice,
                    record?.item?.purchasePrice,
                    record?.item?.category,
                    record?.item?.stock,
                    record?.item?.type,
                    record?.item?.mrp,
                    this.dateToDDMMYYY(record?.item?.expiryDate),
                    record?.item?.brandName,
                    record?.item?.wholesalePrice,
                    record?.item?.wholesaleMinCutOffQty,
                    record?.item?.itemCode,
                    record?.item?.barcode,
                    record?.item?.description,
                    record?.item?.minStock,
                    record?.item?.storageLocation,
                    record?.item?.onlineDukanItem ? 'Yes' : 'No',
                    record?.item?.taxPercentage,
                    record?.item?.cessPercentage,
                    record?.item?.spIncTax ? 'Yes' : 'No',
                    record?.item?.primaryUnit,
                    record?.item?.secondaryUnit,
                    record?.item?.hsn,
                    record?.item?.convertRatio,
                    record?.item?.convertRatioR,
                    record?.item?.note,
                    record?.discount,
                    record?.discountPercentage,
                ]);
            });
    
            workbook.xlsx.writeBuffer().then(buffer => {
                const blob = new Blob([buffer], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                })
                saveAs(blob, 'Item_Details_Report.xlsx');
            });
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:itemDetailReportDownloadExcel", error)  
        }
    }
    
    closeDayBookReport() {
        this.startStamp = this.endStamp = null;
        this.isCutOffDayDatesModalOpen = false;
    }

    async getDayBookReportData(): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            try {

                this.dayBookReportData = null;
                this.dayBookReportData = {
                    records: [],
                    duration: `From ${Utility.dateToDDMMYYY(this.startStamp)} To ${Utility.dateToDDMMYYY(this.endStamp)}`,
                    totalSalesAmount: 0,
                    totalPurchasesAmount: 0,
                    totalMoneyInsAmount: 0,
                    totalMoneyInsUPIAmount: 0,
                    totalMoneyInsChequeAmount: 0,
                    totalMoneyInsCashAmount: 0,
                    totalMoneyOutsAmount: 0,
                    totalMoneyOutsUPIAmount: 0,
                    totalMoneyOutsChequeAmount: 0,
                    totalMoneyOutsCashAmount: 0,
                    print: () => {
                        window?.print();
                    }
                };

                let sales = null;
                let purchases = null;
                let moneyIns = null;
                let moneyOuts = null;

                if (this.selectedReport == "CutOffDayBookReport") {
                    this.dayBookReportData.duration = `From ${Utility.stampTo12HrFormatDateTime(this.startStamp)} To ${Utility.stampTo12HrFormatDateTime(this.endStamp)}`;
                    sales = await this.allDataService.saleService.getByBillCompleteDateRange(this.startStamp, this.endStamp) || [];
                    purchases = await this.allDataService.purchaseService.getByCreatedDateRange(this.startStamp, this.endStamp) || [];
                    moneyIns = await this.allDataService.moneyInService.getByCreatedDateRange(this.startStamp, this.endStamp) || [];
                    moneyOuts = await this.allDataService.moneyOutService.getByCreatedDateRange(this.startStamp, this.endStamp) || [];
                } else {
                    sales = await this.allDataService.saleService.getByBillDateRange(this.startStamp, this.endStamp) || [];
                    purchases = await this.allDataService.purchaseService.getByBillDateRange(this.startStamp, this.endStamp) || [];
                    moneyIns = await this.allDataService.moneyInService.getByBillDateRange(this.startStamp, this.endStamp) || [];
                    moneyOuts = await this.allDataService.moneyOutService.getByBillDateRange(this.startStamp, this.endStamp) || [];
                }

                // Note - Add in future -  saleReturn, purchaseReturn & expense

                sales?.forEach(sale => {
                    this.dayBookReportData.totalSalesAmount += Number(sale?.totalAmount);
                    this.dayBookReportData.records.push({
                        txnType: 'Sale',
                        record: sale,
                    });
                });

                purchases?.forEach(purchase => {
                    this.dayBookReportData.totalPurchasesAmount += Number(purchase?.totalAmount);
                    this.dayBookReportData.records.push({
                        txnType: 'Purchase',
                        record: purchase,
                    });
                });

                moneyIns?.forEach(moneyIn => {
                    this.dayBookReportData.totalMoneyInsAmount += Number(moneyIn?.totalAmount);
                    if (moneyIn.paymentMode === 'cash' || !moneyIn.paymentMode) {
                        this.dayBookReportData.totalMoneyInsCashAmount += Number(moneyIn?.totalAmount);
                    } else if (moneyIn.paymentMode === 'bank') {
                        this.dayBookReportData.totalMoneyInsUPIAmount += Number(moneyIn?.totalAmount);
                    } else if (moneyIn.paymentMode === 'cheque') {
                        this.dayBookReportData.totalMoneyInsChequeAmount += Number(moneyIn?.totalAmount);
                    }
                    this.dayBookReportData.records.push({
                        txnType: 'Money In',
                        record: moneyIn,
                    });
                });

                moneyOuts?.forEach(moneyOut => {
                    this.dayBookReportData.totalMoneyOutsAmount += Number(moneyOut?.totalAmount);
                    if (moneyOut.paymentMode === 'cash' || !moneyOut.paymentMode) {
                        this.dayBookReportData.totalMoneyOutsCashAmount += Number(moneyOut?.totalAmount);
                    } else if (moneyOut.paymentMode === 'bank') {
                        this.dayBookReportData.totalMoneyOutsUPIAmount += Number(moneyOut?.totalAmount);
                    } else if (moneyOut.paymentMode === 'cheque') {
                        this.dayBookReportData.totalMoneyOutsChequeAmount += Number(moneyOut?.totalAmount);
                    }
                    this.dayBookReportData.records.push({
                        txnType: 'Money Out',
                        record: moneyOut,
                    });
                });

                this.dayBookReportData.records?.sort((a: any, b: any) => {
                    if (b?.record?.billDateStamp == a?.record?.billDateStamp) {
                        return b?.record?.createdStamp - a?.record?.createdStamp;
                    }
                    return b?.record?.billDateStamp - a?.record?.billDateStamp;
                });

                return resolve(true);
            } catch (error) {
                SentryUtilites.setLog("ReportsPage:getDayBookReportData", error)  
                return resolve(false);
            }
        });
    }

    async getAllProfilesDayBookReportData(): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            try {
                this.allProfilesDayBookReportData = null;

                this.allProfilesDayBookReportData = {
                    duration: `From ${Utility.dateToDDMMYYY(this.startStamp)} To ${Utility.dateToDDMMYYY(this.endStamp)}`,
                    profiles: [],
                };

                let allProfiles = await this.allDataService.profileService.getAllByPromise();

                for (let i = 0; i < allProfiles?.length; i++) {
                    const profile = allProfiles[i];
                    if (profile?.userId == this.authService.getLoginPhone()) {

                        let dayBookReportData = {
                            profile,
                            totalSalesAmount: 0,
                            totalPurchasesAmount: 0,
                            totalMoneyInsAmount: 0,
                            totalMoneyInsUPIAmount: 0,
                            totalMoneyInsChequeAmount: 0,
                            totalMoneyInsCashAmount: 0,
                            totalMoneyOutsAmount: 0,
                            totalMoneyOutsUPIAmount: 0,
                            totalMoneyOutsChequeAmount: 0,
                            totalMoneyOutsCashAmount: 0,
                        };

                        let sales = await this.allDataService.saleService.getByBillDateRange(this.startStamp, this.endStamp, profile?._localUUID) || [];
                        let purchases = await this.allDataService.purchaseService.getByBillDateRange(this.startStamp, this.endStamp, profile?._localUUID) || [];
                        let moneyIns = await this.allDataService.moneyInService.getByBillDateRange(this.startStamp, this.endStamp, profile?._localUUID) || [];
                        let moneyOuts = await this.allDataService.moneyOutService.getByBillDateRange(this.startStamp, this.endStamp, profile?._localUUID) || [];

                        // Note - Add in future -  saleReturn, purchaseReturn & expense

                        sales?.forEach(sale => {
                            if(Utility.isNumber(sale?.totalAmount)) {
                                dayBookReportData.totalSalesAmount += Number(sale?.totalAmount);
                            }
                        });

                        purchases?.forEach(purchase => {
                            if(Utility.isNumber(purchase?.totalAmount)) {
                                dayBookReportData.totalPurchasesAmount += Number(purchase?.totalAmount);
                            }
                        });

                        moneyIns?.forEach(moneyIn => {
                            if(Utility.isNumber(moneyIn?.totalAmount)) {
                                dayBookReportData.totalMoneyInsAmount += Number(moneyIn?.totalAmount);
                                if (moneyIn.paymentMode === 'cash' || !moneyIn.paymentMode) {
                                    dayBookReportData.totalMoneyInsCashAmount += Number(moneyIn?.totalAmount);
                                } else if (moneyIn.paymentMode === 'bank') {
                                    dayBookReportData.totalMoneyInsUPIAmount += Number(moneyIn?.totalAmount);
                                } else if (moneyIn.paymentMode === 'cheque') {
                                    dayBookReportData.totalMoneyInsChequeAmount += Number(moneyIn?.totalAmount);
                                }
                            }
                        });

                        moneyOuts?.forEach(moneyOut => {
                            if(Utility.isNumber(moneyOut?.totalAmount)) {
                                dayBookReportData.totalMoneyOutsAmount += Number(moneyOut?.totalAmount);
                                if (moneyOut.paymentMode === 'cash' || !moneyOut.paymentMode) {
                                    dayBookReportData.totalMoneyOutsCashAmount += Number(moneyOut?.totalAmount);
                                } else if (moneyOut.paymentMode === 'bank') {
                                    dayBookReportData.totalMoneyOutsUPIAmount += Number(moneyOut?.totalAmount);
                                } else if (moneyOut.paymentMode === 'cheque') {
                                    dayBookReportData.totalMoneyOutsChequeAmount += Number(moneyOut?.totalAmount);
                                }
                            }
                        });

                        this.allProfilesDayBookReportData.profiles.push(dayBookReportData);
                    }
                }

                return resolve(true);
            } catch (error) {
                SentryUtilites.setLog("ReportsPage:getAllProfilesDayBookReportData", error)  
                return resolve(false);
            }
        });
    }

    async getIngredientReportData(): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            try {
                this.ingredientReportData = null;

                this.ingredientReportData = {
                    records: [],
                    duration: `From ${Utility.dateToDDMMYYY(this.startStamp)} To ${Utility.dateToDDMMYYY(this.endStamp)}`,
                    download: this.ingredientReportDownload.bind(this),
                    downloadExcel: this.ingredientReportDownloadExcel.bind(this),
                };

                const sales = await this.allDataService.saleService.getByBillDateRange(this.startStamp, this.endStamp) || [];
                let ingredients = await this.ingredientService.getAll();

                ingredients?.forEach(ingredient => {
                    let totalConsumedQuantity: number = 0;
                    sales?.forEach(sale => {
                        sale?.billItems?.forEach(billItem => {
                            billItem?.item?.itemIngredients?.forEach(itemIngredient => {
                                if (itemIngredient?.ingredient?._localUUID === ingredient?._localUUID) {
                                    totalConsumedQuantity += Utils.capFractionsToSix((itemIngredient?.quantity || 0) * (billItem?.quantity || 0));
                                }
                            });
                        });
                    });
                    this.ingredientReportData?.records?.push({ ingredient, totalConsumedQuantity });
                });

                this.ingredientReportData?.records?.sort((a: any, b: any) => {
                    if (a?.ingredient?.name?.toLowerCase() == b?.ingredient?.name?.toLowerCase()) {
                        return a?.ingredient?.createdStamp - b?.ingredient?.createdStamp;
                    }
                    if (a?.ingredient?.name?.toLowerCase() > b?.ingredient?.name?.toLowerCase()) {
                        return 1;
                    }
                    return -1;
                })

                return resolve(true);
            } catch (error) {
                SentryUtilites.setLog("ReportsPage:getIngredientReportData", error)  
                return resolve(false);
            }
        });
    }

    async ingredientReportDownload() {
        try {
            const doc: jsPDF = new jsPDF();
    
            doc.addFileToVFS("hindi.ttf", Fonts.hindi);
            doc.addFont("hindi.ttf", "hindi", "normal");
            doc.setFont("hindi");
    
            doc.setFontSize(18).setTextColor(56, 103, 214).text("Ingredient Report", 15, 10);
            doc.setFontSize(12).setTextColor(116, 125, 140).text(`${this.selectedProfile?.legalName || this.selectedProfile?.profileName} (${this.selectedProfile?._localUUID?.substr(-4)})`, 15, 20);
            doc.setFontSize(11).setTextColor(116, 125, 140).text(this.authService.getLoginPhone(), 15, 26);
    
            doc.setFontSize(11).setTextColor(116, 125, 140).text("Total Ingredients: " + this.ingredientReportData?.records?.length, 15, 35);
            doc.setFontSize(12).setTextColor(116, 125, 140).text(this.ingredientReportData?.duration, 135, 35);
    
            let body = [];
    
            this.ingredientReportData?.records?.forEach((record, index) => {
                body.push([
                    index + 1,
                    record?.ingredient?.name,
                    record?.ingredient?.category,
                    record?.ingredient?.stock + record?.totalConsumedQuantity,
                    record?.ingredient?.stock,
                    record?.ingredient?.unit,
                ]);
            })
    
            autoTable(doc, {
                head: [['Sr No', 'Ingredient Name', 'Catgegory', 'Opening Stock', 'Closing Stock', 'Unit']],
                body,
                margin: {
                    top: 43
                },
                styles: {
                    font: 'hindi'
                }
            });
    
            doc.save('Ingredient_Report.pdf');
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:ingredientReportDownload", error)  
        }
    }

    async ingredientReportDownloadExcel() {
        try {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Ingredient Report');
    
            worksheet.columns = [
                { header: 'Sr No', key: 'srNo', width: 8, alignment: { horizontal: "left" } },
                { header: 'Ingradient Name', key: 'name', width: 13, alignment: { horizontal: "left" } },
                { header: 'Category', key: 'category', width: 13, alignment: { horizontal: "left" } },
                { header: 'Opening Stock', key: 'openingStock', width: 30, alignment: { horizontal: "left" } },
                { header: 'Closing Stock', key: 'closingStock', width: 30, alignment: { horizontal: "left" } },
                { header: 'Unit', key: 'unit', width: 30, alignment: { horizontal: "left" } },
            ];
    
            worksheet.getRow(1).eachCell(cell => {
                cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FF3E80ED' },
                };
            });
    
            this.ingredientReportData?.records.map((record, index) => {
                worksheet.addRow([
                    index + 1,
                    record?.ingredient?.name,
                    record?.ingredient?.category,
                    record?.ingredient?.stock + record?.totalConsumedQuantity,
                    record?.ingredient?.stock,
                    record?.ingredient?.unit,
                ]);
            });
    
            workbook.xlsx.writeBuffer().then(buffer => {
                const blob = new Blob([buffer], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                })
                saveAs(blob, 'Ingredient_Report.xlsx');
            });
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:ingredientReportDownloadExcel", error)  
        }
    }

    async getSaleWiseIngredientReportData(): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            try {
                this.saleWiseIngredientReportData = null;

                this.saleWiseIngredientReportData = {
                    sales: [],
                    duration: `From ${Utility.dateToDDMMYYY(this.startStamp)} To ${Utility.dateToDDMMYYY(this.endStamp)}`,
                    totalSalesQuantity: 0,
                    totalSalesAmount: 0,
                    download: this.saleWiseIngredientReportDownload.bind(this),
                    downloadExcel: this.saleWiseIngredientReportDownloadExcel.bind(this)
                };

                const sales = await this.allDataService.saleService.getByBillDateRange(this.startStamp, this.endStamp) || [];
                sales?.forEach(sale => {
                    let totalBillItemQuantity: number = 0;
                    let checkIngrediant: boolean = false;

                    sale?.billItems?.forEach(billItem => {
                        totalBillItemQuantity += Number(billItem?.quantity);
                    });

                    this.saleWiseIngredientReportData.totalSalesQuantity += totalBillItemQuantity;
                    this.saleWiseIngredientReportData.totalSalesAmount += sale?.totalAmount;

                    sale?.billItems?.forEach(billItem => {
                        if (billItem?.item?.itemIngredients?.length) {
                            checkIngrediant = true;
                        }
                    })

                    if (checkIngrediant) {
                        this.saleWiseIngredientReportData?.sales?.push({
                            sale,
                            totalBillItemQuantity,
                            totalTax: sale.gstAmount + sale.cessAmount
                        });
                    }
                });

                this.saleWiseIngredientReportData.sales.sort((a, b) => {
                    if (a?.sale?.billDateStamp == b?.sale?.billDateStamp) {
                        return a?.sale?.createdStamp - b?.sale?.createdStamp;
                    }
                    return a?.sale?.billDateStamp - b?.sale?.billDateStamp;
                });

                return resolve(true);
            } catch (error) {
                SentryUtilites.setLog("ReportsPage:getSaleWiseIngredientReportData", error)  
                return resolve(false);
            }
        });
    }

    async saleWiseIngredientReportDownload() {
        try {
            const doc: jsPDF = new jsPDF();
    
            doc.addFileToVFS("hindi.ttf", Fonts.hindi);
            doc.addFont("hindi.ttf", "hindi", "normal");
            doc.setFont("hindi");
    
            doc.setFontSize(18).setTextColor(56, 103, 214).text("Ingredient Report", 15, 10);
            doc.setFontSize(12).setTextColor(116, 125, 140).text(`${this.selectedProfile?.legalName || this.selectedProfile?.profileName} (${this.selectedProfile?._localUUID?.substr(-4)})`, 15, 20);
            doc.setFontSize(11).setTextColor(116, 125, 140).text(this.authService.getLoginPhone(), 15, 26);
    
            doc.setFontSize(11).setTextColor(116, 125, 140).text("Total Sales: " + this.saleWiseIngredientReportData?.sales?.length, 15, 35);
            doc.setFontSize(11).setTextColor(116, 125, 140).text("Total Sale Quantity: " + this.saleWiseIngredientReportData?.totalSalesQuantity, 15, 41);
            doc.setFontSize(11).setTextColor(116, 125, 140).text("Total Sale Amount: " + this.saleWiseIngredientReportData?.totalSalesAmount, 15, 47);
            doc.setFontSize(12).setTextColor(116, 125, 140).text(this.saleWiseIngredientReportData?.duration, 135, 47);
    
            let body = [];
    
            this.saleWiseIngredientReportData?.sales?.forEach((x, index) => {
                let sale = x?.sale;
                // add sale row
                body.push([
                    index + 1,
                    this.dateToDDMMYYY(sale?.billDateStamp),
                    sale?.billNo,
                    sale?.party?.name,
                    sale?.party?.phone,
                    x?.totalBillItemQuantity,
                    x?.totalTax,
                    sale?.totalAmount,
                    sale?.createdByName || sale?.createdBy,
                ]);
                if (sale?.billItems?.length) {
                    // add billItem header
                    body.push([
                        '',
                        '',
                        '#',
                        'Item Name',
                        'Qty',
                        'Unit',
                        'Rs/Unit',
                        'Amount',
                    ]);
    
                    sale?.billItems?.forEach((billItem, billItemIndex) => {
                        // add billItem
                        body.push([
                            '',
                            '',
                            billItemIndex + 1,
                            billItem?.item?.itemName,
                            billItem?.quantity,
                            billItem?.unit,
                            billItem?.price,
                            billItem?.total,
                        ]);
    
                        if (billItem?.item?.itemIngredients?.length) {
                            body.push([
                                '',
                                '',
                                '',
                                '#',
                                'Ingredient Name',
                                'Consumed Qty',
                                'Unit',
                            ]);
    
                            billItem?.item?.itemIngredients?.forEach((itemIngredient, itemIngredientIndex) => {
                                body.push([
                                    '',
                                    '',
                                    '',
                                    itemIngredientIndex + 1,
                                    itemIngredient?.ingredient?.name,
                                    this.capFractionsToSix((itemIngredient?.quantity || 0) * (billItem?.quantity || 0)),
                                    itemIngredient?.ingredient?.unit
                                ]);
                            });
                        }
    
                    });
                }
            })
    
            autoTable(doc, {
                head: [['Sr No', 'Date', 'Txn No', 'Party Name', 'Party Phone', 'Total Quantity', 'Total Tax', 'Total Amount (inc taxes)', 'Created By']],
                body,
                didParseCell: (data) => {
                    if (data.section === 'body' && data.cell.raw == `#`) {
                        Object.keys(data?.row?.cells || {})?.forEach(cellIndex => {
                            if (data.row.cells[cellIndex]?.raw != '') {
                                data.row.cells[cellIndex].styles.textColor = '#FFFFFF';
                                data.row.cells[cellIndex].styles.fillColor = '#2596be';
                            }
                        });
                    }
                },
                margin: {
                    top: 55
                },
                styles: {
                    font: 'hindi'
                }
            });
    
            doc.save('Sale_Wise_Ingredient_Report.pdf');
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:saleWiseIngredientReportDownload", error)  
        }
    }

    async saleWiseIngredientReportDownloadExcel() {
        try {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Sale Wise Ingredient Report');
    
            worksheet.columns = [
                { header: 'Sr No', key: 'srNo', width: 8, alignment: { horizontal: "left" } },
                { header: 'Date', key: 'date', width: 13, alignment: { horizontal: "left" } },
                { header: 'Txn No', key: 'txnNo', width: 13, alignment: { horizontal: "left" } },
                { header: 'Party Name', key: 'partyName', width: 30, alignment: { horizontal: "left" } },
                { header: 'Party Phone', key: 'partyPhone', width: 30, alignment: { horizontal: "left" } },
                { header: 'Total Quantity', key: 'totalQuantity', width: 15, alignment: { horizontal: "right" } },
                { header: 'Total Tax', key: 'totalTax', width: 15, alignment: { horizontal: "right" } },
                { header: 'Total Amount (inc taxes)', key: 'totalAmount', width: 25, alignment: { horizontal: "right" } },
                { header: 'Created By', key: 'createdBy', width: 15, alignment: { horizontal: "left" } },
            ];
    
            worksheet.getRow(1).eachCell(cell => {
                cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FF3E80ED' },
                };
            });
    
            this.saleWiseIngredientReportData?.sales?.map((x, index) => {
                let sale = x?.sale;
                worksheet.addRow([
                    index + 1,
                    this.dateToDDMMYYY(sale?.billDateStamp),
                    sale?.billNo,
                    sale?.party?.name,
                    sale?.party?.phone,
                    x?.totalBillItemQuantity,
                    x?.totalTax,
                    sale?.totalAmount,
                    sale?.createdByName || sale?.createdBy,
                ]);
                if (sale?.billItems?.length) {
                    worksheet.addRow([
                        '',
                        '',
                        '#',
                        'Item Name',
                        'Qty',
                        'Unit',
                        'Rs/Unit',
                        'Amount',
                    ]).eachCell((cell, cellNo) => {
                        if (cellNo != 1 && cellNo != 2) {
                            cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FF3E80ED' },
                            };
                        }
                    });
    
                    sale?.billItems?.forEach((billItem, billItemIndex) => {
                        worksheet.addRow([
                            '',
                            '',
                            billItemIndex + 1,
                            billItem?.item?.itemName,
                            billItem?.quantity,
                            billItem?.unit,
                            billItem?.price,
                            billItem?.total,
                        ]);
    
                        if (billItem?.item?.itemIngredients?.length) {
                            worksheet.addRow([
                                '',
                                '',
                                '',
                                '#',
                                'Ingredient Name',
                                'Consumed Qty',
                                'Unit',
                            ]).eachCell((cell, cellNo) => {
                                if (cellNo != 1 && cellNo != 2) {
                                    cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
                                    cell.fill = {
                                        type: 'pattern',
                                        pattern: 'solid',
                                        fgColor: { argb: 'FF3E80ED' },
                                    };
                                }
                            });
    
                            billItem?.item?.itemIngredients?.forEach((itemIngredient, itemIngredientIndex) => {
                                worksheet.addRow([
                                    '',
                                    '',
                                    '',
                                    itemIngredientIndex + 1,
                                    itemIngredient?.ingredient?.name,
                                    Utils.capFractionsToSix((itemIngredient?.quantity || 0) * (billItem?.quantity || 0)),
                                    itemIngredient?.ingredient?.unit
                                ]);
                            });
                        }
                    });
                }
            });
    
            workbook.xlsx.writeBuffer().then(buffer => {
                const blob = new Blob([buffer], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                })
                saveAs(blob, 'Sale_Wise_Ingredient_Report.xlsx');
            });
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:saleWiseIngredientReportDownloadExcel", error)  
        }
    }

    /**
     *
     * @returns Calculate Raw Material Report Data
     */
    getRawMaterialReportData(): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            try {

                // Fix Start and End Date - Issue from date picker component
                // So this is temporary fix to remove extra 1 day
                if((this.endStamp - this.startStamp) > 86400000) {
                    this.startStamp += 86400000;
                    this.endStamp += 1;
                }
                //  -   -   -   -   -   -   -   -   -   -   -   -   -   -   -

                // Reset data holder objcet to assign new generated report data
                this.rawMaterialReportData = null;
                this.rawMaterialReportData = {
                    duration: `From ${Utility.dateToDDMMYYY(this.startStamp)} To ${Utility.dateToDDMMYYY(this.endStamp-1)}`,
                    days: {},
                    name: {},
                    ingredients:{},
                    downloadExcel: this.rawMaterialReportDownloadExcel.bind(this),
                };
                //  -   -   -   -   -   -   -   -   -   -   -   -   -   -   -

                // Fetch all sales between selectd date rang to calculate sale related variables
                // Calculate values according to formula's and assign them to approriate variable
                const sales: Sale[] = await this.allDataService.saleService.getByBillDateRange(this.startStamp,this.endStamp) || [];

                // Get all ingredient and its transactions to calculate average price
                const ingredients =  await this.ingredientService.getAll();
                const ingredientTransactions =  await this.ingredientStockAdjustService.getAll();

                // Configure/Initialize all days stamp to which data needs to be generate
                const totalDays: number = (this.endStamp - this.startStamp) / 86400000;
                for(let i = totalDays; i > 0; i--) {
                    this.rawMaterialReportData.days[`${this.endStamp-(86400000*i)}`] = {
                        totalSaleAmount: {
                            actualValue: 0,
                            displayValue: 'NA'
                        },
                        averageCostOfRawMaterial: {
                            actualValue: 0,
                            displayValue: 'NA'
                        },
                        averageCostPercentageOfRawMaterial: {
                            actualValue: 0,
                            displayValue: 'NA'
                        },
                    };
                }
                //  -   -   -   -   -   -   -   -   -   -   -   -   -   -   -

                // Assign row value with 0
                ingredients?.forEach(ingredient => {
                    this.rawMaterialReportData.name[ingredient?._localUUID] = ingredient?.name;
                    this.rawMaterialReportData.ingredients[ingredient?._localUUID] = {
                        transferSum: {},
                        returnSum: {},
                        scrapSum: {},
                        purchaseSum: {},
                        openingStock: {},
                        currentDayEODStock: {},
                        totalSalesConsumption: {},
                        totalActualConsumption: {},
                        totalConsumptionDifference: {},
                    };
                    for(let i = totalDays; i > 0; i--) {
                        let currentDayStartStamp: number = this.endStamp-(86400000*i);
                        this.rawMaterialReportData.ingredients[ingredient?._localUUID].transferSum[`${currentDayStartStamp}`] = {
                            actualValue: 0,
                            displayValue: 'NA'
                        };
                        this.rawMaterialReportData.ingredients[ingredient?._localUUID].returnSum[`${currentDayStartStamp}`] = {
                            actualValue: 0,
                            displayValue: 'NA'
                        };
                        this.rawMaterialReportData.ingredients[ingredient?._localUUID].scrapSum[`${currentDayStartStamp}`] = {
                            actualValue: 0,
                            displayValue: 'NA'
                        };
                        this.rawMaterialReportData.ingredients[ingredient?._localUUID].purchaseSum[`${currentDayStartStamp}`] = {
                            actualValue: 0,
                            displayValue: 'NA'
                        };
                        this.rawMaterialReportData.ingredients[ingredient?._localUUID].openingStock[`${currentDayStartStamp}`] = {
                            actualValue: 0,
                            displayValue: 'NA'
                        };
                        this.rawMaterialReportData.ingredients[ingredient?._localUUID].currentDayEODStock[`${currentDayStartStamp}`] = {
                            actualValue: 0,
                            displayValue: 'NA'
                        };
                        this.rawMaterialReportData.ingredients[ingredient?._localUUID].totalSalesConsumption[`${currentDayStartStamp}`] = {
                            actualValue: 0,
                            displayValue: 'NA'
                        };
                        this.rawMaterialReportData.ingredients[ingredient?._localUUID].totalActualConsumption[`${currentDayStartStamp}`] = {
                            actualValue: 0,
                            displayValue: 'NA'
                        };
                        this.rawMaterialReportData.ingredients[ingredient?._localUUID].totalConsumptionDifference[`${currentDayStartStamp}`] = {
                            actualValue: 0,
                            displayValue: 'NA'
                        };
                    }
                });

                // Calculate transferSum, returnSum, scrapSum, purchaseSum, currentDayEODStock, openingStock, totalActualConsumption, totalSalesConsumption, averageCostOfRawMaterial
                if(sales?.length && ingredients?.length && ingredientTransactions?.length) {
                    for(let key in this.rawMaterialReportData.days) {
                        let currentDayStartStamp: number = +key;
                        let currentDayEndStamp: number = currentDayStartStamp + 86400000;
                        let currentDaySales: Sale[] = sales?.filter(sale => sale?.billDateStamp >= currentDayStartStamp && sale?.billDateStamp < currentDayEndStamp);
                        currentDaySales?.forEach(sale => this.rawMaterialReportData.days[key].totalSaleAmount.actualValue += (sale?.totalAmount || 0));

                        const currentDayTransactions = ingredientTransactions?.filter(transaction => transaction?.updatedStamp >= currentDayStartStamp && transaction?.updatedStamp < currentDayEndStamp);

                        ingredients?.forEach(ingredient => {

                            // transferSum
                            currentDayTransactions?.forEach(transaction => {
                                if(transaction?.linkedIngredientUUID === ingredient?._localUUID && transaction?.actionType === ActionType.Transfer) {
                                    this.rawMaterialReportData.ingredients[ingredient?._localUUID].transferSum[`${currentDayStartStamp}`].actualValue += Math.abs(+transaction?.quantity);
                                }
                            });

                            // returnSum
                            currentDayTransactions?.forEach(transaction => {
                                if(transaction?.linkedIngredientUUID === ingredient?._localUUID && transaction?.actionType === ActionType.Return) {
                                    this.rawMaterialReportData.ingredients[ingredient?._localUUID].returnSum[`${currentDayStartStamp}`].actualValue += Math.abs(+transaction?.quantity);
                                }
                            });

                            // scrapSum
                            currentDayTransactions?.forEach(transaction => {
                                if(transaction?.linkedIngredientUUID === ingredient?._localUUID && transaction?.actionType === ActionType.Scrap) {
                                    this.rawMaterialReportData.ingredients[ingredient?._localUUID].scrapSum[`${currentDayStartStamp}`].actualValue += Math.abs(+transaction?.quantity);
                                }
                            });

                            // purchaseSum
                            currentDayTransactions?.forEach(transaction => {
                                if(transaction?.linkedIngredientUUID === ingredient?._localUUID && transaction?.actionType === ActionType.Purchase) {
                                    this.rawMaterialReportData.ingredients[ingredient?._localUUID].purchaseSum[`${currentDayStartStamp}`].actualValue += Math.abs(+transaction?.quantity);
                                }
                            });

                            // currentDayEODStock
                            let eodTransactions = currentDayTransactions?.filter(transaction => transaction?.linkedIngredientUUID === ingredient?._localUUID && transaction?.actionType === ActionType.EOD);
                            eodTransactions.sort((a,b) => b?.updatedStamp - a?.updatedStamp);
                            this.rawMaterialConsumptionReportData.ingredients[ingredient?._localUUID].currentDayEODStock[`${currentDayStartStamp}`].actualValue = Math.abs(+(eodTransactions[0]?.quantity || 0));
                            this.rawMaterialConsumptionReportData.ingredients[ingredient?._localUUID].currentDayEODStock[`${currentDayStartStamp}`].displayValue = eodTransactions[0]?.quantity >= 0 ? `${eodTransactions[0]?.quantity}` : '-';

                            // openingStock
                            let isExecute: boolean = true;
                            let dayCounter: number = 1;
                            do {
                                let dayStartStamp: number = currentDayStartStamp - (86400000 * dayCounter);
                                let dayEndStamp: number = dayStartStamp + 86400000;
                                let previousTransactions =  ingredientTransactions?.filter(transaction => transaction?.updatedStamp < dayEndStamp && transaction?.linkedIngredientUUID === ingredient?._localUUID);
                                if(previousTransactions?.length) {
                                    let yesterdaySales: Sale[] = sales?.filter(sale => sale?.billDateStamp >= dayStartStamp && sale?.billDateStamp < dayEndStamp);
                                    let yesterdayTransactions = ingredientTransactions?.filter(transaction => transaction?.updatedStamp >= dayStartStamp && transaction?.updatedStamp < dayEndStamp && transaction?.linkedIngredientUUID === ingredient?._localUUID);
                                    if(yesterdaySales?.length || yesterdayTransactions?.length) {
                                        let yesterdayEODTransactions = yesterdayTransactions?.filter(transaction => transaction?.actionType === ActionType.EOD);
                                        yesterdayEODTransactions.sort((a,b) => b?.updatedStamp - a?.updatedStamp);
                                        this.rawMaterialConsumptionReportData.ingredients[ingredient?._localUUID].openingStock[`${currentDayStartStamp}`].actualValue = Math.abs(+(yesterdayEODTransactions[0]?.quantity || 0));
                                        this.rawMaterialConsumptionReportData.ingredients[ingredient?._localUUID].openingStock[`${currentDayStartStamp}`].displayValue = yesterdayEODTransactions[0]?.quantity >= 0 ? `${yesterdayEODTransactions[0]?.quantity}` : 'NA';
                                        isExecute = false;
                                    }
                                }else {
                                    isExecute = false;
                                    this.rawMaterialConsumptionReportData.ingredients[ingredient?._localUUID].openingStock[`${currentDayStartStamp}`].actualValue = 0;
                                }
                                dayCounter++
                            }while(isExecute);

                            // totalActualConsumption
                            if(
                                Number(this.rawMaterialConsumptionReportData.ingredients[ingredient?._localUUID].openingStock[`${currentDayStartStamp}`].displayValue) >= 0
                            && this.rawMaterialConsumptionReportData.ingredients[ingredient?._localUUID].purchaseSum[`${currentDayStartStamp}`].actualValue
                            && Number(this.rawMaterialConsumptionReportData.ingredients[ingredient?._localUUID].currentDayEODStock[`${currentDayStartStamp}`].displayValue) >= 0
                            ) {
                                this.rawMaterialReportData.ingredients[ingredient?._localUUID].totalActualConsumption[`${currentDayStartStamp}`].actualValue =
                                (this.rawMaterialReportData.ingredients[ingredient?._localUUID].openingStock[`${currentDayStartStamp}`].actualValue
                                + this.rawMaterialReportData.ingredients[ingredient?._localUUID].purchaseSum[`${currentDayStartStamp}`].actualValue)
                                - this.rawMaterialReportData.ingredients[ingredient?._localUUID].currentDayEODStock[`${currentDayStartStamp}`].actualValue;
                            }

                            // totalSalesConsumption
                            currentDaySales?.forEach(sale => {
                                sale?.billItems?.forEach(billItem => {
                                    if(billItem?.item?.itemIngredients?.length) {
                                        billItem?.item?.itemIngredients?.forEach(itemIngredient => {
                                            if(ingredient?._localUUID === itemIngredient?.ingredient?._localUUID) {
                                                this.rawMaterialReportData.ingredients[itemIngredient?.ingredient?._localUUID].totalSalesConsumption[`${currentDayStartStamp}`].actualValue += (+itemIngredient?.quantity) * (+billItem?.quantity);
                                            }
                                        });
                                    }
                                });
                            });

                            // totalConsumptionDifference
                            if(this.rawMaterialReportData.ingredients[ingredient?._localUUID].totalSalesConsumption[`${currentDayStartStamp}`].actualValue
                            && this.rawMaterialReportData.ingredients[ingredient?._localUUID].totalActualConsumption[`${currentDayStartStamp}`].actualValue) {
                                this.rawMaterialReportData.ingredients[ingredient?._localUUID].totalConsumptionDifference[`${currentDayStartStamp}`].actualValue =
                                this.rawMaterialReportData.ingredients[ingredient?._localUUID].totalSalesConsumption[`${currentDayStartStamp}`].actualValue -
                                this.rawMaterialReportData.ingredients[ingredient?._localUUID].totalActualConsumption[`${currentDayStartStamp}`].actualValue;
                            }

                            // averageCostOfRawMaterial
                            let last30DayStartStamp = currentDayEndStamp - (86400000 * 30);
                            let transactionOf30Days = ingredientTransactions?.filter(transaction => transaction?.updatedStamp >= last30DayStartStamp && transaction?.updatedStamp < currentDayEndStamp && transaction?.linkedIngredientUUID === ingredient?._localUUID && transaction?.actionType === ActionType?.Purchase);
                            let total30DayPurchaseAmount: number = 0;
                            let total30DayPurchaseQuantity: number = 0;
                            transactionOf30Days?.forEach(transaction => {
                                total30DayPurchaseAmount += +(transaction?.price || 0);
                                total30DayPurchaseQuantity += +(transaction?.quantity || 0);
                            })
                            let averagePrice: number = total30DayPurchaseAmount && total30DayPurchaseQuantity ? Utils.capFractionsToSix(total30DayPurchaseAmount / total30DayPurchaseQuantity) : 0;
                            this.rawMaterialReportData.days[key].averageCostOfRawMaterial.actualValue += Utils.capFractionsToSix(averagePrice * this.rawMaterialReportData.ingredients[ingredient?._localUUID].totalActualConsumption[`${currentDayStartStamp}`].actualValue);
                        });

                        // averageCostPercentageOfRawMaterial
                        if(this.rawMaterialReportData.days[key].averageCostOfRawMaterial?.actualValue && this.rawMaterialReportData.days[key].totalSaleAmount?.actualValue) {
                            this.rawMaterialReportData.days[key].averageCostPercentageOfRawMaterial.actualValue = +(((this.rawMaterialReportData.days[key].averageCostOfRawMaterial.actualValue / this.rawMaterialReportData.days[key].totalSaleAmount.actualValue) * 100).toFixed(2));
                        } else {
                            this.rawMaterialReportData.days[key].averageCostPercentageOfRawMaterial.actualValue = 0;
                        }

                    }
                }

                // set Actual value in display value
                for(let daysKeyName in this.rawMaterialReportData?.days) {
                    let eachDayObject = this.rawMaterialReportData?.days[daysKeyName];
                    if(typeof eachDayObject === 'object') {
                        for(let keyName in eachDayObject) {
                            this.rawMaterialReportData.days[daysKeyName][keyName]['displayValue'] = Utils.capFractionsToSix(this.rawMaterialReportData?.days[daysKeyName][keyName]['actualValue']) || 'NA';
                        }
                    }
                }
                for(let ingredientKeyName in this.rawMaterialReportData?.ingredients) {
                    let eachIngredientObject = this.rawMaterialReportData?.ingredients[ingredientKeyName];
                    if(typeof eachIngredientObject === 'object') {
                        for(let actionTypeKeyName in eachIngredientObject) {
                            let eachActionTypeObject = eachIngredientObject[actionTypeKeyName];
                            if(typeof eachActionTypeObject === 'object') {
                                for(let dateWiseKeyName in eachActionTypeObject) {
                                    if(actionTypeKeyName === 'totalSalesConsumption' 
                                    || actionTypeKeyName === 'totalActualConsumption' 
                                    || actionTypeKeyName === 'totalConsumptionDifference') {
                                        this.rawMaterialReportData.ingredients[ingredientKeyName][actionTypeKeyName][dateWiseKeyName]['displayValue'] = `${Utils.capFractionsToSix(this.rawMaterialReportData?.ingredients[ingredientKeyName][actionTypeKeyName][dateWiseKeyName]['actualValue']) || 'NA'}`;
                                    } else if(actionTypeKeyName !== 'openingStock' &&
                                        actionTypeKeyName !== 'currentDayEODStock') {
                                        this.rawMaterialReportData.ingredients[ingredientKeyName][actionTypeKeyName][dateWiseKeyName]['displayValue'] = `${Utils.capFractionsToSix(this.rawMaterialReportData?.ingredients[ingredientKeyName][actionTypeKeyName][dateWiseKeyName]['actualValue']) || '-'}`;
                                    }
                                }
                            }
                        }
                    }
                }
                //  -   -   -   -   -   -   -   -   -   -   -   -   -   -   -

                return resolve(true);
            } catch(error) {
                SentryUtilites.setLog("ReportsPage:getRawMaterialReportData", error)  
                return resolve(false);
            }
        })
    }
    // ----------------------------------------------------------------------------------------------------------------------------------


    checkOperation(val: string): string {
        try {
            if(val === 'transferSum') {
                return 'Transfer';
            } else if(val === 'returnSum') {
                return 'Return';
            } else if(val === 'scrapSum') {
                return 'Scrap';
            } else if(val === 'purchaseSum') {
                return 'Purchase';
            } else if(val === 'openingStock') {
                return 'Opening Stock';
            } else if(val === 'currentDayEODStock') {
                return 'EOD Stock';
            } else if(val === 'totalSalesConsumption') {
                return 'Sales Consumption (SC)';
            } else if(val === 'totalActualConsumption') {
                return 'Actual Consumption (AC)';
            } else if(val === 'totalConsumptionDifference') {
                return 'Difference (AC - SC)';
            }
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:checkOperation", error)  
        }
    }


  /**
   * @description use to download Raw Material Report excel sheet
   */
  async rawMaterialReportDownloadExcel() {
    try {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Raw Material Report');
    
        let headersRow = [
            { header: 'Sr. No.', key: 'srNo', width: 8 },
            { header: 'Overview / Item / Transactions', key: 'overview', width: 30 },
        ]
    
        for(let daysKeyName in this.rawMaterialReportData?.days) {
            headersRow?.push({ header: this.dateToDDMMYYY(+daysKeyName),key: daysKeyName , width: 15 })
        }
    
        worksheet.columns = headersRow;
    
        worksheet.getRow(1).eachCell(cell => {
            cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FF3E80ED' },
            };
        });
    
        // added row for sale, cost of raw material, %cost of raw material
        let totalSaleAmount = ['', 'Sale'];
        let averageCostOfRawMaterial = ['', 'Cost of Raw Material'];
        let averageCostPercentageOfRawMaterial = ['', '%of Cost of Raw Material'];
        for(let daysKeyName in this.rawMaterialReportData?.days) {
            let eachDayObject = this.rawMaterialReportData?.days[daysKeyName];
            if(typeof eachDayObject === 'object') {
                for(let keyName in eachDayObject) {
                    if(keyName === 'totalSaleAmount') {
                        totalSaleAmount.push(this.rawMaterialReportData?.days[daysKeyName][keyName].displayValue);
                    } else if(keyName === 'averageCostOfRawMaterial') {
                        averageCostOfRawMaterial.push(this.rawMaterialReportData?.days[daysKeyName][keyName].displayValue);
                    } else if(keyName === 'averageCostPercentageOfRawMaterial') {
                        averageCostPercentageOfRawMaterial.push(this.rawMaterialReportData?.days[daysKeyName][keyName].displayValue);
                    }
                }
            }
        }
        worksheet.addRow(totalSaleAmount).eachCell((cell, cellNo) => {
            if (cellNo != 1 && cellNo != 2) {
                cell.alignment = {
                    horizontal: "right",
                };
            }
        });
        worksheet.addRow(averageCostOfRawMaterial).eachCell((cell, cellNo) => {
            if (cellNo != 1 && cellNo != 2) {
                cell.alignment = {
                    horizontal: "right",
                };
            }
        });;
        worksheet.addRow(averageCostPercentageOfRawMaterial).eachCell((cell, cellNo) => {
            if (cellNo != 1 && cellNo != 2) {
                cell.alignment = {
                    horizontal: "right",
                };
            }
        });;
    
        // added row for ingredient name, transfer, return, scrap, purchase, opening stock, EOD stock,
        // sale consumption, actual consumption, difference
        let ingredientIndex = 1;
        for(let ingredientKeyName in this.rawMaterialReportData?.ingredients) {
            let eachIngredientObject = this.rawMaterialReportData?.ingredients[ingredientKeyName];
            if(typeof eachIngredientObject === 'object') {
                let ingredientName = [ingredientIndex , this.rawMaterialReportData?.name[ingredientKeyName]];
                let transferSum = ['', 'Transfer'];
                let returnSum = ['', 'Return'];
                let scrapSum = ['', 'Scrap'];
                let purchaseSum = ['', 'Purchase'];
                let openingStock = ['', 'Opening Stock'];
                let currentDayEODStock = ['', 'EOD Stock'];
                let totalSalesConsumption = ['', 'Sales Consumption (SC)'];
                let totalActualConsumption = ['', 'Actual Consumption (AC)'];
                let totalConsumptionDifference = ['', 'Difference (SC - AC)'];
                for(let actionTypeKeyName in eachIngredientObject) {
                    let eachActionTypeObject = eachIngredientObject[actionTypeKeyName];
                    if(typeof eachActionTypeObject === 'object') {
                        for(let dateWiseKeyName in eachActionTypeObject) {
                            if(actionTypeKeyName === 'transferSum') {
                                transferSum.push(eachActionTypeObject[dateWiseKeyName]?.displayValue);
                            } else if(actionTypeKeyName === 'returnSum') {
                                returnSum.push(eachActionTypeObject[dateWiseKeyName]?.displayValue);
                            } else if(actionTypeKeyName === 'scrapSum') {
                                scrapSum.push(eachActionTypeObject[dateWiseKeyName]?.displayValue);
                            } else if(actionTypeKeyName === 'purchaseSum') {
                                purchaseSum.push(eachActionTypeObject[dateWiseKeyName]?.displayValue);
                            } else if(actionTypeKeyName === 'openingStock') {
                                openingStock.push(eachActionTypeObject[dateWiseKeyName]?.displayValue);
                            } else if(actionTypeKeyName === 'currentDayEODStock') {
                                currentDayEODStock.push(eachActionTypeObject[dateWiseKeyName]?.displayValue);
                            } else if(actionTypeKeyName === 'totalSalesConsumption') {
                                totalSalesConsumption.push(eachActionTypeObject[dateWiseKeyName]?.displayValue);
                            } else if(actionTypeKeyName === 'totalActualConsumption') {
                                totalActualConsumption.push(eachActionTypeObject[dateWiseKeyName]?.displayValue);
                            } else if(actionTypeKeyName === 'totalConsumptionDifference') {
                                totalConsumptionDifference.push(eachActionTypeObject[dateWiseKeyName]?.displayValue);
                            }
                        }
                    }
                }
                worksheet.addRow(ingredientName).eachCell((cell, cellNo) => {
                    if (cellNo != 1 && cellNo != 2) {
                        cell.alignment = {
                            horizontal: "right",
                        };
                    }
                });;
                worksheet.addRow(transferSum).eachCell((cell, cellNo) => {
                    if (cellNo != 1 && cellNo != 2) {
                        cell.alignment = {
                            horizontal: "right",
                        };
                    }
                });;
                worksheet.addRow(returnSum).eachCell((cell, cellNo) => {
                    if (cellNo != 1 && cellNo != 2) {
                        cell.alignment = {
                            horizontal: "right",
                        };
                    }
                });;
                worksheet.addRow(scrapSum).eachCell((cell, cellNo) => {
                    if (cellNo != 1 && cellNo != 2) {
                        cell.alignment = {
                            horizontal: "right",
                        };
                    }
                });;
                worksheet.addRow(purchaseSum).eachCell((cell, cellNo) => {
                    if (cellNo != 1 && cellNo != 2) {
                        cell.alignment = {
                            horizontal: "right",
                        };
                    }
                });;
                worksheet.addRow(openingStock).eachCell((cell, cellNo) => {
                    if (cellNo != 1 && cellNo != 2) {
                        cell.alignment = {
                            horizontal: "right",
                        };
                    }
                });;
                worksheet.addRow(currentDayEODStock).eachCell((cell, cellNo) => {
                    if (cellNo != 1 && cellNo != 2) {
                        cell.alignment = {
                            horizontal: "right",
                        };
                    }
                });;
                worksheet.addRow(totalSalesConsumption).eachCell((cell, cellNo) => {
                    if (cellNo != 1 && cellNo != 2) {
                        cell.alignment = {
                            horizontal: "right",
                        };
                    }
                });;
                worksheet.addRow(totalActualConsumption).eachCell((cell, cellNo) => {
                    if (cellNo != 1 && cellNo != 2) {
                        cell.alignment = {
                            horizontal: "right",
                        };
                    }
                });;
                worksheet.addRow(totalConsumptionDifference).eachCell((cell, cellNo) => {
                    if (cellNo != 1 && cellNo != 2) {
                        cell.alignment = {
                            horizontal: "right",
                        };
                    }
                });;
                ingredientIndex++;
            }
        }
    
        workbook.xlsx.writeBuffer().then(buffer => {
            const blob = new Blob([buffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            })
            saveAs(blob, 'Raw_Material_Report.xlsx');
        });
    } catch (error) {
        SentryUtilites.setLog("ReportsPage:rawMaterialReportDownloadExcel", error)  
    }
}
  // ------------------------------------------------------

  /**
   *
   * @returns Calculate Raw Material Cost Report Data
   */
  getRawMaterialCostReportData() : Promise<boolean> {
    return new Promise(async (resolve, reject) => {
        try {
            // Fix Start and End Date - Issue from date picker component
            // So this is temporary fix to remove extra 1 day
            if((this.endStamp - this.startStamp) > 86400000) {
                this.startStamp += 86400000;
                this.endStamp += 1;
            }
            //  -   -   -   -   -   -   -   -   -   -   -   -   -   -   -

            // Reset data holder objcet to assign new generated report data
            this.rawMaterialCostReportData = null;
            this.rawMaterialCostReportData = {
                duration: `From ${Utility.dateToDDMMYYY(this.startStamp)} To ${Utility.dateToDDMMYYY(this.endStamp-1)}`,
                days: {},
                ingredients: {},
                downloadExcel: this.rawMaterialCostReportDownloadExcel.bind(this),
            };
            //  -   -   -   -   -   -   -   -   -   -   -   -   -   -   -

            // Fetch all sales between selectd date rang to calculate sale related variables
            // Calculate values according to formula's and assign them to approriate variable
            const sales: Sale[] = await this.allDataService.saleService.getByBillDateRange(this.startStamp,this.endStamp) || [];

            // Get all ingredient and its transactions to calculate average price
            const ingredients =  await this.ingredientService.getAll();
            const ingredientTransactions =  await this.ingredientStockAdjustService.getAll();

            // Configure/Initialize all days stamp to which data needs to be generate
            const totalDays: number = (this.endStamp - this.startStamp) / 86400000;
            for(let i = totalDays; i > 0; i--) {
                this.rawMaterialCostReportData.days[`${this.endStamp-(86400000*i)}`] = {
                    totalSaleAmount: {
                        actualValue: 0,
                        displayValue: 'NA'
                    },
                    averageIdealCostOfRawMaterial: {
                        actualValue: 0,
                        displayValue: 'NA'
                    },
                    averageIdealCostPercentageOfRawMaterial: {
                        actualValue: 0,
                        displayValue: 'NA'
                    },
                    averageActualCostOfRawMaterial: {
                        actualValue: 0,
                        displayValue: 'NA'
                    },
                    averageActualCostPercentageOfRawMaterial: {
                        actualValue: 0,
                        displayValue: 'NA'
                    },
                    averageCostDifferenceOfRawMaterial: {
                        actualValue: 0,
                        displayValue: 'NA'
                    }
                };
            }
            //  -   -   -   -   -   -   -   -   -   -   -   -   -   -   -

            // Assign row value with 0
            ingredients?.forEach(ingredient => {
                this.rawMaterialCostReportData.ingredients[ingredient?._localUUID] = {
                    purchaseSum: {},
                    openingStock: {},
                    currentDayEODStock: {},
                    totalSalesConsumption: {},
                    totalActualConsumption: {},
                };
                for(let i = totalDays; i > 0; i--) {
                    let currentDayStartStamp: number = this.endStamp-(86400000*i);
                    this.rawMaterialCostReportData.ingredients[ingredient?._localUUID].purchaseSum[`${currentDayStartStamp}`] = {
                        actualValue: 0,
                        displayValue: 'NA'
                    };
                    this.rawMaterialCostReportData.ingredients[ingredient?._localUUID].openingStock[`${currentDayStartStamp}`] = {
                        actualValue: 0,
                        displayValue: 'NA'
                    };
                    this.rawMaterialCostReportData.ingredients[ingredient?._localUUID].currentDayEODStock[`${currentDayStartStamp}`] = {
                        actualValue: 0,
                        displayValue: 'NA'
                    };
                    this.rawMaterialCostReportData.ingredients[ingredient?._localUUID].totalSalesConsumption[`${currentDayStartStamp}`] = {
                        actualValue: 0,
                        displayValue: 'NA'
                    };
                    this.rawMaterialCostReportData.ingredients[ingredient?._localUUID].totalActualConsumption[`${currentDayStartStamp}`] = {
                        actualValue: 0,
                        displayValue: 'NA'
                    };
                }
            });

            // Calculate of purchaseSum, currentDayEODStock, openingStock, totalActualConsumption, totalSalesConsumption,
            // averageIdealCostOfRawMaterial, averageIdealCostPercentageOfRawMaterial, averageActualCostOfRawMaterial,
            // averageActualCostPercentageOfRawMaterial, averageCostDifferenceOfRawMaterial

            if(sales?.length && ingredients?.length && ingredientTransactions?.length) {
                for(let key in this.rawMaterialCostReportData.days) {
                    let currentDayStartStamp: number = +key;
                    let currentDayEndStamp: number = currentDayStartStamp + 86400000;
                    let currentDaySales: Sale[] = sales?.filter(sale => sale?.billDateStamp >= currentDayStartStamp && sale?.billDateStamp < currentDayEndStamp);
                    currentDaySales?.forEach(sale => this.rawMaterialCostReportData.days[key].totalSaleAmount.actualValue += (sale?.totalAmount || 0));

                    const currentDayTransactions = ingredientTransactions?.filter(transaction => transaction?.updatedStamp >= currentDayStartStamp && transaction?.updatedStamp < currentDayEndStamp);
                    ingredients?.forEach(ingredient => {

                        // purchaseSum
                        currentDayTransactions?.forEach(transaction => {
                            if(transaction?.linkedIngredientUUID === ingredient?._localUUID && transaction?.actionType === ActionType.Purchase) {
                                this.rawMaterialCostReportData.ingredients[ingredient?._localUUID].purchaseSum[`${currentDayStartStamp}`].actualValue += this.capFractionsToTwo(Math.abs(+transaction?.quantity));
                            }
                        });

                        // currentDayEODStock
                        let eodTransactions = currentDayTransactions?.filter(transaction => transaction?.linkedIngredientUUID === ingredient?._localUUID && transaction?.actionType === ActionType.EOD);
                        eodTransactions.sort((a,b) => b?.updatedStamp - a?.updatedStamp);
                        this.rawMaterialCostReportData.ingredients[ingredient?._localUUID].currentDayEODStock[`${currentDayStartStamp}`].actualValue = this.capFractionsToTwo(Math.abs(+(eodTransactions[0]?.quantity || 0)));
                        this.rawMaterialCostReportData.ingredients[ingredient?._localUUID].currentDayEODStock[`${currentDayStartStamp}`].displayValue = eodTransactions[0]?.quantity >= 0 ? `${eodTransactions[0]?.quantity}` : '-';

                        // openingStock
                        let isExecute: boolean = true;
                        let dayCounter: number = 1;
                        do {
                            let dayStartStamp: number = currentDayStartStamp - (86400000 * dayCounter);
                            let dayEndStamp: number = dayStartStamp + 86400000;
                            let previousTransactions =  ingredientTransactions?.filter(transaction => transaction?.updatedStamp < dayEndStamp && transaction?.linkedIngredientUUID === ingredient?._localUUID);
                            if(previousTransactions?.length) {
                                let yesterdaySales: Sale[] = sales?.filter(sale => sale?.billDateStamp >= dayStartStamp && sale?.billDateStamp < dayEndStamp);
                                let yesterdayTransactions = ingredientTransactions?.filter(transaction => transaction?.updatedStamp >= dayStartStamp && transaction?.updatedStamp < dayEndStamp && transaction?.linkedIngredientUUID === ingredient?._localUUID);
                                if(yesterdaySales?.length || yesterdayTransactions?.length) {
                                    let yesterdayEODTransactions = yesterdayTransactions?.filter(transaction => transaction?.actionType === ActionType.EOD);
                                    yesterdayEODTransactions.sort((a,b) => b?.updatedStamp - a?.updatedStamp);
                                    this.rawMaterialCostReportData.ingredients[ingredient?._localUUID].openingStock[`${currentDayStartStamp}`].actualValue = this.capFractionsToTwo(Math.abs(+(yesterdayEODTransactions[0]?.quantity || 0)));
                                    this.rawMaterialCostReportData.ingredients[ingredient?._localUUID].openingStock[`${currentDayStartStamp}`].displayValue = yesterdayEODTransactions[0]?.quantity >= 0 ? `${yesterdayEODTransactions[0]?.quantity}` : 'NA';
                                    isExecute = false;
                                }
                            }else {
                                isExecute = false;
                                this.rawMaterialCostReportData.ingredients[ingredient?._localUUID].openingStock[`${currentDayStartStamp}`].actualValue = 0;
                            }
                            dayCounter++
                        }while(isExecute);

                        // totalActualConsumption
                        if(
                            Number(this.rawMaterialCostReportData.ingredients[ingredient?._localUUID].openingStock[`${currentDayStartStamp}`].displayValue) >= 0
                            && this.rawMaterialCostReportData.ingredients[ingredient?._localUUID].purchaseSum[`${currentDayStartStamp}`].actualValue
                            && Number(this.rawMaterialCostReportData.ingredients[ingredient?._localUUID].currentDayEODStock[`${currentDayStartStamp}`].displayValue) >= 0
                        ) {
                            this.rawMaterialCostReportData.ingredients[ingredient?._localUUID].totalActualConsumption[`${currentDayStartStamp}`].actualValue =
                            this.capFractionsToTwo((this.rawMaterialCostReportData.ingredients[ingredient?._localUUID].openingStock[`${currentDayStartStamp}`].actualValue
                            + (this.rawMaterialCostReportData.ingredients[ingredient?._localUUID].purchaseSum[`${currentDayStartStamp}`].actualValue || 0))
                            - this.rawMaterialCostReportData.ingredients[ingredient?._localUUID].currentDayEODStock[`${currentDayStartStamp}`].actualValue);
                        }

                        // totalSalesConsumption
                        currentDaySales?.forEach(sale => {
                            sale?.billItems?.forEach(billItem => {
                                if(billItem?.item?.itemIngredients?.length) {
                                    billItem?.item?.itemIngredients?.forEach(itemIngredient => {
                                        if(ingredient?._localUUID === itemIngredient?.ingredient?._localUUID) {
                                            this.rawMaterialCostReportData.ingredients[itemIngredient?.ingredient?._localUUID].totalSalesConsumption[`${currentDayStartStamp}`].actualValue += this.capFractionsToTwo((+itemIngredient?.quantity) * (+billItem?.quantity));
                                        }
                                    });
                                }
                            });
                        });

                        // averageActualCostOfRawMaterial && averageIdealCostOfRawMaterial
                        let last30DayStartStamp = currentDayEndStamp - (86400000 * 30);
                        let transactionOf30Days = ingredientTransactions?.filter(transaction => transaction?.updatedStamp >= last30DayStartStamp && transaction?.updatedStamp < currentDayEndStamp && transaction?.linkedIngredientUUID === ingredient?._localUUID && transaction?.actionType === ActionType?.Purchase);
                        let total30DayPurchaseAmount: number = 0;
                        let total30DayPurchaseQuantity: number = 0;
                        transactionOf30Days?.forEach(transaction => {
                            total30DayPurchaseAmount += +(transaction?.price || 0);
                            total30DayPurchaseQuantity += +(transaction?.quantity || 0);
                        })
                        let averagePrice: number = total30DayPurchaseAmount && total30DayPurchaseQuantity ? (total30DayPurchaseAmount / total30DayPurchaseQuantity) : 0;

                        // averageActualCostOfRawMaterial
                        this.rawMaterialCostReportData.days[key].averageActualCostOfRawMaterial.actualValue += this.capFractionsToTwo(averagePrice * this.rawMaterialCostReportData.ingredients[ingredient?._localUUID].totalActualConsumption[`${currentDayStartStamp}`].actualValue);

                        //averageIdealCostOfRawMaterial
                        this.rawMaterialCostReportData.days[key].averageIdealCostOfRawMaterial.actualValue += this.capFractionsToTwo(averagePrice * this.rawMaterialCostReportData.ingredients[ingredient?._localUUID].totalSalesConsumption[`${currentDayStartStamp}`].actualValue);
                    });

                    // averageActualCostPercentageOfRawMaterial
                    if(this.rawMaterialCostReportData.days[key].averageActualCostOfRawMaterial?.actualValue && this.rawMaterialCostReportData.days[key].totalSaleAmount?.actualValue) {
                        this.rawMaterialCostReportData.days[key].averageActualCostPercentageOfRawMaterial.actualValue = this.capFractionsToTwo((this.rawMaterialCostReportData.days[key].averageActualCostOfRawMaterial.actualValue / this.rawMaterialCostReportData.days[key].totalSaleAmount.actualValue) * 100);
                    } else {
                        this.rawMaterialCostReportData.days[key].averageActualCostPercentageOfRawMaterial.actualValue = 0;
                    }

                    // averageIdealCostPercentageOfRawMaterial
                    if(this.rawMaterialCostReportData.days[key].averageIdealCostOfRawMaterial?.actualValue && this.rawMaterialCostReportData.days[key].totalSaleAmount?.actualValue) {
                        this.rawMaterialCostReportData.days[key].averageIdealCostPercentageOfRawMaterial.actualValue = this.capFractionsToTwo((this.rawMaterialCostReportData.days[key].averageIdealCostOfRawMaterial.actualValue / this.rawMaterialCostReportData.days[key].totalSaleAmount.actualValue) * 100);
                    } else {
                        this.rawMaterialCostReportData.days[key].averageIdealCostPercentageOfRawMaterial.actualValue = 0;
                    }

                    // averageCostDifferenceOfRawMaterial
                    if(this.rawMaterialCostReportData.days[key].averageActualCostOfRawMaterial?.actualValue && this.rawMaterialCostReportData.days[key].averageIdealCostOfRawMaterial?.actualValue ) {
                        this.rawMaterialCostReportData.days[key].averageCostDifferenceOfRawMaterial.actualValue = this.capFractionsToTwo((this.rawMaterialCostReportData.days[key].averageActualCostOfRawMaterial?.actualValue - this.rawMaterialCostReportData.days[key].averageIdealCostOfRawMaterial?.actualValue));
                    }

                }
            }

            // set Actual value in display value
            for(let daysKeyName in this.rawMaterialCostReportData?.days) {
                let eachDayObject = this.rawMaterialCostReportData?.days[daysKeyName];
                if(typeof eachDayObject === 'object') {
                    for(let keyName in eachDayObject) {
                        this.rawMaterialCostReportData.days[daysKeyName][keyName]['displayValue'] = Utils.capFractionsToSix(this.rawMaterialCostReportData?.days[daysKeyName][keyName]['actualValue']) || 'NA';
                    }
                }
            }
            for(let ingredientKeyName in this.rawMaterialCostReportData?.ingredients) {
                let eachIngredientObject = this.rawMaterialCostReportData?.ingredients[ingredientKeyName];
                if(typeof eachIngredientObject === 'object') {
                    for(let actionTypeKeyName in eachIngredientObject) {
                        let eachActionTypeObject = eachIngredientObject[actionTypeKeyName];
                        if(typeof eachActionTypeObject === 'object') {
                            for(let dateWiseKeyName in eachActionTypeObject) {
                                if(actionTypeKeyName === 'totalSalesConsumption' 
                                || actionTypeKeyName === 'totalActualConsumption') {
                                    this.rawMaterialCostReportData.ingredients[ingredientKeyName][actionTypeKeyName][dateWiseKeyName]['displayValue'] = `${Utils.capFractionsToSix(this.rawMaterialCostReportData?.ingredients[ingredientKeyName][actionTypeKeyName][dateWiseKeyName]['actualValue']) || 'NA'}`;
                                } else if(actionTypeKeyName !== 'openingStock' &&
                                    actionTypeKeyName !== 'currentDayEODStock') {
                                    this.rawMaterialCostReportData.ingredients[ingredientKeyName][actionTypeKeyName][dateWiseKeyName]['displayValue'] = `${Utils.capFractionsToSix(this.rawMaterialCostReportData?.ingredients[ingredientKeyName][actionTypeKeyName][dateWiseKeyName]['actualValue']) || '-'}`;
                                }
                            }
                        }
                    }
                }
            }
            //  -   -   -   -   -   -   -   -   -   -   -   -   -   -   -

            return resolve(true);
        } catch(error) {
            SentryUtilites.setLog("ReportsPage:getRawMaterialCostReportData", error)  
            return resolve(false);
        }
    })
}
    // ------------------------------------------------------

/**
 * @description use to download Raw Material Cost Report excel sheet
 */
async rawMaterialCostReportDownloadExcel() {
    try {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Raw Material Cost Report');
    
        let headersRow = [
            { header: 'Type', key: 'type', width: 8 },
            { header: 'Components', key: 'components', width: 30 },
        ]
    
        for(let daysKeyName in this.rawMaterialCostReportData?.days) {
            headersRow?.push({ header: this.dateToDDMMYYY(+daysKeyName),key: daysKeyName , width: 15 })
        }
    
        worksheet.columns = headersRow;
    
        worksheet.getRow(1).eachCell(cell => {
            cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FF3E80ED' },
            };
        });
    
        // added row for sale, cost of raw material, %cost of raw material
        let totalSaleAmount = ['', 'Total Sale (in ₹)'];
        let averageIdealCostOfRawMaterial = ['Ideal', 'Cost of Raw Material (in ₹)'];
        let averageIdealCostPercentageOfRawMaterial = ['', '%of Cost of Raw Material'];
        let averageActualCostOfRawMaterial = ['Actual', 'Cost of Raw Material (in ₹)'];
        let averageActualCostPercentageOfRawMaterial = ['', '%of Cost of Raw Material'];
        let averageCostDifferenceOfRawMaterial = ['', 'Cost Difference (Actual Cost - Ideal Cost) (in ₹)'];
        for(let daysKeyName in this.rawMaterialCostReportData?.days) {
            let eachDayObject = this.rawMaterialCostReportData?.days[daysKeyName];
            if(typeof eachDayObject === 'object') {
                for(let keyName in eachDayObject) {
                    if(keyName === 'totalSaleAmount') {
                        totalSaleAmount.push(this.rawMaterialCostReportData?.days[daysKeyName][keyName].displayValue);
                    } else if(keyName === 'averageIdealCostOfRawMaterial') {
                        averageIdealCostOfRawMaterial.push(this.rawMaterialCostReportData?.days[daysKeyName][keyName].displayValue);
                    } else if(keyName === 'averageIdealCostPercentageOfRawMaterial') {
                        averageIdealCostPercentageOfRawMaterial.push(this.rawMaterialCostReportData?.days[daysKeyName][keyName].displayValue);
                    } else if(keyName === 'averageActualCostOfRawMaterial') {
                        averageActualCostOfRawMaterial.push(this.rawMaterialCostReportData?.days[daysKeyName][keyName].displayValue);
                    } else if(keyName === 'averageActualCostPercentageOfRawMaterial') {
                        averageActualCostPercentageOfRawMaterial.push(this.rawMaterialCostReportData?.days[daysKeyName][keyName].displayValue);
                    } else if(keyName === 'averageCostDifferenceOfRawMaterial') {
                        averageCostDifferenceOfRawMaterial.push(this.rawMaterialCostReportData?.days[daysKeyName][keyName].displayValue);
                    }
                }
            }
        }
    
        worksheet.addRow(totalSaleAmount).eachCell((cell, cellNo) => {
            if (cellNo != 1 && cellNo != 2) {
                cell.alignment = {
                    horizontal: "right",
                };
            }
        });
        worksheet.addRow(averageIdealCostOfRawMaterial).eachCell((cell, cellNo) => {
            if (cellNo === 1) {
                cell.alignment = {
                    horizontal: "center",
                    vertical: "middle",
                };
            }
            if (cellNo != 1 && cellNo != 2) {
                cell.alignment = {
                    horizontal: "right",
                };
            }
        });
        worksheet.addRow(averageIdealCostPercentageOfRawMaterial).eachCell((cell, cellNo) => {
            if (cellNo != 1 && cellNo != 2) {
                cell.alignment = {
                    horizontal: "right",
                };
            }
        });
        worksheet.addRow(averageActualCostOfRawMaterial).eachCell((cell, cellNo) => {
            if (cellNo === 1) {
                cell.alignment = {
                    horizontal: "center",
                    vertical: "middle",
                };
            }
            if (cellNo != 1 && cellNo != 2) {
                cell.alignment = {
                    horizontal: "right",
                };
            }
        });
        worksheet.addRow(averageActualCostPercentageOfRawMaterial).eachCell((cell, cellNo) => {
            if (cellNo != 1 && cellNo != 2) {
                cell.alignment = {
                    horizontal: "right",
                };
            }
        });
        worksheet.addRow(averageCostDifferenceOfRawMaterial).eachCell((cell, cellNo) => {
            if (cellNo != 1 && cellNo != 2) {
                cell.alignment = {
                    horizontal: "right",
                };
            }
        });
        worksheet.mergeCells('A3:A4');
        worksheet.mergeCells('A5:A6');
    
        workbook.xlsx.writeBuffer().then(buffer => {
            const blob = new Blob([buffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            })
            saveAs(blob, 'Raw_Material_Cost_Report.xlsx');
        });
    } catch (error) {
        SentryUtilites.setLog("ReportsPage:rawMaterialCostReportDownloadExcel", error)  
    }
}
    // ------------------------------------------------------


    /**
     *
     * @returns Calculate Raw Material Consumption Report Data
     */
getRawMaterialConsumptionReportData() : Promise<boolean> {
    return new Promise(async (resolve, reject) => {
        try {

            // Fix Start and End Date - Issue from date picker component
            // So this is temporary fix to remove extra 1 day
            if((this.endStamp - this.startStamp) > 86400000) {
                this.startStamp += 86400000;
                this.endStamp += 1;
            }
            //  -   -   -   -   -   -   -   -   -   -   -   -   -   -   -

            // Reset data holder objcet to assign new generated report data
            this.rawMaterialConsumptionReportData = null;
            this.rawMaterialConsumptionReportData = {
                duration: `From ${Utility.dateToDDMMYYY(this.startStamp)} To ${Utility.dateToDDMMYYY(this.endStamp-1)}`,
                name: {},
                days: [],
                ingredients:{},
                downloadExcel: this.rawMaterialConsumptionReportDownloadExcel.bind(this),
            };
            //  -   -   -   -   -   -   -   -   -   -   -   -   -   -   -

            // Fetch all sales between selectd date rang to calculate sale related variables
            // Calculate values according to formula's and assign them to approriate variable
            const sales: Sale[] = await this.allDataService.saleService.getByBillDateRange(this.startStamp,this.endStamp) || [];

            // Get all ingredient and its transactions to calculate average price
            const ingredients =  await this.ingredientService.getAll();
            ingredients.sort((a,b) => { 
                if (a?.name?.toLowerCase() == b?.name?.toLowerCase()) {
                  return a?.createdStamp - b?.createdStamp;
                } 
                if (a?.name?.toLowerCase() > b?.name?.toLowerCase()) {
                  return 1;
                } 
                return -1;
            })
            const ingredientTransactions =  await this.ingredientStockAdjustService.getAll();

            // Configure/Initialize all days stamp to which data needs to be generate
            const totalDays: number = (this.endStamp - this.startStamp) / 86400000;
            //  -   -   -   -   -   -   -   -   -   -   -   -   -   -   -

            // push days in days array
            for(let i = totalDays; i > 0; i--) {
                let currentDayStartStamp: number = this.endStamp-(86400000*i);
                this.rawMaterialConsumptionReportData.days.push(currentDayStartStamp);
            }

            // Assign row value with 0
            ingredients?.forEach(ingredient => {
                this.rawMaterialConsumptionReportData.name[ingredient?._localUUID] = `${ingredient?.name} (in ${ingredient?.unit})`;
                this.rawMaterialConsumptionReportData.ingredients[ingredient?._localUUID] = {
                    transferSum: {},
                    returnSum: {},
                    scrapSum: {},
                    purchaseSum: {},
                    openingStock: {},
                    currentDayEODStock: {},
                    totalSalesConsumption: {},
                    totalActualConsumption: {},
                    totalConsumptionDifference: {},
                };
                for(let i = totalDays; i > 0; i--) {
                    let currentDayStartStamp: number = this.endStamp-(86400000*i);
                    this.rawMaterialConsumptionReportData.ingredients[ingredient?._localUUID].transferSum[`${currentDayStartStamp}`] = {
                        actualValue: 0,
                        displayValue: 'NA'
                    };
                    this.rawMaterialConsumptionReportData.ingredients[ingredient?._localUUID].returnSum[`${currentDayStartStamp}`] = {
                        actualValue: 0,
                        displayValue: 'NA'
                    };
                    this.rawMaterialConsumptionReportData.ingredients[ingredient?._localUUID].scrapSum[`${currentDayStartStamp}`] = {
                        actualValue: 0,
                        displayValue: 'NA'
                    };
                    this.rawMaterialConsumptionReportData.ingredients[ingredient?._localUUID].purchaseSum[`${currentDayStartStamp}`] = {
                        actualValue: 0,
                        displayValue: 'NA'
                    };
                    this.rawMaterialConsumptionReportData.ingredients[ingredient?._localUUID].openingStock[`${currentDayStartStamp}`] = {
                        actualValue: 0,
                        displayValue: 'NA'
                    };
                    this.rawMaterialConsumptionReportData.ingredients[ingredient?._localUUID].currentDayEODStock[`${currentDayStartStamp}`] = {
                        actualValue: 0,
                        displayValue: 'NA'
                    };
                    this.rawMaterialConsumptionReportData.ingredients[ingredient?._localUUID].totalSalesConsumption[`${currentDayStartStamp}`] = {
                        actualValue: 0,
                        displayValue: 'NA'
                    };
                    this.rawMaterialConsumptionReportData.ingredients[ingredient?._localUUID].totalActualConsumption[`${currentDayStartStamp}`] = {
                        actualValue: 0,
                        displayValue: 'NA'
                    };
                    this.rawMaterialConsumptionReportData.ingredients[ingredient?._localUUID].totalConsumptionDifference[`${currentDayStartStamp}`] = {
                        actualValue: 0,
                        displayValue: 'NA'
                    };
                }
            });

            // Calculate transferSum, returnSum, scrapSum, purchaseSum, currentDayEODStock, openingStock, totalActualConsumption, totalSalesConsumption, averageCostOfRawMaterial
            if(sales?.length && ingredients?.length && ingredientTransactions?.length) {
                this.rawMaterialConsumptionReportData.days?.forEach(numberDate => {
                    let currentDayStartStamp: number = +numberDate;
                    let currentDayEndStamp: number = currentDayStartStamp + 86400000;
                    let currentDaySales: Sale[] = sales?.filter(sale => sale?.billDateStamp >= currentDayStartStamp && sale?.billDateStamp < currentDayEndStamp);

                    const currentDayTransactions = ingredientTransactions?.filter(transaction => transaction?.updatedStamp >= currentDayStartStamp && transaction?.updatedStamp < currentDayEndStamp);

                    ingredients?.forEach(ingredient => {

                        // transferSum
                        currentDayTransactions?.forEach(transaction => {
                            if(transaction?.linkedIngredientUUID === ingredient?._localUUID && transaction?.actionType === ActionType.Transfer) {
                                this.rawMaterialConsumptionReportData.ingredients[ingredient?._localUUID].transferSum[`${currentDayStartStamp}`].actualValue += this.capFractionsToTwo(Math.abs(+transaction?.quantity));
                            }
                        });

                        // returnSum
                        currentDayTransactions?.forEach(transaction => {
                            if(transaction?.linkedIngredientUUID === ingredient?._localUUID && transaction?.actionType === ActionType.Return) {
                                this.rawMaterialConsumptionReportData.ingredients[ingredient?._localUUID].returnSum[`${currentDayStartStamp}`].actualValue += this.capFractionsToTwo(Math.abs(+transaction?.quantity));
                            }
                        });

                        // scrapSum
                        currentDayTransactions?.forEach(transaction => {
                            if(transaction?.linkedIngredientUUID === ingredient?._localUUID && transaction?.actionType === ActionType.Scrap) {
                                this.rawMaterialConsumptionReportData.ingredients[ingredient?._localUUID].scrapSum[`${currentDayStartStamp}`].actualValue += this.capFractionsToTwo(Math.abs(+transaction?.quantity));
                            }
                        });

                        // purchaseSum
                        currentDayTransactions?.forEach(transaction => {
                            if(transaction?.linkedIngredientUUID === ingredient?._localUUID && transaction?.actionType === ActionType.Purchase) {
                                this.rawMaterialConsumptionReportData.ingredients[ingredient?._localUUID].purchaseSum[`${currentDayStartStamp}`].actualValue +=this.capFractionsToTwo(Math.abs(+transaction?.quantity));
                            }
                        });

                        // currentDayEODStock
                        let eodTransactions = currentDayTransactions?.filter(transaction => transaction?.linkedIngredientUUID === ingredient?._localUUID && transaction?.actionType === ActionType.EOD);
                        eodTransactions.sort((a,b) => b?.updatedStamp - a?.updatedStamp);
                        this.rawMaterialConsumptionReportData.ingredients[ingredient?._localUUID].currentDayEODStock[`${currentDayStartStamp}`].actualValue = this.capFractionsToTwo(Math.abs(+(eodTransactions[0]?.quantity || 0)));
                        this.rawMaterialConsumptionReportData.ingredients[ingredient?._localUUID].currentDayEODStock[`${currentDayStartStamp}`].displayValue = eodTransactions[0]?.quantity >= 0 ? `${eodTransactions[0]?.quantity}` : '-';

                        // openingStock
                        let isExecute: boolean = true;
                        let dayCounter: number = 1;
                        do {
                            let dayStartStamp: number = currentDayStartStamp - (86400000 * dayCounter);
                            let dayEndStamp: number = dayStartStamp + 86400000;
                            let previousTransactions =  ingredientTransactions?.filter(transaction => transaction?.updatedStamp < dayEndStamp && transaction?.linkedIngredientUUID === ingredient?._localUUID);
                            if(previousTransactions?.length) {
                                let yesterdaySales: Sale[] = sales?.filter(sale => sale?.billDateStamp >= dayStartStamp && sale?.billDateStamp < dayEndStamp);
                                let yesterdayTransactions = ingredientTransactions?.filter(transaction => transaction?.updatedStamp >= dayStartStamp && transaction?.updatedStamp < dayEndStamp && transaction?.linkedIngredientUUID === ingredient?._localUUID);
                                if(yesterdaySales?.length || yesterdayTransactions?.length) {
                                    let yesterdayEODTransactions = yesterdayTransactions?.filter(transaction => transaction?.actionType === ActionType.EOD);
                                    yesterdayEODTransactions.sort((a,b) => b?.updatedStamp - a?.updatedStamp);
                                    this.rawMaterialConsumptionReportData.ingredients[ingredient?._localUUID].openingStock[`${currentDayStartStamp}`].actualValue = this.capFractionsToTwo(Math.abs(+(yesterdayEODTransactions[0]?.quantity || 0)));
                                    this.rawMaterialConsumptionReportData.ingredients[ingredient?._localUUID].openingStock[`${currentDayStartStamp}`].displayValue = yesterdayEODTransactions[0]?.quantity >= 0 ? `${yesterdayEODTransactions[0]?.quantity}` : 'NA';
                                    isExecute = false;
                                }
                            }else {
                                isExecute = false;
                                this.rawMaterialConsumptionReportData.ingredients[ingredient?._localUUID].openingStock[`${currentDayStartStamp}`].actualValue = 0;
                            }
                            dayCounter++
                        }while(isExecute);

                        // totalActualConsumption
                        if(
                            Number(this.rawMaterialConsumptionReportData.ingredients[ingredient?._localUUID].openingStock[`${currentDayStartStamp}`].displayValue) >= 0
                            && this.rawMaterialConsumptionReportData.ingredients[ingredient?._localUUID].purchaseSum[`${currentDayStartStamp}`].actualValue
                            && Number(this.rawMaterialConsumptionReportData.ingredients[ingredient?._localUUID].currentDayEODStock[`${currentDayStartStamp}`].displayValue) >= 0
                        ) {
                            this.rawMaterialConsumptionReportData.ingredients[ingredient?._localUUID].totalActualConsumption[`${currentDayStartStamp}`].actualValue =
                            this.capFractionsToTwo((this.rawMaterialConsumptionReportData.ingredients[ingredient?._localUUID].openingStock[`${currentDayStartStamp}`].actualValue
                            + (this.rawMaterialConsumptionReportData.ingredients[ingredient?._localUUID].purchaseSum[`${currentDayStartStamp}`].actualValue) || 0)
                            - this.rawMaterialConsumptionReportData.ingredients[ingredient?._localUUID].currentDayEODStock[`${currentDayStartStamp}`].actualValue);
                        }

                        // totalSalesConsumption
                        currentDaySales?.forEach(sale => {
                            sale?.billItems?.forEach(billItem => {
                                if(billItem?.item?.itemIngredients?.length) {
                                    billItem?.item?.itemIngredients?.forEach(itemIngredient => {
                                        if(ingredient?._localUUID === itemIngredient?.ingredient?._localUUID) {
                                            this.rawMaterialConsumptionReportData.ingredients[itemIngredient?.ingredient?._localUUID].totalSalesConsumption[`${currentDayStartStamp}`].actualValue += this.capFractionsToTwo((+itemIngredient?.quantity) * (+billItem?.quantity));
                                        }
                                    });
                                }
                            });
                        });

                        // totalConsumptionDifference
                        if(this.rawMaterialConsumptionReportData.ingredients[ingredient?._localUUID].totalSalesConsumption[`${currentDayStartStamp}`].actualValue
                        && this.rawMaterialConsumptionReportData.ingredients[ingredient?._localUUID].totalActualConsumption[`${currentDayStartStamp}`].actualValue) {
                            this.rawMaterialConsumptionReportData.ingredients[ingredient?._localUUID].totalConsumptionDifference[`${currentDayStartStamp}`].actualValue = 
                            this.capFractionsToTwo(this.rawMaterialConsumptionReportData.ingredients[ingredient?._localUUID].totalActualConsumption[`${currentDayStartStamp}`].actualValue -
                            this.rawMaterialConsumptionReportData.ingredients[ingredient?._localUUID].totalSalesConsumption[`${currentDayStartStamp}`].actualValue);
                        }

                    });

                })

            }

            // set Actual value in display value
            for(let ingredientKeyName in this.rawMaterialConsumptionReportData?.ingredients) {
                let eachIngredientObject = this.rawMaterialConsumptionReportData?.ingredients[ingredientKeyName];
                if(typeof eachIngredientObject === 'object') {
                    for(let actionTypeKeyName in eachIngredientObject) {
                        let eachActionTypeObject = eachIngredientObject[actionTypeKeyName];
                        if(typeof eachActionTypeObject === 'object') {
                            for(let dateWiseKeyName in eachActionTypeObject) {
                                if(actionTypeKeyName === 'totalSalesConsumption' 
                                || actionTypeKeyName === 'totalActualConsumption' 
                                || actionTypeKeyName === 'totalConsumptionDifference') {
                                    this.rawMaterialConsumptionReportData.ingredients[ingredientKeyName][actionTypeKeyName][dateWiseKeyName]['displayValue'] = `${Utils.capFractionsToSix(this.rawMaterialConsumptionReportData?.ingredients[ingredientKeyName][actionTypeKeyName][dateWiseKeyName]['actualValue']) || 'NA'}`;
                                } else if (actionTypeKeyName !== 'openingStock' &&
                                    actionTypeKeyName !== 'currentDayEODStock' ) {
                                    this.rawMaterialConsumptionReportData.ingredients[ingredientKeyName][actionTypeKeyName][dateWiseKeyName]['displayValue'] = `${Utils.capFractionsToSix(this.rawMaterialConsumptionReportData?.ingredients[ingredientKeyName][actionTypeKeyName][dateWiseKeyName]['actualValue']) || '-'}`;   
                                }
                            }
                        }
                    }
                }
            }
            //  -   -   -   -   -   -   -   -   -   -   -   -   -   -   -

            return resolve(true);
        } catch(error) {
            SentryUtilites.setLog("ReportsPage:getRawMaterialConsumptionReportData", error)  
            return resolve(false);
        }
    })
}
    // ------------------------------------------------------


/**
 * @description use to download Raw Material Consumption Report excel sheet
 */
async rawMaterialConsumptionReportDownloadExcel() {
    try {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Raw Material Report');
    
        let headersRow = [
            { header: 'Sr. No.', key: 'srNo', width: 8 },
            { header: 'Raw Materials / Transactions', key: 'overview', width: 30 },
        ]
    
        this.rawMaterialConsumptionReportData?.days?.forEach(numberDate => {
            headersRow?.push({ header: this.dateToDDMMYYY(+numberDate), key: `${numberDate}` , width: 15 })
        })
    
        worksheet.columns = headersRow;
    
        worksheet.getRow(1).eachCell(cell => {
            cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FF3E80ED' },
            };
        });
    
        // added row for ingredient name, transfer, return, scrap, purchase, opening stock, EOD stock,
        // sale consumption, actual consumption, difference
        let ingredientIndex = 1;
        for(let ingredientKeyName in this.rawMaterialConsumptionReportData?.ingredients) {
            let eachIngredientObject = this.rawMaterialConsumptionReportData?.ingredients[ingredientKeyName];
            if(typeof eachIngredientObject === 'object') {
                let ingredientName = [ingredientIndex , this.rawMaterialConsumptionReportData?.name[ingredientKeyName]];
                this.rawMaterialConsumptionReportData?.days?.forEach(numberDate => {
                    ingredientName.push('');
                });
                let transferSum = ['', 'Transfer'];
                let returnSum = ['', 'Return'];
                let scrapSum = ['', 'Scrap'];
                let purchaseSum = ['', 'Purchase'];
                let openingStock = ['', 'Opening Stock'];
                let currentDayEODStock = ['', 'EOD Stock'];
                let totalSalesConsumption = ['', 'Sales Consumption (SC)'];
                let totalActualConsumption = ['', 'Actual Consumption (AC)'];
                let totalConsumptionDifference = ['', 'Difference (AC - SC)'];
                for(let actionTypeKeyName in eachIngredientObject) {
                    let eachActionTypeObject = eachIngredientObject[actionTypeKeyName];
                    if(typeof eachActionTypeObject === 'object') {
                        for(let dateWiseKeyName in eachActionTypeObject) {
                            if(actionTypeKeyName === 'transferSum') {
                                transferSum.push(eachActionTypeObject[dateWiseKeyName]?.displayValue);
                            } else if(actionTypeKeyName === 'returnSum') {
                                returnSum.push(eachActionTypeObject[dateWiseKeyName]?.displayValue);
                            } else if(actionTypeKeyName === 'scrapSum') {
                                scrapSum.push(eachActionTypeObject[dateWiseKeyName]?.displayValue);
                            } else if(actionTypeKeyName === 'purchaseSum') {
                                purchaseSum.push(eachActionTypeObject[dateWiseKeyName]?.displayValue);
                            } else if(actionTypeKeyName === 'openingStock') {
                                openingStock.push(eachActionTypeObject[dateWiseKeyName]?.displayValue);
                            } else if(actionTypeKeyName === 'currentDayEODStock') {
                                currentDayEODStock.push(eachActionTypeObject[dateWiseKeyName]?.displayValue);
                            } else if(actionTypeKeyName === 'totalSalesConsumption') {
                                totalSalesConsumption.push(eachActionTypeObject[dateWiseKeyName]?.displayValue);
                            } else if(actionTypeKeyName === 'totalActualConsumption') {
                                totalActualConsumption.push(eachActionTypeObject[dateWiseKeyName]?.displayValue);
                            } else if(actionTypeKeyName === 'totalConsumptionDifference') {
                                totalConsumptionDifference.push(eachActionTypeObject[dateWiseKeyName]?.displayValue);
                            }
                        }
                    }
                }
                worksheet.addRow(ingredientName).eachCell((cell, cellNo) => {
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'ffc9daf8' },
                    };
                    if (cellNo != 1 && cellNo != 2) {
                        cell.alignment = {
                            horizontal: "right",
                        };
                    }
                });;
                worksheet.addRow(transferSum).eachCell((cell, cellNo) => {
                    if (cellNo != 1 && cellNo != 2) {
                        cell.alignment = {
                            horizontal: "right",
                        };
                    }
                });;
                worksheet.addRow(returnSum).eachCell((cell, cellNo) => {
                    if (cellNo != 1 && cellNo != 2) {
                        cell.alignment = {
                            horizontal: "right",
                        };
                    }
                });;
                worksheet.addRow(scrapSum).eachCell((cell, cellNo) => {
                    if (cellNo != 1 && cellNo != 2) {
                        cell.alignment = {
                            horizontal: "right",
                        };
                    }
                });;
                worksheet.addRow(purchaseSum).eachCell((cell, cellNo) => {
                    if (cellNo != 1 && cellNo != 2) {
                        cell.alignment = {
                            horizontal: "right",
                        };
                    }
                });;
                worksheet.addRow(openingStock).eachCell((cell, cellNo) => {
                    if (cellNo != 1 && cellNo != 2) {
                        cell.alignment = {
                            horizontal: "right",
                        };
                    }
                });;
                worksheet.addRow(currentDayEODStock).eachCell((cell, cellNo) => {
                    if (cellNo != 1 && cellNo != 2) {
                        cell.alignment = {
                            horizontal: "right",
                        };
                    }
                });;
                worksheet.addRow(totalSalesConsumption).eachCell((cell, cellNo) => {
                    if (cellNo != 1 && cellNo != 2) {
                        cell.alignment = {
                            horizontal: "right",
                        };
                    }
                });;
                worksheet.addRow(totalActualConsumption).eachCell((cell, cellNo) => {
                    if (cellNo != 1 && cellNo != 2) {
                        cell.alignment = {
                            horizontal: "right",
                        };
                    }
                });;
                worksheet.addRow(totalConsumptionDifference).eachCell((cell, cellNo) => {
                    if (cellNo != 1 && cellNo != 2) {
                        cell.alignment = {
                            horizontal: "right",
                        };
                    }
                });;
                ingredientIndex++;
            }
        }
    
        workbook.xlsx.writeBuffer().then(buffer => {
            const blob = new Blob([buffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            })
            saveAs(blob, 'Raw_Material_Consumption_Report.xlsx');
        });
    } catch (error) {
        SentryUtilites.setLog("ReportsPage:rawMaterialConsumptionReportDownloadExcel", error)  
    }
}
    // ------------------------------------------------------

/**
 * 
 * @returns Calculate Raw Material Consumption Report Data 
 */
getRawMaterialPurchaseReportData() : Promise<boolean> {
    return new Promise(async (resolve, reject) => {
        try {

            // Fix Start and End Date - Issue from date picker component
            // So this is temporary fix to remove extra 1 day
            if((this.endStamp - this.startStamp) > 86400000) {
                this.startStamp += 86400000;
                this.endStamp += 1;
            }
            //  -   -   -   -   -   -   -   -   -   -   -   -   -   -   -

            // Reset data holder objcet to assign new generated report data
            this.rawMaterialPurchaseReportData = null;
            this.rawMaterialPurchaseReportData = {
                duration: `From ${Utility.dateToDDMMYYY(this.startStamp)} To ${Utility.dateToDDMMYYY(this.endStamp-1)}`,
                days: [],
                name: {},
                unit: {},
                ingredients: {},
                downloadExcel: this.rawMaterialPurchaseReportDownloadExcel.bind(this),
            };
            //  -   -   -   -   -   -   -   -   -   -   -   -   -   -   -

            // Fetch all sales between selectd date rang to calculate sale related variables
            // Calculate values according to formula's and assign them to approriate variable
            const sales: Sale[] = await this.allDataService.saleService.getByBillDateRange(this.startStamp,this.endStamp) || [];

            // Get all ingredient and its transactions to calculate average price 
            const ingredients =  await this.ingredientService.getAll();
            const ingredientTransactions =  await this.ingredientStockAdjustService.getAll();
            // Configure/Initialize all days stamp to which data needs to be generate
            const totalDays: number = (this.endStamp - this.startStamp) / 86400000;
            //  -   -   -   -   -   -   -   -   -   -   -   -   -   -   -

            // push days in days array
            for(let i = totalDays; i > 0; i--) {
                let currentDayStartStamp: number = this.endStamp-(86400000*i);
                this.rawMaterialPurchaseReportData.days.push(currentDayStartStamp);
            }

            // Assign row value with 0
            ingredients?.forEach(ingredient => {
                this.rawMaterialPurchaseReportData.name[ingredient?._localUUID] = ingredient?.name;
                this.rawMaterialPurchaseReportData.unit[ingredient?._localUUID] = ingredient?.unit;
                this.rawMaterialPurchaseReportData.ingredients[ingredient?._localUUID] = {
                    totalQuantity: {},
                    totalPrice: {},
                    perUnitPrice: {},
                };
                for(let i = totalDays; i > 0; i--) {
                    let currentDayStartStamp: number = this.endStamp-(86400000*i);
                    this.rawMaterialPurchaseReportData.ingredients[ingredient?._localUUID].totalQuantity[`${currentDayStartStamp}`] = {
                        actualValue: 0,
                        displayValue: 'NA'
                    };
                    this.rawMaterialPurchaseReportData.ingredients[ingredient?._localUUID].totalPrice[`${currentDayStartStamp}`] = {
                        actualValue: 0,
                        displayValue: 'NA'
                    };
                    this.rawMaterialPurchaseReportData.ingredients[ingredient?._localUUID].perUnitPrice[`${currentDayStartStamp}`] = {
                        actualValue: 0,
                        displayValue: 'NA'
                    };
                }
            });

            // Calculate totalQuantity, totalPrice, perUnitPrice
            if(ingredients?.length && ingredientTransactions?.length) {
                this.rawMaterialPurchaseReportData.days?.forEach(numberDate => {
                    let currentDayStartStamp: number = +numberDate;
                    let currentDayEndStamp: number = currentDayStartStamp + 86400000;

                    const currentDayTransactions = ingredientTransactions?.filter(transaction => transaction?.updatedStamp >= currentDayStartStamp && transaction?.updatedStamp < currentDayEndStamp && transaction?.actionType === ActionType.Purchase);
                    ingredients?.forEach(ingredient => {
                        
                        // totalQuantity, totalPrice
                        currentDayTransactions?.forEach(transaction => {
                            if(transaction?.linkedIngredientUUID === ingredient?._localUUID) {
                                this.rawMaterialPurchaseReportData.ingredients[ingredient?._localUUID].totalQuantity[`${currentDayStartStamp}`].actualValue += this.capFractionsToTwo(Math.abs(+transaction?.quantity));
                                this.rawMaterialPurchaseReportData.ingredients[ingredient?._localUUID].totalPrice[`${currentDayStartStamp}`].actualValue += this.capFractionsToTwo(Math.abs(+transaction?.price));
                            }
                        });

                        // perUnitPrice
                        if(
                            this.rawMaterialPurchaseReportData.ingredients[ingredient?._localUUID].totalQuantity[`${currentDayStartStamp}`].actualValue &&
                            this.rawMaterialPurchaseReportData.ingredients[ingredient?._localUUID].totalPrice[`${currentDayStartStamp}`].actualValue
                        ) {
                            this.rawMaterialPurchaseReportData.ingredients[ingredient?._localUUID].perUnitPrice[`${currentDayStartStamp}`].actualValue = 
                            this.capFractionsToTwo(this.rawMaterialPurchaseReportData.ingredients[ingredient?._localUUID].totalPrice[`${currentDayStartStamp}`].actualValue / 
                            this.rawMaterialPurchaseReportData.ingredients[ingredient?._localUUID].totalQuantity[`${currentDayStartStamp}`].actualValue);
                        }

                    });

                }) 
                
            }

            // set Actual value in display value
            for(let ingredientKeyName in this.rawMaterialPurchaseReportData?.ingredients) {
                let eachIngredientObject = this.rawMaterialPurchaseReportData?.ingredients[ingredientKeyName];
                if(typeof eachIngredientObject === 'object') {
                    for(let actionTypeKeyName in eachIngredientObject) {
                        let eachActionTypeObject = eachIngredientObject[actionTypeKeyName];
                        if(typeof eachActionTypeObject === 'object') {
                            for(let dateWiseKeyName in eachActionTypeObject) {
                                this.rawMaterialPurchaseReportData.ingredients[ingredientKeyName][actionTypeKeyName][dateWiseKeyName]['displayValue'] = `${Utils.capFractionsToSix(this.rawMaterialPurchaseReportData?.ingredients[ingredientKeyName][actionTypeKeyName][dateWiseKeyName]['actualValue']) || 'NA'}`;
                            }
                        }
                    }
                }
            }
            //  -   -   -   -   -   -   -   -   -   -   -   -   -   -   -

            return resolve(true);
        } catch(error) {
            SentryUtilites.setLog("ReportsPage:getRawMaterialPurchaseReportData", error)  
            return resolve(false);
        }
    })
}
// ------------------------------------------------------

/**
 * @description use to download Raw Material Purchase Report excel sheet
 */
async rawMaterialPurchaseReportDownloadExcel() {
    try {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Raw Material Purchase Report');
    
        let headersRow = [
            { header: 'Sr. No.', key: 'srNo', width: 8 },
            { header: 'Raw Materials', key: 'materials', width: 20 },
            { header: 'Quantity / Price', width: 20 },
        ]
    
        this.rawMaterialPurchaseReportData?.days?.forEach(numberDate => {
            headersRow?.push({ header: this.dateToDDMMYYY(+numberDate), key: `${numberDate}` , width: 15 })
        }) 
    
        worksheet.columns = headersRow;
    
        worksheet.getRow(1).eachCell(cell => {
            cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FF3E80ED' },
            };
        });
    
        // added row for ingredient totalQuantity, totalPrice, perUnitPrice
        let ingredientIndex = 1;
        for(let ingredientKeyName in this.rawMaterialPurchaseReportData?.ingredients) {
            let eachIngredientObject = this.rawMaterialPurchaseReportData?.ingredients[ingredientKeyName];
            if(typeof eachIngredientObject === 'object') {
                let totalQuantity = [ingredientIndex, this.rawMaterialPurchaseReportData?.name[ingredientKeyName], `Quantity (in ${this.rawMaterialPurchaseReportData?.unit[ingredientKeyName]})`];
                let totalPrice = ['', '', 'Price (in ₹)'];
                let perUnitPrice = ['', '', `Per Unit Price (in ₹ / ${this.rawMaterialPurchaseReportData?.unit[ingredientKeyName]})`];
                for(let actionTypeKeyName in eachIngredientObject) {
                    let eachActionTypeObject = eachIngredientObject[actionTypeKeyName];
                    if(typeof eachActionTypeObject === 'object') {
                        for(let dateWiseKeyName in eachActionTypeObject) {
                            if(actionTypeKeyName === 'totalQuantity') {
                                totalQuantity.push(eachActionTypeObject[dateWiseKeyName]?.displayValue);
                            } else if(actionTypeKeyName === 'totalPrice') {
                                totalPrice.push(eachActionTypeObject[dateWiseKeyName]?.displayValue);
                            } else if(actionTypeKeyName === 'perUnitPrice') {
                                perUnitPrice.push(eachActionTypeObject[dateWiseKeyName]?.displayValue);
                            }
                        }
                    }
                }
                worksheet.addRow(totalQuantity).eachCell((cell, cellNo) => {
                    if (cellNo != 1 && cellNo != 2 && cellNo != 3) {
                        cell.alignment = {
                            horizontal: "right", 
                        };
                    }
                    if(cellNo == 1 || cellNo == 2) {
                        cell.alignment = {
                            horizontal: "center", 
                            vertical: "middle",
                        };
                    }
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'ffc9daf8' },
                    };
                });
                worksheet.addRow(totalPrice).eachCell((cell, cellNo) => {
                    if (cellNo != 1 && cellNo != 2 && cellNo != 3) {
                        cell.alignment = {
                            horizontal: "right", 
                        };
                    }
                });
                worksheet.addRow(perUnitPrice).eachCell((cell, cellNo) => {
                    if (cellNo != 1 && cellNo != 2 && cellNo != 3) {
                        cell.alignment = {
                            horizontal: "right", 
                        };
                    }
                    cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'ffc9daf8' },
                    };
                });
                ingredientIndex++;
            }
            worksheet.mergeCells(`A${2 + 3 *(ingredientIndex - 2)}:A${4 + 3 *(ingredientIndex - 2)}`);
            worksheet.mergeCells(`B${2 + 3 *(ingredientIndex - 2)}:B${4 + 3 *(ingredientIndex - 2)}`);
        }
    
        workbook.xlsx.writeBuffer().then(buffer => {
            const blob = new Blob([buffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            })
            saveAs(blob, 'Raw_Material_Purchase_Report.xlsx');
        });
    } catch (error) {
        SentryUtilites.setLog("ReportsPage:rawMaterialPurchaseReportDownloadExcel", error)  
    }
}
// ---------------------------------------------------

  async getIngredientDetailReportData(): Promise < boolean > {
    return new Promise(async (resolve, reject) => {
        try {
            this.ingredientDetailReportData = null;

            this.ingredientDetailReportData = {
                records: [],
                duration: `From ${Utility.dateToDDMMYYY(this.startStamp)} To ${Utility.dateToDDMMYYY(this.endStamp)}`,
                download: this.ingredientDetailReportDownload.bind(this),
                downloadExcel: this.ingredientDetailReportDownloadExcel.bind(this),
            };

            const sales = await this.allDataService.saleService.getByBillDateRange(this.startStamp, this.endStamp) || [];
            let ingredients = await this.ingredientService.getAll();
            let ingredientStockAdjusts = (await this.ingredientStockAdjustService.getAll())?.filter(doc => doc?.createdStamp >= this.startStamp && doc?.createdStamp < this.endStamp);;

            ingredients?.forEach(ingredient => {

                let transactions: {
                    type: "Sale" | "Add" | "Reduce";
                    sortStamp: number;
                    createdStamp: number;
                    quantity: number;
                    unit: string;
                }[] = [];

                sales?.forEach(sale => {
                    sale.billItems?.forEach(billItem => {
                        billItem?.item?.itemIngredients?.forEach(itemIngredient => {
                            if (itemIngredient?.ingredient?._localUUID === ingredient?._localUUID) {
                                transactions.push({
                                    type: 'Sale',
                                    sortStamp: sale.billDateStamp,
                                    createdStamp: sale.createdStamp,
                                    quantity: Utils.capFractionsToSix((itemIngredient?.quantity || 0) * (billItem?.quantity || 0)),
                                    unit: itemIngredient?.ingredient?.unit,
                                });
                            }
                        });
                    });
                });

                ingredientStockAdjusts?.filter(x => x?.linkedIngredientUUID === ingredient?._localUUID)?.forEach(ingredientStockAdjust => {
                    transactions.push({
                        type: ingredientStockAdjust?.quantity < 0 ? 'Reduce' : 'Add',
                        sortStamp: ingredientStockAdjust?.createdStamp,
                        createdStamp: ingredientStockAdjust?.createdStamp,
                        quantity: Utils.capFractionsToSix(Math.abs(ingredientStockAdjust?.quantity)),
                        unit: ingredientStockAdjust?.unit,
                    });
                });

                transactions.sort((a: any, b: any) => {
                    if (b?.sortStamp == a?.sortStamp) {
                        return b?.createdStamp - a?.createdStamp;
                    }
                    return b?.sortStamp - a?.sortStamp;
                })

                this.ingredientDetailReportData?.records?.push({ ingredient, transactions });

            });

            this.ingredientDetailReportData?.records?.sort((a: any, b: any) => {
                if (a?.ingredient?.name?.toLowerCase() == b?.ingredient?.name?.toLowerCase()) {
                    return a?.ingredient?.createdStamp - b?.ingredient?.createdStamp;
                }
                if (a?.ingredient?.name?.toLowerCase() > b?.ingredient?.name?.toLowerCase()) {
                    return 1;
                }
                return -1;
            })

            return resolve(true);
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:getIngredientDetailReportData", error)  
            return resolve(false);
        }
    });
}

  async ingredientDetailReportDownload() {
    try {
        const doc: jsPDF = new jsPDF();
    
        doc.addFileToVFS("hindi.ttf", Fonts.hindi);
        doc.addFont("hindi.ttf", "hindi", "normal");
        doc.setFont("hindi");
    
        doc.setFontSize(18).setTextColor(56, 103, 214).text("Ingredient Detail Report", 15, 10);
        doc.setFontSize(12).setTextColor(116, 125, 140).text(`${this.selectedProfile?.legalName || this.selectedProfile?.profileName} (${this.selectedProfile?._localUUID?.substr(-4)})`, 15, 20);
        doc.setFontSize(11).setTextColor(116, 125, 140).text(this.authService.getLoginPhone(), 15, 26);
    
        doc.setFontSize(11).setTextColor(116, 125, 140).text("Total Ingredients: " + this.ingredientDetailReportData?.records?.length, 15, 35);
        doc.setFontSize(12).setTextColor(116, 125, 140).text(this.ingredientDetailReportData?.duration, 135, 35);
    
        let body = [];
    
        this.ingredientDetailReportData?.records?.forEach((record, index) => {
            body.push([
                index + 1,
                record?.ingredient?.name,
                record?.ingredient?.category,
                record?.ingredient?.stock,
                record?.ingredient?.unit,
                ''
            ]);
            if (record?.transactions?.length) {
                body.push([
                    '',
                    '#',
                    'Transaction Type',
                    'Date',
                    'Quantity',
                    'Unit',
                ]);
    
                record?.transactions?.forEach((transaction, billItemIndex) => {
                    body.push([
                        '',
                        billItemIndex + 1,
                        transaction?.type,
                        Utility.dateToDDMMYYY(transaction?.sortStamp),
                        transaction?.quantity,
                        transaction?.unit,
                    ]);
    
                });
            }
        })
    
        autoTable(doc, {
            head: [['Sr No', 'Ingredient Name', 'Category', 'Current Stock', 'Unit', '']],
            body,
            didParseCell: (data) => {
                if (data.section === 'body' && data.cell.raw == `#`) {
                    Object.keys(data?.row?.cells || {})?.forEach(cellIndex => {
                        if (data.row.cells[cellIndex]?.raw != '') {
                            data.row.cells[cellIndex].styles.textColor = '#FFFFFF';
                            data.row.cells[cellIndex].styles.fillColor = '#2596be';
                        }
                    });
                }
            },
            margin: {
                top: 43
            },
            styles: {
                font: 'hindi'
            }
        });
    
        doc.save('Ingredient_Detail_Report.pdf');
    } catch (error) {
        SentryUtilites.setLog("ReportsPage:ingredientDetailReportDownload", error)  
    }
}

  async ingredientDetailReportDownloadExcel() {
    try {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sale Wise Ingredient Report');
    
        worksheet.columns = [
            { header: 'Sr No', key: 'srNo', width: 8, alignment: { horizontal: "left" } },
            { header: 'Ingredient Name', key: 'ingredientName', width: 13, alignment: { horizontal: "left" } },
            { header: 'Category', key: 'category', width: 13, alignment: { horizontal: "left" } },
            { header: 'Current Stock', key: 'currentStock', width: 30, alignment: { horizontal: "left" } },
            { header: 'Unit', key: 'unit', width: 30, alignment: { horizontal: "left" } },
        ];
    
        worksheet.getRow(1).eachCell(cell => {
            cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FF3E80ED' },
            };
        });
    
        this.ingredientDetailReportData?.records.map((record, index) => {
            worksheet.addRow([
                index + 1,
                record?.ingredient?.name,
                record?.ingredient?.category,
                record?.ingredient?.stock,
                record?.ingredient?.unit,
            ]);
            if (record?.transactions?.length) {
                worksheet.addRow([
                    '',
                    '#',
                    'Transaction Type',
                    'Date',
                    'Quantity',
                    'Unit',
                ]).eachCell((cell, cellNo) => {
                    if (cellNo != 1 && cellNo != 2) {
                        cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'FF3E80ED' },
                        };
                    }
                });
    
                record?.transactions?.forEach((transaction, billItemIndex) => {
                    worksheet.addRow([
                        '',
                        billItemIndex + 1,
                        transaction?.type,
                        Utility.dateToDDMMYYY(transaction?.sortStamp),
                        transaction?.quantity,
                        transaction?.unit,
                    ]);
                });
    
            }
        });
    
        workbook.xlsx.writeBuffer().then(buffer => {
            const blob = new Blob([buffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            })
            saveAs(blob, 'Ingredient_Detail_Report.xlsx');
        });
    } catch (error) {
        SentryUtilites.setLog("ReportsPage:ingredientDetailReportDownloadExcel", error)  
    }
}

//Updated Gst sheet
async getGSTR1V1ReportData(): Promise < boolean > {
    return new Promise(async (resolve, reject) => {
        try {

            //: Create a Workbook & later append Worksheets to this Workbook

            const workbook = new ExcelJS.Workbook();

            /////-----------------------------------------------------------------------------------------------

            //: Fetch All Sales from Indexed DB for Further Processing

            let sales = await this.allDataService.saleService.getByBillDateRange(this.startStamp, this.endStamp) || [];
            /////------------------------------------------------------------------------------------------------


            //: GSTR1 Worksheet Data Format
            let gstR1Sales: {
                sale:Sale,
                calculation:{
                    [key:string]: {
                        rate: string;
                        taxableAmount: number;
                        cessAmount: number;
                        igst: number;
                        cgst: number;
                        sgst: number;
                    }
                }
            }[] = [];

            for (let i = 0; i < sales?.length; i++) {
                const sale = sales[i];

                //- Fetched Latest Party from Indexed DB & Check whether GSTIN Variable later Updated or Not
                let fetchedParty = await this.allDataService.partyService.getByUUID(sale?.party?._localUUID);

                if (fetchedParty?._localUUID) {
                    let calculation: {
                        [key: string]: {
                            rate: string;
                            taxableAmount: number;
                            cessAmount: number;
                            igst: number;
                            cgst: number;
                            sgst: number;
                        }
                    } = {};

                    sale?.billItems?.forEach(billItem => {
                        let taxPercentageKey = this.getTaxableAmount(billItem);

                        calculation[billItem?.item?._localUUID] = {
                            rate : taxPercentageKey,
                            taxableAmount: Number(calculation[billItem?.item?._localUUID]?.taxableAmount || 0.0) + Number(billItem.wcdBasePrice || 0) * Number(billItem.quantity || 0),
                            cessAmount: Number(calculation[billItem?.item?._localUUID]?.cessAmount || 0.0) + Number(billItem?.itemTotalCessAmount || 0.0),
                            igst: this.isIgst(sale) ? (calculation[billItem?.item?._localUUID]?.igst || 0) + Number(billItem?.itemTotalGstAmount || 0) : 0,
                            cgst: !this.isIgst(sale) ? (calculation[billItem?.item?._localUUID]?.igst || 0) + (Number(billItem?.itemTotalGstAmount || 0)/2) : 0,
                            sgst: !this.isIgst(sale) ? (calculation[billItem?.item?._localUUID]?.cgst || 0) + (Number(billItem?.itemTotalGstAmount || 0)/2) : 0,
                        };
                    });

                    gstR1Sales.push({
                        sale: { ...sales[i], party: fetchedParty },
                        calculation,
                    });
                }

            }

            //- Sort Main Data in Ascending Order based on Created Stamp
            gstR1Sales?.sort((a: any, b: any) => a?.sale?.billDateStamp == b?.sale?.billDateStamp ? a?.sale?.createdStamp - b?.sale?.createdStamp : a?.sale?.billDateStamp - b?.sale?.billDateStamp);

            const gstR1Worksheet = workbook.addWorksheet('GSTR1');

            const gstR1WorksheetData = [
                ["Date:"],
                ["From",`${Utility.dateToDDMMYYY(this.startStamp)}`,"To",`${Utility.dateToDDMMYYY(this.endStamp)}`],
                ["GSTIN",`${this.selectedProfile?.gstin}`],
                ["Legal Name",`${this.selectedProfile?.legalName || this.selectedProfile?.profileName}`],
                ['(Note: This sheet shows a consolidate view of all kinds of invoices for your reference only)'],
                [""],
                [""],
                [
                    "GSTIN/UIN of Recipient",
                    "Receiver Name",
                    "Invoice Number",
                    "Invoice date",
                    "Invoice Value",
                    "Place Of Supply",
                    "Reverse Charge",
                    "Applicable % of Tax Rate",
                    "Invoice Type",
                    "E-Commerce GSTIN",
                    "Rate",
                    "Taxable Value",
                    "Integrated Tax Amount",
                    "Central Tax Amount",
                    "State/UT Tax Amount",
                    "Cess Amount"
                ]
            ]
            
            gstR1WorksheetData?.forEach((rowData, rowIndex) => {
                const row = gstR1Worksheet.addRow(rowData);
                if (rowIndex === 0 || rowIndex === 1 || rowIndex === 2 || rowIndex === 3 || rowIndex === 4) { // Rows to be bold
                    row.eachCell((cell, colNumber) => {
                        if (colNumber === 1 || colNumber === 3) { 
                            cell.font = {
                                bold: true
                            };
                        }
                    });
                } else if (rowIndex === 6) { // Seventh row
                    row.eachCell((cell, colNumber) => {
                        if (colNumber === 1) { 
                            cell.font = {
                                bold: true
                            };
                        }
                    });
                } else if (rowIndex === 7) { // Eighth row
                    row.eachCell((cell, colNumber) => {
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'f7caac' } 
                        };
                        cell.font = {
                            color: { argb: '000000' } 
                        };
                    });
                }
            });
            gstR1Sales?.forEach(sale => {
                for (let key in sale?.calculation) {
                    gstR1Worksheet.addRow([
                        sale?.sale?.party?.gstin || '-',
                        sale?.sale?.party?.name || '-',
                        sale?.sale?.billNo?.replace(/_/g, ""),
                        Utility.dateToDDMMYYYWithMonthStr(sale?.sale?.billDateStamp, '-'),
                        Number(sale?.sale?.totalAmount),
                        Utility.statesWithCodeByStateName[sale?.sale?.deliveryProvience] || 'Missing Place of Supply',
                        "",
                        "",
                        "Regular B2B",
                        "",
                        sale?.calculation?.[key]?.rate,
                        sale?.calculation?.[key]?.taxableAmount,
                        sale?.calculation?.[key]?.igst,
                        sale?.calculation?.[key]?.cgst,
                        sale?.calculation?.[key]?.sgst,
                        sale?.calculation?.[key]?.cessAmount,
                    ]);
                }
            });

            //------------------------------------------------------------------------------------------------

            //: B2B Worksheet Data Format

            let b2bGstinSales: {
                sale: Sale,
                calculation: {
                    [key: number]: {
                        taxableAmount: number;
                        cessAmount: number;
                    }
                },
            }[] = [];

            /////------------------------------------------------------------------------------------------------

            //: Iterate through All Sales to find GSTIN Party Sales & for Further Calculations

            for (let i = 0; i < sales?.length; i++) {
                const sale = sales[i];

                //- Fetched Latest Party from Indexed DB & Check whether GSTIN Variable later Updated or Not
                let fetchedParty = await this.allDataService.partyService.getByUUID(sale?.party?._localUUID);

                if (fetchedParty?._localUUID && fetchedParty?.gstin) {
                    let calculation: {
                        [key: number]: {
                            taxableAmount: number;
                            cessAmount: number;
                        }
                    } = {};

                    sale?.billItems?.forEach(billItem => {
                        if (billItem?.taxPercentage) {
                            calculation[billItem?.taxPercentage] = {
                                taxableAmount: Number(calculation[billItem?.taxPercentage]?.taxableAmount || 0.0) + Number(billItem?.wcdBasePrice || 0) * Number(billItem?.quantity || 0),
                                cessAmount: Number(calculation[billItem?.taxPercentage]?.cessAmount || 0.0) + Number(billItem?.itemTotalCessAmount || 0.0),
                            };
                        }
                    });

                    b2bGstinSales.push({
                        sale: { ...sales[i], party: fetchedParty },
                        calculation,
                    });
                }

            }

            //- Sort Main Data in Ascending Order based on Created Stamp
            b2bGstinSales?.sort((a: any, b: any) => a?.sale?.billDateStamp == b?.sale?.billDateStamp ? a?.sale?.createdStamp - b?.sale?.createdStamp : a?.sale?.billDateStamp - b?.sale?.billDateStamp);

            /////------------------------------------------------------------------------------------------------

            //: Genearte B2B Worksheet & Add Data in the Worksheet

            const b2bWorksheet = workbook.addWorksheet('b2b');

            let b2bTaxableValue = 0;
            let b2bCessAmount = 0;

            let b2bUniqueBillNumbers = [];
            let b2bUniquetotalAmount = [];
            let b2bUniqueGst = []
            
            // Iterate over the array and collect unique bill numbers and total amounts
            b2bGstinSales?.forEach(item => {
                if(Object.keys(item?.calculation).length){
                    const billNo = item?.sale?.billNo;
                    const gstNo = item?.sale?.party?.gstin;
                    const totalAmount = item?.sale?.totalAmount;
                    const calculation = item?.calculation;

                    // Check if the bill number already exists in the unique bill numbers array
                    if (!b2bUniqueBillNumbers.includes(billNo)) {
                        b2bUniqueBillNumbers.push(billNo);
                        b2bUniquetotalAmount.push(totalAmount);
                    } else {
                        // If the bill number already exists, check if the total amount exists
                        const index = b2bUniqueBillNumbers.indexOf(billNo);
                        if (b2bUniquetotalAmount[index] !== totalAmount) {
                            // If the total amount is different, update the total amount for that bill number
                            b2bUniquetotalAmount[index] = totalAmount;
                        }
                    }

                    if(!b2bUniqueGst.includes(gstNo)){
                        b2bUniqueGst.push(gstNo);
                    }

                    Object.values(calculation)?.forEach(calc => {
                        b2bTaxableValue += calc?.taxableAmount;
                        b2bCessAmount += calc?.cessAmount;
                    });
                }
            });
            
            // Sum up the unique total amounts
            let b2bUniqueBillNumbersCount = b2bUniqueBillNumbers?.length || 0;
            let b2bUniquetotalAmountCount = b2bUniquetotalAmount.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            let b2bUniqueGstCount = b2bUniqueGst?.length || 0;

            const b2blWorksheetData = [
                ["Summary For B2B",""],
                ["No. of Recipients","","No. of Invoices","","Total Invoice Value","","","","","","","Total Taxable Value","Total Cess"],
                [Number(b2bUniqueGstCount),"", Number(b2bUniqueBillNumbersCount),"", Number(b2bUniquetotalAmountCount),"","","","","","",Number(b2bTaxableValue),Number(b2bCessAmount)],
                [
                    "GSTIN/UIN of Recipient",
                    "Receiver Name",
                    "Invoice Number",
                    "Invoice date",
                    "Invoice Value",
                    "Place Of Supply",
                    "Reverse Charge",
                    "Applicable % of Tax Rate",
                    "Invoice Type",
                    "E-Commerce GSTIN",
                    "Rate",
                    "Taxable Value",
                    "Cess Amount"
                ]
            ]

            b2blWorksheetData?.forEach((rowData,rowIndex) => {
                this.applyGstRowStyles(b2bWorksheet,rowData,rowIndex);
            });

            b2bGstinSales?.forEach(b2bGstinSale => {
                for (let key in b2bGstinSale?.calculation) {
                    b2bWorksheet.addRow([
                        b2bGstinSale?.sale?.party?.gstin || '-',
                        b2bGstinSale?.sale?.party?.name || '-',
                        b2bGstinSale?.sale?.billNo?.replace(/_/g, ""),
                        Utility.dateToDDMMYYYWithMonthStr(b2bGstinSale?.sale?.billDateStamp, '-'),
                        Number(b2bGstinSale?.sale?.totalAmount),
                        Utility.statesWithCodeByStateName[b2bGstinSale?.sale?.deliveryProvience] || 'Missing Place of Supply',
                        "",
                        "",
                        "Regular B2B",
                        "",
                        key,
                        b2bGstinSale?.calculation?.[key]?.taxableAmount,
                        b2bGstinSale?.calculation?.[key]?.cessAmount,
                    ]);
                }
            });

            /////-----------------------------------------------------------------------------------------------

            //: B2CL Worksheet Data Format

            let b2clNonGstinSales: {
                sale: Sale,
                calculation: {
                    [key: number]: {
                        taxableAmount: number;
                        cessAmount: number;
                    }
                },
            }[] = [];

            /////------------------------------------------------------------------------------------------------

            //: Iterate through All Sales to find NON GSTIN Party Sales & for Further Calculations

            for (let i = 0; i < sales?.length; i++) {
                const sale = sales[i];

                //- Fetched Latest Party from Indexed DB & Check whether GSTIN Variable later Updated or Not
                let fetchedParty = await this.allDataService.partyService.getByUUID(sale?.party?._localUUID);

                if (fetchedParty?._localUUID && !fetchedParty?.gstin && Number(sale?.totalAmount) > 250000) {

                    let calculation: {
                        [key: number]: {
                            taxableAmount: number;
                            cessAmount: number;
                        } 
                    } = {};

                    sale?.billItems?.forEach(billItem => {
                        if(billItem?.taxPercentage  || billItem?.itemTotalCessAmount){
                            calculation[billItem?.taxPercentage || 0] = {
                                taxableAmount: Number(calculation[billItem?.taxPercentage || 0]?.taxableAmount || 0.0) + Number(billItem?.wcdBasePrice || 0) * Number(billItem?.quantity || 0),
                                cessAmount: Number(calculation[billItem?.taxPercentage || 0]?.cessAmount || 0.0) + Number(billItem?.itemTotalCessAmount || 0.0),
                            };
                        }
                    });

                    b2clNonGstinSales.push({
                        sale: { ...sales[i], party: fetchedParty },
                        calculation,
                    });
                }

            }

            //- Sort Main Data in Ascending Order based on Created Stamp
            b2clNonGstinSales?.sort((a: any, b: any) => a?.sale?.billDateStamp == b?.sale?.billDateStamp ? a?.sale?.createdStamp - b?.sale?.createdStamp : a?.sale?.billDateStamp - b?.sale?.billDateStamp);

            /////------------------------------------------------------------------------------------------------

            //: Genearte B2CL Worksheet & Add Data in the Worksheet

            const b2clWorksheet = workbook.addWorksheet('b2cl');

            let b2clTaxableValue = 0;
            let b2clCessAmount = 0;

            let b2clUniqueBillNumbers = [];
            let b2clUniquetotalAmount = [];
            
            // Iterate over the array and collect unique bill numbers and total amounts
            b2clNonGstinSales?.forEach(item => {
                const billNo = item?.sale?.billNo;
                const totalAmount = item?.sale?.totalAmount;
                const calculation = item?.calculation;

                // Check if the bill number already exists in the unique bill numbers array
                if (!b2clUniqueBillNumbers.includes(billNo)) {
                    b2clUniqueBillNumbers.push(billNo);
                    b2clUniquetotalAmount.push(totalAmount);
                } else {
                    // If the bill number already exists, check if the total amount exists
                    const index = b2clUniqueBillNumbers.indexOf(billNo);
                    if (b2clUniquetotalAmount[index] !== totalAmount) {
                        // If the total amount is different, update the total amount for that bill number
                        b2clUniquetotalAmount[index] = totalAmount;
                    }
                }

                Object.values(calculation)?.forEach(calc => {
                    b2clTaxableValue += calc?.taxableAmount;
                    b2clCessAmount += calc?.cessAmount;
                });
                
            });
            
            // Sum up the unique total amounts
            let b2clUniqueBillNumbersCount = b2clUniqueBillNumbers?.length || 0;
            let b2clUniquetotalAmountCount = b2clUniquetotalAmount.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

            const b2clWorksheetData = [
                ["Summary For B2CL(5)"],
                ["No. of Invoices","","","","","","","",""],
                [Number(b2clUniqueBillNumbersCount),"", Number(b2clUniquetotalAmountCount),"","","",Number(Utils.capFractionsToSix(b2clTaxableValue)), Number(Utils.capFractionsToSix(b2clCessAmount)),""],
                ["Invoice Number", "Invoice date","Invoice Value","Place Of Supply","Applicable % of Tax Rate","Rate","Taxable Value","Cess Amount","E-Commerce GSTIN"]
            ];

            b2clWorksheetData?.forEach((rowData,rowIndex) => {
                this.applyGstRowStyles(b2clWorksheet,rowData,rowIndex);
            });


            b2clNonGstinSales?.forEach(b2clNonGstinSale => {
                for (let key in b2clNonGstinSale?.calculation) {
                    b2clWorksheet.addRow([
                        b2clNonGstinSale?.sale?.billNo?.replace(/_/g, ""),
                        Utility.dateToDDMMYYYWithMonthStr(b2clNonGstinSale?.sale?.billDateStamp, '-'),
                        Number(b2clNonGstinSale?.sale?.totalAmount),
                        Utility.statesWithCodeByStateName[b2clNonGstinSale?.sale?.deliveryProvience] || 'Missing Place of Supply',
                        "",
                        key,
                        b2clNonGstinSale?.calculation?.[key]?.taxableAmount,
                        b2clNonGstinSale?.calculation?.[key]?.cessAmount,
                        "",
                    ]);
                }
            });

            /////-----------------------------------------------------------------------------------------------

            //: B2CS Worksheet Data Format

            let b2csNonGstinSales: {
                [key: string]: {
                    [key: number]: {
                        taxableAmount: number;
                        cessAmount: number;
                    };
                };
            } = {};

            for (let i = 0; i < sales?.length; i++) {
                const sale = sales[i];
                let fetchedParty = await this.allDataService.partyService.getByUUID(sale?.party?._localUUID);
                let placeOfSupply =  Utility.statesWithCodeByStateName[sale?.deliveryProvience] || 'Missing Place of Supply';

                if(fetchedParty?._localUUID && !fetchedParty?.gstin && Number(sale?.totalAmount) < 250000){
                    sale?.billItems?.forEach(billItem => {

                        if (placeOfSupply && (!fetchedParty?.gstin) && (billItem?.taxPercentage  || billItem?.itemTotalCessAmount)) {
                            const taxPercentage = billItem?.taxPercentage;
                            if (!b2csNonGstinSales[placeOfSupply]) {
                                b2csNonGstinSales[placeOfSupply] = {};
                            }
                            if (!b2csNonGstinSales[placeOfSupply][taxPercentage]) {
                                b2csNonGstinSales[placeOfSupply][taxPercentage] = {
                                    taxableAmount: 0,
                                    cessAmount: 0
                                };
                            }
                            if(billItem?.taxPercentage  || billItem?.itemTotalCessAmount){
                                b2csNonGstinSales[placeOfSupply][taxPercentage].taxableAmount += Number(billItem?.wcdBasePrice || 0) * Number(billItem?.quantity || 0);
                                b2csNonGstinSales[placeOfSupply][taxPercentage].cessAmount += Number(billItem?.itemTotalCessAmount || 0.0);
                            }
              
                        }
                    });
                }
                
            }

            //: Genearte B2CS Worksheet & Add Data in the Worksheet

            const b2csWorksheet = workbook.addWorksheet('b2cs');

            let b2csTaxableValue = 0;
            let b2csCessAmount = 0;

            // Add up values from all sales data
            for (let key in b2csNonGstinSales) {
                let innerObject = b2csNonGstinSales[key];
                for (let innerKey in innerObject) {
                    b2csTaxableValue += innerObject[innerKey]?.taxableAmount;
                    b2csCessAmount += innerObject[innerKey]?.cessAmount;
                }
            }

            const b2csWorksheetData = [
                ["Summary For B2CS(7)"],
                ["","","","","Total Taxable  Value","Total Cess",""],
                ["","","","", Number(Utils.capFractionsToSix(b2csTaxableValue)),Number(Utils.capFractionsToSix(b2csCessAmount)),""],
                ["Type","Place Of Supply","Applicable % of Tax Rate","Rate","Taxable Value","Cess Amount","E-Commerce GSTIN"]
            ];

            b2csWorksheetData?.forEach((rowData,rowIndex) => {
                this.applyGstRowStyles(b2csWorksheet,rowData,rowIndex);
            });

            for (let key in b2csNonGstinSales) {
                for (let nestedKey in b2csNonGstinSales[key]) {
                    b2csWorksheet.addRow([
                        "OE",
                        key,
                        "",
                        nestedKey,
                        b2csNonGstinSales?.[key]?.[nestedKey]?.taxableAmount,
                        b2csNonGstinSales?.[key]?.[nestedKey]?.cessAmount,
                        "",
                    ]);
                    
                }
            }


            /////-----------------------------------------------------------------------------------------------

            //: EXEMP Worksheet Data Format

            let exempSales = {
                interStateRegistered: {
                    nil: 0,
                    exempted: 0,
                },
                intraStateRegistered: {
                    nil: 0,
                    exempted: 0,
                },
                interStateUnregistered: {
                    nil: 0,
                    exempted: 0,
                },
                intraStateUnregistered: {
                    nil: 0,
                    exempted: 0,
                }
            };

            /////------------------------------------------------------------------------------------------------

            //: Iterate through All Sales to find GSTIN / NON GSTIN Party Sales & for Further Calculations

            for (let i = 0; i < sales?.length; i++) {
                const sale = sales[i];

                //- Fetched Latest Party from Indexed DB & Check whether GSTIN Variable later Updated or Not
                let fetchedParty = await this.allDataService.partyService.getByUUID(sale?.party?._localUUID);

                
                sale?.billItems?.forEach(billItem => {
                    
                    if(!sale?.deliveryProvience || !this.selectedProfile?.addressProvience){
                        if(fetchedParty?.gstin){
                            if(billItem?.item?.isTaxZero == 1){
                                exempSales.intraStateRegistered.nil += Number(billItem?.basePrice * billItem?.quantity) || 0.0;
                            }else if(billItem?.item?.isTaxExempted == 1){
                                exempSales.intraStateRegistered.exempted += Number(billItem?.basePrice * billItem?.quantity) || 0.0;
                            }
                        }else{
                            if(billItem?.item?.isTaxZero == 1){
                                exempSales.intraStateUnregistered.nil += Number(billItem?.basePrice * billItem?.quantity) || 0.0;
                            }else if(billItem?.item?.isTaxExempted == 1){
                                exempSales.intraStateUnregistered.exempted += Number(billItem?.basePrice * billItem?.quantity) || 0.0;
                            }
                        }
                    }else{
                        if (
                            sale?.deliveryProvience != this.selectedProfile?.addressProvience
                            && fetchedParty?.gstin
                        ) {
                            if (
                                 billItem?.item?.isTaxZero == 1
                            ) {
                                exempSales.interStateRegistered.nil += Number(billItem?.basePrice * billItem?.quantity) || 0.0;
                            } else if (
                                billItem?.item?.isTaxExempted == 1
                            ) {
                                exempSales.interStateRegistered.exempted += Number(billItem?.basePrice * billItem?.quantity) || 0.0;
                            }
                        } else if (
                            sale?.deliveryProvience == this.selectedProfile?.addressProvience
                            && fetchedParty?.gstin
                        ) {
                            if (
                                 billItem?.item?.isTaxZero == 1
                            ) {
                                exempSales.intraStateRegistered.nil += Number(billItem?.basePrice * billItem?.quantity) || 0.0;
                            } else if (
                                billItem?.item?.isTaxExempted == 1
                            ) {
                                exempSales.intraStateRegistered.exempted += Number(billItem?.basePrice * billItem?.quantity) || 0.0;
                            }
                        } else if (
                            sale?.deliveryProvience != this.selectedProfile?.addressProvience
                            && !fetchedParty?.gstin
                        ) {
                            if (
                                 billItem?.item?.isTaxZero == 1
                            ) {
                                exempSales.interStateUnregistered.nil += Number(billItem?.basePrice * billItem?.quantity) || 0.0;
                            } else if (
                                 billItem?.item?.isTaxExempted == 1
                            ) {
                                exempSales.interStateUnregistered.exempted += Number(billItem?.basePrice * billItem?.quantity) || 0.0;
                            }
                        } else if (
                            sale?.deliveryProvience == this.selectedProfile?.addressProvience
                            && !fetchedParty?.gstin
                        ) {
                            if (
                                 billItem?.item?.isTaxZero == 1
                            ) {
                                exempSales.intraStateUnregistered.nil += Number(billItem?.basePrice * billItem?.quantity) || 0.0;
                            } else if (
                                billItem?.item?.isTaxExempted == 1
                            ) {
                                exempSales.intraStateUnregistered.exempted += Number(billItem?.basePrice * billItem?.quantity) || 0.0;
                            }
                        }
                    }

                });
            }

            let totalNilRated = 0;
            let totalExempted = 0;
            let totalNonGst = 0;

            for (let category in exempSales) {
                for (let type in exempSales[category]) {
                    if (type === 'nil') {
                        totalNilRated += exempSales[category][type];
                    }else if(type = 'exempted'){
                        totalExempted += exempSales[category][type];
                    }
                }
            }

            /////------------------------------------------------------------------------------------------------

            //: Genearte EXEMP Worksheet & Add Data in the Worksheet

            const exempsheetData = [
                ["Summary For Nil rated, exempted and non GST outward supplies (8)"],
                ["","Total Nil Rated Supplies","Total Exempted Supplies","Total Non-GST Supplies"],
                ["",Number(totalNilRated),Number(totalExempted),Number(totalNonGst)],
                ["Description", "Nil Rated Supplies","Exempted(Other than Nil Rated/Non GST Supply)","Non-GST Supplies"]
            ];

            const exempWorksheet = workbook.addWorksheet('exemp');

            exempsheetData?.forEach((rowData, rowIndex) => {
                this.applyGstRowStyles(exempWorksheet,rowData,rowIndex);
            });

            let titles = {
                interStateRegistered: 'Inter-State Supplies to Registered Persons',
                intraStateRegistered: 'Intra-State Supplies to Registered Persons',
                interStateUnregistered: 'Inter-State Supplies to Unregistered Persons',
                intraStateUnregistered: 'Intra-State Supplies to Unregistered Persons',
            };

            for (let key in exempSales) {
                exempWorksheet.addRow([
                    titles[key],
                    exempSales[key]?.nil,
                    exempSales[key]?.exempted,
                    0,
                ]);
            }

            /////-----------------------------------------------------------------------------------------------

            //: HSN Worksheet Data Format

            let hsnSales: {
                [key: string]: {
                    [key: string]: {
                        _localUUID: string;
                        name: string;
                        uqc: string;
                        quantity: number;
                        totalAmount: number;
                        rate: number;
                        taxableAmount: number;
                        igst: number;
                        cgst: number;
                        sgst: number;
                        cessAmount: number;
                    };
                };
            } = {};

            /////------------------------------------------------------------------------------------------------
            for (let i = 0; i < sales?.length; i++) {
                const sale = sales[i];
                sale?.billItems?.forEach(billItem => {
                        
                    if(billItem?.item?.hsn && (billItem?.taxPercentage  || billItem?.itemTotalCessAmount)){

                        if(billItem?.item?.hsn){
                            if (!hsnSales[billItem?.item?.hsn ]) {
                                hsnSales[billItem?.item?.hsn ] = {};
                            }
                         }

                        hsnSales[billItem?.item?.hsn ][ (billItem?.taxPercentage) + (billItem?.item?.primaryUnit || "OTH-OTHERS")]  = {
                            _localUUID: billItem?.item?._localUUID,
                            name: "",
                            uqc: this.getUqc(billItem?.item?.primaryUnit),
                            quantity:((hsnSales[billItem?.item?.hsn ]?.[billItem?.taxPercentage + (billItem?.item?.primaryUnit || "OTH-OTHERS")]?.quantity || 0) + Number(billItem?.quantity || 0)),
                            totalAmount: (hsnSales[billItem?.item?.hsn ]?.[billItem?.taxPercentage + (billItem?.item?.primaryUnit || "OTH-OTHERS")]?.totalAmount || 0) + Number(billItem?.total || 0),
                            rate:  Number(billItem?.taxPercentage || 0),
                            taxableAmount: (hsnSales[billItem?.item?.hsn ]?.[billItem?.taxPercentage + (billItem?.item?.primaryUnit || "OTH-OTHERS")]?.taxableAmount || 0) + (Number(billItem?.wcdBasePrice || 0) * Number(billItem?.quantity || 0)),
                            igst: this.isIgst(sale) ? (hsnSales[billItem?.item?.hsn ]?.[billItem?.taxPercentage + (billItem?.item?.primaryUnit || "OTH-OTHERS")]?.igst || 0) + Number(billItem?.itemTotalGstAmount || 0) : 0,
                            cgst: !this.isIgst(sale) ? Utils.capFractionsToSix(((hsnSales[billItem?.item?.hsn ]?.[billItem?.taxPercentage + (billItem?.item?.primaryUnit || "OTH-OTHERS")]?.cgst || 0) + (Number(billItem?.itemTotalGstAmount || 0)/2))) : 0,
                            sgst: !this.isIgst(sale) ? Utils.capFractionsToSix(((hsnSales[billItem?.item?.hsn ]?.[billItem?.taxPercentage + (billItem?.item?.primaryUnit || "OTH-OTHERS")]?.sgst || 0) + (Number(billItem?.itemTotalGstAmount || 0)/2))) : 0,
                            cessAmount: (hsnSales[billItem?.item?.hsn ]?.[billItem?.taxPercentage + (billItem?.item?.primaryUnit || "OTH-OTHERS")]?.cessAmount || 0) + Number(billItem?.itemTotalCessAmount || 0),
                        };
                    }

                    
                });
            }

            /////------------------------------------------------------------------------------------------------

            // Collect unique HSN codes
            const uniqueHsn = new Set();
            for (let hsnCode in hsnSales) {
                console.log(hsnCode)
                uniqueHsn.add(hsnCode);
            }

            // Calculate unique HSN count
            const hsnCount = uniqueHsn.size;

           // variables for each total
            let hsnTotalAmount = 0;
            let hsnTotalTaxableAmount = 0;
            let hsnTotalIGST = 0;
            let hsnTotalCGST = 0;
            let hsnTotalSGST = 0;
            let hsnTotalCessAmount = 0;

            // Add up values from all sales data
            for (let hsnCode in hsnSales) {
                const salesData = hsnSales[hsnCode];
                for (let localUUID in salesData) {
                    const Sale = salesData[localUUID];
                    hsnTotalAmount+= Sale?.totalAmount;
                    hsnTotalTaxableAmount += Sale?.taxableAmount;
                    hsnTotalIGST += Sale?.igst;
                    hsnTotalCGST += Sale?.cgst;
                    hsnTotalSGST += Sale?.sgst;
                    hsnTotalCessAmount += Sale?.cessAmount;
                }
            }

            //: Genearte HSN Worksheet & Add Data in the Worksheet

            const hsnWorksheetData = [
                ["Summary For Itemwise"],
                ["No. of HSN", "", "", "", "Total Value", "", "Total Taxable Value", "Total Integrated Tax", "Total Central Tax", "Total State/UT Tax", "Total Cess"],
                [Number(hsnCount), "", "", "", Number(hsnTotalAmount.toFixed(4)), "", Number(hsnTotalTaxableAmount.toFixed(4)), Number(hsnTotalIGST.toFixed(4)), Number(hsnTotalCGST.toFixed(4)), Number(hsnTotalSGST.toFixed(4)), Number(hsnTotalCessAmount.toFixed(4))],
                ["HSN", "Description", "UQC", "Total Quantity", "Total Value", "Rate", "Taxable Value", "Integrated Tax Amount", "Central Tax Amount", "State/UT Tax Amount", "Cess Amount"]
            ];

            const hsnWorksheet = workbook.addWorksheet('hsn');

            hsnWorksheetData?.forEach((rowData,rowIndex) => {
                this.applyGstRowStyles(hsnWorksheet,rowData,rowIndex);
            });

        for (let hsnCode in hsnSales) {
            const salesData = hsnSales[hsnCode];
            for (let localUUID in salesData) {
                const itemWiseSale = salesData[localUUID];
                    hsnWorksheet.addRow([
                        hsnCode,
                        itemWiseSale?.name,
                        itemWiseSale?.uqc,
                        itemWiseSale?.quantity,
                        itemWiseSale?.totalAmount,
                        itemWiseSale?.rate,
                        itemWiseSale?.taxableAmount,
                        itemWiseSale?.igst,
                        itemWiseSale?.cgst,
                        itemWiseSale?.sgst,
                        itemWiseSale?.cessAmount
                    ]);
            }

        }

            /////-----------------------------------------------------------------------------------------------

            //: ItemWise (if hsn is not added)  Worksheet Data Format
            let itemWiseHsnSales: {
                [key: string]: {
                    [key: string]: {
                        name: string;
                        uqc: string;
                        quantity: number;
                        totalAmount: number;
                        rate: number;
                        taxableAmount: number;
                        igst: number;
                        cgst: number;
                        sgst: number;
                        cessAmount: number;
                    };
                };
            } = {};

            for (let i = 0; i < sales?.length; i++) {
                const sale = sales[i];
                sale?.billItems?.forEach(billItem => {

                    const taxRate = this.getTaxableAmount(billItem);

                    const uniqueKey = `${billItem?.item?._localUUID}_${taxRate}`;

                    if (!itemWiseHsnSales[billItem?.item?.hsn || "EMPTY"]) {
                        itemWiseHsnSales[billItem?.item?.hsn || "EMPTY"] = {};
                    }
                    
                    itemWiseHsnSales[billItem?.item?.hsn || "EMPTY"][uniqueKey] = {
                        name: billItem?.item?.itemName,
                        uqc: this.getUqc(billItem?.item?.primaryUnit),
                        quantity:((itemWiseHsnSales[billItem?.item?.hsn || "EMPTY"]?.[uniqueKey]?.quantity || 0) + Number(billItem?.quantity || 0)),
                        totalAmount: (itemWiseHsnSales[billItem?.item?.hsn || "EMPTY"]?.[uniqueKey]?.totalAmount || 0) + Number(billItem?.total || 0),
                        rate: this.getTaxableAmount(billItem),
                        taxableAmount: (itemWiseHsnSales[billItem?.item?.hsn || "EMPTY"]?.[uniqueKey]?.taxableAmount || 0) + (Number(billItem?.wcdBasePrice || 0) * Number(billItem?.quantity || 0)),
                        igst: this.isIgst(sale) ? (itemWiseHsnSales[billItem?.item?.hsn || "EMPTY"]?.[uniqueKey]?.igst || 0) + Number(billItem?.itemTotalGstAmount || 0) : 0,
                        cgst: !this.isIgst(sale) ? Utils.capFractionsToSix(((itemWiseHsnSales[billItem?.item?.hsn || "EMPTY"]?.[uniqueKey]?.cgst || 0) + Number(billItem?.itemTotalGstAmount || 0)) / 2) : 0,
                        sgst: !this.isIgst(sale) ? Utils.capFractionsToSix(((itemWiseHsnSales[billItem?.item?.hsn || "EMPTY"]?.[uniqueKey]?.sgst || 0) + Number(billItem?.itemTotalGstAmount || 0)) / 2) : 0,
                        cessAmount: (itemWiseHsnSales[billItem?.item?.hsn || "EMPTY"]?.[uniqueKey]?.cessAmount || 0) + Number(billItem?.itemTotalCessAmount || 0),
                    };
                });
            }

             // Collect unique HSN codes
             const uniqueHsnCodes = new Set();
             for (let hsnCode in itemWiseHsnSales) {
                if(hsnCode !== "EMPTY"){
                    uniqueHsnCodes.add(hsnCode);
                }
             }

            // Calculate unique HSN count
            const uniqueHsnCount = uniqueHsnCodes.size;

           // variables for each total
            let totalAmount = 0;
            let totalTaxableAmount = 0;
            let totalIGST = 0;
            let totalCGST = 0;
            let totalSGST = 0;
            let totalCessAmount = 0;

            // Add up values from all sales data
            for (let hsnCode in itemWiseHsnSales) {
                const salesData = itemWiseHsnSales[hsnCode];
                for (let localUUID in salesData) {
                    const itemWiseSale = salesData[localUUID];
                    totalAmount+= itemWiseSale?.totalAmount;
                    totalTaxableAmount += itemWiseSale?.taxableAmount;
                    totalIGST += itemWiseSale?.igst;
                    totalCGST += itemWiseSale?.cgst;
                    totalSGST += itemWiseSale?.sgst;
                    totalCessAmount += itemWiseSale?.cessAmount;
                }
            }
        
            //: ItemWise (if hsn is not added) Worksheet & Add Data in the Worksheet
            const worksheetData = [
                ["Summary For Itemwise"],
                ["No. of HSN", "", "", "", "Total Value", "", "Total Taxable Value", "Total Integrated Tax", "Total Central Tax", "Total State/UT Tax", "Total Cess"],
                [Number(uniqueHsnCount), "", "", "", Number(totalAmount.toFixed(4)), "", Number(totalTaxableAmount.toFixed(4)), Number(totalIGST.toFixed(4)), Number(totalCGST.toFixed(4)), Number(totalSGST.toFixed(4)), Number(totalCessAmount.toFixed(4))],
                ["HSN", "Description", "UQC", "Total Quantity", "Total Value", "Rate", "Taxable Value", "Integrated Tax Amount", "Central Tax Amount", "State/UT Tax Amount", "Cess Amount"]
            ];
            
            const itemWiseWorksheet = workbook.addWorksheet('ItemWise (if hsn is not added)');

            worksheetData?.forEach((rowData,rowIndex) => {
                this.applyGstRowStyles(itemWiseWorksheet,rowData,rowIndex)
            });

            for (let hsnCode in itemWiseHsnSales) {
                const salesData = itemWiseHsnSales[hsnCode];
                for (let localUUID in salesData) {
                    const itemWiseSale = salesData[localUUID];
                    itemWiseWorksheet.addRow([
                        hsnCode === "EMPTY" ? "" : hsnCode,
                        itemWiseSale?.name,
                        itemWiseSale?.uqc,
                        itemWiseSale?.quantity,
                        itemWiseSale?.totalAmount,
                        itemWiseSale?.rate,
                        itemWiseSale?.taxableAmount,
                        itemWiseSale?.igst,
                        itemWiseSale?.cgst,
                        itemWiseSale?.sgst,
                        itemWiseSale?.cessAmount
                    ]);
                }
            }
            
            /////-----------------------------------------------------------------------------------------------

            workbook.xlsx.writeBuffer().then(buffer => {
                const blob = new Blob([buffer], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                })
                saveAs(blob, 'GST1_Report.xlsx');
            });

            return resolve(true);
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:getGSTR1V1ReportData", error)  
            console.error(error)
            return resolve(false);
        }
    });
}

getUqc(uqc: string): string {
    try {
        const uqcTable = Utility.uqcTable;
        if (uqc in uqcTable) {
            return uqcTable[uqc];
        } else {
            return "OTH-OTHERS";
        }
    } catch (error) {
        SentryUtilites.setLog("ReportsPage:getUqc", error)  
        return '';
    }
}

getPlaceOfSupply(sale: Sale): string {
    try {
        return Utility.statesNamesByStateCode[sale?.deliveryProvience] || Utility.statesNamesByStateCode[sale?.party?.gstin?.substring(0, 2)] || Utility.statesNamesByStateCode[this.selectedProfile?.addressProvience] || "Missing Place of Supply";
    } catch (error) {
        SentryUtilites.setLog("ReportsPage:getPlaceOfSupply", error)  
        return '';
    }
}

isIgst(sale: Sale): boolean {
    try {
        return (
            (
                Utility.statesNamesByStateCode[sale?.deliveryProvience]
                || Utility.statesNamesByStateCode[sale?.party?.gstin?.substring(0, 2)]
                || Utility.statesNamesByStateCode[this.selectedProfile?.addressProvience]
            )
            !=
            (
                Utility.statesNamesByStateCode[this.selectedProfile?.addressProvience]
            )
        );
    } catch (error) {
        SentryUtilites.setLog("ReportsPage:isIgst", error)  
        return false;
    }
}

  async getStartEndCutOffDayTime() {
    try {
        let cutOffDays = await this.allDataService.cutOffDayService.getAllByPromise();
        cutOffDays?.forEach(cutOffDay => {
            this.startTimeCutOffArr.push(cutOffDay?.startStamp);
            this.endTimeCutOffArr.push(cutOffDay?.endStamp);
        });
    } catch (error) {
        SentryUtilites.setLog("ReportsPage:getStartEndCutOffDayTime", error)  
    }
}

    applyGstRowStyles(worksheet:any,rowData:any,rowIndex:number){
        try {
            const row = worksheet.addRow(rowData);
    
                // Apply styles based on the row index
                if (rowIndex === 0 || rowIndex === 1) { // First and second rows
                        row.eachCell((cell) => {
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: '0070c0' } 
                            };
                            cell.font = {
                                color: { argb: 'FFFFFF' }, 
                                bold: true
                            };
                        });
                    } else if (rowIndex === 3) { // Fourth row
                        row.eachCell((cell) => {
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'f7caac' } 
                            };
                            cell.font = {
                                color: { argb: '000000' } 
                            };
                        });
                    }
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:applyGstRowStyles", error)  
        }
    }
    
    getTaxableAmount(billItem: BillItem){
        try {
            let taxPercentage;
    
            if(!billItem?.item?.isTaxZero && !billItem?.item?.isTaxExempted && !billItem?.item?.taxPercentage) {
                taxPercentage = "undefined"
            }else if(billItem?.item?.isTaxZero && !billItem?.item?.isTaxExempted && !billItem?.item?.taxPercentage){
                taxPercentage = "0";
            }else if(!billItem?.item?.isTaxZero && billItem?.item?.isTaxExempted && !billItem?.item?.taxPercentage){
                taxPercentage = "Exempted";
            }else{
                taxPercentage = billItem?.item?.taxPercentage + "";
            }
    
            return taxPercentage
        } catch (error) {
            SentryUtilites.setLog("ReportsPage:getTaxableAmount", error)  
            return '';
        }
    }

}
