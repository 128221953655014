<app-pin-verification (verifyTransactionPin)="verifyTransactionPIN($event)"  #pinVerificationElement></app-pin-verification>

<!-- Bulk Sale -->

<ion-modal class="modal-bulk-sale" [isOpen]="isBulkSaleModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar color="light">
        <ion-grid>
          <ion-row class="ion-align-items-center">
            <ion-col size="auto">
              <ion-title>Delete Sale</ion-title>
            </ion-col>
            <ion-col class="ion-text-center">
              <ion-label class="font-size-16">From {{fromStamp | date:'dd/MM/yyyy'}} </ion-label>
              <ion-label class="font-size-16">To {{toStamp | date:'dd/MM/yyyy'}}</ion-label>
            </ion-col>
            <ion-col size="auto" class="ion-text-end">
              <ion-buttons>
                <ion-button (click)="openBulkSaleModal(false)">Close</ion-button>
              </ion-buttons>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-toolbar>
      

    </ion-header>

    <ion-content class="ion-padding">

      <ion-grid>

        <ion-row class="fixed-row">
          <ion-col size="12">

            <!-- Select all sales -->
            <ion-chip class="select-all-checkbox" color="tertiary" *ngIf="bulkSales?.length">
              <ion-item>
                <ion-checkbox
                  slot="start"
                  [checked]="selectedBulkSales.length === viewFilteredList.length"
                  (ionChange)="selectAllSaleCheckboxes($event)"
                  (click)="selectAllSaleCheckboxClick()"
                ></ion-checkbox>
                <ion-label>Select All</ion-label>
              </ion-item>
            </ion-chip>

            <ion-chip color="tertiary">
              <ion-label>Total Count : </ion-label>
              <ion-label>&nbsp;&nbsp; </ion-label>
              <ion-label>{{viewFilteredList.length}}</ion-label>
            </ion-chip>

            <ion-chip color="tertiary">
              <ion-label>Selected Sale Count : </ion-label>
              <ion-label>&nbsp;&nbsp; </ion-label>
              <ion-label>{{selectedBulkSales?.length}}</ion-label>
            </ion-chip>

          </ion-col>

        </ion-row>

        <ion-row class="floating-list">
          <ion-col size="12">
            <ion-item lines="none" *ngFor="let sale of bulkSales">
              <ion-checkbox
                slot="start"
                #bulkSalesCheckedEle
                (ionChange)="addToSelectedBulkSaleArr(sale,$event)"
                [checked]="sale['checked']"
                ></ion-checkbox>
                <ion-label>
                  <ion-chip color="primary">{{ sale.billNo }} | {{ sale.billDateStamp | date: 'dd/MM/yy' }}</ion-chip>
                  <ion-chip color="primary">{{ sale?.party?.name }}</ion-chip>
                  <ion-chip color="primary">{{ sale?.totalAmount | currency: 'INR' }}</ion-chip>
                </ion-label>
            </ion-item>
            <ion-infinite-scroll threshold="10px" *ngIf="bulkSales?.length" (ionInfinite)="loadMoreListData($event)">
              <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Loading more data...">
              </ion-infinite-scroll-content>
            </ion-infinite-scroll>
          </ion-col>
        </ion-row>

      </ion-grid>
    </ion-content>

    <ion-toolbar>
      <ion-button
        class="btn-save"
        expand="full"
        color="danger"
        (click)="deleteBulkSale()"
      >
        <span *ngIf="!bulkSaleDeleteStarted">
          Delete
        </span>
        <span *ngIf="bulkSaleDeleteStarted && !bulkSaleDeleteCompleted">
          <ion-spinner name="lines-sharp-small"></ion-spinner>
        </span>
        <span *ngIf="bulkSaleDeleteCompleted">
          Deleted
        </span>
      </ion-button>
    </ion-toolbar>
  </ng-template>
</ion-modal>
<!-- -------------------------------------------------------------------------------------- -->

<!-- Sale -->

<ion-modal class="modal-sale" [isOpen]="isSaleModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar color="light">
        <ion-title>Delete <ion-chip color="tertiary"> {{ sale?.billNo }} </ion-chip> </ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="openSaleModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">

      <ion-grid>

        <ion-row>
          <ion-col class="txt-center" size="12">
            <ion-chip color="tertiary" *ngIf="sale?.moneyIns?.length">
              <span>
                <ion-checkbox
                  slot="start"
                  [disabled]="true"
                  [checked]="isDeleteSaleAttachedMoneyIns"
                  (ionChange)="setCheckboxVarValue('isDeleteSaleAttachedMoneyIns',$event)"
                ></ion-checkbox>
              </span>
              <ion-label>Also Delete Attached Money In's</ion-label>
            </ion-chip>
            <ion-chip color="tertiary" *ngIf="!sale?.moneyIns?.length">
              <ion-label>There is no Money In is Attached with this Sale</ion-label>
            </ion-chip>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col class="txt-center" size="12">
            <ion-button fill="outline" class="dashed-border txt-transform-normal">
              Sale Delete
              <ion-icon *ngIf="sale?.deletedStamp" color="success" slot="end" name="checkmark-done"></ion-icon>
            </ion-button>
          </ion-col>
        </ion-row>

        <ion-row *ngIf="isDeleteSaleAttachedMoneyIns">
          <ion-col class="txt-center" size="12">
            <ion-button fill="outline" class="dashed-border txt-transform-normal">
              All Money In's Delete
            </ion-button>
            <ion-chip color="tertiary" *ngFor="let moneyIn of sale?.moneyIns">
              {{ moneyIn?.billNo }}
              <span>
                <ion-icon *ngIf="sale?.deletedStamp" color="success" slot="end" name="checkmark-done"></ion-icon>
              </span>
            </ion-chip>
          </ion-col>
        </ion-row>

      </ion-grid>

    </ion-content>

    <ion-toolbar>
      <ion-button
        class="btn-save"
        expand="full"
        color="danger"
        (click)="deleteSale()"
      >
        <span *ngIf="!saleDeleteStarted">
          Delete
        </span>
        <span *ngIf="saleDeleteStarted && !saleDeleteCompleted">
          <ion-spinner name="lines-sharp-small"></ion-spinner>
        </span>
        <span *ngIf="saleDeleteCompleted">
          Deleted
        </span>
      </ion-button>
    </ion-toolbar>
  </ng-template>
</ion-modal>

<!-- -------------------------------------------------------------------------------------- -->
