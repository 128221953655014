import { BillItem } from '../models/BillItem.model';
import { BillType } from './enums/BillType';
import { BillingType } from './enums/BillingType';
import { CalculateBillItemTask } from './enums/CalculateBillItemTask';
import { SentryUtilites } from './sentryUtilites';
import { Utility } from './utility';
import { Utils } from './utils';

export class BillCalculations {

  static calculateBillItem(
    billItem: BillItem,
    billType: BillType,
    billingType: BillingType,
    masterBillCashDiscountPercentage: number,
    task: CalculateBillItemTask = CalculateBillItemTask.CALCULATE
  ): BillItem {
    try {
      if(Utility.isTruthy(billItem)) {
        billItem.effectiveTaxPercentage = this.calculateEffectiveTaxPercentage(billItem)
        billItem.effectiveQuantity = this.calculateEffectiveQuantity(billItem)
        billItem.effectivePrice = this.calculateEffectivePrice(billItem)
        billItem.effectiveMrp = this.calculateEffectiveMrp(billItem)
        if (task == CalculateBillItemTask.DISCOUNT_FROM_AMOUNT) {
          billItem.discountPercentage = this.calculateDiscountPercentageFromAmount(billItem)
        }
        billItem.discount = this.calculateDiscount(billItem)
        if ((billItem.discountPercentage || 0.0) > 100.0) {
          billItem.discountPercentage = 0.0
          billItem.discount = 0.0
        }
        billItem.billCashDiscountPercentage = this.calculateBillCashDiscountPercentageForItem(billItem,masterBillCashDiscountPercentage)
        billItem.effectiveDiscountPercentage = this.calculateEffectiveDiscountPercentage(billItem)
        if ((billItem.effectiveDiscountPercentage || 0.0) > 100.0) {
          billItem.effectiveDiscountPercentage = 0.0
        }
        billItem.basePrice = this.calculateBasePrice(billItem)
        billItem.unitDiscountAmount = this.calculateEffectiveDiscount(billItem)
        billItem.unitGstAmount = this.calculateUnitGstAmount(billItem)
        billItem.unitCessAmount = this.calculateUnitCessAmount(billItem)
        billItem.unitTaxAmount = this.calculateUnitTaxAmount(billItem)
        billItem.itemTotalGstAmount = this.calculateItemTotalGstAmount(billItem)
        billItem.itemTotalCessAmount = this.calculateItemTotalCessAmount(billItem)
        billItem.itemTotalTaxAmount = this.calculateItemTotalTaxAmount(billItem)
        if (task == CalculateBillItemTask.QUANTITY_FROM_TOTAL) {
          billItem.quantity = this.calculateReversQuantity(billItem)
        }
        billItem.subTotal = this.calculateSubTotal(billItem)
        billItem.total = this.calculateTotal(billItem)
        billItem.totalSaving = this.calculateTotalSaving(billItem)
        billItem.wcdBasePrice = this.calculateWcdBasePrice(billItem)
        billItem.wcdUnitTaxAmount = this.calculateWcdUnitTaxAmount(billItem)
        billItem.wcdTotal = this.calculateWcdTotal(billItem)
    
        return billItem
      } else {
        return null;
      }
    } catch (error) {
      SentryUtilites.setLog("BillCalculations:calculateBillItem", Error)
      return null;
    }
  }

  private static calculateEffectiveTaxPercentage(billItem: BillItem): number {
    return (billItem?.taxPercentage || 0.0) + (billItem?.cessPercentage || 0.0);
  }

  private static calculateEffectiveQuantity(billItem: BillItem): number {
    const number = (billItem?.quantity || 1.0) / (billItem?.convertRatioMultiplier || 1.0);
    return Utils.capFractionsToSix(number);
  }

  private static calculatePrice(billItem: BillItem): number {
    const number = (billItem?.price || 0.0) * (1 / (billItem?.convertRatioMultiplier || 1.0));
    return Utils.capFractionsToSix(number);
  }

  private static calculateEffectivePrice(billItem: BillItem): number {
    try {
      if(billItem?.incTax == true) {
        return billItem?.price || 0.0;
      }else {
        return (billItem?.price || 0.0) * (1 + ((billItem?.effectiveTaxPercentage || 0.0) / 100));
      }
    } catch (error) {
      SentryUtilites.setLog("BillCalculations:calculateEffectivePrice", Error)
      return 0; 
    }
  }

  private static calculateEffectiveMrp(billItem: BillItem): number {
    try {
      let number = 0.0;
      if(billItem?.convertRatioMultiplier == 1.0) {
        if((billItem?.item?.mrp || 0.0) > (billItem?.effectivePrice || 0.0)) {
          number = billItem?.item?.mrp || 0.0;
        }else {
          number = billItem?.effectivePrice || 0.0;
        }
      }else {
        if(((billItem?.item?.mrp || 0.0) * (1 / (billItem?.convertRatioMultiplier || 1.0))) > (billItem?.effectivePrice || 0.0)) {
          number = (billItem?.item?.mrp || 0.0) * (1 / (billItem?.convertRatioMultiplier || 1.0));
        }else {
          number = billItem?.effectivePrice || 0.0;
        }
      }
      return Utils.capFractionsToSix(number);
    } catch (error) {
      SentryUtilites.setLog("BillCalculations:calculateEffectiveMrp", Error)
      return 0;
    }
  }

  private static calculateDiscountPercentageFromAmount(billItem: BillItem): number {
    const number = ((billItem?.discount || 0.0) * 100) / (billItem?.price || 0.0);
    return Utils.capFractionsToSix(number);
  }

  private static calculateDiscount(billItem: BillItem): number {
    const number = ((billItem?.discountPercentage || 0.0) * (billItem?.price || 0.0)) / 100;
    return Utils.capFractionsToSix(number);
  }

  private static calculateBillCashDiscountPercentageForItem(billItem: BillItem, masterBillCashDiscountPercentage: number): number {
    const number = ((100 - (billItem?.discountPercentage || 0.0)) * (masterBillCashDiscountPercentage)) / 100;
    return Utils.capFractionsToSix(number);
}

  private static calculateEffectiveDiscountPercentage(billItem: BillItem): number {
    return (billItem?.discountPercentage || 0.0) + (billItem?.billCashDiscountPercentage || 0.0);
  }

  private static calculateBasePrice(billItem: BillItem): number {
    try {
      let number = 0.0;
      if(billItem?.incTax == true) {
        number = (((billItem?.price || 0.0) * ((100 - (billItem?.effectiveDiscountPercentage || 0.0)) / 100)) / (100 + (billItem?.effectiveTaxPercentage || 0.0))) * 100;
      } else {
        number = ((billItem?.price || 0.0) * ((100 - (billItem?.effectiveDiscountPercentage || 0.0)) / 100));
      }
      return Utils.capFractionsToSix(number);
    } catch (error) {
      SentryUtilites.setLog("BillCalculations:calculateBasePrice", Error)
      return 0;
    }
  }

  private static calculateEffectiveDiscount(billItem: BillItem): number {
    try {
      let number = 0.0;
      if((billItem?.effectiveDiscountPercentage || 0.0) < 100.0) {
        number = ((billItem?.basePrice || 0.0) * (billItem?.effectiveDiscountPercentage || 0.0)) / (100 - (billItem?.effectiveDiscountPercentage || 0.0));
      }else {
        number = 0.0;
      }
      return Utils.capFractionsToSix(number);
    } catch (error) {
      SentryUtilites.setLog("BillCalculations:calculateEffectiveDiscount", Error)
      return 0;
    }
  }

  private static calculateUnitGstAmount(billItem: BillItem): number {
    const number = (billItem?.basePrice || 0.0) * ((billItem?.taxPercentage || 0.0) / 100);
    return Utils.capFractionsToSix(number);
  }

  private static calculateUnitCessAmount(billItem: BillItem): number {
    const number = (billItem?.basePrice || 0.0) * ((billItem?.cessPercentage || 0.0) / 100);
    return Utils.capFractionsToSix(number);
  }

  private static calculateUnitTaxAmount(billItem: BillItem): number {
    const number = (billItem?.basePrice || 0.0) * ((billItem?.effectiveTaxPercentage || 0.0) / 100);
    return Utils.capFractionsToSix(number);
  }

  private static calculateItemTotalGstAmount(billItem: BillItem): number {
    const number = (billItem?.unitGstAmount || 0.0) * (billItem?.quantity || 0.0);
    return Utils.capFractionsToSix(number);
  }

  private static calculateItemTotalCessAmount(billItem: BillItem): number {
    const number = (billItem?.unitCessAmount || 0.0) * (billItem?.quantity || 0.0);
    return Utils.capFractionsToSix(number);
  }

  private static calculateItemTotalTaxAmount(billItem: BillItem): number {
    const number = (billItem?.unitTaxAmount || 0.0) * (billItem?.quantity || 0.0);
    return Utils.capFractionsToSix(number);
  }

  private static calculateReversQuantity(billItem: BillItem): number {
    const number = (billItem?.total || 0.0) / Utils.capFractionsToSix((billItem?.basePrice || 0.0) + (billItem?.unitTaxAmount || 0.0));
    return Utils.capFractionsToSix(number);
  }

  private static calculateSubTotal(billItem: BillItem): number {
    try {
      let number = 0.0;
      if(billItem?.incTax == true) {
        number = ((billItem?.price || 0.0) / (100 + (billItem?.effectiveTaxPercentage || 0.0))) * 100;
      }else {
        number = billItem?.price || 0.0;
      }
      number = Utils.capFractionsToSix(number);
      return number * (billItem?.quantity || 0.0);
    } catch (error) {
      SentryUtilites.setLog("BillCalculations:calculateSubTotal", Error)
      return 0;
    }
  }

  private static calculateTotal(billItem: BillItem): number {
    const number = ((billItem?.basePrice || 0.0) + (billItem?.unitTaxAmount || 0.0)) * (billItem?.quantity || 0.0);
    return Utils.capFractionsToSix(number);
  }

  private static calculateTotalSaving(billItem: BillItem): number {
    return ((billItem?.effectiveMrp || 0.0) * (billItem?.quantity || 0.0)) - (billItem?.total || 0.0);
  }

  private static calculateWcdBasePrice(billItem: BillItem): number {
    try {
      let number = 0.0;
      if(billItem?.incTax == true) {
        number = (((billItem?.price || 0.0) * ((100 - (billItem?.discountPercentage || 0.0)) / 100)) / (100 + (billItem?.effectiveTaxPercentage || 0.0))) * 100;
      }else {
        number = ((billItem?.price || 0.0) * ((100 - (billItem?.discountPercentage || 0.0)) / 100));
      }
      return Utils.capFractionsToSix(number);
    } catch (error) {
      SentryUtilites.setLog("BillCalculations:calculateWcdBasePrice", Error)
      return 0;
    }
  }

  private static calculateWcdUnitTaxAmount(billItem: BillItem): number {
    const number = (billItem?.wcdBasePrice || 0.0) * ((billItem?.effectiveTaxPercentage || 0.0) / 100);
    return Utils.capFractionsToSix(number);
  }

  private static calculateWcdTotal(billItem: BillItem): number {
    return ((billItem?.wcdBasePrice || 0.0) + (billItem?.wcdUnitTaxAmount || 0.0)) * (billItem?.quantity || 0.0);
  }

}
