import { AccessControlService } from './../../services/auth/access-control.service';
import { ItemStockAdjustComponent } from './../item-stock-adjust/item-stock-adjust.component';
import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { InfiniteScrollCustomEvent, IonFab, ToastController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { IListView } from '../../../interface/IListView.interface';
import { Item } from '../../models/Item.model';
import { AllDataService } from '../../services/all-data.service';
import { Utility } from '../../utils/utility';
import { Image } from '../../models/image.model';
import { PinVerificationComponent } from '../pin-verification/pin-verification.component';
import { MonthWiseItemStockService } from '../../services/month-wise-item-stock.service';
import { SearchFilterService } from '../../services/search-filter.service';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';
import ItemCategory from '../../models/ItemCategory.model';

@Component({
  selector: 'app-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.component.scss'],
})
export class ItemListComponent implements OnInit, IListView<Item> {

  @Input() searchText = '';
  @Input() barcodeScannerMode: boolean = false;
  @Input() isPurchase: boolean = false;
  @Input() isSalePurchasePrice: boolean = false;

  @Output() itemSelectedEvent = new EventEmitter<Item>();

  @ViewChild('searchBar') searchBar;
  @ViewChild('itemStockAdjust') itemStockAdjust: ItemStockAdjustComponent;
  @ViewChild('filterByFab') filterByFab: IonFab;
  @ViewChild('sortByFab') sortByFab: IonFab;
  @ViewChild('pinVerificationElement') pinVerificationElement: PinVerificationComponent;

  filteredList: Item[] = [];
  completeList: Item[] = [];
  viewFilteredList: Item[] = [];

  appliedCategory = '';
  selectedFilter = '';


  isFilterOptionOpen;
  innerWidth: number;

  categories: string[] = [];
  categoryWiseItemCount = {};

  subsArr: Subscription[] = [];

  isOpenImageCropper = false;

  imageUploadItemUUID: string = null;

  isAbbrivationSearchEnabled: boolean = false;
  isStartsWithSearchPrioritized: boolean = false;

  loadViewTimeStamp: number = 0;

  fabListHeight: string = '';

  shortStr = Utility.shortStr;

  returnZero = () => { return 0 };

  sortObj = {
    "SortByNameAsc": "A to Z",
    "SortByNameDesc": "Z to A",
    "SortByStockAsc": "Stock Ascending",
    "SortByStockDesc": "Stock Descending",
    "lowStockByAsc": "Low Stock By Ascending",
    "SortByFavAsc": 'Favourite Items by Ascending',
  };

  constructor(
    private allDataService: AllDataService,
    private toastController: ToastController,
    private router: Router,
    private accessControlService: AccessControlService,
    private monthWiseItemStockService: MonthWiseItemStockService,
    private searchFilterService: SearchFilterService,
  ) { }

  ngOnInit() {
    try {
      this.fabListHeight = `${Math.floor((window?.innerHeight - 105) * 0.85)}px`;
      this.routerSubscription();
      this.reduceFunctionCall();
      this.subsArr.push(this.allDataService.listForceReloadSubs
        .subscribe(async (listName: string) => {
          if (listName == 'item-list' || listName == 'item-stock-adjust-list' || listName == 'profile-list') {
            this.allDataService.itemService.reloadList();
            this.reduceFunctionCall();
          }
        }))
  
      this.subsArr.push(this.allDataService.itemService.updateSubs
        .subscribe(async (item: Item) => {
          if (item) {
            item.stock = await this.monthWiseItemStockService.getLedgerStock(item?._localUUID);
            let completeListIndex = this.completeList.findIndex(el => el?._localUUID === item?._localUUID);
            if(completeListIndex != -1 && item?.deletedStamp) {
              this.completeList.splice(completeListIndex, 1);
            } else if(completeListIndex != -1) {
              this.completeList[completeListIndex] = item;
            }
            let filteredListIndex = this.filteredList.findIndex(el => el?._localUUID === item?._localUUID);
            if(filteredListIndex != -1 && item?.deletedStamp) {
              this.filteredList.splice(filteredListIndex, 1);
            } else if(filteredListIndex != -1) {
              this.filteredList[filteredListIndex] = item;
            }
            let viewFilteredListIndex = this.viewFilteredList.findIndex(el => el?._localUUID === item?._localUUID);
            if(viewFilteredListIndex != -1 && item?.deletedStamp) {
              this.viewFilteredList.splice(viewFilteredListIndex, 1);
            } else if(viewFilteredListIndex != -1) {
              this.viewFilteredList[viewFilteredListIndex] = item;
            }
          }
        }))
    } catch (error) {
      SentryUtilites.setLog("ItemListComponent:ngOnInit", error)
    }
  }

  ngAfterViewInit() {
    try {
      setTimeout(() => {
        this.searchBar.setFocus();
      }, 500);
      this.innerWidth = window?.innerWidth;
      this.checkScreenWidth(window?.innerWidth);
    } catch (error) {
      SentryUtilites.setLog("ItemListComponent:ngAfterViewInit", error)
    }
  }

  @HostListener('window:resize', ['$event'])
  OnResize() {
    try {
      this.innerWidth = window?.innerWidth;
      this.checkScreenWidth(window?.innerWidth);
    } catch (error) {
      SentryUtilites.setLog("ItemListComponent:OnResize", error)
    }
  }

  checkScreenWidth(width: number) {
    try {
      if(width <= 1080) {
        this.isFilterOptionOpen = false;
      } else {
        this.isFilterOptionOpen = true;
      }
    } catch (error) {
      SentryUtilites.setLog("ItemListComponent:checkScreenWidth", error)
    }
  }

  routerSubscription() {
    try {
      this.subsArr.push(this.router.events.subscribe((event: RouterEvent) => {
        if(event instanceof NavigationEnd) {
          event?.url==='/item' && this.reduceFunctionCall();
        }
      }));
    } catch (error) {
      SentryUtilites.setLog("ItemListComponent:routerSubscription", error)
    }
  }

  async getCategories() {
    try {
      let itemCategories=await this.allDataService.itemCategoryService.getAllByPromise() || [];
      itemCategories.sort((a: ItemCategory, b: ItemCategory) => {
        if(a?.savedPosition || b?.savedPosition) {
          return a?.savedPosition - b?.savedPosition;
        }
        if (a?.name?.toLowerCase() === b?.name?.toLowerCase()) {
          return a?.createdStamp - b?.createdStamp;
        }
        if (a?.name?.toLowerCase() > b?.name?.toLowerCase()) {
          return 1;
        }
        return -1;
      });
      if (itemCategories?.length) {
        this.categories = itemCategories.map(x => x.name);
        if(this.completeList?.length) {
          this.categoryWiseItemCount = {};
          this.categories?.forEach(category => {
            if(category) {
              this.categoryWiseItemCount['All'] = this.completeList?.length
              this.categoryWiseItemCount[category] = this.completeList?.filter(item => item?.category ? item?.category?.toLowerCase()==category?.toLowerCase() : false)?.length;
            }
          });
        }
      }
    } catch (error) {
      SentryUtilites.setLog("ItemListComponent:getCategories", error)
    }
  }

  async loadView() {
    try {
      // this.filteredList = [];
      // this.completeList = [];
      // this.viewFilteredList = [];
  
      // this.appliedCategory = '';
      this.selectedFilter = 'SortByFavAsc';
  
      // this.categories = [];
  
      let completeListHashMap = {};
  
      let completeListLength = this.completeList?.length;
      for(let i = 0; i < completeListLength; i++) {
        let key = JSON.stringify(this.completeList[i]);
        completeListHashMap[key] = true;
      }
  
      let newCompleteList = await this.allDataService.itemService.getAllByPromise() || [];
  
      newCompleteList.sort((a: Item, b: Item) => { 
        if (a?.itemName?.toLowerCase() == b?.itemName?.toLowerCase()) {
          return a?.createdStamp - b?.createdStamp;
        } 
        if (a?.itemName?.toLowerCase() > b?.itemName?.toLowerCase()) {
          return 1;
        } 
        return -1;
      })
      
      const ledgerStockHashMap = await this.monthWiseItemStockService.getLedgerStockHashMap();
  
      let differentObjects = [];
  
      // storing stock and find out differentObjects from old completeList
      let newCompleteListLength = newCompleteList?.length;
      for(let i = 0; i < newCompleteListLength; i++) {
        let newCompleteObj = newCompleteList[i];
        newCompleteList[i].stock = ledgerStockHashMap[newCompleteObj?._localUUID] || 0;
  
        if(this.completeList.length) {
          let key = JSON.stringify(newCompleteObj);
          if(!completeListHashMap[key]) {
            differentObjects.push(newCompleteObj);
          }
        }
      }
  
      if(
        newCompleteList?.length != this.completeList?.length ||
        JSON.stringify(newCompleteList) != JSON.stringify(this.completeList)
        ) {
      
          if(this.completeList.length) {
            let differentObjectsLength = differentObjects?.length;
            if(differentObjectsLength) {
              for(let i=0; i < differentObjectsLength; i++) {
                let differentObject = differentObjects[i];
                let index = this.completeList.findIndex(el => el?._localUUID === differentObject?._localUUID);
                if(index != -1) {
                  this.completeList[index] = differentObject;
                } else {
                  this.completeList.push(differentObject);
                }
              }
            }
          } else {
            this.completeList = newCompleteList;
          }
      
          this.onFilterChange(this.lastSearchKey);
          this.getCategories();
        }
    } catch (error) {
      SentryUtilites.setLog("ItemListComponent:loadView", error)
    }

  }

  reduceFunctionCall() {
    try {
      if(+new Date() > this.loadViewTimeStamp) {
        this.loadViewTimeStamp = +new Date() + 5000;
        this.loadView();
        this.loadCurrentProfile();
      }
    } catch (error) {
      SentryUtilites.setLog("ItemListComponent:reduceFunctionCall", error)
    }
  }

  async loadCurrentProfile() {
    try {
      let profile = await this.allDataService.profileService.getCurrentProfile();
      this.isAbbrivationSearchEnabled = profile?.isAbbrivationSearchEnabled;
      this.isStartsWithSearchPrioritized = profile?.isStartsWithSearchPrioritized === false ? false : true;
    } catch (error) {
      SentryUtilites.setLog("ItemListComponent:loadCurrentProfile", error)
    }
  }

  toggleFilterOptions() {
    this.isFilterOptionOpen = !this.isFilterOptionOpen;
  }

  openTransactionPINModal() {
    this.pinVerificationElement?.openTransactionPINModal();
  }

  async openStockAdjustModal(item: Item) {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({action:'adjustStock'});
      if(!isPermit) {
        return alert("Permission: You don't have permission to delete adjust stock. Please contact to your owner.");
      }
      if (item?._localUUID) {
        this.itemStockAdjust?.setItem(item);
        this.openTransactionPINModal();
      }
    } catch (error) {
      SentryUtilites.setLog("ItemListComponent:openStockAdjustModal", error)
    }
  }

  verifyTransactionPIN(event) {
    try {
      if(event) {
        this.itemStockAdjust?.openStockAdjustModal();
      }
    } catch (error) {
      SentryUtilites.setLog("ItemListComponent:verifyTransactionPIN", error)
    }
  }


  onCategoryChange(category: string) {
    this.appliedCategory = category;
    this.onFilterChange(this.lastSearchKey);
  }

  onSortChnage(selectedFilter: string) {
    this.selectedFilter = selectedFilter;
    this.onFilterChange(this.lastSearchKey);
  }

  closeclickOutSideSort() {
    this.sortByFab.close()
  }

  closeclickOutSideFilter() {
    this.filterByFab.close()
    let scrollCategoryCintainer = document.getElementById('scroll-category-container');
    if(scrollCategoryCintainer) {
      scrollCategoryCintainer.scrollTop = 0;
    }
  }

  closeFilter() {
    this.filterByFab.close()
  }

  closeSort() {
    this.sortByFab.close()
  }

  isFilterPostpond = false;
  lastFilterStamp = 0;
  onFilterChange(searchKey?: string) {
    try {
      if (this.isFilterPostpond) {
        return true;
      }
      if (this.lastFilterStamp < +new Date() - 200) {
        this.lastFilterStamp = +new Date();
        this.applyFilterV2(searchKey);
        // return this.applyFilter({
        //   searchKeyword: searchKey,
        //   selectedFilter: this.selectedFilter,
        //   category: this.appliedCategory
        // })
      } else {
        this.isFilterPostpond = true;
        setTimeout(() => {
          this.isFilterPostpond = false;
          this.onFilterChange(searchKey);
        }, 250);
      }
    } catch (error) {
      SentryUtilites.setLog("ItemListComponent:onFilterChange", error)
    }
  }

  lastSearchKey = '';
  async applyFilter(params: {
    searchKeyword?: string,
    selectedFilter?: string,
    category?: string
  }) {
    try {
      params.searchKeyword = params.searchKeyword?.trim();
      let arr: Item[] = [];
      let lsearchKey = '';
      let isSearchIncremental = false;
      let isSearchCleared = false;
      if (params?.searchKeyword?.length) {
        lsearchKey = Utility.toLowerCase(params.searchKeyword);
        if (lsearchKey.indexOf(this.lastSearchKey) == 0) {
          // isSearchIncremental = true;
          // TODO
          isSearchIncremental = false;
        }
        this.lastSearchKey = lsearchKey;
      }
      if (this.lastSearchKey.length > 0 && lsearchKey.length == 0) {
        isSearchCleared = true;
        this.lastSearchKey = '';
      }
      if(!this.filteredList?.length && isSearchIncremental) {
        this.filteredList = [...this.completeList];
      }
      let completeList = [];
      if (isSearchIncremental) {
        completeList = [...this.filteredList]
      } else {
        completeList = [...this.completeList];
      }
  
      if (params?.category) {
        completeList?.forEach((el) => {
          if (el.category == params.category) {
            arr.push(el);
          }
        });
        arr = arr.filter((el) => {
          if (
            Utility.toLowerCase(el?.itemName).indexOf(lsearchKey) != -1
            || Utility.toLowerCase(el?.barcode) === lsearchKey
            || Utility.toLowerCase(el?.barcode2) === lsearchKey
            || Utility.toLowerCase(el?.barcode3) === lsearchKey
            || Utility.toLowerCase(el?.barcode4) === lsearchKey
            || Utility.toLowerCase(el?.barcode5) === lsearchKey
            || Utility.toLowerCase(el?.itemCode) === lsearchKey
            || Utility.toLowerCase(el?.itemName).includes(lsearchKey)
            || Utility.toLowerCase(el?.barcode).includes(lsearchKey)
            || Utility.toLowerCase(el?.barcode2).includes(lsearchKey)
            || Utility.toLowerCase(el?.barcode3).includes(lsearchKey)
            || Utility.toLowerCase(el?.barcode4).includes(lsearchKey)
            || Utility.toLowerCase(el?.barcode5).includes(lsearchKey)
            || Utility.toLowerCase(el?.itemCode).includes(lsearchKey)
          ) {
            return el;
          }
        });
      } else if (params?.category) {
        completeList?.forEach((el) => {
          if (el.category == params.category) {
            arr.push(el);
          }
        });
      } else if (lsearchKey.length) {
        completeList?.forEach((el) => {
          // if(lsearchKey.length<4){
          //   if(Utility.toLowerCase(el.itemName).indexOf(lsearchKey)==0){
          //     arr.push(el);
          //   }
          // }else{
          if (
            Utility.toLowerCase(el?.itemName).indexOf(lsearchKey) != -1
            || Utility.toLowerCase(el?.barcode) === lsearchKey
            || Utility.toLowerCase(el?.barcode2) === lsearchKey
            || Utility.toLowerCase(el?.barcode3) === lsearchKey
            || Utility.toLowerCase(el?.barcode4) === lsearchKey
            || Utility.toLowerCase(el?.barcode5) === lsearchKey
            || Utility.toLowerCase(el?.itemCode) === lsearchKey
            || Utility.toLowerCase(el?.itemName).includes(lsearchKey)
            || Utility.toLowerCase(el?.barcode).includes(lsearchKey)
            || Utility.toLowerCase(el?.barcode2).includes(lsearchKey)
            || Utility.toLowerCase(el?.barcode3).includes(lsearchKey)
            || Utility.toLowerCase(el?.barcode4).includes(lsearchKey)
            || Utility.toLowerCase(el?.barcode5).includes(lsearchKey)
            || Utility.toLowerCase(el?.itemCode).includes(lsearchKey)
          ) {
            arr.push(el);
          }
          //}
        });
      } else {
        arr = [...completeList]
      }
  
  
      if (params.selectedFilter == 'SortByNameAsc') {
        arr.sort((a: Item, b: Item) => { 
          if (a?.itemName?.toLowerCase() == b?.itemName?.toLowerCase()) {
            return a?.createdStamp - b?.createdStamp;
          } 
          if (a?.itemName?.toLowerCase() > b?.itemName?.toLowerCase()) {
            return 1;
          } 
          return -1;
        })
      } else if (params.selectedFilter == 'SortByNameDesc') {
        arr.sort((a: Item, b: Item) => {
          if (a?.itemName?.toLowerCase() == b?.itemName?.toLowerCase()) {
            return a?.createdStamp - b?.createdStamp;
          }
          if (b?.itemName?.toLowerCase() > a?.itemName?.toLowerCase()) {
            return 1;
          }
          return -1;
        })
      } else if (params.selectedFilter == 'SortByStockAsc') {
        arr.sort((a: Item, b: Item) => a?.stock-b?.stock);
      } else if (params.selectedFilter == 'SortByStockDesc') {
        arr.sort((a: Item, b: Item) => b?.stock-a?.stock);
      } else if (params.selectedFilter == 'lowStockByAsc') {
        arr = arr.filter(item => {
          const stock = Number(item?.stock);
          let minStock = Number(item?.minStock);
          minStock = minStock || 0;
          if (
            Utility.isNumber(stock)
            && Utility.isNumber(minStock)
            && stock <= minStock
          ) {
            return true;
          }else {
            return false;
          }
        });
        arr.sort((a: Item, b: Item) => {
          if (a?.itemName?.toLowerCase() == b?.itemName?.toLowerCase()) {
            return a?.createdStamp - b?.createdStamp;
          }
          if (b?.itemName?.toLowerCase() > a?.itemName?.toLowerCase()) {
            return 1;
          }
          return -1;
        })
      }else if (params.selectedFilter == 'SortByFavAsc') {
        arr.sort((a: Item, b: Item) => {
          if (a?.itemName?.toLowerCase() == b?.itemName?.toLowerCase()) {
            return a?.createdStamp - b?.createdStamp;
          }
          if (a?.itemName?.toLowerCase() > b?.itemName?.toLowerCase()) {
            return 1;
          }
          return -1;
        });
        let favItems: Item[] = [];
        let nonFavItems: Item[] = [];
        arr.filter((party) => {
          if (party?.isFavourite) {
            favItems.push(party);
          } else {
            nonFavItems.push(party);
          }
        });
        arr = favItems.concat(nonFavItems);
      }
  
      let sameItemName: Item[] = [];
        let nonSameItemName: Item[] = [];
        arr.filter((item: Item) => {
          if (item?.itemName?.toLowerCase().trim() == lsearchKey) {
            sameItemName.push(item);
          } else {
            nonSameItemName.push(item);
          }
        });
        arr = sameItemName.concat(nonSameItemName);
  
      // Master Item at Top of the list
  
      let masterItemArr: Item[] = arr.filter(item => item['masterItem']);
      masterItemArr?.forEach(masterItem => {
        const index = arr.findIndex(item => item?._localUUID === masterItem?._localUUID);
        if(index != -1) {
          arr.splice(index,1);
        }
      });
      masterItemArr.sort((a:Item,b:Item) =>  b?.createdStamp - a?.createdStamp);
      arr = [...masterItemArr,...arr];
  
      // --------------------------------
  
      this.filteredList = [...arr];
  
      // if(lsearchKey) {
      //   // let priorityArr: Item[] = [];
      //   // this.filteredList?.forEach((x,i) => {
      //   //   if(x.itemName?.startsWith(lsearchKey)) {
      //   //     priorityArr.push(x);
      //   //     this.filteredList.splice(i,1);
      //   //   }
      //   // });
      //   this.filteredList  = [...this.filteredList];
      // }
      if (lsearchKey.length || params.category || params.selectedFilter || isSearchCleared) {
        this.viewFilteredList = [...this.filteredList.slice(0, 50)];
      } else if (!this.viewFilteredList.length) {
        this.viewFilteredList = [...this.filteredList.slice(0, 50)];
      }
  
      if(!this.viewFilteredList?.length) {
        if(lsearchKey) {
          let index = this.completeList?.findIndex(item => item?.barcode==lsearchKey);
          if(index == -1){
            index = this.completeList?.findIndex(item => item?.barcode2==lsearchKey);
          }
          if(index == -1){
            index = this.completeList?.findIndex(item => item?.barcode3==lsearchKey);
          }
          if(index == -1){
            index = this.completeList?.findIndex(item => item?.barcode4==lsearchKey);
          }
          if(index == -1){
            index = this.completeList?.findIndex(item => item?.barcode5==lsearchKey);
          }
          if(index == -1) {
            let item = await this.allDataService.itemService.getMasterItem(lsearchKey);
            if(item?._localUUID) {
              item = {...item,['masterItem']:true} as any;
              this.completeList.unshift(item);
              if(this.searchBar?.el) {
                this.searchBar.el.value = '';
              }
              const toast = await this.toastController.create({
                header: `${item.itemName} added for barcode ${lsearchKey} from Global Database`,
                duration: 5000,
                position: 'top',
                mode: 'ios',
                color: 'success',
              });
              await toast.present();
            }
          }
        }
      }
  
      return true;
    } catch (error) {
      SentryUtilites.setLog("ItemListComponent:applyFilter", error)
      return false;
    }
  }

  /**
   * 
   * @param inpSearchStr : provide input search result
   */
  async applyFilterV2(inpSearchStr?: string) {
    try {
      inpSearchStr = this.lastSearchKey = inpSearchStr?.toLowerCase();
  
      let arr = [...this.completeList];
  
      if (this.appliedCategory) {
        arr = this.completeList.filter((el: Item) => {
          if (el.category == this.appliedCategory) {
            return el
          }
        });
      }
      
      let finalArray = [];
  
      if(inpSearchStr) {
        let abbrivationSearchResultArr = [];
        if(this.isAbbrivationSearchEnabled) {
          abbrivationSearchResultArr = this.searchFilterService.abbrivationSearch(inpSearchStr, arr);
        }
        let startsWithSearchResultArr = this.searchFilterService.startsWithSearch(inpSearchStr, arr);
        let containsInSearchResultArr = this.searchFilterService.containsInSearch(inpSearchStr, arr);
        let barcodeSearchArr = this.searchFilterService.barcodeSearch(inpSearchStr, arr);
        let itemCodeSearchArr = this.searchFilterService.itemCodeSearch(inpSearchStr, arr);
    
        let rawArray = []
    
        let hashMap = {};
    
        if(this.isAbbrivationSearchEnabled && this.isStartsWithSearchPrioritized) {
          rawArray = [
            ...abbrivationSearchResultArr,
            ...startsWithSearchResultArr,
            ...containsInSearchResultArr,
          ]
        } else if(this.isAbbrivationSearchEnabled && !this.isStartsWithSearchPrioritized) {
          rawArray = [
            ...abbrivationSearchResultArr,
            ...containsInSearchResultArr,
            ...startsWithSearchResultArr,
          ]
        } else if(!this.isAbbrivationSearchEnabled && this.isStartsWithSearchPrioritized) {
          rawArray = [
            ...startsWithSearchResultArr,
            ...containsInSearchResultArr,
          ]
        } else if(!this.isAbbrivationSearchEnabled && !this.isStartsWithSearchPrioritized) {
          rawArray = [
            ...containsInSearchResultArr,
            ...startsWithSearchResultArr,
          ]
        }
    
        rawArray = [
          ...rawArray,
          ...barcodeSearchArr,
          ...itemCodeSearchArr,
        ]
    
        // To remove duplicates item
        rawArray?.forEach((el:Item) => {
          hashMap[el?._localUUID] = el;
        })
    
        for(let key in hashMap) {
          finalArray.push(hashMap[key]);
        }
      } else {
        finalArray = arr;
      }
  
      finalArray = this.searchFilterService.sortByFavItem(finalArray);
  
      if(!inpSearchStr) {
        if (this.selectedFilter == 'SortByNameAsc') {
          finalArray.sort((a: Item, b: Item) => { 
            if (a?.itemName?.toLowerCase() == b?.itemName?.toLowerCase()) {
              return a?.createdStamp - b?.createdStamp;
            } 
            if (a?.itemName?.toLowerCase() > b?.itemName?.toLowerCase()) {
              return 1;
            } 
            return -1;
          })
        } else if (this.selectedFilter == 'SortByNameDesc') {
          finalArray.sort((a: Item, b: Item) => {
            if (a?.itemName?.toLowerCase() == b?.itemName?.toLowerCase()) {
              return a?.createdStamp - b?.createdStamp;
            }
            if (b?.itemName?.toLowerCase() > a?.itemName?.toLowerCase()) {
              return 1;
            }
            return -1;
          })
        } else if (this.selectedFilter == 'SortByStockAsc') {
          finalArray.sort((a: Item, b: Item) => a?.stock-b?.stock);
        } else if (this.selectedFilter == 'SortByStockDesc') {
          finalArray.sort((a: Item, b: Item) => b?.stock-a?.stock);
        } else if (this.selectedFilter == 'lowStockByAsc') {
          finalArray = finalArray.filter(item => {
            const stock = Number(item?.stock);
            let minStock = Number(item?.minStock);
            minStock = minStock || 0;
            if (
              Utility.isNumber(stock)
              && Utility.isNumber(minStock)
              && stock <= minStock
            ) {
              return true;
            }else {
              return false;
            }
          });
          finalArray.sort((a: Item, b: Item) => {
            if (a?.itemName?.toLowerCase() == b?.itemName?.toLowerCase()) {
              return a?.createdStamp - b?.createdStamp;
            }
            if (b?.itemName?.toLowerCase() > a?.itemName?.toLowerCase()) {
              return 1;
            }
            return -1;
          })
        }else if (this.selectedFilter == 'SortByFavAsc') {
          finalArray.sort((a: Item, b: Item) => {
            if (a?.itemName?.toLowerCase() == b?.itemName?.toLowerCase()) {
              return a.createdStamp - b.createdStamp;
            }
            if (a?.itemName?.toLowerCase() > b?.itemName?.toLowerCase()) {
              return 1;
            }
            return -1;
          });
          let favItems: Item[] = [];
          let nonFavItems: Item[] = [];
          finalArray.filter((party) => {
            if (party?.isFavourite) {
              favItems.push(party);
            } else {
              nonFavItems.push(party);
            }
          });
          finalArray = favItems.concat(nonFavItems);
        }
      }
  
      this.filteredList = finalArray;
  
      this.viewFilteredList = this.viewFilteredList?.length > 50 ? [...this.filteredList.slice(0, this.viewFilteredList?.length)] : [...this.filteredList.slice(0, 50)];
  
      if(!this.viewFilteredList?.length) {
        if(inpSearchStr) {
          let index = this.completeList?.findIndex(item => item?.barcode==inpSearchStr);
          if(index == -1){
            index = this.completeList?.findIndex(item => item?.barcode2==inpSearchStr);
          }
          if(index == -1){
            index = this.completeList?.findIndex(item => item?.barcode3==inpSearchStr);
          }
          if(index == -1){
            index = this.completeList?.findIndex(item => item?.barcode4==inpSearchStr);
          }
          if(index == -1){
            index = this.completeList?.findIndex(item => item?.barcode5==inpSearchStr);
          }
          if(index == -1) {
            let item = await this.allDataService.itemService.getMasterItem(inpSearchStr);
            if(item?._localUUID) {
              item = {...item,['masterItem']:true} as any;
              this.completeList.unshift(item);
              if(this.searchBar?.el) {
                this.searchBar.el.value = '';
              }
              const toast = await this.toastController.create({
                header: `${item.itemName} added for barcode ${inpSearchStr} from Global Database`,
                duration: 5000,
                position: 'top',
                mode: 'ios',
                color: 'success',
              });
              await toast.present();
            }
          }
        }
      }
    } catch (error) {
      SentryUtilites.setLog("ItemListComponent:applyFilterV2", error)
    }

  }
  // -------------------------------------------------------

  loadMoreListData(event) {
    try {
      if (this.viewFilteredList.length > 0 && this.viewFilteredList.length <= this.filteredList.length) {
        let appendListLength = this.filteredList.length - this.viewFilteredList.length;
        let lastEl = this.viewFilteredList[this.viewFilteredList.length - 1];
        let counter = 0;
        for (let i = 0; i < this.filteredList.length; i++) {
          if (this.filteredList[i]._localUUID == lastEl._localUUID) {
            counter = 1;
            continue;
          }
          if (counter > 0 && appendListLength >= 50) {
            if (counter <= 50) {
              this.viewFilteredList.push({ ...this.filteredList[i] })
            } else {
              break;
            }
            counter++;
          } else if(counter > 0 && appendListLength < 50) {
            if (counter <= appendListLength) {
              this.viewFilteredList.push({ ...this.filteredList[i] })
            } else {
              break;
            }
            counter++;
          }
        }
        (event as InfiniteScrollCustomEvent).target.complete();
      }
    } catch (error) {
      SentryUtilites.setLog("ItemListComponent:loadMoreListData", error)
    }
  }

  emitElement(item: Item) {
    try {
      this.completeList = [];
      this.itemSelectedEvent.emit(item);
    } catch (error) {
      SentryUtilites.setLog("ItemListComponent:emitElement", error)
    }
  }

  autoSearch(value: string) {
    try {
      if (value && this.searchBar?.el) {
        this.searchBar.el.value = value;
        setTimeout(() => {
          this.applyFilterV2(value);
          // this.applyFilter({
          //   searchKeyword: value
          // })
        }, 500) 
      }
    } catch (error) {
      SentryUtilites.setLog("ItemListComponent:autoSearch", error)
    }
  }

  resetCategory() {
    try {
      this.appliedCategory = '';
      this.toggleFilterOptions();
      this.onFilterChange();
    } catch (error) {
      SentryUtilites.setLog("ItemListComponent:resetCategory", error)
    }
  }

  resetSort() {
    try {
      this.selectedFilter = '';
      this.toggleFilterOptions();
      this.onFilterChange();
    } catch (error) {
      SentryUtilites.setLog("ItemListComponent:resetSort", error)
    }
  }

  ngOnDestroy() {
    try {
      this.subsArr?.forEach((el) => {
        el?.unsubscribe();
      })
    } catch (error) {
      SentryUtilites.setLog("ItemListComponent:ngOnDestroy", error)
    }
  }

  async onBarcodeSearchEnter(searchKey: string): Promise<Item> {
    try {
      // await this.applyFilter({searchKeyword:searchKey});
      searchKey = searchKey?.trim();
      if(searchKey) {
        this.applyFilterV2(searchKey);
        if(this.viewFilteredList?.length === 1) {
          return this.viewFilteredList[0];
        }
      }
      return null;
    } catch (error) {
      SentryUtilites.setLog("ItemListComponent:onBarcodeSearchEnter", error)
      return null;
    }
  }

  async addImage(uuid: string) {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({action:'editItem'});
      if(!isPermit) {
        return alert("Permission: You don't have permission to delete adjust stock. Please contact to your owner.");
      }
      if(uuid) {
        this.imageUploadItemUUID = uuid;
      }
      this.openImageCropper();
    } catch (error) {
      SentryUtilites.setLog("ItemListComponent:addImage", error)
    }
  }

  openImageCropper(value: boolean = true) {
    try {
      this.isOpenImageCropper = value;
      if(value == false) {
        this.imageUploadItemUUID = null;
      }
    } catch (error) {
      SentryUtilites.setLog("ItemListComponent:openImageCropper", error)
    }
  }

  async onImageSaved(image: Image) {
    try {
      image = await this.allDataService.imageService.getByUUID(image._localUUID);
      if(image?._localUUID) {
        let fetchedItem = await this.allDataService.itemService.getByUUID(this.imageUploadItemUUID);
        if(fetchedItem?._localUUID) {
          if(fetchedItem?.images?.length && Array.isArray(fetchedItem?.images)) {
            fetchedItem?.images?.push(image._localUUID);
          }else {
            fetchedItem.images = [image._localUUID]
          }
        }
        let updatedItem = await this.allDataService.itemService.update(fetchedItem);
        if(updatedItem?._localUUID) {
          const toast = await this.toastController.create({
            header: 'Image Added',
            duration: 2000,
            position: 'top',
            mode: 'ios',
            color: 'success',
          });
          await toast.present();
        }else {
          const toast = await this.toastController.create({
            header: 'Something went wrong',
            duration: 2000,
            position: 'top',
            mode: 'ios',
            color: 'danger',
          });
          await toast.present();
        }
      }
    } catch (error) {
      SentryUtilites.setLog("ItemListComponent:onImageSaved", error)
    }
  }

  async makeFavourite(item:Item){
    try {
      let fetchedItem = await this.allDataService?.itemService?.getByUUID(
        item?._localUUID
      );
      
      if(fetchedItem?._localUUID){
        fetchedItem.isFavourite = !fetchedItem?.isFavourite ? true : false;
        await this.allDataService?.itemService?.update(fetchedItem);
      }
      this.loadView();
    } catch (error) {
      SentryUtilites.setLog("ItemListComponent:makeFavourite", error)
    }
  }

}
