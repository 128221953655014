import { Component, OnInit, ViewChild } from '@angular/core';
import { PinVerificationComponent } from '../../components/pin-verification/pin-verification.component';
import { SaleReturn } from '../../models/SaleReturn.model';
import { BaseBillView } from '../../base/base-bill-view';
import { MoneyOut } from '../../models/MoneyOut.model';
import { SettingsComponent } from '../../components/settings/settings.component';
import { Utility } from '../../utils/utility';
import { ActivatedRoute, Router } from '@angular/router';
import { AccessControlService } from '../../services/auth/access-control.service';
import { AlertController } from '@ionic/angular';
import { AllDataService } from '../../services/all-data.service';
import { AuthService } from '../../services/auth/auth.service';
import { PremiumControlService } from '../../services/auth/premium-control.service';
import { ImageBase64Service } from '../../services/image-base64.service';
import { SentryUtilites } from '../../utils/sentryUtilites';
import { SaleReturnBulkDeleteComponent } from '../../components/bulk-delete/sale-return-bulk-delete/sale-return-bulk-delete.component';
import { Profile } from '../../models/Profile.model';
import Party from '../../models/Party.model';
import { HtmlTemplates } from '@makarandkate/invoice-templates';
import { SaleReturnBillPrint } from '@makarandkate/invoice-templates/lib/models/SaleReturnBillPrint.model';

@Component({
  selector: 'app-bill-view-sale-return',
  templateUrl: './bill-view-sale-return.page.html',
  styleUrls: ['./bill-view-sale-return.page.scss'],
})
export class BillViewSaleReturnPage extends BaseBillView<SaleReturn, SaleReturnBillPrint, MoneyOut> implements OnInit {
  
  @ViewChild('pinVerificationElement') pinVerificationElement: PinVerificationComponent;
  @ViewChild('settingsModal') settingsModal: SettingsComponent;

  @ViewChild('deleteDataEle') deleteDataEle: SaleReturnBulkDeleteComponent;
  @ViewChild('div', { static: true }) div: any;

  selectedTemplate: 'temp1SaleReturn' | 'temp17SaleReturn' | 'temp18SaleReturn' | 'temp19SaleReturn' | 'temp20SaleReturn' =  Utility.getFromLocalStorage('selectedSaleReturnTemplate') || 'temp18SaleReturn';
  billPrint = new SaleReturnBillPrint();
  record: SaleReturn = null;
  billDownloadEndpoint: string = 'https://db.ezobooks.in/kappa/billView/saleReturn';

  constructor(
    private router: Router,
    private accessControlService: AccessControlService,
    private alertController: AlertController,
    allDataService: AllDataService,
    authService: AuthService,
    route: ActivatedRoute,
    premiumControlService: PremiumControlService,
    imageBase64Service: ImageBase64Service
  ) {
    super(
      route,
      allDataService,
      authService,
      premiumControlService,
      imageBase64Service
    );
  }

  ngOnInit = async () => {
    this.baseNgOnInit();
  };

  async ionViewWillEnter() {
    this.selectedTemplate = Utility.getFromLocalStorage('selectedSaleReturnTemplate') || 'temp18SaleReturn';
    this.baseIonViewWillEnter();
  }

  ionViewWillLeave() {
    this.ngOnDestroy();
  }

  ngOnDestroy() {
    this.baseNgOnDestroy();
  }

  openTransactionPINModal() {
    this.pinVerificationElement?.openTransactionPINModal();
  }

  verifyTransactionPIN(event) {
    try {
      if (event) {
        this.router.navigate([`sale-return/form/${this.record?._localUUID}`]);
      }
    } catch (error) {
      SentryUtilites.setLog('BillViewSaleReturnPage:verifyTransactionPIN', error);
    }
  }

  setTemplate(event) {
    try {
      this.selectedTemplate = event?.detail?.value;
      Utility.setToLocalStorage('selectedSaleReturnTemplate', this.selectedTemplate);
      this.populateTemplate();
    } catch (error) {
      SentryUtilites.setLog('BillViewSaleReturnPage:setTemplate', error);
    }
  }

  async edit() {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({
        action: 'editSaleReturn',
      });
      if (!isPermit) {
        return alert(
          "Permission: You don't have permission to edit sale return. Please contact to your owner."
        );
      }
      this.openTransactionPINModal();
    } catch (error) {
      SentryUtilites.setLog('BillViewSaleReturnPage:edit', error);
      alert('Something went wrong.');
    }
  }

  async delete() {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({
        action: 'deleteSaleReturn',
      });
      if (!isPermit) {
        return alert(
          "Permission: You don't have permission to delete sale return. Please contact to your owner."
        );
      }
      this.deleteDataEle?.initDeleteSaleReturn(this.record);
    } catch (error) {
      SentryUtilites.setLog('BillViewSaleReturnPage:delete', error);
      alert('Something went wrong.');
    }
  }

  async navigateToBill() {
    try {
      let checkCreateSale = await this.premiumControlService.checkCreateSale();
      if (checkCreateSale) {
        let isPermit = await this.accessControlService.isUserHasAccess({
          action: 'createSaleReturn',
        });
        if (!isPermit) {
          return alert(
            "Permission: You don't have permission to create new sale return. Please contact to your owner."
          );
        }
        this.router.navigate([`sale-return/form`]);
      }
    } catch (error) {
      SentryUtilites.setLog('BillViewSaleReturnPage:navigateToBill', error);
      alert('Something went wrong.');
    }
  }

  openTNCSetting(): void {
    try {
      if (this.settingsModal) {
        this.settingsModal.openSettingsModal();
        this.settingsModal.viewSectionIds = [5];
        this.settingsModal.viewSettingKeys = ['pSetTermsAndConditions'];
      }
    } catch (error) {
      SentryUtilites.setLog('BillViewSaleReturnPage:openTNCSetting', error);
    }
  }

  async setTermsAndConditions(profile: Profile) {
    // if (profile?.iSetPrintMBMA !== false) {
    //     profile.pSetTermsAndConditions = (profile?.pSetTermsAndConditions || '') + `
    //     <b>Mera Bill Mera Adhikar</b>
    //     Win 1 Crore with this Bill
    //     1. Download Mera Bill Mera Adhikar app
    //     2. Register your self
    //     3. Add photo of this bill
    //     4. Lucky Customer will get upto 1 crore from government
    //     5. Lucky Shopkeeper will get upto 1 Crore from EZO.
    //     `;
    // }
  }

  async setRecord(paramDocumentId: string): Promise<SaleReturn> {
    try {
      this.record = await this.allDataService.saleReturnService.getByUUID(
        paramDocumentId
      );
      if (this.record?.deletedStamp) {
        const alert = await this.alertController.create({
          header: 'Alert!',
          message: `Bill Deleted by ${
            this.record?.lastModifiedByName || this.record?.lastModifiedBy
          } on ${Utility.setDateValue(this.record?.deletedStamp)}`,
          mode: 'ios',
          buttons: [
            {
              text: 'Go Back',
              role: 'cancel',
              handler: () => {
                this.router.navigate(['sale-return']);
                return null;
              },
            },
          ],
        });
        await alert.present();
      } else {
        return this.record;
      }
    } catch (error) {
      SentryUtilites.setLog('BillViewSaleReturnPage:setRecord', error);
      return this.record;
    }
  }

  async getMoneyInOut(): Promise<MoneyOut> {
    try {
      if (
        this.record?.moneyOuts?.length &&
        this.record?.moneyOuts[0]?._localUUID
      ) {
        return await this.allDataService.moneyOutService.getByUUID(
          this.record?.moneyOuts[0]?._localUUID
        );
      }
      return null;
    } catch (error) {
      SentryUtilites.setLog('BillViewSaleReturnPage:getMoneyInOut', error);
      return null;
    }
  }

  setBillPrint(
    tokenDetails: any,
    profile: Profile,
    signature: any,
    logo: any,
    party: Party,
    secondaryParty: Party
  ): void {
    try {
      this.billPrint = HtmlTemplates.generateSaleReturnBillObject({
        user: {
          isPro: tokenDetails?.isPro,
          registrationStamp: tokenDetails?.createdStamp,
        },
        profile,
        signature,
        logo,
        party,
        secondaryParty,
        saleReturn: this.record,
        moneyOut: this.moneyInOut,
        isPartyDeleted: this.isPartyDeleted,
      });
    } catch (error) {
      SentryUtilites.setLog('BillViewSaleReturnPage:setBillPrint', error);
    }
  }

  setTemplateToHtmlDiv(htmlString: any): void {
    try {
      this.div.nativeElement.innerHTML = htmlString;
    } catch (error) {
      SentryUtilites.setLog('BillViewSaleReturnPage:setTemplateToHtmlDiv', error);
    }
  }
}
