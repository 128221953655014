import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Utility } from '../../utils/utility';
import { AllDataService } from '../../services/all-data.service';
import { AuthService } from '../../services/auth/auth.service';
import { HtmlTemplates } from '@makarandkate/invoice-templates/lib';
import { DeleteDataComponent } from '../../components/delete-data/delete-data.component';
import Party from '../../models/Party.model';
import { SettingsComponent } from '../../components/settings/settings.component';
import { PinVerificationComponent } from '../../components/pin-verification/pin-verification.component';
import { AccessControlService } from '../../services/auth/access-control.service';
import { Profile } from '../../models/Profile.model';
import { Purchase } from 'src/app/models/Purchase.model';
import { MoneyOut } from 'src/app/models/MoneyOut.model';
import { PurchaseBillPrint } from '@makarandkate/invoice-templates/lib/models/PurchaseBillPrint.model';
import { BaseBillView } from 'src/app/base/base-bill-view';
import { PremiumControlService } from '../../services/auth/premium-control.service';
import { AlertController } from '@ionic/angular';
import { ImageBase64Service } from '../../services/image-base64.service';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-bill-view-purchase',
  templateUrl: './bill-view-purchase.page.html',
  styleUrls: ['./bill-view-purchase.page.scss'],
})
export class BillViewPurchasePage extends BaseBillView<Purchase, PurchaseBillPrint, MoneyOut> implements OnInit {

  @ViewChild('pinVerificationElement') pinVerificationElement: PinVerificationComponent;
  @ViewChild('settingsModal') settingsModal: SettingsComponent;

  @ViewChild('deleteDataEle') deleteDataEle: DeleteDataComponent;
  @ViewChild('div', { static: true }) div: any;

  selectedTemplate: string;

  record: Purchase;

  selectedPurchaseemplate: 'temp1Purchase' | 'temp17Purchase' | 'temp18Purchase' | 'temp19Purchase' | 'temp20Purchase' = Utility.getFromLocalStorage('selectedPurchaseTemplate') || 'temp18Purchase';

  billPrint = new PurchaseBillPrint();

  purchase: Purchase = null;

  billDownloadEndpoint: string = 'https://db.ezobooks.in/kappa/billView/purchase';
  constructor(
    private router: Router,
    private accessControlService: AccessControlService,
    private alertController: AlertController,
    allDataService: AllDataService,
    authService: AuthService,
    route: ActivatedRoute,
    premiumControlService: PremiumControlService,
    imageBase64Service: ImageBase64Service,
  ) {
    super(
      route,
      allDataService,
      authService,
      premiumControlService,
      imageBase64Service,
    );
  }

  ngOnInit = async () => {
    this.baseNgOnInit();
  }

  async ionViewWillEnter() {
    this.selectedTemplate = Utility.getFromLocalStorage('selectedPurchaseTemplate') || 'temp18Purchase';
    this.baseIonViewWillEnter();
    this.isPremiumAccess = await this.premiumControlService.isPremiumAccess();
  }

  ionViewWillLeave() {
    this.ngOnDestroy();
  }

  ngOnDestroy() {
    this.baseNgOnDestroy();
  }

  openTransactionPINModal() {
    this.pinVerificationElement?.openTransactionPINModal();
  }

  verifyTransactionPIN(event) {
    try {
      if (event) {
        this.router.navigate([`purchase/form/${this.record?._localUUID}`]);
      }
    } catch (error) {
      SentryUtilites.setLog("BillViewPurchasePage:verifyTransactionPIN", error)
    }
  }

  setTemplate(event) {
    try {
      this.selectedTemplate = event?.detail?.value;
      Utility.setToLocalStorage('selectedPurchaseTemplate', this.selectedTemplate);
      this.populateTemplate();
    } catch (error) {
      SentryUtilites.setLog("BillViewPurchasePage:setTemplate", error)
    }
  }

  async edit() {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({ action: 'editPurchase' });
      if (!isPermit) {
        return alert("Permission: You don't have permission to edit sale. Please contact to your owner.");
      }
      this.openTransactionPINModal();
    } catch (error) {
      SentryUtilites.setLog("BillViewPurchasePage:edit", error)
      alert("Something went wrong.");
    }
  }

  async delete() {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({ action: 'deletePurchase' });
      if (!isPermit) {
        return alert("Permission: You don't have permission to delete purchase. Please contact to your owner.");
      }
      this.deleteDataEle?.initDeletePurchase(this.record);
    } catch (error) {
      SentryUtilites.setLog("BillViewPurchasePage:delete", error)
      alert("Something went wrong.");
    }
  }

  async navigateToBill(): Promise<void> {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({ action: 'createPurchase' });
      if (!isPermit) {
        return alert("Permission: You don't have permission to create new sale. Please contact to your owner.");
      }
      this.router.navigate([`purchase/form`]);
    } catch (error) {
      SentryUtilites.setLog("BillViewPurchasePage:navigateToBill", error)
      alert("Something went wrong.");
    }
  }

  openTNCSetting(): void {
    try {
      if (this.settingsModal) {
        this.settingsModal.openSettingsModal();
        this.settingsModal.viewSectionIds = [5];
        this.settingsModal.viewSettingKeys = ['pSetTermsAndConditions'];
      }
    } catch (error) {
      SentryUtilites.setLog("BillViewPurchasePage:openTNCSetting", error)
    }
  }

  async setTermsAndConditions(profile: Profile) {
    // if (profile?.iSetPrintMBMA !== false) {
    //   profile.pSetTermsAndConditions = (profile?.pSetTermsAndConditions || '') + `
    //     <b>Mera Bill Mera Adhikar</b>
    //     Win 1 Crore with this Bill
    //     1. Download Mera Bill Mera Adhikar app
    //     2. Register your self
    //     3. Add photo of this bill
    //     4. Lucky Customer will get upto 1 crore from government
    //     5. Lucky Shopkeeper will get upto 1 Crore from EZO.
    //     `;
    // }
  }

  async setRecord(paramDocumentId: string): Promise<Purchase> {
    try {
      this.record = await this.allDataService.purchaseService.getByUUID(paramDocumentId);
      if(this.record?.deletedStamp) {
        const alert = await this.alertController.create({
            header: 'Alert!',
            message: `Bill Deleted by ${this.record?.lastModifiedByName || this.record?.lastModifiedBy} on ${Utility.setDateValue(this.record?.deletedStamp)}`,
            mode: 'ios',
            buttons: [
              {
                text: 'Go Back',
                role: 'cancel',
                handler: () => {
                    this.router.navigate(['purchase']);
                    return null
                 },
              },
            ],
        });
        await alert.present();
      } else {
          return this.record;
      }
    } catch (error) {
      SentryUtilites.setLog("BillViewPurchasePage:setRecord", error)
      return this.record;
    }
  }

  async getMoneyInOut(): Promise<MoneyOut> {
    try {
      if (this.record?.moneyOuts?.length && this.record?.moneyOuts[0]?._localUUID) {
        return await this.allDataService.moneyOutService.getByUUID(this.record?.moneyOuts[0]?._localUUID);
      }
      return null;
    } catch (error) {
      SentryUtilites.setLog("BillViewPurchasePage:getMoneyInOut", error)
      return null;
    }
  }

  setBillPrint(
    tokenDetails: any,
    profile: Profile,
    signature: any,
    logo: any,
    party: Party,
    secondaryParty: Party,
  ): void {
    try {
      this.billPrint = HtmlTemplates.generatePurchaseBillObject({
        user: {
          isPro: tokenDetails?.isPro,
          registrationStamp: tokenDetails?.createdStamp
        },
        profile,
        signature,
        logo,
        party,
        purchase: this.record,
        moneyOut: this.moneyInOut,
        isPartyDeleted: this.isPartyDeleted,
      });
    } catch (error) {
      SentryUtilites.setLog("BillViewPurchasePage:setBillPrint", error)
    }
  }

  setTemplateToHtmlDiv(htmlString: any): void {
    try {
      this.div.nativeElement.innerHTML = htmlString;
    } catch (error) {
      SentryUtilites.setLog("BillViewPurchasePage:setTemplateToHtmlDiv", error)
    }
  }



}
