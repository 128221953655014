<ion-header>
  <ion-toolbar class="{{ getHeaderColorClass() }}">
    <app-navigation-control slot="start" type="ingredient/edit"></app-navigation-control>
    <ion-title> {{ headerTitles[paramAction] }} </ion-title>
    <!-- <ion-chip class="chip-new-ingredient" color="tertiary" slot="end" (click)="onNewIngredientClick()"> + New Ingredient </ion-chip> -->
  </ion-toolbar>
</ion-header>

<ion-content>

  <ion-searchbar
    #searchBar
    placeholder="Search by raw material name..."
    [debounce]="100"
    (ionChange)="onFilterChange($event['detail'].value)"
  >
  </ion-searchbar>
  
  <ion-fab class="btn-filter-by" #filterByFab>
    <ion-fab-button class="first-fab-button" size="small" (clickOutSide)="closeclickOutSideFilter()">
      Filter By
    </ion-fab-button>
    <ion-fab-list side="bottom" [ngClass]="{'scrollable': categories?.length>=18}">
      <ion-fab-button
        [ngClass]="{'filter-sort-active': !appliedCategory}"
        (click)="onCategoryChange('')"
      >
        All ({{ categoryWiseIngredientCount['All'] || 0 }})
      </ion-fab-button>
      <ion-fab-button
        [ngClass]="{'filter-sort-active': category===appliedCategory}"
        *ngFor="let category of categories"
        (click)="onCategoryChange(category)"
      >
        {{ category }}  ({{ categoryWiseIngredientCount[category] || 0 }})
      </ion-fab-button>
    </ion-fab-list>
  </ion-fab>
  
  <ion-fab class="btn-sort-by" #sortByFab>
    <ion-fab-button class="first-fab-button" size="small" (clickOutSide)="closeclickOutSideSort()">
      Sort By
    </ion-fab-button>
    <ion-fab-list side="bottom">
      <ion-fab-button
        [ngClass]="{'filter-sort-active': sort?.key===selectedFilter}"
        *ngFor="let sort of sortObj|keyvalue: returnZero"
        (click)="onSortChnage(sort.key)"
      >
        {{ sort?.value }}
      </ion-fab-button>
    </ion-fab-list>
  </ion-fab>
  
  <ion-grid>

    <ion-row>
      
      <ion-col class="col-categories" size="2" [ngStyle]="{'height': innerHeight}">
        <ng-container *ngIf="!paramDocumentId">
          <ion-item lines="none" [class.active-item]="!appliedCategory" (click)="onCategoryChange('')">
            All ({{ filteredList?.length || 0 }})
          </ion-item>
          <ion-item lines="none" *ngFor="let category of categories" [class.active-item]="category === appliedCategory"
            (click)="onCategoryChange(category)">
            {{ category }} ({{ categoryWiseIngredientCount[category] || 0 }})
          </ion-item>
        </ng-container>
      </ion-col>

      <ion-col class="col-raw-material-list" size="10" [ngStyle]="{'height': innerHeight}">
        <ion-row class="table-row">
          <ion-col class="bold row-header-titles" size="{{paramAction=='purchase'?4:6}}">Raw Material</ion-col>
          <ion-col class="bold row-header-titles" size="{{paramAction=='purchase'?4:6}}">Quantity (Units)</ion-col>
          <ion-col class="bold row-header-titles" size="4" *ngIf="paramAction=='purchase'">Total Price (in rupees)</ion-col>

          <ng-container *ngFor="let ingredient of viewFilteredList; let i=index;">
            <ion-col [ngClass]="{'row-alt-color':i%2!==0}" class="semi-bold" size="{{paramAction=='purchase'?4:6}}"> {{ ingredient?.name }} </ion-col>
            <ion-col [ngClass]="{'row-alt-color':i%2!==0}" size="{{paramAction=='purchase'?4:6}}">
              <ion-row>
                <ion-col class="no-extra-space" size="8"  class="ion-col-textbox">
                  <ion-input 
                    type="number" 
                    min="0"
                    [value]="paramAction=='eod'&&ingredient?.todayEOD?.quantity >= 0 ? ingredient?.todayEOD?.quantity : ''"
                    (ionBlur)="onIngredientEdit('quantity',$event,ingredient?._localUUID,ingredient?.unit,ingredient?.todayEOD)"
                  ></ion-input>
                </ion-col>
                <ion-col class="no-extra-space" size="4">
                  <span class="sp-unit">(in {{ ingredient?.unit }})</span>
                </ion-col>
              </ion-row>
            </ion-col>
            <ion-col [ngClass]="{'row-alt-color':i%2!==0}" size="4" *ngIf="paramAction=='purchase'">
              <ion-row>
                <ion-col class="ion-col-textbox">
                  <ion-input type="number" min="0" (ionBlur)="onIngredientEdit('price',$event,ingredient?._localUUID,ingredient?.unit,null)"></ion-input>
                </ion-col>
              </ion-row>
            </ion-col>
          </ng-container>
        </ion-row>
      </ion-col>

    </ion-row>
  
  </ion-grid>


  <ion-button class="btn-save" (click)="saveIngredientStockAdjusts()">
    Save
  </ion-button>

  <ion-infinite-scroll
    threshold="10px"
    *ngIf="viewFilteredList?.length"
    (ionInfinite)="loadMoreListData($event)">
    <ion-infinite-scroll-content
      loadingSpinner="bubbles"
      loadingText="Loading more data...">
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
  
  <app-ingredient-stock-adjust #ingredientStockAdjustEle (saveTrigger)="loadView()"></app-ingredient-stock-adjust>

  <ion-modal [ngStyle]="{'left': ionModalLeftShift}" [isOpen]="isNoteModalOpen" [backdropDismiss]="false">
    <ng-template>
      <ion-header>
        <ion-toolbar>
          <ion-title>Add Note (Optional)</ion-title>
          <ion-buttons slot="end">
            <ion-button (click)="openNoteModal(false)">Close</ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-padding">
  
        <ion-textarea
          class="new-ion-textarea"
          rows="10"
          placeholder="You can mention who is making the entry e.g John Doe"
          (ionBlur)="setNote($event)"
        ></ion-textarea>
        <ion-button
          color="primary"
          expand="full"
          (click)="save()"
        >
          Save
        </ion-button>
        
      </ion-content>

    </ng-template>
  </ion-modal>
  
</ion-content>