<ion-header>
  <ion-toolbar 
    class="{{ getHeaderColorClass() }}">
    <app-navigation-control 
      slot="start" 
      type="party/whatapp-bulk-msg">
    </app-navigation-control>
    <ion-title>WhatsApp Bulk Message Templates</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid 
    class="all-list" 
    [ngClass]="{'mobile': isMobile}">
    <ion-card 
      color="success" 
      *ngFor="let template of templates" 
      (click)="selectTemplate(template)">
      <ion-card-content> {{template?.msgBody}} </ion-card-content>
    </ion-card>
  </ion-grid>
</ion-content>

<ion-modal 
  class="party-modal" 
  [isOpen]="openPartyModal" 
  [backdropDismiss]="true">
  <ng-template>
    <ion-header>
      <ion-toolbar 
        class="{{ getHeaderColorClass() }}">
        <ion-title>Choose Party</ion-title>
        <ion-buttons 
          slot="start">
          <ion-chip 
            color="tertiary" 
            class="btn-back" 
            (click)="openPartySelectorModal(false)">
            <ion-icon 
              name="arrow-back-sharp">
            </ion-icon>
          </ion-chip>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">

      <app-whatapp-party-selector
        [isModal]="true"
        [variables]="selectedTemplate?.variables"
        #partyListComponent
        (nextClick)="selectedParties($event)"
      >
      </app-whatapp-party-selector>
    </ion-content>
  </ng-template>
</ion-modal>

<ion-modal 
  class="template-modal" 
  [isOpen]="openSampleTemplate" 
  [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar 
        class="{{ getHeaderColorClass() }}">
        <ion-title>Sample Template</ion-title>
        <ion-buttons 
          slot="end" 
          class="sample-template-btn">
          <ion-button 
            (click)="openSampleTemplateModal()">
            Close
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content 
      class="ion-padding">
      <ion-button 
        fill="clear" 
        class="btn-party-list btn-txt-black">
        Selected Parties: {{selectedPartyList?.length}}
      </ion-button>
      <div 
        class="selected-template-card">
        <ion-card 
          color="success">
          <ion-card-content>
            {{selectedTemplate?.msgBody}} 
          </ion-card-content>
        </ion-card>
      </div>
      <ion-button 
        class="cancel-btn" 
        (click)="openSampleTemplateModal()">
        Cancel
      </ion-button>
      <ion-button 
        class="send-btn" 
        (click)="sendTemplatesToParties()">
        Send WhatsApp message to {{selectedPartyList?.length}} Parties
      </ion-button>
    </ion-content>
  </ng-template>
</ion-modal>

<ion-modal 
  class="custom-template-modal" 
  [isOpen]="openCustomFieldTitle" 
  [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar 
        class="{{ getHeaderColorClass() }}">
        <ion-title></ion-title>
        <ion-buttons 
          slot="end" 
          class="sample-template-btn">
          <ion-button 
            (click)="openCustomFieldTitleModal()">
            Close
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content 
      class="ion-padding">
      <ng-container>
        <ion-item lines="none" *ngFor="let customField of customFieldArray;">
          <ion-label position="stacked">{{customField}}</ion-label>
          <ion-input (ionBlur)="addCustomValue(customField, customFieldVal?.value)"  placeholder="Enter {{customField}}" #customFieldVal></ion-input>
        </ion-item>
      </ng-container>
      <ion-button 
        class="cancel-btn" 
        (click)="openCustomFieldTitleModal()">
        Cancel
      </ion-button>
      <ion-button 
        class="send-btn" 
        (click)="setCustomFieldTitle()">
        Set
      </ion-button>
    </ion-content>
  </ng-template>
</ion-modal>