import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appReportDateRangeModal]'
})
export class ReportDateRangeModalDirective {

  constructor(private ele: ElementRef) {
    const style = (document.createElement('style'));
    style.textContent = `.modal-wrapper {
      position:relative;
      left:10%;
      top:-20%;
    }
    `;
    this.ele?.nativeElement?.shadowRoot?.appendChild(style);
  }

}
