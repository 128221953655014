import { MonthWiseItemStockDao } from './dao/month-wise-item-stock.dao';
import { MonthWisePartyCreditDao } from './dao/month-wise-party-credit.dao';
import { BehaviorSubject } from "rxjs";
import { ExpressServerService } from "./api/express-server.service";
import { IDataService } from "../../interface/IDataService.interface";
import { Profile } from "../models/Profile.model";
import { ProfileDao } from './dao/profile.dao';
import { PartyDao } from './dao/party.dao';
import { Utility } from "../utils/utility";
import { ItemDao } from "./dao/item.dao";
import { AuthService } from "./auth/auth.service";
import { SentryUtilites } from '../utils/sentryUtilites';

export class ProfileService implements IDataService<Profile>{
  private static _instance: ProfileService;

  public static getInstance(
    dao: ProfileDao, 
    partyDao: PartyDao, 
    itemDao: ItemDao, 
    expressServerService: ExpressServerService, 
    authService: AuthService,
    monthWisePartyCreditDao: MonthWisePartyCreditDao,
    monthWiseItemStockDao: MonthWiseItemStockDao,
    ) {
    if (!this._instance) {
      this._instance = new ProfileService(
        dao, 
        partyDao, 
        itemDao, 
        expressServerService,
        authService,
        monthWisePartyCreditDao,
        monthWiseItemStockDao,
        )
    }
    this._instance.reloadList();
    return this._instance;
  }

  constructor(
    profileDao: ProfileDao, 
    partyDao: PartyDao, 
    itemDao: ItemDao, 
    expressServerService: ExpressServerService, 
    authService: AuthService,
    monthWisePartyCreditDao: MonthWisePartyCreditDao,
    monthWiseItemStockDao: MonthWiseItemStockDao,
    ) {
    this.dao = profileDao;
    this.partyDao = partyDao;
    this.itemDao = itemDao;
    this.expressServerService = expressServerService;
    this.authService = authService;
    this.monthWisePartyCreditDao = monthWisePartyCreditDao;
    this.monthWiseItemStockDao = monthWiseItemStockDao;
  }
  dao: ProfileDao;
  partyDao: PartyDao;
  itemDao: ItemDao;
  expressServerService: ExpressServerService;
  authService: AuthService;
  monthWisePartyCreditDao: MonthWisePartyCreditDao;
  monthWiseItemStockDao: MonthWiseItemStockDao;

  LIST_REFRESH_RATE = 1000;
  selectedProfileId: string = null;
  list: Profile[] = [];
  listSubs = new BehaviorSubject<Profile[]>([]);
  updateSubs = new BehaviorSubject<Profile>(null);

  lastReloadStamp: number = 0;
  isReloadPostpond = false;

  initService() {
    this.list = [];
    this.reloadList();
  }

  reloadList = async () => {
    try {
      if (this.isReloadPostpond) {
        return;
      }
      const currentStamp = +new Date();
      if (this.lastReloadStamp < (currentStamp - this.LIST_REFRESH_RATE)) {
        this.lastReloadStamp = currentStamp;
        let docs = await this.dao.getAll();
        this.list = docs;
        this.listSubs.next(this.list);
        this.trySyncUnsynced();
      } else {
        this.isReloadPostpond = true;
        setTimeout(() => {
          this.isReloadPostpond = false;
          this.reloadList();
        }, this.LIST_REFRESH_RATE + 100);
      }
    } catch (error) {
      SentryUtilites.setLog("ProfileService:reloadList", error)
      return null;
    }
  }

  getAllByPromise(): Promise<Profile[]> {
    return this.dao.getAll();
  }

  /**
   * 
   * @returns : deleted profile list from profile dao
   * @description : return deleted profile list from profile dao on promise
   */
  getAllDeletedProfileByPromise(): Promise<Profile[]> {
    return this.dao.getAllDeletedProfile();
  }
  // ------------------------------------------

  getAllByPromiseByProfile(profileId: string) {
    return this.dao.getAllByProfile(profileId);
  }

  getById(id: number): Promise<Profile> {
    return this.dao.getById(id)
  }

  getByUUID(uuid: string): Promise<Profile> {
    return this.dao.getByUUID(uuid);
  }

  getCurrentProfile(): Promise<Profile> {
    let selectedProfileId = Utility.getFromLocalStorage('selectedProfile');
    return this.dao.getByUUID(selectedProfileId);
  }

  save(profile: Profile): Promise<Profile> {
    if(Utility.isTruthy(profile)) {
      profile.userId = this.authService.getLoginPhone();
      profile.createdBy = profile.lastModifiedBy = this.authService.getLoginPhone();
      profile.createdByName = profile.lastModifiedByName = Utility.getCreatedByName();
      return new Promise(async (resolve, reject) => {
        try {
          let savedProfile = await this.dao.save(profile);
          this.reloadList();
          return resolve(savedProfile);
        } catch (error) {
          SentryUtilites.setLog("ProfileService:save", error)
          return resolve(null);
        }
      });
    } else {
      return null;
    }
  }

  update(profile: Profile): Promise<Profile> {
    return new Promise(async (resolve, reject) => {
      try {
        if(profile?._localUUID) {
          profile.lastModifiedBy = this.authService.getLoginPhone();
          profile.lastModifiedByName = Utility.getCreatedByName();
          let updatedProfile = await this.dao.update(profile);
          this.reloadList();
          return resolve(updatedProfile);
        }
        return resolve(null);
      } catch (error) {
        SentryUtilites.setLog("ProfileService:update", error)
        return resolve(null);
      }
    });
  }

  delete(profile: Profile): Promise<Profile> {
    return new Promise(async (resolve, reject) => {
      try {
        if(profile?._localUUID) {
          profile.lastModifiedBy = this.authService.getLoginPhone();
          profile.lastModifiedByName = Utility.getCreatedByName();
          let deletedProfile = await this.dao.delete(profile);
          this.reloadList();
          return resolve(deletedProfile);
        }
        return resolve(null);
      } catch (error) {
        SentryUtilites.setLog("ProfileService:delete", error)
        return resolve(null);
      }
    });
  }

  isSyncLock = false;
  isSyncPostPond = false;
  async trySyncUnsynced(postpond?: boolean) {
    try {
      if (this.isSyncLock) {
        if (!this.isSyncPostPond) {
          setTimeout(() => {
            this.isSyncPostPond = true;
            this.trySyncUnsynced(true);
          }, 200);
        }
        return true;
      }
      if (postpond) {
        this.isSyncPostPond = false;
      }
      this.isSyncLock = true;
      let unSyncedElements = await this.dao.getAllUnsynced('');
  
      try {
        if(unSyncedElements?.length) {
  
          for (let i = 0; i < unSyncedElements?.length; i++) {
            let unSyncedElement = unSyncedElements[i];
            if(unSyncedElement?._localUUID) {
              unSyncedElement['updatedStamp'] = +new Date();
            }
          }
  
          await this.dao.bulkPut(unSyncedElements);
          await Utility.wait(1000);
          
          let chunkArr = Utility.getChunkArr(unSyncedElements);
          let chunkArrLength = chunkArr?.length;
  
          for(let i = 0; i < chunkArrLength; i++) {
            let result = await this.expressServerService.makeSyncCall('profile', chunkArr[i]);
            if (result && result?.['records']?.length) {
              let arr = result?.['records'];
              for (let i = 0; i < arr?.length; i++) {
                const el = arr[i];
                await this.updateSyncStamp(el);
              }
            }
          }
        }
  
      } catch (err) {
        SentryUtilites.setLog("ProfileService:trySyncUnsynced:inner", err)
       }
  
      this.isSyncLock = false;
    } catch (error) {
      SentryUtilites.setLog("ProfileService:trySyncUnsynced", error)
    }

  }

  updateSyncStamp(el: Profile): Promise<Profile> {
    return new Promise(async (resolve, reject) => {
      try {
        let updatedEl = await this.dao.updateSyncStamp(el);
        this.updateSubs.next(updatedEl);
        return resolve(updatedEl);
      } catch (error) {
        SentryUtilites.setLog("ProfileService:updateSyncStamp", error)
        return resolve(null);
      }
    });
  }

  async createProfile(profileName: string, isSystemMetaData: boolean = false) {
    try {
      let profile = new Profile();
      profile.profileName = profileName;
      profile.iSetItemSelectorStyleWeb = 'RestaurantImage';
      profile.pSetTermsAndConditions = 'Thank You! Visit Again!';
      profile.pSetPoweredByEzoStatus = true;
      profile.discountSoundStatus = true;
      profile.dSetDiscountStatusI = true;
      profile.dSetDiscountOfferTypeI = 1;
      profile.dSetDiscountNameI = 'Basic Discount';
      profile.dSetDiscountPercentI = 2;
      profile.dSetDiscountMaximumAmountI = 20;
      profile.dSetDiscountMinimumAmountI = 1;
      profile.dSetDiscountExpiryDaysI = 365;
      profile.dSetDiscountOfferTypeII = 1;
      profile.isStartsWithSearchPrioritized = true;
      const tokenDetails = this.authService.getDecodedAccessToken();
      if(tokenDetails?.phone) {
        profile.contactPersonPhone = tokenDetails?.phone;
      }
      let result = await this.save(profile);
  
      return result;
    } catch (error) {
      SentryUtilites.setLog("ProfileService:createProfile", error)
        return null;
    }
  }

  async addSampleDataToProfile(profileUUID:string){
    try {
      let p = await this.partyDao.addCashSaleParty(profileUUID);
      let i = await this.itemDao.addSamosaItem(profileUUID);
      await this.monthWisePartyCreditDao.save(p?._localUUID);
      await this.monthWiseItemStockDao.save(i?._localUUID);
    } catch (error) {
      SentryUtilites.setLog("ProfileService:addSampleDataToProfile", error)
        return null;
    }
  }

  async setCreatedByName() {
    try {
      let loginPhone = this.authService.getLoginPhone();
      let currentProfile = await this.getCurrentProfile();
      if(loginPhone == currentProfile?.userId) {
        Utility.setCreatedByName('Admin');
      }else {
        let accessTo = currentProfile?.accessTo?.filter(accessTo => accessTo?.userId==loginPhone)[0];
        if(accessTo?.name) {
          Utility.setCreatedByName(accessTo?.name);
        }else {
          Utility.setCreatedByName(null);
        }
      }
    } catch (error) {
      SentryUtilites.setLog("ProfileService:setCreatedByName", error)
        return null;
    }
  }

  copyData(fromProfileId: string, toProfileId: string): Promise<boolean> {
    return new Promise(async (resolve,reject) => {
      try {
        if(Utility.isTruthy(fromProfileId) && Utility.isTruthy(toProfileId)) {
          let fromRecord = await this.getByUUID(fromProfileId);
          if(fromRecord?._localUUID) {
            let toRecord = await this.getByUUID(toProfileId);
            if(toRecord?._localUUID) {
              delete fromRecord?._localId;
              delete fromRecord?._localUUID;
              delete fromRecord?.createdStamp;
              delete fromRecord?.deletedStamp;
              delete fromRecord?.syncStamp;
              delete fromRecord?.deviceSyncStartStamp;
              delete fromRecord?.updatedStamp;
              delete fromRecord?.userId;
              delete fromRecord?.createdBy;
              delete fromRecord?.lastModifiedBy;
              delete fromRecord?.createdByName;
              delete fromRecord?.lastModifiedByName;
              delete fromRecord?.profileName;
              toRecord = {...toRecord,...fromRecord};
              let savedRecord = await this.update(toRecord);
              if(savedRecord?._localUUID) {
                return resolve(true);
              }
            }
          }
        }
        return resolve(false);
      } catch (error) {
        SentryUtilites.setLog("ProfileService:copyData", error)
        return resolve(false);
      }
    });
  }

}

