import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { Subscription } from 'rxjs';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-no-access',
  templateUrl: './no-access.page.html',
  styleUrls: ['./no-access.page.scss'],
})
export class NoAccessPage implements OnInit {

  isUserLoggedIn: boolean = false;
  userLoggedInSubs: Subscription;

  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit() { 
    this.checkUserLogin();
  }

  ngOnDestroy() {
    this.userLoggedInSubs.unsubscribe();
  }

  checkUserLogin() {
    try {
      this.userLoggedInSubs = this.authService.userLoggedInSubs.subscribe((isUserLoggedIn)=>{
        this.isUserLoggedIn = isUserLoggedIn;
      });
    } catch (error) {
      SentryUtilites.setLog("NoAccessPage:checkUserLogin", error)
    }
  }

  navigateToSaleForm() {
    try {
      if(this.isUserLoggedIn) {
        this.router.navigate(['dashboard']);
      } else {
        this.router.navigate(['sign-in-with-gmail']);
      }
    } catch (error) {
      SentryUtilites.setLog("NoAccessPage:navigateToSaleForm", error)
    }
  }

}
