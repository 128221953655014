import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { Validator } from '../utils/validator';
import { SentryUtilites } from '../utils/sentryUtilites';

@Directive({
  selector: '[appColorFormValidation]'
})
export class ColorFormValidationDirective {

  @Input() controlName: 'phone' | 'positiveNumber' | 'gstin' | 'pincode' | 'email' | 'upi' | 'bankAccountNo' | 'bankIFSC' | 'noSpaceAtStart' = null;
  @Input() inputDesign: 'box' | '' = null;

  constructor(private elRef: ElementRef) { 
    // on visit form it will check value
    setTimeout(() => {
      this.checkValue();
    }, 100);
  }

  @HostListener('ionBlur') 
  onBlur() {
    this.checkValue();
  }

  /**
   * @description : validate input value for phone, positive number, gstin, pincode, email, upi, bank account no, 
   *                bank IFSC, no space at start
   */
  checkValue() {
    try {
      let val = this.elRef?.nativeElement?.value;
      if(this.controlName && val) {
        if(
          this.controlName == 'phone' && !Boolean(val?.match(Validator.phone)) ||
          this.controlName == 'positiveNumber' && !Boolean(val?.match(Validator.positiveNumber)) ||
          this.controlName == 'gstin' && !Boolean(val?.match(Validator.gstin)) ||
          this.controlName == 'pincode' && !Boolean(val?.match(Validator.pincode)) ||
          this.controlName == 'email' && !Boolean(val?.match(Validator.email)) ||
          this.controlName == 'upi' && !Boolean(val?.match(Validator.upi)) ||
          this.controlName == 'bankAccountNo' && !Boolean(val?.match(Validator.bankAccountNo)) ||
          this.controlName == 'bankIFSC' && !Boolean(val?.match(Validator.bankIFSC)) ||
          this.controlName == 'noSpaceAtStart' && !Boolean(val?.match(Validator.noSpaceAtStart))
          ) {
          if(this.inputDesign == 'box') {
            this.elRef.nativeElement.style.borderColor = '#ff0000';
          } else {
            this.elRef.nativeElement.style.color = '#ff0000';
          }
        } else {
          this.elRef.nativeElement.style.color = '';
          this.elRef.nativeElement.style.borderColor = '';
        }
      } else {
        this.elRef.nativeElement.style.color = '';
        this.elRef.nativeElement.style.borderColor = '';
      }
    } catch (error) {
      SentryUtilites.setLog("ColorFormValidationDirective:checkValue", error)
    }
  }
  // ----------------------------------------------------

}
