<ion-header>
  <ion-toolbar class="{{ getHeaderColorClass() }}">
    <app-navigation-control slot="start" type="marketing"></app-navigation-control>
    <ion-title class="header-title">Marketing Tools</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>

  <ion-grid>
    <ion-row class="row-order-review-card">
      <ion-col size="4"></ion-col>
      <ion-col size="4">
        <ion-button class="btn-order-review-card" expand="full" (click)="orderReviewCard()">
          ORDER REVIEW CARD
        </ion-button>
      </ion-col>
      <ion-col size="4"></ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="4"></ion-col>
      <ion-col size="4">
        (NFC Card) <br>
        Only one card per business
      </ion-col>
      <ion-col size="4"></ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="4"></ion-col>
      <ion-col size="4">
        <ion-button class="btn-order-review-card" expand="full" (click)="influencer()">
          Influencer Marketing
        </ion-button>
      </ion-col>
      <ion-col size="4"></ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="4"></ion-col>
      <ion-col size="4">
        <ion-button class="btn-order-review-card" expand="full" (click)="marketingChat()">
          Business Chat
        </ion-button>
      </ion-col>
      <ion-col size="4"></ion-col>
    </ion-row>
  </ion-grid>
 
</ion-content>