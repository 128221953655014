import { Injectable } from '@angular/core';
import { LoginTokenService } from './login-token.service';
import { AuthService } from './auth/auth.service';
import { Router } from '@angular/router';
import { EventService } from './event.service';
import { SentryUtilites } from '../utils/sentryUtilites';

@Injectable({
  providedIn: 'root'
})
export class LogoutService {

  constructor(
    private loginTokenService: LoginTokenService,
    private authService: AuthService,
    private eventService: EventService,
  ) { }

  async logout(isTriggerPageReload: boolean = true): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        this.eventService.setShowContactSupportPopup(false);
        this.eventService.setShowPremiumPopup(false);
        let token = this.authService.getTokenUUID();
        if(token) {
          this.loginTokenService.completeLogout(token);
          await this.authService.logout();
          setTimeout(() => {
            isTriggerPageReload && window?.location?.reload();
            return resolve(true);
          }, 200);
        }
      } catch (error) {
        SentryUtilites.setLog("LogoutService:getById", error)
        return resolve(false);
      }
    });
  }
}
