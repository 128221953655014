import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Utility } from '../../utils/utility';
import { AllDataService } from '../../services/all-data.service';
import { AuthService } from '../../services/auth/auth.service';
import { HtmlTemplates } from '@makarandkate/invoice-templates/lib';
import { DeleteDataComponent } from '../../components/delete-data/delete-data.component';
import Party from '../../models/Party.model';
import { SettingsComponent } from '../../components/settings/settings.component';
import { PinVerificationComponent } from '../../components/pin-verification/pin-verification.component';
import { MoneyIn } from '../../models/MoneyIn.model';
import { AccessControlService } from '../../services/auth/access-control.service';
import { Profile } from '../../models/Profile.model';
import { MoneyInBillPrint } from '@makarandkate/invoice-templates/lib/models/MoneyInBillPrint.model';
import { BaseBillView } from 'src/app/base/base-bill-view';
import { PremiumControlService } from '../../services/auth/premium-control.service';
import { AlertController } from '@ionic/angular';
import { ImageBase64Service } from '../../services/image-base64.service';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
    selector: 'app-bill-view-money-in',
    templateUrl: './bill-view-money-in.page.html',
    styleUrls: ['./bill-view-money-in.page.scss'],
})
export class BillViewMoneyInPage extends BaseBillView<MoneyIn, MoneyInBillPrint, null> implements OnInit {

    @ViewChild('pinVerificationElement') pinVerificationElement: PinVerificationComponent;
    @ViewChild('settingsModal') settingsModal: SettingsComponent;

    @ViewChild('deleteDataEle') deleteDataEle: DeleteDataComponent;
    @ViewChild('div', { static: true }) div: any;

    selectedTemplate: string;
    record: MoneyIn;
    selectedMoneyInemplate: 'temp1MoneyIn' | 'temp17MoneyIn' | 'temp18MoneyIn' | 'temp19MoneyIn' | 'temp20MoneyIn' = Utility.getFromLocalStorage('selectedMoneyInTemplate') || 'temp18MoneyIn';
    billPrint = new MoneyInBillPrint();
    moneyIn: MoneyIn = null;
    billDownloadEndpoint: string = 'https://db.ezobooks.in/kappa/billView/moneyIn';

    constructor(
        private router: Router,
        private accessControlService: AccessControlService,
        private alertController: AlertController,
        allDataService: AllDataService,
        authService: AuthService,
        route: ActivatedRoute,
        premiumControlService: PremiumControlService,
        imageBase64Service: ImageBase64Service,
    ) {
        super(
            route,
            allDataService,
            authService,
            premiumControlService,
            imageBase64Service,
        );
    }


    ngOnInit = async () => {
        this.baseNgOnInit();
    }

    async ionViewWillEnter() {
        this.selectedTemplate = Utility.getFromLocalStorage('selectedMoneyInTemplate') || 'temp18MoneyIn';
        this.baseIonViewWillEnter();
    }

    ionViewWillLeave() {
        this.ngOnDestroy();
    }

    ngOnDestroy() {
        this.baseNgOnDestroy();
    }

    openTransactionPINModal() {
        this.pinVerificationElement?.openTransactionPINModal();
    }

    verifyTransactionPIN(event) {
        try {
            if (event) {
                this.router.navigate([`money-in/form/${this.record?._localUUID}`]);
            }
        } catch (error) {
            SentryUtilites.setLog("BillViewMoneyInPage:verifyTransactionPIN", error)
        }
    }
    setTemplate(event) {
        try {
            this.selectedTemplate = event?.detail?.value;
            Utility.setToLocalStorage('selectedMoneyInTemplate', this.selectedTemplate);
            this.populateTemplate();
        } catch (error) {
            SentryUtilites.setLog("BillViewMoneyInPage:setTemplate", error)
        }
    }

    async edit() {
        try {
            let isPermit = await this.accessControlService.isUserHasAccess({ action: 'editMoneyIn' });
            if (!isPermit) {
                return alert("Permission: You don't have permission to edit sale. Please contact to your owner.");
            }
            this.openTransactionPINModal();
        } catch (error) {
            SentryUtilites.setLog("BillViewMoneyInPage:edit", error)
            alert("Something went wrong.");
        }
    }

    async delete() {
        try {
            let isPermit = await this.accessControlService.isUserHasAccess({ action: 'deleteMoneyIn' });
            if (!isPermit) {
                return alert("Permission: You don't have permission to delete sale. Please contact to your owner.");
            }
            this.deleteDataEle?.initDeleteMoneyIn(this.moneyIn);
        } catch (error) {
            SentryUtilites.setLog("BillViewMoneyInPage:delete", error)
            alert("Something went wrong."); 
        }
    }

    async navigateToBill() {
        try {
            let isPermit = await this.accessControlService.isUserHasAccess({ action: 'createMoneyIn' });
            if (!isPermit) {
                return alert("Permission: You don't have permission to create new sale. Please contact to your owner.");
            }
            this.router.navigate([`money-in/form`]);
        } catch (error) {
            SentryUtilites.setLog("BillViewMoneyInPage:navigateToBill", error)
            alert("Something went wrong."); 
        }
    }

    openTNCSetting(): void {
        try {
            if (this.settingsModal) {
                this.settingsModal.openSettingsModal();
                this.settingsModal.viewSectionIds = [5];
                this.settingsModal.viewSettingKeys = ['pSetTermsAndConditions'];
            }
        } catch (error) {
            SentryUtilites.setLog("BillViewMoneyInPage:openTNCSetting", error)
            alert("Something went wrong."); 
        }
    }

    async setTermsAndConditions() {
        return null;
    }

    async setRecord(paramDocumentId: string): Promise<MoneyIn> {
        try {
            this.record = await this.allDataService.moneyInService.getByUUID(paramDocumentId);
            if(this.record?.deletedStamp) {
                const alert = await this.alertController.create({
                    header: 'Alert!',
                    message: `Bill Deleted by ${this.record?.lastModifiedByName || this.record?.lastModifiedBy} on ${Utility.setDateValue(this.record?.deletedStamp)}`,
                    mode: 'ios',
                    buttons: [
                      {
                        text: 'Go Back',
                        role: 'cancel',
                        handler: () => {
                            this.router.navigate(['money-in']);
                            return null;
                         },
                      },
                    ],
                });
                await alert.present();
            } else {
                return this.record;
            }
        } catch (error) {
            SentryUtilites.setLog("BillViewMoneyInPage:setRecord", error)
            return this.record;
        }
    }

    async getMoneyInOut(): Promise<null> {
        return null;
    }

    setBillPrint(
        tokenDetails: any,
        profile: Profile,
        signature: any,
        logo: any,
        party: Party,
    ): void {
        try {
            this.billPrint = HtmlTemplates.generateMoneyInBillObject({
                user: {
                    isPro: tokenDetails?.isPro,
                    registrationStamp: tokenDetails?.createdStamp
                },
                profile,
                signature,
                logo,
                party,
                moneyIn: this.record,
                isPartyDeleted: this.isPartyDeleted,
            });
        } catch (error) {
            SentryUtilites.setLog("BillViewMoneyInPage:setBillPrint", error)
        }
    }

    setTemplateToHtmlDiv(htmlString: any): void {
        try {
            this.div.nativeElement.innerHTML = htmlString;
        } catch (error) {
            SentryUtilites.setLog("BillViewMoneyInPage:setTemplateToHtmlDiv", error)
        }
    }



}
