<ion-header [ngClass]="{'mobile': isMobile}">
  <ion-toolbar class="{{ getHeaderColorClass() }}">
    <ion-buttons slot="start">
      <ion-menu-button></ion-menu-button>
    </ion-buttons>
    <ion-title class="ion-title">
      <img *ngIf="isPremiumAccess" class="img-premium-account" src="../assets/icons/premium-account.png">
      <ion-chip color="primary" routerLink="/profile">
        <ion-label class="labelStyle" [ngStyle]="isPremiumAccess ? {'max-width': '195px'} : {'max-width': '240px'}">{{
          selectedProfile?._localUUID?.substr(-4) }} | {{ selectedProfile?.legalName ||
          selectedProfile?.profileName || 'Change Profile' }}</ion-label>
      </ion-chip>
    </ion-title>
    <ion-text class="last-sync-time" color="medium">Last Sync at {{ lastSyncTime }}</ion-text>
    <ion-text *ngIf="isTimeDifference" class="last-sync-time timezone-warning alert-border padding-alert-boder"
      color="warning"> <i>Time Zone
        Mismatched</i> </ion-text>
    <ion-chip *ngIf="selectedProfile?.aAppCutOffDayStatus" class="chip-end-day" slot="end" color="tertiary"
      (click)="endDay()">End Day</ion-chip>
    <!-- <ion-chip class="chip-end-day" slot="end" color="tertiary"
      (click)="openSlider()">Open Slider</ion-chip> -->
    <img [class]="selectedProfile?.aAppCutOffDayStatus ? 'icon-support' : 'temp-icon-support'"
      src="../../../assets/icons/support-call.png" (click)="openSupportModal()">
    <ion-chip class="chip-privacy" slot="end" color="tertiary">
      <span class="title">Privacy</span>
      <span>
        <ion-toggle color="tertiary" slot="end" [checked]="privacyStatus"
          (ionChange)="onPrivacySettingUpdate($event)"></ion-toggle>
      </span>
    </ion-chip>
    <ion-chip color="primary" slot="end" class="chip-option" (click)="toggleOption()" (clickOutSide)="clickOutSide()">
      <ion-icon name="ellipsis-vertical"></ion-icon>
    </ion-chip>
  </ion-toolbar>
  <ion-list *ngIf="isOptionOpen" class="show-chip-option">
    <ion-item (click)="openSupportModal()">
      Support
      <!-- <ion-img src="../../../assets/icons/support-call.png" style="width: 20px;"></ion-img> -->
    </ion-item>
    <ion-item *ngIf="selectedProfile?.aAppCutOffDayStatus" (click)="endDay()">End Day
    </ion-item>
    <ion-item (click)="keepOpenOption()">
      <span class="title">Privacy</span>
      <span>
        <ion-toggle color="tertiary" slot="end" [checked]="privacyStatus"
          (ionChange)="onPrivacySettingUpdate($event)"></ion-toggle>
      </span>
    </ion-item>
  </ion-list>
</ion-header>

<ion-content [ngClass]="{'mobile': isMobile}">
  <ion-grid>
    <ion-row *ngIf="!privacyStatus" class="card-total-amounts" id="scroll-container">
      <ng-container *ngFor="let card of cardList.slice(0,7)">
        <ion-col *ngIf="card?.isVisbile && showCards" size-sm="4" size-xl="3" size-xs="6">
          <ion-card [color]="card.color" mode="ios">
            <ion-card-header>
              <ion-card-title [ngClass]="{'mobile': isMobile}" [routerLink]="card.listUrl"
                [queryParams]="card.listQueryParam">
                <span *ngIf="card?.rupeeSymb">{{card.amount | currency:'INR'}}</span>
                <span *ngIf="!card?.rupeeSymb">{{card.amount}}</span>
                <ion-skeleton-text *ngIf="card.loader" [animated]="true"></ion-skeleton-text>
              </ion-card-title>
              <ion-card-subtitle>
                <span [routerLink]="card.listUrl" [queryParams]="card.listQueryParam">{{card.filter==='Today' ? "Today's"
                  : ""}} {{card.title}}</span>
                <ion-chip color="light" *ngIf="card?.formUrl" [ngClass]="{'mobile': isMobile}" (click)="checkSaleCredit(card?.formUrl)">Add <span
                    class="symbol">+</span></ion-chip>
              </ion-card-subtitle>
            </ion-card-header>
          </ion-card>
        </ion-col>
      </ng-container>

      <!-- Right arrow for scroll Start -->
      <ion-icon 
        name="arrow-forward-outline" 
        *ngIf="!hideRightScroll && hideScroll" 
        [ngClass]="isMobile && !privacyStatus ? 'right-arrow-hide' : 'right-arrow'" 
        (click)="rightScroll()"
        ></ion-icon>
      <!-- Right arrow for scroll End -->

      <!-- Left arrow for scroll Start -->
      <ion-icon 
        name="arrow-back-outline" 
        *ngIf="hideRightScroll && hideScroll" 
        [ngClass]="isMobile && !privacyStatus ? 'left-arrow-hide' : 'left-arrow'"  
        (click)="leftScroll()">
      </ion-icon>
      <!-- Left arrow for scroll End -->

    </ion-row>

    <!-- loyal customers cards start -->
    <ion-row *ngIf="!privacyStatus" class="card-total-amounts">
      <ion-col size-sm="4" size-xl="3" size-xs="6" *ngFor="let card of cardList.slice(7, 10)">
        <ion-card *ngIf="card?.isVisbile && showCards" [color]="card.color" mode="ios">
          <ion-card-header>
            <ion-card-title [routerLink]="card.listUrl" [queryParams]="card.listQueryParam">
              <span *ngIf="card?.rupeeSymb">{{card.amount | currency:'INR'}}</span>
              <span *ngIf="!card?.rupeeSymb">{{card.amount}}</span>
              <ion-skeleton-text *ngIf="card.loader" [animated]="true"></ion-skeleton-text>
            </ion-card-title>
            <ion-card-subtitle>
              <span [routerLink]="card.listUrl" [queryParams]="card.listQueryParam">{{card.filter==='Today' ? "Today's"
                : ""}} {{card.title}}</span>
              <ion-chip color="light" *ngIf="card.formUrl" [routerLink]="card.formUrl">Add <span
                  class="symbol">+</span></ion-chip>
            </ion-card-subtitle>
          </ion-card-header>
        </ion-card>
      </ion-col>
    </ion-row>
    <!-- loyal customers cards End -->

    <!-- google mybuisness cards start -->
    <ion-row *ngIf="!privacyStatus && gmbLocationId?.length && isLocationMetricsHasData" class="card-total-amounts" id="scroll-gmb-container">
      <ng-container *ngFor="let card of cardList.slice(10)">
        <ion-col size-sm="4" size-xl="3" size-xs="6" *ngIf="!card?.showRefresh" class="google-col" (click)="googleNavigate(card.title)">
          <ion-card *ngIf="card?.isVisbile && showCards" mode="ios">
            <ion-card-header>
              <ion-card-title [routerLink]="card.listUrl" [queryParams]="card.listQueryParam">
                <span *ngIf="card?.rupeeSymb">{{card.amount | currency:'INR'}}</span>
                <span *ngIf="!card?.rupeeSymb">{{card.amount}}</span>
                <button *ngIf="card?.replyWithAI" class="reply-with-ai-btn">Reply with AI</button>
                <ion-skeleton-text *ngIf="card.loader" [animated]="true"></ion-skeleton-text>
              </ion-card-title>
              <ion-card-subtitle>
                <span [routerLink]="card.listUrl" [queryParams]="card.listQueryParam">
                  <img class="google-img-card" src="../../../assets/images/google_logo.png">
                  {{card.filter==='Today' ? "Today's" : ""}} {{card.title}}</span>
                <ion-chip color="light" *ngIf="card.formUrl" [routerLink]="card.formUrl">Add <span
                    class="symbol">+</span></ion-chip>
              </ion-card-subtitle>
            </ion-card-header>
          </ion-card>
        </ion-col>
        <ion-col size="2" *ngIf="card?.showRefresh" class="refresh-col">
          <ion-card (click)="getLocationMetrics(true)">
            <img class="refresh-img-card" src="../../../assets/images/refresh_button.png">
          </ion-card>
        </ion-col>
      </ng-container>
    </ion-row>
    <!-- google mybuisness cards End -->

    <!-- GMB Right arrow for scroll Start -->
    <ion-icon 
    name="arrow-forward-outline" 
    *ngIf="!hideGMBRightScroll && gmbLocationId?.length && isLocationMetricsHasData" 
    [ngClass]="isMobile && !privacyStatus ? 'right-arrow-hide' : 'right-gmb-arrow'" 
    (click)="rightGMBScroll()"
    ></ion-icon>
  <!-- GMB Right arrow for scroll End -->

  <!-- Left arrow for scroll Start -->
  <ion-icon 
    name="arrow-back-outline" 
    *ngIf="hideGMBRightScroll && gmbLocationId?.length && isLocationMetricsHasData" 
    [ngClass]="isMobile && !privacyStatus ? 'left-arrow-hide' : 'left-gmb-arrow'"  
    (click)="leftGMBScroll()">
  </ion-icon>
  <!-- Left arrow for scroll End -->

    <!-- Get More Customers Start -->
    <ion-row class="google-auth-btn" *ngIf="!privacyStatus && !gmbLocationId?.length && !isLocationMetricsHasData">
      <ion-button (click)="getMoreCustomers()" fill="outline">
        <ion-img class="google-img" src="../../../assets/images/google_logo.png"></ion-img>
        Get More Customers
        <ion-img class="google-img" src="../../../assets/images/google_logo.png"></ion-img>
      </ion-button>
    </ion-row>
    <!-- Get More Customers End -->

    <!-- Pending Data -->

    <ion-card *ngIf="unsyncedRecordsCount" color="danger">
      <ion-card-content class="dash-pending">
        <h1>DATA UPLOAD PENDING !</h1>
        <h2>{{ unsyncedRecordsCount }} records need to be uploaded</h2>
      </ion-card-content>
    </ion-card>

    <!-- End of Pending Data -->

    <ion-row>

      <ion-col size="12">
        <ion-item class="segment" lines="none">
          <ion-segment mode="ios" value="RecentTransaction" (ionChange)="onListTypeChange($event)">
            <ion-segment-button value="RecentTransaction">
              <ion-label>Recent Transactions</ion-label>
            </ion-segment-button>
            <ion-segment-button value="PaymentReminders">
              <ion-label>Payment Reminders</ion-label>
            </ion-segment-button>
            <ion-segment-button value="BirthdayReminders" *ngIf="birthdayReminder">
              <ion-label>Birthday Reminders</ion-label>
            </ion-segment-button>
          </ion-segment>
        </ion-item>
      </ion-col>

      <ion-col size="12" *ngIf="isRecentTransactionList && !isBirthdayReminderList && !isPaymentReminderList">
        <ion-row>
          <ion-col size="12">
            <ion-button fill="clear" class="btn-txt-black padding-end-none">
              Recent Transactions
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row class="padding-5px all-list-include-date-filter">
          <ion-col size="12" class="col-custom-row parent-col"
            *ngFor="let sale of viewFilteredList">
            <app-sale-row [sale]="sale" [isRecentTransactionList]="true" (onDeleteClick)="delete($event)"
              (onAddPaymentClick)="addPayment($event)">
            </app-sale-row>
          </ion-col>

          <ion-infinite-scroll threshold="10px" *ngIf="viewFilteredList?.length" (ionInfinite)="loadMoreListData($event)">
            <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Loading more data...">
            </ion-infinite-scroll-content>
          </ion-infinite-scroll>

          <app-money-in-selector #moneyInSelector *ngIf="showMoneyInSelector" [isSaleList]="true"
            [sale]="this.selectedSale" [billTotalAmount]="this.selectedSale.totalAmount"
            [moneyInCart]="this.selectedSale?.moneyIns" (onModelClose)="closeMoneySelectorModal()">
          </app-money-in-selector>
          <app-delete-data #deleteDataEle (onSaleDelete)="onDelete($event)"></app-delete-data>
        </ion-row>
      </ion-col>

      <ion-col size="12" *ngIf=" isPaymentReminderList && !isRecentTransactionList && !isBirthdayReminderList">
        <app-payment-reminder></app-payment-reminder>
      </ion-col>

      <!-- Birthday Reminders -->
      <ion-col size="12" *ngIf="isBirthdayReminderList && !isRecentTransactionList && !isPaymentReminderList">
        <ion-row>
          <ion-col size="4">
            <ion-button fill="clear" class="btn-txt-black padding-end-none">
              Birthday Reminders
            </ion-button>
          </ion-col>

          <ion-col size="8">
            <ion-item class="segment" lines="none">
              <ion-segment mode="ios" [value]="selectedDateRange" (ionChange)="onDateRangeChange($event)">
                <ion-segment-button value="today" >
                  <ion-label>Today</ion-label>
                </ion-segment-button>
                <ion-segment-button value="tomorrow">
                  <ion-label>Tomorrow</ion-label>
                </ion-segment-button>
                <ion-segment-button value="week">
                  <ion-label>Week</ion-label>
                </ion-segment-button>
              </ion-segment>
            </ion-item>
          </ion-col>
        </ion-row>

        <ion-row class="padding-5px">
          <ion-col size="12" >
            <!-- Party List -->
            <ion-row class="padding-none party-item-custom-row col-custom-row" *ngFor="let party of birthdayFilterList">
              <ion-col  size="6" 
                class="txt-left padding-none">
                <ion-row >
                  <ion-col size="12">
                    <ion-button fill="clear" class="btn-txt-black btn-party-item-name">
                      {{ party.name }}
                    </ion-button>
                  </ion-col>
                  <ion-col size="12">
                    <ion-label class="label-party-phone label-note padding-5px">
                      {{ party['birthDate'] | date:'dd-MM'}} | {{party['daysToGo']}} days to go
                    </ion-label>
                  </ion-col>
                </ion-row>
              </ion-col>
              <ion-col size="6" class="txt-right">
                <ion-row>
                  <ion-col 
                    size="12" class="padding-none">
                    <ion-button fill="clear" class="padding-end-none btn-name label-note">
                    </ion-button>
                    <ion-button fill="outline" class="txt-transform-normal btn-party-credit remove-border">
                      Balance : <ion-label class="margin-inline-start-10px"
                        [color]="party.credit>=0 ? 'success' : 'danger'">{{
                          MATHABS(party.credit) | currency: 'INR' }}</ion-label>
                    </ion-button>
                  </ion-col>
    
                  <ion-col size="12" class="padding-none" >
                    <ion-col size="auto" offset="1">
                      <div *ngIf="party?.syncStamp > party?.updatedStamp; then thenBlock else elseBlock"></div>
                      <ng-template #thenBlock>
                        <ion-button class="btn-popover party-icon-success-warning" title="Data Uploaded" fill="clear">
                          <ion-icon name="checkmark-done-sharp" size="small" color="success">
                          </ion-icon>
                        </ion-button>
                      </ng-template>
                      <ng-template #elseBlock>
                        <ion-button class="btn-popover party-icon-success-warning" title="Warning" fill="clear">
                          <ion-icon name="warning-sharp" color="warning" size="small">
                          </ion-icon>
                        </ion-button>
                      </ng-template>
                    </ion-col>
                  </ion-col>

                </ion-row>
              </ion-col>
            </ion-row>
            <!-- End of Party List -->
          </ion-col>
        </ion-row>


      </ion-col>

    </ion-row>
  </ion-grid>

  <app-settings #settingsModal></app-settings>

</ion-content>

<ion-footer>
  <div class="bottom-sheet">

    <div class="btn-container">
      <ion-button fill="clear" expand="full" routerLink="/dashboard">
        <ion-row>
          <ion-col>
            <ion-icon name="home-outline"></ion-icon>
          </ion-col>
          <ion-col>
            <span>Dashboard</span>
          </ion-col>
        </ion-row>
      </ion-button>
    </div>
    <div class="btn-container">
      <ion-button fill="clear" expand="full" routerLink="/party">
        <ion-row>
          <ion-col>
            <ion-icon name="people-outline"></ion-icon>
          </ion-col>
          <ion-col>
            <span>Party</span>
          </ion-col>
        </ion-row>
      </ion-button>
    </div>
    <div class="btn-container btn-sale-invoice">
      <!-- For 3 sale credit use below code -->
      <!-- in ion-chip add [ngClass]="{'red-sale-invoice': isShowRedSaleButton}" -->
      <ion-chip class="chip-add-sale" fill="clear" expand="full" (click)="navigateToSale()">
        <span>
          <ion-icon name="add-outline"></ion-icon> SALE | INVOICE
          <!-- <span *ngIf="!isPremiumAccess">( {{saleCredits}} Credit )</span> -->
        </span>
      </ion-chip>
    </div>
    <div class="btn-container">
      <ion-button fill="clear" expand="full" routerLink="/item">
        <ion-row>
          <ion-col>
            <ion-icon name="cart-outline"></ion-icon>
          </ion-col>
          <ion-col>
            <span>Inventory</span>
          </ion-col>
        </ion-row>
      </ion-button>
    </div>
    <div class="btn-container">
      <ion-button fill="clear" expand="full" routerLink="/marketing">
        <ion-row>
          <ion-col>
            <ion-icon name="people"></ion-icon>
          </ion-col>
          <ion-col>
            <span>Marketing</span>
          </ion-col>
        </ion-row>
      </ion-button>
    </div>
    <div class="btn-container">
      <ion-button fill="clear" expand="full" (click)="settingsModal?.openSettingsModal()">
        <ion-row>
          <ion-col>
            <ion-icon name="settings-outline"></ion-icon>
          </ion-col>
          <ion-col>
            <span>Settings</span>
          </ion-col>
        </ion-row>
      </ion-button>
    </div>

  </div>
</ion-footer>

<!-- <app-new-feature-slides #newFeatureSlidesEle></app-new-feature-slides> -->