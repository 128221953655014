import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AllDataService } from '../../../services/all-data.service';
import { MoneyOut } from '../../../models/MoneyOut.model';
import { PinVerificationComponent } from '../../pin-verification/pin-verification.component';
import { AlertController, InfiniteScrollCustomEvent } from '@ionic/angular';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-money-out-bulk-delete',
  templateUrl: './money-out-bulk-delete.component.html',
  styleUrls: ['./money-out-bulk-delete.component.scss'],
})
export class MoneyOutBulkDeleteComponent implements OnInit {

  @Output() onMoneyOutDelete = new EventEmitter<MoneyOut>();
  @Output() onBulkMoneyOutDelete = new EventEmitter<boolean>();
  @ViewChild('pinVerificationElement') pinVerificationElement: PinVerificationComponent;
  @Input() fromStamp:number = null;
  @Input() toStamp: number = null;

  // Money Out
  isMoneyOutModalOpen = false;
  moneyOut: MoneyOut = null;
  moneyOutDeleteStarted = false;
  moneyOutDeleteCompleted = false;
  //----------------------------------------------------

    // Bulk Money Out
    isBulkMoneyOutModalOpen = false;
    checkAllMoneyOutCheckboxes: boolean = false;
    bulkMoneyOuts: MoneyOut[] = [];
    selectedBulkMoneyOuts: MoneyOut[] = [];
    bulkMoneyOutDeleteStarted = false;
    bulkMoneyOutDeleteCompleted = false;
    //----------------------------------------------------

    // Security Pin common functionality var
    selectedVar: string = '';
    bulkSelectArray: string[] = [];
    viewFilteredList:MoneyOut[] = [];
    // ---------------------------------


  constructor(
    private allDataService: AllDataService,
    private alertController: AlertController,
  ) { }

  ngOnInit() {}

  openTransactionPINModal() {
    this.pinVerificationElement?.openTransactionPINModal();
  }

  async verifyTransactionPIN(event) {
    try {
      if(event){
        if(this.selectedVar == 'MoneyOut'){
         this.openMoneyOutModal(true);
        }else if(this.selectedVar == 'BulkMoneyOut'){
          this.openBulkMoneyOutModal(true);
          await this.onDateRangeChange();
          //edit bulk sale
          this.bulkMoneyOuts?.forEach((el)=> {
            el["checked"] = false
          })
        }
      }else{
        this.openBulkMoneyOutModal(false);
        this.openMoneyOutModal(false);
      }
    } catch (error) {
      SentryUtilites.setLog("MoneyOutBulkDeleteComponent:verifyTransactionPIN", error)
    }
  }

  setCheckboxVarValue(variable: string, event: any) {
    this[variable] = event?.detail?.checked;
  }

  async deleteConfirm(): Promise<boolean> {
    return new Promise(async (resolve,reject) => {
      try {
        const alert = await this.alertController.create({
          mode: 'ios',
          header: 'Please Confirm!',
          message: 'Are you sure want to delete?',
          buttons: [
            {
              text: 'No',
              role: 'cancel',
              handler: () => {
                resolve(false);
                return true;
              },
            },
            {
              text: 'Yes',
              role: 'confirm',
              handler: () => {
                resolve(true);
                return true;
              },
            },
          ],
        });
        await alert.present();
      } catch (error) {
        SentryUtilites.setLog("MoneyOutBulkDeleteComponent:deleteConfirm", error)
        return resolve(false);
      }
    });
  }

    // Money Out

    async openMoneyOutModal(value: boolean = true) {
      try {
        if(value) {
          if ((await this.deleteConfirm()) === false) return;
        }
        this.isMoneyOutModalOpen = value;
        return;
      } catch (error) {
        SentryUtilites.setLog("MoneyOutBulkDeleteComponent:openMoneyOutModal", error)
      }
    }
  
    async initDeleteMoneyOut(moneyOut: MoneyOut) {
      try {
        this.moneyOut = moneyOut;
        this.selectedVar = 'MoneyOut';
        this.openTransactionPINModal();
      } catch (error) {
        SentryUtilites.setLog("MoneyOutBulkDeleteComponent:initDeleteMoneyOut", error)
      }
    }
  
    async deleteMoneyOut() {
      try {
        this.moneyOutDeleteStarted = true;
        let deletedMoneyOut = await this.allDataService.moneyOutService.delete(this.moneyOut);
        if (deletedMoneyOut?.deletedStamp) {
          this.moneyOut = deletedMoneyOut;
          this.allDataService.partyService.reloadList();
          this.allDataService.purchaseService.reloadList();
          this.allDataService.expenseService.reloadList();
          this.allDataService.saleReturnService.reloadList();
          this.allDataService.listForceReloadSubs.next('moneyout-list');
          this.moneyOutDeleteCompleted = true;
          setTimeout(() => {
            this.openMoneyOutModal(false);
            this.moneyOut = null;
            this.moneyOutDeleteStarted = false;
            this.moneyOutDeleteCompleted = false;
            setTimeout(() => {
              this.onMoneyOutDelete.emit(deletedMoneyOut);
            }, 1000);
          }, 500);
        }
      } catch (error) {
        SentryUtilites.setLog("MoneyOutBulkDeleteComponent:deleteMoneyOut", error)
      }
    }
  
    //--------------------------------------------------------------------------------

    // Bulk Money Out

    async openBulkMoneyOutModal(value: boolean = true) {
      try {
        if(value) {
          if ((await this.deleteConfirm()) === false) return;
        }
        this.isBulkMoneyOutModalOpen = value;
        return;
      } catch (error) {
        SentryUtilites.setLog("MoneyOutBulkDeleteComponent:openBulkMoneyOutModal", error)
      }
    }
  
    async initDeleteBulkMoneyOut() {
      try {
        this.selectedVar = 'BulkMoneyOut';
        if(!this.bulkSelectArray.includes('BulkMoneyOut')) {
          this.bulkSelectArray.push('BulkMoneyOut');
        }
        this.openTransactionPINModal();
      } catch (error) {
        SentryUtilites.setLog("MoneyOutBulkDeleteComponent:initDeleteBulkMoneyOut", error)
      }
    }
  
    addToSelectedBulkMoneyOutArr(moneyOut: MoneyOut,event: any) {
      try {
        const index = this.selectedBulkMoneyOuts?.findIndex(x => x?._localUUID === moneyOut?._localUUID);
        this.bulkMoneyOuts?.forEach(x => {
          if(x?._localUUID === moneyOut?._localUUID){
            x["checked"] = event?.detail?.checked;
          }
        });
        if(event?.detail?.checked) {
          if(index == -1){
            this.selectedBulkMoneyOuts.push(moneyOut);    
          }
        }else {
          if(index != -1) {
            this.selectedBulkMoneyOuts.splice(index,1);
          }
        }
      } catch (error) {
        SentryUtilites.setLog("MoneyOutBulkDeleteComponent:addToSelectedBulkMoneyOutArr", error)
      }
    }
  
    async deleteBulkMoneyOut() {
      try {
        this.bulkMoneyOutDeleteStarted = true;
        if(this.selectedBulkMoneyOuts?.length) {
          for (let i = 0; i < this.selectedBulkMoneyOuts.length; i++) {
            let moneyOut = this.selectedBulkMoneyOuts[i];
            await this.allDataService.moneyOutService.delete(moneyOut);
          }
        }
    
        this.allDataService.purchaseService.reloadList();
        this.allDataService.expenseService.reloadList();
        this.allDataService.partyService.reloadList();
        this.allDataService.saleReturnService.reloadList();
        this.allDataService.listForceReloadSubs.next('moneyout-list');
    
        this.bulkMoneyOutDeleteCompleted = true;
    
        setTimeout(() => {
          this.openBulkMoneyOutModal(false);
          this.bulkMoneyOuts = [];
          this.selectedBulkMoneyOuts = [];
          this.bulkMoneyOutDeleteStarted = false;
          this.bulkMoneyOutDeleteCompleted = false;
          setTimeout(() => {
            this.onBulkMoneyOutDelete.emit(true);
          }, 1000);
        }, 500);
      } catch (error) {
        SentryUtilites.setLog("MoneyOutBulkDeleteComponent:deleteBulkMoneyOut", error)
      }
    }
  
    //--------------------------------------------------------------------------------

    selectAllMoneyOutCheckboxes(event: CustomEventInit){
      try {
        if(this.checkAllMoneyOutCheckboxes) {
          if(event?.detail?.checked) {
            this.viewFilteredList?.forEach((el)=> {el["checked"] = true});
            this.selectedBulkMoneyOuts = [...this.viewFilteredList];
          } else {
            this.bulkMoneyOuts?.forEach((el)=> {el["checked"] = false});
            this.viewFilteredList?.forEach((el)=> {el["checked"] = false});
            this.selectedBulkMoneyOuts = [];
          }
        }
  
        this.checkAllMoneyOutCheckboxes = false;
      } catch (error) {
        SentryUtilites.setLog("MoneyOutBulkDeleteComponent:selectAllMoneyOutCheckboxes", error)
      }
    }

    selectAllMoneyOutCheckboxClick() {
      this.checkAllMoneyOutCheckboxes = true;
    }

    loadMoreListData(event) {
      try {
        if (this.bulkMoneyOuts.length > 0 && this.bulkMoneyOuts.length <= this.viewFilteredList.length) {
          let appendListLength = this.viewFilteredList.length - this.bulkMoneyOuts.length;
          let lastEl = this.bulkMoneyOuts[this.bulkMoneyOuts.length - 1];
          let counter = 0;
          for (let i = 0; i < this.viewFilteredList.length; i++) {
            if (this.viewFilteredList[i]._localUUID == lastEl._localUUID) {
              counter = 1;
              continue;
            }
            if (counter > 0 && appendListLength >= 52) {
              if (counter <= 52) {
                this.bulkMoneyOuts.push(this.viewFilteredList[i])
              } else {
                break;
              }
              counter++;
            } else if(counter > 0 && appendListLength < 52) {
              if (counter <= appendListLength) {
                this.bulkMoneyOuts.push(this.viewFilteredList[i])
              } else {
                break;
              }
              counter++;
            }
          }
          (event as InfiniteScrollCustomEvent).target.complete();
        }
      } catch (error) {
        SentryUtilites.setLog("MoneyOutBulkDeleteComponent:loadMoreListData", error)
      }
    }

    async onDateRangeChange(){
      try {
        this.viewFilteredList = await this.allDataService?.moneyOutService?.getByBillDateRange(this.fromStamp, this.toStamp) || [];
        this.viewFilteredList = this.viewFilteredList?.filter(x => !x?.deletedStamp);
        this.bulkMoneyOuts = this.viewFilteredList.slice(0,50);
      } catch (error) {
        SentryUtilites.setLog("MoneyOutBulkDeleteComponent:onDateRangeChange", error)
      }
    }
}
