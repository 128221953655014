export class IMonthWisePartyCredit {
    static SCHEMA_NAME = 'month-wise-party-credit';
}

export class MonthWisePartyCredit {
    _localId: number;
    partyLocalUUID: string;
    ledgerCredit: number;
    ledger: {
        [key: string]: number;
    };
}