import { MoneyInBillPrint } from './models/MoneyInBillPrint.model';
import { InvoicePrint } from "./models/print.model";
import { Gst1A5 } from "./templates/gst1-A5";
import { Gst2A5 } from "./templates/gst2-A5";
import { LetterHead } from "./templates/letter-head";
import { ModernA5 } from "./templates/modern-A5";
import { TallyGST } from "./templates/tallyGST";
import { TallyHSN } from "./templates/tallyHSN";
import { Temp1 } from "./templates/temp1";
import { Temp1 as Temp1V2 } from "./templatesV2/temp1";
import { Temp1MoneyIn as  Temp1MoneyInV2} from "./templatesV2/temp1-money-in";
import { Temp1MoneyOut as  Temp1MoneyOutV2} from "./templatesV2/temp1-money-out";
import { Temp1Purchase as Temp1PurchaseV2 } from "./templatesV2/temp1-purchase";
import { Temp1Expense as Temp1ExpenseV2 } from "./templatesV2/temp1-expense";
import { Temp1Estimate as  Temp1EstimateV2} from "./templatesV2/temp1-estimate";
import { Temp1PurchaseReturnV2 } from './templatesV2/temp1-purchase-return';
import { Temp17 as Temp17V2 } from "./templatesV2/temp17";
import { Temp17Estimate as Temp17EstimateV2 } from "./templatesV2/temp17-estimate";
import { Temp17MoneyIn as Temp17MoneyInV2 } from "./templatesV2/temp17-money-in";
import { Temp17MoneyOut as  Temp17MoneyOutV2} from "./templatesV2/temp17-money-out";
import { Temp17Purchase as Temp17PurchaseV2 } from "./templatesV2/temp17-purchase";
import { Temp17Expense as  Temp17ExpenseV2} from "./templatesV2/temp17-expense";
import {Temp17PurchaseReturnV2} from "./templatesV2/temp-17-purchase-return"
import { Temp18 as Temp18V2 } from "./templatesV2/temp18";
import { Temp18MoneyIn as  Temp18MoneyInV2} from "./templatesV2/temp18-money-in";
import { Temp18MoneyOut as Temp18MoneyOutV2 } from "./templatesV2/temp18-money-out";
import { Temp18Purchase as  Temp18PurchaseV2} from "./templatesV2/temp18-purchase";
import { Temp18Expense as  Temp18ExpenseV2} from "./templatesV2/temp18-expense";
import { Temp18Estimate as  Temp18EstimateV2} from "./templatesV2/temp18-estimate";
import { Temp18PurchaseReturnV2 } from './templatesV2/temp18-purchase-return';
import { Temp19 as Temp19V2 } from "./templatesV2/temp19";
import { Temp19MoneyIn as Temp19MoneyInV2 } from "./templatesV2/temp19-money-in";
import { Temp19MoneyOut as  Temp19MoneyOutV2} from "./templatesV2/temp19-money-out";
import { Temp19Purchase as  Temp19PurchaseV2} from "./templatesV2/temp19-purchase";
import { Temp19Expense as Temp19ExpenseV2 } from "./templatesV2/temp19-expense";
import { Temp19Estimate as  Temp19EstimateV2} from "./templatesV2/temp19-estimate";
import { Temp19PurchaseReturnV2 } from './templatesV2/temp19-purchase-return';
import { Temp20 as Temp20V2 } from "./templatesV2/temp20";
import { Temp20MoneyIn as  Temp20MoneyInV2} from "./templatesV2/temp20-money-in";
import { Temp20MoneyOut as Temp20MoneyOutV2} from "./templatesV2/temp20-money-out";
import { Temp20Purchase as Temp20PurchaseV2} from "./templatesV2/temp20-purchase";
import { Temp20Expense as  Temp20ExpenseV2} from "./templatesV2/temp20-expense";
import { Temp20Estimate as  Temp20EstimateV2} from "./templatesV2/temp20-estimate";
import { Temp20PurchaseReturnV2 } from './templatesV2/temp20-purchase-return';
import { Temp1SaleReturn as Temp1SaleReturnV2} from './templatesV2/temp1-sale-return';
import { Temp17SaleReturn as Temp17SaleReturnV2} from './templatesV2/temp17-sale-return';
import { Temp18SaleReturn as Temp18SaleReturnV2} from './templatesV2/temp18-sale-return';
import { Temp19SaleReturn as Temp19SaleReturnV2} from './templatesV2/temp19-sale-return';
import { Temp20SaleReturn as Temp20SaleReturnV2} from './templatesV2/temp20-sale-return';
import { Temp17 } from "./templates/temp17";
import { Temp2 } from "./templates/temp2";
import { Temp3 } from "./templates/temp3";
import { Temp18 } from "./templates/temp18";
import { BillPrint } from "./models/BillPrint.model";
import { SaleBillPrint } from "./models/SaleBillPrint.model";
import { PurchaseBillPrint } from "./models/PurchaseBillPrint.model";
import { ExpenseBillPrint } from "./models/ExpenseBillPrint.model";
import { MoneyOutBillPrint } from './models/MoneyOutBillPrint.model';
import { EstimateBillPrint } from "./models/EstimateBillPrint.model";
import { PurchaseReturnBillPrint } from './models/purchase-return2.model';
import { SaleReturnBillPrint } from './models/SaleReturnBillPrint.model';

export  class  HtmlTemplates{

  private static newLineToBr(inputString: string) {
    return (inputString?.length > 0) ? inputString.replace(/\n/g, '<br/>') : "";
  }
  
  public static async getTemplateHtml(data: InvoicePrint, invoicePref: any) {
    try {
  
      const templateObj = {
        temp6: (x) => new Temp1(x).main(),
        temp2: (x) => new Temp2(x).main(),
        temp3: (x) => new Temp3(x).main(),
        temp9: (x) => new ModernA5(x).main(),
        temp10: (x) => new Gst1A5(x).main(),
        temp11: (x) => new Gst2A5(x).main(),
        temp12: (x) => new TallyHSN(x).main(),
        temp13: (x) => new TallyGST(x).main(),
        temp14: (x) => new LetterHead(x).main(),
        temp17: (x) => new Temp17(x).main(),
        temp18: (x) => new Temp18(x).main(),
      };
      if (templateObj[invoicePref]) {
        return await templateObj[invoicePref](data);
      }
      return await templateObj.temp6(data);
    } catch (error) {}
  }

  public static generateBillObject(args:{
    user:{
      isPro:number,
      registrationStamp:number
    },
    profile:any,
    signature:any,
    logo:any,
    party:any,
    secondaryParty:any,
    sale:any,
    moneyIn:any,
    isPartyDeleted:boolean
  }){
    let billPrint=new BillPrint();
    billPrint.user = args.user;
    

    billPrint.profile = {
      addressProvience: args.profile?.addressProvience,
      legalName: args.profile?.legalName,
      addressLine1: (args.profile?.addressLine1 || '') + (args.profile?.addressLine2 || ''),
      addressPostalCode: args.profile?.addressPostalCode,
      contactPersonPhone: args.profile?.contactPersonPhone,
      contactPersonEmail: args.profile?.contactPersonEmail,
      gstin: args.profile?.gstin,
      fssaiNumber: args.profile?.fssaiNumber,
      licenseNumber: args.profile?.licenseNumber,
      bankAccountNo: args.profile?.bankAccountNo,
      bankName: args.profile?.bankName,
      bankIFSC: args.profile?.bankIFSC,
      bankAccountType: args.profile?.bankAccountType,
      bankUPI: args.profile?.bankUPI,
      additionalInputFieldTitle: args.profile?.additionalInputFieldTitle,
      additionalInputFieldValue: args.profile?.additionalInputFieldValue,
      additionalDateFieldValue: args.profile?.additionalDateFieldValue,
      additionalDateFieldTitle: args.profile?.additionalDateFieldTitle,
      signatureLink: args.signature?.imageBase64 || '',
      logoLink: args.profile?.pSetLogoPrintStatus ? args.logo?.imageBase64 || '' : '',
    };

    billPrint.bill = {
      partyData: {
        ledgerCredit: args.party?.credit,
        profileData: {
          legalName: args.party?.businessName,
          gstNumber: args.party?.gstin,
          contactPersonName: args.party?.name,
          contactPersonPhone: args.party?.phone,
          contactPersonEmail: args.party?.email,
          addressLine1: args.party?.billingAddress,
          addressState: args.party?.billingProvience,
          addressPincode: args.party?.billingPostalCode,
          addressOneLine1: args.party?.deliveryAddress,
          addressOneState: args.party?.deliveryProvience,
          addressOnePincode: args.party?.deliveryPostalCode,
          udf1T: '',
          udf1V: '',
          udf2T: '',
          udf2V: '',
          udf3T: '',
          udf3V: '',
          additionalInputFieldTitle: '',
          additionalInputFieldValue: '',
          additionalDateFieldValue: '',
          additionalDateFieldTitle: '',
        },
      },

      moneyInOut: {
        billNo: args.moneyIn?.billNo,
        dateStamp: args.moneyIn?.billDateStamp,
        amount: args.sale?.amountReceived || args.moneyIn?.totalAmount,
        txnMode: args.moneyIn?.paymentMode,
        txnRef: args.moneyIn?.paymentId,
      },

      billItems: args.sale.billItems?.map((billItem: any) => {
        return {
          
          itemDes: this.newLineToBr(billItem?.billNote || ''),
          itemSrl: '',
          hsn: billItem.item?.hsn || '',
          price: this.capFractionsToTwo(billItem.price),
          basePrice:this.capFractionsToTwo(billItem.basePrice),
          incTax: billItem.incTax,
          taxPercentage: this.capFractionsToTwo(billItem.taxPercentage),
          cessPercentage:this.capFractionsToTwo(billItem.cessPercentage),
          unit: billItem.unit,
          quantity: billItem.quantity,
          discountPercentage: this.capFractionsToTwo(billItem.discountPercentage),
          discount: this.capFractionsToTwo(billItem.discount),
          unitGstAmount: this.capFractionsToTwo(billItem.unitGstAmount),
          unitCessAmount: this.capFractionsToTwo(billItem.unitCessAmount),
          unitDiscountAmount: this.capFractionsToTwo(billItem.unitDiscountAmount),
          unitTaxAmount: this.capFractionsToTwo(billItem.unitTaxAmount),
          itemTotalGstAmount: this.capFractionsToTwo(billItem.itemTotalGstAmount),
          itemTotalCessAmount: this.capFractionsToTwo(billItem.itemTotalCessAmount),
          itemTotalTaxAmount: this.capFractionsToTwo(billItem.itemTotalTaxAmount),
          effectiveTaxPercentage: this.capFractionsToTwo(billItem.effectiveTaxPercentage),
          billCashDiscountPercentage: this.capFractionsToTwo(billItem.billCashDiscountPercentage),
          total: this.capFractionsToTwo(billItem.wcdTotal),
          createdStamp: billItem.item?.createdStamp,
          item: {
            itemName: billItem.item?.itemName,
            hsn: billItem.item?.hsn,
            mrp: billItem.item?.mrp,
            expiryDate: billItem.item?.expiryDate,
          },
          additionalInputFieldValue: '',
          additionalDateFieldValue: 0,
          additionalInputFieldTitle: '',
          additionalDateFieldTitle: '',
          mrp: billItem.item?.mrp || null,
          variant: '',
        };
      }),

      roundOffValue: this.capFractionsToTwo(args.sale.roundOffValue) || 0,
      billNo: args.sale.billNo,
      deliveryState: args.sale.party.deliveryProvience as any,
      billDateStamp: args.sale.billDateStamp,
      dueDateStamp: args.sale.dueDateStamp,
      purOrderNo: args.sale.transportDetail?.purOrderNo,
      eWayBillNo: args.sale.transportDetail?.eWayBillNo,
      additionalAmount: this.capFractionsToTwo(args.sale.transportDetail?.additionalAmount),
      discountAmount: this.capFractionsToTwo(args.sale.discountAmount),
      gstAmount: this.capFractionsToTwo(args.sale.gstAmount),
      cessAmount: this.capFractionsToTwo(args.sale.cessAmount),
      totalAmount: this.capFractionsToTwo(args.sale.totalAmount),
      createdStamp: args.sale.createdStamp,
      transporterName: args.sale.transportDetail?.transporterName,
      vehicleNumber: args.sale.transportDetail?.vehicleNumber,
      deliveryDate: args.sale.transportDetail?.deliveryDate,
      color: '#3498db',
      eWayBillDate: args.sale.transportDetail?.eWayBillDate,
      note: args.sale.note,
      billTitle: '',
      tnc: args.profile?.pSetTermsAndConditions || null,
      cashDiscount: this.capFractionsToTwo(args.sale.cashDiscount),
      amountReceived: this.capFractionsToTwo(args.sale.amountReceived),
      cashDiscountPercentage: this.capFractionsToTwo(args.sale.cashDiscountPercentage),
      challanNo: args.sale.transportDetail?.challanNo || null,
      totalSaving: this.capFractionsToTwo(args.sale.totalSaving),
      subTotalAmount: this.capFractionsToTwo(args.sale.subTotalAmount),
      partyPreviousBalance: this.capFractionsToTwo(args.sale.partyPreviousBalance) || null,
      billCompleteStamp: args.sale.billCompleteStamp,
      serviceChargePercentage: this.capFractionsToTwo(args.sale.serviceChargePercentage),
      serviceChargeAmount: this.capFractionsToTwo(args.sale.serviceChargeAmount),
      deliveryProvience: args?.sale?.deliveryProvience,
    };

    if(args.secondaryParty?._localUUID) {
      billPrint.bill.secondaryPartyData = {
        ledgerCredit: args.secondaryParty?.credit,
        profileData: {
          legalName: args.secondaryParty?.businessName,
          gstNumber: args.secondaryParty?.gstin,
          contactPersonName: args.secondaryParty?.name,
          contactPersonPhone: args.secondaryParty?.phone,
          contactPersonEmail: args.secondaryParty?.email,
          addressLine1: args.secondaryParty?.billingAddress,
          addressState: args.secondaryParty?.billingProvience,
          addressPincode: args.secondaryParty?.billingPostalCode,
          addressOneLine1: args.secondaryParty?.deliveryAddress,
          addressOneState: args.secondaryParty?.deliveryProvience,
          addressOnePincode: args.secondaryParty?.deliveryPostalCode,
          udf1T: '',
          udf1V: '',
          udf2T: '',
          udf2V: '',
          udf3T: '',
          udf3V: '',
          additionalInputFieldTitle: '',
          additionalInputFieldValue: '',
          additionalDateFieldValue: '',
          additionalDateFieldTitle: '',
        },
      };
    }

    // set moneyInOut

    billPrint.moneyInOut = null;

    // set bill type flag

    billPrint.isEstimate = false;
    billPrint.isPurchase = false;
    billPrint.isSaleReturn = false;
    billPrint.isPurchaseReturn = false;
    billPrint.isMoneyIn = false;
    billPrint.isMoneyOut = false;

    // set settings - TODO All

    billPrint.settings = {
      showSignatureNotRequiredText: false,
      showSignatureInTemplates: true,
      makeItemNameBold: false,
      showPreviousAmount: false,
      showTaxAndDiscAmt: args.profile?.pSetTaxMrpHsnPrintStatus,
      billSavingsAmount: true,
    };

    // set extra - TODO All

    billPrint.extra = {
      thermalTncTemplate: args.profile?.pSetTermsAndConditions || null,
    };

    billPrint.qrPayLink = '';
    billPrint.addressTo = '';
    billPrint.pageWidth = null;

    if(args.profile?.bankUPI && args.profile?.pSetQrPrintStatus) {
      let receivedAmount: number = 0;
      let balancedAmount: number = 0;
      args.sale?.moneyIns.forEach(moneyIn => receivedAmount += Number(moneyIn.totalAmount));
      balancedAmount = args.sale?.totalAmount - receivedAmount;
      if(args.profile?.pSetAlwaysQrPrintStatus || (Number(balancedAmount) || 0) > 0){
        if(balancedAmount == 0 ){
          balancedAmount = args.sale?.totalAmount
        }
        billPrint.qrPayLink = `upi://pay?pa=${args.profile?.bankUPI}&pn=${args.profile?.legalName}&am=${balancedAmount}&cu=INR&tn=${args.sale.billNo}`;
      }
    }

    return billPrint;
  }

  public static generateSaleBillObject(args:{
    user:{
      isPro:number,
      registrationStamp:number
    },
    profile:any,
    signature:any,
    logo:any,
    party:any,
    secondaryParty:any,
    sale:any,
    moneyIn:any,
    isPartyDeleted?:boolean
  }){
    let billPrint=new SaleBillPrint();
    billPrint.user = args.user;
    

    billPrint.profile = {
      addressProvience: args.profile?.addressProvience,
      legalName: args.profile?.legalName,
      addressLine1: (args.profile?.addressLine1 || '') + (args.profile?.addressLine2 || ''),
      addressCity:args.profile?.addressCity,
      addressPostalCode: args.profile?.addressPostalCode,
      contactPersonPhone: args.profile?.contactPersonPhone,
      contactPersonEmail: args.profile?.contactPersonEmail,
      gstin: args.profile?.gstin,
      fssaiNumber: args.profile?.fssaiNumber,
      licenseNumber: args.profile?.licenseNumber,
      bankAccountNo: args.profile?.bankAccountNo,
      bankName: args.profile?.bankName,
      bankIFSC: args.profile?.bankIFSC,
      bankAccountType: args.profile?.bankAccountType,
      bankUPI: args.profile?.bankUPI,
      additionalInputFieldTitle: args.profile?.additionalInputFieldTitle,
      additionalInputFieldValue: args.profile?.additionalInputFieldValue,
      additionalDateFieldValue: args.profile?.additionalDateFieldValue,
      additionalDateFieldTitle: args.profile?.additionalDateFieldTitle,
      signatureLink: args.signature?.imageBase64 || '',
      logoLink: args.profile?.pSetLogoPrintStatus ? args.logo?.imageBase64 || '' : '',
      additionalInputFieldTitle1: args?.profile?.additionalInputFieldTitle1,
      additionalInputFieldTitle2: args?.profile?.additionalInputFieldTitle2,
      additionalInputFieldTitle3: args?.profile?.additionalInputFieldTitle3,
      additionalInputFieldTitle4: args?.profile?.additionalInputFieldTitle4,
      additionalInputFieldTitle5: args?.profile?.additionalInputFieldTitle5,
      additionalInputFieldValue1: args?.profile?.additionalInputFieldValue1,
      additionalInputFieldValue2: args?.profile?.additionalInputFieldValue2,
      additionalInputFieldValue3: args?.profile?.additionalInputFieldValue3,
      additionalInputFieldValue4: args?.profile?.additionalInputFieldValue4,
      additionalInputFieldValue5: args?.profile?.additionalInputFieldValue5,
    };

    billPrint.bill = {
      partyData: {
        ledgerCredit: args.party?.credit,
        profileData: {
          legalName: args.party?.businessName,
          gstNumber: args.party?.gstin,
          contactPersonName: args.party?.name,
          contactPersonPhone: args.party?.phone,
          contactPersonEmail: args.party?.email,
          addressLine1: args.party?.billingAddress,
          addressState: args.party?.billingProvience,
          addressPincode: args.party?.billingPostalCode,
          addressOneLine1: args.party?.deliveryAddress,
          addressOneState: args.party?.deliveryProvience,
          addressOnePincode: args.party?.deliveryPostalCode,
          udf1T: '',
          udf1V: '',
          udf2T: '',
          udf2V: '',
          udf3T: '',
          udf3V: '',
          additionalInputFieldTitle: '',
          additionalInputFieldValue: '',
          additionalDateFieldValue: '',
          additionalDateFieldTitle: '',
        },
      },


      moneyIn: {
        billNo: args.moneyIn?.billNo,
        dateStamp: args.moneyIn?.billDateStamp,
        amount: args.sale?.amountReceived || args.moneyIn?.totalAmount,
        txnMode: args.moneyIn?.paymentMode,
        txnRef: args.moneyIn?.paymentId,
      },

      isPartyDeleted:args.isPartyDeleted,


      billItems: args.sale.billItems?.map((billItem: any) => {
        return {
          
          itemDes: this.newLineToBr(billItem?.billNote || ''),
          itemSrl: '',
          hsn: billItem.item?.hsn || '',
          price: this.capFractionsToThree(billItem.price),
          basePrice:this.capFractionsToTwo(billItem.basePrice),
          incTax: billItem.incTax,
          taxPercentage: this.capFractionsToTwo(billItem.taxPercentage),
          cessPercentage:this.capFractionsToTwo(billItem.cessPercentage),
          unit: billItem.unit,
          quantity: billItem.quantity,
          discountPercentage: this.capFractionsToTwo(billItem.discountPercentage),
          discount: this.capFractionsToTwo(billItem.discount),
          unitGstAmount: this.capFractionsToTwo(billItem.unitGstAmount),
          unitCessAmount: this.capFractionsToTwo(billItem.unitCessAmount),
          unitDiscountAmount: this.capFractionsToTwo(billItem.unitDiscountAmount),
          unitTaxAmount: this.capFractionsToTwo(billItem.unitTaxAmount),
          itemTotalGstAmount: this.capFractionsToTwo(billItem.itemTotalGstAmount),
          itemTotalCessAmount: this.capFractionsToTwo(billItem.itemTotalCessAmount),
          itemTotalTaxAmount: this.capFractionsToTwo(billItem.itemTotalTaxAmount),
          effectiveTaxPercentage: this.capFractionsToTwo(billItem.effectiveTaxPercentage),
          billCashDiscountPercentage: this.capFractionsToTwo(billItem.billCashDiscountPercentage),
          total: this.capFractionsToTwo(billItem.wcdTotal),
          createdStamp: billItem.item?.createdStamp,
          item: {
            itemName: billItem.item?.itemName,
            hsn: billItem.item?.hsn,
            mrp: billItem.item?.mrp,
            expiryDate: billItem.item?.expiryDate,
          },
          additionalInputFieldValue: '',
          additionalDateFieldValue: 0,
          additionalInputFieldTitle: '',
          additionalDateFieldTitle: '',
          mrp: billItem.item?.mrp || null,
          // mrp: this.capFractionsToTwo(billItem?.effectiveMrp),
          variant: '',
          subTotal: this.capFractionsToTwo(billItem.subTotal)
        };
      }),

      roundOffValue: this.capFractionsToTwo(args.sale.roundOffValue) || 0,
      billNo: args.sale.billNo,
      deliveryState: args.sale.party.deliveryProvience as any,
      billDateStamp: args.sale.billDateStamp,
      dueDateStamp: args.sale.dueDateStamp,
      purOrderNo: args.sale.transportDetail?.purOrderNo,
      eWayBillNo: args.sale.transportDetail?.eWayBillNo,
      additionalAmount: this.capFractionsToTwo(args.sale.transportDetail?.additionalAmount),
      discountAmount: this.capFractionsToTwo(args.sale.discountAmount),
      gstAmount: this.capFractionsToTwo(args.sale.gstAmount),
      cessAmount: this.capFractionsToTwo(args.sale.cessAmount),
      totalAmount: this.capFractionsToTwo(args.sale.totalAmount),
      createdStamp: args.sale.createdStamp,
      transporterName: args.sale.transportDetail?.transporterName,
      vehicleNumber: args.sale.transportDetail?.vehicleNumber,
      deliveryDate: args.sale.transportDetail?.deliveryDate,
      color: '#3498db',
      eWayBillDate: args.sale.transportDetail?.eWayBillDate,
      note: args.sale.note,
      billTitle: '',
      tnc: args.profile?.pSetTermsAndConditions || null,
      cashDiscount: this.capFractionsToTwo(args.sale.cashDiscount),
      amountReceived: this.capFractionsToTwo(args.sale.amountReceived),
      cashDiscountPercentage: this.capFractionsToTwo(args.sale.cashDiscountPercentage),
      challanNo: args.sale.transportDetail?.challanNo || null,
      totalSaving: this.capFractionsToTwo(args.sale.totalSaving),
      subTotalAmount: this.capFractionsToTwo(args.sale.subTotalAmount),
      partyPreviousBalance: this.capFractionsToTwo(args.sale.partyPreviousBalance) || null,
      billCompleteStamp: args.sale.billCompleteStamp,
      serviceChargePercentage: this.capFractionsToTwo(args.sale.serviceChargePercentage),
      serviceChargeAmount: this.capFractionsToTwo(args.sale.serviceChargeAmount),
      deliveryProvience: args?.sale?.deliveryProvience,
    };

    if(args.secondaryParty?._localUUID) {
      billPrint.bill.secondaryPartyData = {
        ledgerCredit: args.secondaryParty?.credit,
        profileData: {
          legalName: args.secondaryParty?.businessName,
          gstNumber: args.secondaryParty?.gstin,
          contactPersonName: args.secondaryParty?.name,
          contactPersonPhone: args.secondaryParty?.phone,
          contactPersonEmail: args.secondaryParty?.email,
          addressLine1: args.secondaryParty?.billingAddress,
          addressState: args.secondaryParty?.billingProvience,
          addressPincode: args.secondaryParty?.billingPostalCode,
          addressOneLine1: args.secondaryParty?.deliveryAddress,
          addressOneState: args.secondaryParty?.deliveryProvience,
          addressOnePincode: args.secondaryParty?.deliveryPostalCode,
          udf1T: '',
          udf1V: '',
          udf2T: '',
          udf2V: '',
          udf3T: '',
          udf3V: '',
          additionalInputFieldTitle: '',
          additionalInputFieldValue: '',
          additionalDateFieldValue: '',
          additionalDateFieldTitle: '',
        },
      };
    }

    // set settings - TODO All

    billPrint.settings = {
      showSignatureNotRequiredText: false,
      showSignatureInTemplates: true,
      makeItemNameBold: false,
      showPreviousAmount: args?.profile?.iSetAutoPreviousBalanceStatus,
      showTaxAndDiscAmt: args.profile?.pSetTaxMrpHsnPrintStatus,
      billSavingsAmount: true,
    };

    // set extra - TODO All

    billPrint.extra = {
      thermalTncTemplate: args.profile?.pSetTermsAndConditions || null,
    };

    billPrint.qrPayLink = '';
    billPrint.addressTo = '';
    billPrint.pageWidth = null;

    if(args.profile?.bankUPI && args.profile?.pSetQrPrintStatus) {
      let receivedAmount: number = 0;
      let balancedAmount: number = 0;
      args.sale?.moneyIns.forEach(moneyIn => receivedAmount += Number(moneyIn.totalAmount));
      balancedAmount = args.sale?.totalAmount - receivedAmount;
      if(args.profile?.pSetAlwaysQrPrintStatus || (Number(balancedAmount) || 0) > 0){
        if(balancedAmount == 0 ){
          balancedAmount = args.sale?.totalAmount
        }
        billPrint.qrPayLink = `upi://pay?pa=${args.profile?.bankUPI}&pn=${args.profile?.legalName}&am=${balancedAmount}&cu=INR&tn=${args.sale.billNo}`;
      }
    }

    return billPrint;
  }

  public static generatePurchaseBillObject(args:{
    user:{
      isPro:number,
      registrationStamp:number
    },
    profile:any,
    signature:any,
    logo:any,
    party:any,
    purchase:any,
    moneyOut:any,
    isPartyDeleted?:boolean
  }){
    let billPrint=new PurchaseBillPrint();
    billPrint.user = args.user;
    

    billPrint.profile = {
      addressProvience: args.profile?.addressProvience,
      legalName: args.profile?.legalName,
      addressLine1: (args.profile?.addressLine1 || '') + (args.profile?.addressLine2 || ''),
      addressCity:args.profile?.addressCity,
      addressPostalCode: args.profile?.addressPostalCode,
      contactPersonPhone: args.profile?.contactPersonPhone,
      contactPersonEmail: args.profile?.contactPersonEmail,
      gstin: args.profile?.gstin,
      fssaiNumber: args.profile?.fssaiNumber,
      licenseNumber: args.profile?.licenseNumber,
      bankAccountNo: args.profile?.bankAccountNo,
      bankName: args.profile?.bankName,
      bankIFSC: args.profile?.bankIFSC,
      bankAccountType: args.profile?.bankAccountType,
      bankUPI: args.profile?.bankUPI,
      additionalInputFieldTitle: args.profile?.additionalInputFieldTitle,
      additionalInputFieldValue: args.profile?.additionalInputFieldValue,
      additionalDateFieldValue: args.profile?.additionalDateFieldValue,
      additionalDateFieldTitle: args.profile?.additionalDateFieldTitle,
      signatureLink: args.signature?.imageBase64 || '',
      logoLink: args.profile?.pSetLogoPrintStatus ? args.logo?.imageBase64 || '' : '',
      additionalInputFieldTitle1: args?.profile?.additionalInputFieldTitle1,
      additionalInputFieldTitle2: args?.profile?.additionalInputFieldTitle2,
      additionalInputFieldTitle3: args?.profile?.additionalInputFieldTitle3,
      additionalInputFieldTitle4: args?.profile?.additionalInputFieldTitle4,
      additionalInputFieldTitle5: args?.profile?.additionalInputFieldTitle5,
      additionalInputFieldValue1: args?.profile?.additionalInputFieldValue1,
      additionalInputFieldValue2: args?.profile?.additionalInputFieldValue2,
      additionalInputFieldValue3: args?.profile?.additionalInputFieldValue3,
      additionalInputFieldValue4: args?.profile?.additionalInputFieldValue4,
      additionalInputFieldValue5: args?.profile?.additionalInputFieldValue5,
    };

    billPrint.bill = {
      partyData: {
        ledgerCredit: null,
        profileData: {
          legalName: args.party?.businessName,
          gstNumber: args.party?.gstin,
          contactPersonName: args.party?.name,
          contactPersonPhone: args.party?.phone,
          contactPersonEmail: args.party?.email,
          addressLine1: args.party?.billingAddress,
          addressState: args.party?.billingProvience,
          addressPincode: args.party?.billingPostalCode,
          addressOneLine1: args.party?.deliveryAddress,
          addressOneState: args.party?.deliveryProvience,
          addressOnePincode: args.party?.deliveryPostalCode,
          udf1T: '',
          udf1V: '',
          udf2T: '',
          udf2V: '',
          udf3T: '',
          udf3V: '',
          additionalInputFieldTitle: '',
          additionalInputFieldValue: '',
          additionalDateFieldValue: '',
          additionalDateFieldTitle: '',
        },
      },

      moneyOut: {
        billNo: args.moneyOut?.billNo,
        dateStamp: args.moneyOut?.billDateStamp,
        amount: args.purchase?.amountPaid || args.moneyOut?.totalAmount,
        txnMode: args.moneyOut?.paymentMode,
        txnRef: args.moneyOut?.paymentId,
      },

      billItems: args.purchase.billItems?.map((billItem: any) => {
        return {
          
          itemDes: this.newLineToBr(billItem?.billNote || ''),
          itemSrl: '',
          hsn: billItem.item?.hsn || '',
          price: this.capFractionsToThree(billItem.price),
          basePrice:this.capFractionsToTwo(billItem.basePrice),
          incTax: billItem.incTax,
          taxPercentage: this.capFractionsToTwo(billItem.taxPercentage),
          cessPercentage:this.capFractionsToTwo(billItem.cessPercentage),
          unit: billItem.unit,
          quantity: billItem.quantity,
          discountPercentage: this.capFractionsToTwo(billItem.discountPercentage),
          discount: this.capFractionsToTwo(billItem.discount),
          unitGstAmount: this.capFractionsToTwo(billItem.unitGstAmount),
          unitCessAmount: this.capFractionsToTwo(billItem.unitCessAmount),
          unitDiscountAmount: this.capFractionsToTwo(billItem.unitDiscountAmount),
          unitTaxAmount: this.capFractionsToTwo(billItem.unitTaxAmount),
          itemTotalGstAmount: this.capFractionsToTwo(billItem.itemTotalGstAmount),
          itemTotalCessAmount: this.capFractionsToTwo(billItem.itemTotalCessAmount),
          itemTotalTaxAmount: this.capFractionsToTwo(billItem.itemTotalTaxAmount),
          effectiveTaxPercentage: this.capFractionsToTwo(billItem.effectiveTaxPercentage),
          billCashDiscountPercentage: this.capFractionsToTwo(billItem.billCashDiscountPercentage),
          total: this.capFractionsToTwo(billItem.wcdTotal),
          createdStamp: billItem.item?.createdStamp,
          item: {
            itemName: billItem.item?.itemName,
            hsn: billItem.item?.hsn,
            mrp: billItem.item?.mrp,
            expiryDate: billItem.item?.expiryDate,
          },
          additionalInputFieldValue: '',
          additionalDateFieldValue: 0,
          additionalInputFieldTitle: '',
          additionalDateFieldTitle: '',
          mrp: billItem.item?.mrp || null,
          variant: '',
          subTotal: this.capFractionsToTwo(billItem.subTotal)
        };
      }),

      roundOffValue: this.capFractionsToTwo(args.purchase.roundOffValue) || 0,
      billNo: args.purchase.billNo,
      deliveryState: args.purchase.party.deliveryProvience as any,
      billDateStamp: args.purchase.billDateStamp,
      dueDateStamp: args.purchase.dueDateStamp,
      purOrderNo: args.purchase.transportDetail?.purOrderNo,
      eWayBillNo: args.purchase.transportDetail?.eWayBillNo,
      additionalAmount: this.capFractionsToTwo(args.purchase.transportDetail?.additionalAmount),
      discountAmount: this.capFractionsToTwo(args.purchase.discountAmount),
      gstAmount: this.capFractionsToTwo(args.purchase.gstAmount),
      cessAmount: this.capFractionsToTwo(args.purchase.cessAmount),
      totalAmount: this.capFractionsToTwo(args.purchase.totalAmount),
      createdStamp: args.purchase.createdStamp,
      transporterName: args.purchase.transportDetail?.transporterName,
      vehicleNumber: args.purchase.transportDetail?.vehicleNumber,
      deliveryDate: args.purchase.transportDetail?.deliveryDate,
      color: '#3498db',
      eWayBillDate: args.purchase.transportDetail?.eWayBillDate,
      note: args.purchase.note,
      billTitle: '',
      tnc: args.profile?.pSetTermsAndConditions || null,
      cashDiscount: this.capFractionsToTwo(args.purchase.cashDiscount),
      amountPaid: this.capFractionsToTwo(args.purchase.amountPaid),
      cashDiscountPercentage: this.capFractionsToTwo(args.purchase.cashDiscountPercentage),
      challanNo: args.purchase.transportDetail?.challanNo || null,
      totalSaving: this.capFractionsToTwo(args.purchase.totalSaving),
      subTotalAmount: this.capFractionsToTwo(args.purchase.subTotalAmount),
      partyPreviousBalance: null,
      billCompleteStamp: args.purchase.billCompleteStamp,
      serviceChargePercentage: this.capFractionsToTwo(args.purchase.serviceChargePercentage),
      serviceChargeAmount: this.capFractionsToTwo(args.purchase.serviceChargeAmount),
      deliveryProvience: args?.purchase?.deliveryProvience,
    };

    // set settings - TODO All

    billPrint.settings = {
      showSignatureNotRequiredText: false,
      showSignatureInTemplates: true,
      makeItemNameBold: false,
      showPreviousAmount: false,
      showTaxAndDiscAmt: args.profile?.pSetTaxMrpHsnPrintStatus,
      billSavingsAmount: true,
    };

    // set extra - TODO All

    billPrint.extra = {
      thermalTncTemplate: args.profile?.pSetTermsAndConditions || null,
    };

    billPrint.qrPayLink = '';
    billPrint.addressTo = '';
    billPrint.pageWidth = null;

    return billPrint;
  }

  public static generateMoneyInBillObject(args:{
    user:{
      isPro:number,
      registrationStamp:number
    },
    profile:any,
    signature:any,
    logo:any,
    party:any,
    moneyIn:any,
    isPartyDeleted?:boolean
  }){
    let billPrint=new MoneyInBillPrint();
    billPrint.user = args.user;

    billPrint.profile = {
      addressProvience: args.profile?.addressProvience,
      legalName: args.profile?.legalName,
      addressLine1: (args.profile?.addressLine1 || '') + (args.profile?.addressLine2 || ''),
      addressCity:args.profile?.addressCity,
      addressPostalCode: args.profile?.addressPostalCode,
      contactPersonPhone: args.profile?.contactPersonPhone,
      contactPersonEmail: args.profile?.contactPersonEmail,
      gstin: args.profile?.gstin,
      fssaiNumber: args.profile?.fssaiNumber,
      licenseNumber: args.profile?.licenseNumber,
      bankAccountNo: args.profile?.bankAccountNo,
      bankName: args.profile?.bankName,
      bankIFSC: args.profile?.bankIFSC,
      bankAccountType: args.profile?.bankAccountType,
      bankUPI: args.profile?.bankUPI,
      additionalInputFieldTitle: args.profile?.additionalInputFieldTitle,
      additionalInputFieldValue: args.profile?.additionalInputFieldValue,
      additionalDateFieldValue: args.profile?.additionalDateFieldValue,
      additionalDateFieldTitle: args.profile?.additionalDateFieldTitle,
      signatureLink: args.signature?.imageBase64 || '',
      logoLink: args.profile?.pSetLogoPrintStatus ? args.logo?.imageBase64 || '' : '',
      additionalInputFieldTitle1: args?.profile?.additionalInputFieldTitle1,
      additionalInputFieldTitle2: args?.profile?.additionalInputFieldTitle2,
      additionalInputFieldTitle3: args?.profile?.additionalInputFieldTitle3,
      additionalInputFieldTitle4: args?.profile?.additionalInputFieldTitle4,
      additionalInputFieldTitle5: args?.profile?.additionalInputFieldTitle5,
      additionalInputFieldValue1: args?.profile?.additionalInputFieldValue1,
      additionalInputFieldValue2: args?.profile?.additionalInputFieldValue2,
      additionalInputFieldValue3: args?.profile?.additionalInputFieldValue3,
      additionalInputFieldValue4: args?.profile?.additionalInputFieldValue4,
      additionalInputFieldValue5: args?.profile?.additionalInputFieldValue5,
    };

    billPrint.bill = {
      partyData: {
        ledgerCredit: args.party?.credit,
        profileData: {
          legalName: args.party?.businessName,
          gstNumber: args.party?.gstin,
          contactPersonName: args.party?.name,
          contactPersonPhone: args.party?.phone,
          contactPersonEmail: args.party?.email,
          addressLine1: args.party?.billingAddress,
          addressState: args.party?.billingProvience,
          addressPincode: args.party?.billingPostalCode,
          addressOneLine1: args.party?.deliveryAddress,
          addressOneState: args.party?.deliveryProvience,
          addressOnePincode: args.party?.deliveryPostalCode,
          udf1T: '',
          udf1V: '',
          udf2T: '',
          udf2V: '',
          udf3T: '',
          udf3V: '',
          additionalInputFieldTitle: '',
          additionalInputFieldValue: '',
          additionalDateFieldValue: '',
          additionalDateFieldTitle: '',
        },
      },

      txnMode: args.moneyIn?.paymentMode,
      txnRef: args.moneyIn?.paymentId,
      isPartyDeleted:args.isPartyDeleted,
      billNo: args.moneyIn.billNo,
      billDateStamp: args.moneyIn.billDateStamp,
      totalAmount: this.capFractionsToTwo(args.moneyIn.totalAmount),
      createdStamp: args.moneyIn.createdStamp,
      color: '#3498db',
      billTitle: '',
      tnc: args.profile?.pSetTermsAndConditions || null,
    };

    // set settings - TODO All

    billPrint.settings = {
      showSignatureNotRequiredText: false,
      showSignatureInTemplates: true,
    };

    // set extra - TODO All

    billPrint.extra = {
      thermalTncTemplate: args.profile?.pSetTermsAndConditions || null,
    };

    billPrint.qrPayLink = '';
    billPrint.addressTo = '';
    billPrint.pageWidth = null;


    return billPrint;
  }

  public static generateMoneyOutBillObject(args:{
    user:{
      isPro:number,
      registrationStamp:number
    },
    profile:any,
    signature:any,
    logo:any,
    party:any,
    moneyOut:any,
    isPartyDeleted?:boolean
  }){
    let billPrint=new MoneyOutBillPrint();
    billPrint.user = args.user;
    

    billPrint.profile = {
      addressProvience: args.profile?.addressProvience,
      legalName: args.profile?.legalName,
      addressLine1: (args.profile?.addressLine1 || '') + (args.profile?.addressLine2 || ''),
      addressCity:args.profile?.addressCity,
      addressPostalCode: args.profile?.addressPostalCode,
      contactPersonPhone: args.profile?.contactPersonPhone,
      contactPersonEmail: args.profile?.contactPersonEmail,
      gstin: args.profile?.gstin,
      fssaiNumber: args.profile?.fssaiNumber,
      licenseNumber: args.profile?.licenseNumber,
      bankAccountNo: args.profile?.bankAccountNo,
      bankName: args.profile?.bankName,
      bankIFSC: args.profile?.bankIFSC,
      bankAccountType: args.profile?.bankAccountType,
      bankUPI: args.profile?.bankUPI,
      additionalInputFieldTitle: args.profile?.additionalInputFieldTitle,
      additionalInputFieldValue: args.profile?.additionalInputFieldValue,
      additionalDateFieldValue: args.profile?.additionalDateFieldValue,
      additionalDateFieldTitle: args.profile?.additionalDateFieldTitle,
      signatureLink: args.signature?.imageBase64 || '',
      logoLink: args.profile?.pSetLogoPrintStatus ? args.logo?.imageBase64 || '' : '',
      additionalInputFieldTitle1: args?.profile?.additionalInputFieldTitle1,
      additionalInputFieldTitle2: args?.profile?.additionalInputFieldTitle2,
      additionalInputFieldTitle3: args?.profile?.additionalInputFieldTitle3,
      additionalInputFieldTitle4: args?.profile?.additionalInputFieldTitle4,
      additionalInputFieldTitle5: args?.profile?.additionalInputFieldTitle5,
      additionalInputFieldValue1: args?.profile?.additionalInputFieldValue1,
      additionalInputFieldValue2: args?.profile?.additionalInputFieldValue2,
      additionalInputFieldValue3: args?.profile?.additionalInputFieldValue3,
      additionalInputFieldValue4: args?.profile?.additionalInputFieldValue4,
      additionalInputFieldValue5: args?.profile?.additionalInputFieldValue5,
    };

    billPrint.bill = {
      partyData: {
        ledgerCredit: args.party?.credit,
        profileData: {
          legalName: args.party?.businessName,
          gstNumber: args.party?.gstin,
          contactPersonName: args.party?.name,
          contactPersonPhone: args.party?.phone,
          contactPersonEmail: args.party?.email,
          addressLine1: args.party?.billingAddress,
          addressState: args.party?.billingProvience,
          addressPincode: args.party?.billingPostalCode,
          addressOneLine1: args.party?.deliveryAddress,
          addressOneState: args.party?.deliveryProvience,
          addressOnePincode: args.party?.deliveryPostalCode,
          udf1T: '',
          udf1V: '',
          udf2T: '',
          udf2V: '',
          udf3T: '',
          udf3V: '',
          additionalInputFieldTitle: '',
          additionalInputFieldValue: '',
          additionalDateFieldValue: '',
          additionalDateFieldTitle: '',
        },
      },

      txnMode: args.moneyOut?.paymentMode,
      txnRef: args.moneyOut?.paymentId,
      isPartyDeleted:args.isPartyDeleted,
      billNo: args.moneyOut.billNo,
      billDateStamp: args.moneyOut.billDateStamp,
      totalAmount: this.capFractionsToTwo(args.moneyOut.totalAmount),
      createdStamp: args.moneyOut.createdStamp,
      color: '#3498db',
      billTitle: '',
      tnc: args.profile?.pSetTermsAndConditions || null,
    };

    billPrint.settings = {
      showSignatureNotRequiredText: false,
      showSignatureInTemplates: true,
    };

    // set extra - TODO All

    billPrint.extra = {
      thermalTncTemplate: args.profile?.pSetTermsAndConditions || null,
    };

    billPrint.qrPayLink = '';
    billPrint.addressTo = '';
    billPrint.pageWidth = null;

    return billPrint;
  }

  public static generateExpenseBillObject(args:{
    user:{
      isPro:number,
      registrationStamp:number
    },
    profile:any,
    signature:any,
    logo:any,
    party:any,
    expense:any,
    moneyOut:any,
    isPartyDeleted?:boolean
  }){
    let billPrint=new ExpenseBillPrint();
    billPrint.user = args.user;
    
    //Category
    billPrint.category = args.expense.category;

    billPrint.profile = {
      addressProvience: args.profile?.addressProvience,
      legalName: args.profile?.legalName,
      addressLine1: (args.profile?.addressLine1 || '') + (args.profile?.addressLine2 || ''),
      addressCity:args.profile?.addressCity,
      addressPostalCode: args.profile?.addressPostalCode,
      contactPersonPhone: args.profile?.contactPersonPhone,
      contactPersonEmail: args.profile?.contactPersonEmail,
      gstin: args.profile?.gstin,
      fssaiNumber: args.profile?.fssaiNumber,
      licenseNumber: args.profile?.licenseNumber,
      bankAccountNo: args.profile?.bankAccountNo,
      bankName: args.profile?.bankName,
      bankIFSC: args.profile?.bankIFSC,
      bankAccountType: args.profile?.bankAccountType,
      bankUPI: args.profile?.bankUPI,
      additionalInputFieldTitle: args.profile?.additionalInputFieldTitle,
      additionalInputFieldValue: args.profile?.additionalInputFieldValue,
      additionalDateFieldValue: args.profile?.additionalDateFieldValue,
      additionalDateFieldTitle: args.profile?.additionalDateFieldTitle,
      signatureLink: args.signature?.imageBase64 || '',
      logoLink: args.profile?.pSetLogoPrintStatus ? args.logo?.imageBase64 || '' : '',
      additionalInputFieldTitle1: args?.profile?.additionalInputFieldTitle1,
      additionalInputFieldTitle2: args?.profile?.additionalInputFieldTitle2,
      additionalInputFieldTitle3: args?.profile?.additionalInputFieldTitle3,
      additionalInputFieldTitle4: args?.profile?.additionalInputFieldTitle4,
      additionalInputFieldTitle5: args?.profile?.additionalInputFieldTitle5,
      additionalInputFieldValue1: args?.profile?.additionalInputFieldValue1,
      additionalInputFieldValue2: args?.profile?.additionalInputFieldValue2,
      additionalInputFieldValue3: args?.profile?.additionalInputFieldValue3,
      additionalInputFieldValue4: args?.profile?.additionalInputFieldValue4,
      additionalInputFieldValue5: args?.profile?.additionalInputFieldValue5,
    };

    billPrint.bill = {
      partyData: {
        ledgerCredit: args.party?.credit,
        profileData: {
          legalName: args.party?.businessName,
          gstNumber: args.party?.gstin,
          contactPersonName: args.party?.name,
          contactPersonPhone: args.party?.phone,
          contactPersonEmail: args.party?.email,
          addressLine1: args.party?.billingAddress,
          addressState: args.party?.billingProvience,
          addressPincode: args.party?.billingPostalCode,
          addressOneLine1: args.party?.deliveryAddress,
          addressOneState: args.party?.deliveryProvience,
          addressOnePincode: args.party?.deliveryPostalCode,
          udf1T: '',
          udf1V: '',
          udf2T: '',
          udf2V: '',
          udf3T: '',
          udf3V: '',
          additionalInputFieldTitle: '',
          additionalInputFieldValue: '',
          additionalDateFieldValue: '',
          additionalDateFieldTitle: '',
        },
      },

      isPartyDeleted:args.isPartyDeleted,
      
      moneyOut: {
        billNo: args.moneyOut?.billNo,
        dateStamp: args.moneyOut?.billDateStamp,
        amount: args.expense?.amountPaid || args.moneyOut?.totalAmount,
        txnMode: args.moneyOut?.paymentMode,
        txnRef: args.moneyOut?.paymentId,
      },

      billNo: args.expense.billNo,
      billDateStamp: args.expense.billDateStamp,
      dueDateStamp: args.expense.dueDateStamp,
      totalAmount: this.capFractionsToTwo(args.expense.totalAmount),
      createdStamp: args.expense.createdStamp,
      color: '#3498db',
      billTitle: '',
      note: args.expense.note,
      tnc: args.profile?.pSetTermsAndConditions || null,
      partyPreviousBalance: this.capFractionsToTwo(args.expense.partyPreviousBalance) || null,
      billCompleteStamp: args.expense.billCompleteStamp,
    };

    // set settings - TODO All

    billPrint.settings = {
      showSignatureNotRequiredText: false,
      showSignatureInTemplates: true,
    };

    // set extra - TODO All

    billPrint.extra = {
      thermalTncTemplate: args.profile?.pSetTermsAndConditions || null,
    };

    billPrint.qrPayLink = '';
    billPrint.addressTo = '';
    billPrint.pageWidth = null;

    return billPrint;
  }
  
  public static generateEstimateBillObject(args:{
    user:{
      isPro:number,
      registrationStamp:number
    },
    profile:any,
    signature:any,
    logo:any,
    party:any,
    secondaryParty:any,
    estimate:any,
    isPartyDeleted?:boolean
  }){
    let billPrint=new EstimateBillPrint();
    billPrint.user = args.user;
    

    billPrint.profile = {
      addressProvience: args.profile?.addressProvience,
      legalName: args.profile?.legalName,
      addressLine1: (args.profile?.addressLine1 || '') + (args.profile?.addressLine2 || ''),
      addressCity:args.profile?.addressCity,
      addressPostalCode: args.profile?.addressPostalCode,
      contactPersonPhone: args.profile?.contactPersonPhone,
      contactPersonEmail: args.profile?.contactPersonEmail,
      gstin: args.profile?.gstin,
      fssaiNumber: args.profile?.fssaiNumber,
      licenseNumber: args.profile?.licenseNumber,
      bankAccountNo: args.profile?.bankAccountNo,
      bankName: args.profile?.bankName,
      bankIFSC: args.profile?.bankIFSC,
      bankAccountType: args.profile?.bankAccountType,
      bankUPI: args.profile?.bankUPI,
      additionalInputFieldTitle: args.profile?.additionalInputFieldTitle,
      additionalInputFieldValue: args.profile?.additionalInputFieldValue,
      additionalDateFieldValue: args.profile?.additionalDateFieldValue,
      additionalDateFieldTitle: args.profile?.additionalDateFieldTitle,
      signatureLink: args.signature?.imageBase64 || '',
      logoLink: args.profile?.pSetLogoPrintStatus ? args.logo?.imageBase64 || '' : '',
      additionalInputFieldTitle1: args?.profile?.additionalInputFieldTitle1,
      additionalInputFieldTitle2: args?.profile?.additionalInputFieldTitle2,
      additionalInputFieldTitle3: args?.profile?.additionalInputFieldTitle3,
      additionalInputFieldTitle4: args?.profile?.additionalInputFieldTitle4,
      additionalInputFieldTitle5: args?.profile?.additionalInputFieldTitle5,
      additionalInputFieldValue1: args?.profile?.additionalInputFieldValue1,
      additionalInputFieldValue2: args?.profile?.additionalInputFieldValue2,
      additionalInputFieldValue3: args?.profile?.additionalInputFieldValue3,
      additionalInputFieldValue4: args?.profile?.additionalInputFieldValue4,
      additionalInputFieldValue5: args?.profile?.additionalInputFieldValue5,
    };

    billPrint.bill = {
      partyData: {
        ledgerCredit: args.party?.credit,
        profileData: {
          legalName: args.party?.businessName,
          gstNumber: args.party?.gstin,
          contactPersonName: args.party?.name,
          contactPersonPhone: args.party?.phone,
          contactPersonEmail: args.party?.email,
          addressLine1: args.party?.billingAddress,
          addressState: args.party?.billingProvience,
          addressPincode: args.party?.billingPostalCode,
          addressOneLine1: args.party?.deliveryAddress,
          addressOneState: args.party?.deliveryProvience,
          addressOnePincode: args.party?.deliveryPostalCode,
          udf1T: '',
          udf1V: '',
          udf2T: '',
          udf2V: '',
          udf3T: '',
          udf3V: '',
          additionalInputFieldTitle: '',
          additionalInputFieldValue: '',
          additionalDateFieldValue: '',
          additionalDateFieldTitle: '',
        },
      },

      isPartyDeleted:args.isPartyDeleted,

      billItems: args.estimate.billItems?.map((billItem: any) => {
        return {
          
          itemDes: this.newLineToBr(billItem?.billNote || ''),
          itemSrl: '',
          hsn: billItem.item?.hsn || '',
          price: this.capFractionsToThree(billItem.price),
          basePrice:this.capFractionsToTwo(billItem.basePrice),
          incTax: billItem.incTax,
          taxPercentage: this.capFractionsToTwo(billItem.taxPercentage),
          cessPercentage:this.capFractionsToTwo(billItem.cessPercentage),
          unit: billItem.unit,
          quantity: billItem.quantity,
          discountPercentage: this.capFractionsToTwo(billItem.discountPercentage),
          discount: this.capFractionsToTwo(billItem.discount),
          unitGstAmount: this.capFractionsToTwo(billItem.unitGstAmount),
          unitCessAmount: this.capFractionsToTwo(billItem.unitCessAmount),
          unitDiscountAmount: this.capFractionsToTwo(billItem.unitDiscountAmount),
          unitTaxAmount: this.capFractionsToTwo(billItem.unitTaxAmount),
          itemTotalGstAmount: this.capFractionsToTwo(billItem.itemTotalGstAmount),
          itemTotalCessAmount: this.capFractionsToTwo(billItem.itemTotalCessAmount),
          itemTotalTaxAmount: this.capFractionsToTwo(billItem.itemTotalTaxAmount),
          effectiveTaxPercentage: this.capFractionsToTwo(billItem.effectiveTaxPercentage),
          billCashDiscountPercentage: this.capFractionsToTwo(billItem.billCashDiscountPercentage),
          total: this.capFractionsToTwo(billItem.wcdTotal),
          createdStamp: billItem.item?.createdStamp,
          item: {
            itemName: billItem.item?.itemName,
            hsn: billItem.item?.hsn,
            mrp: billItem.item?.mrp,
            expiryDate: billItem.item?.expiryDate,
          },
          additionalInputFieldValue: '',
          additionalDateFieldValue: 0,
          additionalInputFieldTitle: '',
          additionalDateFieldTitle: '',
          mrp: billItem.item?.mrp || null,
          variant: '',
          subTotal: this.capFractionsToTwo(billItem.subTotal)
        };
      }),

      roundOffValue: this.capFractionsToTwo(args.estimate.roundOffValue) || 0,
      billNo: args.estimate.billNo,
      deliveryState: args.estimate.party.deliveryProvience as any,
      billDateStamp: args.estimate.billDateStamp,
      dueDateStamp: args.estimate.dueDateStamp,
      purOrderNo: args.estimate.transportDetail?.purOrderNo,
      eWayBillNo: args.estimate.transportDetail?.eWayBillNo,
      additionalAmount: this.capFractionsToTwo(args.estimate.transportDetail?.additionalAmount),
      discountAmount: this.capFractionsToTwo(args.estimate.discountAmount),
      gstAmount: this.capFractionsToTwo(args.estimate.gstAmount),
      cessAmount: this.capFractionsToTwo(args.estimate.cessAmount),
      totalAmount: this.capFractionsToTwo(args.estimate.totalAmount),
      createdStamp: args.estimate.createdStamp,
      transporterName: args.estimate.transportDetail?.transporterName,
      vehicleNumber: args.estimate.transportDetail?.vehicleNumber,
      deliveryDate: args.estimate.transportDetail?.deliveryDate,
      color: '#3498db',
      eWayBillDate: args.estimate.transportDetail?.eWayBillDate,
      note: args.estimate.note,
      billTitle: '',
      tnc: args.profile?.pSetTermsAndConditions || null,
      cashDiscount: this.capFractionsToTwo(args.estimate.cashDiscount),
      cashDiscountPercentage: this.capFractionsToTwo(args.estimate.cashDiscountPercentage),
      challanNo: args.estimate.transportDetail?.challanNo || null,
      totalSaving: this.capFractionsToTwo(args.estimate.totalSaving),
      subTotalAmount: this.capFractionsToTwo(args.estimate.subTotalAmount),
      partyPreviousBalance: this.capFractionsToTwo(args.estimate.partyPreviousBalance) || null,
      serviceChargePercentage: this.capFractionsToTwo(args.estimate.serviceChargePercentage),
      serviceChargeAmount: this.capFractionsToTwo(args.estimate.serviceChargeAmount),
      deliveryProvience: args?.estimate?.deliveryProvience,
    };

    if(args.secondaryParty?._localUUID) {
      billPrint.bill.secondaryPartyData = {
        ledgerCredit: args.secondaryParty?.credit,
        profileData: {
          legalName: args.secondaryParty?.businessName,
          gstNumber: args.secondaryParty?.gstin,
          contactPersonName: args.secondaryParty?.name,
          contactPersonPhone: args.secondaryParty?.phone,
          contactPersonEmail: args.secondaryParty?.email,
          addressLine1: args.secondaryParty?.billingAddress,
          addressState: args.secondaryParty?.billingProvience,
          addressPincode: args.secondaryParty?.billingPostalCode,
          addressOneLine1: args.secondaryParty?.deliveryAddress,
          addressOneState: args.secondaryParty?.deliveryProvience,
          addressOnePincode: args.secondaryParty?.deliveryPostalCode,
          udf1T: '',
          udf1V: '',
          udf2T: '',
          udf2V: '',
          udf3T: '',
          udf3V: '',
          additionalInputFieldTitle: '',
          additionalInputFieldValue: '',
          additionalDateFieldValue: '',
          additionalDateFieldTitle: '',
        },
      };
    }

    // set settings - TODO All

    billPrint.settings = {
      showSignatureNotRequiredText: false,
      showSignatureInTemplates: true,
      makeItemNameBold: false,
      showPreviousAmount: false,
      showTaxAndDiscAmt: args.profile?.pSetTaxMrpHsnPrintStatus,
      billSavingsAmount: true,
    };

    // set extra - TODO All

    billPrint.extra = {
      thermalTncTemplate: args.profile?.pSetTermsAndConditions || null,
    };

    billPrint.qrPayLink = '';
    billPrint.addressTo = '';
    billPrint.pageWidth = null;

    if(args.profile?.bankUPI && args.profile?.pSetQrPrintStatus) {
      let receivedAmount: number = 0;
      let balancedAmount: number = 0;
      balancedAmount = args.estimate?.totalAmount - receivedAmount;
      if(args.profile?.pSetAlwaysQrPrintStatus || (Number(balancedAmount) || 0) > 0){
        if(balancedAmount == 0 ){
          balancedAmount = args.estimate?.totalAmount
        }
        billPrint.qrPayLink = `upi://pay?pa=${args.profile?.bankUPI}&pn=${args.profile?.legalName}&am=${balancedAmount}&cu=INR&tn=${args.estimate.billNo}`;
      }
    }

    return billPrint;
  }

  public static generatePurchaseReturnBillObject(args:{
    user:{
      isPro:number,
      registrationStamp:number
    },
    profile:any,
    signature:any,
    logo:any,
    party:any,
    purchaseReturn:any,
    moneyIn:any,
    isPartyDeleted?:boolean
  }){
    let billPrint=new PurchaseReturnBillPrint();
    billPrint.user = args.user;
    

    billPrint.profile = {
      addressProvience: args.profile?.addressProvience,
      legalName: args.profile?.legalName,
      addressLine1: (args.profile?.addressLine1 || '') + (args.profile?.addressLine2 || ''),
      addressCity:args.profile?.addressCity,
      addressPostalCode: args.profile?.addressPostalCode,
      contactPersonPhone: args.profile?.contactPersonPhone,
      contactPersonEmail: args.profile?.contactPersonEmail,
      gstin: args.profile?.gstin,
      fssaiNumber: args.profile?.fssaiNumber,
      licenseNumber: args.profile?.licenseNumber,
      bankAccountNo: args.profile?.bankAccountNo,
      bankName: args.profile?.bankName,
      bankIFSC: args.profile?.bankIFSC,
      bankAccountType: args.profile?.bankAccountType,
      bankUPI: args.profile?.bankUPI,
      additionalInputFieldTitle: args.profile?.additionalInputFieldTitle,
      additionalInputFieldValue: args.profile?.additionalInputFieldValue,
      additionalDateFieldValue: args.profile?.additionalDateFieldValue,
      additionalDateFieldTitle: args.profile?.additionalDateFieldTitle,
      signatureLink: args.signature?.imageBase64 || '',
      logoLink: args.profile?.pSetLogoPrintStatus ? args.logo?.imageBase64 || '' : '',
      additionalInputFieldTitle1: args?.profile?.additionalInputFieldTitle1,
      additionalInputFieldTitle2: args?.profile?.additionalInputFieldTitle2,
      additionalInputFieldTitle3: args?.profile?.additionalInputFieldTitle3,
      additionalInputFieldTitle4: args?.profile?.additionalInputFieldTitle4,
      additionalInputFieldTitle5: args?.profile?.additionalInputFieldTitle5,
      additionalInputFieldValue1: args?.profile?.additionalInputFieldValue1,
      additionalInputFieldValue2: args?.profile?.additionalInputFieldValue2,
      additionalInputFieldValue3: args?.profile?.additionalInputFieldValue3,
      additionalInputFieldValue4: args?.profile?.additionalInputFieldValue4,
      additionalInputFieldValue5: args?.profile?.additionalInputFieldValue5,
    };

    billPrint.bill = {
      partyData: {
        ledgerCredit: null,
        profileData: {
          legalName: args.party?.businessName,
          gstNumber: args.party?.gstin,
          contactPersonName: args.party?.name,
          contactPersonPhone: args.party?.phone,
          contactPersonEmail: args.party?.email,
          addressLine1: args.party?.billingAddress,
          addressState: args.party?.billingProvience,
          addressPincode: args.party?.billingPostalCode,
          addressOneLine1: args.party?.deliveryAddress,
          addressOneState: args.party?.deliveryProvience,
          addressOnePincode: args.party?.deliveryPostalCode,
          udf1T: '',
          udf1V: '',
          udf2T: '',
          udf2V: '',
          udf3T: '',
          udf3V: '',
          additionalInputFieldTitle: '',
          additionalInputFieldValue: '',
          additionalDateFieldValue: '',
          additionalDateFieldTitle: '',
        },
      },

      moneyIn: {
        billNo: args.moneyIn?.billNo,
        dateStamp: args.moneyIn?.billDateStamp,
        amount: args.purchaseReturn?.amountPaid || args.moneyIn?.totalAmount,
        txnMode: args.moneyIn?.paymentMode,
        txnRef: args.moneyIn?.paymentId,
      },

      billItems: args.purchaseReturn.billItems?.map((billItem: any) => {
        return {
          
          itemDes: this.newLineToBr(billItem?.billNote || ''),
          itemSrl: '',
          hsn: billItem.item?.hsn || '',
          price: this.capFractionsToTwo(billItem.price),
          basePrice:this.capFractionsToTwo(billItem.basePrice),
          incTax: billItem.incTax,
          taxPercentage: this.capFractionsToTwo(billItem.taxPercentage),
          cessPercentage:this.capFractionsToTwo(billItem.cessPercentage),
          unit: billItem.unit,
          quantity: billItem.quantity,
          discountPercentage: this.capFractionsToTwo(billItem.discountPercentage),
          discount: this.capFractionsToTwo(billItem.discount),
          unitGstAmount: this.capFractionsToTwo(billItem.unitGstAmount),
          unitCessAmount: this.capFractionsToTwo(billItem.unitCessAmount),
          unitDiscountAmount: this.capFractionsToTwo(billItem.unitDiscountAmount),
          unitTaxAmount: this.capFractionsToTwo(billItem.unitTaxAmount),
          itemTotalGstAmount: this.capFractionsToTwo(billItem.itemTotalGstAmount),
          itemTotalCessAmount: this.capFractionsToTwo(billItem.itemTotalCessAmount),
          itemTotalTaxAmount: this.capFractionsToTwo(billItem.itemTotalTaxAmount),
          effectiveTaxPercentage: this.capFractionsToTwo(billItem.effectiveTaxPercentage),
          billCashDiscountPercentage: this.capFractionsToTwo(billItem.billCashDiscountPercentage),
          total: this.capFractionsToTwo(billItem.wcdTotal),
          createdStamp: billItem.item?.createdStamp,
          item: {
            itemName: billItem.item?.itemName,
            hsn: billItem.item?.hsn,
            mrp: billItem.item?.mrp,
            expiryDate: billItem.item?.expiryDate,
          },
          additionalInputFieldValue: '',
          additionalDateFieldValue: 0,
          additionalInputFieldTitle: '',
          additionalDateFieldTitle: '',
          mrp: billItem.item?.mrp || null,
          variant: '',
          subTotal: this.capFractionsToTwo(billItem.subTotal)
        };
      }),

      roundOffValue: this.capFractionsToTwo(args.purchaseReturn.roundOffValue) || 0,
      billNo: args.purchaseReturn.billNo,
      deliveryState: args.purchaseReturn.party.deliveryProvience as any,
      billDateStamp: args.purchaseReturn.billDateStamp,
      dueDateStamp: args.purchaseReturn.dueDateStamp,
      purOrderNo: args.purchaseReturn.transportDetail?.purOrderNo,
      eWayBillNo: args.purchaseReturn.transportDetail?.eWayBillNo,
      additionalAmount: this.capFractionsToTwo(args.purchaseReturn.transportDetail?.additionalAmount),
      discountAmount: this.capFractionsToTwo(args.purchaseReturn.discountAmount),
      gstAmount: this.capFractionsToTwo(args.purchaseReturn.gstAmount),
      cessAmount: this.capFractionsToTwo(args.purchaseReturn.cessAmount),
      totalAmount: this.capFractionsToTwo(args.purchaseReturn.totalAmount),
      createdStamp: args.purchaseReturn.createdStamp,
      transporterName: args.purchaseReturn.transportDetail?.transporterName,
      vehicleNumber: args.purchaseReturn.transportDetail?.vehicleNumber,
      deliveryDate: args.purchaseReturn.transportDetail?.deliveryDate,
      color: '#3498db',
      eWayBillDate: args.purchaseReturn.transportDetail?.eWayBillDate,
      note: args.purchaseReturn.note,
      billTitle: '',
      tnc: args.profile?.pSetTermsAndConditions || null,
      cashDiscount: this.capFractionsToTwo(args.purchaseReturn.cashDiscount),
      amountPaid: this.capFractionsToTwo(args.purchaseReturn.amountPaid),
      cashDiscountPercentage: this.capFractionsToTwo(args.purchaseReturn.cashDiscountPercentage),
      challanNo: args.purchaseReturn.transportDetail?.challanNo || null,
      totalSaving: this.capFractionsToTwo(args.purchaseReturn.totalSaving),
      subTotalAmount: this.capFractionsToTwo(args.purchaseReturn.subTotalAmount),
      partyPreviousBalance: null,
      billCompleteStamp: args.purchaseReturn.billCompleteStamp,
      serviceChargePercentage: this.capFractionsToTwo(args.purchaseReturn.serviceChargePercentage),
      serviceChargeAmount: this.capFractionsToTwo(args.purchaseReturn.serviceChargeAmount),
      deliveryProvience: args?.purchaseReturn?.deliveryProvience,
      purchaseBillNo: args?.purchaseReturn?.purchaseBillNo,
      purchaseBillDateStamp: args?.purchaseReturn?.purchaseBillDateStamp,
      purchaseCreatedStamp: args?.purchaseReturn?.purchaseCreatedStamp,
    };

    // set settings - TODO All

    billPrint.settings = {
      showSignatureNotRequiredText: false,
      showSignatureInTemplates: true,
      makeItemNameBold: false,
      showPreviousAmount: false,
      showTaxAndDiscAmt: args.profile?.pSetTaxMrpHsnPrintStatus,
      billSavingsAmount: true,
    };

    // set extra - TODO All

    billPrint.extra = {
      thermalTncTemplate: args.profile?.pSetTermsAndConditions || null,
    };

    billPrint.qrPayLink = '';
    billPrint.addressTo = '';
    billPrint.pageWidth = null;

    return billPrint;
  }

  public static generateSaleReturnBillObject(args:{
    user:{
      isPro:number,
      registrationStamp:number
    },
    profile:any,
    signature:any,
    logo:any,
    party:any,
    secondaryParty:any,
    saleReturn:any,
    moneyOut:any,
    isPartyDeleted?:boolean
  }){
    let billPrint=new SaleReturnBillPrint();
    billPrint.user = args.user;
    

    billPrint.profile = {
      addressProvience: args.profile?.addressProvience,
      legalName: args.profile?.legalName,
      addressLine1: (args.profile?.addressLine1 || '') + (args.profile?.addressLine2 || ''),
      addressCity:args.profile?.addressCity,
      addressPostalCode: args.profile?.addressPostalCode,
      contactPersonPhone: args.profile?.contactPersonPhone,
      contactPersonEmail: args.profile?.contactPersonEmail,
      gstin: args.profile?.gstin,
      fssaiNumber: args.profile?.fssaiNumber,
      licenseNumber: args.profile?.licenseNumber,
      bankAccountNo: args.profile?.bankAccountNo,
      bankName: args.profile?.bankName,
      bankIFSC: args.profile?.bankIFSC,
      bankAccountType: args.profile?.bankAccountType,
      bankUPI: args.profile?.bankUPI,
      additionalInputFieldTitle: args.profile?.additionalInputFieldTitle,
      additionalInputFieldValue: args.profile?.additionalInputFieldValue,
      additionalDateFieldValue: args.profile?.additionalDateFieldValue,
      additionalDateFieldTitle: args.profile?.additionalDateFieldTitle,
      signatureLink: args.signature?.imageBase64 || '',
      logoLink: args.profile?.pSetLogoPrintStatus ? args.logo?.imageBase64 || '' : '',
      additionalInputFieldTitle1: args?.profile?.additionalInputFieldTitle1,
      additionalInputFieldTitle2: args?.profile?.additionalInputFieldTitle2,
      additionalInputFieldTitle3: args?.profile?.additionalInputFieldTitle3,
      additionalInputFieldTitle4: args?.profile?.additionalInputFieldTitle4,
      additionalInputFieldTitle5: args?.profile?.additionalInputFieldTitle5,
      additionalInputFieldValue1: args?.profile?.additionalInputFieldValue1,
      additionalInputFieldValue2: args?.profile?.additionalInputFieldValue2,
      additionalInputFieldValue3: args?.profile?.additionalInputFieldValue3,
      additionalInputFieldValue4: args?.profile?.additionalInputFieldValue4,
      additionalInputFieldValue5: args?.profile?.additionalInputFieldValue5,
    };

    billPrint.bill = {
      partyData: {
        ledgerCredit: args.party?.credit,
        profileData: {
          legalName: args.party?.businessName,
          gstNumber: args.party?.gstin,
          contactPersonName: args.party?.name,
          contactPersonPhone: args.party?.phone,
          contactPersonEmail: args.party?.email,
          addressLine1: args.party?.billingAddress,
          addressState: args.party?.billingProvience,
          addressPincode: args.party?.billingPostalCode,
          addressOneLine1: args.party?.deliveryAddress,
          addressOneState: args.party?.deliveryProvience,
          addressOnePincode: args.party?.deliveryPostalCode,
          udf1T: '',
          udf1V: '',
          udf2T: '',
          udf2V: '',
          udf3T: '',
          udf3V: '',
          additionalInputFieldTitle: '',
          additionalInputFieldValue: '',
          additionalDateFieldValue: '',
          additionalDateFieldTitle: '',
        },
      },


      moneyOut: {
        billNo: args.moneyOut?.billNo,
        dateStamp: args.moneyOut?.billDateStamp,
        amount: args.saleReturn?.amountReceived || args.moneyOut?.totalAmount,
        txnMode: args.moneyOut?.paymentMode,
        txnRef: args.moneyOut?.paymentId,
      },

      isPartyDeleted:args.isPartyDeleted,


      billItems: args.saleReturn.billItems?.map((billItem: any) => {
        return {
          
          itemDes: this.newLineToBr(billItem?.billNote || ''),
          itemSrl: '',
          hsn: billItem.item?.hsn || '',
          price: this.capFractionsToThree(billItem.price),
          basePrice:this.capFractionsToTwo(billItem.basePrice),
          incTax: billItem.incTax,
          taxPercentage: this.capFractionsToTwo(billItem.taxPercentage),
          cessPercentage:this.capFractionsToTwo(billItem.cessPercentage),
          unit: billItem.unit,
          quantity: billItem.quantity,
          discountPercentage: this.capFractionsToTwo(billItem.discountPercentage),
          discount: this.capFractionsToTwo(billItem.discount),
          unitGstAmount: this.capFractionsToTwo(billItem.unitGstAmount),
          unitCessAmount: this.capFractionsToTwo(billItem.unitCessAmount),
          unitDiscountAmount: this.capFractionsToTwo(billItem.unitDiscountAmount),
          unitTaxAmount: this.capFractionsToTwo(billItem.unitTaxAmount),
          itemTotalGstAmount: this.capFractionsToTwo(billItem.itemTotalGstAmount),
          itemTotalCessAmount: this.capFractionsToTwo(billItem.itemTotalCessAmount),
          itemTotalTaxAmount: this.capFractionsToTwo(billItem.itemTotalTaxAmount),
          effectiveTaxPercentage: this.capFractionsToTwo(billItem.effectiveTaxPercentage),
          billCashDiscountPercentage: this.capFractionsToTwo(billItem.billCashDiscountPercentage),
          total: this.capFractionsToTwo(billItem.wcdTotal),
          createdStamp: billItem.item?.createdStamp,
          item: {
            itemName: billItem.item?.itemName,
            hsn: billItem.item?.hsn,
            mrp: billItem.item?.mrp,
            expiryDate: billItem.item?.expiryDate,
          },
          additionalInputFieldValue: '',
          additionalDateFieldValue: 0,
          additionalInputFieldTitle: '',
          additionalDateFieldTitle: '',
          mrp: billItem.item?.mrp || null,
          // mrp: this.capFractionsToTwo(billItem?.effectiveMrp),
          variant: '',
        };
      }),

      roundOffValue: this.capFractionsToTwo(args.saleReturn.roundOffValue) || 0,
      billNo: args.saleReturn.billNo,
      deliveryState: args.saleReturn.party.deliveryProvience as any,
      billDateStamp: args.saleReturn.billDateStamp,
      dueDateStamp: args.saleReturn.dueDateStamp,
      purOrderNo: args.saleReturn.transportDetail?.purOrderNo,
      eWayBillNo: args.saleReturn.transportDetail?.eWayBillNo,
      additionalAmount: this.capFractionsToTwo(args.saleReturn.transportDetail?.additionalAmount),
      discountAmount: this.capFractionsToTwo(args.saleReturn.discountAmount),
      gstAmount: this.capFractionsToTwo(args.saleReturn.gstAmount),
      cessAmount: this.capFractionsToTwo(args.saleReturn.cessAmount),
      totalAmount: this.capFractionsToTwo(args.saleReturn.totalAmount),
      createdStamp: args.saleReturn.createdStamp,
      transporterName: args.saleReturn.transportDetail?.transporterName,
      vehicleNumber: args.saleReturn.transportDetail?.vehicleNumber,
      deliveryDate: args.saleReturn.transportDetail?.deliveryDate,
      color: '#3498db',
      eWayBillDate: args.saleReturn.transportDetail?.eWayBillDate,
      note: args.saleReturn.note,
      billTitle: '',
      tnc: args.profile?.pSetTermsAndConditions || null,
      cashDiscount: this.capFractionsToTwo(args.saleReturn.cashDiscount),
      amountReceived: this.capFractionsToTwo(args.saleReturn.amountReceived),
      cashDiscountPercentage: this.capFractionsToTwo(args.saleReturn.cashDiscountPercentage),
      challanNo: args.saleReturn.transportDetail?.challanNo || null,
      totalSaving: this.capFractionsToTwo(args.saleReturn.totalSaving),
      subTotalAmount: this.capFractionsToTwo(args.saleReturn.subTotalAmount),
      partyPreviousBalance: this.capFractionsToTwo(args.saleReturn.partyPreviousBalance) || null,
      billCompleteStamp: args.saleReturn.billCompleteStamp,
      serviceChargePercentage: this.capFractionsToTwo(args.saleReturn.serviceChargePercentage),
      serviceChargeAmount: this.capFractionsToTwo(args.saleReturn.serviceChargeAmount),
      deliveryProvience: args?.saleReturn?.deliveryProvience,
      saleBillDateStamp: args?.saleReturn?.saleBillDateStamp,
      saleBillNo: args?.saleReturn?.saleBillNo,
      saleCreatedStamp: args?.saleReturn?.saleCreatedStamp,
    };

    if(args.secondaryParty?._localUUID) {
      billPrint.bill.secondaryPartyData = {
        ledgerCredit: args.secondaryParty?.credit,
        profileData: {
          legalName: args.secondaryParty?.businessName,
          gstNumber: args.secondaryParty?.gstin,
          contactPersonName: args.secondaryParty?.name,
          contactPersonPhone: args.secondaryParty?.phone,
          contactPersonEmail: args.secondaryParty?.email,
          addressLine1: args.secondaryParty?.billingAddress,
          addressState: args.secondaryParty?.billingProvience,
          addressPincode: args.secondaryParty?.billingPostalCode,
          addressOneLine1: args.secondaryParty?.deliveryAddress,
          addressOneState: args.secondaryParty?.deliveryProvience,
          addressOnePincode: args.secondaryParty?.deliveryPostalCode,
          udf1T: '',
          udf1V: '',
          udf2T: '',
          udf2V: '',
          udf3T: '',
          udf3V: '',
          additionalInputFieldTitle: '',
          additionalInputFieldValue: '',
          additionalDateFieldValue: '',
          additionalDateFieldTitle: '',
        },
      };
    }

    // set settings - TODO All

    billPrint.settings = {
      showSignatureNotRequiredText: false,
      showSignatureInTemplates: true,
      makeItemNameBold: false,
      showPreviousAmount: args?.profile?.iSetAutoPreviousBalanceStatus,
      showTaxAndDiscAmt: args.profile?.pSetTaxMrpHsnPrintStatus,
      billSavingsAmount: true,
    };

    // set extra - TODO All

    billPrint.extra = {
      thermalTncTemplate: args.profile?.pSetTermsAndConditions || null,
    };

    billPrint.qrPayLink = '';
    billPrint.addressTo = '';
    billPrint.pageWidth = null;

    if(args.profile?.bankUPI && args.profile?.pSetQrPrintStatus) {
      let receivedAmount: number = 0;
      let balancedAmount: number = 0;
      args.saleReturn?.moneyOuts.forEach(moneyOut => receivedAmount += Number(moneyOut.totalAmount));
      balancedAmount = args.saleReturn?.totalAmount - receivedAmount;
      if(args.profile?.pSetAlwaysQrPrintStatus || (Number(balancedAmount) || 0) > 0){
        if(balancedAmount == 0 ){
          balancedAmount = args.saleReturn?.totalAmount
        }
        billPrint.qrPayLink = `upi://pay?pa=${args.profile?.bankUPI}&pn=${args.profile?.legalName}&am=${balancedAmount}&cu=INR&tn=${args.saleReturn.billNo}`;
      }
    }

    return billPrint;
  }

  public static async getBillTemplateHtml(data: BillPrint | EstimateBillPrint | SaleBillPrint | PurchaseBillPrint | PurchaseReturnBillPrint | ExpenseBillPrint | MoneyInBillPrint | MoneyOutBillPrint | SaleReturnBillPrint , billPref: any) {
    try {
  
      const templateObj = {
        temp1MoneyIn: (x) => new Temp1MoneyInV2(x).main(),
        temp1MoneyOut: (x) => new Temp1MoneyOutV2(x).main(),
        temp1Purchase:(x) => new Temp1PurchaseV2(x).main(),
        temp1Expense:(x) => new Temp1ExpenseV2(x).main(),
        temp1Estimate:(x) => new Temp1EstimateV2(x).main(),
        temp1PurchaseReturn: (x) => new Temp1PurchaseReturnV2(x).main(),
        temp6: (x) => new Temp1V2(x).main(),
        temp2: (x) => new Temp2(x).main(),
        temp3: (x) => new Temp3(x).main(),
        temp9: (x) => new ModernA5(x).main(),
        temp10: (x) => new Gst1A5(x).main(),
        temp11: (x) => new Gst2A5(x).main(),
        temp12: (x) => new TallyHSN(x).main(),
        temp13: (x) => new TallyGST(x).main(),
        temp14: (x) => new LetterHead(x).main(),
        temp17: (x) => new Temp17V2(x).main(),
        temp17MoneyIn: (x) => new Temp17MoneyInV2(x).main(),
        temp17MoneyOut: (x) => new Temp17MoneyOutV2(x).main(),
        temp17Purchase:(x) => new Temp17PurchaseV2(x).main(),
        temp17Expense:(x) => new Temp17ExpenseV2(x).main(),
        temp17Estimate:(x) => new Temp17EstimateV2(x).main(),
        temp17PurchaseReturn: (x) => new Temp17PurchaseReturnV2(x).main(),
        temp18: (x) => new Temp18V2(x).main(),
        temp18MoneyIn: (x) => new Temp18MoneyInV2(x).main(),
        temp18MoneyOut: (x) => new Temp18MoneyOutV2(x).main(),
        temp18Purchase:(x) => new Temp18PurchaseV2(x).main(),
        temp18Expense:(x) => new Temp18ExpenseV2(x).main(),
        temp18Estimate:(x) => new Temp18EstimateV2(x).main(),
        temp18PurchaseReturn:(x) => new Temp18PurchaseReturnV2(x).main(),
        temp19: (x) => new Temp19V2(x).main(),
        temp19MoneyIn: (x) => new Temp19MoneyInV2(x).main(),
        temp19MoneyOut: (x) => new Temp19MoneyOutV2(x).main(),
        temp19Purchase:(x) => new Temp19PurchaseV2(x).main(),
        temp19Expense:(x) => new Temp19ExpenseV2(x).main(),
        Temp19Estimate:(x) => new Temp19EstimateV2(x).main(),
        temp19PurchaseReturn:(x) => new Temp19PurchaseReturnV2(x).main(),
        temp20: (x) => new Temp20V2(x).main(),
        temp20MoneyIn: (x) => new Temp20MoneyInV2(x).main(),
        temp20MoneyOut: (x) => new Temp20MoneyOutV2(x).main(),
        temp20Purchase:(x) => new Temp20PurchaseV2(x).main(),
        temp20Expense:(x) => new Temp20ExpenseV2(x).main(),
        temp20Estimate:(x) => new Temp20EstimateV2(x).main(),
        temp20PurchaseReturn: (x) => new Temp20PurchaseReturnV2(x).main(),
        //Sale Return
        temp1SaleReturn: (x) => new Temp1SaleReturnV2(x).main(),
        temp17SaleReturn: (x) => new Temp17SaleReturnV2(x).main(),
        temp18SaleReturn: (x) => new Temp18SaleReturnV2(x).main(),
        temp19SaleReturn: (x) => new Temp19SaleReturnV2(x).main(),
        temp20SaleReturn: (x) => new Temp20SaleReturnV2(x).main(),

      };
      if (templateObj[billPref]) {
        try {
          return await templateObj[billPref](data);
        } catch (error) {
          console.error("catch error => ", error)
        }
      }
      return await templateObj.temp6(data);
    } catch (error) {}
  }


  public static capFractionsToTwo(fractionNumber){
    return Math.round((Number(fractionNumber)||0)*100)/100;
  }

  public static capFractionsToThree(fractionNumber) {
    return Math.round((Number(fractionNumber) || 0) * 1000) / 1000;
  }

}

