import { Component, OnInit } from '@angular/core';
import { Utility } from '../../utils/utility';
import { Order, OrderStatus } from '../../models/Order.model';
import { OrderService } from 'src/app/services/order.service';
import { InfiniteScrollCustomEvent } from '@ionic/angular';
import { Router } from '@angular/router';
import { AccessControlService } from 'src/app/services/auth/access-control.service';
import { AllDataService } from 'src/app/services/all-data.service';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-order',
  templateUrl: './order.page.html',
  styleUrls: ['./order.page.scss'],
})
export class OrderPage implements OnInit {

  getHeaderColorClass = Utility.getHeaderColorClass;
  OrderStatus = OrderStatus;

  selectedStatus: OrderStatus = OrderStatus.Placed;

  completeList: Order[] = [];
  filteredList: Order[] = [];
  viewFilteredList: Order[] = [];

  startStamp: number = null;
  endStamp: number = null;

  constructor(
    private orderService: OrderService,
    private allDataService: AllDataService,
    private accessControlService: AccessControlService,
    private router: Router,
  ) { }

  ngOnInit() { }

  async ionViewWillEnter() {
    this.getData();
  }

  onDateRangeChange(data:{fromStamp:number,toStamp:number}) {
    this.startStamp = data.fromStamp;
    this.endStamp = data.toStamp;
    this.getData();
  }

  async getData() {
    try {
      this.completeList = await this.orderService.getAll(this.startStamp,this.endStamp,this.selectedStatus) || [];
      this.filteredList = this.completeList.filter(order => order?.status == this.selectedStatus);
      this.viewFilteredList = [...this.filteredList.slice(0, 50)];
    } catch (error) {
      SentryUtilites.setLog("OrderPage:getData", error)
    }
  }

  loadMoreListData = (event) => {
    try {
      if (this.viewFilteredList.length > 0 && this.viewFilteredList.length <= this.filteredList.length) {
        let appendListLength = this.filteredList.length - this.viewFilteredList.length;
        let lastEl = this.viewFilteredList[this.viewFilteredList.length - 1];
        let counter = 0;
        for (let i = 0; i < this.filteredList.length; i++) {
          if (this.filteredList[i]._localUUID == lastEl._localUUID) {
            counter = 1;
            continue;
          } 
          if (counter > 0 && appendListLength >= 50) {
            if (counter <= 50) {
              this.viewFilteredList.push({ ...this.filteredList[i] })
            } else {
              break;
            }
            counter++;
          } else if(counter > 0 && appendListLength < 50) {
            if (counter <= appendListLength) {
              this.viewFilteredList.push({ ...this.filteredList[i] })
            } else {
              break;
            }
            counter++;
          }
        }
        (event as InfiniteScrollCustomEvent).target.complete();
      }
    } catch (error) {
      SentryUtilites.setLog("OrderPage:loadMoreListData", error)
    }
  }

  onStatusFilterChange = (event) => {
    this.selectedStatus = event?.detail?.value;
    this.getData();
  }

  accept = async (order: Order) => {
    try {
      if(order?._localUUID) {
        order.status = OrderStatus.Accepted;
        let updatedOrder = await this.orderService.update(order);
        if(updatedOrder?._localUUID) {
          // this.allDataService.listForceReloadSubs.next('')
          this.getData();
          // TODO - Success popup
        }
      }
    } catch (error) {
      SentryUtilites.setLog("OrderPage:accept", error)
    }
  }

  reject = async (order: Order) => {
    try {
      if(order?._localUUID) {
        order.status = OrderStatus.Rejected;
        let updatedOrder = await this.orderService.update(order);
        if(updatedOrder?._localUUID) {
          this.getData();
          // TODO - Success popup
        }
      }
    } catch (error) {
      SentryUtilites.setLog("OrderPage:reject", error)
    }
  }

  toSale = async (order: Order) => {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({action:'createSale'});
      if(!isPermit) {
        return alert("Permission: You don't have permission to create new sale. Please contact to your owner.");
      }
      this.router.navigate(['sale/form'],{
        queryParams: {
          orderLocalUUID: order?._localUUID
        }
      });
    } catch (error) {
      SentryUtilites.setLog("OrderPage:toSale", error)
    }
  }

  viewSale = async (order: Order) => {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({action:'viewSale'});
      if(!isPermit) {
        return alert("Permission: You don't have permission to view bill. Please contact to your owner.");
      }
      this.router.navigate([`bill-view/${order.linkedSaleUUID}`]);
    } catch (error) {
      SentryUtilites.setLog("OrderPage:viewSale", error)
    }
  }

}
