<ion-header>
  <ion-toolbar class="{{ getHeaderColorClass() }}">
    <app-navigation-control slot="start" type="party"></app-navigation-control>
    <ion-title class="header-title">Party List <ion-chip> Total Count: {{ partyListEle?.filteredList?.length
        }}</ion-chip> </ion-title>
    <ion-text class="last-sync-time" color="medium">Last Sync at {{ lastSyncTime }}</ion-text>
    <ion-text *ngIf="isTimeDifference" class="last-sync-time timezone-warning normal-view alert-border padding-alert-boder" color="warning"> <i>Time Zone
        Mismatched</i> </ion-text>
    <ion-chip class="ion-chip" color="danger" slot="end" (click)="bulkDelete()"> Bulk Delete </ion-chip>
    <!-- <ion-chip color="primary" slot="end" class="ion-chip whatapp-ion-chip" (click)="onWhatappBulkMessageClick()">WhatsApp Bulk Message</ion-chip> -->
    <ion-chip color="primary" slot="end" class="ion-chip" (click)="onNewPartyClick()">+ New Party </ion-chip>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-button fill="clear" class="btn-add" (click)="onNewPartyClick()">
    + New Party
  </ion-button>
  <app-party-list #partyListEle (partySelectedEvent)="onPartySelectedEvent($event)">
  </app-party-list>
</ion-content>

<app-pin-verification (verifyTransactionPin)="verifyTransactionPIN($event)" #pinVerificationElement></app-pin-verification>