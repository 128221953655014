import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PartySelectorComponent } from '../../../components/party-selector/party-selector.component';
import { PartyRestaurantSelectorComponent } from '../../../components/party-restaurant-selector/party-restaurant-selector.component';
import { ItemSelectorComponent } from '../../../components/item-selector/item-selector.component';
import { ItemRestaurantSelectorComponent } from '../../../components/item-restaurant-selector/item-restaurant-selector.component';
import { MoneyOutSelectorComponent } from '../../../components/money-out-selector/money-out-selector.component';
import { SettingsComponent } from '../../../components/settings/settings.component';
import { IonInput, ToastController } from '@ionic/angular';
import { Utility } from '../../../utils/utility';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SaleReturn } from '../../../models/SaleReturn.model';
import { Profile } from '../../../models/Profile.model';
import { Subscription } from 'rxjs';
import { AllDataService } from '../../../services/all-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from '../../../services/order.service';
import { PremiumControlService } from '../../../services/auth/premium-control.service';
import { EventService } from '../../../services/event.service';
import { MonthWisePartyCreditService } from '../../../services/month-wise-party-credit.service';
import { AuthService } from '../../../services/auth/auth.service';
import { SentryUtilites } from '../../../utils/sentryUtilites';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import Party from '../../../models/Party.model';
import { Utils } from '../../../utils/utils';
import { BillItem } from '../../../models/BillItem.model';
import { MoneyOut } from '../../../models/MoneyOut.model';
import { CalculateBillTask } from '../../../utils/enums/CalculateBillTask';
import { BillCalculations } from '../../../utils/BillCalculations';
import { BillType } from '../../../utils/enums/BillType';
import { Sale } from '../../../models/Sale.model';

@Component({
  selector: 'app-sale-return-form',
  templateUrl: './sale-return-form.page.html',
  styleUrls: ['./sale-return-form.page.scss'],
})
export class SaleReturnFormPage implements OnInit {

  /*
    Native Element Ref
  */

    @ViewChild('partySelector', { static: false }) partySelector: PartySelectorComponent;
    @ViewChild('partyRestaurantSelector', { static: false }) partyRestaurantSelector: PartyRestaurantSelectorComponent;
    @ViewChild('itemSelector', { static: false }) itemSelector: ItemSelectorComponent;
    @ViewChild('itemRestaurantSelector', { static: false }) itemRestaurantSelector: ItemRestaurantSelectorComponent;
    @ViewChild('moneyOutSelector', { static: false }) moneyOutSelector: MoneyOutSelectorComponent;
    @ViewChild('settingsModal') settingsModal: SettingsComponent;
    @ViewChild('amountPaidEle') amountPaidEle: IonInput;
    @ViewChild('paymentIdEle') paymentIdEle: IonInput;
  
    getHeaderColorClass = Utility.getHeaderColorClass;
    maxDate = Utility.maxDateUpto50Year();
    states = Utility.states;
    setDateValue = Utility.setDateValue;
    isTrue = Boolean;
  
    /*
     Reactive Form
    */
  
    form: FormGroup;
  
    barcodeScannerMode: boolean = false;
    dueNetObj = { ...Utility.dueNetObj };
  
    dpBillDateValue: string = null;
    dpSaleBillDateValue: string = null;
    dpDueDateValue: string = null;
    dpEWayBillDateValue: string = null;
    dpDeliveryDateValue: string = null;
    minDateTransport: string = Utility.ionDatePickerFormattedString(+new Date().setHours(0, 0, 0, 0));
  
    showTransportDetailsCard: boolean = false;
    paramDocumentId: string = null;
    orderLocalUUID: string = null;
    queryParamSaleUUID: string = null;
    fetchedSaleReturn: SaleReturn = null;
    fetchedSale: Sale = null;
  
    amountPaidChecked: boolean = null;
    isDisableAmountPaid: boolean = false;
    dueDateContentsMounted: boolean = true;
  
    onClickSave: boolean = false;
  
    previousBalanceChecked: boolean = false;
    previousBalanceAmount: number = null;
  
    selectedProfile: Profile = null;
  
    paymentMode: string = 'cash';
  
    listStyleView: boolean = false;
  
    noPartySelectError: boolean = false;
  
    iSetItemPriceHistoryStatus: boolean = false;
  
    iSetOutOfStockHideStatus: boolean = false;
  
    isOpenSecondaryPartyModal: boolean = false;
    
    minDueDate: string = Utility.ionDatePickerFormattedString(+new Date().setHours(0, 0, 0, 0));
  
    todayDate: number = +new Date().setHours(0, 0, 0, 0);
  
    //check if sale return is edited
    isSaleReturnEdited: boolean = false;
  
    showCashDiscountPercent: number = 0;
  
    applyLastSaleReturnStamp: string = null;
  
    isOpenBillDatePicker: boolean = false;
    isOpenSaleBillDatePicker: boolean = false;
    isOpenDueDatePicker: boolean = false;
    isOpenEwayBillDatePicker: boolean = false;
    isOpenDeliveryDatePicker: boolean = false;
    keepContentsMounted: boolean = false;
  
    isStaffAssignToSaleReturn: boolean = false;
  
    isNgOnInitRun: boolean = false;
  
    subArr: Subscription[] = [];
  
    constructor(
      private formBuilder: FormBuilder,
      private toastController: ToastController,
      private allDataService: AllDataService,
      private route: ActivatedRoute,
      private router: Router,
      private orderService: OrderService,
      private premiumControlService: PremiumControlService,
      private eventService: EventService,
      private monthWisePartyCreditService: MonthWisePartyCreditService,
      private authService: AuthService,
    ) { }
  
    /*
      Life Cycle Hooks
    */
  
    ngOnInit() {
      try {
        this.isNgOnInitRun = true;
        this.getParamDocumentId();
        this.getOrderLocalUUID();
        this.getQuerySaleLocalUUID();
        this.initializeReactiveForm();
        this.populateBarcodeScannerSetting();
        this.subArr.push(this.allDataService.listForceReloadSubs.subscribe((listName: string) => {
          if (listName == 'profile-list') {
            this.getSelectedProfile();
          }
        }));
      } catch (error) {
        SentryUtilites.setLog("SaleReturnFormPage:ngOnInit", error)
      }
    }
  
    async ngAfterViewInit() {
      try {
        await this.getSelectedProfile();
        setTimeout(() => {
            this.populateForm();
        }, 500);
      } catch (error) {
        SentryUtilites.setLog("SaleReturnFormPage:ngAfterViewInit", error)
      }
    }
  
    ionViewWillEnter() {
      if(!this.isNgOnInitRun) {
        this.ngOnInit();
        this.ngAfterViewInit();
      }
    }
  
    ionViewWillLeave() {
      this.ngOnDestroy();
    }
    
    ngOnDestroy() {
      try {
        this.paramDocumentId = null;
        this.queryParamSaleUUID = null;
        this.orderLocalUUID = null;
        this.resetBillForm();
        this.keepContentsMounted = false;
      } catch (error) {
        SentryUtilites.setLog("SaleReturnFormPage:ngOnDestroy", error)
      }
    }
  
    onSettingSave() {
      this.populateBarcodeScannerSetting();
    }
  
    getParamDocumentId() {
      this.paramDocumentId = this.route.snapshot.paramMap.get('documentId');
    }
  
    getOrderLocalUUID() {
      this.orderLocalUUID = this.route.snapshot.queryParamMap.get('orderLocalUUID');
    }

    getQuerySaleLocalUUID() {
      // to recieved updated value
      this.subArr.push(this.route.queryParams.subscribe(query => {
        this.queryParamSaleUUID = query['saleLocalUUID'];
      }));
    }
  
    populateSettings() {
      try {
        this.listStyleView = (this.selectedProfile?.iSetItemSelectorStyleWeb === 'List');
        if(this.amountPaidChecked === null) {
          this.amountPaidChecked = this.selectedProfile?.iSetAutoMoneyInStatus;
        }
        this.previousBalanceChecked = this.selectedProfile?.iSetAutoPreviousBalanceStatus;
        this.iSetItemPriceHistoryStatus = this.selectedProfile?.iSetItemPriceHistoryStatus;
        this.iSetOutOfStockHideStatus = this.selectedProfile?.iSetOutOfStockHideStatus;
        let phone = Utility.getFromLocalStorage('selectedProfileUserId');
        let isOwner = phone === this.authService.getLoginPhone();
        this.isStaffAssignToSaleReturn = isOwner && this.selectedProfile?.iSetAssignStaffToTheSale && !this.listStyleView;
        if(this.selectedProfile?.iSetServiceChargePercentage) {
          this.form?.patchValue({serviceChargePercentage: this.selectedProfile?.iSetServiceChargePercentage});
        }
      } catch (error) {
        SentryUtilites.setLog("SaleReturnFormPage:populateSettings", error)
      }
    }
  
    // Fetch selected profile
    async getSelectedProfile() {
      try {
        this.selectedProfile = await this.allDataService.profileService.getCurrentProfile();
        this.populateSettings();
        return true;
      } catch (error) {
        SentryUtilites.setLog("SaleReturnFormPage:getSelectedProfile", error)
        return false;
      }
    }
  
    async populateForm() {
      try {
        if (this.paramDocumentId) {
          this.fetchedSaleReturn = await this.allDataService.saleReturnService.getByUUID(this.paramDocumentId);
          
          if(this.fetchedSaleReturn?._localUUID) {
            if(this.fetchedSaleReturn?.billCompleteStamp){
              this.isSaleReturnEdited = true;
            }
      
            if(!this.fetchedSaleReturn?.deletedStamp) {
              this.form.patchValue({ ...this.fetchedSaleReturn });
              this.dpBillDateValue = Utility.ionDatePickerFormattedString(this.fetchedSaleReturn?.billDateStamp);
              this.dpSaleBillDateValue = Utility.ionDatePickerFormattedString(this.fetchedSaleReturn?.saleBillDateStamp);
              // if(this.fetchedSaleReturn?.dueDateStamp) {
              //   this.dpDueDateValue = Utility.ionDatePickerFormattedString(this.fetchedSaleReturn?.dueDateStamp);
              // }
              // this.dueDateContentsMounted = true;
      
              if(this.listStyleView) {
                this.partySelector?.setSelectedParty(this.fetchedSaleReturn?.party);
                if(this.paramDocumentId && this.fetchedSaleReturn?.billCompleteStamp) {
                  // this.partySelector?.disable();
                }
                this.itemSelector?.setBillItems(this.fetchedSaleReturn?.billItems);
                this.itemSelector.disable();
              }else {
                this.partyRestaurantSelector?.setSelectedParty(this.fetchedSaleReturn?.party);
                if(this.paramDocumentId && this.fetchedSaleReturn?.billCompleteStamp) {
                  // this.partyRestaurantSelector?.disable();
                }
                this.itemRestaurantSelector?.setBillItems(this.fetchedSaleReturn?.billItems);
                this.itemRestaurantSelector.disable();
              }
              this.populateTransportDetails(this.fetchedSaleReturn?.transportDetail);
              this.keepContentsMounted = true;
              // this.setTransportDetailsCardVisibiltity();
              this.disableAmountPaid();
              if(this.fetchedSaleReturn?.moneyOuts?.length) {
                this.paymentMode = this.fetchedSaleReturn?.moneyOuts[0]?.paymentMode;
                if(this.paymentIdEle) {
                  (this.paymentIdEle as any)['el'].value = this.fetchedSaleReturn?.moneyOuts[0]?.paymentId;
                  this.paymentIdEle.value = this.fetchedSaleReturn?.moneyOuts[0]?.paymentId;
                }
                if(this.itemRestaurantSelector) {
                  this.itemRestaurantSelector.paymentId = this.fetchedSaleReturn?.moneyOuts[0]?.paymentId;
                }
              }
              setTimeout(() => {
                this.form.patchValue({
                  cashDiscount: this.fetchedSaleReturn?.cashDiscount,
                  cashDiscountPercentage: this.fetchedSaleReturn?.cashDiscountPercentage,
                });
                this.recalculateBillItems()
              }, 1000);
            }
          }
        }else if(this.orderLocalUUID) {
          let order = await this.orderService.getByUUID(this.orderLocalUUID);
          if(order?._localUUID) {
            this.populateBillNumber();
            let latestParty = await this.allDataService.partyService.getByUUID(order?.party?._localUUID);
            if(latestParty?._localUUID ) {
              this.form?.get('party')?.setValue(latestParty);
              if(this.listStyleView) {
                this.partySelector?.setSelectedParty(latestParty);
                this.partySelector?.disable();
              }else {
                this.partyRestaurantSelector?.setSelectedParty(latestParty);
                this.partyRestaurantSelector?.disable();
              }
            }else {
              alert('Party not found');
            }
            if(order?.orderItems?.length) {
              if(this.listStyleView) {
                this.itemSelector?.openItemSelectorModal();
                setTimeout(() => {
                  this.itemSelector?.selectOrderItem(order?.orderItems);
                }, 500);
              }else {
                this.itemRestaurantSelector?.openItemSelectorModal();
                setTimeout(() => {
                  this.itemRestaurantSelector?.selectOrderItem(order?.orderItems);
                }, 500);
              }
            }else {
              alert('Items not found');
            }
            return;
          }else {
            alert('Order not found');
          }
        } else if (this.queryParamSaleUUID) {
          this.fetchedSale = await this.allDataService.saleService.getByUUID(this.queryParamSaleUUID);
          
          if(this.fetchedSale?._localUUID) {
            if(this.fetchedSale?.billCompleteStamp){
              this.isSaleReturnEdited = true;
            }
      
            if(!this.fetchedSale?.deletedStamp) {
              this.form.patchValue({ 
                ...this.fetchedSale,
                saleBillNo: this.fetchedSale?.billNo, 
                saleBillDateStamp: this.fetchedSale?.billDateStamp, 
                saleCreatedStamp: this.fetchedSale?.createdStamp,
                billDateStamp: +new Date().setHours(0,0,0,0),
                amountPaid: this.fetchedSale?.amountReceived,
               });
              this.populateBillNumber();
              this.dpSaleBillDateValue = Utility.ionDatePickerFormattedString(this.fetchedSale?.billDateStamp);
              // if(this.fetchedSale?.dueDateStamp) {
              //   this.dpDueDateValue = Utility.ionDatePickerFormattedString(this.fetchedSale?.dueDateStamp);
              // }
              // this.dueDateContentsMounted = true;
      
              if(this.listStyleView) {
                this.partySelector?.setSelectedParty(this.fetchedSale?.party);
                if(this.queryParamSaleUUID && this.fetchedSale?.billCompleteStamp) {
                  this.partySelector?.disable();
                }
                this.itemSelector?.setBillItems(this.fetchedSale?.billItems);
                this.itemSelector.disable();
              }else {
                this.partyRestaurantSelector?.setSelectedParty(this.fetchedSale?.party);
                if(this.queryParamSaleUUID && this.fetchedSale?.billCompleteStamp) {
                  this.partyRestaurantSelector?.disable();
                }
                this.itemRestaurantSelector?.setBillItems(this.fetchedSale?.billItems);
                this.itemRestaurantSelector.disable();
              }
              this.populateTransportDetails(this.fetchedSale?.transportDetail);
              this.keepContentsMounted = true;
              this.calculatePartyCredit(this.fetchedSale?.party?._localUUID);
              // this.setTransportDetailsCardVisibiltity();
              if(this.fetchedSale?.moneyIns?.length) {
                this.paymentMode = this.fetchedSale?.moneyIns[0]?.paymentMode;
                if(this.paymentIdEle) {
                  (this.paymentIdEle as any)['el'].value = this.fetchedSale?.moneyIns[0]?.paymentId;
                  this.paymentIdEle.value = this.fetchedSale?.moneyIns[0]?.paymentId;
                }
                if(this.itemRestaurantSelector) {
                  this.itemRestaurantSelector.paymentId = this.fetchedSale?.moneyIns[0]?.paymentId;
                }
              }
              setTimeout(() => {
                this.form.patchValue({
                  cashDiscount: this.fetchedSale?.cashDiscount,
                  cashDiscountPercentage: this.fetchedSale?.cashDiscountPercentage,
                });
                this.recalculateBillItems()
              }, 1000);
            }
          }
        }else{
          !this.fetchedSaleReturn && this.populateBillNumber();
          this.openPartySelectorModal(); 
        }
      } catch (error) {
        SentryUtilites.setLog("SaleReturnFormPage:populateForm", error)
      }
      
    }
  
    /*
      Reactive Form Initialization
    */
  
    initializeReactiveForm() {
      try {
        this.form = this.formBuilder.group({
          billNo: ['', Validators.required],
          billDateStamp: [+new Date().setHours(0, 0, 0, 0), Validators.required],
          saleBillNo: [''],
          saleBillDateStamp: [+new Date().setHours(0, 0, 0, 0), Validators.required],
          saleCreatedStamp: [],
          // dueDateStamp: [null],
          // billingTerm: [null],
          party: [null, Validators.required],
          partySecondary: [null],
          billItems: [null],
          transportDetail: this.formBuilder.group({
            additionalAmount: [null],
            purOrderNo: [null],
            challanNo: [null],
            eWayBillNo: [null],
            eWayBillDate: [],
            transporterName: [null],
            vehicleNumber: [null],
            transDistance: [null, Validators.maxLength(5)],
            deliveryLocation: [null],
            deliveryDate: [],
            tnc: [null],
          }),
          gstAmount: [0],
          cessAmount: [0],
          discountAmount: [0],
          cashDiscount: [null],
          cashDiscountPercentage: [null],
          totalAmount: [null],
          amountPaid: [null],
          subTotalAmount: [null],
          totalSaving: [null],
          note: [null],
          roundOffValue: [null],
          // partyPreviousBalance: [null],
          serviceChargePercentage: [null],
          serviceChargeAmount: [null],
          deliveryProvience: [null],
          createdBy: [null],
          lastModifiedBy: [null],
          createdByName: [null],
          lastModifiedByName: [null],
        });
        this.subArr.push(this.form.get("amountPaid").valueChanges.pipe(distinctUntilChanged(), debounceTime(300)).subscribe(amountPaid => {
          if(+amountPaid && !this.amountPaidChecked) {
            this.amountPaidChecked = true;
          }
          if(amountPaid < 0) {
            this.form?.patchValue({amountPaid: Math.abs(amountPaid)});
          }
          if(amountPaid > this.form?.value?.totalAmount) {
            this.form?.patchValue({amountPaid: this.form?.value?.totalAmount});
          }
        }));
        this.subArr.push(this.form.get("billDateStamp").valueChanges.pipe(distinctUntilChanged(), debounceTime(300)).subscribe(billDateStamp => {
          this.minDueDate = Utility.ionDatePickerFormattedString(billDateStamp);
        }));
        this.subArr.push(this.form.get("totalAmount").valueChanges.pipe(distinctUntilChanged(), debounceTime(300)).subscribe(totalAmount => {
          if(this.amountPaidChecked) {
            this.form?.patchValue({amountPaid: this.fetchedSale?.amountReceived || totalAmount});
          }
        }));
        this.subArr.push(this.form.get("serviceChargePercentage").valueChanges.pipe(distinctUntilChanged(), debounceTime(300)).subscribe(serviceChargePercentage => {
          setTimeout(() => {
            this.calculateSubTotalAmount();
          }, 500);
        }));
      } catch (error) {
        SentryUtilites.setLog("SaleReturnFormPage:initializeReactiveForm", error)
      }
    }
  
    //----------------------------------------------------------------------------
  
    openSettingsModal() {
      this.settingsModal?.openSettingsModal();
    }
  
    /*
      SaleReturn Number
    */
  
    async populateBillNumber() {
      try {
        this.form.patchValue({
          billNo: await this.allDataService.saleReturnService.getNewSaleReturnNo()
        });
      } catch (error) {
        SentryUtilites.setLog("SaleReturnFormPage:populateBillNumber", error)
      }
    }
  
    
  
    //----------------------------------------------------------------------------
  
    /*
      PartyV0 Selector functons
    */
  
    openPartySelectorModal() {
      try {
        if (
          this.listStyleView
          && this.partySelector
          && !this.partySelector?.isPartySelected
        ) {
          this.partySelector?.openPartySelectorModal();
        } else {
          this.partySelector?.openPartySelectorModal(false);
        }
        if (
          !this.listStyleView
          && this.partyRestaurantSelector
          && !this.partyRestaurantSelector?.isPartySelected
        ) {
          this.partyRestaurantSelector?.openPartySelectorModal();
        } else {
          this.partyRestaurantSelector?.openPartySelectorModal(false);
        }
      } catch (error) {
        SentryUtilites.setLog("SaleReturnFormPage:openPartySelectorModal", error)
      }
    }
  
    /**
     * @description : open secondary party modal
     */
    async openSeconadryPartyModal() {
      try {
        this.isOpenSecondaryPartyModal = true;
        await Utility.wait(500)
        this.itemRestaurantSelector?.openSeconadryPartyModal();
      } catch (error) {
        SentryUtilites.setLog("SaleReturnFormPage:openSeconadryPartyModal", error)
      }
    }
    // ---------------------------------
  
    async onPartySelect(party: Party) {
      try {
        if (party?._localUUID) {
          this.form.patchValue({ party });
          this.calculatePartyCredit(party?._localUUID);
          if(party?.paymentTerm) {
            // this.form.patchValue({
            //   billingTerm: party?.paymentTerm
            // });
            this.onBillingTermSelect({detail: {value: party?.paymentTerm}});
          }
          this.form.patchValue({
            deliveryProvience: party?.deliveryProvience || party?.billingProvience
          });
          this.itemRestaurantSelector?.openItemSelectorModal();
        }
      } catch (error) {
        SentryUtilites.setLog("SaleReturnFormPage:onPartySelect", error)
      }
    }
  
    onSeconadryPartySelect(party: Party) {
      try {
        if (party?._localUUID || (party?.name || party?.billingAddress) || party?.phone) {
          this.form.patchValue({
            partySecondary: party
          });
          this.calculateSubTotalAmount();
          this.calculateAdditionalDiscount();
        }
      } catch (error) {
        SentryUtilites.setLog("SaleReturnFormPage:onSeconadryPartySelect", error)
      }
    }
  
    async calculatePartyCredit(partyUUID: string) {
      try {
        if(partyUUID) {
          const ledgerCredit = Utils.capFractionsToTwo(await this.monthWisePartyCreditService?.getLedgerCredit(partyUUID));
          // if(this.previousBalanceChecked) {
          //   this.form?.patchValue({
          //     partyPreviousBalance: ledgerCredit,
          //   });
          // }
          if(this.form?.value?.party?._localUUID) {
            let party: Party = await this.allDataService.partyService.getByUUID(this.form?.value?.party?._localUUID);
            if(party?._localUUID) {
              party.credit = ledgerCredit;
              let updatedParty = await this.allDataService.partyService.update(party);
              if(updatedParty?._localUUID) {
                this.allDataService.listForceReloadSubs.next('party-list');
              }
            }
          }
        }
      } catch (error) {
        SentryUtilites.setLog("SaleReturnFormPage:calculatePartyCredit", error)
      }
    }
  
    //----------------------------------------------------------------------------
  
    /*
      SaleReturn Items
    */
  
    async populateBillItems(billItems: BillItem[]) {
      try {
        let totalAmount = 0;
        billItems?.forEach(x => totalAmount += x?.total);
        if (this.itemRestaurantSelector?.amountReceivedChecked) {
          this.amountPaidChecked = true;
          // this.form.patchValue({ amountPaid: totalAmount });
        }
        this.form.patchValue({
          billItems,
          cashDiscount: this.fetchedSaleReturn?.cashDiscount || null,
          cashDiscountPercentage: this.fetchedSaleReturn?.cashDiscountPercentage || null,
        });
        this.recalculateBillItems();
      } catch (error) {
        SentryUtilites.setLog("SaleReturnFormPage:populateBillItems", error)
      }
    }
  
    async onCartClear(event) {
      try {
        if(event) {
          if(this.paramDocumentId) {
            this.router.navigate(['sale-return/form']);
          }
          this.resetBillForm();
          this.ngOnInit();
          this.ngAfterViewInit();
        }
      } catch (error) {
        SentryUtilites.setLog("SaleReturnFormPage:onCartClear", error)
      }
    }
  
    //----------------------------------------------------------------------------
  
    onMoneyOutCartUpdate(moneyOutCart: MoneyOut[]) {
      try {
        let totalAmountReceived = 0;
        moneyOutCart?.forEach(x => totalAmountReceived += Number(x?.totalAmount));
        this.form?.controls?.amountPaid?.setValue(totalAmountReceived);
      } catch (error) {
        SentryUtilites.setLog("SaleReturnFormPage:onMoneyOutCartUpdate", error)
      }
    }
  
    /*
      Transport Details
    */
  
    populateTransportDetails(data: any) {
      try {
        this.form.patchValue({ transportDetail: data });
        if (data?.eWayBillDate) {
          this.dpEWayBillDateValue = Utility.ionDatePickerFormattedString(data.eWayBillDate);
        }
        if (data?.deliveryDate) {
          this.dpDeliveryDateValue = Utility.ionDatePickerFormattedString(data.deliveryDate);
        }
        // this.setTransportDetailsCardVisibiltity();
        this.calculateSubTotalAmount();
      } catch (error) {
        SentryUtilites.setLog("SaleReturnFormPage:populateTransportDetails", error)
      }
    }
  
    setTransportDetailsCardVisibiltity() {
      try {
        let data = this.form.value.transportDetail;
        if (
          data?.additionalAmount
          || data?.eWayBillNo
          || data?.eWayBillDate
          || data?.purOrderNo
          || data?.challanNo
          || data?.transporterName
          || data?.vehicleNumber
          || data?.deliveryDate
          || data?.transDistance
          || data?.deliveryLocation
          || data?.tnc
        ) {
          this.showTransportDetailsCard = true;
        } else {
          this.showTransportDetailsCard = false;
        }
      } catch (error) {
        SentryUtilites.setLog("SaleReturnFormPage:setTransportDetailsCardVisibiltity", error)
      }
    }
  
    //----------------------------------------------------------------------------
  
    /*
      SaleReturn Total Amount
    */
  
    setTotalAmount(totalAmount: number) {
      this.form.patchValue({ totalAmount });
    }
  
    //----------------------------------------------------------------------------
  
    /*
      SaleReturn Settings
    */
  
    async getBillSetting() {
      return null;
    }
  
    //----------------------------------------------------------------------------
  
    amountPaidSetBlur(keyboardEvent: any) {
      try {
        keyboardEvent = keyboardEvent as KeyboardEvent;
        keyboardEvent?.preventDefault();
        keyboardEvent?.target?.blur();
      } catch (error) {
        SentryUtilites.setLog("SaleReturnFormPage:amountPaidSetBlur", error)
      }
    }
  
    /**
     * 
     * @param event : date event
     * @description : on E way bill date select assign date value
     */
    onEwayBillDateSelect(event) {
      try {
        if(event?.detail?.value) {
          let timeStamp = +new Date(event?.detail?.value).setHours(0, 0, 0, 0);
          this.form?.get('transportDetail.eWayBillDate')?.setValue(timeStamp);
          this.dpEWayBillDateValue = Utility.ionDatePickerFormattedString(timeStamp);
          Utility.closeDatePicker(event?.target);
        }
      } catch (error) {
        SentryUtilites.setLog("SaleReturnFormPage:onEwayBillDateSelect", error)
      }
    }
    // --------------------------------------
  
    /**
     * 
     * @param event : date event
     * @description : on delivery date select assign date value
     */
    onDeliveryDateSelect(event) {
      try {
        if(event?.detail?.value) {
          let timeStamp = +new Date(event?.detail?.value).setHours(0, 0, 0, 0);
          this.form?.get('transportDetail.deliveryDate')?.setValue(timeStamp);
          this.dpDeliveryDateValue = Utility.ionDatePickerFormattedString(timeStamp);
          Utility.closeDatePicker(event?.target);
        }
      } catch (error) {
        SentryUtilites.setLog("SaleReturnFormPage:onDeliveryDateSelect", error)
      }
    }
    // ------------------------------------
  
    setPaymentMode(event) {
      try {
        this.paymentMode = event?.detail?.value || 'cash';
        this.onItemSelectorPaymentModeChange(this.paymentMode);
      } catch (error) {
        SentryUtilites.setLog("SaleReturnFormPage:setPaymentMode", error)
      }
    }
  
    onItemSelectorPaymentModeChange(paymentMode: string) {
      try {
        this.paymentMode = paymentMode;
        if(this.paramDocumentId && this.fetchedSaleReturn?.billCompleteStamp && this.fetchedSaleReturn?.moneyOuts?.length) {
          this.fetchedSaleReturn.moneyOuts[0].paymentMode = paymentMode;
        }
      } catch (error) {
        SentryUtilites.setLog("SaleReturnFormPage:onItemSelectorPaymentModeChange", error)
      }
    }
  
    /*
      Barcode Scanner
    */
  
    async populateBarcodeScannerSetting() {
      this.barcodeScannerMode = Utility.getFromLocalStorage('item_barcode_scanner');
    }
  
    async onBarcodeScannerModeChange(event: any) {
      try {
        this.barcodeScannerMode = event?.detail?.checked;
        // Utility.setToLocalStorage('item_barcode_scanner', this.barcodeScannerMode);
        // const message = `Barcode Scanner Mode ${this.barcodeScannerMode ? 'Enabled' : 'Disabled'}`;
        // const color = 'success';
    
        // const toast = await this.toastController.create({
        //   header: message,
        //   duration: 2000,
        //   position: 'top',
        //   mode: 'ios',
        //   icon: 'barcode-sharp',
        //   color,
        // });
        // await toast.present();
      } catch (error) {
        SentryUtilites.setLog("SaleReturnFormPage:onBarcodeScannerModeChange", error)
      }
    }
  
    //----------------------------------------------------------------------------
  
    focusAmountPaid(event) {
      try {
        if(event) {
          this.amountPaidEle?.setFocus();
        }
      } catch (error) {
        SentryUtilites.setLog("SaleReturnFormPage:focusAmountPaid", error)
      }
    }
  
    onAmountPaidCheckedChange(event: any) {
      try {
        if(this.amountPaidChecked != event?.detail?.checked) {
          this.amountPaidChecked = event?.detail?.checked;
          if (this.amountPaidChecked) {
            this.populateAmountPaid(Number(this.form?.value?.amountPaid) || this.form?.value?.totalAmount);
          } else {
            this.populateAmountPaid(null);
          }
        }
      } catch (error) {
        SentryUtilites.setLog("SaleReturnFormPage:onAmountPaidCheckedChange", error)
      }
    }
  
    async onPreviousBalanceCheckedChange(event: any) {
      try {
        this.previousBalanceChecked = event?.detail?.checked;
        let partyLocalUUID = this.form?.value?.party?._localUUID;
        if (this.previousBalanceChecked && partyLocalUUID) {
          const ledgerCredit = Utils.capFractionsToTwo(await this.monthWisePartyCreditService?.getLedgerCredit(partyLocalUUID));
          // this.form?.patchValue({
          //   partyPreviousBalance: ledgerCredit,
          // });
        } else {
          // this.form?.patchValue({
          //   partyPreviousBalance: null
          // });
        }
      } catch (error) {
        SentryUtilites.setLog("SaleReturnFormPage:onPreviousBalanceCheckedChange", error)
      }
    }
  
    populateAmountPaid(amountPaid: number) {
      this.form.patchValue({ amountPaid });
    }
  
    disableAmountPaid() {
      try {
        if (this.fetchedSaleReturn?.moneyOuts?.length) {
          this.amountPaidChecked = true;
          this.isDisableAmountPaid = true;
          this.form.get('amountPaid')?.disable();
        }
      } catch (error) {
        SentryUtilites.setLog("SaleReturnFormPage:disableAmountPaid", error)
      }
    }
  
    /**
     * 
     * @param event : date event
     * @description : on bill date select assign date value
     */
    onBillDateSelect(event) {
      try {
        if(event?.detail?.value) {
          let timeStamp = +new Date(event?.detail?.value).setHours(0, 0, 0, 0);
          this.form?.controls?.billDateStamp?.setValue(timeStamp);
          this.dpBillDateValue = Utility.ionDatePickerFormattedString(timeStamp);
          Utility.closeDatePicker(event?.target);
          // if(this.form?.value?.billingTerm) {
          //   this.onBillingTermSelect({detail:{value:this.form?.value?.billingTerm}});
          // }
        }
      } catch (error) {
        SentryUtilites.setLog("SaleReturnFormPage:onBillDateSelect", error)
      }
    }
    // ----------------------------------------

    /**
     * 
     * @param event : date event
     * @description : on bill date select assign date value
     */
    onSaleBillDateSelect(event) {
      try {
        if(event?.detail?.value) {
          let timeStamp = +new Date(event?.detail?.value).setHours(0, 0, 0, 0);
          this.form?.controls?.billDateStamp?.setValue(timeStamp);
          this.dpSaleBillDateValue = Utility.ionDatePickerFormattedString(timeStamp);
          Utility.closeDatePicker(event?.target);
          // if(this.form?.value?.billingTerm) {
          //   this.onBillingTermSelect({detail:{value:this.form?.value?.billingTerm}});
          // }
        }
      } catch (error) {
        SentryUtilites.setLog("SaleReturnFormPage:onSaleBillDateSelect", error)
      }
    }
    // ----------------------------------------
  
    /**
     * 
     * @param event : date event
     * @description : on due date select assign date value
     */
    onBillDueDateSelect(event) {
      try {
        if(event?.detail?.value) {
          let timeStamp = +new Date(event?.detail?.value).setHours(0, 0, 0, 0);
          // this.form?.controls?.dueDateStamp?.setValue(timeStamp);
          this.dpDueDateValue = Utility.ionDatePickerFormattedString(timeStamp);
          Utility.closeDatePicker(event?.target);
        }
      } catch (error) {
        SentryUtilites.setLog("SaleReturnFormPage:onBillDueDateSelect", error)
      }
    }
    // ----------------------------------------
  
    returnZero() {
      return 0;
    }
  
    onBillingTermSelect(event) {
      try {
        this.dpDueDateValue = null;
        // const dueDateStamp = Utility.getTimestampByDays(this.dueNetObj[event?.detail?.value || 'net0']?.days ,this.form?.value?.billDateStamp);
        // this.form?.controls?.dueDateStamp?.setValue(dueDateStamp);
        this.dueDateContentsMounted = true;
        // this.dpDueDateValue = Utility.ionDatePickerFormattedString(dueDateStamp);
      } catch (error) {
        SentryUtilites.setLog("SaleReturnFormPage:onBillingTermSelect", error)
      }
    }
  
    setPaymentId(value) {
      try {
        if(this.paymentIdEle) {
          (this.paymentIdEle as any)['el'].value = value;
          if(this.paramDocumentId && this.fetchedSaleReturn?.billCompleteStamp && this.fetchedSaleReturn?.moneyOuts?.length) {
            this.fetchedSaleReturn.moneyOuts[0].paymentId = value;
          }
        }
      } catch (error) {
        SentryUtilites.setLog("SaleReturnFormPage:setPaymentId", error)
      }
    }
  
    onSaveClick() {
      try {
        if (this.paramDocumentId) {
          this.update();
        } else {
          this.save();
        }
      } catch (error) {
        SentryUtilites.setLog("SaleReturnFormPage:onSaveClick", error)
      }
    }
  
    async updateOrderSaleId(sale: SaleReturn) {
      try {
        if(this.orderLocalUUID && sale?._localUUID) {
          let order = await this.orderService.getByUUID(this.orderLocalUUID);
          if(order?._localUUID) {
            order.linkedSaleUUID = sale?._localUUID;
            this.orderService.update(order);
          }
        }
      } catch (error) {
        SentryUtilites.setLog("SaleReturnFormPage:updateOrderSaleId", error)
      }
    }
  
    async addSaleReturn() {
      try {
        let checkCreateSale = await this.premiumControlService.checkCreateSale();
        if(checkCreateSale) {
          if(!this.form?.value?.party?._localUUID) {
            const toast = await this.toastController.create({
              header: 'Please Select Party to Save Bill',
              duration: 3000,
              position: 'top',
              mode: 'ios',
              color: 'danger',
            });
            await toast.present();
            this.onClickSave = false;
            this.noPartySelectError = true;
            return null;
          }else {
            this.noPartySelectError = false;
          }
      
          if (this.form.valid) {
            let saleReturn = new SaleReturn();
      
            saleReturn = { ...this.form.value };
      
            saleReturn.moneyOuts = [];
      
            if (Number(this.form?.value?.amountPaid)) {
              let moneyOut = new MoneyOut();
              moneyOut.party = this.form.value.party;
              moneyOut.billDateStamp = saleReturn.billDateStamp;
              moneyOut.totalAmount = this.form.value.amountPaid;
              moneyOut.billNo = await this.allDataService.moneyOutService.getNewMoneyOutNo();
              moneyOut.paymentMode = this.paymentMode || 'cash';
              moneyOut.paymentId = this.paymentIdEle?.value as string || null;
              saleReturn.moneyOuts.push(moneyOut);
            }
      
            saleReturn.billCompleteStamp = +new Date();

            if(this.queryParamSaleUUID) {
              saleReturn.linkedSaleUUID = this.queryParamSaleUUID;
            }
      
            let savedSaleReturn = await this.allDataService.saleReturnService.save(saleReturn);
      
            this.allDataService.itemService.reloadList();
            this.allDataService.partyService.reloadList();
            this.allDataService.moneyOutService.reloadList();
            this.allDataService.saleService.reloadList();
            this.allDataService.listForceReloadSubs.next('sale-return-list');
            if(savedSaleReturn?.party?._localUUID) {
              let updatedParty=await this.allDataService.partyService.getByUUID(savedSaleReturn?.party?._localUUID);
              this.allDataService.partyService.updateSubs.next(updatedParty);
            }
            if(savedSaleReturn?.billItems?.length){
              for(let i=0;i<savedSaleReturn.billItems.length;i++){
                let billItem = savedSaleReturn?.billItems[i]?.item;
                if(billItem?._localUUID) {
                  let updatedItem=await this.allDataService.itemService.getByUUID(billItem?._localUUID);
                  this.allDataService.itemService.updateSubs.next(updatedItem);
                }
              }
            }
            if(savedSaleReturn?.moneyOuts?.length){
              for(let i=0;i<savedSaleReturn?.moneyOuts?.length;i++){
                let moneyOut = savedSaleReturn?.moneyOuts[i];
                if(moneyOut?._localUUID) {
                  let updatedMoneyIn=await this.allDataService.moneyOutService.getByUUID(moneyOut?._localUUID);
                  this.allDataService.moneyOutService.updateSubs.next(updatedMoneyIn);
                }
              }
              this.allDataService.moneyOutService.reloadList();
            }
            this.updateOrderSaleId(savedSaleReturn);
    
            setTimeout(()=> {
              this.allDataService.listForceReloadSubs.next('item-list');
              this.allDataService.listForceReloadSubs.next('party-list');
              this.allDataService.listForceReloadSubs.next('moneyout-list');
            }, 2000);
    
            return savedSaleReturn;
          } else {
            return null;
          }
        }
      } catch (error) {
        SentryUtilites.setLog("SaleReturnFormPage:addSaleReturn", error)
        return null;
      }
    }
  
    async updateSaleReturn() {
      try {
        if (this.form.valid) {
          let saleReturn = new SaleReturn();
    
          saleReturn = {
            ...this.fetchedSaleReturn,
            ...this.form.value
          };

          if(!saleReturn?.billCompleteStamp) {
            if (Number(this.form?.value?.amountPaid)) {
              let moneyOut = new MoneyOut();
              moneyOut.party = saleReturn.party;
              moneyOut.billDateStamp = saleReturn.billDateStamp;
              moneyOut.totalAmount = this.form.value.amountPaid;
              moneyOut.billNo = await this.allDataService.moneyOutService.getNewMoneyOutNo();
              moneyOut.paymentMode = this.paymentMode || 'cash';
              moneyOut.paymentId = this.paymentIdEle?.value as string || null;
              saleReturn.moneyOuts = [moneyOut];
            }
          }else {
            saleReturn.moneyOuts = this.moneyOutSelector?.getSelectedMoneyOuts() || [];
          }
    
          saleReturn.billCompleteStamp = +new Date();
          if(!this.isStaffAssignToSaleReturn) {
            saleReturn.lastModifiedBy = this.authService.getLoginPhone();
            saleReturn.lastModifiedByName = Utility.getCreatedByName();
          }
          let updatedSaleReturn = await this.allDataService.saleReturnService.update(saleReturn);
          this.allDataService.itemService.reloadList();
          this.allDataService.partyService.reloadList();
          this.allDataService.moneyOutService.reloadList();
          this.allDataService.saleService.reloadList();
          this.allDataService.listForceReloadSubs.next('sale-return-list');
          if(updatedSaleReturn?.party?._localUUID) {
            let updatedParty=await this.allDataService.partyService.getByUUID(updatedSaleReturn?.party?._localUUID);
            this.allDataService.partyService.updateSubs.next(updatedParty);
          }
          if(updatedSaleReturn?.billItems?.length){
            for(let i=0;i<updatedSaleReturn.billItems.length;i++){
              let billItem = updatedSaleReturn?.billItems[i]?.item;
              if(billItem?._localUUID) {
                let updatedItem=await this.allDataService.itemService.getByUUID(billItem?._localUUID);
                this.allDataService.itemService.updateSubs.next(updatedItem);
              }
            }
          }
    
          if(updatedSaleReturn?.moneyOuts?.length){
            for(let i=0;i<updatedSaleReturn.moneyOuts.length;i++){
              if(updatedSaleReturn?.moneyOuts[i]?._localUUID) {
                let moneyOut = updatedSaleReturn?.moneyOuts[i];
                if(moneyOut?._localUUID) {
                  let updatedMoneyIn=await this.allDataService.moneyOutService.getByUUID(moneyOut?._localUUID);
                  this.allDataService.moneyOutService.updateSubs.next(updatedMoneyIn);
                }
              }
            }
          }
    
          setTimeout(()=> {
            this.allDataService.listForceReloadSubs.next('item-list');
            this.allDataService.listForceReloadSubs.next('party-list');
            this.allDataService.listForceReloadSubs.next('moneyout-list');
          }, 2000);
    
          return updatedSaleReturn;
        } else {
          return null;
        }
      } catch (error) {
        SentryUtilites.setLog("SaleReturnFormPage:updateSaleReturn", error)
        return null;
      }
    }
  
    async save() {
      try {
        let savedSaleReturn = await this.addSaleReturn();
        if (savedSaleReturn?._localUUID) {
          this.allDataService.listForceReloadSubs.next('sale-return-list');
          this.itemRestaurantSelector?.openItemSelectorModal(false);
          this.router.navigate(['sale-return']);
        }
      } catch (error) {
        SentryUtilites.setLog("SaleReturnFormPage:save", error)
      }
    }
  
    async onlySave() {
      try {
        if (!this.onClickSave) {
          this.onClickSave = true;
          let savedSaleReturn = null;
    
          if (this.paramDocumentId) {
            if (this.fetchedSaleReturn?.party?._localUUID == this.form?.value?.party?._localUUID) {
              savedSaleReturn = await this.updateSaleReturn();
            } else {
              await this.allDataService.saleReturnService.delete(this.fetchedSaleReturn);
              savedSaleReturn = await this.addSaleReturn();
            }
          } else if (this.fetchedSaleReturn?._localUUID) {
            savedSaleReturn = await this.updateSaleReturn();
          } else {
            savedSaleReturn = await this.addSaleReturn();
          } 
          // if (this.fetchedSaleReturn?._localUUID) {
          //   savedSaleReturn = await this.updateSaleReturn();
          // } else {
          //   savedSaleReturn = await this.addSaleReturn();
          // }
    
          if (savedSaleReturn?._localUUID) {
            this.showTransportDetailsCard = false;
            this.onClickSave = false;
            this.resetBillForm();
            this.ngOnInit();
            this.ngAfterViewInit();
            if (Utility.getFromLocalStorage('bill_amount_sound_status')) {
              Utility.playAmountSpeech(savedSaleReturn?.totalAmount);
            }
          }
        }
      } catch (error) {
        SentryUtilites.setLog("SaleReturnFormPage:onlySave", error)
      }
    }
  
    async savePrint() {
      try {
        if (!this.onClickSave) {
          this.onClickSave = true;
          let savedSaleReturn: SaleReturn = null;
    
          if (this.paramDocumentId) {
            if (this.fetchedSaleReturn?.party?._localUUID == this.form?.value?.party?._localUUID) {
              savedSaleReturn = await this.updateSaleReturn();
            } else {
              await this.allDataService.saleReturnService.delete(this.fetchedSaleReturn);
              savedSaleReturn = await this.addSaleReturn();
            }
          } else if (this.fetchedSaleReturn?._localUUID) {
            savedSaleReturn = await this.updateSaleReturn();
          } else {
            savedSaleReturn = await this.addSaleReturn();
          }
    
          // if (this.fetchedSaleReturn?._localUUID) {
          //   savedSaleReturn = await this.updateSaleReturn();
          // } else {
          //   savedSaleReturn = await this.addSaleReturn();
          // }
          if (savedSaleReturn?._localUUID) {
            this.onClickSave = false;
            this.showTransportDetailsCard = false;
            this.itemRestaurantSelector?.openItemSelectorModal(false);
            this.resetBillForm();
            this.partySelector?.openPartySelectorModal(false);
            this.partyRestaurantSelector?.openPartySelectorModal(false);
            setTimeout(() => {
              this.router.navigate([`/bill-view-sale-return/${savedSaleReturn._localUUID}`], {
                queryParams: { isPrint: true },
              });
            }, 0);
            if (Utility.getFromLocalStorage('bill_amount_sound_status')) {
              Utility.playAmountSpeech(savedSaleReturn?.totalAmount);
            }
          }
        }
      } catch (error) {
        SentryUtilites.setLog("SaleReturnFormPage:savePrint", error)
      }
      
    }
  
    async update() {
      try {
        let savedSaleReturn = await this.updateSaleReturn();
        if (savedSaleReturn?._localUUID) {
          this.allDataService.listForceReloadSubs.next('sale-return-list');
          this.router.navigate(['sale-return']);
        }
      } catch (error) {
        SentryUtilites.setLog("SaleReturnFormPage:update", error)
      }
    }
  
    resetBillForm() {
      try {
        this.itemRestaurantSelector?.openItemSelectorModal(false);
        this.itemRestaurantSelector?.resetCart();
        this.itemSelector?.openItemSelectorModal(false);
        this.itemSelector?.resetCart();
        if(this.listStyleView) {
          this.partySelector?.resetParty();
          this.partySelector?.enable();
          this.itemSelector?.enable();
        }else {
          this.partyRestaurantSelector?.resetParty();
          this.partyRestaurantSelector?.enable();
          this.itemRestaurantSelector?.enable();
        }
    
        this.form.reset();
        this.isNgOnInitRun = false;
        this.barcodeScannerMode = false;
        this.dpDueDateValue = null;
        this.dpEWayBillDateValue = null;
        this.dpDeliveryDateValue = null;
        this.showTransportDetailsCard = false;
        this.amountPaidChecked = null;
        this.previousBalanceChecked = false;
        this.isDisableAmountPaid = false;
        this.isOpenSecondaryPartyModal = false;
  
        this.subArr?.forEach(sub => {
          sub?.unsubscribe();
        })
        this.subArr = [];
    
        if(this.paramDocumentId && this.fetchedSaleReturn?.billCompleteStamp) {
          this.fetchedSaleReturn = null;
          this.orderLocalUUID = null;
          this.router.navigate(['sale-return/form']);
          this.initializeReactiveForm();
        }else if(this.orderLocalUUID) {
          this.fetchedSaleReturn = null;
          this.orderLocalUUID = null;
          this.router.navigate(['sale-return/form']);
          this.initializeReactiveForm();
          this.openPartySelectorModal();
        }else if(this.queryParamSaleUUID) {
          this.router.navigate(['sale-return']);
        }else {
          this.paramDocumentId = null;
          this.fetchedSaleReturn = null;
          this.fetchedSaleReturn = null;
          this.queryParamSaleUUID = null;
        }
      } catch (error) {
        SentryUtilites.setLog("SaleReturnFormPage:resetBillForm", error)
      }
  
    }
  
    toggleShowAllFields() {
      this.showTransportDetailsCard = !this.showTransportDetailsCard;
    }

    openMoneyOutSelectorModal() {
      try {
        if (this.moneyOutSelector) {
          this.moneyOutSelector.openMoneyOutSelectorModal();
        } else {
          this.moneyOutSelector.openMoneyOutSelectorModal(false);
        }
      } catch (error) {
        SentryUtilites.setLog("SaleReturnFormPage:openMoneyOutSelectorModal", error)
      }
    }
  
    // Calculations
  
    CalculateBillTask = CalculateBillTask;
  
    cashDiscountAmount = 0.0;
    cashDiscountPercentage = 0.0;
  
    serviceChargeAmount = 0.0;
    serviceChargePercentage = 0.0;
  
    unitDiscountAmountTotal = 0.0;
    unitGstAmountTotal = 0.0;
    unitCessAmountTotal = 0.0;
    unitTaxAmountTotal = 0.0;
    unitSubTotalAmountTotal = 0.0;
    unitTotalAmountTotal = 0.0;
    unitTotalSavingAmountTotal = 0.0;
  
    billItemsTotal = 0.0;
    wcdBillItemsTotal = 0.0;
  
  
    calculateSubTotalAmount() {
      try {
    
        this.unitDiscountAmountTotal = 0.0;
        this.unitGstAmountTotal = 0.0;
        this.unitCessAmountTotal = 0.0;
        this.unitTaxAmountTotal = 0.0;
        this.unitSubTotalAmountTotal = 0.0;
        this.unitTotalAmountTotal = 0.0;
        this.unitTotalSavingAmountTotal = 0.0;
    
        this.billItemsTotal = 0.0;
        this.wcdBillItemsTotal = 0.0;
    
        let billItems = this.form?.value?.billItems as BillItem[];
    
        billItems?.forEach(billItem => {
          this.unitDiscountAmountTotal += billItem?.unitDiscountAmount || 0.0;
          this.unitGstAmountTotal += billItem?.itemTotalGstAmount || 0.0;
          this.unitCessAmountTotal += billItem?.itemTotalCessAmount || 0.0;
          this.unitTaxAmountTotal += billItem?.itemTotalTaxAmount || 0.0;
          this.unitSubTotalAmountTotal += billItem?.subTotal || 0.0;
          this.unitTotalAmountTotal += billItem?.total || 0.0;
          this.unitTotalSavingAmountTotal += billItem?.totalSaving || 0.0;
    
          this.billItemsTotal += billItem?.total || 0.0;
          this.wcdBillItemsTotal += billItem?.wcdTotal || 0.0;
        });
    
        this.form?.patchValue({
          discountAmount: this.unitDiscountAmountTotal,
          gstAmount: this.unitGstAmountTotal,
          cessAmount: this.unitCessAmountTotal,
          subTotalAmount: this.unitSubTotalAmountTotal,
          totalSaving: this.unitTotalSavingAmountTotal,
        });
    
    
        this.calculateServiceCharge(CalculateBillTask.SERVICE_CHARGE_FROM_PERCENTAGE);
    
        this.calculateTotalAmount();
      } catch (error) {
        SentryUtilites.setLog("SaleReturnFormPage:calculateSubTotalAmount", error)
      }
    }
  
    calculateCashDiscount(task: CalculateBillTask = CalculateBillTask.CALCULATE) {
      try {
        this.cashDiscountAmount = 0.0;
        this.cashDiscountPercentage = 0.0;
    
        if (task == CalculateBillTask.DISCOUNT_FROM_PERCENTAGE) {
          this.cashDiscountPercentage = this.form?.value?.cashDiscountPercentage;
          if(this.wcdBillItemsTotal > 0) {
            this.cashDiscountAmount = Utils.capFractionsToSix(this.wcdBillItemsTotal * this.cashDiscountPercentage / 100);
          }
          this.form?.patchValue({ cashDiscount: this.cashDiscountAmount });
        } else if (task == CalculateBillTask.DISCOUNT_FROM_AMOUNT) {
          this.cashDiscountAmount = this.form?.value?.cashDiscount;
          if(this.wcdBillItemsTotal > 0) {
            this.cashDiscountPercentage = Utils.capFractionsToSix((this.cashDiscountAmount * 100) / this.wcdBillItemsTotal);
            if(
              this.showCashDiscountPercent && 
              this.selectedProfile?.discountSoundStatus && 
              Utility.checkValidPhoneNumber(this.form?.value?.partySecondary?.phone)
              ) {
              Utility.playDiscountSpeech(Utils.capFractionsToTwo(this.showCashDiscountPercent));
            }
          }
          this.form?.patchValue({ cashDiscountPercentage: this.cashDiscountPercentage });
        }
    
        if (this.cashDiscountAmount > this.wcdBillItemsTotal || this.cashDiscountAmount < 0) {
          this.cashDiscountAmount = 0.0;
          this.cashDiscountPercentage = 0.0;
          this.form?.patchValue({
            cashDiscount: null,
            cashDiscountPercentage: null
          });
        }
    
        this.recalculateBillItems();
      } catch (error) {
        SentryUtilites.setLog("SaleReturnFormPage:calculateCashDiscount", error)
      }
    }
  
    calculateServiceCharge(task: CalculateBillTask = CalculateBillTask.CALCULATE) {
      try {
        this.serviceChargeAmount = 0.0;
        this.serviceChargePercentage = 0.0;
    
        if(this.form?.value?.serviceChargePercentage < 0 || this.form?.value?.serviceChargeAmount < 0) {
          this.form?.patchValue({ serviceChargePercentage: 0, serviceChargeAmount: 0 });
        } else if (task == CalculateBillTask.SERVICE_CHARGE_FROM_PERCENTAGE) {
          this.serviceChargePercentage = this.form?.value?.serviceChargePercentage;
          if(this.unitSubTotalAmountTotal > 0) {
            this.serviceChargeAmount = Utils.capFractionsToSix(this.unitSubTotalAmountTotal * this.serviceChargePercentage / 100);
          }
          this.form?.patchValue({ serviceChargeAmount: this.serviceChargeAmount });
        } else if (task == CalculateBillTask.SERVICE_CHARGE_FROM_AMOUNT) {
          this.serviceChargeAmount = this.form?.value?.serviceChargeAmount;
          if(this.unitSubTotalAmountTotal > 0) {
            this.serviceChargePercentage = Utils.capFractionsToSix((this.serviceChargeAmount * 100) / this.unitSubTotalAmountTotal);
          }
          this.form?.patchValue({ serviceChargePercentage: this.serviceChargePercentage });
        }
    
        if (this.cashDiscountAmount < 0) {
          this.cashDiscountAmount = 0.0;
          this.cashDiscountPercentage = 0.0;
          this.form?.patchValue({
            cashDiscountAmount: null,
            cashDiscountPercentage: null
          });
        }
      } catch (error) {
        SentryUtilites.setLog("SaleReturnFormPage:calculateServiceCharge", error)
      }
  
    }
  
    calculateTotalAmount() {
      try {
        let additionalCharges = this.form?.value?.transportDetail?.additionalAmount || 0;
        if(!Utility.isNumber(additionalCharges)) {
          additionalCharges = 0.0;
        }
    
        let serviceChargeAmount = this.form?.value?.serviceChargeAmount || 0;
        if(!Utility.isNumber(serviceChargeAmount)) {
          serviceChargeAmount = 0.0;
        }
    
        let totalAmount = this.unitTotalAmountTotal + Number(additionalCharges) + Number(serviceChargeAmount);
        totalAmount = Utils.capFractionsToSix(totalAmount);
    
        let roundOffValue = 0.0;
        if(this.selectedProfile?.iSetRoundOffTotalAmountStatus) {
          let floorDelta = (totalAmount || 0.0) - Math.floor(totalAmount || 0.0);
          let ceilDelta = Math.ceil(totalAmount || 0.0) - (totalAmount || 0.0);
    
          if(floorDelta > ceilDelta) {
            roundOffValue = ceilDelta;
          }else {
            roundOffValue = floorDelta * -1;
          }
          roundOffValue = Utils.capFractionsToSix(roundOffValue);
          totalAmount = Math.round(totalAmount + roundOffValue);
        }
    
    
        this.form?.patchValue({
          totalAmount,
          roundOffValue
        });
    
        if (this.amountPaidChecked && !this.fetchedSaleReturn && !this.fetchedSale) {
          this.populateAmountPaid(this.fetchedSaleReturn || this.fetchedSale ? this.fetchedSaleReturn?.amountPaid || this.fetchedSale?.amountReceived : this.form?.value?.totalAmount);
        }
      } catch (error) {
        SentryUtilites.setLog("SaleReturnFormPage:calculateTotalAmount", error)
      }
  
    }
  
    calculateAdditionalDiscount() {
      try {
        if(this.form?.value?.partySecondary) {
    
          this.showCashDiscountPercent = 0.0;
    
          let isValidDiscountI = false;
          let isValidDiscountII = false;
    
          let discountPercentI = this.selectedProfile?.dSetDiscountPercentI;
          let discountMaximumAmountI = this.selectedProfile?.dSetDiscountMaximumAmountI;
          let discountMinimumAmountI = this.selectedProfile?.dSetDiscountMinimumAmountI;
          let discountPercentII = this.selectedProfile?.dSetDiscountPercentII;
          let discountMaximumAmountII = this.selectedProfile?.dSetDiscountMaximumAmountII;
          let discountMinimumAmountII = this.selectedProfile?.dSetDiscountMinimumAmountII;
    
          let additionalDiscountAmountI = 0.0;
          let additionalDiscountAmountII = 0.0;
    
          let lastSaleStamp = this.form?.value?.partySecondary?.lastSaleStamp || 0;
    
          // if discount-1 is on
          if(this.selectedProfile?.dSetDiscountStatusI) {
            if ((this.selectedProfile?.dSetDiscountOfferTypeI || 0) == 0) {
              isValidDiscountI = true;
            } else if (this.selectedProfile?.dSetDiscountOfferTypeI == 1) {
              let discountExpiryStamp = this.selectedProfile?.dSetDiscountExpiryDaysI * 86400000; 
              if (lastSaleStamp > 0 && discountExpiryStamp > 0) {
                if ((lastSaleStamp + discountExpiryStamp) > +new Date()) {
                  isValidDiscountI = true;
                }
              }
            }
    
            if (isValidDiscountI && discountMinimumAmountI <= this.unitTotalAmountTotal) {
              additionalDiscountAmountI = (this.unitTotalAmountTotal / 100) * discountPercentI;
              if (discountMaximumAmountI && additionalDiscountAmountI > discountMaximumAmountI) {
                additionalDiscountAmountI = discountMaximumAmountI;
              }
            } else {
              isValidDiscountI = false;
            }
          }
    
          // if discount-2 is on
          if (this.selectedProfile?.dSetDiscountStatusII) {
            if ((this.selectedProfile?.dSetDiscountOfferTypeII || 0) == 0) {
              isValidDiscountII = true;
            } else if (this.selectedProfile?.dSetDiscountOfferTypeII == 1) {
              let discountExpiryStamp = this.selectedProfile?.dSetDiscountExpiryDaysII * 86400000; 
              if (lastSaleStamp > 0 && discountExpiryStamp > 0) {
                if ((lastSaleStamp + discountExpiryStamp) > +new Date()) {
                  isValidDiscountII = true;
                }
              }
            }
    
            if (isValidDiscountII && discountMinimumAmountII <= this.unitTotalAmountTotal) {
              additionalDiscountAmountII = (this.unitTotalAmountTotal / 100) * discountPercentII;
              if (discountMaximumAmountII && additionalDiscountAmountII > discountMaximumAmountII) {
                additionalDiscountAmountII = discountMaximumAmountII;
              }
            } else {
              isValidDiscountII = false;
            }
          }
    
          this.eventService.setCustomerRepeated(false);
    
          this.cashDiscountAmount = 0.0;
          this.cashDiscountPercentage = 0.0;
          if(isValidDiscountI && isValidDiscountII) {
            this.cashDiscountAmount = additionalDiscountAmountI > additionalDiscountAmountII ? additionalDiscountAmountI : additionalDiscountAmountII;
            if(additionalDiscountAmountI || additionalDiscountAmountII) {
              this.showCashDiscountPercent = additionalDiscountAmountI > additionalDiscountAmountII ? discountPercentI : discountPercentII;
            }
            if(additionalDiscountAmountI > additionalDiscountAmountII && this.selectedProfile?.dSetDiscountOfferTypeI == 1) {
              this.eventService.setCustomerRepeated(true);
            } else if(additionalDiscountAmountII > additionalDiscountAmountI && this.selectedProfile?.dSetDiscountOfferTypeII == 1) {
              this.eventService.setCustomerRepeated(true);
            }
          } else if (isValidDiscountI && additionalDiscountAmountI) {
            this.cashDiscountAmount = additionalDiscountAmountI;
            this.showCashDiscountPercent = discountPercentI;
            if(this.selectedProfile?.dSetDiscountOfferTypeI == 1) {
              this.eventService.setCustomerRepeated(true);
            }
          } else if (isValidDiscountII && additionalDiscountAmountII) {
            this.cashDiscountAmount = additionalDiscountAmountII;
            this.showCashDiscountPercent = discountPercentII;
            if(this.selectedProfile?.dSetDiscountOfferTypeII == 1) {
              this.eventService.setCustomerRepeated(true);
            }
          }
    
          this.form?.patchValue({
            cashDiscount: this.cashDiscountAmount,
            cashDiscountPercentage: null
          });
          this.calculateCashDiscount(CalculateBillTask.DISCOUNT_FROM_AMOUNT);
        }
      } catch (error) {
        SentryUtilites.setLog("SaleReturnFormPage:calculateAdditionalDiscount", error)
      }
    }
  
    recalculateBillItems() {
      try {
        let billItems = this.form?.value?.billItems;
        billItems?.forEach(billItem => {
          BillCalculations.calculateBillItem(billItem,BillType.SALERETURN,null,this.form?.value?.cashDiscountPercentage);
        });
        this.itemRestaurantSelector?.setBillItemsNoEmitNoCal(billItems);
        this.form?.patchValue({
          billItems
        });
        this.calculateSubTotalAmount();
      } catch (error) {
        SentryUtilites.setLog("SaleReturnFormPage:recalculateBillItems", error)
      }
    }
  
    /**
     * 
     * @param party : provide party object
     * @description : store party object in partySecondary
     */
    savedSecondaryParty(party: Party) {
      try {
        if(party?._localUUID) {
          this.form?.patchValue({
            partySecondary: party,
          })
        }
      } catch (error) {
        SentryUtilites.setLog("SaleReturnFormPage:savedSecondaryParty", error)
      }
    }
    // ----------------------------------
  
    /**
     * 
     * @param event : provide object of userId and name
     * @description : store value of userId, createdBy, lastModifiedBy, createdByName, lastModifiedByName
     */
    setSelectedStaff(event) {
      try {
        let userId = event?.userId || this.authService.getLoginPhone();
        let name = event?.name || event?.userId || Utility.getCreatedByName();
        this.form?.patchValue({
          lastModifiedBy: userId,
          lastModifiedByName: name,
        })
        
        if(!this.paramDocumentId) {
          this.form?.patchValue({
            createdBy: userId,
            createdByName: name,
          })
        }
      } catch (error) {
        SentryUtilites.setLog("SaleReturnFormPage:setSelectedStaff", error)
      }
    }
    // ---------------------------------
  
    /**
     * @description : close date picker
     */
    closeDOBPicker() {
      try {
        this.isOpenBillDatePicker = false;
        this.isOpenSaleBillDatePicker = false;
        this.isOpenDueDatePicker = false;
        this.isOpenEwayBillDatePicker = false;
        this.isOpenDeliveryDatePicker = false;
      } catch (error) {
        SentryUtilites.setLog("SaleReturnFormPage:closeDOBPicker", error)
      }
    }
    // ---------------------------------

}
