import { MonthWisePartyCreditDao } from './dao/month-wise-party-credit.dao';
import { MonthWiseItemStockDao } from './dao/month-wise-item-stock.dao';
import { BehaviorSubject } from "rxjs";
import { SaleReturn } from "../models/SaleReturn.model";
import { Utility } from "../utils/utility";
import { ExpressServerService } from "./api/express-server.service";
import { IDataService } from "../../interface/IDataService.interface";
import { ItemDao } from "./dao/item.dao";
import { PartyDao } from "./dao/party.dao";
import { MoneyOutDao } from "./dao/money-out.dao";
import { SaleReturnDao } from "./dao/sale-return.dao";
import { MoneyOut } from "../models/MoneyOut.model";
import { AuthService } from "./auth/auth.service";
import { PartyItemPriceMapDao } from "./dao/party-item-price-map.dao";
import PartyItemPriceMap from "../models/PartyItemPriceMap.model";
import { ProfileDao } from "./dao/profile.dao";
import { Utils } from './../utils/utils';
import { SentryUtilites } from '../utils/sentryUtilites';
import { SaleDao } from './dao/sale.dao';

export class SaleReturnService implements IDataService<SaleReturn>{
  private static _instance: SaleReturnService;

  public static getInstance(
    saleReturnDao: SaleReturnDao,
    partyDao: PartyDao,
    itemDao: ItemDao,
    saleDao: SaleDao,
    moneyOutDao: MoneyOutDao,
    partyItemPriceMapDao: PartyItemPriceMapDao,
    profileDao: ProfileDao,
    expressServerService: ExpressServerService,
    authService: AuthService,
    monthWisePartyCreditDao: MonthWisePartyCreditDao,
    monthWiseItemStockDao: MonthWiseItemStockDao,
  ) {
    if (!this._instance) {
      this._instance = new SaleReturnService(
        saleReturnDao,
        partyDao,
        itemDao,
        saleDao,
        moneyOutDao,
        partyItemPriceMapDao,
        profileDao,
        expressServerService,
        authService,
        monthWisePartyCreditDao,
        monthWiseItemStockDao,
      )
      this._instance.initService();
    }
    this._instance.reloadList();
    return this._instance;
  }

  constructor(
    dao: SaleReturnDao,
    partyDao: PartyDao,
    itemDao: ItemDao,
    saleDao: SaleDao,
    moneyOutDao: MoneyOutDao,
    partyItemPriceMapDao: PartyItemPriceMapDao,
    profileDao: ProfileDao,
    expressServerService: ExpressServerService,
    authService: AuthService,
    monthWisePartyCreditDao: MonthWisePartyCreditDao,
    monthWiseItemStockDao: MonthWiseItemStockDao,
  ) {
    this.dao = dao;
    this.expressServerService = expressServerService;
    this.itemDao = itemDao
    this.partyDao = partyDao;
    this.saleDao = saleDao;
    this.moneyOutDao = moneyOutDao;
    this.partyItemPriceMapDao = partyItemPriceMapDao;
    this.profileDao = profileDao;
    this.authService = authService;
    this.monthWisePartyCreditDao = monthWisePartyCreditDao;
    this.monthWiseItemStockDao = monthWiseItemStockDao;
  }
  dao: SaleReturnDao;
  itemDao: ItemDao;
  partyDao: PartyDao;
  saleDao: SaleDao;
  moneyOutDao: MoneyOutDao;
  partyItemPriceMapDao: PartyItemPriceMapDao;
  profileDao: ProfileDao;
  expressServerService: ExpressServerService;
  authService: AuthService;
  monthWisePartyCreditDao: MonthWisePartyCreditDao;
  monthWiseItemStockDao: MonthWiseItemStockDao;

  LIST_REFRESH_RATE = 1000;
  selectedProfileId: string = null;
  selectedProfileUserId: string = null;
  updateSubs = new BehaviorSubject<SaleReturn>(null);


  lastReloadStamp: number = 0;
  isReloadPostpond = false;

  initService() {
    this.selectedProfileId = Utility.getFromLocalStorage('selectedProfile');
    this.selectedProfileUserId = Utility.getFromLocalStorage('selectedProfileUserId');
    this.reloadList();
  }


  async reloadList() {
    try {
      if (this.isReloadPostpond) {
        return;
      }
      const currentStamp = +new Date();
      if (this.lastReloadStamp < (currentStamp - this.LIST_REFRESH_RATE)) {
        this.lastReloadStamp = currentStamp;
        this.trySyncUnsynced();
      } else {
        this.isReloadPostpond = true;
        setTimeout(() => {
          this.isReloadPostpond = false;
          this.reloadList();
        }, this.LIST_REFRESH_RATE + 100);
      }
    } catch (error) {
      SentryUtilites.setLog("SaleReturnService:reloadList", error)
      return null;
    }
  }

  getAll() {
    return this.dao.getAll();
  }
  
  getAllByPromise() {
    return this.dao.getAllByProfile(this.selectedProfileId);
  }

  getAllByPromiseByProfile(profileId: string) {
    return this.dao.getAllByProfile(profileId);
  }

  getAllByProfileWithOnlyRunningBill() {
    return this.dao.getAllByProfileWithOnlyRunningBill(this.selectedProfileId);
  }

  getAllByProfileWithRunningBill() {
    return this.dao.getAllByProfileWithRunningBill(this.selectedProfileId);
  }

  getAllWithDeletedByProfile() {
    return this.dao.getAllWithDeletedByProfile(this.selectedProfileId);
  }

  getByBillDateRange(startTime: number, endTime: number, profileId?: string): Promise<SaleReturn[]> {
    return new Promise(async (resolve, reject) => {
      try {
        let allDocs = await this.dao.getAllByProfile(profileId || this.selectedProfileId);
        if (allDocs != null) {
          let filteredDocs = allDocs.filter(doc => doc?.billDateStamp >= startTime && doc?.billDateStamp < endTime);
          return resolve(filteredDocs);
        } else {
          return resolve(null);
        }
      } catch (error) {
        SentryUtilites.setLog("SaleReturnService:getByBillDateRange", error)
        return resolve(null);
      }
    });
  }

  getByCreatedDateRange(startTime: number, endTime: number): Promise<SaleReturn[]> {
    return new Promise(async (resolve, reject) => {
      try {
        let allDocs = await this.dao.getAllByProfile(this.selectedProfileId);
        if (allDocs != null) {
          let filteredDocs = allDocs.filter(doc => doc?.createdStamp >= startTime && doc?.createdStamp < endTime);
          return resolve(filteredDocs);
        } else {
          return resolve(null);
        }
      } catch (error) {
        SentryUtilites.setLog("SaleReturnService:getByCreatedDateRange", error)
        return resolve(null);
      }
    });
  }

  getByBillCompleteDateRange(startTime: number, endTime: number): Promise<SaleReturn[]> {
    return new Promise(async (resolve, reject) => {
      try {
        let allDocs = await this.dao.getAllByProfile(this.selectedProfileId);
        if (allDocs != null) {
          let filteredDocs = allDocs.filter(doc => doc?.billCompleteStamp >= startTime && doc?.billCompleteStamp < endTime);
          return resolve(filteredDocs);
        } else {
          return resolve(null);
        }
      } catch (error) {
        SentryUtilites.setLog("SaleReturnService:getByBillCompleteDateRange", error)
        return resolve(null);
      }
    });
  }

  getById(id: number): Promise<SaleReturn> {
    return this.dao.getById(id);
  }

  getByUUID(uuid: string): Promise<SaleReturn> {
    return this.dao.getByUUID(uuid);
  }

  save(saleReturn: SaleReturn): Promise<SaleReturn> {
    return new Promise(async (resolve, reject) => {
      try {
        if(Utility.isTruthy(saleReturn)) {
          let currentProfile = this.selectedProfileId;
          saleReturn.userId = this.selectedProfileUserId;
          if(!saleReturn.profileId) {
            saleReturn.profileId = currentProfile;
          }
          if(!saleReturn?.createdBy) {
            saleReturn.createdBy = saleReturn.lastModifiedBy = this.authService.getLoginPhone();
          }
          if(!saleReturn?.createdByName) {
            saleReturn.createdByName = saleReturn.lastModifiedByName = Utility.getCreatedByName();
          }
  
          if (!saleReturn._localUUID) {
            saleReturn._localUUID = Utility.getUUID();
          }
  
          if(!saleReturn.amountPaid) {
            saleReturn.amountPaid = 0.0;
          }
  
  
          if (saleReturn?.moneyOuts?.length === 1) {
            saleReturn.moneyOuts[0].userId = this.selectedProfileUserId;
            saleReturn.moneyOuts[0].profileId = this.selectedProfileId;
            saleReturn.moneyOuts[0].createdBy = saleReturn.moneyOuts[0].lastModifiedBy = saleReturn?.createdBy;
            saleReturn.moneyOuts[0].createdByName = saleReturn.moneyOuts[0].lastModifiedByName = saleReturn?.createdByName;
            saleReturn.moneyOuts[0].linkedSaleReturnUUID = saleReturn?._localUUID;
            saleReturn.moneyOuts[0]._localUUID = Utility.getUUID();
            saleReturn.amountPaid = saleReturn?.moneyOuts[0]?.totalAmount || 0.0;
          }
  
          let savedSaleReturn = await this.dao.save(saleReturn);
  
          if(savedSaleReturn?.billCompleteStamp) {
  
            // Link Sale
            if(savedSaleReturn?.linkedSaleUUID) {
              await this.saleDao.linkSaleReturn(savedSaleReturn?.linkedSaleUUID, savedSaleReturn?._localUUID);
            }
  
            //MoneyOut
            //----------------------------------------
            if (savedSaleReturn.moneyOuts?.length === 1) {
  
              let savedMoneyOut = await this.moneyOutDao.save(savedSaleReturn?.moneyOuts[0]);
              if(savedMoneyOut?._localUUID) {
                let totalAmount = savedMoneyOut?.totalAmount || 0.0;
                if (totalAmount > 0) {
                  savedSaleReturn.party.lastModifiedBy = saleReturn?.lastModifiedBy;
                  savedSaleReturn.party.lastModifiedByName = saleReturn?.lastModifiedByName;
                  await this.partyDao.updateCredit(
                    savedMoneyOut?.party,
                    totalAmount,
                  )
                  await this.monthWisePartyCreditDao?.modifyCredit(
                    savedMoneyOut?.party?._localUUID,
                    savedMoneyOut?.billDateStamp,
                    totalAmount
                  )
                }
              }
            }
            //----------------------------------------
  
            //Party
            //----------------------------------------
            savedSaleReturn.party.lastModifiedBy = saleReturn?.lastModifiedBy;
            savedSaleReturn.party.lastModifiedByName = saleReturn?.lastModifiedByName;
            await this.partyDao.updateCredit(
              savedSaleReturn?.party,
              -savedSaleReturn?.totalAmount
            )
            await this.monthWisePartyCreditDao.modifyCredit(
              savedSaleReturn?.party?._localUUID,
              savedSaleReturn?.billDateStamp,
              -savedSaleReturn?.totalAmount
            )
  
            if(savedSaleReturn?.partySecondary?._localUUID) {
              savedSaleReturn.partySecondary.lastModifiedBy = saleReturn?.lastModifiedBy;
              savedSaleReturn.partySecondary.lastModifiedByName = saleReturn?.lastModifiedByName;
              await this.partyDao.updateLastSaleStamp(
                savedSaleReturn?.partySecondary,
                savedSaleReturn?.createdStamp
              );
            }
            
            //Item & Price Map Update
            let allPriceMaps = await this.partyItemPriceMapDao.getAllByProfile(this.selectedProfileId);
            let partyPriceMap = allPriceMaps.filter(x => x?.partyUUID == savedSaleReturn?.party?._localUUID);
            let currentProfileData = await this.profileDao.getByUUID(this.selectedProfileId);
            for (let i = 0; i < savedSaleReturn.billItems?.length; i++) {
              let billItem = savedSaleReturn?.billItems[i];
              if(billItem?.item?._localUUID) {
                billItem.item.lastModifiedBy = saleReturn?.lastModifiedBy;
                billItem.item.lastModifiedByName = saleReturn?.lastModifiedByName;
                let qty = billItem?.unit === billItem?.item?.primaryUnit ? billItem?.quantity : Utils.capFractionsToTwo(billItem?.quantity / billItem?.convertRatioMultiplier);
                await this.itemDao.updateStock(
                  billItem?.item,
                  qty
                );
                await this.monthWiseItemStockDao?.modifyStock(
                  billItem?.item?._localUUID,
                  savedSaleReturn?.billDateStamp,
                  qty
                )
                if(currentProfileData?.iSetItemPriceHistoryStatus) {
                  let primaryUnitPrice = billItem?.price * billItem?.convertRatioMultiplier;
    
                  let priceMap = partyPriceMap.filter(x => x?.itemUUID == billItem?.item?._localUUID)[0];
                  if(priceMap?._localUUID) {
                    priceMap.sellPrice = primaryUnitPrice;
                    priceMap.lastModifiedBy = this.authService.getLoginPhone();
                    priceMap.lastModifiedByName = Utility.getCreatedByName();
                    await this.partyItemPriceMapDao.update(priceMap);
                  }else {
                    let newPriceMap = new PartyItemPriceMap();
                    newPriceMap.partyUUID = savedSaleReturn?.party?._localUUID;
                    newPriceMap.itemUUID = billItem?.item?._localUUID;
                    newPriceMap.sellPrice = primaryUnitPrice;
                    newPriceMap.userId = this.selectedProfileUserId;
                    newPriceMap.profileId = currentProfile;
                    newPriceMap.createdBy = newPriceMap.lastModifiedBy = this.authService.getLoginPhone();
                    newPriceMap.createdByName = newPriceMap.lastModifiedByName = Utility.getCreatedByName();
                    await this.partyItemPriceMapDao.save(newPriceMap);
                  }
                }
              }
  
            }
  
            this.partyItemPriceMapDao.resetCachePartyItemMap();
  
            //----------------------------------------
  
          }
  
          this.reloadList();
          return resolve(savedSaleReturn);
        } else {
          return resolve(null);
        }

      } catch (err) {
        SentryUtilites.setLog("SaleReturnService:save", err)
        return resolve(null)
      }

    });
  }

  update(saleReturn: SaleReturn): Promise<SaleReturn> {
    // saleReturn.lastModifiedBy = this.authService.getLoginPhone();
    // saleReturn.lastModifiedByName = Utility.getCreatedByName();
    return new Promise(async (resolve, reject) => {
      try {
        if (saleReturn?._localUUID) {
          let oldSaleReturn = await this.getByUUID(saleReturn._localUUID);

          if (
            oldSaleReturn.party?._localUUID != null
            && oldSaleReturn.party?._localUUID != ""
            && saleReturn.party?._localUUID != ""
            && oldSaleReturn.party?._localUUID == saleReturn.party?._localUUID
          ) {

            if(saleReturn?.moneyOuts?.length) {
              let totalAmountReceived = 0.0;
              saleReturn?.moneyOuts?.forEach(x => totalAmountReceived += Number(x?.totalAmount));
              saleReturn.amountPaid = totalAmountReceived;
            }else {
              saleReturn.amountPaid = 0.0;
            }

            let addMoneyOuts: MoneyOut[] = [];
            let updateMoneyOuts: MoneyOut[] = [];
            let deleteMoneyOuts: MoneyOut[] = [];

            saleReturn?.moneyOuts?.forEach(newMoneyOut => {
              let isMatched = false;
              oldSaleReturn?.moneyOuts?.forEach(oldMoneyOut => {
                if(oldMoneyOut?._localUUID === newMoneyOut?._localUUID) {
                  isMatched = true;
                  return;
                }
              });
              if(isMatched) {
                // ConstraintError: Unable to add key to index '_localUUID': at least one key does not satisfy the uniqueness requirements.
                // getting this error if _localId is not match with your current indexedDb _localId at time of update and delete.
                delete newMoneyOut?._localId;
                updateMoneyOuts.push(newMoneyOut);
              }else {
                newMoneyOut._localUUID = Utility.getUUID();
                newMoneyOut.profileId = saleReturn?.profileId;
                newMoneyOut.userId = saleReturn?.userId;
                newMoneyOut.createdBy = newMoneyOut.lastModifiedBy = saleReturn?.lastModifiedBy;
                newMoneyOut.createdByName = newMoneyOut.lastModifiedByName = saleReturn?.lastModifiedByName;
                newMoneyOut.linkedSaleReturnUUID = saleReturn?._localUUID;
                newMoneyOut.party = saleReturn?.party;
                addMoneyOuts.push(newMoneyOut);
              }
            });

            oldSaleReturn?.moneyOuts?.forEach(oldMoneyOut => {
              if(oldMoneyOut?._localUUID) {
                let shouldDelete = true;
                saleReturn?.moneyOuts?.forEach(newMoneyOut => {
                  if(oldMoneyOut?._localUUID === newMoneyOut?._localUUID) {
                    shouldDelete = false;
                  }
                });
                if(shouldDelete) {
                  deleteMoneyOuts.push(oldMoneyOut);
                }
              }
            });

            saleReturn.moneyOuts = [...addMoneyOuts, ...updateMoneyOuts];

            let updatedSaleReturn = await this.dao.update(saleReturn);

            if(updatedSaleReturn?.billCompleteStamp) {

              //MoneyOut

              if(addMoneyOuts?.length) {
                for (let i = 0; i < addMoneyOuts?.length; i++) {
                  let moneyOut = addMoneyOuts[i];
                  let savedMoneyOut = await this.moneyOutDao.save(moneyOut);
                  if(savedMoneyOut?._localUUID) {
                    savedMoneyOut.party.lastModifiedBy = saleReturn.lastModifiedBy;
                    savedMoneyOut.party.lastModifiedByName = saleReturn.lastModifiedByName;
                    await this.partyDao.updateCredit(
                      savedMoneyOut?.party,
                      savedMoneyOut?.totalAmount
                    );
                    await this.monthWisePartyCreditDao?.modifyCredit(
                      savedMoneyOut?.party?._localUUID,
                      savedMoneyOut?.billDateStamp,
                      savedMoneyOut?.totalAmount
                    )
                  }
                }
              }

              if(updateMoneyOuts?.length) {
                for (let i = 0; i < updateMoneyOuts?.length; i++) {
                  let oldMoneyOut = await this.moneyOutDao.getByUUID(updateMoneyOuts[i]?._localUUID);
                  if(oldMoneyOut?._localUUID) {
                    let newMoneyOut = {...updateMoneyOuts[i]};
                    delete newMoneyOut?.party;
                    oldMoneyOut = {...oldMoneyOut,...newMoneyOut};
                    let savedMoneyOut = await this.moneyOutDao.update(oldMoneyOut);
                    if(savedMoneyOut?._localUUID) {
                      let deltaAmount = savedMoneyOut?.totalAmount - oldMoneyOut?.totalAmount;
                      savedMoneyOut.party.lastModifiedBy = saleReturn?.lastModifiedBy;
                      savedMoneyOut.party.lastModifiedByName = saleReturn?.lastModifiedByName;
                      await this.partyDao.updateCredit(
                        savedMoneyOut?.party,
                        deltaAmount
                      );
                      await this.monthWisePartyCreditDao?.modifyCredit(
                        savedMoneyOut?.party?._localUUID,
                        savedMoneyOut?.billDateStamp,
                        deltaAmount
                      )
                    }
                  }
                }
              }

              if(deleteMoneyOuts?.length) {
                for (let i = 0; i < deleteMoneyOuts?.length; i++) {
                  let oldMoneyOut = await this.moneyOutDao.getByUUID(deleteMoneyOuts[i]?._localUUID);
                  if(oldMoneyOut?._localUUID) {
                    let newMoneyOut = {...deleteMoneyOuts[i]};
                    delete newMoneyOut.party;
                    oldMoneyOut = {...oldMoneyOut,...newMoneyOut};
                    let savedMoneyOut = await this.moneyOutDao.delete(oldMoneyOut);
                    if(savedMoneyOut?._localUUID) {
                      savedMoneyOut.party.lastModifiedBy = saleReturn?.lastModifiedBy;
                      savedMoneyOut.party.lastModifiedByName = saleReturn?.lastModifiedByName;
                      await this.partyDao.updateCredit(
                        savedMoneyOut?.party,
                        -savedMoneyOut?.totalAmount
                      );
                      await this.monthWisePartyCreditDao?.modifyCredit(
                        savedMoneyOut?.party?._localUUID,
                        savedMoneyOut?.billDateStamp,
                        -savedMoneyOut?.totalAmount
                      )
                    }
                  }
                }
              }

              //------------------------------------------------------------------


              //Party
              //------------------------------------------------------------------
              let deltaPartyCredit:number = 0;
              if(!oldSaleReturn?.billCompleteStamp && updatedSaleReturn?.billCompleteStamp) {
                deltaPartyCredit = updatedSaleReturn?.totalAmount || 0.0;
              }else {
                deltaPartyCredit = (updatedSaleReturn?.totalAmount || 0.0) - (oldSaleReturn?.totalAmount || 0.0)
              }

              updatedSaleReturn.party.lastModifiedBy = saleReturn?.lastModifiedBy;
              updatedSaleReturn.party.lastModifiedByName = saleReturn?.lastModifiedByName;

              await this.partyDao.updateCredit(
                updatedSaleReturn?.party,
                -deltaPartyCredit
              )
              await this.monthWisePartyCreditDao?.modifyCredit(
                updatedSaleReturn?.party?._localUUID,
                updatedSaleReturn?.billDateStamp,
                -deltaPartyCredit
              )

              //Item
              //----------------------------------------
              if(oldSaleReturn?.billCompleteStamp) {
                for (let i = 0; i < oldSaleReturn?.billItems?.length; i++) {
                  let billItem = oldSaleReturn?.billItems[i];
                  if(billItem?.item?._localUUID) {
                    billItem.item.lastModifiedBy = saleReturn?.lastModifiedBy;
                    billItem.item.lastModifiedByName = saleReturn?.lastModifiedByName;
                    let qty = billItem?.unit === billItem?.item?.primaryUnit ? billItem?.quantity : Utils.capFractionsToTwo(billItem?.quantity / billItem?.convertRatioMultiplier);
                    await this.itemDao.updateStock(
                      billItem?.item,
                      -qty
                    );
                    await this.monthWiseItemStockDao?.modifyStock(
                      billItem?.item?._localUUID,
                      oldSaleReturn?.billDateStamp,
                      -qty
                    )
                  }
                }
              }

              let allPriceMaps = await this.partyItemPriceMapDao.getAllByProfile(this.selectedProfileId);
              let partyPriceMap = allPriceMaps.filter(x => x.partyUUID == updatedSaleReturn?.party?._localUUID);
              let currentProfileData = await this.profileDao.getByUUID(this.selectedProfileId);

              for (let i = 0; i < updatedSaleReturn?.billItems?.length; i++) {
                let billItem = updatedSaleReturn?.billItems[i];
                if(billItem?.item?._localUUID) {
                  billItem.item.lastModifiedBy = saleReturn?.lastModifiedBy;
                  billItem.item.lastModifiedByName = saleReturn?.lastModifiedByName;
                  let qty = billItem?.unit === billItem?.item?.primaryUnit ? billItem?.quantity : Utils.capFractionsToTwo(billItem?.quantity / billItem?.convertRatioMultiplier);
                  await this.itemDao.updateStock(
                    billItem?.item,
                    qty
                  );
                  await this.monthWiseItemStockDao?.modifyStock(
                    billItem?.item?._localUUID,
                    updatedSaleReturn?.billDateStamp,
                    qty
                  )
  
                  if(currentProfileData?.iSetItemPriceHistoryStatus) {
                    let primaryUnitPrice = billItem?.price * billItem?.convertRatioMultiplier;
  
                    let priceMap = partyPriceMap.filter(x => x?.itemUUID == billItem?.item?._localUUID)[0];
                    if(priceMap?._localUUID) {
                      priceMap.sellPrice = primaryUnitPrice;
                      priceMap.lastModifiedBy = this.authService.getLoginPhone();
                      priceMap.lastModifiedByName = Utility.getCreatedByName();
                      await this.partyItemPriceMapDao.update(priceMap);
                    }else {
                      let newPriceMap = new PartyItemPriceMap();
                      newPriceMap.partyUUID = updatedSaleReturn?.party?._localUUID;
                      newPriceMap.itemUUID = billItem?.item?._localUUID;
                      newPriceMap.sellPrice = primaryUnitPrice;
                      newPriceMap.userId = this.selectedProfileUserId;
                      newPriceMap.profileId = this.selectedProfileId;
                      newPriceMap.createdBy = newPriceMap.lastModifiedBy = this.authService.getLoginPhone();
                      newPriceMap.createdByName = newPriceMap.lastModifiedByName = Utility.getCreatedByName();
                      await this.partyItemPriceMapDao.save(newPriceMap);
                    }
                  }
                }

              }

              this.partyItemPriceMapDao.resetCachePartyItemMap();

            }

            this.reloadList();
            this.updateSubs.next(updatedSaleReturn);
            return resolve(updatedSaleReturn);

          }
        }
        return resolve(null)
      } catch (err) {
        SentryUtilites.setLog("SaleReturnService:update", err)
        return resolve(null)
      }
    });
  }

  delete(saleReturn: SaleReturn): Promise<SaleReturn> {
    return new Promise(async (resolve, reject) => {
      try {
        if(saleReturn?._localUUID) {
          saleReturn.lastModifiedBy = this.authService.getLoginPhone();
          saleReturn.lastModifiedByName = Utility.getCreatedByName();
          let saleTobeDeleted = await this.getByUUID(saleReturn._localUUID)
          let deletedSaleReturn = await this.dao.delete(saleTobeDeleted);
  
          if(deletedSaleReturn?.billCompleteStamp) {

            // Unlink Sale
            if(deletedSaleReturn?.linkedSaleUUID) {
              await this.saleDao.unLinkSaleReturn(deletedSaleReturn?.linkedSaleUUID, deletedSaleReturn?._localUUID);
            }
  
            //MoneyOut
  
            if (saleTobeDeleted?.moneyOuts?.length) {
              for (let i = 0; i < saleTobeDeleted?.moneyOuts?.length; i++) {
                const moneyOut = saleTobeDeleted?.moneyOuts[i];
                let fetchedMoneyOut = await this.moneyOutDao.getByUUID(moneyOut?._localUUID);
                if(fetchedMoneyOut?._localUUID) {
                  fetchedMoneyOut.lastModifiedBy = this.authService.getLoginPhone();
                  fetchedMoneyOut.lastModifiedByName = Utility.getCreatedByName();
                  let deletedMoneyOut = await this.moneyOutDao?.delete(fetchedMoneyOut);
    
                  let totalAmount = deletedMoneyOut.totalAmount || 0.0;
                  if (totalAmount > 0) {
                    saleTobeDeleted.party.lastModifiedBy = deletedSaleReturn?.lastModifiedBy;
                    saleTobeDeleted.party.lastModifiedByName = deletedSaleReturn?.lastModifiedByName;
                    await this.partyDao.updateCredit(
                      saleTobeDeleted?.party,
                      -totalAmount
                    )
                    await this.monthWisePartyCreditDao?.modifyCredit(
                      saleTobeDeleted?.party?._localUUID,
                      saleTobeDeleted?.billDateStamp,
                      -totalAmount
                    )
                  }
                }
              }
            }
  
            //----------------------------------------
  
            //Party
            //----------------------------------------
            deletedSaleReturn.party.lastModifiedBy = deletedSaleReturn?.lastModifiedBy;
            deletedSaleReturn.party.lastModifiedByName = deletedSaleReturn?.lastModifiedByName;
            await this.partyDao.updateCredit(
              deletedSaleReturn?.party,
              (deletedSaleReturn?.totalAmount || 0.0)
            )
            await this.monthWisePartyCreditDao?.modifyCredit(
              deletedSaleReturn?.party?._localUUID,
              deletedSaleReturn?.billDateStamp,
              (deletedSaleReturn?.totalAmount || 0.0)
            )
  
            //Item
            //----------------------------------------
            for (let i = 0; i < deletedSaleReturn?.billItems?.length; i++) {
              let billItem = deletedSaleReturn?.billItems[i];
              if(billItem?.item?._localUUID) {
                billItem.item.lastModifiedBy = deletedSaleReturn?.lastModifiedBy;
                billItem.item.lastModifiedByName = deletedSaleReturn?.lastModifiedByName;
                let qty = billItem?.unit === billItem?.item?.primaryUnit ? billItem?.quantity : Utils.capFractionsToTwo(billItem?.quantity / billItem?.convertRatioMultiplier);
                await this.itemDao.updateStock(
                  billItem?.item,
                  -qty
                )
                await this.monthWiseItemStockDao?.modifyStock(
                  billItem?.item?._localUUID,
                  deletedSaleReturn?.billDateStamp,
                  -qty
                )
              }
            }
  
          }
  
          this.reloadList();
          this.updateSubs.next(deletedSaleReturn);
  
          return resolve(deletedSaleReturn);
        }
        return resolve(null);

      } catch (err) {
        SentryUtilites.setLog("SaleReturnService:delete", err)
        return resolve(null);
      }

    });
  }

  isSyncLock = false;
  isSyncPostPond = false;
  async trySyncUnsynced(postpond?: boolean) {
    try {
      if (this.isSyncLock) {
        if (!this.isSyncPostPond) {
          setTimeout(() => {
            this.isSyncPostPond = true;
            this.trySyncUnsynced(true);
          }, 200);
        }
        return true;
      }
      if (postpond) {
        this.isSyncPostPond = false;
      }
      this.isSyncLock = true;
      let unSyncedElements: SaleReturn[] = await this.dao.getAllUnsynced(this.selectedProfileId);
      if (unSyncedElements && unSyncedElements?.length) {
        try {
  
          for (let i = 0; i < unSyncedElements?.length; i++) {
            let unSyncedElement = unSyncedElements[i];
            if(unSyncedElement?._localUUID) {
              unSyncedElement['updatedStamp'] = +new Date();
            }
          }
  
          await this.dao.bulkPut(unSyncedElements);
          await Utility.wait(1000);
  
          for (let i = 0; i < unSyncedElements?.length; i++) {
            let unSyncedElement = unSyncedElements[i];
            if(unSyncedElement?._localUUID) {
              unSyncedElement['profile'] = await this.profileDao.getByUUID(unSyncedElement?.profileId);
            }
          }
  
          let chunkArr = Utility.getChunkArr(unSyncedElements);
          let chunkArrLength = chunkArr?.length;
  
          for(let i = 0; i < chunkArrLength; i++) {
            let result = await this.expressServerService.makeSyncCall('saleReturn', chunkArr[i]);
            if (result && result?.['records']?.length) {
              let arr = result?.['records'];
              for (let i = 0; i < arr?.length; i++) {
                const el = arr[i];
                await this.updateSyncStamp(el);
              }
            }
          }
        } catch (err) {
          SentryUtilites.setLog("SaleReturnService:trySyncUnsynced:inner", err)
        }
      }
      this.isSyncLock = false;
    } catch (error) {
      SentryUtilites.setLog("SaleReturnService:trySyncUnsynced", error)
    }
  }

  updateSyncStamp(el: SaleReturn): Promise<SaleReturn> {
    return new Promise(async (resolve, reject) => {
      try {
        let updatedEl = await this.dao.updateSyncStamp(el);
        this.updateSubs.next(updatedEl);
        return resolve(updatedEl);
      } catch (error) {
        SentryUtilites.setLog("SaleReturnService:updateSyncStamp", error)
        return resolve(null);
      }
    });
  }

  async getNewSaleReturnNo(): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        let saleReturns = await this.dao.getAllByProfileWithRunningBill(this.selectedProfileId);
        let nextSaleNo = 'INV_RTN_001';
        if (saleReturns[0]?.billNo) {
          nextSaleNo = Utility.nextNo(saleReturns[0]?.billNo);
        }
        return resolve(nextSaleNo);
      } catch (error) {
        SentryUtilites.setLog("SaleReturnService:getNewSaleReturnNo", error)
        return resolve(null);
      }

    });
  }

  getNewBillNo() {

  }

  /**
   * 
   * @returns : return deleted saleReturns from saleReturn dao
   */
  async getAllDeleted(): Promise<SaleReturn[]> {
    try {
      let res = await this.dao.getAllDeletedByProfile(this.selectedProfileId);
      return res || [];
    } catch (error) {
      SentryUtilites.setLog("SaleReturnService:getAllDeleted", error)
      return [];
    }
  }
  // -------------------------------------------

  copyData(fromProfileId: string, toProfileId: string): Promise<boolean> {
    return new Promise(async (resolve,reject) => {
      try {
        if(Utility.isTruthy(fromProfileId) && Utility.isTruthy(toProfileId)) {
          let fromRecords = await this.getAllByPromiseByProfile(fromProfileId);
          if(fromRecords?.length) {
            let toRecords: SaleReturn[] = [];
            for (let i = 0; i < fromRecords?.length; i++) {
              const fetchedRecord = fromRecords[i];
              if(fetchedRecord?._localUUID) {
                fetchedRecord.profileId = toProfileId;
                delete fetchedRecord?._localId;
                delete fetchedRecord?._localUUID;
                let savedRecord = await this.save(fetchedRecord);
                if(savedRecord?._localUUID) {
                  toRecords?.push(savedRecord);
                }
              }
            }
            if(fromRecords?.length === toRecords?.length) {
              return resolve(true);
            }
          }
        }
        return resolve(false);
      } catch (error) {
        SentryUtilites.setLog("SaleReturnService:copyData", error)
        return resolve(false);
      }
    });
  }

  /**
   * @description : saleReturn credit remaining for non premium account
   * @returns : return saleReturn credit remaining for non premium account
   */
    async nonPremiumSaleReturnCeditRemaining() {
      try {
        let saleReturnDataWithDeleted: SaleReturn[] = await this.dao.getAllWithDeletedByProfile(this.selectedProfileId);
        let todayStartStamp: number = +new Date().setHours(0,0,0,0);
        let todayEndStamp: number = todayStartStamp + 86400000;
    
        let todaySale: SaleReturn[] = saleReturnDataWithDeleted.filter(saleReturn => saleReturn?.createdStamp >= todayStartStamp && saleReturn?.createdStamp < todayEndStamp);
    
        return todaySale?.length < 3 ? 3 - todaySale?.length : 0;
      } catch (error) {
        SentryUtilites.setLog("SaleReturnService:nonPremiumSaleCeditRemaining", error)
        return 0;
      }
    }
    // --------------------------------------------------------------------

}
