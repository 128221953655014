<ion-header>
  <ion-toolbar color="primary">
    <ion-title>Support</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>

  <ion-grid>

    <ion-row>
      <ion-col size="12" class="txt-center">
        <ion-label color="danger">
          Database Error Occurred on Your System
        </ion-label>
      </ion-col>
    </ion-row>

    <ion-row class="row-fixing-db">
      <ion-col size="12">
        <ion-label class="font-size-30px">
          Fixing Database
        </ion-label>
      </ion-col>
      <ion-col size="12">
        <ion-label color="danger font-size-24px">
          Please do not close window
        </ion-label>
      </ion-col>
      <ion-col size="12" class="col-progress">
        <ion-label color="primary" class="font-size-22px">
          {{ progressText }}
        </ion-label>
        <ion-progress-bar [value]="progress" type="indeterminate"></ion-progress-bar>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="12" class="txt-center">
        <ion-button fill="clear" class="btn-txt-black padding-end-none btn-support-title">
          Contact to Support
        </ion-button>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12" class="txt-center">
        <ion-chip class="chip-support" color="tertiary">
          <img src="../../../assets/icons/support-call.png">
          <span>+91 9167134134</span>
        </ion-chip>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12" class="txt-center">
        <ion-chip class="chip-whatsapp" color="success" (click)="whatsappSupport()">
          <img src="../../../assets/icons/whatsapp.png">
          <span>WhatsApp Support</span>
        </ion-chip>
      </ion-col>
    </ion-row>
  </ion-grid>

</ion-content>
