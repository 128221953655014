import { TransportDetail } from './TransportDetail.model';
import { BaseModel } from "./BaseModel.model";
import { BillItem } from "./BillItem.model";
import Party from "./Party.model";
import { MoneyOut } from './MoneyOut.model';

export enum IBillingMode {
  "c"="c",
  "n"="n",
}

export class ISaleReturn {

  static SCHEMA_NAME = 'sale-return';

}
export class SaleReturn extends BaseModel {

  profileId: string;

  billNo: string;

  saleBillNo: string;
  
  previousBillNo: string;

  billCompleteStamp: number;

  billDateStamp: number;

  saleBillDateStamp: number;

  saleCreatedStamp: number;

  // dueDateStamp: number;

  // billingTerm: string;

  party: Party;

  partySecondary?: Party;

  billItems: BillItem[];

  moneyOuts?: MoneyOut[];

  transportDetail: TransportDetail;

  deliveryProvience: string;

  senderProvience: string;

  note: string;

  cashDiscount: number;

  cashDiscountPercentage: number;

  additionalDiscount: number;

  // Calculation Keys

  discountAmount: number;

  gstAmount: number;

  cessAmount: number;

  subTotalAmount: number;

  totalAmount: number;

  totalSaving: number;

  amountPaid: number;

  roundOffValue: number;

  // partyPreviousBalance: number;

  serviceChargePercentage: number;

  serviceChargeAmount: number;

  billingMode?: IBillingMode;

  linkedSaleUUID: string;

}
