// Core imports
import { Component, OnInit, ViewChild } from '@angular/core';
import { InfiniteScrollCustomEvent, AlertController, ToastController, IonInput, IonSelect, LoadingController } from '@ionic/angular';
//--------------------------------------------------------------------------------------------------

// Rxjs
import { Subscription } from 'rxjs';
//---------------------------------------------------------------------------------------------------

// Third Party Imports
//----------------------------------------------------------------------------------------------------------------

//Modals
import { IProfile, Profile } from '../../models/Profile.model';
// -----------------------------------------------------------------------------------------------------------------

// Services
import { AuthService } from './../../services/auth/auth.service';
import { AllDataService } from '../../services/all-data.service';
import { PremiumControlService } from 'src/app/services/auth/premium-control.service';
//------------------------------------------------------------------------------------------------------------------

// Utilities
import { Utility } from '../../utils/utility';
//------------------------------------------------------------------------------------------------------------------


// Others
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Router } from '@angular/router';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';
//------------------------------------------------------------------------------------------------------------------


@Component({
  selector: 'app-profile',
  templateUrl: './profile.page.html',
  styleUrls: ['./profile.page.scss'],
})
export class ProfilePage implements OnInit {
  // Input Decorators
  //------------------------------------------------------------------------------------------------------------

  // Output Decorators
  //------------------------------------------------------------------------------------------------------------

  // ViewChild References
  @ViewChild('createNewProfileEle') createNewProfileEle: IonInput;
  @ViewChild('toProfileEle') toProfileEle: IonSelect;
  //--------------------------------------------------------------------------------------------------------------------

  // Utils Methods
  getHeaderColorClass = Utility.getHeaderColorClass;
  lastSyncTime = Utility.getCollectionLastRespSyncTime(IProfile.SCHEMA_NAME);
  selectdProfileId: string = Utility.getFromLocalStorage('selectedProfile');
  //--------------------------------------------------------------------------------------------------------------------

  // Global Variables

  countDown:number = 10;
  countDownInterval:any;

  lastSyncTimeSub: Subscription;
  isTimeDifference = false;

  ownerUserId: string = null;

  filteredList: Profile[] = [];
  completeList: Profile[] = [];
  viewFilteredList: Profile[] = [];
  deletedProfileList: Profile[] = [];

  appliedCategory = '';
  selectedFilter = '';

  isFilterOptionOpen = false;
  openCopyProfileModal: boolean = false;

  subsArr: Subscription[] = [];
  collections = [];
  returnZero = () => 0;
  selectedCollections = [];
  selectToProfileId = null;
  selectFromProfileId = null;
  selectAll: boolean = false;

  // Restore Profile variables
  isOpenRestoreProfileModal: boolean = false;
  selecteDeletedProfileList: Profile[] = [];
  // ----------------------------------
  
  collectionTypes: {
    [key: string]: {
      serviceName: string;
    }
  } = {
      'Profile': {
        serviceName: 'profileService'
      },
      'Items': {
        serviceName: 'itemService'
      },
      'Item Categories': {
        serviceName: 'itemCategoryService'
      },
      'Party': {
        serviceName: 'partyService'
      },
      'Party Categories': {
        serviceName: 'partyCategoryService'
      },
      // 'Item Stock Adjust': {
      //   serviceName: 'itemStockAdjustService'
      // },
      // 'Item Unit': {
      //   serviceName: 'itemUnitService'
      // },
      // 'Money In': {
      //   serviceName: 'moneyInService'
      // },
      // 'Money Out': {
      //   serviceName: 'moneyOutService'
      // },
      // 'Sales': {
      //   serviceName: 'saleService'
      // },
      // // ask about kot save service
      // 'Kot': {
      //   serviceName: 'kotService'
      // },
      // 'Purchase': {
      //   serviceName: 'purchaseService'
      // },
      // 'Expense': {
      //   serviceName: 'expenseService'
      // },
      // 'Image': {
      //   serviceName: 'imageService'
      // },
      // 'Licence': {
      //   serviceName: 'licenceService'
      // },
      // 'Cut Off Day': {
      //   serviceName: 'cutOffDayService'
      // },
    };
//-----------------------------------------------------------------------------------------------------------------

  constructor(
    private allDataService: AllDataService,
    private authService: AuthService,
    private alertController: AlertController,
    private toastController: ToastController,
    private premiumControlService: PremiumControlService,
    private ngxIndexedDBService: NgxIndexedDBService,
    private router: Router,
    private loadingCtrl: LoadingController
  ) { }

  // Life Cycle Hooks
  ngOnInit() {
    try {
      this.allDataService.lastSyncSubs.subscribe(x => {
        this.lastSyncTime = Utility.getCollectionLastRespSyncTime(IProfile.SCHEMA_NAME);
        this.isTimeDifference = this.allDataService.isTimeDifference;
      });
      this.setOwnerUserId();
      this.loadView();
      this.subsArr.push(this.allDataService.listForceReloadSubs
        .subscribe((listName: string) => {
          if (listName == 'profile-list') {
            this.loadView();
          }
        }));
      this.subsArr.push(this.allDataService.profileService.updateSubs
        .subscribe((el: Profile) => {
          if (el) {
            for (let i = 0; i < this.viewFilteredList.length; i++) {
              if (el._localUUID == this.viewFilteredList[i]._localUUID) {
                this.viewFilteredList[i] = el;
                break;
              }
            }
            for (let i = 0; i < this.filteredList.length; i++) {
              if (el._localUUID == this.filteredList[i]._localUUID) {
                this.filteredList[i] = el;
                break;
              }
            }
          }
        }))
    } catch (error) {
      SentryUtilites.setLog("ProfilePage:ngOnInit", error)
    }
    }

  //------------------------------------------------------------------------------------------

  getItemData() {
      return this.allDataService.itemService.getAllByPromiseByProfile(this.selectFromProfileId);
  }


  setOwnerUserId() {
    this.ownerUserId = this.authService.getLoginPhone();
  }

  async loadView() {
    try {
      // this.filteredList = [];
      // this.completeList = [];
      // this.viewFilteredList = [];
  
      this.appliedCategory = '';
      this.selectedFilter = '';
  
      this.isFilterOptionOpen = false;
  
      let newCompleteList = await this.allDataService.profileService.getAllByPromise() || [];
      if(
        newCompleteList.length != this.completeList.length ||
        JSON.stringify(newCompleteList) != JSON.stringify(this.completeList)
        ) {
          this.completeList = newCompleteList;
          if(this.completeList.length) {
            let accessProfiles = this.completeList.filter(x => x.userId!=this.ownerUserId);
            if(accessProfiles?.length) {
              accessProfiles?.forEach(x => {
                let index = this.completeList.findIndex(y => y._localUUID==x._localUUID);
                if(index != -1) {
                  this.completeList.splice(index,1);
                  this.completeList.unshift(x);
                }
              });
            }
          }
          this.onFilterChange();
        }
    } catch (error) {
      SentryUtilites.setLog("ProfilePage:loadView", error)
    }

  }

  toggleFilterOptions() {
    this.isFilterOptionOpen = !this.isFilterOptionOpen;
  }

  onCategoryChange(category: string) {
    this.appliedCategory = category;
    this.onFilterChange();
  }

  onSortChnage(selectedFilter: string) {
    this.selectedFilter = selectedFilter;
    this.onFilterChange();
  }

  isFilterPostpond = false;
  lastFilterStamp = 0;
  onFilterChange(searchKey?: string) {
    try {
      if (this.isFilterPostpond) {
        return true;
      }
      if (this.lastFilterStamp < +new Date() - 200) {
        this.lastFilterStamp = +new Date();
        this.applyFilter({
          searchKeyword: searchKey,
          selectedFilter: this.selectedFilter,
          category: this.appliedCategory
        })
      } else {
        this.isFilterPostpond = true;
        setTimeout(() => {
          this.isFilterPostpond = false;
          this.onFilterChange(searchKey);
        }, 250);
      }
    } catch (error) {
      SentryUtilites.setLog("ProfilePage:onFilterChange", error)
    }
  }


  lastSearchKey = '';
  applyFilter(params: {
    searchKeyword?: string,
    selectedFilter?: string,
    category?: string
  }) {
    try {
      let arr = [];
      let lsearchKey = '';
      let isSearchIncremental = false;
      let isSearchCleared = false;
      if (params?.searchKeyword?.length) {
        lsearchKey = Utility.toLowerCase(params.searchKeyword);
        if (lsearchKey.indexOf(this.lastSearchKey) == 0) {
          isSearchIncremental = true;
        }
        this.lastSearchKey = lsearchKey;
      }
      if (this.lastSearchKey.length > 0 && lsearchKey.length == 0) {
        isSearchCleared = true;
        this.lastSearchKey = '';
      }
      let completeList = [];
      if (isSearchIncremental) {
        completeList = [...this.filteredList]
      } else {
        completeList = [...this.completeList];
      }
  
      if (lsearchKey.length) {
        completeList?.forEach((el) => {
          if (lsearchKey.length < 4) {
            if (Utility.toLowerCase(el.billNo).indexOf(lsearchKey) == 0) {
              arr.push(el);
            }
          } else {
            if (Utility.toLowerCase(el.billNo).indexOf(lsearchKey) != -1) {
              arr.push(el);
            }
          }
        });
  
      } else {
        arr = [...completeList]
      }
  
  
      if (params.selectedFilter == 'SortByNameAsc') {
  
      } else if (params.selectedFilter == 'SortByNameDesc') {
  
      } else if (params.selectedFilter == 'SortByCreditAsc') {
  
      } else if (params.selectedFilter == 'SortByCreditDesc') {
  
      }
  
  
      this.filteredList = [...arr];
  
      if (lsearchKey.length || isSearchCleared) {
        this.viewFilteredList = [...this.filteredList.slice(0, 50)];
      } else if (!this.viewFilteredList.length) {
        this.viewFilteredList = [...this.filteredList.slice(0, 50)];
      }
    } catch (error) {
      SentryUtilites.setLog("ProfilePage:applyFilter", error)
    }

  }


  loadMoreListData(event) {
    try {
      if (this.viewFilteredList.length > 0 && this.viewFilteredList.length <= this.filteredList.length) {
        let appendListLength = this.filteredList.length - this.viewFilteredList.length;
        let lastEl = this.viewFilteredList[this.viewFilteredList.length - 1];
        let counter = 0;
        for (let i = 0; i < this.filteredList.length; i++) {
          if (this.filteredList[i]._localUUID == lastEl._localUUID) {
            counter = 1;
            continue;
          }
          if (counter > 0 && appendListLength >= 50) {
            if (counter <= 50) {
              this.viewFilteredList.push({ ...this.filteredList[i] })
            } else {
              break;
            }
            counter++;
          } else if(counter > 0 && appendListLength < 50) {
            if (counter <= appendListLength) {
              this.viewFilteredList.push({ ...this.filteredList[i] })
            } else {
              break;
            }
            counter++;
          }
        }
        (event as InfiniteScrollCustomEvent)?.target?.complete();
      }
    } catch (error) {
      SentryUtilites.setLog("ProfilePage:loadMoreListData", error)
    }
  }

  ionViewWillLeave() {
    this.ngOnDestroy();
  }

  ngOnDestroy() {
    try {
      this.subsArr?.forEach((el) => {
        el?.unsubscribe();
      })
      this.lastSyncTimeSub?.unsubscribe();
    } catch (error) {
      SentryUtilites.setLog("ProfilePage:ngOnDestroy", error)
    }
  }

  emitElement(profile: Profile) {

  }

  async presentAddProfileAlert() {
    try {
      if(this.premiumControlService.isPremiumAccess({alert:true})) {
        const alert = await this.alertController.create({
          header: 'Profile Details',
          buttons: [
            {
              text: 'Save',
              handler: async (value) => {
                if(value?.profileName) {
                  this.saveProfile(value?.profileName);
                  return true;
                }
                return false;
              },
            }
          ],
          mode: 'ios',
          inputs: [
            {
              type: 'text',
              placeholder: 'Profile Name',
              name: 'profileName',
            },
          ],
        });
  
        await alert.present();
      }
    } catch (error) {
      SentryUtilites.setLog("ProfilePage:presentAddProfileAlert", error)
    }
  }

  openCopyProfile(isOpen: boolean) {
    this.openCopyProfileModal = isOpen;
  }

  async createProfile(value) {
    let newProfileData = await this.saveProfile(value);
    this.toProfileEle.value = newProfileData?._localUUID;
    let data = await this.allDataService.profileService.getAllByPromise();
    if(this.createNewProfileEle) {
      this.createNewProfileEle.value = '';
    }
  }

  async selectFromProfile(event) {
    try {
      this.selectFromProfileId = event?.detail?.value;
      this.selectedCollections = [];
      this.collections = [];
      let collectionKey = Object.keys(this.collectionTypes);
      for (let i = 0; i < collectionKey.length; i++) {
        let keyName = collectionKey[i];
        let getData = await this.allDataService[this.collectionTypes[keyName].serviceName].getAllByPromiseByProfile(this.selectFromProfileId);
        if(getData.length) {
          if(keyName === 'Profile') {
            this.collections.push({
              title: keyName,
              checked: true
            })
            this.selectedCollections.push(keyName);
            continue
          }
          this.collections.push({
            title: keyName,
            checked: false
          })
        }
      }
    } catch (error) {
      SentryUtilites.setLog("ProfilePage:selectFromProfile", error)
    }
  }

  selectToProfile(event) {
    this.selectToProfileId = event?.detail?.value;
  }

  selectedAllCollection(checked: boolean) {
    try {
      this.selectedCollections = [];
      this.collections?.forEach(collection => {
        collection.checked = checked;
        if(collection.title === 'Profile') {
          collection.checked = true;
        }
        if(checked) {
          this.selectedCollections.push(collection.title);
        }
      })
    } catch (error) {
      SentryUtilites.setLog("ProfilePage:selectedAllCollection", error)
    }
  }

  selectedCollection(checked: boolean, keyName: string) {
    try {
      if(!this.selectedCollections.includes(keyName) && checked) {
        this.selectedCollections.push(keyName);
      } else {
        let index = this.selectedCollections.findIndex(item => item === keyName);
        this.selectedCollections.splice(index, 1);
      }
      if(this.collections.length === this.selectedCollections.length) {
        this.selectAll = true;
      } else {
        this.selectAll = false;
      }
    } catch (error) {
      SentryUtilites.setLog("ProfilePage:selectedCollection", error)
    }
  }

  getAllIndexedDbData(schemaName: string): Promise<any[]> {
    return new Promise((resolve, reject) => {
      try {
        this.ngxIndexedDBService.getAll(schemaName).subscribe((docs: any[]) => {
          return resolve(docs)
        },
        err => {
          console.error(err);
          if(this.authService.isUserLoggedIn && typeof err == 'string' && err?.includes('objectStore does not exists')) {
            this.router.navigate(['idbx-error']);
          } else if (typeof err?.target?.error == 'object') {
            SentryUtilites.setLog("ProfilePage:getAllIndexedDbData", err?.target?.error)
          } else {
            SentryUtilites.setLog("ProfilePage:getAllIndexedDbData", err)
          }
        });
      } catch (err) {
        SentryUtilites.setLog("ProfilePage:getAllIndexedDbData", err)
        return resolve(null)
      }
    });
  }

  async copyProfile() {
    try {
      let errorText: string;
      if(!this.selectFromProfileId) {
        errorText = `Select From Profile Id`;
      }
      if(!this.selectToProfileId) {
        errorText = errorText ?  `${errorText}, Select To Profile Id` : 'Select To Profile Id';
      }
      if(!this.selectedCollections?.length) {
        errorText = errorText ?  `${errorText}, Select at least one collection` : 'Select at least one collection';
      }
      if (errorText) {
        const toast = await this.toastController.create({
          header: errorText,
          duration: 2000,
          position: 'top',
          mode: 'ios',
          color: 'warning',
        });
        await toast.present();
        return
      }
      let errorMessage = '';
      if(this.selectToProfileId && this.selectedCollections?.length) {
        const loading = await this.loadingCtrl.create({
          message: `Copying...`,
          cssClass: 'custom-loading',
        });
        loading.present();
        for (let i = 0; i < this.selectedCollections.length; i++) {
          let selectionKeyName = this.selectedCollections[i];
          loading.message = `Copying ${selectionKeyName}`;
          let value = await this.allDataService[this.collectionTypes[selectionKeyName].serviceName].copyData(this.selectFromProfileId, this.selectToProfileId);
          if(!value) {
            errorMessage = `${selectionKeyName}, ${errorMessage}`;
          }
  
        }
        loading.dismiss();
      }
      if(errorMessage.length > 0) {
        const errorLoading = await this.loadingCtrl.create({
          message: `${errorMessage} Fail Copying...`,
          cssClass: 'error-loading',
        });
        errorLoading.present();
        setTimeout(() => {
          errorLoading.dismiss();
        }, 2000)
      }
      this.openCopyProfileModal = false;
    } catch (error) {
      SentryUtilites.setLog("ProfilePage:copyProfile", error)
    }
  }

  async saveProfile(profileName: string) {
    try {
      let result = await this.allDataService.profileService.createProfile(profileName);
      if (result?._localUUID) {
        this.allDataService.listForceReloadSubs.next('profile-list');
        this.allDataService.profileService.reloadList();
        await this.allDataService.profileService.addSampleDataToProfile(result?._localUUID);
        this.allDataService.itemService.reloadList();
        this.allDataService.partyService.reloadList();
        this.allDataService.listForceReloadSubs.next('party-list');
        this.allDataService.listForceReloadSubs.next('item-list');
        const toast = await this.toastController.create({
          header: "Profile Created Successfully",
          duration: 2000,
          position: 'top',
          mode: 'ios',
          color: 'success',
        });
        await toast.present();
      }else {
        const toast = await this.toastController.create({
          header: "Profile creation Failed",
          duration: 2000,
          position: 'top',
          mode: 'ios',
          color: 'danger',
        });
        await toast.present();
      }
      return result;
    } catch (error) {
      SentryUtilites.setLog("ProfilePage:saveProfile", error)
      return null;
    }
  }

  activateProfile(profile: Profile) {
    try {
      Utility.setToLocalStorage('selectedProfile', profile._localUUID);
      Utility.setToLocalStorage('selectedProfileUserId', profile.userId);
      this.selectdProfileId = profile._localUUID;
      if(profile?._localUUID) {
        profile = Utility.settingDefaultValue(profile);
        this.allDataService.profileService.update(profile);
      }
      this.onFilterChange();
      this.allDataService.initService();
      this.allDataService.listForceReloadSubs.next('profile-list');
      this.allDataService.socketConnect();
    } catch (error) {
      SentryUtilites.setLog("ProfilePage:activateProfile", error)
    }
  }

  async presentDeleteConfirm(profile: Profile) {
    try {
      const alert = await this.alertController.create({
        header: 'Alert!',
        message: 'Are you sure you want to delete your profile?',
        mode: 'ios',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => { 
              this.resetCountdown();
            },
          },
          {
            text: 'Delete',
            role: 'confirm',
            cssClass: 'alert-button-pending',
            handler: () => {
              if(this.countDown > 0){
                return false;
              }
              this.deleteProfile(profile);
              this.resetCountdown();
            },
          },
        ],
      });
  
      this.countDownInterval = setInterval(() => {
  
        if (this.countDown === -1) {
          clearInterval(this.countDownInterval);
        } else {
          alert.message = `Are you sure you want to delete your profile? <span class="hideCounter">${this.countDown}</span>`;
          alert.buttons[1]["text"] = this.countDown > 0 ?`Delete (${this.countDown})` : `Delete`,
          alert.buttons[1]["cssClass"] = this.countDown > 0 ? 'alert-button-pending' :'alert-button-confirm'
          
        }
        this.countDown--;
      }, 1000);
      
      await alert.present();
    } catch (error) {
      SentryUtilites.setLog("ProfilePage:presentDeleteConfirm", error)
    }
  }

  resetCountdown() {
    clearInterval(this.countDownInterval);
    this.countDown = 10; 
  }

  async deleteProfile(profile: Profile) {
    try {
      let deletedProfile = await this.allDataService.profileService.delete(profile);
      if (deletedProfile?.deletedStamp) {
        const toast = await this.toastController.create({
          header: "Profile Deleted Successfully",
          duration: 2000,
          position: 'top',
          mode: 'ios',
          color: 'success',
        });
        await toast.present();
        this.allDataService.listForceReloadSubs.next('profile-list');
      }
    } catch (error) {
      SentryUtilites.setLog("ProfilePage:deleteProfile", error)
    }
  }

  dismissCopyModal(event) {
    this.selectedCollections = [];
    this.collections = [];
    this.selectAll = false;
  }

  getSelfProfile(completeList: Profile[]) {
    return completeList?.filter(profile => profile?.userId === this.ownerUserId)
  }

  /**
   * 
   * @param isOpen : boolean value provide for open or close restore modal
   * @description : this method use to open or close restore profile modal and also get list of deleted profile list
   */
  async openRestoreProfile(isOpen: boolean = true) {
    try {
      this.isOpenRestoreProfileModal = isOpen;
      if(isOpen) {
        this.deletedProfileList = await this.allDataService?.profileService?.getAllDeletedProfileByPromise();
      } else {
        this.deletedProfileList?.forEach(profile => {
          profile['checked'] = false;
        }) 
        this.deletedProfileList = [];
        this.selecteDeletedProfileList = [];
      }
    } catch (error) {
      SentryUtilites.setLog("ProfilePage:openRestoreProfile", error)
    }
  }
  // ------------------------------------------

  /**
   * 
   * @param checked : return selected select all check boolean value
   * @description : this method select all or remove all deleted profile list from selecteDeletedProfileList
   */
  selectAllDeletedProfile(checked) {
    try {
      if(checked) {
        this.selecteDeletedProfileList = [...this.deletedProfileList];
      } else {
        this.selecteDeletedProfileList = [];
      }
      this.deletedProfileList?.forEach(profile => {
        profile['checked'] = checked;
      }) 
    } catch (error) {
      SentryUtilites.setLog("ProfilePage:selectAllDeletedProfile", error)
    }
  }
  // ------------------------------------------

  /**
   * 
   * @param checked : return selected select all check boolean value
   * @param localUUID : provide local id you want to push or splice from selecteDeletedProfileList
   * @description : it will check value true or false base on checkbox value
   */
  selectedSingleDeletedProfile(checked: boolean, localUUID: string) {
    try {
      let filteredProfile = this.deletedProfileList?.filter(profile => profile?._localUUID === localUUID);
      filteredProfile[0]['checked'] = checked;
      if(checked) {
        let index = this.selecteDeletedProfileList?.findIndex(profile => profile?._localUUID === localUUID);
        if(index === -1) {
          this.selecteDeletedProfileList?.push(filteredProfile[0]);
        }
      } else {
        let index = this.selecteDeletedProfileList?.findIndex(profile => profile?._localUUID === localUUID);
        if(index !== -1) {
          this.selecteDeletedProfileList?.splice(index, 1);
        }
      }
    } catch (error) {
      SentryUtilites.setLog("ProfilePage:selectedSingleDeletedProfile", error)
    }
  }
  // ------------------------------------------

  /**
   * @description : it is use to restore selected profile from selecteDeletedProfileList
   */
  async restoreProfile() {
    try {
      if(this.selecteDeletedProfileList?.length) {
        for(let i = 0; i < this.selecteDeletedProfileList?.length; i++) {
          let profile = this.selecteDeletedProfileList[i];
          profile.deletedStamp = 0;
          delete profile['checked'];
          await this.allDataService.profileService?.update(profile);
        }
        this.openRestoreProfile(false);
        const toast = await this.toastController?.create({
          header: "Profile Restored Successfully",
          duration: 2000,
          position: 'top',
          mode: 'ios',
          color: 'success',
        });
        await toast?.present();
        this.loadView();
      }
    } catch (error) {
      SentryUtilites.setLog("ProfilePage:restoreProfile", error)
    }
  }
  // ------------------------------------------

}
