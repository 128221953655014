import { AllDataService } from './../../services/all-data.service';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AccessTo } from '../../models/AccessTo.model';
import { AlertController, ToastController } from '@ionic/angular';
import { PinVerificationComponent } from '../../components/pin-verification/pin-verification.component';
import { IProfile, Profile } from '../../models/Profile.model';
import { Utility } from '../../utils/utility';
import { Subscription } from 'rxjs';
import { CommonService } from '../../services/common.service';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-staff',
  templateUrl: './staff.page.html',
  styleUrls: ['./staff.page.scss'],
})
export class StaffPage implements OnInit {

  @ViewChild('pinVerificationElement') pinVerificationElement: PinVerificationComponent;

  getHeaderColorClass = Utility.getHeaderColorClass;

  lastSyncTime = Utility.getCollectionLastRespSyncTime(IProfile.SCHEMA_NAME);
  isTimeDifference: boolean = false;
  deletedUserId: string = '';

  accessToList: AccessTo[] = [];
  selectedProfile: Profile = null;
  subsArr: Subscription[] = [];
  selectedUserId: string = '';
  selectedOption: string = '';

  isMobile: boolean = false;

  isAssignStaff: boolean = false;
  isClickedAssignStaffToggle: boolean = false;

  loadViewTimeStamp: number = 0;

  isNgOnInitRun: boolean = false;

  constructor(
    private router: Router,
    private allDataService: AllDataService,
    private alertController: AlertController,
    private toastController: ToastController,
    private commonService: CommonService
  ) { }

  ngOnInit() {
    try {
      this.isNgOnInitRun = true;
      this.isMobile = this.commonService.isMobile();
      this.subsArr.push(this.allDataService.lastSyncSubs.subscribe(x => {
        this.lastSyncTime = Utility.getCollectionLastRespSyncTime(IProfile.SCHEMA_NAME);
        this.isTimeDifference = this.allDataService.isTimeDifference;
      }));
      this.subsArr.push(this.allDataService.listForceReloadSubs
        .subscribe((listName: string) => {
          if (listName == 'profile-list') {
            this.reduceFunctionCall();
          }
      }));
      this.subsArr.push(this.allDataService.profileService.updateSubs.subscribe(res => {
        this.loadView();
      }))
    } catch (error) {
      SentryUtilites.setLog("StaffPage:ngOnInit", error)
    }
  }

  ionViewWillLeave() {
    this.ngOnDestroy();
  }

  ngOnDestroy() {
    this.subsArr?.forEach(sub => sub?.unsubscribe());
    this.isNgOnInitRun = false;
  }

  ionViewWillEnter() {
    this.reduceFunctionCall();
    if(!this.isNgOnInitRun) {
      this.ngOnInit();
    }
  }

  onNewStaffClick() {
    this.router.navigate(['staff/form']);
  }

  async loadView() {
    this.selectedProfile = await this.allDataService.profileService.getCurrentProfile();
    this.accessToList = this.selectedProfile?.accessTo || [];
    this.isAssignStaff = this.selectedProfile?.iSetAssignStaffToTheSale;
  }

  reduceFunctionCall() {
    if(+new Date() > this.loadViewTimeStamp) {
      this.loadViewTimeStamp = +new Date() + 5000;
      this.loadView();
    }
  }

  edit(userId: string) {
    this.selectedOption = 'edit';
    this.selectedUserId = userId;
    this.openTransactionPINModal();
  }

  delete(userId: string) {
    this.selectedOption = 'delete';
    this.deletedUserId = userId;
    this.openTransactionPINModal();
  }

  async deleteConfirm(): Promise<boolean> {
    return new Promise(async (resolve,reject) => {
      try {
        const alert = await this.alertController.create({
          mode: 'ios',
          header: 'Please Confirm!',
          message: 'Are you sure want to delete?',
          buttons: [
            {
              text: 'No',
              role: 'cancel',
              handler: () => {
                resolve(false);
                return true;
              },
            },
            {
              text: 'Yes',
              role: 'confirm',
              handler: () => {
                resolve(true);
                return true;
              },
            },
          ],
        });
        await alert.present();
      } catch (error) {
        SentryUtilites.setLog("StaffPage:deleteConfirm", error)
        return false;
      }
    });
  }

  openTransactionPINModal() {
    this.pinVerificationElement?.openTransactionPINModal();
  }

  async verifyTransactionPIN(event) {
    try {
      if(event && this.selectedOption === 'delete') {
        if(await this.deleteConfirm()) {
          const profile = await this.allDataService.profileService.getCurrentProfile();
          if(profile?.accessTo?.length) {
            let index = profile?.accessTo?.findIndex(x => x?.userId === this.deletedUserId);
            if(index != -1) {
              profile.accessTo.splice(index,1);
              const updatedProfile = await this.allDataService.profileService.update(profile);
              if(updatedProfile) {
                if(updatedProfile?.accessTo?.filter(x => x.userId === this.deletedUserId)?.length === 0) {
                  const toast = await this.toastController.create({
                    message: 'Staff Deleted successfully',
                    duration: 2000,
                    color: 'success',
                  });
                  await toast.present();
                  this.reduceFunctionCall();
                  return true;
                }
              }
            }
          }
          const toast = await this.toastController.create({
            message: 'Something Went Wrong',
            duration: 2000,
            color: 'danger',
          });
          await toast.present();
          return false;
        }
      } else if(event && this.selectedOption === 'edit') {
        this.router.navigate([`staff/form/${this.selectedUserId}`]);
      }
    } catch (error) {
      SentryUtilites.setLog("StaffPage:verifyTransactionPIN", error)
      return false;
    }
  }

  /**
   * @description : user clicked on toggle
   */
  clickAssignStaffToggle() {
    this.isClickedAssignStaffToggle = true;
  }
  // -------------------------------------

  /**
   * 
   * @param event : emit CustomEventInit if toggle change
   * @description : store value of Assign Staff to the Invoice 
   */
  async assignStaff(event: CustomEventInit) {
    try {
      if(this.isClickedAssignStaffToggle && this.selectedProfile) {
        this.selectedProfile.iSetAssignStaffToTheSale = this.isAssignStaff = event?.detail?.checked;
        await this.allDataService.profileService.update(this.selectedProfile);
        
        await Utility.wait(1500);
        this.reduceFunctionCall();
      }
    } catch (error) {
      SentryUtilites.setLog("StaffPage:assignStaff", error)
    }
  }
  // -------------------------------------

  @HostListener('window:resize', ['$event'])
  OnResize() {
    setTimeout(()=> {
    this.isMobile = this.commonService.isMobile()
    }, 20)
  }

}
