import { Injectable } from "@angular/core";
import { ExpressServerService } from "./api/express-server.service";
import { LoginToken } from "../models/LoginToken.model";
import { SentryUtilites } from "../utils/sentryUtilites";

@Injectable({
  providedIn: 'root'
})
export class LoginTokenService {
  
  constructor(
    private expressServerService: ExpressServerService,
  ) { }

  async getAll(): Promise<LoginToken[]> {
    try {
      let res = await this.expressServerService.makeGetAllCall(`loginToken/fetchAllv3`);
      return res?.['records'] || [];
    } catch (error) {
      SentryUtilites.setLog("LoginTokenService:getAll", error)
      return [];
    }
  }

  async initiateLogout(localUUID: string): Promise<boolean> {
    try {
      let res = await this.expressServerService.makeEzoOfServerPostCall(`loginToken/initiateLogout`,{localUUID});
      return res?.status == 'success' ? true : false;
    } catch (error) {
      SentryUtilites.setLog("LoginTokenService:initiateLogout", error)
      return false;
    }
  }

  
  async completeLogout(localUUID: string): Promise<boolean> {
    try {
      let res = await this.expressServerService.makeEzoOfServerPostCall(`loginToken/completeLogout`,{localUUID});
      return res?.status == 'success' ? true : false;
    } catch (error) {
      SentryUtilites.setLog("LoginTokenService:getById", error)
      return false;
    }
  }

}