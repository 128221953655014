import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NgxIndexedDBService } from "ngx-indexed-db";
import { IMonthWiseItemStock, MonthWiseItemStock } from "../../models/MonthWiseItemStock.model";
import { Utility } from "src/app/utils/utility";
import { SentryUtilites } from "src/app/utils/sentryUtilites";

@Injectable({
    providedIn: 'root',
})
export class MonthWiseItemStockDao {

    constructor(
        private router: Router,
        private ngxIndexedDBService: NgxIndexedDBService,
    ) { }

    /**
     * 
     * @param monthWiseItemStock : provide monthWiseItemStock object
     * @returns : return monthWiseItemStock saved object in indexeddb
     * @description : save monthWiseItemStock object in indexeddb
     */
    save(itemLocalUUID: string): Promise<MonthWiseItemStock> {
        return new Promise((resolve, reject) => {
            try {
                if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && itemLocalUUID) {
                    let monthWiseItemStock = new MonthWiseItemStock();
                    monthWiseItemStock.itemLocalUUID = itemLocalUUID;
                    monthWiseItemStock.ledgerStock = 0;
                    monthWiseItemStock.ledger = {};
                    this.ngxIndexedDBService
                        .add(IMonthWiseItemStock.SCHEMA_NAME, monthWiseItemStock)
                        .subscribe(
                            (savedMonthWiseItemStock: MonthWiseItemStock) => {
                                return resolve(savedMonthWiseItemStock);
                            },
                            (err) => {
                                console.error(err);
                                if (typeof err == 'string' && err?.includes('objectStore does not exists')) {
                                    this.router.navigate(['idbx-error']);
                                } else if (typeof err?.target?.error == 'object') {
                                    SentryUtilites.setLog("MonthWiseItemStockDao:save", err?.target?.error)
                                } else {
                                    SentryUtilites.setLog("MonthWiseItemStockDao:save", err)
                                }
                            }
                        );
                } else {
                  return resolve(null);
                }
            } catch (err) {
                SentryUtilites.setLog("MonthWiseItemStockDao:save", err)
                return resolve(null);
            }
        });
    }
    // -------------------------------------------------------------------------------------------------------------------

    /**
     * 
     * @param monthWiseItemStocks : provide monthWiseItemStock array of object
     * @returns : return true if successfully execute code
     */
        bulkAdd(monthWiseItemStocks: MonthWiseItemStock[]): Promise<boolean> {
            return new Promise((resolve, reject) => {
                try {
                    if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && monthWiseItemStocks?.length) {
                        this.ngxIndexedDBService.bulkAdd(IMonthWiseItemStock.SCHEMA_NAME, monthWiseItemStocks)
                        .subscribe((monthWiseItemStocks) => {
                            return resolve(true);
                        },
                        (err) => {
                            console.error(err);
                            if (typeof err == 'string' && err?.includes('objectStore does not exists')) {
                                this.router.navigate(['idbx-error']);
                            } else if (typeof err?.target?.error == 'object') {
                                SentryUtilites.setLog("MonthWiseItemStockDao:bulkAdd", err?.target?.error)
                            } else {
                                SentryUtilites.setLog("MonthWiseItemStockDao:bulkAdd", err)
                            }
                        })
                    } else {
                      return resolve(false);
                    }
                } catch (error) {
                    SentryUtilites.setLog("MonthWiseItemStockDao:bulkAdd", error)
                    return resolve(false);
                }
            })
        }
        // ---------------------------------------------------------------

    /**
     * 
     * @param monthWiseItemStock : provide monthWiseItemStock object
     * @returns : return monthWiseItemStock saved object in indexeddb
     * @description : update monthWiseItemStock object in indexeddb
     */
    update(monthWiseItemStock: MonthWiseItemStock): Promise<MonthWiseItemStock> {
        return new Promise(async (resolve, reject) => {
            try {
                if (Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && monthWiseItemStock?.itemLocalUUID) {
                    let oldData = await this.getByItemLocalUUID(monthWiseItemStock?.itemLocalUUID);
                    monthWiseItemStock._localId = oldData?._localId;
                    this.ngxIndexedDBService
                        .update(IMonthWiseItemStock.SCHEMA_NAME, monthWiseItemStock)
                        .subscribe(
                            (updatedMonthWisePreviousBalance: MonthWiseItemStock) => {
                                return resolve(updatedMonthWisePreviousBalance);
                            },
                            (err) => {
                                console.error(err);
                                if (typeof err == 'string' && err?.includes('objectStore does not exists')) {
                                    this.router.navigate(['idbx-error']);
                                } else if (typeof err?.target?.error == 'object') {
                                    SentryUtilites.setLog("MonthWiseItemStockDao:update", err?.target?.error)
                                } else {
                                    SentryUtilites.setLog("MonthWiseItemStockDao:update", err)
                                }
                            }
                        );
                } else {
                    return resolve(null);
                }
            } catch (err) {
                SentryUtilites.setLog("MonthWiseItemStockDao:update", err)
                return resolve(null);
            }
        })
    }
    // ------------------------------------------------------------------------------------------------------------------------------

    /**
     * 
     * @param monthWiseItemStocks : provide monthWiseItemStock array of object
     * @returns : return true if successfully execute code
     */
    bulkPut(monthWiseItemStocks: MonthWiseItemStock[]): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && monthWiseItemStocks?.length) {
                    this.ngxIndexedDBService.bulkPut(IMonthWiseItemStock.SCHEMA_NAME, monthWiseItemStocks)
                    .subscribe((monthWiseItemStocks) => {
                        return resolve(true);
                    },
                    (err) => {
                        console.error(err);
                        if (typeof err == 'string' && err?.includes('objectStore does not exists')) {
                            this.router.navigate(['idbx-error']);
                        } else if (typeof err?.target?.error == 'object') {
                            SentryUtilites.setLog("MonthWiseItemStockDao:bulkPut", err?.target?.error)
                        } else {
                            SentryUtilites.setLog("MonthWiseItemStockDao:bulkPut", err)
                        }
                    })
                } else {
                  return resolve(false);
                }
            } catch (error) {
                SentryUtilites.setLog("MonthWiseItemStockDao:bulkPut", error)
                return resolve(false);
            }
        })
    }
    // ---------------------------------------------------------------

    /**
     * 
     * @param itemLocalUUID : provide item localUUID
     * @param billDateStamp : provide billDateStamp
     * @param totalAmount : totalAmount
     * @returns : return true if successfully execute code
     */
    modifyStock(
        itemLocalUUID: string,
        billDateStamp: number,
        quantity: number
    ): Promise<boolean> {
        return new Promise(async (resolve, rejects) => {
            try {
                if(itemLocalUUID) {
                    let monthWiseItemStock = await this.getByItemLocalUUID(itemLocalUUID);
                    if(!monthWiseItemStock) {
                        monthWiseItemStock = await this.save(itemLocalUUID);
                    }
                    if (monthWiseItemStock && Utility.isNumber(quantity)) {
                        let yearMonth = Utility.getYearMonthKey(billDateStamp);
                        if (!monthWiseItemStock?.ledger?.hasOwnProperty(yearMonth)) {
                            monthWiseItemStock.ledger[yearMonth] = 0;
                        }
                        monthWiseItemStock.ledger[yearMonth] += Number(quantity);
                        monthWiseItemStock.ledgerStock += Number(quantity);
                        await this.update(monthWiseItemStock);
                    }
                }
                return resolve(true);
            } catch (err) {
                SentryUtilites.setLog("MonthWiseItemStockDao:modifyStock", err)
                return resolve(false);
            }
        })
    }
    // --------------------------------------------------------------------

    /**
     * 
     * @param itemLocalUUID : provide itemLocalUUID
     * @returns : return monthWiseItemStock object by searching itemLocalUUID from indexeddb
     */
    getByItemLocalUUID(itemLocalUUID: string): Promise<MonthWiseItemStock> {
        return new Promise((resolve, reject) => {
            try {
                if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && itemLocalUUID) {
                    this.ngxIndexedDBService
                        .getByIndex(IMonthWiseItemStock.SCHEMA_NAME, 'itemLocalUUID', itemLocalUUID)
                        .subscribe(
                            (monthWiseItemStock: MonthWiseItemStock) => {
                                return resolve(monthWiseItemStock);
                            },
                            (err) => {
                                console.error(err);
                                if (typeof err == 'string' && err?.includes('objectStore does not exists')) {
                                    this.router.navigate(['idbx-error']);
                                } else if (typeof err?.target?.error == 'object') {
                                    SentryUtilites.setLog("MonthWiseItemStockDao:getByItemLocalUUID", err?.target?.error)
                                } else {
                                    SentryUtilites.setLog("MonthWiseItemStockDao:getByItemLocalUUID", err)
                                }
                            }
                        );
                } else {
                  return resolve(null);
                }
            } catch (err) {
                SentryUtilites.setLog("MonthWiseItemStockDao:getByItemLocalUUID", err)
                return resolve(null);
            }
        })
    }
    // -----------------------------------------------------------------------------------------------------------------------------------

    /**
     * 
     * @returns : return all monthWiseItemStock array of object from indexeddb
     */
    getAll(): Promise<MonthWiseItemStock[]> {
        return new Promise((resolve, reject) => {
            try {
                if(Boolean(Utility.getFromLocalStorage('_ezo_login_token'))) {
                    this.ngxIndexedDBService.getAll(IMonthWiseItemStock.SCHEMA_NAME)
                    .subscribe(
                        (monthWiseItemStocks: MonthWiseItemStock[]) => {
                            return resolve(monthWiseItemStocks);
                        },
                        (err) => {
                            console.error(err);
                            if (typeof err == 'string' && err?.includes('objectStore does not exists')) {
                                this.router.navigate(['idbx-error']);
                            } else if (typeof err?.target?.error == 'object') {
                                SentryUtilites.setLog("MonthWiseItemStockDao:getAll", err?.target?.error)
                            } else {
                                SentryUtilites.setLog("MonthWiseItemStockDao:getAll", err)
                            }
                        }
                    );
                } else {
                  return resolve([]);
                }
            } catch (err) {
                SentryUtilites.setLog("MonthWiseItemStockDao:getAll", err)
                return resolve([]);
            }
        })
    }
    // ------------------------------------------------------------------------------------------------------------------------------------------------

}
