import { Utils } from './../../utils/utils';
import { Component, OnInit, ViewChild, Output, EventEmitter, Input, SimpleChanges, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MoneyIn } from '../../models/MoneyIn.model';
import Party from '../../models/Party.model';
import { AllDataService } from '../../services/all-data.service';
import { Utility } from '../../utils/utility';
import { Validator } from '../../utils/validator';
import { PartySelectorComponent } from '../party-selector/party-selector.component';
import { Sale } from '../../models/Sale.model';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { IonInput } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';
import { PurchaseReturn } from '../../models/PurchaseReturn.model';

@Component({
  selector: 'app-money-in-form',
  templateUrl: './money-in-form.component.html',
  styleUrls: ['./money-in-form.component.scss'],
})
export class MoneyInFormComponent implements OnInit {

  @Input() sale: Sale = null;
  @Input() purchaseReturn: PurchaseReturn = null;
  @Input() billTotalAmount: number = null;
  @Input() moneyInCart: MoneyIn[] = [];
  @Input() paramDocumentId: string = null;

  @Output() savedMoneyIn = new EventEmitter<MoneyIn>();
  @Output() updatedMoneyIn = new EventEmitter<MoneyIn>();

  @ViewChild('partySelector') partySelector: PartySelectorComponent;
  @ViewChild('saveButton', {read: ElementRef}) saveButton: ElementRef;
  @ViewChild('inpTotalAmountEle') inpTotalAmountEle: IonInput;

  capFractionsToTwo = Utils.capFractionsToTwo;
  setDateValue = Utility.setDateValue;

  maxDate = Utility.maxDateUpto50Year();

  form: FormGroup;
  fetchedMoneyIn: MoneyIn = null;

  saveLoader = false;

  todayDate: number = +new Date().setHours(0, 0, 0, 0);

  isOpenBillDatePicker: boolean = false;

  keepContentsMounted: boolean = false;

  dpBillDateValue: string = null;

  subArr: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private allDataService: AllDataService,
  ) { }

  ngOnInit() {
    this.initializeReactiveForm();
    this.populateForm();
  }

  ngAfterViewInit() { }

  ngOnChanges(changes: SimpleChanges) {
    try {
      if (changes?.paramDocumentId?.currentValue != changes?.paramDocumentId?.previousValue) {
        this.populateForm();
      }
      if (
          changes?.sale?.currentValue != changes?.sale?.previousValue ||
          changes?.purchaseReturn?.currentValue != changes?.purchaseReturn?.previousValue
        ) {
        setTimeout(() => {
          this.populateParty();
        }, 500);
      }
    } catch (error) {
      SentryUtilites.setLog("MoneyInFormComponent:ngOnChanges", error)
    }
  }

  ngDestory() { 
    try {
      this.keepContentsMounted = false;
      this.subArr?.forEach(sub => sub?.unsubscribe());
    } catch (error) {
      SentryUtilites.setLog("MoneyInFormComponent:ngDestory", error)
    }
  }

  /*
    Reactive Form Initialization
  */

  initializeReactiveForm() {
    try {
      this.form = this.formBuilder.group({
        billNo: [null, Validators.required],
        billDateStamp: [+new Date().setHours(0, 0, 0, 0), Validators.required],
        party: [null, Validators.required],
        totalAmount: [null, [Validators.required, Validators.pattern(Validator.positiveNumber)]],
        paymentMode: ['cash'],
        paymentId: [null],
      });
      this.form.patchValue({totalAmount: this.maxAllowedMoneyInAmount()});
      this.subArr.push(this.form.get("totalAmount").valueChanges.pipe(distinctUntilChanged(), debounceTime(300)).subscribe(totalAmount => {
        if(totalAmount != null && totalAmount < 0) {
          this.form.patchValue({totalAmount: Math.abs(totalAmount===0 ? 1 : totalAmount)});
        } else {
          this.form.patchValue({totalAmount: totalAmount !== null ? Utils.capFractionsToTwo(totalAmount) : null});
        }
      }));
    } catch (error) {
      SentryUtilites.setLog("MoneyInFormComponent:initializeReactiveForm", error)
    }
  }

  async populateForm() {
    try {
      if (this.paramDocumentId) {
        this.fetchedMoneyIn = await this.allDataService.moneyInService.getByUUID(this.paramDocumentId);
        this.dpBillDateValue = Utility.ionDatePickerFormattedString(this.fetchedMoneyIn?.billDateStamp);
        this.form.patchValue(this.fetchedMoneyIn);
        this.keepContentsMounted = true;
        this.partySelector.setSelectedParty(this.fetchedMoneyIn?.party);
      } else {
        this.populateBillNumber();
      }
      this.populateParty();
    } catch (error) {
      SentryUtilites.setLog("MoneyInFormComponent:populateForm", error)
    }
  }

  populateParty() {
    try {
      if (this.sale?.party || this.purchaseReturn?.party) {
        this.partySelector?.setSelectedParty(this.sale?.party || this.purchaseReturn?.party);
        this.partySelector?.disable();
        this.form?.controls?.party?.setValue(this.sale?.party || this.purchaseReturn?.party);
      }else if(this.paramDocumentId) {
        this.partySelector?.setSelectedParty(this.fetchedMoneyIn?.party);
        this.partySelector?.disable();
        this.form?.controls?.party?.setValue(this.fetchedMoneyIn?.party);
      }
    } catch (error) {
      SentryUtilites.setLog("MoneyInFormComponent:populateParty", error)
    }
  }

  onPartySelect(party: Party) {
    try {
      if (party?._localUUID) {
        this.form.patchValue({
          party: party
        });
      }
    } catch (error) {
      SentryUtilites.setLog("MoneyInFormComponent:onPartySelect", error)
    }
  }

  /**
   * 
   * @param event : date event
   * @description : on bill date select assign date value
   */
  onBillDateSelect(event) {
    try {
      if(event?.detail?.value) {
        let timeStamp = +new Date(event?.detail?.value).setHours(0, 0, 0, 0);
        this.form?.controls?.billDateStamp?.setValue(timeStamp);
        this.dpBillDateValue = Utility.ionDatePickerFormattedString(timeStamp);
        Utility.closeDatePicker(event?.target);
      }
    } catch (error) {
      SentryUtilites.setLog("MoneyInFormComponent:onBillDateSelect", error)
    }
  }
  // ----------------------------------

  /*
    Bill Number
  */

  async populateBillNumber() {
    try {
      this.form.patchValue({
        billNo: await this.allDataService.moneyInService.getNewMoneyInNo()
      });
    } catch (error) {
      SentryUtilites.setLog("MoneyInFormComponent:populateBillNumber", error)
    }
  }

  //----------------------------------------------------------------------------

  getMonayInCartTotal(moneyInCart: MoneyIn[]) {
    try {
      let totalAmount: number = 0;
      moneyInCart?.forEach(moneyIn => totalAmount += Number(moneyIn?.totalAmount));
      return totalAmount;
    } catch (error) {
      SentryUtilites.setLog("MoneyInFormComponent:getMonayInCartTotal", error)
      return 0;
    }
  }

  maxAllowedMoneyInAmount() {
    try {
      let totalAmount: number = 0;
      this.moneyInCart?.forEach(moneyIn => totalAmount += Number(moneyIn?.totalAmount));
      return this.capFractionsToTwo(this.billTotalAmount - (totalAmount || 0.0));
    } catch (error) {
      SentryUtilites.setLog("MoneyInFormComponent:maxAllowedMoneyInAmount", error)
      return 0;
    }
  }

  onSaveClick() {
    try {
      if(this.saveLoader) {
        return false;
      }
      if (this.paramDocumentId) {
        this.update();
      } else {
        this.save();
      }
    } catch (error) {
      SentryUtilites.setLog("MoneyInFormComponent:onSaveClick", error)
    }

  }


  async save() {
    try {
      if (this.form.valid) {
        this.saveLoader = true;
        let moneyIn = new MoneyIn();
        moneyIn = { ...this.form.value };
  
        let savedMoneyIn: MoneyIn = null;
  
        if(this.sale?._localUUID) {
          moneyIn.linkedSaleUUID = this.sale?._localUUID;
        }
        if(this.purchaseReturn?._localUUID) {
          moneyIn.linkedPurchaseReturnUUID = this.purchaseReturn?._localUUID;
        }
        savedMoneyIn = await this.allDataService.moneyInService.save(moneyIn);
  
        if (savedMoneyIn?._localUUID) {
          this.savedMoneyIn.emit(savedMoneyIn);
          this.allDataService.listForceReloadSubs.next('sale-list');
          this.allDataService.listForceReloadSubs.next('purchase-return-list');
          this.allDataService.listForceReloadSubs.next('party-list');
          this.allDataService.listForceReloadSubs.next('moneyin-list');
          await Utility.wait(1000)
          this.allDataService.saleService.reloadList();
          this.allDataService.partyService.reloadList();
          this.allDataService.purchaseReturnService.reloadList();
          this.saveLoader = false;
        }
      }
    } catch (error) {
      SentryUtilites.setLog("MoneyInFormComponent:save", error)
    }
  }

  async update() {
    try {
      if (this.form.valid) {
        this.saveLoader = true;
        let updatedMoneyIn = {
          ...this.fetchedMoneyIn,
          ...this.form.value
        }
        updatedMoneyIn = await this.allDataService.moneyInService.update(updatedMoneyIn);
        if (updatedMoneyIn?._localUUID) {
          this.updatedMoneyIn.emit(updatedMoneyIn);
          this.allDataService.listForceReloadSubs.next('sale-list');
          this.allDataService.listForceReloadSubs.next('purchase-return-list');
          this.allDataService.listForceReloadSubs.next('party-list');
          this.allDataService.listForceReloadSubs.next('moneyin-list');
          await Utility.wait(1000);
          this.allDataService.saleService.reloadList();
          this.allDataService.partyService.reloadList();
          this.allDataService.purchaseReturnService.reloadList();
          this.saveLoader = false;
        }
      }
    } catch (error) {
      SentryUtilites.setLog("MoneyInFormComponent:update", error)
    }
  }

  onMoneyInDateSelect(event) {
    try {
      this.form?.controls?.billDateStamp?.setValue(
        +new Date(event?.detail?.value).setHours(0, 0, 0, 0)
      );
      Utility.closeDatePicker(event?.target);
    } catch (error) {
      SentryUtilites.setLog("MoneyInFormComponent:onMoneyInDateSelect", error)
    }
  }

  /**
   * @description : close date picker
   */
  closeDOBPicker() {
    this.isOpenBillDatePicker = false;
  }
  // -------------------------

}
