import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NgxIndexedDBService } from "ngx-indexed-db";
import { IMonthWisePartyCredit, MonthWisePartyCredit } from "../../models/MonthWisePartyCredit.model";
import { Utility } from "src/app/utils/utility";
import { SentryUtilites } from "src/app/utils/sentryUtilites";

@Injectable({
    providedIn: 'root',
})
export class MonthWisePartyCreditDao {

    constructor(
        private router: Router,
        private ngxIndexedDBService: NgxIndexedDBService,
    ) { }

    /**
     * 
     * @param monthWisePartyCredit : provide monthWisePartyCredit object
     * @returns : return monthWisePartyCredit saved object in indexeddb
     * @description : save monthWisePartyCredit object in indexeddb
     */
    save(partyLocalUUID: string): Promise<MonthWisePartyCredit> {
        return new Promise((resolve, reject) => {
            try {
                if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && partyLocalUUID) {
                    let monthWisePartyCredit = new MonthWisePartyCredit();
                    monthWisePartyCredit.partyLocalUUID = partyLocalUUID;
                    monthWisePartyCredit.ledgerCredit = 0;
                    monthWisePartyCredit.ledger = {};
                    this.ngxIndexedDBService
                        .add(IMonthWisePartyCredit.SCHEMA_NAME, monthWisePartyCredit)
                        .subscribe(
                            (savedMonthWisePartyCredit: MonthWisePartyCredit) => {
                                return resolve(savedMonthWisePartyCredit);
                            },
                            (err) => {
                                console.error(err);
                                if (typeof err == 'string' && err?.includes('objectStore does not exists')) {
                                    this.router.navigate(['idbx-error']);
                                } else if (typeof err?.target?.error == 'object') {
                                    SentryUtilites.setLog("MonthWisePartyCreditDao:save", err?.target?.error)
                                } else {
                                    SentryUtilites.setLog("MonthWisePartyCreditDao:save", err)
                                }
                            }
                        );
                } else {
                  return resolve(null);
                }
            } catch (err) {
                SentryUtilites.setLog("MonthWisePartyCreditDao:save", err)
                return resolve(null);
            }
        });
    }
    // -------------------------------------------------------------------------------------------------------------

    /**
     * 
     * @param monthWisePartyCredits : provide monthWisePartyCredit array of object
     * @returns : return true if successfully execute code
     */
        bulkAdd(monthWisePartyCredits: MonthWisePartyCredit[]): Promise<boolean> {
            return new Promise((resolve, reject) => {
                try {
                    if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && monthWisePartyCredits?.length) {
                        this.ngxIndexedDBService.bulkAdd(IMonthWisePartyCredit.SCHEMA_NAME, monthWisePartyCredits)
                        .subscribe((monthWisePartyCredits) => {
                            return resolve(true);
                        },
                        (err) => {
                            console.error(err);
                            if (typeof err == 'string' && err?.includes('objectStore does not exists')) {
                                this.router.navigate(['idbx-error']);
                            } else if (typeof err?.target?.error == 'object') {
                                SentryUtilites.setLog("MonthWisePartyCreditDao:bulkAdd", err?.target?.error)
                            } else {
                                SentryUtilites.setLog("MonthWisePartyCreditDao:bulkAdd", err)
                            }
                        })
                    } else {
                      return resolve(false);
                    }
                } catch (error) {
                    SentryUtilites.setLog("MonthWisePartyCreditDao:bulkAdd", error)
                    return resolve(false);
                }
            })
        }
        // ---------------------------------------------------------------

    /**
     * 
     * @param monthWisePartyCredit : provide monthWisePartyCredit object
     * @returns : return monthWisePartyCredit saved object in indexeddb
     * @description : update monthWisePartyCredit object in indexeddb
     */
    update(monthWisePartyCredit: MonthWisePartyCredit): Promise<MonthWisePartyCredit> {
        return new Promise(async (resolve, reject) => {
            try {
                if (Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && monthWisePartyCredit?.partyLocalUUID) {
                    let oldData = await this.getByPartyLocalUUID(monthWisePartyCredit?.partyLocalUUID);
                    monthWisePartyCredit._localId = oldData?._localId;
                    this.ngxIndexedDBService
                        .update(IMonthWisePartyCredit.SCHEMA_NAME, monthWisePartyCredit)
                        .subscribe(
                            (updatedMonthWisePartyCredit: MonthWisePartyCredit) => {
                                return resolve(updatedMonthWisePartyCredit);
                            },
                            (err) => {
                                console.error(err);
                                if (typeof err == 'string' && err?.includes('objectStore does not exists')) {
                                    this.router.navigate(['idbx-error']);
                                } else if (typeof err?.target?.error == 'object') {
                                    SentryUtilites.setLog("MonthWisePartyCreditDao:update", err?.target?.error)
                                } else {
                                    SentryUtilites.setLog("MonthWisePartyCreditDao:update", err)
                                }
                            }
                        );
                } else {
                    return resolve(null);
                }
            } catch (err) {
                SentryUtilites.setLog("MonthWisePartyCreditDao:update", err)
                return resolve(null);
            }
        })
    }
    // ---------------------------------------------------------------------------------------------------------------

    /**
     * 
     * @param monthWisePartyCredits : provide monthWisePartyCredit array of object
     * @returns : return true if successfully execute code
     */
    bulkPut(monthWisePartyCredits: MonthWisePartyCredit[]): Promise<boolean> {
        return new Promise((resolve, reject) => {
            try {
                if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && monthWisePartyCredits?.length) {
                    this.ngxIndexedDBService.bulkPut(IMonthWisePartyCredit.SCHEMA_NAME, monthWisePartyCredits)
                    .subscribe((monthWisePartyCredits) => {
                        return resolve(true);
                    },
                    (err) => {
                        console.error(err);
                        if (typeof err == 'string' && err?.includes('objectStore does not exists')) {
                            this.router.navigate(['idbx-error']);
                        } else if (typeof err?.target?.error == 'object') {
                            SentryUtilites.setLog("MonthWisePartyCreditDao:bulkPut", err?.target?.error)
                        } else {
                            SentryUtilites.setLog("MonthWisePartyCreditDao:bulkPut", err)
                        }
                    })
                } else {
                  return resolve(false);
                }
            } catch (error) {
                SentryUtilites.setLog("MonthWisePartyCreditDao:bulkPut", error)
                return resolve(false);
            }
        })
    }
    // ---------------------------------------------------------------

    /**
     * 
     * @param partyLocalUUID : provide party localUUID
     * @param billDateStamp : provide billDateStamp
     * @param creditIncrementBy : creditIncrementBy
     * @returns : return true if successfully execute code
     */
    modifyCredit(
        partyLocalUUID: string,
        billDateStamp: number,
        creditIncrementBy: number
    ): Promise<boolean> {
        return new Promise(async (resolve, rejects) => {
            try {
                if (partyLocalUUID) {
                    let monthWisePartyCredit = await this.getByPartyLocalUUID(partyLocalUUID);
                    if(!monthWisePartyCredit) {
                        monthWisePartyCredit = await this.save(partyLocalUUID);
                    }
                    if (monthWisePartyCredit && Utility.isNumber(creditIncrementBy)) {
                        let yearMonth = Utility.getYearMonthKey(billDateStamp);
                        if (!monthWisePartyCredit?.ledger?.hasOwnProperty(yearMonth)) {
                            monthWisePartyCredit.ledger[yearMonth] = 0;
                        }
                        monthWisePartyCredit.ledger[yearMonth] += Number(creditIncrementBy);
                        monthWisePartyCredit.ledgerCredit += Number(creditIncrementBy);
                        await this.update(monthWisePartyCredit);
                    }
                }
                return resolve(true);
            } catch (err) {
                SentryUtilites.setLog("MonthWisePartyCreditDao:modifyCredit", err)
                return resolve(false);
            }
        })
    }
    // ------------------------------------------------------------

    /**
     * 
     * @param partyLocalUUID : provide partyLocalUUID
     * @returns : return monthWisePartyCredit object by searching partyLocalUUID from indexeddb
     */
    getByPartyLocalUUID(partyLocalUUID: string): Promise<MonthWisePartyCredit> {
        return new Promise((resolve, reject) => {
            try {
                if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && partyLocalUUID) {
                    this.ngxIndexedDBService
                        .getByIndex(IMonthWisePartyCredit.SCHEMA_NAME, 'partyLocalUUID', partyLocalUUID)
                        .subscribe(
                            (monthWisePartyCredit: MonthWisePartyCredit) => {
                                return resolve(monthWisePartyCredit);
                            },
                            (err) => {
                                console.error(err);
                                if (typeof err == 'string' && err?.includes('objectStore does not exists')) {
                                    this.router.navigate(['idbx-error']);
                                } else if (typeof err?.target?.error == 'object') {
                                    SentryUtilites.setLog("MonthWisePartyCreditDao:getByPartyLocalUUID", err?.target?.error)
                                } else {
                                    SentryUtilites.setLog("MonthWisePartyCreditDao:getByPartyLocalUUID", err)
                                }
                            }
                        );
                } else {
                  return resolve(null);
                }
            } catch (err) {
                SentryUtilites.setLog("MonthWisePartyCreditDao:getByPartyLocalUUID", err)
                return resolve(null);
            }
        })
    }
    // ---------------------------------------------------------------------------------------------------------------------

    /**
     * 
     * @returns : return all monthWisePartyCredit array of object from indexeddb
     */
    getAll(): Promise<MonthWisePartyCredit[]> {
        return new Promise((resolve, reject) => {
            try {
                if(Boolean(Utility.getFromLocalStorage('_ezo_login_token'))) {
                    this.ngxIndexedDBService.getAll(IMonthWisePartyCredit.SCHEMA_NAME)
                    .subscribe(
                        (monthWisePartyCredits: MonthWisePartyCredit[]) => {
                            return resolve(monthWisePartyCredits);
                        },
                        (err) => {
                            console.error(err);
                            if (typeof err == 'string' && err?.includes('objectStore does not exists')) {
                                this.router.navigate(['idbx-error']);
                            } else if (typeof err?.target?.error == 'object') {
                                SentryUtilites.setLog("MonthWisePartyCreditDao:getAll", err?.target?.error)
                            } else {
                                SentryUtilites.setLog("MonthWisePartyCreditDao:getAll", err)
                            }
                        }
                    );
                } else {
                  return resolve([]);
                }
            } catch (err) {
                SentryUtilites.setLog("MonthWisePartyCreditDao:getAll", err)
                return resolve([]);
            }
        })
    }
    // -----------------------------------------------------------------------------------------------------------------------------------------------------

}
