import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MoneyOut } from '../../models/MoneyOut.model';
import { Purchase } from '../../models/Purchase.model';
import { Expense } from '../../models/Expense.model';
import { Utils } from '../../utils/utils';
import { MoneyOutFormComponent } from '../money-out-form/money-out-form.component';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';
import { SaleReturn } from '../../models/SaleReturn.model';
import { AllDataService } from '../../services/all-data.service';
import { AlertController, InfiniteScrollCustomEvent } from '@ionic/angular';
import { CommonService } from '../../services/common.service';
import { Utility } from '../../utils/utility';

@Component({
  selector: 'app-money-out-selector',
  templateUrl: './money-out-selector.component.html',
  styleUrls: ['./money-out-selector.component.scss'],
})
export class MoneyOutSelectorComponent implements OnInit {

  @ViewChild('moenyOutFormEle') moenyOutFormEle: MoneyOutFormComponent;

  @Input() purchase: Purchase = null;
  @Input() expense: Expense = null;
  @Input() saleReturn: SaleReturn = null;
  @Input() billTotalAmount: number = null;
  @Input() moneyOutCart: MoneyOut[] = [];
  @Input() isPurchaseList: boolean = false;
  @Input() isExpenseList: boolean = false;
  @Input() isSaleReturnList: boolean = false;
  @Input() isPurchaseForm: boolean = false;
  @Input() isExpenseForm: boolean = false;
  @Input() isSaleReturnForm: boolean = false;

  @Output() moneyOutsSelectedEvent = new EventEmitter<MoneyOut[]>();
  @Output() onModelClose = new EventEmitter<boolean>();

  capFractionsToTwo = Utils.capFractionsToTwo;

  viewPartyWiseMoneyOutList: MoneyOut[] = [];
  completePartyWiseMoneyOutList: MoneyOut[] = [];
  selectedAttachMoneyOutList: MoneyOut[] = [];

  isModalOpen = false;
  isOpenMoneyOutForm = false;
  isEditable = true;
  isOpenLinkMoneyOutList: boolean = false;
  totalMoneyOutPending: number = 0;
  totalSelectedMoneyOutAmount: number = 0;

  constructor(
    private allDataService: AllDataService,
    private alertController: AlertController,
    private commonService: CommonService,
  ) { }

  ngOnInit() { }

  ngAfterViewInit() {
    try {
      this.calculateTotalPendingMoneyOut();
      setTimeout(() => {
        if (this.isPurchaseList || this.isExpenseList || this.isSaleReturnList) {
          this.openMoneyOutSelectorModal();
        }
      }, 0);
    } catch (error) {
      SentryUtilites.setLog("MoneyOutSelectorComponent:ngAfterViewInit", error)
    }
  }

  ngDestory() { }

  calculateTotalPendingMoneyOut() {
    let totalMoneyOutDone = 0;
    this.moneyOutCart?.forEach(moneyOut => totalMoneyOutDone += Utility.isNumber(moneyOut?.totalAmount) ? +moneyOut?.totalAmount : 0);
    this.totalMoneyOutPending = Utils.capFractionsToTwo(this.billTotalAmount - totalMoneyOutDone);
  }

  openMoneyOutSelectorModal(isOpen: boolean = true) {
    try {
      if (this.isEditable) {
        this.isModalOpen = isOpen;
      }
      if(!this.isModalOpen) {
        setTimeout(() => {
          this.onModelClose.emit(true);
        }, 500);
      }else {
        this.toggleMoneyOutForm(true);
      }
      setTimeout(async ()=> {
        await this.moenyOutFormEle?.inpTotalAmountEle?.setFocus();
      }, 500)
    } catch (error) {
      SentryUtilites.setLog("MoneyOutSelectorComponent:openMoneyOutSelectorModal", error)
    }
  }

  async onMoneyOutSelect(moneyOut: MoneyOut) {
    try {
      this.isOpenMoneyOutForm = false;
      if(this.purchase?._localUUID) {
        if(moneyOut?._localUUID && moneyOut?.linkedPurchaseUUID===this.purchase?._localUUID) {
          this.moneyOutCart?.push(moneyOut);
          this.moneyOutsSelectedEvent.emit(this.moneyOutCart);
          this.openMoneyOutSelectorModal(false);
        }
      }
      if(this.expense?._localUUID) {
        if(moneyOut?._localUUID && moneyOut?.linkedExpenseUUID===this.expense?._localUUID) {
          this.moneyOutCart?.push(moneyOut);
          this.moneyOutsSelectedEvent.emit(this.moneyOutCart);
          this.openMoneyOutSelectorModal(false);
        }
      }
      if(this.saleReturn?._localUUID) {
        if(moneyOut?._localUUID && moneyOut?.linkedSaleReturnUUID===this.saleReturn?._localUUID) {
          this.moneyOutCart?.push(moneyOut);
          this.moneyOutsSelectedEvent.emit(this.moneyOutCart);
          this.openMoneyOutSelectorModal(false);
        }
      }
    } catch (error) {
      SentryUtilites.setLog("MoneyOutSelectorComponent:onMoneyOutSelect", error)
    }
  }

  /**
   * 
   * @param value : provide boolean value to open or close link moneyOut modal
   */
  async toggleLinkMoneyOutList(value: boolean) {
    try {
      this.isOpenLinkMoneyOutList = value;
      if(value) {
        let moneyOuts = await this.allDataService.moneyOutService.getAll();
        if(this.isPurchaseList || this.isPurchaseForm) {
          this.completePartyWiseMoneyOutList = moneyOuts.filter(moneyOut => !moneyOut?.linkedPurchaseUUID && !moneyOut?.linkedExpenseUUID && moneyOut?.party?._localUUID === this.purchase?.party?._localUUID && moneyOut?.totalAmount <= this.totalMoneyOutPending);
        } else if(this.isExpenseList || this.isExpenseForm) {
          this.completePartyWiseMoneyOutList = moneyOuts.filter(moneyOut => !moneyOut?.linkedPurchaseUUID && !moneyOut?.linkedExpenseUUID && moneyOut?.party?._localUUID === this.expense?.party?._localUUID && moneyOut?.totalAmount <= this.totalMoneyOutPending);
        }
        this.viewPartyWiseMoneyOutList = this.completePartyWiseMoneyOutList.sort((a, b) => a?.createdStamp - b?.createdStamp).slice(0,50);
        this.totalSelectedMoneyOutAmount = 0;
        this.selectedAttachMoneyOutList = [];
      }
    } catch (error) {
      SentryUtilites.setLog('MoneyOutSelectorComponent:toggleLinkMoneyOutList', error);
    }
  }
  // --------------------------------------

  /**
   * 
   * @param event : check box event
   * @param moneyOut : selected moneyOut
   * @description : store or remove selected moneyOut in selectedAttachMoneyOutList array
   */
  selectedAttachMoneyOut(event: CustomEventInit, moneyOut: MoneyOut) {
    try {
      let isChecked = event?.detail?.checked;
      if(isChecked) {
        this.selectedAttachMoneyOutList.push(moneyOut);
        this.totalSelectedMoneyOutAmount += Utility.isNumber(moneyOut?.totalAmount) ? +moneyOut?.totalAmount : 0; 
      } else {
        let index = this.selectedAttachMoneyOutList.findIndex(el => moneyOut?._localUUID === el?._localUUID);
        if(index != -1) {
          this.selectedAttachMoneyOutList.splice(index,1);
          this.totalSelectedMoneyOutAmount -= Utility.isNumber(moneyOut?.totalAmount) ? +moneyOut?.totalAmount : 0;
        }
      }
    } catch (error) {
      SentryUtilites.setLog('MoneyOutSelectorComponent:selectedAttachMoneyOut', error);
    }
  }
  // -------------------------------------------

  /**
   * 
   * @description : open attach money out alert and perform attach moneyOuts opration
   */
  async attachMoneyOuts() {
    try {
      if(this.selectedAttachMoneyOutList?.length) {
        let linkMoneyOutAlert = await this.alertController.create({
          header: 'Confirm!',
          message: `Do you want to attach ₹${this.totalSelectedMoneyOutAmount} payment with bill ${this.purchase?.billNo || this.expense?.billNo}?`,
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              handler: () => {},
            },
            {
              text: 'Attach',
              role: 'confirm',
              handler: async () => {
                if ((this.isPurchaseList || this.isPurchaseForm) && this.purchase?._localUUID) {
                  for(let i = 0; i < this.selectedAttachMoneyOutList?.length; i++) {
                    let moneyOut = this.selectedAttachMoneyOutList[i];
                    moneyOut.linkedPurchaseUUID = this.purchase?._localUUID;
                    await this.allDataService.moneyOutService.update(moneyOut);
                    this.moneyOutCart?.push(moneyOut);
                  }
                } else if ((this.isExpenseList || this.isExpenseForm) && this.expense?._localUUID) {
                  for(let i = 0; i < this.selectedAttachMoneyOutList?.length; i++) {
                    let moneyOut = this.selectedAttachMoneyOutList[i];
                    moneyOut.linkedExpenseUUID = this.expense?._localUUID;
                    await this.allDataService.moneyOutService.update(moneyOut);
                    this.moneyOutCart?.push(moneyOut);
                  }
                }
                this.commonService.generateToast('success', `Total ${this.selectedAttachMoneyOutList?.length} Money Outs with total payment amount of ₹${this.totalSelectedMoneyOutAmount} is successfully attached with bill ${this.purchase?.billNo || this.expense?.billNo}.`);
                this.moneyOutsSelectedEvent.emit(this.moneyOutCart);
                this.toggleLinkMoneyOutList(false);
                if(this.totalSelectedMoneyOutAmount == this.totalMoneyOutPending) {
                  this.toggleMoneyOutForm(false);
                } else {
                  this.moenyOutFormEle.initializeReactiveForm();
                  this.moenyOutFormEle.populateForm();
                  this.calculateTotalPendingMoneyOut();
                  this.selectedAttachMoneyOutList = [];
                }
                this.totalSelectedMoneyOutAmount = 0
              },
            },
          ],
          mode: 'ios',
        });
        linkMoneyOutAlert.present();
      } else {
        this.commonService.generateToast('warning', 'Please Select Money Out');
      }
    } catch (error) {
      SentryUtilites.setLog('MoneyOutSelectorComponent:attachMoneyOuts', error);
    }
  }
  // --------------------------------------

  closeMoneyOutForm() {
    this.isModalOpen = false;
  }

  getTotal(moneyOutCart: MoneyOut[]) {
    try {
      let totalAmount: number = 0;
      moneyOutCart?.forEach(moneyOut => totalAmount += Number(moneyOut?.totalAmount));
      return this.capFractionsToTwo(totalAmount);
    } catch (error) {
      SentryUtilites.setLog("MoneyOutSelectorComponent:getTotal", error)
    }
  }

  enable() {
    this.isEditable = true;
  }

  disable() {
    this.isEditable = false;
  }

  toggleMoneyOutForm(value: boolean) {
    try {
      this.isOpenMoneyOutForm = value;
      if(value==false) {
        this.openMoneyOutSelectorModal(false);
      }
    } catch (error) {
      SentryUtilites.setLog("MoneyOutSelectorComponent:toggleMoneyOutForm", error)
    }
  }

  getSelectedMoneyOuts() {
    return this.moneyOutCart;
  }

  deleteMoneyOut(moneyOut: MoneyOut) {
    try {
      const index = this.moneyOutCart?.findIndex(x => x?._localUUID == moneyOut?._localUUID);
      if (index != -1) {
        this.moneyOutCart?.splice(index, 1);
        this.moneyOutsSelectedEvent.emit(this.moneyOutCart);
      }
    } catch (error) {
      SentryUtilites.setLog("MoneyOutSelectorComponent:deleteMoneyOut", error)
    }
  }

  loadMorePartyWiseMoneyOutListData(event) {
    try {
      if (this.viewPartyWiseMoneyOutList.length > 0 && this.viewPartyWiseMoneyOutList.length <= this.completePartyWiseMoneyOutList.length) {
        let appendListLength = this.completePartyWiseMoneyOutList.length - this.viewPartyWiseMoneyOutList.length;
        let lastEl = this.viewPartyWiseMoneyOutList[this.viewPartyWiseMoneyOutList.length - 1];
        let counter = 0;
        for (let i = 0; i < this.completePartyWiseMoneyOutList.length; i++) {
          if (this.completePartyWiseMoneyOutList[i]._localUUID == lastEl._localUUID) {
            counter = 1;
            continue;
          }
          if (counter > 0 && appendListLength >= 50) {
            if (counter <= 50) {
              this.viewPartyWiseMoneyOutList.push(this.completePartyWiseMoneyOutList[i])
            } else {
              break;
            }
            counter++;
          } else if(counter > 0 && appendListLength < 50) {
            if (counter <= appendListLength) {
              this.viewPartyWiseMoneyOutList.push(this.completePartyWiseMoneyOutList[i])
            } else {
              break;
            }
            counter++;
          }
        }
        (event as InfiniteScrollCustomEvent)?.target?.complete();
      }
    } catch (error) {
      SentryUtilites.setLog("MoneyOutSelectorComponent:loadMorePartyWiseMoneyOutListData", error)
    }
  }

}
