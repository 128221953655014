import { MonthWiseItemStockDao } from './dao/month-wise-item-stock.dao';
import { ItemStockAdjustDao } from './dao/item-stock-adjust.dao';
import { BehaviorSubject } from "rxjs";
import { Utility } from "../utils/utility";
import { ExpressServerService } from "./api/express-server.service";
import { IDataService } from "../../interface/IDataService.interface";
import { ItemStockAdjust } from "../models/ItemStockAdjust.model";
import { ItemDao } from './dao/item.dao';
import { AuthService } from './auth/auth.service';
import { SentryUtilites } from '../utils/sentryUtilites';

export class ItemStockAdjustService implements IDataService<ItemStockAdjust>{
  private static _instance: ItemStockAdjustService;

  public static getInstance(
    dao: ItemStockAdjustDao, 
    itemDao: ItemDao, 
    expressServerService: ExpressServerService, 
    authService: AuthService,
    monthWiseItemStockDao: MonthWiseItemStockDao,
    ) {
    if (!this._instance) {
      this._instance = new ItemStockAdjustService(
        dao, 
        itemDao, 
        expressServerService,
        authService,
        monthWiseItemStockDao
        )
      this._instance.initService();
    }
    this._instance.reloadList();
    return this._instance;
  }

  constructor(
    dao: ItemStockAdjustDao, 
    itemDao: ItemDao, 
    expressServerService: ExpressServerService, 
    authService: AuthService,
    monthWiseItemStockDao: MonthWiseItemStockDao,
    ) {
    this.dao = dao;
    this.itemDao = itemDao;
    this.expressServerService = expressServerService;
    this.authService = authService;
    this.monthWiseItemStockDao = monthWiseItemStockDao;
  }
  dao: ItemStockAdjustDao;
  itemDao: ItemDao;
  expressServerService: ExpressServerService;
  authService: AuthService;
  monthWiseItemStockDao: MonthWiseItemStockDao;

  LIST_REFRESH_RATE = 1000;
  selectedProfileId: string = null;
  selectedProfileUserId: string = null;
  updateSubs = new BehaviorSubject<ItemStockAdjust>(null);


  lastReloadStamp: number = 0;
  isReloadPostpond = false;

  initService() {
    this.selectedProfileId = Utility.getFromLocalStorage('selectedProfile');
    this.selectedProfileUserId = Utility.getFromLocalStorage('selectedProfileUserId');
    this.reloadList();
  }

  async reloadList() {
    try {
      if (this.isReloadPostpond) {
        return;
      }
      const currentStamp = +new Date();
      if (this.lastReloadStamp < (currentStamp - this.LIST_REFRESH_RATE)) {
        this.lastReloadStamp = currentStamp;
        this.trySyncUnsynced();
      } else {
        this.isReloadPostpond = true;
        setTimeout(() => {
          this.isReloadPostpond = false;
          this.reloadList();
        }, this.LIST_REFRESH_RATE + 100);
      }
    } catch (error) {
      SentryUtilites.setLog("ItemStockAdjustService:reloadList", error)
      return null;
    }
  }

  getAll() {
    return this.dao.getAll();
  }
  
  getAllByPromise() {
    return this.dao.getAllByProfile(this.selectedProfileId);
  }

  getAllByPromiseByProfile(profileId: string) {
    return this.dao.getAllByProfile(profileId);
  }

  getById(id: number): Promise<ItemStockAdjust> {
    return this.dao.getById(id);
  }

  getByUUID(uuid: string): Promise<ItemStockAdjust> {
    return this.dao.getByUUID(uuid);
  }

  getAllByLinkedItemUUID(linkedItemUUID: string): Promise<ItemStockAdjust[]> {
    return this.dao.getAllByLinkedItemUUID(linkedItemUUID);
  }

  getAllByLinkedItemUUIDByDateRange(linkedItemUUID: string, startTime: number, endTime: number): Promise<ItemStockAdjust[]> {
    return new Promise(async (resolve, reject) => {
      try {
        let allDocs = await this.dao.getAllByLinkedItemUUID(linkedItemUUID);
        if (allDocs != null) {
          let filteredDocs = allDocs.filter(doc => doc?.createdStamp >= startTime && doc?.createdStamp < endTime);
          return resolve(filteredDocs);
        } else {
          return resolve([]);
        }
      } catch (error) {
        SentryUtilites.setLog("ItemStockAdjustService:getAllByLinkedItemUUIDByDateRange", error)
        return resolve([]);
      }
    });
  }

  save(itemStockAdjust: ItemStockAdjust): Promise<ItemStockAdjust> {
    if(Utility.isTruthy(itemStockAdjust)) {
      itemStockAdjust.userId = this.selectedProfileUserId;
      if(!itemStockAdjust.profileId) {
        itemStockAdjust.profileId = this.selectedProfileId;
      }
      itemStockAdjust.createdBy = itemStockAdjust.lastModifiedBy = this.authService.getLoginPhone();
      itemStockAdjust.createdByName = itemStockAdjust.lastModifiedByName = Utility.getCreatedByName();
  
      return new Promise(async (resolve, reject) => {
        try {
          let savedItemStockAdjust = await this.dao.save(itemStockAdjust);
          if (savedItemStockAdjust?._localUUID) {
            let item  = await this.itemDao.getByUUID(itemStockAdjust?.linkedItemUUID);
            item.lastModifiedBy = itemStockAdjust?.lastModifiedBy;
            item.lastModifiedByName = itemStockAdjust?.lastModifiedByName;
            await this.itemDao.updateStock(
              item,
              itemStockAdjust?.quantity
            )
            await this.monthWiseItemStockDao?.modifyStock(
              item?._localUUID,
              savedItemStockAdjust?.updatedStamp,
              itemStockAdjust?.quantity
            )
          }
          this.reloadList();
          return resolve(savedItemStockAdjust);
        } catch (error) {
          SentryUtilites.setLog("ItemStockAdjustService:save", error)
          return resolve(null);
        }
      });
    } else {
      return null;
    }
  }

  update(itemStockAdjust: ItemStockAdjust): Promise<ItemStockAdjust> {
    return new Promise(async (resolve, reject) => {
      try {
        if(itemStockAdjust?._localUUID) {
          itemStockAdjust.lastModifiedBy = this.authService.getLoginPhone();
          itemStockAdjust.lastModifiedByName = Utility.getCreatedByName();
          let updatedItemStockAdjust = await this.dao.update(itemStockAdjust);
          this.reloadList();
          this.updateSubs.next(updatedItemStockAdjust);
          return resolve(updatedItemStockAdjust);
        }
        return resolve(null);
      } catch (error) {
        SentryUtilites.setLog("ItemStockAdjustService:update", error)
        return resolve(null);
      }
    });
  }

  delete(itemStockAdjust: ItemStockAdjust): Promise<ItemStockAdjust> {
    return new Promise(async (resolve, reject) => {
      try {
        if(itemStockAdjust?._localUUID) {
          itemStockAdjust.lastModifiedBy = this.authService.getLoginPhone();
          itemStockAdjust.lastModifiedByName = Utility.getCreatedByName();
          let deletedItemStockAdjust = await this.dao.delete(itemStockAdjust);
          this.reloadList();
          this.updateSubs.next(deletedItemStockAdjust);
          return resolve(deletedItemStockAdjust);
        }
        return resolve(null);
      } catch (error) {
        SentryUtilites.setLog("ItemStockAdjustService:delete", error)
        return resolve(null);
      }
    });
  }

  updateSyncStamp(el: ItemStockAdjust): Promise<ItemStockAdjust> {
    return new Promise(async (resolve, reject) => {
      try {
        let updatedEl = await this.dao.updateSyncStamp(el);
        this.updateSubs.next(updatedEl);
        return resolve(updatedEl);
      } catch (error) {
        SentryUtilites.setLog("ItemStockAdjustService:updateSyncStamp", error)
        return resolve(null);
      }
    });
  }

  isSyncLock = false;
  isSyncPostPond = false;
  async trySyncUnsynced(postpond?: boolean) {
    try {
      if (this.isSyncLock) {
        if (!this.isSyncPostPond) {
          setTimeout(() => {
            this.isSyncPostPond = true;
            this.trySyncUnsynced(true);
          }, 200);
        }
        return true;
      }
      if (postpond) {
        this.isSyncPostPond = false;
      }
      this.isSyncLock = true;
      let unSyncedElements: ItemStockAdjust[] = await this.dao.getAllUnsynced(this.selectedProfileId);
      if (unSyncedElements && unSyncedElements?.length) {
        try {
  
          for (let i = 0; i < unSyncedElements?.length; i++) {
            let unSyncedElement = unSyncedElements[i];
            if(unSyncedElement?._localUUID) {
              unSyncedElement['updatedStamp'] = +new Date();
            }
          }
  
          await this.dao.bulkPut(unSyncedElements);
          await Utility.wait(1000);
          
          let chunkArr = Utility.getChunkArr(unSyncedElements);
          let chunkArrLength = chunkArr?.length;
  
          for(let i = 0; i < chunkArrLength; i++) {
            let result = await this.expressServerService.makeSyncCall('itemStockAdjust', chunkArr[i]);
            if (result && result?.['records']?.length) {
              let arr = result?.['records'];
              for (let i = 0; i < arr?.length; i++) {
                const el = arr[i];
                await this.updateSyncStamp(el);
              }
            }
          }
        } catch (err) {
          SentryUtilites.setLog("ItemStockAdjustService:trySyncUnsynced:inner", err)
        }
      }
      this.isSyncLock = false;
    } catch (error) {
      SentryUtilites.setLog("ItemStockAdjustService:trySyncUnsynced", error)
    }
  }

  copyData(fromProfileId: string, toProfileId: string): Promise<boolean> {
    return new Promise(async (resolve,reject) => {
      try {
        if(Utility.isTruthy(fromProfileId) && Utility.isTruthy(toProfileId)) {
          let fromRecords = await this.getAllByPromiseByProfile(fromProfileId);
          if(fromRecords?.length) {
            let toRecords: ItemStockAdjust[] = [];
            for (let i = 0; i < fromRecords?.length; i++) {
              const fetchedRecord = fromRecords[i];
              if(fetchedRecord?._localUUID) {
                fetchedRecord.profileId = toProfileId;
                delete fetchedRecord?._localId;
                delete fetchedRecord?._localUUID;
                let savedRecord = await this.save(fetchedRecord);
                if(savedRecord?._localUUID) {
                  toRecords?.push(savedRecord);
                }
              }
            }
            if(fromRecords?.length === toRecords?.length) {
              return resolve(true);
            }
          }
        }
        return resolve(false);
      } catch (error) {
        SentryUtilites.setLog("ItemStockAdjustService:copyData", error)
        return resolve(false);
      }
    });
  }

}
