import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SaleReturn } from '../../../models/SaleReturn.model';
import { PinVerificationComponent } from '../../pin-verification/pin-verification.component';
import { Utils } from '../../../utils/utils';
import { AccessControlService } from '../../../services/auth/access-control.service';
import { AllDataService } from '../../../services/all-data.service';
import { AuthService } from '../../../services/auth/auth.service';
import { Router } from '@angular/router';
import { SentryUtilites } from '../../../utils/sentryUtilites';
import { MoneyOut } from '../../../models/MoneyOut.model';
import { Utility } from '../../../utils/utility';

@Component({
  selector: 'app-sale-return-row',
  templateUrl: './sale-return-row.component.html',
  styleUrls: ['./sale-return-row.component.scss'],
})
export class SaleReturnRowComponent implements OnInit {

  @ViewChild('pinVerificationElement') pinVerificationElement: PinVerificationComponent;

  @Input() saleReturn: SaleReturn = null;
  @Input() isRecentTransactionList: boolean = false;

  @Output() onDeleteClick = new EventEmitter<SaleReturn>();
  @Output() onAddPaymentClick = new EventEmitter<SaleReturn>();

  capFractionsToTwo = Utils.capFractionsToTwo;

  constructor(
    private router: Router,
    private accessControlService: AccessControlService,
    private allDataService: AllDataService,
    private authService: AuthService,
  ) { }

  ngOnInit() { }

  async billView(saleReturn: SaleReturn) {
    try {
      if(!saleReturn?.deletedStamp){
        let isPermit = await this.accessControlService.isUserHasAccess({action:'viewSaleReturn'});
        if(!isPermit) {
          return alert("Permission: You don't have permission to view bill. Please contact to your owner.");
        }
        this.router.navigate([`bill-view-sale-return/${saleReturn?._localUUID}`]);
      }
    } catch (error) {
      SentryUtilites.setLog("SaleReturnRowComponent:billView", error)
      alert("Something went wrong.");
    }
  }

  openTransactionPINModal() {
    this.pinVerificationElement?.openTransactionPINModal();
  }

  verifyTransactionPIN(event) {
    try {
      if(event) {
        this.router.navigate([`sale-return/form/${this.saleReturn?._localUUID}`]);
      }
    } catch (error) {
      SentryUtilites.setLog("SaleReturnRowComponent:verifyTransactionPIN", error)
    }
  }

  async edit(saleReturn: SaleReturn) {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({action:'editSaleReturn'});
      if(!isPermit) {
        return alert("Permission: You don't have permission to edit sale Return. Please contact to your owner.");
      }
      this.openTransactionPINModal();
    } catch (error) {
      SentryUtilites.setLog("SaleReturnRowComponent:edit", error)
      alert("Something went wrong.");
    }
  }

  getBalanceAmount(moneyOuts: MoneyOut[], totalAmount: number) {
    try {
      if(!moneyOuts || !moneyOuts?.length) {
        return this.capFractionsToTwo(totalAmount);
      }
      if (moneyOuts?.length) {
        let receivedAmount: number = 0;
        moneyOuts?.forEach(moneyOut => receivedAmount += Number(moneyOut?.totalAmount));
        let pendingAmount = totalAmount - receivedAmount;
        pendingAmount = pendingAmount < 0 ? 0 : pendingAmount;
        return this.capFractionsToTwo(pendingAmount);
      }
    } catch (error) {
      SentryUtilites.setLog("SaleReturnRowComponent:getBalanceAmount", error)
      return 0;
    }
  }

  extraMoneyOutAmount(moneyOuts: MoneyOut[], totalAmount: number) {
    try {
      if (moneyOuts?.length) {
        let receivedAmount: number = 0;
        moneyOuts?.forEach(moneyOut => receivedAmount += Number(moneyOut?.totalAmount));
        if(receivedAmount > totalAmount) {
          return receivedAmount - totalAmount;
        }else {
          return 0;
        }
      }
    } catch (error) {
      SentryUtilites.setLog("SaleReturnRowComponent:extraMoneyOutAmount", error)
      return 0;
    }
  }

  delete(saleReturn: SaleReturn) {
    this.onDeleteClick.emit(saleReturn);
  }

  async addPayment(saleReturn: SaleReturn) {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({action:'createMoneyOut'});
      if(!isPermit) {
        return alert("Permission: You don't have permission to create money out. Please contact to your owner.");
      }
      this.onAddPaymentClick.emit(saleReturn);
    } catch (error) {
      SentryUtilites.setLog("SaleReturnRowComponent:addPayment", error)
      alert("Something went wrong.");
    }
  }

  async setPaymentMode(paymentMode: 'cash' | 'cheque' | 'bank') {
    try {
      if(this.saleReturn?._localUUID && this.saleReturn?.moneyOuts?.length) {
        this.saleReturn.moneyOuts[0].paymentMode = paymentMode;
        this.saleReturn.lastModifiedBy = this.authService.getLoginPhone();
        this.saleReturn.lastModifiedByName = Utility.getCreatedByName();
        this.saleReturn = await this.allDataService.saleReturnService.update(this.saleReturn);
        if(this.saleReturn?.moneyOuts) {
          let moneyOut = await this.allDataService.moneyOutService.getByUUID(this.saleReturn?.moneyOuts[0]?._localUUID);
          if(moneyOut?._localUUID) {
            moneyOut.paymentMode = paymentMode;
            await this.allDataService.moneyOutService.update(moneyOut);
          }
        }
  
        this.allDataService.itemService.reloadList();
        this.allDataService.partyService.reloadList();
        this.allDataService.moneyOutService.reloadList();
        this.allDataService.listForceReloadSubs.next('sale-return-list');
        setTimeout(()=> {
          this.allDataService.listForceReloadSubs.next('item-list');
          this.allDataService.listForceReloadSubs.next('party-list');
          this.allDataService.listForceReloadSubs.next('moneyout-list');
        }, 2000);
      }
    } catch (error) {
      SentryUtilites.setLog("SaleReturnRowComponent:setPaymentMode", error)
    }
  }

  async viewSale(saleReturn: SaleReturn) {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({action:'viewSale'});
      if(!isPermit) {
        return alert("Permission: You don't have permission to view Sale. Please contact to your owner.");
      }
      if(saleReturn?.linkedSaleUUID) {
        this.router.navigate([`bill-view/${saleReturn?.linkedSaleUUID}`]);
      }
    } catch (error) {
      SentryUtilites.setLog("SaleReturnRowComponent:viewSale", error)
    }
  }

}
