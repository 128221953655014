import { Injectable } from "@angular/core";
import { Utility } from "../utils/utility";
import { ExpressServerService } from "./api/express-server.service";
import { AuthService } from "./auth/auth.service";
import { IngredientStockAdjust } from "../models/IngredientStockAdjust.model";
import { Ingredient } from "../models/Ingredient.model";
import { SentryUtilites } from "../utils/sentryUtilites";

@Injectable({
  providedIn: 'root'
})
export class IngredientStockAdjustService {
  
  constructor(
    private expressServerService: ExpressServerService,
    private authService: AuthService,
  ) { }
  
  async getAll(): Promise<IngredientStockAdjust[]> {
    try {
      let profileId = Utility.getFromLocalStorage('selectedProfile');
      let userId = Utility.getFromLocalStorage('selectedProfileUserId');
      let res = await this.expressServerService.makeGetAllCall(`ingredientStockAdjust/getAllByProfile?profileId=${profileId}&userId=${userId}`);
      return res?.['records'] || [];
    } catch (error) {
      SentryUtilites.setLog("IngredientStockAdjustService:getAll", error)
      return [];
    }
  }

  async save(ingredientStockAdjust: IngredientStockAdjust): Promise<IngredientStockAdjust> {
    try {
      if(Utility.isTruthy(ingredientStockAdjust)) {
        let profileId = Utility.getFromLocalStorage('selectedProfile');
        let selectedProfileUserId = Utility.getFromLocalStorage('selectedProfileUserId');
        ingredientStockAdjust.userId = selectedProfileUserId;
        ingredientStockAdjust.profileId = profileId;
        ingredientStockAdjust.createdBy = ingredientStockAdjust.lastModifiedBy = this.authService.getLoginPhone();
        ingredientStockAdjust.createdByName = ingredientStockAdjust.lastModifiedByName = Utility.getCreatedByName();
        let timeStamp = +new Date();
        ingredientStockAdjust.createdStamp = timeStamp;
        ingredientStockAdjust.updatedStamp = timeStamp;
        ingredientStockAdjust.userUpdatedStamp = timeStamp;
        ingredientStockAdjust.deletedStamp = 0;
        ingredientStockAdjust.syncStamp = 0;
        ingredientStockAdjust._localUUID = Utility.getUUID();
    
        let res = await this.expressServerService.makeEzoOfServerPostCall(`ingredientStockAdjust/save`,{ingredientStockAdjust});
        return res?.['records'] || null;
      } else {
        return null;
      }
    } catch (error) {
      SentryUtilites.setLog("IngredientStockAdjustService:save", error)
      return null;
    }
  }

  async saveAll(ingredientStockAdjusts: IngredientStockAdjust[]): Promise<IngredientStockAdjust[]> {
    try {
      let profileId = Utility.getFromLocalStorage('selectedProfile');
      let selectedProfileUserId = Utility.getFromLocalStorage('selectedProfileUserId');
  
      ingredientStockAdjusts?.map(ingredientStockAdjust => {
        if(Utility.isTruthy(ingredientStockAdjust)) {
          ingredientStockAdjust.userId = selectedProfileUserId;
          ingredientStockAdjust.profileId = profileId;
          ingredientStockAdjust.createdBy = ingredientStockAdjust?.createdBy || this.authService.getLoginPhone();
          ingredientStockAdjust.lastModifiedBy = ingredientStockAdjust?.lastModifiedBy || this.authService.getLoginPhone();
          ingredientStockAdjust.createdByName = ingredientStockAdjust?.createdByName || Utility.getCreatedByName();
          ingredientStockAdjust.lastModifiedByName = ingredientStockAdjust?.lastModifiedByName || Utility.getCreatedByName();
          let timeStamp = +new Date();
          ingredientStockAdjust.createdStamp = ingredientStockAdjust?.createdStamp || timeStamp;
          ingredientStockAdjust.updatedStamp = timeStamp;
          ingredientStockAdjust.userUpdatedStamp = timeStamp;
          ingredientStockAdjust.deletedStamp = 0;
          ingredientStockAdjust.syncStamp = ingredientStockAdjust?.syncStamp || 0;
          ingredientStockAdjust._localUUID = ingredientStockAdjust?._localUUID || Utility.getUUID();
          delete ingredientStockAdjust?.['_id'];
        }
      });
  
      let res = await this.expressServerService.makeEzoOfServerPostCall(`ingredientStockAdjust/saveAll`,{ingredientStockAdjusts});
      return res?.['records'] || [];
    } catch (error) {
      SentryUtilites.setLog("IngredientStockAdjustService:saveAll", error)
      return [];
    }
  }

  async getTransactions(uuid: string): Promise<{ingredient: Ingredient, ingredientStockAdjusts: IngredientStockAdjust[]}> {
    try {
      let res = await this.expressServerService.makeGetAllCall(`ingredientStockAdjust/getTransactions?linkedIngredientUUID=${uuid}`);
      return res?.['records'] || null;
    } catch (error) {
      SentryUtilites.setLog("IngredientStockAdjustService:getTransactions", error)
      return null;
    }
  }

  async getYesterdayEOD(uuid: string): Promise<IngredientStockAdjust> {
    try {
      let res = await this.expressServerService.makeGetAllCall(`ingredientStockAdjust/getYesterdayEOD?linkedIngredientUUID=${uuid}`);
      return res?.['records'] || null;
    } catch (error) {
      SentryUtilites.setLog("IngredientStockAdjustService:getYesterdayEOD", error)
      return null;
    }
  }

}