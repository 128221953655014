<ion-header>
  <ion-toolbar class="{{ getHeaderColorClass() }}">
    <app-navigation-control slot="start" type="estimate/form"></app-navigation-control>
    <ion-title>Create Estimate</ion-title>
    <ion-chip class="chip-barcode" slot="end" color="tertiary">
      <span class="title">Barcode Scanner Mode</span>
      <span>
        <ion-checkbox
          slot="end"
          color="tertiary"
          [checked]="barcodeScannerMode"
          (ionChange)="onBarcodeScannerModeChange($event)"
        >
        </ion-checkbox>
      </span>
    </ion-chip>
    <ion-chip
      slot="end"
      fill="clear"
      class="btn-item-name padding-start-none"
      color="primary"
      (click)="openSettingsModal()"
    >
      <ion-icon name="settings-sharp"></ion-icon>
    </ion-chip>
  </ion-toolbar>
</ion-header>

<ion-content>

  <!-- bill date modal start -->
  <ion-modal 
    class="date-modal" 
    [keepContentsMounted]="keepContentsMounted" 
    [isOpen]="isOpenBillDatePicker" 
    (willDismiss)="closeDOBPicker()">
    <ng-template>
      <ion-datetime
        appYearDefaultBtn   
        [showDefaultButtons]="true"
        [max]="maxDate"
        [value]="dpBillDateValue"
        (ionChange)="onBillDateSelect($event)"
        presentation="date"
      >
        <span slot="title">Select a Bill Date</span>
      </ion-datetime>
    </ng-template>
  </ion-modal>
  <!-- bill date modal end -->

  <!-- due date modal start -->
  <ion-modal 
    class="date-modal" 
    [keepContentsMounted]="keepContentsMounted" 
    [isOpen]="isOpenDueDatePicker"
    (willDismiss)="closeDOBPicker()">
    <ng-template>
      <ion-datetime 
        appYearDefaultBtn 
        [min]="minDueDate" 
        [showDefaultButtons]="true" 
        presentation="date" 
        [value]="dpDueDateValue" 
        [max]="maxDate" 
        (ionChange)="onBillDueDateSelect($event)">
        <span slot="title">Select a Bill Due Date</span>
      </ion-datetime>
    </ng-template>
  </ion-modal>
  <!-- due date modal end -->

  <!-- E way bill date modal start -->
  <ion-modal 
    class="modal-transport-date"
    [keepContentsMounted]="keepContentsMounted"
    [isOpen]="isOpenEwayBillDatePicker"
    (willDismiss)="closeDOBPicker()">
    <ng-template>
      <ion-datetime 
        appYearDefaultBtn 
        [value]="dpEWayBillDateValue" 
        [min]="minDateTransport" 
        [showDefaultButtons]="true" 
        presentation="date" 
        [max]="maxDate"
        (ionChange)="onEwayBillDateSelect($event)">
      </ion-datetime>
    </ng-template>
  </ion-modal>
  <!-- E way bill date modal end -->

  <!-- delivery date modal start -->
  <ion-modal 
    class="modal-transport-date"
    [keepContentsMounted]="keepContentsMounted"
    [isOpen]="isOpenDeliveryDatePicker"
    (willDismiss)="closeDOBPicker()">
    <ng-template>
      <ion-datetime 
        appYearDefaultBtn 
        [value]="dpDeliveryDateValue" 
        [min]="minDateTransport" 
        [showDefaultButtons]="true" 
        presentation="date" 
        [max]="maxDate"
        (ionChange)="onDeliveryDateSelect($event)">
      </ion-datetime>
    </ng-template>
  </ion-modal>
  <!-- delivery date modal start -->

  <ion-grid>

    <form [formGroup]="form">

      <ion-row>
        <ion-col size="5">
          <ion-row class="bill-dates-holder">
            <ion-col size="6">
              <ion-button fill="clear" class="btn-bill-dates-title btn-txt-black">
                Estimate No
              </ion-button>
              <ion-input class="new-ion-input" [disabled]="fetchedEstimate?.createdStamp ? true : false" placeholder="eg. INV_001" formControlName="billNo"></ion-input>
            </ion-col>
            <ion-col size="5">
              <ion-row>
                <ion-col size="6">
                  <ion-button fill="clear" class="btn-bill-dates-title btn-txt-black">
                    Bill Date
                  </ion-button>
                </ion-col>
                <ion-col size="6">

                  <!-- bill date input start -->
                  <input 
                    type="button" 
                    class="date-input"
                    [value]="setDateValue(form?.value?.billDateStamp || todayDate)"
                    (click)="isOpenBillDatePicker = true;">
                  <!-- bill date input end -->

                </ion-col>
              </ion-row>
            </ion-col>
            <ion-col size="6">
              <ion-button fill="clear" class="btn-bill-dates-title btn-txt-black">
                Billing Term
              </ion-button>
              <ion-select
                [interfaceOptions]="{header: 'Billing Term'}"
                class="new-ion-select"
                interface="alert"
                placeholder="Select"
                (ionChange)="onBillingTermSelect($event)"
                formControlName="billingTerm"
              >
                <ion-select-option
                  *ngFor="let term of dueNetObj|keyvalue : returnZero"
                  [value]="term.key"
                >
                  {{ term.value.title }}
                </ion-select-option>
              </ion-select>
            </ion-col>
            <ion-col size="5">
              <ion-row>
                <ion-col size="6">
                  <ion-button fill="clear" class="btn-bill-dates-title btn-txt-black">
                    Due Date
                  </ion-button>
                </ion-col>
                <ion-col size="6">

                  <!-- due date input start -->
                  <input 
                    type="button" 
                    class="date-input"
                    [value]="setDateValue(form?.value?.dueDateStamp || todayDate)"
                    (click)="isOpenDueDatePicker = true;">
                  <!-- due date input end -->

                </ion-col>
              </ion-row>
            </ion-col>
          </ion-row>
        </ion-col>

        <ion-col size="7">
          <app-party-selector
            *ngIf="listStyleView || isOpenSecondaryPartyModal"
            #partySelector
            [isSale] = "true"
            [isSecondaryPartyView]="!listStyleView"
            [totalAmount]="form?.value?.totalAmount" 
            [cartLength]="form?.value?.billItems?.length"
            [cashDiscountPercentage]="cashDiscountPercentage"
            [dSetDiscountOfferTypeI]="selectedProfile?.dSetDiscountOfferTypeI"
            [dSetDiscountOfferTypeII]="selectedProfile?.dSetDiscountOfferTypeII"
            [dSetDiscountStatusI]="selectedProfile?.dSetDiscountStatusI"
            [defaultCashParty]="selectedProfile?.iSetAutoCashSaleStatus"
            [dSetDiscountStatusII]="selectedProfile?.dSetDiscountStatusII"
            (partySelectedEvent)="onPartySelect($event)"
          >
          </app-party-selector>
          <app-party-restaurant-selector
            *ngIf="!listStyleView"
            #partyRestaurantSelector
            [defaultCashParty]="selectedProfile?.iSetAutoCashSaleStatus"
            [noPartySelectError]="noPartySelectError"
            (partySelectedEvent)="onPartySelect($event)"
          >
          </app-party-restaurant-selector>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="5">

        </ion-col>
        <ion-col size="7">
          <ion-row class="delivery-state-holder">
            <ion-col>
              <ion-button 
                fill="clear" 
                class="btn-title btn-clear-txt-only btn-txt-black">
                Delivery State
              </ion-button>
              <ion-select 
                [interfaceOptions]="{header: 'Delivery State'}" 
                class="new-ion-select delivery-state-select" 
                interface="alert" 
                placeholder="Select" 
                formControlName="deliveryProvience">
                <ion-select-option 
                  *ngFor="let state of states|keyvalue : returnZero" 
                  [value]="state.key"
                  [disabled]="isDisabledState(state?.value)">
                  {{ state.value }}
                </ion-select-option>
              </ion-select>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size-xs="12" size-lg="12">
          <app-item-selector
            *ngIf="listStyleView"
            #itemSelector
            [isEstimate]="true"
            [paramDocumentId]="paramDocumentId"
            [party]="form?.value?.party"
            [iSetItemPriceHistoryStatus]="iSetItemPriceHistoryStatus"
            [barcodeScannerMode]="barcodeScannerMode"
            (itemAdded)="populateBillItems($event)"
            (focusAmountReceived)="focusAmountReceived($event)"
          >
          </app-item-selector>
          <app-item-restaurant-selector
            *ngIf="!listStyleView"
            #itemRestaurantSelector
            [isEstimate]="true"
            [saleUUID]="fetchedEstimate?._localUUID"
            [partySecondary]="this.form?.value?.partySecondary"
            [amountReceivedAmount]="form?.value?.amountReceived"
            [sortByItemCodeAsc]="selectedProfile?.iSetItemsSortByCodeStatus"
            [showBookmarkList]="selectedProfile?.iSetShowItemCategoryListStatus"
            [lockSellPrice]="selectedProfile?.iSetLockSellPriceStatus"
            [negativeStockLock]="selectedProfile?.iSetLockNegativeStockStatus"
            [iSetItemSelectorColumns]="selectedProfile?.iSetItemSelectorColumns || 4"
            [barcodeScannerMode]="barcodeScannerMode"
            [paramDocumentId]="paramDocumentId"
            [paymentMode]="paymentMode"
            [paymentId]="paymentIdEle?.value"
            [isPrintedKOT]="form?.value?.isPrintedKOT"
            [totalAmount]="form?.value?.totalAmount"
            [party]="form?.value?.party"
            [iSetItemPriceHistoryStatus]="iSetItemPriceHistoryStatus"
            [iSetOutOfStockHideStatus]="iSetOutOfStockHideStatus"
            [iSetQuickSaleStatus]="selectedProfile?.iSetQuickSaleStatus"
            [iSetShowStockStatus]="selectedProfile?.iSetShowStockStatus"
            (itemAdded)="populateBillItems($event)"
            (cartCleared)="onCartClear($event)"
            (savePrintTrigger)="savePrint()"
            (onlySaveTrigger)="onlySave()"
            (secondaryPartyAddTrigger)="onSeconadryPartySelect($event)"
            (barcodeChangeTrigger)="populateBarcodeScannerSetting()"
          >
          </app-item-restaurant-selector>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size-xs="12" size-lg="12">
          <!-- <app-transport-details
            (savedData)="populateTransportDetails($event)"
            [populateData]="form?.value?.transportDetail"
          >
          </app-transport-details> -->

          <ion-row>
            <ion-col>
              <ion-button fill="outline" class="dashed-border" (click)="toggleShowAllFields()">
                {{ showTransportDetailsCard ? 'Less Transport Details' : 'More Transport Details' }}
                <ion-icon *ngIf="!showTransportDetailsCard" name="chevron-down-outline"></ion-icon>
                <ion-icon *ngIf="showTransportDetailsCard" name="chevron-up-outline"></ion-icon>
              </ion-button>
            </ion-col>
          </ion-row>

          <ion-row class="transport-details-card" formGroupName="transportDetail" *ngIf="showTransportDetailsCard">
            <ion-col size-lg="6">
              <ion-item lines="none">
                <ion-label>Labour/Transport Charges</ion-label>
                  <ion-input
                    inputmode="numeric"
                    type="number"
                    formControlName="additionalAmount"
                  ></ion-input>
              </ion-item>
              <ion-item lines="none">
                <ion-label>Purchase Order Number</ion-label>
                <ion-input
                  formControlName="purOrderNo"
                ></ion-input>
              </ion-item>
              <ion-item lines="none">
                <ion-label>Challan Number</ion-label>
                <ion-input
                  formControlName="challanNo"
                ></ion-input>
              </ion-item>
              <ion-item lines="none">
                <ion-label>E Way Bill Number</ion-label>
                <ion-input
                  formControlName="eWayBillNo"
                ></ion-input>
              </ion-item>
              <ion-item lines="none">
                <ion-label>E Way Bill Date</ion-label>

                <!-- E way bill date input start -->
                <input 
                  type="button"
                  class="transport-date-input"
                  [value]="setDateValue(form?.value?.transportDetail?.eWayBillDate || todayDate)"
                  (click)="isOpenEwayBillDatePicker = true;">
                <!-- E way bill date input end -->

              </ion-item>
            </ion-col>

            <ion-col size-lg="6">
              <ion-item lines="none">
                <ion-label>Transporter Name</ion-label>
                <ion-input
                  formControlName="transporterName"
                ></ion-input>
              </ion-item>
              <ion-item lines="none">
                <ion-label>Vehicle Number</ion-label>
                <ion-input
                  formControlName="vehicleNumber"
                ></ion-input>
              </ion-item>
              <ion-item lines="none">
                <ion-label>Transport Distance</ion-label>
                <ion-input
                  formControlName="transDistance"
                ></ion-input>
                <ion-label
                  class="err-msg-label"
                  [ngClass]="{'active':this?.form?.controls?.transDistance?.errors}"
                >
                  Max allowed length is 5
                </ion-label>
              </ion-item>
              <ion-item lines="none">
                <ion-label>Delivery Location</ion-label>
                <ion-input
                  formControlName="deliveryLocation"
                ></ion-input>
              </ion-item>
              <ion-item lines="none">
                <ion-label>Delivery Date</ion-label>

                <!-- delivery date input start -->
                <input 
                  type="button"
                  class="transport-date-input"
                  [value]="setDateValue(form?.value?.transportDetail?.deliveryDate || todayDate)"
                  (click)="isOpenDeliveryDatePicker = true;">
                <!-- delivery date input end -->

              </ion-item>
            </ion-col>

            <ion-col size-lg="12">
              <ion-item lines="none">
                <ion-label>Terms & Conditions</ion-label>
              </ion-item>
              <ion-item lines="none">
                <ion-textarea
                  rows="5"
                  placeholder="Write Your Terms & Conditions Here"
                  formControlName="tnc"
                ></ion-textarea>
              </ion-item>
            </ion-col>
          </ion-row>

        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="6" size-xl="4">
          <ion-item lines="none">
            <ion-label>Cash Discount ( In % )</ion-label>
            <ion-input
              type="number"
              formControlName="cashDiscountPercentage"
              (ionBlur)="calculateCashDiscount(CalculateBillTask.DISCOUNT_FROM_PERCENTAGE)"
              (keyup.enter)="calculateCashDiscount(CalculateBillTask.DISCOUNT_FROM_PERCENTAGE)"
            ></ion-input>
          </ion-item>
          <ion-item lines="none">
            <ion-label>Cash Discount ( In ₹ )</ion-label>
            <ion-input
              type="number"
              formControlName="cashDiscount"
              (ionBlur)="calculateCashDiscount(CalculateBillTask.DISCOUNT_FROM_AMOUNT)"
              (keyup.enter)="calculateCashDiscount(CalculateBillTask.DISCOUNT_FROM_AMOUNT)"
            ></ion-input>
          </ion-item>
        </ion-col>
        <ion-col size="6" size-xl="4">
          <ion-item lines="none">
            <ion-label>Service Charge Percentage</ion-label>
            <ion-input
              formControlName="serviceChargePercentage"
              type="number"
              (ionBlur)="calculateServiceCharge(CalculateBillTask.SERVICE_CHARGE_FROM_PERCENTAGE)"
              (keyup.enter)="calculateServiceCharge(CalculateBillTask.SERVICE_CHARGE_FROM_PERCENTAGE)"
            ></ion-input>
          </ion-item>
          <ion-item lines="none">
            <ion-label>Service Charge Amount</ion-label>
            <ion-input
              formControlName="serviceChargeAmount"
              type="number"
              (ionBlur)="calculateServiceCharge(CalculateBillTask.SERVICE_CHARGE_FROM_AMOUNT)"
              (keyup.enter)="calculateServiceCharge(CalculateBillTask.SERVICE_CHARGE_FROM_AMOUNT)"
            ></ion-input>
          </ion-item>
        </ion-col>
        <ion-col size="6" size-xl="4">
          <ion-item lines="none">
            <ion-label>Total Amount</ion-label>
            <ion-input
              readonly="true"
              formControlName="totalAmount"
              type="number"
            ></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size-xs="12" size-lg="12">
          <ion-item lines="none">
            <ion-label>Estimate Note</ion-label>
          </ion-item>
          <ion-item lines="none">
            <ion-textarea
              rows="2"
              placeholder="Write Your Note Here"
              formControlName="note"
            ></ion-textarea>
          </ion-item>
        </ion-col>
      </ion-row>

      <ion-row class="last-row">
        <ion-col size-xs="12" size-lg="12">
        </ion-col>
      </ion-row>

      <div class="div-bottom-save-btns">
        <ion-button
          *ngIf="selectedProfile?.iSetQuickSaleStatus || listStyleView"
          class="flt-rght btn-save"
          color="primary"
          [disabled]="form?.invalid || !form?.value?.billItems?.length"
          (click)="savePrint()"
        >
          SAVE & PRINT ({{ form?.value?.totalAmount | currency: 'INR' }})
        </ion-button>
        <ion-button
          *ngIf="selectedProfile?.iSetQuickSaleStatus || listStyleView"
          class="flt-rght btn-save"
          color="tertiary"
          [disabled]="form?.invalid || !form?.value?.billItems?.length"
          (click)="onlySave()"
        >
          ONLY SAVE ({{ form?.value?.totalAmount | currency: 'INR' }})
        </ion-button>
        <ion-button 
          *ngIf="!selectedProfile?.iSetQuickSaleStatus && !listStyleView" 
          class="flt-rght btn-save" 
          color="tertiary"
          [disabled]="form?.invalid || !form?.value?.billItems?.length" 
          (click)="openSeconadryPartyModal()">
          Next ({{ form?.value?.totalAmount | currency: 'INR' }})
        </ion-button>
      </div>

      <!-- <ion-row>
        <ion-col size-xs="12" size-lg="12">
          <ion-button class="btn-save-sale" expand="full" [disabled]="form?.invalid || !form?.value?.billItems?.length" (click)="onSaveClick()">Save Sale</ion-button>
        </ion-col>
      </ion-row> -->

    </form>

    <ion-row>
      <ion-col size="12">
        <div class="kot-canvas-area" style="display:none;">
          <p class="kot-date"></p>
          <h2 class="kot-billNo"></h2>
          <h2 class="kot-party"></h2>
          <br/>
          <span class="kot-itemList"> </span>
        </div>
      </ion-col>
    </ion-row>

  </ion-grid>


</ion-content>

<app-settings
  #settingsModal
  (onSave)="onSettingSave()"
>
</app-settings>

<audio #kotPrintAudioEle>
  <source src="../../../../../assets/sounds/kot-print.mpeg" type="audio/mpeg">
</audio>
