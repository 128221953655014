import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PurchaseReturn } from '../../../models/PurchaseReturn.model';
import { PinVerificationComponent } from '../../pin-verification/pin-verification.component';
import { AllDataService } from '../../../services/all-data.service';
import { AlertController, InfiniteScrollCustomEvent } from '@ionic/angular';
import { SentryUtilites } from '../../../utils/sentryUtilites';
import { Router } from '@angular/router';

@Component({
  selector: 'app-purchase-return-bulk-delete',
  templateUrl: './purchase-return-bulk-delete.component.html',
  styleUrls: ['./purchase-return-bulk-delete.component.scss'],
})
export class PurchaseReturnBulkDeleteComponent implements OnInit {
  
  @ViewChild('pinVerificationElement') pinVerificationElement: PinVerificationComponent;

  @Input() navigateTo: string = '';
  
  @Output() onPurchaseReturnDelete = new EventEmitter<PurchaseReturn>();
  @Output() onBulkPurchaseReturnDelete = new EventEmitter<boolean>();
  @Input() fromStamp:number = null;
  @Input() toStamp: number = null;

  // Purchase Return
  isPurchaseReturnModalOpen = false;
  purchaseReturn: PurchaseReturn = null;
  isDeletePurchaseReturnAttachedMoneyIns = true;
  purchaseReturnDeleteStarted = false;
  purchaseReturnDeleteCompleted = false;
  //----------------------------------------------------

  // Bulk Purchase Return
  isBulkPurchaseReturnModalOpen = false;
  checkAllPurchaseReturnCheckboxes: boolean = false;
  bulkPurchaseReturns: PurchaseReturn[] = [];
  selectedBulkPurchaseReturns: PurchaseReturn[] = [];
  bulkPurchaseReturnDeleteStarted = false;
  bulkPurchaseReturnDeleteCompleted = false;
  //----------------------------------------------------

  // Security Pin common functionality var
  selectedVar: string = '';
  bulkSelectArray: string[] = [];
  viewFilteredList:PurchaseReturn[] = [];
  // ---------------------------------

  constructor(
    private allDataService: AllDataService,
    private alertController: AlertController,
    private router: Router,
  ) { }

  ngOnInit() {}

  openTransactionPINModal() {
    this.pinVerificationElement?.openTransactionPINModal();
  }

  async verifyTransactionPIN(event) {
    try {
      if(event){
        if(this.selectedVar == 'PurchaseReturn'){
         this.openPurchaseModal(true);
        }else if(this.selectedVar == 'BulkPurchaseReturn'){
          this.openBulkPurchaseReturnModal(true);
          await this.onDateRangeChange();
          //edit bulk PurchaseReturn
          this.bulkPurchaseReturns?.forEach((el)=> {
            el["checked"] = false
          })
        }
      }else{
        this.openBulkPurchaseReturnModal(false);
        this.openPurchaseModal(false);
      }
    } catch (error) {
      SentryUtilites.setLog("PurchaseReturnBulkDeleteComponent:verifyTransactionPIN", error)
    }
  }

  setCheckboxVarValue(variable: string, event: any) {
    this[variable] = event?.detail?.checked;
  }

  async deleteConfirm(): Promise<boolean> {
    return new Promise(async (resolve,reject) => {
      try {
        const alert = await this.alertController.create({
          mode: 'ios',
          header: 'Please Confirm!',
          message: 'Are you sure want to delete?',
          buttons: [
            {
              text: 'No',
              role: 'cancel',
              handler: () => {
                resolve(false);
                return true;
              },
            },
            {
              text: 'Yes',
              role: 'confirm',
              handler: () => {
                resolve(true);
                return true;
              },
            },
          ],
        });
        await alert.present();
      } catch (error) {
        SentryUtilites.setLog("PurchaseReturnBulkDeleteComponent:deleteConfirm", error)
        return resolve(false);
      }
    });
  }

  async deleteMoneyInConfirm(): Promise<boolean> {
    return new Promise(async (resolve,reject) => {
      try {
        const alert = await this.alertController.create({
          mode: 'ios',
          header: `Also Delete Attached Money In's`,
          message: 'Are you sure want to delete?',
          buttons: [
            {
              text: 'No',
              role: 'cancel',
              handler: () => {
                resolve(false);
                return true;
              },
            },
            {
              text: 'Yes',
              role: 'confirm',
              handler: () => {
                resolve(true);
                return true;
              },
            },
          ],
        });
        await alert.present();
      } catch (error) {
        SentryUtilites.setLog("PurchaseReturnBulkDeleteComponent:deleteMoneyInConfirm", error)
        return resolve(false);
      }
    });
  }

  async onDateRangeChange(){
    try {
      this.viewFilteredList = await this.allDataService?.purchaseReturnService?.getByBillDateRange(this.fromStamp, this.toStamp) || [];
      this.viewFilteredList = this.viewFilteredList?.filter(x => !x?.deletedStamp);
      this.bulkPurchaseReturns = this.viewFilteredList.slice(0,50);
    } catch (error) {
      SentryUtilites.setLog("PurchaseReturnBulkDeleteComponent:onDateRangeChange", error)
    }
  }

 // PurchaseReturn
  async openPurchaseModal(value: boolean = true) {
    try {
      if(value) {
        if ((await this.deleteConfirm()) === false) return;
      }
      this.isPurchaseReturnModalOpen = value;
    } catch (error) {
      SentryUtilites.setLog("PurchaseReturnBulkDeleteComponent:openPurchaseModal", error)
    }
  }

  async initDeletePurchaseReturn(purchaseReturn: PurchaseReturn) {
    try {
      this.purchaseReturn = purchaseReturn;
      this.selectedVar = 'PurchaseReturn';
      this.openTransactionPINModal();
    } catch (error) {
      SentryUtilites.setLog("PurchaseReturnBulkDeleteComponent:initDeletePurchaseReturn", error)
    }
  }

  async deletePurchaseReturn() {
    try {
      this.purchaseReturnDeleteStarted = true;
      let deletedPurchase = await this.allDataService.purchaseReturnService.delete(this.purchaseReturn);
      if (deletedPurchase?.deletedStamp) {
        this.purchaseReturn = deletedPurchase;
        this.allDataService.partyService.reloadList();
        this.allDataService.itemService.reloadList();
        this.allDataService.moneyInService.reloadList();
        this.allDataService.purchaseService.reloadList();
        this.allDataService.listForceReloadSubs.next('purchase-return-list');
        this.purchaseReturnDeleteCompleted = true;
        setTimeout(() => {
          this.openPurchaseModal(false);
          this.purchaseReturn = null;
          this.isDeletePurchaseReturnAttachedMoneyIns = false;
          this.purchaseReturnDeleteStarted = false;
          this.purchaseReturnDeleteCompleted = false;
          if(this.navigateTo) {
            this.router.navigate([this.navigateTo]);
          }
          setTimeout(() => {
            this.onPurchaseReturnDelete.emit(deletedPurchase);
          }, 1000);
        }, 500);
      }
    } catch (error) {
      SentryUtilites.setLog("PurchaseReturnBulkDeleteComponent:deletePurchaseReturn", error)
    }
  }

  // Bulk PurchaseReturn

   async openBulkPurchaseReturnModal(value: boolean = true) {
    try {
      if(value) {
        if ((await this.deleteConfirm()) === false) return;
      }
      this.isBulkPurchaseReturnModalOpen = value
      this.selectedBulkPurchaseReturns = [];
    } catch (error) {
      SentryUtilites.setLog("PurchaseReturnBulkDeleteComponent:openBulkPurchaseReturnModal", error)
    }
  }

  async initDeleteBulkPurchaseReturn() {
    try {
      this.selectedVar = 'BulkPurchaseReturn';
      if(!this.bulkSelectArray.includes('BulkPurchaseReturn')) {
        this.bulkSelectArray.push('BulkPurchaseReturn');
      }
      this.openTransactionPINModal();
    } catch (error) {
      SentryUtilites.setLog("PurchaseReturnBulkDeleteComponent:initDeleteBulkPurchaseReturn", error)
    }
  }

   /**
   * 
   * @param purchaseReturn : Provide PurchaseReturn object
   * @param event : ionic CustomEventInit
   * @description : this method add checked PurchaseReturn in selectedBulkPurchaseReturns array 
   *                if unchecked it will that PurchaseReturn from selectedBulkPurchaseReturns array
   */
   addToSelectedBulkPurchaseReturnArr(purchaseReturn: PurchaseReturn,event: CustomEventInit) {
    try {
      const index = this.selectedBulkPurchaseReturns?.findIndex(x => x?._localUUID === purchaseReturn?._localUUID);
      this.bulkPurchaseReturns?.forEach(x => {
        if(x?._localUUID === purchaseReturn?._localUUID){
          x["checked"] = event?.detail?.checked;
        }
      });
      if(event?.detail?.checked) {
        if(index == -1){
          this.selectedBulkPurchaseReturns.push(purchaseReturn);    
        }
      }else {
        if(index != -1) {
          this.selectedBulkPurchaseReturns.splice(index,1);
        }
      }
    } catch (error) {
      SentryUtilites.setLog("PurchaseReturnBulkDeleteComponent:addToSelectedBulkPurchaseReturnArr", error)
    }
  }

  async deleteBulkPurchaseReturn() {
    try {
      if(await this.deleteMoneyInConfirm()){
        this.bulkPurchaseReturnDeleteStarted = true;
        if(this.selectedBulkPurchaseReturns?.length) {
          for (let i = 0; i < this.selectedBulkPurchaseReturns.length; i++) {
            let purchaseReturn = this.selectedBulkPurchaseReturns[i];
            await this.allDataService.purchaseReturnService.delete(purchaseReturn);
          }
        }
  
        this.allDataService.moneyInService.reloadList();
        this.allDataService.partyService.reloadList();
        this.allDataService.itemService.reloadList();
        this.allDataService.purchaseService.reloadList();
        this.allDataService.listForceReloadSubs.next('purchase-return-list');
  
        this.bulkPurchaseReturnDeleteCompleted = true;
  
        setTimeout(() => {
          this.openBulkPurchaseReturnModal(false);
          this.bulkPurchaseReturns = [];
          this.selectedBulkPurchaseReturns = [];
          this.bulkPurchaseReturnDeleteStarted = false;
          this.bulkPurchaseReturnDeleteCompleted = false;
          setTimeout(() => {
            this.onBulkPurchaseReturnDelete.emit(true);
          }, 1000);
        }, 500);
      }
    } catch (error) {
      SentryUtilites.setLog("PurchaseReturnBulkDeleteComponent:deleteBulkPurchaseReturn", error)
    }
  }

  /**
   * 
   * @param event : ionic CustomEventInit
   * @description : this method push all Purchases in selectedBulkPurchaseReturns if select all checkbox is checked
   */
  selectAllPurchaseReturnCheckboxes(event: CustomEventInit){
    try {
      if(this.checkAllPurchaseReturnCheckboxes) {
        if(event?.detail?.checked) {
          this.viewFilteredList?.forEach((el)=> {el["checked"] = true});
          this.selectedBulkPurchaseReturns = [...this.viewFilteredList];
        } else {
          this.bulkPurchaseReturns?.forEach((el)=> {el["checked"] = false});
          this.viewFilteredList?.forEach((el)=> {el["checked"] = false});
          this.selectedBulkPurchaseReturns = [];
        }
      }
      this.checkAllPurchaseReturnCheckboxes = false;
    } catch (error) {
      SentryUtilites.setLog("PurchaseReturnBulkDeleteComponent:selectAllPurchaseReturnCheckboxes", error)
    }
  }

   /**
   * @description: this method check user is click on checkbox or not.
   */
   selectAllPurchaseReturnCheckboxClick() {
    this.checkAllPurchaseReturnCheckboxes = true;
  }

  loadMoreListData(event) {
    try {
      if (this.bulkPurchaseReturns.length > 0 && this.bulkPurchaseReturns.length <= this.viewFilteredList.length) {
        let appendListLength = this.viewFilteredList.length - this.bulkPurchaseReturns.length;
        let lastEl = this.bulkPurchaseReturns[this.bulkPurchaseReturns.length - 1];
        let counter = 0;
        for (let i = 0; i < this.viewFilteredList.length; i++) {
          if (this.viewFilteredList[i]._localUUID == lastEl._localUUID) {
            counter = 1;
            continue;
          }
          if (counter > 0 && appendListLength >= 52) {
            if (counter <= 52) {
              this.bulkPurchaseReturns.push(this.viewFilteredList[i])
            } else {
              break;
            }
            counter++;
          } else if(counter > 0 && appendListLength < 52) {
            if (counter <= appendListLength) {
              this.bulkPurchaseReturns.push(this.viewFilteredList[i])
            } else {
              break;
            }
            counter++;
          }
        }
        (event as InfiniteScrollCustomEvent).target.complete();
      }
    } catch (error) {
      SentryUtilites.setLog("PurchaseReturnBulkDeleteComponent:loadMoreListData", error)
    }
  }

}
