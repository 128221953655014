import { NgxIndexedDBService } from "ngx-indexed-db";
import { IDataRepo } from "../../../interface/IDataRepo.interface";
import { Estimate, IEstimate } from "../../models/Estimate.model";
import { Router } from "@angular/router";
import { Utility } from "../../utils/utility";
import { SentryUtilites } from "src/app/utils/sentryUtilites";

export class EstimateDao implements IDataRepo<Estimate>{
    private static _instance: EstimateDao;

    public static getInstance(
        ngxIndexedDBService: NgxIndexedDBService,
        router: Router,
    ) {
        if (!this._instance) {
            this._instance = new EstimateDao(ngxIndexedDBService, router)
        }
        return this._instance;
    }

    constructor(
        ngxIndexedDBService: NgxIndexedDBService,
        router: Router,
    ) {
        this.ngxIndexedDBService = ngxIndexedDBService;
        this.router = router;
    }

    ngxIndexedDBService: NgxIndexedDBService;
    router: Router;

    save(data: Estimate): Promise<Estimate> {
        return new Promise((resolve, reject) => {
            try {
                if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && Utility.isTruthy(data)) {
                    let timeStamp = +new Date();
                    data.createdStamp = timeStamp
                    data.updatedStamp = timeStamp
                    data.userUpdatedStamp = timeStamp
                    data.deletedStamp = 0
                    data.syncStamp = 0
    
                    this.ngxIndexedDBService
                        .add(IEstimate.SCHEMA_NAME, data)
                        .subscribe((savedEstimate: Estimate) => {
                            return resolve(savedEstimate);
                        },
                            err => {
                                console.error(err);
                                if (typeof err == 'string' && err?.includes('objectStore does not exists')) {
                                    this.router.navigate(['idbx-error']);
                                } else if (typeof err?.target?.error == 'object') {
                                    SentryUtilites.setLog("EstimateDao:save", err?.target?.error)
                                } else {
                                    SentryUtilites.setLog("EstimateDao:save", err)
                                }
                            });
                } else {
                    return resolve(null);
                }
            } catch (err) {
                SentryUtilites.setLog("EstimateDao:save", err)
                return resolve(null);
            }
        });
    }


    saveDb(data: Estimate): Promise<Estimate> {
        return new Promise((resolve, reject) => {
            try {
                if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && Utility.isTruthy(data)) {
                    this.ngxIndexedDBService
                        .add(IEstimate.SCHEMA_NAME, data)
                        .subscribe((savedRecord: Estimate) => {
                            return resolve(savedRecord);
                        },
                            err => {
                                console.error(err);
                                if (typeof err == 'string' && err?.includes('objectStore does not exists')) {
                                    this.router.navigate(['idbx-error']);
                                } else if (typeof err?.target?.error == 'object') {
                                    SentryUtilites.setLog("EstimateDao:saveDb", err?.target?.error)
                                } else {
                                    SentryUtilites.setLog("EstimateDao:saveDb", err)
                                }
                            });
                } else {
                    return resolve(null);
                }
            } catch (error) {
                SentryUtilites.setLog("EstimateDao:saveDb", error)
                return resolve(null);
            }
        })
    }

    update(data: Estimate): Promise<Estimate> {
        return new Promise(async (resolve, reject) => {
            try {
                if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?._localUUID) {
                    let oldData = await this.getByUUID(data?._localUUID);
                    if(oldData?._localUUID) {
                        data._localId = oldData?._localId;
                        let timeStamp = +new Date();
                        data.updatedStamp = timeStamp
                        data.userUpdatedStamp = timeStamp
                        this.ngxIndexedDBService
                            .update(IEstimate.SCHEMA_NAME, data)
                            .subscribe((updatedEstimate: Estimate) => {
                                return resolve(updatedEstimate);
                            },
                                err => {
                                    console.error(err);
                                    if (typeof err == 'string' && err?.includes('objectStore does not exists')) {
                                        this.router.navigate(['idbx-error']);
                                    } else if (typeof err?.target?.error == 'object') {
                                        SentryUtilites.setLog("EstimateDao:update", err?.target?.error)
                                    } else {
                                        SentryUtilites.setLog("EstimateDao:update", err)
                                    }
                            });
                    } else {
                        return resolve(null);
                    }
                } else {
                    return resolve(null);
                }
            } catch (err) {
                SentryUtilites.setLog("EstimateDao:update", err)
                return resolve(null);
            }
        })
    }

    updateConvertedStamp(data: Estimate, convertedStamp: number): Promise<Estimate> {
        return new Promise(async (resolve, reject) => {
            try {
                if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?._localUUID) {
                    let oldData = await this.getByUUID(data?._localUUID);
                    if(oldData?._localUUID) {
                        data._localId = oldData?._localId;
                        let timeStamp = +new Date();
                        data.billConvertedStamp = convertedStamp || timeStamp
                        data.updatedStamp = timeStamp;
                        data.userUpdatedStamp = timeStamp;
                        this.ngxIndexedDBService
                            .update(IEstimate.SCHEMA_NAME, data)
                            .subscribe((updatedEstimate: Estimate) => {
                                return resolve(updatedEstimate);
                            },
                                err => {
                                    console.error(err);
                                    if (typeof err == 'string' && err?.includes('objectStore does not exists')) {
                                        this.router.navigate(['idbx-error']);
                                    } else if (typeof err?.target?.error == 'object') {
                                        SentryUtilites.setLog("EstimateDao:updateConvertedStamp", err?.target?.error)
                                    } else {
                                        SentryUtilites.setLog("EstimateDao:updateConvertedStamp", err)
                                    }
                            });
                    } else {
                        return resolve(null);
                    }
                } else {
                    return resolve(null);
                }
            } catch (err) {
                SentryUtilites.setLog("EstimateDao:updateConvertedStamp", err)
                return resolve(null);
            }
        })
    }

    updateDb(data: Estimate): Promise<Estimate> {
        return new Promise(async (resolve, reject) => {
            try {
                if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?._localUUID) {
                    let oldData = await this.getByUUID(data?._localUUID);
                    if(oldData?._localUUID) {
                        data._localId = oldData?._localId;
                        this.ngxIndexedDBService
                            .update(IEstimate.SCHEMA_NAME, data)
                            .subscribe((updatedRecord: Estimate) => {
                                return resolve(updatedRecord);
                            },
                                err => {
                                    console.error(err);
                                    if (typeof err == 'string' && err?.includes('objectStore does not exists')) {
                                        this.router.navigate(['idbx-error']);
                                    } else if (typeof err?.target?.error == 'object') {
                                        SentryUtilites.setLog("EstimateDao:updateDb", err?.target?.error)
                                    } else {
                                        SentryUtilites.setLog("EstimateDao:updateDb", err)
                                    }
                            });
                    } else {
                        return resolve(null);
                    }
                } else {
                    return resolve(null);
                }
            } catch (err) {
                SentryUtilites.setLog("EstimateDao:updateDb", err)
                return resolve(null);
            }
        })
    }

    bulkPut(data: Estimate[]): Promise<boolean> {
        return new Promise((resolve, reject) => {
          try {
            if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?.length) {
                this.ngxIndexedDBService
                    .bulkPut(IEstimate.SCHEMA_NAME, data)
                    .subscribe((data) => {
                        resolve(true);
                    },
                    err => {
                        console.error(err);
                        if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                            this.router.navigate(['idbx-error']);
                        } else if (typeof err?.target?.error == 'object') {
                            SentryUtilites.setLog("EstimateDao:bulkPut", err?.target?.error)
                        } else {
                            SentryUtilites.setLog("EstimateDao:bulkPut", err)
                        }
                    })
            } else {
                return resolve(false);
            }
          } catch (error) {
            SentryUtilites.setLog("EstimateDao:bulkPut", error)
            return resolve(false);
          }
        })
      }

    delete(data: Estimate): Promise<Estimate> {
        return new Promise(async (resolve, reject) => {
            try {
                if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?._localUUID) {
                    let oldData = await this.getByUUID(data?._localUUID);
                    if(oldData?._localUUID) {
                        data._localId = oldData?._localId;
                        let timeStamp = +new Date();
                        data.updatedStamp = timeStamp;
                        data.userUpdatedStamp = timeStamp;
                        data.deletedStamp = timeStamp;
                        this.ngxIndexedDBService
                            .update(IEstimate.SCHEMA_NAME, data)
                            .subscribe((updatedEstimate: Estimate) => {
                                return resolve(updatedEstimate);
                            },
                                err => {
                                    console.error(err);
                                    if (typeof err == 'string' && err?.includes('objectStore does not exists')) {
                                        this.router.navigate(['idbx-error']);
                                    } else if (typeof err?.target?.error == 'object') {
                                        SentryUtilites.setLog("EstimateDao:delete", err?.target?.error)
                                    } else {
                                        SentryUtilites.setLog("EstimateDao:delete", err)
                                    }
                                });
                    } else {
                        return resolve(null);
                    }
                } else {
                    return resolve(null);
                }
            } catch (err) {
                SentryUtilites.setLog("EstimateDao:delete", err)
                return resolve(null);
            }
        });
    }
    getById(id: number): Promise<Estimate> {
        return new Promise((resolve, reject) => {
            try {
                if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && id) {
                    this.ngxIndexedDBService
                        .getByKey(IEstimate.SCHEMA_NAME, id)
                        .subscribe((profile: Estimate) => {
                            return resolve(profile);
                        },
                            err => {
                                console.error(err);
                                if (typeof err == 'string' && err?.includes('objectStore does not exists')) {
                                    this.router.navigate(['idbx-error']);
                                } else if (typeof err?.target?.error == 'object') {
                                    SentryUtilites.setLog("EstimateDao:getById", err?.target?.error)
                                } else {
                                    SentryUtilites.setLog("EstimateDao:getById", err)
                                }
                            });
                } else {
                    return resolve(null);
                }
            } catch (err) {
                SentryUtilites.setLog("EstimateDao:getById", err)
                return resolve(null)
            }
        })
    }
    getByUUID(uuid: string): Promise<Estimate> {
        return new Promise((resolve, reject) => {
            try {
                if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && uuid) {
                    this.ngxIndexedDBService
                        .getByIndex(IEstimate.SCHEMA_NAME, '_localUUID', uuid)
                        .subscribe((profile: Estimate) => {
                            return resolve(profile);
                        },
                            err => {
                                console.error(err);
                                if (typeof err == 'string' && err?.includes('objectStore does not exists')) {
                                    this.router.navigate(['idbx-error']);
                                } else if (typeof err?.target?.error == 'object') {
                                    SentryUtilites.setLog("EstimateDao:getByUUID", err?.target?.error)
                                } else {
                                    SentryUtilites.setLog("EstimateDao:getByUUID", err)
                                }
                            });
                } else {
                    return resolve(null);
                }
            } catch (err) {
                SentryUtilites.setLog("EstimateDao:getByUUID", err)
                return resolve(null)
            }
        })
    }
    getAll(): Promise<Estimate[]> {
        return new Promise((resolve, reject) => {
            try {
                if(Boolean(Utility.getFromLocalStorage('_ezo_login_token'))) {
                    this.ngxIndexedDBService.getAll(IEstimate.SCHEMA_NAME).subscribe((docs: Estimate[]) => {
                        docs = docs?.filter(x => !x?.deletedStamp && x?.createdStamp);
                        docs?.sort((a, b) => b?.createdStamp - a?.createdStamp);
                        return resolve(docs)
                    },
                        err => {
                            console.error(err);
                            if (typeof err == 'string' && err?.includes('objectStore does not exists')) {
                                this.router.navigate(['idbx-error']);
                            } else if (typeof err?.target?.error == 'object') {
                                SentryUtilites.setLog("EstimateDao:getAll", err?.target?.error)
                            } else {
                                SentryUtilites.setLog("EstimateDao:getAll", err)
                            }
                        });
                } else {
                    return resolve([]);
                }
            } catch (err) {
                SentryUtilites.setLog("EstimateDao:getAll", err)
                return resolve([])
            }
        })
    }
    getAllByProfile(profileId: string): Promise<Estimate[]> {
        return new Promise((resolve, reject) => {
            try {
                if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && profileId) {
                    this.ngxIndexedDBService.getAll(IEstimate.SCHEMA_NAME).subscribe((docs: Estimate[]) => {
                        docs = docs?.filter(x => !x?.deletedStamp && x?.profileId == profileId && x?.createdStamp);
                        docs?.sort((a, b) => b?.createdStamp - a?.createdStamp);
                        return resolve(docs)
                    },
                        err => {
                            console.error(err);
                            if (typeof err == 'string' && err?.includes('objectStore does not exists')) {
                                this.router.navigate(['idbx-error']);
                            } else if (typeof err?.target?.error == 'object') {
                                SentryUtilites.setLog("EstimateDao:getAllByProfile", err?.target?.error)
                            } else {
                                SentryUtilites.setLog("EstimateDao:getAllByProfile", err)
                            }
                        });
                } else {
                    return resolve([]);
                }
            } catch (err) {
                SentryUtilites.setLog("EstimateDao:getAllByProfile", err)
                return resolve([])
            }
        })
    }

    getAllByProfileWithOnlyRunningBill(profileId: string): Promise<Estimate[]> {
        return new Promise((resolve, reject) => {
            try {
                if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && profileId) {
                    this.ngxIndexedDBService.getAll(IEstimate.SCHEMA_NAME).subscribe((docs: Estimate[]) => {
                        docs = docs?.filter(x => !x?.deletedStamp && x?.profileId == profileId);
                        docs?.sort((a, b) => b?.createdStamp - a?.createdStamp);
                        return resolve(docs)
                    },
                        err => {
                            console.error(err);
                            if (typeof err == 'string' && err?.includes('objectStore does not exists')) {
                                this.router.navigate(['idbx-error']);
                            } else if (typeof err?.target?.error == 'object') {
                                SentryUtilites.setLog("EstimateDao:getAllByProfileWithOnlyRunningBill", err?.target?.error)
                            } else {
                                SentryUtilites.setLog("EstimateDao:getAllByProfileWithOnlyRunningBill", err)
                            }
                        });
                } else {
                    return resolve([]);
                }
            } catch (err) {
                SentryUtilites.setLog("EstimateDao:getAllByProfileWithOnlyRunningBill", err)
                return resolve([])
            }
        })
    }

    getAllByProfileWithRunningBill(profileId: string): Promise<Estimate[]> {
        return new Promise((resolve, reject) => {
            try {
                if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && profileId) {
                    this.ngxIndexedDBService.getAll(IEstimate.SCHEMA_NAME).subscribe((docs: Estimate[]) => {
                        docs = docs?.filter(x => !x?.deletedStamp && x?.profileId == profileId);
                        docs?.sort((a, b) => b?.createdStamp - a?.createdStamp);
                        return resolve(docs)
                    },
                        err => {
                            console.error(err);
                            if (typeof err == 'string' && err?.includes('objectStore does not exists')) {
                                this.router.navigate(['idbx-error']);
                            } else if (typeof err?.target?.error == 'object') {
                                SentryUtilites.setLog("EstimateDao:getAllByProfileWithRunningBill", err?.target?.error)
                            } else {
                                SentryUtilites.setLog("EstimateDao:getAllByProfileWithRunningBill", err)
                            }
                        });
                } else {
                    return resolve([]);
                }
            } catch (err) {
                SentryUtilites.setLog("EstimateDao:getAllByProfileWithRunningBill", err)
                return resolve([])
            }
        })
    }

    getAllWithDeletedByProfile(profileId: string): Promise<Estimate[]> {
        return new Promise((resolve, reject) => {
            try {
                if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && profileId) {
                    this.ngxIndexedDBService.getAll(IEstimate.SCHEMA_NAME).subscribe((docs: Estimate[]) => {
                        docs = docs?.filter(x => x?.profileId == profileId && x?.createdStamp && !x?.billConvertedStamp);
                        docs?.sort((a, b) => b?.createdStamp - a?.createdStamp);
                        return resolve(docs)
                    },
                        err => {
                            console.error(err);
                            if (typeof err == 'string' && err?.includes('objectStore does not exists')) {
                                this.router.navigate(['idbx-error']);
                            } else if (typeof err?.target?.error == 'object') {
                                SentryUtilites.setLog("EstimateDao:getAllWithDeletedByProfile", err?.target?.error)
                            } else {
                                SentryUtilites.setLog("EstimateDao:getAllWithDeletedByProfile", err)
                            }
                        });
                } else {
                    return resolve([]);
                }
            } catch (err) {
                SentryUtilites.setLog("EstimateDao:getAllWithDeletedByProfile", err)
                return resolve([])
            }
        })
    }

   /**
   * 
   * @param profileId : provide profile ID
   * @returns : return deleted estimates
   */
  getAllDeletedByProfile(profileId: string): Promise<Estimate[]> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && profileId) {
            this.ngxIndexedDBService.getAll(IEstimate.SCHEMA_NAME).subscribe((docs: Estimate[]) => {
                docs = docs?.filter(x => x?.profileId == profileId && x?.createdStamp && !x?.billConvertedStamp && x?.deletedStamp);
                docs?.sort((a, b) => b?.createdStamp - a?.createdStamp);
                return resolve(docs)
            },
            err => {
                console.error(err);
                if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                  this.router.navigate(['idbx-error']);
                } else if (typeof err?.target?.error == 'object') {
                    SentryUtilites.setLog("EstimateDao:getAllDeletedByProfile", err?.target?.error)
                } else {
                    SentryUtilites.setLog("EstimateDao:getAllDeletedByProfile", err)
                }
            });
        } else {
            return resolve([]);
        }
        } catch (err) {
            SentryUtilites.setLog("EstimateDao:getAllDeletedByProfile", err)
            return resolve([])
        }
    })
  }
  // -----------------------------------------------------

    getAllUnsynced(profileId: string): Promise<Estimate[]> {
        return new Promise((resolve, reject) => {
            try {
                if(Boolean(Utility.getFromLocalStorage('_ezo_login_token'))) {
                    let unSyncedElements: Estimate[] = [];
                    this.ngxIndexedDBService.getAll(IEstimate.SCHEMA_NAME).subscribe(async (elArr: Estimate[]) => {
                        for (let i = 0; i < elArr?.length; i++) {
                            const el = elArr[i];
                            if (el?.updatedStamp > el?.syncStamp || !el?._serverIdRef) {
                                unSyncedElements.push(el);
                            }
                        }
                        return resolve(unSyncedElements)
                    },
                        err => {
                            console.error(err);
                            if (typeof err == 'string' && err?.includes('objectStore does not exists')) {
                                this.router.navigate(['idbx-error']);
                            } else if (typeof err?.target?.error == 'object') {
                                SentryUtilites.setLog("EstimateDao:getAllUnsynced", err?.target?.error)
                            } else {
                                SentryUtilites.setLog("EstimateDao:getAllUnsynced", err)
                            }
                        });
                } else {
                    return resolve([]);
                }
            } catch (err) {
                SentryUtilites.setLog("EstimateDao:getAllUnsynced", err)
                return resolve([])
            }
        })
    }
    updateSyncStamp(data: Estimate): Promise<Estimate> {
        return new Promise(async (resolve, reject) => {
            try {
                if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?._localUUID) {
                    this.ngxIndexedDBService
                    .getByIndex(IEstimate.SCHEMA_NAME, '_localUUID', data._localUUID)
                    .subscribe((dbEl: Estimate) => {
                        if(dbEl) {
                                dbEl.syncStamp = data?.syncStamp || 0;
                                dbEl._serverIdRef = data?._serverIdRef;
                                this.ngxIndexedDBService
                                    .update(IEstimate.SCHEMA_NAME, dbEl)
                                    .subscribe((updatedEl: Estimate) => {
                                        return resolve(updatedEl);
                                    },
                                    err => {
                                        console.error(err);
                                        if (typeof err == 'string' && err?.includes('objectStore does not exists')) {
                                            this.router.navigate(['idbx-error']);
                                        } else if (typeof err?.target?.error == 'object') {
                                            SentryUtilites.setLog("EstimateDao:updateSyncStamp", err?.target?.error)
                                        } else {
                                            SentryUtilites.setLog("EstimateDao:updateSyncStamp", err)
                                        }
                                    });
                            } else {
                              return resolve(null);``
                            }
                        },
                        err => {
                            console.error(err);
                            if (typeof err == 'string' && err?.includes('objectStore does not exists')) {
                                this.router.navigate(['idbx-error']);
                            } else if (typeof err?.target?.error == 'object') {
                                SentryUtilites.setLog("EstimateDao:updateSyncStamp", err?.target?.error)
                            } else {
                                SentryUtilites.setLog("EstimateDao:updateSyncStamp", err)
                            }
                        });
                } else {
                    return resolve(null);
                }
            } catch (err) {
                SentryUtilites.setLog("EstimateDao:updateSyncStamp", err)
                return resolve(null)
            }
        })
    }

}
