import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Ingredient } from '../../models/Ingredient.model';
import { ToastController } from '@ionic/angular';
import { IngredientStockAdjust } from '../../models/IngredientStockAdjust.model';
import { IngredientStockAdjustService } from '../../services/ingredient-stock-adjust.service';
import { AllDataService } from '../../services/all-data.service';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-ingredient-stock-adjust',
  templateUrl: './ingredient-stock-adjust.component.html',
  styleUrls: ['./ingredient-stock-adjust.component.scss'],
})
export class IngredientStockAdjustComponent implements OnInit {

  @Output() saveTrigger = new EventEmitter<IngredientStockAdjust>();

  ingredient: Ingredient = null;
  isModalOpen = false;
  isDisableSave = false;
  addReduceOperation: 'Add' | 'Reduce' = 'Add';
  quantity: number = 0;
  note: string = '';

  constructor(
    private toastController: ToastController,
    private ingredientStockAdjustService: IngredientStockAdjustService,
    private allDataService: AllDataService,
  ) { }

  ngOnInit() { }

  openStockAdjustModal(isOpen: boolean = true) {
    try {
      this.isModalOpen = isOpen;
      if(!isOpen) {
        this.resetModal();
      }
    } catch (error) {
      SentryUtilites.setLog("IngredientStockAdjustComponent:openStockAdjustModal", error)
    }
  }

  resetModal() {
    try {
      this.ingredient = null;
      this.addReduceOperation = 'Add';
      this.quantity = 0;
      this.note = '';
    } catch (error) {
      SentryUtilites.setLog("IngredientStockAdjustComponent:resetModal", error)
    }
  }

  setIngredient(ingredient) {
    this.ingredient = ingredient;
  }

  onAddReduceOperationSelect(event: any) {
    try {
      if (event?.detail?.value) {
        this.addReduceOperation = event.detail.value;
      }
    } catch (error) {
      SentryUtilites.setLog("IngredientStockAdjustComponent:onAddReduceOperationSelect", error)
    }
  }

  setQuantity(event: any) {
    try {
      let value = event?.detail?.target?.value;
      if (value && isNaN(+value) === false) {
        value = +value;
        if(value<0) {
          event.target.value = this.quantity = Math.abs(value);
        }else {
          this.quantity = +value;
        }
      } else {
        this.quantity = 0;
      }
    } catch (error) {
      SentryUtilites.setLog("IngredientStockAdjustComponent:setQuantity", error)
    }
  }

  setNote(event: any) {
    this.note = event?.detail?.target?.value || '';
  }

  async save() {
    try {
      this.isDisableSave = true;
      if (this.quantity > 0 && this.addReduceOperation && this.ingredient?._localUUID) {
        const ingredientStockAdjust = new IngredientStockAdjust();
        ingredientStockAdjust.quantity = this.addReduceOperation === 'Add' ? this.quantity : this.quantity * -1;
        ingredientStockAdjust.linkedIngredientUUID = this.ingredient?._localUUID;
        ingredientStockAdjust.note = this.note;
        ingredientStockAdjust.unit = this.ingredient?.unit;
        const savedIngredientStockAdjust = await this.ingredientStockAdjustService.save(ingredientStockAdjust);
        if (savedIngredientStockAdjust?._localUUID) {
          // this.allDataService.listForceReloadSubs.next('');
          this.presentToast(`Ingredient Stock ${this.addReduceOperation}ed Successful.`, 'success');
          this.saveTrigger.emit(savedIngredientStockAdjust);
        } else {
          this.presentToast(`Something Went Wrong.`, 'danger');
        }
      }
      this.isDisableSave = false;
    } catch (error) {
      SentryUtilites.setLog("IngredientStockAdjustComponent:save", error)
    }
  }

  async presentToast(header: string, color: 'success' | 'danger') {
    try {
      const toast = await this.toastController.create({
        header,
        duration: 2000,
        position: 'top',
        mode: 'ios',
        color: color,
      });
      await toast.present();
      this.openStockAdjustModal(false);
    } catch (error) {
      SentryUtilites.setLog("IngredientStockAdjustComponent:presentToast", error)
    }
  }

}
