import { User } from "../models/user.model";

export default class EzoAd{
    public static showAd(user:User){
        if(user){
            let currentStamp=+new Date();
            let registrationStamp=Number(user?.registrationStamp) || 0;
            let proStamp=Number(user?.isPro) || 0;
            let showAdBanner=false;
            
              if(proStamp<currentStamp){
                if(currentStamp-registrationStamp > (15*24*60*60*1000)){
                  showAdBanner=true;
                }
              }
      
            
            if(showAdBanner){
              return `<img src='https://assets.ezobanks.com/ezobooks/ezo_ad_invoice.png' width='100%' style="padding:0 25px;margin:10px 0;"/>`
            }
        }
        
        return '';
    }
}