<ion-modal [ngClass]="{'mobile': isMobile}" class="modal-settings" [isOpen]="isModalOpen" (willDismiss)="dismissLoggedinDevices()" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar color="light">
        <ion-title>Logged In Devices</ion-title>
        <ion-buttons slot="end">
          <ion-button fill="clear" (click)="getLoginTokens(true)"><ion-icon name="refresh-outline"></ion-icon></ion-button>
          <ion-button (click)="openModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">

      <ion-grid>

        <ion-row *ngIf="filteredList?.length" class="row-head">
          <ion-col size-lg="2.8">Login Devices</ion-col>
          <ion-col size-lg="2">Status</ion-col>
          <ion-col size-lg="2.5">Last Active</ion-col>
          <ion-col size-lg="2.5">Login By</ion-col>
          <ion-col size-lg="1.5">Action</ion-col>
        </ion-row>

        <ng-container *ngFor="let loginToken of viewFilteredList;">
          <ion-row class="row-body" [ngClass]="{'logout-row': loginToken?.logoutCompletedStamp}">
            <ion-col size-lg="2.8" class="device-name">
              <ion-icon name="phone-portrait-outline" *ngIf="loginToken?.device && checkPhoneLogin(loginToken?.device.toLowerCase()) && !isMobile" ></ion-icon>
              <ion-icon name="desktop-outline" *ngIf="loginToken?.device && !checkPhoneLogin(loginToken?.device.toLowerCase()) && !isMobile"></ion-icon>
              <span>
                {{ loginToken?.device ? loginToken?.device : 'Not Available' }}
              </span>
            </ion-col>
            <ion-col size-lg="2" class="active" *ngIf="!loginToken?.logoutInitiatedStamp && !loginToken?.logoutCompletedStamp">
              Active
            </ion-col>
            <ion-col size-lg="2" class="pending" *ngIf="loginToken?.logoutInitiatedStamp && !loginToken?.logoutCompletedStamp">
              Idle
            </ion-col>
            <ion-col size-lg="2" class="logout" *ngIf="loginToken?.logoutCompletedStamp">
              Logged Out
            </ion-col>
            <ion-col size-lg="2.5">{{ loginToken?.lastActiveStamp ? (loginToken?.lastActiveStamp | date: 'dd/MM/yy hh:mm:ss a') : 'Not Available' }}</ion-col>
            <ng-container *ngIf="loginToken?.userId && loginPhone">
              <ion-col *ngIf="loginToken?.userId != loginPhone" size-lg="2.5">
                <span class="staff">
                  Staff - {{getStaff(loginToken?.userId)}}
                </span>
              </ion-col>
              <ion-col *ngIf="loginToken?.userId == loginPhone" size-lg="2.5">
                <span class="owner">
                  Owner
                </span>
              </ion-col>
            </ng-container>
            <ion-col size-lg="1.5">
              <ion-button *ngIf="!loginToken?.logoutCompletedStamp" fill="outline" class="outline-border font-12px ion-button-width" (click)="presentLogoutConfirm(loginToken)">
                Log Out
              </ion-button>
            </ion-col>
          </ion-row>
        </ng-container>

      </ion-grid>

      <ion-infinite-scroll threshold="10px" *ngIf="viewFilteredList?.length" (ionInfinite)="loadMoreListData($event)">
        <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Loading more data...">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
      
    </ion-content>
  </ng-template>
</ion-modal>
