
import { AllDataService } from './../../services/all-data.service';
import {Component,Input,OnInit, Output,EventEmitter} from '@angular/core';
import { Subscription } from 'rxjs';
import { AccessTo } from 'src/app/models/AccessTo.model';
import { Profile } from 'src/app/models/Profile.model';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-staff-list',
  templateUrl: './staff-list.component.html',
  styleUrls: ['./staff-list.component.scss'],
})
export class StaffListComponent implements OnInit {
  accessToList: AccessTo[] = [];
  selectedProfile: Profile = null;
  subsArr: Subscription[] = [];
  isMobile: boolean = null;


  @Input() isModal = false;
  @Output() selectedStaff = new EventEmitter<AccessTo>()

  constructor(
    private allDataService: AllDataService,
  ) { }

  ngOnInit() {
    try {
      this.subsArr.push(this.allDataService.listForceReloadSubs
        .subscribe((listName: string) => {
          if (listName == 'profile-list') {
            this.loadView();
          }
        }));
      this.loadView();
    } catch (error) {
      SentryUtilites.setLog("StaffListComponent:ngOnInit", error)
    }
  }
  
  async loadView() {
    try {
      this.selectedProfile = await this.allDataService.profileService.getCurrentProfile();
      this.accessToList = this.selectedProfile?.accessTo || [];
    } catch (error) {
      SentryUtilites.setLog("StaffListComponent:loadView", error)
    }
  }

  getSelectedStaff(staff:AccessTo){
    this.selectedStaff.emit(staff);
  }
}
