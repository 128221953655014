import { Component, OnInit, ViewChild } from '@angular/core';
import PartyCategory from '../../models/PartyCategory.model';
import { Subscription } from 'rxjs';
import { AllDataService } from '../../services/all-data.service';
import { AlertController, InfiniteScrollCustomEvent, IonInput, ItemReorderEventDetail, ToastController } from '@ionic/angular';
import { AccessControlService } from '../../services/auth/access-control.service';
import { PinVerificationComponent } from '../pin-verification/pin-verification.component';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

type CustomPartyCategory = {
  isEdit: boolean;
  category: PartyCategory
}
@Component({
  selector: 'app-party-category-list',
  templateUrl: './party-category-list.component.html',
  styleUrls: ['./party-category-list.component.scss'],
})
export class PartyCategoryListComponent implements OnInit {

  @ViewChild('editInputEle') editInputEle: IonInput;
  @ViewChild('pinVerificationElement') pinVerificationElement: PinVerificationComponent;

  partyCategories: CustomPartyCategory[] = [];
  viewPartyCategories: CustomPartyCategory[] = [];
  selectedEditPartyCategory: CustomPartyCategory = null;
  selectedOptionType: string = '';
  catSubs: Subscription;
  seletcedDeleteCategory: PartyCategory = null;
  categoryWiseItemCount = {};
  subsArr: Subscription[] = [];

  constructor(
    private allDataService: AllDataService,
    private alertController: AlertController,
    private toastController: ToastController,
    private accessControlService: AccessControlService,
  ) { }

  ngOnInit() {
    try {
      this.getCategories();
      this.subsArr?.push(this.allDataService.listForceReloadSubs.subscribe((listName: string) => {
        if (listName == 'party-category-list') {
          this.getCategories();
        }
      }))
    } catch (error) {
      SentryUtilites.setLog("PartyCategoryListComponent:ngOnInit", error)
    }
  }

  async getCategories() {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({action:'viewPartyCategory'});
      if(!isPermit) {
        return alert("Permission: You don't have permission to view category. Please contact to your owner.");
      }
      let partyCategories=await this.allDataService.partyCategoryService.getAllByPromise();
      partyCategories.sort((a: PartyCategory, b: PartyCategory) => {
        if(a?.savedPosition || b?.savedPosition) {
          return a?.savedPosition - b?.savedPosition;
        }
        if (a?.name == b?.name) {
          return a?.createdStamp - b?.createdStamp;
        }
        if (a?.name > b?.name) {
          return 1;
        }
        return -1;
      });

      let partyCategoryList = [];
      partyCategories?.forEach(category => partyCategoryList?.push({isEdit: false, category}));
      this.partyCategories = partyCategoryList;
      this.viewPartyCategories = partyCategoryList.slice(0, this.viewPartyCategories?.length > 50 ? this.viewPartyCategories?.length : 50);
  
      let completeList = await this.allDataService.partyService.getAllByPromise();
      if(completeList?.length) {
        this.categoryWiseItemCount = {};
        partyCategories?.forEach(category => {
          this.categoryWiseItemCount[category?.name] = completeList?.filter(party => {
            if(party?.category){
              return party.category.toLowerCase()==category.name.toLowerCase()
            }
            return false;
          })?.length;
        });
      }
    } catch (error) {
      SentryUtilites.setLog("PartyCategoryListComponent:getCategories", error)
    }
  }

  handleReorder(ev: CustomEvent<ItemReorderEventDetail>) {
    try {
      let minValue = Math.min(ev?.detail?.from, ev?.detail?.to);
      this.viewPartyCategories = ev.detail.complete(this.viewPartyCategories);
      this.viewPartyCategories?.forEach((partyCategory,index) => {
        if(index >= minValue) {
          partyCategory.category.savedPosition = index;
          this.allDataService.partyCategoryService.update(partyCategory?.category);
        }
      });
    } catch (error) {
      SentryUtilites.setLog("PartyCategoryListComponent:handleReorder", error)
    }
  }

  async presentAddCategoryAlert() {
    try {
      const alert = await this.alertController.create({
        header: 'Add New Category',
        buttons: [
          {
            text: 'Save',
            handler: async (value) => {
              return await this.addNewCategory(value);
            },
          }
        ],
        mode: 'ios',
        inputs: [
          {
            type: 'text',
            placeholder: 'Category Name',
            name: 'categoryName',
          }
        ],
      });
  
      await alert.present();
    } catch (error) {
      SentryUtilites.setLog("PartyCategoryListComponent:presentAddCategoryAlert", error)
    }
  }

  async addNewCategory(value: { categoryName: string }) {
    try {
      let categoryName = value?.categoryName.toLowerCase().trim()
      if(categoryName) {
        if(this.partyCategories.findIndex(partyCategory => partyCategory?.category?.name.toLowerCase() === categoryName) === -1) {
          let category = new PartyCategory();
          category.savedPosition = this.partyCategories?.length + 1;
          category.name = value.categoryName;
          let result = await this.allDataService.partyCategoryService.save(category);
          if(result._localUUID) {
            this.allDataService.listForceReloadSubs.next('party-category-list');
            this.catSubs?.unsubscribe();
            this.getCategories();
            return true;
          }else {
            return false;
          }
        } else {
          const toast = await this.toastController.create({
            header: "Party category already exits",
            duration: 2000,
            position: 'top',
            mode: 'ios',
            color: 'warning',
          });
          await toast.present();
        }
      }
    } catch (error) {
      SentryUtilites.setLog("PartyCategoryListComponent:addNewCategory", error)
      return false;
    }
  }

  async enableEdit(partyCategory: CustomPartyCategory) {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({action:'editPartyCategory'});
      if(!isPermit) {
        return alert("Permission: You don't have permission to edit category. Please contact to your owner.");
      }
      this.selectedOptionType = 'edit';
      this.selectedEditPartyCategory = partyCategory;
      this.openTransactionPINModal();
    } catch (error) {
      SentryUtilites.setLog("PartyCategoryListComponent:enableEdit", error)
    }
  }

  disableEdit(partyCategory: CustomPartyCategory) {
    partyCategory.isEdit = false;
  }

  async update(categoryUUID: string) {
    try {
      let updatedCategoryName: string = (this.editInputEle as any)?.el?.value;
      if(updatedCategoryName) {
        let fetchedCategory = await this.allDataService.partyCategoryService.getByUUID(categoryUUID);
        if(fetchedCategory?._localUUID) {
          let oldNameOfCategory = fetchedCategory?.name;
          fetchedCategory.name = updatedCategoryName;
          let updatedCategory = await this.allDataService.partyCategoryService.update(fetchedCategory);
          if(updatedCategory?._localUUID) {
            let allParties = await this.allDataService.partyService.getAllByPromise();
            let filteredParties = allParties?.filter(party => party?.category?.toLowerCase() === oldNameOfCategory?.toLowerCase());
            if(filteredParties?.length) {
              for(let i = 0; i < filteredParties?.length; i++) {
                let party = filteredParties[i];
                party.category = updatedCategory?.name;
                await this.allDataService.partyService.update(party);
              }
            }
            this.getCategories();
            const toast = await this.toastController.create({
              message: 'Category updated successfully',
              duration: 3000,
              color: 'success',
            });
            await toast.present();
            return true;
          }else {
            const toast = await this.toastController.create({
              message: 'Something went wrong',
              duration: 3000,
              color: 'danger',
            });
            await toast.present();
            return false;
          }
        }
      }else {
        const toast = await this.toastController.create({
          message: 'Category name cannot be blank',
          duration: 3000,
          color: 'dark',
        });
        await toast.present();
      }
    } catch (error) {
      SentryUtilites.setLog("PartyCategoryListComponent:update", error)
      return false;
    }
  }


  async deleteConfirm(): Promise<boolean> {
    return new Promise(async (resolve,reject) => {
      try {
        const alert = await this.alertController.create({
          header: 'Warning!',
          message: "Are you sure want to delete?",
          mode: 'ios',
          buttons: [
            {
              text: 'No',
              role: 'cancel',
              handler: () => resolve(false)
            },
            {
              text: 'Yes',
              role: 'confirm',
              handler: () => resolve(true)
            },
          ],
        });
        await alert.present();
      } catch (error) {
        SentryUtilites.setLog("PartyCategoryListComponent:deleteConfirm", error)
        return false;
      }
    });
  }

  async delete(category: PartyCategory) {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({action:'deletePartyCategory'});
      if(!isPermit) {
        return alert("Permission: You don't have permission to delete category. Please contact to your owner.");
      }
      this.selectedOptionType = 'delete';
      this.seletcedDeleteCategory = category;
      this.openTransactionPINModal();
    } catch (error) {
      SentryUtilites.setLog("PartyCategoryListComponent:delete", error)
    }
  }

  ngOnDestroy() {
    try {
      this.catSubs?.unsubscribe();
      this.subsArr?.forEach(sub => {
        sub.unsubscribe();
      });
    } catch (error) {
      SentryUtilites.setLog("PartyCategoryListComponent:ngOnDestroy", error)
    }
  }

  openTransactionPINModal() {
    this.pinVerificationElement?.openTransactionPINModal();
  }

  async verifyTransactionPIN(event) {
    try {
      if(event && this.selectedOptionType === 'edit') {
        this.partyCategories?.forEach(partyCategory => partyCategory.isEdit=false );
        this.selectedEditPartyCategory.isEdit = true;
        setTimeout(() => {
          this.editInputEle?.setFocus();
        }, 500);
      } else if (event && this.selectedOptionType === 'delete') {
        const confirm = await this.deleteConfirm();
        if(confirm) {
          let dbCategory = await this.allDataService.partyCategoryService.getByUUID(this.seletcedDeleteCategory?._localUUID);
          if(dbCategory?._localUUID) {
            let deletedCategory = await this.allDataService.partyCategoryService.delete(dbCategory);
            if(deletedCategory?._localUUID) {
              let allParties = await this.allDataService.partyService.getAllByPromise();
              let filteredParties = allParties?.filter(party => party?.category?.toLowerCase() === deletedCategory?.name?.toLowerCase());
              if(filteredParties?.length) {
                for(let i = 0; i < filteredParties?.length; i++) {
                  let party = filteredParties[i];
                  party.category = null;
                  await this.allDataService.partyService.update(party);
                }
              }
              this.getCategories();
            }
          }
        }
      }
    } catch (error) {
      SentryUtilites.setLog("PartyCategoryListComponent:verifyTransactionPIN", error)
    }
  }

  loadMoreListData(event: any) {
    if (
      this.viewPartyCategories.length > 0 &&
      this.viewPartyCategories.length <= this.partyCategories.length
    ) {
      let appendListLength = this.partyCategories.length - this.viewPartyCategories.length;
      let lastEl = this.viewPartyCategories[this.viewPartyCategories.length - 1];
      let counter = 0;
      for (let i = 0; i < this.partyCategories.length; i++) {
        if (this.partyCategories[i].category._localUUID == lastEl.category._localUUID) {
          counter = 1;
          continue;
        }
        if (counter > 0 && appendListLength >= 50) {
          if (counter <= 50) {
            this.viewPartyCategories.push({ ...this.partyCategories[i] });
          } else {
            break;
          }
          counter++;
        } else if(counter > 0 && appendListLength < 50) {
          if (counter <= appendListLength) {
            this.viewPartyCategories.push({ ...this.partyCategories[i] })
          } else {
            break;
          }
          counter++;
        }
      }
      (event as InfiniteScrollCustomEvent)?.target?.complete();
    }
  }

}
