import { CommonService } from 'src/app/services/common.service';
import { AlertController, InfiniteScrollCustomEvent } from '@ionic/angular';
import { AllDataService } from 'src/app/services/all-data.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MoneyIn } from '../../models/MoneyIn.model';
import { Sale } from '../../models/Sale.model';
import { Utils } from '../../utils/utils';
import { MoneyInFormComponent } from '../money-in-form/money-in-form.component';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';
import { PurchaseReturn } from '../../models/PurchaseReturn.model';
import { Utility } from '../../utils/utility';

@Component({
  selector: 'app-money-in-selector',
  templateUrl: './money-in-selector.component.html',
  styleUrls: ['./money-in-selector.component.scss'],
})
export class MoneyInSelectorComponent implements OnInit {

  @ViewChild('moenyInFormEle') moenyInFormEle: MoneyInFormComponent;

  @Input() sale: Sale = null;
  @Input() purchaseReturn: PurchaseReturn = null;
  @Input() billTotalAmount: number = null;
  @Input() moneyInCart: MoneyIn[] = [];
  @Input() isSaleList: boolean = false;
  @Input() isPurchaseReturnList: boolean = false;

  @Output() moneyInsSelectedEvent = new EventEmitter<MoneyIn[]>();
  @Output() onModelClose = new EventEmitter<boolean>();

  capFractionsToTwo = Utils.capFractionsToTwo;

  completePartyWiseMoneyInList: MoneyIn[] = [];
  viewPartyWiseMoneyInList: MoneyIn[] = [];
  selectedAttachMoneyInList: MoneyIn[] = [];

  totalMoneyInPending: number = 0;
  totalSelectedMoneyInAmount: number = 0;

  isModalOpen = false;
  isOpenMoneyInForm = false;
  isOpenLinkMoneyInList = false;
  isEditable = true;

  constructor(
    private allDataService: AllDataService,
    private alertController: AlertController,
    private commonService: CommonService,
  ) { }

  ngOnInit() {}

  ngAfterViewInit() {
    try {
      this.calculateTotalPendingMoneyIn();
      setTimeout(() => {
        if (this.isSaleList || this.isPurchaseReturnList) {
          this.openMoneyInSelectorModal();
        }
      }, 0);
    } catch (error) {
      SentryUtilites.setLog("MoneyInSelectorComponent:ngAfterViewInit", error)
    }
  }

  ngDestory() { }

  calculateTotalPendingMoneyIn() {
    let totalMoneyInDone = 0;
    this.moneyInCart?.forEach(moneyIn => totalMoneyInDone += Utility.isNumber(moneyIn?.totalAmount) ? +moneyIn?.totalAmount : 0);
    this.totalMoneyInPending = Utils.capFractionsToTwo(this.billTotalAmount - totalMoneyInDone);
  }

  openMoneyInSelectorModal(isOpen: boolean = true) {
    try {
      if (this.isEditable) {
        this.isModalOpen = isOpen;
      }
      if(!this.isModalOpen) {
        setTimeout(() => {
          this.onModelClose.emit(true);
        }, 500);
      }else {
        this.toggleMoneyInForm(true);
      }
      setTimeout(async ()=> {
        await this.moenyInFormEle?.inpTotalAmountEle?.setFocus();
      }, 500)
    } catch (error) {
      SentryUtilites.setLog("MoneyInSelectorComponent:openMoneyInSelectorModal", error)
    }
  }

  async onMoneyInSelect(moneyIn: MoneyIn) {
    try {
      this.isOpenMoneyInForm = false;
      if(
          moneyIn?._localUUID && 
          (
            moneyIn?.linkedSaleUUID===this.sale?._localUUID || 
            moneyIn?.linkedPurchaseReturnUUID === this.purchaseReturn?._localUUID
          )
        ) {
        this.moneyInCart?.push(moneyIn);
        this.moneyInsSelectedEvent.emit(this.moneyInCart);
        this.openMoneyInSelectorModal(false);
      }
    } catch (error) {
      SentryUtilites.setLog("MoneyInSelectorComponent:onMoneyInSelect", error)
    }
  }

  getTotal(moneyInCart: MoneyIn[]) {
    try {
      let totalAmount: number = 0;
      moneyInCart?.forEach(moneyIn => totalAmount += Number(moneyIn?.totalAmount));
      return this.capFractionsToTwo(totalAmount);
    } catch (error) {
      SentryUtilites.setLog("MoneyInSelectorComponent:getTotal", error)
      return 0;
    }
  }

  enable() {
    this.isEditable = true;
  }

  disable() {
    this.isEditable = false;
  }

  toggleMoneyInForm(value: boolean) {
    try {
      this.isOpenMoneyInForm = value;
      if(value==false) {
        this.openMoneyInSelectorModal(false);
      }
    } catch (error) {
      SentryUtilites.setLog("MoneyInSelectorComponent:toggleMoneyInForm", error)
    }
  }

  /**
   * 
   * @param value : provide boolean value to open or close link moneyIn modal
   */
  async toggleLinkMoneyInList(value: boolean) {
    try {
      this.isOpenLinkMoneyInList = value;
      if(value) {
        let moneyIns = await this.allDataService.moneyInService.getAll();
        this.completePartyWiseMoneyInList = moneyIns?.filter(moneyIn => !moneyIn?.linkedSaleUUID && moneyIn?.party?._localUUID === this.sale?.party?._localUUID && moneyIn?.totalAmount <= this.totalMoneyInPending);
        this.viewPartyWiseMoneyInList = this.completePartyWiseMoneyInList.sort((a, b) => a?.createdStamp - b?.createdStamp).slice(0,50);
        this.totalSelectedMoneyInAmount = 0;
        this.selectedAttachMoneyInList = [];
      }
    } catch (error) {
      SentryUtilites.setLog('MoneyInSelectorComponent:toggleLinkMoneyInList', error);
    }
  }
  // --------------------------------------

  /**
   * 
   * @description : open attach money in alert and perform attach moneyIns opration
   */
  async attachMoneyIns() {
    try {
      if(this.selectedAttachMoneyInList?.length) {
        let linkMoneyInAlert = await this.alertController.create({
          header: 'Confirm!',
          message: `Do you want to attach ₹${this.totalSelectedMoneyInAmount} payment with bill ${this.sale?.billNo}?`,
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              handler: () => {},
            },
            {
              text: 'Attach',
              role: 'confirm',
              handler: async () => {
                if(this.sale?._localUUID) {
                  for(let i = 0; i < this.selectedAttachMoneyInList?.length; i++) {
                    let moneyIn = this.selectedAttachMoneyInList[i];
                    moneyIn.linkedSaleUUID = this.sale?._localUUID;
                    await this.allDataService.moneyInService.update(moneyIn);
                    this.moneyInCart?.push(moneyIn);
                  }
                }
                this.commonService.generateToast('success', `Total ${this.selectedAttachMoneyInList?.length} Money Ins with total payment amount of ₹${this.totalSelectedMoneyInAmount} is successfully attached with bill ${this.sale?.billNo}.`);
                this.moneyInsSelectedEvent.emit(this.moneyInCart);
                this.toggleLinkMoneyInList(false);
                if(this.totalSelectedMoneyInAmount == this.totalMoneyInPending) {
                  this.toggleMoneyInForm(false);
                } else {
                  this.moenyInFormEle.initializeReactiveForm();
                  this.moenyInFormEle.populateForm();
                  this.calculateTotalPendingMoneyIn();
                  this.selectedAttachMoneyInList = [];
                }
                this.totalSelectedMoneyInAmount = 0;
              },
            },
          ],
          mode: 'ios',
        });
        linkMoneyInAlert.present();
      } else {
        this.commonService.generateToast('warning', 'Please Select Money In');
      }
    } catch (error) {
      SentryUtilites.setLog('MoneyInSelectorComponent:attachMoneyIns', error);
    }
  }
  // --------------------------------------

  /**
   * 
   * @param event : check box event
   * @param moneyIn : selected moneyIn
   * @description : store or remove selected moneyIn in selectedAttachMoneyInList array
   */
  selectedAttachMoneyIn(event: CustomEventInit, moneyIn: MoneyIn) {
    try {
      let isChecked = event?.detail?.checked;
      if(isChecked) {
        this.selectedAttachMoneyInList.push(moneyIn);
        this.totalSelectedMoneyInAmount += Utility.isNumber(moneyIn?.totalAmount) ? +moneyIn?.totalAmount : 0; 
      } else {
        let index = this.selectedAttachMoneyInList.findIndex(el => moneyIn?._localUUID === el?._localUUID);
        if(index != -1) {
          this.selectedAttachMoneyInList.splice(index,1);
          this.totalSelectedMoneyInAmount -= Utility.isNumber(moneyIn?.totalAmount) ? +moneyIn?.totalAmount : 0;
        }
      }
    } catch (error) {
      SentryUtilites.setLog('MoneyInSelectorComponent:selectedAttachMoneyIn', error);
    }
  }
  // -------------------------------------------

  getSelectedMoneyIns() {
    return this.moneyInCart;
  }

  deleteMoneyIn(moneyIn: MoneyIn) {
    try {
      const index = this.moneyInCart?.findIndex(x => x._localUUID == moneyIn._localUUID);
      if (index != -1) {
        this.moneyInCart?.splice(index, 1);
        this.moneyInsSelectedEvent.emit(this.moneyInCart);
      }
    } catch (error) {
      SentryUtilites.setLog("MoneyInSelectorComponent:deleteMoneyIn", error)
    }
  }

  loadMorePartyWiseMoneyInListData(event) {
    try {
      if (this.viewPartyWiseMoneyInList.length > 0 && this.viewPartyWiseMoneyInList.length <= this.completePartyWiseMoneyInList.length) {
        let appendListLength = this.completePartyWiseMoneyInList.length - this.viewPartyWiseMoneyInList.length;
        let lastEl = this.viewPartyWiseMoneyInList[this.viewPartyWiseMoneyInList.length - 1];
        let counter = 0;
        for (let i = 0; i < this.completePartyWiseMoneyInList.length; i++) {
          if (this.completePartyWiseMoneyInList[i]._localUUID == lastEl._localUUID) {
            counter = 1;
            continue;
          }
          if (counter > 0 && appendListLength >= 50) {
            if (counter <= 50) {
              this.viewPartyWiseMoneyInList.push(this.completePartyWiseMoneyInList[i])
            } else {
              break;
            }
            counter++;
          } else if(counter > 0 && appendListLength < 50) {
            if (counter <= appendListLength) {
              this.viewPartyWiseMoneyInList.push(this.completePartyWiseMoneyInList[i])
            } else {
              break;
            }
            counter++;
          }
        }
        (event as InfiniteScrollCustomEvent)?.target?.complete();
      }
    } catch (error) {
      SentryUtilites.setLog("MoneyInSelectorComponent:loadMorePartyWiseMoneyInListData", error)
    }
  }

}
