import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Kot } from '../../models/Kot.model';
import { Subscription } from 'rxjs';
import { AllDataService } from '../../services/all-data.service';
import { InfiniteScrollCustomEvent } from '@ionic/angular';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Sale } from '../../models/Sale.model';
import { Utility } from '../../utils/utility';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

export type KotData = {
  kot: Kot;
  sale: Sale;
};

@Component({
  selector: 'app-kot-list',
  templateUrl: './kot-list.component.html',
  styleUrls: ['./kot-list.component.scss'],
})
export class KotListComponent implements OnInit {

  @Input() viewSaleBtn: boolean = false;
  @Input() printKotBtn: boolean = false;
  @Input() saleUUID: string = null;
  @Input() isMobile: boolean = null;
  @Input() innerHeight: string = null;

  @Output() initKotTrigger = new EventEmitter<Kot>();
  @Output() onRowClickTrigger = new EventEmitter<KotData>();

  filteredList: KotData[] = [];
  completeList: KotData[] = [];
  viewFilteredList: KotData[] = [];

  subsArr: Subscription[] = [];

  fromStamp: number = null;
  toStamp: number = null;

  returnZero = () => { return 0 };

  shortStr = Utility.shortStr;

  constructor(
    private allDataService: AllDataService,
    private router: Router,
  ) { }

  ngOnInit() {
    try {
      this.routerSubscription();
      this.loadView();
      this.subsArr.push(this.allDataService.listForceReloadSubs.subscribe((listName: string) => {
        if (listName == 'kot-list') {
          this.loadView();
        }
      }));
    } catch (error) {
      SentryUtilites.setLog("KotListComponent:ngOnInit", error)
    }
  }

  ngAfterViewInit() { }

  ngOnDestroy() {
    try {
      this.subsArr?.forEach((el) => {
        el?.unsubscribe();
      });
    } catch (error) {
      SentryUtilites.setLog("KotListComponent:ngOnDestroy", error)
    }
  }

  routerSubscription() {
    try {
      this.subsArr.push(this.router.events.subscribe((event: RouterEvent) => {
        if(event instanceof NavigationEnd) {
          event?.url==='/kot' && this.loadView();
        }
      }));
    } catch (error) {
      SentryUtilites.setLog("KotListComponent:routerSubscription", error)
    }
  }

  onDateRangeChange(data:{fromStamp:number,toStamp:number}) {
    try {
      this.fromStamp = data.fromStamp;
      this.toStamp = data.toStamp;
      this.loadView();
    } catch (error) {
      SentryUtilites.setLog("KotListComponent:onDateRangeChange", error)
    }
  }

  async loadView() {
    try {
      if(this.saleUUID) {
        this.filteredList = [];
        let allKots = (await this.allDataService.kotService.getAllByPromise())?.filter(kot => kot?.linkedSaleUUID==this.saleUUID);
        let sale = await this.allDataService.saleService.getByUUID(this.saleUUID);
        this.completeList = [];
        allKots?.forEach(kot => {
          let kotData: KotData = { kot, sale };
          this.completeList?.push(kotData);
        });
        this.viewFilteredList = [];
        this.viewFilteredList = [...this.completeList.slice(0, 50)];
      }else {
        this.filteredList = [];
        let allKots = (await this.allDataService.kotService.getAllByPromise())?.filter(kot => kot?.createdStamp>=this.fromStamp && kot?.createdStamp<=this.toStamp);
        let allSales = (await this.allDataService.saleService.getAllByProfileWithRunningBill())?.filter(sale => sale?.billDateStamp>=this.fromStamp && sale?.billDateStamp<=this.toStamp);
        this.completeList = [];
        allKots?.forEach(kot => {
          let kotData: KotData = { kot, sale: null };
          if(kot?.linkedSaleUUID) {
            let sale = allSales?.find(sale => sale?._localUUID===kot?.linkedSaleUUID);
            if(sale?._localUUID) {
              kotData.sale = sale;
            }
          }
          this.completeList?.push(kotData);
        });
        this.viewFilteredList = [];
        this.viewFilteredList = [...this.completeList.slice(0, 50)];
      }
    } catch (error) {
      SentryUtilites.setLog("KotListComponent:loadView", error)
    }
  }

  loadMoreListData(event) {
    try {
      if (this.viewFilteredList.length > 0 && this.viewFilteredList.length <= this.filteredList.length) {
        let appendListLength = this.filteredList.length - this.viewFilteredList.length;
        let lastEl = this.viewFilteredList[this.viewFilteredList.length - 1];
        let counter = 0;
        for (let i = 0; i < this.filteredList.length; i++) {
          if (this.filteredList[i].kot._localUUID == lastEl.kot._localUUID) {
            counter = 1;
            continue;
          }
          if (counter > 0 && appendListLength >= 50) {
            if (counter <= 50) {
              this.viewFilteredList.push({ ...this.filteredList[i] })
            } else {
              break;
            }
            counter++;
          } else if(counter > 0 && appendListLength < 50) {
            if (counter <= appendListLength) {
              this.viewFilteredList.push({ ...this.filteredList[i] })
            } else {
              break;
            }
            counter++;
          }
        }
        (event as InfiniteScrollCustomEvent).target.complete();
      } 
    } catch (error) {
      SentryUtilites.setLog("KotListComponent:loadMoreListData", error)
    }
  }

  viewSale(kotData: KotData) {
    this.router.navigate([`/bill-view/${kotData?.sale?._localUUID}`]);
  }

  printKot(kotData: KotData) {
    this.initKotTrigger.emit(kotData?.kot);
  }

  onRowClick(kotData: KotData) {
    this.onRowClickTrigger.emit(kotData);
  }
 
}
