<ion-row>
  <ion-col size="6" *ngIf="cartItems?.length">
    <ion-button fill="clear" class="btn-item-name padding-start-none" color="primary">
      Invoice Items
    </ion-button>
  </ion-col>
  <ion-col class="txt-right" size="6" *ngIf="cartItems?.length">
    <ion-button fill="clear" class="btn-item-name padding-start-none" color="danger"
      (click)="presentClearCartConfirm()" [hidden]="!isEditable">
      Clear Items
    </ion-button>
  </ion-col>
  <ion-col size="12" class="col-custom-row" *ngFor="let billItem of cartItems">
    <ion-row class="padding-none">
      <ion-col size="12">
        <ion-button fill="clear" class="btn-item-name btn-txt-black padding-start-none txt-transform-normal">
          {{ billItem?.item?.itemName }}
        </ion-button>
      </ion-col>
      <ion-col size="6">
        <ion-button fill="clear" class="btn-item-name btn-txt-black padding-start-none">
          {{ isPurchase ? 'Purchase' : isPurchaseReturn ? 'Purchase Return' : 'Sell' }} Price:
        </ion-button>
        <ion-button fill="outline" class="dashed-border">
          {{ billItem?.price | currency: 'INR' }}
        </ion-button>
        <ion-button fill="clear" class="btn-item-name btn-txt-black padding-start-none"
          (click)="initBillItemForm(billItem)" [disabled]="!isEditable">
          <ion-icon name="pencil"></ion-icon>
        </ion-button>
      </ion-col>
      <ion-col size="6" class="txt-right">
        <ion-button fill="outline" class="outline-border" color="primary" (click)="decreaseQty(billItem)" [disabled]="!isEditable">
          -
        </ion-button>
        <ion-button fill="outline" class="outline-border" color="primary" (click)="initQuantityForm(billItem)" [disabled]="!isEditable">
          {{ billItem?.quantity }}
        </ion-button>
        <ion-button fill="outline" class="outline-border" color="primary" (click)="increaseQty(billItem)" [disabled]="!isEditable">
          +
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-col>
  <ion-col size="6">
    <ion-button
      fill="outline"
      class="outline-border btn-details"
      color="primary"
      (click)="openItemSelectorModal()"
      [disabled]="!isEditable"
    >
      ADD MORE ITEMS
    </ion-button>
  </ion-col>
  <ion-col size="6">
    <ion-button fill="clear" class="btn-txt-black padding-end-none flt-rght" color="primary">
      Total: {{ totalAmount | currency: 'INR' }}
    </ion-button>
  </ion-col>
</ion-row>


<ion-modal [ngClass]="{'cssHideModal':cssHideModal}" class="modal-item-selector" [isOpen]="isModalOpen && isEditable"
  [backdropDismiss]="false" animated="false">
  <ng-template>
    <ion-header>
      <ion-toolbar class="{{ getHeaderColorClass() }}">
        <ion-chip slot="start" color="tertiary" class="btn-back" (click)="openItemSelectorModal(false)">
          <ion-icon name="arrow-back-sharp"></ion-icon>
        </ion-chip>
        <ion-searchbar slot="start" #searchBar placeholder="Search by item name, item code..." [debounce]="100"
          (ionChange)="!barcodeScannerMode ? onFilterChange($event['detail'].value) : null" (keyup.enter)="onBarcodeSearchEnter($event)">
        </ion-searchbar>
        <ion-chip class="chip-barcode" slot="end" color="tertiary">
          <span class="title">Barcode Scanner Mode</span>
          <span>
            <ion-checkbox slot="end" color="tertiary" [checked]="barcodeScannerMode"
              (ionChange)="onBarcodeScannerModeChange($event)">
            </ion-checkbox>
          </span>
        </ion-chip>
        <ion-chip slot="end" fill="outline" color="primary" (click)="openItemAddModal()">
          Item +
        </ion-chip>
        <ion-chip *ngIf="!isPurchase && !isEstimate && !isEditedSale && !isEditedSaleReturn && !isPurchaseReturn" slot="end" fill="outline" color="primary" (click)="printKOT()"
          [disabled]="!cartItems?.length">
          KOT
        </ion-chip>
        <ion-chip *ngIf="!isPurchase && !isEstimate && !isEditedSale && !isEditedSaleReturn && !isPurchaseReturn" slot="end" fill="outline" color="primary" (click)="triggerHoldNew()"
          [disabled]="!cartItems?.length">
          Hold & New
        </ion-chip>
        <ion-chip *ngIf="!isPurchase && !isPurchaseReturn" slot="end" fill="outline" color="primary" (click)="openSeconadryPartyModal()">
          Parcel/Customer {{ this.partySecondary?.name ? ': '+this.partySecondary?.name : '' }}
        </ion-chip>
        <ion-chip slot="end" fill="clear" class="btn-item-name padding-start-none" color="danger"
          [disabled]="!cartItems?.length" (click)="presentClearCartConfirm()">
          <ion-icon name="bag-remove"></ion-icon>
        </ion-chip>
        <ion-chip slot="end" fill="clear" class="btn-item-name padding-start-none" (click)="openSettingsModal()">
          <ion-icon name="settings-sharp"></ion-icon>
        </ion-chip>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <app-item-restaurant-list #itemRestaurantList [isPurchase]="isPurchase" [isPurchaseReturn]="isPurchaseReturn" [isEstimate]="isEstimate" [cartItems]="cartItems"
        [sortByItemCodeAsc]="sortByItemCodeAsc" [showBookmarkList]="showBookmarkList"
        [negativeStockLock]="negativeStockLock" [barcodeScannerMode]="barcodeScannerMode"
        [iSetItemSelectorColumns]="iSetItemSelectorColumns" [party]="party" [paramDocumentId]="paramDocumentId"
        [iSetItemPriceHistoryStatus]="iSetItemPriceHistoryStatus" [iSetOutOfStockHideStatus]="iSetOutOfStockHideStatus"
        [iSetShowStockStatus]="iSetShowStockStatus" (onPriceShowStatusChange)="onPriceShowStatusChange($event)"
        (updateCart)="setBillItems($event)" (setBillItemsNoEmitNoCal)="setBillItemsNoEmitNoCal($event)"
        (openBillItemForm)="initBillItemForm($event)" (openQuantityForm)="initQuantityForm($event)">
      </app-item-restaurant-list>
    </ion-content>
    <ion-footer>
      <ion-toolbar>
        <ion-row>
          <ion-col size="12">
            <ion-button fill="clear" class="btn-txt-black padding-end-none" color="primary">
              Total: {{ totalAmount | currency: 'INR' }}
            </ion-button>
            <ion-segment mode="ios" [value]="paymentMode" (ionChange)="setPaymentMode($event)"
              *ngIf="paramDocumentId && billCompleteStamp">
              <ion-segment-button value="bank">
                <ion-label>UPI/Bank/POS</ion-label>
              </ion-segment-button>
              <ion-segment-button value="cash">
                <ion-label>Cash</ion-label>
              </ion-segment-button>
              <ion-segment-button value="cheque">
                <ion-label>Cheque</ion-label>
              </ion-segment-button>
            </ion-segment>
            <ion-button fill="clear" class="btn-txt-black padding-end-none"
              *ngIf="(!paramDocumentId || (!isPurchase && !isPurchaseReturn && !billCompleteStamp)) && !isEstimate">
              <ion-checkbox class="cb-amount-received" [disabled]="!cartItems?.length" [checked]="amountReceivedChecked"
                (ionChange)="onAmountReceivedCheckedChange($event)">
              </ion-checkbox>
              Amount {{ isPurchase ? 'Paid' : 'Received' }}:
              <ion-input #amountReceivedEle type="number" class="inp-amount-received"
                [value]="amountReceivedChecked ? amountReceivedEle?.value || totalAmount || 0 : 0"
                [disabled]="!cartItems?.length"
                (ionChange)="onAmountReceivedAmountChange($event)"></ion-input>
              <span class="span-max-amt-received" *ngIf="showMaxAmtReceivedText"> Maximum amount received should be {{
                totalAmount | currency: 'INR' }} </span>
            </ion-button>
            <ion-segment mode="ios" [value]="paymentMode" (ionChange)="setPaymentMode($event)"
              *ngIf="(!paramDocumentId || !billCompleteStamp) && amountReceivedChecked && cartItems?.length">
              <ion-segment-button value="bank">
                <ion-label>UPI/Bank/POS</ion-label>
              </ion-segment-button>
              <ion-segment-button value="cash">
                <ion-label>Cash</ion-label>
              </ion-segment-button>
              <ion-segment-button value="cheque">
                <ion-label>Cheque</ion-label>
              </ion-segment-button>
            </ion-segment>
            <ion-button fill="clear" class="btn-txt-black padding-end-none">
              <ion-input *ngIf="paymentMode=='cheque' || paymentMode=='bank'" class="inp-payment-id"
                placeholder="Payment Reference Id" [value]="paymentId" (ionChange)="setPaymentId($event)"></ion-input>
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="8">
            <ion-button fill="outline" class="outline-border btn-details" color="primary"
              (click)="openItemSelectorModal(false)">
              DETAILS
            </ion-button>
          </ion-col>
          <ion-col size="4">
            <ion-button *ngIf="(iSetQuickSaleStatus || ((isPurchase || isPurchaseReturn) && !iSetQuickSaleStatus)) && !isStaffAssignToSale" #savePrintButtonEle class="flt-rght btn-save" color="primary"
              [disabled]="!cartItems?.length" (click)="triggerSavePrint()">
              <!-- <ion-spinner *ngIf="showSaveAndPrintSpinner" name="lines-sharp"></ion-spinner> -->
              SAVE & PRINT ({{ totalAmount | currency: 'INR' }})
            </ion-button>
            <ion-button *ngIf="(iSetQuickSaleStatus || ((isPurchase || isPurchaseReturn) && !iSetQuickSaleStatus)) && !isStaffAssignToSale" class="flt-rght btn-save" color="tertiary"
              [disabled]="!cartItems?.length" (click)="triggerOnlySave()">
              <!-- <ion-spinner *ngIf="showOnlySaveSpinner" name="lines-sharp"></ion-spinner> -->
              ONLY SAVE ({{ totalAmount | currency: 'INR' }})
            </ion-button>
            <ion-button *ngIf="!isPurchase && !isPurchaseReturn && !iSetQuickSaleStatus || isStaffAssignToSale" class="flt-rght btn-save" color="tertiary"
              [disabled]="!cartItems?.length" (click)="openSeconadryPartyModal()">
              Next ({{ totalAmount | currency: 'INR' }})
            </ion-button>
            <!-- <ion-button *ngIf="isPurchase" class="flt-rght btn-save" color="tertiary" [disabled]="!cartItems?.length"
              (click)="triggerSavePrint()">
              Save ({{ totalAmount | currency: 'INR' }})
            </ion-button> -->
          </ion-col>
        </ion-row>
      </ion-toolbar>
    </ion-footer>
  </ng-template>
</ion-modal>

<ion-modal class="modal-bill-item-form" [isOpen]="isBillItemFormOpen || isQuantityFormOpen" [backdropDismiss]="false"
  [showBackdrop]="true" animated="true">
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ isBillItemFormOpen ? 'Edit Bill Item' : 'Update Bill Item Quantity' }}</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="closeBillitemForm()">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <form [formGroup]="billItemForm">
        <ion-row>
          <ion-col size="6" *ngIf="isBillItemFormOpen || onlyPriceFieldShow">
            <ion-item lines="none">
              <ion-label position="floating">{{ isPurchase ? 'Purchase' : isPurchaseReturn ? 'Purchase Return' : 'Sell' }} Price <span
                  class="span-mandatory">*</span></ion-label>
              <ion-input #formSellPriceEle [disabled]="lockSellPrice && !isPurchase && !isPurchaseReturn && !isEstimate" formControlName="price" type="number"
                placeholder="Price" (ionBlur)="updateCurrentItem()" (keyup.enter)="save()"></ion-input>
            </ion-item>
          </ion-col>
          <ion-col size="6" *ngIf="isBillItemFormOpen && !onlyPriceFieldShow">
            <ion-item lines="none" class="padding-top-bottom-8px">
              <ion-select interface="alert"
                [placeholder]="!currentItem?.item?.primaryUnit && !currentItem?.item?.secondaryUnit ? 'No Unit Selected' : 'Select Unit'"
                formControlName="unit" [disabled]="!currentItem?.item?.primaryUnit && !currentItem?.item?.secondaryUnit"
                (ionChange)="updateConvertRationMultiplier()">
                <ion-select-option *ngIf="currentItem?.item?.primaryUnit" [value]="currentItem?.item?.primaryUnit">
                  {{ currentItem?.item?.primaryUnit }}
                </ion-select-option>
                <ion-select-option *ngIf="currentItem?.item?.secondaryUnit" [value]="currentItem?.item?.secondaryUnit">
                  {{ currentItem?.item?.secondaryUnit }}
                </ion-select-option>
              </ion-select>
            </ion-item>
          </ion-col>
          <ion-col size="12">
            <ion-item lines="none" *ngIf="!onlyPriceFieldShow">
              <ion-label position="floating">Quantity</ion-label>
              <ion-input #formQuantityEle formControlName="quantity" type="number" placeholder="Quantity"
                (ionBlur)="updateCurrentItem()" (keyup.enter)="save()"></ion-input>
            </ion-item>
          </ion-col>
          <ion-col size="12" *ngIf="isBillItemFormOpen && !onlyPriceFieldShow">
            <ion-item lines="none">
              <ion-label position="floating">Final Price</ion-label>
              <ion-input formControlName="finalPrice" type="number" placeholder="Final Price"
                (ionBlur)="updateCurrentItem(CalculateBillItemTask.QUANTITY_FROM_TOTAL)"
                (keyup.enter)="save()"></ion-input>
            </ion-item>
          </ion-col>
          <ion-col size="6" *ngIf="isBillItemFormOpen && !onlyPriceFieldShow">
            <ion-item lines="none">
              <ion-label position="floating">Discount Percentage</ion-label>
              <ion-input formControlName="discountPercentage" type="number" placeholder="Discount Percentage"
                (ionBlur)="updateCurrentItem()" (keyup.enter)="save()"></ion-input>
            </ion-item>
          </ion-col>
          <ion-col size="6" *ngIf="isBillItemFormOpen && !onlyPriceFieldShow">
            <ion-item lines="none">
              <ion-label position="floating">Discount Amount</ion-label>
              <ion-input formControlName="discountAmount" type="number" placeholder="Discount Amount"
                (ionBlur)="updateCurrentItem(CalculateBillItemTask.DISCOUNT_FROM_AMOUNT)"
                (keyup.enter)="save()"></ion-input>
            </ion-item>
          </ion-col>
          <ion-col size="12" *ngIf="isBillItemFormOpen && !onlyPriceFieldShow">
            <ion-item lines="none">
              <ion-label position="floating">Bill Note</ion-label>
              <ion-input formControlName="billNote" placeholder="Bill Note" (keyup.enter)="save()"></ion-input>
            </ion-item>
          </ion-col>
          <ion-col size="12" *ngIf="isBillItemFormOpen && !onlyPriceFieldShow && !isPurchase && !isPurchaseReturn">
            <ion-item lines="none">
              <ion-label position="floating">Kot Note</ion-label>
              <ion-input formControlName="kotNote" placeholder="Kot Note" (keyup.enter)="save()"></ion-input>
            </ion-item>
          </ion-col>

        </ion-row>
      </form>
    </ion-content>
    <ion-footer>
      <ion-toolbar>
        <ion-button class="btn-save" expand="full" [disabled]="billItemForm?.invalid" (click)="save()">
          Save {{ billItemForm?.value?.finalPrice ? '(' + (billItemForm?.value?.finalPrice | currency: 'INR') + ')' : ''
          }}
        </ion-button>
      </ion-toolbar>
    </ion-footer>
  </ng-template>
</ion-modal>


<app-party-selector 
  #secondaryPartySelector 
  [isSecondaryPartyView]="true"
  [totalAmount]="totalAmount" 
  [cartLength]="cartItems?.length"
  [cashDiscountPercentage]="cashDiscountPercentage"
  [dSetDiscountOfferTypeI]="dSetDiscountOfferTypeI"
  [dSetDiscountOfferTypeII]="dSetDiscountOfferTypeII"
  [dSetDiscountStatusI]="dSetDiscountStatusI"
  [dSetDiscountStatusII]="dSetDiscountStatusII"
  [discountSoundStatus]="discountSoundStatus"
  [assignStaffUserId]="assignStaffUserId"
  [isEditedSale]="isEditedSale"
  [isEditedSaleReturn]="isEditedSaleReturn"
  [isStaffAssignToSale]="isStaffAssignToSale"
  (partySelectedEvent)="onSecondaryPartySelect($event)"
  (secondaryPartySavePrintEvent)="secondaryPartySavePrintEvent($event)"
  (secondaryPartyOnlySaveEvent)="secondaryPartyOnlySaveEvent($event)"
  (secondaryDiscount)="secondaryDiscount($event)"
  (selectedStaff)="emitSelectedStaff($event)">
</app-party-selector>

<ion-modal class="modal-item-form" [isOpen]="isItemAddModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-chip slot="start" color="tertiary" class="btn-back" (click)="openItemAddModal(false)">
          <ion-icon name="arrow-back-sharp"></ion-icon>
        </ion-chip>
        <ion-title slot="start">Add New Item</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <app-item-form [isBillform]="true" (saveTrigger)="saveTrigger($event)">
      </app-item-form>
    </ion-content>
  </ng-template>
</ion-modal>

<app-settings #settingsModal (onSave)="onSettingSave()">
</app-settings>


<ion-modal class="modal-kot-list" [isOpen]="isKotListModalOpen" [backdropDismiss]="false" animated="false">
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-title>Kot List</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="openKotListModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <app-kot-list [printKotBtn]="true" [saleUUID]="saleUUID" (initKotTrigger)="initKot($event)">
      </app-kot-list>
    </ion-content>
  </ng-template>
</ion-modal>