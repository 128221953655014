import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Ingredient } from '../../models/Ingredient.model';
import { InfiniteScrollCustomEvent, ToastController } from '@ionic/angular';
import { Utility } from '../../utils/utility';
import { IngredientService } from '../../services/ingredient.service';
import { AllDataService } from '../../services/all-data.service';
import { ItemIngredient } from '../../models/ItemIngredient.mode';
import { CommonService } from 'src/app/services/common.service';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-ingredient-selector',
  templateUrl: './ingredient-selector.component.html',
  styleUrls: ['./ingredient-selector.component.scss'],
})
export class IngredientSelectorComponent implements OnInit {

  @Input() cartIngredients: ItemIngredient[] = [];

  @Output() saveTrigger = new EventEmitter<ItemIngredient[]>();

  shortStr = Utility.shortStr;
  isMobile: boolean = null;


  isModalOpen = false;

  filteredList: ItemIngredient[] = [];
  completeList: ItemIngredient[] = [];
  viewFilteredList: ItemIngredient[] = [];

  appliedCategory = '';
  selectedFilter = '';

  categories: string[] = [];

  cartItemsCategories: string[] = [];

  categoryWiseItemCount = {};

  constructor(
    private toastController: ToastController,
    private ingredientService: IngredientService,
    private allDataService: AllDataService,
    private commonService: CommonService
  ) { }

  ngOnInit() {
    this.isMobile = this.commonService.isMobile();
  }

  openModal(value:boolean = true) {
    try {
      this.isModalOpen = value;
      if(this.isModalOpen) {
        this.loadView();
      }
    } catch (error) {
      SentryUtilites.setLog("IngredientSelectorComponent:openModal", error)
    }
  }

  async saveAndProceed() {
    try {
      let selectedIngredients: ItemIngredient[] = this.completeList?.filter(ingredient => ingredient['quantity']>0);
  
      // If customer remove all Ingredients
      
      // if(!selectedIngredients?.length) {
      //   const toast = await this.toastController.create({
      //     message: 'No ingredient Selected.',
      //     duration: 2000,
      //     color: 'dark',
      //   });
      //   await toast.present();
      //   return null;
      // }
  
      this.openModal(false);
  
      this.saveTrigger.emit(selectedIngredients);
    } catch (error) {
      SentryUtilites.setLog("IngredientSelectorComponent:saveAndProceed", error)
    }
  }

  increaseQty(uuid: string) {
    try {
      let index = this.completeList.findIndex(itemIngredient => itemIngredient?.ingredient?._localUUID===uuid);
      if(index != -1) {
        this.completeList[index]['quantity'] = (this.completeList[index]['quantity'] || 0) + 1;
      }
      this.maintainCartIngredientCategory();
    } catch (error) {
      SentryUtilites.setLog("IngredientSelectorComponent:increaseQty", error)
    }
  }

  decreaseQty(uuid: string) {
    try {
      let index = this.completeList.findIndex(itemIngredient => itemIngredient?.ingredient?._localUUID===uuid);
      if(index != -1) {
        let quantity = (this.completeList[index]['quantity'] || 0) - 1;
        this.completeList[index]['quantity'] = quantity >= 0 ? quantity : 0;
      }
      this.maintainCartIngredientCategory();
    } catch (error) {
      SentryUtilites.setLog("IngredientSelectorComponent:decreaseQty", error)
    }
  }

  onInpQtyChange(uuid: string, event: any) {
    try {
      let index = this.completeList.findIndex(itemIngredient => itemIngredient?.ingredient?._localUUID===uuid);
      if(index != -1) {
        let qty = Number(event?.detail?.target?.value);
        if(Utility.isNumber(qty)) {
          if(qty < 0) {
            qty = Math.abs(qty);
          }
        }else {
          qty = 0;
        }
        this.completeList[index]['quantity'] = qty;
      }
      this.maintainCartIngredientCategory();
    } catch (error) {
      SentryUtilites.setLog("IngredientSelectorComponent:onInpQtyChange", error)
    }
  }

  async loadView() {
    try {
      this.filteredList = [];
      this.completeList = [];
      this.viewFilteredList = [];
  
      this.appliedCategory = '';
      this.selectedFilter = 'SortByNameAsc';
  
      this.categories = [];
  
      this.completeList = await this.getCompleteList();
      this.onFilterChange();
      this.getCategories();
    } catch (error) {
      SentryUtilites.setLog("IngredientSelectorComponent:loadView", error)
    }
  }

  async getCompleteList() {
    try {
      let completeList = await this.ingredientService.getAll() || [];
      return completeList.map(ingredient => {
        let quantity = 0;
        if(this.cartIngredients?.length) {
          let index = this.cartIngredients?.findIndex(x => ingredient?._localUUID==x?.ingredient?._localUUID);
          if(index != -1) {
            quantity = this.cartIngredients[index]?.quantity;
          }
        }
        return {quantity,ingredient};
      });
    } catch (error) {
      SentryUtilites.setLog("IngredientSelectorComponent:getCompleteList", error)
      return [];
    }
  }

  async getCategories() {
    try {
      let categories=(await this.allDataService.profileService.getCurrentProfile())?.ingredientCategories;
      if (categories?.length) {
        this.categories = categories;
        if(this.completeList?.length) {
          this.categoryWiseItemCount = {};
          this.categories?.forEach(category => {
            this.categoryWiseItemCount[category] = this.completeList?.filter(itemIngredient => itemIngredient?.ingredient?.category?.toLowerCase()==category?.toLowerCase())?.length;
          });
        }
      }
    } catch (error) {
      SentryUtilites.setLog("IngredientSelectorComponent:getCategories", error)
    }
  }

  isFilterPostpond = false;
  lastFilterStamp = 0;
  onFilterChange(searchKey?: string) {
    try {
      if (this.isFilterPostpond) {
        return true;
      }
      if (this.lastFilterStamp < +new Date() - 200) {
        this.lastFilterStamp = +new Date();
        return this.applyFilter({
          searchKeyword: searchKey,
          selectedFilter: this.selectedFilter,
          category: this.appliedCategory
        })
      } else {
        this.isFilterPostpond = true;
        setTimeout(() => {
          this.isFilterPostpond = false;
          this.onFilterChange(searchKey);
        }, 250);
      }
    } catch (error) {
      SentryUtilites.setLog("IngredientSelectorComponent:onFilterChange", error)
    }
  }

  lastSearchKey = '';
  async applyFilter(params: {
    searchKeyword?: string,
    selectedFilter?: string,
    category?: string
  }) {
    try {
      let arr: ItemIngredient[] = [];
      let lsearchKey = '';
      let isSearchIncremental = false;
      let isSearchCleared = false;
      if (params?.searchKeyword?.length) {
        lsearchKey = Utility.toLowerCase(params.searchKeyword);
        if (lsearchKey.indexOf(this.lastSearchKey) == 0) {
          isSearchIncremental = false;
        }
        this.lastSearchKey = lsearchKey;
      }
      if (this.lastSearchKey.length > 0 && lsearchKey.length == 0) {
        isSearchCleared = true;
        this.lastSearchKey = '';
      }
      let completeList = [];
      if (isSearchIncremental) {
        completeList = [...this.filteredList]
      } else {
        completeList = [...this.completeList];
      }
      if (params?.category) {
        completeList?.forEach((el) => {
          if (el?.ingredient?.category == params.category) {
            arr.push(el);
          }
        });
      } else if (lsearchKey.length) {
        completeList?.forEach((el) => {
          if (Utility.toLowerCase(el?.ingredient?.name).indexOf(lsearchKey) != -1) {
            arr.push(el);
          }
        });
  
      } else {
        arr = [...completeList]
      }
  
  
      if (params.selectedFilter == 'SortByNameAsc') {
        arr.sort((a: ItemIngredient, b: ItemIngredient) => {
          if (a?.ingredient?.name?.toLowerCase() == b?.ingredient?.name?.toLowerCase()) {
            return a?.ingredient?.createdStamp - b?.ingredient?.createdStamp;
          }
          if (a?.ingredient?.name?.toLowerCase() > b?.ingredient?.name?.toLowerCase()) {
            return 1;
          }
          return -1;
        })
      } else if (params.selectedFilter == 'SortByNameDesc') {
        arr.sort((a: ItemIngredient, b: ItemIngredient) => {
          if (a?.ingredient?.name?.toLowerCase() == b?.ingredient?.name?.toLowerCase()) {
            return a?.ingredient?.createdStamp - b?.ingredient?.createdStamp;
          }
          if (b?.ingredient?.name?.toLowerCase() > a?.ingredient?.name?.toLowerCase()) {
            return 1;
          }
          return -1;
        })
      } else if (params.selectedFilter == 'SortByStockAsc') {
        arr.sort((a: ItemIngredient, b: ItemIngredient) => a?.ingredient?.stock-b?.ingredient?.stock);
      } else if (params.selectedFilter == 'SortByStockDesc') {
        arr.sort((a: ItemIngredient, b: ItemIngredient) => b?.ingredient?.stock-a?.ingredient?.stock);
      } else if (params.selectedFilter == 'lowStockByAsc') {
        arr = arr.filter(item => {
          const stock = Number(item?.ingredient?.stock);
          let minStock = Number(item?.ingredient?.minStock);
          minStock = minStock || 0;
          if (
            Utility.isNumber(stock)
            && Utility.isNumber(minStock)
            && stock <= minStock
          ) {
            return true;
          }else {
            return false;
          }
        });
        arr.sort((a: ItemIngredient, b: ItemIngredient) => {
          if (a?.ingredient?.name?.toLowerCase() == b?.ingredient?.name?.toLowerCase()) {
            return a?.ingredient?.createdStamp - b?.ingredient?.createdStamp;
          }
          if (b?.ingredient?.name?.toLowerCase() > a?.ingredient?.name?.toLowerCase()) {
            return 1;
          }
          return -1;
        })
      }
  
      this.filteredList = [...arr];
  
      if (lsearchKey.length || params.category || params.selectedFilter || isSearchCleared) {
        this.viewFilteredList = [...this.filteredList.slice(0, 50)];
      } else if (!this.viewFilteredList.length) {
        this.viewFilteredList = [...this.filteredList.slice(0, 50)];
      }
  
      return true;
    } catch (error) {
      SentryUtilites.setLog("IngredientSelectorComponent:applyFilter", error)
      return false;
    }
  }

  onCategoryChange(category: string) {
    try {
      this.appliedCategory = category;
      this.onFilterChange();
    } catch (error) {
      SentryUtilites.setLog("IngredientSelectorComponent:onCategoryChange", error)
    }
  }

  maintainCartIngredientCategory() {
    try {
      this.cartItemsCategories = [];
      this.completeList?.forEach(itemIngredient => {
        if(itemIngredient?.ingredient?.category && itemIngredient['quantity']>0) {
          this.cartItemsCategories.push(itemIngredient?.ingredient?.category);
        }
      });
    } catch (error) {
      SentryUtilites.setLog("IngredientSelectorComponent:maintainCartIngredientCategory", error)
    }
  }

  loadMoreListData(event) {
    try {
      if (this.viewFilteredList.length > 0 && this.viewFilteredList.length <= this.filteredList.length) {
        let appendListLength = this.filteredList.length - this.viewFilteredList.length;
        let lastEl = this.viewFilteredList[this.viewFilteredList.length - 1];
        let counter = 0;
        for (let i = 0; i < this.filteredList.length; i++) {
          if (this.filteredList[i].ingredient?._localUUID == lastEl?.ingredient?._localUUID) {
            counter = 1;
            continue;
          }
          if (counter > 0 && appendListLength >= 50) {
            if (counter <= 50) {
              this.viewFilteredList.push({ ...this.filteredList[i] })
            } else {
              break;
            }
            counter++;
          } else if(counter > 0 && appendListLength < 50) {
            if (counter <= appendListLength) {
              this.viewFilteredList.push({ ...this.filteredList[i] })
            } else {
              break;
            }
            counter++;
          }
        }
        (event as InfiniteScrollCustomEvent).target.complete();
      }
    } catch (error) {
      SentryUtilites.setLog("IngredientSelectorComponent:loadMoreListData", error)
    }
  }

}
