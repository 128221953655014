import { NgxIndexedDBService } from "ngx-indexed-db";
import { IDataRepo } from "../../../interface/IDataRepo.interface";
import { Licence, ILicence } from "../../models/Licence.model";
import { Utility } from "../../utils/utility";
import { Router } from "@angular/router";
import { SentryUtilites } from "src/app/utils/sentryUtilites";

export class LicenceDao implements IDataRepo<Licence>{
    private static _instance: LicenceDao;

    public static getInstance(
        ngxIndexedDBService: NgxIndexedDBService,
        router: Router,
    ) {
        if (!this._instance) {
            this._instance = new LicenceDao(ngxIndexedDBService, router)
        }
        return this._instance;
    }

    constructor(
        ngxIndexedDBService: NgxIndexedDBService,
        router: Router,
        ) {
        this.ngxIndexedDBService = ngxIndexedDBService;
        this.router = router;
      }
    
      ngxIndexedDBService: NgxIndexedDBService;
      router: Router;

    save(data: Licence): Promise<Licence> {
        return new Promise((resolve, reject) => {
            try {
                if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && Utility.isTruthy(data)) {
                    let timeStamp = +new Date();
                    data.createdStamp = timeStamp
                    data.updatedStamp = timeStamp
                    data.userUpdatedStamp = timeStamp
                    data.deletedStamp = 0
                    data.syncStamp = 0
    
                    data._localUUID = Utility.getUUID()+''+Utility.generateRandomPhone(4);
    
                    this.ngxIndexedDBService
                        .add(ILicence.SCHEMA_NAME, data)
                        .subscribe((savedProfile: Licence) => {
                            return resolve(savedProfile);
                        },
                        err => {
                            console.error(err);
                            if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                                this.router.navigate(['idbx-error']);
                            } else if (typeof err?.target?.error == 'object') {
                                    SentryUtilites.setLog("LicenceDao:save", err?.target?.error)
                                } else {
                                    SentryUtilites.setLog("LicenceDao:save", err)
                                }
                        });
                } else {
                  return resolve(null);
                }
            } catch (err) {
                SentryUtilites.setLog("LicenceDao:save", err)
                return resolve(null);
            }
        });
    }


    saveDb(data: Licence): Promise<Licence> {
        return new Promise((resolve, reject) => {
            try {
                if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && Utility.isTruthy(data)) {
                    this.ngxIndexedDBService
                        .add(ILicence.SCHEMA_NAME, data)
                        .subscribe((savedRecord: Licence) => {
                            return resolve(savedRecord);
                        },
                        err => {
                            console.error(err);
                            if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                                this.router.navigate(['idbx-error']);
                            } else if (typeof err?.target?.error == 'object') {
                                SentryUtilites.setLog("LicenceDao:saveDb", err?.target?.error)
                            } else {
                                SentryUtilites.setLog("LicenceDao:saveDb", err)
                            }
                        });
                } else {
                  return resolve(null);
                }
            } catch (error) {
                SentryUtilites.setLog("LicenceDao:saveDb", error)
                return resolve(null);
            }
        })
    }

    update(data: Licence): Promise<Licence> {
        return new Promise(async (resolve, reject) => {
            try {
                if (Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?._localUUID) {
                    let oldData = await this.getByUUID(data?._localUUID);
                    if(oldData?._localUUID) {
                        data._localId = oldData?._localId;
                        let timeStamp = +new Date();
                        data.updatedStamp = timeStamp
                        data.userUpdatedStamp = timeStamp
                        this.ngxIndexedDBService
                            .update(ILicence.SCHEMA_NAME, data)
                            .subscribe((updatedProfile: Licence) => {
                                return resolve(updatedProfile);
                            },
                            err => {
                                console.error(err);
                                if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                                    this.router.navigate(['idbx-error']);
                                } else if (typeof err?.target?.error == 'object') {
                                    SentryUtilites.setLog("LicenceDao:update", err?.target?.error)
                                } else {
                                    SentryUtilites.setLog("LicenceDao:update", err)
                                }
                            });
                    } else {
                        return resolve(null);
                    }
                } else {
                    return resolve(null);
                }
            } catch (err) {
                SentryUtilites.setLog("LicenceDao:update", err)
                return resolve(null);
            }
        })
    }

    updateDb(data: Licence): Promise<Licence> {
        return new Promise(async (resolve, reject) => {
            try {
                if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?._localUUID) {
                    let oldData = await this.getByUUID(data?._localUUID);
                    if(oldData?._localUUID) {
                        data._localId = oldData?._localId;
                        this.ngxIndexedDBService
                            .update(ILicence.SCHEMA_NAME, data)
                            .subscribe((updatedRecord: Licence) => {
                                return resolve(updatedRecord);
                            },
                            err => {
                                console.error(err);
                                if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                                    this.router.navigate(['idbx-error']);
                                } else if (typeof err?.target?.error == 'object') {
                                    SentryUtilites.setLog("LicenceDao:updateDb", err?.target?.error)
                                } else {
                                    SentryUtilites.setLog("LicenceDao:updateDb", err)
                                }
                            });
                    } else {
                        return resolve(null);
                    }
                } else {
                    return resolve(null);
                }
            } catch (err) {
                SentryUtilites.setLog("LicenceDao:updateDb", err)
                return resolve(null);
            }
        })
    }

    bulkPut(data: Licence[]): Promise<boolean> {
        return new Promise((resolve, reject) => {
          try {
            if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?.length) {
                this.ngxIndexedDBService
                  .bulkPut(ILicence.SCHEMA_NAME, data)
                  .subscribe((data) => {
                    return resolve(true);
                  },
                  err => {
                    console.error(err);
                    if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                        this.router.navigate(['idbx-error']);
                    } else if (typeof err?.target?.error == 'object') {
                        SentryUtilites.setLog("LicenceDao:bulkPut", err?.target?.error)
                    } else {
                        SentryUtilites.setLog("LicenceDao:bulkPut", err)
                    }
                  })
            } else {
              return resolve(false);
            }
          } catch (error) {
            SentryUtilites.setLog("LicenceDao:bulkPut", error)
            return resolve(false);
          }
        })
      }

    delete(data: Licence): Promise<Licence> {
        return new Promise(async (resolve, reject) => {
            try {
                if (Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data._localUUID) {
                    let oldData = await this.getByUUID(data?._localUUID);
                    if(oldData?._localUUID) {
                        data._localId = oldData?._localId;
                        let timeStamp = +new Date();
                        data.updatedStamp = timeStamp;
                        data.userUpdatedStamp = timeStamp;
                        data.deletedStamp = timeStamp;
                        this.ngxIndexedDBService
                            .update(ILicence.SCHEMA_NAME, data)
                            .subscribe((updatedProfile: Licence) => {
                                return resolve(updatedProfile);
                            },
                            err => {
                                console.error(err);
                                if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                                    this.router.navigate(['idbx-error']);
                                } else if (typeof err?.target?.error == 'object') {
                                    SentryUtilites.setLog("LicenceDao:delete", err?.target?.error)
                                } else {
                                    SentryUtilites.setLog("LicenceDao:delete", err)
                                }
                            });
                    } else {
                        return resolve(null);
                    }
                } else {
                    return resolve(null);
                }
            } catch (err) {
                SentryUtilites.setLog("LicenceDao:delete", err)
                return resolve(null);
            }
        });
    }
    getById(id: number): Promise<Licence> {
        return new Promise((resolve, reject) => {
            try {
                if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && id) {
                    this.ngxIndexedDBService
                        .getByKey(ILicence.SCHEMA_NAME, id)
                        .subscribe((profile: Licence) => {
                            return resolve(profile);
                        },
                        err => {
                            console.error(err);
                            if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                                this.router.navigate(['idbx-error']);
                            } else if (typeof err?.target?.error == 'object') {
                                SentryUtilites.setLog("LicenceDao:getById", err?.target?.error)
                            } else {
                                SentryUtilites.setLog("LicenceDao:getById", err)
                            }
                        });
                } else {
                  return resolve(null);
                }
            } catch (err) {
                SentryUtilites.setLog("LicenceDao:getById", err)
                return resolve(null)
            }
        })
    }
    getByUUID(uuid: string): Promise<Licence> {
        return new Promise((resolve, reject) => {
            try {
                if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && uuid) {
                    this.ngxIndexedDBService
                        .getByIndex(ILicence.SCHEMA_NAME, '_localUUID', uuid)
                        .subscribe((profile: Licence) => {
                            return resolve(profile);
                        },
                        err => {
                            console.error(err);
                            if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                                this.router.navigate(['idbx-error']);
                            } else if (typeof err?.target?.error == 'object') {
                                SentryUtilites.setLog("LicenceDao:getByUUID", err?.target?.error)
                            } else {
                                SentryUtilites.setLog("LicenceDao:getByUUID", err)
                            }
                        });
                } else {
                  return resolve(null);
                }
            } catch (err) {
                SentryUtilites.setLog("LicenceDao:getByUUID", err)
                return resolve(null)
            }
        })
    }
    getAll(): Promise<Licence[]> {
        return new Promise((resolve, reject) => {
            try {
                if(Boolean(Utility.getFromLocalStorage('_ezo_login_token'))) {
                    this.ngxIndexedDBService.getAll(ILicence.SCHEMA_NAME).subscribe((docs: Licence[]) => {
                        docs = docs?.filter(x => !x?.deletedStamp);
                        docs?.sort((a, b) => b?.createdStamp - a?.createdStamp);
                        return resolve(docs)
                    },
                    err => {
                        console.error(err);
                        if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                            this.router.navigate(['idbx-error']);
                        } else if (typeof err?.target?.error == 'object') {
                            SentryUtilites.setLog("LicenceDao:getAll", err?.target?.error)
                        } else {
                            SentryUtilites.setLog("LicenceDao:getAll", err)
                        }
                    });
                } else {
                  return resolve([]);
                }
            } catch (err) {
                SentryUtilites.setLog("LicenceDao:getAll", err)
                return resolve([])
            }
        })
    }
    getAllByProfile(profileId: string): Promise<Licence[]> {
        return new Promise((resolve, reject) => {
            try {
                if(Boolean(Utility.getFromLocalStorage('_ezo_login_token'))) {
                    this.ngxIndexedDBService.getAll(ILicence.SCHEMA_NAME).subscribe((docs: Licence[]) => {
                        docs = docs?.filter(x => !x?.deletedStamp);
                        docs?.sort((a, b) => b?.createdStamp - a?.createdStamp);
                        return resolve(docs)
                    },
                    err => {
                        console.error(err);
                        if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                            this.router.navigate(['idbx-error']);
                        } else if (typeof err?.target?.error == 'object') {
                            SentryUtilites.setLog("LicenceDao:getAllByProfile", err?.target?.error)
                        } else {
                            SentryUtilites.setLog("LicenceDao:getAllByProfile", err)
                        }
                    });
                } else {
                  return resolve([]);
                }
            } catch (err) {
                SentryUtilites.setLog("LicenceDao:getAllByProfile", err)
                return resolve([])
            }
        })
    }
    getAllUnsynced(profileId: string): Promise<Licence[]> {
        return new Promise((resolve, reject) => {
            try {
                if(Boolean(Utility.getFromLocalStorage('_ezo_login_token'))) {
                    let unSyncedElements: Licence[] = [];
                    this.ngxIndexedDBService.getAll(ILicence.SCHEMA_NAME).subscribe(async (elArr: Licence[]) => {
                        for (let i = 0; i < elArr?.length; i++) {
                            const el = elArr[i];
                            if (el?.updatedStamp > el?.syncStamp || !el?._serverIdRef) {
                                unSyncedElements.push(el);
                            }
                        }
                        return resolve(unSyncedElements)
                    },
                    err => {
                        console.error(err);
                        if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                            this.router.navigate(['idbx-error']);
                        } else if (typeof err?.target?.error == 'object') {
                            SentryUtilites.setLog("LicenceDao:getAllUnsynced", err?.target?.error)
                        } else {
                            SentryUtilites.setLog("LicenceDao:getAllUnsynced", err)
                        }
                    });
                } else {
                  return resolve([]);
                }
            } catch (err) {
                SentryUtilites.setLog("LicenceDao:getAllUnsynced", err)
                return resolve([])
            }
        })
    }
    updateSyncStamp(data: Licence): Promise<Licence> {
        return new Promise(async (resolve, reject) => {
            try {
                if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?._localUUID) {
                    this.ngxIndexedDBService
                    .getByIndex(ILicence.SCHEMA_NAME, '_localUUID', data._localUUID)
                    .subscribe((dbEl: Licence) => {
                        if(dbEl) {
                                dbEl.syncStamp = data?.syncStamp || 0;
                                dbEl._serverIdRef = data?._serverIdRef;
                                this.ngxIndexedDBService
                                    .update(ILicence.SCHEMA_NAME, dbEl)
                                    .subscribe((updatedEl: Licence) => {
                                        return resolve(updatedEl);
                                    },
                                    err => {
                                        console.error(err);
                                        if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                                            this.router.navigate(['idbx-error']);
                                        } else if (typeof err?.target?.error == 'object') {
                                            SentryUtilites.setLog("LicenceDao:updateSyncStamp", err?.target?.error)
                                        } else {
                                            SentryUtilites.setLog("LicenceDao:updateSyncStamp", err)
                                        }
                                    });
                            } else {
                              return resolve(null);
                            }
                        },
                        err => {
                            console.error(err);
                            if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                                this.router.navigate(['idbx-error']);
                            } else if (typeof err?.target?.error == 'object') {
                                SentryUtilites.setLog("LicenceDao:updateSyncStamp", err?.target?.error)
                            } else {
                                SentryUtilites.setLog("LicenceDao:updateSyncStamp", err)
                            }
                        })
                } else {
                    return resolve(null);
                }
            } catch (err) {
                SentryUtilites.setLog("LicenceDao:updateSyncStamp", err)
                return resolve(null)
            }
        })
    }

}
