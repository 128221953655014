import { CommonService } from '../../services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Utility } from '../../utils/utility';
import { GoogleMybusinessService } from '../../services/google-mybusiness.service';
import { Component, OnInit } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { environment } from '../../../environments/environment';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-google-mybusiness',
  templateUrl: './google-mybusiness.page.html',
  styleUrls: ['./google-mybusiness.page.scss'],
})
export class GoogleMybusinessPage implements OnInit {

  getHeaderColorClass = Utility.getHeaderColorClass;

  locationData = null;
  loading: HTMLIonLoadingElement = null;
  isShowAuthenticateUserBtn: boolean = false;
  isUserVisited: boolean = false;
  gmbLocationId: string = '';
  status: string = '';
  isMobile: boolean = false;

  constructor(
    private googleMybusinessService: GoogleMybusinessService,
    private router: Router,
    private commonService: CommonService,
    private loadingCtrl: LoadingController,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    try {
      this.isMobile = this.commonService.isMobile();
      this.status =  this.activatedRoute?.snapshot?.queryParamMap?.get('status') || '';
      if(this.status?.length) {
        if(this.status === 'success') {
          this.getLocations();
        } else if(this.status === 'fail') {
          this.commonService.generateToast('warning', 'Try again later')
        }
        return
      }
      
      let selectedProfile = Utility.getFromLocalStorage('selectedProfile');
      this.gmbLocationId = Utility.getFromLocalStorage(`gmbLocationId_${selectedProfile}`) || '';  
  
      if(!this.gmbLocationId) {
        this.getLocations();
      } else {
        this.getLocations(true);
      }
    } catch (error) {
      SentryUtilites.setLog("GoogleMybusinessPage:ngOnInit", error)
    }

  }

  ionViewWillLeave() {
    this.loading?.dismiss();
  }

  /**
   * 
   * @param isHaveGmbLocationId : have GmbLocationID
   */
  async getLocations(isHaveGmbLocationId: boolean = false) {
    try {
      this.showLoading('Loading Locations List....', this.isMobile ? 'mobile-loading-list-loader' : 'loading-list-loader');
      this.locationData = await this.googleMybusinessService.getLocationsV2();
      if(this.locationData) {
        this.loading?.dismiss();
      }
      
      let locationsLength = this.locationData?.locations?.length;
      if(locationsLength) {
        for(let i=0; i < locationsLength; i++ ) {
          this.locationData.locations[i]['address'] = '';
          for(let j=0; j < this.locationData.locations[i]?.storefrontAddress?.addressLines?.length; j++ ) { 
            this.locationData.locations[i]['address'] += (this.locationData.locations[i]?.storefrontAddress?.addressLines[j]|| '')+ ((this.locationData.locations[i]?.storefrontAddress?.addressLines[j]|| '') ? ', ' : '')
          }
        }
        for(let i=0; i < locationsLength; i++ ) {
          this.locationData.locations[i]['address'] += (this.locationData.locations[i]?.storefrontAddress?.sublocality|| '')+((this.locationData.locations[i]?.storefrontAddress?.sublocality|| '') ? ', ' : '');
        }
        for(let i=0; i < locationsLength; i++ ) {
          this.locationData.locations[i]['address'] += (this.locationData.locations[i]?.storefrontAddress?.locality|| '')+((this.locationData.locations[i]?.storefrontAddress?.locality|| '') ? ', ' : '');
        }
        for(let i=0; i < locationsLength; i++ ) {
          this.locationData.locations[i]['address'] += (this.locationData.locations[i]?.storefrontAddress?.administrativeArea|| '')+((this.locationData.locations[i]?.storefrontAddress?.administrativeArea|| '') ? ', ' : '');
        }
        for(let i=0; i < locationsLength; i++ ) {
          this.locationData.locations[i]['address'] += (this.locationData.locations[i]?.storefrontAddress?.regionCode|| '')+((this.locationData.locations[i]?.storefrontAddress?.regionCode|| '') ? ', ' : '');
        }
        for(let i=0; i < locationsLength; i++ ) {
          this.locationData.locations[i]['address'] += (this.locationData.locations[i]?.storefrontAddress?.postalCode|| '')+((this.locationData.locations[i]?.storefrontAddress?.postalCode|| '') ? ', ' : '');
        }
      } else if(isHaveGmbLocationId) {
        this.isShowAuthenticateUserBtn = true;
        this.commonService.generateToast('warning', 'Locations are Unavailable')
      } else {
        this.authenticateWithGoogle();
      }
    } catch (error) {
      SentryUtilites.setLog("GoogleMybusinessPage:getLocations", error)
    }
  }
  // ----------------------------------------------

  /**
   * @description : redirect to google authenticate page
   */
  authenticateWithGoogle() {
    try {
      let userId = Utility.getFromLocalStorage('selectedProfileUserId');
      let selectedProfile = Utility.getFromLocalStorage('selectedProfile');
  
      window.open(
        `${environment.ezoOfServerGmb}auth/${userId}/${selectedProfile}`,
        '_self'
      )
    } catch (error) {
      SentryUtilites.setLog("GoogleMybusinessPage:authenticateWithGoogle", error)
    }
  }
  // ----------------------------------------------

  /**
   * 
   * @param locationName : provide location name
   * @param isVerify : in location have verificationStatus in COMPLETED status 
   */
  async setLocation(locationName: string, isVerify: boolean) {
    try {
      if(isVerify) {
        let res = await this.googleMybusinessService.setLocation(locationName);
        if(res['status'] === 'success') {
          let selectedProfile = Utility.getFromLocalStorage('selectedProfile');
          Utility.setToLocalStorage(`gmbLocationId_${selectedProfile}`, locationName);
          this.router.navigate(['dashboard']);
        } else {
          this.commonService.generateToast('warning', 'Please get this verified from Google to continue!');
        }
      } else {
        this.commonService.generateToast('warning', 'Please get this verified from Google to continue!');
      }
    } catch (error) {
      SentryUtilites.setLog("GoogleMybusinessPage:setLocation", error)
    }
  }
  // ----------------------------------------------

  /**
   * 
   * @param message : provide text message
   * @param cssClass : provide css class
   */
  async showLoading(message: string, cssClass: string) {
    try {
      this.loading = await this.loadingCtrl.create({ 
        message,
        cssClass,
      });
      this.loading?.present();
    } catch (error) {
      SentryUtilites.setLog("GoogleMybusinessPage:showLoading", error)
    }
  }
  // ----------------------------------------------

}
