import { BaseModel } from "./BaseModel.model";
import { BillItem } from "./BillItem.model";
import { MoneyIn } from "./MoneyIn.model";
import Party from "./Party.model";
import { TransportDetail } from "./TransportDetail.model";

export class IPurchaseReturn {

  static SCHEMA_NAME = 'purchase-return';

}
export class PurchaseReturn extends BaseModel {

  profileId: string;

  billNo: string;
  billDateStamp: number;
  purchaseBillNo: string;
  purchaseBillDateStamp: number;
  purchaseCreatedStamp: number;
  party: Party;
  billItems: BillItem[];
  subTotalAmount: number;
  totalAmount: number;
  totalSaving: number;

  moneyIns?: MoneyIn[];

  dueDateStamp:number;
  // billingTerm:string;

  note: string;
  transportDetail: TransportDetail;

  gstAmount: number;
  cessAmount: number;
  discountAmount: number;
  cashDiscount: number;
  cashDiscountPercentage: number;
  amountReceived : number;
  additionalDiscount :number;
  senderProvience: string;
  deliveryProvience:string;

  roundOffValue: number;
  linkedPurchaseUUID: string;

}
