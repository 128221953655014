<ion-header [ngClass]="{'mobile': isMobile}">
  <ion-toolbar class="{{ getHeaderColorClass() }}">
    <app-navigation-control slot="start" type="ingredient"></app-navigation-control>
    <ion-title>Raw Material List <ion-chip> Total Count: {{ filteredList?.length }}</ion-chip> </ion-title>
    <!-- <ion-chip class="chip-new-ingredient" color="tertiary" slot="end" (click)="onNewIngredientClick()"> + New Ingredient </ion-chip> -->
  </ion-toolbar>
</ion-header>

<ion-content [ngClass]="{'mobile': isMobile}">

  <div class="backdrop" *ngIf="isActionBtnsEnabled" (click)="disableActionBtns()"></div>
  <ion-searchbar
    #searchBar
    placeholder="Search by raw material name..."
    [debounce]="100"
    (ionChange)="onFilterChange($event['detail'].value)"
  >
  </ion-searchbar>
  
  <ion-fab class="btn-filter-by" #filterByFab>
    <ion-fab-button class="first-fab-button" size="small" (clickOutSide)="closeclickOutSideFilter()">
      Filter By
    </ion-fab-button>
    <ion-fab-list side="bottom" [ngClass]="{'scrollable': categories?.length>=18}">
      <ion-fab-button
        [ngClass]="{'filter-sort-active': !appliedCategory}"
        (click)="onCategoryChange('')"
      >
        All ({{ categoryWiseIngredientCount['All'] || 0 }})
      </ion-fab-button>
      <ion-fab-button
        [ngClass]="{'filter-sort-active': category===appliedCategory}"
        *ngFor="let category of categories"
        (click)="onCategoryChange(category)"
      >
        {{ category }}  ({{ categoryWiseIngredientCount[category] || 0 }})
      </ion-fab-button>
    </ion-fab-list>
  </ion-fab>
  
  <ion-fab class="btn-sort-by" #sortByFab>
    <ion-fab-button class="first-fab-button" size="small" (clickOutSide)="closeclickOutSideSort()">
      Sort By
    </ion-fab-button>
    <ion-fab-list side="bottom">
      <ion-fab-button
        [ngClass]="{'filter-sort-active': sort?.key===selectedFilter}"
        *ngFor="let sort of sortObj|keyvalue: returnZero"
        (click)="onSortChnage(sort.key)"
      >
        {{ sort?.value }}
      </ion-fab-button>
    </ion-fab-list>
  </ion-fab>
  
  <ion-grid>

    <ion-row>
      
      <ion-col class="col-categories" [ngStyle]="{'height': innerHeight}" size-xl="2" size-xs="4">
        <ion-item lines="none" [class.active-item]="!appliedCategory" (click)="onCategoryChange('')">
          All ({{ filteredList?.length || 0 }})
        </ion-item>
        <ion-item lines="none" *ngFor="let category of categories" [class.active-item]="category === appliedCategory"
          (click)="onCategoryChange(category)">
          {{ category }} ({{ categoryWiseIngredientCount[category] || 0 }})
        </ion-item>
      </ion-col>

      <ion-col class="col-raw-material-list" [ngStyle]="{'height': innerHeight}" size-xl="10" size-xs="8">
        <ion-row class="item-custom-row" *ngFor="let ingredient of viewFilteredList">

          <ion-col size-xl="4.5" size-xs="12" class="item-name" (click)="navigateToTransaction(ingredient?._localUUID)">
            <ion-button fill="clear" class="btn-item-name">
              {{ shortStr(ingredient?.name,85) }}
            </ion-button>
          </ion-col>

          <ion-col size-xl="5">
            <ion-row>
              <ion-col size-xl="4" size-xs="6" (click)="navigateToTransaction(ingredient?._localUUID)">
                <span class="opening-stock">
                  Opening Stock
                </span>
              </ion-col>
              <ion-col size-xl="1" size-xs="1" (click)="navigateToTransaction(ingredient?._localUUID)">
                :
              </ion-col>
              <ion-col size-xl="4" size-xs="3" (click)="navigateToTransaction(ingredient?._localUUID)">
                {{ ingredient?.yesterdayEOD ? 
                 (ingredient?.yesterdayEOD?.quantity ? ((ingredient?.yesterdayEOD?.quantity || 0) | number: '1.2-2') : '0') : 
                '-' }}
              </ion-col>
              <ion-col size-xl="3" size-xs="2" (click)="navigateToTransaction(ingredient?._localUUID)">
                {{ ingredient?.yesterdayEOD?.unit || '-' }}
              </ion-col>
              <ion-col size-xl="4">
                <ion-button class="live-stock" fill="outline" shape="round" (click)="getLiveStock(ingredient?._localUUID)">
                  Live Stock
                </ion-button>
              </ion-col>
              <ion-col size-xl="1" class="colon" (click)="navigateToTransaction(ingredient?._localUUID)">
                <span>
                  :
                </span>
              </ion-col>
              <ion-col class="col-live-stock" size-xl="4" (click)="navigateToTransaction(ingredient?._localUUID)">
                {{ ingredient?.stock ? ((ingredient?.stock || 0) | number: '1.2-2') : '-' }}
              </ion-col>
              <ion-col class="col-live-stock" size-xl="3" (click)="navigateToTransaction(ingredient?._localUUID)">
                {{ ingredient?.unit || '-' }}
              </ion-col>
            </ion-row>
          </ion-col>

          <ion-col size-xl="2.5" class="raw-material-list-fab">
            <ion-button title="Edit" color="primary" fill="clear" (click)="editRawMaterial(ingredient?._localUUID)">
              <ion-icon name="create"></ion-icon>
            </ion-button>
            <ion-button title="Delete" color="danger" fill="clear" (click)="deleteRawMaterial(ingredient)">
              <ion-icon name="trash"></ion-icon>
            </ion-button>
            <ion-button class="btn-circle" (click)="enableActionBtns(ingredient)">
              +
            </ion-button>
          </ion-col>
      
        </ion-row>
      </ion-col>

    </ion-row>
  
  </ion-grid>

  <div class="action-buttons-holder" *ngIf="isActionBtnsEnabled">
    <ion-button (click)="navigateAction('purchase')">Purchase</ion-button>
    <ion-button (click)="navigateAction('scrap')">Scrap</ion-button>
    <ion-button (click)="navigateAction('transfer')">Transfer</ion-button>
    <ion-button (click)="navigateAction('return')">Return</ion-button>
    <ion-button (click)="navigateAction('eod')">EOD Stock</ion-button>
    <ion-button (click)="emitElement()">Add Raw Material</ion-button>
  </div>

  <ion-button class="btn-bottom-fab" (click)="enableActionBtns(null)">
    <ion-icon name="add"></ion-icon>
     Manage
  </ion-button>

  <ion-infinite-scroll
    threshold="10px"
    *ngIf="viewFilteredList?.length"
    (ionInfinite)="loadMoreListData($event)">
    <ion-infinite-scroll-content
      loadingSpinner="bubbles"
      loadingText="Loading more data...">
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
  
  <app-ingredient-stock-adjust #ingredientStockAdjustEle (saveTrigger)="loadView()"></app-ingredient-stock-adjust>

  
</ion-content>