import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appYearDefaultBtn]'
})
export class YearDefaultBtnDirective {

  constructor(private ionDatetimeButtonEle: ElementRef) {
    const style = (document.createElement('style'));
    style.textContent = `
    .datetime-year {
      height: 291px;
    }
    .datetime-footer {
      display: block !important;
    }
    `;
    this.ionDatetimeButtonEle?.nativeElement?.shadowRoot?.appendChild(style);
  }

}
