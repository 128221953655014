import { Utility } from "../utils/utility";
import { ExpressServerService } from "./api/express-server.service";
import { Injectable } from "@angular/core";
import { IImageBase64, ImageBase64 } from "../models/ImageBase64.model";
import { Image } from "../models/image.model";
import { NgxIndexedDBService } from "ngx-indexed-db";
import { Router } from "@angular/router";
import { AllDataService } from "./all-data.service";
import { environment } from "../../environments/environment";
import { SentryUtilites } from "../utils/sentryUtilites";

@Injectable({
  providedIn: 'root'
})
export class ImageBase64Service {

  isUserLoggedIn = Boolean(Utility.getFromLocalStorage('_ezo_login_token'));

  constructor(
    private allDataService: AllDataService,
    private expressServerService: ExpressServerService,
    private ngxIndexedDBService: NgxIndexedDBService,
    private router: Router,
  ) { }

  async getByUrl(url: string): Promise<ImageBase64> {
    return new Promise((resolve, reject) => {
      try {
        if(Utility.isTruthy) {
          this.ngxIndexedDBService
            .getByIndex(IImageBase64.SCHEMA_NAME, 'url', url)
            .subscribe((record: ImageBase64) => {
              return resolve(record);
            },
              err => {
                console.error(err);
                if(this.isUserLoggedIn && typeof err == 'string' && err?.includes('objectStore does not exists')) {
                  this.router.navigate(['idbx-error']);
                } else if (typeof err?.target?.error == 'object') {
                  SentryUtilites.setLog("ImageBase64Service:getByUrl", err?.target?.error)
                } else {
                  SentryUtilites.setLog("ImageBase64Service:getByUrl", err)
                }
              });
        } else {
          return resolve(null);
        }
      } catch (err) {
        SentryUtilites.setLog("ImageBase64Service:getByUrl", err)
        return resolve(null)
      }
    })
  }

  save(imageBase64: ImageBase64): Promise<ImageBase64> {
    return new Promise(async (resolve, reject) => {
      try {
        if(Utility.isTruthy(imageBase64)) {
          this.ngxIndexedDBService
            .add(IImageBase64.SCHEMA_NAME, imageBase64)
            .subscribe((savedImageBase64: ImageBase64) => {
              return resolve(savedImageBase64);
            },
            err => {
              console.error(err);
              if(this.isUserLoggedIn && typeof err == 'string' && err?.includes('objectStore does not exists')) {
                this.router.navigate(['idbx-error']);
              } else if (typeof err?.target?.error == 'object') {
                SentryUtilites.setLog("ImageBase64Service:save", err?.target?.error)
              } else {
                SentryUtilites.setLog("ImageBase64Service:save", err)
              }
            }
          ); 
        } else {
          return resolve(null);
        }
      } catch (error) {
        SentryUtilites.setLog("ImageBase64Service:save", error)
        return resolve(null)
      }
    });
  }

  update(imageBase64: ImageBase64): Promise<ImageBase64> {
    return new Promise(async (resolve, reject) => {
      try {
        if(imageBase64?.url) {
          this.ngxIndexedDBService
            .update(IImageBase64.SCHEMA_NAME, imageBase64)
            .subscribe((savedImageBase64: ImageBase64) => {
              return resolve(savedImageBase64);
            },
            err => {
              console.error(err);
              if(this.isUserLoggedIn && typeof err == 'string' && err?.includes('objectStore does not exists')) {
                this.router.navigate(['idbx-error']);
              } else if (typeof err?.target?.error == 'object') {
                SentryUtilites.setLog("ImageBase64Service:update", err?.target?.error)
              } else {
                SentryUtilites.setLog("ImageBase64Service:update", err)
              }
            }
          );  
        } else {
          return resolve(null);
        }
      } catch (error) {
        SentryUtilites.setLog("ImageBase64Service:update", error)
        return resolve(null)
      }
    });
  }

  isRunning = false;
  async initiateImageBase64BackgroundProcess() {
    try {
      if(this.isRunning) {
        return null;
      }
      this.isRunning = true;
      let allImages = await this.allDataService.imageService.getAll();
      for (let i = 0; i < allImages?.length; i++) {
        let image = allImages[i];
        await this.getBase64FromDBorServer(image);
      }
      this.isRunning = false;
    } catch (error) {
      SentryUtilites.setLog("ImageBase64Service:initiateImageBase64BackgroundProcess", error)
      return null;
    }
  }

  async getBase64FromDBorServer(image:Image) {
    try {
      if(image?._localUUID) {
        let url = `${environment.ezoOfServerImage}image/getbase64/${image?.userId}/${image?.profileId}/${image?._localUUID}`;
        let dbImageBase64 = await this.getByUrl(url);
        if(!dbImageBase64?.imageBase64) {
          let serverBase64Image = null;
            serverBase64Image = await this.expressServerService.makeImageBase64Call(url);
          if(serverBase64Image?.['base64']) {
            let savedResult = await this.save({url,imageBase64:serverBase64Image?.['base64']});
            if(savedResult?.imageBase64) {
              return 'data:image/png;base64,' + savedResult?.imageBase64;
            }
          }else {
            return null;
          }
        }
        return 'data:image/png;base64,' + dbImageBase64?.imageBase64;
      } else {
        return null;
      }
    } catch (error) {
      SentryUtilites.setLog("ImageBase64Service:getBase64FromDBorServer", error)
      return null;
    }
  }

  async getBase64FromDBorServerWithoutSave(image:Image) {
    try {
      if(image?._localUUID) {
        let url = `${environment.ezoOfServerImage}image/getbase64/${image?.userId}/${image?.profileId}/${image?._localUUID}`;
        let dbImageBase64 = await this.getByUrl(url);
        if(!dbImageBase64?.imageBase64) {
          let serverBase64Image = null;
            serverBase64Image = await this.expressServerService.makeImageBase64Call(url);
          if(serverBase64Image?.['base64']) {
            return 'data:image/png;base64,' + serverBase64Image?.['base64'];
          }else {
            return null;
          }
        }
        return 'data:image/png;base64,' + dbImageBase64?.imageBase64;
      } else {
        return null;
      }
    } catch (error) {
      SentryUtilites.setLog("ImageBase64Service:getBase64FromDBorServer", error)
      return null;
    }
  }

}
