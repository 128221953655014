import { InvoiceItem } from "../models/invoice-item.model";
import { InvoicePrint } from "../models/print.model";
import { commonTempData } from "./commonTempData";

export class Temp18{

    constructor(
      private data: InvoicePrint,
      ){
      }
    textFontSize=2.5;
    boldTextFontSize=2.6;
    headerFontSize=5;
    totalSaving = 0;
    
    finalTaxPercentageTableObj={};
    isTax=false;
    sameState = false;




    main(){
      this.finalTaxPercentageTableObj={};
      return `
      <style>
      ${commonTempData.invoiceHtmlTemplateCss()}
      .three-inch-holder *{
        margin:0;
        padding:0;
      }
      .three-inch-holder b{
        color:#000;
      }
      .three-inch-holder .text-sm{
        font-size:10px;
      }
      </style>
      <span class="invoice-template-main">
        <div 
          class="container-new arial three-inch-holder"
          style="width:70mm;background: white;padding: 2mm;padding-bottom: 70px;visibility: visible;-webkit-print-color-adjust: exact !important;color-adjust: exact !important;">
          ${this.header()}
          ${this.templateBody()}
          ${this.footer()}
        </div>
      </span>
      `
    }

    header(){
      return `
        ${this.sellerDetails()}
        ${this.invoiceTitle()}`;

    }

    footer(){
      let domain='';
      let includeDukanUrl=0;
      try{
        includeDukanUrl=this.data?.user?.profileData?.additionalData?.include3InchOnlineDukanLink
      }catch(err){

      }
      if(
         this.data?.user?.onlineDukanData?.domain!=""){
          domain=this.data?.user?.onlineDukanData?.domain;
        }
        
      return `<div style="text-align:center">
        <p style="font-size:${this.textFontSize}mm;text-align: justify;">${(this.data?.user?.profileData?.thermalTncTemplate || '').replace(/\n/g,'<br/>')}</p>
        ${domain?.length>0 && includeDukanUrl?`
          <p style="font-size:${this.textFontSize}mm">Now Order Online @</p>
          <p style="font-size:${this.textFontSize}mm">https://${domain}.ezo.digital</p>
        `:''}
        
      </div>
      <div class="row" style="margin-top:20px;"><hr/></div>`;
      
    }

    includeTaxPercentHeader=false;
    includeHsnHeader=false;
    includeMrpHeader=false;

    templateBody(){
      let html='';
      let itemRowsHtml='';
      
      let totalQty=0;
      let receivedOrPaidAmt = 0;
      let totalTax=0;
      let isTax = false;
      let totalTaxAmt =0;
      let sameState = false;

      let partyInfo = this.data?.partyInfo?.profileData||null;
  
      if( partyInfo && (partyInfo?.addressState == this.data?.user?.profileData?.addressState)){
        sameState = true;
      }
      else if((!partyInfo?.addressState || !(this.data?.user?.profileData?.addressState))){
        sameState = true;
      }
  
      if (this.data?.gstAmount || this.data?.cessAmount) {
        totalTaxAmt = this.data?.gstAmount + this.data?.cessAmount;
        isTax = true;
      }

      if(this.data?.moneyInInfo || this.data?.moneyOut){
        receivedOrPaidAmt = (this.data?.moneyInInfo?.amount || this.data?.moneyOut?.amount ||0);
      }

      if(this.data?.amountReceived) {
        receivedOrPaidAmt = this.data?.amountReceived;
      }

      let gstTableObj={};
      
      for(let i=0;i<this.data?.invoiceItems?.length;i++){
        let invoiceItem=this.data?.invoiceItems[i];
        if(invoiceItem?.taxPercentage){
          this.includeTaxPercentHeader=true;
        }
        if(invoiceItem?.mrp || invoiceItem?.item?.mrp){
          this.includeMrpHeader=true;
        }
        if(invoiceItem?.hsn){
          this.includeHsnHeader=true;
        }

      }

      for(let i=0;i<this.data?.invoiceItems?.length;i++){
        let invoiceItem=this.data?.invoiceItems[i];
        totalTax+=invoiceItem.totalTax ||0;
        totalQty+=invoiceItem.quantity ||0;
        let el = this.data?.invoiceItems[i] ;
        gstTableObj = this.calculationForHsnTable(el,gstTableObj);

        if((el?.totalTax || el?.cess) && !this.isTax){
          this.isTax = true;
        }

        this.finalTaxPercentageTableObj = this.addSameTaxPercentageObjs(gstTableObj);
        try{
          itemRowsHtml+=this.itemRow(i+1,invoiceItem);
        }catch(err){
          console.error(err);
        }
        if(el?.mrp&& el?.item?.mrp && el?.sellPrice && !this.data?.isPurchase){
          if(!el?.spIncTax){
            //Exclusive
            this.totalSaving += (((el?.mrp || el?.item?.mrp) - el?.sellPrice + (el?.discountFlat||0))*el?.quantity)-((el?.totalCess||0) + (el?.totalTax||0));
          } else {
            //Inclusive
            this.totalSaving += ((el?.mrp || el?.item?.mrp) - el?.sellPrice + (el?.discountFlat||0))*el?.quantity;
          }
        }else {
          if(el?.item && (!el?.mrp && !el?.item?.mrp) && el?.sellPrice && !this.data?.isPurchase) {
            if(el?.totalDiscount) {
              this.totalSaving += el?.totalDiscount;
            }
          }
        }
      }

      this.totalSaving = (this.totalSaving||0)+(this.data?.flatDiscount||0) ;
      let itemNameColLength=20+7+5+6;
      if(this.includeHsnHeader){itemNameColLength-=7};
      if(this.includeTaxPercentHeader){itemNameColLength-=5};
      if(this.includeMrpHeader){itemNameColLength-=6};

      let htmlHeader=`
      <style>
      #table-3inch td,
      #table-3inch th,
      #table-3inch td p,
      #table-gst-breakup th,
      #table-gst-breakup td
      {
        font-size:${this.textFontSize}mm;
        vertical-align: top;
      }
      #table-gst-breakup th,
      #table-gst-breakup td{
        text-align:center !important;
      }
      </style>
      <table id="table-3inch">
        <thead>
          <tr>
            <th style="width:5mm !important">Sr</th>
            ${this.includeHsnHeader?`<th style="width:7mm !important">HSN</th>`:''}
            <th style="width:${itemNameColLength}mm !important;text-align:left;">Particular</th>
            ${this.includeTaxPercentHeader?`<th style="width:5mm !important">GST</th>`:''}
            <th style="width:5mm !important">Qt</th>
            ${this.includeMrpHeader?`<th style="width:7mm !important">MRP</th>`:''}
            <th style="width:6mm !important">Rate</th>
            <th style="width:6mm !important">Amt</th>
          </tr>
        </thead>
        <tbody>
          ${itemRowsHtml}
        </tbody>
      </table>
      
       
      <div class="row">
        <hr/>
      </div>
      `;
      html=htmlHeader+html;
      let balanceAmt = ((this.data?.totalAmount||0) - receivedOrPaidAmt);
      let balHtml='';
      if(balanceAmt>0){
        balHtml=`<b style="font-size:${this.boldTextFontSize}mm">Bal :</b> <span style="font-size:${this.textFontSize}mm">${commonTempData.unitAdjuster(balanceAmt)}</span>`;
      }
      html+=`
        <div class="row">
          <hr/>
          <div class="col-xs-6" style="text-align:left">
            <b style="font-size:${this.boldTextFontSize}mm">Qty:</b> <span style="font-size:${this.textFontSize}mm">${totalQty}</span>
          </div>
          <div class="col-xs-6" style="text-align:right">
            <b style="font-size:${this.boldTextFontSize}mm">Net:</b> <span style="font-size:${this.textFontSize}mm">${this.data?.totalAmount}</span>
          </div>
          <div class="col-xs-9">
           
            <span style="font-size:${this.textFontSize}mm"> ${commonTempData.convertToIndianCurrency((this.data?.totalAmount || 0).toString())}</span>
          </div>
          <div class="col-xs-3" style="text-align:right">
            ${balHtml}
          </div>
        </div>
        <div class="row">
          <hr/>
          ${this.includeTaxPercentHeader?this.generateGSTTable():''}
        </div>
        <div class="row">
          <hr/>
          `
 
          
          html +=`
          
          <div class="col-xs-12">
          <hr/>
          </div>
          
          `
          
          let additionalAmount = this.data?.additionalAmount || 0;
          if(additionalAmount){
            html += this.commonTaxAndAmtDetails('Labour/Delivery Charges', additionalAmount);
          }
      
          if(this.data?.flatDiscountWithInvoiceDiscount){
            html+=`<div class="col-xs-12" style="text-align:right">
              <b style="font-size:${this.boldTextFontSize}mm">Cash Discount : </b>
              <span style="font-size:${this.textFontSize}mm">${commonTempData.unitAdjuster(this.data?.flatDiscountWithInvoiceDiscount)} (${this.data?.flatDiscountPercentage}%)</span>
            </div>`;
          }else if(this.data?.flatDiscount){
            html+=`<div class="col-xs-12" style="text-align:right">
              <b style="font-size:${this.boldTextFontSize}mm">Cash Discount : </b>
              <span style="font-size:${this.textFontSize}mm">${commonTempData.unitAdjuster(this.data?.flatDiscount)}</span>
            </div>`;
          }


        let receivedOrPaid = this.data?.isPurchase? 'Paid Amount': 'Received Amount';
        let modeOfPayment='';
        if(this.data?.isPurchase){
          modeOfPayment=this.data?.moneyOutInfo?.txnMode || '';
        }else{
          modeOfPayment=this.data?.moneyInInfo?.txnMode || '';
        }
        if(receivedOrPaidAmt){
          html+=`<div class="col-xs-12" style="text-align:right">
              <b style="font-size:${this.boldTextFontSize}mm">${receivedOrPaid} : </b>
              <span style="font-size:${this.textFontSize}mm">${commonTempData.unitAdjuster(receivedOrPaidAmt||0)}</span>
              <br/>
              ${modeOfPayment?` <b style="font-size:${this.boldTextFontSize}mm">Mode Of Payment : </b>
              <span style="font-size:${this.textFontSize}mm">${modeOfPayment}</span>`:''}
             
            </div>`;
        }
  
        if(this.totalSaving > 0){
          html+=`<div class="col-xs-12" style="text-align:center">
              <b style="font-size:${this.boldTextFontSize}mm">Total saving on this bill : </b>
              <span style="font-size:${this.textFontSize}mm">${commonTempData.unitAdjuster(this.totalSaving)}</span>
            </div>`;
        }

        html+=`
          
        </div>
        <div class="row">
          <hr/>
        </div>
      `
      
      return html;
    }

    commonTaxAndAmtDetails(title, value){

      return `
          <div class="col-xs-6"></div>
          <div class="col-xs-3" style="text-align:right;font-size:${this.boldTextFontSize}mm">${title}</div>
          <div class="col-xs-3" style="text-align:right;font-size:${this.textFontSize}mm">${value}</div>
      `
    }

    itemRow(index,invoiceItem:InvoiceItem){
      
      return `
        <tr>
            <td>${index}</td>
            ${this.includeHsnHeader?`<td>${(((invoiceItem?.hsn) ||'')+'').substring(0,4)}</td>`:''}
            <td style="text-align:left;">
              <p>${invoiceItem?.itemName}</p>
              <p style="word-wrap: break-word;">${(((invoiceItem?.itemDes && invoiceItem.itemDes !="0")?invoiceItem.itemDes:'')+'').substring(0,100)}</p>
              <p style="word-wrap: break-word;">${(invoiceItem?.itemSrl?'('+invoiceItem.itemSrl+')':'')}</p>
            </td>
            ${this.includeTaxPercentHeader?`<td>${invoiceItem?.taxPercentage?invoiceItem?.taxPercentage+"%":''}</td>`:''}
            <td>${invoiceItem?.quantity}</td>
            ${this.includeMrpHeader?`<td>${invoiceItem?.mrp || invoiceItem?.item?.mrp ||''}</td>`:''}
            <td>${invoiceItem?.sellPrice}</td>
            <td>${invoiceItem?.totalAmount}</td>
        </tr>
      
      `
    }

    generateGSTTable(){
      let gstTableHtml=`
      <h3 style="font-size:${this.boldTextFontSize+1}mm;margin-top:5mm;">GST Breakup</h3>
      ${this.hsnSacTable()}`
      return gstTableHtml;
    }

    sellerDetails(){
        let html = "";
        let sellerProfileData = this.data?.user?.profileData;
        html+=`<div style="text-align:center">
          <h3 style="font-size:${this.headerFontSize}mm;">${sellerProfileData?.legalName ||''}</h3>
          <p style="font-size:${this.textFontSize}mm">${sellerProfileData?.addressLine1 || ''}${sellerProfileData?.addressState?', '+sellerProfileData?.addressState:''}${sellerProfileData?.addressPincode?', '+sellerProfileData?.addressPincode:''}</p>
          <p style="font-size:${this.textFontSize}mm">${(this.data?.user?.profileData?.contactPersonPhone && this.data?.user?.profileData?.contactPersonPhone!='0')? 'Phone: '+this.data?.user?.profileData?.contactPersonPhone:''}</p>
          <p style="font-size:${this.textFontSize}mm">${(this.data?.user?.profileData?.contactPersonEmail)? 'Email: '+this.data?.user?.profileData?.contactPersonEmail:''}</p>
          <p style="font-size:${this.textFontSize}mm">${this.data?.user?.profileData?.gstNumber?'GST Number : '+this.data?.user?.profileData?.gstNumber:''}</p>
        </div>`

        return html;

    }

    invoiceTitle(){
      let party = this.data?.partyInfo?.profileData ||null;
      let html = `
        <div class="row">
          <hr/>
          <h4 style="font-size:${this.headerFontSize-1}mm"  class="text-center">${commonTempData.getInvoiceTitle(this.data) || ''}</h4>
          <hr/>
          <div class="col-xs-6">
           <b style="font-size:${this.boldTextFontSize}mm">Bill No :</b> <span style="font-size:${this.boldTextFontSize}mm">${this.data?.invoiceNo ||''}</span>
          </div>
          <div class="col-xs-6" style="text-align:right">
           <b style="font-size:${this.boldTextFontSize}mm">Date :</b> <span style="font-size:${this.boldTextFontSize}mm">${commonTempData.dateToDDMMYYY(this.data?.invoiceDateStamp)}</span>
          </div>
          <div class="col-xs-6">
           
          </div>
          <div class="col-xs-6" style="text-align:right">
            ${this.data?.dueDateStamp && this.data?.dueDateStamp>0 ? `<b style="font-size:${this.boldTextFontSize}mm">Due Date:</b> <span style="font-size:${this.boldTextFontSize}mm">${commonTempData.dateToDDMMYYY(this.data?.dueDateStamp)}</span>` : ''}
          </div>
        </div>
        <div class="row">
          <hr/>
          <div class="col-xs-12">
            <b style="font-size:${this.boldTextFontSize}mm">Bill To :</b> <span style="font-size:${this.textFontSize}mm">${party?.contactPersonName}</span>
          </div>
          <div class="col-xs-12">
            <b style="font-size:${this.boldTextFontSize}mm">Address :</b> <span style="font-size:${this.textFontSize}mm">${party?.addressLine1?party?.addressLine1:''} ${party?.addressState?', '+party?.addressState:''}${party?.addressPincode?', '+party?.addressPincode:''}</span>
          </div>
        </div>
        <div class="row">
          <hr/>
        </div>
      `;
      
      return html;
    }


    addSameTaxPercentageObjs(mainObj){
      let sanitizedObj = {};
      for (const key in mainObj) {
        let holder = {};
        mainObj[key].forEach(function(d) {
          if(d?.taxPercentage != 0){
            if (holder.hasOwnProperty(d?.taxPercentage)) {
              holder[d?.taxPercentage] = {
                "taxAmount":(holder[d?.taxPercentage].taxAmount + d?.taxAmount),
                "itemPrice":(holder[d?.taxPercentage].itemPrice+(d?.itemPrice * d?.quantity)-(d?.isIncTax?d?.taxAmount:0)-(d.discount || 0))
              };
            } else {
              holder[d?.taxPercentage] = {
                "taxAmount":d?.taxAmount,
                "itemPrice":(d?.itemPrice * d?.quantity) - (d?.isIncTax?d?.taxAmount:0)-(d.discount || 0)
              };
            }
          }
        }
        );
        let array = [];
        
        for (let prop in holder) {
          array.push({ taxPercentage: prop, taxAmount: holder[prop]?.taxAmount, taxableValue: holder[prop]?.itemPrice});
        }
        if(array.length){
          sanitizedObj[key] = array;
        }
      }
      
      return sanitizedObj;
    }

    commonHsnTableCode(el){
      let isIncTax = false;

      if(this.data?.isPurchase){
        if(el?.ppIncTax){
          isIncTax = true;
        }
      }else{
        if(el?.spIncTax){
          isIncTax = true;
        }
      }

      return {
        'taxAmount': el?.totalTax ||0,
        'itemPrice': this.data?.isPurchase? el?.purchasePrice: el?.sellPrice,
        'taxPercentage': el?.taxPercentage||0,
        'quantity': el?.quantity ||1,
        'isIncTax':isIncTax,
        'discount': el?.totalDiscount || 0
      }

    }

    calculationForHsnTable(el,gstTableObj){

      if(el?.taxPercentage){ 
          if(gstTableObj[el?.taxPercentage]){
            gstTableObj[el?.taxPercentage].push({
              ...this.commonHsnTableCode(el)
            })
          } else{
            gstTableObj[el?.taxPercentage] = [{
              ...this.commonHsnTableCode(el)
            }]
          }
      }

      return gstTableObj;

    }

    hsnSacTable(){
      if(!Object.keys(this.finalTaxPercentageTableObj).length){
        return '';
      }

      let partyEl = (this.data?.partyInfo?.profileData || null);

      if(partyEl && (partyEl?.addressState == (this.data?.user?.profileData?.addressState))){
        this.sameState = true;
       }
       else if((!partyEl?.addressState || !(this.data?.user?.profileData?.addressState))){
        this.sameState = true;
      }

      let html ='';
      html +=`
      <table id="table-gst-breakup" style="width: 100%; text-align: center;table-layout: fixed;">
      <thead class="no-b-btm" style="-webkit-print-color-adjust: exact !important;color-adjust: exact !important;">
        <tr>
        
        <td>Gross</td>
        <td>
          ${(!this.sameState&&this.isTax)?'IGST':'SGST'}
        </td>
        <td>
          ${(!this.sameState&&this.isTax)?'IGST Amt':'SGST Amt'}
        </td>`
        if(this.sameState&&this.isTax){
          html +=`<td>CGST</td><td>CGST Amt</td>`
        }

        html +=`
        </tr>
        <tr>`
          
      html +=`
      </thead>
      <tbody>`

      for (const key in this.finalTaxPercentageTableObj) {
        if(this.finalTaxPercentageTableObj[key]){
          html +=this.gstSacRow(key,this.finalTaxPercentageTableObj[key]);
        }
      }

      html +=`
      </tbody>
      </table>
      `

      return html;
    }
    


    gstSacRow(taxPercentage,gstData){
      let html ='';
      for (let i = 0; i < gstData.length; i++) {
        const element = gstData[i];
        html +=`
          <tr>
          
          <td>
            ${commonTempData.unitAdjuster(element?.taxableValue || 0)}</td>
          <td>
          ${(!this.sameState&&this.isTax)?(+element?.taxPercentage)?.toFixed(2):(+element?.taxPercentage/2)?.toFixed(2)}%
          </td>
          <td>
          ${(!this.sameState&&this.isTax)?(element?.taxAmount)?.toFixed(2):(element?.taxAmount/2)?.toFixed(2)}</td>
          `
          if(this.sameState&&this.isTax){
            html+=`<td>
            ${(+element?.taxPercentage/2)?.toFixed(2)}%
            </td>
            <td>
            ${(element?.taxAmount/2)?.toFixed(2)}</td>
            `
          }
          html+=`
            </tr>
            `       
      }
    
      return html;
    }

}

