import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AccessControlService } from 'src/app/services/auth/access-control.service';
import { PinVerificationComponent } from '../../pin-verification/pin-verification.component';
import { Expense } from 'src/app/models/Expense.model';
import { AllDataService } from 'src/app/services/all-data.service';
import { MoneyOut } from '../../../models/MoneyOut.model'
import { Utils } from '../../../utils/utils';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-expense-row',
  templateUrl: './expense-row.component.html',
  styleUrls: ['./expense-row.component.scss'],
})
export class ExpenseRowComponent implements OnInit {

  @Input() expense: Expense = null;
  @Input() isRecentTransactionList: boolean = false;

  @Output() onDeleteClick = new EventEmitter<Expense>();
  @Output() onAddPaymentClick = new EventEmitter<Expense>();

  @ViewChild('pinVerificationElement') pinVerificationElement: PinVerificationComponent;

  capFractionsToTwo = Utils.capFractionsToTwo;

  constructor(
    private router: Router,
    private accessControlService: AccessControlService,
    private allDataService: AllDataService,
  ) { }

  ngOnInit() { }

  openTransactionPINModal() {
    this.pinVerificationElement?.openTransactionPINModal();
  }

  async billView(expense: Expense) {
    try {
      if(!expense?.deletedStamp) {
        let isPermit = await this.accessControlService.isUserHasAccess({action:'viewExpense'});
        if(!isPermit) {
          return alert("Permission: You don't have permission to view bill. Please contact to your owner.");
        }
        this.router.navigate([`bill-view-expense/${expense?._localUUID}`]);
      }
    } catch (error) {
      SentryUtilites.setLog("ExpenseRowComponent:billView", error)
      alert("Something went wrong.");
    }
  }

  verifyTransactionPIN(event) {
    try {
      if(event) {
        this.router.navigate([`expense/form/${this.expense?._localUUID}`]);
      }
    } catch (error) {
      SentryUtilites.setLog("ExpenseRowComponent:verifyTransactionPIN", error)
    }
  }

  async edit(expense: Expense) {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({action:'editExpense'});
      if(!isPermit) {
        return alert("Permission: You don't have permission to edit expense. Please contact to your owner.");
      }
      this.openTransactionPINModal();
    } catch (error) {
      SentryUtilites.setLog("ExpenseRowComponent:edit", error)
      alert("Something went wrong.");
    }
  }

  getBalanceAmount(moneyOuts: MoneyOut[], totalAmount: number) {
    try {
      if(!moneyOuts || !moneyOuts?.length) {
        return this.capFractionsToTwo(totalAmount);
      }
      if (moneyOuts?.length) {
        let receivedAmount: number = 0;
        moneyOuts?.forEach(moneyOut => receivedAmount += Number(moneyOut?.totalAmount));
        let pendingAmount = totalAmount - receivedAmount;
        pendingAmount = pendingAmount < 0 ? 0 : pendingAmount;
        return this.capFractionsToTwo(pendingAmount);
      }
    } catch (error) {
      SentryUtilites.setLog("ExpenseRowComponent:getBalanceAmount", error)
      return 0;
    }
  }

  delete(expense: Expense) {
    this.onDeleteClick.emit(expense);
  }

  async addPayment(expense: Expense) {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({action:'createMoneyOut'});
      if(!isPermit) {
        return alert("Permission: You don't have permission to create money out. Please contact to your owner.");
      }
      this.onAddPaymentClick.emit(expense);
    } catch (error) {
      SentryUtilites.setLog("ExpenseRowComponent:addPayment", error)
      alert("Something went wrong.");
    }
  }

  async setPaymentMode(paymentMode: 'cash' | 'cheque' | 'bank') {
    try {
      if(this.expense?.moneyOuts?.length) {
        this.expense.moneyOuts[0].paymentMode = paymentMode;
        this.expense = await this.allDataService.expenseService.update(this.expense);
      }
    } catch (error) {
      SentryUtilites.setLog("ExpenseRowComponent:setPaymentMode", error)
    }
  }

}
