import { format, utcToZonedTime } from 'date-fns-tz';
import { v4 as uuidv4 } from 'uuid';
import { Validator } from './validator';
import PackageJson from '../../../package.json';
import * as platform from 'platform';
import { Profile } from '../models/Profile.model';
import { SentryUtilites } from './sentryUtilites';


export class Utility {

  static statesNamesByStateCode = {
    "01":"01-JAMMU AND KASHMIR",
    "02":"02-HIMACHAL PRADESH",
    "03":"03-PUNJAB",
    "04":"04-CHANDIGARH",
    "05":"05-UTTARAKHAND",
    "06":"06-HARYANA",
    "07":"07-DELHI",
    "08":"08-RAJASTHAN",
    "09":"09-UTTAR PRADESH",
    "10":"10-BIHAR",
    "11":"11-SIKKIM",
    "12":"12-ARUNACHAL PRADESH",
    "13":"13-NAGALAND",
    "14":"14-MANIPUR",
    "15":"15-MIZORAM",
    "16":"16-TRIPURA",
    "17":"17-MEGHALAYA",
    "18":"18-ASSAM",
    "19":"19-WEST BENGAL",
    "20":"20-JHARKHAND",
    "21":"21-ODISHA",
    "22":"22-CHHATTISGARH",
    "23":"23-MADHYA PRADESH",
    "24":"24-GUJARAT",
    "25":"25-DAMAN AND DIU",
    "26":"26-DADRA AND NAGAR HAVELI",
    "27":"27-MAHARASHTRA",
    "29":"29-Karnataka",
    "30":"30-GOA",
    "31":"31-LAKSHADWEEP",
    "32":"32-KERALA",
    "33":"33-TAMIL NADU",
    "34":"34-PUDUCHERRY",
    "35":"35-ANDAMAN AND NICOBAR",
    "36":"36-TELANGANA",
    "37":"37-ANDHRA PRADESH",
    "38":"38-LADAKH",
    "97":"97-OTHER TERRITORY",
    "96":"96-OTHER COUNTRY"
  }

  static states = {
    ['ANDAMAN AND NICOBAR']: 'ANDAMAN AND NICOBAR',
    ['ANDHRA PRADESH BEFORE DIVISION']: 'ANDHRA PRADESH BEFORE DIVISION',
    ['ANDHRA PRADESH NEW']: 'ANDHRA PRADESH NEW',
    ['ANDHRA PRADESH']: 'ANDHRA PRADESH',
    ['ARUNACHAL PRADESH']: 'ARUNACHAL PRADESH',
    ['ASSAM']: 'ASSAM',
    ['BIHAR']: 'BIHAR',
    ['CHANDIGARH']: 'CHANDIGARH',
    ['CHHATTISGARH']: 'CHHATTISGARH',
    ['DADRA AND NAGAR HAVELI']: 'DADRA AND NAGAR HAVELI',
    ['DAMAN AND DIU']: 'DAMAN AND DIU',
    ['DELHI']: 'DELHI',
    ['GOA']: 'GOA',
    ['GUJARAT']: 'GUJARAT',
    ['HARYANA']: 'HARYANA',
    ['HIMACHAL PRADESH']: 'HIMACHAL PRADESH',
    ['JAMMU AND KASHMIR']: 'JAMMU AND KASHMIR',
    ['JHARKHAND']: 'JHARKHAND',
    ['KARNATAKA']: 'KARNATAKA',
    ['KERALA']: 'KERALA',
    ['LADAKH']: 'LADAKH',
    ['LAKSHADWEEP']: 'LAKSHADWEEP',
    ['MADHYA PRADESH']: 'MADHYA PRADESH',
    ['MAHARASHTRA']: 'MAHARASHTRA',
    ['MANIPUR']: 'MANIPUR',
    ['MEGHALAYA']: 'MEGHALAYA',
    ['MIZORAM']: 'MIZORAM',
    ['NAGALAND']: 'NAGALAND',
    ['ODISHA']: 'ODISHA',
    ['OUT OF INDIA Exempt']: 'OUT OF INDIA Exempt',
    ['OUT OF INDIA Taxable']: 'OUT OF INDIA Taxable',
    ['PUDUCHERRY']: 'PUDUCHERRY',
    ['PUNJAB']: 'PUNJAB',
    ['RAJASTHAN']: 'RAJASTHAN',
    ['SALE SEZ Exempt']: 'SALE SEZ Exempt',
    ['SALE SEZ Taxable']: 'SALE SEZ Taxable',
    ['SIKKIM']: 'SIKKIM',
    ['TAMIL NADU']: 'TAMIL NADU',
    ['TELANGANA']: 'TELANGANA',
    ['TRIPURA']: 'TRIPURA',
    ['UTTARAKHAND']: 'UTTARAKHAND',
    ['UTTAR PRADESH']: 'UTTAR PRADESH',
    ['WEST BENGAL']: 'WEST BENGAL',
  };

  static statesWithCodeByStateName = {
    ['ANDAMAN AND NICOBAR']: '35-ANDAMAN AND NICOBAR',
    ['ANDHRA PRADESH BEFORE DIVISION']: '37-ANDHRA PRADESH BEFORE DIVISION',
    ['ANDHRA PRADESH NEW']: '37-ANDHRA PRADESH NEW',
    ['ANDHRA PRADESH']: '37-ANDHRA PRADESH',
    ['ARUNACHAL PRADESH']: '12-ARUNACHAL PRADESH',
    ['ASSAM']: '18-ASSAM',
    ['BIHAR']: '10-BIHAR',
    ['CHANDIGARH']: '04-CHANDIGARH',
    ['CHHATTISGARH']: '22-CHHATTISGARH',
    ['DADRA AND NAGAR HAVELI']: '26-DADRA AND NAGAR HAVELI',
    ['DAMAN AND DIU']: '25-DAMAN AND DIU',
    ['DELHI']: '07-DELHI',
    ['GOA']: '30-GOA',
    ['GUJARAT']: '24-GUJARAT',
    ['HARYANA']: '06-HARYANA',
    ['HIMACHAL PRADESH']: '02-HIMACHAL PRADESH',
    ['JAMMU AND KASHMIR']: '01-JAMMU AND KASHMIR',
    ['JHARKHAND']: '20-JHARKHAND',
    ['KARNATAKA']: '29-KARNATAKA',
    ['KERALA']: '32-KERALA',
    ['LADAKH']: '38-LADAKH',
    ['LAKSHADWEEP']: '31-LAKSHADWEEP',
    ['MADHYA PRADESH']: '23-MADHYA PRADESH',
    ['MAHARASHTRA']: '27-MAHARASHTRA',
    ['MANIPUR']: '14-MANIPUR',
    ['MEGHALAYA']: '17-MEGHALAYA',
    ['MIZORAM']: '15-MIZORAM',
    ['NAGALAND']: '13-NAGALAND',
    ['ODISHA']: '21-ODISHA',
    ['OUT OF INDIA Exempt']: 'OUT OF INDIA Exempt',
    ['OUT OF INDIA Taxable']: 'OUT OF INDIA Taxable',
    ['PUDUCHERRY']: '34-PUDUCHERRY',
    ['PUNJAB']: '03-PUNJAB',
    ['RAJASTHAN']: '08-RAJASTHAN',
    ['SALE SEZ Exempt']: 'SALE SEZ Exempt',
    ['SALE SEZ Taxable']: 'SALE SEZ Taxable',
    ['SIKKIM']: '11-SIKKIM',
    ['TAMIL NADU']: '33-TAMIL NADU',
    ['TELANGANA']: '36-TELANGANA',
    ['TRIPURA']: '16-TRIPURA',
    ['UTTARAKHAND']: '05-UTTARAKHAND',
    ['UTTAR PRADESH']: '09-UTTAR PRADESH',
    ['WEST BENGAL']: '19-WEST BENGAL',
  };

  static unitAbvrMap = {
    'PCS': 'Pieces',
    'BOX': 'Box',
    'KGS': 'Kilograms',
    'LTR': 'Litre',
    'UNT': 'Units',
    'BAG': 'Bags',
    'BAL': 'BALE',
    'BOU': 'Biliion of unit',
    'BTL': 'Bottles',
    'BKL': 'Buckles',
    'BUN': 'Bunches',
    'BDL': 'Bundels',
    'CAN': 'Cans',
    'CTN': 'Cartons',
    'CMS': 'Centimeters',
    'CCM': 'Cubic Centimeters',
    'CBM': 'Cubic Meters',
    'DAY': 'Days',
    'DOZ': 'Dozens',
    'DRM': 'Drums',
    'GMS': 'Grammes',
    'GGK': 'Great Gross',
    'GYD': 'Gross Yards',
    'KLR': 'Kilolitre',
    'KME': 'Kilometer',
    'MTR': 'Meters',
    'MTS': 'Metric Ton',
    'MGS': 'MilliGram',
    'MLT': 'Millilitre',
    'NOS': 'Numbers',
    'OTH': 'Others',
    'PAC': 'Packs',
    'PRS': 'Pairs',
    'QTL': 'Quintal',
    'ROL': 'Rolls',
    'SET': 'Sets',
    'SQF': 'Sq. Feet',
    'SQY': 'Sq. Yards',
    'SQM': 'Sq. Meters',
    'TBS': 'tablets',
    'TGM': 'Ten gross',
    'THD': 'Thousand',
    'TON': 'Tonnes',
    'TUB': 'Tubes',
    'UGS': 'US Gallons',
    'YDS': 'Yards',
  };

  static uqcTable = {
    "BAG": "BAG-BAGS",
    "BAL": "BAL-BALE",
    "BDL": "BDL-BUNDLES",
    "BKL": "BKL-BUCKLES",
    "BOU": "BOU-BILLION OF UNITS",
    "BOX": "BOX-BOX",
    "BTL": "BTL-BOTTLES",
    "BUN": "BUN-BUNCHES",
    "CAN": "CAN-CANS",
    "CCM": "CCM-CUBIC CENTIMETERS",
    "CMS": "CMS-CENTIMETERS",
    "CBM": "CBM-CUBIC METERS",
    "CTN": "CTN-CARTONS",
    "DOZ": "DOZ-DOZENS",
    "DRM": "DRM-DRUMS",
    "GGK": "GGK-GREAT GROSS",
    "GMS": "GMS-GRAMMES",
    "GRS": "GRS-GROSS",
    "GYD": "GYD-GROSS YARDS",
    "KGS": "KGS-KILOGRAMS",
    "KLR": "KLR-KILOLITRE",
    "KME": "KME-KILOMETRE",
    "LTR": "LTR-LITRES",
    "MLS": "MLS-MILLI LITRES",
    "MLT": "MLT-MILILITRE",
    "MTR": "MTR-METERS",
    "MTS": "MTS-METRIC TON",
    "NOS": "NOS-NUMBERS",
    "OTH": "OTH-OTHERS",
    "PAC": "PAC-PACKS",
    "PCS": "PCS-PIECES",
    "PRS": "PRS-PAIRS",
    "QTL": "QTL-QUINTAL",
    "ROL": "ROL-ROLLS",
    "SET": "SET-SETS",
    "SQF": "SQF-SQUARE FEET",
    "SQM": "SQM-SQUARE METERS",
    "SQY": "SQY-SQUARE YARDS",
    "TBS": "TBS-TABLETS",
    "TGM": "TGM-TEN GROSS",
    "THD": "THD-THOUSANDS",
    "TON": "TON-TONNES",
    "TUB": "TUB-TUBES",
    "UGS": "UGS-US GALLONS",
    "UNT": "UNT-UNITS",
  }

  static taxTypeOptions = {
    "Non-GST Supplies": 0,
    "Exempted": 0,
    "0%": 0,
    "0.25%": 0.25,
    "1%": 1,
    "3%": 3,
    "5%": 5,
    "12%": 12,
    "18%": 18,
    "28%": 28,
  };

  static nameSet = {
    firstname: [
      'Aarav',
      'Vihaan',
      'Vivaan',
      'Ananya',
      'Diya',
      'Advik',
      'Kabir',
      'Anaya',
      'Aarav',
      'Vivaan',
      'Aditya',
      'Vivaan',
      'Vihaan',
      'Arjun',
      'Vivaan',
      'Reyansh',
      'Mohammed',
      'Sai',
      'Arnav',
      'Aayan',
      'Krishna',
      'Ishaan',
      'Shaurya',
      'Atharva',
      'Advik',
      'Pranav',
      'Advaith',
      'Aaryan',
      'Dhruv',
      'Kabir',
      'Ritvik',
      'Aarush',
      'Kian',
      'Darsh',
      'Veer',
      'Saanvi',
      'Anya',
      'Aadhya',
      'Aaradhya',
      'Ananya',
      'Pari',
      'Anika',
      'Navya',
      'Angel',
      'Diya',
      'Myra',
      'Sara',
      'Iraa',
      'Ahana',
      'Anvi',
      'Prisha',
      'Riya',
      'Aarohi',
      'Anaya',
      'Akshara',
      'Eva',
      'Shanaya',
      'Kyra',
      'Siya'
    ],
    surnames: [
      'Bedi',
      'Gandhi',
      'Parekh',
      'Kohli',
      'Ahluwalia',
      'Chandra',
      'Jha',
      'Khanna',
      'Bajwa',
      'Chawla',
      'Lal',
      'Anand',
      'Gill',
      'Chakrabarti',
      'Dubey',
      'Kapoor',
      'Khurana',
      'Modi',
      'Kulkarni',
      'Khatri',
      'Kaur',
      'Dhillon',
      'Kumar',
      'Gupta',
      'Naidu',
      'Das',
      'Jain',
      'Chowdhury',
      'Dalal',
      'Thakur',
      'Gokhale',
      'Apte',
      'Sachdev',
      'Mehta',
      'Ganguly',
      'Bhasin',
      'Mannan',
      'Ahuja',
      'Singh',
      'Bakshi',
      'Basu',
      'Ray',
      'Mani',
      'Datta',
      'Balakrishna',
      'Biswas',
      'Laghari',
      'Malhotra',
      'Dewan',
      'Purohit'
    ]
  };

  static industryList = [
    'Food/Restaurant/Hotel',
    'Bakery/Cake Shop',
    'Kirana/Super Mart',
    'Clothing Shop',
    'FMCG/Distributor',
    'Mobile/Computer',
    'Arts and Design',
    'Automobile',
    'Agriculture',
    'Carpentry',
    'Construction',
    'Dairy',
    'Doctor/Clinic/Hospital',
    'Electronics',
    'Engineering',
    'Fruits and Vegetable',
    'General Store',
    'Gift Shop',
    'Hotel',
    'Ice Cream Parlour',
    'Interior Designer or Architect',
    'IT Services',
    'Jewellery',
    'Kirana Mart',
    'laundry',
    'Meat',
    'Medicine (Pharma)',
    'NGO Charity',
    'Others',
    'Photography',
    'Plumbing',
    'Printing',
    'Salon/Beauty Parlour',
    'School/Coaching',
    'Security Services',
    'Service',
    'Staffing',
    'Stationary',
    'Sweet Mart',
    'Tailoring/Boutique',
    'Textile',
    'Tiffin Service',
    'Tours & Travel',
    'Transportation',
  ];

  static dueNetObj = {
    net0: {
      title: 'Net 0',
      days: 0
    },
    net1: {
      title: 'Net 1',
      days: 1
    },
    net7: {
      title: 'Net 7',
      days: 6
    },
    net15: {
      title: 'Net 15',
      days: 14
    },
    net30: {
      title: 'Net 30',
      days: 29
    },
    net90: {
      title: 'Net 90',
      days: 89
    }
  };

  /**
   * 
   * @param value : provide value to check truthness
   * @returns : provided value is true or false
   */
  static isTruthy(value: any) {
    try {
      if(
        !value ||
        value === 'undefined' ||
        value === 'null' ||
        value === '""' ||
        value === "''" ||
        value === 'NaN' ||
        value === 'false' ||
        value === '0n' ||
        typeof value == 'string' && value?.trim() === ''
        ) {
          return false;
        } else {
          return true;
        }
    } catch (error) {
      SentryUtilites.setLog("Utility:isTruthy", error);
      return false;
    }
  }
  // -------------------------------

  /**
   * 
   * @param profile : provide profile object
   * @returns : set default value if key is not exist in profile
   */
  static settingDefaultValue(profile: Profile) {
    try {
      if(profile?._localUUID) {
        if(!profile?.hasOwnProperty('iSetItemSelectorStyleWeb')) {
          profile.iSetItemSelectorStyleWeb = 'RestaurantImage';
        }
        if(!profile?.hasOwnProperty('pSetTermsAndConditions')) {
          profile.pSetTermsAndConditions = 'Thank You! Visit Again!';
        }
        if(!profile?.hasOwnProperty('pSetPoweredByEzoStatus')) {
          profile.pSetPoweredByEzoStatus = true;
        }
        if(!profile?.hasOwnProperty('discountSoundStatus')) {
          profile.discountSoundStatus = true;
        }
        if(!profile?.hasOwnProperty('dSetDiscountStatusI')) {
          profile.dSetDiscountStatusI = true;
        }
        if(!profile?.hasOwnProperty('dSetDiscountNameI')) {
          profile.dSetDiscountNameI = 'Basic Discount';
        }
        if(!profile?.hasOwnProperty('dSetDiscountPercentI')) {
          profile.dSetDiscountPercentI = 2;
        }
        if(!profile?.hasOwnProperty('dSetDiscountMaximumAmountI')) {
          profile.dSetDiscountMaximumAmountI = 20;
        }
        if(!profile?.hasOwnProperty('dSetDiscountMinimumAmountI')) {
          profile.dSetDiscountMinimumAmountI = 1;
        }
        if(!profile?.hasOwnProperty('dSetDiscountExpiryDaysI')) {
          profile.dSetDiscountExpiryDaysI = 365;
        }
        if(!profile?.hasOwnProperty('dSetDiscountOfferTypeI')) {
          profile.dSetDiscountOfferTypeI = 1;
        }
        if(!profile?.hasOwnProperty('dSetDiscountOfferTypeII')) {
          profile.dSetDiscountOfferTypeII = 1;
        }
        if(!profile?.hasOwnProperty('isStartsWithSearchPrioritized')) {
          profile.isStartsWithSearchPrioritized = true;
        }
        return profile;
      } else {
        return null;
      }
    } catch (error) {
      SentryUtilites.setLog("Utility:settingDefaultValue", error)
      return null;
    }
  }
  // -----------------------------------------------------

  static getCollectionSyncStamp(collectionName: string, profileId?: string) {
    let stamp = 0;
    try {
      stamp = Number(this.getFromLocalStorage(`_sync_stamp_${profileId || ''}${collectionName}`)) || 0;
    } catch (err) {
      SentryUtilites.setLog("Utility:getCollectionSyncStamp", err)
    }
    return stamp;
  }

  static setCollectionSyncStamp(collectionName: string, syncStamp: number, profileId?: string) {
    try {
      this.setToLocalStorage(`_sync_stamp_${profileId || ''}${collectionName}`, Number(syncStamp))
    } catch (err) {
      SentryUtilites.setLog("Utility:setCollectionSyncStamp", err)
    }
  }

  static getCollectionLastRespSyncStamp(collectionName: string, profileId?: string) {
    let stamp = 0;
    try {
      stamp = Number(this.getFromLocalStorage(`_last_resp_sync_stamp_${profileId || ''}${collectionName}`)) || 0;
    } catch (err) {
      SentryUtilites.setLog("Utility:getCollectionLastRespSyncStamp", err)
     }
    return stamp;
  }

  static getCollectionLastRespSyncTime(collectionName: string, profileId?: string) {
    let str = null;
    try {
      let stamp = this.getCollectionLastRespSyncStamp(collectionName, profileId);
      str = `${new Date(stamp)}`;
      str = str?.substring(0, str.length-31);
    } catch (err) {
      SentryUtilites.setLog("Utility:getCollectionLastRespSyncTime", err)
     }
    return str;
  }

  static setCollectionLastRespSyncStamp(collectionName: string, syncStamp: number, profileId?: string) {
    try {
      this.setToLocalStorage(`_last_resp_sync_stamp_${profileId || ''}dashboard`, Number(syncStamp))
      this.setToLocalStorage(`_last_resp_sync_stamp_${profileId || ''}${collectionName}`, Number(syncStamp))
    } catch (err) {
      SentryUtilites.setLog("Utility:setCollectionLastRespSyncStamp", err)
     }
  }

  /**
   * 
   * @returns : if localStorage exists in window it will give response in boolean
   */
  static isLocalStorageExists() {
    try {
      if(window?.localStorage) {
        return true;
      }
    } catch (error) {
      return false;
    }
  }
  // ---------------------------------

  static setToLocalStorage(key: string, value: any) {
    if(this.isLocalStorageExists()) {
      localStorage.setItem(key, JSON.stringify(value || null));
    } else {
      alert(`Please don't select this option "Don't allow sites to save data on your device (not recommended)" from Browser Setting`);
      window?.location?.reload();
    }
  }

  static getFromLocalStorage(key: string) {
    try {
      if(this.isLocalStorageExists()) {
        const data = localStorage.getItem(key);
        if (
          !(!data ||
          data === 'undefined' ||
          data === 'null' ||
          data === '""' ||
          data === "''" ||
          data === 'NaN' ||
          data === 'false' ||
          data === '0n' ||
          typeof data == 'string' && data?.trim() === '') || 
          data === '0' || 
          data === 'false'
          ) {
          return JSON.parse(data || null);
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (error) {
      SentryUtilites.setLog("Utility:getFromLocalStorage", error)
      return null
    }
  }

  static removeFromLocalStorage(key: string) {
    if(this.isLocalStorageExists()) {
      localStorage.removeItem(key);
    }
  }

  static generateRandomString(length: number = 10) {
    try {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      return result;
    } catch (error) {
      SentryUtilites.setLog("Utility:generateRandomString", error)
      return '';
    }
  }

  static generateName() {
    try {
      const fname = this.nameSet?.firstname[Math.floor(Math.random() * this.nameSet?.firstname?.length)];
      const lname = this.nameSet?.surnames[Math.floor(Math.random() * this.nameSet?.surnames?.length)];
      return `${fname} ${lname}`;
    } catch (error) {
      SentryUtilites.setLog("Utility:generateName", error)
      return '';
    }
  }

  static randomIntFromInterval(min, max) { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  static generateRandomPhone(length: number = 9) {
    try {
      let result = '';
      const characters = '0123456789';
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      return this.generateRandomPhoneFirstDigit() + result;
    } catch (error) {
      SentryUtilites.setLog("Utility:generateRandomPhone", error)
      return '';
    }
  }

  static generateRandomPhoneFirstDigit(length: number = 1) {
    try {
      let result = '';
      const characters = '123456789';
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      return result;
    } catch (error) {
      SentryUtilites.setLog("Utility:generateRandomPhoneFirstDigit", error)
      return '';
    }
  }

  static nextNo(billNo: string) {
    try {
      if (!billNo || typeof billNo != 'string') {
        return;
      }
  
      const regex = new RegExp(/\d+/g);
      const m = billNo?.match(regex);
      const lastNumber = m ? m?.pop() : 0;
      let prefix = '';
      let prefLastChar = '';
  
      const parsedNumber = Math.floor(+lastNumber);
      const parsedNumberStr = parsedNumber + '';
      const x = parsedNumber + 1;
  
      prefix = billNo?.substring(0, billNo?.lastIndexOf(parsedNumberStr));
  
      const prevNoLength = (x - 1 + '').length;
      const currentNoLength = (x + '').length;
      if (currentNoLength > prevNoLength) {
        if (prefix?.length > 0) {
          prefLastChar = prefix?.substring(prefix?.length - 1);
          if (prefLastChar === '0') {
            prefix = prefix?.substring(0, prefix?.length - 1);
          }
        }
      }
      return prefix + x;
    } catch (error) {
      SentryUtilites.setLog("Utility:nextNo", error)
      return '';
    }
  }

  static getTimestampByDays(days: number, providedDateStamp?: number) {
    try {
      const date = providedDateStamp ? new Date(providedDateStamp) : new Date();
      return +new Date(date.setDate(date.getDate() + days)).setHours(0, 0, 0, 0) + 8.64e+7;
    } catch (error) {
      SentryUtilites.setLog("Utility:getTimestampByDays", error)
      return 0;
    }
  }

  static ionDatePickerFormattedString(timestamp: number) {
    try {
      // Get the time zone set on the user's device
      const userTimeZone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
  
      // Create a date object from a UTC date string
      const date = new Date(timestamp);

      if(date+'' != 'Invalid Date') {
        // Use date-fns-tz to convert from UTC to a zoned time
        const zonedTime = utcToZonedTime(date, userTimeZone);
        
        // Create a formatted string from the zoned time
        return format(zonedTime, 'yyyy-MM-dd HH:mm:ssXXX', { timeZone: userTimeZone }).replace(' ', 'T');
      } else {
        return '';
      }
    } catch (error) {
      SentryUtilites.setLog("Utility:ionDatePickerFormattedString", error)
      return '';
    }
  }

  /**
   * 
   * @param timestamp : provide time stamp
   * @returns : return date in format dd MMM yyyy (e.g. 01 Apr 2024)
   */
  static setDateValue(timestamp : number) {
    try {
      // Get the time zone set on the user's device
      const userTimeZone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
  
      // Create a date object from a UTC date string
      const date = new Date(timestamp);
  
      if(date+'' != 'Invalid Date') {
        // Use date-fns-tz to convert from UTC to a zoned time
        const zonedTime = utcToZonedTime(date, userTimeZone);
        
        // Create a formatted string from the zoned time
        return format(zonedTime, 'dd MMM yyyy', { timeZone: userTimeZone });
      } else {
        return '';
      }
    } catch (error) {
      SentryUtilites.setLog("Utility:setDateValue", error)
      return '';
    }
  }
  // ----------------------------------------

  /**
   * 
   * @param timestamp : provide time stamp
   * @returns : return date in format dd MMM (e.g. 01 Apr)
   */
  static showDayMonthValue(timestamp : number) {
    try {
      // Get the time zone set on the user's device
      const userTimeZone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
  
      // Create a date object from a UTC date string
      const date = new Date(timestamp);
  
      if(date+'' != 'Invalid Date') {
        // Use date-fns-tz to convert from UTC to a zoned time
        const zonedTime = utcToZonedTime(date, userTimeZone);
        
        // Create a formatted string from the zoned time
        return format(zonedTime, 'dd MMM', { timeZone: userTimeZone });
      } else {
        return '';
      }
    } catch (error) {
      SentryUtilites.setLog("Utility:setDateValue", error)
      return '';
    }
  }
  // ----------------------------------------

  static closeDatePicker(target: HTMLIonDatetimeElement) {
    target.confirm(true);
  }

  /**
   * @description : close ion select options alert
   */
  static closeIonSelectAlert() {
    try {
      let backDrop = document.querySelector('ion-backdrop');
      if (backDrop) {
        backDrop?.click();
      }
    } catch (error) {
      SentryUtilites.setLog("Utility:closeIonSelectAlert", error)
    }
  }
  // ----------------------------------------

  /**
   * 
   * @param str : provide string value
   * @returns : return first letter capital case of provided string
   */
  static firstLetterCapitalOfWord(str: string) {
    try {
      if (!str) return str;
      return (str + "")
        ?.toLowerCase()
        ?.split(" ")
        ?.map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        ?.join(" ");
    } catch (error) {
      SentryUtilites.setLog("Utility:firstLetterCapitalOfWord", error)
      return '';
    }
  }
  // -------------------------------------

  /**
   * 
   * @param str : provide string value
   * @returns : return first letter lower case of provided string
   */
  static firstLetterLowerCaseOfWord(str: string) {
    try {
      if (!str) return str;
      return str.charAt(0).toLowerCase() + str.slice(1);
    } catch (error) {
      SentryUtilites.setLog("Utility:firstLetterLowerCaseOfWord", error)
      return '';
    }
  }
  // -------------------------------------

  static newLineToBr(inputString: string) {
    return (inputString?.length > 0) ? inputString?.replace(/\n/g, '<br/>') : "";
  }

  static toLowerCase(str: string) {
    try {
      let lstr = (str + '')?.toLowerCase();
      if (lstr == 'undefined') {
        return '';
      } else if (lstr == 'null') {
        return '';
      } else if (lstr == '[object Object]') {
        return '';
      }
      return lstr;
    } catch (error) {
      SentryUtilites.setLog("Utility:toLowerCase", error)
      return '';
    }
  }

  static wait(milliseconds: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        setTimeout(() => {
          return resolve(true);
        }, milliseconds);
      } catch (error) {
        SentryUtilites.setLog("Utility:wait", error)
        return resolve(false);
      }
    });
  }

  static getUUID(): string {
    return uuidv4();
  }

  static isNullOrUndefinedOrEmpty(value: any): boolean {
    try {
      if (value === null || value === undefined) {
        return true;
      } else if (typeof value === "string") {
        return value?.trim() === "";
      }
    } catch (error) {
      SentryUtilites.setLog("Utility:isNullOrUndefinedOrEmpty", error)
      return false;
    }
  }

  static getDateTimeString(date: Date | string): string {
    try {
      if (this.isNullOrUndefinedOrEmpty(date)) {
        return null;
      }
      if (typeof date === "string") {
        return date;
      }
      return (
        date.getMonth() +
        1 +
        "/" +
        date.getDate() +
        "/" +
        date.getFullYear()
        + " "
        + date.getHours()
        + ':'
        + date.getMinutes()
        + ':'
        + date.getSeconds()
  
      );
    } catch (error) {
      SentryUtilites.setLog("Utility:getDateTimeString", error)
      return '';
    }
  }

  static getDateTime(timeStamp: number): string {
    try {
      let date = new Date(timeStamp);
      return (
        date.getMonth() +
        1 +
        "/" +
        date.getDate() +
        "/" +
        date.getFullYear()
        + " "
        + date.getHours()
        + ':'
        + date.getMinutes()
        + ':'
        + date.getSeconds()
  
      );
    } catch (error) {
      SentryUtilites.setLog("Utility:getDateTime", error)
      return '';
    }
  }

  static dateToDDMMYYY(date: number, seperator?: string) {
    try {
      if(!date) {
        return '';
      }
      if (!seperator) seperator = '/';
      const d = new Date(date),
        dd = d.getDate() > 9 ? d.getDate() : '0' + d.getDate(),
        mm = d.getMonth() + 1 > 9 ? d.getMonth() + 1 : '0' + (d.getMonth() + 1),
        yyyy = d.getFullYear();
      return dd + seperator + mm + seperator + yyyy;
    } catch (error) {
      SentryUtilites.setLog("Utility:dateToDDMMYYY", error)
      return '';
    }
  }

  static dateToDDMMYYYWithMonthStr(date: number, seperator?: string) {
    try {
      if(!date) {
        return '';
      }
      if (!seperator) seperator = '/';
      const d = new Date(date),
        dd = d.getDate() > 9 ? d.getDate() : '0' + d.getDate(),
        mm = d.getMonth(),
        yyyy = d.getFullYear();
  
      let monthArr = ['Jan','Feb','March','Apr','May','June','July','Aug','Sept','Oct','Nov','Dec'];
      return dd + seperator + monthArr[mm] + seperator + yyyy;
    } catch (error) {
      SentryUtilites.setLog("Utility:dateToDDMMYYYWithMonthStr", error)
      return '';
    }
  }

  static maxDateUpto50Year() {
    let date = new Date();
    return new Date(date.setFullYear(date.getFullYear() + 50)).toISOString();
  }

  /**
   * 
   * @param num1 : provide first number
   * @param num2 : provide second number
   * @returns : maximum of two number
   */
  static maximumOfTwoNumber(num1: number = 0, num2: number = 0) {
    return Math.max(num1, num2);
  }
  // -------------------------------------

  static isNumber(value: any): boolean {
    try {
      if (isNaN(Number(value)) === false) {
        return true;
      }
      return false;
    } catch (error) {
      SentryUtilites.setLog("Utility:isNumber", error)
      return false;
    }
  }

  static async playAmountSpeech(amount: number, isPaymentReceived: Boolean = false) {
    try {
      if (this.isNumber(amount)) {

        amount = Math.trunc(amount);
        let amountArray = (amount+"")?.split('')?.reverse();

        let tens = '';
        let hundreds = '';
        let thousands = '';
        let lakhs = '';
        let crores = '';

        for (let i = 0; i < amountArray?.length; i++) {
          if(i === 8 || i === 7) {
            crores = amountArray[i] + crores ;
          }
          if(i === 6 || i === 5) {
            lakhs =  amountArray[i] + lakhs;
          }
          if(i === 4 || i === 3) {
            thousands = amountArray[i] + thousands;
          }
          if(i === 2) {
            hundreds = amountArray[i] + hundreds;
          }
          if(i === 1 || i === 0) {
            tens = amountArray[i] + tens;
          }
        }


        let path = '../../assets/sounds/amounts/';
        let soundArr = [];

        if(crores && +crores) {
          soundArr.push(path+`digit_${+crores}.mp3`);
          soundArr.push(path+`crore.mp3`);
        }

        if(lakhs && +lakhs) {
          soundArr.push(path+`digit_${+lakhs}.mp3`);
          soundArr.push(path+`lakh.mp3`);
        }

        if(thousands && +thousands) {
          soundArr.push(path+`digit_${+thousands}.mp3`);
          soundArr.push(path+`thousand.mpeg`);
        }

        if(hundreds && +hundreds) {
          soundArr.push(path+`digit_${+hundreds}.mp3`);
          soundArr.push(path+`hundred.mp3`);
        }

        if(tens && +tens) {
          soundArr.push(path+`digit_${+tens}.mp3`);
        }

        soundArr.push(path+`rupees.mp3`);
        soundArr.push(path+`only.mp3`);


        let audio = document.createElement('audio');
        audio.src = soundArr[0]

        let index = 0;
        audio.play();

        audio.onended = () => {
          index += 1;
          if(index < soundArr?.length) {
            audio.src = soundArr[index];
            audio.play();
          }else {
            audio.pause();
            audio.remove();
          }
        };
      }
    } catch (e) { 
      SentryUtilites.setLog("Utility:playAmountSpeech", e)
    }
  }

  /**
   * 
   * @param amount : provide amount
   * @description : speak discount apply to customer speech
   */
  static async playDiscountSpeech(amount: number) {
    try {
      if (this.isNumber(amount)) {
        let beforeDecimal = Math.trunc(amount);
        let amountArray = (beforeDecimal+"")?.split('')?.reverse();

        let tens = '';
        let hundreds = '';
        let thousands = '';
        let lakhs = '';
        let crores = '';

        for (let i = 0; i < amountArray?.length; i++) {
          if(i === 8 || i === 7) {
            crores = amountArray[i] + crores ;
          }
          if(i === 6 || i === 5) {
            lakhs =  amountArray[i] + lakhs;
          }
          if(i === 4 || i === 3) {
            thousands = amountArray[i] + thousands;
          }
          if(i === 2) {
            hundreds = amountArray[i] + hundreds;
          }
          if(i === 1 || i === 0) {
            tens = amountArray[i] + tens;
          }
        }

        let afterDecimal =  (amount+"")?.split('.')[1];

        let afterDecimalVal = '';

        if(afterDecimal) {
          let afterDecimalArray = afterDecimal?.split('')?.reverse();
  
          for (let i = 0; i < afterDecimalArray?.length; i++) {
            if(i === 1 || i === 0) {
              afterDecimalVal = afterDecimalArray[i] + afterDecimalVal;
            }
          }
        }

        let path = '../../assets/sounds/amounts/';
        let soundArr = [];

        if(crores && +crores) {
          soundArr.push(path+`digit_${+crores}.mp3`);
          soundArr.push(path+`crore.mp3`);
        }

        if(lakhs && +lakhs) {
          soundArr.push(path+`digit_${+lakhs}.mp3`);
          soundArr.push(path+`lakh.mp3`);
        }

        if(thousands && +thousands) {
          soundArr.push(path+`digit_${+thousands}.mp3`);
          soundArr.push(path+`thousand.mpeg`);
        }

        if(hundreds && +hundreds) {
          soundArr.push(path+`digit_${+hundreds}.mp3`);
          soundArr.push(path+`hundred.mp3`);
        }

        if(tens && +tens) {
          soundArr.push(path+`digit_${+tens}.mp3`);
        }

        if(afterDecimal) {
          soundArr.push(path+`point.mp3`);
  
          if(afterDecimalVal && +afterDecimalVal) {
            soundArr.push(path+`digit_${+[afterDecimalVal[0]]}.mp3`);
            soundArr.push(path+`digit_${+[afterDecimalVal[1]]}.mp3`);
          }
        }

        soundArr.push(path+`discount_sound.mp3`);

        let audio = document.createElement('audio');
        audio.src = soundArr[0]

        let index = 0;
        audio.play();

        audio.onended = () => {
          index += 1;
          if(index < soundArr?.length) {
            audio.src = soundArr[index];
            audio.play();
          }else {
            audio.pause();
            audio.remove();
          }
        };
      }
    } catch (e) {
      SentryUtilites.setLog("Utility:playDiscountSpeech", e)
     }
  }
  // -----------------------------------------------

  /**
   * @description : speak get disccount on your number speech
   */
  static async playParcelModelDiscountSpeech() {
    try {

      let path = '../../assets/sounds/amounts/';
      let soundArr = [];

      soundArr.push(path+`get_disccount_on_your_number.mp3`);

      let audio = document.createElement('audio');
      audio.src = soundArr[0]

      let index = 0;
      audio.play();

      audio.onended = () => {
        index += 1;
        if(index < soundArr?.length) {
          audio.src = soundArr[index];
          audio.play();
        }else {
          audio.pause();
          audio.remove();
        }
      };
      
    } catch (e) {
      SentryUtilites.setLog("Utility:playParcelModelDiscountSpeech", e)
     }
  }
  // -----------------------------------------------

  static shortStr(str: String, length: number) {
    return str?.substr(0, length) || '';
  }

  static getAddition(arr: {[key:string]:any}[], key: string) {
    try {
      let total = 0;
      if(arr?.length) {
        arr?.forEach(el => {
          if(el && el[key] && Utility.isNumber(el[key])) {
            total += Number(el[key]);
          }
        });
      }
      return total;
    } catch (error) {
      SentryUtilites.setLog("Utility:getAddition", error)
      return 0;
    }
  }

  static preventEmptyString(str: string) {
    try {
      if(str?.match(Validator.onlyWhiteSpaces)) {
        return '';
      }else {
        return str;
      }
    } catch (error) {
      SentryUtilites.setLog("Utility:preventEmptyString", error)
      return '';
    }
  }

  /**
   * 
   * @param phoneNumber : provide phone number
   * @returns : return true if phone number match regular expression
   */
  static checkValidPhoneNumber(phoneNumber: string) {
    return Boolean(phoneNumber?.match(Validator?.parcelPhone));
  }
  // ---------------------------------------

  static randomNumber(length: number) {
		if (!length) length = 6;
		return Math.floor(Math.random() * 10 ** length);
	}

  static getHeaderColorClass() {
    return Utility.getFromLocalStorage('header-color-class') as string;
  }

  static setCreatedByName(name: string) {
    this.setToLocalStorage('createdByName',name);
  }

  static getCreatedByName() {
    return this.getFromLocalStorage('createdByName');
  }

  static returnZero = () => 0;

  static stampTo12HrFormatDateTime(stamp:number) {
    try {
      let dt=new Date(+stamp);
      let dd=("0"+(dt.getDate())).slice(-2);
      let mm=("0"+(dt.getMonth()+1)).slice(-2);
      let yy=(""+dt.getFullYear()).slice(-2);
      let nHH=dt.getHours();
      let ap='AM';
      if(nHH>12){
        ap='PM';
        nHH-=12;
      }else if(nHH==12){
        ap='PM';
      }else if(nHH==0) {
        nHH = 12;
      }
      let hh=("0"+(nHH)).slice(-2);
      let MM=("0"+(dt.getMinutes())).slice(-2);
      let SS=("0"+(dt.getSeconds())).slice(-2);
      return dd+'/'+mm+'/'+yy+' '+hh+':'+MM +' '+ap;
    } catch (error) {
      SentryUtilites.setLog("Utility:stampTo12HrFormatDateTime", error)
      return '';
    }
  }

  static getDeviceInfo () {
    try {
      let navigator = window?.navigator || null;
      if(!navigator) {
        return JSON.stringify({
          msg:'navigator not found'
        });
      }
      return JSON.stringify({
        con: {
          dl: navigator?.['connection']?.['downlink'],
          et: navigator?.['connection']?.['effectiveType'],
          rtt: navigator?.['connection']?.['rtt'],
        },
        dm: navigator?.['deviceMemory'],
        onl: navigator?.onLine,
        uad: Utility.getDevice(),
        scr: {
          w: window?.['screen']?.['width'],
          h: window?.['screen']?.['height'],
        },
        av: navigator?.appVersion,
      });
    } catch (error) {
      SentryUtilites.setLog("Utility:getDeviceInfo", error)
      return '';
    }
  }

  static getDevice() {
    try {
      if(navigator?.hasOwnProperty('userAgentData')) {
        return `${platform?.name} - ${navigator?.['userAgentData']?.['platform']} - ${navigator?.['userAgentData']?.['mobile'] ? 'Mobile' : 'Desktop'}`;
      } else {
        return `${platform?.name} - ${''} - ${''}`;
      }
    } catch (error) {
      SentryUtilites.setLog("Utility:getDevice", error)
      return '';
    }
  }

  static isMobile() {
    try {
      if(window?.navigator?.hasOwnProperty('userAgentData')) {
        return window?.navigator?.['userAgentData']?.['mobile'];
      } else {
        return true;
      }
    } catch (error) {
      SentryUtilites.setLog("Utility:isMobile", error)
    }
  }

  static getCurrentLongLat(): Promise<{lat: number, long: number}> {
    return new Promise((resolve,reject) => {
      // navigator.geolocation.getCurrentPosition(position => {
      //   let lat = position.coords.latitude;
      //   let long = position.coords.longitude;
      //   resolve({lat,long});
      // },
      // err => {
      //   resolve({lat: null, long: null});
      // });
      return resolve({lat: null, long: null});
    });

  }

  static getChunkArr(unSyncedElements: any[]) {
    try {
      let chunkSize = 20;
      let chunkArr = [];
      for (let i = 0; i < unSyncedElements?.length; i += chunkSize) {
        chunkArr.push(unSyncedElements?.slice(i,i+chunkSize))
      }
      return chunkArr;
    } catch (error) {
      SentryUtilites.setLog("Utility:getChunkArr", error)
      return [];
    }
  }

  static splitByCamelCase(value: string) {
    return value.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
  }

  /**
   * 
   * @param stamp : provide timeStamp
   * @returns : return number of days ago visited text
   */
  static countDays(stamp:number) {
    try {
      if(stamp) {
        let curDate = +new Date();
        let delta = curDate - stamp;
        let days = Math.floor(delta/86400000);
        return `${days} days ago visited`;
      } else {
        return '';
      }
    } catch (error) {
      SentryUtilites.setLog("Utility:countDays", error)
      return '';
    }
  }
  // ------------------------------


  /**
   * 
   * @param date : provide date timeStamp
   * @param seperator : month year separater
   * @returns : return month year string with separater
   */
  static getYearMonthKey(timestamp: number): string {
    try {
      if(timestamp) {
        const dt = new Date(timestamp),
        mm = dt.getMonth() + 1 ,
        yyyy = dt.getFullYear()?.toString();
        return `${yyyy?.substr(-2)}${('0'+mm)?.substr(-2)}`;
      }else {
        return '';
      }
    } catch (error) {
      SentryUtilites.setLog("Utility:getYearMonthKey", error)
      return '';
    }
  }
  // ----------------------------------------------

  /**
   * 
   * @param val : string value
   * @param num : show text value length from last
   * @param mask : provide masking symbol
   * @returns 
   */
  static stringMask(val: string, num: number = 4, mask: string = '*') {
    try {
      if(
        !(!val ||
          val === 'undefined' ||
          val === 'null' ||
          val === '""' ||
          val === "''" ||
          val === 'NaN' ||
          val === 'false' ||
          val === '0n' ||
          typeof val == 'string' && val?.trim() === '')
        ) {
        return ('' + val)?.slice(0, -num)?.replace(/./g, mask) + ('' + val)?.slice(-num);
      }
      return '';
    } catch (error) {
      SentryUtilites.setLog("Utility:stringMask", error)
      return '';
    }
  }
  // ----------------------------------------------

  /**
   * 
   * @param itemName : provide Item Name
   * @returns : return Abbrivation string
   */
  static getAbbrivation(itemName: string) {
    try {
      if(
        !(!itemName ||
          itemName === 'undefined' ||
          itemName === 'null' ||
          itemName === '""' ||
          itemName === "''" ||
          itemName === 'NaN' ||
          itemName === 'false' ||
          itemName === '0n' ||
          typeof itemName == 'string' && itemName?.trim() === '')) {
        let abbrivationName = '';
        let itemNameWordsArr = itemName?.trim()?.split(' ');
        for(let i = 0; i < itemNameWordsArr?.length; i++) {
          abbrivationName = abbrivationName + itemNameWordsArr[i]?.substr(0,1);
        }
        return abbrivationName?.toLowerCase();
      } else {
        return '';
      }
    } catch (error) {
      SentryUtilites.setLog("Utility:getAbbrivation", error)
      return '';
    }
  }
  // -----------------------------------

  /**
   * 
   * @param classN : provide class name
   * @param isValid : provide input feild is valid or not in boolean
   */
  static changeBorderColor(classN: string, isValid: boolean) {
    let className = document.getElementsByClassName(classN);
    for(let i = 0; i < className.length; i++) {
      className[i]['style'].borderColor = isValid ? '' : '#ff0000';
    }
  }
  // -------------------------------

  /**
   * 
   * @param stateName : provide state name
   * @returns : return boolean value
   */
  static isDisabledState(stateName: string) {
    if(
      stateName == 'ANDHRA PRADESH BEFORE DIVISION' ||
      stateName == '37-ANDHRA PRADESH BEFORE DIVISION' ||
      stateName == 'ANDHRA PRADESH NEW' ||
      stateName == '37-ANDHRA PRADESH NEW'
    ) {
      return true;
    }
    return false;
  }
  // -------------------------------

}
