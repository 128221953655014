<ion-header>
  <ion-toolbar class="{{ getHeaderColorClass() }}">
    <app-navigation-control slot="start" type="money-out/form"></app-navigation-control>
    <ion-title>Money Out Form</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">

  <app-money-out-form
    [paramDocumentId]="paramDocumentId"
    (savedMoneyOut)="savedMoneyOut($event)"
    (updatedMoneyOut)="updatedMoneyOut($event)"
  >
  </app-money-out-form>

</ion-content>
