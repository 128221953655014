import { IProfile } from './../../models/Profile.model';
import { IMoneyIn } from './../../models/MoneyIn.model';
import { IParty } from './../../models/Party.model';
import { Utility } from './../../utils/utility';
import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { IItem } from '../../models/Item.model';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { IMoneyOut } from '../../models/MoneyOut.model';
import { ISale } from '../../models/Sale.model';
import { IPurchase } from '../../models/Purchase.model';
import { IItemCategory } from '../../models/ItemCategory.model';
import { IPartyCategory } from '../../models/PartyCategory.model';
import { IEstimate } from 'src/app/models/Estimate.model';
import { IMonthWisePartyCredit } from '../../models/MonthWisePartyCredit.model';
import { IMonthWiseItemStock } from '../../models/MonthWiseItemStock.model';
import { IImage } from '../../models/image.model';
import { ICutOffDay } from '../../models/CutOffDay.model';
import { IExpense } from '../../models/Expense.model';
import { IItemStockAdjust } from '../../models/ItemStockAdjust.model';
import { IKot } from '../../models/Kot.model';
import { ILicence } from '../../models/Licence.model';
import { IPartyItemPriceMap } from '../../models/PartyItemPriceMap.model';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';
import { IPurchaseReturn } from '../../models/PurchaseReturn.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements CanActivate {
  isUserLoggedIn = false;
  userLoggedInSubs = new BehaviorSubject<boolean>(false);

  constructor(
    private ngxIndexedDBService: NgxIndexedDBService,
    private router: Router,
  ) {
    this.isUserLoggedIn = Boolean(Utility.getFromLocalStorage('_ezo_login_token'));
    this.userLoggedInSubs.next(this.isUserLoggedIn);
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> | BehaviorSubject<boolean> {
    try {
      this.isUserLoggedIn = Boolean(Utility.getFromLocalStorage('_ezo_login_token'));
      if (!this.isUserLoggedIn) {
        this.router.navigate(['sign-in-with-gmail']);
      }
      this.userLoggedInSubs.next(this.isUserLoggedIn);
      return this.isUserLoggedIn;
    } catch (error) {
      SentryUtilites.setLog("AuthService:canActivate", error)
      return false;
    }
  }

  isAuthenticated(): boolean {
    try {
      const ezoToken = Utility.getFromLocalStorage('_ezo_login_token');
      return Boolean(ezoToken);
    } catch (error) {
      SentryUtilites.setLog("AuthService:isAuthenticated", error)
      return false;
    }
  }

  setToken(token: string) {
    try {
      Utility.setToLocalStorage('_ezo_login_token', token);
      this.isUserLoggedIn = true;
      this.userLoggedInSubs.next(this.isUserLoggedIn);
    } catch (error) {
      SentryUtilites.setLog("AuthService:setToken", error)
    }
  }

  getToken() {
    try {
      const ezoToken = Utility.getFromLocalStorage('_ezo_login_token');
      return ezoToken;
    } catch (error) {
      SentryUtilites.setLog("AuthService:getToken", error)
    }
  }

  async logout() {
    try {
      localStorage.clear();
      await this.clearDb();
      this.isUserLoggedIn = false;
      this.userLoggedInSubs.next(this.isUserLoggedIn);
      return true;
    } catch (error) {
      SentryUtilites.setLog("AuthService:logout", error)
      return false;
    }
  }

  getDecodedAccessToken(token?: string): any {
    try {
      const ezoToken = token || Utility.getFromLocalStorage('_ezo_login_token');
      if(ezoToken) {
        return jwt_decode(ezoToken);
      } else {
        return null;
      }
    } catch (Error) {
      SentryUtilites.setLog("AuthService:getDecodedAccessToken", Error)
      return null;
    }
  }

  getLoginPhone(): string {
    let phone = null;
    try {
      let token = this.getDecodedAccessToken();
      phone = token?.phone;
    } catch (err) {
      SentryUtilites.setLog("AuthService:getLoginPhone", err)
      return null;
    }
    return phone;
  }

  getTokenUUID(): string {
    let uuid = null;
    try {
      let token = this.getDecodedAccessToken();
      uuid = token?._uuid;
    } catch (err) {
      SentryUtilites.setLog("AuthService:getTokenUUID", err)
      return null;
    }
    return uuid;
  }

  validateToken(token: string): boolean {
    try {
      let tokenDetails = this.getDecodedAccessToken(token);
      return tokenDetails?._uuid && tokenDetails?.phone;
    } catch (error) {
      SentryUtilites.setLog("AuthService:validateToken", error)
      return false;
    }
 }
 
  clearDb(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        let collections = [
          IParty.SCHEMA_NAME,
          IItem.SCHEMA_NAME,
          IMoneyIn.SCHEMA_NAME,
          IMoneyOut.SCHEMA_NAME,
          ISale.SCHEMA_NAME,
          IPurchase.SCHEMA_NAME,
          IPurchaseReturn.SCHEMA_NAME,
          IItemCategory.SCHEMA_NAME,
          IPartyCategory.SCHEMA_NAME,
          IProfile.SCHEMA_NAME,
          IEstimate.SCHEMA_NAME,
          IMonthWisePartyCredit.SCHEMA_NAME,
          IMonthWiseItemStock.SCHEMA_NAME,
          ICutOffDay.SCHEMA_NAME,
          IExpense.SCHEMA_NAME,
          IImage.SCHEMA_NAME,
          IItemStockAdjust.SCHEMA_NAME,
          IKot.SCHEMA_NAME,
          ILicence.SCHEMA_NAME,
          IPartyItemPriceMap.SCHEMA_NAME,
        ];
        let successArr = [];
        for (let i = 0; i < collections?.length; i++) {
          const collection = collections[i];
          let result = await this.clearCollection(collection);
          successArr.push(result);
        }
  
        if (successArr?.filter(ele => ele === false)?.length) {
          return resolve(false);
        } else {
          return resolve(true);
        }
      } catch (error) {
        SentryUtilites.setLog("AuthService:clearDb", error)
        return resolve(false);
      }
    });
  }

  clearCollection(collection: string) {
    return new Promise((resolve, reject) => {
      try {
        this.ngxIndexedDBService.clear(collection).subscribe((successDeleted) => {
          return resolve(true);
        },
          (error) => {
            return resolve(false);
          });
      } catch (error) {
        SentryUtilites.setLog("AuthService:clearCollection", error)
        return resolve(false);
      }
    })
  }

}
