import { AccessControlService } from './../../../services/auth/access-control.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ToastController } from '@ionic/angular';
import { MoneyIn } from '../../../models/MoneyIn.model';
import { Sale } from '../../../models/Sale.model';
import { AllDataService } from '../../../services/all-data.service';
import { PinVerificationComponent } from '../../pin-verification/pin-verification.component';
import { Utils } from '../../../utils/utils';
import { Utility } from '../../../utils/utility';
import { AuthService } from '../../../services/auth/auth.service';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-sale-row',
  templateUrl: './sale-row.component.html',
  styleUrls: ['./sale-row.component.scss'],
})
export class SaleRowComponent implements OnInit {

  @Input() sale: Sale = null;
  @Input() isRecentTransactionList: boolean = false;

  @Output() onDeleteClick = new EventEmitter<Sale>();
  @Output() onAddPaymentClick = new EventEmitter<Sale>();

  @ViewChild('pinVerificationElement') pinVerificationElement: PinVerificationComponent;

  capFractionsToTwo = Utils.capFractionsToTwo;

  constructor(
    private router: Router,
    private accessControlService: AccessControlService,
    private allDataService: AllDataService,
    private authService: AuthService,
  ) { }

  ngOnInit() { }

  async billView(sale: Sale) {
    try {
      if(!sale?.deletedStamp){
        let isPermit = await this.accessControlService.isUserHasAccess({action:'viewSale'});
        if(!isPermit) {
          return alert("Permission: You don't have permission to view bill. Please contact to your owner.");
        }
        this.router.navigate([`bill-view/${sale?._localUUID}`]);
      }
    } catch (error) {
      SentryUtilites.setLog("SaleRowComponent:billView", error)
      alert("Something went wrong.");
    }
  }

  openTransactionPINModal() {
    this.pinVerificationElement?.openTransactionPINModal();
  }

  async createSaleReturn(sale: Sale) {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({action:'createSaleReturn'});
      if(!isPermit) {
        return alert("Permission: You don't have permission to create sale return. Please contact to your owner.");
      }
      this.router.navigate([`sale-return/form`],
      { queryParams: {
        saleLocalUUID: sale?._localUUID,
      }});
    } catch (error) {
      SentryUtilites.setLog("SaleRowComponent:createsaleReturn", error)
      alert("Something went wrong.");
    }
  }

  verifyTransactionPIN(event) {
    try {
      if(event) {
        this.router.navigate([`sale/form/${this.sale?._localUUID}`]);
      }
    } catch (error) {
      SentryUtilites.setLog("SaleRowComponent:verifyTransactionPIN", error)
    }
  }

  async edit(sale: Sale) {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({action:'editSale'});
      if(!isPermit) {
        return alert("Permission: You don't have permission to edit sale. Please contact to your owner.");
      }
      this.openTransactionPINModal();
    } catch (error) {
      SentryUtilites.setLog("SaleRowComponent:edit", error)
      alert("Something went wrong.");
    }
  }

  getBalanceAmount(moneyIns: MoneyIn[], totalAmount: number) {
    try {
      if(!moneyIns || !moneyIns?.length) {
        return this.capFractionsToTwo(totalAmount);
      }
      if (moneyIns?.length) {
        let receivedAmount: number = 0;
        moneyIns?.forEach(moneyIn => receivedAmount += Number(moneyIn?.totalAmount));
        let pendingAmount = totalAmount - receivedAmount;
        pendingAmount = pendingAmount < 0 ? 0 : pendingAmount;
        return this.capFractionsToTwo(pendingAmount);
      }
    } catch (error) {
      SentryUtilites.setLog("SaleRowComponent:getBalanceAmount", error)
      return 0;
    }
  }

  extraMoneyInAmount(moneyIns: MoneyIn[], totalAmount: number) {
    try {
      if (moneyIns?.length) {
        let receivedAmount: number = 0;
        moneyIns?.forEach(moneyIn => receivedAmount += Number(moneyIn?.totalAmount));
        if(receivedAmount > totalAmount) {
          return receivedAmount - totalAmount;
        }else {
          return 0;
        }
      }
    } catch (error) {
      SentryUtilites.setLog("SaleRowComponent:extraMoneyInAmount", error)
      return 0;
    }
  }

  delete(sale: Sale) {
    this.onDeleteClick.emit(sale);
  }

  async addPayment(sale: Sale) {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({action:'createMoneyIn'});
      if(!isPermit) {
        return alert("Permission: You don't have permission to create money in. Please contact to your owner.");
      }
      this.onAddPaymentClick.emit(sale);
    } catch (error) {
      SentryUtilites.setLog("SaleRowComponent:addPayment", error)
      alert("Something went wrong.");
    }
  }

  async setPaymentMode(paymentMode: 'cash' | 'cheque' | 'bank') {
    try {
      if(this.sale?._localUUID && this.sale?.moneyIns?.length) {
        this.sale.moneyIns[0].paymentMode = paymentMode;
        this.sale.lastModifiedBy = this.authService.getLoginPhone();
        this.sale.lastModifiedByName = Utility.getCreatedByName();
        this.sale = await this.allDataService.saleService.update(this.sale);
        if(this.sale?.moneyIns) {
          let moneyIn = await this.allDataService.moneyInService.getByUUID(this.sale?.moneyIns[0]?._localUUID);
          if(moneyIn?._localUUID) {
            moneyIn.paymentMode = paymentMode;
            await this.allDataService.moneyInService.update(moneyIn);
          }
        }
  
        this.allDataService.itemService.reloadList();
        this.allDataService.partyService.reloadList();
        this.allDataService.moneyInService.reloadList();
        this.allDataService.listForceReloadSubs.next('sale-list');
        setTimeout(()=> {
          this.allDataService.listForceReloadSubs.next('item-list');
          this.allDataService.listForceReloadSubs.next('party-list');
          this.allDataService.listForceReloadSubs.next('moneyin-list');
        }, 2000);
      }
    } catch (error) {
      SentryUtilites.setLog("SaleRowComponent:setPaymentMode", error)
    }
  }

  async viewSaleReturn(sale: Sale) {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({action:'viewSaleReturn'});
      if(!isPermit) {
        return alert("Permission: You don't have permission to view Sale Return. Please contact to your owner.");
      }
      if(sale?.linkedSaleReturnUUID) {
        this.router.navigate([`bill-view-sale-return/${sale?.linkedSaleReturnUUID}`]);
      }
    } catch (error) {
      SentryUtilites.setLog("SaleRowComponent:viewSaleReturn", error)
    }
  }

}
