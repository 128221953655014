import { AuthService } from './../../services/auth/auth.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { PartyCategoryListComponent } from '../../components/party-category-list/party-category-list.component';
import { ToastController } from '@ionic/angular';
import { ItemCategoryListComponent } from '../../components/item-category-list/item-category-list.component';
import { Utility } from '../../utils/utility';
import { AccessControlService } from '../../services/auth/access-control.service';
import { IPartyCategory } from '../../models/PartyCategory.model';
import { IItemCategory } from '../../models/ItemCategory.model';
import { Subscription } from 'rxjs';
import { AllDataService } from '../../services/all-data.service';
import { ActivatedRoute } from '@angular/router';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.page.html',
  styleUrls: ['./categories.page.scss'],
})
export class CategoriesPage implements OnInit {

  @ViewChild('partyCatListEle') partyCatListEle: PartyCategoryListComponent;
  @ViewChild('itemCatListEle') itemCatListEle: ItemCategoryListComponent;

  getHeaderColorClass = Utility.getHeaderColorClass;

  lastSyncTime = Utility.getCollectionLastRespSyncTime(IPartyCategory.SCHEMA_NAME);
  lastSyncTimeSub: Subscription;
  isTimeDifference = false;

  selectedView: 'PartyCategoryView' | 'ItemCategoryView' = null;

  isNgOnInitRun: boolean = false;

  constructor(
    private toastController: ToastController,
    private authService: AuthService,
    private accessControlService: AccessControlService,
    private allDataService: AllDataService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    try {
      this.isNgOnInitRun = true;
      this.selectedView = 'PartyCategoryView';
      this.allDataService.lastSyncSubs.subscribe(x => {
        if(this.selectedView === 'PartyCategoryView') {
          this.lastSyncTime = Utility.getCollectionLastRespSyncTime(IPartyCategory.SCHEMA_NAME);
        }
        if(this.selectedView === 'ItemCategoryView') {
          this.lastSyncTime = Utility.getCollectionLastRespSyncTime(IItemCategory.SCHEMA_NAME);
        }
        this.isTimeDifference = this.allDataService.isTimeDifference;
      });
      let queryParam = this.activatedRoute.snapshot.queryParamMap.get('selectItem');
      if(queryParam) {
        this.selectedView = 'ItemCategoryView';
      }
    } catch (error) {
      SentryUtilites.setLog("CategoriesPage:ngOnInit", error)
    }
  }

  ionViewWillEnter() {
    if(!this.isNgOnInitRun) {
      this.ngOnInit();
    }
  }

  ionViewWillLeave() {
    this.ngOnDestroy();
  }

  ngOnDestroy() {
    this.isNgOnInitRun = false;
    this.selectedView = null;
    this.lastSyncTimeSub?.unsubscribe();
  }

  async bulkDelete() {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({action:'deleteItemCategory'});
      if(!isPermit) {
        return alert("Permission: You don't have permission to delete item category. Please contact to your owner.");
      }
      let phone = Utility.getFromLocalStorage('selectedProfileUserId');
      let profileId = Utility.getFromLocalStorage('selectedProfile');
      window.open(`https://db.ezobooks.in/kappa/bulk/${this.selectedView === 'PartyCategoryView' ? 'listPartyCategories' : 'listItemCategories'}?userId=${phone}&profileId=${profileId}`);
    } catch (error) {
      SentryUtilites.setLog("CategoriesPage:bulkDelete", error)
      alert("Something went wrong.");
    }
  }

  onSelectedViewChange = (event: any) => {
    try {
      this.selectedView = event?.detail?.value
      if(this.selectedView === 'PartyCategoryView') {
        this.lastSyncTime = Utility.getCollectionLastRespSyncTime(IPartyCategory.SCHEMA_NAME);
      }
      if(this.selectedView === 'ItemCategoryView') {
        this.lastSyncTime = Utility.getCollectionLastRespSyncTime(IItemCategory.SCHEMA_NAME);
      }
    } catch (error) {
      SentryUtilites.setLog("CategoriesPage:onSelectedViewChange", error)
    }
  };

  presentAddCategoryAlert = async () => {
    try {
      if(this.selectedView === 'PartyCategoryView') {
        let isPermit = await this.accessControlService.isUserHasAccess({action:'createPartyCategory'});
        if(!isPermit) {
          return alert("Permission: You don't have permission to create new category. Please contact to your owner.");
        }
      }
      if(this.selectedView === 'ItemCategoryView') {
        let isPermit = await this.accessControlService.isUserHasAccess({action:'createItemCategory'});
        if(!isPermit) {
          return alert("Permission: You don't have permission to create new category. Please contact to your owner.");
        }
      }
      switch(this.selectedView) {
        case 'PartyCategoryView': this.partyCatListEle?.presentAddCategoryAlert(); break;
        case 'ItemCategoryView': this.itemCatListEle?.presentAddCategoryAlert(); break;
        default: return;
      }
    } catch (error) {
      SentryUtilites.setLog("CategoriesPage:presentAddCategoryAlert", error)
      alert("Something went wrong.");
    }
  };

  presentSuccessToast = async () => {
    try {
      const toast = await this.toastController.create({
        header: 'List Sequence Saved',
        duration: 2000,
        position: 'top',
        mode: 'ios',
        color: 'success',
      });
      await toast.present();
    } catch (error) {
      SentryUtilites.setLog("CategoriesPage:presentSuccessToast", error)
    }
  }

}
