<ion-modal [keepContentsMounted]="true">
  <ng-template>
    <ion-datetime
      appYearDefaultBtn      
      id="paymentReminderDate"
      [showDefaultButtons]="true"
      [value]="dpPaymentReminderDateValue"
      (ionChange)="onDateSelect($event)"
      [max]="maxDate"
    >
      <span slot="title">Select a Date</span>
    </ion-datetime>
  </ng-template>
</ion-modal>

<ion-row>
  <ion-col size="12">
    <ion-button fill="clear" class="btn-txt-black padding-end-none">
      Payment Reminders
    </ion-button>
  </ion-col>
  <ion-col size="3">
    <ion-item class="segment" lines="none">
      <ion-segment mode="ios" [value]="selectedDateRange" (ionChange)="onDateRangeChange($event)">
        <ion-segment-button value="today">
          <ion-label>Today</ion-label>
        </ion-segment-button>
        <ion-segment-button value="tomorrow">
          <ion-label>Tomorrow</ion-label>
        </ion-segment-button>
      </ion-segment>
    </ion-item>
  </ion-col>
  <ion-col size="2">
    <ion-item lines="none">
      <ion-datetime-button datetime="paymentReminderDate" appRmTimeBtn></ion-datetime-button>
    </ion-item>
  </ion-col>
</ion-row>

<ion-row class="padding-5px" *ngIf="paymentReminderParties?.length">
  <ion-col size="12"
    class="col-custom-row"
    *ngFor="let party of paymentReminderParties"
    (click)="emitElement(party)"
  >
    <ion-row class="padding-none">
        <ion-col size="4" class="txt-left">
          <ion-button
            fill="clear"
            class="btn-party-name btn-txt-black padding-start-none"
          >
            {{ party.name }}
          </ion-button>
        </ion-col>
        <ion-col size="4" class="txt-left">
          <ion-button
            fill="clear"
            class="btn-party-name btn-txt-black padding-start-none"
          >
            {{ party.phone }}
          </ion-button>
        </ion-col>
        <ion-col size="4" class="txt-right">
          <ion-button
            fill="outline"
            class="dashed-border txt-transform-normal"
          >
            Current Credit: <ion-label class="margin-inline-start-10px" [color]="party.credit<0 ? 'success' : 'danger'">{{ MATHABS(party.credit) | currency: 'INR' }}</ion-label>
          </ion-button>
        </ion-col>
    </ion-row>
  </ion-col>
</ion-row>
