<ion-header>
  <ion-toolbar>
    <ion-title>Ezo Admin Sign In</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col size-md="4" size-xs="1"></ion-col>
      <ion-col size-md="4" size-xs="10">
        <form [formGroup]="signInForm">
          <h3>Login</h3>
          <hr />
          <ion-item *ngIf="!isOTPSent">
            <ion-label position="floating">Mobile Number</ion-label>
            <ion-input #phoneEle [autofocus]="true" type="number" formControlName="phone"
              (keyup.enter)="signInForm?.valid ? getOTP() : null"></ion-input>
          </ion-item>

          <ion-item *ngIf="!isOTPSent" class="terms-and-condition">
            <ion-checkbox formControlName="termsAndCondition" slot="start"></ion-checkbox>
            <ion-label>
              I agree to the 
              <a href="https://db.ezobooks.in/kappa/tnc/redirect" target="_blank">terms and conditions</a>
              <span class="span-mandatory">*</span>
            </ion-label>
          </ion-item>
          
          <ion-item *ngIf="isOTPSent">
            <ion-label position="floating">OTP</ion-label>
            <ion-input #otpEle [autofocus]="true" type="text" formControlName="otp"
              (keyup.enter)="signInForm?.valid ? verifyOTP() : null"></ion-input>
          </ion-item>

          <ion-button *ngIf="!showBtnLoader" [disabled]="signInForm?.invalid"
            (click)="!isOTPSent ? getOTP() : verifyOTP()" expand="full">
            {{ !isOTPSent ? 'Get' : 'Verify' }} OTP
          </ion-button>
          <ion-button *ngIf="showBtnLoader" expand="full">
            <ion-spinner name="lines-sharp-small"></ion-spinner>
          </ion-button>

          <ion-label class="label-edit-phone" *ngIf="isOTPSent" (click)="changeMobile(signInForm?.value?.phone, signInForm?.value?.termsAndCondition)">
            Edit Mobile <span class="edit-phone">{{ signInForm?.value?.phone }}</span>
          </ion-label>

        </form>
      </ion-col>
      <ion-col size-md="4" size-xs="1"></ion-col>
    </ion-row>
  </ion-grid>


</ion-content>