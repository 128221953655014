import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PinVerificationComponent } from '../../pin-verification/pin-verification.component';
import { AlertController, InfiniteScrollCustomEvent } from '@ionic/angular';
import { AllDataService } from '../../../services/all-data.service';
import { Purchase } from 'src/app/models/Purchase.model';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-purchase-bulk-delete',
  templateUrl: './purchase-bulk-delete.component.html',
  styleUrls: ['./purchase-bulk-delete.component.scss'],
})
export class PurchaseBulkDeleteComponent implements OnInit {

  @Output() onPurchaseDelete = new EventEmitter<Purchase>();
  @Output() onBulkPurchaseDelete = new EventEmitter<boolean>();
  @ViewChild('pinVerificationElement') pinVerificationElement: PinVerificationComponent;
  @Input() fromStamp:number = null;
  @Input() toStamp: number = null;

  // Purchase
  isPurchaseModalOpen = false;
  purchase: Purchase = null;
  isDeletePurchaseAttachedMoneyOuts = true;
  purchaseDeleteStarted = false;
  purchaseDeleteCompleted = false;
  //----------------------------------------------------

  // Bulk Purchase
  isBulkPurchaseModalOpen = false;
  checkAllPurchaseCheckboxes: boolean = false;
  bulkPurchases: Purchase[] = [];
  selectedBulkPurchases: Purchase[] = [];
  bulkPurchaseDeleteStarted = false;
  bulkPurchaseDeleteCompleted = false;
  //----------------------------------------------------

  // Security Pin common functionality var
  selectedVar: string = '';
  bulkSelectArray: string[] = [];
  viewFilteredList:Purchase[] = [];
  // ---------------------------------

  constructor(
    private allDataService: AllDataService,
    private alertController: AlertController,
  ) { }

  ngOnInit() {}

  openTransactionPINModal() {
    this.pinVerificationElement?.openTransactionPINModal();
  }

  async verifyTransactionPIN(event) {
    try {
      if(event){
        if(this.selectedVar == 'Purchase'){
         this.openPurchaseModal(true);
        }else if(this.selectedVar == 'BulkPurchase'){
          this.openBulkPurchaseModal(true);
          await this.onDateRangeChange();
          //edit bulk Purchase
          this.bulkPurchases?.forEach((el)=> {
            el["checked"] = false
          })
        }
      }else{
        this.openBulkPurchaseModal(false);
        this.openPurchaseModal(false);
      }
    } catch (error) {
      SentryUtilites.setLog("PurchaseBulkDeleteComponent:verifyTransactionPIN", error)
    }
  }

  setCheckboxVarValue(variable: string, event: any) {
    this[variable] = event?.detail?.checked;
  }

  async deleteConfirm(): Promise<boolean> {
    return new Promise(async (resolve,reject) => {
      try {
        const alert = await this.alertController.create({
          mode: 'ios',
          header: 'Please Confirm!',
          message: 'Are you sure want to delete?',
          buttons: [
            {
              text: 'No',
              role: 'cancel',
              handler: () => {
                resolve(false);
                return true;
              },
            },
            {
              text: 'Yes',
              role: 'confirm',
              handler: () => {
                resolve(true);
                return true;
              },
            },
          ],
        });
        await alert.present();
      } catch (error) {
        SentryUtilites.setLog("PurchaseBulkDeleteComponent:deleteConfirm", error)
        return resolve(false);
      }
    });
  }

  async deleteMoneyOutConfirm(): Promise<boolean> {
    return new Promise(async (resolve,reject) => {
      try {
        const alert = await this.alertController.create({
          mode: 'ios',
          header: `Also Delete Attached Money Out's`,
          message: 'Are you sure want to delete?',
          buttons: [
            {
              text: 'No',
              role: 'cancel',
              handler: () => {
                resolve(false);
                return true;
              },
            },
            {
              text: 'Yes',
              role: 'confirm',
              handler: () => {
                resolve(true);
                return true;
              },
            },
          ],
        });
        await alert.present();
      } catch (error) {
        SentryUtilites.setLog("PurchaseBulkDeleteComponent:deleteMoneyOutConfirm", error)
        return resolve(false);
      }
    });
  }

  async onDateRangeChange(){
    try {
      this.viewFilteredList = await this.allDataService?.purchaseService?.getByBillDateRange(this.fromStamp, this.toStamp) || [];
      this.viewFilteredList = this.viewFilteredList?.filter(x => !x?.deletedStamp);
      this.bulkPurchases = this.viewFilteredList.slice(0,50);
    } catch (error) {
      SentryUtilites.setLog("PurchaseBulkDeleteComponent:onDateRangeChange", error)
    }
  }

 // Purchase
  async openPurchaseModal(value: boolean = true) {
    try {
      if(value) {
        if ((await this.deleteConfirm()) === false) return;
      }
      this.isPurchaseModalOpen = value;
    } catch (error) {
      SentryUtilites.setLog("PurchaseBulkDeleteComponent:openPurchaseModal", error)
    }
  }

  async initDeletePurchase(purchase: Purchase) {
    try {
      this.purchase = purchase;
      this.selectedVar = 'Purchase';
      this.openTransactionPINModal();
    } catch (error) {
      SentryUtilites.setLog("PurchaseBulkDeleteComponent:initDeletePurchase", error)
    }
  }

  async deletePurchase() {
    try {
      this.purchaseDeleteStarted = true;
      let deletedPurchase = await this.allDataService.purchaseService.delete(this.purchase);
      if (deletedPurchase?.deletedStamp) {
        this.purchase = deletedPurchase;
        this.allDataService.partyService.reloadList();
        this.allDataService.itemService.reloadList();
        this.allDataService.moneyOutService.reloadList();
        this.allDataService.listForceReloadSubs.next('purchase-list');
        this.purchaseDeleteCompleted = true;
        setTimeout(() => {
          this.openPurchaseModal(false);
          this.purchase = null;
          this.isDeletePurchaseAttachedMoneyOuts = false;
          this.purchaseDeleteStarted = false;
          this.purchaseDeleteCompleted = false;
          setTimeout(() => {
            this.onPurchaseDelete.emit(deletedPurchase);
          }, 1000);
        }, 500);
      }
    } catch (error) {
      SentryUtilites.setLog("PurchaseBulkDeleteComponent:deletePurchase", error)
    }
  }

  // Bulk Purchase

   async openBulkPurchaseModal(value: boolean = true) {
    try {
      if(value) {
        if ((await this.deleteConfirm()) === false) return;
      }
      this.isBulkPurchaseModalOpen = value
      this.selectedBulkPurchases = [];
    } catch (error) {
      SentryUtilites.setLog("PurchaseBulkDeleteComponent:openBulkPurchaseModal", error)
    }
  }

  async initDeleteBulkPurchase() {
    try {
      this.selectedVar = 'BulkPurchase';
      if(!this.bulkSelectArray.includes('BulkPurchase')) {
        this.bulkSelectArray.push('BulkPurchase');
      }
      this.openTransactionPINModal();
    } catch (error) {
      SentryUtilites.setLog("PurchaseBulkDeleteComponent:initDeleteBulkPurchase", error)
    }
  }

   /**
   * 
   * @param purchase : Provide Purchase object
   * @param event : ionic CustomEventInit
   * @description : this method add checked Purchase in selectedBulkPurchases array 
   *                if unchecked it will that Purchase from selectedBulkPurchases array
   */
   addToSelectedBulkPurchaseArr(purchase: Purchase,event: CustomEventInit) {
    try {
      const index = this.selectedBulkPurchases?.findIndex(x => x?._localUUID === purchase?._localUUID);
      this.bulkPurchases?.forEach(x => {
        if(x?._localUUID === purchase?._localUUID){
          x["checked"] = event?.detail?.checked;
        }
      });
      if(event?.detail?.checked) {
        if(index == -1){
          this.selectedBulkPurchases.push(purchase);    
        }
      }else {
        if(index != -1) {
          this.selectedBulkPurchases.splice(index,1);
        }
      }
    } catch (error) {
      SentryUtilites.setLog("PurchaseBulkDeleteComponent:addToSelectedBulkPurchaseArr", error)
    }
  }

  async deleteBulkPurchase() {
    try {
      if(await this.deleteMoneyOutConfirm()){
        this.bulkPurchaseDeleteStarted = true;
        if(this.selectedBulkPurchases?.length) {
          for (let i = 0; i < this.selectedBulkPurchases.length; i++) {
            let purchase = this.selectedBulkPurchases[i];
            await this.allDataService.purchaseService.delete(purchase);
          }
        }
  
        this.allDataService.moneyOutService.reloadList();
        this.allDataService.partyService.reloadList();
        this.allDataService.itemService.reloadList();
        this.allDataService.listForceReloadSubs.next('purchase-list');
  
        this.bulkPurchaseDeleteCompleted = true;
  
        setTimeout(() => {
          this.openBulkPurchaseModal(false);
          this.bulkPurchases = [];
          this.selectedBulkPurchases = [];
          this.bulkPurchaseDeleteStarted = false;
          this.bulkPurchaseDeleteCompleted = false;
          setTimeout(() => {
            this.onBulkPurchaseDelete.emit(true);
          }, 1000);
        }, 500);
      }
    } catch (error) {
      SentryUtilites.setLog("PurchaseBulkDeleteComponent:deleteBulkPurchase", error)
    }
  }

  /**
   * 
   * @param event : ionic CustomEventInit
   * @description : this method push all Purchases in selectedBulkPurchases if select all checkbox is checked
   */
  selectAllPurchaseCheckboxes(event: CustomEventInit){
    try {
      if(this.checkAllPurchaseCheckboxes) {
        if(event?.detail?.checked) {
          this.viewFilteredList?.forEach((el)=> {el["checked"] = true});
          this.selectedBulkPurchases = [...this.viewFilteredList];
        } else {
          this.bulkPurchases?.forEach((el)=> {el["checked"] = false});
          this.viewFilteredList?.forEach((el)=> {el["checked"] = false});
          this.selectedBulkPurchases = [];
        }
      }
      this.checkAllPurchaseCheckboxes = false;
    } catch (error) {
      SentryUtilites.setLog("PurchaseBulkDeleteComponent:selectAllPurchaseCheckboxes", error)
    }
  }

   /**
   * @description: this method check user is click on checkbox or not.
   */
   selectAllPurchaseCheckboxClick() {
    this.checkAllPurchaseCheckboxes = true;
  }

  loadMoreListData(event) {
    try {
      if (this.bulkPurchases.length > 0 && this.bulkPurchases.length <= this.viewFilteredList.length) {
        let appendListLength = this.viewFilteredList.length - this.bulkPurchases.length;
        let lastEl = this.bulkPurchases[this.bulkPurchases.length - 1];
        let counter = 0;
        for (let i = 0; i < this.viewFilteredList.length; i++) {
          if (this.viewFilteredList[i]._localUUID == lastEl._localUUID) {
            counter = 1;
            continue;
          }
          if (counter > 0 && appendListLength >= 52) {
            if (counter <= 52) {
              this.bulkPurchases.push(this.viewFilteredList[i])
            } else {
              break;
            }
            counter++;
          } else if(counter > 0 && appendListLength < 52) {
            if (counter <= appendListLength) {
              this.bulkPurchases.push(this.viewFilteredList[i])
            } else {
              break;
            }
            counter++;
          }
        }
        (event as InfiniteScrollCustomEvent).target.complete();
      }
    } catch (error) {
      SentryUtilites.setLog("PurchaseBulkDeleteComponent:loadMoreListData", error)
    }
  }
}
