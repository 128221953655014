import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  showPremiumPopup = new Subject<boolean>();
  showContactSupportPopup = new Subject<boolean>();
  showMax3Creditmsg = new Subject<boolean>();
  showSideNav = new Subject<boolean>();
  isCustomerRepeated = new Subject<boolean>();

  constructor() { }

  /**
   * @description : show non premium modal
   * @param flag : provide boolean value to show non premium modal
   */
  setShowPremiumPopup(flag: boolean, showMax3Creditmsg?: boolean) {
    this.showPremiumPopup.next(flag);
    this.showMax3Creditmsg.next(showMax3Creditmsg);
  }
  // ----------------------------------------------------

  /**
   * @description : show contact support modal
   * @param flag : provide boolean value to show contact support modal
   */
  setShowContactSupportPopup(flag: boolean) {
    this.showContactSupportPopup.next(flag);
  }
  // ----------------------------------------------------

  /**
   * @description : show side nav
   * @param flag : provide boolean value to show side nav
   */
  setShowSideNav(flag: boolean) {
    this.showSideNav.next(flag);
  }
  // ----------------------------------------------------

  /**
   * @description : show repeat customer last visited for discount-1 and discount-2
   * @param flag : provide boolean value to show side nav
   */
  setCustomerRepeated(flag: boolean) {
    this.isCustomerRepeated?.next(flag);
  }
  // ----------------------------------------------------

}
