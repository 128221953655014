import { MoneyOutBillPrint } from "src/models/MoneyOutBillPrint.model";
import { commonTempData } from "./commonTempData";

export class Temp18MoneyOut {

  constructor(
    private data: MoneyOutBillPrint,
  ) {
  }
  textFontSize = 3;
  boldTextFontSize = 3.3;
  headerFontSize = 5;

  async main() {
    return `
      <style>
      ${commonTempData.invoiceHtmlTemplateCss()}
      .three-inch-holder *{
        margin:0;
        padding:0;
      }
      .three-inch-holder b{
        color:#000;
      }
      .three-inch-holder .text-sm{
        font-size:10px;
      }
      .text-3i-p{
        font-size:10px;
      }
      </style>
      <span class="invoice-template-main">
        <div 
          class="container-new arial three-inch-holder"
          style="width:70mm;background: white;padding: 2mm;padding-bottom: 70px;visibility: visible;-webkit-print-color-adjust: exact !important;color-adjust: exact !important;">
          ${this.header()}
          ${await this.templateBody()}
          ${this.footer()}
        </div>
      </span>
      `
  }

  header() {
    return `
        ${this.sellerDetails()}
        ${this.invoiceTitle()}`;

  }

  footer() {
    let domain = '';
    let includeDukanUrl = 0;
    try {
      includeDukanUrl = this.data?.settings?.include3InchOnlineDukanLink ? 1 : 0
    } catch (err) {

    }
    if (
      this.data?.onlineDukan?.domain != "") {
      domain = this.data?.onlineDukan?.domain;
    }

    return `<div style="text-align:center">
        <p style="font-size:${this.textFontSize}mm;text-align: justify;text-align-last: center;word-break:break-word" >${(this.data?.extra?.thermalTncTemplate || '').replace(/\n/g, '<br/>')}</p>
        ${domain?.length > 0 && includeDukanUrl ? `
          <p style="font-size:${this.textFontSize}mm">Now Order Online @</p>
          <p style="font-size:${this.textFontSize}mm">https://${domain}.ezo.digital</p>
        `: ''}
        
      </div>
      <div class="row" style="margin-top:20px;"><hr/></div>`;

  }

  includeTaxPercentHeader = false;
  includeHsnHeader = false;
  includeMrpHeader = false;

  async templateBody() {
    let html = '';

    html +=` 
          <div class="col-xs-12" style="text-align:center">
          <hr/>
            <b  class="text-3i-h2">
              Paid  : ${commonTempData.unitAdjuster(this.data?.bill?.totalAmount)}
            </b>
          <hr/>
          </div>
          `

    html+=`
    <div class="row">
      <hr/>
      <div class="col-xs-12 text-3i-h3" style="text-align:right">
      </div>
      `
    
    let modeOfPayment =  this.data?.bill?.txnMode || '';
    let txnNo =  this.data?.bill?.txnRef || '';
   
      html += `<div class="col-xs-12" style="text-align:right">
              ${modeOfPayment ? ` <b style="font-size:${this.boldTextFontSize}mm">Mode Of Payment : </b>
              <span style="font-size:${this.textFontSize}mm">${modeOfPayment}</span>` : ''}
              ${txnNo ? ` <br/><b style="font-size:${this.boldTextFontSize}mm">Txn No : </b>
              <span style="font-size:${this.textFontSize}mm">${txnNo}</span>` : ''}  
            </div>`;
    html += `
          
        </div>
        <div class="row">
          <hr/>
        </div>
      `

      if(this.data?.qrPayLink){
        let qrCodeBase64 = await commonTempData.generateQR(this.data?.qrPayLink);
  
        html += `
        <div style="width: 100%; margin: auto;" class="m-l-neg-10 m-l-neg-2">
          <img style="width: 100%;" src="${qrCodeBase64}">
        </div>`;
      }

    return html;
  }
  

  sellerDetails() {
    let html = "";
    if(this.data?.profile?.logoLink){
      html += `
      <div style="display: flow-root;width: 100%;">
        <img src="${this.data?.profile?.logoLink}" style="display: block;margin:auto;width:100px;height: 100px; max-width: none !important;">
      </div>
      `
    }
    let sellerProfileData = this.data?.profile;
    html += `<div style="text-align:center">
          <h3 style="font-size:${this.headerFontSize}mm;">${sellerProfileData?.legalName || ''}</h3>
          <p style="font-size:${this.textFontSize}mm">${sellerProfileData?.addressLine1 || ''},${sellerProfileData?.addressCity || ''}${sellerProfileData?.addressProvience ? ', ' + sellerProfileData?.addressProvience : ''}${sellerProfileData?.addressPostalCode ? ', ' + sellerProfileData?.addressPostalCode : ''}</p>
          <p style="font-size:${this.textFontSize}mm">${(sellerProfileData?.contactPersonPhone && sellerProfileData?.contactPersonPhone != '0') ? 'Phone: ' + sellerProfileData?.contactPersonPhone : ''}</p>
          <p style="font-size:${this.textFontSize}mm">${(sellerProfileData?.contactPersonEmail) ? 'Email: ' + sellerProfileData?.contactPersonEmail : ''}</p>
          <p style="font-size:${this.textFontSize}mm" style="word-break:break-word">${sellerProfileData?.gstin ? 'GST Number : ' + sellerProfileData?.gstin : ''}</p>
          <p style="font-size:${this.textFontSize}mm" style="word-break:break-word">${sellerProfileData?.fssaiNumber ? 'FSSAI No. : ' + sellerProfileData?.fssaiNumber : ''}</p>
          <p style="font-size:${this.textFontSize}mm" style="word-break:break-word">${sellerProfileData?.licenseNumber ? 'License No. : ' + sellerProfileData?.licenseNumber : ''}</p>
          <p style="font-size:${this.textFontSize}mm" style="word-break:break-word">
            ${
              sellerProfileData?.additionalInputFieldTitle1 
              && ((sellerProfileData?.additionalInputFieldTitle1 +"").trim()).length>0 
              && (sellerProfileData?.additionalInputFieldValue1)
              && ((sellerProfileData?.additionalInputFieldValue1 + "").trim()).length>0 ? `${sellerProfileData?.additionalInputFieldTitle1}:</b> ${sellerProfileData?.additionalInputFieldValue1}` : ''
            }
          </p>
          <p style="font-size:${this.textFontSize}mm" style="word-break:break-word">
            ${
              sellerProfileData?.additionalInputFieldTitle2 
              && ((sellerProfileData?.additionalInputFieldTitle2 +"").trim()).length>0 
              && (sellerProfileData?.additionalInputFieldValue2)
              && ((sellerProfileData?.additionalInputFieldValue2 + "").trim()).length>0 ? `${sellerProfileData?.additionalInputFieldTitle2}:</b> ${sellerProfileData?.additionalInputFieldValue2}` : ''
            }
          </p>
          <p style="font-size:${this.textFontSize}mm" style="word-break:break-word">
            ${
              sellerProfileData?.additionalInputFieldTitle3 
              && ((sellerProfileData?.additionalInputFieldTitle3 +"").trim()).length>0 
              && (sellerProfileData?.additionalInputFieldValue3)
              && ((sellerProfileData?.additionalInputFieldValue3 + "").trim()).length>0 ? `${sellerProfileData?.additionalInputFieldTitle3}:</b> ${sellerProfileData?.additionalInputFieldValue3}` : ''
            }
          </p>
          <p style="font-size:${this.textFontSize}mm" style="word-break:break-word">
            ${
              sellerProfileData?.additionalInputFieldTitle4 
              && ((sellerProfileData?.additionalInputFieldTitle4 +"").trim()).length>0 
              && (sellerProfileData?.additionalInputFieldValue4)
              && ((sellerProfileData?.additionalInputFieldValue4 + "").trim()).length>0 ? `${sellerProfileData?.additionalInputFieldTitle4}:</b> ${sellerProfileData?.additionalInputFieldValue4}` : ''
            }
          </p>
          <p style="font-size:${this.textFontSize}mm" style="word-break:break-word">
            ${
              sellerProfileData?.additionalInputFieldTitle5 
              && ((sellerProfileData?.additionalInputFieldTitle5 +"").trim()).length>0 
              && (sellerProfileData?.additionalInputFieldValue5)
              && ((sellerProfileData?.additionalInputFieldValue5 + "").trim()).length>0 ? `${sellerProfileData?.additionalInputFieldTitle5}:</b> ${sellerProfileData?.additionalInputFieldValue5}` : ''
            }
          </p>
        </div>`

    return html;

  }

  invoiceTitle() {
    let party = this.data?.bill?.partyData?.profileData || null;
    let isBillDateDiff=commonTempData.isBillDateDiff(this.data?.bill?.createdStamp,this.data?.bill?.billDateStamp);

    let html = `
        <div class="row">
          <hr/>
          <h4 style="font-size:${this.headerFontSize - 1}mm"  class="text-center">${commonTempData.getInvoiceTitle(this.data) || ''}</h4>
          <hr/>
          <div class="col-xs-12">
           <b style="font-size:${this.boldTextFontSize}mm">Receipt No :</b> <span style="font-size:${this.boldTextFontSize}mm">${this.data?.bill?.billNo || ''}</span>
          </div>
          <div class="col-xs-12">
           <b style="font-size:${this.boldTextFontSize}mm">Created on :</b> <span style="font-size:${this.boldTextFontSize}mm">${commonTempData.dateToDDMMYYYHHMMAP(this.data?.bill?.createdStamp)}</span>
          </div>
          ${isBillDateDiff?`
            <div class="col-xs-12">
            <b style="font-size:${this.boldTextFontSize}mm">Date :</b> <span style="font-size:${this.boldTextFontSize}mm">${commonTempData.dateToDDMMYYY(this.data?.bill?.billDateStamp)}</span>
            </div>
          `:''}
        </div>
        <div class="row">
          <hr/>
          <div class="col-xs-12">
            <b style="font-size:${this.boldTextFontSize}mm">Bill To :</b> 
            <span style="font-size:${this.textFontSize}mm">${party?.contactPersonName}${party?.contactPersonPhone ? ' <hr/> '+party?.contactPersonPhone : ''}</span>
            ${this.data?.bill?.isPartyDeleted ? "<hr/><p class='text-danger'>Party Has Been Deleted</p>" : "" }
            ${party?.gstNumber ? `<hr/>`+party?.gstNumber+`<hr/>` :''}
          </div>
          
        </div>`;

        if(party?.addressLine1){
          html+=`
          <div class="row">
          <div class="col-xs-12">
            <b style="font-size:${this.boldTextFontSize}mm">Address : </b> <span style="font-size:${this.textFontSize}mm">${party?.addressLine1 ? party?.addressLine1 : ''} ${party?.addressState ? ', ' + party?.addressState : ''}${party?.addressPincode ? ', ' + party?.addressPincode : ''}</span>
          </div>
          </div>`
        }

      html += `
        <div class="row">
          <hr/>
        </div>
      `;

    return html;
  }


}


