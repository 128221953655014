import { AccessTo } from "./AccessTo.model";
import { BaseModel } from "./BaseModel.model";
export class IProfile {
  static SCHEMA_NAME = 'profile';
}
export class Profile extends BaseModel {

  // This is profiles name, not to be used for billing purpose
  // eg. GST Account / Non-GST Account
  profileName: string;

  // This is name used for billing
  // eg. Ganesh Enterprises
  legalName?: string;

  // By default its value will be same as login phone
  // This phone will be used in billing
  // Login number and contactPersonPhone can be different
  // This is used for multiple outlets
  contactPersonPhone?: string;

  contactPersonName?: string;
  contactPersonEmail?: string;
  addressLine1?: string;
  addressLine2?: string;
  addressCity?: string;
  addressProvience?: string;
  addressCountry?: string;

  // Postal Code is a numeric string
  // Its datatype is always string, but only digits will be accepted
  addressPostalCode?: string;

  gstin?: string;
  fssaiNumber?: string;
  licenseNumber?: string;
  bankAccountNo?: string;
  bankName?: string;
  bankIFSC?: string;
  bankAccountType?: string;
  bankUPI?: string;

  // Additional Fields 
  additionalInputFieldTitle?: string;
  additionalInputFieldValue?: string;
  additionalInputFieldTitle1?: string;
  additionalInputFieldValue1?: string;
  additionalInputFieldTitle2?: string;
  additionalInputFieldValue2?: string;
  additionalInputFieldTitle3?: string;
  additionalInputFieldValue3?: string;
  additionalInputFieldTitle4?: string;
  additionalInputFieldValue4?: string;
  additionalInputFieldTitle5?: string;
  additionalInputFieldValue5?: string;

  additionalDateFieldValue?: string;
  additionalDateFieldTitle?: string;

  // This will be file server link
  // This key is used for syncing purpose only
  // Actual image will be available in device local dataset
  signatureLink?: string;

  luckyImageLink?: string;

  // This will be file server link
  // This key is used for syncing purpose only
  // Actual image will be available in device local dataset
  logoLink?: string;

  // This is text template for sale with variables custom syntax
  // This template will be auto populated in sale form while creating and not when edited
  // eg.
  /*
      1. All items sold will not be returned,refunded
      2. Payment shall be made in the name of __LEAGAL_NAME__
      3. __LEGAL_NAME__ is not liable for loss of item.
  */
  /*
      LegalName = "Ganesh Enterpises"

      1. All items sold will not be returned,refunded
      2. Payment shall be made in the name of Ganesh Enterpises
      3. Ganesh Enterpises is not liable for loss of item.
  */
  // This should accept emojies, bold, italics, tab spacing, underline
  saleFooterTemplate?: string;
  purchaseFooterTemplate?: string;
  moneyInFooterTemplate?: string;
  moneyOutFooterTemplate?: string;
  ezoIndustry?: string;

  // Access Control
  accessTo?: AccessTo[];

  // Categories
  expenseCategories?: string[];
  ingredientCategories?: string[];

  //Settings
  aAppDashboardPrivacyStatus?: boolean; //dashboard*
  aAppPin?: string; //TODO
  aAppPinStatus?: boolean; //6.1*

  
  aSetBirthdayReminderStatus?: boolean; //8.4 - todo
  
  iSetItemSelectorStyle?: 'List' | 'Restaurant' | 'RestaurantImage'; //2.1*
  iSetItemSelectorStyleWeb?: 'List' | 'Restaurant' | 'RestaurantImage'; //2.1*
  iSetCalculatorBillingStatus?: boolean; //2.3*
  iSetCalculatorBillingSellPriceStatus?: boolean; //2.3*
  iSetCalculatorBillingQuantityStatus?: boolean; //2.3*
  iSetAutoCashSaleStatus?: boolean; //2.4*
  iSetAutoMoneyInStatus?: boolean; //2.5*
  iSetAutoNextBillStatus?: boolean; //2.6*
  iSetAutoPreviousSellPriceStatus?: boolean; //2.7*
  iSetItemsSortByCodeStatus?: boolean; //2.8*
  iSetShowItemCategoryListStatus?: boolean; //2.9*
  iSetLockSellPriceStatus?: boolean; //2.10*
  iSetLockNegativeStockStatus?: boolean; //2.11*
  iSetBillBySalePersonStatus?: boolean; //2.12*
  iSetItemSelectorColumns?: number; //2.17*
  iSetItemCategoryListWidth?: string; //2.16*
  iSetItemPriceVariationsStatus?: boolean; //2.17*
  iSetAlphaNumericBarcodeStatus?: boolean; //2.18*
  iSetAutoPreviousBalanceStatus?: boolean; //2.24*
  iSetServiceChargePercentage?: number; //2.25*
  iSetItemPriceHistoryStatus?: boolean; //2.26*
  iSetOutOfStockHideStatus?: boolean; //2.27*
  iSetQuickSaleStatus?: boolean; //2.28*
  iSetShowStockStatus?: boolean; //2.29*
  iSetPrintTimeOnBill?: boolean = true; //2.30
  iSetPrintMBMA?: boolean = true; //2.30

  // Abbreviation Match
  isAbbrivationSearchEnabled?: boolean; //2.30
  //-----------------------------------------------------------------------

  // Select Item Filter Priority
  isStartsWithSearchPrioritized?: boolean = true //2.31
  //-----------------------------------------------------------------------
  
  pSetAutoPrintStatus?: boolean; //5.1*
  pSetLogoPrintStatus?: boolean; //5.2*
  pSetQrPrintStatus?: boolean; //5.3*
  pSetAlwaysQrPrintStatus?:boolean //5.3.1*
  pSetTokenNoPrintStatus?: boolean; //5.4*
  pSetTaxMrpHsnPrintStatus?: boolean; //5.5*
  pSetTermsAndConditions?: string; //5.8*
  pSetTermsAndConditionsPrintStatus?: boolean; //5.8*

  pSetPoweredByEzoStatus?: boolean; //7.2*
  pSetDomainByEzoStatus?: boolean; //7.3*
  discountSoundStatus?: boolean // 7.8
  iSetRoundOffTotalAmountStatus?: boolean; //7.10
  aAppCutOffDayStatus?: boolean; //7.14
  aAppEnableOnlineBillingStatus?: boolean; // 7.15

  iSetColorPartySelectorSaleHold?: string; //7.12
  iSetColorPartySelectorSaleKotPending?: string; //7.12
  iSetColorPartySelectorSelectedParty?: string; //TODO
  iSetColorItemSelectorSelectedItem?: string; //7.12
  iSetBillAmountSoundStatus?: boolean; //TODO

  dSetDiscountStatusI?: boolean; //9.1*
  dSetDiscountOfferTypeI?: number; //9.2*
  dSetDiscountNameI?: string; //TODO
  dSetDiscountPercentI?: number; //TODO
  dSetDiscountMaximumAmountI?: number; //TODO
  dSetDiscountMinimumAmountI?: number; //TODO
  dSetDiscountExpiryDaysI?: number; //TODO

  dSetDiscountStatusII?: boolean; //10.1
  dSetDiscountOfferTypeII?: number; //10.2
  dSetDiscountNameII?: string; //TODO
  dSetDiscountPercentII?: number; //TODO
  dSetDiscountMaximumAmountII?: number; //TODO
  dSetDiscountMinimumAmountII?: number; //TODO
  dSetDiscountExpiryDaysII?: number; //TODO
  dSetDiscountPrintStatus?: boolean; //TODO

  //-----------------------------------------------------------------------

  // Item Form Settings

  itSetEnableMRP?: boolean;
  itSetEnableTaxExclusiveSellPrice?: boolean;
  itSetEnableCessTax?: boolean;
  itSetEnableBarcode?: boolean;
  itSetEnableItemDescription?: boolean;
  itSetEnableItemCode?: boolean;
  itSetEnableLowStockAlert?: boolean;
  itSetEnableStotageLocation?: boolean;
  itSetEnableBulkUnit?: boolean;
  itSetEnableCategory?: boolean;
  itSetEnableImages?: boolean;
  itSetEnableOnlineDukkanItem?: boolean;
  itSetEnableWholesaleRate?: boolean;
  itSetEnableIngredient?: boolean; //7.15

  //-----------------------------------------------------------------------

  // Messaging campaign

  mcSendMessageToParty?: boolean; //8.1
  mcSendMessageToOwner?: boolean; //8.2
  mcSendReminderMarketingMessageDays?: number; //8.3

  //-----------------------------------------------------------------------

  // Staff Setting
  iSetAssignStaffToTheSale?: boolean;
  //-----------------------------------------------------------------------

}

//shared preferences
// 2.2 barcode localstorage  - item_barcode_scanner
// 2.14 barcode - item_barcode_scanner_speed
// 3 -
// 4 -
// 5.6 -
// 5.7 -
// 5.9 -
// PendingKotSoundStatus("pending_kot_sound_status") 7.7,


// SETTINGS | INVOICE --------------------------------------------------------------------------
// BillSettingItemBarcodeScannerStatus("item_barcode_scanner"),
// BillSettingItemBarcodeScannerSpeed("item_barcode_scanner_speed"),
//----------------------------------------------------------------------------------------------

// SETTINGS | PRINTER --------------------------------------------------------------------------
// PrinterSettingPrinterTypeI("pSetPrinterTypeI"),
// PrinterSettingPrinterAddressI("pSetPrinterAddressI"),
// PrinterSettingPrinterDotsI("pSetPrinterDotsI"),
// PrinterSettingPrinterCharsI("pSetPrinterCharsI"),
// PrinterSettingPrinterColumn2CharsI("pSetPrinterColumn2CharsI"),
// PrinterSettingPrinterColumn3CharsI("pSetPrinterColumn3CharsI"),
// PrinterSettingPrinterColumn4CharsI("pSetPrinterColumn4CharsI"),
// PrinterSettingPrinterLineHeightI("pSetPrinterLineHeightI"),
// PrinterSettingPrinterTypeII("pSetPrinterTypeII"),
// PrinterSettingPrinterAddressII("pSetPrinterAddressII"),
// PrinterSettingPrinterDotsII("pSetPrinterDotsII"),
// PrinterSettingPrinterCharsII("pSetPrinterCharsII"),
// PrinterSettingPrinterColumn2CharsII("pSetPrinterColumn2CharsII"),
// PrinterSettingPrinterColumn3CharsII("pSetPrinterColumn3CharsII"),
// PrinterSettingPrinterColumn4CharsII("pSetPrinterColumn4CharsII"),
// PrinterSettingPrinterLineHeightII("pSetPrinterLineHeightII"),
//----------------------------------------------------------------------------------------------

// SETTINGS | PRINT ----------------------------------------------------------------------------
// PrintSettingRegionalLanguagePrintStatus("pSetRegionalLanguagePrint"),
// PrintSettingRegionalLanguagePrintFontSize("pSetRegionalLanguagePrintFontSize"),
// PrintSettingPrinterSpacingFixStatus("pSetPrinterSpacingFix"),
//----------------------------------------------------------------------------------------------

// APP SETTINGS --------------------------------------------------------------------------------
// SaleTutorialStatus("sale_tutorial_status"),
// TimeCutStatus("time_cut_status"),
// HorizontalViewStatus("horizontal_view_status"),
// TelpoSelectionStatus("telpo_selection_status"),
// RockchipSelectionStatus("rockchip_selection_status"),
//----------------------------------------------------------------------------------------------
