<ion-grid>

  <form [formGroup]="form">

    <ion-row class="custom-row">

      <!-- Ingredient Name -->

      <ion-col class="custom-col" size-lg="8" size-xs="12">
        <ion-button fill="clear" class="btn-title btn-txt-black">
          Raw Material Name <span class="span-mandatory">*</span>
        </ion-button>
        <ion-input
          #itemNameEle
          class="new-ion-input"
          placeholder="Example: Cashew"
          formControlName="name"
        ></ion-input>
      </ion-col>

      <!-- -------------------------------------------------------------------------------- -->

      <!-- Unit -->

      <ion-col class="custom-col" size-lg="1.5" size-xs="6">
        <ion-button fill="clear" class="btn-title btn-txt-black">
          Unit <span class="span-mandatory">*</span>
        </ion-button>
        <ion-select
          [interfaceOptions]="{header: 'Unit'}"
          class="new-ion-select"
          [disabled]="paramDocumentId?.length > 0"
          interface="alert"
          placeholder="Select Unit"
          formControlName="unit"
        >
          <ion-select-option
            *ngFor="let unit of unitsOptions|keyvalue : returnZero"
            [value]="unit.key"
          >
            {{ unit.value }}
          </ion-select-option>
        </ion-select>
      </ion-col>

      <!-- -------------------------------------------------------------------------------- -->

      <!-- Add Custom Unit -->

      <ion-col class="custom-col" size-lg="2.5"  size-xs="6">
        <ion-button fill="clear" class="btn-title btn-txt-black">
          Add Custom Unit
        </ion-button>
        <ion-input 
          #customUnit
          [disabled]="paramDocumentId?.length > 0"
          class="new-ion-input add-custom-unit "
          placeholder="Example: Kilograms"
        ></ion-input>
        <ion-button [disabled]="!customUnit?.value && paramDocumentId?.length > 0" class="btn-save-small" size="small" color="primary" (click)="addCustomUnit()">Add</ion-button>
      </ion-col>

      <!-- -------------------------------------------------------------------------------- -->

    </ion-row>

    <ion-row class="custom-row">

      <!-- Category -->

      <ion-col class="custom-col" size-lg="4" size-xs="6">
        <ion-button fill="clear" class="btn-title btn-txt-black">
          Category
        </ion-button>
        <ion-select
          [interfaceOptions]="{header: 'Category'}"
          class="new-ion-select"
          interface="alert"
          placeholder="Select Category"
          formControlName="category"
        >
          <ion-select-option
            *ngFor="let category of categories"
            [value]="category"
          >
            {{ category }}
          </ion-select-option>
        </ion-select>
      </ion-col>

      <!-- -------------------------------------------------------------------------------- -->

      <!-- Add Custom Category -->

      <ion-col class="custom-col" size-lg="4"  size-xs="6">
        <ion-button fill="clear" class="btn-title btn-txt-black">
          Add Custom Category
        </ion-button>
        <ion-input
          #categoryEle
          class="new-ion-input add-custom-unit "
          placeholder="Example: Dry Fruits"
        ></ion-input>
        <ion-button [disabled]="!categoryEle?.value" class="btn-save-small" size="small" color="primary" (click)="addCategory()">Add</ion-button>
      </ion-col>

      <!-- -------------------------------------------------------------------------------- -->

      <!-- Low Stock Alert -->

      <ion-col class="custom-col left-devider" size-xs="4"  size-xs="12">
        <ion-button fill="clear" class="btn-title btn-txt-black">
          Low Stock Alert
        </ion-button>
        <ion-input
          class="new-ion-input "
          placeholder="Example: 10"
          type="number"
          formControlName="minStock"
        ></ion-input>
      </ion-col>

      <!-- -------------------------------------------------------------------------------- -->

    </ion-row>

    <ion-row class="custom-row">

      <!-- Description -->

      <ion-col class="custom-col" size="12">
        <ion-button fill="clear" class="btn-title btn-txt-black">
          Description
        </ion-button>
        <ion-textarea
          class="new-ion-textarea "
          rows="3"
          placeholder="Example: Natural Premium Nutritious & Delicious High Protein Cashews."
          formControlName="description"
        ></ion-textarea>
      </ion-col>

      <!-- -------------------------------------------------------------------------------- -->

    </ion-row>

    <ion-row class="custom-row" *ngIf="imagesBase64?.length">

      <!-- Add Image -->

      <ion-col class="custom-col" size="12">
        <ion-button fill="clear" class="btn-title btn-txt-black">
          Images
        </ion-button>
      </ion-col>
      <ion-col class="custom-col" size="2" *ngFor="let imageData of imagesBase64">
        <ion-item class="item-image" lines="none">
          <img class="img-logo" [src]="imageData?.base64">
          <ion-icon class="img-remove-icon" color="danger" name="close-circle" (click)="removeImage(imageData?.imageUUID)"></ion-icon>
        </ion-item>
      </ion-col>

      <!-- -------------------------------------------------------------------------------- -->

    </ion-row>

    <ion-row>
      <ion-col size-lg="2"  size-xs="12">
        <ion-button expand="full" (click)="openImageCropper()"> Add Image </ion-button>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size-xs="12" size="12">
        <ion-button class="btn-save-item" expand="full" [disabled]="form?.invalid || isDisableSave" (click)="onSaveClick()">Save Raw Material</ion-button>
      </ion-col>
    </ion-row>

  </form>


</ion-grid>

<app-image-cropper
  *ngIf="isOpenImageCropper"
  (imageSaved)="onImageSaved($event)"
  (modalClosed)="openImageCropper($event ? false : true)"
>
</app-image-cropper>