<ion-content>

  <ion-grid>

    <ion-row>
      <ion-col size="12">
        <ion-label color="danger">
          You don't have an access to this page.
        </ion-label>
      </ion-col>
      <ion-col size="12">
        <ion-button (click)="navigateToSaleForm()">
          {{isUserLoggedIn ? 'Dashboard' : 'Login'}}
        </ion-button>
      </ion-col>
    </ion-row>

  </ion-grid>

</ion-content>
