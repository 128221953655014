<ion-header>
  <ion-toolbar class="{{ getHeaderColorClass() }}">
    <app-navigation-control slot="start" type="purchase/form"></app-navigation-control>
    <ion-title>Create Purchase</ion-title>
    <ion-chip class="chip-barcode" slot="end" color="tertiary">
      <span class="title">Barcode Scanner Mode</span>
      <span>
        <ion-checkbox
          slot="end"
          color="tertiary"
          [checked]="barcodeScannerMode"
          (ionChange)="onBarcodeScannerModeChange($event)"
        >
        </ion-checkbox>
      </span>
    </ion-chip>
    <ion-chip
      slot="end"
      fill="clear"
      class="btn-item-name padding-start-none"
      color="primary"
      (click)="openSettingsModal()"
    >
      <ion-icon name="settings-sharp"></ion-icon>
    </ion-chip>
  </ion-toolbar>
</ion-header>

<ion-content>

  <!-- bill date modal start -->
  <ion-modal 
    class="date-modal" 
    [keepContentsMounted]="keepContentsMounted" 
    [isOpen]="isOpenBillDatePicker" 
    (willDismiss)="closeDOBPicker()">
    <ng-template>
      <ion-datetime
        appYearDefaultBtn   
        [value]="dpBillDateValue"
        [showDefaultButtons]="true"
        [max]="maxDate"
        (ionChange)="onBillDateSelect($event)"
        presentation="date"
      >
        <span slot="title">Select a Bill Date</span>
      </ion-datetime>
    </ng-template>
  </ion-modal>
  <!-- bill date modal end -->

  <!-- E way bill date modal start -->
  <ion-modal 
    class="modal-transport-date"
    [keepContentsMounted]="keepContentsMounted"
    [isOpen]="isOpenEwayBillDatePicker"
    (willDismiss)="closeDOBPicker()">
    <ng-template>
      <ion-datetime 
        appYearDefaultBtn 
        [value]="dpEWayBillDateValue" 
        [min]="minDateTransport" 
        [showDefaultButtons]="true" 
        presentation="date" 
        [max]="maxDate"
        (ionChange)="onEwayBillDateSelect($event)">
      </ion-datetime>
    </ng-template>
  </ion-modal>
  <!-- E way bill date modal end -->

  <!-- delivery date modal start -->
  <ion-modal 
    class="modal-transport-date"
    [keepContentsMounted]="keepContentsMounted"
    [isOpen]="isOpenDeliveryDatePicker"
    (willDismiss)="closeDOBPicker()">
    <ng-template>
      <ion-datetime 
        appYearDefaultBtn 
        [value]="dpDeliveryDateValue" 
        [min]="minDateTransport" 
        [showDefaultButtons]="true" 
        presentation="date" 
        [max]="maxDate"
        (ionChange)="onDeliveryDateSelect($event)">
      </ion-datetime>
    </ng-template>
  </ion-modal>
  <!-- delivery date modal end -->

  <ion-grid>

    <form [formGroup]="form">

      <ion-row>
        <ion-col size="5">
          <ion-row class="bill-dates-holder">
            <ion-col size="6">
              <ion-button fill="clear" class="btn-bill-dates-title btn-txt-black">
                Purchase No.
              </ion-button>
              <ion-input class="new-ion-input" [disabled]="paramDocumentId ? true : false" placeholder="eg. PUR_001" formControlName="billNo"></ion-input>
            </ion-col>
            <ion-col size="5">
              <ion-row>
                <ion-col size="6">
                  <ion-button fill="clear" class="btn-bill-dates-title btn-txt-black">
                    Bill Date
                  </ion-button>
                </ion-col>
                <ion-col size="6">

                  <!-- bill date input start -->
                  <input 
                    type="button" 
                    class="date-input"
                    [value]="setDateValue(form?.value?.billDateStamp || todayDate)"
                    (click)="isOpenBillDatePicker = true;">
                  <!-- bill date input end -->

                </ion-col>
              </ion-row>
            </ion-col>
          </ion-row>
        </ion-col>

        <ion-col size="7">
          <app-party-selector
            *ngIf="listStyleView"
            #partySelector
            (partySelectedEvent)="onPartySelect($event)"
          >
          </app-party-selector>
          <app-party-restaurant-selector
            *ngIf="!listStyleView"
            #partyRestaurantSelector
            [noPartySelectError]="noPartySelectError"
            (partySelectedEvent)="onPartySelect($event)"
          >
          </app-party-restaurant-selector>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="5">

        </ion-col>
        <ion-col size="7">
          <ion-row class="delivery-state-holder">
            <ion-col>
              <ion-button 
                fill="clear" 
                class="btn-title btn-clear-txt-only btn-txt-black">
                Delivery State
              </ion-button>
              <ion-select 
                [interfaceOptions]="{header: 'Delivery State'}" 
                class="new-ion-select delivery-state-select" 
                interface="alert" 
                placeholder="Select" 
                formControlName="deliveryProvience">
                <ion-select-option 
                  *ngFor="let state of states|keyvalue : returnZero" 
                  [value]="state.key"
                  [disabled]="isDisabledState(state?.value)">
                  {{ state.value }}
                </ion-select-option>
              </ion-select>
            </ion-col>
          </ion-row>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size-xs="12" size-lg="12">
          <app-item-selector
            *ngIf="listStyleView"
            #itemSelector
            [isPurchase]="true"
            [barcodeScannerMode]="barcodeScannerMode"
            (itemAdded)="populateBillItems($event)"
            (focusAmountReceived)="focusAmountPaid($event)"
          >
          </app-item-selector>
          <app-item-restaurant-selector
            *ngIf="!listStyleView"
            #itemRestaurantSelector
            [isPurchase]="true"
            [amountReceivedChecked]="amountPaidChecked"
            [amountReceivedAmount]="form?.value?.amountPaid"
            [sortByItemCodeAsc]="selectedProfile?.iSetItemsSortByCodeStatus"
            [showBookmarkList]="selectedProfile?.iSetShowItemCategoryListStatus"
            [lockSellPrice]="selectedProfile?.iSetLockSellPriceStatus"
            [negativeStockLock]="selectedProfile?.iSetLockNegativeStockStatus"
            [barcodeScannerMode]="barcodeScannerMode"
            [paramDocumentId]="paramDocumentId"
            [paymentMode]="paymentMode"
            [paymentId]="paymentIdEle?.value"
            [totalAmount]="form?.value?.totalAmount"
            [party]="form?.value?.party"
            [iSetItemPriceHistoryStatus]="iSetItemPriceHistoryStatus"
            [iSetOutOfStockHideStatus]="iSetOutOfStockHideStatus"
            [iSetQuickSaleStatus]="selectedProfile?.iSetQuickSaleStatus"
            [iSetShowStockStatus]="selectedProfile?.iSetShowStockStatus"
            (itemAdded)="populateBillItems($event)"
            (savePrintTrigger)="savePrint()"
            (onlySaveTrigger)="onSaveClick()"
            (amountReceivedCheckedChange)="onAmountPaidCheckedChange($event)"
            (amountReceivedAmountChange)="populateAmountPaid($event)"
            (paymentModeChange)="onItemSelectorPaymentModeChange($event)"
            (paymentIdChange)="setPaymentId($event)"
            (barcodeChangeTrigger)="populateBarcodeScannerSetting()"
          >
          </app-item-restaurant-selector>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size-xs="12" size-lg="12">
          <!-- <app-transport-details
            (savedData)="populateTransportDetails($event)"
            [populateData]="form?.value?.transportDetail"
          >
          </app-transport-details> -->

          <!-- transport detail toggle start -->
          <ion-row>
            <ion-col>
              <ion-button fill="outline" class="dashed-border" (click)="toggleTransportDetailsFields()">
                {{ showTransportDetailsCard ? 'Less Transport Details' : 'More Transport Details' }}
                <ion-icon *ngIf="!showTransportDetailsCard" name="chevron-down-outline"></ion-icon>
                <ion-icon *ngIf="showTransportDetailsCard" name="chevron-up-outline"></ion-icon>
              </ion-button>
            </ion-col>
          </ion-row>
          <!-- transport detail toggle end -->

          <ion-row class="transport-details-card" formGroupName="transportDetail" *ngIf="showTransportDetailsCard">
            <ion-col size-lg="6">
              <ion-item lines="none">
                <ion-label>Labour/Transport Charges</ion-label>
                <ion-input
                  inputmode="numeric"
                  type="number"
                  formControlName="additionalAmount"
                ></ion-input>
              </ion-item>
              <ion-item lines="none">
                <ion-label>Purchase Order Number</ion-label>
                <ion-input
                  formControlName="purOrderNo"
                ></ion-input>
              </ion-item>
              <ion-item lines="none">
                <ion-label>Challan Number</ion-label>
                <ion-input
                  formControlName="challanNo"
                ></ion-input>
              </ion-item>
              <ion-item lines="none">
                <ion-label>E Way Bill Number</ion-label>
                <ion-input
                  formControlName="eWayBillNo"
                ></ion-input>
              </ion-item>
              <ion-item lines="none">
                <ion-label>E Way Bill Date</ion-label>

                <!-- E way bill date input start -->
                <input 
                  type="button"
                  class="transport-date-input"
                  [value]="setDateValue(form?.value?.transportDetail?.eWayBillDate || todayDate)"
                  (click)="isOpenEwayBillDatePicker = true;">
                <!-- E way bill date input end -->

              </ion-item>
            </ion-col>

            <ion-col size-lg="6">
              <ion-item lines="none">
                <ion-label>Transporter Name</ion-label>
                <ion-input
                  formControlName="transporterName"
                ></ion-input>
              </ion-item>
              <ion-item lines="none">
                <ion-label>Vehicle Number</ion-label>
                <ion-input
                  formControlName="vehicleNumber"
                ></ion-input>
              </ion-item>
              <ion-item lines="none">
                <ion-label>Transport Distance</ion-label>
                <ion-input
                  formControlName="transDistance"
                ></ion-input>
              </ion-item>
              <ion-item lines="none">
                <ion-label>Delivery Location</ion-label>
                <ion-input
                  formControlName="deliveryLocation"
                ></ion-input>
              </ion-item>
              <ion-item lines="none">
                <ion-label>Delivery Date</ion-label>

                <!-- delivery date input start -->
                <input 
                  type="button"
                  class="transport-date-input"
                  [value]="setDateValue(form?.value?.transportDetail?.deliveryDate || todayDate)"
                  (click)="isOpenDeliveryDatePicker = true;">
                <!-- delivery date input end -->

              </ion-item>
            </ion-col>

            <ion-col size-lg="12">
              <ion-item lines="none">
                <ion-label>Terms & Conditions</ion-label>
              </ion-item>
              <ion-item lines="none">
                <ion-textarea
                  rows="5"
                  placeholder="Write Your Terms & Conditions Here"
                  formControlName="tnc"
                ></ion-textarea>
              </ion-item>
            </ion-col>
          </ion-row>

        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col offset="0" offset-xl="4" size="6" size-xl="4">
          <ion-item lines="none">
            <ion-label>Cash Discount ( In % )</ion-label>
            <ion-input
              type="number"
              formControlName="cashDiscountPercentage"
              (ionBlur)="calculateCashDiscount(CalculateBillTask.DISCOUNT_FROM_PERCENTAGE)"
              (keyup.enter)="calculateCashDiscount(CalculateBillTask.DISCOUNT_FROM_PERCENTAGE)"
            ></ion-input>
          </ion-item>
          <ion-item lines="none">
            <ion-label>Cash Discount ( In ₹ )</ion-label>
            <ion-input
              type="number"
              formControlName="cashDiscount"
              (ionBlur)="calculateCashDiscount(CalculateBillTask.DISCOUNT_FROM_AMOUNT)"
              (keyup.enter)="calculateCashDiscount(CalculateBillTask.DISCOUNT_FROM_AMOUNT)"
            ></ion-input>
          </ion-item>
        </ion-col>
        <ion-col size="6" size-xl="4">
          <ion-item lines="none">
            <ion-label>Total Amount</ion-label>
            <ion-input
              readonly="true"
              formControlName="totalAmount"
              type="number"
            ></ion-input>
          </ion-item>
          <ion-item lines="none" *ngIf="!paramDocumentId">
            <ion-label>Amount Paid</ion-label>
            <ion-checkbox
              class="cb-amount-received"
              [checked]="amountPaidChecked"
              (ionChange)="onAmountPaidCheckedChange($event)"
              [disabled]="isDisableAmountPaid || !form?.value?.billItems?.length"
            >
            </ion-checkbox>
            <ion-input
              #amountReceivedEle
              formControlName="amountPaid"
              type="number"
              [disabled]="!form?.value?.billItems?.length"
              (keyup.enter)="(form?.valid && form?.value?.billItems?.length) ? onSaveClick() : null"
              (keydown.arrowUp)="amountPaidSetBlur($event)"
              (keydown.arrowDown)="amountPaidSetBlur($event)"
            ></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>

      <ion-row *ngIf="paramDocumentId || (!isDisableAmountPaid && amountPaidChecked && form?.value?.billItems?.length)">
        <ion-col offset-xl="8" offset="6">
          <ion-item lines="none">
            <ion-segment mode="ios" [value]="paymentMode" (ionChange)="setPaymentMode($event)">
              <ion-segment-button value="cash">
                <ion-label>Cash</ion-label>
              </ion-segment-button>
              <ion-segment-button value="cheque">
                <ion-label>Cheque</ion-label>
              </ion-segment-button>
              <ion-segment-button value="bank">
                <ion-label>Bank</ion-label>
              </ion-segment-button>
            </ion-segment>
          </ion-item>
          <ion-item lines="none" *ngIf="paymentMode=='cheque' || paymentMode=='bank'">
            <ion-input
              #paymentIdEle
              class="input-payment-id"
              placeholder="Enter Payment Refrence Id"
              (ionChange)="setPaymentId($event['detail']['value'])"
            ></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>

      <ion-row *ngIf="paramDocumentId">
        <ion-col size-xs="12" size-lg="12">
          <app-money-out-selector
            *ngIf="fetchedPurchase"
            #moneyOutSelector
            [purchase]="fetchedPurchase"
            [isPurchaseForm]="true"
            [billTotalAmount]="this.form.value.totalAmount"
            [moneyOutCart]="this.fetchedPurchase?.moneyOuts"
            (moneyOutsSelectedEvent)="onMoneyOutCartUpdate($event)"
          >
          </app-money-out-selector>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size-xs="12" size-lg="12">
          <ion-item lines="none">
            <ion-label>Purchase Note</ion-label>
          </ion-item>
          <ion-item lines="none">
            <ion-textarea
              rows="2"
              placeholder="Write Your Note Here"
              formControlName="note"
            ></ion-textarea>
          </ion-item>
        </ion-col>
      </ion-row>

      <ion-row class="last-row">
        <ion-col size-xs="12" size-lg="12">
        </ion-col>
      </ion-row>


      <div class="div-bottom-save-btns">
        <ion-button
          class="flt-rght btn-save"
          color="primary"
          [disabled]="form?.invalid || !form?.value?.billItems?.length || !form?.value?.totalAmount"
          (click)="savePrint()"
        >
          SAVE & PRINT ({{ form?.value?.totalAmount | currency: 'INR' }})
        </ion-button>
        <ion-button
          class="flt-rght btn-save"
          color="tertiary"
          [disabled]="form?.invalid || !form?.value?.billItems?.length || !form?.value?.totalAmount"
          (click)="onlySave()"
        >
          ONLY SAVE ({{ form?.value?.totalAmount | currency: 'INR' }})
        </ion-button>

        <!-- <ion-button
          class="flt-rght btn-save"
          color="primary"
          [disabled]="form?.invalid || !form?.value?.billItems?.length"
          (click)="onSaveClick()"
        >
          SAVE ({{ form?.value?.totalAmount | currency: 'INR' }})
        </ion-button> -->
      </div>

    </form>

  </ion-grid>


</ion-content>

<app-settings
  #settingsModal
  (onSave)="onSettingSave()"
>
</app-settings>