<ion-toolbar [style.padding-bottom]="isFilterOptionOpen &&  innerWidth < 1080? '32px' : '0px'">
  <ion-searchbar #searchBar placeholder="Search by item name, item code..."
    (ionChange)="onFilterChange($event['detail'].value)">
  </ion-searchbar>

</ion-toolbar>

<ion-button [style.top]="!isSalePurchasePrice ? '84px' : ''" class="btn-show-hide" (click)="toggleFilterOptions()">
  {{isFilterOptionOpen ? 'Hide Filter' : 'Show Filter'}}
</ion-button>

<ion-fab class="btn-filter-by" #filterByFab
  [style.top]="!isSalePurchasePrice && innerWidth > 1080 ? '80px' : !isSalePurchasePrice && innerWidth <= 1080 ? '116px' : ''"
  *ngIf="isFilterOptionOpen">
  <ion-fab-button class="first-fab-button" size="small" (click)="closeSort()" (clickOutSide)="closeclickOutSideFilter()">
    Filter By
  </ion-fab-button>
  <ion-fab-list 
    side="bottom" 
    [ngStyle]="{'max-height': fabListHeight}"
    id="scroll-category-container"
    >
    <ion-fab-button [show]="true" [ngClass]="{'filter-sort-active': !appliedCategory}" (click)="onCategoryChange('')">
      All ({{ categoryWiseItemCount['All'] || 0 }})
    </ion-fab-button>
    <ion-fab-button [show]="true" [ngClass]="{'filter-sort-active': category===appliedCategory}" *ngFor="let category of categories"
      (click)="onCategoryChange(category)">
      {{ category }} ({{ categoryWiseItemCount[category] || 0 }})
    </ion-fab-button>
  </ion-fab-list>
</ion-fab>

<ion-fab class="btn-sort-by" #sortByFab
  [style.top]="!isSalePurchasePrice && innerWidth > 1080 ? '80px' : !isSalePurchasePrice && innerWidth <= 1080 ? '116px' : ''"
  *ngIf="isFilterOptionOpen">
  <ion-fab-button class="first-fab-button" size="small" (click)="closeFilter()" (clickOutSide)="closeclickOutSideSort()">
    Sort By
  </ion-fab-button>
  <ion-fab-list side="bottom">
    <ion-fab-button [ngClass]="{'filter-sort-active': sort?.key===selectedFilter}"
      *ngFor="let sort of sortObj|keyvalue: returnZero" (click)="onSortChnage(sort.key)">
      {{ sort?.value }}
    </ion-fab-button>
  </ion-fab-list>
</ion-fab>

<ion-grid class="all-list" [style.margin-top]="isFilterOptionOpen &&  innerWidth < 1080? '80px' : '50px'">

  <ion-row class="party-item-custom-row" *ngFor="let item of viewFilteredList"
    [style.padding-right]="!isSalePurchasePrice ? '20px' : ''">

    <ion-col size="9" (click)="emitElement(item)">
      <ion-button fill="clear" class="btn-party-item-name">
        {{ shortStr(item?.itemName,85) }}
      </ion-button>
    </ion-col>

    <ion-col size-xs="2.8" size-xl="3" (click)="emitElement(item)">
      <ion-button [color]="item?.stock>0 ? 'success' : 'danger'" fill="clear"
        class="btn-stock txt-transform-normal flt-rght">
        Stock: {{ item?.stock | number: '1.2-2'}}
      </ion-button>
    </ion-col>

    <ion-col size-md="8.6" size-xs="7" (click)="emitElement(item)">
      <ion-button *ngIf="isSalePurchasePrice || (!isSalePurchasePrice && !isPurchase)" color="primary" fill="clear"
        class="btn-sell-price">
        <span class="label-note txt-transform-normal">Sell Price: </span>{{ item?.sellPrice || 0 | currency:'INR' }}
      </ion-button>
      <ion-button *ngIf="isSalePurchasePrice || (!isSalePurchasePrice && isPurchase)" color="primary" fill="clear"
        class="btn-sell-price">
        <span class="label-note txt-transform-normal">Purchase Price: </span>{{ item?.purchasePrice ||
        0
        | currency:'INR' }}
      </ion-button>
    </ion-col>
   

    <ion-col size-md="3" size-xs="3" class="txt-right" style="display: flex; flex-wrap: wrap; justify-content: end;">
      <ion-button size="small" fill="outline" color="primary" class="chip-add-image outline-border"
        (click)="addImage(item?._localUUID)">
        Add Image
      </ion-button>
      <ion-button size="small" fill="outline" color="primary" class="chip-adjust-stock outline-border"
        (click)="openStockAdjustModal(item)">
        Adjust Stock
      </ion-button>
    </ion-col>

    <!-- Favourite Item -->
    <ion-col size-md="8.6" size-xs="7" class="txt-right"></ion-col>
    <ion-col size-md="3" size-xs="3" class="txt-right" style="display: flex; justify-content: center;">
      <ion-button 
      class="btn-popover icon-star" 
      title="Favorite" 
      fill="clear" 
      (click)="makeFavourite(item)">
      <ion-icon 
        *ngIf="!item?.isFavourite" 
        color="warning" 
        name="star-outline" 
        size="small">
      </ion-icon>
      <ion-icon 
        *ngIf="item?.isFavourite" 
        color="warning" name="star" size="small"></ion-icon>
      </ion-button>
    </ion-col>
    <ion-col size-md="0.4" size-xs="1.2" (click)="emitElement(item)">
      <div *ngIf="item?.syncStamp > item?.updatedStamp; then thenBlock else elseBlock"></div>
      <ng-template #thenBlock>
        <ion-button class="btn-popover icon-sync" title="Data Uploaded" fill="clear">
          <ion-icon 
            name="checkmark-done-sharp" 
            size="small"
            color="success">
          </ion-icon>
        </ion-button>
      </ng-template>
      <ng-template #elseBlock>
        <ion-button class="btn-popover icon-sync" title="Warning" fill="clear">
          <ion-icon 
            name="warning-sharp" 
            color="warning" 
            size="small">
          </ion-icon>
        </ion-button>
      </ng-template>
    </ion-col>
  </ion-row>
</ion-grid>

<ion-infinite-scroll threshold="10px" *ngIf="viewFilteredList?.length" (ionInfinite)="loadMoreListData($event)">
  <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Loading more data...">
  </ion-infinite-scroll-content>
</ion-infinite-scroll>

<app-item-stock-adjust #itemStockAdjust></app-item-stock-adjust>

<app-image-cropper *ngIf="isOpenImageCropper" (imageSaved)="onImageSaved($event)"
  (modalClosed)="openImageCropper($event ? false : true)">
</app-image-cropper>

<app-pin-verification (verifyTransactionPin)="verifyTransactionPIN($event)" #pinVerificationElement></app-pin-verification>