export class BaseModel {

  _localId: number;
  _localUUID: string;
  _serverIdRef: string;
  createdStamp: number;
  deletedStamp: number = 0;
  syncStamp: number;
  deviceSyncStartStamp: number;
  updatedStamp: number;
  userUpdatedStamp: number;
  userId: string;
  createdBy?: string;
  lastModifiedBy?: string;
  createdByName?: string;
  lastModifiedByName?: string;
}
