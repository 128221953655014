import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { InfiniteScrollCustomEvent } from '@ionic/angular';
import { Subscription } from 'rxjs';
import Party from '../../models/Party.model';
import { AllDataService } from '../../services/all-data.service';
import { Utility } from '../../utils/utility';
import { Sale } from '../../models/Sale.model';
import PartyCategory from '../../models/PartyCategory.model';
import { MonthWisePartyCreditService } from '../../services/month-wise-party-credit.service';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

export interface RunningBillHashMap {
  [key: string]: Sale
}

@Component({
  selector: 'app-party-restaurant-list',
  templateUrl: './party-restaurant-list.component.html',
  styleUrls: ['./party-restaurant-list.component.scss'],
})
export class PartyRestaurantListComponent implements OnInit {

  @Input() defaultCashParty = false;
  @Input() searchText = '';
  @Input() selectedParty: Party = null;
  @Input() isSale = false;
  @Input() isMaskPhone: boolean = false;

  @Output() partySelectedEvent = new EventEmitter<Party>();
  @Output() addNewPartyEvent = new EventEmitter<string>();
  @Output() partyRunningBillHashMap = new EventEmitter<RunningBillHashMap>();

  @ViewChild('searchBar') searchBar;

  filteredList: Party[] = [];
  completeList: Party[] = [];
  viewFilteredList: Party[] = [];

  appliedCategory = '';
  selectedFilter = '';

  isFilterOptionOpen = false;

  categories: string[] = [];

  subsArr: Subscription[] = [];

  categoryWisePartyCount = {};

  loadViewTimeStamp: number = 0;

  MATHABS = Math.abs;

  shortStr = Utility.shortStr;
  stringMask = Utility.stringMask;

  cssColors = {
    runningBillColor: '#E8F5E9',
    kotBillColor: '#FFEBEE',
  };

  runningBillHashMap: RunningBillHashMap = { };

  constructor(
    private allDataService: AllDataService,
    private monthWisePartyCreditService: MonthWisePartyCreditService,
  ) { }

  ngOnInit() {
    try {
      this.setCssColor();
      this.reduceFunctionCall();
      this.subsArr.push(this.allDataService.listForceReloadSubs
        .subscribe(async (listName: string) => {
          if (
            listName == 'party-list' || 
            listName == 'sale-list' || 
            listName == 'profile-list'
            ) {
            // wait for storing party data for kot and hold
            await Utility.wait(2000);
            this.reduceFunctionCall();
          }
        }))
  
      this.subsArr.push(this.allDataService.partyService.updateSubs
        .subscribe((party: Party) => {
          if (party) {
            for (let i = 0; i < this.viewFilteredList.length; i++) {
              if (party._localUUID == this.viewFilteredList[i]._localUUID) {
                this.viewFilteredList[i] = party;
                break;
              }
            }
            for (let i = 0; i < this.filteredList.length; i++) {
              if (party._localUUID == this.filteredList[i]._localUUID) {
                this.filteredList[i] = party;
                break;
              }
            }
          }
        }))
    } catch (error) {
      SentryUtilites.setLog("PartyRestaurantListComponent:ngOnInit", error)
    }
  }

  ngAfterViewInit() {
    try {
      setTimeout(() => {
        this.searchBar?.setFocus();
        this.reduceFunctionCall();
      }, 500);
    } catch (error) {
      SentryUtilites.setLog("PartyRestaurantListComponent:ngAfterViewInit", error)
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    try {
      if (changes?.defaultCashParty?.currentValue != changes?.defaultCashParty?.previousValue) {
        setTimeout(() => {
          this.reduceFunctionCall();
        }, 500);
      }
    } catch (error) {
      SentryUtilites.setLog("PartyRestaurantListComponent:ngOnChanges", error)
    }
  }

  async setCssColor() {
    try {
      let profile = await this.allDataService.profileService.getCurrentProfile();
      this.cssColors.runningBillColor = profile?.iSetColorPartySelectorSaleHold || '#E8F5E9';
      this.cssColors.kotBillColor = profile?.iSetColorPartySelectorSaleKotPending || '#FFEBEE';
    } catch (error) {
      SentryUtilites.setLog("PartyRestaurantListComponent:setCssColor", error)
    }
  }

  async selectDefaultCashParty() {
    try {
      if (this.defaultCashParty) {
        let result = this.completeList.filter(x => x?.isCashSaleParty);
        if (result && result?.length && result[0]?._localUUID) {
          this.emitElement(result[0],this.runningBillHashMap[result[0]?._localUUID]?._localUUID);
        }
      }
    } catch (error) {
      SentryUtilites.setLog("PartyRestaurantListComponent:selectDefaultCashParty", error)
    }
  }

  async getCategories() {
    try {
      let partyCategories=await this.allDataService.partyCategoryService.getAllByPromise();
      partyCategories.sort((a: PartyCategory, b: PartyCategory) => {
        if(a?.savedPosition || b?.savedPosition) {
          return a?.savedPosition - b?.savedPosition;
        }
        if (a?.name == b?.name) {
          return a?.createdStamp - b?.createdStamp;
        }
        if (a?.name > b?.name) {
          return 1;
        }
        return -1;
      });
      if (partyCategories?.length) {
        this.categories = partyCategories.map(x => x.name);
      }
    } catch (error) {
      SentryUtilites.setLog("PartyRestaurantListComponent:getCategories", error)
    }
  }

  async loadView() {
    try {
      // this.filteredList = [];
      // this.completeList = [];
      // this.viewFilteredList = [];
  
      // this.appliedCategory = '';
      // this.selectedFilter = '';
  
      this.isFilterOptionOpen = false;
  
      // this.categories = [];
  
      let completeListHashMap = {};
  
      let completeListLength = this.completeList?.length;
      for (let i = 0; i < completeListLength; i++) {
        let key = JSON.stringify(this.completeList[i]);
        completeListHashMap[key] = true;
      }
  
      let newCompleteList =
        await this.allDataService.partyService.getAllByPromise() || [];
  
      const ledgerCreditHashMap =
        await this.monthWisePartyCreditService.getLedgerCreditHashMap();
  
      let differentObjects = [];
  
      // storing credit and find out differentObjects from completeList
      let newCompleteListLength = newCompleteList?.length;
      for (let i = 0; i < newCompleteListLength; i++) {
        let newCompleteObj = newCompleteList[i];
        newCompleteObj.credit = ledgerCreditHashMap[newCompleteObj._localUUID] || 0;
  
        if(this.completeList?.length) {
          let key = JSON.stringify(newCompleteObj);
          if (!completeListHashMap[key]) {
            differentObjects.push(newCompleteObj);
          }
        }
      }
  
      if (
        newCompleteList?.length != this.completeList?.length ||
        JSON.stringify(newCompleteList) != JSON.stringify(this.completeList)
      ) {
        if(this.completeList?.length) {
          let differentObjectsLength = differentObjects?.length;
          if (differentObjectsLength) {
            for (let i = 0; i < differentObjectsLength; i++) {
              let differentObject = differentObjects[i];
              let index = this.completeList.findIndex(
                (el) => el?._localUUID === differentObject?._localUUID
              );
              if (index != -1) {
                this.completeList[index] = differentObject;
              } else {
                this.completeList.push(differentObject);
              }
            }
          }
        } else {
          this.completeList = newCompleteList;
        }
  
        this.selectedFilter = 'SortByFavAsc';
        this.onFilterChange(this.lastSearchKey);
        if (this.completeList?.length) {
          this.categoryWisePartyCount = {};
          this.categories?.forEach((category) => {
            if(category) {
              this.categoryWisePartyCount[category] = this.completeList?.filter(
                (party) => party?.category ? party?.category?.toLowerCase() == category?.toLowerCase(): false
              )?.length;
            }
          });
        }
  
        let salesOnHold =
          await this.allDataService.saleService.getAllByProfileWithOnlyRunningBill();
        this.completeList?.forEach((party) => {
          if (this.isSale) {
            this.runningBillHashMap[party._localUUID] =
              salesOnHold
                .filter((sale) => sale?.party?._localUUID === party?._localUUID)
                .sort((a, b) => b?.createdStamp - a?.createdStamp)[0] || null;
          }
        });
        this.partyRunningBillHashMap.emit(this.runningBillHashMap);
      }
  
      return true;
    } catch (error) {
      SentryUtilites.setLog("PartyRestaurantListComponent:loadView", error)
      return false;
    }
  }

  async reduceFunctionCall() {
    try {
      if(+new Date() > this.loadViewTimeStamp) {
        this.loadViewTimeStamp = +new Date() + 5000;
        this.getCategories();
        await this.loadView();
        this.selectDefaultCashParty();
      }
    } catch (error) {
      SentryUtilites.setLog("PartyRestaurantListComponent:reduceFunctionCall", error)
    }
  }

  toggleFilterOptions() {
    this.isFilterOptionOpen = !this.isFilterOptionOpen;
  }

  onCategoryChange(category: string) {
    this.appliedCategory = category;
    this.onFilterChange(this.lastSearchKey);
  }

  onSortChnage(selectedFilter: string) {
    this.selectedFilter = selectedFilter;
    this.onFilterChange();
  }

  isFilterPostpond = false;
  lastFilterStamp = 0;
  onFilterChange(searchKey?: string) {
    try {
      if (this.isFilterPostpond) {
        return true;
      }
      if (this.lastFilterStamp < +new Date() - 200) {
        this.lastFilterStamp = +new Date();
        this.applyFilter({
          searchKeyword: searchKey,
          selectedFilter: this.selectedFilter,
          category: this.appliedCategory
        })
      } else {
        this.isFilterPostpond = true;
        setTimeout(() => {
          this.isFilterPostpond = false;
          this.onFilterChange(searchKey);
        }, 250);
      }
    } catch (error) {
      SentryUtilites.setLog("PartyRestaurantListComponent:onFilterChange", error)
    }
  }


  lastSearchKey = '';
  applyFilter(params: {
    searchKeyword?: string,
    selectedFilter?: string,
    category?: string
  }) {
    try {
      params.searchKeyword = params.searchKeyword?.trim();
      let arr = [];
      let lsearchKey = '';
      let isSearchIncremental = false;
      let isSearchCleared = false;
      if (params?.searchKeyword?.length) {
        lsearchKey = Utility.toLowerCase(params.searchKeyword);
        if (lsearchKey.indexOf(this.lastSearchKey) == 0) {
          isSearchIncremental = true;
        }
        this.lastSearchKey = lsearchKey;
      }
      if (this.lastSearchKey.length > 0 && lsearchKey.length == 0) {
        isSearchCleared = true;
        this.lastSearchKey = '';
      }
      if(!this.filteredList?.length && isSearchIncremental) {
        this.filteredList = [...this.completeList];
      }
      let completeList = [];
      if (isSearchIncremental) {
        completeList = [...this.filteredList]
      } else {
        completeList = [...this.completeList];
      }
      if (params?.category?.length) {
        completeList?.forEach((el) => {
          if (el?.category == params.category) {
            if (lsearchKey.length) {
              if (Utility.toLowerCase(el?.name).indexOf(lsearchKey) != -1) {
                arr.push(el);
              }
            } else {
              arr.push(el);
            }
          }
        });
      } else if (lsearchKey.length) {
        completeList?.forEach((el: Party) => {
          if (lsearchKey.length < 4) {
            if(Utility.toLowerCase(el?.phone?.toString()).indexOf(lsearchKey) != -1) {
              arr.push(el)
            } else if (isNaN(Number(lsearchKey)) === false) {
              if (Utility.toLowerCase(el?.name).indexOf(lsearchKey) != -1) {
                arr.push(el);
              }
            } else if (Utility.toLowerCase(el?.name).indexOf(lsearchKey) == 0
            || Utility.toLowerCase(el?.name).includes(lsearchKey)) {
              arr.push(el);
            }
          } else if (
            Utility.toLowerCase(el?.phone?.toString()).indexOf(lsearchKey) != -1
            && isNaN(Number(lsearchKey)) == false
          ) {
            arr.push(el);
          } else {
            if (Utility.toLowerCase(el?.name).indexOf(lsearchKey) != -1) {
              arr.push(el);
            }
          }
        });
      } else {
        arr = [...completeList]
      }
  
      if (params.selectedFilter == 'SortByNameAsc') {
        arr.sort((a: Party, b: Party) => {
          if (a?.name?.toLowerCase() == b?.name?.toLowerCase()) {
            return a?.createdStamp - b?.createdStamp;
          }
          if (a?.name?.toLowerCase() > b?.name?.toLowerCase()) {
            return 1;
          }
          return -1;
        })
      } else if (params.selectedFilter == 'SortByNameDesc') {
        arr.sort((a: Party, b: Party) => {
          if (a?.name?.toLowerCase() == b?.name?.toLowerCase()) {
            return a?.createdStamp - b?.createdStamp;
          }
          if (b?.name?.toLowerCase() > a?.name?.toLowerCase()) {
            return 1;
          }
          return -1;
        })
      } else if (params.selectedFilter == 'SortByCreditAsc') {
  
      } else if (params.selectedFilter == 'SortByCreditDesc') {
  
      } else if (params.selectedFilter == 'SortByFavAsc') {
        arr.sort((a: Party, b: Party) => {
          if (a?.name?.toLowerCase() == b?.name?.toLowerCase()) {
            return a?.createdStamp - b?.createdStamp;
          }
          if (a?.name?.toLowerCase() > b?.name?.toLowerCase()) {
            return 1;
          }
          return -1;
        });
        let favParties: Party[] = [];
        let nonFavParties: Party[] = [];
        arr.filter(party => {
          if(party?.isFavourite) {
            favParties.push(party);
          }else {
            nonFavParties.push(party);
          }
        });
        arr = favParties.concat(nonFavParties);
      }
  
  
      this.filteredList = [...arr];
  
      if(lsearchKey) {
        let priorityArr: Party[] = [];
        this.filteredList?.forEach((x,i) => {
          if((x?.name+'')?.startsWith(lsearchKey) || (x?.phone+'')?.startsWith(lsearchKey)) {
            priorityArr.push(x);
            this.filteredList.splice(i,1);
          }
        });
        this.filteredList  = [...priorityArr.sort(),...this.filteredList];
      }
  
      this.viewFilteredList = this.viewFilteredList?.length > 50 ? this.filteredList.slice(0, this.viewFilteredList?.length) : this.filteredList.slice(0, 50);
    } catch (error) {
      SentryUtilites.setLog("PartyRestaurantListComponent:applyFilter", error)
    }

  }


  loadMoreListData(event: any) {
    try {
      if (this.viewFilteredList.length > 0 && this.viewFilteredList.length <= this.filteredList.length) {
        let appendListLength = this.filteredList.length - this.viewFilteredList.length;
        let lastEl = this.viewFilteredList[this.viewFilteredList.length - 1];
        let counter = 0;
        for (let i = 0; i < this.filteredList.length; i++) {
          if (this.filteredList[i]._localUUID == lastEl._localUUID) {
            counter = 1;
            continue;
          }
          if (counter > 0 && appendListLength >= 50) {
            if (counter <= 50) {
              this.viewFilteredList.push({ ...this.filteredList[i] })
            } else {
              break;
            }
            counter++;
          } else if (counter > 0 && appendListLength < 50) {
            if (counter <= appendListLength) {
              this.viewFilteredList.push({ ...this.filteredList[i] })
            } else {
              break;
            }
            counter++;
          }
        }
        (event as InfiniteScrollCustomEvent)?.target?.complete();
      }
    } catch (error) {
      SentryUtilites.setLog("PartyRestaurantListComponent:loadMoreListData", error)
    }
  }

  emitElement(party: Party, runningSaleBill: string) {
    try {
      if(party?._localUUID) {
        party.runningSaleBill = runningSaleBill;
        this.partySelectedEvent.emit(party);
      }
    } catch (error) {
      SentryUtilites.setLog("PartyRestaurantListComponent:emitElement", error)
    }
  }

  addNewParty() {
    this.addNewPartyEvent.emit(this.lastSearchKey);
  }

  ngOnDestroy() {
    try {
      this.completeList = [];
      this.subsArr?.forEach((el) => {
        el?.unsubscribe();
      })
    } catch (error) {
      SentryUtilites.setLog("PartyRestaurantListComponent:ngOnDestroy", error)
    }
  }

}
