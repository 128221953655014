import { Subscription } from 'rxjs';
import { ILicence, Licence } from '../../models/Licence.model';
import { Utility } from '../../utils/utility';
import { AllDataService } from './../../services/all-data.service';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { IonInput, ToastController } from '@ionic/angular';
import { AuthService } from '../../services/auth/auth.service';
import { CommonService } from '../../services/common.service';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-licence-info',
  templateUrl: './licence-info.component.html',
  styleUrls: ['./licence-info.component.scss'],
})
export class LicenceInfoComponent implements OnInit {

  @ViewChild('whastappMessageCreditsEle') whastappMessageCreditsEle: IonInput;

  lastSyncTime = Utility.getCollectionLastRespSyncTime(ILicence.SCHEMA_NAME);
  lastSyncTimeSub: Subscription;
  isTimeDifference = false;

  licence: Licence = null;
  isModalOpen = false;
  dateToDDMMYYY = Utility.dateToDDMMYYY;
  maximumOfTwoNumber = Utility.maximumOfTwoNumber;
  isMobile: boolean = null;

  constructor(
    private allDataService: AllDataService,
    private authService: AuthService,
    private toastController: ToastController,
    private commonService: CommonService
  ) { }

  ngOnInit() {
    try {
      this.isMobile = this.commonService.isMobile();
      this.allDataService.lastSyncSubs.subscribe(x => {
        this.lastSyncTime = Utility.getCollectionLastRespSyncTime(ILicence.SCHEMA_NAME);
        this.isTimeDifference = this.allDataService.isTimeDifference;
      });
    } catch (error) {
      SentryUtilites.setLog("LicenceInfoComponent:ngOnInit", error)
    }
  }

  ngOnDestroy() {
    this.lastSyncTimeSub?.unsubscribe();
  }

  async getLicences(clicked?: boolean) {
    try {
      let licences = await this.allDataService.licenceService.getAllByPromise();
      let profile = await this.allDataService.profileService.getCurrentProfile();
      this.licence = licences.filter(licence => licence?.userId == profile?.userId)[0];
      if(clicked) {
        const toast = await this.toastController.create({
          header: 'Refresh License',
          duration: 2000,
          position: 'top',
          mode: 'ios',
          color: 'success',
        });
        await toast.present();
      }
    } catch (error) {
      SentryUtilites.setLog("LicenceInfoComponent:getLicences", error)
    }
  }

  getAddition(n1: number, n2: number) {
    try {
      n1 = +n1 || 0;
      n2 = +n2 || 0;
      return n1 + n2;
    } catch (error) {
      SentryUtilites.setLog("LicenceInfoComponent:getAddition", error)
      return 0;
    }
  }

  openModal(value: boolean = true) {
    try {
      this.isModalOpen = value;
      if(this.isModalOpen) {
        this.getLicences();
      }
    } catch (error) {
      SentryUtilites.setLog("LicenceInfoComponent:openModal", error)
    }
  }

  buyMoreCredits() {
    try {
      let amount = this.whastappMessageCreditsEle?.value || 0;
      let loginPhone = this.authService.getLoginPhone();
      if(amount && loginPhone) {
        window.open(` https://ezobooks.in/pg/messageCreditOrder/${loginPhone}/${amount}`);
      }
    } catch (error) {
      SentryUtilites.setLog("LicenceInfoComponent:buyMoreCredits", error)
    }
  }

  @HostListener('window:resize', ['$event'])
  OnResize() {
    try {
      setTimeout(()=> {
        this.isMobile = this.commonService.isMobile();
      }, 20)
    } catch (error) {
      SentryUtilites.setLog("LicenceInfoComponent:OnResize", error)
    }
  }

}
