<ion-toolbar
  [style.padding-bottom]="isFilterOptionOpen &&  innerWidth < 1080? '32px' : '0px'">
  <ion-searchbar 
    #searchBar 
    placeholder="Search party name or phone..." 
    [debounce]="100"
    (ionChange)="onFilterChange($event['detail'].value)">
  </ion-searchbar>
</ion-toolbar>

<ion-button 
  class="btn-show-hide" 
  (click)="toggleFilterOptions()">
  {{isFilterOptionOpen ? 'Hide Filter' : 'Show Filter'}}
</ion-button>

<ion-fab 
  *ngIf="isFilterOptionOpen" 
  class="btn-category-by" 
  #categoryFab>
  <ion-fab-button 
    class="first-fab-button" 
    size="small" 
    (click)="closeSatisfactionSort()" 
    (clickOutSide)="closeclickOutSideCategory()">
    Category
  </ion-fab-button>
  <ion-fab-list 
    side="bottom">
    <ion-fab-button 
      [ngClass]="{'filter-sort-active': !appliedCategory}" 
      (click)="onCategoryChange('')">
      All ({{ categories?.length || 0 }})
    </ion-fab-button>
    <ion-fab-button 
      *ngFor="let cat of categories" 
      [ngClass]="{'filter-sort-active': cat === appliedCategory}"
      (click)="onCategoryChange(cat)">
      {{cat}}
    </ion-fab-button>
  </ion-fab-list>
</ion-fab>

<ion-fab 
  *ngIf="isFilterOptionOpen" 
  class="btn-satisfaction-by" 
  #satisfactionFab>
  <ion-fab-button 
    class="first-fab-button" 
    size="small" 
    (click)="closeCategorySort()" 
    (clickOutSide)="closeclickOutSideSatisfaction()">
    Satisfaction
  </ion-fab-button>
  <ion-fab-list 
    side="bottom">
    <ion-fab-button 
      *ngFor="let satisfaction of satisfactionObj|keyvalue: returnZero"
      [ngClass]="{'filter-sort-active': satisfaction?.value === appliedSatisfaction}"
      (click)="onSatisfactionChange(satisfaction.value)">
      {{satisfaction?.key}}
    </ion-fab-button>
  </ion-fab-list>
</ion-fab>

<ion-fab 
  *ngIf="isFilterOptionOpen" 
  class="btn-sort-by" 
  #sortByFab>
  <ion-fab-button 
    class="first-fab-button" 
    size="small" 
    (click)="closeCategorySatisfaction()" 
    (clickOutSide)="closeclickOutSideSort()">
    Sort By
  </ion-fab-button>
  <ion-fab-list 
    side="bottom">
    <ion-fab-button 
      [ngClass]="{'filter-sort-active': sort?.key===selectedFilter}"
      *ngFor="let sort of sortObj|keyvalue: returnZero" 
      (click)="onSortChnage(sort.key)">
      {{ sort?.value }}
    </ion-fab-button>
  </ion-fab-list>
</ion-fab>

<ion-grid 
  class="all-list" 
  [ngClass]="{'ion-modal':isModal,'mobile': isMobile}">

  <!-- Pills Options start -->

  <ion-label class="pills-option">
    <div class="r-pill">
      <div class="r-pill__group">
        <span class="r-pill__item" [ngStyle]="{'padding': selectAllEle?.checked ? '0px': '4px 12px'}" (click)="selectedPills('show-all')">
          <input type="radio" id="select-all" name="radios" #selectAllEle [checked]="isPillsSelectedVal === 'show-all'">
          <label for="select-all">Select All</label>
        </span>
        <span class="r-pill__item" [ngStyle]="{'padding': active30daysEle?.checked ? '0px': '4px 12px'}" (click)="selectedPills('active-30-days')">
          <input type="radio" id="active-30-days" name="radios" #active30daysEle [checked]="isPillsSelectedVal === 'active-30-days'">
          <label for="active-30-days">Active last 30 days</label>
        </span>
        <span class="r-pill__item" [ngStyle]="{'padding': active60daysEle?.checked ? '0px': '4px 12px'}" (click)="selectedPills('active-60-days')">
          <input type="radio" id="active-60-days" name="radios" #active60daysEle [checked]="isPillsSelectedVal === 'active-60-days'">
          <label for="active-60-days">Active last 60 days</label>
        </span>
        <span class="r-pill__item" [ngStyle]="{'padding': inActive30daysEle?.checked ? '0px': '4px 12px'}" (click)="selectedPills('in-active-30-days')">
          <input type="radio" id="in-active-30-days" name="radios" #inActive30daysEle [checked]="isPillsSelectedVal === 'in-active-30-days'">
          <label for="in-active-30-days">In Active last 30 days</label>
        </span>
        <span class="r-pill__item" [ngStyle]="{'padding': inActive60daysEle?.checked ? '0px': '4px 12px'}" (click)="selectedPills('in-active-60-days')">
          <input type="radio" id="in-active-60-days" name="radios" #inActive60daysEle [checked]="isPillsSelectedVal === 'in-active-60-days'">
          <label for="in-active-60-days">In Active last 60 days</label>
        </span>
      </div>
    </div>
  </ion-label>

  <!-- Pills Options end -->

  <ion-item 
    style="width: 100%;" 
    (click)="selectParty(party, ionChecked?.checked)" 
    *ngFor="let party of viewFilteredList" 
    [disabled]="!checkDisableKeys(party) || !party?.phone || party?.isCashSaleParty">
    <ion-checkbox 
      #ionChecked 
      [(ngModel)]="party['checked']" 
      slot="start">
    </ion-checkbox>
    <ion-label 
      class="party-label" 
      style="width: 100%;">
      <div>
        {{ party.name }}
      </div>
      <div 
        class="error-msg" 
        *ngIf="checkErrorMsg(party)">
        {{checkErrorMsg(party)}}
      </div>
    </ion-label>
  </ion-item>
  <ion-button 
    class="next-btn" 
    (click)="onNextClick()">
    Next
  </ion-button>
  <ion-row 
    class="padding-none party-item-custom-row col-custom-row"
    [ngClass]="runningBillHashMap[party?._localUUID]?._localUUID ? runningBillHashMap[party?._localUUID]?.isPrintedKOT ? 'running-bill-color' : 'kot-color' : ''"
    *ngFor="let party of viewFilteredList">
  </ion-row>
</ion-grid>

<ion-infinite-scroll 
  threshold="10px" 
  *ngIf="viewFilteredList?.length"
  (ionInfinite)="loadMoreListData($event)">
  <ion-infinite-scroll-content 
    loadingSpinner="bubbles" 
    loadingText="Loading more data...">
  </ion-infinite-scroll-content>
</ion-infinite-scroll>