<ion-modal [ngClass]="{'mobile': isMobile}" class="modal-settings" [isOpen]="isModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar color="light">
        <ion-title>Licences</ion-title>
        <ion-text class="last-sync-time" color="medium">Last Sync at {{ lastSyncTime }}</ion-text>
        <ion-text *ngIf="isTimeDifference" class="last-sync-time timezone-warning alert-border padding-alert-boder" color="warning" > <i>Time Zone Mismatched</i> </ion-text>
        <ion-buttons slot="end">
          <ion-button fill="clear" class="refresh" (click)="getLicences(true)"><ion-icon name="refresh-outline"></ion-icon></ion-button>
          <ion-button (click)="openModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">

      <ion-grid>

        <ion-row *ngIf="licence">
          <ion-col size="12">
            <ion-item lines="none">
              <ion-label>Pro Activation Date: {{ dateToDDMMYYY(licence?.proActivationStamp) || '-' }}</ion-label>
            </ion-item>
            <ion-item lines="none">
              <ion-label>Pro Expiry Date: {{ dateToDDMMYYY(maximumOfTwoNumber(licence?.proExpiryStamp || 0, licence?.proTrialExpiryStamp || 0)) || '-' }}</ion-label>
            </ion-item>
            <!-- <ion-item lines="none">
              <ion-label>Sms Credits: {{ licence?.smsCredits }}</ion-label>
            </ion-item>
            <ion-item lines="none">
              <ion-label>WhatsApp Message Credits: {{ getAddition(licence?.whastappMessageCredits,licence?.paidWhastappMessageCredits) }}</ion-label>
            </ion-item>
            <ion-item>
              <ion-input #whastappMessageCreditsEle type="number" placeholder="Enter Amount"></ion-input>
              <ion-button (click)="buyMoreCredits()">Buy More Credits</ion-button>
            </ion-item>
            <ion-item lines="none">
              <ion-label>Bill Print Credits: {{ licence?.billPrintCredit }}</ion-label>
            </ion-item> -->
          </ion-col>
        </ion-row>

      </ion-grid>

    </ion-content>
  </ng-template>
</ion-modal>
