import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IonInput, ToastController } from '@ionic/angular';
import Party from '../../../models/Party.model';
import { PartySelectorComponent } from 'src/app/components/party-selector/party-selector.component';
import { Expense } from 'src/app/models/Expense.model';
import { Profile } from 'src/app/models/Profile.model';
import { AllDataService } from 'src/app/services/all-data.service';
import { Utility } from 'src/app/utils/utility';
import { MoneyOut } from 'src/app/models/MoneyOut.model';
import { MoneyOutSelectorComponent } from 'src/app/components/money-out-selector/money-out-selector.component';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-expense-form',
  templateUrl: './expense-form.page.html',
  styleUrls: ['./expense-form.page.scss'],
})
export class ExpenseFormPage implements OnInit {

  @ViewChild('partySelector', { static: false }) partySelector: PartySelectorComponent;
  @ViewChild('moneyOutSelector', { static: false }) moneyOutSelector: MoneyOutSelectorComponent;
  @ViewChild('amountPaidEle') amountPaidEle: IonInput;
  @ViewChild('paymentIdEle') paymentIdEle: IonInput;
  @ViewChild('categoryEle') categoryEle: IonInput;

  getHeaderColorClass = Utility.getHeaderColorClass;
  maxDate = Utility.maxDateUpto50Year();
  setDateValue = Utility.setDateValue;

  form: FormGroup;
  dpBillDateValue: string = null;
  paramDocumentId: string = null;
  fetchedExpense: Expense = null;
  amountPaidChecked = false;
  selectedProfile: Profile = null;
  paymentMode: string = 'cash';
  basicCategories = ['Rent', 'Salary', 'Electricity Bill'];
  categories = [...this.basicCategories].sort();

  todayDate: number = +new Date().setHours(0, 0, 0, 0);

  isOpenBillDatePicker: boolean = false;
  keepContentsMounted: boolean = false;

  subArr: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private toastController: ToastController,
    private allDataService: AllDataService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
    try {
      this.getParamDocumentId();
      this.initializeReactiveForm();
      this.subArr.push(this.allDataService.listForceReloadSubs.subscribe((listName: string) => {
        if (listName == 'profile-list') {
          this.getSelectedProfile();
        }
      }));
    } catch (error) {
      SentryUtilites.setLog("ExpenseFormPage:ngOnInit", error)
    }
  }

  async ngAfterViewInit() {
    try {
      await this.getSelectedProfile();
      setTimeout(() => {
        this.populateForm();
      }, 500);
    } catch (error) {
      SentryUtilites.setLog("ExpenseFormPage:ngAfterViewInit", error)
    }
  }

  ionViewWillLeave() {
    this.ngOnDestroy();
  }

  ngOnDestroy() {
    try {
      this.keepContentsMounted = false;
      this.subArr?.forEach(sub => {
        sub?.unsubscribe();
      })
    } catch (error) {
      SentryUtilites.setLog("ExpenseFormPage:ngOnDestroy", error)
    }
  }

  getParamDocumentId() {
    this.paramDocumentId = this.route.snapshot.paramMap.get('documentId');
  }

  populateSettings() { }

  async getSelectedProfile() {
    try {
      this.selectedProfile = await this.allDataService.profileService.getCurrentProfile();
      this.populateSettings();
      return true;
    } catch (error) {
      SentryUtilites.setLog("ExpenseFormPage:getSelectedProfile", error)
      return false;
    }
  }

  async populateForm() {
    try {
      let profile = await this.allDataService.profileService.getCurrentProfile();
      if(profile?.expenseCategories?.length) {
        this.categories = [...this.basicCategories,...profile?.expenseCategories];
        this.categories?.sort();
      }
      if (this.paramDocumentId) {
        this.fetchedExpense = await this.allDataService.expenseService.getByUUID(this.paramDocumentId);
        if (this.fetchedExpense?._localUUID && !this.fetchedExpense?.deletedStamp) {
          this.form.patchValue({ ...this.fetchedExpense });
          this.dpBillDateValue = Utility.ionDatePickerFormattedString(this.fetchedExpense?.billDateStamp);
          this.keepContentsMounted = true;
  
          this.partySelector?.setSelectedParty(this.fetchedExpense.party);
          this.partySelector?.disable();
  
          if (this.fetchedExpense?.moneyOuts?.length) {
            this.paymentMode = this.fetchedExpense?.moneyOuts[0]?.paymentMode;
            if (this.paymentIdEle) {
              (this.paymentIdEle as any)['el'].value = this.fetchedExpense?.moneyOuts[0]?.paymentId;
              this.paymentIdEle.value = this.fetchedExpense?.moneyOuts[0]?.paymentId;
            }
          }
        }
      } else {
        this.populateBillNumber();
        this.partySelector?.openPartySelectorModal();
      }
    } catch (error) {
      SentryUtilites.setLog("ExpenseFormPage:populateForm", error)
    }
  }

  //----------------------------------------------------------------------------

  /*
    Reactive Form Initialization
  */

  initializeReactiveForm() {
    try {
      this.form = this.formBuilder.group({
        billNo: ['', Validators.required],
        billDateStamp: [+new Date().setHours(0, 0, 0, 0), Validators.required],
        party: [null, Validators.required],
        totalAmount: [null,Validators.required],
        amountPaid: [null],
        note: [null],
        category: [null],
      });
  
      this.subArr.push(this.form.get("totalAmount").valueChanges.pipe(distinctUntilChanged(), debounceTime(300)).subscribe(totalAmount => {
        if(totalAmount && totalAmount < 1) {
          this.form?.patchValue({ totalAmount: null });
          return;
        }
        if (this.amountPaidChecked && totalAmount > 0) {
          this.form?.patchValue({ amountPaid: totalAmount });
        }else {
          this.form?.patchValue({ amountPaid: null });
        }
      }));
      this.subArr.push(this.form.get("amountPaid").valueChanges.pipe(distinctUntilChanged(), debounceTime(300)).subscribe(amountPaid => {
        if(amountPaid && amountPaid < 1) {
          this.form?.patchValue({ amountPaid: null });
          return;
        }
        if(amountPaid && !this.amountPaidChecked) {
          this.amountPaidChecked = true;
        }
      }));
    } catch (error) {
      SentryUtilites.setLog("ExpenseFormPage:initializeReactiveForm", error)
    }
  }

  //----------------------------------------------------------------------------

  /*
    Expense Number
  */

  async populateBillNumber() {
    try {
      this.form.patchValue({
        billNo: await this.allDataService.expenseService.getNewExpenseNo()
      });
    } catch (error) {
      SentryUtilites.setLog("ExpenseFormPage:populateBillNumber", error)
    }
  }

  //----------------------------------------------------------------------------

  /*
    Party Selector functons
  */

  onPartySelect(party: Party) {
    try {
      if (party?._localUUID) {
        this.form.patchValue({ party });
      }
    } catch (error) {
      SentryUtilites.setLog("ExpenseFormPage:onPartySelect", error)
    }
  }

  //----------------------------------------------------------------------------

  onMoneyOutCartUpdate(moneyOutCart: MoneyOut[]) {
    try {
      let totalAmountPaid = 0;
      moneyOutCart?.forEach(x => totalAmountPaid += Number(x?.totalAmount));
      this.form?.controls?.amountPaid?.setValue(totalAmountPaid);
    } catch (error) {
      SentryUtilites.setLog("ExpenseFormPage:onMoneyOutCartUpdate", error)
    }
  }

  setPaymentMode(event) {
    this.paymentMode = event?.detail?.value || 'cash';
  }

  onAmountPaidCheckedChange(event: any) {
    try {
      if(this.amountPaidChecked != event?.detail?.checked) {
        this.amountPaidChecked = event?.detail?.checked;
        if (this.amountPaidChecked) {
          this.form.patchValue({amountPaid: this.form?.value?.totalAmount});
        } else {
          this.form.patchValue({amountPaid: null});
        }
      }
    } catch (error) {
      SentryUtilites.setLog("ExpenseFormPage:onAmountPaidCheckedChange", error)
    }
  }

  /**
   * 
   * @param event : date event
   * @description : on bill date select assign date value
   */
  onBillDateSelect(event) {
    try {
      if (event?.detail?.value) {
        let timeStamp = +new Date(event?.detail?.value).setHours(0, 0, 0, 0);
        this.form?.controls?.billDateStamp?.setValue(timeStamp);
        this.dpBillDateValue = Utility.ionDatePickerFormattedString(timeStamp);
        Utility.closeDatePicker(event?.target);
      }
    } catch (error) {
      SentryUtilites.setLog("ExpenseFormPage:onBillDateSelect", error)
    }
  }
  // --------------------------------

  setPaymentId(value) {
    try {
      if (this.paymentIdEle) {
        (this.paymentIdEle as any)['el'].value = value;
        if (this.paramDocumentId && this.fetchedExpense?.moneyOuts?.length) {
          this.fetchedExpense.moneyOuts[0].paymentId = value;
        }
      }
    } catch (error) {
      SentryUtilites.setLog("ExpenseFormPage:setPaymentId", error)
    }
  }

  async onSaveClick() {
    try {
      let savedExpense= null;
      if (this.paramDocumentId) {
        savedExpense= await this.update();
      } else {
        savedExpense= await this.add();
      }
      if (savedExpense?._localUUID) {
        this.router.navigate(['expense']);
      }
    } catch (error) {
      SentryUtilites.setLog("ExpenseFormPage:onSaveClick", error)
    }
  }

  async add() {
    try {
      if (!this.form?.value?.party?._localUUID) {
        const toast = await this.toastController.create({
          header: 'Please Select Party to Save Expense',
          duration: 3000,
          position: 'top',
          mode: 'ios',
          color: 'danger',
        });
        await toast.present();
        return null;
      }
  
      if (!this.form?.value?.category) {
        const toast = await this.toastController.create({
          header: 'Please Select Category to Save Expense',
          duration: 3000,
          position: 'top',
          mode: 'ios',
          color: 'danger',
        });
        await toast.present();
        return null;
      }
  
      if (this.form.valid) {
        let expense = new Expense();
  
        expense = { ...this.form.value };
  
        let fetchedParty = await this.allDataService.partyService.getByUUID(expense?.party?._localUUID);
        expense.party = fetchedParty;
  
        expense.moneyOuts = [];
  
        if (Number(this.form?.value?.amountPaid)) {
          let moneyOut = new MoneyOut();
          moneyOut.party = expense.party;
          moneyOut.billDateStamp = expense.billDateStamp;
          moneyOut.totalAmount = this.form.value.amountPaid;
          moneyOut.billNo = await this.allDataService.moneyOutService.getNewMoneyOutNo();
          moneyOut.paymentMode = this.paymentMode || 'cash';
          moneyOut.paymentId = this.paymentIdEle?.value as string || null;
          expense.moneyOuts.push(moneyOut);
        }
  
        let savedExpense = await this.allDataService.expenseService.save(expense);
        if(savedExpense?._localUUID) {
          this.allDataService.partyService.reloadList();
          this.allDataService.moneyOutService.reloadList();
          this.allDataService.listForceReloadSubs.next('moneyout-list');
          this.allDataService.listForceReloadSubs.next('expense-list');
          this.allDataService.listForceReloadSubs.next('party-list');
        }
  
        return savedExpense;
      } else {
        return null;
      }
    } catch (error) {
      SentryUtilites.setLog("ExpenseFormPage:add", error)
      return null;
    }
  }

  async update() {
    try {
      if (this.form.valid) {
        let expense = new Expense();
  
        expense = {
          ...this.fetchedExpense,
          ...this.form.value
        };
  
        let fetchedParty = await this.allDataService.partyService.getByUUID(expense?.party?._localUUID);
        expense.party = fetchedParty;
  
        expense.moneyOuts = this.moneyOutSelector?.getSelectedMoneyOuts() || [] as any;
  
        let updatedExpense = await this.allDataService.expenseService.update(expense);
        if(updatedExpense?._localUUID) {
          this.allDataService.partyService.reloadList();
          this.allDataService.moneyOutService.reloadList();
          this.allDataService.listForceReloadSubs.next('moneyout-list');
          this.allDataService.listForceReloadSubs.next('expense-list');
          this.allDataService.listForceReloadSubs.next('party-list');
        }
  
        return updatedExpense;
      } else {
        return null;
      }
    } catch (error) {
      SentryUtilites.setLog("ExpenseFormPage:update", error)
      return null;
    }
  }

  openMoneyOutSelectorModal() {
    try {
      if (this.moneyOutSelector) {
        this.moneyOutSelector.openMoneyOutSelectorModal();
      } else {
        this.moneyOutSelector.openMoneyOutSelectorModal(false);
      }
    } catch (error) {
      SentryUtilites.setLog("ExpenseFormPage:openMoneyOutSelectorModal", error)
    }
  }

  async addCategory() {
    try {
      if(this.categoryEle) {
        const categoryName: string = (this.categoryEle?.value as string)?.toLowerCase()?.trim();
        if(categoryName) {
          if(this.categories?.findIndex(x => x?.toLowerCase() === categoryName?.toLowerCase()) === -1) {
            let profile = await this.allDataService.profileService.getCurrentProfile();
            if(profile?.expenseCategories?.length) {
              profile.expenseCategories?.push(categoryName);
            }else {
              profile.expenseCategories = [categoryName];
            }
            let savedProfile = await this.allDataService.profileService.update(profile);
            if(savedProfile?._localUUID) {
              this.allDataService.listForceReloadSubs.next('profile-list');
              this.categories.push(categoryName);
            }else {
              return null;
            }
          }
          this.form?.patchValue({ category: categoryName });
          this.categoryEle.value = '';
        }
      }
    } catch (error) {
      SentryUtilites.setLog("ExpenseFormPage:addCategory", error)
      return null;
    }
  }

  /**
   * @description : close date picker
   */
  closeDOBPicker() {
    this.isOpenBillDatePicker = false;
  }
  // -------------------------------

}
