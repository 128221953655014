import { AccessControlService } from './../../../services/auth/access-control.service';
import { Router } from '@angular/router';
import { MoneyIn } from './../../../models/MoneyIn.model';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AllDataService } from '../../../services/all-data.service';
import { AlertController, ToastController } from '@ionic/angular';
import { PinVerificationComponent } from '../../pin-verification/pin-verification.component';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-money-in-row',
  templateUrl: './money-in-row.component.html',
  styleUrls: ['./money-in-row.component.scss'],
})
export class MoneyInRowComponent implements OnInit {

  @Input() moneyIn: MoneyIn = null;

  @Output() onDeleteClick = new EventEmitter<MoneyIn>();

  @ViewChild('pinVerificationElement') pinVerificationElement: PinVerificationComponent;

  selectedMoneyIn: MoneyIn = null;

  constructor(
    private router: Router,
    private accessControlService: AccessControlService,
  ) { }

  ngOnInit() {}

  openTransactionPINModal() {
    this.pinVerificationElement?.openTransactionPINModal();
  }

  async billView(moneyIn: MoneyIn) {
    try {
      if(!moneyIn?.deletedStamp) {
        let isPermit = await this.accessControlService.isUserHasAccess({action:'viewMoneyIn'});
        if(!isPermit) {
          return alert("Permission: You don't have permission to view bill. Please contact to your owner.");
        }
        this.router.navigate([`bill-view-money-in/${moneyIn?._localUUID}`]);
      }
    } catch (error) {
      SentryUtilites.setLog("MoneyInRowComponent:billView", error)
      alert("Something went wrong.");
    }
  }

  verifyTransactionPIN(event) {
    try {
      if(event) {
        this.router.navigate([`money-in/form/${this.moneyIn?._localUUID}`]);
      }
    } catch (error) {
      SentryUtilites.setLog("MoneyInRowComponent:verifyTransactionPIN", error)
    }
  }

  async edit(moenyIn: MoneyIn) {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({action:'editMoneyIn'});
      if(!isPermit) {
        return alert("Permission: You don't have permission to edit money in. Please contact to your owner.");
      }
      this.openTransactionPINModal();
    } catch (error) {
      SentryUtilites.setLog("MoneyInRowComponent:edit", error)
      alert("Something went wrong.");
    }
  }

  resetSelectedMoneyIn() {
    this.selectedMoneyIn = null;
  }

  delete(moneyIn: MoneyIn) {
    this.onDeleteClick.emit(moneyIn);
  }

  viewSale(moneyIn: MoneyIn) {
    try {
      if(moneyIn?.linkedSaleUUID) {
        this.router.navigate([`bill-view/${moneyIn?.linkedSaleUUID}`]);
      }
    } catch (error) {
      SentryUtilites.setLog("MoneyInRowComponent:viewSale", error)
    }
  }

  viewPurchaseReturn(moneyIn: MoneyIn) {
    try {
      if(moneyIn?.linkedPurchaseReturnUUID) {
        this.router.navigate([`bill-view-purchase-return/${moneyIn?.linkedPurchaseReturnUUID}`]);
      }
    } catch (error) {
      SentryUtilites.setLog("MoneyInRowComponent:viewPurchaseReturn", error)
    }
  }

}
