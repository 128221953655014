import { AccessControlService } from './../../../services/auth/access-control.service';
import { Purchase } from './../../../models/Purchase.model';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AllDataService } from '../../../services/all-data.service';
import { AlertController, ToastController } from '@ionic/angular';
import { PinVerificationComponent } from '../../pin-verification/pin-verification.component';
import { MoneyOut } from '../../../models/MoneyOut.model';
import { Utils } from '../../../utils/utils';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-purchase-row',
  templateUrl: './purchase-row.component.html',
  styleUrls: ['./purchase-row.component.scss'],
})
export class PurchaseRowComponent implements OnInit {

  @Input() purchase: Purchase = null;
  @Input() isRecentTransactionList: boolean = false;

  @Output() onAddPaymentClick = new EventEmitter<Purchase>();
  @Output() onDeleteClick = new EventEmitter<Purchase>();

  @ViewChild('pinVerificationElement') pinVerificationElement: PinVerificationComponent;

  capFractionsToTwo = Utils.capFractionsToTwo;

  selectedPurchase: Purchase = null;

  constructor(
    private router: Router,
    private accessControlService: AccessControlService,
    private allDataService: AllDataService,
  ) { }

  ngOnInit() { }

  openTransactionPINModal() {
    this.pinVerificationElement?.openTransactionPINModal();
  }

  async createPurchaseReturn(purchase: Purchase) {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({action:'createPurchaseReturn'});
      if(!isPermit) {
        return alert("Permission: You don't have permission to create purchase return. Please contact to your owner.");
      }
      this.router.navigate([`purchase-return/form`],
      { queryParams: {
        purchaseLocalUUID: purchase?._localUUID,
      }});
    } catch (error) {
      SentryUtilites.setLog("PurchaseRowComponent:createPurchaseReturn", error)
      alert("Something went wrong.");
    }
  }

  async billView(purchase: Purchase) {
    try {
      if(!purchase?.deletedStamp) {
        let isPermit = await this.accessControlService.isUserHasAccess({action:'viewPurchase'});
        if(!isPermit) {
          return alert("Permission: You don't have permission to view bill. Please contact to your owner.");
        }
        this.router.navigate([`bill-view-purchase/${purchase?._localUUID}`]);
      }
    } catch (error) {
      SentryUtilites.setLog("PurchaseRowComponent:billView", error)
      alert("Something went wrong.");
    }
  }

  verifyTransactionPIN(event) {
    try {
      if(event) {
        this.router.navigate([`purchase/form/${this.purchase?._localUUID}`]);
      }
    } catch (error) {
      SentryUtilites.setLog("PurchaseRowComponent:verifyTransactionPIN", error)
    }
  }

  async edit(purchase: Purchase) {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({action:'editPurchase'});
      if(!isPermit) {
        return alert("Permission: You don't have permission to edit purchase. Please contact to your owner.");
      }
      this.openTransactionPINModal();
    } catch (error) {
      SentryUtilites.setLog("PurchaseRowComponent:edit", error)
      alert("Something went wrong.");
    }
  }

  resetSelectedPurchase() {
    this.selectedPurchase = null;
  }

  delete(purchase: Purchase) {
    this.onDeleteClick.emit(purchase);
  }

  getBalanceAmount(moneyOuts: MoneyOut[], totalAmount: number) {
    try {
      if(!moneyOuts || !moneyOuts?.length) {
        return this.capFractionsToTwo(totalAmount);
      }
      if (moneyOuts?.length) {
        let paidAmount: number = 0;
        moneyOuts?.forEach(moneyOut => paidAmount += Number(moneyOut.totalAmount));
        let pendingAmount = totalAmount - paidAmount;
        pendingAmount = pendingAmount < 0 ? 0 : pendingAmount;
        return this.capFractionsToTwo(pendingAmount);
      }
    } catch (error) {
      SentryUtilites.setLog("PurchaseRowComponent:getBalanceAmount", error)
      return 0;
    }
  }

  extraMoneyInAmount(moneyOuts: MoneyOut[], totalAmount: number) {
    try {
      if (moneyOuts?.length) {
        let receivedAmount: number = 0;
        moneyOuts?.forEach(moneyOut => receivedAmount += Number(moneyOut.totalAmount));
        if(receivedAmount > totalAmount) {
          return receivedAmount - totalAmount;
        }else {
          return 0;
        }
      }
    } catch (error) {
      SentryUtilites.setLog("PurchaseRowComponent:extraMoneyInAmount", error)
      return 0;
    }
  }

  async addPayment(purchase: Purchase) {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({action:'createMoneyOut'});
      if(!isPermit) {
        return alert("Permission: You don't have permission to create money out. Please contact to your owner.");
      }
      this.onAddPaymentClick.emit(purchase);
    } catch (error) {
      SentryUtilites.setLog("PurchaseRowComponent:addPayment", error)
      alert("Something went wrong.");
    }
  }

  async setPaymentMode(paymentMode: 'cash' | 'cheque' | 'bank') {
    try {
      if(this.purchase?.moneyOuts?.length) {
        this.purchase.moneyOuts[0].paymentMode = paymentMode;
        this.purchase = await this.allDataService.purchaseService.update(this.purchase);
      }
    } catch (error) {
      SentryUtilites.setLog("PurchaseRowComponent:setPaymentMode", error)
    }
  }

  async viewPurchaseReturn(purchase: Purchase) {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({action:'viewPurchaseReturn'});
      if(!isPermit) {
        return alert("Permission: You don't have permission to view Purchase Return. Please contact to your owner.");
      }
      if(purchase?.linkedPurchaseReturnUUID) {
        this.router.navigate([`bill-view-purchase-return/${purchase?.linkedPurchaseReturnUUID}`]);
      }
    } catch (error) {
      SentryUtilites.setLog("PurchaseRowComponent:viewPurchaseReturn", error)
    }
  }

}
