<ion-item
  class="party-details-holder alert-border"
  lines="none"
  [ngClass]="{'no-drop': !isEditable, 'alert-border': noPartySelectError && !selectedParty}"
  (click)="openPartySelectorModal()"
>
  <ion-grid>
    <ion-row>
      <ion-col size="12">
        <ion-icon class="icon-person" color="primary" name="person"></ion-icon>
        <ion-button fill="clear" class="btn-clear-txt-only minimum-btn-txt btn-txt-black">
          {{ shortStr(selectedParty?.name,25) }}
        </ion-button>
      </ion-col>
      <ion-col size="3">
        <ion-icon class="icon-wallet-phone" name="call" color="primary"></ion-icon>
        <ion-button *ngIf="selectedParty?.phone" class="btn-clear-txt-only minimum-btn-txt" fill="clear">
          {{ isMaskPhone ? stringMask(selectedParty?.phone) : selectedParty?.phone }}
        </ion-button>
        <ion-button *ngIf="!selectedParty?.phone" class="minimum-btn-txt btn-not-available" fill="clear">
          Not Available
        </ion-button>
      </ion-col>
      <ion-col size="4">
        <ion-icon class="icon-wallet-phone" name="wallet" color="primary"></ion-icon>
        <ion-button [color]="selectedParty?.credit>=0 ? 'success' : 'danger'" class="btn-clear-txt-only minimum-btn-txt" fill="clear">
          {{ MATHABS(selectedParty?.credit) | currency: 'INR' }}
        </ion-button>
      </ion-col>
      <ion-col size="1">
        <ion-button color="primary" class="btn-clear-txt-only minimum-btn-txt" fill="clear">
          GSTIN
        </ion-button>
      </ion-col>
      <ion-col size="4">
        <ion-button *ngIf="selectedParty?.gstin" color="tertiary" class="btn-clear-txt-only minimum-btn-txt btn-gstin" fill="clear">
          {{ selectedParty?.gstin }}
        </ion-button>
        <ion-button *ngIf="!selectedParty?.gstin" class="minimum-btn-txt btn-gstin btn-not-available" fill="clear">
          Not Available
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-item>

<ion-modal class="full-width-modal" [isOpen]="isModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar class="{{ getHeaderColorClass() }}">
        <ion-chip slot="start" color="tertiary" class="btn-back" (click)="openPartySelectorModal(false)">
          <ion-icon name="arrow-back-sharp"></ion-icon>
        </ion-chip>
        <ion-searchbar
          slot="start"
          #searchBar
          placeholder="Search party name or phone..."
          [debounce]="100"
          (ionChange)="onFilterChange($event['detail'].value)"
        >
        </ion-searchbar>
        <ion-chip
          slot="end"
          color="tertiary"
          class="last-btn"
          (click)="presentAddPartyAlert()"
        >
          + New Party
        </ion-chip>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <app-party-restaurant-list
        #partyRestaurantList
        [defaultCashParty]="defaultCashParty && !previouslyCashSaleAutoSelected"
        [selectedParty]="selectedParty"
        [isSale] = "isSale"
        [isMaskPhone]="isMaskPhone"
        (partySelectedEvent)="onPartySelect($event)"
        (addNewPartyEvent)="presentAddPartyAlert($event)"
        (partyRunningBillHashMap)="partyRunningBillHashMap($event)"
      >
      </app-party-restaurant-list>
    </ion-content>
  </ng-template>
</ion-modal>
