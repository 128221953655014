import { Utility } from './../../utils/utility';
import { Component, EventEmitter, OnInit, Output, ViewChild, ChangeDetectorRef, Input, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BillItem } from '../../models/BillItem.model';
import { Item } from '../../models/Item.model';
import { CalculateBillItemTask } from '../../utils/enums/CalculateBillItemTask';
import { ItemListComponent } from '../item-list/item-list.component';
import { BillCalculations } from '../../utils/BillCalculations';
import { BillType } from '../../utils/enums/BillType';
import { KotItem } from 'src/app/models/KotItem.model';
import { AllDataService } from 'src/app/services/all-data.service';
import Party, { BillingType } from '../../models/Party.model';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-item-selector',
  templateUrl: './item-selector.component.html',
  styleUrls: ['./item-selector.component.scss'],
})
export class ItemSelectorComponent implements OnInit {

  @Input() isPurchase: boolean = false;
  @Input() isPurchaseReturn: boolean = false;
  @Input() isEstimate:boolean = false;
  @Input() party: Party = null;
  @Input() paramDocumentId: string = null;
  @Input() iSetItemPriceHistoryStatus: boolean = false;
  @Input() barcodeScannerMode: boolean = false;

  @Output() itemAdded = new EventEmitter<BillItem[]>();
  @Output() focusAmountReceived = new EventEmitter<boolean>();

  @ViewChild('inpItemName', { static: false }) inpItemName;
  @ViewChild('inpQuantity', { static: false }) inpQuantity;
  @ViewChild('sbTax', { static: false }) sbTax;

  @ViewChild('itemListEl', { static: false }) itemListEl: ItemListComponent;

  billItemForm: FormGroup;

  selectedItems: BillItem[] = [];
  currentSelectedItem: Item = null;
  isModalOpen = false;
  searchText: string = '0.25%';

  unitsOptions: any = Utility.unitAbvrMap;
  taxTypeOptions = Utility.taxTypeOptions;
  sbTaxValue: string = null;

  isItemAddModalOpen = false;
  clickOnUnitOption: boolean = false;

  isEditable: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private cd: ChangeDetectorRef,
    private allDataService: AllDataService,
  ) { }

  ngOnInit() {
    this.initializeReactiveForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    try {
      if (changes?.barcodeScannerMode?.currentValue != changes?.barcodeScannerMode?.previousValue) {
        this.focusBarcodeSearchBar();
      }
    } catch (error) {
      SentryUtilites.setLog("ItemSelectorComponent:ngOnChanges", error)
    }
  }

  openItemAddModal(isOpen: boolean = true) {
    this.isItemAddModalOpen = isOpen;
  }

  /*
    Reactive Form Initialization
  */

  initializeReactiveForm() {
    try {
      this.billItemForm = this.formBuilder.group({
        itemName: [''],
        quantity: [null],
        price: [null],
        discountPercentage: [null],
        discount: [null],
        taxPercentage: [null],
        incTax: [null],
        unit: [''],
        total: [null],
        isTaxExempted: [null],
        isTaxZero: [null],
        isNonGstSupplies: [null]
      });
      // this.billItemForm.get("price").valueChanges.pipe(distinctUntilChanged(), debounceTime(300)).subscribe(price => {
      //   if(price != null && price < 1) {
      //     this.billItemForm.patchValue({price: Math.abs(price===0 ? 1 : price)});
      //   }
      // });
      this.billItemForm?.get("discountPercentage")?.valueChanges.pipe(distinctUntilChanged(), debounceTime(300)).subscribe(discountPercentage => {
        if(discountPercentage < 0 || discountPercentage > 100) {
          this.billItemForm.patchValue({discountPercentage: Math.abs(discountPercentage>100 ? 100 : discountPercentage)});
        }else if(discountPercentage!=null && !Number.isInteger(discountPercentage)) {
          let arr:string[] = (''+discountPercentage).split('.');
          if(arr[1]?.length > 3) {
            this.billItemForm.patchValue({discountPercentage: Number(arr[0]+'.'+arr[1].substr(0,3))});
          }
        }
      });
      this.billItemForm?.get("discountAmount")?.valueChanges.pipe(distinctUntilChanged(), debounceTime(300)).subscribe(discountAmount => {
        if(discountAmount < 0 || discountAmount > this.billItemForm?.value?.price) {
          this.billItemForm.patchValue({discountAmount: Math.abs(discountAmount > this.billItemForm?.value?.price ? this.billItemForm?.value?.price : discountAmount)});
        }else if(discountAmount!=null && !Number.isInteger(discountAmount)) {
          let arr:string[] = (''+discountAmount).split('.');
          if(arr[1]?.length > 3) {
            this.billItemForm.patchValue({discountAmount: Number(arr[0]+'.'+arr[1].substr(0,3))});
          }
        }
      });
    } catch (error) {
      SentryUtilites.setLog("ItemSelectorComponent:initializeReactiveForm", error)
    }
  }

  correctDiscountPercentageValue(discountPercentage: number) {
    try {
      if(discountPercentage!=null && !Number.isInteger(discountPercentage)) {
        let arr:string[] = (''+discountPercentage).split('.');
        if(arr[1]?.length > 3) {
          return Number(arr[0]+'.'+arr[1].substr(0,3));
        }
      }
    } catch (error) {
      SentryUtilites.setLog("ItemSelectorComponent:correctDiscountPercentageValue", error)
      return 0;
    }
  }

  correctDiscountValue(discountAmount: number) {
    try {
      if(discountAmount!=null && !Number.isInteger(discountAmount)) {
        let arr:string[] = (''+discountAmount).split('.');
        if(arr[1]?.length > 3) {
          return Number(arr[0]+'.'+arr[1].substr(0,3));
        }
      }
    } catch (error) {
      SentryUtilites.setLog("ItemSelectorComponent:correctDiscountValue", error)
      return 0;
    }
  }

  //----------------------------------------------------------------------------

  openItemSelectorModal(isOpen: boolean = true) {
    try {
      this.searchText = this.inpItemName?.value;
      if(this.searchText) {
        this.isModalOpen = isOpen;
        setTimeout(() => {
          this.itemListEl?.autoSearch(this.searchText);
        }, 0);
      }
    } catch (error) {
      SentryUtilites.setLog("ItemSelectorComponent:openItemSelectorModal", error)
    }
  }

  async onItemSelected(item: Item) {
    try {
      this.currentSelectedItem = item;
      this.billItemForm.patchValue({
        itemName: item?.itemName,
        quantity: 1,
        price: await this.getPrice(item),
        taxPercentage: item?.taxPercentage,
        incTax: item?.spIncTax,
        unit: item?.primaryUnit,
        isTaxExempted: item?.isTaxExempted,
        isTaxZero: item?.isTaxZero,
        isNonGstSupplies: item?.isNonGstSupplies
      });
      if (
        this.currentSelectedItem?.isNonGstSupplies == 0
        && this.currentSelectedItem?.isTaxExempted == 1
        && this.currentSelectedItem?.isTaxZero == 0
      ) {
        if (this.sbTax?.el) {
          this.sbTax.el.value = 'Exempted';
        }
      } else if( 
        this.currentSelectedItem?.isNonGstSupplies == 1
        && this.currentSelectedItem?.isTaxExempted == 0
        && this.currentSelectedItem?.isTaxZero == 0){
          if (this.sbTax?.el) {
            this.sbTax.el.value = 'Non-GST Supplies';
          }
      }else if (
        (
          this.currentSelectedItem?.isNonGstSupplies == 0
          && this.currentSelectedItem?.isTaxExempted == 0
          && this.currentSelectedItem?.isTaxZero == 1
        )
        ||
        this.currentSelectedItem?.taxPercentage
      ) {
        if (this.sbTax?.el) {
          this.sbTax.el.value = `${this.currentSelectedItem?.taxPercentage}%`;
        }
      }
      this.cd.detectChanges();
      this.openItemSelectorModal(false);
      setTimeout(() => {
        this.inpQuantity.setFocus();
      }, 100);
      return true;
    } catch (error) {
      SentryUtilites.setLog("ItemSelectorComponent:onItemSelected", error)
      return false;
    }
  }

  addItemToSelectedList(data?:{quantity?:number,kotNote?:string}) {
    try {
      if(this.currentSelectedItem) {
        let billItem = new BillItem();
        billItem = {...this.billItemForm.value }
        billItem.item = new Item();
        billItem.item = this.currentSelectedItem;
        billItem.convertRatioMultiplier = billItem?.item?.convertRatioR;
        if(data?.quantity) {
          billItem.quantity = data?.quantity;
        }
        if(data?.kotNote) {
          billItem.kotNote = data?.kotNote;
        }
        let task = CalculateBillItemTask.CALCULATE;
        if (billItem.discount) {
          task = CalculateBillItemTask.DISCOUNT_FROM_AMOUNT;
        }
        if (this.isPurchase || this.isPurchaseReturn) {
          BillCalculations.calculateBillItem(billItem,BillType.PURCHASE, null, null, task);
        } else {
          BillCalculations.calculateBillItem(billItem,BillType.SALE, null, null, task);
        }
        this.selectedItems.push(billItem);
        this.itemAdded.emit(this.selectedItems);
        this.resetCurrentBillItem();
        setTimeout(() => {
          this.inpItemName.setFocus();
        }, 100);
      }
    } catch (error) {
      SentryUtilites.setLog("ItemSelectorComponent:addItemToSelectedList", error)
    }
  }

  getBillItems() {
    return this.selectedItems;
  }

  setBillItems(billItems: BillItem[]) {
    this.selectedItems = billItems;
  }

  resetCurrentBillItem() {
    try {
      this.currentSelectedItem = null;
      this.billItemForm.reset();
      if(this.sbTax) {
        this.sbTax.value = null;
      }
    } catch (error) {
      SentryUtilites.setLog("ItemSelectorComponent:resetCurrentBillItem", error)
    }
  }

  onTaxSelect(event) {
    try {
      let value = event?.detail?.value;
      if (value == 'Exempted') {
        this.billItemForm.patchValue({
          isTaxExempted: 1,
          isTaxZero: 0,
          taxPercentage: Utility.taxTypeOptions[value],
        });
      } else if (value == '0%') {
        this.billItemForm.patchValue({
          isTaxExempted: 0,
          isTaxZero: 1,
          taxPercentage: Utility.taxTypeOptions[value],
        });
      } else {
        this.billItemForm.patchValue({
          isTaxExempted: 0,
          isTaxZero: 0,
          taxPercentage: Utility.taxTypeOptions[value],
        });
      }
    } catch (error) {
      SentryUtilites.setLog("ItemSelectorComponent:onTaxSelect", error)
    }
  }

  returnZero() {
    return 0;
  }

  editItem(billItem: BillItem) {
    try {
      if(this.isEditable) {
        this.addItemToSelectedList();
        this.removeItem(billItem);
        this.currentSelectedItem = { ...billItem.item };
        this.billItemForm.patchValue({ ...billItem });
        if (
          this.currentSelectedItem.taxPercentage == 0
          && this.currentSelectedItem.isTaxExempted == 1
        ) {
          if (this.sbTax?.el) {
            this.sbTax.el.value = 'Exempted';
          }
        } else if (
          (
            this.currentSelectedItem.taxPercentage == 0
            && this.currentSelectedItem.isTaxZero == 1
          )
          ||
          this.currentSelectedItem.taxPercentage
        ) {
          if (this.sbTax?.el) {
            this.sbTax.el.value = `${this.currentSelectedItem.taxPercentage}%`;
          }
        }
        this.cd.detectChanges();
        setTimeout(() => {
          this.inpQuantity.setFocus();
        }, 100);
      }
    } catch (error) {
      SentryUtilites.setLog("ItemSelectorComponent:editItem", error)
    }
  }

  removeItem(billItem: BillItem) {
    try {
      const index = this.selectedItems.findIndex(x => billItem?.item?._localUUID === x?.item?._localUUID);
      this.selectedItems.splice(index, 1);
    } catch (error) {
      SentryUtilites.setLog("ItemSelectorComponent:removeItem", error)
    }
  }

  async onBarcodeSearchEnter() {
    try {
      if(this.barcodeScannerMode) {
        let item = await this.itemListEl?.onBarcodeSearchEnter(this.inpItemName?.value);
        if(item?._localUUID) {
          let itemFound = this.selectedItems?.filter(x => x?.item?._localUUID === item?._localUUID);
          if(itemFound?.length) {
            itemFound[0].quantity += 1;
            this.editItem(itemFound[0]);
          }else {
            await this.onItemSelected(item);
          }
          this.addItemToSelectedList();
          this.inpItemName.value = '';
          this.inpItemName?.setFocus();
        }else {
        }
      }
    } catch (error) {
      SentryUtilites.setLog("ItemSelectorComponent:onBarcodeSearchEnter", error)
    }
  }

  focusBarcodeSearchBar() {
    try {
      if(this.barcodeScannerMode) {
        setTimeout(() => {
          this.inpItemName?.setFocus();
        }, 1000);
      }
    } catch (error) {
      SentryUtilites.setLog("ItemSelectorComponent:focusBarcodeSearchBar", error)
    }
  }

  resetCart() {
    this.setBillItems([]);
    this.resetCurrentBillItem();
  }

  async selectOrderItem(orderItems: KotItem[]) {
    try {
      let completeList = await this.allDataService.itemService.getAllByPromise();
      for (let i = 0; i < orderItems?.length; i++) {
        const orderItem = orderItems[i];
        let index = completeList.findIndex(item => item?._localUUID==orderItem?.itemUUID)
        if(index != -1) {
          await this.onItemSelected(completeList[index]);
          this.addItemToSelectedList({quantity:orderItem?.quantity,kotNote:orderItem?.note});
        }
      }
    } catch (error) {
      SentryUtilites.setLog("ItemSelectorComponent:selectOrderItem", error)
    }
  }

  saveTrigger(item: Item) {
    this.itemListEl?.loadView();
    this.onItemSelected(item);
    this.openItemAddModal(false);
    setTimeout(() => {
      this.inpQuantity.setFocus();
    }, 1000);
  }

  async getPrice(item: Item) {
    try {
      if(!this.paramDocumentId) {
        let priceMap = null;
        if(this.party?._localUUID && this.iSetItemPriceHistoryStatus && !this.isPurchase && !this.isPurchaseReturn) {
          priceMap = await this.allDataService.partyItemPriceMapService.getByPartyUUIDItemUUID(this.party?._localUUID,item?._localUUID);
        }
        return priceMap?.sellPrice || this.getPriceByBillingType(item);
      }else {
        return this.isPurchase || this.isPurchaseReturn ? item?.purchasePrice || 0.0 : item?.sellPrice || 0.0;
      }
    } catch (error) {
      SentryUtilites.setLog("ItemSelectorComponent:getPrice", error)
    }
  }

  getPriceByBillingType(item: Item): number {
    try {
      if(this.isPurchase || this.isPurchaseReturn) {
        return item?.purchasePrice || 0.0;
      }
      if(this.party?.billingType == BillingType.OnlineDeliverySellPrice && item?.onlineDeliverySellPrice!=null && item?.onlineDeliverySellPrice > -1) {
        return item?.onlineDeliverySellPrice;
      }else if(this.party?.billingType == BillingType.AcSellPrice && item?.acSellPrice!=null && item?.acSellPrice > -1) {
        return item?.acSellPrice;
      }else if(this.party?.billingType == BillingType.NonAcSellPrice && item?.nonAcSellPrice!=null && item?.nonAcSellPrice > -1) {
        return item?.nonAcSellPrice;
      }else if(this.party?.billingType == BillingType.SellPrice && item?.sellPrice!=null && item?.sellPrice > -1) {
        return item?.sellPrice;
      }
      return item?.sellPrice;
    } catch (error) {
      SentryUtilites.setLog("ItemSelectorComponent:getPriceByBillingType", error)
      return 0;
    }
  }

  /**
   * @description : provide only selected primary and secondary unit
   */
  showUnitPerItem() {
    try {
      this.clickOnUnitOption = true;
      let newUnitOptions = {};
      if(this.currentSelectedItem?.primaryUnit) {
        newUnitOptions[this.currentSelectedItem?.primaryUnit] = this.unitsOptions[this.currentSelectedItem?.primaryUnit] || [this.currentSelectedItem?.primaryUnit]; 
      }
      if(this.currentSelectedItem?.secondaryUnit) {
        newUnitOptions[this.currentSelectedItem?.secondaryUnit] = this.unitsOptions[this.currentSelectedItem?.secondaryUnit] || [this.currentSelectedItem?.secondaryUnit]; 
      }
      this.unitsOptions = newUnitOptions;
    } catch (error) {
      SentryUtilites.setLog("ItemSelectorComponent:showUnitPerItem", error)
    }
  }
  // ------------------------------------------------

  /**
   * 
   * @param event : change unit event
   * @description : update price as per primary and secondary unit
   */
  updateUnitChangePrice(event) {
    try {
      if(this.clickOnUnitOption) {
        if(this.currentSelectedItem) {
          let price = 0;
          if(event?.detail?.value == this.currentSelectedItem?.secondaryUnit) {
            if(!this.isPurchase && !this.isPurchaseReturn) {
              price = (+this.currentSelectedItem?.sellPrice || 0) * this.currentSelectedItem?.convertRatio;
            } else {
              price = (+this.currentSelectedItem?.purchasePrice || 0) * this.currentSelectedItem?.convertRatio;
            }
          } else {
            if(!this.isPurchase && !this.isPurchaseReturn) {
              price = +this.currentSelectedItem?.sellPrice || 0;
            } else {
              price = +this.currentSelectedItem?.purchasePrice || 0;
            }
          }
          this.billItemForm.patchValue({price});
          this.clickOnUnitOption = false;
        }
      }
    } catch (error) {
      SentryUtilites.setLog("ItemSelectorComponent:updateUnitChangePrice", error)
    }
  }
  // ------------------------------------------------

  enable() {
    this.isEditable = true;
  }

  disable() {
    this.isEditable = false;
  }

}
