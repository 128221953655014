import { EstimateBillPrint } from "src/models/EstimateBillPrint.model";
import { Arrays } from "../utils/ReferenceData";
import { commonTempData } from "./commonTempData";
import { Utility } from "../utils/Utility";

export class Temp17Estimate{

    constructor(
      private data: EstimateBillPrint,
      ){
      }

    totalSaving = 0;

    async main(){
      return `
      <style>
      ${commonTempData.invoiceHtmlTemplateCss()}
      .three-inch-holder *{
        margin:0;
        padding:0;
      }
      .three-inch-holder b{
        color:#000;
      }
      .three-inch-holder .text-sm{
        font-size:10px;
      }
      .text-3i-h1{
        font-size:18px;
        font-weight:700;
        word-break:break-word;
      }
      .text-3i-h2{
        font-size:16px;
        word-break:break-all;
      }
      .text-3i-h3{
        font-size:14px;
        word-break:break-all;
      }
      .text-3i-p{
        font-size:11px;
        word-break:break-all;
      }
      </style>
      <span class="invoice-template-main">
        <div 
          class="container-new arial three-inch-holder"
          style="width:70mm;background: white;padding: 2mm;padding-bottom: 70px;visibility: visible;-webkit-print-color-adjust: exact !important;color-adjust: exact !important;">
          ${this.header()}
          ${await this.templateBody()}
          ${await this.footer()}
        </div>
      </span>
      `
    }

    header(){
      return `
        ${this.sellerDetails()}
        ${this.invoiceTitle()}`;

    }

    async footer(){
      let domain='';
      let includeDukanUrl=0;
      try{
        includeDukanUrl=this.data?.settings?.include3InchOnlineDukanLink ? 1 : 0
      }catch(err){

      }
      let html = ``;
      let note = this.data?.bill?.note
      

      if(
         this.data?.onlineDukan?.domain!=""){
          domain=this.data?.onlineDukan?.domain;
        }
      html = `<div class="row" style="margin-top:20px;"></div>
      `
      if (note) {
        html += `
      <div style="text-align:left">
        <p class="text-3i-h3">(Note : ${note})</p>
      </div>
      <br/>
      `
      }
      
      html+=`
      <div style="text-align:center">
        <p class="text-3i-h3" style="text-align: justify;text-align-last: center;word-break:break-word">${(this.data?.extra?.thermalTncTemplate || '').replace(/\n/g,'<br/>')}</p>
        ${domain?.length>0 && includeDukanUrl?`
          <p class="text-3i-h3">Now Order Online @</p>
          <p class="text-3i-h3">https://${domain}.ezo.digital</p>
        `:''}
        
      </div>
      `;

      if(this.data?.qrPayLink){
        let qrCodeBase64 = await commonTempData.generateQR(this.data?.qrPayLink);
  
        html += `
        <div style="width: 100%; margin: auto;" class="m-l-neg-10 m-l-neg-2">
          <img style="width: 100%;" src="${qrCodeBase64}">
        </div>`;
      }

      return html;
      
    }

    includeTaxPercentHeader=false;
    includeHsnHeader=false;
    includeMrpHeader=false;

    async templateBody(){
      let html='';
      
      let totalQty=0;
      let totalTax=0;
      let isTax = false;
      let totalTaxAmt =0;
      let sameState = false;

      let partyInfo = this.data?.bill?.partyData?.profileData||null;
      
      let partyStateCode = partyInfo?.gstNumber?.substring(0,2) ? Utility.statesNamesByStateCode[partyInfo?.gstNumber?.substring(0,2)] : null;
      let profileStateCode = this.data?.profile?.gstin?.substring(0,2) ? Utility.statesNamesByStateCode[this.data?.profile?.gstin?.substring(0,2)] : null;
      let userState = profileStateCode || this.data?.profile?.addressProvience?.toUpperCase();
      let partyState = partyStateCode || this.data?.bill?.deliveryProvience?.toUpperCase();

      let gstStr='SGST';

      if(userState && partyState) {
        sameState = userState === partyState;
        if(sameState && Arrays.UTS.indexOf(userState) != -1) {
          gstStr='UTGST';
        } 
      } else {
        sameState = true;
        if(Arrays.UTS.indexOf(userState) != -1 || Arrays.UTS.indexOf(partyState) != -1) {
          gstStr='UTGST';
        } 
      }

      if (this.data?.bill?.gstAmount || this.data?.bill?.cessAmount) {
        totalTaxAmt = this.data?.bill?.gstAmount + this.data?.bill?.cessAmount;
        isTax = true;
      }

      for(let i=0;i<this.data?.bill?.billItems?.length;i++){
        let invoiceItem=this.data?.bill?.billItems[i];
        totalQty+=invoiceItem.quantity ||0;
        try{
          html+=this.itemRow(invoiceItem);
        }catch(err){
          console.error(err);
        }
      }
      if(this.data?.bill?.totalSaving>0 && this.data?.settings?.billSavingsAmount){
        this.totalSaving=this.data?.bill?.totalSaving;
      }
      let htmlHeader=`
      <div class="row">
        <hr/>
        <div class="col-xs-6" style="text-align:left">
        <b class="text-3i-h3">Item Name</b>
        </div>
        <div class="col-xs-2" style="text-align:center">
        <b class="text-3i-h3">Qty</b>
        </div>
        <div class="col-xs-2" style="text-align:right">
        <b class="text-3i-h3">Rate</b>
        </div>
        <div class="col-xs-2" style="text-align:right">
        <b class="text-3i-h3">Total</b>
        </div>
        <div class="col-xs-6" style="text-align:right">
        
        </div>
        <div class="col-xs-2" style="text-align:right">
        <span class="text-sm class="text-3i-h3"">${this.includeTaxPercentHeader?'Tax%':''}</span>
        </div>
        <div class="col-xs-2" style="text-align:right">
        <span class="text-sm class="text-3i-h3"">${this.includeMrpHeader?'MRP':''}</sapn>
        </div>
        <div class="col-xs-2" style="text-align:right">
        <span class="text-sm class="text-3i-h3"">${this.includeHsnHeader?'HSN':''}</span>
        </div>
      </div> 
      <div class="row">
        <hr/>
      </div>
      `;
      html=htmlHeader+html;
      let serviceChargeStr=``;
      if(this.data?.bill?.serviceChargePercentage){
        serviceChargeStr=`<div class="col-xs-12 text-3i-h3" style="text-align:right">
        Service Charge : (${this.data?.bill?.serviceChargePercentage}%) ${this.data?.bill?.serviceChargeAmount}
        </div>`;
      }
      html+=`
        <div class="row">
          <hr/>
          <div class="col-xs-6 text-3i-h3" style="text-align:left">
          Total Items : ${this.data?.bill?.billItems?.length}
          </div>
          <div class="col-xs-6 text-3i-h3" style="text-align:right">
          Total Quantity : ${commonTempData.unitAdjusterUptoThree(totalQty)}
          </div>
          ${serviceChargeStr}
        </div>
        <div class="row">
        <hr/>
        <div class="col-xs-12 text-3i-h3" style="text-align:right">
        Sub Total (without tax) : ${commonTempData.unitAdjuster(this.data?.bill?.subTotalAmount)}
        </div>
          `
          if(this.data?.bill?.cashDiscount){
            html +=
            `<div class="col-xs-12 text-3i-h3" style="text-align:right">
            Cash Discount : ${commonTempData.unitAdjuster(this.data?.bill?.cashDiscount)} (${this.data?.bill?.cashDiscountPercentage}%)
            </div>`
          }
 
          if (!sameState && isTax) {
            html += this.commonTaxAndAmtDetails('IGST', commonTempData.unitAdjuster(this.data?.bill?.gstAmount));
          }
      
          if (sameState && isTax) {
            html += this.commonTaxAndAmtDetails(gstStr, commonTempData.unitAdjuster(this.data?.bill?.gstAmount / 2));
          }
      
          if (sameState && isTax) {
            html += this.commonTaxAndAmtDetails('CGST', commonTempData.unitAdjuster(this.data?.bill?.gstAmount / 2));
          }
      
          if (this.data?.bill?.cessAmount) {
            html += this.commonTaxAndAmtDetails('CESS', commonTempData.unitAdjuster(this.data?.bill?.cessAmount));
          }

          if (this.data?.bill?.roundOffValue) {
            html += this.commonTaxAndAmtDetails('Round off', commonTempData.unitAdjuster(this.data?.bill?.roundOffValue));
          }

          html +=`
          
          <div class="col-xs-12" style="text-align:center">
          <hr/>
            <b  class="text-3i-h2">
              Total : ${commonTempData.unitAdjuster(this.data?.bill?.totalAmount)}
            </b>
          <hr/>
          </div>
          
          `
          
          let additionalAmount = this.data?.bill?.additionalAmount || 0;
          if(additionalAmount){
            html += this.commonTwoRowDetails('Labour/Delivery Charges', additionalAmount);
          }
      
          // if(this.data?.bill?.cashDiscount){
          //   html +=this.commonTwoRowDetails('Cash Discount', `${commonTempData.unitAdjuster(this.data?.bill?.cashDiscount)} (${this.data?.bill?.cashDiscountPercentage}%)`);
          // }
  
        if(this.totalSaving > 0){
          html +=this.commonTwoRowDetails('Total saving on this bill', commonTempData.unitAdjuster(this.totalSaving));
        }

        html+=`
        </div>
        <div class="row">
          <hr/>
        </div>
      `
      return html;
    }

    commonTwoRowDetails(title,value){
      return `
          <div class="col-xs-9" style="text-align:right">${title}</div>
          <div class="col-xs-3" style="text-align:right">${value}</div>
      `
    }

    commonTaxAndAmtDetails(title, value){

      return `
          <div class="col-xs-6"></div>
          <div class="col-xs-3 text-3i-h3" style="text-align:right">${title}</div>
          <div class="col-xs-3 text-3i-h3" style="text-align:right">${value}</div>
      `
    }

    itemRow(invoiceItem:any){
      if(!(this.data?.settings?.pSetTaxMrpHsnPrintStatus===false) && (invoiceItem?.taxPercentage)){
        this.includeTaxPercentHeader=true;
      }
      if(!(this.data?.settings?.pSetTaxMrpHsnPrintStatus===false) && (invoiceItem?.mrp || invoiceItem?.item?.mrp)){
        this.includeMrpHeader=true;
      }
      if(!(this.data?.settings?.pSetTaxMrpHsnPrintStatus===false) && (invoiceItem?.hsn)){
        this.includeHsnHeader=true;
      }
      return `
      <div class="row">
        <div class="col-xs-6 text-3i-h3" style="text-align:left">
          ${invoiceItem?.item?.itemName}
          <p style="word-wrap: break-word;">${(((invoiceItem?.itemDes && invoiceItem.itemDes != "0") ? invoiceItem.itemDes : '') + '').substring(0, 100)}</p>
          <p style="word-wrap: break-word;">${(invoiceItem?.itemSrl ? '(' + invoiceItem.itemSrl + ')' : '')}</p>
        </div>
        <div class="col-xs-2 text-3i-h3" style="text-align:center">
        ${commonTempData.unitAdjusterUptoThree(invoiceItem?.quantity)}
        </div>
        <div class="col-xs-2 text-3i-h3" style="text-align:right">
          ${invoiceItem?.price}
        </div>
        <div class="col-xs-2 text-3i-h3" style="text-align:right">
          ${commonTempData.unitAdjuster(invoiceItem?.total)}
        </div>
        <div class="col-xs-6 text-3i-h3" style="text-align:right">
          ${invoiceItem?.totalDiscount?' Discount : Rs '+commonTempData.unitAdjuster(invoiceItem?.totalDiscount):''}
        </div>
        <div class="col-xs-2 text-3i-h3" style="text-align:right">
          <span class="text-sm">${this.includeTaxPercentHeader? (invoiceItem?.taxPercentage?commonTempData.unitAdjuster(invoiceItem?.taxPercentage)+"%":''):''}</span>
        </div>
        <div class="col-xs-2 text-3i-h3" style="text-align:right">
          <span class="text-sm">${this.includeMrpHeader? (invoiceItem?.mrp || invoiceItem?.item?.mrp ||'') : ''}</span>
        </div>
        <div class="col-xs-2 text-3i-h3" style="text-align:right">
          <span class="text-sm">${this.includeHsnHeader? (invoiceItem?.hsn ||'') : ''}</span>
        </div>
      </div>
      `
    }

    sellerDetails(){
        let html = '';
        if(this.data?.profile?.logoLink){
          html += `
          <div style="display: flow-root;width: 100%;">
            <img src="${this.data?.profile?.logoLink}" style="display: block;margin:auto;width:100px;height: 100px; max-width: none !important;">
          </div>
          `
        }
        
        html+= `<h5 class="text-center">Estimate</h5>`

        let sellerProfileData = this.data?.profile;
        html+=`<div style="text-align:center">
          <h3 class="text-3i-h1">${this.data?.profile?.legalName ||''}</h3>
          <p class="text-3i-h3">${sellerProfileData?.addressLine1 || ''},${sellerProfileData?.addressCity || ''}${sellerProfileData?.addressProvience?', '+sellerProfileData?.addressProvience:''}${sellerProfileData?.addressPostalCode?', '+sellerProfileData?.addressPostalCode:''}</p>
          <p class="text-3i-h3">${(this.data?.profile?.contactPersonPhone && this.data?.profile?.contactPersonPhone!='0')? 'Phone: '+this.data?.profile?.contactPersonPhone:''}</p>
          <p class="text-3i-h3">${(this.data?.profile?.contactPersonEmail)? 'Email: '+this.data?.profile?.contactPersonEmail:''}</p>
          <p class="text-3i-h3" style="word-break:break-word">${this.data?.profile?.gstin?'GST Number : '+this.data?.profile?.gstin:''}</p>
          <p class="text-3i-h3" style="word-break:break-word">${this.data?.profile?.fssaiNumber?'FSSAI No. : '+this.data?.profile?.fssaiNumber:''}</p>
          <p class="text-3i-h3" style="word-break:break-word">${this.data?.profile?.licenseNumber?'License No. : '+this.data?.profile?.licenseNumber:''}</p>
          <p class="text-3i-h3" style="word-break:break-word">
            ${
              this.data?.profile?.additionalInputFieldTitle1 
              && ((this.data?.profile?.additionalInputFieldTitle1 +"").trim()).length>0 
              && (this.data?.profile?.additionalInputFieldValue1)
              && ((this.data?.profile?.additionalInputFieldValue1 + "").trim()).length>0 ? `${this.data?.profile?.additionalInputFieldTitle1}:</b> ${this.data?.profile?.additionalInputFieldValue1}`:''
            }
          </p>
          <p class="text-3i-h3" style="word-break:break-word">
            ${
              this.data?.profile?.additionalInputFieldTitle2 
              && ((this.data?.profile?.additionalInputFieldTitle2 +"").trim()).length>0 
              && (this.data?.profile?.additionalInputFieldValue2)
              && ((this.data?.profile?.additionalInputFieldValue2 + "").trim()).length>0 ? `${this.data?.profile?.additionalInputFieldTitle2}:</b> ${this.data?.profile?.additionalInputFieldValue2}`:''
            }
          </p>
          <p class="text-3i-h3" style="word-break:break-word">
            ${
              this.data?.profile?.additionalInputFieldTitle3 
              && ((this.data?.profile?.additionalInputFieldTitle3 +"").trim()).length>0 
              && (this.data?.profile?.additionalInputFieldValue3)
              && ((this.data?.profile?.additionalInputFieldValue3 + "").trim()).length>0 ? `${this.data?.profile?.additionalInputFieldTitle3}:</b> ${this.data?.profile?.additionalInputFieldValue3}`:''
            }
          </p>
          <p class="text-3i-h3" style="word-break:break-word">
            ${
              this.data?.profile?.additionalInputFieldTitle4 
              && ((this.data?.profile?.additionalInputFieldTitle4 +"").trim()).length>0 
              && (this.data?.profile?.additionalInputFieldValue4)
              && ((this.data?.profile?.additionalInputFieldValue4 + "").trim()).length>0 ? `${this.data?.profile?.additionalInputFieldTitle4}:</b> ${this.data?.profile?.additionalInputFieldValue4}`:''
            }
          </p>
          <p class="text-3i-h3" style="word-break:break-word">
            ${
              this.data?.profile?.additionalInputFieldTitle5 
              && ((this.data?.profile?.additionalInputFieldTitle5 +"").trim()).length>0 
              && (this.data?.profile?.additionalInputFieldValue5)
              && ((this.data?.profile?.additionalInputFieldValue5 + "").trim()).length>0 ? `${this.data?.profile?.additionalInputFieldTitle5}:</b> ${this.data?.profile?.additionalInputFieldValue5}`:''
            }
          </p>
        </div>`

        return html;

    }

    invoiceTitle(){
      let party = this.data?.bill?.partyData?.profileData ||null;
      let isBillDateDiff=commonTempData.isBillDateDiff(this.data?.bill?.createdStamp,this.data?.bill?.billDateStamp);
      let html = `
        <div class="row">
          <hr/>
          <div class="col-xs-12 text-3i-h2">
           <b>Bill No</b>: ${this.data?.bill?.billNo ||''}
          </div>
          <div class="col-xs-12 text-3i-h2">
            
            <b>Created on</b>: ${this.data?.bill?.createdStamp?commonTempData.dateToDDMMYYYHHMMAP(this.data?.bill?.createdStamp):''}
            ${isBillDateDiff? `
            <b>Bill Date</b>: ${commonTempData.dateToDDMMYY(this.data?.bill?.billDateStamp)}
            `:''}
          </div>
          ${this.data?.settings?.iSetPrintTimeOnBill?`
          <div class="row text-3i-p">
             
          </div>
          `:''}
          <div class="col-xs-12 text-3i-h2" style="text-align:right">
            ${this.data?.bill?.dueDateStamp && this.data?.bill?.dueDateStamp>0 ? this.commonDateFormat2('Due On', this.data?.bill?.dueDateStamp):''}
          </div>
        </div>
        
        <div class="row">
          <hr/>
          <div class="col-xs-12 text-3i-h2">
            <b>Bill To</b> : ${party?.contactPersonName}${party?.contactPersonPhone ? ' <hr/> '+party?.contactPersonPhone : ''}
            ${this.data?.bill?.isPartyDeleted ? "<hr/><p class='text-danger'>Party Has Been Deleted</p>" : "" }
            ${party?.gstNumber ? `<hr/>`+party?.gstNumber+`<hr/>` :''}
          </div>
          
          `;

          if(party?.addressLine1){
            html+=`<div class="col-xs-12 text-3i-h2">
          <b>Billing Address :</b>  ${party?.addressLine1 ? party?.addressLine1 : ''} ${party?.addressState ? ', ' + party?.addressState : ''}${party?.addressPincode ? ', ' + party?.addressPincode : ''}
            </div>`
          }
          if(this.data?.bill?.deliveryProvience || party?.addressOneLine1 || party?.addressOneState){
            let deliveryState = this.data?.bill?.deliveryProvience || party?.addressOneState;
            html+=`<div class="col-xs-12 text-3i-h2">
            <b>Delivery Address :</b>  ${party?.addressOneLine1 || ''}${deliveryState? ', '+deliveryState:''}${party?.addressOnePincode?', '+party?.addressOnePincode:''}
            </div>`
          }

          if(this.data?.bill?.secondaryPartyData?.profileData?.contactPersonName) {
            html += `
              <div class="col-xs-12 text-3i-h2">
                <b>Bill To</b> : ${this.data?.bill?.secondaryPartyData?.profileData?.contactPersonName}${this.data?.bill?.secondaryPartyData?.profileData?.contactPersonPhone ? ' <hr/> '+this.data?.bill?.secondaryPartyData?.profileData?.contactPersonPhone : ''}
                ${this.data?.bill?.secondaryPartyData?.profileData?.gstNumber ? `<hr/>`+this.data?.bill?.secondaryPartyData?.profileData?.gstNumber+`<hr/>` :''}
                </div>
            `;
          }
          if(this.data?.bill?.secondaryPartyData?.profileData?.addressLine1) {
            html += `
              <div class="col-xs-12 text-3i-h2">
                  Address : ${this.data?.bill?.secondaryPartyData?.profileData?.addressLine1 ? this.data?.bill?.secondaryPartyData?.profileData?.addressLine1 : ''} ${this.data?.bill?.secondaryPartyData?.profileData?.addressState ? ', ' + this.data?.bill?.secondaryPartyData?.profileData?.addressState : ''}${this.data?.bill?.secondaryPartyData?.profileData?.addressPincode ? ', ' + this.data?.bill?.secondaryPartyData?.profileData?.addressPincode : ''}
              </div>
            `;
          }
          
      html += `
            
        </div>
        <div class="row">
        <hr/>
        </div>
      `;
      
      return html;
    }


    commonDateFormat(dateName, timeStamp){
      return `
        <div
          style="display: flow-root;font-weight: bold;color: #000;width: 50%;float: left;"
          class="f-14 f-b m-5 text-3i-h2">
            ${dateName} :
          </div>

          <div
          style="display: flow-root;color: #000;width: 50%;float: left;text-align: right;"
          class="f-14 m-5 text-3i-h2">
             ${commonTempData.dateToDDMMYYY(timeStamp)}
        </div>
      `
    }

    commonDateFormat2(dateName, timeStamp){
      return `
        <div
          style="display: flow-root;font-weight: bold;color: #000;width: 50%;float: left;"
          class="f-14 f-b m-5 text-3i-h2">
            ${dateName} :
          </div>

          <div
          style="display: flow-root;color: #000;width: 50%;float: left;text-align: right;"
          class="f-14 m-5 text-3i-h2">
             ${commonTempData.dateToDDMMYY(timeStamp)}
        </div>
      `
    }


}

