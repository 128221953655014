import { MonthWisePartyCreditDao } from './dao/month-wise-party-credit.dao';
import { MonthWiseItemStockDao } from './dao/month-wise-item-stock.dao';
import { BehaviorSubject } from "rxjs";
import { Sale } from "../models/Sale.model";
import { Utility } from "../utils/utility";
import { ExpressServerService } from "./api/express-server.service";
import { IDataService } from "../../interface/IDataService.interface";
import { ItemDao } from "./dao/item.dao";
import { PartyDao } from "./dao/party.dao";
import { MoneyInDao } from "./dao/money-in.dao";
import { SaleDao } from "./dao/sale.dao";
import { MoneyIn } from "../models/MoneyIn.model";
import { AuthService } from "./auth/auth.service";
import { PartyItemPriceMapDao } from "./dao/party-item-price-map.dao";
import PartyItemPriceMap from "../models/PartyItemPriceMap.model";
import { ProfileDao } from "./dao/profile.dao";
import { Utils } from './../utils/utils';
import { SentryUtilites } from '../utils/sentryUtilites';

export class SaleService implements IDataService<Sale>{
  private static _instance: SaleService;

  public static getInstance(
    saleDao: SaleDao,
    partyDao: PartyDao,
    itemDao: ItemDao,
    moneyInDao: MoneyInDao,
    partyItemPriceMapDao: PartyItemPriceMapDao,
    profileDao: ProfileDao,
    expressServerService: ExpressServerService,
    authService: AuthService,
    monthWisePartyCreditDao: MonthWisePartyCreditDao,
    monthWiseItemStockDao: MonthWiseItemStockDao,
  ) {
    if (!this._instance) {
      this._instance = new SaleService(
        saleDao,
        partyDao,
        itemDao,
        moneyInDao,
        partyItemPriceMapDao,
        profileDao,
        expressServerService,
        authService,
        monthWisePartyCreditDao,
        monthWiseItemStockDao,
      )
      this._instance.initService();
    }
    this._instance.reloadList();
    return this._instance;
  }

  constructor(
    dao: SaleDao,
    partyDao: PartyDao,
    itemDao: ItemDao,
    moneyInDao: MoneyInDao,
    partyItemPriceMapDao: PartyItemPriceMapDao,
    profileDao: ProfileDao,
    expressServerService: ExpressServerService,
    authService: AuthService,
    monthWisePartyCreditDao: MonthWisePartyCreditDao,
    monthWiseItemStockDao: MonthWiseItemStockDao,
  ) {
    this.dao = dao;
    this.expressServerService = expressServerService;
    this.itemDao = itemDao
    this.partyDao = partyDao;
    this.moneyInDao = moneyInDao;
    this.partyItemPriceMapDao = partyItemPriceMapDao;
    this.profileDao = profileDao;
    this.authService = authService;
    this.monthWisePartyCreditDao = monthWisePartyCreditDao;
    this.monthWiseItemStockDao = monthWiseItemStockDao;
  }
  dao: SaleDao;
  itemDao: ItemDao;
  partyDao: PartyDao;
  moneyInDao: MoneyInDao;
  partyItemPriceMapDao: PartyItemPriceMapDao;
  profileDao: ProfileDao;
  expressServerService: ExpressServerService;
  authService: AuthService;
  monthWisePartyCreditDao: MonthWisePartyCreditDao;
  monthWiseItemStockDao: MonthWiseItemStockDao;

  LIST_REFRESH_RATE = 1000;
  selectedProfileId: string = null;
  selectedProfileUserId: string = null;
  updateSubs = new BehaviorSubject<Sale>(null);


  lastReloadStamp: number = 0;
  isReloadPostpond = false;

  initService() {
    this.selectedProfileId = Utility.getFromLocalStorage('selectedProfile');
    this.selectedProfileUserId = Utility.getFromLocalStorage('selectedProfileUserId');
    this.reloadList();
  }


  async reloadList() {
    try {
      if (this.isReloadPostpond) {
        return;
      }
      const currentStamp = +new Date();
      if (this.lastReloadStamp < (currentStamp - this.LIST_REFRESH_RATE)) {
        this.lastReloadStamp = currentStamp;
        this.trySyncUnsynced();
      } else {
        this.isReloadPostpond = true;
        setTimeout(() => {
          this.isReloadPostpond = false;
          this.reloadList();
        }, this.LIST_REFRESH_RATE + 100);
      }
    } catch (error) {
      SentryUtilites.setLog("SaleService:reloadList", error)
      return null;
    }
  }

  getAll() {
    return this.dao.getAll();
  }
  
  getAllByPromise() {
    return this.dao.getAllByProfile(this.selectedProfileId);
  }

  getAllByPromiseByProfile(profileId: string) {
    return this.dao.getAllByProfile(profileId);
  }

  getAllByProfileWithOnlyRunningBill() {
    return this.dao.getAllByProfileWithOnlyRunningBill(this.selectedProfileId);
  }

  getAllByProfileWithRunningBill() {
    return this.dao.getAllByProfileWithRunningBill(this.selectedProfileId);
  }

  getAllWithDeletedByProfile() {
    return this.dao.getAllWithDeletedByProfile(this.selectedProfileId);
  }

  getByBillDateRange(startTime: number, endTime: number, profileId?: string): Promise<Sale[]> {
    return new Promise(async (resolve, reject) => {
      try {
        let allDocs = await this.dao.getAllByProfile(profileId || this.selectedProfileId);
        if (allDocs != null) {
          let filteredDocs = allDocs.filter(doc => doc?.billDateStamp >= startTime && doc?.billDateStamp < endTime);
          return resolve(filteredDocs);
        } else {
          return resolve(null);
        }
      } catch (error) {
        SentryUtilites.setLog("SaleService:getByBillDateRange", error)
        return resolve(null);
      }
    });
  }

  getByCreatedDateRange(startTime: number, endTime: number): Promise<Sale[]> {
    return new Promise(async (resolve, reject) => {
      try {
        let allDocs = await this.dao.getAllByProfile(this.selectedProfileId);
        if (allDocs != null) {
          let filteredDocs = allDocs.filter(doc => doc?.createdStamp >= startTime && doc?.createdStamp < endTime);
          return resolve(filteredDocs);
        } else {
          return resolve(null);
        }
      } catch (error) {
        SentryUtilites.setLog("SaleService:getByCreatedDateRange", error)
        return resolve(null);
      }
    });
  }

  getByBillCompleteDateRange(startTime: number, endTime: number): Promise<Sale[]> {
    return new Promise(async (resolve, reject) => {
      try {
        let allDocs = await this.dao.getAllByProfile(this.selectedProfileId);
        if (allDocs != null) {
          let filteredDocs = allDocs.filter(doc => doc?.billCompleteStamp >= startTime && doc?.billCompleteStamp < endTime);
          return resolve(filteredDocs);
        } else {
          return resolve(null);
        }
      } catch (error) {
        SentryUtilites.setLog("SaleService:getByBillCompleteDateRange", error)
        return resolve(null);
      }
    });
  }

  getById(id: number): Promise<Sale> {
    return this.dao.getById(id);
  }

  getByUUID(uuid: string): Promise<Sale> {
    return this.dao.getByUUID(uuid);
  }

  save(sale: Sale): Promise<Sale> {
    return new Promise(async (resolve, reject) => {
      try {
        if(Utility.isTruthy(sale)) {
          let currentProfile = this.selectedProfileId;
          sale.userId = this.selectedProfileUserId;
          if(!sale.profileId) {
            sale.profileId = currentProfile;
          }
          if(!sale?.createdBy) {
            sale.createdBy = sale.lastModifiedBy = this.authService.getLoginPhone();
          }
          if(!sale?.createdByName) {
            sale.createdByName = sale.lastModifiedByName = Utility.getCreatedByName();
          }
  
          if (!sale._localUUID) {
            sale._localUUID = Utility.getUUID();
          }
  
          if(!sale.amountReceived) {
            sale.amountReceived = 0.0;
          }
  
  
          if (sale?.moneyIns?.length === 1) {
            sale.moneyIns[0].userId = this.selectedProfileUserId;
            sale.moneyIns[0].profileId = this.selectedProfileId;
            sale.moneyIns[0].createdBy = sale.moneyIns[0].lastModifiedBy = sale?.createdBy;
            sale.moneyIns[0].createdByName = sale.moneyIns[0].lastModifiedByName = sale?.createdByName;
            sale.moneyIns[0].linkedSaleUUID = sale?._localUUID;
            sale.moneyIns[0]._localUUID = Utility.getUUID();
            sale.amountReceived = sale?.moneyIns[0]?.totalAmount || 0.0;
          }
  
          let savedSale = await this.dao.save(sale);
  
          if(savedSale?.billCompleteStamp) {
  
            //MoneyIn
            //----------------------------------------
            if (savedSale.moneyIns?.length === 1) {
  
              let savedMoneyIn = await this.moneyInDao.save(savedSale?.moneyIns[0]);
              if(savedMoneyIn?._localUUID) {
                let totalAmount = savedMoneyIn?.totalAmount || 0.0;
                if (totalAmount > 0) {
                  savedSale.party.lastModifiedBy = sale?.lastModifiedBy;
                  savedSale.party.lastModifiedByName = sale?.lastModifiedByName;
                  await this.partyDao.updateCredit(
                    savedMoneyIn?.party,
                    -totalAmount,
                  )
                  await this.monthWisePartyCreditDao?.modifyCredit(
                    savedMoneyIn?.party?._localUUID,
                    savedMoneyIn?.billDateStamp,
                    -totalAmount
                  )
                }
              }
            }
            //----------------------------------------
  
            //Party
            //----------------------------------------
            savedSale.party.lastModifiedBy = sale?.lastModifiedBy;
            savedSale.party.lastModifiedByName = sale?.lastModifiedByName;
            await this.partyDao.updateCredit(
              savedSale?.party,
              savedSale?.totalAmount
            )
            await this.monthWisePartyCreditDao.modifyCredit(
              savedSale?.party?._localUUID,
              savedSale?.billDateStamp,
              savedSale?.totalAmount
            )
  
            if(savedSale?.partySecondary?._localUUID) {
              savedSale.partySecondary.lastModifiedBy = sale?.lastModifiedBy;
              savedSale.partySecondary.lastModifiedByName = sale?.lastModifiedByName;
              await this.partyDao.updateLastSaleStamp(
                savedSale?.partySecondary,
                savedSale?.createdStamp
              );
            }
            
            //Item & Price Map Update
            let allPriceMaps = await this.partyItemPriceMapDao.getAllByProfile(this.selectedProfileId);
            let partyPriceMap = allPriceMaps.filter(x => x?.partyUUID == savedSale?.party?._localUUID);
            let currentProfileData = await this.profileDao.getByUUID(this.selectedProfileId);
            for (let i = 0; i < savedSale.billItems?.length; i++) {
              let billItem = savedSale?.billItems[i];
              if(billItem?.item?._localUUID) {
                billItem.item.lastModifiedBy = sale?.lastModifiedBy;
                billItem.item.lastModifiedByName = sale?.lastModifiedByName;
                let qty = billItem?.unit === billItem?.item?.primaryUnit ? billItem?.quantity : Utils.capFractionsToTwo(billItem?.quantity / billItem?.convertRatioMultiplier);
                await this.itemDao.updateStock(
                  billItem?.item,
                  -qty
                );
                await this.monthWiseItemStockDao?.modifyStock(
                  billItem?.item?._localUUID,
                  savedSale?.billDateStamp,
                  -qty
                )
                if(currentProfileData?.iSetItemPriceHistoryStatus) {
                  let primaryUnitPrice = billItem?.price * billItem?.convertRatioMultiplier;
    
                  let priceMap = partyPriceMap.filter(x => x?.itemUUID == billItem?.item?._localUUID)[0];
                  if(priceMap?._localUUID) {
                    priceMap.sellPrice = primaryUnitPrice;
                    priceMap.lastModifiedBy = this.authService.getLoginPhone();
                    priceMap.lastModifiedByName = Utility.getCreatedByName();
                    await this.partyItemPriceMapDao.update(priceMap);
                  }else {
                    let newPriceMap = new PartyItemPriceMap();
                    newPriceMap.partyUUID = savedSale?.party?._localUUID;
                    newPriceMap.itemUUID = billItem?.item?._localUUID;
                    newPriceMap.sellPrice = primaryUnitPrice;
                    newPriceMap.userId = this.selectedProfileUserId;
                    newPriceMap.profileId = currentProfile;
                    newPriceMap.createdBy = newPriceMap.lastModifiedBy = this.authService.getLoginPhone();
                    newPriceMap.createdByName = newPriceMap.lastModifiedByName = Utility.getCreatedByName();
                    await this.partyItemPriceMapDao.save(newPriceMap);
                  }
                }
              }
  
            }
  
            this.partyItemPriceMapDao.resetCachePartyItemMap();
  
            //----------------------------------------
  
          }
          this.reloadList();
          return resolve(savedSale);
        } else {
          return resolve(null)
        }
      } catch (err) {
        SentryUtilites.setLog("SaleService:save", err)
        return resolve(null)
      }

    });
  }

  update(sale: Sale): Promise<Sale> {
    // sale.lastModifiedBy = this.authService.getLoginPhone();
    // sale.lastModifiedByName = Utility.getCreatedByName();
    return new Promise(async (resolve, reject) => {
      try {
        if (sale?._localUUID) {
          let oldSale = await this.getByUUID(sale._localUUID);

          if (
            oldSale.party?._localUUID != null
            && oldSale.party?._localUUID != ""
            && sale.party?._localUUID != ""
            && oldSale.party?._localUUID == sale.party?._localUUID
          ) {

            if(sale?.moneyIns?.length) {
              let totalAmountReceived = 0.0;
              sale?.moneyIns?.forEach(x => totalAmountReceived += Number(x?.totalAmount));
              sale.amountReceived = totalAmountReceived;
            }else {
              sale.amountReceived = 0.0;
            }

            let addMoneyIns: MoneyIn[] = [];
            let updateMoneyIns: MoneyIn[] = [];
            let deleteMoneyIns: MoneyIn[] = [];

            sale?.moneyIns?.forEach(newMoneyIn => {
              let isMatched = false;
              oldSale?.moneyIns?.forEach(oldMoneyIn => {
                if(oldMoneyIn?._localUUID === newMoneyIn?._localUUID) {
                  isMatched = true;
                  return;
                }
              });
              if(isMatched) {
                // ConstraintError: Unable to add key to index '_localUUID': at least one key does not satisfy the uniqueness requirements.
                // getting this error if _localId is not match with your current indexedDb _localId at time of update and delete.
                delete newMoneyIn?._localId;
                updateMoneyIns.push(newMoneyIn);
              }else {
                newMoneyIn._localUUID = Utility.getUUID();
                newMoneyIn.profileId = sale?.profileId;
                newMoneyIn.userId = sale?.userId;
                newMoneyIn.createdBy = newMoneyIn.lastModifiedBy = sale?.lastModifiedBy;
                newMoneyIn.createdByName = newMoneyIn.lastModifiedByName = sale?.lastModifiedByName;
                newMoneyIn.linkedSaleUUID = sale?._localUUID;
                newMoneyIn.party = sale?.party;
                addMoneyIns.push(newMoneyIn);
              }
            });

            oldSale?.moneyIns?.forEach(oldMoneyIn => {
              let shouldDelete = true;
              sale?.moneyIns?.forEach(newMoneyIn => {
                if(oldMoneyIn?._localUUID === newMoneyIn?._localUUID) {
                  shouldDelete = false;
                }
              });
              if(shouldDelete) {
                deleteMoneyIns.push(oldMoneyIn);
              }
            });

            sale.moneyIns = [...addMoneyIns, ...updateMoneyIns];

            let updatedSale = await this.dao.update(sale);

            if(updatedSale?.billCompleteStamp) {

              //MoneyIn

              if(addMoneyIns?.length) {
                for (let i = 0; i < addMoneyIns?.length; i++) {
                  let moneyIn = addMoneyIns[i];
                  let savedMoneyIn = await this.moneyInDao.save(moneyIn);
                  if(savedMoneyIn?._localUUID) {
                    savedMoneyIn.party.lastModifiedBy = sale.lastModifiedBy;
                    savedMoneyIn.party.lastModifiedByName = sale.lastModifiedByName;
                    await this.partyDao.updateCredit(
                      savedMoneyIn?.party,
                      -savedMoneyIn?.totalAmount
                    );
                    await this.monthWisePartyCreditDao?.modifyCredit(
                      savedMoneyIn?.party?._localUUID,
                      savedMoneyIn?.billDateStamp,
                      -savedMoneyIn?.totalAmount
                    )
                  }
                }
              }

              if(updateMoneyIns?.length) {
                for (let i = 0; i < updateMoneyIns?.length; i++) {
                  let oldMoneyIn = await this.moneyInDao.getByUUID(updateMoneyIns[i]?._localUUID);
                  if(oldMoneyIn?._localUUID) {
                    let newMoneyIn = {...updateMoneyIns[i]};
                    delete newMoneyIn?.party;
                    oldMoneyIn = {...oldMoneyIn,...newMoneyIn};
                    let savedMoneyIn = await this.moneyInDao.update(oldMoneyIn);
                    if(savedMoneyIn?._localUUID) {
                      let deltaAmount = savedMoneyIn?.totalAmount - oldMoneyIn?.totalAmount;
                      savedMoneyIn.party.lastModifiedBy = sale?.lastModifiedBy;
                      savedMoneyIn.party.lastModifiedByName = sale?.lastModifiedByName;
                      await this.partyDao.updateCredit(
                        savedMoneyIn?.party,
                        -deltaAmount
                      );
                      await this.monthWisePartyCreditDao?.modifyCredit(
                        savedMoneyIn?.party?._localUUID,
                        savedMoneyIn?.billDateStamp,
                        -deltaAmount
                      )
                    }
                  }
                }
              }

              if(deleteMoneyIns?.length) {
                for (let i = 0; i < deleteMoneyIns?.length; i++) {
                  let oldMoneyIn = await this.moneyInDao.getByUUID(deleteMoneyIns[i]?._localUUID);
                  if(oldMoneyIn?._localUUID) {
                    let newMoneyIn = {...deleteMoneyIns[i]};
                    delete newMoneyIn.party;
                    oldMoneyIn = {...oldMoneyIn,...newMoneyIn};
                    let savedMoneyIn = await this.moneyInDao.delete(oldMoneyIn);
                    if(savedMoneyIn?._localUUID) {
                      savedMoneyIn.party.lastModifiedBy = sale?.lastModifiedBy;
                      savedMoneyIn.party.lastModifiedByName = sale?.lastModifiedByName;
                      await this.partyDao.updateCredit(
                        savedMoneyIn?.party,
                        savedMoneyIn?.totalAmount
                      );
                      await this.monthWisePartyCreditDao?.modifyCredit(
                        savedMoneyIn?.party?._localUUID,
                        savedMoneyIn?.billDateStamp,
                        savedMoneyIn?.totalAmount
                      )
                    }
                  }
                }
              }

              //------------------------------------------------------------------


              //Party
              //------------------------------------------------------------------
              let deltaPartyCredit:number = 0;
              if(!oldSale?.billCompleteStamp && updatedSale?.billCompleteStamp) {
                deltaPartyCredit = updatedSale?.totalAmount || 0.0;
              }else {
                deltaPartyCredit = (updatedSale?.totalAmount || 0.0) - (oldSale?.totalAmount || 0.0)
              }

              updatedSale.party.lastModifiedBy = sale?.lastModifiedBy;
              updatedSale.party.lastModifiedByName = sale?.lastModifiedByName;

              await this.partyDao.updateCredit(
                updatedSale?.party,
                deltaPartyCredit
              )
              await this.monthWisePartyCreditDao?.modifyCredit(
                updatedSale?.party?._localUUID,
                updatedSale?.billDateStamp,
                deltaPartyCredit
              )

              //Item
              //----------------------------------------
              if(oldSale?.billCompleteStamp) {
                for (let i = 0; i < oldSale?.billItems?.length; i++) {
                  let billItem = oldSale?.billItems[i];

                  if(billItem?.item?._localUUID) {
                    billItem.item.lastModifiedBy = sale?.lastModifiedBy;
                    billItem.item.lastModifiedByName = sale?.lastModifiedByName;
                    let qty = billItem?.unit === billItem?.item?.primaryUnit ? billItem?.quantity : Utils.capFractionsToTwo(billItem?.quantity / billItem?.convertRatioMultiplier);
                    await this.itemDao.updateStock(
                      billItem?.item,
                      qty
                    );
                    await this.monthWiseItemStockDao?.modifyStock(
                      billItem?.item?._localUUID,
                      oldSale?.billDateStamp,
                      qty
                    )
                  }
                }
              }

              let allPriceMaps = await this.partyItemPriceMapDao.getAllByProfile(this.selectedProfileId);
              let partyPriceMap = allPriceMaps.filter(x => x.partyUUID == updatedSale?.party?._localUUID);
              let currentProfileData = await this.profileDao.getByUUID(this.selectedProfileId);

              for (let i = 0; i < updatedSale?.billItems?.length; i++) {
                let billItem = updatedSale?.billItems[i];

                if(billItem?.item?._localUUID) {
                  billItem.item.lastModifiedBy = sale?.lastModifiedBy;
                  billItem.item.lastModifiedByName = sale?.lastModifiedByName;
                  let qty = billItem?.unit === billItem?.item?.primaryUnit ? billItem?.quantity : Utils.capFractionsToTwo(billItem?.quantity / billItem?.convertRatioMultiplier);
                  await this.itemDao.updateStock(
                    billItem?.item,
                    -qty
                  );
                  await this.monthWiseItemStockDao?.modifyStock(
                    billItem?.item?._localUUID,
                    updatedSale?.billDateStamp,
                    -qty
                  )
  
                  if(currentProfileData?.iSetItemPriceHistoryStatus) {
                    let primaryUnitPrice = billItem?.price * billItem?.convertRatioMultiplier;
  
                    let priceMap = partyPriceMap.filter(x => x?.itemUUID == billItem?.item?._localUUID)[0];
                    if(priceMap?._localUUID) {
                      priceMap.sellPrice = primaryUnitPrice;
                      priceMap.lastModifiedBy = this.authService.getLoginPhone();
                      priceMap.lastModifiedByName = Utility.getCreatedByName();
                      await this.partyItemPriceMapDao.update(priceMap);
                    }else {
                      let newPriceMap = new PartyItemPriceMap();
                      newPriceMap.partyUUID = updatedSale?.party?._localUUID;
                      newPriceMap.itemUUID = billItem?.item?._localUUID;
                      newPriceMap.sellPrice = primaryUnitPrice;
                      newPriceMap.userId = this.selectedProfileUserId;
                      newPriceMap.profileId = this.selectedProfileId;
                      newPriceMap.createdBy = newPriceMap.lastModifiedBy = this.authService.getLoginPhone();
                      newPriceMap.createdByName = newPriceMap.lastModifiedByName = Utility.getCreatedByName();
                      await this.partyItemPriceMapDao.save(newPriceMap);
                    }
                  }
                }

              }

              this.partyItemPriceMapDao.resetCachePartyItemMap();

            }

            this.reloadList();
            this.updateSubs.next(updatedSale);
            return resolve(updatedSale);
          }
        }
        return resolve(null)
      } catch (err) {
        SentryUtilites.setLog("SaleService:update", err)
        return resolve(null)
      }
    });
  }

  delete(sale: Sale): Promise<Sale> {
    return new Promise(async (resolve, reject) => {
      try {
        if(sale?._localUUID) {
          sale.lastModifiedBy = this.authService.getLoginPhone();
          sale.lastModifiedByName = Utility.getCreatedByName();
          let saleTobeDeleted = await this.getByUUID(sale._localUUID)
          let deletedSale = await this.dao.delete(saleTobeDeleted);
  
          if(deletedSale?.billCompleteStamp) {
  
            //MoneyIn
  
            if (saleTobeDeleted?.moneyIns?.length) {
              for (let i = 0; i < saleTobeDeleted?.moneyIns?.length; i++) {
                const moneyIn = saleTobeDeleted?.moneyIns[i];
                let fetchedMoneyIn = await this.moneyInDao.getByUUID(moneyIn?._localUUID);
                if(fetchedMoneyIn?._localUUID) {
                  fetchedMoneyIn.lastModifiedBy = this.authService.getLoginPhone();
                  fetchedMoneyIn.lastModifiedByName = Utility.getCreatedByName();
                  let deletedMoneyIn = await this.moneyInDao?.delete(fetchedMoneyIn);
                  if(deletedMoneyIn?._localUUID) {
                    let totalAmount = deletedMoneyIn?.totalAmount || 0.0;
                    if (totalAmount > 0) {
                      saleTobeDeleted.party.lastModifiedBy = deletedSale?.lastModifiedBy;
                      saleTobeDeleted.party.lastModifiedByName = deletedSale?.lastModifiedByName;
                      await this.partyDao.updateCredit(
                        saleTobeDeleted?.party,
                        totalAmount
                      )
                      await this.monthWisePartyCreditDao?.modifyCredit(
                        saleTobeDeleted?.party?._localUUID,
                        saleTobeDeleted?.billDateStamp,
                        totalAmount
                      )
                    }
                  }
                }
              }
            }
  
            //----------------------------------------
  
            //Party
            //----------------------------------------
            deletedSale.party.lastModifiedBy = deletedSale?.lastModifiedBy;
            deletedSale.party.lastModifiedByName = deletedSale?.lastModifiedByName;
            await this.partyDao.updateCredit(
              deletedSale?.party,
              -(deletedSale?.totalAmount || 0.0)
            )
            await this.monthWisePartyCreditDao?.modifyCredit(
              deletedSale?.party?._localUUID,
              deletedSale?.billDateStamp,
              -(deletedSale?.totalAmount || 0.0)
            )
  
            //Item
            //----------------------------------------
            for (let i = 0; i < deletedSale?.billItems?.length; i++) {
              let billItem = deletedSale?.billItems[i];
              if(billItem?.item?._localUUID) {
                billItem.item.lastModifiedBy = deletedSale?.lastModifiedBy;
                billItem.item.lastModifiedByName = deletedSale?.lastModifiedByName;
                let qty = billItem?.unit === billItem?.item?.primaryUnit ? billItem?.quantity : Utils.capFractionsToTwo(billItem?.quantity / billItem?.convertRatioMultiplier);
                await this.itemDao.updateStock(
                  billItem?.item,
                  qty
                )
                await this.monthWiseItemStockDao?.modifyStock(
                  billItem?.item?._localUUID,
                  deletedSale?.billDateStamp,
                  qty
                )
              }
            }
  
          }
  
          this.reloadList();
          this.updateSubs.next(deletedSale);
  
          return resolve(deletedSale);
        }
        return resolve(null);

      } catch (err) {
        SentryUtilites.setLog("SaleService:delete", err)
        return resolve(null);
      }

    });
  }

  isSyncLock = false;
  isSyncPostPond = false;
  async trySyncUnsynced(postpond?: boolean) {
    try {
      // added delay to fixed sync issue if customer change party while edit 
      await Utility.wait(2000);
      if (this.isSyncLock) {
        if (!this.isSyncPostPond) {
          setTimeout(() => {
            this.isSyncPostPond = true;
            this.trySyncUnsynced(true);
          }, 200);
        }
        return true;
      }
      if (postpond) {
        this.isSyncPostPond = false;
      }
      this.isSyncLock = true;
      let unSyncedElements: Sale[] = await this.dao.getAllUnsynced(this.selectedProfileId);
      if (unSyncedElements && unSyncedElements?.length) {
        try {
  
          for (let i = 0; i < unSyncedElements?.length; i++) {
            let unSyncedElement = unSyncedElements[i];
            if(unSyncedElement?._localUUID) {
              unSyncedElement['updatedStamp'] = +new Date();
            }
          }
  
          await this.dao.bulkPut(unSyncedElements);
          await Utility.wait(1000);
  
          for (let i = 0; i < unSyncedElements?.length; i++) {
            let unSyncedElement = unSyncedElements[i];
            if(unSyncedElement?._localUUID) {
              unSyncedElement['profile'] = await this.profileDao.getByUUID(unSyncedElement?.profileId);
            }
          }
  
          let chunkArr = Utility.getChunkArr(unSyncedElements);
          let chunkArrLength = chunkArr?.length;
          for(let i = 0; i < chunkArrLength; i++) {
            let result = await this.expressServerService.makeSyncCall('sale', chunkArr[i]);
            if (result && result?.['records']?.length) {
              let arr = result?.['records'];
              for (let i = 0; i < arr?.length; i++) {
                const el = arr[i];
                await this.updateSyncStamp(el);
              }
            }
          }
        } catch (err) {
          SentryUtilites.setLog("SaleService:trySyncUnsynced:inner", err)
        }
      }
      this.isSyncLock = false;
    } catch (error) {
      SentryUtilites.setLog("SaleService:trySyncUnsynced", error)
    }
  }

  updateSyncStamp(el: Sale): Promise<Sale> {
    return new Promise(async (resolve, reject) => {
      try {
        let updatedEl = await this.dao.updateSyncStamp(el);
        this.updateSubs.next(updatedEl);
        return resolve(updatedEl);
      } catch (error) {
        SentryUtilites.setLog("SaleService:updateSyncStamp", error)
        return resolve(null);
      }
    });
  }

  async getNewSaleNo(): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        let sales = await this.dao.getAllByProfileWithRunningBill(this.selectedProfileId);
        let nextSaleNo = 'INV_001';
        if (sales[0]?.billNo) {
          nextSaleNo = Utility.nextNo(sales[0]?.billNo);
        }
        return resolve(nextSaleNo);
      } catch (error) {
        SentryUtilites.setLog("SaleService:getNewSaleNo", error)
        return resolve(null);
      }

    });
  }

  /**
   * 
   * @returns : return deleted sales from sale dao
   */
  async getAllDeleted(): Promise<Sale[]> {
    try {
      let res = await this.dao.getAllDeletedByProfile(this.selectedProfileId);
      return res || [];
    } catch (error) {
      SentryUtilites.setLog("SaleService:getAllDeleted", error)
      return [];
    }
  }
  // -------------------------------------------

  copyData(fromProfileId: string, toProfileId: string): Promise<boolean> {
    return new Promise(async (resolve,reject) => {
      try {
        if(Utility.isTruthy(fromProfileId) && Utility.isTruthy(toProfileId)) {
          let fromRecords = await this.getAllByPromiseByProfile(fromProfileId);
          if(fromRecords?.length) {
            let toRecords: Sale[] = [];
            for (let i = 0; i < fromRecords?.length; i++) {
              const fetchedRecord = fromRecords[i];
              if(fetchedRecord?._localUUID) {
                fetchedRecord.profileId = toProfileId;
                delete fetchedRecord?._localId;
                delete fetchedRecord?._localUUID;
                let savedRecord = await this.save(fetchedRecord);
                if(savedRecord?._localUUID) {
                  toRecords?.push(savedRecord);
                }
              }
            }
            if(fromRecords?.length === toRecords?.length) {
              return resolve(true);
            }
          }
        }
        return resolve(false);
      } catch (error) {
        SentryUtilites.setLog("SaleService:copyData", error)
        return resolve(false);
      }
    });
  }

  /**
   * @description : sale credit remaining for non premium account
   * @returns : return sale credit remaining for non premium account
   */
    async nonPremiumSaleCeditRemaining() {
      try {
        let saleDataWithDeleted: Sale[] = await this.dao.getAllWithDeletedByProfile(this.selectedProfileId);
        let todayStartStamp: number = +new Date().setHours(0,0,0,0);
        let todayEndStamp: number = todayStartStamp + 86400000;
    
        let todaySale: Sale[] = saleDataWithDeleted?.filter(sale => sale?.createdStamp >= todayStartStamp && sale?.createdStamp < todayEndStamp);
    
        return todaySale?.length < 3 ? 3 - todaySale?.length : 0;
      } catch (error) {
        SentryUtilites.setLog("SaleService:nonPremiumSaleCeditRemaining", error)
        return 0;
      }
    }
    // --------------------------------------------------------------------

}
