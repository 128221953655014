<ion-grid>
  <ion-row>
    <ion-col class="col-categories" *ngIf="showBookmarkList && !barcodeScannerMode" size="3">
      <ion-item lines="none" [class.active-item]="!appliedCategory" (click)="onCategoryChange('')">
        All
      </ion-item>
      <ion-item lines="none" [class.active-item]="appliedCategory == 'FilterByFav' " (click)="onCategoryChange('FilterByFav')">
        Best Seller Items ({{favItemCount}})
      </ion-item>
      <ion-item lines="none" *ngFor="let category of categories" [class.active-item]="category === appliedCategory"
        [class.cart-item-category]="cartItemsCategories?.includes(category)" (click)="onCategoryChange(category)">
        {{ category }} ({{ categoryWiseItemCount[category] || 0 }})
      </ion-item>
    </ion-col>

    <ion-col class="col-item-list" [ngClass]="{'dynamic-margin-left':showBookmarkList && !barcodeScannerMode}"
      size="{{ showBookmarkList && !barcodeScannerMode ? 9 : 12 }}">

      <ion-row>

        <ion-col class="barcode-scanner-note" *ngIf="barcodeScannerMode && !cartItems?.length">
          <ion-label>In barcode scanner mode use barcode scanner to add items in cart list</ion-label>
        </ion-col>

        <ion-col class="col-card" size="{{getCardColSize()}}"
          *ngFor="let billItem of barcodeScannerMode ? maintainSequenceForBarcode(cartItems) : viewFilteredList">

          <ion-item lines="none"
            [style.--ion-background-color]="billItem?.quantity ? cssColors?.selectedItemColor : '#FFFFFF'">

            <ion-row class="padding-none">
              
              <!-- cancel button --> 
              <span class="close-icon" *ngIf="billItem?.quantity">
                <ion-icon name="close-circle" [color]="'danger'" (click)="clearSelectedItem(billItem?.item?._localUUID)"></ion-icon>
              </span>
              <!-- ---------------------- --> 

              <span class="current-stock" *ngIf="iSetShowStockStatus" [ngStyle]="!billItem?.quantity ? {'right': '15px'} : {}">
                Stock: {{ capFractionsToTwo(billItem?.item?.stock) }}
              </span>

              <ion-col size="12" (click)="increaseQty(billItem)">
                <!-- <img class="img-logo" *ngIf="billItem?.item?.images?.length"
                  [src]="imagesBase64[billItem?.item?.images[0]]" /> -->

                  <img class="img-logo" 
                  [src]="getItemImageSrc(billItem?.item?._localUUID)" />
                
              </ion-col>

              <ion-col class="padding-none" size="12" (click)="increaseQty(billItem)">
                <ion-button fill="clear" class="btn-item-name btn-txt-black">
                  {{ selectedFilter === 'SortByItemCodeAsc' && billItem.item?.itemCode ? billItem.item?.itemCode+' | ' : ''}}
                  {{ shortStr(billItem.item?.itemName,85) }}
                </ion-button>
              </ion-col>
              <ion-col *ngIf="!(negativeStockLock && billItem?.item?.stock<=0 && !isPurchase && !isPurchaseReturn && !isEstimate)" class="padding-none" size="6">
                <ion-button fill="outline" shape="round" color="primary" class="outline-border font-12px"
                  (click)="openQuantityForm.emit(billItem)">
                  {{ billItem.quantity }}
                </ion-button>
              </ion-col>
              <ion-col *ngIf="!(negativeStockLock && billItem?.item?.stock<=0 && !isPurchase && !isPurchaseReturn && !isEstimate)" class="padding-none" size="6">
                <ion-button fill="outline" class="dashed-border flt-rght" (click)="openBillItemForm.emit(billItem)">
                  {{ billItem.price | currency: 'INR' }}
                </ion-button>
              </ion-col>
              <ion-col class="col-negative-stock-item"
                *ngIf="!isPurchase && !isPurchaseReturn && !isEstimate && negativeStockLock && billItem?.item?.stock<=0" size="12">
                Negative Stock Item
              </ion-col>
              <ion-col class="col-negative-stock-item" *ngIf="!isPurchase && !isPurchaseReturn && !isEstimate && billItem?.['priceMap']" size="12">
                Previous Sell Price {{ billItem?.['priceMap'] }}
              </ion-col>
            </ion-row>

          </ion-item>

        </ion-col>
      </ion-row>

    </ion-col>
  </ion-row>
</ion-grid>

<ion-infinite-scroll threshold="10px" *ngIf="viewFilteredList?.length" (ionInfinite)="loadMoreListData($event)">
  <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Loading more data...">
  </ion-infinite-scroll-content>
</ion-infinite-scroll>