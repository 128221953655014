<ion-row class="padding-none cursor-pointer custom-row">
  <ion-col size-md="6" size-xs="5.5" class="txt-left remove-padding-top-bottom">
    <ion-row>
      <ion-col size="12" class="add-bottom-padding">
        <ion-button fill="clear" class="btn-txt-black padding-start-none">
          {{ saleReturn?.party?.name }}
        </ion-button>
      </ion-col>
      <ion-col size="12" (click)="billView(saleReturn)">
        <ion-label class="label-note">
          {{ (saleReturn?.totalAmount || 0) | currency:'INR' }}
        </ion-label>
      </ion-col>
      <ion-col size="12" *ngIf="!saleReturn?.deletedStamp">
        <!-- <ion-button fill="outline" class="outline-border font-12px ion-button-bottom-padding ion-button-height"
          color="success"
          *ngIf="getBalanceAmount(saleReturn?.moneyOuts,saleReturn?.totalAmount)"
          (click)="addPayment(saleReturn)">
          + Add Payment
        </ion-button> -->
        <ion-button fill="outline"
          class="outline-border font-12px ion-button-bottom-padding ion-button-height" color="danger"
          *ngIf="getBalanceAmount(saleReturn?.moneyOuts,saleReturn?.totalAmount)">
          {{ getBalanceAmount(saleReturn?.moneyOuts,saleReturn?.totalAmount) | currency:'INR' }}
          Balance
        </ion-button>
        <ion-button fill="outline" class="outline-border font-12px ion-button-bottom-padding ion-button-height"
          color="success" *ngIf="getBalanceAmount(saleReturn?.moneyOuts,saleReturn?.totalAmount)===0">
          Paid
        </ion-button>
        <ion-text color="danger" class="text-extra-money-in font-12px"
          *ngIf="extraMoneyOutAmount(saleReturn?.moneyOuts,saleReturn?.totalAmount)">
          Extra Money Out Amount is <b>{{ extraMoneyOutAmount(saleReturn?.moneyOuts,saleReturn?.totalAmount) | currency: 'INR' }}</b>
        </ion-text>
        <ion-button *ngIf="saleReturn?.linkedSaleUUID" (click)="viewSale(saleReturn)" fill="outline" color="primary"
          class="font-12px ion-button-height add-margin-left ion-button-bottom-padding outline-border">
          View Sale
        </ion-button>
      </ion-col>
      <ion-col size="10"
        *ngIf="!saleReturn?.deletedStamp && saleReturn?.moneyOuts?.length && isRecentTransactionList">
        <ion-button [fill]="saleReturn?.moneyOuts[0]?.paymentMode=='bank' ? 'solid' : 'outline'"
          class="outline-border font-12px ion-button-bottom-padding ion-button-height" color="primary"
          (click)="setPaymentMode('bank')">
          UPI/BANK
        </ion-button>
        <ion-button [fill]="saleReturn?.moneyOuts[0]?.paymentMode=='cash' ? 'solid' : 'outline'"
          class="outline-border font-12px add-margin-left ion-button-bottom-padding ion-button-height" color="primary"
          (click)="setPaymentMode('cash')">
          CASH
        </ion-button>
        <ion-button [fill]="saleReturn?.moneyOuts[0]?.paymentMode=='cheque' ? 'solid' : 'outline'"
          class="outline-border font-12px add-margin-left ion-button-bottom-padding ion-button-height" color="primary"
          (click)="setPaymentMode('cheque')">
          CHEQUE
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-col>
  <ion-col size-md="6" size-xs="6.5" class="txt-right remove-padding-top-bottom">
    <ion-row>
      <ion-col size="12" (click)="billView(saleReturn)" class="remove-bottom-padding">
        <ion-button *ngIf="saleReturn?.previousBillNo" fill="clear" class="btn-txt-grey padding-end-none">
          Old Bill No: {{ saleReturn?.previousBillNo }}
        </ion-button>
        <ion-button fill="clear" class="btn-txt-black padding-end-none">
          {{ saleReturn?.billNo }} | {{ saleReturn?.billDateStamp | date: 'dd/MM/yy' }}
        </ion-button>
      </ion-col>
      <ion-col size="12" *ngIf="!saleReturn?.deletedStamp">
        <ion-button title="Print" color="tertiary" fill="clear" (click)="billView(saleReturn)">
          <ion-icon name="print"></ion-icon>
        </ion-button>
        <!-- <ion-button title="Edit" color="primary" fill="clear" (click)="edit(saleReturn)">
          <ion-icon name="create"></ion-icon>
        </ion-button> -->
        <ion-button title="Delete" color="danger" fill="clear" (click)="delete(saleReturn)">
          <ion-icon name="trash"></ion-icon>
        </ion-button>
      </ion-col>
      <ion-col size-md="11"  size-xs="10" (click)="billView(saleReturn)">
        <ion-label class="label-note">
          {{ (saleReturn?.userUpdatedStamp || saleReturn?.updatedStamp) > saleReturn?.createdStamp ? 'Edited by ' : 'Created by ' }} 
          {{ (saleReturn?.userUpdatedStamp || saleReturn?.updatedStamp) > saleReturn?.createdStamp ? saleReturn?.lastModifiedByName || saleReturn?.lastModifiedBy :  saleReturn?.createdByName || saleReturn?.createdBy }}
          on
          {{ (saleReturn?.userUpdatedStamp || saleReturn?.updatedStamp) | date: 'dd/MM/yy h:mm:ss a' }}
        </ion-label>
      </ion-col>
      <ion-col size-md="1" size-xs="2" (click)="billView(saleReturn)">
        <ion-col size="auto" offset="1">
          <div *ngIf="saleReturn?.syncStamp > saleReturn?.updatedStamp; then thenBlock else elseBlock"></div>
          <ng-template #thenBlock>
            <ion-button class="btn-popover" title="Data Uploaded" fill="clear">
              <ion-icon 
                name="checkmark-done-sharp" 
                color="success" 
                size="small">
              </ion-icon>
            </ion-button>
          </ng-template>
          <ng-template #elseBlock>
            <ion-button class="btn-popover" title="Warning" fill="clear">
              <ion-icon 
                name="warning-sharp" 
                color="warning" 
                size="small">
              </ion-icon>
            </ion-button>
          </ng-template>
        </ion-col>
      </ion-col>
    </ion-row>
  </ion-col>
</ion-row>

<app-pin-verification (verifyTransactionPin)="verifyTransactionPIN($event)" #pinVerificationElement></app-pin-verification>