<!-- Profile Page -->
<ion-header>
  <ion-toolbar class="{{ getHeaderColorClass() }}">
    <app-navigation-control slot="start" type="profile"></app-navigation-control>
    <ion-title>Profile List</ion-title>
    <ion-text class="last-sync-time" color="medium">Last Sync at {{ lastSyncTime }}</ion-text>
    <ion-text *ngIf="isTimeDifference" class="last-sync-time timezone-warning alert-border padding-alert-boder" color="warning"> <i>Time Zone
        Mismatched</i> </ion-text>
    <ion-chip class="chip-create-new" color="tertiary" slot="end" (click)="openRestoreProfile()">
      Restore Profiles
    </ion-chip>
    <ion-chip class="chip-create-new" color="tertiary" slot="end" id="open-copy-modal" (click)="openCopyProfile(true)">
      Copy Profile
    </ion-chip>
    <!-- <ion-chip class="chip-create-new" color="tertiary" slot="end" (click)="presentAddProfileAlert()"> + New Profile
    </ion-chip> -->
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col size="12">
        <ion-item lines="none" class="custom-row" *ngFor="let profile of viewFilteredList"
          (click)="emitElement(profile)" [ngClass]="{'activated-row': profile._serverIdRef==selectdProfileId}">
          <ion-grid>
            <ion-row>
              <ion-col>
                <span> {{ profile?.profileName || '' }} | {{ profile?.legalName || '' }} | ( ID: {{
                  profile?._localUUID?.substr(-4) }} )</span>
                <br>
                <span class="grey-color"> Owner Phone: {{ profile?.contactPersonPhone }} </span>
                <span class="span-access-from" *ngIf="profile?.userId && ownerUserId!=profile?.userId"> Access From {{
                  profile?.userId }} </span>
                <div *ngIf="profile?.syncStamp > profile?.updatedStamp; then thenBlock else elseBlock"></div>
                <ng-template #thenBlock>
                  <ion-icon class="icon-sync" name="checkmark-done-sharp" color="success"></ion-icon>
                </ng-template>
                <ng-template #elseBlock>
                  <ion-icon class="icon-sync" name="warning-sharp" color="warning"></ion-icon>
                </ng-template>
                <ion-chip *ngIf="profile._localUUID!=selectdProfileId && ownerUserId==profile?.userId" color="danger"
                  (click)="presentDeleteConfirm(profile)">
                  <ion-label>Delete</ion-label>
                </ion-chip>
                <ion-chip *ngIf="profile._localUUID==selectdProfileId" color="success">
                  <ion-label>Activated</ion-label>
                </ion-chip>
                <ion-chip *ngIf="profile._localUUID!=selectdProfileId" color="primary"
                  (click)="activateProfile(profile)">
                  <ion-label>Activate</ion-label>
                </ion-chip>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-item>
        <span (click)="presentAddProfileAlert()">.</span>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-infinite-scroll threshold="10px" *ngIf="viewFilteredList?.length" (ionInfinite)="loadMoreListData($event)">
    <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Loading more data...">
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>

</ion-content>
<!-- ---------------------------------------------------------------------------------- -->

<!-- Copy Profile Modal -->
<ion-modal class="modal-copy-profile" trigger="open-copy-modal" [isOpen]="openCopyProfileModal"
  (willDismiss)="dismissCopyModal($event)" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar color="light">
        <ion-title>Copy Profile</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="openCopyProfile(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-grid class="form-custom-css">
        <ion-row>
          <ion-col size="12">
            <ion-button color="tertiary" fill="clear" class="padding-end-none">
              From Profile<span class="span-mandatory">*</span>
            </ion-button>
            <ion-select interface="alert" placeholder="Select From Profile" class="new-ion-select ion-select"
              #fromProfileEle (ionChange)="selectFromProfile($event)">
              <ion-select-option *ngFor="let copyFromProfile of getSelfProfile(completeList)"
                [value]="copyFromProfile?._localUUID" [disabled]="toProfileEle?.value === copyFromProfile?._localUUID">
                {{ copyFromProfile?.profileName || '' }} | {{ copyFromProfile?.legalName || '' }} | ( ID: {{
                copyFromProfile?._localUUID?.substr(-4) }} )
              </ion-select-option>
            </ion-select>
          </ion-col>
          <ion-col size="12">
            <ion-button color="tertiary" fill="clear" class="padding-end-none">
              To Profile<span class="span-mandatory">*</span>
            </ion-button>
            <ion-select interface="alert" placeholder="Select To Profile" [value]="toProfileEle?.value"
              class="new-ion-select ion-select" #toProfileEle (ionChange)="selectToProfile($event)">
              <ion-select-option *ngFor="let copyToProfile of getSelfProfile(completeList)"
                [value]="copyToProfile?._localUUID" [disabled]="fromProfileEle?.value === copyToProfile?._localUUID">
                {{ copyToProfile?.profileName || '' }} | {{ copyToProfile?.legalName || '' }} | ( ID: {{
                copyToProfile?._localUUID?.substr(-4) }} )
              </ion-select-option>
            </ion-select>
          </ion-col>
          <!-- <ion-col size="12">
            <ion-row class="exclude-form-custom-css">
              <ion-col size="5.8">
                <hr>
              </ion-col>
              <ion-col>
                or
              </ion-col>
              <ion-col size="5.8">
                <hr>
              </ion-col>
            </ion-row>
          </ion-col>
          <ion-col size="12">
            <ion-button color="tertiary" fill="clear" class="padding-end-none">
              Create To Profile
            </ion-button>
            <ion-input #createNewProfileEle class="ion-input"></ion-input>
            <ion-button class="inp-insider-btn" [disabled]="!createNewProfileEle?.value" size="small" color="primary"
              (click)="createProfile(createNewProfileEle?.value)">Create Profile</ion-button>
          </ion-col> -->
          <ion-col size="12" *ngIf="collections.length">
            <ion-row class="collections-row">
              <ion-col size="9.5">
                <ion-button color="tertiary" fill="clear" class="padding-end-none">
                  Collections<span class="span-mandatory">*</span>
                </ion-button>
              </ion-col>
              <ion-col size="2.5">
                <ion-item (click)="selectedAllCollection(selectAll)">
                  <ion-checkbox slot="start" [(ngModel)]="selectAll"></ion-checkbox>
                  <ion-label>Select all</ion-label>
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row class="collections-row">
              <ion-col size="6" *ngFor="let collection of collections">
                <ion-item [disabled]="collection.title === 'Profile'"
                  (click)="selectedCollection(collection.checked, collection.title)">
                  <ion-checkbox slot="start" [(ngModel)]="collection.checked"></ion-checkbox>
                  <ion-label>{{collection.title}}</ion-label>
                </ion-item>
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
    <ion-footer>
      <ion-button expand="full" (click)="copyProfile()">
        Copy Profile
      </ion-button>
    </ion-footer>
  </ng-template>
</ion-modal>
<!-- ----------------------------------------------------------------------------------------- -->

<!-- Restore Profile Modal Start -->
<ion-modal 
  class="restore-profile-modal" 
  [isOpen]="isOpenRestoreProfileModal" 
  [backdropDismiss]="false" 
  (willDismiss)="openRestoreProfile(false)">
  <ng-template>
    <ion-header>
      <ion-toolbar color="light">
        <ion-title>Deleted Profiles List</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="openRestoreProfile(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-grid>
        <ion-row>
          <ion-col size="12">
            <ion-item class="header-row" (click)="selectAllDeletedProfile(selectAllDeletedProfileEle?.checked)">
              <span>
                <ion-checkbox 
                  slot="start" 
                  #selectAllDeletedProfileEle 
                  [checked]="selecteDeletedProfileList?.length === deletedProfileList?.length && 
                            selecteDeletedProfileList?.length && 
                            deletedProfileList?.length ">
                </ion-checkbox>
              </span>
              <ion-label>
                <ion-row>
                  <ion-col size="6">
                    Profile Name
                  </ion-col>
                  <ion-col size="6">
                    Profile ID
                  </ion-col>
                </ion-row>
              </ion-label>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row *ngFor="let profile of deletedProfileList;">
          <ion-col size="12">
            <ion-item (click)="selectedSingleDeletedProfile(selectedDeletedProfileEle?.checked, profile?._localUUID)">
              <ion-checkbox 
                slot="start" 
                #selectedDeletedProfileEle 
                [checked]="profile?.['checked']">
              </ion-checkbox>
              <ion-label>
                <ion-row>
                  <ion-col size="6">
                    {{profile?.profileName || profile?.legalName}}
                  </ion-col>
                  <ion-col size="6">
                    {{profile?._localUUID?.substr(-4)}}
                  </ion-col>
                </ion-row>
              </ion-label>
            </ion-item>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
    <ion-footer>
      <ion-button expand="full" (click)="restoreProfile()">
        Restore Profile
      </ion-button>
    </ion-footer>
  </ng-template>
</ion-modal>
<!-- Restore Profile Modal End -->