import JsBarcode from 'jsbarcode';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';
import { DOMImplementation, XMLSerializer } from 'xmldom';


const xmlSerializer = new XMLSerializer();
const document = new DOMImplementation().createDocument('http://www.w3.org/1999/xhtml', 'html', null);
const svgNode = document.createElementNS('http://www.w3.org/2000/svg', 'svg');

function barcoder(value: string, width?: number) {
   JsBarcode(svgNode, value, {
    xmlDocument: document,
    width: width || 2,
    height: 28,
    fontSize: 10,
    margin: 10
});
}

export interface Ibarcode {
    itemName: string;
    companyName: string;
    mrp: number;
    salePrice: number;
    columns?: number;
    rows?: number;
    barcode?: string;
}

export class BarcodeTemplate{
    columns: number;
    rows: number;
    itemName = '';
    companyName = '';
    mrp: number;
    salePrice: number;
    barcode!: string;


    constructor(barcodeObject: Ibarcode) {
        this.itemName = barcodeObject?.itemName || '';
        this.companyName = barcodeObject?.companyName || '';
        this.mrp = barcodeObject?.mrp;
        this.salePrice = barcodeObject?.salePrice;
        this.columns = barcodeObject?.columns || 3;
        this.rows = barcodeObject?.rows || 7;
        this.barcode = barcodeObject?.barcode || '';
    }

    templateBody() {
        try {
            let width = 1.5;
            if (this.barcode.length > 8) {
                width = 1;
            }
            barcoder(this.barcode, width);
            const svgText = xmlSerializer.serializeToString(svgNode);
            let newMrp = '';
            let newSalePrice = '';
            let priceCombination = '';
            if (!this.mrp && !this.salePrice) {
                this.rows = 12;
            }
            if (this.mrp) {
                newMrp = 'MRP:  &#8377;' + this.mrp;
            }
            if (this.salePrice) {
                newSalePrice = 'Sale Price: &#8377; ' + this.salePrice;
            }
            if (this.mrp && this.mrp.toString().length >= 3 && this.salePrice && this.salePrice.toString().length >= 3) {
                newMrp = '';
                newSalePrice = '';
                priceCombination = 'MRP:  &#8377;' + this.mrp + '<br>' + 'Sale Price: &#8377; ' + this.salePrice;
            }
            // if (priceCombination) {
            //     this.rows = 5;
            // }
            if (this.itemName.length > 26) {
                this.itemName = this.itemName.substring(0, 26);
            }
            let html = '';
            let td = '';
            const td1 = `
            <td>
                <div class="barCard" style="
                border: 1px black solid;
        width: 200px;
        text-align: center;
        margin: 0px 0px 0px 0px;
        font-size: 14px;">
                    <div class="barTitle">
                            ${this.companyName}
                        <div>
                            ${this.itemName}
                        </div>
                    </div>
                    <div class="barCode">
                        ${newMrp} ${newSalePrice}
                        ${priceCombination}
                    </div>
                    <div>
                       ${svgText}
                    </div>
                </div>
            </td>`;
    
    
            for (let i = 0; i < this.columns; i++){
                td += td1;
            }
    
            let tr = '';
            const tr1 = `
            <tr>
            ${td}
            </tr>`;
    
            for (let i = 0; i < this.rows; i++){
                tr += tr1;
            }
    
    
            html = `
            <div class="container-new arial three-inch-holder">
                <table class="saveBarcode" style="margin: 5px 5px 5px 5px;">
                    ${tr}
                </table>
            </div>`;
    
            return html;
        } catch (error) {
            SentryUtilites.setLog("BarcodeTemplate:templateBody", error)
            return '';
        }
    }


}


