<ion-header>
  <ion-toolbar class="{{ getHeaderColorClass() }}">
    <ion-title>error-not-found</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <p>Not Found</p>
  <ion-button (click)="goToDashboard()">GO TO DASHBOARD</ion-button>
</ion-content>
