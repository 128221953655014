import { Utility } from './../utils/utility';
import { BaseModel } from "./BaseModel.model";

export class IParty {

  static SCHEMA_NAME = 'party';

}

export default class Party extends BaseModel {
  profileId: string;

  category: string;
  credit: number;
  name: string;
  phone: string;
  type: 'customer' | 'supplier';
  gstin: string;
  billingType: BillingType;
  dateOfBirth: number;
  businessName: string;
  email: string;
  billingAddress: string;
  billingProvience: keyof typeof Utility.states;
  billingPostalCode: string;
  deliveryAddress: string;
  deliveryProvience: keyof typeof Utility.states;
  deliveryPostalCode: string;
  paymentTerm: string;
  sendAlerts: boolean;
  isCashSaleParty: boolean;
  isFavourite: boolean;
  lastSaleStamp: number;
  satisfactionIndex?: number;

  runningSaleBill: string;
  runningSaleBillAmount: number;
  isPrintedKOT: boolean;

}

export enum BillingType {
  OnlineDeliverySellPrice = "OnlineDeliverySellPrice",
  AcSellPrice = "AcSellPrice",
  NonAcSellPrice = "NonAcSellPrice",
  SellPrice = "SellPrice",
}
