import { ToastController } from '@ionic/angular';
import { ItemStockAdjust } from './../../models/ItemStockAdjust.model';
import { AllDataService } from './../../services/all-data.service';
import { Component, OnInit } from '@angular/core';
import { Item } from '../../models/Item.model';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-item-stock-adjust',
  templateUrl: './item-stock-adjust.component.html',
  styleUrls: ['./item-stock-adjust.component.scss'],
})
export class ItemStockAdjustComponent implements OnInit {

  item: Item = null;
  isModalOpen = false;
  addReduceOperation: 'Add' | 'Reduce' = 'Add';
  quantity: number = 0;
  note: string = '';

  isBlockSave: boolean = false;

  constructor(
    private allDataService: AllDataService,
    private toastController: ToastController,
  ) { }

  ngOnInit() { }

  openStockAdjustModal(isOpen: boolean = true) {
    try {
      this.isModalOpen = isOpen;
      if(!isOpen) {
        this.resetModal();
      }else {
        this.isBlockSave = false;
      }
    } catch (error) {
      SentryUtilites.setLog("ItemStockAdjustComponent:openStockAdjustModal", error)
    }
  }

  resetModal() {
    try {
      this.item = null;
      this.addReduceOperation = 'Add';
      this.quantity = 0;
      this.note = '';
    } catch (error) {
      SentryUtilites.setLog("ItemStockAdjustComponent:resetModal", error)
    }
  }

  setItem(item) {
    this.item = item;
  }

  onAddReduceOperationSelect(event: any) {
    try {
      if (event?.detail?.value) {
        this.addReduceOperation = event.detail.value;
      }
    } catch (error) {
      SentryUtilites.setLog("ItemStockAdjustComponent:onAddReduceOperationSelect", error)
    }
  }

  setQuantity(event: any) {
    try {
      let value = event?.detail?.target?.value;
      if (value && isNaN(+value) === false) {
        this.quantity = +value;
      } else {
        this.quantity = 0;
      }
    } catch (error) {
      SentryUtilites.setLog("ItemStockAdjustComponent:setQuantity", error)
    }
  }

  setNote(event: any) {
    this.note = event?.detail?.target?.value || '';
  }

  async save() {
    try {
      if(!this.isBlockSave) {
        if (this.quantity > 0 && this.addReduceOperation && this.item?._localUUID) {
          this.isBlockSave = true;
          const itemStockAdjust = new ItemStockAdjust();
          itemStockAdjust.quantity = this.addReduceOperation === 'Add' ? this.quantity : this.quantity * -1;
          itemStockAdjust.linkedItemUUID = this.item?._localUUID;
          itemStockAdjust.note = this.note;
          const savedItemStockAdjust = await this.allDataService.itemStockAdjustService.save(itemStockAdjust);
    
          if (savedItemStockAdjust?._localUUID) {
            this.allDataService.listForceReloadSubs.next('item-stock-adjust-list');
            let updatedItem=await this.allDataService.itemService.getByUUID(savedItemStockAdjust.linkedItemUUID);
            this.allDataService.itemService.reloadList();
            this.allDataService.itemService.updateSubs.next(updatedItem);
            this.presentToast(`Item Stock ${this.addReduceOperation}ed Successful.`, 'success');
          } else {
            this.presentToast(`Something Went Wrong.`, 'danger');
          }
          setTimeout(() => {
            this.isBlockSave = false;
          }, 5000);
        }
      }
    } catch (error) {
      SentryUtilites.setLog("ItemStockAdjustComponent:save", error)
    }
  }

  async presentToast(header: string, color: 'success' | 'danger') {
    try {
      const toast = await this.toastController.create({
        header,
        duration: 2000,
        position: 'top',
        mode: 'ios',
        color: color,
      });
      await toast.present();
      this.openStockAdjustModal(false);
    } catch (error) {
      SentryUtilites.setLog("ItemStockAdjustComponent:presentToast", error)
    }
  }



}
