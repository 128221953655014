import { BaseModel } from "./BaseModel.model";
import { ProfileLicence } from "./ProfileLicence.model";

export class ILicence {

  static SCHEMA_NAME = 'licence';

}
export class Licence extends BaseModel {
  profileId?: string;
  userId: string;
  proActivationStamp: number;
  proExpiryStamp: number;
  proTrialExpiryStamp: number;
  smsCredits: number;
  whastappMessageCredits: number;
  paidWhastappMessageCredits: number;
  billPrintCredit: number;
  profileLicences: ProfileLicence[];

}
