import { BehaviorSubject } from "rxjs";
import { Utility } from "../utils/utility";
import { ExpressServerService } from "./api/express-server.service";
import { IDataService } from "../../interface/IDataService.interface";
import { ItemDao } from "./dao/item.dao";
import { PartyDao } from "./dao/party.dao";
import { AuthService } from "./auth/auth.service";
import { PartyItemPriceMapDao } from "./dao/party-item-price-map.dao";
import PartyItemPriceMap from "../models/PartyItemPriceMap.model";
import { ProfileDao } from "./dao/profile.dao";
import { EstimateDao } from "./dao/estimate.dao";
import { Estimate } from "../models/Estimate.model";
import { SentryUtilites } from "../utils/sentryUtilites";

export class EstimateService implements IDataService<Estimate>{
  private static _instance: EstimateService;

  public static getInstance(
    estimateDao: EstimateDao,
    partyDao: PartyDao,
    itemDao: ItemDao,
    partyItemPriceMapDao: PartyItemPriceMapDao,
    profileDao: ProfileDao,
    expressServerService: ExpressServerService,
    authService: AuthService
  ) {
    if (!this._instance) {
      this._instance = new EstimateService(
        estimateDao,
        partyDao,
        itemDao,
        partyItemPriceMapDao,
        profileDao,
        expressServerService,
        authService
      )
      this._instance.initService();
    }
    this._instance.reloadList();
    return this._instance;
  }

  constructor(
    dao: EstimateDao,
    partyDao: PartyDao,
    itemDao: ItemDao,
    partyItemPriceMapDao: PartyItemPriceMapDao,
    profileDao: ProfileDao,
    expressServerService: ExpressServerService,
    authService: AuthService
  ) {
    this.dao = dao;
    this.expressServerService = expressServerService;
    this.itemDao = itemDao
    this.partyDao = partyDao;
    this.partyItemPriceMapDao = partyItemPriceMapDao;
    this.profileDao = profileDao;
    this.authService = authService;
  }
  dao: EstimateDao;
  itemDao: ItemDao;
  partyDao: PartyDao;
  partyItemPriceMapDao: PartyItemPriceMapDao;
  profileDao: ProfileDao;
  expressServerService: ExpressServerService;
  authService: AuthService;

  LIST_REFRESH_RATE = 1000;
  selectedProfileId: string = null;
  selectedProfileUserId: string = null;
  updateSubs = new BehaviorSubject<Estimate>(null);


  lastReloadStamp: number = 0;
  isReloadPostpond = false;

  initService() {
    this.selectedProfileId = Utility.getFromLocalStorage('selectedProfile');
    this.selectedProfileUserId = Utility.getFromLocalStorage('selectedProfileUserId');
    this.reloadList();
  }


  async reloadList() {
    try {
      if (this.isReloadPostpond) {
        return;
      }
      const currentStamp = +new Date();
      if (this.lastReloadStamp < (currentStamp - this.LIST_REFRESH_RATE)) {
        this.lastReloadStamp = currentStamp;
        this.trySyncUnsynced();
      } else {
        this.isReloadPostpond = true;
        setTimeout(() => {
          this.isReloadPostpond = false;
          this.reloadList();
        }, this.LIST_REFRESH_RATE + 100);
      }
    } catch (error) {
      SentryUtilites.setLog("EstimateService:reloadList", error)
      return false
    }
  }

  getAllByPromise() {
    return this.dao.getAllByProfile(this.selectedProfileId);
  }

  getAllByPromiseByProfile(profileId: string) {
    return this.dao.getAllByProfile(profileId);
  }

  getAllByProfileWithOnlyRunningBill() {
    return this.dao.getAllByProfileWithOnlyRunningBill(this.selectedProfileId);
  }

  getAllByProfileWithRunningBill() {
    return this.dao.getAllByProfileWithRunningBill(this.selectedProfileId);
  }

  getAllWithDeletedByProfile() {
    return this.dao.getAllWithDeletedByProfile(this.selectedProfileId);
  }

  getByBillDateRange(startTime: number, endTime: number, profileId?: string): Promise<Estimate[]> {
    return new Promise(async (resolve, reject) => {
      try {
        let allDocs = await this.dao.getAllByProfile(profileId || this.selectedProfileId);
        if (allDocs != null) {
          let filteredDocs = allDocs.filter(doc => doc?.billDateStamp >= startTime && doc?.billDateStamp < endTime);
          return resolve(filteredDocs);
        } else {
          return resolve(null);
        }
      } catch (error) {
        SentryUtilites.setLog("EstimateService:getByBillDateRange", error)
        return resolve(null);
      }
    });
  }

  getByCreatedDateRange(startTime: number, endTime: number): Promise<Estimate[]> {
    return new Promise(async (resolve, reject) => {
      try {
        let allDocs = await this.dao.getAllByProfile(this.selectedProfileId);
        if (allDocs != null) {
          let filteredDocs = allDocs.filter(doc => doc?.createdStamp >= startTime && doc?.createdStamp < endTime);
          return resolve(filteredDocs);
        } else {
          return resolve(null);
        }
      } catch (error) {
        SentryUtilites.setLog("EstimateService:getByCreatedDateRange", error)
        return resolve(null);
      }
    });
  }

  getByBillCompleteDateRange(startTime: number, endTime: number): Promise<Estimate[]> {
    return new Promise(async (resolve, reject) => {
      try {
        let allDocs = await this.dao.getAllByProfile(this.selectedProfileId);
        if (allDocs != null) {
          let filteredDocs = allDocs.filter(doc => doc?.createdStamp >= startTime && doc?.createdStamp < endTime);
          return resolve(filteredDocs);
        } else {
          return resolve(null);
        }
      } catch (error) {
        SentryUtilites.setLog("EstimateService:getByBillCompleteDateRange", error)
        return resolve(null);
      }
    });
  }

  getById(id: number): Promise<Estimate> {
    return this.dao.getById(id);
  }

  getByUUID(uuid: string): Promise<Estimate> {
    return this.dao.getByUUID(uuid);
  }

  save(estimate: Estimate): Promise<Estimate> {
    return new Promise(async (resolve, reject) => {
      try {
        if(Utility.isTruthy(estimate)) {
          let currentProfile = this.selectedProfileId;
          estimate.userId = this.selectedProfileUserId;
          if(!estimate?.profileId) {
            estimate.profileId = currentProfile;
          }
          estimate.createdBy = estimate.lastModifiedBy = this.authService.getLoginPhone();
          estimate.createdByName = estimate.lastModifiedByName = Utility.getCreatedByName();
  
          if (!estimate?._localUUID) {
            estimate._localUUID = Utility.getUUID();
          }
  
          let savedEstimate = await this.dao.save(estimate);
  
          this.reloadList();
          return resolve(savedEstimate);
        } else {
          return resolve(null);
        }

      } catch (err) {
        SentryUtilites.setLog("EstimateService:save", err)
        return resolve(null)
      }

    });
  }

  update(estimate: Estimate): Promise<Estimate> {
    return new Promise(async (resolve, reject) => {
      try {
        if (estimate?._localUUID) {
          estimate.lastModifiedBy = this.authService.getLoginPhone();
          estimate.lastModifiedByName = Utility.getCreatedByName();
          let oldEstimate = await this.getByUUID(estimate._localUUID);

          if (
            oldEstimate?.party?._localUUID != null
            && estimate?.party?._localUUID != ""
            && oldEstimate?.party?._localUUID != ""
            && oldEstimate?.party?._localUUID == estimate?.party?._localUUID
          ) {

            let updatedEstimate = await this.dao.update(estimate);
            this.reloadList();
            this.updateSubs.next(updatedEstimate);
            return resolve(updatedEstimate);

          }
        }
        return resolve(null)
      } catch (err) {
        SentryUtilites.setLog("EstimateService:update", err)
        return resolve(null)
      }
    });
  }

  delete(estimate: Estimate): Promise<Estimate> {
    return new Promise(async (resolve, reject) => {
      try {
        if(estimate?._localUUID) {
          estimate.lastModifiedBy = this.authService.getLoginPhone();
          estimate.lastModifiedByName = Utility.getCreatedByName();
          let estimateTobeDeleted = await this.getByUUID(estimate?._localUUID)
          let deletedEstimate = await this.dao.delete(estimateTobeDeleted);
         
            //Party
            //----------------------------------------
            deletedEstimate.party.lastModifiedBy = deletedEstimate?.lastModifiedBy;
            deletedEstimate.party.lastModifiedByName = deletedEstimate?.lastModifiedByName;
  
          //Item
            //----------------------------------------
            for (let i = 0; i < deletedEstimate?.billItems?.length; i++) {
              let billItem = deletedEstimate?.billItems[i];
              if(billItem?.item?._localUUID) {
                billItem.item.lastModifiedBy = deletedEstimate?.lastModifiedBy;
                billItem.item.lastModifiedByName = deletedEstimate?.lastModifiedByName;
              }
            }
          this.reloadList();
          this.updateSubs.next(deletedEstimate);
          return resolve(deletedEstimate);
        }
        return resolve(null);
      } catch (err) {
        SentryUtilites.setLog("EstimateService:delete", err)
        return resolve(null);
      }

    });
  }

  isSyncLock = false;
  isSyncPostPond = false;
  async trySyncUnsynced(postpond?: boolean) {
    try {
      if (this.isSyncLock) {
        if (!this.isSyncPostPond) {
          setTimeout(() => {
            this.isSyncPostPond = true;
            this.trySyncUnsynced(true);
          }, 200);
        }
        return true;
      }
      if (postpond) {
        this.isSyncPostPond = false;
      }
      this.isSyncLock = true;
      let unSyncedElements: Estimate[] = await this.dao.getAllUnsynced(this.selectedProfileId);
      if (unSyncedElements && unSyncedElements.length) {
        try {
  
          for (let i = 0; i < unSyncedElements.length; i++) {
            let unSyncedElement = unSyncedElements[i];
            if(unSyncedElement?._localUUID) {
              unSyncedElement['updatedStamp'] = +new Date();
            }
          }
  
          await this.dao.bulkPut(unSyncedElements);
          await Utility.wait(1000);
  
          for (let i = 0; i < unSyncedElements?.length; i++) {
            let unSyncedElement = unSyncedElements[i];
            if(unSyncedElement?._localUUID) {
              unSyncedElement['profile'] = await this.profileDao.getByUUID(unSyncedElements[i]?.profileId);
            }
          }
  
          let chunkArr = Utility.getChunkArr(unSyncedElements);
          let chunkArrLength = chunkArr?.length;
          
          for(let i = 0; i < chunkArrLength; i++) {
            let result = await this.expressServerService.makeSyncCall('estimate', chunkArr[i]);
            if (result && result?.['records']?.length) {
              let arr = result?.['records'];
              for (let i = 0; i < arr?.length; i++) {
                const el = arr[i];
                await this.updateSyncStamp(el);
              }
            }
          }
        } catch (err) {
          SentryUtilites.setLog("EstimateService:trySyncUnsynced:inner", err)
        }
      }
      this.isSyncLock = false;
    } catch (error) {
      SentryUtilites.setLog("EstimateService:trySyncUnsynced", error)
    }
  }

  updateSyncStamp(el: Estimate): Promise<Estimate> {
    return new Promise(async (resolve, reject) => {
      try {
        let updatedEl = await this.dao.updateSyncStamp(el);
        this.updateSubs.next(updatedEl);
        return resolve(updatedEl);
      } catch (error) {
        SentryUtilites.setLog("EstimateService:updateSyncStamp", error)
        return resolve(null)
      }
    });
  }

  async getNewEstimateNo(): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        let estimates = await this.dao.getAllByProfileWithRunningBill(this.selectedProfileId);
        let nextEstimateNo = 'INV_001';
        if (estimates[0]?.billNo) {
          nextEstimateNo = Utility.nextNo(estimates[0]?.billNo);
        }
        return resolve(nextEstimateNo);
      } catch (error) {
        SentryUtilites.setLog("EstimateService:getNewEstimateNo", error)
        return resolve(null)
      }

    });
  }

  /**
   * 
   * @returns : return deleted estimates from estimate dao
   */
  async getAllDeleted(): Promise<Estimate[]> {
    try {
      let res = await this.dao.getAllDeletedByProfile(this.selectedProfileId);
      return res || [];
    } catch (error) {
      SentryUtilites.setLog("EstimateService:getAllDeleted", error)
      return [];
    }
  }
  // --------------------------------

  copyData(fromProfileId: string, toProfileId: string): Promise<boolean> {
    return new Promise(async (resolve,reject) => {
      try {
        if(Utility.isTruthy(fromProfileId) && Utility.isTruthy(toProfileId)) {
          let fromRecords = await this.getAllByPromiseByProfile(fromProfileId);
          if(fromRecords?.length) {
            let toRecords: Estimate[] = [];
            for (let i = 0; i < fromRecords?.length; i++) {
              const fetchedRecord = fromRecords[i];
              if(fetchedRecord?._localUUID) {
                fetchedRecord.profileId = toProfileId;
                delete fetchedRecord?._localId;
                delete fetchedRecord?._localUUID;
                let savedRecord = await this.save(fetchedRecord);
                if(savedRecord?._localUUID) {
                  toRecords?.push(savedRecord);
                }
              }
            }
            if(fromRecords?.length === toRecords?.length) {
              return resolve(true);
            }
          }
        }
        return resolve(false);
      } catch (error) {
        SentryUtilites.setLog("EstimateService:copyData", error)
        return resolve(false);
      }
    });
  }


  updateConvertedStamp(estimate: Estimate, convertedStamp:number): Promise<Estimate> {
    estimate.lastModifiedBy = this.authService.getLoginPhone();
    estimate.lastModifiedByName = Utility.getCreatedByName();
    return new Promise(async (resolve, reject) => {
      try {
        if (estimate?._localUUID) {
          let oldEstimate = await this.getByUUID(estimate._localUUID);

          if (
            oldEstimate?.party?._localUUID != null
            && estimate.party?._localUUID != ""
            && oldEstimate?.party?._localUUID != ""
            && oldEstimate?.party?._localUUID == estimate?.party?._localUUID
          ) {

            let updatedEstimate = await this.dao.updateConvertedStamp(estimate,convertedStamp); // ToDo create updated ConvertedStamp method
            this.reloadList();
            this.updateSubs.next(updatedEstimate);
            return resolve(updatedEstimate);

          }
        }
        return resolve(null)
      } catch (err) {
        SentryUtilites.setLog("EstimateService:updateConvertedStamp", err)
        return resolve(null)
      }
    });
  }



  

}
