<!-- bill date modal start -->
<ion-modal 
  class="date-modal" 
  [keepContentsMounted]="keepContentsMounted" 
  [isOpen]="isOpenBillDatePicker" 
  (willDismiss)="closeDOBPicker()">
  <ng-template>
    <ion-datetime
      appYearDefaultBtn   
      [showDefaultButtons]="true"
      [max]="maxDate"
      [value]="dpBillDateValue"
      (ionChange)="onBillDateSelect($event)"
      presentation="date"
    >
      <span slot="title">Select a Money Out Date</span>
    </ion-datetime>
  </ng-template>
</ion-modal>
<!-- bill date modal end -->

<ion-grid>

  <form [formGroup]="form">

    <ion-row>
      <ion-col size-lg="6">
        <ion-item lines="none">
          <ion-label>Receipt No</ion-label>
          <ion-input
            [disabled]="paramDocumentId ? true : false"
            placeholder="Receipt No"
            formControlName="billNo"
          ></ion-input>
        </ion-item>
      </ion-col>
      <ion-col size-lg="6">
        <ion-item lines="none">
          <ion-label>Money Out Date</ion-label>

          <!-- bill date input start -->
          <input 
            type="button" 
            class="date-input"
            [value]="setDateValue(form?.value?.billDateStamp || todayDate)"
            (click)="isOpenBillDatePicker = true;">
          <!-- bill date input end -->

        </ion-item>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size-xs="12" size-lg="12">
        <app-party-selector
          #partySelector
          (partySelectedEvent)="onPartySelect($event)"
        >
        </app-party-selector>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="purchase || expense || saleReturn">
      <ion-col size="6">
      </ion-col>
      <ion-col class="txt-right" size="6">
        <ion-chip color="tertiary"> Max allowed money out amount: {{ maxAllowedMoneyOutInAmount() | currency: 'INR' }}</ion-chip>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size-lg="6">
        <ion-item lines="none">
          <ion-segment mode="ios" formControlName="paymentMode">
            <ion-segment-button value="cash">
              <ion-label>Cash</ion-label>
            </ion-segment-button>
            <ion-segment-button value="cheque">
              <ion-label>Cheque</ion-label>
            </ion-segment-button>
            <ion-segment-button value="bank">
              <ion-label>Bank</ion-label>
            </ion-segment-button>
          </ion-segment>
        </ion-item>
        <ion-item lines="none" *ngIf="form?.value?.paymentMode=='cheque' || form?.value?.paymentMode=='bank'">
          <ion-input
            class="input-payment-id"
            formControlName="paymentId"
            placeholder="Enter Payment Refrence Id"
          ></ion-input>
        </ion-item>
      </ion-col>
      <ion-col size-lg="6">
        <ion-item lines="none">
          <ion-label>Total Amount</ion-label>
          <ion-input
            #inpTotalAmountEle
            placeholder="Total Amount"
            type="number"
            formControlName="totalAmount"
            (keyup.enter)="this?.form?.invalid || ((purchase || saleReturn) && form?.value?.totalAmount > maxAllowedMoneyOutInAmount()) ? null : onSaveClick()"
          ></ion-input>
        </ion-item>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="12">
        <ion-button
          class="btn-save-money-in"
          expand="full"
          [disabled]="this?.form?.invalid || ((purchase || expense || saleReturn) && form?.value?.totalAmount > maxAllowedMoneyOutInAmount())"
          (click)="onSaveClick()"
        >
          Save Money Out
        </ion-button>
      </ion-col>
    </ion-row>

  </form>

</ion-grid>
