import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PurchaseReturn } from '../../../models/PurchaseReturn.model';
import { PinVerificationComponent } from '../../pin-verification/pin-verification.component';
import { Utils } from '../../../utils/utils';
import { Router } from '@angular/router';
import { AccessControlService } from '../../../services/auth/access-control.service';
import { AllDataService } from '../../../services/all-data.service';
import { SentryUtilites } from '../../../utils/sentryUtilites';
import { MoneyIn } from '../../../models/MoneyIn.model';

@Component({
  selector: 'app-purchase-return-row',
  templateUrl: './purchase-return-row.component.html',
  styleUrls: ['./purchase-return-row.component.scss'],
})
export class PurchaseReturnRowComponent implements OnInit {

  @Input() purchaseReturn: PurchaseReturn = null;
  @Input() isRecentTransactionList: boolean = false;

  @Output() onAddPaymentClick = new EventEmitter<PurchaseReturn>();
  @Output() onDeleteClick = new EventEmitter<PurchaseReturn>();

  @ViewChild('pinVerificationElement') pinVerificationElement: PinVerificationComponent;

  capFractionsToTwo = Utils.capFractionsToTwo;

  selectedPurchase: PurchaseReturn = null;

  constructor(
    private router: Router,
    private accessControlService: AccessControlService,
    private allDataService: AllDataService,
  ) { }

  ngOnInit() { }

  openTransactionPINModal() {
    this.pinVerificationElement?.openTransactionPINModal();
  }

  async billView(purchaseReturn: PurchaseReturn) {
    try {
      if(!purchaseReturn?.deletedStamp) {
        let isPermit = await this.accessControlService.isUserHasAccess({action:'viewPurchaseReturn'});
        if(!isPermit) {
          return alert("Permission: You don't have permission to view bill. Please contact to your owner.");
        }
        this.router.navigate([`bill-view-purchase-return/${purchaseReturn?._localUUID}`]);
      }
    } catch (error) {
      SentryUtilites.setLog("PurchaseReturnRowComponent:billView", error)
      alert("Something went wrong.");
    }
  }

  verifyTransactionPIN(event) {
    try {
      if(event) {
        this.router.navigate([`purchase-return/form/${this.purchaseReturn?._localUUID}`]);
      }
    } catch (error) {
      SentryUtilites.setLog("PurchaseReturnRowComponent:verifyTransactionPIN", error)
    }
  }

  async edit(purchaseReturn: PurchaseReturn) {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({action:'editPurchaseReturn'});
      if(!isPermit) {
        return alert("Permission: You don't have permission to edit purchase return. Please contact to your owner.");
      }
      this.openTransactionPINModal();
    } catch (error) {
      SentryUtilites.setLog("PurchaseReturnRowComponent:edit", error)
      alert("Something went wrong.");
    }
  }

  resetSelectedPurchase() {
    this.selectedPurchase = null;
  }

  delete(purchaseReturn: PurchaseReturn) {
    this.onDeleteClick.emit(purchaseReturn);
  }

  getBalanceAmount(moneyIns: MoneyIn[], totalAmount: number) {
    try {
      if(!moneyIns || !moneyIns?.length) {
        return this.capFractionsToTwo(totalAmount);
      }
      if (moneyIns?.length) {
        let paidAmount: number = 0;
        moneyIns?.forEach(moneyIn => paidAmount += Number(moneyIn.totalAmount));
        let pendingAmount = totalAmount - paidAmount;
        pendingAmount = pendingAmount < 0 ? 0 : pendingAmount;
        return this.capFractionsToTwo(pendingAmount);
      }
    } catch (error) {
      SentryUtilites.setLog("PurchaseReturnRowComponent:getBalanceAmount", error)
      return 0;
    }
  }

  extraMoneyInAmount(moneyIns: MoneyIn[], totalAmount: number) {
    try {
      if (moneyIns?.length) {
        let receivedAmount: number = 0;
        moneyIns?.forEach(moneyIn => receivedAmount += Number(moneyIn.totalAmount));
        if(receivedAmount > totalAmount) {
          return receivedAmount - totalAmount;
        }else {
          return 0;
        }
      }
    } catch (error) {
      SentryUtilites.setLog("PurchaseReturnRowComponent:extraMoneyInAmount", error)
      return 0;
    }
  }

  async addPayment(purchaseReturn: PurchaseReturn) {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({action:'createMoneyIn'});
      if(!isPermit) {
        return alert("Permission: You don't have permission to create money in. Please contact to your owner.");
      }
      this.onAddPaymentClick.emit(purchaseReturn);
    } catch (error) {
      SentryUtilites.setLog("PurchaseReturnRowComponent:addPayment", error)
      alert("Something went wrong.");
    }
  }

  async setPaymentMode(paymentMode: 'cash' | 'cheque' | 'bank') {
    try {
      if(this.purchaseReturn?.moneyIns?.length) {
        this.purchaseReturn.moneyIns[0].paymentMode = paymentMode;
        this.purchaseReturn = await this.allDataService.purchaseReturnService.update(this.purchaseReturn);
      }
    } catch (error) {
      SentryUtilites.setLog("PurchaseReturnRowComponent:setPaymentMode", error)
    }
  }

  async viewPurchase(purchaseReturn: PurchaseReturn) {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({action:'viewPurchase'});
      if(!isPermit) {
        return alert("Permission: You don't have permission to view Purchase. Please contact to your owner.");
      }
      if(purchaseReturn?.linkedPurchaseUUID) {
        this.router.navigate([`bill-view-purchase/${purchaseReturn?.linkedPurchaseUUID}`]);
      }
    } catch (error) {
      SentryUtilites.setLog("PurchaseReturnRowComponent:viewPurchase", error)
    }
  }

}
