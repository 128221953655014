import { Arrays } from "../utils/ReferenceData";

export class SaleReturnBillPrint{
    user: {
        isPro?: number;
        registrationStamp?: number;
    };

    profile: {
        addressProvience?: string;
        legalName?: string;
        addressLine1?: string;
        addressLine2?: string;
        addressCity?:string;
        addressPostalCode?: string;
        contactPersonPhone?: string;
        contactPersonEmail?: string;
        gstin?: string;
        fssaiNumber?: string;
        licenseNumber?: string;
        bankAccountNo?: string;
        bankName?: string;
        bankIFSC?: string;
        bankAccountType?: string;
        bankUPI?: string;
        additionalInputFieldTitle?: string;
        additionalInputFieldValue?: string;
        additionalDateFieldValue?: string; // Not Avail
        additionalDateFieldTitle?: string; // Not Avail
        signatureLink?: string;
        logoLink?: string;
        additionalInputFieldTitle1?: string;
        additionalInputFieldTitle2?: string;
        additionalInputFieldTitle3?: string;
        additionalInputFieldTitle4?: string;
        additionalInputFieldTitle5?: string;
        additionalInputFieldValue1?: string;
        additionalInputFieldValue2?: string;
        additionalInputFieldValue3?: string;
        additionalInputFieldValue4?: string;
        additionalInputFieldValue5?: string;
    };

    bill?: {
        partyData: {
            ledgerCredit?: number;
            profileData: {
                legalName?: string;
                gstNumber?: string;
                contactPersonName?: string;
                contactPersonPhone?: string;
                contactPersonEmail?: string;
                addressLine1?: string;
                addressState?: string;
                addressPincode?: string;
                addressOneLine1?: string;
                addressOneState?: string;
                addressOnePincode?: string;
                udf1T?: string;
                udf1V?: string;
                udf2T?: string;
                udf2V?: string;
                udf3T?: string;
                udf3V?: string;
                additionalInputFieldTitle?: string;
                additionalInputFieldValue?: string;
                additionalDateFieldValue?: string; // Not Avail
                additionalDateFieldTitle?: string; // Not Avail
            };
        };
        secondaryPartyData?: {
            ledgerCredit?: number;
            profileData: {
                legalName?: string;
                gstNumber?: string;
                contactPersonName?: string;
                contactPersonPhone?: string;
                contactPersonEmail?: string;
                addressLine1?: string;
                addressState?: string;
                addressPincode?: string;
                addressOneLine1?: string;
                addressOneState?: string;
                addressOnePincode?: string;
                udf1T?: string;
                udf1V?: string;
                udf2T?: string;
                udf2V?: string;
                udf3T?: string;
                udf3V?: string;
                additionalInputFieldTitle?: string;
                additionalInputFieldValue?: string;
                additionalDateFieldValue?: string; // Not Avail
                additionalDateFieldTitle?: string; // Not Avail
            };
        };
        moneyOut: {
            billNo?: string;
            dateStamp?: number;
            amount?: number;
            txnMode?: string;
            txnRef?: string;
        }
        isPartyDeleted?:boolean;
        roundOffValue?: number;
        billNo?: string;
        deliveryState?: keyof typeof Arrays.states;
        billDateStamp?: number;
        dueDateStamp: number;
        purOrderNo?: string;
        eWayBillNo?: string;
        additionalAmount?: number;
        discountAmount?: number;
        gstAmount?: number;
        cessAmount?: number;
        totalAmount: number;
        createdStamp?: number;
        transporterName?: string;
        vehicleNumber?: string;
        deliveryDate?: number;
        color: string;
        billItems?: {
            itemDes?: string;
            itemSrl?: string;
            hsn?: string;
            price: number;
            incTax?: boolean;
            taxPercentage: number;
            cessPercentage: number;
            unit: string;
            quantity: number;
            discountPercentage?: number;
            discount?: number;
            unitGstAmount?: number;
            unitCessAmount?: number;
            unitDiscountAmount?: number;
            unitTaxAmount?: number;
            itemTotalCessAmount?: number;
            itemTotalGstAmount?: number;
            itemTotalTaxAmount?: number;
            effectiveTaxPercentage?: number;
            billCashDiscountPercentage?: number;
            total: number;
            createdStamp?: number;
            item?: {
                itemName?: string;
                hsn?: string;
                mrp?: number;
                expiryDate?: number;
            };
            additionalInputFieldValue?: string;
            additionalDateFieldValue?: number;
            additionalInputFieldTitle?: string;
            additionalDateFieldTitle?: string;
            mrp?: number;
            variant?: string;
        }[];
        eWayBillDate?: number;
        note?: string;
        billTitle?: string;
        tnc?: string;
        cashDiscount?: number;
        amountReceived?: number;
        cashDiscountPercentage?: number;
        challanNo?: any;
        totalSaving?: number;
        subTotalAmount?: number;
        partyPreviousBalance?: number;
        billCompleteStamp: number;
        serviceChargePercentage?: number;
        serviceChargeAmount?: number;
        deliveryProvience?: string;
        saleBillDateStamp?: number;
        saleBillNo?: number;
        saleCreatedStamp?: number;
    };
    
    settings?: {
        showSignatureNotRequiredText?: boolean;
        showSignatureInTemplates?: boolean;
        makeItemNameBold?: boolean;
        showPreviousAmount?: boolean;
        showTaxAndDiscAmt?: boolean;
        billSavingsAmount?: boolean;
        include3InchOnlineDukanLink?: boolean;
        iSetPrintTimeOnBill?: boolean;
        pSetTaxMrpHsnPrintStatus?: boolean;
    };

    onlineDukan?: {
        domain: string;
    };

    extra?: {
        thermalTncTemplate?: string;
    };

    qrPayLink?: string;
    addressTo?: string;
    pageWidth?: number;
    category?:string;
  billPrint: {
    partyData: { ledgerCredit: any; profileData: { legalName: any; gstNumber: any; contactPersonName: any; contactPersonPhone: any; contactPersonEmail: any; addressLine1: any; addressState: any; addressPincode: any; addressOneLine1: any; addressOneState: any; addressOnePincode: any; udf1T: string; udf1V: string; udf2T: string; udf2V: string; udf3T: string; udf3V: string; additionalInputFieldTitle: string; additionalInputFieldValue: string; additionalDateFieldValue: string; additionalDateFieldTitle: string; }; }; moneyOut: { billNo: any; dateStamp: any; amount: any; txnMode: any; txnRef: any; }; isPartyDeleted: boolean; billItems: any; roundOffValue: number; billNo: any; deliveryState: any; billDateStamp: any; dueDateStamp: any; purOrderNo: any; eWayBillNo: any; additionalAmount: number // Not Avail
      ; discountAmount: number; gstAmount: number; cessAmount: number; totalAmount: number; createdStamp: any; transporterName: any; vehicleNumber: any; deliveryDate: any; color: string; eWayBillDate: any; note: any; billTitle: string; tnc: any; cashDiscount: number; amountReceived: number; cashDiscountPercentage: number; challanNo: any; totalSaving: number; subTotalAmount: number; partyPreviousBalance: number; billCompleteStamp: any; serviceChargePercentage: number; serviceChargeAmount: number; deliveryProvience: any;
  };
}