import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AlertController, IonInput, ToastController } from '@ionic/angular';
import { Validator } from '../../utils/validator';
import Party from '../../models/Party.model';
import { AllDataService } from '../../services/all-data.service';
import { PartyListComponent } from '../party-list/party-list.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Utils } from '../../utils/utils';
import { Utility } from '../../utils/utility';
import { EventService } from '../../services/event.service';
import { AccessTo } from '../../models/AccessTo.model';
import { AuthService } from '../../services/auth/auth.service';
import { AccessControlService } from '../../services/auth/access-control.service';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-party-selector',
  templateUrl: './party-selector.component.html',
  styleUrls: ['./party-selector.component.scss'],
})
export class PartySelectorComponent implements OnInit {

  @Input() defaultCashParty = false;
  @Input() isSecondaryPartyView: boolean = false;
  @Input() totalAmount: number = null;
  @Input() cartLength: number = null;
  @Input() isSale = false;
  @Input() cashDiscountPercentage: number = 0;
  @Input() dSetDiscountOfferTypeI: number = 0;
  @Input() dSetDiscountOfferTypeII: number = 0;
  @Input() dSetDiscountStatusI: boolean = false;
  @Input() dSetDiscountStatusII: boolean = false;
  @Input() discountSoundStatus: boolean = false;
  @Input() isEditedSale: boolean = false;
  @Input() isEditedSaleReturn: boolean = false;
  @Input() isStaffAssignToSale: boolean = false;
  @Input() assignStaffUserId: string = '';

  @Output() partySelectedEvent = new EventEmitter<Party>();
  @Output() secondaryPartySavePrintEvent = new EventEmitter<Party>();
  @Output() secondaryPartyOnlySaveEvent = new EventEmitter<Party>();
  @Output() secondaryDiscount = new EventEmitter<Party>();
  @Output() selectedStaff = new EventEmitter<{userId: string; name: string;}>();

  @ViewChild('partyListComponent', { static: false }) partyListComponent: PartyListComponent;
  @ViewChild('formSeconadryPartyPhoneEle') formSeconadryPartyPhoneEle: IonInput;

  secondaryPartyForm : FormGroup;

  isPartySelected = false;
  selectedParty: Party = null;
  inpPhoneValue: string = '';
  isModalOpen = false;
  isEditable = true;
  showSecondaryPartySaveBtn = true;
  isValidPhoneNumber: boolean = false;
  isValidPhoneNumberOnSave: boolean = null;
  lastActiveStr: string = '';
  accessToList: AccessTo[] = [];
  filterPartyList: Party[] = [];
  showPartySuggestionsList: boolean = false;
  showSecondaryPartyAlert: boolean = false;
  isMaskPhone: boolean = false;
  previouslyCashSaleAutoSelected = false;   
  isDOBDateModalOpen = false;
  keepDateContentsMounted = false;
  dpDobValue: string = null;

  capFractionsToTwo = Utils.capFractionsToTwo;
  stringMask = Utility.stringMask;
  showDayMonthValue = Utility.showDayMonthValue;

  constructor(
    private formBuilder: FormBuilder,
    private alertController: AlertController,
    private allDataService: AllDataService,
    private eventService: EventService,
    private toastController: ToastController,
    private authService: AuthService,
    private accessControlService: AccessControlService,
  ) { }

  ngOnInit() {
    try {
      this.keepDateContentsMounted = true;
      if(this.isSecondaryPartyView) {
        this.secondaryPartyForm = this.formBuilder.group({
          secondaryPartyName: [null],
          secondaryPartyPhone: [null],
          secondaryPartyAddress: [null],
          secondaryPartyDOB: [null],
          secondaryPartyAssignStaff: [null],
        });
        setTimeout(() => {
          this.formSeconadryPartyPhoneEle?.setFocus();
        }, 1000);
      }
      this.eventService.isCustomerRepeated.subscribe(res => {
        this.lastVisitedCustomer(res);
      })
  
      this.secondaryPartyForm?.get('secondaryPartyAssignStaff')?.valueChanges?.pipe(distinctUntilChanged(), debounceTime(300)).subscribe(res => {
        this.selectedStaff.emit({userId: res?.userId, name: res?.name});
      })
    } catch (error) {
      SentryUtilites.setLog("PartySelectorComponent:ngOnInit", error)
    }
  }

  ngDestory() { }

  async populateSecondaryPartyWhileEdit(party: Party) {
    try {
      this.lastActiveStr = null;
      if(party?._localUUID) {
        let fetchedParty = await this.allDataService.partyService.getByUUID(party?._localUUID);
        if(fetchedParty) {
          this.isValidPhoneNumberOnSave = true;
          if((this.dSetDiscountStatusI || this.dSetDiscountStatusII) && !this.isEditedSale && !this.isEditedSaleReturn) {
            this.secondaryDiscount.emit(fetchedParty);
            this.inpPhoneValue = fetchedParty?.phone;
            this.isValidPhoneNumber = Utility.checkValidPhoneNumber(this.inpPhoneValue);
          }
        }
        this.secondaryPartyForm.patchValue({
          secondaryPartyName: fetchedParty.name,
          secondaryPartyPhone: fetchedParty.phone,
          secondaryPartyAddress: fetchedParty.billingAddress,
        });
        if(fetchedParty?.dateOfBirth) {
          this.dpDobValue = Utility.ionDatePickerFormattedString(
            fetchedParty?.dateOfBirth
          );
          this.secondaryPartyForm.patchValue({
            secondaryPartyDOB: fetchedParty?.dateOfBirth,
          })
        }
        this.selectedParty = fetchedParty;
      }else if(party?.name || party?.billingAddress) {
        this.secondaryPartyForm.patchValue({
          secondaryPartyName: party.name,
          secondaryPartyAddress: party.billingAddress,
        });
        this.selectedParty = party;
      }
      if(!this.isEditedSale && !this.isEditedSaleReturn) {
        if(!party && this.discountSoundStatus) {
          Utility.playParcelModelDiscountSpeech();
        }
        setTimeout(() => {
          this.formSeconadryPartyPhoneEle?.setFocus();
        }, 250);
      }
    } catch (error) {
      SentryUtilites.setLog("PartySelectorComponent:populateSecondaryPartyWhileEdit", error)
    }
  }

  async openPartySelectorModal(isOpen: boolean = true) {
    try {
      this.filterPartyList = [];
      if(this.isStaffAssignToSale) {
        let profile = await this.allDataService.profileService.getCurrentProfile();
        this.accessToList = profile?.accessTo || [];
        let adminStaff = new AccessTo();
        adminStaff.userId = '';
        adminStaff.name = 'Admin';
        this.accessToList.unshift(adminStaff)
        let accessTo = this.accessToList.filter(x => x.userId == this.assignStaffUserId)[0];
        this.secondaryPartyForm?.patchValue({
          secondaryPartyAssignStaff: accessTo,
        })
      }
      if (this.isEditable) {
        this.isModalOpen = isOpen;
      }
    } catch (error) {
      SentryUtilites.setLog("PartySelectorComponent:openPartySelectorModal", error)
    }
  }

  onPartySelect(party: Party) {
    try {
      this.previouslyCashSaleAutoSelected = true;
      this.isPartySelected = true;
      this.selectedParty = party;
      this.partySelectedEvent.emit(party);
      this.openPartySelectorModal(false);
      this.checkMaskPhone();
    } catch (error) {
      SentryUtilites.setLog("PartySelectorComponent:onPartySelect", error)
    }
  }

  async checkMaskPhone() {
    try {
      let phone = Utility.getFromLocalStorage('selectedProfileUserId');
      let isOwner = phone === this.authService.getLoginPhone();
      this.isMaskPhone = !isOwner && await this.accessControlService.isUserHasAccess({action: 'hidePhone'});
    } catch (error) {
      SentryUtilites.setLog("PartySelectorComponent:checkMaskPhone", error)
    }
  }

  enable() {
    this.isEditable = true;
  }

  disable() {
    this.isEditable = false;
  }

  getParty() {
    return this.selectedParty;
  }

  openDOBPicker() {
    this.keepDateContentsMounted = true;
    this.isDOBDateModalOpen = true;
  }

  closeDOBPicker() {
    this.isDOBDateModalOpen = false;
  }

  //set default date
  setDefaultDate(){
    try {
      let currentDate = new Date();
      currentDate.setFullYear(2000);
       let formattedDate = +new Date(currentDate);
       this.dpDobValue = Utility.ionDatePickerFormattedString(formattedDate);
    } catch (error) {
      SentryUtilites.setLog("PartyFormPage:setDefaultDate", error)
    }
  }
  //--------------------------------------------------------------------------------------

  onDateOfBirthSelect(event) {
    try {
      if (event.detail.value) {
        this.dpDobValue = event.detail.value;
      } else {
        this.setDefaultDate();
      }
      this.secondaryPartyForm?.controls?.secondaryPartyDOB?.setValue(
        +new Date(this.dpDobValue).setHours(0, 0, 0, 0)
      );
      this.closeDOBPicker();
    } catch (error) {
      SentryUtilites.setLog("PartyFormPage:onDateOfBirthSelect", error)
    }
  }

  onCancelDateOfBirth(event) {
    try {
      if(this.secondaryPartyForm?.controls?.secondaryPartyDOB?.value) {
        event.target.reset(Utility.ionDatePickerFormattedString(this.secondaryPartyForm?.controls?.secondaryPartyDOB?.value));
      } else {
        // event.target.reset();
        this.setDefaultDate();
      }
    } catch (error) {
      SentryUtilites.setLog("PartyFormPage:onCancelDateOfBirth", error)
    }
  }

  resetParty() {
    try {
      this.previouslyCashSaleAutoSelected = false;
      this.selectedParty = null;
      this.isPartySelected = false;
      this.lastActiveStr = null;
      this.inpPhoneValue = null;
      this.isValidPhoneNumber = false;
      this.isValidPhoneNumberOnSave = null;
      this.secondaryPartyForm?.reset();
    } catch (error) {
      SentryUtilites.setLog("PartySelectorComponent:resetParty", error)
    }
  }

  setSelectedParty(party: Party) {
    this.selectedParty = party;
    this.checkMaskPhone();
  }

  async presentAddPartyAlert(value?: string) {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({action:'createParty'});
      if(!isPermit) {
        return alert("Permission: You don't have permission to create party. Please contact to your owner.");
      }
      let phone = null;
      let name = '';
      if (value) {
        if (value?.length == 10 && isNaN(Number(value)) == false) {
          phone = Number(value);
        } else {
          name = value;
        }
      }
      const alertControl = await this.alertController.create({
        header: 'Party Details',
        buttons: [
          {
            text: 'Save',
            handler: async (value) => {
              if(value?.partyName && !value?.partyName?.match(Validator.noSpaceAtStart) || !value?.partyName?.trim()) {
                Utility.changeBorderColor('party-name', false);
                return false;
              }
              Utility.changeBorderColor('party-name', true);
              if(value?.partyPhone) {
                if(!value?.partyPhone?.match(Validator.phone)) {
                  Utility.changeBorderColor('party-phone', false);
                  return false;
                }
                Utility.changeBorderColor('party-phone', true);
              }
              let result = await this.saveParty(value);
              return result ? true : false;
            },
          }
        ],
        mode: 'ios',
        inputs: [
          {
            type: 'text',
            placeholder: 'Customer / Supplier Name',
            name: 'partyName',
            value: name || '',
            cssClass: 'party-name',
          },
          {
            type: 'number',
            placeholder: 'Phone Number',
            name: 'partyPhone',
            value: phone || '',
            attributes: {
              pattern: Validator.phone,
            },
            cssClass: 'party-phone',
          },
        ],
      });
  
      await alertControl.present();
    } catch (error) {
      SentryUtilites.setLog("PartySelectorComponent:presentAddPartyAlert", error)
    }
  }

  async saveParty(value: { partyName: string, partyPhone: number, address?: string }) {
    try {
      this.keepDateContentsMounted = false;
      this.dpDobValue = null;
      let party = new Party();
      party.name = value.partyName || value.partyPhone.toString();
      party.phone = value.partyPhone.toString();
      party.billingAddress = value.address;
      let result = await this.allDataService.partyService.save(party);
      if(result && !this.isSecondaryPartyView) {
        this.allDataService.listForceReloadSubs.next('party-list');
        this.onPartySelect(party);
      }
      return result;
    } catch (error) {
      SentryUtilites.setLog("PartySelectorComponent:saveParty", error)
      return null;
    }
  }

  async onSecondaryPhoneChange(event) {
    try {
      const value = event?.detail?.target?.value;
      this.inpPhoneValue = value;
      this.showPartySuggestionsList = event?.showPartySuggestionsList === false ? false : value?.length ? true : false;
      this.filterPartyList = this.partyListComponent.completeList.filter(x => Utility.isNumber(x?.phone) ? x?.phone?.toString()?.includes(value) : x?.phone?.includes(value));
      this.filterPartyList?.sort((a: Party, b: Party) => {
        if (a?.phone == b?.phone) {
          return a.createdStamp - b.createdStamp;
        }
        if (b?.phone > a?.phone) {
          return 1;
        }
        return -1;
      });
      if(!value) {
        this.isValidPhoneNumberOnSave = null;
      } else {
        this.isValidPhoneNumberOnSave = true;
      }
      if(value) {
        let searchedParty = this.partyListComponent?.completeList?.filter(party => party.phone==value)[0];
        if(searchedParty) {
          const defaultDate = new Date(searchedParty?.dateOfBirth);
          this.selectedParty = searchedParty;
          this.secondaryPartyForm.patchValue({
            secondaryPartyName: searchedParty.name,
            secondaryPartyAddress: searchedParty.billingAddress || '',
          });
          if(searchedParty?.dateOfBirth){
            this.dpDobValue = Utility.ionDatePickerFormattedString(
              searchedParty?.dateOfBirth
            );
            this.secondaryPartyForm.patchValue({
              secondaryPartyDOB: searchedParty?.dateOfBirth,
            })
          }else{
            this.secondaryPartyForm.patchValue({
              secondaryPartyDOB: "",
            })
          }
        }else {
          this.lastActiveStr = null;
          this.selectedParty = null;
          this.secondaryPartyForm.patchValue({
            secondaryPartyName: null,
            secondaryPartyAddress: null,
            secondaryPartyDOB: null,
          });
        }
  
        this.isValidPhoneNumber = Utility?.checkValidPhoneNumber(value);
  
        if((this.dSetDiscountStatusI || this.dSetDiscountStatusII) && this.isValidPhoneNumber) {
          if(this.selectedParty) {
            this.secondaryDiscount.emit(this.selectedParty);
          } else {
            let party = new Party();
            party.phone = value;
            this.secondaryDiscount.emit(party);
          }
        } else {
          this.secondaryDiscount.emit(null);
        }
  
      }
    } catch (error) {
      SentryUtilites.setLog("PartySelectorComponent:onSecondaryPhoneChange", error)
    }
  }

  /**
   * 
   * @param phone : provide phone number
   * @description : select party and populates it data in parcel page
   */
  setSecondaryPartyPhone(phone: string) {
    try {
      this.secondaryPartyForm?.patchValue({
        secondaryPartyPhone: phone
      })
      this.showPartySuggestionsList = false;
  
      let obj = {
        detail: {
          target: {
            value: phone
          }
        },
        showPartySuggestionsList: false
      }
      this.onSecondaryPhoneChange(obj);
    } catch (error) {
      SentryUtilites.setLog("PartySelectorComponent:setSecondaryPartyPhone", error)
    }
  }
  // --------------------------------

  lastVisitedCustomer(isCustomerRepeated: boolean) {
    try {
      if((this.dSetDiscountOfferTypeI == 1 || this.dSetDiscountOfferTypeII == 1) && isCustomerRepeated) {
        if(this.selectedParty?.lastSaleStamp) {
          this.lastActiveStr = Utility.countDays(this.selectedParty?.lastSaleStamp);
        }
      } else {
        this.lastActiveStr = null;
      }
    } catch (error) {
      SentryUtilites.setLog("PartySelectorComponent:lastVisitedCustomer", error)
    }
  }

  emitParty() {
    try {
      this.isPartySelected = true;
      this.partySelectedEvent.emit(this.selectedParty);
      this.openPartySelectorModal(false);
    } catch (error) {
      SentryUtilites.setLog("PartySelectorComponent:emitParty", error)
    }
  }

  async emitSave(type: 'OnlySave' | 'SavePrint') {
    try {
      this.showPartySuggestionsList = false;
      this.isValidPhoneNumberOnSave = this.isValidPhoneNumber;
      if(!this.cartLength) {
        this.showToast('Please select item', 'danger');
        return;
      }
      if(!this.isValidPhoneNumber && this.inpPhoneValue?.length) {
        this.showSecondaryPartyAlert = true;
        return;
      }
      if(this.secondaryPartyForm?.valid && !this.selectedParty) {
        if(this.secondaryPartyForm?.value?.secondaryPartyPhone) {
          let party = await this.saveParty({
            partyName: this.secondaryPartyForm.value?.secondaryPartyName,
            partyPhone: this.secondaryPartyForm.value?.secondaryPartyPhone,
            address: this.secondaryPartyForm.value?.secondaryPartyAddress,
          });
          if(this.secondaryPartyForm.value?.secondaryPartyDOB){
            let modifiedParty = { ...party };
            modifiedParty.dateOfBirth = +new Date(this.secondaryPartyForm.value?.secondaryPartyDOB);
            this.selectedParty = modifiedParty;
          }else{
            this.selectedParty = party
          }
        }else {
          let newPartyWithOutPhone = new Party();
          newPartyWithOutPhone.name = this.secondaryPartyForm?.value?.secondaryPartyName;
          newPartyWithOutPhone.billingAddress = this.secondaryPartyForm?.value?.secondaryPartyAddress;
          newPartyWithOutPhone.dateOfBirth = +new Date(this.secondaryPartyForm?.value?.secondaryPartyDOB);
          this.selectedParty = newPartyWithOutPhone;
        }
      }
      if(
        this.selectedParty
        && (this.selectedParty?.name != this.secondaryPartyForm.value?.secondaryPartyName
        || this.selectedParty?.billingAddress != this.secondaryPartyForm.value?.secondaryPartyAddress)
      ) {
        this.selectedParty.name = this.secondaryPartyForm.value?.secondaryPartyName || this.selectedParty?.name;
        this.selectedParty.billingAddress = this.secondaryPartyForm.value?.secondaryPartyAddress;
        this.selectedParty = await this.allDataService?.partyService?.update(this.selectedParty);
      }
  
      if(this.secondaryPartyForm?.value?.secondaryPartyDOB){
        let dateOfBirth = +new Date(this.secondaryPartyForm?.value?.secondaryPartyDOB);
        this.selectedParty.dateOfBirth = new Date(dateOfBirth).getTime();
        this.selectedParty = await this.allDataService?.partyService?.update(this.selectedParty);
      }
  
      this.isPartySelected = true;
      this.openPartySelectorModal(false);
      if((type == 'OnlySave' || type == 'SavePrint') && this.isStaffAssignToSale) {
        this.selectedStaff.emit({userId: this.secondaryPartyForm?.value?.secondaryPartyAssignStaff?.userId, name: this.secondaryPartyForm?.value?.secondaryPartyAssignStaff?.name});
      }
      if(type == 'OnlySave') {
        this.secondaryPartyOnlySaveEvent.emit(this.selectedParty);
      }
      if(type == 'SavePrint') {
        this.secondaryPartySavePrintEvent.emit(this.selectedParty);
      }
    } catch (error) {
      SentryUtilites.setLog("PartySelectorComponent:emitSave", error)
    }
  }

  /**
   * 
   * @returns : return predefined ionic color value on save form if isValidPhoneNumberOnSave condition match
   */
  phoneNumberColorOnSave() {
    try {
      if(this.isValidPhoneNumberOnSave === true) {
        return 'tertiary';
      } else if (this.isValidPhoneNumberOnSave === false) {
        return 'danger';
      } 
      return '';
    } catch (error) {
      SentryUtilites.setLog("PartySelectorComponent:phoneNumberColorOnSave", error)
      return '';
    }
  }
  // -----------------------------------------

  /**
   * 
   * @param message : provide text message
   * @param color : provide ionic define color name
   * @description : show toast
   */
  async showToast(message: string, color: string) {
    try {
      const toast = await this.toastController.create({
        message,
        duration: 2000,
        position: 'top',
        mode: 'ios',
        color,
      });
      await toast.present();
    } catch (error) {
      SentryUtilites.setLog("PartySelectorComponent:showToast", error)
    }
  }
  // ---------------------------------------

  /**
   * @description : close secondary party alert
   */
  closeSecondaryPartyAlert() {
    this.showSecondaryPartyAlert = false;
  }
  // ---------------------------------------

}
