import { Component, OnInit, ViewChild } from '@angular/core';
import { IngredientFormComponent } from '../../../components/ingredient-form/ingredient-form.component';
import { Utility } from '../../../utils/utility';
import { ToastController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { Ingredient } from '../../../models/Ingredient.model';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-ingredient-form-page',
  templateUrl: './ingredient-form.page.html',
  styleUrls: ['./ingredient-form.page.scss'],
})
export class IngredientFormPage implements OnInit {

  @ViewChild('ingredientFormEle') ingredientFormEle: IngredientFormComponent;
  
  getHeaderColorClass = Utility.getHeaderColorClass;

  paramDocumentId: string = null;

  constructor(
    private toastController: ToastController,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.getParamDocumentId();
  }

  getParamDocumentId() {
    this.paramDocumentId = this.route.snapshot.paramMap.get('documentId');
  }

  saveTrigger(ingredient: Ingredient) {
    this.presentSuccessToast("Ingredient Saved Successfully");
  }

  updateTrigger(ingredient: Ingredient) {
    this.presentSuccessToast("Ingredient Updated Successfully");
  }

  async presentSuccessToast(header: string) {
    try {
      const toast = await this.toastController.create({
        header,
        duration: 2000,
        position: 'top',
        mode: 'ios',
        color: 'success',
      });
      await toast.present();
      this.router.navigate(['ingredient']);
    } catch (error) {
      SentryUtilites.setLog("IngredientFormPage:presentSuccessToast", error)
    }
  }

}
