import { BehaviorSubject } from "rxjs";
import PartyItemPriceMap from "../models/PartyItemPriceMap.model";
import { Utility } from "../utils/utility";
import { ExpressServerService } from "./api/express-server.service";
import { IDataService } from "../../interface/IDataService.interface";
import { PartyItemPriceMapDao } from "./dao/party-item-price-map.dao";
import { AuthService } from "./auth/auth.service";
import { SentryUtilites } from "../utils/sentryUtilites";

export class PartyItemPriceMapService implements IDataService<PartyItemPriceMap>{

  private static _instance: PartyItemPriceMapService;

  public static getInstance(dao: PartyItemPriceMapDao, expressServerService: ExpressServerService, authService: AuthService) {
    if (!this._instance) {
      this._instance = new PartyItemPriceMapService(dao, expressServerService,authService)
      this._instance.initService();
    }
    this._instance.reloadList();
    return this._instance;
  }

  constructor(dao: PartyItemPriceMapDao, expressServerService: ExpressServerService, authService: AuthService) {
    this.dao = dao;
    this.expressServerService = expressServerService;
    this.authService = authService;
  }
  dao: PartyItemPriceMapDao;
  expressServerService: ExpressServerService;
  authService: AuthService;

  LIST_REFRESH_RATE = 1000;
  selectedProfileId: string = null;
  selectedProfileUserId: string = null;
  updateSubs = new BehaviorSubject<PartyItemPriceMap>(null);

  lastReloadStamp: number = 0;
  isReloadPostpond = false;

  initService() {
    this.selectedProfileId = Utility.getFromLocalStorage('selectedProfile');
    this.selectedProfileUserId = Utility.getFromLocalStorage('selectedProfileUserId');
    this.reloadList();
  }


  async reloadList() {
    try {
      if (this.isReloadPostpond) {
        return;
      }
      const currentStamp = +new Date();
      if (this.lastReloadStamp < (currentStamp - this.LIST_REFRESH_RATE)) {
        this.lastReloadStamp = currentStamp;
        this.trySyncUnsynced();
      } else {
        this.isReloadPostpond = true;
        setTimeout(() => {
          this.isReloadPostpond = false;
          this.reloadList();
        }, this.LIST_REFRESH_RATE + 100);
      }
    } catch (error) {
      SentryUtilites.setLog("PartyItemPriceMapService:reloadList", error)
      return null;
    }
  }

  getAllByPromise() {
    return this.dao.getAllByProfile(this.selectedProfileId);
  }

  getAllByPromiseByProfile(profileId: string) {
    return this.dao.getAllByProfile(profileId);
  }

  getById(id: number): Promise<PartyItemPriceMap> {
    return this.dao.getById(id);
  }

  getByUUID(uuid: string): Promise<PartyItemPriceMap> {
    return this.dao.getByUUID(uuid);
  }

  getByPartyUUIDItemUUID(partyUUID: string,itemUUID: string) {
    return this.dao.getByPartyUUIDItemUUID(this.selectedProfileId,partyUUID,itemUUID);
  }


  save(partyItemPriceMap: PartyItemPriceMap): Promise<PartyItemPriceMap> {
    if(Utility.isTruthy(partyItemPriceMap)) {
      partyItemPriceMap.userId = this.selectedProfileUserId;
      if(!partyItemPriceMap?.profileId) {
        partyItemPriceMap.profileId = this.selectedProfileId;
      }
      partyItemPriceMap.createdBy = partyItemPriceMap.lastModifiedBy = this.authService.getLoginPhone();
      partyItemPriceMap.createdByName = partyItemPriceMap.lastModifiedByName = Utility.getCreatedByName();
  
      return new Promise(async (resolve, reject) => {
        try {
          let savedItem = await this.dao.save(partyItemPriceMap);
          this.reloadList();
          return resolve(savedItem);
        } catch (error) {
          SentryUtilites.setLog("PartyItemPriceMapService:save", error)
          return resolve(null);
        }
      });
    } else {
      return null;
    }
  }

  update(partyItemPriceMap: PartyItemPriceMap): Promise<PartyItemPriceMap> {
    return new Promise(async (resolve, reject) => {
      try {
        if(partyItemPriceMap?._localUUID) {
          partyItemPriceMap.lastModifiedBy = this.authService.getLoginPhone();
          partyItemPriceMap.lastModifiedByName = Utility.getCreatedByName();
          let updatedItem = await this.dao.update(partyItemPriceMap);
          this.reloadList();
          this.updateSubs.next(updatedItem);
          return resolve(updatedItem);
        }
        return resolve(null);
      } catch (error) {
        SentryUtilites.setLog("PartyItemPriceMapService:update", error)
        return resolve(null);
      }
    });
  }

  delete(partyItemPriceMap: PartyItemPriceMap): Promise<PartyItemPriceMap> {
    return new Promise(async (resolve, reject) => {
      try {
        if(partyItemPriceMap?._localUUID) {
          partyItemPriceMap.lastModifiedBy = this.authService.getLoginPhone();
          partyItemPriceMap.lastModifiedByName = Utility.getCreatedByName();
          let deletedItem = await this.dao.delete(partyItemPriceMap);
          this.reloadList();
          this.updateSubs.next(deletedItem);
          return resolve(deletedItem);
        }
        return resolve(null);
      } catch (error) {
        SentryUtilites.setLog("PartyItemPriceMapService:delete", error)
        return resolve(null);
      }
    });
  }

  updateSyncStamp(el: PartyItemPriceMap): Promise<PartyItemPriceMap> {
    return new Promise(async (resolve, reject) => {
      try {
        let updatedEl = await this.dao.updateSyncStamp(el);
        this.updateSubs.next(updatedEl);
        return resolve(updatedEl);
      } catch (error) {
        SentryUtilites.setLog("PartyItemPriceMapService:updateSyncStamp", error)
        return resolve(null);
      }
    });
  }

  isSyncLock = false;
  isSyncPostPond = false;
  async trySyncUnsynced(postpond?: boolean) {
    try {
      if (this.isSyncLock) {
        if (!this.isSyncPostPond) {
          setTimeout(() => {
            this.isSyncPostPond = true;
            this.trySyncUnsynced(true);
          }, 200);
        }
        return true;
      }
      if (postpond) {
        this.isSyncPostPond = false;
      }
      this.isSyncLock = true;
      let unSyncedElements: PartyItemPriceMap[] = await this.dao.getAllUnsynced(this.selectedProfileId);
      if (unSyncedElements && unSyncedElements?.length) {
        try {
  
          for (let i = 0; i < unSyncedElements?.length; i++) {
            let unSyncedElement = unSyncedElements[i];
            if(unSyncedElement?._localUUID) {
              unSyncedElement['updatedStamp'] = +new Date();
            }
          }
  
          await this.dao.bulkPut(unSyncedElements);
          await Utility.wait(1000);
          
          let chunkArr = Utility.getChunkArr(unSyncedElements);
          let chunkArrLength = chunkArr?.length;
  
          for(let i = 0; i < chunkArrLength; i++) {
            let result = await this.expressServerService.makeSyncCall('partyItemPriceMap', chunkArr[i]);
            if (result && result?.['records']?.length) {
              let arr = result?.['records'];
              for (let i = 0; i < arr?.length; i++) {
                const el = arr[i];
                await this.updateSyncStamp(el);
              }
            }
          }
        } catch (err) {
          SentryUtilites.setLog("PartyItemPriceMapService:trySyncUnsynced:inner", err)
        }
      }
      this.isSyncLock = false;
    } catch (error) {
      SentryUtilites.setLog("PartyItemPriceMapService:trySyncUnsynced", error)
    }
  }

  copyData(fromProfileId: string, toProfileId: string): Promise<boolean> {
    return new Promise(async (resolve,reject) => {
      try {
        if(Utility.isTruthy(fromProfileId) && Utility.isTruthy(toProfileId)) {
          let fromRecords = await this.getAllByPromiseByProfile(fromProfileId);
          if(fromRecords?.length) {
            let toRecords: PartyItemPriceMap[] = [];
            for (let i = 0; i < fromRecords?.length; i++) {
              const fetchedRecord = fromRecords[i];
              if(fetchedRecord?._localUUID) {
                fetchedRecord.profileId = toProfileId;
                delete fetchedRecord?._localId;
                delete fetchedRecord?._localUUID;
                let savedRecord = await this.save(fetchedRecord);
                if(savedRecord?._localUUID) {
                  toRecords?.push(savedRecord);
                }
              }
            }
            if(fromRecords?.length === toRecords?.length) {
              return resolve(true);
            }
          }
        }
        return resolve(false);
      } catch (error) {
        SentryUtilites.setLog("PartyItemPriceMapService:copyData", error)
        return resolve(false);
      }
    });
  }

}

