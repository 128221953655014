import * as Sentry from "@sentry/angular";

export class SentryUtilites {

    /**
     * 
     * @param {string} tag provide tag name
     * @param {any} error provide error object
     */
    static setLog(tag: string, error: any) {
        Sentry.getCurrentScope().setTransactionName(tag)
        Sentry.captureException(error);
        console.log(tag, error)
    }
    // ---------------------------------------

}