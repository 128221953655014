import { Injectable } from '@angular/core';
import { ModalController, Platform, ToastController } from '@ionic/angular';
import * as platform from 'platform';
import { SentryUtilites } from '../utils/sentryUtilites';

@Injectable({
  providedIn: 'root',
})
export class CommonService {

  constructor(
    private platform: Platform,
    private toastController: ToastController,
    private modalCtrl: ModalController,
    ) {}

  isMobile() {
    try {
      if (
        this.platform?.is('ios') ||
        this.platform?.is('ipad') ||
        this.platform?.is('iphone') ||
        this.platform?.is('android') ||
        this.platform?.is('mobile') ||
        this.platform?.is('phablet') ||
        this.platform?.is('cordova')
      ) {
        return true;
      } else if (this.platform?.is('desktop') || this.platform?.is('mobileweb')) {
        return false;
      }
    } catch (error) {
      SentryUtilites.setLog("CommonService:isMobile", error)
      return false;
    }
  }

  getDevice() {
    return `${platform?.name} - ${window?.navigator?.['platform']} - ${this.isMobile()? 'Mobile' : 'Desktop'}`;
  }

  /**
   * 
   * @param color : provide ionic toast color
   * @param message : provide message to show
   */
  async generateToast(
    color: string,
    message: string
  ) {
    try {
      const toast = await this.toastController.create({
        header: message,
        duration: 2000,
        position: 'top',
        mode: 'ios',
        color,
      });
      await toast.present();
    } catch (error) {
      SentryUtilites.setLog("CommonService:isMobile", error)
      return false;
    }
  }
  // -----------------------------------

  /**
   * 
   * @description : close open ion modal
   */
  async closeModal() {
    // close modal
    try {
      let element = await this.modalCtrl.getTop();
      if (element) {
        await element?.dismiss();
        this.closeModal();
      }
    } catch (error) {
      SentryUtilites.setLog("CommonService:closeModal", error)
    }

  }
  // -----------------------------------
  
}
