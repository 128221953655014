import { Injectable } from "@angular/core";
import { MonthWisePartyCredit } from "../models/MonthWisePartyCredit.model";
import { MonthWisePartyCreditDao } from "./dao/month-wise-party-credit.dao";
import { Utility } from "../utils/utility";
import { SentryUtilites } from "../utils/sentryUtilites";

@Injectable({
    providedIn: 'root',
})
export class MonthWisePartyCreditService {

    constructor(
        private dao: MonthWisePartyCreditDao,
    ) { }

    /**
     * 
     * @returns : return all monthWisePartyCredit array of object from monthWisePartyCredit dao
     */
    getAll(): Promise<MonthWisePartyCredit[]> {
        return this.dao.getAll();
    }
    // -----------------------------------------------------------

    /**
     * 
     * @param partyLocalUUID : provide party _localUUID
     * @returns : return monthWisePartyCredit object from monthWisePartyCredit dao
     */
    getByPartyLocalUUID(partyLocalUUID: string): Promise<MonthWisePartyCredit> {
        return this.dao.getByPartyLocalUUID(partyLocalUUID);
    }
    // ------------------------------------------------------------------------------------

    /**
     * 
     * @param partyLocalUUID : provide party _localUUID
     * @returns : return saved monthWisePartyCredit object from monthWisePartyCredit dao
     */
    save(partyLocalUUID: string): Promise<MonthWisePartyCredit> {
        return new Promise(async (resolve, reject) => {
            try {
                let savedMonthWisePartyCredit = await this.dao.save(partyLocalUUID);
                return resolve(savedMonthWisePartyCredit);
            } catch (err) {
                SentryUtilites.setLog("MonthWisePartyCreditService:save", err)
                return resolve(null);
            }
        });
    }
    // ------------------------------------------------------------------------------------------

    /**
     * 
     * @param monthWisePartyCredits : provide monthWisePartyCredit array of object
     * @returns : return true if successfully execute code from monthWisePartyCredit dao
     */
    bulkAdd(monthWisePartyCredits: MonthWisePartyCredit[]): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            try {
                let isSuccess = await this.dao.bulkAdd(monthWisePartyCredits);
                return resolve(isSuccess);
            } catch (err) {
                SentryUtilites.setLog("MonthWisePartyCreditService:bulkAdd", err)
                return resolve(false);
            }
        })
    }
    // -------------------------------------------------------------------------------------

    /**
     * 
     * @param monthWisePartyCredit : provide monthWisePartyCredit object
     * @returns : return updated monthWisePartyCredit object from monthWisePartyCredit dao
     */
    update(monthWisePartyCredit: MonthWisePartyCredit): Promise<MonthWisePartyCredit> {
        return new Promise(async (resolve, reject) => {
            try {
                let updatedMonthWisePartyCredit = await this.dao.update(monthWisePartyCredit);
                return resolve(updatedMonthWisePartyCredit)
            } catch (err) {
                SentryUtilites.setLog("MonthWisePartyCreditService:update", err)
                return resolve(null)
            }
        });
    }
    // -------------------------------------------------------------------------------------------------

    /**
     * 
     * @param monthWisePartyCredits : provide monthWisePartyCredit array of object
     * @returns : return true if successfully execute code from monthWisePartyCredit dao
     */
    bulkPut(monthWisePartyCredits: MonthWisePartyCredit[]): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            try {
                let isSuccess = await this.dao.bulkPut(monthWisePartyCredits);
                return resolve(isSuccess);
            } catch (err) {
                SentryUtilites.setLog("MonthWisePartyCreditService:bulkPut", err)
                return resolve(false);
            }
        })
    }
    // -------------------------------------------------------------------------------------

    /**
     * 
     * @param partyLocalUUID : provide party _localUUID
     * @returns : return credit value from monthWisePartyCredit ledger
     */
    calculateCredit(partyLocalUUID: string): Promise<number> {
        return new Promise(async (resolve, reject) => {
            try {
                let monthWisePartyCreditOFParty = await this.getByPartyLocalUUID(partyLocalUUID);
                let partyLedger = monthWisePartyCreditOFParty?.ledger;
                let credit = 0;
                if (partyLedger && Object.keys(partyLedger)?.length) {
                    for (let prop in partyLedger) {
                        credit += partyLedger[prop];
                    }
                }
                return resolve(credit);
            } catch (err) {
                SentryUtilites.setLog("MonthWisePartyCreditService:calculateCredit", err)
                return resolve(null);
            }
        })
    }
    // --------------------------------------------------------------------------------------------------

    /**
     * 
     * @param partyLocalUUID : provide party _localUUID
     * @returns : return monthWisePartyCredit object 
     */
    getLedgerCredit(partyLocalUUID: string): Promise<number> {
        return new Promise(async (resolve, reject) => {
            try {
                let monthWisePartyCredit= await this.getByPartyLocalUUID(partyLocalUUID);
                return resolve(monthWisePartyCredit?.ledgerCredit || 0);
            } catch (error) {
                SentryUtilites.setLog("MonthWisePartyCreditService:getLedgerCredit", error)
                return resolve(null);
            }
        })
    }
    // --------------------------------------------------------------------------------------------------

    /**
     * 
     * @returns : return ledgerCredit HashMap
     */
    getLedgerCreditHashMap(): Promise<{[key:string]:number}> {
        return new Promise(async (resolve, reject) => {
            try {
                let monthWisePartyCredits = await this.getAll();
                const ledgerCreditHashMap = {};
                monthWisePartyCredits?.forEach(monthWisePartyCredit => {
                    ledgerCreditHashMap[monthWisePartyCredit?.partyLocalUUID] = monthWisePartyCredit?.ledgerCredit;
                });
                return resolve(ledgerCreditHashMap);
            } catch (error) {
                SentryUtilites.setLog("MonthWisePartyCreditService:getLedgerCreditHashMap", error)
                return resolve(null);
            }
        })
    }
    // --------------------------------------------------------------------------------------------------

    /**
     * 
     * @param partyLocalUUID : provide party localUUID
     * @param billDateStamp : provide billDateStamp
     * @param creditIncrementBy : creditIncrementBy
     * @returns : return true if successfully execute code
     */
    modifyCredit(
        partyLocalUUID: string,
        billDateStamp: number,
        creditIncrementBy: number
    ): Promise<boolean> {
        return new Promise(async (resolve, rejects) => {
            try {
                let isSuccess = this.dao.modifyCredit(partyLocalUUID, billDateStamp, creditIncrementBy);
                return resolve(isSuccess);
            } catch (err) {
                SentryUtilites.setLog("MonthWisePartyCreditService:modifyCredit", err)
                return resolve(false);
            }
        })
    }
    // ------------------------------------------------------------
    
}
