import { ToastController } from '@ionic/angular';
import { MoneyIn } from './../../../models/MoneyIn.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Utility } from 'src/app/utils/utility';

@Component({
  selector: 'app-money-in-form-page',
  templateUrl: './money-in-form.page.html',
  styleUrls: ['./money-in-form.page.scss'],
})
export class MoneyInFormPage implements OnInit, AfterViewInit {

  getHeaderColorClass = Utility.getHeaderColorClass;
  
  paramDocumentId: string = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toastController: ToastController,
  ) { }

  ngOnInit() {
    this.getParamDocumentId();
  }

  ngAfterViewInit() { }

  ngDestory() { }

  getParamDocumentId() {
    this.paramDocumentId = this.route.snapshot.paramMap.get('documentId');
  }

  async savedMoneyIn(moneyIn: MoneyIn) {
    if (moneyIn?._localUUID) {
      const toast = await this.toastController.create({
        header: "Money In Saved Successfully",
        duration: 2000,
        position: 'top',
        mode: 'ios',
        color: 'success',
      });
      await toast.present();
      this.router.navigate(['money-in']);
    }
  }

  async updatedMoneyIn(moneyIn: MoneyIn) {
    if (moneyIn?._localUUID) {
      const toast = await this.toastController.create({
        header: "Money In Updated Successfully",
        duration: 2000,
        position: 'top',
        mode: 'ios',
        color: 'success',
      });
      await toast.present();
      this.router.navigate(['money-in']);
    }
  }

}
