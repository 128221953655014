import { Subscription } from 'rxjs';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IonInput, ToastController } from '@ionic/angular';
import { ItemSelectorComponent } from '../../../components/item-selector/item-selector.component';
import { PartySelectorComponent } from '../../../components/party-selector/party-selector.component';
import { TransportDetailsComponent } from '../../../components/transport-details/transport-details.component';
import { BillItem } from '../../../models/BillItem.model';
import { MoneyOut } from '../../../models/MoneyOut.model';
import Party from '../../../models/Party.model';
import { Purchase } from '../../../models/Purchase.model';
import { AllDataService } from '../../../services/all-data.service';
import { Utility } from '../../../utils/utility';
import { Utils } from '../../../utils/utils';
import { CalculateBillTask } from '../../../utils/enums/CalculateBillTask';
import { BillCalculations } from '../../../utils/BillCalculations';
import { BillType } from '../../../utils/enums/BillType';
import { Profile } from '../../../models/Profile.model';
import { ItemRestaurantSelectorComponent } from '../../../components/item-restaurant-selector/item-restaurant-selector.component';
import { MoneyOutSelectorComponent } from '../../../components/money-out-selector/money-out-selector.component';
import { SettingsComponent } from '../../../components/settings/settings.component';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-purchase-form',
  templateUrl: './purchase-form.page.html',
  styleUrls: ['./purchase-form.page.scss'],
})
export class PurchaseFormPage implements OnInit {
  /*
    Native Element Ref
  */

  @ViewChild('partySelector', { static: false }) partySelector: PartySelectorComponent;
  @ViewChild('partyRestaurantSelector', { static: false }) partyRestaurantSelector: PartySelectorComponent;
  @ViewChild('itemSelector', { static: false }) itemSelector: ItemSelectorComponent;
  @ViewChild('itemRestaurantSelector', { static: false }) itemRestaurantSelector: ItemRestaurantSelectorComponent;
  @ViewChild('moneyOutSelector', { static: false }) moneyOutSelector: MoneyOutSelectorComponent;
  @ViewChild('settingsModal') settingsModal: SettingsComponent;
  @ViewChild('amountPaidEle') amountPaidEle: IonInput;
  @ViewChild('paymentIdEle') paymentIdEle: IonInput;

  getHeaderColorClass = Utility.getHeaderColorClass;
  maxDate = Utility.maxDateUpto50Year();
  states = Utility.states;
  setDateValue = Utility.setDateValue;
  isDisabledState = Utility.isDisabledState;

  /*
     Reactive Form
    */

  form: FormGroup;

  barcodeScannerMode = false;

  dpBillDateValue: string = null;
  dpEWayBillDateValue: string = null;
  dpDeliveryDateValue: string = null;
  minDateTransport: string = Utility.ionDatePickerFormattedString(+new Date().setHours(0, 0, 0, 0));

  showTransportDetailsCard = false;
  paramDocumentId: string = null;
  fetchedPurchase: Purchase = null;

  amountPaidChecked: boolean = null;
  isDisableAmountPaid = false;

  selectedProfile: Profile = null;

  paymentMode: string = 'cash';

  listStyleView = false;

  noPartySelectError = false;

  iSetItemPriceHistoryStatus = false;

  iSetOutOfStockHideStatus = false;

  isOpenBillDatePicker: boolean = false;
  isOpenEwayBillDatePicker: boolean = false;
  isOpenDeliveryDatePicker: boolean = false;
  keepContentsMounted: boolean = false;

  todayDate: number = +new Date().setHours(0, 0, 0, 0);

  isNgOnInitRun: boolean = false;

  subArr: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private toastController: ToastController,
    private allDataService: AllDataService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  /*
      Life Cycle Hooks
    */

  ngOnInit() {
    try {
      this.isNgOnInitRun = true;
      this.getParamDocumentId();
      this.initializeReactiveForm();
      this.populateBarcodeScannerSetting();
      this.subArr.push(this.allDataService.listForceReloadSubs.subscribe((listName: string) => {
        if (listName == 'profile-list') {
          this.getSelectedProfile();
        }
      }));
    } catch (error) {
      SentryUtilites.setLog("PurchaseFormPage:ngOnInit", error)
    }
  }

  async ngAfterViewInit() {
    try {
      await this.getSelectedProfile();
      setTimeout(() => {
        this.populateForm();
      }, 500);
    } catch (error) {
      SentryUtilites.setLog("PurchaseFormPage:ngAfterViewInit", error)
    }
  }

  ionViewWillEnter() {
    if(!this.isNgOnInitRun) {
      this.ngOnInit();
      this.ngAfterViewInit();
    }
  }

  ionViewWillLeave() {
    try {
      this.paramDocumentId = null;
      this.resetBillForm();
    } catch (error) {
      SentryUtilites.setLog("PurchaseFormPage:addCationViewWillLeaveegory", error)
    }
  }

  onSettingSave() {
    this.populateBarcodeScannerSetting();
  }

  getParamDocumentId() {
    this.paramDocumentId = this.route.snapshot.paramMap.get('documentId');
  }

  populateSettings() {
    try {
      this.listStyleView = this.selectedProfile?.iSetItemSelectorStyleWeb === 'List';
      if(this.amountPaidChecked === null) {
        this.amountPaidChecked = this.selectedProfile?.iSetAutoMoneyInStatus;
      }
      this.iSetItemPriceHistoryStatus = this.selectedProfile?.iSetItemPriceHistoryStatus;
      this.iSetOutOfStockHideStatus = this.selectedProfile?.iSetOutOfStockHideStatus;
      if (this.selectedProfile?.iSetServiceChargePercentage) {
        this.form?.patchValue({
          serviceChargePercentage: this.selectedProfile?.iSetServiceChargePercentage,
        });
      }
    } catch (error) {
      SentryUtilites.setLog("PurchaseFormPage:populateSettings", error)
    }
  }

  // Fetch selected profile
  async getSelectedProfile() {
    try {
      this.selectedProfile =
        await this.allDataService.profileService.getCurrentProfile();
      this.populateSettings();
      return true;
    } catch (error) {
      SentryUtilites.setLog("PurchaseFormPage:getSelectedProfile", error)
      return false;
    }
  }

  async populateForm() {
    try {
      if (this.paramDocumentId) {
        this.fetchedPurchase = await this.allDataService.purchaseService.getByUUID(
          this.paramDocumentId
        );
        if(this.fetchedPurchase?._localUUID) {
          if (!this.fetchedPurchase?.deletedStamp) {
            this.form.patchValue({ ...this.fetchedPurchase });
            this.dpBillDateValue = Utility.ionDatePickerFormattedString(this.fetchedPurchase?.billDateStamp);
    
            if (this.listStyleView) {
              this.partySelector?.setSelectedParty(this.fetchedPurchase?.party);
              this.paramDocumentId && this.partySelector?.disable();
            } else {
              this.partyRestaurantSelector?.setSelectedParty(this.fetchedPurchase?.party);
              this.paramDocumentId && this.partyRestaurantSelector?.disable();
            }
    
            this.itemSelector?.setBillItems(this.fetchedPurchase?.billItems);
            this.itemRestaurantSelector?.setBillItems(this.fetchedPurchase?.billItems);
            this.itemRestaurantSelector?.openItemSelectorModal();
    
            this.populateTransportDetails(this.fetchedPurchase?.transportDetail);
            // this.setTransportDetailsCardVisibiltity();
    
            this.disableAmountPaid();
            if (this.fetchedPurchase?.moneyOuts?.length) {
              this.paymentMode = this.fetchedPurchase?.moneyOuts[0]?.paymentMode;
              setTimeout(() => {
                if (this.paymentIdEle) {
                  (this.paymentIdEle as any)['el'].value =
                    this.fetchedPurchase?.moneyOuts[0]?.paymentId;
                  this.paymentIdEle.value = this.fetchedPurchase?.moneyOuts[0]?.paymentId;
                }
                if (this.itemRestaurantSelector) {
                  this.itemRestaurantSelector.paymentId =
                    this.fetchedPurchase?.moneyOuts[0]?.paymentId;
                }
              }, 1000);
            }
          }
    
          setTimeout(() => {
            this.form.patchValue({
              cashDiscount: this.fetchedPurchase?.cashDiscount,
              cashDiscountPercentage: this.fetchedPurchase?.cashDiscountPercentage,
            });
            this.recalculateBillItems()
          }, 1000);
        }
      } else {
        this.populateBillNumber();
        this.openPartySelectorModal();
      }
    } catch (error) {
      SentryUtilites.setLog("PurchaseFormPage:populateForm", error)
    }
  }

  //----------------------------------------------------------------------------

  /*
      Reactive Form Initialization
    */

  initializeReactiveForm() {
    try {
      this.form = this.formBuilder.group({
        billNo: ['', Validators.required],
        billDateStamp: [+new Date().setHours(0, 0, 0, 0), Validators.required],
        party: [null, Validators.required],
        billItems: [null],
        transportDetail: this.formBuilder.group({
          additionalAmount: [null],
          purOrderNo: [null],
          challanNo: [null],
          eWayBillNo: [null],
          eWayBillDate: [],
          transporterName: [null],
          vehicleNumber: [null],
          transDistance: [null, Validators.maxLength(5)],
          deliveryLocation: [null],
          deliveryDate: [],
          tnc: [null],
        }),
        gstAmount: [0],
        cessAmount: [0],
        discountAmount: [0],
        totalAmount: [null],
        amountPaid: [null],
        subTotalAmount: [null],
        note: [null],
        cashDiscount: [null],
        cashDiscountPercentage: [null],
        deliveryProvience: [null],
      });
      this.subArr.push(this.form.get('amountPaid').valueChanges.pipe(distinctUntilChanged(), debounceTime(300)).subscribe((amountPaid) => {
        if (+amountPaid && !this.amountPaidChecked) {
          this.amountPaidChecked = true;
        }
        if (amountPaid < 0) {
          this.form?.patchValue({ amountPaid: Math.abs(amountPaid) });
        }
        if (amountPaid > this.form?.value?.totalAmount) {
          this.form?.patchValue({
            amountPaid: this.form?.value?.totalAmount,
          });
        }
      }));
      this.subArr.push(this.form.get('totalAmount').valueChanges.pipe(distinctUntilChanged(), debounceTime(300)).subscribe((totalAmount) => {
        if (this.amountPaidChecked) {
          this.form?.patchValue({ amountPaid: totalAmount });
        }
      }));
      this.subArr.push(this.form.get("cashDiscountPercentage").valueChanges.pipe(distinctUntilChanged(), debounceTime(300)).subscribe(cashDiscountPercentage => {
        if(cashDiscountPercentage < 0 || cashDiscountPercentage > 100) {
          this.form.patchValue({cashDiscountPercentage: Math.abs(cashDiscountPercentage>100 ? 100 : cashDiscountPercentage)});
        }else if(cashDiscountPercentage!=null && !Number.isInteger(cashDiscountPercentage)) {
          let arr:string[] = (''+cashDiscountPercentage).split('.');
          if(arr[1]?.length > 3) {
            this.form.patchValue({cashDiscountPercentage: Number(arr[0]+'.'+arr[1].substr(0,3))});
          }
        }
      }));
    } catch (error) {
      SentryUtilites.setLog("PurchaseFormPage:initializeReactiveForm", error)
    }
  }

  //----------------------------------------------------------------------------

  openSettingsModal() {
    this.settingsModal?.openSettingsModal();
  }

  /*
      Sale Number
    */

  async populateBillNumber() {
    try {
      this.form.patchValue({
        billNo: await this.allDataService.purchaseService.getNewPurchaseNo(),
      });
    } catch (error) {
      SentryUtilites.setLog("PurchaseFormPage:populateBillNumber", error)
    }
  }

  //----------------------------------------------------------------------------

  /*
      PartyV0 Selector functons
    */

  openPartySelectorModal() {
    try {
      if (
        this.listStyleView &&
        this.partySelector &&
        !this.partySelector?.isPartySelected
      ) {
        this.partySelector?.openPartySelectorModal();
      } else {
        this.partySelector?.openPartySelectorModal(false);
      }
      if (
        !this.listStyleView &&
        this.partyRestaurantSelector &&
        !this.partyRestaurantSelector?.isPartySelected
      ) {
        this.partyRestaurantSelector?.openPartySelectorModal();
      } else {
        this.partyRestaurantSelector?.openPartySelectorModal(false);
      }
    } catch (error) {
      SentryUtilites.setLog("PurchaseFormPage:openPartySelectorModal", error)
    }
  }

  onPartySelect(party: Party) {
    try {
      if (party?._localUUID) {
        this.form.patchValue({ party });
        this.form.patchValue({
          deliveryProvience: party?.deliveryProvience || party?.billingProvience
        });
        this.itemRestaurantSelector?.openItemSelectorModal();
      }
    } catch (error) {
      SentryUtilites.setLog("PurchaseFormPage:onPartySelect", error)
    }
  }

  //----------------------------------------------------------------------------

  /*
      Sale Items
    */

  async populateBillItems(billItems: BillItem[]) {
    try {
      let totalAmount = 0;
      billItems?.forEach((x) => (totalAmount += x.total));
      if (this.itemRestaurantSelector?.amountReceivedChecked) {
        this.amountPaidChecked = true;
      }
      this.form.patchValue({
        billItems,
        cashDiscount: null,
        cashDiscountPercentage: null,
      });
      this.recalculateBillItems();
    } catch (error) {
      SentryUtilites.setLog("PurchaseFormPage:populateBillItems", error)
    }
  }

  //----------------------------------------------------------------------------

  onMoneyOutCartUpdate(moneyOutCart: MoneyOut[]) {
    try {
      let totalAmountPaid= 0;
      moneyOutCart?.forEach(
        (x) => (totalAmountPaid+= Number(x?.totalAmount))
      );
      this.form?.controls?.amountPaid?.setValue(totalAmountPaid);
    } catch (error) {
      SentryUtilites.setLog("PurchaseFormPage:onMoneyOutCartUpdate", error)
    }
  }

  /*
      Transport Details
    */

  populateTransportDetails(data: any) {
    try {
      this.form.patchValue({ transportDetail: data });
      if (data?.eWayBillDate) {
        this.dpEWayBillDateValue = Utility.ionDatePickerFormattedString(
          data?.eWayBillDate
        );
      }
      if (data?.deliveryDate) {
        this.dpDeliveryDateValue = Utility.ionDatePickerFormattedString(
          data?.deliveryDate
        );
      }
      // this.setTransportDetailsCardVisibiltity();
      this.calculateSubTotalAmount();
    } catch (error) {
      SentryUtilites.setLog("PurchaseFormPage:populateTransportDetails", error)
    }
  }

  setTransportDetailsCardVisibiltity() {
    try {
      let data = this.form.value.transportDetail;
      if (
        data?.additionalAmount ||
        data?.eWayBillNo ||
        data?.eWayBillDate ||
        data?.purOrderNo ||
        data?.challanNo ||
        data?.transporterName ||
        data?.vehicleNumber ||
        data?.deliveryDate ||
        data?.transDistance ||
        data?.deliveryLocation ||
        data?.tnc
      ) {
        this.showTransportDetailsCard = true;
      } else {
        this.showTransportDetailsCard = false;
      }
    } catch (error) {
      SentryUtilites.setLog("PurchaseFormPage:setTransportDetailsCardVisibiltity", error)
    }
  }

  //----------------------------------------------------------------------------

  /*
      Sale Total Amount
    */

  setTotalAmount(totalAmount: number) {
    this.form.patchValue({ totalAmount });
  }

  //----------------------------------------------------------------------------

  /*
      Sale Settings
    */

  async getBillSetting() {
    return null;
  }

  //----------------------------------------------------------------------------

  amountPaidSetBlur(keyboardEvent: any) {
    try {
      keyboardEvent = keyboardEvent as KeyboardEvent;
      keyboardEvent?.preventDefault();
      keyboardEvent?.target?.blur();
    } catch (error) {
      SentryUtilites.setLog("PurchaseFormPage:amountPaidSetBlur", error)
    }
  }

  setPaymentMode(event) {
    try {
      this.paymentMode = event?.detail?.value || 'cash';
      this.onItemSelectorPaymentModeChange(this.paymentMode);
    } catch (error) {
      SentryUtilites.setLog("PurchaseFormPage:setPaymentMode", error)
    }
  }

  onItemSelectorPaymentModeChange(paymentMode: string) {
    try {
      this.paymentMode = paymentMode;
      if (
        this.paramDocumentId &&
        this.fetchedPurchase?.moneyOuts?.length
      ) {
        this.fetchedPurchase.moneyOuts[0].paymentMode = paymentMode;
      }
    } catch (error) {
      SentryUtilites.setLog("PurchaseFormPage:onItemSelectorPaymentModeChange", error)
    }
  }

  /*
      Barcode Scanner
    */

  async populateBarcodeScannerSetting() {
    try {
      this.barcodeScannerMode = Utility.getFromLocalStorage(
        'item_barcode_scanner'
      );
    } catch (error) {
      SentryUtilites.setLog("PurchaseFormPage:populateBarcodeScannerSetting", error)
    }
  }

  async onBarcodeScannerModeChange(event: any) {
    try {
      this.barcodeScannerMode = event?.detail?.checked;
      // Utility.setToLocalStorage('item_barcode_scanner', this.barcodeScannerMode);
    } catch (error) {
      SentryUtilites.setLog("PurchaseFormPage:onBarcodeScannerModeChange", error)
    }
  }

  //----------------------------------------------------------------------------

  focusAmountPaid(event) {
    try {
      if (event) {
        this.amountPaidEle?.setFocus();
      }
    } catch (error) {
      SentryUtilites.setLog("PurchaseFormPage:focusAmountPaid", error)
    }
  }

  onAmountPaidCheckedChange(event: any) {
    try {
      if (this.amountPaidChecked != event?.detail?.checked) {
        this.amountPaidChecked = event?.detail?.checked;
        if (this.amountPaidChecked) {
          
          this.populateAmountPaid(
            Number(this.form?.value?.amountPaid) || this.form?.value?.totalAmount
          );
        } else {
          this.populateAmountPaid(null);
        }
      }
    } catch (error) {
      SentryUtilites.setLog("PurchaseFormPage:onAmountPaidCheckedChange", error)
    }
  }

  populateAmountPaid(amountPaid: number) {
    this.form.patchValue({ amountPaid });
  }

  disableAmountPaid() {
    try {
      if (this.fetchedPurchase?.moneyOuts?.length) {
        this.amountPaidChecked = true;
        this.isDisableAmountPaid = true;
        this.form.get('amountPaid')?.disable();
      }
    } catch (error) {
      SentryUtilites.setLog("PurchaseFormPage:disableAmountPaid", error)
    }
  }

  /**
   * 
   * @param event : date event
   * @description : bill date select assign date value
   */
  onBillDateSelect(event) {
    try {
      if (event?.detail?.value) {
        let timeStamp = +new Date(event?.detail?.value).setHours(0, 0, 0, 0);
        this.form?.controls?.billDateStamp?.setValue(timeStamp);
        this.dpBillDateValue = Utility.ionDatePickerFormattedString(timeStamp);
        Utility.closeDatePicker(event?.target);
      }
    } catch (error) {
      SentryUtilites.setLog("PurchaseFormPage:onBillDateSelect", error)
    }
  }
  // --------------------------------

  /**
   * 
   * @param event : date event
   * @description : E way bill date select assign date value
   */
  onEwayBillDateSelect(event) {
    try {
      if(event?.detail?.value) {
        let timeStamp = +new Date(event?.detail?.value).setHours(0, 0, 0, 0);
        this.form?.get('transportDetail.eWayBillDate')?.setValue(timeStamp);
        this.dpEWayBillDateValue = Utility.ionDatePickerFormattedString(timeStamp);
        Utility.closeDatePicker(event?.target);
      }
    } catch (error) {
      SentryUtilites.setLog("PurchaseFormPage:onEwayBillDateSelect", error)
    }
  }
  // --------------------------------

  /**
   * 
   * @param event : date event
   * @description : delivery date select assign date value
   */
  onDeliveryDateSelect(event) {
    try {
      if(event?.detail?.value) {
        let timeStamp = +new Date(event?.detail?.value).setHours(0, 0, 0, 0);
        this.form?.get('transportDetail.deliveryDate')?.setValue(timeStamp);
        this.dpDeliveryDateValue = Utility.ionDatePickerFormattedString(timeStamp);
        Utility.closeDatePicker(event?.target);
      }
    } catch (error) {
      SentryUtilites.setLog("PurchaseFormPage:onDeliveryDateSelect", error)
    }
  }
  // --------------------------------

  returnZero() {
    return 0;
  }

  setPaymentId(value) {
    try {
      if (this.paymentIdEle) {
        (this.paymentIdEle as any)['el'].value = value;
        if (
          this.paramDocumentId &&
          this.fetchedPurchase?.moneyOuts?.length
        ) {
          this.fetchedPurchase.moneyOuts[0].paymentId = value;
        }
      }
    } catch (error) {
      SentryUtilites.setLog("PurchaseFormPage:setPaymentId", error)
    }
  }

  onSaveClick() {
    try {
      if (this.paramDocumentId) {
        this.update();
      } else {
        this.save();
      }
    } catch (error) {
      SentryUtilites.setLog("PurchaseFormPage:onSaveClick", error)
    }
  }

  async addPurchase() {
    try {
      if (!this.form?.value?.party?._localUUID) {
        const toast = await this.toastController.create({
          header: 'Please Select Party to Save Bill',
          duration: 3000,
          position: 'top',
          mode: 'ios',
          color: 'danger',
        });
        await toast.present();
        this.noPartySelectError = true;
        return null;
      } else {
        this.noPartySelectError = false;
      }
  
      if (this.form.valid) {
        let purchase = new Purchase();
  
        purchase = { ...this.form.value };
  
        purchase.moneyOuts = [];
  
        if (Number(this.form?.value?.amountPaid)) {
          let moneyOut = new MoneyOut();
          moneyOut.party = this.form.value.party;
          moneyOut.billDateStamp = purchase?.billDateStamp;
          moneyOut.totalAmount = this.form.value.amountPaid;
          moneyOut.billNo =
            await this.allDataService.moneyOutService.getNewMoneyOutNo();
          moneyOut.paymentMode = this.paymentMode || 'cash';
          moneyOut.paymentId = (this.paymentIdEle?.value as string) || null;
          purchase.moneyOuts.push(moneyOut);
        }
  
        return await this.allDataService.purchaseService.save(purchase);
      } else {
        return null;
      }
    } catch (error) {
      SentryUtilites.setLog("PurchaseFormPage:addPurchase", error)
      return null;
    }
  }

  async updatePurchase() {
    try {
      if (this.form.valid) {
        let purchase = new Purchase();
  
        purchase = {
          ...this.fetchedPurchase,
          ...this.form.value,
        };
  
        // -- not required for update purchase because here not have kot and hold -- //
        // if (!purchase?.moneyOuts?.length) {
        //   if (Number(this.form?.value?.amountPaid)) {
        //     let moneyOut = new MoneyOut();
        //     moneyOut.party = purchase.party;
        //     moneyOut.billDateStamp = purchase.billDateStamp;
        //     moneyOut.totalAmount = this.form.value.amountPaid;
        //     moneyOut.billNo =
        //       await this.allDataService.moneyOutService.getNewMoneyOutNo();
        //     moneyOut.paymentMode = this.paymentMode || 'cash';
        //     moneyOut.paymentId = (this.paymentIdEle?.value as string) || null;
        //     purchase.moneyOuts = [moneyOut];
        //   }
        // } else {
        //   purchase.moneyOuts = this.moneyOutSelector?.getSelectedMoneyOuts() || [];
        // }
  
        purchase.moneyOuts = this.moneyOutSelector?.getSelectedMoneyOuts() || [];
  
        return await this.allDataService.purchaseService.update(purchase);
      } else {
        return null;
      }
    } catch (error) {
      SentryUtilites.setLog("PurchaseFormPage:updatePurchase", error)
      return null;
    }
  }

  async save() {
    try {
      let savedPurchase = await this.addPurchase();
      if (savedPurchase?._localUUID) {
        this.listForceList();
        this.itemRestaurantSelector?.openItemSelectorModal(false);
        this.router.navigate(['purchase']);
      }
    } catch (error) {
      SentryUtilites.setLog("PurchaseFormPage:save", error)
    }
  }

  async onlySave() {
    try {
      let savedPurchase = null;
      if (this.fetchedPurchase?._localUUID) {
        savedPurchase = await this.updatePurchase();
      } else {
        savedPurchase = await this.addPurchase();
      }
      this.listForceList();
      if (savedPurchase?._localUUID) {
        this.resetBillForm();
        this.ngOnInit();
        this.ngAfterViewInit();
      }
    } catch (error) {
      SentryUtilites.setLog("PurchaseFormPage:onlySave", error)
    }
  }

  async savePrint() {
    try {
      let savedPurchase: Purchase = null;
      if (this.fetchedPurchase?._localUUID) {
        savedPurchase = await this.updatePurchase();
      } else {
        savedPurchase = await this.addPurchase();
      }
      this.listForceList();
      if (savedPurchase?._localUUID) {
        this.itemRestaurantSelector?.openItemSelectorModal(false);
        this.resetBillForm();
        setTimeout(() => {
          this.router.navigate([`/bill-view-purchase/${savedPurchase?._localUUID}`], {
            queryParams: { isPrint: true },
          });
        }, 0);
        if (Utility.getFromLocalStorage('bill_amount_sound_status')) {
          Utility.playAmountSpeech(savedPurchase?.totalAmount);
        }
      }
    } catch (error) {
      SentryUtilites.setLog("PurchaseFormPage:savePrint", error)
    }
  }

  async update() {
    try {
      let savedPurchase = await this.updatePurchase();
      if (savedPurchase?._localUUID) {
        this.listForceList();
        this.itemRestaurantSelector?.openItemSelectorModal(false);
        this.router.navigate(['purchase']);
      }
    } catch (error) {
      SentryUtilites.setLog("PurchaseFormPage:update", error)
    }
  }

  resetBillForm() {
    try {
      this.itemRestaurantSelector?.openItemSelectorModal(false);
      this.itemRestaurantSelector?.resetCart();
      this.itemSelector?.openItemSelectorModal(false);
      this.itemSelector?.resetCart();
      if (this.listStyleView) {
        this.partySelector?.resetParty();
        this.partySelector?.enable();
      } else {
        this.partyRestaurantSelector?.resetParty();
        this.partyRestaurantSelector?.enable();
      }
  
      this.form.reset();
      this.isNgOnInitRun = false;
      this.barcodeScannerMode = false;
      this.dpEWayBillDateValue = null;
      this.dpDeliveryDateValue = null;
      this.showTransportDetailsCard = false;
      this.amountPaidChecked = null;
      this.isDisableAmountPaid = false;

      this.subArr?.forEach(sub => {
        sub?.unsubscribe();
      })
      this.subArr = [];
  
      if (this.paramDocumentId) {
        this.fetchedPurchase = null;
        this.router.navigate(['purchase/form']);
        this.initializeReactiveForm();
      } else {
        this.paramDocumentId = null;
        this.fetchedPurchase = null;
      }
    } catch (error) {
      SentryUtilites.setLog("PurchaseFormPage:resetBillForm", error)
    }
  }

  openMoneyOutSelectorModal() {
    try {
      if (this.moneyOutSelector) {
        this.moneyOutSelector.openMoneyOutSelectorModal();
      } else {
        this.moneyOutSelector.openMoneyOutSelectorModal(false);
      }
    } catch (error) {
      SentryUtilites.setLog("PurchaseFormPage:openMoneyOutSelectorModal", error)
    }
  }

  async listForceList() {
    try {
      this.allDataService.itemService.reloadList();
      this.allDataService.partyService.reloadList();
      this.allDataService.moneyOutService.reloadList();
      await Utility.wait(2000);
      this.allDataService.listForceReloadSubs.next('moneyout-list');
      this.allDataService.listForceReloadSubs.next('purchase-list');
      this.allDataService.listForceReloadSubs.next('party-list');
    } catch (error) {
      SentryUtilites.setLog("PurchaseFormPage:listForceList", error)
    }
  }

  // Calculations

  CalculateBillTask = CalculateBillTask;

  cashDiscountAmount = 0.0;
  cashDiscountPercentage = 0.0;

  unitDiscountAmountTotal = 0.0;
  unitGstAmountTotal = 0.0;
  unitCessAmountTotal = 0.0;
  unitTaxAmountTotal = 0.0;
  unitSubTotalAmountTotal = 0.0;
  unitTotalAmountTotal = 0.0;
  unitTotalSavingAmountTotal = 0.0;

  billItemsTotal = 0.0;
  wcdBillItemsTotal = 0.0;

  calculateSubTotalAmount() {
    try {
      this.unitDiscountAmountTotal = 0.0;
      this.unitGstAmountTotal = 0.0;
      this.unitCessAmountTotal = 0.0;
      this.unitTaxAmountTotal = 0.0;
      this.unitSubTotalAmountTotal = 0.0;
      this.unitTotalAmountTotal = 0.0;
      this.unitTotalSavingAmountTotal = 0.0;
  
      this.billItemsTotal = 0.0;
      this.wcdBillItemsTotal = 0.0;
  
      let billItems = this.form?.value?.billItems as BillItem[];
  
      billItems?.forEach((billItem) => {
        this.unitDiscountAmountTotal += billItem?.unitDiscountAmount || 0.0;
        this.unitGstAmountTotal += billItem?.itemTotalGstAmount || 0.0;
        this.unitCessAmountTotal += billItem?.itemTotalCessAmount || 0.0;
        this.unitTaxAmountTotal += billItem?.itemTotalTaxAmount || 0.0;
        this.unitSubTotalAmountTotal += billItem?.subTotal || 0.0;
        this.unitTotalAmountTotal += billItem?.total || 0.0;
        this.unitTotalSavingAmountTotal += billItem?.totalSaving || 0.0;
  
        this.billItemsTotal += billItem?.total || 0.0;
        this.wcdBillItemsTotal += billItem?.wcdTotal || 0.0;
      });
  
      this.form?.patchValue({
        discountAmount: this.unitDiscountAmountTotal,
        gstAmount: this.unitGstAmountTotal,
        cessAmount: this.unitCessAmountTotal,
        subTotalAmount: this.unitSubTotalAmountTotal,
        totalSaving: this.unitTotalSavingAmountTotal,
      });
      this.calculateTotalAmount();
    } catch (error) {
      SentryUtilites.setLog("PurchaseFormPage:calculateSubTotalAmount", error)
    }
  }

  calculateCashDiscount(task: CalculateBillTask = CalculateBillTask.CALCULATE) {
    try {
      this.cashDiscountAmount = 0.0;
      this.cashDiscountPercentage = 0.0;
  
      if (task == CalculateBillTask.DISCOUNT_FROM_PERCENTAGE) {
        this.cashDiscountPercentage = this.form?.value?.cashDiscountPercentage;
        this.cashDiscountAmount = Utils.capFractionsToSix(this.wcdBillItemsTotal * this.cashDiscountPercentage / 100);
        this.form?.patchValue({
          cashDiscount: this.cashDiscountAmount
        });
      } else if (task == CalculateBillTask.DISCOUNT_FROM_AMOUNT) {
        this.cashDiscountAmount = this.form?.value?.cashDiscount;
        if(this.wcdBillItemsTotal > 0) {
          this.cashDiscountPercentage = Utils.capFractionsToSix((this.cashDiscountAmount * 100) / this.wcdBillItemsTotal);
        } else {
          this.cashDiscountPercentage = 0.0;
        }
        this.form?.patchValue({
          cashDiscountPercentage: this.cashDiscountPercentage
        });
      }
  
      if (this.cashDiscountAmount > this.wcdBillItemsTotal || this.cashDiscountAmount < 0) {
        this.cashDiscountAmount = 0.0;
        this.cashDiscountPercentage = 0.0;
        this.form?.patchValue({
          cashDiscountAmount: null,
          cashDiscountPercentage: null
        });
      }
  
      this.recalculateBillItems();
    } catch (error) {
      SentryUtilites.setLog("PurchaseFormPage:calculateCashDiscount", error)
    }

  }

  calculateTotalAmount() {
    try {
      let additionalCharges =
        this.form?.value?.transportDetail?.additionalAmount || 0;
      if (!Utility.isNumber(additionalCharges)) {
        additionalCharges = 0.0;
      }
  
      let serviceChargeAmount = this.form?.value?.serviceChargeAmount || 0;
      if (!Utility.isNumber(serviceChargeAmount)) {
        serviceChargeAmount = 0.0;
      }
  
      let totalAmount =
        this.unitTotalAmountTotal +
        Number(additionalCharges) +
        Number(serviceChargeAmount);
      totalAmount = Utils.capFractionsToSix(totalAmount);
  
      let roundOffValue = 0.0;
      if (this.selectedProfile?.iSetRoundOffTotalAmountStatus) {
        let floorDelta = (totalAmount || 0.0) - Math.floor(totalAmount || 0.0);
        let ceilDelta = Math.ceil(totalAmount || 0.0) - (totalAmount || 0.0);
  
        if (floorDelta > ceilDelta) {
          roundOffValue = ceilDelta;
        } else {
          roundOffValue = floorDelta * -1;
        }
        roundOffValue = Utils.capFractionsToSix(roundOffValue);
        totalAmount = Math.round(totalAmount + roundOffValue);
      }
  
      this.form?.patchValue({
        totalAmount,
        roundOffValue,
      });
  
      if (this.amountPaidChecked && !this.fetchedPurchase) {
        this.populateAmountPaid(
          this.fetchedPurchase
            ? this.fetchedPurchase?.amountPaid
            : this.form?.value?.totalAmount
        );
      }
    } catch (error) {
      SentryUtilites.setLog("PurchaseFormPage:calculateTotalAmount", error)
    }
  }

  recalculateBillItems() {
    try {
      let billItems = this.form?.value?.billItems;
      billItems?.forEach((billItem) => {
        BillCalculations.calculateBillItem(
          billItem,
          BillType.PURCHASE,
          null,
          this.form?.value?.cashDiscountPercentage
        );
      });
      this.itemRestaurantSelector?.setBillItemsNoEmitNoCal(billItems);
      this.form?.patchValue({
        billItems,
      });
      this.calculateSubTotalAmount();
    } catch (error) {
      SentryUtilites.setLog("PurchaseFormPage:recalculateBillItems", error)
    }
  }

  /**
   * @description : close date picker
   */
  closeDOBPicker() {
    try {
      this.isOpenBillDatePicker = false;
      this.isOpenEwayBillDatePicker = false;
      this.isOpenDeliveryDatePicker = false;
    } catch (error) {
      SentryUtilites.setLog("PurchaseFormPage:closeDOBPicker", error)
    }
  }
  // ----------------------------------

  /**
   * @description : toggle for show/hide Transport Details
   */
  toggleTransportDetailsFields() {
    this.showTransportDetailsCard = !this.showTransportDetailsCard;
  }
  // -----------------------------------

}
