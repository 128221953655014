import { AlertController, IonInput, IonSearchbar, ToastController } from '@ionic/angular';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BillItem } from '../../models/BillItem.model';
import Party from '../../models/Party.model';
import { CalculateBillItemTask } from '../../utils/enums/CalculateBillItemTask';
import { ItemRestaurantListComponent } from '../item-restaurant-list/item-restaurant-list.component';
import { PartySelectorComponent } from '../party-selector/party-selector.component';
import { SettingsComponent } from '../settings/settings.component';
import { BillCalculations } from '../../utils/BillCalculations';
import { BillType } from '../../utils/enums/BillType';
import { BillingType } from '../../utils/enums/BillingType';
import { Utility } from '../../utils/utility';
import { AllDataService } from 'src/app/services/all-data.service';
import { KotItem } from 'src/app/models/KotItem.model';
import { Kot } from '../../models/Kot.model';
import { Utils } from '../../utils/utils';
import { AccessControlService } from '../../services/auth/access-control.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-item-restaurant-selector',
  templateUrl: './item-restaurant-selector.component.html',
  styleUrls: ['./item-restaurant-selector.component.scss'],
})
export class ItemRestaurantSelectorComponent implements OnInit {

  @Input() saleUUID: string = null;
  @Input() isPurchase: boolean = false;
  @Input() isPurchaseReturn: boolean = false;
  @Input() isEstimate:boolean = false;
  @Input() isEditedSale:boolean = false;
  @Input() isEditedSaleReturn:boolean = false;
  @Input() partySecondary: Party = null;
  @Input() amountReceivedChecked: boolean = false;
  @Input() amountReceivedAmount: number = null;
  @Input() paymentMode: string = null;
  @Input() paymentId: any = null;
  @Input() sortByItemCodeAsc: boolean = false;
  @Input() showBookmarkList: boolean = false;
  @Input() lockSellPrice: boolean = false;
  @Input() negativeStockLock: boolean = false;
  @Input() barcodeScannerMode: boolean = false;
  @Input() paramDocumentId: string = null;
  @Input() billCompleteStamp: number = null;
  @Input() isPrintedKOT: boolean = false;
  @Input() totalAmount: number = 0;
  @Input() party: Party = null;
  @Input() iSetItemPriceHistoryStatus: boolean = false;
  @Input() iSetOutOfStockHideStatus: boolean = false;
  @Input() iSetQuickSaleStatus: boolean = false;
  @Input() iSetShowStockStatus: boolean = false;
  @Input() iSetItemSelectorColumns: number = 4;
  @Input() cashDiscountPercentage: number = 0;
  @Input() dSetDiscountOfferTypeI: number = 0;
  @Input() dSetDiscountOfferTypeII: number = 0;
  @Input() dSetDiscountStatusI: boolean = false;
  @Input() dSetDiscountStatusII: boolean = false;
  @Input() discountSoundStatus: boolean = false;
  @Input() isStaffAssignToSale: boolean = false;
  @Input() assignStaffUserId: string = '';

  @Output() itemAdded = new EventEmitter<BillItem[]>();
  @Output() cartCleared = new EventEmitter<boolean>();
  @Output() savePrintTrigger = new EventEmitter<boolean>();
  @Output() onlySaveTrigger = new EventEmitter<boolean>();
  @Output() holdNewTrigger = new EventEmitter<boolean>();
  @Output() printKotTrigger = new EventEmitter<boolean>();
  @Output() secondaryPartyAddTrigger = new EventEmitter<Party>();
  @Output() amountReceivedCheckedChange = new EventEmitter<{detail:{checked:boolean}}>();
  @Output() amountReceivedAmountChange = new EventEmitter<number>();
  @Output() paymentModeChange = new EventEmitter<string>();
  @Output() paymentIdChange = new EventEmitter<string>();
  @Output() barcodeChangeTrigger = new EventEmitter<boolean>();
  @Output() initKotTrigger = new EventEmitter<Kot>();
  @Output() savedSecondaryParty = new EventEmitter<Party>();
  @Output() selectedStaff = new EventEmitter<{userId: string; name: string;}>();

  /*
    Native Element Ref
  */

  @ViewChild('secondaryPartySelector', { static: false }) secondaryPartySelector: PartySelectorComponent;
  @ViewChild('itemRestaurantList') itemRestaurantList: ItemRestaurantListComponent;
  @ViewChild('settingsModal') settingsModal: SettingsComponent;
  @ViewChild('searchBar') searchBar: IonSearchbar;
  @ViewChild('formSellPriceEle') formSellPriceEle: IonInput;
  @ViewChild('formQuantityEle') formQuantityEle: IonInput;
  @ViewChild('amountReceivedEle') amountReceivedEle: IonInput;

  //------------------------------------------------------------------------------------------

  getHeaderColorClass = Utility.getHeaderColorClass;

  cartItems: BillItem[] = [];

  previousKOT: BillItem[] = []
  currentKOT: BillItem[] = []

  currentItem: BillItem = null;

  isModalOpen = false;
  isItemAddModalOpen = false;
  isBillItemFormOpen = false;
  isQuantityFormOpen = false;
  isKotListModalOpen = false;

  billItemForm: FormGroup;

  cssHideModal: boolean = false;

  showMaxAmtReceivedText = false;
  showSaveAndPrintSpinner = false;
  showOnlySaveSpinner = false;

  onlyPriceFieldShow = false;
  isEditable: boolean = true;

  subArr: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private alertController: AlertController,
    private toastController: ToastController,
    private allDataService: AllDataService,
    private accessControlService: AccessControlService,
  ) { }

  ngOnInit() {
    this.initializeReactiveForms();
  }

  openKotListModal(value: boolean = true) {
    try {
      if(!this.isPrintedKOT) {
        return ;
      }
      this.isKotListModalOpen = value;
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantSelectorComponent:openKotListModal", error)
    }
  }

  onPriceShowStatusChange(billItem: BillItem) {
    try {
      this.onlyPriceFieldShow = billItem ? true : false;;
      if(this.onlyPriceFieldShow) {
        this.initBillItemForm(billItem)
      }
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantSelectorComponent:onPriceShowStatusChange", error)
    }
  }

  initializeReactiveForms() {
    try {
      this.billItemForm = this.formBuilder.group({
        price: [null, Validators.required],
        unit: [null],
        quantity: [null],
        finalPrice: [null],
        discountPercentage: [null],
        discountAmount: [null],
        billNote: [null],
        kotNote: [null],
      });
      this.subArr.push(this.billItemForm.get("quantity").valueChanges.pipe(distinctUntilChanged(), debounceTime(300)).subscribe(async (quantity) => {
        if(!this.isPurchase || !this.isEstimate || !this.isPurchaseReturn) {
          if(quantity < 0) {
            this.billItemForm.patchValue({quantity: Math.abs(quantity)});
          }else if(this.negativeStockLock && this.currentItem?.item?.stock > 0 && this.currentItem?.item?.stock < quantity) {
            this.billItemForm.patchValue({quantity: Math.abs(this.currentItem?.item?.stock)});
            const toast = await this.toastController.create({
              message: 'Quantity Exhausted',
              duration: 2000,
              color: 'dark',
            });
            await toast.present();
          }
        }
      }));
      this.subArr.push(this.billItemForm.get("price").valueChanges.pipe(distinctUntilChanged(), debounceTime(300)).subscribe(price => {
        if(price < 0) {
          this.billItemForm.patchValue({price: Math.abs(price)});
        }
      }));
      this.subArr.push(this.billItemForm.get("discountPercentage").valueChanges.pipe(distinctUntilChanged(), debounceTime(300)).subscribe(discountPercentage => {
        if(discountPercentage < 0 || discountPercentage > 100) {
          this.billItemForm.patchValue({discountPercentage: Math.abs(discountPercentage>100 ? 100 : discountPercentage)});
        }else if(discountPercentage!=null && !Number.isInteger(discountPercentage)) {
          let arr:string[] = (''+discountPercentage).split('.');
          if(arr[1]?.length > 3) {
            this.billItemForm.patchValue({discountPercentage: Number(arr[0]+'.'+arr[1].substr(0,3))});
          }
        }
      }));
      this.subArr.push(this.billItemForm.get("discountAmount").valueChanges.pipe(distinctUntilChanged(), debounceTime(300)).subscribe(discountAmount => {
        if(discountAmount < 0 || discountAmount > this.billItemForm?.value?.price) {
          this.billItemForm.patchValue({discountAmount: Math.abs(discountAmount > this.billItemForm?.value?.price ? this.billItemForm?.value?.price : discountAmount)});
        }else if(discountAmount!=null && !Number.isInteger(discountAmount)) {
          let arr:string[] = (''+discountAmount).split('.');
          if(arr[1]?.length > 3) {
            this.billItemForm.patchValue({discountAmount: Number(arr[0]+'.'+arr[1].substr(0,3))});
          }
        }
      }));
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantSelectorComponent:initializeReactiveForms", error)
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    try {
      if (changes?.lockSellPrice?.currentValue != changes?.lockSellPrice?.previousValue) {
        if(this.lockSellPrice && this.isBillItemFormOpen) {
          this.billItemForm?.get('price')?.disable();
        }else if(!this.lockSellPrice){
          this.billItemForm?.get('price')?.enable();
        }
      }
      if (changes?.barcodeScannerMode?.currentValue != changes?.barcodeScannerMode?.previousValue) {
        this.focusBarcodeSearchBar();
      }
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantSelectorComponent:ngOnChanges", error)
    }
  }

  onSettingSave() {
    try {
      this.populateBarcodeScannerSetting();
      this.barcodeChangeTrigger.emit(true);
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantSelectorComponent:onSettingSave", error)
    }
  }

  /*
    Barcode Scanner
  */

    async populateBarcodeScannerSetting() {
      this.barcodeScannerMode = Utility.getFromLocalStorage('item_barcode_scanner');
    }

    async onBarcodeScannerModeChange(event: any) {
      try {
        this.barcodeScannerMode = event?.detail?.checked;
        // Utility.setToLocalStorage('item_barcode_scanner', this.barcodeScannerMode);
        this.barcodeChangeTrigger.emit(true);
      } catch (error) {
        SentryUtilites.setLog("ItemRestaurantSelectorComponent:onBarcodeScannerModeChange", error)
      }
    }

    //----------------------------------------------------------------------------

  initBillItemForm(billItem: BillItem) {
    this.openBillItemForm();
    this.populateBillItemForm(billItem);
  }

  initQuantityForm(billItem: BillItem) {
    this.openQuantityForm();
    this.populateBillItemForm(billItem);
  }

  populateBillItemForm(billItem: BillItem) {
    try {
      this.currentItem = billItem;
  
      this.billItemForm.patchValue({
        price: this.currentItem?.price,
        unit: this.currentItem?.unit || this.currentItem?.item?.primaryUnit,
        quantity: this.currentItem?.quantity,
        finalPrice: this.currentItem?.total,
        discountPercentage: this.currentItem?.discountPercentage,
        discountAmount: this.currentItem?.discount,
        billNote: this.currentItem?.billNote,
        kotNote: this.currentItem?.kotNote,
      });
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantSelectorComponent:populateBillItemForm", error)
    }
  }

  resetForm() {
    // this.billItemForm.reset();
    this.currentItem = null;
  }

  setPaymentMode(event) {
    try {
      if(this.paymentMode != event?.detail?.value) {
        this.paymentMode = event?.detail?.value;
        this.paymentModeChange.emit(this.paymentMode);
      }
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantSelectorComponent:setPaymentMode", error)
    }
  }

  setPaymentId(event) {
    try {
      if(this.paymentId != event?.detail?.value) {
        this.paymentId = event?.detail?.value;
        this.paymentIdChange.emit(this.paymentId);
      }
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantSelectorComponent:setPaymentId", error)
    }
  }

  updateConvertRationMultiplier() {
    try {
      if(this.billItemForm?.value?.unit === this.currentItem?.item?.secondaryUnit) {
        this.currentItem.convertRatioMultiplier = this.currentItem?.item?.convertRatioR;
        this.billItemForm?.patchValue({
          price: Utils.capFractionsToSix((this.currentItem?.price || 0.0) * (1 / (this.currentItem?.convertRatioMultiplier || 1.0)))
        });
      } else {
        this.currentItem.convertRatioMultiplier = 1.0;
        this.billItemForm?.patchValue({
          price: this.isPurchase || this.isPurchaseReturn ? this.currentItem?.item?.purchasePrice || 0.0 : this.currentItem?.item?.sellPrice || 0.0
        });
      }
      this.updateCurrentItem();
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantSelectorComponent:updateConvertRationMultiplier", error)
    }
  }

  openItemSelectorModal(isOpen: boolean = true) {
    try {
      this.isModalOpen = isOpen;
      if(isOpen && this.isEditable) {
        this.focusBarcodeSearchBar();
        this.populateBarcodeScannerSetting();
      }
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantSelectorComponent:openItemSelectorModal", error)
    }
  }

  async openItemAddModal(isOpen: boolean = true) {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({action:'createItem'});
      if(!isPermit) {
        return alert("Permission: You don't have permission to create item. Please contact to your owner.");
      }
      this.isItemAddModalOpen = isOpen;
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantSelectorComponent:openItemAddModal", error)
    }
  }

  openBillItemForm(isOpen: boolean = true) {
    try {
      this.isBillItemFormOpen = isOpen;
      if(isOpen) {
        setTimeout(() => {
          this.formSellPriceEle?.setFocus();
        }, 0);
      }
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantSelectorComponent:openBillItemForm", error)
    }
  }

  openQuantityForm(isOpen: boolean = true) {
    try {
      this.isQuantityFormOpen = isOpen;
      if(isOpen) {
        setTimeout(() => {
          this.formQuantityEle?.setFocus();
        }, 0);
      }
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantSelectorComponent:openQuantityForm", error)
    }
  }

  closeBillitemForm() {
    try {
      let priceValue = this.billItemForm.get('price').value;
      if(this.currentItem) {
        this.currentItem.price = priceValue ? priceValue : 0;
      }
      this.itemRestaurantList?.replaceBillItem(this.currentItem);
      this.openBillItemForm(false);
      this.openQuantityForm(false);
      this.onlyPriceFieldShow = false;
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantSelectorComponent:closeBillitemForm", error)
    }
  }

  getBillItems() {
    return this.cartItems;
  }

  setBillItems(billItems: BillItem[]) {
    try {
      let totalAmount = 0;
      this.cartItems = billItems;
      this.cartItems?.forEach(x => {this.calculation(x); totalAmount += x?.total});
      this.setTotalAmount();
      this.itemAdded.emit(this.cartItems);
      // if(this.searchBar) {
      //   this.searchBar.value = '';
      // }
      if(this.amountReceivedChecked && this.isModalOpen && this.amountReceivedEle) {
        this.amountReceivedEle.value = totalAmount;
        this.amountReceivedAmountChange.emit(this.totalAmount)
      }
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantSelectorComponent:setBillItems", error)
    }
  }

  setBillItemsNoEmitNoCal(billItems: BillItem[]) {
    try {
      this.cartItems = billItems;
      this.setTotalAmount();
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantSelectorComponent:setBillItemsNoEmitNoCal", error)
    }
  }

  setTotalAmount() {
    // this.totalAmount = 0;
    // this.cartItems?.forEach(x => this.totalAmount += x.total);
  }

  CalculateBillItemTask = CalculateBillItemTask;

  updateCurrentItem(task:CalculateBillItemTask = CalculateBillItemTask.CALCULATE) {
    try {
      if(this.billItemForm.value.quantity === null) {
        this.billItemForm.patchValue({quantity: 0});
      }
      if (this.currentItem) {
        this.currentItem.price = this.billItemForm.value.price;
        this.currentItem.unit = this.billItemForm.value.unit;
        this.currentItem.quantity = this.billItemForm.value.quantity;
        this.currentItem.total = this.billItemForm.value.finalPrice;
        this.currentItem.discountPercentage = this.billItemForm.value.discountPercentage;
        this.currentItem.discount = this.billItemForm.value.discountAmount;
        this.currentItem.billNote = this.billItemForm.value.billNote;
        this.currentItem.kotNote = this.billItemForm.value.kotNote;
        this.calculation(this.currentItem,task);
        this.populateBillItemForm(this.currentItem);
      }
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantSelectorComponent:updateCurrentItem", error)
    }
  }

  calculation(billItem: BillItem, task: CalculateBillItemTask = CalculateBillItemTask.CALCULATE) {
    BillCalculations.calculateBillItem(billItem,BillType.SALE,null,null,task);
  }

  async save() {
    try {
      if(this.billItemForm?.valid) {
        if((this.isPurchase || this.isPurchaseReturn) && this.onlyPriceFieldShow) {
          if(!this.billItemForm?.value?.quantity) {
            this.billItemForm?.patchValue({quantity: 1});
          }
          this.onlyPriceFieldShow = false;
          if(this.billItemForm?.value?.price && Utility.isNumber(this.billItemForm?.value?.price) && this.currentItem?.item?._localUUID) {
            let actualItem = await this.allDataService.itemService.getByUUID(this.currentItem?.item?._localUUID);
            if(actualItem?._localUUID) {
              this.allDataService.listForceReloadSubs.next('item-list')
              actualItem.purchasePrice = this.billItemForm?.value?.price;
              this.allDataService.itemService.update(actualItem);
            }
          }
        }
        this.billItemForm?.get('price')?.enable();
        this.updateCurrentItem();
        this.itemRestaurantList?.replaceBillItem(this.currentItem);
        this.openBillItemForm(false);
        this.openQuantityForm(false);
        this.resetForm();
        this.setBillItems(this.cartItems);
      }
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantSelectorComponent:save", error)
    }
  }

  returnZero() {
    return 0;
  }

  onAmountReceivedCheckedChange(event: any) {
    try {
      this.amountReceivedChecked = event?.detail?.checked;
      this.amountReceivedCheckedChange.emit({detail:{checked:this.amountReceivedChecked}});
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantSelectorComponent:onAmountReceivedCheckedChange", error)
    }
  }

  onAmountReceivedAmountChange(event: any) {
    try {
      if(this.amountReceivedEle && this.isModalOpen) {
        let amountReceived = Number(event?.detail?.value || null);
        if(amountReceived < 0) {
          this.amountReceivedEle.value = amountReceived = Math.abs(amountReceived);
        }
        if(amountReceived > this.totalAmount) {
          this.amountReceivedEle.value = amountReceived = this.totalAmount;
          this.showMaxAmtReceivedText = true;
        }
        this.amountReceivedAmountChange.emit(amountReceived);
      }
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantSelectorComponent:onAmountReceivedAmountChange", error)
    }
  }

  async printKOT() {
    try {
      if(!this.isPrintedKOT) {
        this.printKotTrigger.emit(true);
      }else {
        this.noPrintKotMessage();
      }
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantSelectorComponent:printKOT", error)
    }
  }

  async noPrintKotMessage() {
    try {
      const toast = await this.toastController.create({
        message: 'You have already printed the item currently on this bill',
        duration: 2000,
        color: 'dark',
      });
  
      await toast.present();
      this.openKotListModal();
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantSelectorComponent:noPrintKotMessage", error)
    }
  }

  triggerSavePrint() {
    try {
      this.showSaveAndPrintSpinner = true;
      if(this.isPurchase || this.isPurchaseReturn) {
        this.openItemSelectorModal(false);
      }
      this.savePrintTrigger.emit(true);
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantSelectorComponent:triggerSavePrint", error)
    }
  }

  triggerOnlySave() {
    try {
      this.showOnlySaveSpinner = true;
      this.onlySaveTrigger.emit(true);
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantSelectorComponent:triggerOnlySave", error)
    }
  }

  triggerHoldNew() {
    this.holdNewTrigger.emit(true);
  }

  async increaseQty(billItem: BillItem) {
    try {
      if(!this.isPurchase && !this.isPurchaseReturn && !this.isEstimate && this.negativeStockLock && billItem?.item?.stock > 0 && billItem?.item?.stock == billItem?.quantity) {
        const toast = await this.toastController.create({
          message: 'Quantity Exhausted',
          duration: 2000,
          color: 'dark',
        });
        await toast.present();
        return;
      }
      billItem.quantity += 1;
      this.setBillItems(this.cartItems);
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantSelectorComponent:increaseQty", error)
    }
  }

  decreaseQty(billItem: BillItem) {
    try {
      billItem.quantity -= 1;
      if(billItem.quantity <= 0) {
        let index = this.cartItems.findIndex(x => billItem?.item?._localUUID == x?.item?._localUUID);
        if (index != -1) {
          this.cartItems.splice(index, 1);
        }
      }
      this.setBillItems(this.cartItems);
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantSelectorComponent:decreaseQty", error)
    }
  }

  resetCart() {
    try {
      this.setBillItems([]);
      this.partySecondary = null;
      this.secondaryPartySelector?.resetParty();
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantSelectorComponent:resetCart", error)
    }
  }

  clearCart() {
    try {
      this.setBillItems([]);
      this.itemRestaurantList?.reset();
      this.cartCleared.emit(true);
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantSelectorComponent:clearCart", error)
    }
  }

  resetItemSelector() {
    this.itemRestaurantList?.reset();
  }

  openSeconadryPartyModal() {
    this.secondaryPartySelector?.openPartySelectorModal();
    this.secondaryPartySelector?.populateSecondaryPartyWhileEdit(this.partySecondary);
  }

  onSecondaryPartySelect(party: Party) {
    try {
      if (party?._localUUID) {
        this.partySecondary = party;
        this.secondaryPartyAddTrigger.emit(party);
      }
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantSelectorComponent:onSecondaryPartySelect", error)
    }
  }

  secondaryPartyOnlySaveEvent(party: Party) {
    try {
      if (party?._localUUID || (party?.name || party?.billingAddress)) {
        this.partySecondary = party;
        this.savedSecondaryParty.emit(party);
      }
      if(this.cartItems?.length) {
        this.triggerOnlySave();
      }
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantSelectorComponent:secondaryPartyOnlySaveEvent", error)
    }
  }

  secondaryPartySavePrintEvent(party: Party) {
    try {
      if (party?._localUUID || (party?.name || party?.billingAddress)) {
        this.partySecondary = party;
        this.savedSecondaryParty.emit(party);
      }
      if(this.cartItems?.length) {
        this.triggerSavePrint();
      }
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantSelectorComponent:secondaryPartySavePrintEvent", error)
    }
  }

  secondaryDiscount(party: Party) {
    try {
      if(party?._localUUID || (party?.name || party?.billingAddress) || party?.phone) {
        this.partySecondary = party;
        this.itemAdded.emit(this.cartItems);
        this.secondaryPartyAddTrigger.emit(party);
      }
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantSelectorComponent:secondaryDiscount", error)
    }
  }

  async presentClearCartConfirm() {
    try {
      const alert = await this.alertController.create({
        header: 'Alert!',
        message: 'Are you sure to clear cart?',
        subHeader: 'This will delete running bill.',
        mode: 'ios',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => { },
          },
          {
            text: 'Clear Items',
            role: 'confirm',
            handler: this.clearCart.bind(this),
          },
        ],
      });
  
      await alert.present();
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantSelectorComponent:presentClearCartConfirm", error)
    }
  }

  async presentResetItemSelectorConfirm() {
    try {
      const alert = await this.alertController.create({
        header: 'Alert!',
        message: 'Are you sure to clear cart?',
        mode: 'ios',
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => { },
          },
          {
            text: 'Reset',
            role: 'confirm',
            handler: this.resetItemSelector.bind(this),
          },
        ],
      });
  
      await alert.present();
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantSelectorComponent:presentResetItemSelectorConfirm", error)
    }
  }

  saveTrigger($event) {
    this.itemRestaurantList?.loadView();
    this.openItemAddModal(false);
  }

  openSettingsModal() {
    this.settingsModal?.openSettingsModal();
  }

  onFilterChange(value: string) {
    this.itemRestaurantList?.onFilterChange(value);
  }

  onBarcodeSearchEnter(event: any) {
    try {
      setTimeout(() => {
        if(this.barcodeScannerMode && event?.target?.value) {
          this.itemRestaurantList?.onBarcodeSearchEnter(event?.target?.value);
          this.searchBar.value = '';
          this.searchBar?.setFocus();
        }
      }, 500);
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantSelectorComponent:onBarcodeSearchEnter", error)
    }
  }

  focusBarcodeSearchBar() {
    try {
      setTimeout(() => {
        this.searchBar?.setFocus();
      }, 500);
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantSelectorComponent:focusBarcodeSearchBar", error)
    }
  }

  selectOrderItem(orderItems: KotItem[]) {
    this.itemRestaurantList?.selectOrderItem(orderItems);
  }

  initKot(kot: Kot) {
    this.initKotTrigger.emit(kot);
    this.openKotListModal(false);
  }

  /**
   * 
   * @param event : provide object of userId and name
   */
  emitSelectedStaff(event) {
    this.selectedStaff.emit(event);
  }
  // --------------------------------

  enable() {
    this.isEditable = true;
  }

  disable() {
    this.isEditable = false;
  }

  ngOnDestroy() {
    try {
      this.subArr?.forEach((el) => {
        el?.unsubscribe();
      })
    } catch (error) {
      SentryUtilites.setLog("ItemRestaurantSelectorComponent:ngOnDestroy", error)
    }
  }

}
