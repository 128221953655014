<ion-grid class="no-ion-grid-padding">
  <ion-row class="ion-grid-column-padding row-header">
    <ion-col size="2">
      <ion-label color="primary">Item Name</ion-label>
    </ion-col>
    <ion-col size="1">
      <ion-label color="primary">Quantity</ion-label>
    </ion-col>
    <ion-col size="1.5">
      <ion-label color="primary">{{ isPurchase ? 'Purchase Price' : isPurchaseReturn ? 'Purchase Return Price' : 'Sell Price' }}</ion-label>
    </ion-col>
    <ion-col size="1">
      <ion-label color="primary">Disc %</ion-label>
    </ion-col>
    <ion-col size="1">
      <ion-label color="primary">Disc</ion-label>
    </ion-col>
    <ion-col size="1">
      <ion-label color="primary">Tax %</ion-label>
    </ion-col>
    <ion-col size="1">
      <ion-label color="primary">Tax Inc</ion-label>
    </ion-col>
    <ion-col size="1">
      <ion-label color="primary">Unit</ion-label>
    </ion-col>
    <ion-col size="1.5">
      <ion-label color="primary">Total</ion-label>
    </ion-col>
    <!-- <ion-col size="1">
      <ion-label color="primary">Additional Charges</ion-label>
    </ion-col> -->
  </ion-row>

  <ion-row
    *ngFor="let billItem of selectedItems"
    class="ion-grid-column-padding selected-item-row"
    (click)="editItem(billItem)"
  >
    <ion-col size="2">
      <ion-label>{{billItem.item.itemName}}</ion-label>
    </ion-col>
    <ion-col size="1">
      <ion-label>{{billItem.quantity}}</ion-label>
    </ion-col>
    <ion-col size="1.5">
      <ion-label>{{ billItem?.price }}</ion-label>
    </ion-col>
    <ion-col size="1">
      <ion-label>{{correctDiscountPercentageValue(billItem.discountPercentage)}}</ion-label>
    </ion-col>
    <ion-col size="1">
      <ion-label>{{correctDiscountValue(billItem.discount)}}</ion-label>
    </ion-col>
    <ion-col size="1">
      <ion-label>{{billItem.taxPercentage}}</ion-label>
    </ion-col>
    <ion-col size="1">
      <ion-label>{{ billItem.incTax ? 'Yes' : 'No' }}</ion-label>
    </ion-col>
    <ion-col size="1">
      <ion-label>{{billItem.unit}}</ion-label>
    </ion-col>
    <ion-col size="1.5">
      <ion-label>{{billItem.total}}</ion-label>
    </ion-col>
    <ion-col size="1" [hidden]="!isEditable">
      <ion-label (click)="removeItem(billItem)" color="danger" class="remove-label">Remove</ion-label>
    </ion-col>
  </ion-row>

  <form [formGroup]="billItemForm" *ngIf="isEditable">
    <ion-row>
      <ion-col size="2">
        <ion-input
          *ngIf="!barcodeScannerMode"
          #inpItemName
          placeholder="Search Item..."
          formControlName="itemName"
          (keyup)="openItemSelectorModal()"
          (keyup.enter)="this.focusAmountReceived.emit(true)"
        >
        </ion-input>
        <ion-input
          *ngIf="barcodeScannerMode"
          #inpItemName
          placeholder="Search Item..."
          formControlName="itemName"
          (keyup.enter)="onBarcodeSearchEnter()"
        >
        </ion-input>
      </ion-col>
      <ion-col size="1">
        <ion-input
          #inpQuantity
          type="number"
          placeholder="Quantity"
          formControlName="quantity"
          (keyup.enter)="addItemToSelectedList()"
        >
        </ion-input>
      </ion-col>
      <ion-col size="1.5">
        <ion-input
          type="number"
          placeholder="{{isPurchase ? 'Purchase Price' : isPurchaseReturn ? 'Purchase Return Price' : 'Sell Price'}}"
          formControlName="price"
          (keyup.enter)="addItemToSelectedList()"
        >
        </ion-input>
      </ion-col>
      <ion-col size="1">
        <ion-input
          type="number"
          placeholder="Disc %"
          formControlName="discountPercentage"
          (keyup.enter)="addItemToSelectedList()"
        >
        </ion-input>
      </ion-col>
      <ion-col size="1">
        <ion-input
          type="number"
          placeholder="Disc"
          formControlName="discount"
          (keyup.enter)="addItemToSelectedList()"
        >
        </ion-input>
      </ion-col>
      <ion-col size="1">
        <ion-select
          #sbTax
          interface="alert"
          placeholder="Select"
          [value]="sbTaxValue"
          (ionChange)="onTaxSelect($event)"
          [interfaceOptions]="{header: 'Tax %'}"
        >
          <ion-select-option
            *ngFor="let unit of taxTypeOptions|keyvalue : returnZero"
            [value]="unit.key"
          >
            {{ unit.key }}
          </ion-select-option>
        </ion-select>
      </ion-col>
      <ion-col size="1">
        <ion-select
          interface="alert"
          placeholder="Select"
          formControlName="incTax"
          [interfaceOptions]="{header: 'Tax Inc'}"
        >
          <ion-select-option [value]="1"> Yes </ion-select-option>
          <ion-select-option [value]="0"> No </ion-select-option>
        </ion-select>
      </ion-col>
      <ion-col size="1">
        <ion-select
          interface="alert"
          placeholder="Select"
          formControlName="unit"
          [interfaceOptions]="{header: 'Unit'}"
          (click)="showUnitPerItem()"
          (ionChange)="updateUnitChangePrice($event)"
          [disabled]="!currentSelectedItem?.primaryUnit && !currentSelectedItem?.secondaryUnit"
        >
          <ion-select-option
            *ngFor="let unit of unitsOptions|keyvalue : returnZero"
            [value]="unit.key"
          >
            {{ unit.value }}
          </ion-select-option>
        </ion-select>
      </ion-col>
      <ion-col size="1.5">
        <ion-input
          readonly="true"
          placeholder="Total"
          type="number"
          formControlName="total"
        >
        </ion-input>
      </ion-col>
      <!-- <ion-col size="1">
        <ion-input
          placeholder="Add Chrg"
        >
        </ion-input>
      </ion-col> -->
    </ion-row>
    <ion-row *ngIf="billItemForm?.value?.itemName">
      <ion-col size="4">
        <ion-label color="danger">Please press "Enter" to add / update item</ion-label>
      </ion-col>
    </ion-row>
    <ion-button
      fill="outline"
      class="outline-border btn-details"
      color="primary"
      size="small"
      (click)="openItemAddModal()"
    >
      Item +
    </ion-button>
  </form>
</ion-grid>

<ion-modal [isOpen]="isModalOpen" [backdropDismiss]="false" animated="false">
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-title>Select Item</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="openItemSelectorModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <app-item-list
        #itemListEl
        [barcodeScannerMode]="barcodeScannerMode"
        [isPurchase]="isPurchase"
        (itemSelectedEvent)="onItemSelected($event)"
      >
      </app-item-list>
    </ion-content>
  </ng-template>
</ion-modal>

<app-item-list
  #itemListEl
  *ngIf="barcodeScannerMode"
  [style]="{display:'none'}"
  [barcodeScannerMode]="barcodeScannerMode"
  (itemSelectedEvent)="onItemSelected($event)"
>
</app-item-list>

<ion-modal class="modal-item-form" [isOpen]="isItemAddModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <ion-chip slot="start" color="tertiary" class="btn-back" (click)="openItemAddModal(false)">
          <ion-icon name="arrow-back-sharp"></ion-icon>
        </ion-chip>
        <ion-title slot="start">Add New Item</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <app-item-form
        [isBillform]="true"
        (saveTrigger)="saveTrigger($event)"
      >
      </app-item-form>
    </ion-content>
  </ng-template>
</ion-modal>
