<app-pin-verification (verifyTransactionPin)="verifyTransactionPIN($event)"  #pinVerificationElement></app-pin-verification>

<!-- Bulk Purchase Return -->

<ion-modal class="modal-bulk-purchase-return" [isOpen]="isBulkPurchaseReturnModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar color="light">
        <ion-grid>
          <ion-row class="ion-align-items-center">
            <ion-col size="auto">
              <ion-title>Delete Purchases</ion-title>
            </ion-col>
            <ion-col class="ion-text-center">
              <ion-label class="font-size-16">From {{fromStamp | date:'dd/MM/yyyy'}} </ion-label>
              <ion-label class="font-size-16">To {{toStamp | date:'dd/MM/yyyy'}}</ion-label>
            </ion-col>
            <ion-col size="auto" class="ion-text-end">
              <ion-buttons>
                <ion-button (click)="openBulkPurchaseReturnModal(false)">Close</ion-button>
              </ion-buttons>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">

      <ion-grid>

        <ion-row class="fixed-row">
          <ion-col size="12">
            <!-- Select all Purchase Return-->
            <ion-chip class="select-all-checkbox" color="tertiary" *ngIf="bulkPurchaseReturns?.length">
              <ion-item>
                <ion-checkbox
                  slot="start"
                  [checked]="selectedBulkPurchaseReturns.length === viewFilteredList?.length"
                  (ionChange)="selectAllPurchaseReturnCheckboxes($event)"
                  (click)="selectAllPurchaseReturnCheckboxClick()"
                ></ion-checkbox>
                <ion-label>Select All</ion-label>
              </ion-item>
            </ion-chip>

            <ion-chip color="tertiary">
              <ion-label>Total Count : </ion-label>
              <ion-label>&nbsp;&nbsp; </ion-label>
              <ion-label>{{viewFilteredList.length}}</ion-label>
            </ion-chip>

            <ion-chip color="tertiary">
              <ion-label>Selected Purchase Return Count : </ion-label>
              <ion-label>&nbsp;&nbsp; </ion-label>
              <ion-label>{{selectedBulkPurchaseReturns?.length}}</ion-label>
            </ion-chip>

          </ion-col>
        </ion-row>

        <ion-row class="floating-list">
          <ion-col size="12">
            <ion-item lines="none" *ngFor="let purchaseReturn of bulkPurchaseReturns">
              <ion-checkbox
                slot="start"
                (ionChange)="addToSelectedBulkPurchaseReturnArr(purchaseReturn,$event)"
                [checked]="purchaseReturn['checked']"
              ></ion-checkbox>
              <ion-label>
                <ion-chip color="primary">{{ purchaseReturn.billNo }} | {{ purchaseReturn.billDateStamp | date: 'dd/MM/yy' }}</ion-chip>
                <ion-chip color="primary">{{ purchaseReturn?.party?.name }}</ion-chip>
                <ion-chip color="primary">{{ purchaseReturn?.totalAmount | currency: 'INR' }}</ion-chip>
              </ion-label>
            </ion-item>
            <ion-infinite-scroll threshold="10px" *ngIf="bulkPurchaseReturns?.length" (ionInfinite)="loadMoreListData($event)">
              <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Loading more data...">
              </ion-infinite-scroll-content>
            </ion-infinite-scroll>
          </ion-col>
        </ion-row>

      </ion-grid>

    </ion-content>

    <ion-toolbar>
      <ion-button
        class="btn-save"
        expand="full"
        color="danger"
        (click)="deleteBulkPurchaseReturn()"
      >
        <span *ngIf="!bulkPurchaseReturnDeleteStarted">
          Delete
        </span>
        <span *ngIf="bulkPurchaseReturnDeleteStarted && !bulkPurchaseReturnDeleteCompleted">
          <ion-spinner name="lines-sharp-small"></ion-spinner>
        </span>
        <span *ngIf="bulkPurchaseReturnDeleteCompleted">
          Deleted
        </span>
      </ion-button>
    </ion-toolbar>
  </ng-template>
</ion-modal>

<!-- -------------------------------------------------------------------------------------- -->

<!-- Purchase Return -->

<ion-modal class="modal-purchase-return" [isOpen]="isPurchaseReturnModalOpen" [backdropDismiss]="false">
  <ng-template>
    <ion-header>
      <ion-toolbar color="light">
        <ion-title>Delete <ion-chip color="tertiary"> {{ purchaseReturn?.billNo }} </ion-chip> </ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="openPurchaseModal(false)">Close</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">

      <ion-grid>

        <ion-row>
          <ion-col class="txt-center" size="12">
            <ion-chip color="tertiary" *ngIf="purchaseReturn?.moneyIns?.length">
              <span>
                <ion-checkbox
                  slot="start"
                  [disabled]="true"
                  [checked]="isDeletePurchaseReturnAttachedMoneyIns"
                  (ionChange)="setCheckboxVarValue('isDeletePurchaseReturnAttachedMoneyIns',$event)"
                ></ion-checkbox>
              </span>
              <ion-label>Also Delete Attached Money In's</ion-label>
            </ion-chip>
            <ion-chip color="tertiary" *ngIf="!purchaseReturn?.moneyIns?.length">
              <ion-label>There is no Money In is Attached with this Purchase Return</ion-label>
            </ion-chip>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col class="txt-center" size="12">
            <ion-button fill="outline" class="dashed-border txt-transform-normal">
              Purchase Return Delete
              <ion-icon *ngIf="purchaseReturn?.deletedStamp" color="success" slot="end" name="checkmark-done"></ion-icon>
            </ion-button>
          </ion-col>
        </ion-row>

        <ion-row *ngIf="isDeletePurchaseReturnAttachedMoneyIns">
          <ion-col class="txt-center" size="12">
            <ion-button fill="outline" class="dashed-border txt-transform-normal">
              All Money In's Delete
            </ion-button>
            <ion-chip color="tertiary" *ngFor="let moneyIn of purchaseReturn?.moneyIns">
              {{ moneyIn?.billNo }}
              <span>
                <ion-icon *ngIf="purchaseReturn?.deletedStamp" color="success" slot="end" name="checkmark-done"></ion-icon>
              </span>
            </ion-chip>
          </ion-col>
        </ion-row>

      </ion-grid>

    </ion-content>

    <ion-toolbar>
      <ion-button
        class="btn-save"
        expand="full"
        color="danger"
        (click)="deletePurchaseReturn()"
      >
        <span *ngIf="!purchaseReturnDeleteStarted">
          Delete
        </span>
        <span *ngIf="purchaseReturnDeleteStarted && !purchaseReturnDeleteCompleted">
          <ion-spinner name="lines-sharp-small"></ion-spinner>
        </span>
        <span *ngIf="purchaseReturnDeleteCompleted">
          Deleted
        </span>
      </ion-button>
    </ion-toolbar>
  </ng-template>
</ion-modal>

<!-- -------------------------------------------------------------------------------------- -->
