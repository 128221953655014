import { NgxIndexedDBService } from "ngx-indexed-db";
import { IDataRepo } from "../../../interface/IDataRepo.interface";
import PartyItemPriceMap, { IPartyItemPriceMap } from "../../models/PartyItemPriceMap.model";
import { Utility } from "../../utils/utility";
import { Router } from "@angular/router";
import { SentryUtilites } from "src/app/utils/sentryUtilites";

export class PartyItemPriceMapDao implements IDataRepo<PartyItemPriceMap>{
    private static _instance: PartyItemPriceMapDao;

    public static getInstance(
        ngxIndexedDBService: NgxIndexedDBService,
        router: Router,
    ) {
        if (!this._instance) {
            this._instance = new PartyItemPriceMapDao(ngxIndexedDBService, router)
        }
        return this._instance;
    }

    constructor(
        ngxIndexedDBService: NgxIndexedDBService,
        router: Router,
        ) {
        this.ngxIndexedDBService = ngxIndexedDBService;
        this.router = router;
      }
    
      ngxIndexedDBService: NgxIndexedDBService;
      router: Router;

    save(data: PartyItemPriceMap): Promise<PartyItemPriceMap> {
        return new Promise((resolve, reject) => {
            try {
                if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && Utility.isTruthy(data)) {
                    let timeStamp = +new Date();
                    data.createdStamp = timeStamp
                    data.updatedStamp = timeStamp
                    data.userUpdatedStamp = timeStamp
                    data.deletedStamp = 0
                    data.syncStamp = 0
    
                    data._localUUID = Utility.getUUID();
    
                    this.ngxIndexedDBService
                        .add(IPartyItemPriceMap.SCHEMA_NAME, data)
                        .subscribe((savedPartyItemPriceMap: PartyItemPriceMap) => {
                            return resolve(savedPartyItemPriceMap);
                        },
                        err => {
                            console.error(err);
                            if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                                this.router.navigate(['idbx-error']);
                            } else if (typeof err?.target?.error == 'object') {
                                SentryUtilites.setLog("PartyItemPriceMapDao:save", err?.target?.error)
                            } else {
                                SentryUtilites.setLog("PartyItemPriceMapDao:save", err)
                            }
                        });
                } else {
                  return resolve(null);
                }
            } catch (err) {
                SentryUtilites.setLog("PartyItemPriceMapDao:save", err)
                return resolve(null);
            }
        });
    }


    saveDb(data: PartyItemPriceMap): Promise<PartyItemPriceMap> {
        return new Promise((resolve, reject) => {
            try {
                if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && Utility.isTruthy(data)) {
                    this.ngxIndexedDBService
                        .add(IPartyItemPriceMap.SCHEMA_NAME, data)
                        .subscribe((savedRecord: PartyItemPriceMap) => {
                            return resolve(savedRecord);
                        },
                        err => {
                            console.error(err);
                            if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                                this.router.navigate(['idbx-error']);
                            } else if (typeof err?.target?.error == 'object') {
                                SentryUtilites.setLog("PartyItemPriceMapDao:saveDb", err?.target?.error)
                            } else {
                                SentryUtilites.setLog("PartyItemPriceMapDao:saveDb", err)
                            }
                        });
                } else {
                  return resolve(null);
                }
            } catch (error) {
                SentryUtilites.setLog("PartyItemPriceMapDao:saveDb", error)
                return resolve(null);
            }
        })
    }

    update(data: PartyItemPriceMap): Promise<PartyItemPriceMap> {
        return new Promise(async (resolve, reject) => {
            try {
                if (Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?._localUUID) {
                    let oldData = await this.getByUUID(data?._localUUID);
                    if(oldData?._localUUID) {
                        data._localId = oldData?._localId;
                        let timeStamp = +new Date();
                        data.updatedStamp = timeStamp
                        data.userUpdatedStamp = timeStamp
                        this.ngxIndexedDBService
                            .update(IPartyItemPriceMap.SCHEMA_NAME, data)
                            .subscribe((updatedPartyItemPriceMap: PartyItemPriceMap) => {
                              return resolve(updatedPartyItemPriceMap);
                            },
                            err => {
                                console.error(err);
                              if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                                    this.router.navigate(['idbx-error']);
                                } else if (typeof err?.target?.error == 'object') {
                                    SentryUtilites.setLog("PartyItemPriceMapDao:update", err?.target?.error)
                                } else {
                                    SentryUtilites.setLog("PartyItemPriceMapDao:update", err)
                                }
                            });
                    } else {
                        return resolve(null);
                    }
                }else {
                  return resolve(null);
                }
            } catch (err) {
                SentryUtilites.setLog("PartyItemPriceMapDao:update", err)
                return resolve(null);
            }
        })
    }

    updateDb(data: PartyItemPriceMap): Promise<PartyItemPriceMap> {
        return new Promise(async (resolve, reject) => {
            try {
                if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?._localUUID) {
                    let oldData = await this.getByUUID(data?._localUUID);
                    if(oldData?._localUUID) {
                        data._localId = oldData?._localId;
                        this.ngxIndexedDBService
                            .update(IPartyItemPriceMap.SCHEMA_NAME, data)
                            .subscribe((updatedRecord: PartyItemPriceMap) => {
                                return resolve(updatedRecord);
                            },
                            err => {
                                console.error(err);
                                if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                                    this.router.navigate(['idbx-error']);
                                } else if (typeof err?.target?.error == 'object') {
                                    SentryUtilites.setLog("PartyItemPriceMapDao:updateDb", err?.target?.error)
                                } else {
                                    SentryUtilites.setLog("PartyItemPriceMapDao:updateDb", err)
                                }
                            });
                    } else {
                        return resolve(null);
                    }
                } else {
                    return resolve(null);
                }
            } catch (err) {
                SentryUtilites.setLog("PartyItemPriceMapDao:updateDb", err)
                return resolve(null);
            }
        })
    }

    bulkPut(data: PartyItemPriceMap[]): Promise<boolean> {
        return new Promise((resolve, reject) => {
          try {
            if(Boolean(Utility.getFromLocalStorage('_ezo_login_token'))) {
                this.ngxIndexedDBService
                  .bulkPut(IPartyItemPriceMap.SCHEMA_NAME, data)
                  .subscribe((data) => {
                    return resolve(true);
                  },
                  err => {
                    console.error(err);
                    if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                        this.router.navigate(['idbx-error']);
                    } else if (typeof err?.target?.error == 'object') {
                        SentryUtilites.setLog("PartyItemPriceMapDao:bulkPut", err?.target?.error)
                    } else {
                        SentryUtilites.setLog("PartyItemPriceMapDao:bulkPut", err)
                    }
                  })
            } else {
              return resolve(false);
            }
          } catch (error) {
            SentryUtilites.setLog("PartyItemPriceMapDao:bulkPut", error)
            return resolve(false);
          }
        })
      }

    delete(data: PartyItemPriceMap): Promise<PartyItemPriceMap> {
        return new Promise(async (resolve, reject) => {
            try {
                if (Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?._localUUID) {
                    let oldData = await this.getByUUID(data?._localUUID);
                    if(oldData?._localUUID) {
                        data._localId = oldData?._localId;
                        let timeStamp = +new Date();
                        data.updatedStamp = timeStamp;
                        data.userUpdatedStamp = timeStamp;
                        data.deletedStamp = timeStamp;
                        this.ngxIndexedDBService
                            .update(IPartyItemPriceMap.SCHEMA_NAME, data)
                            .subscribe((updatedPartyItemPriceMap: PartyItemPriceMap) => {
                                return resolve(updatedPartyItemPriceMap);
                            },
                            err => {
                                console.error(err);
                                if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                                    this.router.navigate(['idbx-error']);
                                } else if (typeof err?.target?.error == 'object') {
                                    SentryUtilites.setLog("PartyItemPriceMapDao:delete", err?.target?.error)
                                } else {
                                    SentryUtilites.setLog("PartyItemPriceMapDao:delete", err)
                                }
                            });
                    } else {
                        return resolve(null);
                    }
                } else {
                    return resolve(null);
                }
            } catch (err) {
                SentryUtilites.setLog("PartyItemPriceMapDao:delete", err)
                return resolve(null);
            }
        });
    }
    getById(id: number): Promise<PartyItemPriceMap> {
        return new Promise((resolve, reject) => {
            try {
                if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && id) {
                    this.ngxIndexedDBService
                        .getByKey(IPartyItemPriceMap.SCHEMA_NAME, id)
                        .subscribe((profile: PartyItemPriceMap) => {
                            return resolve(profile);
                        },
                        err => {
                            console.error(err);
                            if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                                this.router.navigate(['idbx-error']);
                            } else if (typeof err?.target?.error == 'object') {
                                SentryUtilites.setLog("PartyItemPriceMapDao:getById", err?.target?.error)
                            } else {
                                SentryUtilites.setLog("PartyItemPriceMapDao:getById", err)
                            }
                        });
                } else {
                  return resolve(null);
                }
            } catch (err) {
                SentryUtilites.setLog("PartyItemPriceMapDao:getById", err)
                return resolve(null)
            }
        })
    }
    getByUUID(uuid: string): Promise<PartyItemPriceMap> {
        return new Promise((resolve, reject) => {
            try {
                if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && uuid) {
                    this.ngxIndexedDBService
                        .getByIndex(IPartyItemPriceMap.SCHEMA_NAME, '_localUUID', uuid)
                        .subscribe((profile: PartyItemPriceMap) => {
                            return resolve(profile);
                        },
                        err => {
                            console.error(err);
                            if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                                this.router.navigate(['idbx-error']);
                            } else if (typeof err?.target?.error == 'object') {
                                SentryUtilites.setLog("PartyItemPriceMapDao:getByUUID", err?.target?.error)
                            } else {
                                SentryUtilites.setLog("PartyItemPriceMapDao:getByUUID", err)
                            }
                        });
                } else {
                  return resolve(null);
                }
            } catch (err) {
                SentryUtilites.setLog("PartyItemPriceMapDao:getByUUID", err)
                return resolve(null)
            }
        })
    }
    getAll(): Promise<PartyItemPriceMap[]> {
        return new Promise((resolve, reject) => {
            try {
                if(Boolean(Utility.getFromLocalStorage('_ezo_login_token'))) {
                    this.ngxIndexedDBService.getAll(IPartyItemPriceMap.SCHEMA_NAME).subscribe((docs: PartyItemPriceMap[]) => {
                        docs = docs?.filter(x => !x?.deletedStamp);
                        docs?.sort((a, b) => b?.createdStamp - a?.createdStamp);
                        return resolve(docs)
                    },
                    err => {
                        console.error(err);
                        if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                            this.router.navigate(['idbx-error']);
                        } else if (typeof err?.target?.error == 'object') {
                            SentryUtilites.setLog("PartyItemPriceMapDao:getAll", err?.target?.error)
                        } else {
                            SentryUtilites.setLog("PartyItemPriceMapDao:getAll", err)
                        }
                    });
                } else {
                  return resolve([]);
                }
            } catch (err) {
                SentryUtilites.setLog("PartyItemPriceMapDao:getAll", err)
                return resolve([])
            }
        })
    }
    getAllByProfile(profileId: string): Promise<PartyItemPriceMap[]> {
        return new Promise((resolve, reject) => {
            try {
                if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && profileId) {
                    this.ngxIndexedDBService.getAll(IPartyItemPriceMap.SCHEMA_NAME).subscribe((docs: PartyItemPriceMap[]) => {
                        docs = docs?.filter(x => !x?.deletedStamp && x?.profileId == profileId);
                        docs?.sort((a, b) => b?.createdStamp - a?.createdStamp);
                        return resolve(docs)
                    },
                    err => {
                        console.error(err);
                        if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                            this.router.navigate(['idbx-error']);
                        } else if (typeof err?.target?.error == 'object') {
                            SentryUtilites.setLog("PartyItemPriceMapDao:getAllByProfile", err?.target?.error)
                        } else {
                            SentryUtilites.setLog("PartyItemPriceMapDao:getAllByProfile", err)
                        }
                    });
                } else {
                  return resolve([]);
                }
            } catch (err) {
                SentryUtilites.setLog("PartyItemPriceMapDao:getAllByProfile", err)
                return resolve([])
            }
        })
    }

    cachePartyItemMap: {
      [partyId:string]: PartyItemPriceMap[]
    } = null;

    resetCachePartyItemMap() {
      this.cachePartyItemMap = null;
    }

    async getByPartyUUIDItemUUID(profileId: string,partyUUID: string,itemUUID: string): Promise<PartyItemPriceMap> {
      return new Promise(async (resolve, reject) => {
          try {
            let partyItemPriceMapArr = [];
            if(this.cachePartyItemMap && this.cachePartyItemMap[partyUUID]) {
              partyItemPriceMapArr = this.cachePartyItemMap[partyUUID];
            }else {
              let allRecords = await this.getAllByProfile(profileId);
              allRecords?.forEach(record => {
                if(record?.partyUUID == partyUUID) {
                  partyItemPriceMapArr.push(record);
                }
              });
              if(this.cachePartyItemMap) {
                this.cachePartyItemMap[partyUUID] = partyItemPriceMapArr;
              }else {
                this.cachePartyItemMap = {
                  [partyUUID]: partyItemPriceMapArr
                };
              }
            }
            return resolve(partyItemPriceMapArr.filter(x => x?.itemUUID == itemUUID)[0]);
          } catch (err) {
            SentryUtilites.setLog("PartyItemPriceMapDao:getByPartyUUIDItemUUID", err)
            return resolve(null)
          }
      })
    }

    getAllUnsynced(profileId: string): Promise<PartyItemPriceMap[]> {
        return new Promise((resolve, reject) => {
            try {
                if(Boolean(Utility.getFromLocalStorage('_ezo_login_token'))) {
                    let unSyncedElements: PartyItemPriceMap[] = [];
                    this.ngxIndexedDBService.getAll(IPartyItemPriceMap.SCHEMA_NAME).subscribe(async (elArr: PartyItemPriceMap[]) => {
                        for (let i = 0; i < elArr?.length; i++) {
                            const el = elArr[i];
                            if (el?.updatedStamp > el?.syncStamp || !el?._serverIdRef) {
                                unSyncedElements.push(el);
                            }
                        }
                        return resolve(unSyncedElements)
                    },
                    err => {
                        console.error(err);
                        if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                            this.router.navigate(['idbx-error']);
                        } else if (typeof err?.target?.error == 'object') {
                            SentryUtilites.setLog("PartyItemPriceMapDao:getAllUnsynced", err?.target?.error)
                        } else {
                            SentryUtilites.setLog("PartyItemPriceMapDao:getAllUnsynced", err)
                        }
                    });
                } else {
                  return resolve([]);
                }
            } catch (err) {
                SentryUtilites.setLog("PartyItemPriceMapDao:getAllUnsynced", err)
                return resolve([])
            }
        })
    }
    updateSyncStamp(data: PartyItemPriceMap): Promise<PartyItemPriceMap> {
        return new Promise(async (resolve, reject) => {
            try {
                if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?._localUUID) {
                    this.ngxIndexedDBService
                    .getByIndex(IPartyItemPriceMap.SCHEMA_NAME, '_localUUID', data?._localUUID)
                    .subscribe((dbEl: PartyItemPriceMap) => {
                        if(dbEl) {
                                dbEl.syncStamp = data?.syncStamp || 0;
                                dbEl._serverIdRef = data?._serverIdRef;
                                this.ngxIndexedDBService
                                    .update(IPartyItemPriceMap.SCHEMA_NAME, dbEl)
                                    .subscribe((updatedEl: PartyItemPriceMap) => {
                                        return resolve(updatedEl);
                                    },
                                    err => {
                                        console.error(err);
                                        if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                                            this.router.navigate(['idbx-error']);
                                        } else if (typeof err?.target?.error == 'object') {
                                            SentryUtilites.setLog("PartyItemPriceMapDao:updateSyncStamp", err?.target?.error)
                                        } else {
                                            SentryUtilites.setLog("PartyItemPriceMapDao:updateSyncStamp", err)
                                        }
                                    });
                            } else {
                              return resolve(null);
                            }
                        },
                        err => {
                            console.error(err);
                            if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                                this.router.navigate(['idbx-error']);
                            } else if (typeof err?.target?.error == 'object') {
                                SentryUtilites.setLog("PartyItemPriceMapDao:updateSyncStamp", err?.target?.error)
                            } else {
                                SentryUtilites.setLog("PartyItemPriceMapDao:updateSyncStamp", err)
                            }
                        })
                } else {
                    return resolve(null);
                }
            } catch (err) {
                SentryUtilites.setLog("PartyItemPriceMapDao:updateSyncStamp", err)
                return resolve(null);
            }
        })
    }



}
