import { Injectable } from '@angular/core';
import { Order, OrderStatus } from '../models/Order.model';
import { ExpressServerService } from './api/express-server.service';
import { AuthService } from './auth/auth.service';
import { Utility } from '../utils/utility';
import { SentryUtilites } from '../utils/sentryUtilites';

@Injectable({
  providedIn: 'root'
})
export class OrderService {


  constructor(
    private expressServerService: ExpressServerService,
    private authService: AuthService,
  ) { }

  async getAll(startStamp: number,endStamp: number,status: OrderStatus): Promise<Order[]>  {
    try {
      let path = `order/getAll?startStamp=${startStamp}&endStamp=${endStamp}&status=${status}`;
      let res = await this.expressServerService.makeGetAllCall(path);
      let orders: Order[] = res?.['records'] || [];
      if(orders?.length) {
        let profileId = Utility.getFromLocalStorage('selectedProfile');
        orders = orders?.filter(order => order?.profileId == profileId);
      }
      return orders;
    } catch (error) {
      SentryUtilites.setLog("OrderService:getAll", error)
      return [];
    }
  }

  async getByUUID(_localUUID: string): Promise<Order> {
    try {
      let profileId = Utility.getFromLocalStorage('selectedProfile');
      let userId = Utility.getFromLocalStorage('selectedProfileUserId');
      let res = await this.expressServerService.makeEzoOfServerPostCall('order/getByUUID',{_localUUID,profileId,userId});
      return res?.['record'] || null;
    } catch (error) {
      SentryUtilites.setLog("OrderService:getByUUID", error)
      return null;
    }
  }

  async update(order: Order): Promise<Order> {
    try {
      order.updatedStamp = +new Date();
      order.userUpdatedStamp = order.updatedStamp;
      order.lastModifiedBy = this.authService.getLoginPhone();
      order.lastModifiedByName = Utility.getCreatedByName();
      delete order?.['_id'];
      let res = await this.expressServerService.makeEzoOfServerPostCall('order/save', {order});
      return res?.['record'] || null;
    } catch (error) {
      SentryUtilites.setLog("OrderService:update", error)
      return null;
    }
  }
  
}
