import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Utility } from '../../utils/utility';
import { AllDataService } from '../../services/all-data.service';
import { AuthService } from '../../services/auth/auth.service';
import { HtmlTemplates } from '@makarandkate/invoice-templates/lib';
import { DeleteDataComponent } from '../../components/delete-data/delete-data.component';
import Party from '../../models/Party.model';
import { SettingsComponent } from '../../components/settings/settings.component';
import { PinVerificationComponent } from '../../components/pin-verification/pin-verification.component';
import { AccessControlService } from '../../services/auth/access-control.service';
import { Profile } from '../../models/Profile.model';
import { MoneyOut } from 'src/app/models/MoneyOut.model';
import { Expense } from 'src/app/models/Expense.model';
import { ExpenseBillPrint } from '@makarandkate/invoice-templates/lib/models/ExpenseBillPrint.model';
import { BaseBillView } from 'src/app/base/base-bill-view';
import { PremiumControlService } from '../../services/auth/premium-control.service';
import { AlertController } from '@ionic/angular';
import { ImageBase64Service } from '../../services/image-base64.service';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
    selector: 'app-bill-view-expense',
    templateUrl: './bill-view-expense.page.html',
    styleUrls: ['./bill-view-expense.page.scss'],
})
export class BillViewExpensePage extends BaseBillView<Expense, ExpenseBillPrint, MoneyOut> implements OnInit {

    @ViewChild('pinVerificationElement') pinVerificationElement: PinVerificationComponent;
    @ViewChild('settingsModal') settingsModal: SettingsComponent;

    @ViewChild('deleteDataEle') deleteDataEle: DeleteDataComponent;
    @ViewChild('div', { static: true }) div: any;

    selectedTemplate: string;
    record: Expense;
    selectedExpenseTemplate: 'temp1Expense' | 'temp17Expense' | 'temp18Expense' | 'temp19Expense' | 'temp20Expense' = Utility.getFromLocalStorage('selectedExpenseTemplate') || 'temp18Expense';
    billPrint = new ExpenseBillPrint();
    expense: Expense = null;
    billDownloadEndpoint: string = 'https://db.ezobooks.in/kappa/billView/expense';

    constructor(
        private router: Router,
        private accessControlService: AccessControlService,
        private alertController: AlertController,
        allDataService: AllDataService,
        authService: AuthService,
        route: ActivatedRoute,
        premiumControlService: PremiumControlService,
        imageBase64Service: ImageBase64Service,
    ) {
        super(
            route,
            allDataService,
            authService,
            premiumControlService,
            imageBase64Service,
        );
    }

    ngOnInit = async () => {
        this.baseNgOnInit();
    }

    async ionViewWillEnter() {
        this.selectedTemplate = Utility.getFromLocalStorage('selectedExpenseTemplate') || 'temp18Expense';
        this.baseIonViewWillEnter();
    }

    ionViewWillLeave() {
        this.ngOnDestroy();
    }

    ngOnDestroy() {
        this.baseNgOnDestroy();
    }

    openTransactionPINModal() {
        this.pinVerificationElement?.openTransactionPINModal();
    }

    verifyTransactionPIN(event) {
        try {
            if (event) {
                this.router.navigate([`expense/form/${this.record?._localUUID}`]);
            }
        } catch (error) {
            SentryUtilites.setLog("BillViewExpensePage:verifyTransactionPIN", error)
        }
    }
    setTemplate(event) {
        try {
            this.selectedTemplate = event?.detail?.value;
            Utility.setToLocalStorage('selectedExpenseTemplate', this.selectedTemplate);
            this.populateTemplate();
        } catch (error) {
            SentryUtilites.setLog("BillViewExpensePage:setTemplate", error)
        }
    }

    async edit() {
        try {
            let isPermit = await this.accessControlService.isUserHasAccess({ action: 'editExpense' });
            if (!isPermit) {
                return alert("Permission: You don't have permission to edit sale. Please contact to your owner.");
            }
            this.openTransactionPINModal();
        } catch (error) {
            SentryUtilites.setLog("BillViewExpensePage:edit", error)
            alert("Something went wrong.");
        }
    }

    async delete() {
        try {
            let isPermit = await this.accessControlService.isUserHasAccess({ action: 'deleteExpense' });
            if (!isPermit) {
                return alert("Permission: You don't have permission to delete purchase. Please contact to your owner.");
            }
            this.deleteDataEle?.initDeleteExpense(this.expense);
        } catch (error) {
            SentryUtilites.setLog("BillViewExpensePage:setBillPrint", error)
            alert("Something went wrong.");
        }
    }

    async navigateToBill() {
        try {
            let isPermit = await this.accessControlService.isUserHasAccess({ action: 'createSale' });
            if (!isPermit) {
                return alert("Permission: You don't have permission to create new sale. Please contact to your owner.");
            }
            this.router.navigate([`expense/form`]);
        } catch (error) {
            SentryUtilites.setLog("BillViewExpensePage:navigateToBill", error)
            alert("Something went wrong.");
        }
    }

    openTNCSetting(): void {
        try {
            if (this.settingsModal) {
                this.settingsModal.openSettingsModal();
                this.settingsModal.viewSectionIds = [5];
                this.settingsModal.viewSettingKeys = ['pSetTermsAndConditions'];
            }
        } catch (error) {
            SentryUtilites.setLog("BillViewExpensePage:openTNCSetting", error)
            alert("Something went wrong.");
        }
    }

    async setTermsAndConditions() {
        return null;
    }

    async setRecord(paramDocumentId: string): Promise<Expense> {
        try {
            this.record = await this.allDataService.expenseService.getByUUID(paramDocumentId);
            if(this.record?.deletedStamp) {
                const alert = await this.alertController.create({
                    header: 'Alert!',
                    message: `Bill Deleted by ${this.record?.lastModifiedByName || this.record?.lastModifiedBy} on ${Utility.setDateValue(this.record?.deletedStamp)}`,
                    mode: 'ios',
                    buttons: [
                      {
                        text: 'Go Back',
                        role: 'cancel',
                        handler: () => {
                            this.router.navigate(['expense']);
                            return null;
                         },
                      },
                    ],
                });
                await alert.present();
            } else {
                return this.record;
            }
        } catch (error) {
            SentryUtilites.setLog("BillViewExpensePage:setRecord", error)
            return this.record;
        }
    }

    async getMoneyInOut(): Promise<MoneyOut> {
        try {
            if (this.record?.moneyOuts?.length && this.record?.moneyOuts[0]?._localUUID) {
                return await this.allDataService.moneyOutService.getByUUID(this.record?.moneyOuts[0]?._localUUID);
            }
            return null;
        } catch (error) {
            SentryUtilites.setLog("BillViewExpensePage:getMoneyInOut", error)
            return null;
        }
    }

    setBillPrint(
        tokenDetails: any,
        profile: Profile,
        signature: any,
        logo: any,
        party: Party,
        secondaryParty: Party,
    ): void {
        try {
            this.billPrint = HtmlTemplates.generateExpenseBillObject({
                user: {
                    isPro: tokenDetails?.isPro,
                    registrationStamp: tokenDetails?.createdStamp
                },
                profile,
                signature,
                logo,
                party,
                expense: this.record,
                moneyOut: this.moneyInOut,
                isPartyDeleted: this.isPartyDeleted,
            });
        } catch (error) {
            SentryUtilites.setLog("BillViewExpensePage:setBillPrint", error)
        }
    }

    setTemplateToHtmlDiv(htmlString: any): void {
        try {
            this.div.nativeElement.innerHTML = htmlString;
        } catch (error) {
            SentryUtilites.setLog("BillViewExpensePage:setTemplateToHtmlDiv", error)
        }
    }


}
