import { AllDataService } from './../../services/all-data.service';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Output, EventEmitter, Input } from '@angular/core';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { Image as ImageModel } from '../../models/image.model';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss'],
})
export class ImageCropperComponent implements OnInit,AfterViewInit {

  @Input() targetImageWidth: number = 256;
  @Input() targetImageHeight: number = 256;
  @Input() maintainAspectRatio = false;

  @Output() modalClosed = new EventEmitter<boolean>();
  @Output() imageSaved = new EventEmitter<ImageModel>();

  @ViewChild('fileEvent') fileEvent: ElementRef;
  @ViewChild('imageChooserEle') imageChooserEle: ElementRef;

  isModalOpen = false;
  imageChangedEvent: any = '';
  croppedImage: any = '';

  isImageLoader = true;

  constructor(
    private allDataService: AllDataService,
  ) { }

  ngOnInit() { }

  ngAfterViewInit() {
    this.fileEvent?.nativeElement?.click();
  }

  cancelFileDialog(event) {
    this.modalClosed.emit(true)
  }

  openModal(value: boolean = true) {
    try {
      this.isModalOpen = value;
      if(value === false) {
        setTimeout(() => {
          this.modalClosed.emit(true);
        }, 1000);
      }
    } catch (error) {
      SentryUtilites.setLog("ImageCropperComponent:openModal", error)
    }
  }

  fileChangeEvent(event: any): void {
    this.openModal(true)
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded(image: LoadedImage) { }

  cropperReady() {
    this.isImageLoader = false;
  }

  loadImageFailed() { }

  async upload() {
    try {
      const targetBase64 = this.convertCroppedImageDimensions();
      const image = new ImageModel();
      image.imageBase64 = targetBase64.replace('data:image/png;base64,','');
      const savedImage = await this.allDataService.imageService.save(image);
      if(savedImage?._localUUID) {
        this.allDataService.listForceReloadSubs.next('image-list');
        this.imageSaved.emit(savedImage);
        this.openModal(false);
      }
    } catch (error) {
      SentryUtilites.setLog("ImageCropperComponent:upload", error)
    }
  }

  convertCroppedImageDimensions() {
    try {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
  
      canvas.width = this.targetImageWidth;
      canvas.height = this.targetImageHeight;
  
      const image = new Image();
      image.src = this.croppedImage;
  
      context.drawImage(image,0,0,canvas.width,canvas.height);
  
      const targetBase64 = canvas.toDataURL();
  
      return targetBase64;
    } catch (error) {
      SentryUtilites.setLog("ImageCropperComponent:convertCroppedImageDimensions", error)
      return '';
    }
  }

}
