import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Utility } from '../../../utils/utility';
import { Ingredient } from '../../../models/Ingredient.model';
import { AllDataService } from '../../../services/all-data.service';
import { AlertController, InfiniteScrollCustomEvent, IonFab, LoadingController, ToastController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { IngredientStockAdjustComponent } from '../../../components/ingredient-stock-adjust/ingredient-stock-adjust.component';
import { IngredientService } from '../../../services/ingredient.service';
import { ActionType, IngredientStockAdjust } from '../../../models/IngredientStockAdjust.model';
import { IngredientStockAdjustService } from '../../../services/ingredient-stock-adjust.service';
import { CommonService } from '../../../services/common.service';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-ingredient-edit',
  templateUrl: './ingredient-edit.page.html',
  styleUrls: ['./ingredient-edit.page.scss'],
})
export class IngredientEditPage implements OnInit {

  @Input() searchText = '';
  
  @ViewChild('searchBar') searchBar;
  @ViewChild('ingredientStockAdjustEle') ingredientStockAdjustEle: IngredientStockAdjustComponent;
  @ViewChild('filterByFab') filterByFab: IonFab;
  @ViewChild('sortByFab') sortByFab: IonFab;

  getHeaderColorClass = Utility.getHeaderColorClass;

  filteredList: Ingredient[] = [];
  completeList: Ingredient[] = [];
  viewFilteredList: Ingredient[] = [];

  appliedCategory = '';
  selectedFilter = '';

  isFilterOptionOpen = false;

  categories: string[] = [];
  categoryWiseIngredientCount = {};
  ingredientStockAdjusts: IngredientStockAdjust[] = [];

  subsArr: Subscription[] = [];

  ionModalLeftShift: string = '';

  loading: HTMLIonLoadingElement = null;

  paramDocumentId: string = null;
  paramAction: 'purchase' | 'scrap' | 'transfer' | 'return' | 'eod' = null;

  headerTitles = {
    'purchase': 'Purchase Entry',
    'scrap': 'Scrap Entry',
    'transfer': 'Transfer Entry',
    'return': 'Return Entry',
    'eod': 'EOD Stock Entry',
  };

  entriesToUpdate: {
    [key: string]: {
      quantity?: number;
      price?: number;
      unit?: string;
      existingTransaction?: IngredientStockAdjust;
    };
  } = {};

  shortStr = Utility.shortStr;

  returnZero = Utility.returnZero;

  sortObj = {
    "SortByNameAsc": "A to Z",
    "SortByNameDesc": "Z to A",
    "SortByStockAsc": "Stock Ascending",
    "SortByStockDesc": "Stock Descending",
    "lowStockByAsc": "Low Stock By Ascending",
  };

  isMobile: boolean = null;
  innerHeight: string = '';

  isNoteModalOpen: boolean = false;
  note: string = '';

  constructor(
    private allDataService: AllDataService,
    private ingredientService: IngredientService,
    private ingredientStockAdjustService: IngredientStockAdjustService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private alertController: AlertController,
    private commonService: CommonService,
    private loadingCtrl: LoadingController,
    private toastController: ToastController,
  ) { }

  ngOnInit() {
    try {
      this.isMobile = this.commonService.isMobile();
      let screenInnerWidth = window?.innerWidth;
      if(this.isMobile) {
        this.innerHeight = `${(((window?.innerHeight - 327)/window?.innerHeight) * 100)}%`;
      } else {
        this.innerHeight = `${(((window?.innerHeight - 120)/window?.innerHeight) * 100)}%`;
        this.ionModalLeftShift = `${((screenInnerWidth - (screenInnerWidth - 289))/ screenInnerWidth) * 100}%`
      }
    } catch (error) {
      SentryUtilites.setLog("IngredientEditPage:ngOnInit", error)
    }
   }

  async ngAfterViewInit() { 
    try {
      await this.showLoading('Loading Raw Material list...');
      this.paramAction = this.activatedRoute.snapshot.paramMap.get('action') as any;
      this.paramDocumentId = this.activatedRoute.snapshot.paramMap.get('documentId');
      this.loadView();
    } catch (error) {
      SentryUtilites.setLog("IngredientEditPage:ngAfterViewInit", error)
    }
  }

  openNoteModal(value: boolean = true) {
    this.isNoteModalOpen = value;
  }

  async getCategories() {
    try {
      let profile = await this.allDataService.profileService.getCurrentProfile();
      if(profile?.ingredientCategories?.length && Array.isArray(profile?.ingredientCategories)) {
        this.categories = profile?.ingredientCategories;
        this.categories?.sort();
        this.categoryWiseIngredientCount = {};
        this.categories?.forEach(category => {
          this.categoryWiseIngredientCount[category] = this.completeList?.filter(ingredient => ingredient?.category?.toLowerCase()==category?.toLowerCase())?.length;
        });
      }
    } catch (error) {
      SentryUtilites.setLog("IngredientEditPage:getCategories", error)
    }
  }

  closeclickOutSideSort() {
    this.sortByFab.close()
  }

  closeclickOutSideFilter() {
    this.filterByFab.close()
  }

  async loadView() {
    try {
      this.filteredList = [];
      this.completeList = [];
      this.viewFilteredList = [];
  
      this.appliedCategory = '';
      this.selectedFilter = 'SortByNameAsc';
  
      this.isFilterOptionOpen = false;
  
      this.categories = [];
  
      if(this.paramDocumentId) {
        try {
          let ingredient = await this.ingredientService.getWithOpeningStockByUUID(this.paramDocumentId);
          this.completeList = [ingredient];
        } catch (error) {
          this.loading?.dismiss();
          await this.showToast('Something went wrong, Please try again later.', 'danger');
        }
      }else {
        try {
          this.completeList = await this.ingredientService.getAllWithOpeningStockByProfile();
        } catch (error) {
          this.loading?.dismiss();
          await this.showToast('Something went wrong, Please try again later.', 'danger');
        }
      }
  
      this.onFilterChange();
      this.getCategories();
    } catch (error) {
      SentryUtilites.setLog("IngredientEditPage:loadView", error)
    }

  }

  toggleFilterOptions() {
    this.isFilterOptionOpen = !this.isFilterOptionOpen;
  }

  onCategoryChange(category: string) {
    this.appliedCategory = category;
    this.onFilterChange();
  }

  onSortChnage(selectedFilter: string) {
    this.selectedFilter = selectedFilter;
    this.onFilterChange();
  }

  isFilterPostpond = false;
  lastFilterStamp = 0;
  onFilterChange(searchKey?: string) {
    try {
      if (this.isFilterPostpond) {
        return true;
      }
      if (this.lastFilterStamp < +new Date() - 200) {
        this.lastFilterStamp = +new Date();
        return this.applyFilter({
          searchKeyword: searchKey,
          selectedFilter: this.selectedFilter,
          category: this.appliedCategory
        })
      } else {
        this.isFilterPostpond = true;
        setTimeout(() => {
          this.isFilterPostpond = false;
          this.onFilterChange(searchKey);
        }, 250);
      }
    } catch (error) {
      SentryUtilites.setLog("IngredientEditPage:onFilterChange", error)
    }
  }

  lastSearchKey = '';
  async applyFilter(params: {
    searchKeyword?: string,
    selectedFilter?: string,
    category?: string
  }) {
    try {
      let arr: Ingredient[] = [];
      let lsearchKey = '';
      let isSearchIncremental = false;
      let isSearchCleared = false;
      if (params?.searchKeyword?.length) {
        lsearchKey = Utility.toLowerCase(params.searchKeyword);
        if (lsearchKey.indexOf(this.lastSearchKey) == 0) {
          // isSearchIncremental = true;
          // TODO
          isSearchIncremental = false;
        }
        this.lastSearchKey = lsearchKey;
      }
      if (this.lastSearchKey.length > 0 && lsearchKey.length == 0) {
        isSearchCleared = true;
        this.lastSearchKey = '';
      }
      let completeList = [];
      if (isSearchIncremental) {
        completeList = [...this.filteredList]
      } else {
        completeList = [...this.completeList];
      }
  
      if (params?.category) {
        completeList?.forEach((el) => {
          if (el.category == params.category) {
            arr.push(el);
          }
        });
        arr = arr.filter((el) => {
          if (Utility.toLowerCase(el?.name).indexOf(lsearchKey) != -1) {
            return el;
          }
        });
      } else if (params?.category) {
        completeList?.forEach((el) => {
          if (el?.category == params?.category) {
            arr.push(el);
          }
        });
      } else if (lsearchKey.length) {
        completeList?.forEach((el) => {
          if (Utility.toLowerCase(el?.name).indexOf(lsearchKey) != -1) {
            arr.push(el);
          }
        });
  
      } else {
        arr = [...completeList]
      }
  
  
      if (params.selectedFilter == 'SortByNameAsc') {
        arr.sort((a: Ingredient, b: Ingredient) => {
          if (a?.name?.toLowerCase() == b?.name?.toLowerCase()) {
            return a?.createdStamp - b?.createdStamp;
          }
          if (a?.name?.toLowerCase() > b?.name?.toLowerCase()) {
            return 1;
          }
          return -1;
        })
      } else if (params.selectedFilter == 'SortByNameDesc') {
        arr.sort((a: Ingredient, b: Ingredient) => {
          if (a?.name?.toLowerCase() == b?.name?.toLowerCase()) {
            return a?.createdStamp - b?.createdStamp;
          }
          if (b?.name?.toLowerCase() > a?.name?.toLowerCase()) {
            return 1;
          }
          return -1;
        })
      } else if (params.selectedFilter == 'SortByStockAsc') {
        arr.sort((a: Ingredient, b: Ingredient) => a?.stock-b?.stock);
      } else if (params.selectedFilter == 'SortByStockDesc') {
        arr.sort((a: Ingredient, b: Ingredient) => b?.stock-a?.stock);
      } else if (params.selectedFilter == 'lowStockByAsc') {
        arr = arr.filter(item => {
          const stock = Number(item?.stock);
          let minStock = Number(item?.minStock);
          minStock = minStock || 0;
          if (
            Utility.isNumber(stock)
            && Utility.isNumber(minStock)
            && stock <= minStock
          ) {
            return true;
          }else {
            return false;
          }
        });
        arr.sort((a: Ingredient, b: Ingredient) => {
          if (a?.name?.toLowerCase() == b?.name?.toLowerCase()) {
            return a?.createdStamp - b?.createdStamp;
          }
          if (b?.name?.toLowerCase() > a?.name?.toLowerCase()) {
            return 1;
          }
          return -1;
        })
      }
  
      this.filteredList = [...arr];
  
      if (lsearchKey.length || params.category || params.selectedFilter || isSearchCleared) {
        this.viewFilteredList = [...this.filteredList.slice(0, 50)];
      } else if (!this.viewFilteredList.length) {
        this.viewFilteredList = [...this.filteredList.slice(0, 50)];
      }
      this.loading?.dismiss();
  
      return true;
    } catch (error) {
      SentryUtilites.setLog("IngredientEditPage:applyFilter", error)
      return false;
    }
  }


  loadMoreListData(event) {
    try {
      if (this.viewFilteredList.length > 0 && this.viewFilteredList.length <= this.filteredList.length) {
        let appendListLength = this.filteredList.length - this.viewFilteredList.length;
        let lastEl = this.viewFilteredList[this.viewFilteredList.length - 1];
        let counter = 0;
        for (let i = 0; i < this.filteredList.length; i++) {
          if (this.filteredList[i]._localUUID == lastEl._localUUID) {
            counter = 1;
            continue;
          }
          if (counter > 0 && appendListLength >= 50) {
            if (counter <= 50) {
              this.viewFilteredList.push({ ...this.filteredList[i] })
            } else {
              break;
            }
            counter++;
          } else if(counter > 0 && appendListLength < 50) {
            if (counter <= appendListLength) {
              this.viewFilteredList.push({ ...this.filteredList[i] })
            } else {
              break;
            }
            counter++;
          }
        }
        (event as InfiniteScrollCustomEvent).target.complete();
      }
    } catch (error) {
      SentryUtilites.setLog("IngredientEditPage:loadMoreListData", error)
    }
  }

  autoSearch(value: string) {
    if (value && this.searchBar?.el) {
      this.searchBar.el.value = value;
    }
  }

  resetCategory() {
    this.appliedCategory = '';
    this.toggleFilterOptions();
    this.onFilterChange();
  }

  resetSort() {
    this.selectedFilter = '';
    this.toggleFilterOptions();
    this.onFilterChange();
  }

  ngOnDestroy() {
    this.subsArr?.forEach((el) => {
      el.unsubscribe();
    })
  }

  async saveIngredientStockAdjusts() {
    try {
      this.ingredientStockAdjusts = []; 
      for(let key in this.entriesToUpdate) {
        let ingredientStockAdjust = this.entriesToUpdate[key]?.existingTransaction || new IngredientStockAdjust();
        ingredientStockAdjust.actionType = this.getEnumBasedActionType();
        ingredientStockAdjust.unit = this.entriesToUpdate[key].unit;
        if(ingredientStockAdjust?.actionType === ActionType.Purchase) {
          if(
            this.entriesToUpdate[key] 
            && this.entriesToUpdate[key]?.price
            && this.entriesToUpdate[key]?.quantity
            && Utility.isNumber(this.entriesToUpdate[key]?.price)
            && Utility.isNumber(this.entriesToUpdate[key]?.quantity)
          ) {
            ingredientStockAdjust.quantity = Number(this.entriesToUpdate[key]?.quantity);
            ingredientStockAdjust.price = Number(this.entriesToUpdate[key]?.price);
            ingredientStockAdjust.linkedIngredientUUID = key;
            this.ingredientStockAdjusts.push(ingredientStockAdjust);
          }
        } else if(this.paramAction === 'eod') {
          if(
            this.entriesToUpdate[key]
          ) {
            ingredientStockAdjust.quantity =this.entriesToUpdate[key]?.quantity !== null ? Number(this.entriesToUpdate[key]?.quantity) : null;
            ingredientStockAdjust.linkedIngredientUUID = key;
            this.ingredientStockAdjusts.push(ingredientStockAdjust);
          }
        } else {
          if(
            this.entriesToUpdate[key] 
            && this.entriesToUpdate[key]?.quantity
            && Utility.isNumber(this.entriesToUpdate[key]?.quantity)
          ) {
            if(ingredientStockAdjust?.actionType === ActionType.EOD) {
              ingredientStockAdjust.quantity = Number(this.entriesToUpdate[key]?.quantity);
            } else {
              ingredientStockAdjust.quantity = Number(this.entriesToUpdate[key]?.quantity) * -1;
            }
            ingredientStockAdjust.linkedIngredientUUID = key;
            this.ingredientStockAdjusts.push(ingredientStockAdjust);
          }
        }
      }
  
      if(this.paramAction === 'eod' && this.ingredientStockAdjusts?.length) {
        let index = this.ingredientStockAdjusts.findIndex(ingredient => ingredient?.quantity !== null);
        if(index === -1) {
          const alert = await this.alertController.create({
            header: 'Alert!',
            message: `Please add quantity`,
            mode: 'ios',
            cssClass: 'raw-material-entry-save',
            buttons: [
              {
                text: 'Okay',
                role: 'cancel',
              },
            ],
          });
          await alert.present();
        } else {
          this.openNoteModal();
        }
      } else if(this.ingredientStockAdjusts?.length) {
        this.openNoteModal();
      } else if (this.paramAction !== 'eod') {
        const alert = await this.alertController.create({
          header: 'Alert!',
          message: `Please add quantity ${this.paramAction === 'purchase' ? 'and price' : ''}`,
          mode: 'ios',
          cssClass: 'raw-material-entry-save',
          buttons: [
            {
              text: 'Okay',
              role: 'cancel',
            },
          ],
        });
  
        await alert.present();
      }
    } catch (error) {
      SentryUtilites.setLog("IngredientEditPage:saveIngredientStockAdjusts", error)
    }

  }

  async save() {
    try {
      this.openNoteModal(false);
  
      this.ingredientStockAdjusts?.forEach(ingredientStockAdjust => {
        ingredientStockAdjust['note'] = this.note;
      })
  
      if(this.ingredientStockAdjusts?.length) {
        this.ingredientStockAdjusts.map(x => x._localUUID = x?.actionType === ActionType.EOD ? x._localUUID : null);
        try {
          let records = await this.ingredientStockAdjustService.saveAll(this.ingredientStockAdjusts);
          if(records?.length) {
            setTimeout(() => {
              this.router.navigate(['ingredient']);
            }, 200);
            return null
          }
        } catch (error) {
          await this.showToast('Something went wrong, Please try again later.', 'danger');
        }
      }
    } catch (error) {
      SentryUtilites.setLog("IngredientEditPage:save", error)
      return null;
    }

  }

  onIngredientEdit(fieldName: string, event: any, localUUID: string, unit: string, existingTransaction: IngredientStockAdjust) {
    try {
      let fieldValue;
      if(this.paramAction !== 'eod') {
        fieldValue = Math.abs(Number(event?.target?.value));
      } else {
        fieldValue = event?.target?.value?.length > 0 && Number(event?.target?.value) >= 0 ? Number(event?.target?.value) : null;
      }
      if(this.entriesToUpdate[localUUID]) {
        this.entriesToUpdate[localUUID][fieldName] = fieldValue;
        this.entriesToUpdate[localUUID]['unit'] = unit;
        this.entriesToUpdate[localUUID]['existingTransaction'] = existingTransaction;
      }else {
        this.entriesToUpdate[localUUID] = {};
        this.entriesToUpdate[localUUID][fieldName] = fieldValue;
        this.entriesToUpdate[localUUID]['unit'] = unit;
        this.entriesToUpdate[localUUID]['existingTransaction'] = existingTransaction;
      }
    } catch (error) {
      SentryUtilites.setLog("IngredientEditPage:onIngredientEdit", error)
    }
  }

  getEnumBasedActionType(): ActionType {
    try {
      if(this.paramAction == 'purchase')
        return ActionType.Purchase;
      else if(this.paramAction == 'scrap')
        return ActionType.Scrap; 
      else if(this.paramAction == 'transfer')
        return ActionType.Transfer; 
      else if(this.paramAction == 'return')
        return ActionType.Return; 
      else if(this.paramAction == 'eod')
        return ActionType.EOD; 
    } catch (error) {
      SentryUtilites.setLog("IngredientEditPage:getEnumBasedActionType", error)
    }
  }

  setNote(event: any) {
    this.note = event?.target?.value;
  }

  async showLoading(msg: string) {
    try {
      this.loading = await this.loadingCtrl.create({ 
        message: msg,
        cssClass: 'raw-material-loader'
      });
      this.loading?.present();
      
    } catch (error) {
      SentryUtilites.setLog("IngredientEditPage:showLoading", error)
    }
  }

  @HostListener('window:resize', ['$event'])
  OnResize() {
    try {
      setTimeout(() => {
        this.isMobile = this.commonService.isMobile();
        let screenInnerWidth = window?.innerWidth;
        if(this.isMobile) {
          this.innerHeight = `${(((window?.innerHeight - 327)/window?.innerHeight) * 100)}%`;
        } else {
          this.ionModalLeftShift = `${((screenInnerWidth - (screenInnerWidth - 289))/ screenInnerWidth) * 100}%`
          this.innerHeight = `${(((window?.innerHeight - 120)/window?.innerHeight) * 100)}%`;
        }
      }, 50)
    } catch (error) {
      SentryUtilites.setLog("IngredientEditPage:OnResize", error)
    }
  }

  async showToast(msg: string, toastColor: string) {
    try {
      const toast = await this.toastController.create({
        message: msg,
        duration: 2000,
        color: toastColor,
      });
      await toast.present();
    } catch (error) {
      SentryUtilites.setLog("IngredientEditPage:showToast", error)
    }
  }

}
