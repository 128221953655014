import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { MoneyOut } from '../../../models/MoneyOut.model';
import { Utility } from 'src/app/utils/utility';

@Component({
  selector: 'app-money-out-form-page',
  templateUrl: './money-out-form.page.html',
  styleUrls: ['./money-out-form.page.scss'],
})
export class MoneyOutFormPage implements OnInit {

  getHeaderColorClass = Utility.getHeaderColorClass;

  paramDocumentId: string = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toastController: ToastController,
  ) { }

  ngOnInit() {
    this.getParamDocumentId();
  }

  ngAfterViewInit() { }

  ngDestory() { }

  getParamDocumentId() {
    this.paramDocumentId = this.route.snapshot.paramMap.get('documentId');
  }

  async savedMoneyOut(moneyOut: MoneyOut) {
    if (moneyOut?._localUUID) {
      const toast = await this.toastController.create({
        header: "Money Out Saved Successfully",
        duration: 2000,
        position: 'top',
        mode: 'ios',
        color: 'success',
      });
      await toast.present();
      this.router.navigate(['money-out']);
    }
  }

  async updatedMoneyOut(moneyOut: MoneyOut) {
    if (moneyOut?._localUUID) {
      const toast = await this.toastController.create({
        header: "Money Out Updated Successfully",
        duration: 2000,
        position: 'top',
        mode: 'ios',
        color: 'success',
      });
      await toast.present();
      this.router.navigate(['money-out']);
    }
  }

}
