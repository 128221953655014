import { IImage } from './../../models/image.model';
import { NgxIndexedDBService } from "ngx-indexed-db";
import { IDataRepo } from "../../../interface/IDataRepo.interface";
import { Image } from "../../models/image.model";
import { Utility } from "../../utils/utility";
import { Router } from '@angular/router';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

export class ImageDao implements IDataRepo<Image>{
  private static _instance: ImageDao;

  public static getInstance(
    ngxIndexedDBService: NgxIndexedDBService,
    router: Router,
  ) {
    if (!this._instance) {
      this._instance = new ImageDao(ngxIndexedDBService, router)
    }
    return this._instance;
  }

  constructor(
    ngxIndexedDBService: NgxIndexedDBService,
    router: Router,
    ) {
    this.ngxIndexedDBService = ngxIndexedDBService;
    this.router = router;
  }

  ngxIndexedDBService: NgxIndexedDBService;
  router: Router;

  save(data: Image): Promise<Image> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && Utility.isTruthy(data)) {
          let timeStamp = +new Date();
          data.createdStamp = timeStamp
          data.updatedStamp = timeStamp
          data.userUpdatedStamp = timeStamp
          data.deletedStamp = 0
          data.syncStamp = 0
  
          data._localUUID = Utility.getUUID();
  
          this.ngxIndexedDBService
            .add(IImage.SCHEMA_NAME, data)
            .subscribe((savedImage: Image) => {
              return resolve(savedImage);
            },
            err => {
              console.error(err);
              if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                this.router.navigate(['idbx-error']);
              } else if (typeof err?.target?.error == 'object') {
                SentryUtilites.setLog("ImageDao:save", err?.target?.error)
              } else {
                SentryUtilites.setLog("ImageDao:save", err)
              }
            });
        } else {
          return resolve(null);
        }
      } catch (err) {
        SentryUtilites.setLog("ImageDao:save", err)
        return resolve(null);
      }
    });
  }

  saveDb(data: Image): Promise<Image> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && Utility.isTruthy(data)) {
          this.ngxIndexedDBService
            .add(IImage.SCHEMA_NAME, data)
            .subscribe((savedItem: Image) => {
              return resolve(savedItem);
            },
            err => {
              console.error(err);
              if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                this.router.navigate(['idbx-error']);
              } else if (typeof err?.target?.error == 'object') {
                SentryUtilites.setLog("ImageDao:saveDb", err?.target?.error)
              } else {
                SentryUtilites.setLog("ImageDao:saveDb", err)
              }
            });
        } else {
          return resolve(null);
        }
      } catch (error) {
        SentryUtilites.setLog("ImageDao:saveDb", error)
        return resolve(null);
      }
    })
  }

  update(data: Image): Promise<Image> {
    return new Promise(async (resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?._localUUID) {
          let oldData = await this.getByUUID(data?._localUUID);
          if(oldData?._localUUID) {
            data._localId = oldData?._localId;
            let timeStamp = +new Date();
            data.updatedStamp = timeStamp
            data.userUpdatedStamp = timeStamp
            this.ngxIndexedDBService
              .update(IImage.SCHEMA_NAME, data)
              .subscribe((updatedImage: Image) => {
                return resolve(updatedImage);
              },
              err => {
                console.error(err);
                if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                  this.router.navigate(['idbx-error']);
                } else if (typeof err?.target?.error == 'object') {
                  SentryUtilites.setLog("ImageDao:update", err?.target?.error)
                } else {
                  SentryUtilites.setLog("ImageDao:update", err)
                }
              });
          } else {
            return resolve(null);
          }
        } else {
          return resolve(null);
        }
      } catch (err) {
        SentryUtilites.setLog("ImageDao:update", err)
        return resolve(null);
      }
    })
  }
  updateDb(data: Image): Promise<Image> {
    return new Promise(async (resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?._localUUID) {
          let oldData = await this.getByUUID(data?._localUUID);
          if(oldData?._localUUID) {
            data._localId = oldData?._localId;
            this.ngxIndexedDBService
              .update(IImage.SCHEMA_NAME, data)
              .subscribe((updatedRecord: Image) => {
                return resolve(updatedRecord);
              },
              err => {
                console.error(err);
                if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                  this.router.navigate(['idbx-error']);
                } else if (typeof err?.target?.error == 'object') {
                  SentryUtilites.setLog("ImageDao:updateDb", err?.target?.error)
                } else {
                  SentryUtilites.setLog("ImageDao:updateDb", err)
                }
              });
          } else {
            return resolve(null);
          }
        } else {
          return resolve(null);
        }
      } catch (err) {
        SentryUtilites.setLog("ImageDao:updateDb", err)
        return resolve(null);
      }
    })
  }

  bulkPut(data: Image[]): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?.length) {
          this.ngxIndexedDBService
            .bulkPut(IImage.SCHEMA_NAME, data)
            .subscribe((data) => {
              resolve(true);
            },
            err => {
              console.error(err);
              if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                this.router.navigate(['idbx-error']);
              } else if (typeof err?.target?.error == 'object') {
                SentryUtilites.setLog("ImageDao:bulkPut", err?.target?.error)
              } else {
                SentryUtilites.setLog("ImageDao:bulkPut", err)
              }
            })
        } else {
          return resolve(false);
        }
      } catch (error) {
        SentryUtilites.setLog("ImageDao:bulkPut", error)
        return resolve(false);
      }
    })
  }

  delete(data: Image): Promise<Image> {
    return new Promise(async (resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?._localUUID) {
          let oldData = await this.getByUUID(data?._localUUID);
          if(oldData?._localUUID) {
            data._localId = oldData?._localId;
            let timeStamp = +new Date();
            data.updatedStamp = timeStamp;
            data.userUpdatedStamp = timeStamp;
            data.deletedStamp = timeStamp;
            this.ngxIndexedDBService
              .update(IImage.SCHEMA_NAME, data)
              .subscribe((updatedImage: Image) => {
                return resolve(updatedImage);
              },
              err => {
                console.error(err);
                if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                  this.router.navigate(['idbx-error']);
                } else if (typeof err?.target?.error == 'object') {
                  SentryUtilites.setLog("ImageDao:delete", err?.target?.error)
                } else {
                  SentryUtilites.setLog("ImageDao:delete", err)
                }
              });
          } else {
            return resolve(null);
          }
        } else {
          return resolve(null);
        }
      } catch (err) {
        SentryUtilites.setLog("ImageDao:delete", err)
        return resolve(null);
      }
    });
  }
  getById(id: number): Promise<Image> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && id) {
          this.ngxIndexedDBService
            .getByKey(IImage.SCHEMA_NAME, id)
            .subscribe((profile: Image) => {
              return resolve(profile);
            },
            err => {
              console.error(err);
              if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                this.router.navigate(['idbx-error']);
              } else if (typeof err?.target?.error == 'object') {
                SentryUtilites.setLog("ImageDao:getById", err?.target?.error)
              } else {
                SentryUtilites.setLog("ImageDao:getById", err)
              }
            });
        } else {
          return resolve(null);
        }
      } catch (err) {
        SentryUtilites.setLog("ImageDao:getById", err)
        return resolve(null)
      }
    })
  }
  getByUUID(uuid: string): Promise<Image> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && uuid) {
          this.ngxIndexedDBService
            .getByIndex(IImage.SCHEMA_NAME, '_localUUID', uuid)
            .subscribe((profile: Image) => {
              return resolve(profile);
            },
            err => {
              console.error(err);
              if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                this.router.navigate(['idbx-error']);
              } else if (typeof err?.target?.error == 'object') {
                SentryUtilites.setLog("ImageDao:getByUUID", err?.target?.error)
              } else {
                SentryUtilites.setLog("ImageDao:getByUUID", err)
              }
            });
        } else {
          return resolve(null);
        }
      } catch (err) {
        SentryUtilites.setLog("ImageDao:getByUUID", err)
        return resolve(null)
      }
    })
  }
  getAll(): Promise<Image[]> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token'))) {
          this.ngxIndexedDBService.getAll(IImage.SCHEMA_NAME).subscribe((docs: Image[]) => {
            docs = docs?.filter(x => !x?.deletedStamp);
            docs?.sort((a, b) => b?.createdStamp - a?.createdStamp);
            return resolve(docs)
          },
          err => {
            console.error(err);
            if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
              this.router.navigate(['idbx-error']);
            } else if (typeof err?.target?.error == 'object') {
              SentryUtilites.setLog("ImageDao:getAll", err?.target?.error)
            } else {
              SentryUtilites.setLog("ImageDao:getAll", err)
            }
          });
        } else {
          return resolve([]);
        }
      } catch (err) {
        SentryUtilites.setLog("ImageDao:getAll", err)
        return resolve([])
      }
    })
  }
  getAllByProfile(profileId: string): Promise<Image[]> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && profileId) {
          this.ngxIndexedDBService.getAll(IImage.SCHEMA_NAME).subscribe((docs: Image[]) => {
            docs = docs?.filter(x => !x?.deletedStamp && x?.profileId == profileId);
            docs?.sort((a, b) => b?.createdStamp - a?.createdStamp);
            return resolve(docs)
          },
          err => {
            console.error(err);
            if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
              this.router.navigate(['idbx-error']);
            } else if (typeof err?.target?.error == 'object') {
              SentryUtilites.setLog("ImageDao:getAllByProfile", err?.target?.error)
            } else {
              SentryUtilites.setLog("ImageDao:getAllByProfile", err)
            }
          });
        } else {
          return resolve([]);
        }
      } catch (err) {
        SentryUtilites.setLog("ImageDao:getAllByProfile", err)
        return resolve([])
      }
    })
  }
  getAllUnsynced(profileId: string): Promise<Image[]> {
    return new Promise((resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token'))) {
          let unSyncedElements: Image[] = [];
          this.ngxIndexedDBService.getAll(IImage.SCHEMA_NAME).subscribe(async (elArr: Image[]) => {
            for (let i = 0; i < elArr?.length; i++) {
              const el = elArr[i];
              if (el?.updatedStamp > el?.syncStamp || !el?._serverIdRef) {
                unSyncedElements.push(el);
              }
            }
            return resolve(unSyncedElements)
          },
          err => {
            console.error(err);
            if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
              this.router.navigate(['idbx-error']);
            } else if (typeof err?.target?.error == 'object') {
              SentryUtilites.setLog("ImageDao:getAllUnsynced", err?.target?.error)
            } else {
              SentryUtilites.setLog("ImageDao:getAllUnsynced", err)
            }
          });
        } else {
          return resolve([]);
        }
      } catch (err) {
        SentryUtilites.setLog("ImageDao:getAllUnsynced", err)
        return resolve([])
      }
    })
  }
  updateSyncStamp(data: Image): Promise<Image> {
    return new Promise(async (resolve, reject) => {
      try {
        if(Boolean(Utility.getFromLocalStorage('_ezo_login_token')) && data?._localUUID) {
          this.ngxIndexedDBService
          .getByIndex(IImage.SCHEMA_NAME, '_localUUID', data?._localUUID)
          .subscribe((dbEl: Image) => {
            if(dbEl) {
                dbEl.syncStamp = data?.syncStamp || 0;
                dbEl._serverIdRef = data?._serverIdRef;
                this.ngxIndexedDBService
                  .update(IImage.SCHEMA_NAME, dbEl)
                  .subscribe((updatedEl: Image) => {
                    return resolve(updatedEl);
                  },
                  err => {
                    console.error(err);
                    if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                      this.router.navigate(['idbx-error']);
                    } else if (typeof err?.target?.error == 'object') {
                      SentryUtilites.setLog("ImageDao:updateSyncStamp", err?.target?.error)
                    } else {
                      SentryUtilites.setLog("ImageDao:updateSyncStamp", err)
                    }
                  });
              } else {
                return resolve(null);
              }
            },
            err => {
              console.error(err);
              if(typeof err == 'string' && err?.includes('objectStore does not exists')) {
                this.router.navigate(['idbx-error']);
              } else if (typeof err?.target?.error == 'object') {
                SentryUtilites.setLog("ImageDao:updateSyncStamp", err?.target?.error)
              } else {
                SentryUtilites.setLog("ImageDao:updateSyncStamp", err)
              }
            })
        } else {
          return resolve(null);
        }
      } catch (err) {
        SentryUtilites.setLog("ImageDao:updateSyncStamp", err)
        return resolve(null)
      }
    })
  }

}
