import { InvoiceItem } from "../models/invoice-item.model";
import { InvoicePrint } from "../models/print.model";
import { commonTempData } from "./commonTempData";

export class Temp17{

    constructor(
      private data: InvoicePrint,
      ){
      }

    totalSaving = 0;

    main(){
      return `
      <style>
      ${commonTempData.invoiceHtmlTemplateCss()}
      .three-inch-holder *{
        margin:0;
        padding:0;
      }
      .three-inch-holder b{
        color:#000;
      }
      .three-inch-holder .text-sm{
        font-size:10px;
      }
      .text-3i-h1{
        font-size:22px;
        font-weight:700;
      }
      .text-3i-h2{
        font-size:20px;
      }
      .text-3i-h3{
        font-size:18px;
      }
      </style>
      <span class="invoice-template-main">
        <div 
          class="container-new arial three-inch-holder"
          style="width:70mm;background: white;padding: 2mm;padding-bottom: 70px;visibility: visible;-webkit-print-color-adjust: exact !important;color-adjust: exact !important;">
          ${this.header()}
          ${this.templateBody()}
          ${this.footer()}
        </div>
      </span>
      `
    }

    header(){
      return `
        ${this.sellerDetails()}
        ${this.invoiceTitle()}`;

    }

    footer(){
      let domain='';
      let includeDukanUrl=0;
      try{
        includeDukanUrl=this.data?.user?.profileData?.additionalData?.include3InchOnlineDukanLink
      }catch(err){

      }
      if(
         this.data?.user?.onlineDukanData?.domain!=""){
          domain=this.data?.user?.onlineDukanData?.domain;
        }
      return `<div style="text-align:center">
        <p class="text-3i-h3">${(this.data?.user?.profileData?.thermalTncTemplate || '').replace(/\n/g,'<br/>')}</p>
        ${domain?.length>0 && includeDukanUrl?`
          <p class="text-3i-h3">Now Order Online @</p>
          <p class="text-3i-h3">https://${domain}.ezo.digital</p>
        `:''}
        
      </div>
      <div class="row" style="margin-top:20px;"><hr/></div>`;
      
    }

    includeTaxPercentHeader=false;
    includeHsnHeader=false;
    includeMrpHeader=false;

    templateBody(){
      let html='';
      
      let totalQty=0;
      let receivedOrPaidAmt = 0;
      let totalTax=0;
      let isTax = false;
      let totalTaxAmt =0;
      let sameState = false;

      let partyInfo = this.data?.partyInfo?.profileData||null;
  
      if( partyInfo && (partyInfo?.addressState == this.data?.user?.profileData?.addressState)){
        sameState = true;
      }
      else if((!partyInfo?.addressState || !(this.data?.user?.profileData?.addressState))){
        sameState = true;
      }
  
      if (this.data?.gstAmount || this.data?.cessAmount) {
        totalTaxAmt = this.data?.gstAmount + this.data?.cessAmount;
        isTax = true;
      }

      if(this.data?.moneyInInfo || this.data?.moneyOut){
        receivedOrPaidAmt = (this.data?.moneyInInfo?.amount || this.data?.moneyOut?.amount ||0);
      }

      if(this.data?.amountReceived) {
        receivedOrPaidAmt = this.data?.amountReceived;
      }

      for(let i=0;i<this.data?.invoiceItems?.length;i++){
        let invoiceItem=this.data?.invoiceItems[i];
        totalTax+=invoiceItem.totalTax ||0;
        totalQty+=invoiceItem.quantity ||0;
        try{
          html+=this.itemRow(invoiceItem);
        }catch(err){
          console.error(err);
        }
        let el = this.data?.invoiceItems[i] ;
        if(el?.mrp&& el?.item?.mrp && el?.sellPrice && !this.data?.isPurchase){
          if(!el?.spIncTax){
            //Exclusive
            this.totalSaving += (((el?.mrp || el?.item?.mrp) - el?.sellPrice + (el?.discountFlat||0))*el?.quantity)-((el?.totalCess||0) + (el?.totalTax||0));
          } else {
            //Inclusive
            this.totalSaving += ((el?.mrp || el?.item?.mrp) - el?.sellPrice + (el?.discountFlat||0))*el?.quantity;
          }
        }else {
          if(el?.item && (!el?.mrp && !el?.item?.mrp) && el?.sellPrice && !this.data?.isPurchase) {
            if(el?.totalDiscount) {
              this.totalSaving += el?.totalDiscount;
            }
          }
        }
      }

      this.totalSaving = (this.totalSaving||0)+(this.data?.flatDiscount||0) ;
      let htmlHeader=`
      <div class="row">
        <hr/>
        <div class="col-xs-6" style="text-align:left">
        <b class="text-3i-h3">Item Name</b>
        </div>
        <div class="col-xs-2" style="text-align:right">
        <b class="text-3i-h3">Qty</b>
        </div>
        <div class="col-xs-2" style="text-align:right">
        <b class="text-3i-h3">Rate</b>
        </div>
        <div class="col-xs-2" style="text-align:right">
        <b class="text-3i-h3">Total</b>
        </div>
        <div class="col-xs-6" style="text-align:left">
        
        </div>
        <div class="col-xs-2" style="text-align:right">
        <span class="text-sm class="text-3i-h3"">${this.includeTaxPercentHeader?'Tax%':''}</span>
        </div>
        <div class="col-xs-2" style="text-align:right">
        <span class="text-sm class="text-3i-h3"">${this.includeMrpHeader?'MRP':''}</sapn>
        </div>
        <div class="col-xs-2" style="text-align:right">
        <span class="text-sm class="text-3i-h3"">${this.includeHsnHeader?'HSN':''}</span>
        </div>
      </div> 
      <div class="row">
        <hr/>
      </div>
      `;
      html=htmlHeader+html;
      html+=`
        <div class="row">
          <hr/>
          <div class="col-xs-6 text-3i-h3" style="text-align:left">
          Total Items : ${this.data?.invoiceItems.length}
          </div>
          <div class="col-xs-6 text-3i-h3" style="text-align:right">
          Total Quantity : ${totalQty}
          </div>
        </div>
        <div class="row">
          <hr/>
          `
 
          if (!sameState && isTax) {
            html += this.commonTaxAndAmtDetails('IGST', commonTempData.unitAdjuster(this.data?.gstAmount));
          }
      
          if (sameState && isTax) {
            html += this.commonTaxAndAmtDetails('SGST', commonTempData.unitAdjuster(this.data?.gstAmount / 2));
          }
      
          if (sameState && isTax) {
            html += this.commonTaxAndAmtDetails('CGST', commonTempData.unitAdjuster(this.data?.gstAmount / 2));
          }
      
          if (this.data?.cessAmount) {
            html += this.commonTaxAndAmtDetails('CESS', commonTempData.unitAdjuster(this.data?.cessAmount));
          }

          html +=`
          
          <div class="col-xs-12" style="text-align:center">
          <hr/>
            <b  class="text-3i-h2">
              Total : ${this.data?.totalAmount}
            </b>
          <hr/>
          </div>
          
          `
          
          let additionalAmount = this.data?.additionalAmount || 0;
          if(additionalAmount){
            html += this.commonTwoRowDetails('Labour/Delivery Charges', additionalAmount);
          }
      
          if(this.data?.flatDiscountWithInvoiceDiscount){
            html +=this.commonTwoRowDetails('Cash Discount', `${commonTempData.unitAdjuster(this.data?.flatDiscountWithInvoiceDiscount)} (${this.data?.flatDiscountPercentage}%)`);
          }else if(this.data?.flatDiscount){
            html +=this.commonTwoRowDetails('Cash Discount', `${commonTempData.unitAdjuster(this.data?.flatDiscount)}`);
          }


        let receivedOrPaid = this.data?.isPurchase? 'Paid Amount': 'Received Amount';
        if(receivedOrPaidAmt){
          html += this.commonTwoRowDetails(receivedOrPaid, commonTempData.unitAdjuster(receivedOrPaidAmt||0));
        }
        let balanceAmt = ((this.data?.totalAmount||0) - receivedOrPaidAmt);
        if(balanceAmt>0){
          html +=this.commonTwoRowDetails('Balance', commonTempData.unitAdjuster(balanceAmt));
        }
  
        if(this.totalSaving > 0){
          html +=this.commonTwoRowDetails('Total saving on this bill', commonTempData.unitAdjuster(this.totalSaving));
        }

        html+=`
          
        </div>
        <div class="row">
          <hr/>
        </div>
      `
      
      return html;
    }

    commonTwoRowDetails(title,value){
      return `
          <div class="col-xs-9" style="text-align:right">${title}</div>
          <div class="col-xs-3" style="text-align:right">${value}</div>
      `
    }

    commonTaxAndAmtDetails(title, value){

      return `
          <div class="col-xs-6"></div>
          <div class="col-xs-3 text-3i-h3" style="text-align:right">${title}</div>
          <div class="col-xs-3 text-3i-h3" style="text-align:right">${value}</div>
      `
    }

    itemRow(invoiceItem:InvoiceItem){
      if(invoiceItem?.taxPercentage){
        this.includeTaxPercentHeader=true;
      }
      if(invoiceItem?.mrp || invoiceItem?.item?.mrp){
        this.includeMrpHeader=true;
      }
      if(invoiceItem?.hsn){
        this.includeHsnHeader=true;
      }
      return `
      <div class="row">
        <div class="col-xs-6 text-3i-h3" style="text-align:left">
          ${invoiceItem?.itemName}
        </div>
        <div class="col-xs-2 text-3i-h3" style="text-align:right">
          ${invoiceItem?.quantity}
        </div>
        <div class="col-xs-2 text-3i-h3" style="text-align:right">
          ${invoiceItem?.sellPrice}
        </div>
        <div class="col-xs-2 text-3i-h3" style="text-align:right">
          ${invoiceItem?.totalAmount}
        </div>
        <div class="col-xs-6 text-3i-h3" style="text-align:left">
          ${invoiceItem?.totalDiscount?' Discount : Rs '+invoiceItem?.totalDiscount:''}
        </div>
        <div class="col-xs-2 text-3i-h3" style="text-align:right">
          <span class="text-sm">${invoiceItem?.taxPercentage?invoiceItem?.taxPercentage+"%":''}</span>
        </div>
        <div class="col-xs-2 text-3i-h3" style="text-align:right">
          <span class="text-sm">${invoiceItem?.mrp || invoiceItem?.item?.mrp ||''}</span>
        </div>
        <div class="col-xs-2 text-3i-h3" style="text-align:right">
          <span class="text-sm">${invoiceItem?.hsn ||''}</span>
        </div>
      </div>
      `
    }

    sellerDetails(){
        let html = '';
        let sellerProfileData = this.data?.user?.profileData;
        html+=`<div style="text-align:center">
          <h3 class="text-3i-h1">${this.data?.user?.profileData?.legalName ||''}</h3>
          <p class="text-3i-h3">${sellerProfileData?.addressLine1 || ''}${sellerProfileData?.addressState?', '+sellerProfileData?.addressState:''}${sellerProfileData?.addressPincode?', '+sellerProfileData?.addressPincode:''}</p>
          <p class="text-3i-h3">${(this.data?.user?.profileData?.contactPersonPhone && this.data?.user?.profileData?.contactPersonPhone!='0')? 'Phone: '+this.data?.user?.profileData?.contactPersonPhone:''}</p>
          <p class="text-3i-h3">${(this.data?.user?.profileData?.contactPersonEmail)? 'Email: '+this.data?.user?.profileData?.contactPersonEmail:''}</p>
          <p class="text-3i-h3">${this.data?.user?.profileData?.gstNumber?'GST Number : '+this.data?.user?.profileData?.gstNumber:''}</p>
        </div>`

        return html;

    }

    invoiceTitle(){
      let party = this.data?.partyInfo?.profileData ||null;
      let html = `
        <div class="row">
          <hr/>
          <div class="col-xs-6 text-3i-h2">
           <b>Bill No</b>: ${this.data?.invoiceNo ||''}
          </div>
          <div class="col-xs-6 text-3i-h2" style="text-align:right">
            ${this.data?.invoiceDateStamp?this.commonDateFormat('Date', this.data?.invoiceDateStamp):''}
          </div>
          <div class="col-xs-4 text-3i-h2">
           
          </div>
          <div class="col-xs-8 text-3i-h2" style="text-align:right">
            ${this.data?.dueDateStamp && this.data?.dueDateStamp>0 ?this.commonDateFormat('Due On', this.data?.dueDateStamp):''}
          </div>
        </div>
        <div class="row">
          <hr/>
          <div class="col-xs-12 text-3i-h2">
            Bill To : ${party?.contactPersonName}
          </div>
          
        </div>
        <div class="row">
        <hr/>
        </div>
      `;
      
      return html;
    }


    commonDateFormat(dateName, timeStamp){
      return `
        <div
          style="display: flow-root;font-weight: bold;color: #000;width: 50%;float: left;"
          class="f-14 f-b m-5 text-3i-h2">
            ${dateName} :
          </div>

          <div
          style="display: flow-root;color: #000;width: 60%;float: left;text-align: right;"
          class="f-14 m-5 text-3i-h2">
             ${commonTempData.dateToDDMMYYY(timeStamp)}
        </div>
      `
    }


}

