import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AllDataService } from '../all-data.service';
import { AuthService } from './auth.service';
import { Validator } from '../../utils/validator';
import { Utility } from 'src/app/utils/utility';
import { EventService } from '../event.service';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Injectable({
  providedIn: 'root'
})
export class PremiumControlService implements CanActivate {

  constructor(
    private allDataService: AllDataService,
    private authService: AuthService,
    private eventService: EventService,
  ) { }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return await this.isPremiumAccess({alert: true});
    // For 3 sale credit use below code
    // return await this.checkCreateSale();
  }

  async isPremiumAccess(options?: { alert?: boolean }) {
    try {
      const proExpiryStamp = await this.getProExpiryStamp();
      let isPremiumAccess = (proExpiryStamp > +new Date());
      Utility.setToLocalStorage('isPremiumAccess',isPremiumAccess);
      if(this.authService?.isUserLoggedIn && options?.alert && !isPremiumAccess) {
        this.eventService.setShowPremiumPopup(true);
      }else {
        this.eventService.setShowPremiumPopup(false);
      }
      let currentProfile = await this.allDataService.profileService.getCurrentProfile();
      let ownerUserId = this.authService.getLoginPhone();
      if(this.authService?.isUserLoggedIn) {
        if(ownerUserId === currentProfile?.userId) {
          Utility.setToLocalStorage('header-color-class', isPremiumAccess ? 'header-blue' : 'header-green');
        }else {
          Utility.setToLocalStorage('header-color-class', isPremiumAccess ? 'header-orange' : 'header-black');
        }
      }
      return isPremiumAccess;
      
    } catch (error) {
      SentryUtilites.setLog("PremiumControlService:isPremiumAccess", error)
      return false;
    }
  }

  /**
   * 
   * @description : Provide login account proExpiryStamp
   * @returns : return login account proExpiryStamp
   */
  getProExpiryStamp(): Promise<number> {
    return new Promise(async (resolve, reject) => {
      try {
        let licences = await this.allDataService.licenceService.getAllByPromise();
        let profile = await this.allDataService.profileService.getCurrentProfile();
        let licence = licences.filter(licence => licence?.userId == profile?.userId)[0];
        let proExpiryStamp = Number(Utility.maximumOfTwoNumber(licence?.proExpiryStamp || 0, licence?.proTrialExpiryStamp || 0));
        return resolve(Utility.isNumber(proExpiryStamp) ? proExpiryStamp : 0);
      } catch (error) {
        SentryUtilites.setLog("PremiumControlService:getProExpiryStamp", error)
        return resolve(0);
      }
    })
  }
  // ------------------------------------------------

  /**
   * 
   * @description : check sale having remaining credit for create sale
   * @returns : return true boolean value if have sale credit
   */
  async checkCreateSale() {
    try {
      let isCreateSale = !(await this.isPremiumAccess()) ? (await this.allDataService.saleService.nonPremiumSaleCeditRemaining()) > 0 : true;
      if(!isCreateSale) {
        this.eventService.setShowPremiumPopup(true, true);
        return false;
      }
      return true;
    } catch (error) {
      SentryUtilites.setLog("PremiumControlService:checkCreateSale", error)
      return false;
    }
  }
  // ------------------------------------------------

  /**
   * 
   * @description : check sale return having remaining credit for create sale return
   * @returns : return true boolean value if have sale return credit
   */
  async checkCreateSaleReturn() {
    try {
      let isCreateSaleReturn = !(await this.isPremiumAccess()) ? (await this.allDataService.saleReturnService.nonPremiumSaleReturnCeditRemaining()) > 0 : true;
      if(!isCreateSaleReturn) {
        this.eventService.setShowPremiumPopup(true, true);
        return false;
      }
      return true;
    } catch (error) {
      SentryUtilites.setLog("PremiumControlService:checkCreateSaleReturn", error)
      return false;
    }
  }
  // ------------------------------------------------

}
