
export class ExpenseBillPrint {

    user: {
        isPro?: number;
        registrationStamp?: number;
    };

    profile: {
        addressProvience?: string;
        legalName?: string;
        addressLine1?: string;
        addressLine2?: string;
        addressCity?:string;
        addressPostalCode?: string;
        contactPersonPhone?: string;
        contactPersonEmail?: string;
        gstin?: string;
        fssaiNumber?: string;
        licenseNumber?: string;
        bankAccountNo?: string;
        bankName?: string;
        bankIFSC?: string;
        bankAccountType?: string;
        bankUPI?: string;
        additionalInputFieldTitle?: string;
        additionalInputFieldValue?: string;
        additionalDateFieldValue?: string; // Not Avail
        additionalDateFieldTitle?: string; // Not Avail
        signatureLink?: string;
        logoLink?: string;
        additionalInputFieldTitle1?: string;
        additionalInputFieldTitle2?: string;
        additionalInputFieldTitle3?: string;
        additionalInputFieldTitle4?: string;
        additionalInputFieldTitle5?: string;
        additionalInputFieldValue1?: string;
        additionalInputFieldValue2?: string;
        additionalInputFieldValue3?: string;
        additionalInputFieldValue4?: string;
        additionalInputFieldValue5?: string;
    };

    bill?: {
        partyData: {
            ledgerCredit?: number;
            profileData: {
                legalName?: string;
                gstNumber?: string;
                contactPersonName?: string;
                contactPersonPhone?: string;
                contactPersonEmail?: string;
                addressLine1?: string;
                addressState?: string;
                addressPincode?: string;
                addressOneLine1?: string;
                addressOneState?: string;
                addressOnePincode?: string;
                udf1T?: string;
                udf1V?: string;
                udf2T?: string;
                udf2V?: string;
                udf3T?: string;
                udf3V?: string;
                additionalInputFieldTitle?: string;
                additionalInputFieldValue?: string;
                additionalDateFieldValue?: string; // Not Avail
                additionalDateFieldTitle?: string; // Not Avail
            };
        };
        moneyOut: {
            billNo?: string;
            dateStamp?: number;
            amount?: number;
            txnMode?: string;
            txnRef?: string;
        }
        isPartyDeleted?:boolean;
        roundOffValue?: number;
        billNo?: string;
        billDateStamp?: number;
        dueDateStamp: number;
        totalAmount: number;
        createdStamp?: number;
        color: string;
        eWayBillDate?: number;
        note?: string;
        billTitle?: string;
        amountPaid?:number;
        tnc?: string;
        partyPreviousBalance?: number;
        billCompleteStamp: number;
    };

    settings?: {
        showSignatureNotRequiredText?: boolean;
        showSignatureInTemplates?: boolean;
        include3InchOnlineDukanLink?: boolean;
        iSetPrintTimeOnBill?: boolean;
    };

    onlineDukan?: {
        domain: string;
    }

    extra?: {
        thermalTncTemplate?: string;
    }

    qrPayLink?: string;
    addressTo?: string;
    pageWidth?: number;
    category?:string;

}