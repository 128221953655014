// Core Imports
import { ActivatedRoute, Router,} from '@angular/router';
import { AlertController, IonInput, ToastController, } from '@ionic/angular';
import { Component, OnInit, AfterViewInit, ChangeDetectorRef, ViewChild, } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
//---------------------------------------------------------------------------------------------------

// Third Party Imports
//------------------------------------------------------------------------------------------------------------

// Models 
import Party, { BillingType } from '../../../models/Party.model';
import PartyCategory from '../../../models/PartyCategory.model';
//-----------------------------------------------------------------------

// Services
import { AllDataService } from '../../../services/all-data.service';
import { AccessControlService } from '../../../services/auth/access-control.service';
//-----------------------------------------------------------------------

// Utilities
import { Validator } from './../../../utils/validator';
import { Utility } from '../../../utils/utility';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
//---------------------------------------------------------------------------

@Component({
  selector: 'app-party-form',
  templateUrl: './party-form.page.html',
  styleUrls: ['./party-form.page.scss'],
})
export class PartyFormPage implements OnInit, AfterViewInit {
  @ViewChild('partyNameEle') partyNameEle: IonInput;
  @ViewChild('addNewCategoryEle') addNewCategoryEle: IonInput;

  getHeaderColorClass = Utility.getHeaderColorClass;
  isDisabledState = Utility.isDisabledState;
  showDayMonthValue = Utility.showDayMonthValue;

    // Input Decorators
  //------------------------------------------------------------------------------------------------------------

  // Output Decorators
  //------------------------------------------------------------------------------------------------------------

  // Viewchild References
  //------------------------------------------------------------------------------------------------------------
  /*
   Reactive Form
  */

  form: FormGroup;

  //-------------------------------


  // Global Variables
  paramDocumentId: string = null;
  fetchedParty: Party = null;

  categories: string[] = [];

  dueNetObj = { ...Utility.dueNetObj };
  keepDateContentsMounted: boolean = false;

  isDOBDateModalOpen = false;
  dpDobValue: string = null;
  checkPlatform;

  isBlockSave: boolean = false;

  // maxDobDate: string = Utility.ionDatePickerFormattedString(
  //   +new Date().setHours(0, 0, 0, 0)
  // );

  billingType = {
    [BillingType.OnlineDeliverySellPrice]: 'Online Delivery Sell Price',
    [BillingType.AcSellPrice]: 'AC Sell Price',
    [BillingType.NonAcSellPrice]: 'Non AC Sell Price',
    [BillingType.SellPrice]: 'Regular Price (Sell Price)',
  };

  states = Utility.states;

  showAllFields = false;

  duplicatePartyByName: Party = null;
  duplicatePartyByPhone: Party = null;

  routerSub: Subscription;

  isCreatePartyCategoryPermission = true;

  subArr: Subscription[] = [];
  //-------------------------------------------------------------------------------------


  constructor(
    private formBuilder: FormBuilder,
    private allDataService: AllDataService,
    private toastController: ToastController,
    private router: Router,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private alertController: AlertController,
    private accessControlService: AccessControlService
  ) {}

  // Life cycle hooks
  ngOnInit() {
    try {
      this.getParamDocumentId();
      this.initializeReactiveForm();
      this.getCategories();
      this.setCreatePartyCategoryPermission();
      this.setDefaultDate();
      this.checkPlatform = window?.navigator;
    } catch (error) {
      SentryUtilites.setLog("PartyFormPage:ngOnInit", error)
    }
  }

  ngAfterViewInit(): void {
    try {
      this.isBlockSave = false;
      this.activateEnterKeyListener();
      this.populateForm();
      setTimeout(() => {
        this.partyNameEle?.setFocus();
      }, 200);
    } catch (error) {
      SentryUtilites.setLog("PartyFormPage:ngAfterViewInit", error)
    }
  }

  ionViewWillLeave() {
    this.ngOnDestroy();
  }

  ngOnDestroy() {
    try {
      this.deactivateEnterKeyListener();
      this.subArr?.forEach(sub => {
        sub?.unsubscribe();
      })
    } catch (error) {
      SentryUtilites.setLog("PartyFormPage:ngOnDestroy", error)
    }
  }

  //set default date
  setDefaultDate(){
    try {
      let currentDate = new Date();
      currentDate.setFullYear(2000);
       let formattedDate = +new Date(currentDate);
       this.dpDobValue = Utility.ionDatePickerFormattedString(formattedDate);
    } catch (error) {
      SentryUtilites.setLog("PartyFormPage:setDefaultDate", error)
    }
  }
  //--------------------------------------------------------------------------------------

  async setCreatePartyCategoryPermission() {
    try {
      this.isCreatePartyCategoryPermission =
        await this.accessControlService.isUserHasAccess({
          action: 'createPartyCategory',
        });
    } catch (error) {
      SentryUtilites.setLog("PartyFormPage:setCreatePartyCategoryPermission", error)
    }
  }

  openDOBPicker() {
    this.keepDateContentsMounted = true;
    this.isDOBDateModalOpen = true;
  }

  closeDOBPicker(val) {
    this.isDOBDateModalOpen = false;
  }

  enterKeyFn = (keyboardEvent: KeyboardEvent) => {
    if (keyboardEvent?.code === 'Enter' && this.form?.valid) {
      this.presentSamePartyNamePhoneConfirm();
    }
  };

  activateEnterKeyListener() {
    document.addEventListener('keyup', this.enterKeyFn, true);
  }

  deactivateEnterKeyListener() {
    document.removeEventListener('keyup', this.enterKeyFn, true);
  }

  getParamDocumentId() {
    this.paramDocumentId = this.route.snapshot.paramMap.get('documentId');
  }

  initializeReactiveForm() {
    try {
      this.form = this.formBuilder.group({
        name: [null, [Validators.required, Validators.pattern(Validator.noSpaceAtStart)]],
        phone: [null, [Validators.pattern(Validator.phone)]],
        category: [null],
        type: ['customer'],
        gstin: [null, Validators.pattern(Validator.gstin)],
        billingType: [null],
        dateOfBirth: [null, Validators.pattern(Validator.positiveNumber)],
        businessName: [null],
        email: [null, Validators.pattern(Validator.email)],
        billingAddress: [null],
        billingProvience: [null],
        billingPostalCode: [null, Validators.pattern(Validator.pincode)],
        deliveryAddress: [null],
        deliveryProvience: [null],
        deliveryPostalCode: [null, Validators.pattern(Validator.pincode)],
        paymentTerm: [null],
        sendAlerts: [true],
      });
      this.subArr.push(this.form.get('gstin').valueChanges.pipe(distinctUntilChanged(), debounceTime(300)).subscribe(async (gstin) => {
        if ((gstin + '')?.length >= 15 && !(gstin + '')?.match(Validator.gstin)) {
          const toast = await this.toastController.create({
            message: 'You have entered wrong format of GSTIN',
            duration: 2000,
            color: 'danger',
          });
          await toast.present();
        }
      }));
    } catch (error) {
      SentryUtilites.setLog("PartyFormPage:initializeReactiveForm", error)
    }
  }

  async populateForm() {
    try {
      if (this.paramDocumentId) {
        this.fetchedParty = await this.allDataService.partyService.getByUUID(
          this.paramDocumentId
        );
        this.form.patchValue(this.fetchedParty);
        if (this.fetchedParty?.dateOfBirth) {
          this.dpDobValue = Utility.ionDatePickerFormattedString(
            this.fetchedParty?.dateOfBirth
          );
          this.keepDateContentsMounted = true;
        }
      }
    } catch (error) {
      SentryUtilites.setLog("PartyFormPage:populateForm", error)
    }
  }

  async getCategories() {
    try {
      let partyCategories =
        await this.allDataService.partyCategoryService.getAllByPromise();
      if (partyCategories) {
        this.categories = partyCategories?.map((x) => x?.name);
      }
    } catch (error) {
      SentryUtilites.setLog("PartyFormPage:getCategories", error)
    }
  }

  /**
   * 
   * @param event : on segment change provide CustomEvent object
   * @description : convert string value of true, false into boolean value
   */
  onSendAlertsChange(event: CustomEventInit) {
    try {
      console.log(event)
      if(event?.detail?.value===true || event?.detail?.value==='true') {
        this.form?.patchValue({sendAlerts: true});
      }else if(event?.detail?.value===false || event?.detail?.value==='false'){
        this.form?.patchValue({sendAlerts: false});
      }
    } catch (error) {
      SentryUtilites.setLog("PartyFormPage:onSendAlertsChange", error)
    }
  }
  // ---------------------------------------

  onSaveClick() {
    try {
      if (this.paramDocumentId) {
        this.update();
      } else {
        this.save();
      }
      this.dpDobValue = null;
      this.keepDateContentsMounted = false;
    } catch (error) {
      SentryUtilites.setLog("PartyFormPage:onSaveClick", error)
    }
  }

  async save() {
    try {
      if (this.form.valid && !this.isBlockSave) {
        this.isBlockSave = true;
        let party = new Party();
        party = { ...this.form.value };
        let savedParty = await this.allDataService.partyService.save(party);
        if (savedParty?._localUUID) {
          this.allDataService.listForceReloadSubs.next('party-list');
          this.presentSuccessToast('Party Saved Successfully');
          setTimeout(() => {
            this.isBlockSave = false;
          }, 5000);
        } else {
          this.isBlockSave = false;
        }
      }
    } catch (error) {
      SentryUtilites.setLog("PartyFormPage:save", error)
    }
  }

  async update() {
    try {
      if (this.form.valid && !this.isBlockSave) {
        this.isBlockSave = true;
        this.fetchedParty = {
          ...this.fetchedParty,
          ...this.form.value,
        };
        let updatedParty = await this.allDataService.partyService.update(
          this.fetchedParty
        );
        if (updatedParty?._localUUID) {
          this.allDataService.listForceReloadSubs.next('party-list');
          this.presentSuccessToast('Party Updated Successfully');
          setTimeout(() => {
            this.isBlockSave = false;
          }, 5000);
        } else {
          this.isBlockSave = false;
        }
      }
    } catch (error) {
      SentryUtilites.setLog("PartyFormPage:update", error)
    }
  }

  async presentSuccessToast(header: string) {
    try {
      const toast = await this.toastController.create({
        header,
        duration: 2000,
        position: 'top',
        mode: 'ios',
        color: 'success',
      });
      await toast.present();
      this.router.navigate(['party']);
    } catch (error) {
      SentryUtilites.setLog("PartyFormPage:presentSuccessToast", error)
    }
  }

  async addCategory() {
    try {
      if (this.addNewCategoryEle) {
        const categoryName: string = (this.addNewCategoryEle?.value as string).toLowerCase().trim();
        if (categoryName) {
          if (
            this.categories?.findIndex(
              (x) => x?.toLowerCase() === categoryName
            ) === -1
          ) {
            let category = new PartyCategory();
            category.name = categoryName;
            let savedCategory =
              await this.allDataService.partyCategoryService.save(category);
            if (savedCategory._localUUID) {
              this.allDataService.listForceReloadSubs.next('party-category-list');
              this.categories.push(categoryName);
            } else {
              return null;
            }
          } else {
            const toast = await this.toastController.create({
              header: "Party category already exits",
              duration: 2000,
              position: 'top',
              mode: 'ios',
              color: 'warning',
            });
            await toast.present();
          }
          this.form?.patchValue({ category: categoryName });
          this.addNewCategoryEle.value = '';
        }
      }
    } catch (error) {
      SentryUtilites.setLog("PartyFormPage:addCategory", error)
    }
  }

  async addNewCategory(value: { categoryName: string }) {
    try {
      let category = new PartyCategory();
      category.name = value.categoryName;
      let result = await this.allDataService.partyCategoryService.save(category);
      if (result?._localUUID) {
        this.allDataService.listForceReloadSubs.next('party-category-list');
        this.categories.push(value?.categoryName);
        this.form?.patchValue({
          category: value?.categoryName,
        });
        return true;
      } else {
        return false;
      }
    } catch (error) {
      SentryUtilites.setLog("PartyFormPage:addNewCategory", error)
      return false;
    }
  }

  onDateOfBirthSelect(event) {
    try {
      if (event.detail.value) {
        this.dpDobValue = event.detail.value;
        this.form?.controls?.dateOfBirth?.setValue(
          +new Date(event?.detail?.value).setHours(0, 0, 0, 0)
        );
        Utility.closeDatePicker(event?.target);
      }
    } catch (error) {
      SentryUtilites.setLog("PartyFormPage:onDateOfBirthSelect", error)
    }
  }

  onCancelDateOfBirth(event) {
    try {
      if(this.form?.controls?.dateOfBirth?.value) {
        event.target.reset(Utility.ionDatePickerFormattedString(this.form?.controls?.dateOfBirth?.value));
      } else {
        // event.target.reset();
        this.setDefaultDate();
      }
    } catch (error) {
      SentryUtilites.setLog("PartyFormPage:onCancelDateOfBirth", error)
    }
  }

  onClickedSBA(event: any) {
    try {
      if (event?.detail?.checked) {
        this.form.patchValue({
          deliveryAddress: this.form.value.billingAddress,
          deliveryProvience: this.form.value.billingProvience,
          deliveryPostalCode: this.form.value.billingPostalCode,
        });
      } else {
        this.form.patchValue({
          deliveryAddress: null,
          deliveryProvience: null,
          deliveryPostalCode: null,
        });
      }
    } catch (error) {
      SentryUtilites.setLog("PartyFormPage:onClickedSBA", error)
    }
  }

  returnZero = () => 0;

  toggleShowAllFields() {
    this.showAllFields = !this.showAllFields;
  }

  async onPartyNameChange() {
    try {
      this.form?.patchValue({
        name: Utility.preventEmptyString(this.form?.value?.name),
      });
      const allParties = await this.allDataService.partyService.getAllByPromise();
      const matchedParties = allParties?.filter(
        (party) =>
          party?.name?.toLowerCase() === this.form?.value?.name?.toLowerCase()
      );
      if (matchedParties?.length) {
        this.duplicatePartyByName = matchedParties[0];
      } else {
        this.duplicatePartyByName = null;
      }
    } catch (error) {
      SentryUtilites.setLog("PartyFormPage:onPartyNameChange", error)
    }
  }

  async onPartyPhoneChange() {
    try {
      const allParties = await this.allDataService.partyService.getAllByPromise();
      const matchedParties = allParties?.filter(
        (party) => party?.phone === this.form?.value?.phone
      );
      if (
        !matchedParties?.length ||
        (this.paramDocumentId && matchedParties?.length === 1)
      ) {
        this.duplicatePartyByPhone = null;
      } else if (matchedParties?.length) {
        this.duplicatePartyByPhone = matchedParties[0];
      }
    } catch (error) {
      SentryUtilites.setLog("PartyFormPage:onPartyPhoneChange", error)
    }
  }

  async presentSamePartyNamePhoneConfirm() {
    try {
      if (
        !this.paramDocumentId &&
        (this.duplicatePartyByName?._localUUID ||
          this.duplicatePartyByPhone?._localUUID)
      ) {
        let message: string = '';
        if (
          this.duplicatePartyByName?._localUUID &&
          this.duplicatePartyByPhone?._localUUID
        ) {
          message =
            'Party with same name & phone is already exists. Do you still want create new party with the same name & phone?';
        } else if (this.duplicatePartyByName?._localUUID) {
          message =
            'Party with same name is already exists. Do you still want create new party with the same name?';
        } else if (this.duplicatePartyByPhone?._localUUID) {
          message =
            'Party with same phone is already exists. Do you still want create new party with the same phone?';
        }
        const alert = await this.alertController.create({
          header: 'Warning!',
          message,
          mode: 'ios',
          buttons: [
            {
              text: 'No',
              role: 'cancel',
            },
            {
              text: 'Yes',
              role: 'confirm',
              handler: this.onSaveClick.bind(this),
            },
          ],
        });
  
        await alert.present();
      } else {
        this.onSaveClick();
      }
    } catch (error) {
      SentryUtilites.setLog("PartyFormPage:presentSamePartyNamePhoneConfirm", error)
    }
  }
}
