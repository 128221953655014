<ion-content>

  <ion-grid>
    <ion-row class="row-fixing-db">
      <ion-col size="12">
        <ion-label class="font-size-30px">
          Maintaining Ledger
        </ion-label>
      </ion-col>
      <ion-col size="12">
        <ion-label color="danger font-size-24px">
          Please do not close / refresh window
        </ion-label>
      </ion-col>
      <ion-col size="12" class="col-progress">
        <ion-label color="primary" class="font-size-22px">

        </ion-label>
        <ion-progress-bar type="indeterminate"></ion-progress-bar>
      </ion-col>
    </ion-row>
  </ion-grid>

</ion-content>