import { MonthWiseItemStockService } from './../../../services/month-wise-item-stock.service';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Item } from '../../../models/Item.model';
import { LedgerItem } from '../../../models/LedgerItem.model';
import { AllDataService } from '../../../services/all-data.service';
import { DeleteDataComponent } from '../../../components/delete-data/delete-data.component';
import { PinVerificationComponent } from '../../../components/pin-verification/pin-verification.component';
import { AccessControlService } from '../../../services/auth/access-control.service';
import { Utility } from '../../../utils/utility';
import { CommonService } from '../../../services/common.service';
import { InfiniteScrollCustomEvent } from '@ionic/angular';
import { Subscription } from 'rxjs/internal/Subscription';
import { Utils } from '../../../utils/utils';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-item-transaction',
  templateUrl: './item-transaction.page.html',
  styleUrls: ['./item-transaction.page.scss'],
})
export class ItemTransactionPage implements OnInit {

  @ViewChild('deleteDataEle') deleteDataEle: DeleteDataComponent;
  @ViewChild('pinVerificationElement') pinVerificationElement: PinVerificationComponent;
  
  getHeaderColorClass = Utility.getHeaderColorClass;
  splitByCamelCase = Utility.splitByCamelCase;
  capFractionsToTwo = Utils.capFractionsToTwo;

  paramDocumentId: string = null;
  fetchedItem: Item = null;
  transactionsList: LedgerItem[] = [];
  filteredList: LedgerItem[] = [];
  viewFilteredList: LedgerItem[] = [];

  ledgerStock: number = null;

  startStamp: number = +new Date().setHours(0,0,0,0);
  endStamp: number = +new Date().setHours(23,59,59,999);
  
  subsArr: Subscription[] = [];

  isMobile: boolean = null;
  isOptionOpen: boolean = false;
  innerHeight: string = '';

  loadViewTimeStamp: number = 0;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private allDataService: AllDataService,
    private accessControlService: AccessControlService,
    private commonService: CommonService,
    private monthWiseItemStockService: MonthWiseItemStockService,
  ) { }

  async ngOnInit() {
    try {
      this.isMobile = this.commonService.isMobile();
      if(this.isMobile) {
        this.innerHeight = `${(((window?.innerHeight - 327)/window?.innerHeight) * 100) - 2}%`;
      } else {
        this.innerHeight = `${(((window?.innerHeight - 251)/window?.innerHeight) * 100) - 2}%`;
      }
      this.reduceFunctionCall();
      this.subsArr.push(
        this.allDataService.listForceReloadSubs.subscribe(async (listName: string) => {
          if (
            listName == 'item-list'
            || listName == 'purchase-list'
            || listName == 'purchase-return-list'
            || listName == 'sale-list'
            || listName == 'sale-return-list'
            || listName == 'expense-list'
            ) {
            // wait for save response to database
            await Utility.wait(2000)
            this.reduceFunctionCall();
          }
        })
      );
    } catch (error) {
      SentryUtilites.setLog("ItemTransactionPage:ngOnInit", error)
    }
  }

  ionViewWillLeave() {
    this.ngOnDestroy();
  }

  ngOnDestroy() {
    this.subsArr?.forEach(sub => {sub?.unsubscribe()});
  }

  getParamDocumentId() {
    this.paramDocumentId = this.route.snapshot.paramMap.get('documentId');
  }

  async populateItem(): Promise<boolean> {
    try {
      if(this.paramDocumentId) {
        this.fetchedItem = await this.allDataService.itemService.getByUUID(this.paramDocumentId);
        return true;
      }
    } catch (error) {
      SentryUtilites.setLog("ItemTransactionPage:populateItem", error)
      return false;
    }
  }

  async getTransactions() {
    try {
      await this.populateItem();
  
      let newTransactionsList = [];
  
      let allSales = await this.allDataService.saleService.getAllByPromise() || [];
      let allSaleReturns = await this.allDataService.saleReturnService.getAllByPromise() || [];
      let allPurchases = await this.allDataService.purchaseService.getAllByPromise() || [];
      let allPurchaseReturns = await this.allDataService.purchaseReturnService.getAllByPromise() || [];
      let allStockAdjust = await this.allDataService.itemStockAdjustService.getAllByLinkedItemUUID(this.paramDocumentId) || [];
      allSales?.forEach(sale => {
        let itemFound = sale?.billItems?.filter(x => x?.item?._localUUID===this.paramDocumentId);
        let qty = itemFound.reduce((accumulator, currentValue)=>{
          let itemQty = currentValue?.unit === currentValue?.item?.primaryUnit ? currentValue?.quantity : currentValue?.quantity / (currentValue?.convertRatioMultiplier || 1);
          return accumulator + itemQty
        },0);
        if(itemFound?.length > 0) {
          newTransactionsList.push({
            type: "Sale",
            linkedLocalUUID: sale?._localUUID,
            billNo: sale?.billNo,
            createdStamp: sale?.createdStamp,
            billDateStamp: sale?.billDateStamp,
            updatedStamp: sale?.updatedStamp,
            syncStamp: sale?.syncStamp,
            quantity: this.capFractionsToTwo(qty),
            runningStock: 0,
          });
        }
      });

      allSaleReturns?.forEach(saleReturn => {
        let itemFound = saleReturn?.billItems?.filter(x => x?.item?._localUUID===this.paramDocumentId);
        let qty = itemFound.reduce((accumulator, currentValue)=>{
          let itemQty = currentValue?.unit === currentValue?.item?.primaryUnit ? currentValue?.quantity : currentValue?.quantity / (currentValue?.convertRatioMultiplier || 1);
          return accumulator + itemQty
        },0);
        if(itemFound?.length > 0) {
          newTransactionsList.push({
            type: "SaleReturn",
            linkedLocalUUID: saleReturn?._localUUID,
            billNo: saleReturn?.billNo,
            createdStamp: saleReturn?.createdStamp,
            billDateStamp: saleReturn?.billDateStamp,
            updatedStamp: saleReturn?.updatedStamp,
            syncStamp: saleReturn?.syncStamp,
            quantity: this.capFractionsToTwo(qty),
            runningStock: 0,
          });
        }
      });
  
      allPurchases?.forEach(purchase => {
        let itemFound = purchase?.billItems?.find(x => x?.item?._localUUID===this.paramDocumentId);
        let qty = itemFound?.unit === itemFound?.item?.primaryUnit ? itemFound?.quantity : itemFound?.quantity / (itemFound?.convertRatioMultiplier || 1);
        if(itemFound?.item?._localUUID) {
          newTransactionsList.push({
            type: "Purchase",
            linkedLocalUUID: purchase?._localUUID,
            billNo: purchase?.billNo,
            createdStamp: purchase?.createdStamp,
            billDateStamp: purchase?.billDateStamp,
            updatedStamp: purchase?.updatedStamp,
            syncStamp: purchase?.syncStamp,
            quantity: this.capFractionsToTwo(qty),
            runningStock: 0,
          });
        }
      });
      allPurchaseReturns?.forEach(purchaseReturn => {
        let itemFound = purchaseReturn?.billItems?.find(x => x?.item?._localUUID===this.paramDocumentId);
        let qty = itemFound?.unit === itemFound?.item?.primaryUnit ? itemFound?.quantity : itemFound?.quantity / (itemFound?.convertRatioMultiplier || 1);
        if(itemFound?.item?._localUUID) {
          newTransactionsList.push({
            type: "PurchaseReturn",
            linkedLocalUUID: purchaseReturn?._localUUID,
            billNo: purchaseReturn?.billNo,
            createdStamp: purchaseReturn?.createdStamp,
            billDateStamp: purchaseReturn?.billDateStamp,
            updatedStamp: purchaseReturn?.updatedStamp,
            syncStamp: purchaseReturn?.syncStamp,
            quantity: this.capFractionsToTwo(qty),
            runningStock: 0,
          });
        }
      });
  
      allStockAdjust?.forEach(stockAdjust => {
        newTransactionsList.push({
          type: stockAdjust?.quantity > 0 ? 'Add' : 'Reduce',
          linkedLocalUUID: stockAdjust?._localUUID,
          billNo: null,
          createdStamp: stockAdjust?.createdStamp,
          billDateStamp: +new Date(stockAdjust?.createdStamp).setHours(0,0,0,0),
          updatedStamp: stockAdjust?.updatedStamp,
          syncStamp: stockAdjust?.syncStamp,
          quantity: Math.abs(stockAdjust?.quantity),
          runningStock: 0,
          note: stockAdjust?.note || null,
        });
      });
  
      newTransactionsList.sort((a,b) => {
        if(b?.billDateStamp == a?.billDateStamp) {
          return b?.createdStamp - a?.createdStamp;
        }
        return b?.billDateStamp - a?.billDateStamp;
      });
  
      let stock = 0;
      newTransactionsList.reverse()?.forEach(transaction => {
        if(transaction?.type === 'Sale') {
          stock -= Number(transaction?.quantity);
        }
        if(transaction?.type === 'SaleReturn') {
          stock += Number(transaction?.quantity);
        }
        if(transaction?.type === 'Reduce') {
          stock -= Number(transaction?.quantity);
        }
        if(transaction?.type === 'Purchase') {
          stock += Number(transaction?.quantity);
        }
        if(transaction?.type === 'PurchaseReturn') {
          stock -= Number(transaction?.quantity);
        }
        if(transaction?.type === 'Add') {
          stock += Number(transaction?.quantity);
        }
  
        transaction.runningStock = stock;
      });
  
      this.ledgerStock = await this.monthWiseItemStockService.getLedgerStock(this.fetchedItem?._localUUID);
  
      newTransactionsList.reverse();
  
      let completeListHashMap = {};
  
      let completeListLength = this.transactionsList.length;
      for (let i = 0; i < completeListLength; i++) {
        let key = JSON.stringify(this.transactionsList[i]);
        completeListHashMap[key] = true;
      }
  
      let differentObjects = [];
  
       // storing credit and find out differentObjects from transactionsList
      if(this.transactionsList?.length) {
        let newTransactionsListLength = newTransactionsList?.length;
        for (let i = 0; i < newTransactionsListLength; i++) {
          let newCompleteObj = newTransactionsList[i];
          let key = JSON.stringify(newCompleteObj);
          if (!completeListHashMap[key]) {
            differentObjects.push(newCompleteObj);
          }
        }
      }
  
      if(
        this.transactionsList?.length != newTransactionsList?.length
        || JSON.stringify(this.transactionsList) != JSON.stringify(newTransactionsList)
      ) {
        
        if(this.transactionsList?.length) {
          let differentObjectsLength = differentObjects?.length;
          if (differentObjectsLength) {
            for (let i = 0; i < differentObjectsLength; i++) {
              let differentObject = differentObjects[i];
              let index = this.transactionsList.findIndex(
                (el) => el?.linkedLocalUUID === differentObject?.linkedLocalUUID
              );
              if (index != -1) {
                this.transactionsList[index] = differentObject;
              } else {
                this.transactionsList.push(differentObject);
              }
            }
          }
        } else {
          this.transactionsList = newTransactionsList;
        }
  
        this.filteredList = this.transactionsList.filter(x => x.billDateStamp >= this.startStamp && x.billDateStamp < this.endStamp);
        this.filteredList.sort((a,b) => {
          if(b?.billDateStamp == a?.billDateStamp) {
            return b?.createdStamp - a?.createdStamp;
          }
          return b?.billDateStamp - a?.billDateStamp;
        });
        this.viewFilteredList = this.viewFilteredList?.length > 50 ? this.filteredList.slice(0, this.viewFilteredList?.length) : this.filteredList.slice(0, 50);
    
        this.fetchedItem = await this.allDataService.itemService.update(this.fetchedItem);
      }
  
  
      return true;
    } catch (error) {
      SentryUtilites.setLog("ItemTransactionPage:getTransactions", error)
      return false;
    }
  }

  reduceFunctionCall() {
    try {
      if(+new Date() > this.loadViewTimeStamp) {
        this.loadViewTimeStamp = +new Date() + 5000;
        this.getParamDocumentId();
        this.getTransactions();
      }
    } catch (error) {
      SentryUtilites.setLog("ItemTransactionPage:reduceFunctionCall", error)
    }
  }

  openTransactionPINModal() {
    this.pinVerificationElement?.openTransactionPINModal();
  }

  verifyTransactionPIN(event) {
    try {
      if(event) {
        this.router.navigate([`item/form/${this.paramDocumentId}`]);
      }
    } catch (error) {
      SentryUtilites.setLog("ItemTransactionPage:verifyTransactionPIN", error)
    }
  }

  async editItem() {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({action:'editItem'});
      if(!isPermit) {
        return alert("Permission: You don't have permission to edit item. Please contact to your owner.");
      }
      this.openTransactionPINModal();
    } catch (error) {
      SentryUtilites.setLog("ItemTransactionPage:editItem", error)
      alert("Something went wrong.");
    }
  }

  async deleteItem() {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({action:'deleteItem'});
      if(!isPermit) {
        return alert("Permission: You don't have permission to delete item. Please contact to your owner.");
      }
      this.deleteDataEle?.initDeleteItem(this.fetchedItem);
    } catch (error) {
      SentryUtilites.setLog("ItemTransactionPage:deleteItem", error)
      alert("Something went wrong.");
    }
  }

  onItemDelete(event) {
    try {
      if(event) {
        this.router.navigate(['item']);
      }
    } catch (error) {
      SentryUtilites.setLog("ItemTransactionPage:onItemDelete", error)
    }
  }

  onDateRangeChange(data:{fromStamp:number,toStamp:number}) {
    try {
      if(this.startStamp === data.fromStamp && this.endStamp === data.toStamp) {
        return null;
      }else {
        this.startStamp = data.fromStamp;
        this.endStamp = data.toStamp;
        this.filteredList = this.transactionsList.filter(x => x?.billDateStamp >= this.startStamp && x?.billDateStamp < this.endStamp);
        this.filteredList.sort((a,b) => {
          if(b?.billDateStamp == a?.billDateStamp) {
            return b?.createdStamp - a?.createdStamp;
          }
          return b?.billDateStamp - a?.billDateStamp;
        });
        this.viewFilteredList = this.viewFilteredList?.length > 50 ? this.filteredList.slice(0, this.viewFilteredList?.length) : this.filteredList.slice(0, 50);
      }
    } catch (error) {
      SentryUtilites.setLog("ItemTransactionPage:onDateRangeChange", error)
    }
  }

  async navigateToBillView(transaction: LedgerItem) {
    try {
      if(transaction?.type === 'Sale') {
        let isPermit = await this.accessControlService.isUserHasAccess({action:'viewSale'});
        if(!isPermit) {
          return alert("Permission: You don't have permission to view bill. Please contact to your owner.");
        }
        this.router.navigate([`bill-view/${transaction?.linkedLocalUUID}`]);
      }
      if(transaction?.type === 'SaleReturn') {
        let isPermit = await this.accessControlService.isUserHasAccess({action:'viewSaleReturn'});
        if(!isPermit) {
          return alert("Permission: You don't have permission to view bill. Please contact to your owner.");
        }
        this.router.navigate([`bill-view-sale-return/${transaction?.linkedLocalUUID}`]);
      }
      if(transaction?.type === 'Purchase') {
        let isPermit = await this.accessControlService.isUserHasAccess({action:'viewPurchase'});
        if(!isPermit) {
          return alert("Permission: You don't have permission to view bill. Please contact to your owner.");
        }
        this.router.navigate([`bill-view-purchase/${transaction?.linkedLocalUUID}`]);
      }
      if(transaction?.type === 'PurchaseReturn') {
        let isPermit = await this.accessControlService.isUserHasAccess({action:'viewPurchaseReturn'});
        if(!isPermit) {
          return alert("Permission: You don't have permission to view bill. Please contact to your owner.");
        }
        this.router.navigate([`bill-view-purchase-return/${transaction?.linkedLocalUUID}`]);
      }
    } catch (error) {
      SentryUtilites.setLog("ItemTransactionPage:navigateToBillView", error)
    }
  }

  getTransactionTypeColor = (type: string) => {
    try {
      if(type === 'Sale' || type === 'PurchaseReturn' || type === 'Reduce') {
        return 'danger';
      }
      if(type === 'Purchase' || type === 'SaleReturn' || type === 'Add') {
        return 'success';
      }
    } catch (error) {
      SentryUtilites.setLog("ItemTransactionPage:getTransactionTypeColor", error)
    }
  }

  openReportView() {
    try {
      if(this.fetchedItem?._localUUID) {
        this.router.navigate(['reports'], { queryParams: { type: 'ItemReport', localUUID: this.fetchedItem?._localUUID } });
      }
    } catch (error) {
      SentryUtilites.setLog("ItemTransactionPage:openReportView", error)
    }
  }

  loadMoreListData(event) {
    try {
      if (this.viewFilteredList.length > 0 && this.viewFilteredList.length <= this.transactionsList.length) {
        let appendListLength = this.filteredList.length - this.viewFilteredList.length;
        let lastEl = this.viewFilteredList[this.viewFilteredList.length - 1];
        let counter = 0;
        for (let i = 0; i < this.filteredList.length; i++) {
          if (this.filteredList[i].linkedLocalUUID == lastEl.linkedLocalUUID) {
            counter = 1;
            continue;
          } 
          if (counter > 0 && appendListLength >= 50) {
            if (counter <= 50) {
              this.viewFilteredList.push({ ...this.filteredList[i] })
            } else {
              break;
            }
            counter++;
          } else if(counter > 0 && appendListLength < 50) {
            if (counter <= appendListLength) {
              this.viewFilteredList.push({ ...this.filteredList[i] })
            } else {
              break;
            }
            counter++;
          }
        }
        (event as InfiniteScrollCustomEvent).target.complete();
      }
    } catch (error) {
      SentryUtilites.setLog("ItemTransactionPage:loadMoreListData", error)
    }
  }

  toggleOption() {
    this.isOptionOpen = !this.isOptionOpen;
  }

  clickOutSide() {
    this.isOptionOpen = false;
  }

  @HostListener('window:resize', ['$event'])
  OnResize() {
    try {
      setTimeout(() => {
        this.isMobile = this.commonService.isMobile();
        if(this.isMobile) {
          this.innerHeight = `${(((window?.innerHeight - 327)/window?.innerHeight) * 100) - 2}%`;
        } else {
          this.innerHeight = `${(((window?.innerHeight - 251)/window?.innerHeight) * 100) - 2}%`;
        }
      }, 50)
    } catch (error) {
      SentryUtilites.setLog("ItemTransactionPage:presentSuccessToast", error)
    }
  }

}
