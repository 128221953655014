<ion-modal class="modal-enter-transaction-pin" [isOpen]="isTransactionPINModalOpen" [backdropDismiss]="false">
    <ng-template>
      <ion-header>
        <ion-toolbar>
          <ion-title>Enter Transaction PIN</ion-title>
          <ion-buttons slot="end">
            <ion-button (click)="openTransactionPINModal(false)" color="danger">Close</ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-padding">
        <ion-item>
            <ion-label>Enter Transaction PIN</ion-label>
        </ion-item>

        <div class="outerPinBox">
            <div class="pinBox">
              <input 
                class="pinEntry-password" 
                #inpTransactionPINEle 
                (keyup.enter)="verifyTransactionPIN()" 
                type="password" 
                maxlength="4">
              <span class="box1"></span>
              <span class="box2"></span>
              <span class="box3"></span>
              <span class="box4"></span>
              <span class="box5"></span>
            </div>
        </div>
      </ion-content>

      <ion-toolbar>
        <ion-buttons slot="end">
            <ion-button 
              class="btn-save" 
              fill="clear" 
              (click)="forgetPIN()" 
              color="danger">Forgot PIN?</ion-button>
        </ion-buttons>
      </ion-toolbar>
  
      <ion-toolbar>
          <ion-button 
            class="btn-save" 
            color="primary" 
            expand="full" 
            (click)="verifyTransactionPIN()">Verify</ion-button>
      </ion-toolbar>
    </ng-template>
</ion-modal>
<ion-modal class="modal-pin-reset" [isOpen]="isPinResetModalOpen" [backdropDismiss]="false">
    <ng-template>
      <ion-header>
        <ion-toolbar>
          <ion-title>PIN Reset OTP</ion-title>
          <ion-buttons slot="end">
            <ion-button (click)="openPinResetModal(false)" color="danger">Close</ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-padding">
        <ion-item>
            <ion-label>Enter OTP</ion-label>
        </ion-item>

        <div class="outerPinBox">
            <div class="pinBox">
              <input 
                class="pinEntry-text" 
                #inpPinResetOTPEle 
                (keyup.enter)="verifyPinResetOTP()" 
                type="text"
                maxlength="4">
              <span class="box1"></span>
              <span class="box2"></span>
              <span class="box3"></span>
              <span class="box4"></span>
              <span class="box5"></span>
            </div>
        </div>
      </ion-content>
  
      <ion-toolbar>
        <ion-button 
          class="btn-save" 
          color="primary" 
          expand="full" 
          (click)="verifyPinResetOTP()">
          Verify
        </ion-button>
      </ion-toolbar>
    </ng-template>
</ion-modal>

<ion-modal class="modal-pin-generation" [isOpen]="isPinGenerationModalOpen" [backdropDismiss]="false">
    <ng-template>
      <ion-header>
        <ion-toolbar>
          <ion-title>PIN Generation</ion-title>
          <ion-buttons slot="end">
            <ion-button (click)="openPinGenerationModal(false)" color="danger">Close</ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-padding">

        <ion-item>
            <ion-label>Enter New PIN</ion-label>
        </ion-item>

        <div class="outerPinBox">
            <div class="pinBox">
              <input 
                class="pinEntry-text" 
                type="text" 
                maxlength="4" 
                (keyup.enter)="matchPin()" 
                #inpEnterNewPinEle>
              <span class="box1"></span>
              <span class="box2"></span>
              <span class="box3"></span>
              <span class="box4"></span>
              <span class="box5"></span>
            </div>
        </div>

        <ion-item>
            <ion-label>Re-enter New PIN</ion-label>
        </ion-item>

        <div class="outerPinBox">
            <div class="pinBox">
              <input 
                class="pinEntry-text" 
                type="text" 
                maxlength="4" 
                #inpSecondPinEle
                (keyup.enter)="matchPin()">
              <span class="box1"></span>
              <span class="box2"></span>
              <span class="box3"></span>
              <span class="box4"></span>
              <span class="box5"></span>
            </div>
        </div>
  
      </ion-content>
  
      <ion-toolbar>
        <ion-button 
          class="btn-save" 
          color="primary" 
          expand="full"  
          (click)="matchPin()">
          Proceed
        </ion-button>
      </ion-toolbar>
    </ng-template>
</ion-modal>