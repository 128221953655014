import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthService as AuthGuard } from './services/auth/auth.service';
import { AccessControlService as AccessControl } from './services/auth/access-control.service';
import { OnlineControlService as OnlineControl } from './services/auth/online-control.service';
import { PremiumControlService as PremiumControl } from './services/auth/premium-control.service';
import { SalePage } from './pages/sale/sale.page';
import { SaleFormPage } from './pages/sale/sale-form/sale-form.page';
import { PurchasePage } from './pages/purchase/purchase.page';
import { PurchaseFormPage } from './pages/purchase/purchase-form/purchase-form.page';
import { MoneyInPage } from './pages/money-in/money-in.page';
import { MoneyInFormPage } from './pages/money-in/money-in-form/money-in-form.page';
import { MoneyOutPage } from './pages/money-out/money-out.page';
import { MoneyOutFormPage } from './pages/money-out/money-out-form/money-out-form.page';
import { DashboardPage } from './pages/dashboard/dashboard.page';
import { ProfilePage } from './pages/profile/profile.page';
import { ProfileFormPage } from './pages/profile/profile-form/profile-form.page';
import { PartyPage } from './pages/party/party.page';
import { PartyFormPage } from './pages/party/party-form/party-form.page';
import { PartyTransactionPage } from './pages/party/party-transaction/party-transaction.page';
import { ItemPage } from './pages/item/item.page';
import { ReportsPage } from './pages/reports/reports.page';
import { SigninPage } from './pages/signin/signin.page';
import { ErrorNotFoundPage } from './pages/error-not-found/error-not-found.page';
import { BillViewPage } from './pages/bill-view/bill-view.page';
import { LogoutPage } from './pages/logout/logout.page';
import { FetchInitialDataPage } from './pages/fetch-initial-data/fetch-initial-data.page';
import { CategoriesPage } from './pages/categories/categories.page';
import { ItemFormPage } from './pages/item/item-form/item-form.page';
import { ItemTransactionPage } from './pages/item/item-transaction/item-transaction.page';
import { BarcodePrintPage } from './pages/item/barcode-print/barcode-print.page';
import { StaffFormPage } from './pages/staff/staff-form/staff-form.page';
import { StaffPage } from './pages/staff/staff.page';
import { NoAccessPage } from './pages/no-access/no-access.page';
import { ExpensePage } from './pages/expense/expense.page';
import { ExpenseFormPage } from './pages/expense/expense-form/expense-form.page';
import { OrderPage } from './pages/order/order.page';
import { KotPage } from './pages/kot/kot.page';
import { IngredientPage } from './pages/ingredient/ingredient.page';
import { IngredientFormPage } from './pages/ingredient/ingredient-form/ingredient-form.page';
import { IngredientTransactionPage } from './pages/ingredient/ingredient-transaction/ingredient-transaction.page';
import { ItemBulkEditPage } from './pages/item/item-bulk-edit/item-bulk-edit.page';
import { SignInV2Page } from './pages/sign-in-v2/sign-in-v2.page';
import { WhatappBulkMsgPage } from './pages/whatapp-bulk-msg/whatapp-bulk-msg.page';
import { BillViewMoneyInPage } from './pages/bill-view-money-in/bill-view-money-in.page';
import { BillViewMoneyOutPage } from './pages/bill-view-money-out/bill-view-money-out.page';
import { BillViewPurchasePage } from './pages/bill-view-purchase/bill-view-purchase.page';
import { BillViewExpensePage } from './pages/bill-view-expense/bill-view-expense.page';
import { EstimatePage } from './pages/estimate/estimate.page';
import { EstimateFormPage } from './pages/estimate/estimate-form/estimate-form.page';
import { BillViewEstimatePage } from './pages/bill-view-estimate/bill-view-estimate.page';
import { IngredientEditPage } from './pages/ingredient/ingredient-edit/ingredient-edit.page';
import { IdbxErrorPage } from './pages/idbx-error/idbx-error.page';
import { RawMaterialPurchaseBulkEditPage } from './pages/raw-material-purchase-bulk-edit/raw-material-purchase-bulk-edit.page';
import { MarketingPage } from './pages/marketing/marketing.page';
import { LedgerLoadingPage } from './pages/ledger-loading/ledger-loading.page';
import { LedgerCheckService as LedgerCheck } from './services/auth/ledger-check.service';
import { GoogleMybusinessPage } from './pages/google-mybusiness/google-mybusiness.page';
import { FetchInitialDataV2Page } from './pages/fetch-initial-data-v2/fetch-initial-data-v2.page';
import { SaleReturnPage } from './pages/sale-return/sale-return.page';
import { SaleReturnFormPage } from './pages/sale-return/sale-return-form/sale-return-form.page';
import { BillViewSaleReturnPage } from './pages/bill-view-sale-return/bill-view-sale-return.page';
import { PurchaseReturnPage } from './pages/purchase-return/purchase-return.page';
import { PurchaseReturnFormPage } from './pages/purchase-return/purchase-return-form/purchase-return-form.page';
import { BillViewPurchaseReturnPage } from './pages/bill-view-purchase-return/bill-view-purchase-return.page';
import { SignInWithGmailPage } from './pages/sign-in-with-gmail/sign-in-with-gmail.page';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'fetch-initial-data-v2',
  },

  // signin
  {
    path: 'sign-in',
    component: SigninPage,
  },
  {
    path: 'sign-in/:token',
    component: SigninPage,
  },
  {
    path: 'sign-in-v2',
    component: SignInV2Page,
  },
  {
    path: 'sign-in-with-gmail',
    component: SignInWithGmailPage,
  },
  //-------------------------------------------------------------------------------------

  // Fetch Initial Data
  {
    path: 'fetch-initial-data',
    component: FetchInitialDataPage,
    canActivate: [AuthGuard],
  },
  {
    path: 'fetch-initial-data-v2',
    component: FetchInitialDataV2Page,
    canActivate: [AuthGuard],
  },
  //-------------------------------------------------------------------------------------

  // Profile
  {
    path: 'profile',
    component: ProfilePage,
    canActivate: [AuthGuard, PremiumControl, AccessControl, LedgerCheck],
  },
  {
    path: 'profile/form',
    component: ProfileFormPage,
    canActivate: [AuthGuard, PremiumControl, AccessControl, LedgerCheck],
  },
  //-------------------------------------------------------------------------------------

  // Dashboard
  {
    path: 'dashboard',
    component: DashboardPage,
    canActivate: [AuthGuard, AccessControl, LedgerCheck],
  },
  //-------------------------------------------------------------------------------------

  // Party
  {
    path: 'party',
    component: PartyPage,
    canActivate: [AuthGuard, PremiumControl, AccessControl, LedgerCheck],
  },
  {
    path: 'party/form',
    component: PartyFormPage,
    canActivate: [AuthGuard, PremiumControl, AccessControl, LedgerCheck],
  },
  {
    path: 'party/form/:documentId',
    component: PartyFormPage,
    canActivate: [AuthGuard, PremiumControl, AccessControl, LedgerCheck],
  },
  {
    path: 'party/transaction/:documentId',
    component: PartyTransactionPage,
    canActivate: [AuthGuard, PremiumControl, AccessControl, LedgerCheck],
  },

  {
    path: 'party/whatapp-bulk-msg',
    component: WhatappBulkMsgPage,
    canActivate: [AuthGuard, PremiumControl, LedgerCheck]
  },
  //-------------------------------------------------------------------------------------

  // Item
  {
    path: 'item',
    component: ItemPage,
    canActivate: [AuthGuard, PremiumControl, AccessControl, LedgerCheck],
  },
  {
    path: 'item/form',
    component: ItemFormPage,
    canActivate: [AuthGuard, PremiumControl, AccessControl, LedgerCheck],
  },
  {
    path: 'item/form/:documentId',
    component: ItemFormPage,
    canActivate: [AuthGuard, PremiumControl, AccessControl, LedgerCheck],
  },
  {
    path: 'item/transaction/:documentId',
    component: ItemTransactionPage,
    canActivate: [AuthGuard, PremiumControl, AccessControl, LedgerCheck],
  },
  {
    path: 'item/barcode-print',
    component: BarcodePrintPage,
    canActivate: [AuthGuard, PremiumControl, AccessControl, LedgerCheck],
  },
  {
    path: 'item/bulk-edit',
    component: ItemBulkEditPage,
    canActivate: [AuthGuard, PremiumControl, LedgerCheck],
  },
  //-------------------------------------------------------------------------------------

  // Ingredient
  {
    path: 'ingredient',
    component: IngredientPage,
    canActivate: [OnlineControl, PremiumControl, LedgerCheck],
  },
  {
    path: 'ingredient/form',
    component: IngredientFormPage,
    canActivate: [OnlineControl, PremiumControl, LedgerCheck],
  },
  {
    path: 'ingredient/form/:documentId',
    component: IngredientFormPage,
    canActivate: [OnlineControl, PremiumControl, LedgerCheck],
  },
  {
    path: 'ingredient/transaction/:documentId',
    component: IngredientTransactionPage,
    canActivate: [OnlineControl, PremiumControl, LedgerCheck],
  },
  {
    path: 'ingredient/edit/:action',
    component: IngredientEditPage,
    canActivate: [OnlineControl, PremiumControl, LedgerCheck],
  },
  {
    path: 'ingredient/edit/:action/:documentId',
    component: IngredientEditPage,
    canActivate: [OnlineControl, PremiumControl, LedgerCheck],
  },
  {
    path: 'ingredient/transaction/:documentId/raw-material-purchase-bulk-edit',
    component: RawMaterialPurchaseBulkEditPage,
    canActivate: [OnlineControl, PremiumControl, LedgerCheck],
  },
  //-------------------------------------------------------------------------------------

  // Sale
  {
    path: 'sale',
    component: SalePage,
    canActivate: [AuthGuard, PremiumControl, AccessControl, LedgerCheck],
  },
  {
    path: 'sale/form',
    component: SaleFormPage,
    canActivate: [AuthGuard, PremiumControl, AccessControl, LedgerCheck],
  },
  {
    path: 'sale/form/:documentId',
    component: SaleFormPage,
    canActivate: [AuthGuard, PremiumControl, AccessControl, LedgerCheck],
  },
  //-------------------------------------------------------------------------------------

  // Sale Return
  {
    path: 'sale-return',
    component: SaleReturnPage,
    canActivate: [AuthGuard, PremiumControl, AccessControl, LedgerCheck],
  },
  {
    path: 'sale-return/form',
    component: SaleReturnFormPage,
    canActivate: [AuthGuard, PremiumControl, AccessControl, LedgerCheck],
  },
  {
    path: 'sale-return/form/:documentId',
    component: SaleReturnFormPage,
    canActivate: [AuthGuard, PremiumControl, AccessControl, LedgerCheck],
  },
  //-------------------------------------------------------------------------------------

  // Kot
  {
    path: 'kot',
    component: KotPage,
    canActivate: [AuthGuard, PremiumControl, AccessControl, LedgerCheck],
  },
  //-------------------------------------------------------------------------------------

  //estimate
  {
    path: 'estimate',
    component: EstimatePage,
    canActivate: [AuthGuard, PremiumControl, AccessControl, LedgerCheck],
  },
  {
    path:'estimate/form',
    component:EstimateFormPage,
    canActivate:[AuthGuard, PremiumControl, AccessControl, LedgerCheck]
  },
  {
    path:'estimate/form/:documentId',
    component:EstimateFormPage,
    canActivate:[AuthGuard, PremiumControl, AccessControl, LedgerCheck]
  },
  //-------------------------------------------------------------------------------------

  // Order
  {
    path: 'order',
    component: OrderPage,
    canActivate: [AuthGuard,PremiumControl,AccessControl, LedgerCheck]
  },
  //-------------------------------------------------------------------------------------

  // Purchase
  {
    path: 'purchase',
    component: PurchasePage,
    canActivate: [AuthGuard, PremiumControl, AccessControl, LedgerCheck],
  },
  {
    path: 'purchase/form',
    component: PurchaseFormPage,
    canActivate: [AuthGuard, PremiumControl, AccessControl, LedgerCheck],
  },
  {
    path: 'purchase/form/:documentId',
    component: PurchaseFormPage,
    canActivate: [AuthGuard, PremiumControl, AccessControl, LedgerCheck],
  },
  //-------------------------------------------------------------------------------------

  // Purchase Return
  {
    path: 'purchase-return',
    component: PurchaseReturnPage,
    canActivate: [AuthGuard, PremiumControl, AccessControl, LedgerCheck],
  },
  {
    path: 'purchase-return/form',
    component: PurchaseReturnFormPage,
    canActivate: [AuthGuard, PremiumControl, AccessControl, LedgerCheck],
  },
  {
    path: 'purchase-return/form/:documentId',
    component: PurchaseReturnFormPage,
    canActivate: [AuthGuard, PremiumControl, AccessControl, LedgerCheck],
  },
  //-------------------------------------------------------------------------------------

  // Expense
  {
    path: 'expense',
    component: ExpensePage,
    canActivate: [AuthGuard, PremiumControl, AccessControl, LedgerCheck],
  },
  {
    path: 'expense/form',
    component: ExpenseFormPage,
    canActivate: [AuthGuard, PremiumControl, AccessControl, LedgerCheck],
  },
  {
    path: 'expense/form/:documentId',
    component: ExpenseFormPage,
    canActivate: [AuthGuard, PremiumControl, AccessControl, LedgerCheck],
  },
  //-------------------------------------------------------------------------------------

  // Money In
  {
    path: 'money-in',
    component: MoneyInPage,
    canActivate: [AuthGuard, PremiumControl, AccessControl, LedgerCheck],
  },
  {
    path: 'money-in/form',
    component: MoneyInFormPage,
    canActivate: [AuthGuard, PremiumControl, AccessControl, LedgerCheck],
  },
  {
    path: 'money-in/form/:documentId',
    component: MoneyInFormPage,
    canActivate: [AuthGuard, PremiumControl, AccessControl, LedgerCheck],
  },
  //-------------------------------------------------------------------------------------

  // Money Out
  {
    path: 'money-out',
    component: MoneyOutPage,
    canActivate: [AuthGuard, PremiumControl, AccessControl, LedgerCheck],
  },
  {
    path: 'money-out/form',
    component: MoneyOutFormPage,
    canActivate: [AuthGuard, PremiumControl, AccessControl, LedgerCheck],
  },
  {
    path: 'money-out/form/:documentId',
    component: MoneyOutFormPage,
    canActivate: [AuthGuard, PremiumControl, AccessControl, LedgerCheck],
  },
  //-------------------------------------------------------------------------------------

  // Categories
  {
    path: 'categories',
    component: CategoriesPage,
    canActivate: [AuthGuard, PremiumControl, AccessControl, LedgerCheck],
  },
  //-------------------------------------------------------------------------------------

  // Bill View Sale
  {
    path: 'bill-view/:documentId',
    component: BillViewPage,
    canActivate: [AuthGuard, PremiumControl, AccessControl, LedgerCheck],
  },
  //-------------------------------------------------------------------------------------

  // Bill View Sale Return
  {
    path: 'bill-view-sale-return/:documentId',
    component: BillViewSaleReturnPage,
    canActivate: [AuthGuard, PremiumControl, AccessControl, LedgerCheck],
  },
  //-------------------------------------------------------------------------------------

  // Bill View Estimate
  {
    path: 'bill-view-estimate/:documentId',
    component: BillViewEstimatePage,
    canActivate: [AuthGuard, PremiumControl, AccessControl, LedgerCheck],
  },
  //-------------------------------------------------------------------------------------

  // Bill View Purchase
  {
    path: 'bill-view-purchase/:documentId',
    component: BillViewPurchasePage,
    canActivate: [AuthGuard, PremiumControl, AccessControl, LedgerCheck],
  },
  //-------------------------------------------------------------------------------------

  // Bill View Purchase Return
  {
    path: 'bill-view-purchase-return/:documentId',
    component: BillViewPurchaseReturnPage,
    canActivate: [AuthGuard, PremiumControl, AccessControl, LedgerCheck],
  },
  //-------------------------------------------------------------------------------------

  // Bill View MoneyIn
  {
    path: 'bill-view-money-in/:documentId',
    component: BillViewMoneyInPage,
    canActivate: [AuthGuard, PremiumControl, AccessControl, LedgerCheck],
  },
  //-------------------------------------------------------------------------------------

  // Bill View MoneyOut
  {
    path: 'bill-view-money-out/:documentId',
    component: BillViewMoneyOutPage,
    canActivate: [AuthGuard, PremiumControl, AccessControl, LedgerCheck],
  },
  //-------------------------------------------------------------------------------------

  // Bill View Expense
  {
    path: 'bill-view-expense/:documentId',
    component: BillViewExpensePage,
    canActivate: [AuthGuard, PremiumControl, AccessControl, LedgerCheck],
  },
  //-------------------------------------------------------------------------------------

  // Reports
  {
    path: 'reports',
    component: ReportsPage,
    canActivate: [AuthGuard, PremiumControl, AccessControl, LedgerCheck],
  },
  //-------------------------------------------------------------------------------------

  // Staff
  {
    path: 'staff',
    component: StaffPage,
    canActivate: [AuthGuard, PremiumControl, AccessControl, LedgerCheck],
  },
  {
    path: 'staff/form',
    component: StaffFormPage,
    canActivate: [AuthGuard, PremiumControl, AccessControl, LedgerCheck],
  },
  {
    path: 'staff/form/:userId',
    component: StaffFormPage,
    canActivate: [AuthGuard, PremiumControl, AccessControl, LedgerCheck],
  },
  //-------------------------------------------------------------------------------------
  
  // Marketing
  {
    path: 'marketing',
    component: MarketingPage,
    canActivate: [AuthGuard, PremiumControl, LedgerCheck],
  },
  //-------------------------------------------------------------------------------------
  
  // Ledger loading 
  {
    path: 'ledger-loading',
    component: LedgerLoadingPage,
    canActivate: [AuthGuard],
  },
  //-------------------------------------------------------------------------------------

  // logout
  {
    path: 'logout',
    component: LogoutPage,
    canActivate: [AuthGuard]
  },
  //-------------------------------------------------------------------------------------

  // Google mybusiness
  {
    path: 'google-mybusiness',
    component: GoogleMybusinessPage,
  },
  //-------------------------------------------------------------------------------------

  // Error Page
  {
    path: 'error-not-found',
    component: ErrorNotFoundPage,
  },
  //-------------------------------------------------------------------------------------

  // No Access Page
  {
    path: 'no-access',
    component: NoAccessPage,
  },
  //-------------------------------------------------------------------------------------

  // No Index db table found page
  {
    path: 'idbx-error',
    component: IdbxErrorPage,
    canActivate: [AuthGuard],
  },
  //-------------------------------------------------------------------------------------

  {
    path: '**',
    redirectTo: '/error-not-found',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
