import { TransportDetail } from './TransportDetail.model';
import { MoneyIn } from './MoneyIn.model';
import { BaseModel } from "./BaseModel.model";
import { BillItem } from "./BillItem.model";
import Party from "./Party.model";

export enum IBillingMode {
  "c"="c",
  "n"="n",
}

export class ISale {

  static SCHEMA_NAME = 'sale';

}
export class Sale extends BaseModel {

  profileId: string;

  billNo: string;
  
  previousBillNo: string;

  billCompleteStamp: number;

  billDateStamp: number;

  dueDateStamp: number;

  billingTerm: string;

  party: Party;

  partySecondary?: Party;

  billItems: BillItem[];

  moneyIns?: MoneyIn[];

  transportDetail: TransportDetail;

  deliveryProvience: string;

  senderProvience: string;

  note: string;

  cashDiscount: number;

  cashDiscountPercentage: number;

  additionalDiscount: number;

  isPrintedKOT: boolean;

  // Calculation Keys

  discountAmount: number;

  gstAmount: number;

  cessAmount: number;

  subTotalAmount: number;

  totalAmount: number;

  totalSaving: number;

  amountReceived: number;

  roundOffValue: number;

  partyPreviousBalance: number;

  serviceChargePercentage: number;

  serviceChargeAmount: number;

  billingMode?: IBillingMode;

  linkedSaleReturnUUID: string;

}
