// Core Imports
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InfiniteScrollCustomEvent, IonDatetime, ToastController } from '@ionic/angular';
//------------------------------------------------------------------------------------------------------------

// Third Party Imports
//------------------------------------------------------------------------------------------------------------

// Models
import { Profile } from '../../../models/Profile.model';
import { Image } from '../../../models/image.model';
import { Item } from '../../../models/Item.model';
import { ItemUnit } from '../../../models/ItemUnit.model';
import ItemCategory from '../../../models/ItemCategory.model';
//------------------------------------------------------------------------------------------------------------

// Services
import { AllDataService } from '../../../services/all-data.service';
import { AccessControlService } from '../../../services/auth/access-control.service';
//------------------------------------------------------------------------------------------------------------

// Components
import { BarcodeGeneratorComponent } from '../../../components/barcode-generator/barcode-generator.component';
import { IngredientSelectorComponent } from '../../../components/ingredient-selector/ingredient-selector.component';
//------------------------------------------------------------------------------------------------------------

// Utilities
import { Utility } from '../../../utils/utility';
import { Utils } from '../../../utils/utils';
//------------------------------------------------------------------------------------------------------------

// Others
import { Validator } from '../../../utils/validator';
import { format, utcToZonedTime } from 'date-fns-tz';
import { ItemStockAdjust } from '../../../models/ItemStockAdjust.model';
import { ImageCropperComponent } from '../../../components/image-cropper/image-cropper.component';
import { MonthWiseItemStockService } from '../../../services/month-wise-item-stock.service';
import { ImageBase64Service } from '../../../services/image-base64.service';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';
//------------------------------------------------------------------------------------------------------------


@Component({
  selector: 'app-item-bulk-edit-page',
  templateUrl: './item-bulk-edit.page.html',
  styleUrls: ['./item-bulk-edit.page.scss'],
})
export class ItemBulkEditPage implements OnInit {

  // Input Decorators
  //------------------------------------------------------------------------------------------------------------

  // Output Decorators
  //------------------------------------------------------------------------------------------------------------

  // Viewchild References
  @ViewChild('barcodeGeneratorEle') barcodeGeneratorEle: BarcodeGeneratorComponent;
  @ViewChild('ingredientSelectorEle') ingredientSelectorEle: IngredientSelectorComponent;
  @ViewChild('customPrimarySecondaryUnitEle') customPrimarySecondaryUnitEle: ElementRef;
  @ViewChild('customCategoryEle') customCategoryEle: ElementRef;
  @ViewChild('imageCropperEle') imageCropperEle: ImageCropperComponent;
  //------------------------------------------------------------------------------------------------------------
  
  // Utils Methods
  getHeaderColorClass = Utility.getHeaderColorClass;
  returnZero = Utility.returnZero;
  maxDate = Utility.maxDateUpto50Year();
  capFractionsToTwo = Utils.capFractionsToTwo;
  setDateValue = Utility.setDateValue;
  //------------------------------------------------------------------------------------------------------------

  // Global Variables
  isModalOpen = true;

  minExpiryDate: string = null;

  dpExpiryDateValue: string = null;

  completeList: Item[] = [];
  viewFilteredList: Item[] = [];
  editedItems: Item[] = [];
  changeStock: ItemStockAdjust[] = [];

  itemFieldTitles: {[key:string]:string} = {
    'col-sr':'Sr',
    'col-item-name':'Item Name',
    'col-type':'Type',
    'col-sell-price':'Sell Price',
    'col-primary-unit':'Primary Unit',
    'col-category':'Category',
    'col-purchase-price':'Purchase Price',
    'col-mrp':'MRP',
    'col-online-delivery-sell-price':'Online Delivery Sell Price',
    'col-online-sell-price':'Online Sell Price',
    'col-ac-sell-price':'AC Sell Price',
    'col-non-ac-sell-price':'Non AC Sell Price',
    'col-tax-percentage':'Tax Percentage',
    'col-cess-percentage':'Cess Percentage',
    'col-spinc-tax':'Tax (Inclusive | Exclusive)',
    'col-actual-cost':'Actual Cost to Customer',
    'col-brand-name':'Brand Name',
    'col-expiry-Date':'Expiry Date',
    'col-item-code':'Item Code',
    'col-barcode-1':'Barcode 1',
    'col-barcode-2':'Barcode 2',
    'col-barcode-3':'Barcode 3',
    'col-barcode-4':'Barcode 4',
    'col-barcode-5':'Barcode 5',
    'col-hsn':'HSN',
    'col-low-stock-alert':'Low Stock Alert',
    'col-storage-location':'Storage Location',
    'col-online-dukan-item':'Show Product in Online Dukkan',
    'col-secondary-unit':'Secondary Unit',
    'col-convert-ratio':'Retail Sale Unit PER 1 Bulk Purchase Unit',
    'col-convert-ratioR':'Bulk Purchase Unit PER 1 Retail Sale Unit',
    'col-images':'Images',
    '':'',
    'col-current-stock':'Current Stock',
    'col-adjust-stock':'Adjust Stock (+10: Add, -10: Reduce)'
  };

  checkInputValue = {
    itemName: {
      name : 'Item Name',
      pattern: /([a-zA-Z0-9])+([ -~])*/
    } ,
    sellPrice: {
      name : 'Sell Price',
      pattern: /\d*[0-9]\d*/
    } 
  }

  unitsOptions = { ...Utility.unitAbvrMap };
  taxTypeOptions = Utility.taxTypeOptions;

  categories: string[] = [];
  isCreateItemCategoryPermission = true;

  selectedProfile: Profile = null;
  
  queryParamCategoryName: string = null;
  selectedBarcodeItem: any = null;
  selectedBarcode: string = null;
  selectedAddImage: any = null;
  selectedIndex: number = null;

  isOpenImageCropper = false;
  imagesBase64: {
    _localUUID: string;
    imageUUID: string;
    base64: string;
  }[] = [];
  
  queryParam: string = null;

  itemLoader: HTMLIonLoadingElement = null;
  keepDateContentsMounted: boolean = false;
  isExpiryDateModalOpen = false;
  selectedLocalUUId : string = null;

  isDisabledItemBulkUpdateBtn: boolean = false;

  //------------------------------------------------------------------------------------------------------------

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastController: ToastController,
    private allDataService: AllDataService,
    private accessControlService: AccessControlService,
    private monthWiseItemStockService: MonthWiseItemStockService,
    private imageBase64Service: ImageBase64Service,
  ) {}

  // Life Cycle Hooks
  ngOnInit() {
    try {
      this.minExpiryDate = this.setDateValue(
        +new Date().setHours(0, 0, 0, 0)
      );
      this.queryParam = this.activatedRoute.snapshot.queryParamMap.get('categoryName');
    } catch (error) {
      SentryUtilites.setLog("ItemBulkEditPage:ngOnInit", error)
    }
  }

  async ionViewWillEnter() {
    this.isModalOpen = true;
    this.setQueryParamCategoryName();
    this.getSelectedProfile();
    this.getSetAllItemData();
    this.getCustomUnit();
    this.getCategories();
    this.setCreateItemCategoryPermission();
  }

  ionViewWillLeave() {
    this.imagesBase64 = [];
    this.dpExpiryDateValue = null;
    this.keepDateContentsMounted = false;
  }

  ngOnDestroy(): void {
    this.isModalOpen = false;
  }
  //------------------------------------------------------------------------------------------------------------

  

  setQueryParamCategoryName() {
    try {
      this.queryParamCategoryName = this.activatedRoute.snapshot.queryParamMap.get('categoryName');
    } catch (error) {
      SentryUtilites.setLog("ItemBulkEditPage:setQueryParamCategoryName", error)
    }
  }

  async getSelectedProfile() {
    try {
      this.selectedProfile = await this.allDataService.profileService.getCurrentProfile();
    } catch (error) {
      SentryUtilites.setLog("ItemBulkEditPage:getSelectedProfile", error)
    }
  }

  async getSetAllItemData() {
    try {
      this.completeList = await this.allDataService.itemService.getAllByPromise() || [];
  
      const ledgerStockHashMap = await this.monthWiseItemStockService.getLedgerStockHashMap();
  
      // storing item stock
      let completeListLength = this.completeList?.length;
      for(let i = 0; i < completeListLength; i++) {
        this.completeList[i].stock = ledgerStockHashMap[this.completeList[i]?._localUUID];
        this.loadImages(this.completeList[i]);
      }
      
      this.completeList.map(item => item.type = item?.type?.toLowerCase() as any)
      this.completeList.sort((a: any, b: any) => {
        if (a?.itemName?.toLowerCase() == b?.itemName?.toLowerCase()) {
          return a?.createdStamp - b?.createdStamp;
        }
        if (b?.itemName?.toLowerCase() > a?.itemName?.toLowerCase()) {
          return -1;
        }
        return 1;
      });
      if(this.queryParamCategoryName) {
        this.completeList = this.completeList.filter(item => item?.category?.toLowerCase() === this.queryParamCategoryName?.toLowerCase());
      }
      this.viewFilteredList = [...this.completeList].splice(0, 30);
    } catch (error) {
      SentryUtilites.setLog("ItemBulkEditPage:getSetAllItemData", error)
    }
  }

  async getCustomUnit() {
    try {
      let itemUnits = (await this.allDataService.itemUnitService.getAllByPromise()) || [];
      itemUnits?.forEach(unit=> this.unitsOptions[unit.name] = unit.name);
    } catch (error) {
      SentryUtilites.setLog("ItemBulkEditPage:getCustomUnit", error)
    }
  }

  async addCustomUnit(value: string) {
    try {
      if(value) {
        if (
          this.unitsOptions[value]?.toLowerCase() !== value?.toLowerCase()
        ) {
          let itemUnit = new ItemUnit();
          itemUnit.name = value;
          let savedItemUnit = await this.allDataService.itemUnitService.save(
            itemUnit
          );
          if (savedItemUnit?._localUUID) {
            this.allDataService.listForceReloadSubs.next('item-unit-list');
            this.unitsOptions[value] = value;
          } else {
            return null;
          }
          this.customPrimarySecondaryUnitEle.nativeElement.value = '';
        }
      }
    } catch (error) {
      SentryUtilites.setLog("ItemBulkEditPage:addCustomUnit", error)
      return null;
    }
  }

  async getCategories() {
    try {
      let itemCategories = await this.allDataService.itemCategoryService.getAllByPromise();
      this.categories = itemCategories.map((x) => x?.name);
    } catch (error) {
      SentryUtilites.setLog("ItemBulkEditPage:getCategories", error)
    }
  }

  async addCategory(value: string) {
    try {
      value = value?.toLowerCase().trim();
        if (value) {
          if (
            this.categories?.findIndex(
              (x) => x?.toLowerCase() === value?.toLowerCase()
            ) === -1
          ) {
            let category = new ItemCategory();
            category.name = value;
            let savedCategory =
              await this.allDataService.itemCategoryService.save(category);
            if (savedCategory._localUUID) {
              this.allDataService.listForceReloadSubs.next('item-category-list');
              this.categories.push(value);
            } else {
              return null;
            }
          } else {
            const toast = await this.toastController.create({
              header: "Item category already exits",
              duration: 2000,
              position: 'top',
              mode: 'ios',
              color: 'warning',
            });
            await toast.present();
          }
          if(this.customCategoryEle) {
            this.customCategoryEle.nativeElement.value = '';
          }
        }
    } catch (error) {
      SentryUtilites.setLog("ItemBulkEditPage:addCategory", error)
      return null;
    }
    
  }

  async setCreateItemCategoryPermission() {
    try {
      this.isCreateItemCategoryPermission =  await this.accessControlService.isUserHasAccess({ action: 'createItemCategory'});
    } catch (error) {
      SentryUtilites.setLog("ItemBulkEditPage:setCreateItemCategoryPermission", error)
    }
  }

  onItemEditTaxSelect(__localUUID: string, value: string) {
    try {
      let index = this.editedItems.findIndex(item => item?._localUUID === __localUUID);
      if(index != -1) {
        if (value == 'Exempted') {
          this.editedItems[index].isTaxExempted = 1;
          this.editedItems[index].isTaxZero = 0;
          this.editedItems[index].isNonGstSupplies = 0;
          this.editedItems[index].taxPercentage = Utility.taxTypeOptions[value];
        }else if(value == 'Non-GST Supplies'){
          this.editedItems[index].isTaxExempted = 0;
          this.editedItems[index].isTaxZero = 0;
          this.editedItems[index].isNonGstSupplies = 1;
          this.editedItems[index].taxPercentage = Utility.taxTypeOptions[value];
        } else if (value == '0%') {
          this.editedItems[index].isTaxExempted = 0;
          this.editedItems[index].isTaxZero = 1;
          this.editedItems[index].isNonGstSupplies = 0;
          this.editedItems[index].taxPercentage = Utility.taxTypeOptions[value];
        } else {
          this.editedItems[index].isTaxExempted = 0;
          this.editedItems[index].isTaxZero = 0;
          this.editedItems[index].isNonGstSupplies = 0;
          this.editedItems[index].taxPercentage = value === 'null' ? null : Utility.taxTypeOptions[value];
        }
      } else {
        let index = this.viewFilteredList.findIndex(item => item?._localUUID === __localUUID);
        if(index != -1) {
          let item = this.viewFilteredList[index];
          if (value == 'Exempted') {
            item.isTaxExempted = 1;
            item.isTaxZero = 0;
            item.isNonGstSupplies = 0;
            item.taxPercentage = Utility.taxTypeOptions[value];
          }else if(value == 'Non-GST Supplies'){
            item.isTaxExempted = 0;
            item.isTaxZero = 0;
            item.isNonGstSupplies = 1;
            item.taxPercentage = Utility.taxTypeOptions[value];
          } else if (value == '0%') {
            item.isTaxExempted = 0;
            item.isTaxZero = 1;
            item.isNonGstSupplies = 0;
            item.taxPercentage = Utility.taxTypeOptions[value];
          } else {
            item.isTaxExempted = 0;
            item.isTaxZero = 0;
            item.isNonGstSupplies = 0;
            item.taxPercentage = value === 'null' ? null : Utility.taxTypeOptions[value];
          }
          this.editedItems.push(item)
        }
      }
    } catch (error) {
      SentryUtilites.setLog("ItemBulkEditPage:onItemEditTaxSelect", error)
    }
  }

  // removeImage(imageUUId: string, index) {
  //   let formImages: string[] = this.formArray?.at(index)?.value?.images;
  //   if (formImages?.length) {
  //     const index = formImages.findIndex((uuid) => uuid === imageUUId);
  //     if (index != -1) {
  //       formImages.splice(index, 1);
  //       this.formArray?.at(index)?.patchValue({
  //         images: formImages,
  //       });
  //       this.formArray?.at(index)?.markAsDirty();
  //       const indx = this.imagesBase64.findIndex(
  //         (imageData) => imageData.imageUUID === imageUUId
  //       );
  //       if (indx != -1) {
  //         this.imagesBase64.splice(index, 1);
  //       }
  //     }
  //   }
  // }

  // preventEmptyString(formControlName: string, index) {
  //   this.formArray.at(index).patchValue({
  //     [formControlName]: Utility.preventEmptyString(
  //       this.formArray.at(index).value[formControlName]
  //     ),
  //   });
  // }

  openBarcodeGeneratorModal(_localUUID: string, barcode: string, selectedBarcode: string, value: boolean = true) {
    try {
      this.selectedBarcode = selectedBarcode;
      if(this.editedItems.length) {
        let index = this.editedItems.findIndex(item => item?._localUUID === _localUUID);
        if (index != -1) {
          this.editedItems[index][`${selectedBarcode}`] = barcode;
        }
      } else {
        let index = this.viewFilteredList.findIndex(item => item?._localUUID === _localUUID);
        if(index != -1) {
          let item = {...this.viewFilteredList[index]};
          item[`${selectedBarcode}`] = barcode;
          this.editedItems.push(item);
        }
      }
      let index = this.viewFilteredList.findIndex(item => item?._localUUID === _localUUID);
      if(index != -1) {
        this.viewFilteredList[index][`${selectedBarcode}`] = barcode;
        this.selectedBarcodeItem = this.viewFilteredList[index];
      }
      setTimeout(()=> {
        this.barcodeGeneratorEle?.openModal(value);
      }, 200)
    } catch (error) {
      SentryUtilites.setLog("ItemBulkEditPage:openBarcodeGeneratorModal", error)
    }
  }

  async populateBarcode(barcode: any, _localUUID: string) {
    try {
      if(this.editedItems.length) {
        let editedItemsIndex = this.editedItems.findIndex(item => item?._localUUID === _localUUID);
        let viewFilteredListIndex = this.viewFilteredList.findIndex(item => item?._localUUID === _localUUID);
        if (editedItemsIndex == -1) {
          let index = this.viewFilteredList.findIndex(
            (item) => item?._localUUID === _localUUID
          );
          if (index != -1) {
            let item = { ...this.viewFilteredList[index] };
            item[`${this.selectedBarcode}`] = barcode;
            this.editedItems.push(item);
            this.viewFilteredList[viewFilteredListIndex][`${this.selectedBarcode}`] = barcode;
          }
          return;
        }
        this.editedItems[editedItemsIndex][`${this.selectedBarcode}`] = barcode;
        this.viewFilteredList[viewFilteredListIndex][`${this.selectedBarcode}`] = barcode;
      } else {
        let viewFilteredListIndex = this.viewFilteredList.findIndex(item => item?._localUUID === _localUUID);
        let index = this.viewFilteredList.findIndex(item => item?._localUUID===_localUUID);
        if(index != -1) {
          let item = {...this.viewFilteredList[index]};
          item[`${this.selectedBarcode}`] = barcode;
          this.editedItems.push(item);
          this.viewFilteredList[viewFilteredListIndex][`${this.selectedBarcode}`] = barcode;
        }
      }
    } catch (error) {
      SentryUtilites.setLog("ItemBulkEditPage:populateBarcode", error)
    }
  }

  selectedRatioInputBox: 'CONVERT_RATIO' | 'CONVERT_RATIO_R' = null;
  currentlyActiveRatioInput(whoStarted: 'CONVERT_RATIO' | 'CONVERT_RATIO_R') {
    this.selectedRatioInputBox = whoStarted;
  }

  calculateBulkPurchaseRatio(
    _localUUID: string,
    key: string,
    value
  ) {
    try {
      let editedItemsIndex = this.editedItems.findIndex(item => item?._localUUID === _localUUID);
      let viewFilteredListIndex = this.viewFilteredList.findIndex(item => item?._localUUID === _localUUID);
      if(key === 'convertRatioR') {
        if(editedItemsIndex != -1) {
          this.editedItems[editedItemsIndex][key] = Utils.capFractionsToSix(1 / this.editedItems[editedItemsIndex]?.convertRatio);
        }
        if(viewFilteredListIndex != -1) {
          this.viewFilteredList[viewFilteredListIndex][key] = Utils.capFractionsToSix(1 / this.editedItems[editedItemsIndex]?.convertRatio);
        }
      } else if (key === 'convertRatio') {
        if(editedItemsIndex != -1) {
          this.editedItems[editedItemsIndex][key] = Utils.capFractionsToSix(1 / this.editedItems[editedItemsIndex]?.convertRatioR);
        }
        if(viewFilteredListIndex != -1) {
          this.viewFilteredList[viewFilteredListIndex][key] = Utils.capFractionsToSix(1 / this.editedItems[editedItemsIndex]?.convertRatioR);
        }
      }
    } catch (error) {
      SentryUtilites.setLog("ItemBulkEditPage:calculateBulkPurchaseRatio", error)
    }
  }

  // saveForm() {
  //   let dirtyArray = this.formArray.controls.filter((control) => control.dirty);
  //   dirtyArray?.forEach(async (item) => {
  //     let filterObject = this.viewFilteredList.filter(
  //       (object) => object._localUUID === item.value._localUUID
  //     );
  //     let itemObject = { ...filterObject[0], ...item.value };
  //     delete itemObject.primaryCustomUnit;
  //     delete itemObject.secondaryCustomUnit;
  //     await this.allDataService.itemService.update(itemObject);
  //   });
  //   if (dirtyArray.length) {
  //     this.updateTrigger();
  //   }
  // }

  // onSpIncTaxChange(event, index) {
  //   if (event?.detail?.value === true || event?.detail?.value === 'true') {
  //     this.formArray.at(index).patchValue({ spIncTax: true });
  //   } else if (
  //     event?.detail?.value === false ||
  //     event?.detail?.value === 'false'
  //   ) {
  //     this.formArray.at(index).patchValue({ spIncTax: false });
  //   }
  //   this.formArray.at(index).markAsDirty();
  // }

  async loadImages(item) {
    try {
      if (item?.images?.length) {
        for (let i = 0; i < item?.images?.length; i++) {
          const imageId = item?.images[i];
          const image = await this.allDataService.imageService.getByUUID(imageId);
          let base64 = await this.imageBase64Service.getBase64FromDBorServerWithoutSave(image);
          if (base64 && image) {
            this.imagesBase64.push({
              _localUUID: item?._localUUID,
              imageUUID: image?._localUUID,
              base64,
            });
          }
        }
      }
    } catch (error) {
      SentryUtilites.setLog("ItemBulkEditPage:loadImages", error)
    }
  }

  openImageCropper(_localUUID: string, value: boolean = true) {
    try {
      this.isOpenImageCropper = value;
      let viewFilteredListIndex = this.viewFilteredList.findIndex(item => item?._localUUID === _localUUID);
      this.selectedAddImage = {...this.viewFilteredList[viewFilteredListIndex]};
      this.imageCropperEle?.openModal();
    } catch (error) {
      SentryUtilites.setLog("ItemBulkEditPage:openImageCropper", error)
    }
  }

  async onImageSaved(image: Image) {
    try {
      image = await this.allDataService.imageService.getByUUID(image?._localUUID);
      let editedItemsIndex = this.editedItems.findIndex(item => item?._localUUID === this.selectedAddImage?._localUUID);
      let viewFilteredListIndex = this.viewFilteredList.findIndex(item => item?._localUUID === this.selectedAddImage?._localUUID);
      if(editedItemsIndex != -1) {
        this.editedItems[editedItemsIndex]?.images?.push(image?._localUUID);
      } else {
        if(this.viewFilteredList[viewFilteredListIndex]?.images?.length) {
          this.viewFilteredList[viewFilteredListIndex]?.images?.push(image?._localUUID);
        } else {
          this.viewFilteredList[viewFilteredListIndex]['images'] = [image?._localUUID];
        }
        this.editedItems?.push(this.viewFilteredList[viewFilteredListIndex]);
      }
      if (image?.imageBase64) {
        this.imagesBase64?.push({
          _localUUID: this.selectedAddImage?._localUUID,
          imageUUID: image?._localUUID,
          base64: image?.imageBase64,
        });
      }
    } catch (error) {
      SentryUtilites.setLog("ItemBulkEditPage:onImageSaved", error)
    }
  }

  // openIngredientSelector(index) {
  //   this.selectedIndex = index;
  //   this.selectedBarcodeItem = this.formArray.at(index);
  //   this.ingredientSelectorEle?.openModal();
  // }

  // saveIngredients(itemIngredients: ItemIngredient[]) {
  //   this.selectedBarcodeItem?.patchValue({ itemIngredients });
  //   this.formArray.at(this.selectedIndex).markAsDirty();
  // }

  openExpiryDatePicker(_localUUID: string, value: any) {
    try {
      this.selectedLocalUUId = _localUUID;
      this.dpExpiryDateValue = value ? Utility.ionDatePickerFormattedString(value) : null;
      this.keepDateContentsMounted = true;
      this.isExpiryDateModalOpen = true;
    } catch (error) {
      SentryUtilites.setLog("ItemBulkEditPage:openExpiryDatePicker", error)
    }
  }

  onCancelExpiryDate(event) {
    try {
      if(this.dpExpiryDateValue) {
        event.target.reset(this.dpExpiryDateValue);
      } else {
        event.target.reset(null);
      }
      this.keepDateContentsMounted = false;
    } catch (error) {
      SentryUtilites.setLog("ItemBulkEditPage:onCancelExpiryDate", error)
    }
  }

  closeDOBPicker(event) {
    this.isExpiryDateModalOpen = false;
    this.keepDateContentsMounted = false;
  }

  onExpiryDateSelect(event) {
    try {
      if(event?.detail?.value) {
        this.dpExpiryDateValue = event?.detail?.value;
        if(this.editedItems?.length) {
          let index = this.editedItems.findIndex(item => item?._localUUID===this.selectedLocalUUId);
          if(index != -1) {
            this.editedItems[index]['expiryDate'] = +new Date(event?.detail?.value).setHours(0, 0, 0, 0);
            return;
          }
        }
        let index = this.viewFilteredList.findIndex(item => item?._localUUID===this.selectedLocalUUId);
        if(index != -1) {
          let item = this.viewFilteredList[index];
          item.expiryDate = +new Date(event?.detail?.value).setHours(0, 0, 0, 0);
          this.editedItems.push(item);
        }
        // this.dpExpiryDateValue = null;
        // this.isExpiryDateModalOpen = false;
        // event.confirm();
        // event.reset()
        Utility.closeDatePicker(event?.target);
        this.keepDateContentsMounted = false;
      }
    } catch (error) {
      SentryUtilites.setLog("ItemBulkEditPage:onExpiryDateSelect", error)
    }
  }

  updateTrigger() {
    this.presentToast('Item Updated Successfully', 'success');
    this.router.navigate(['item']);
  }

  async presentToast(header: string, color: string) {
    const toast = await this.toastController.create({
      header,
      duration: 2000,
      position: 'top',
      mode: 'ios',
      color: color,
    });
    await toast.present();
  }

  loadMoreListData(event) {
    try {
      if (
        this.viewFilteredList.length > 0 &&
        this.viewFilteredList.length <= this.completeList.length
      ) {
          let appendListLength = this.completeList.length - this.viewFilteredList.length;
        let lastEl = this.viewFilteredList[this.viewFilteredList.length - 1];
        let counter = 0;
        for (let i = 0; i < this.completeList.length; i++) {
          if (this.completeList[i]._localUUID == lastEl._localUUID) {
            counter = 1;
            continue;
          }
          if (counter > 0 && appendListLength >= 30) {
            if (counter <= 30) {
              this.viewFilteredList.push({ ...this.completeList[i] });
            } else {
              break;
            }
            counter++;
          } else if (counter > 0 && appendListLength < 30) {
            if (counter <= appendListLength) {
              this.viewFilteredList.push({ ...this.completeList[i] });
            } else {
              break;
            }
            counter++;
          }
        }
        this.loadImages(this.viewFilteredList);
        (event as InfiniteScrollCustomEvent).target.complete();
      }
    } catch (error) {
      SentryUtilites.setLog("ItemBulkEditPage:loadMoreListData", error)
    }
  }

  onItemEdit(_localUUID: string, key: string, value: any) {
    try {
      let priceArr = ["sellPrice","purchasePrice","mrp","onlineDeliverySellPrice","onlineSellPrice","acSellPrice","nonAcSellPrice"];
      if(this.editedItems?.length) {
        let index = this.editedItems.findIndex(item => item?._localUUID===_localUUID);
        if(index != -1) {
          if(priceArr?.includes(key)) {
            this.editedItems[index][key] = Math.abs(value);
          }else {
            if(key === 'onlineDukanItem') {
              this.editedItems[index][key] = value === 'true' ? true : false;
            } else {
              this.editedItems[index][key] = value == "null" ? null : value;
            }
          }
          // this.checkNameSellPriceValue(this.editedItems[index]);
          return;
        }
      }
      let index = this.viewFilteredList.findIndex(item => item?._localUUID===_localUUID);
      if(index != -1) {
        let item = this.viewFilteredList[index];
        if(priceArr?.includes(key)) {
          item[key] = Math.abs(value);
        }else {
          if(key === 'onlineDukanItem') {
            item[key] = value === 'true' ? true : false;
          } else {
            item[key] = value == "null" ? null : value;
          }
        }
        // this.checkNameSellPriceValue(item);
        this.editedItems.push(item);
      }
    } catch (error) {
      SentryUtilites.setLog("ItemBulkEditPage:onItemEdit", error)
    }
  }
/**
 * 
 * @param _localUUID : provide item _localUUID
 * @param value : input value
 */
  async adjustStock(_localUUID: string, value: number) {
    try {
      let index = this.viewFilteredList.findIndex(item=> item?._localUUID===_localUUID);
      if(index != -1) {
        this.viewFilteredList[index]['adjustStock'] = value;
        let editIndex = this.editedItems?.findIndex(item=> item?._localUUID===_localUUID);
        if(editIndex == -1) {
          this.editedItems.push(this.viewFilteredList[index]);
        }
  
        let changeIndex = this.changeStock?.findIndex(itemStock=> itemStock?.linkedItemUUID===_localUUID);
        if(changeIndex == -1) {
          const itemStockAdjust = new ItemStockAdjust();
          itemStockAdjust.quantity = value;
          itemStockAdjust.linkedItemUUID = _localUUID;
          this.changeStock.push(itemStockAdjust);
        } else {
          this.changeStock[changeIndex].quantity = value;
        }
      }
    } catch (error) {
      SentryUtilites.setLog("ItemBulkEditPage:isQuantityThresholdMaintanined", error)
    }
  }
  // -----------------------------------------------------

  noSpaceAtStartValidator(value) {
    try {
      if (value && /^\s/.test(value)) {
        return { startsWithSpace: true };
      }
      return null;
    } catch (error) {
      SentryUtilites.setLog("ItemBulkEditPage:noSpaceAtStartValidator", error)
      return null;
    }
  }

  checkNameSellPriceValue(item: Item) {
    try {
      if(
        !item?.itemName
        && !item?.sellPrice
        && !item?.itemName?.match(this.checkInputValue?.itemName?.pattern) 
        && !item?.sellPrice?.toString().match(this.checkInputValue?.sellPrice?.pattern)
      ) {
        this.presentToast(`Item name & sell price are mandatory. Please provide valid details to update.`,'danger');
      }
    } catch (error) {
      SentryUtilites.setLog("ItemBulkEditPage:checkNameSellPriceValue", error)
    }
  }

  async updateEditedItems() {
    try {
      this.isDisabledItemBulkUpdateBtn = true;
      this.editedItems?.forEach(item => {
        let index = this.completeList?.findIndex(x => x?._localUUID===item?._localUUID);
        if(index != -1) {
          this.completeList.splice(index,1,item);
        }
      });
  
      let invalidEditedItems = this.editedItems.filter(item => !item?.itemName);
      let invalidCompleteItems = this.completeList.filter(item => !item?.itemName);
      if(invalidEditedItems?.length || invalidCompleteItems?.length) {
        this.presentToast(`Item name are mandatory for all Items. Please provide valid details to update.`,'danger');
        return null;
      }

      let invalidEditedItemsconvertRatio = this.editedItems.filter(item => item?.secondaryUnit && item?.secondaryUnit!='null' && (!item?.convertRatio || !item?.convertRatioR));
      let invalidCompleteItemsconvertRatio = this.completeList.filter(item => item?.secondaryUnit && item?.secondaryUnit!='null' && (!item?.convertRatio || !item?.convertRatioR));
      if(invalidCompleteItemsconvertRatio?.length || invalidEditedItemsconvertRatio?.length) {
        this.presentToast(`Retail Sale Unit PER 1 Bulk Purchase Unit are mandatory for those have Secondary Unit. Please provide valid details to update.`,'danger');
        return null;
      }
  
      if(this.editedItems?.length || this.changeStock?.length) {
  
        let changeStockLength = this.changeStock?.length;
        for(let i=0; i<changeStockLength; i++) {
          this.allDataService.itemStockAdjustService.save(this.changeStock[i]);
        }
  
        this.dpExpiryDateValue = null;
        this.keepDateContentsMounted = false;
        this.editedItems?.forEach(item => {
          if(item.hasOwnProperty('adjustStock')) {
            item.stock = +item.stock + item['adjustStock']
          }
          delete item['adjustStock']
          this.allDataService.itemService.update(item);
          this.allDataService.listForceReloadSubs.next('item-list');
        });
        
        setTimeout(()=>{
          this.updateTrigger();
        }, 1000);
        this.isModalOpen = false;
      }
    } catch (error) {
      SentryUtilites.setLog("ItemBulkEditPage:updateEditedItems", error)
    }

  }

  getTaxPercentageValue(item: Item) {
    try {
      if(item?.taxPercentage) {
          return item?.taxPercentage + '%';
      }else{
        if(item?.isTaxExempted && !item?.isTaxZero && !item?.isNonGstSupplies) {
          return 'Exempted';
        }else if(!item?.isTaxExempted && item?.isTaxZero && !item?.isNonGstSupplies) {
          return '0%';
        }else if(!item?.isTaxExempted && !item?.isTaxZero && item?.isNonGstSupplies){
          return 'Non-GST Supplies';
        }else{
          return null
        }
      }
    } catch (error) {
      SentryUtilites.setLog("ItemBulkEditPage:getTaxPercentageValue", error)
      return null;
    }
  }

  closeItemBulkEditPage() {
    try {
      if(this.queryParam) {
        this.router.navigate(['categories'], {
          queryParams: {
            selectItem: true,
          },
        })
      }
      this.isModalOpen = false;
    } catch (error) {
      SentryUtilites.setLog("ItemBulkEditPage:closeItemBulkEditPage", error)
    }
  }
  
}
