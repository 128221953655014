import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-app-update',
  templateUrl: './app-update.component.html',
  styleUrls: ['./app-update.component.scss'],
})
export class AppUpdateComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

  updateApp() {
    document?.location?.reload();
  }

}
