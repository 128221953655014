<ion-list>
  <ion-reorder-group [disabled]="false" (ionItemReorder)="handleReorder($any($event))">
    <ion-item lines="none" *ngFor="let itemCategory of viewItemCategories">
      <ion-button fill="clear" class="txt-transform-normal" *ngIf="!itemCategory?.isEdit">
        {{ itemCategory?.category?.name }} ({{categoryWiseItemCount[itemCategory?.category?.name] || 0}})
      </ion-button>
      <ion-input
        #editInputEle
        *ngIf="itemCategory?.isEdit"
        class="new-ion-input"
        [value]="itemCategory?.category?.name"
      >
      </ion-input>
      <ion-button *ngIf="itemCategory?.isEdit" class="btn-update" color="primary" size="small" (click)="update(itemCategory?.category?._localUUID)">
        Update
      </ion-button>
      <ion-button *ngIf="itemCategory?.isEdit" class="btn-cancel" color="light" size="small" (click)="disableEdit(itemCategory)">
        Cancel
      </ion-button>
      <ion-button title="Edit" color="primary" slot="end" fill="clear" (click)="enableEdit(itemCategory)">
        <ion-icon name="create"></ion-icon>
      </ion-button>
      <ion-button title="Bulk Edit" color="primary" slot="end" fill="clear" (click)="bulkEdit(itemCategory.category.name)">
        <ion-icon name="albums"></ion-icon>
      </ion-button>
      <ion-button title="Delete" color="danger" slot="end" fill="clear" (click)="delete(itemCategory?.category)">
        <ion-icon name="trash"></ion-icon>
      </ion-button>
      <ion-reorder slot="end"></ion-reorder>
    </ion-item>
  </ion-reorder-group>
</ion-list>

<ion-infinite-scroll threshold="10px" *ngIf="viewItemCategories?.length" (ionInfinite)="loadMoreListData($event)">
  <ion-infinite-scroll-content loadingSpinner="bubbles" loadingText="Loading more data...">
  </ion-infinite-scroll-content>
</ion-infinite-scroll>

<app-pin-verification (verifyTransactionPin)="verifyTransactionPIN($event)" #pinVerificationElement></app-pin-verification>