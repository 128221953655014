import { GoogleMybusinessService } from '../../services/google-mybusiness.service';
import { MonthWiseItemStockService } from './../../services/month-wise-item-stock.service';
import { MonthWisePartyCreditService } from './../../services/month-wise-party-credit.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, AfterViewInit, ViewChild, HostListener } from '@angular/core';
import { AllDataService } from './../../services/all-data.service';
import { SettingsComponent } from '../../components/settings/settings.component';
import { Utility } from '../../utils/utility';
import { AccessControlService } from '../../services/auth/access-control.service';
import { Sale } from '../../models/Sale.model';
import { DeleteDataComponent } from '../../components/delete-data/delete-data.component';
import { CutOffDay } from '../../models/CutOffDay.model';
import { Profile } from '../../models/Profile.model';
import { Subscription } from 'rxjs';
import { PremiumControlService } from 'src/app/services/auth/premium-control.service';
import { CommonService } from '../../services/common.service';
import { NewFeatureSlidesComponent } from '../../components/new-feature-slides/new-feature-slides.component';
import { AlertController, InfiniteScrollCustomEvent } from '@ionic/angular';
import { EventService } from '../../services/event.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import Party from '../../models/Party.model';
import { environment } from '../../../environments/environment';
import { SaleBulkDeleteComponent } from '../../components/bulk-delete/sale-bulk-delete/sale-bulk-delete.component';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.page.html',
  styleUrls: ['./dashboard.page.scss'],
})
export class DashboardPage implements OnInit, AfterViewInit {
  @ViewChild('settingsModal') settingsModal: SettingsComponent;
  @ViewChild('deleteDataEle') deleteDataEle: SaleBulkDeleteComponent;
  @ViewChild('newFeatureSlidesEle') newFeatureSlidesEle: NewFeatureSlidesComponent;

  getHeaderColorClass = Utility.getHeaderColorClass;
  isMobile: boolean = null;

  lastSyncTime = Utility.getCollectionLastRespSyncTime('dashboard');
  allListSubs: Subscription;


  isTimeDifference = false;
  selectedDateRange: 'today' | 'tomorrow' | 'week' = 'today';
  completeList: Sale[] = [];
  viewFilteredList: Sale[] = [];
  birthdayFilterList: Party[] = [];
  birthdayCompleteList: Party[] = [];
  selectedSale: Sale = null;
  showMoneyInSelector = false;
  isOptionOpen: boolean = false;

  privacyStatus: boolean = true;

  birthdayReminder: boolean = false;

  isRecentTransactionList = true;
  isPaymentReminderList = false;
  isBirthdayReminderList = false;

  selectedProfile: Profile = null;

  isPremiumAccess = false;

  profileSubs: Subscription;
  lastSyncSubs: Subscription;

  unsyncedRecordsCount: number = 0;

  saleCredits: number = 0;
  isShowRedSaleButton: boolean = false;

  cutoffDayAlertOpen: boolean = false;

  cutOffDayAlert = null;

  startTimeStamp: number = 0;

  hideRightScroll: boolean = false;

  hideGMBRightScroll: boolean = false;

  hideScroll: boolean = true;

  showCards: boolean = false;

  locationMetricData = null;

  isLocationMetricsHasData: boolean = false;

  gmbLocationId: string = '';

  isNgOnInitRun: boolean = false;

  loadViewTimeStamp: number = 0;

  cardList: {
    filter: 'Today' | 'All';
    title: string;
    amount: number;
    rupeeSymb: boolean;
    formUrl: string;
    formQueryParam: object;
    listUrl: string;
    listQueryParam: object;
    loader: boolean;
    info: string;
    color:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'success'
    | 'warning'
    | 'danger'
    | 'light'
    | 'medium'
    | 'dark';
    colSize: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    serviceName: string;
    callback: Function;
    isVisbile: boolean;
    showRefresh?: boolean;
    replyWithAI?: boolean;
  }[] = [
      {
        filter: 'Today',
        title: 'Sale',
        amount: null,
        rupeeSymb: true,
        formUrl: '/sale/form',
        formQueryParam: {},
        listUrl: '/sale',
        listQueryParam: {},
        loader: true,
        info: 'Total Sale Amount for Today',
        color: 'primary',
        colSize: 3,
        serviceName: 'saleService',
        callback: this.generateTotalAmount.bind(this),
        isVisbile: true,
      },
      {
        filter: 'Today',
        title: 'Money In',
        amount: null,
        rupeeSymb: true,
        formUrl: '/money-in/form',
        formQueryParam: {},
        listUrl: '/money-in',
        listQueryParam: {},
        loader: true,
        info: 'Total Money In (Cash, Bank, Cheque, UPI) Amount for Today',
        color: 'success',
        colSize: 3,
        serviceName: 'moneyInService',
        callback: this.generateTotalAmount.bind(this),
        isVisbile: true,
      },
      {
        filter: 'All',
        title: 'Receivable',
        amount: null,
        rupeeSymb: true,
        formUrl: null,
        formQueryParam: {},
        listUrl: '/party',
        listQueryParam: { receivable: true },
        loader: true,
        info: 'Total Receivable Amount',
        color: 'success',
        colSize: 3,
        serviceName: 'partyService',
        callback: this.generateReceivableAmount.bind(this),
        isVisbile: true,
      },
      {
        filter: 'All',
        title: 'Payable',
        amount: null,
        rupeeSymb: true,
        formUrl: null,
        formQueryParam: {},
        listUrl: '/party',
        listQueryParam: { payable: true },
        loader: true,
        info: 'Total Payable Amount',
        color: 'danger',
        colSize: 3,
        serviceName: 'partyService',
        callback: this.generatePayableAmount.bind(this),
        isVisbile: true,
      },
      {
        filter: 'All',
        title: 'Low Stock',
        amount: null,
        rupeeSymb: false,
        formUrl: null,
        formQueryParam: {},
        listUrl: '/item',
        listQueryParam: { isLowStockListFilter: true },
        loader: true,
        info: 'Total Low Stock Items',
        color: 'secondary',
        colSize: 3,
        serviceName: 'itemService',
        callback: this.generateLowStockCount.bind(this),
        isVisbile: true,
      },
      {
        filter: 'Today',
        title: 'Purchase',
        amount: null,
        rupeeSymb: true,
        formUrl: '/purchase/form',
        formQueryParam: {},
        listUrl: '/purchase',
        listQueryParam: {},
        loader: true,
        info: 'Total Purchase Amount for Today',
        color: 'secondary',
        colSize: 3,
        serviceName: 'purchaseService',
        callback: this.generateTotalAmount.bind(this),
        isVisbile: true,
      },
      {
        filter: 'Today',
        title: 'Money Out',
        amount: null,
        rupeeSymb: true,
        formUrl: '/money-out/form',
        formQueryParam: {},
        listUrl: '/money-out',
        listQueryParam: {},
        loader: true,
        info: 'Total Money Out (Cash, Bank, Cheque, UPI) Amount for Today',
        color: 'danger',
        colSize: 3,
        serviceName: 'moneyOutService',
        callback: this.generateTotalAmount.bind(this),
        isVisbile: true,
      },

      {
        filter: 'Today',
        title: 'Customer',
        amount: null,
        rupeeSymb: false,
        formUrl: null,
        formQueryParam: {},
        listUrl: null,
        listQueryParam: {},
        loader: true,
        info: null,
        color: 'primary',
        colSize: 3,
        serviceName: 'saleService',
        callback: this.generateTotalCustomerCount.bind(this),
        isVisbile: true,
      },
      {
        filter: 'Today',
        title: 'Loyal Customer',
        amount: null,
        rupeeSymb: false,
        formUrl: null,
        formQueryParam: {},
        listUrl: null,
        listQueryParam: {},
        loader: true,
        info: null,
        color: 'success',
        colSize: 3,
        serviceName: 'saleService',
        callback: this.generateLoyalCustomerCount.bind(this),
        isVisbile: true,
      },
      {
        filter: 'Today',
        title: 'New Customer',
        amount: null,
        rupeeSymb: false,
        formUrl: null,
        formQueryParam: {},
        listUrl: null,
        listQueryParam: {},
        loader: true,
        info: null,
        color: 'secondary',
        colSize: 3,
        serviceName: null,
        callback: this.generateNewCustomerCount.bind(this),
        isVisbile: true,
      },
      {
        filter: null,
        title: 'Google Score',
        amount: null,
        rupeeSymb: false,
        formUrl: null,
        formQueryParam: {},
        listUrl: null,
        listQueryParam: {},
        loader: true,
        info: null,
        color: null,
        colSize: 3,
        serviceName: null,
        callback: this.generateScoreCount.bind(this),
        isVisbile: true,
      },{
        filter: null,
        title: 'Reply Pending',
        amount: null,
        rupeeSymb: false,
        formUrl: null,
        formQueryParam: {},
        listUrl: null,
        listQueryParam: {},
        loader: true,
        info: null,
        color: null,
        colSize: 3,
        serviceName: null,
        callback: this.generateReplyPendingCount.bind(this),
        isVisbile: true,
        replyWithAI: true,
      },{
        filter: null,
        title: 'Reviews (Lifetime)',
        amount: null,
        rupeeSymb: false,
        formUrl: null,
        formQueryParam: {},
        listUrl: null,
        listQueryParam: {},
        loader: true,
        info: null,
        color: null,
        colSize: 3,
        serviceName: null,
        callback: this.generateReviewsCount.bind(this),
        isVisbile: true,
      },{
        filter: null,
        title: 'Views (last 30 days)',
        amount: null,
        rupeeSymb: false,
        formUrl: null,
        formQueryParam: {},
        listUrl: null,
        listQueryParam: {},
        loader: true,
        info: null,
        color: null,
        colSize: 3,
        serviceName: null,
        callback: this.generateViewsCount.bind(this),
        isVisbile: true,
      },{
        filter: null,
        title: 'Engagements (last 30 days)',
        amount: null,
        rupeeSymb: false,
        formUrl: null,
        formQueryParam: {},
        listUrl: null,
        listQueryParam: {},
        loader: true,
        info: null,
        color: null,
        colSize: 3,
        serviceName: null,
        callback: this.generateEngagementsCount.bind(this),
        isVisbile: true,
      },
      {
        filter: null,
        title: null,
        amount: null,
        rupeeSymb: false,
        formUrl: null,
        formQueryParam: {},
        listUrl: null,
        listQueryParam: {},
        loader: true,
        info: null,
        color: null,
        colSize: 3,
        serviceName: null,
        callback: null,
        isVisbile: true,
        showRefresh: true,
      },
    ];
  MATHABS = Math.abs;
  constructor(
    private allDataService: AllDataService,
    private router: Router,
    private activetedRoute: ActivatedRoute,
    private accessControlService: AccessControlService,
    private premiumControlService: PremiumControlService,
    private commonService: CommonService,
    private eventService: EventService,
    private alertController: AlertController,
    private monthWisePartyCreditService: MonthWisePartyCreditService,
    private monthWiseItemStockService: MonthWiseItemStockService,
    private googleMybusinessService: GoogleMybusinessService,
  ) { }

  async ngOnInit() {
    try {
      this.isNgOnInitRun = true;

      // reload (refresh) page if in queryParam have a == r
      let a = this.activetedRoute?.snapshot?.queryParams?.a;
      if(a === 'r') {
        this.router.navigate(['dashboard']);
        setTimeout(() => {
          window?.location?.reload();
        }, 500);
      }

      this.isMobile = this.commonService.isMobile();
      let selectedProfile = Utility.getFromLocalStorage('selectedProfile');
      this.gmbLocationId = Utility.getFromLocalStorage(`gmbLocationId_${selectedProfile}`);
      if(this.gmbLocationId?.length) {
        this.getLocationMetrics();
      }
      await this.isUserHasPremimumAccess();
      if (!this.isPremiumAccess) {
        this.saleCredits = (await this.allDataService.saleService.nonPremiumSaleCeditRemaining()) || 0;
        if(!this.saleCredits) {
          this.isShowRedSaleButton = true;
        }
      }
      this.lastSyncSubs = this.allDataService.lastSyncSubs.subscribe((x) => {
        this.lastSyncTime = Utility.getCollectionLastRespSyncTime(
          'dashboard'
        );
        this.isTimeDifference = this.allDataService.isTimeDifference;
      });
      this.profileSubs = this.allDataService.listForceReloadSubs.pipe(debounceTime(2000)).subscribe(async (listName: string) => {
        if (
          listName == 'profile-list'
          || listName == 'sale-list'
          || listName == 'moneyin-list'
          || listName == 'party-list'
          || listName == 'licence-list'
          || listName == 'item-list'
          || listName == 'moneyout-list'
          || listName == 'purchase-list'
        ) {
          this.allDataService.saleService.reloadList();
          await this.getSelectedProfile();
          this.setCardsVisibility()
          this.reduceFunctionCall();
          // await this.isUserHasPremimumAccess();
          if (!this.isPremiumAccess) {
            this.saleCredits = (await this.allDataService.saleService.nonPremiumSaleCeditRemaining()) || 0;
            if(!this.saleCredits) {
              this.isShowRedSaleButton = true;
            } else {
              this.isShowRedSaleButton = false;
            }
          }
        }

      });
      await this.getSelectedProfile();
      this.populatePrivacySetting();
      this.setCardsVisibility();
      this.reduceFunctionCall();
      // setInterval(()=> {
      //   this.allDataService.initService();
      // }, environment.syncIntervalMillis);
      this.getAllUnsyncData();
    } catch (error) {
      SentryUtilites.setLog("DashboardPage:ngOnInit", error)
    }
  }

  async ngAfterViewInit() {
    try {
      this.gmbLocationId = Utility.getFromLocalStorage(`gmbLocationId_${Utility.getFromLocalStorage('selectedProfile')}`);
      await this.isUserHasPremimumAccess();
      if (!this.allListSubs || this.allListSubs?.closed) {
        this.allListSubs = this.allDataService.listForceReloadSubs.subscribe((list) => {
          if (list == 'all-list') {
            this.reduceFunctionCall();
            let selectedProfile = Utility.getFromLocalStorage('selectedProfile');
            this.gmbLocationId = Utility.getFromLocalStorage(`gmbLocationId_${selectedProfile}`);
            if(this.gmbLocationId?.length) {
              this.getLocationMetrics();
            }
          }
          this.getAllUnsyncData();

        });
      }
    } catch (error) {
      SentryUtilites.setLog("DashboardPage:ngAfterViewInit", error)
    }
  }

  async ionViewWillEnter() {
    try {
      this.cutOffDayAlert?.dismiss();
      this.cutoffDayAlertOpen = false;
      if(!this.isNgOnInitRun) {
        this.ngOnInit();
      }
    } catch (error) {
      SentryUtilites.setLog("DashboardPage:ionViewWillEnter", error)
    }
  }

  ionViewWillLeave() {
    this.ngOnDestroy();
  }

  ngOnDestroy() {
    try {
      this.cutoffDayAlertOpen = true;
      this.isNgOnInitRun = false;
      this.profileSubs?.unsubscribe();
      this.allListSubs?.unsubscribe();
      this.lastSyncSubs?.unsubscribe();
    } catch (error) {
      SentryUtilites.setLog("DashboardPage:ngOnDestroy", error)
    }
  }

  async isUserHasPremimumAccess() {
    try {
      this.isPremiumAccess = await this.premiumControlService.isPremiumAccess({ alert: true });
      return this.isPremiumAccess;
    } catch (error) {
      SentryUtilites.setLog("DashboardPage:isUserHasPremimumAccess", error)
      return false;
    }
  }

  @HostListener('window:resize', ['$event'])
  OnResize() {
    setTimeout(() => {
      this.isMobile = this.commonService.isMobile()
    }, 20)
  }

  async getSelectedProfile() {
    try {
      this.selectedProfile = await this.allDataService.profileService.getCurrentProfile();
      this.birthdayReminder = this.selectedProfile?.aSetBirthdayReminderStatus;
    } catch (error) {
      SentryUtilites.setLog("DashboardPage:getSelectedProfile", error)
    }
  }

  async loadView() {
    try {
      let startTime: number = +new Date().setHours(0, 0, 0, 0);
      let endTime: number = startTime + 86400000 - 1;

      if (this.selectedProfile?.aAppCutOffDayStatus) {
        let latestCutOffDay = await this.allDataService.cutOffDayService.getLatestCutOff();
        let noCutoffAlertStamp = Utility.getFromLocalStorage(`_no_cutoff_day_alert_stamp-${this.selectedProfile?._localUUID?.substr(-4)}`) ? +Utility.getFromLocalStorage(`_no_cutoff_day_alert_stamp-${this.selectedProfile?._localUUID?.substr(-4)}`) : 0;
        let hours24Millis = 86400000;
        let endStampSpan = +new Date() - latestCutOffDay?.endStamp;
        let noCutoffAlertStampDays = 0
        let latestCutOffDayEndStampDays = 0

        // logic for cut off day alert stamp to show latestCutOffDay endStamp days and latestCutOffDayEndStampDays from current time
        if (noCutoffAlertStamp) {
          noCutoffAlertStampDays = Math.ceil((noCutoffAlertStamp - latestCutOffDay?.endStamp) / hours24Millis);
          latestCutOffDayEndStampDays = Math.ceil(endStampSpan / hours24Millis);
        }

        if (latestCutOffDay?.endStamp && endStampSpan < hours24Millis) {
          startTime = latestCutOffDay?.endStamp;
          endTime = +new Date();
        }

        // logic for show cut off day alert
        if (latestCutOffDay?.endStamp && endStampSpan > hours24Millis) {
          if ((!noCutoffAlertStamp || noCutoffAlertStampDays !== latestCutOffDayEndStampDays) && !this.cutoffDayAlertOpen) {
            this.cutoffDayAlertOpen = true;
            this.endDay();
          }
        }
      }
      let newCompleteList = await this.allDataService.saleService.getByBillCompleteDateRange(startTime, endTime) || [];
      if (
        newCompleteList.length != this.completeList.length ||
        JSON.stringify(newCompleteList) != JSON.stringify(this.completeList)
      ) {
        this.completeList = [];
        this.completeList = newCompleteList;
        this.completeList?.sort((a: Sale, b: Sale) => {
          return b?.billCompleteStamp - a?.billCompleteStamp;
        });
        this.viewFilteredList = [...this.completeList.slice(0, 50)];
      }
      //Get all Parties
      let filterParty = await this.allDataService.partyService.getAllByPromise() || [];
      this.birthdayCompleteList = filterParty.filter((party) => party?.dateOfBirth) || [];
      // //set default today birthday list
      if (this.birthdayReminder && this.selectedDateRange =='today') {
        let currentDate = new Date();
        let getCurrentDate = currentDate.getDate();
        let getCurrentMonth = currentDate.getMonth();
        this.birthdayFilterList = this.birthdayCompleteList.filter((party) => {
          let birthdate = new Date(party?.dateOfBirth);
          let birthDateDate = birthdate.getDate();
          let birthDateMonth = birthdate.getMonth();
          party['daysToGo'] = birthDateDate - getCurrentDate;
          party['birthDate'] = `${birthDateDate}-${birthDateMonth + 1}`;
          return (
            birthDateDate === getCurrentDate &&
            birthDateMonth === getCurrentMonth
          );
        });
      }
    } catch (error) {
      SentryUtilites.setLog("DashboardPage:loadView", error)
    }

    //
  }

  reduceFunctionCall() {
    try {
      if(+new Date() > this.loadViewTimeStamp) {
        this.loadViewTimeStamp = +new Date() + 5000;
        this.calculateAmounts();
        this.loadView();
      }
    } catch (error) {
      SentryUtilites.setLog("DashboardPage:reduceFunctionCall", error)
    }
  }

  async isPermit(title: string): Promise<boolean> {
    try {
      let accessType = {
        Sale: 'viewDashboardSalesTotal',
        Purchase: 'viewDashboardPurchasesTotal',
        'Money In': 'viewDashboardMoneyInsTotal',
        'Money Out': 'viewDashboardMoneyOutsTotal',
        Receivable: 'viewDashboardReceivableTotal',
        Payable: 'viewDashboardPayableTotal',
        'Low Stock': 'viewDashboardLowStockCount',
        'Customer' : 'viewTodaysCustomer',
        'Loyal Customer': 'viewTodaysLoyalCustomer',
        'New Customer' : 'viewTodaysNewCustomer'
      };
      if (accessType[title]) {
        return await this.accessControlService.isUserHasAccess({
          action: accessType[title],
        });
      }
      return true;
    } catch (error) {
      SentryUtilites.setLog("DashboardPage:isPermit", error)
      return false;
    }
  }

  async setCardsVisibility() {
    try {
      let counter = 0;
      for (let i = 0; i < this.cardList?.length; i++) {
        this.cardList[i].isVisbile = await this.isPermit(this.cardList[i]?.title);
        if(!this.cardList[i]?.isVisbile) {
          counter++;
        }
      }
      if(counter > 4) {
        this.hideScroll = false;
      }
      this.showCards = true;
    } catch (error) {
      SentryUtilites.setLog("DashboardPage:setCardsVisibility", error)
    }
  }

  openSupportModal() {
    this.eventService.setShowContactSupportPopup(true);
  }

  async calculateAmounts() {
    try {
      let startTime: number = +new Date().setHours(0, 0, 0, 0);
      let endTime: number = startTime + 86400000 - 1;

      let isCutOffDayFilter = false;
      if (this.selectedProfile?.aAppCutOffDayStatus) {
        let latestCutOffDay = await this.allDataService.cutOffDayService.getLatestCutOff();
        let hours24Millis = 1000 * 60 * 60 * 24;
        if (latestCutOffDay?.endStamp && (+new Date() - latestCutOffDay?.endStamp) < hours24Millis) {
          startTime = latestCutOffDay?.endStamp;
          endTime = +new Date();
          isCutOffDayFilter = true;
        }
      }

      this.startTimeStamp = startTime;

      for (let i = 0; i < this.cardList?.length; i++) {
        const card = this.cardList[i];
        let list = null;
        if (this.allDataService && this.allDataService[card.serviceName]) {
          if (card.filter === 'Today') {
            if (isCutOffDayFilter) {
              list = await this.allDataService[card.serviceName].getByCreatedDateRange(
                startTime,
                endTime
              ) || [];
            } else {
              list = await this.allDataService[card.serviceName].getByBillDateRange(
                startTime,
                endTime
              ) || [];
            }
          } else if (card.filter === 'All') {
            list = await this.allDataService[card.serviceName].getAllByPromise();
          }
          if(card?.callback) {
            card.amount = await card?.callback(list);
          }
        } else {
          if(card?.callback) {
            card.amount = await card?.callback(list);
          }
        }
        card.loader = false;
      }
    } catch (error) {
      SentryUtilites.setLog("DashboardPage:calculateAmounts", error)
    }
  }

  generateTotalAmount(list: any[]) {
    try {
      let cardAmount = 0;
      list?.forEach((element) => {
        const totalAmount = Number(element?.totalAmount);
        if (isNaN(totalAmount) === false) {
          cardAmount += totalAmount;
        }
      });
      return cardAmount;
    } catch (error) {
      SentryUtilites.setLog("DashboardPage:generateTotalAmount", error)
      return 0;
    }
  }

  /**
   *
   * @param list : provide party list
   * @returns : receivable amount
   */
  async generateReceivableAmount(list: any[]) {
    try {
      let cardAmount = 0;
      let listLength = list?.length;

      const ledgerCreditHashMap = await this.monthWisePartyCreditService.getLedgerCreditHashMap();

      for(let i = 0; i < listLength; i++) {
        let element = list[i];
        let ledgerCredit = ledgerCreditHashMap[element?._localUUID];
        if (Utility.isNumber(ledgerCredit) && ledgerCredit > 0) {
          cardAmount += ledgerCredit;
        }
      }
      return cardAmount;
    } catch (error) {
      SentryUtilites.setLog("DashboardPage:generateReceivableAmount", error)
      return 0;
    }
  }
  // -------------------------------------------

  /**
   *
   * @param list : provide party list
   * @returns : payable amount
   */
  async generatePayableAmount(list: any[]) {
    try {
      let cardAmount = 0;
      let listLength = list?.length;

      const ledgerCreditHashMap = await this.monthWisePartyCreditService.getLedgerCreditHashMap();

      for(let i = 0; i < listLength; i++) {
        let element = list[i];
        let ledgerCredit = ledgerCreditHashMap[element?._localUUID];
        if (Utility.isNumber(ledgerCredit) && ledgerCredit < 0) {
          cardAmount += ledgerCredit;
        }
      }
      return Math.abs(cardAmount);
    } catch (error) {
      SentryUtilites.setLog("DashboardPage:generatePayableAmount", error)
      return 0;
    }
  }
  // -------------------------------------------

  /**
   *
   * @param list : provide item list
   * @returns : low stock amount
   */
  async generateLowStockCount(list: any[]) {
    try {
      let cardAmount = 0;
      let listLength = list?.length;

      const ledgerStockHashMap = await this.monthWiseItemStockService.getLedgerStockHashMap();

      for(let i = 0; i < listLength; i++) {
        let element = list[i];
        let ledgerStock = ledgerStockHashMap[element?._localUUID];
        let minStock = Number(element.minStock);
        minStock = minStock || 0;
        if (
          Utility.isNumber(ledgerStock) &&
          Utility.isNumber(minStock) &&
          ledgerStock <= minStock
        ) {
          cardAmount++;
        }
      }
      return cardAmount;
    } catch (error) {
      SentryUtilites.setLog("DashboardPage:generateLowStockCount", error)
      return 0;
    }
  }
  // -------------------------------------------

  /**
   *
   * @param list : list of sale between start and end stamp
   * @returns : total count of customer
   */
  generateTotalCustomerCount(list: any[]) {
    try {
      let totalCount = 0;
      list?.forEach((element) => {
        if (element?._localUUID) {
          totalCount++;
        }
      })
      return totalCount;
    } catch (error) {
      SentryUtilites.setLog("DashboardPage:generateTotalCustomerCount", error)
      return 0;
    }
  }
  // --------------------------------------

  /**
   *
   * @param list : list of sale
   * @returns : Count of repeat customers
   */
  async generateLoyalCustomerCount(list: any[]) {
    try {
      let parties = [];
      let allParty = await this.allDataService.partyService.getAllByPromise() || [];
      let filteredParties = allParty.filter(doc => doc?.createdStamp < this.startTimeStamp && doc?.lastSaleStamp > this.startTimeStamp);
      list?.forEach(element => {
        let index = filteredParties?.findIndex(el => el?._localUUID === element?.partySecondary?._localUUID);
        let partyIndex = parties?.findIndex(el => el?._localUUID === filteredParties[index]?._localUUID)
        if (index !== -1 && partyIndex === -1) {
          parties?.push(filteredParties[index]);
        }
      })
      return parties?.length;
    } catch (error) {
      SentryUtilites.setLog("DashboardPage:generateLoyalCustomerCount", error)
      return 0;
    }
  }
  // --------------------------------------

  /**
   *
   * @returns : New customers visited the store/outlet
   */
  generateNewCustomerCount() {
    return (this.cardList[7].amount || 0) - (this.cardList[8].amount || 0);
  }
  // --------------------------------------

  /**
   *
   * @returns : return google Score Count
   */
  generateScoreCount() {
    return `${Math.round(this.locationMetricData?.locationMetrics?.googleProfileScore)} / ${this.locationMetricData?.locationMetrics?.googleProfileScoreOutOf}`;
  }
  // ------------------------------------------

  /**
   *
   * @returns : return google Reply Pending Count
   */
  generateReplyPendingCount() {
    return `${Math.round(this.locationMetricData?.locationMetrics?.googleProfileReviewsWithPendingReplyCount)} / ${this.locationMetricData?.locationMetrics?.googleProfileReviewsOutOfCount}`;
  }
  // ------------------------------------------

  /**
   *
   * @returns : return google Reviews Count
   */
  generateReviewsCount() {
    return `${this.locationMetricData?.locationMetrics?.googleProfileReviewsCount}`;
  }
  // ------------------------------------------

  /**
   *
   * @returns : return google Views Count
   */
  generateViewsCount() {
    return `${this.locationMetricData?.locationMetrics?.viewsOnGoogle}`;
  }
  // ------------------------------------------

  /**
   *
   * @returns : return google Engagements Count
   */
  generateEngagementsCount() {
    return `${this.locationMetricData?.locationMetrics?.engagementOnGoogle}`;
  }
  // ------------------------------------------

  async navigateToSale() {
    try {
      let isCreateSale = !(this.isPremiumAccess) ? (await this.allDataService.saleService.nonPremiumSaleCeditRemaining()) > 0 : true;
      if (!isCreateSale) {
        return this.eventService.setShowPremiumPopup(true, true);
      }
      this.router.navigate(['sale/form']);
    } catch (error) {
      SentryUtilites.setLog("DashboardPage:navigateToSale", error)
      alert("Something went wrong.");
    }
  }

  async populatePrivacySetting() {
    try {
      const profile =
        await this.allDataService.profileService.getCurrentProfile();
      if (profile) {
        if (
          profile.aAppDashboardPrivacyStatus === false ||
          profile.aAppDashboardPrivacyStatus === true
        ) {
          this.privacyStatus = profile.aAppDashboardPrivacyStatus;
        }
      }
    } catch (error) {
      SentryUtilites.setLog("DashboardPage:populatePrivacySetting", error)
    }
  }

  async onPrivacySettingUpdate(event: any) {
    try {
      this.privacyStatus = event?.detail?.checked;
      const profile =
        await this.allDataService.profileService.getCurrentProfile();
      if (profile?.aAppDashboardPrivacyStatus != this.privacyStatus) {
        profile.aAppDashboardPrivacyStatus = this.privacyStatus;
        await this.allDataService.profileService.update(profile);
        this.allDataService.listForceReloadSubs.next('profile-list');
      }
    } catch (error) {
      SentryUtilites.setLog("DashboardPage:onPrivacySettingUpdate", error)
    }
  }

  emitElement(sale: Sale) {
    this.router.navigate([`sale/form/${sale?._localUUID}`]);
  }

  async delete(sale: Sale) {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({
        action: 'deleteSale',
      });
      if (!isPermit) {
        return alert(
          "Permission: You don't have permission to delete sale. Please contact to your owner."
        );
      }
      this.deleteDataEle?.initDeleteSale(sale);
    } catch (error) {
      SentryUtilites.setLog("DashboardPage:delete", error)
      alert("Something went wrong.");
    }
  }

  onDelete(sale: Sale) {
    try {
      if (sale?._localUUID && sale?.deletedStamp) {
        let index1 = this.completeList.findIndex(
          (x) => x?._localUUID === sale?._localUUID
        );
        index1 != -1 && this.completeList.splice(index1, 1);
        this.viewFilteredList = [...this.completeList.slice(0, 50)];
      }
    } catch (error) {
      SentryUtilites.setLog("DashboardPage:onDelete", error)
    }
  }

  resetSelectedSale() {
    this.selectedSale = null;
  }

  addPayment(sale: Sale) {
    this.selectedSale = sale;
    this.showMoneyInSelector = true;
  }

  async closeMoneySelectorModal() {
    try {
      this.showMoneyInSelector = false;
      this.allDataService.saleService.reloadList();
      this.allDataService.listForceReloadSubs.next('sale-list');
      await Utility.wait(2000);
      this.allDataService.listForceReloadSubs.next('sale-list');
    } catch (error) {
      SentryUtilites.setLog("DashboardPage:closeMoneySelectorModal", error)
    }
  }

  onListTypeChange(event: any) {
    try {
      let value = event?.detail?.value;
      if (value == 'RecentTransaction') {
        this.isRecentTransactionList = true;
        this.isBirthdayReminderList = false;
        this.isPaymentReminderList = false;
      }
      if (value == 'PaymentReminders') {
        this.isPaymentReminderList = true;
        this.isRecentTransactionList = false;
        this.isBirthdayReminderList = false;

      }
      if (value == 'BirthdayReminders') {
        this.isBirthdayReminderList = true;
        this.isRecentTransactionList = false;
        this.isPaymentReminderList = false;
      }
    } catch (error) {
      SentryUtilites.setLog("DashboardPage:onListTypeChange", error)
    }
  }

  async endDay() {
    try {
      this.cutOffDayAlert = await this.alertController.create({
        header: 'Cut Off Day Alert!',
        message: 'Would you like to end the day?',
        inputs: [
          {
            type: 'text',
            placeholder: 'Note',
            name: 'note',
          }
        ],
        buttons: [
          {
            text: 'No',
            role: 'cancel',
            handler: () => {
              Utility.setToLocalStorage(`_no_cutoff_day_alert_stamp-${this.selectedProfile?._localUUID?.substr(-4)}`, +new Date());
              this.cutoffDayAlertOpen = false;
              this.cutOffDayAlert.dismiss();
            },
          },
          {
            text: 'Yes',
            role: 'confirm',
            handler: async (value) => {
              let note = value?.note;
              const cutOffDay = new CutOffDay();
              cutOffDay.note = note || '';
              let savedCutOffDay = await this.allDataService.cutOffDayService.save(cutOffDay);
              if (savedCutOffDay?._localUUID) {
                this.allDataService.listForceReloadSubs.next('cut-off-day-list');
                this.reduceFunctionCall();
                this.commonService.generateToast('success', 'Success');
              }
              Utility.setToLocalStorage(`_no_cutoff_day_alert_stamp-${this.selectedProfile?._localUUID?.substr(-4)}`, +new Date());
              this.cutoffDayAlertOpen = false;
              this.cutOffDayAlert.dismiss();
            },
          },
        ],
        mode: 'ios',
      });
      await this.cutOffDayAlert.present();
    } catch (error) {
      SentryUtilites.setLog("DashboardPage:endDay", error)
    }
  }

  toggleOption() {
    this.isOptionOpen = !this.isOptionOpen;
  }

  clickOutSide() {
    this.isOptionOpen = false;
  }

  keepOpenOption() {
    setTimeout(() => {
      this.isOptionOpen = true;
    }, 0)
  }

  async getAllUnsyncData() {
    this.unsyncedRecordsCount = (await this.allDataService.getAllUnsyncData())?.totalUnsyncedRecords;
  }

  openSlider() {
    this.newFeatureSlidesEle?.openNewFeatureSlider();
  }

  /**
   * @description: check sale credit remaining for non premium
   * @param routerLink : navigation router
   * @returns : stop excution of code further if sale credit are exhausted and show error modal
   */
  async checkSaleCredit(routerLink: string) {
    try {
      if (routerLink.includes('sale')) {
        let checkCreateSale = await this.premiumControlService.checkCreateSale();
        if (checkCreateSale) {
          this.router.navigate([routerLink]);
        }
      } else {
        this.router.navigate([routerLink]);
      }
    } catch (error) {
      SentryUtilites.setLog("DashboardPage:checkSaleCredit", error)
    }
  }
  // ----------------------------------------------------------------------

  loadMoreListData(event) {
    try {
      if (this.viewFilteredList.length > 0 && this.viewFilteredList.length <= this.completeList.length) {
        let appendListLength = this.completeList.length - this.viewFilteredList.length;
        let lastEl = this.viewFilteredList[this.viewFilteredList.length - 1];
        let counter = 0;
        for (let i = 0; i < this.completeList.length; i++) {
          if (this.completeList[i]._localUUID == lastEl._localUUID) {
            counter = 1;
            continue;
          }
          if (counter > 0 && appendListLength >= 50) {
            if (counter <= 50) {
              this.viewFilteredList.push({ ...this.completeList[i] })
            } else {
              break;
            }
            counter++;
          } else if (counter > 0 && appendListLength < 50) {
            if (counter <= appendListLength) {
              this.viewFilteredList.push({ ...this.completeList[i] })
            } else {
              break;
            }
            counter++;
          }
        }
        (event as InfiniteScrollCustomEvent)?.target?.complete();
      }
    } catch (error) {
      SentryUtilites.setLog("DashboardPage:loadMoreListData", error)
    }
  }

  /**
   * @description : right scroll content inside container
   */
  rightScroll() {
    try {
      let scrollContainer = document.getElementById('scroll-container');
      if(scrollContainer){
        scrollContainer.scrollLeft += 5000;
        let scrollWidth = scrollContainer.scrollWidth;
        let actualScroll = scrollContainer.scrollLeft;
        let scrollPercent = (actualScroll * 100 / scrollWidth);
        if(scrollPercent > 5) {
          this.hideRightScroll = true;
        }
      }
    } catch (error) {
      SentryUtilites.setLog("DashboardPage:rightScroll", error)
    }

  }
  // --------------------------------

  /**
   * @description : left scroll content inside container
   */
  leftScroll() {
    try {
      let scrollContainer = document.getElementById('scroll-container');
      if(scrollContainer){
        scrollContainer.scrollLeft -= 5000;
        let scrollWidth = scrollContainer.scrollWidth;
        let actualScroll = scrollContainer.scrollLeft;
        let scrollPercent = (actualScroll * 100 / scrollWidth);
        if (scrollPercent < 5) {
          this.hideRightScroll = false;
        }
      }
    } catch (error) {
      SentryUtilites.setLog("DashboardPage:leftScroll", error)
    }
  }
  // --------------------------------

  /**
   * @description : right scroll gmb content inside container
   */
  rightGMBScroll() {
    try {
      let scrollGMBContainer = document.getElementById('scroll-gmb-container');
      if(scrollGMBContainer){
        scrollGMBContainer.scrollLeft += 5000;
        let scrollWidth = scrollGMBContainer.scrollWidth;
        let actualScroll = scrollGMBContainer.scrollLeft;
        let scrollPercent = (actualScroll * 100 / scrollWidth);
        if(scrollPercent > 5) {
          this.hideGMBRightScroll = true;
        }
      }
    } catch (error) {
      SentryUtilites.setLog("DashboardPage:rightGMBScroll", error)
    }
  }
  // --------------------------------

  /**
   * @description : left scroll gmb content inside container
   */
  leftGMBScroll() {
    try {
      let scrollGMBContainer = document.getElementById('scroll-gmb-container');
      if(scrollGMBContainer){
        scrollGMBContainer.scrollLeft -= 5000;
        let scrollWidth = scrollGMBContainer.scrollWidth;
        let actualScroll = scrollGMBContainer.scrollLeft;
        let scrollPercent = (actualScroll * 100 / scrollWidth);
        if (scrollPercent < 5) {
          this.hideGMBRightScroll = false;
        }
      }
    } catch (error) {
      SentryUtilites.setLog("DashboardPage:leftGMBScroll", error)
    }

  }
  // --------------------------------

   onDateRangeChange(event) {
    try {
      this.selectedDateRange = event?.detail?.value;

      // Current Date
      const currentDate = new Date();
      const getCurrentDate = currentDate.getDate();
      const getCurrentMonth = currentDate.getMonth();

      // Function to filter birthday list
      let condition:Function;

      // filtering based on selected date range
      if (this.selectedDateRange === 'today') {
          condition = (birthDateDate:number, birthDateMonth:number) =>
              birthDateDate === getCurrentDate && birthDateMonth === getCurrentMonth;
      } else if (this.selectedDateRange === 'tomorrow') {
          condition = (birthDateDate:number, birthDateMonth:number) =>
              birthDateDate === (getCurrentDate + 1) && birthDateMonth === getCurrentMonth;
      } else if (this.selectedDateRange === 'week') {
          condition = (birthDateDate:number, birthDateMonth:number) =>
              (getCurrentDate + 1) < birthDateDate && birthDateDate <= getCurrentDate + 7 &&
              birthDateMonth === getCurrentMonth;
      }

      // Filter birthday list
      this.birthdayFilterList = this.birthdayCompleteList.filter((party) => {
          let dob = new Date(party?.dateOfBirth);
          let birthDateDate = dob.getDate();
          let birthDateMonth = dob.getMonth();
          party['daysToGo'] = birthDateDate - getCurrentDate;
          party['birthDate'] = dob
          return condition(birthDateDate, birthDateMonth);
      });

      // Sort birthday list
      this.birthdayFilterList = this.birthdayFilterList.sort((a, b) => a?.dateOfBirth - b?.dateOfBirth);
    } catch (error) {
      SentryUtilites.setLog("DashboardPage:onDateRangeChange", error)
    }
}

  /**
   * @description : navigate to google-mybusiness
   */
  getMoreCustomers() {
    //this.router.navigate(['google-mybusiness']);
    window.open(
      `https://gmc.ezobooks.in/`,
      '_blank'
    );
  }
  // -----------------------------------------

  /**
   *
   * @param isRefresh : provide true if you click on refresh button
   * @description : call getLocationMetrics api
   */
  async getLocationMetrics(isRefresh?: boolean) {
    try {
      this.locationMetricData = await this.googleMybusinessService.getLocationMetrics();
      if(this.locationMetricData?.locationMetrics){
        this.isLocationMetricsHasData = Boolean(Object.keys(this.locationMetricData?.locationMetrics)?.length);
        this.getLocationMetricsData();
        if(isRefresh) {
          this.commonService.generateToast('success', 'Refresh Location Metrics Data');
        }
      }
    } catch (error) {
      SentryUtilites.setLog("DashboardPage:getLocationMetrics", error)
    }
  }
  // -----------------------------------------

  /**
   * @description : integrate value of google my buisness cards
   */
  async getLocationMetricsData() {
    try {
      let googleCardList = this.cardList.slice(10);
      for (let i = 0; i < googleCardList.length; i++) {
        const card = googleCardList[i];
        if(card?.callback) {
          card.amount = await card?.callback(this.locationMetricData);
        }
      }
    } catch (error) {
      SentryUtilites.setLog("DashboardPage:getLocationMetricsData", error)
    }
  }
  // -----------------------------------------

  /**
   *
   * @param title : provide title of card you click
   * @description : redirect to getGoogleProfileScoreData or getGoogleRatingReviews as you click on respective cards
   */
  googleNavigate(title) {
    try {
      let url = '';
      let userId =  Utility.getFromLocalStorage('selectedProfileUserId');
      let profileId =  Utility.getFromLocalStorage('selectedProfile');
      if(title === 'Google Score') {
        url = `${environment.ezoOfServerGmb}getGoogleProfileScoreData/${userId}/${profileId}`;
      } else if(
        title === 'Reply Pending' ||
        title === 'Reviews (Lifetime)'
      ) {
        url = `${environment.ezoOfServerGmb}getGoogleRatingReviews/${userId}/${profileId}`;
      }
      if(url) {
        window.open(
            url,
            '_blank'
        );
      }
    } catch (error) {
      SentryUtilites.setLog("DashboardPage:googleNavigate", error)
    }
  }
  // -----------------------------------------

}
