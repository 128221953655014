<ion-header>
  <ion-toolbar class="{{ getHeaderColorClass() }}">
    <app-navigation-control slot="start" type="ingredient/form"></app-navigation-control>
    <ion-title>Create Raw Material</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>

  <app-ingredient-form
    #itemFormEle
    (saveTrigger)="saveTrigger($event)"
    (updateTrigger)="updateTrigger($event)"
  >
  </app-ingredient-form>

</ion-content>