import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { SentryUtilites } from '../../utils/sentryUtilites';
import { InfiniteScrollCustomEvent, IonSearchbar } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { Utility } from '../../utils/utility';
import { IPurchaseReturn, PurchaseReturn } from '../../models/PurchaseReturn.model';
import { AllDataService } from '../../services/all-data.service';
import { Router } from '@angular/router';
import { AccessControlService } from '../../services/auth/access-control.service';
import { CommonService } from '../../services/common.service';
import { PurchaseReturnBulkDeleteComponent } from '../../components/bulk-delete/purchase-return-bulk-delete/purchase-return-bulk-delete.component';

@Component({
  selector: 'app-purchase-return',
  templateUrl: './purchase-return.page.html',
  styleUrls: ['./purchase-return.page.scss'],
})
export class PurchaseReturnPage implements OnInit {

  @ViewChild('deleteDataEle') deleteDataEle: PurchaseReturnBulkDeleteComponent;
  @ViewChild('searchBar') searchBar: IonSearchbar;

  getHeaderColorClass = Utility.getHeaderColorClass;

  lastSyncTime = Utility.getCollectionLastRespSyncTime(IPurchaseReturn.SCHEMA_NAME);
  lastSyncTimeSub: Subscription;
  isTimeDifference = false;

  isMobile: boolean = null;
  innerHeight: string = '';

  filteredList: PurchaseReturn[] = [];
  completeList: PurchaseReturn[] = [];
  viewFilteredList: PurchaseReturn[] = [];

  appliedCategory = '';
  selectedFilter = '';

  isFilterOptionOpen = false;
  isOptionOpen: boolean = false;

  subsArr: Subscription[] = [];

  selectedPurchaseReturn: PurchaseReturn = null;
  showMoneyInSelector = false;

  fromStamp: number = null;
  toStamp: number = null;

  isBinList = false;

  loadViewTimeStamp: number = 0;

  isNgOnInitRun: boolean = false;

  getAddition = Utility.getAddition;

  constructor(
    private allDataService: AllDataService,
    private router: Router,
    private accessControlService: AccessControlService,
    private commonService: CommonService,
  ) { }

  ngOnInit() {
    try {
      this.isNgOnInitRun = true;
      this.isMobile = this.commonService.isMobile();
      if(this.isMobile) {
        this.innerHeight = `${(((window?.innerHeight - 268)/window?.innerHeight) * 100)}%`;
      } else {
        this.innerHeight = `${(((window?.innerHeight - 174)/window?.innerHeight) * 100)}%`;
      }
      this.subsArr.push(this.allDataService.lastSyncSubs.subscribe(x => {
        this.lastSyncTime = Utility.getCollectionLastRespSyncTime(IPurchaseReturn.SCHEMA_NAME);
        this.isTimeDifference = this.allDataService.isTimeDifference;
      }));
      this.subsArr.push(this.allDataService.listForceReloadSubs.subscribe(async (listName: string) => {
        if (listName == 'purchase-return-list') {
          // added wait for get save all response from server for purchase return sync
          await Utility.wait(1000);
          this.reduceFunctionCall();
        }
      }))
  
      this.subsArr.push(this.allDataService.purchaseReturnService.updateSubs
        .subscribe((purchaseReturn: PurchaseReturn) => {
          if (purchaseReturn) {
            let completeListIndex = this.completeList.findIndex(el => el?._localUUID === purchaseReturn?._localUUID);
            if(completeListIndex != -1) {
              if(purchaseReturn?.deletedStamp) {
                this.completeList.splice(completeListIndex, 1);
              } else {
                this.completeList[completeListIndex] = purchaseReturn;
              }
            }
            let filteredListIndex = this.filteredList.findIndex(el => el?._localUUID === purchaseReturn?._localUUID);
            if(filteredListIndex != -1) {
              if(purchaseReturn?.deletedStamp) {
                this.filteredList.splice(filteredListIndex, 1);
              } else {
                this.filteredList[filteredListIndex] = purchaseReturn;
              }
            }
            let viewFilteredListIndex = this.viewFilteredList.findIndex(el => el?._localUUID === purchaseReturn?._localUUID);
            if(viewFilteredListIndex != -1) {
              if(purchaseReturn?.deletedStamp) {
                this.viewFilteredList.splice(viewFilteredListIndex, 1);
              } else {
                this.viewFilteredList[viewFilteredListIndex] = purchaseReturn;
              }
            }
          }
        })
      )
    } catch (error) {
      SentryUtilites.setLog("PurchaseReturnPage:ngOnInit", error)
    }
  }

  ionViewWillEnter() {
    this.reduceFunctionCall();
    if(!this.isNgOnInitRun) {
      this.ngOnInit();
    }
  }

  async loadView() {
    try {
      this.filteredList = [];
      this.completeList = [];
      this.viewFilteredList = [];
  
      this.appliedCategory = '';
      this.selectedFilter = '';
  
      this.isFilterOptionOpen = false;
  
      this.completeList = await this.allDataService.purchaseReturnService.getAllWithDeletedByProfile() || [];
      this.onFilterChange(this.lastSearchKey);
    } catch (error) {
      SentryUtilites.setLog("PurchaseReturnPage:loadView", error)
    }

  }

  reduceFunctionCall() {
    try {
      if(+new Date() > this.loadViewTimeStamp) {
        this.loadViewTimeStamp = +new Date() + 5000;
        this.loadView();
      }
    } catch (error) {
      SentryUtilites.setLog("PurchaseReturnPage:reduceFunctionCall", error)
    }
  }

  onBinListFilterChange(event) {
    this.isBinList = event?.detail?.checked;
    if(this.searchBar) {
      this.searchBar.value = '';
    }
    this.applyFilter({
      searchKeyword: '',
      selectedFilter: this.selectedFilter,
      category: this.appliedCategory
    })
  }

  toggleFilterOptions() {
    this.isFilterOptionOpen = !this.isFilterOptionOpen;
  }

  onCategoryChange(category: string) {
    this.appliedCategory = category;
    this.onFilterChange();
  }

  onSortChnage(selectedFilter: string) {
    this.selectedFilter = selectedFilter;
    this.onFilterChange();
  }

  isFilterPostpond = false;
  lastFilterStamp = 0;
  onFilterChange(searchKey?: string) {
    try {
      if (this.isFilterPostpond) {
        return true;
      }
      if (this.lastFilterStamp < +new Date() - 200) {
        this.lastFilterStamp = +new Date();
        this.applyFilter({
          searchKeyword: searchKey,
          selectedFilter: this.selectedFilter,
          category: this.appliedCategory
        })
      } else {
        this.isFilterPostpond = true;
        setTimeout(() => {
          this.isFilterPostpond = false;
          this.onFilterChange(searchKey);
        }, 250);
      }
    } catch (error) {
      SentryUtilites.setLog("PurchaseReturnPage:onFilterChange", error)
    }
  }


  lastSearchKey = '';
  async applyFilter(params: {
    searchKeyword?: string,
    selectedFilter?: string,
    category?: string
  }) {
    try {
      let arr = [];
      let lsearchKey = '';
      let isSearchIncremental = false;
      let isSearchCleared = false;
      if (params?.searchKeyword?.length) {
        lsearchKey = Utility.toLowerCase(params.searchKeyword);
        if (lsearchKey.indexOf(this.lastSearchKey) == 0) {
          isSearchIncremental = true;
        }
        this.lastSearchKey = lsearchKey;
      }
      if (this.lastSearchKey.length > 0 && lsearchKey.length == 0) {
        isSearchCleared = true;
        this.lastSearchKey = '';
      }
      if(!this.filteredList?.length && isSearchIncremental) {
        this.filteredList = [...this.completeList];
      }
      let completeList = [];
      if (isSearchIncremental) {
        completeList = [...this.filteredList]
      } else {
        completeList = [...this.completeList];
      }
  
      if(this.isBinList) {
        completeList = await this.allDataService.purchaseReturnService.getAllDeleted();
      }else {
        completeList = completeList?.filter(x => !x?.deletedStamp);
      }
  
      if (lsearchKey.length) {
        completeList?.forEach((el) => {
          if (
            Utility.toLowerCase(el?.billNo).indexOf(lsearchKey) != -1
            || Utility.toLowerCase(el?.party?.name).indexOf(lsearchKey) != -1
            // || Utility.toLowerCase(el.party.phone).indexOf(lsearchKey) != -1
          ) {
            arr.push(el);
          }
        });
  
      } else {
        arr = [...completeList]
      }
  
  
      if (params.selectedFilter == 'SortByNameAsc') {
  
      } else if (params.selectedFilter == 'SortByNameDesc') {
  
      } else if (params.selectedFilter == 'SortByCreditAsc') {
  
      } else if (params.selectedFilter == 'SortByCreditDesc') {
  
      }
  
      if(this.fromStamp && this.toStamp) {
        arr = arr.filter(sale => sale?.billDateStamp>=this.fromStamp && sale?.billDateStamp<=this.toStamp);
      }
  
      this.filteredList = [...arr];
  
      if (lsearchKey.length || isSearchCleared || (this.fromStamp && this.toStamp) || this.isBinList) {
        this.viewFilteredList = [...this.filteredList.slice(0, 50)];
      } else if (!this.viewFilteredList.length) {
        this.viewFilteredList = [...this.filteredList.slice(0, 50)];
      }
    } catch (error) {
      SentryUtilites.setLog("PurchaseReturnPage:applyFilter", error)
    }

  }


  loadMoreListData(event) {
    try {
      if (this.viewFilteredList.length > 0 && this.viewFilteredList.length <= this.filteredList.length) {
        let appendListLength = this.filteredList.length - this.viewFilteredList.length;
        let lastEl = this.viewFilteredList[this.viewFilteredList.length - 1];
        let counter = 0;
        for (let i = 0; i < this.filteredList.length; i++) {
          if (this.filteredList[i]._localUUID == lastEl._localUUID) {
            counter = 1;
            continue;
          }
          if (counter > 0 && appendListLength >= 50) {
            if (counter <= 50) {
              this.viewFilteredList.push({ ...this.filteredList[i] })
            } else {
              break;
            }
            counter++;
          } else if(counter > 0 && appendListLength < 50) {
            if (counter <= appendListLength) {
              this.viewFilteredList.push({ ...this.filteredList[i] })
            } else {
              break;
            }
            counter++;
          }
        }
        (event as InfiniteScrollCustomEvent)?.target?.complete();
      } 
    } catch (error) {
      SentryUtilites.setLog("PurchaseReturnPage:loadMoreListData", error)
    }
  }

  async onNewPurchaseClick() {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({action:'createPurchaseReturn'});
      if(!isPermit) {
        return alert("Permission: You don't have permission to create purchase return. Please contact to your owner.");
      }
      this.router.navigate([`purchase-return/form`]);
    } catch (error) {
      SentryUtilites.setLog("PurchaseReturnPage:onNewPurchaseClick", error)
      alert("Something went wrong.");
    }
  }

  ionViewWillLeave() {
    this.ngOnDestroy();
  }

  ngOnDestroy() {
    try {
      this.isNgOnInitRun = false;
      this.subsArr?.forEach((el) => {
        el.unsubscribe();
      })
      this.lastSyncTimeSub?.unsubscribe();
    } catch (error) {
      SentryUtilites.setLog("PurchaseReturnPage:ngOnDestroy", error)
    }
  }

  emitElement(t: PurchaseReturn) {

  }

  onDateRangeChange(data:{fromStamp:number,toStamp:number}) {
    this.fromStamp = data.fromStamp;
    this.toStamp = data.toStamp;
    this.onFilterChange();
  }

  async delete(purchase: PurchaseReturn) {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({action:'deletePurchaseReturn'});
      if(!isPermit) {
        return alert("Permission: You don't have permission to delete purchase return. Please contact to your owner.");
      }
      this.deleteDataEle?.initDeletePurchaseReturn(purchase);
    } catch (error) {
      SentryUtilites.setLog("PurchaseReturnPage:delete", error)
      alert("Something went wrong.");
    }
  }

  onDelete(record: PurchaseReturn) {
    try {
      if(record?._localUUID && record?.deletedStamp) {
        let index1 = this.completeList.findIndex(x => x?._localUUID===record?._localUUID);
        index1 != -1 && this.completeList.splice(index1,1);
        let index2 = this.viewFilteredList.findIndex(x => x?._localUUID===record?._localUUID);
        index2 != -1 && this.viewFilteredList.splice(index2,1);
      }
    } catch (error) {
      SentryUtilites.setLog("PurchaseReturnPage:onDelete", error)
    }
  }

  async bulkDelete() {
    try {
      let isPermit = await this.accessControlService.isUserHasAccess({action:'deletePurchaseReturn'});
      if(!isPermit) {
        return alert("Permission: You don't have permission to delete purchase return. Please contact to your owner.");
      }
      this.deleteDataEle?.initDeleteBulkPurchaseReturn();
    } catch (error) {
      SentryUtilites.setLog("PurchaseReturnPage:bulkDelete", error)
      alert("Something went wrong.");
    }
  }

  onBulkDelete(success: boolean) {
    success && this.reduceFunctionCall();
  }

  addPayment(purchase: PurchaseReturn) {
    this.selectedPurchaseReturn = purchase;
    this.showMoneyInSelector = true;
  }

  async onMoneyInCartUpdate() {
    try {

    } catch (error) {
      SentryUtilites.setLog("PurchaseReturnPage:onMoneyInCartUpdate", error)
    }
  }

  toggleOption() {
    this.isOptionOpen = !this.isOptionOpen;
  }

  clickOutSide() {
    this.isOptionOpen = false;
  }

  keepOpenOption() {
    setTimeout(() => {
      this.isOptionOpen = true;
    }, 0)
  }

  @HostListener('window:resize', ['$event'])
  OnResize() {
    try {
      setTimeout(() => {
        this.isMobile = this.commonService.isMobile();
        if(this.isMobile) {
          this.innerHeight = `${(((window?.innerHeight - 268)/window?.innerHeight) * 100)}%`;
        } else {
          this.innerHeight = `${(((window?.innerHeight - 174)/window?.innerHeight) * 100)}%`;
        }
      }, 50)
    } catch (error) {
      SentryUtilites.setLog("PurchaseReturnPage:OnResize", error)
    }
  }

}
