import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Expense } from '../../../models/Expense.model';
import { PinVerificationComponent } from '../../pin-verification/pin-verification.component';
import { AlertController, InfiniteScrollCustomEvent } from '@ionic/angular';
import { AllDataService } from '../../../services/all-data.service';
import { SentryUtilites } from 'src/app/utils/sentryUtilites';

@Component({
  selector: 'app-expense-bulk-delete',
  templateUrl: './expense-bulk-delete.component.html',
  styleUrls: ['./expense-bulk-delete.component.scss'],
})
export class ExpenseBulkDeleteComponent implements OnInit {

  @Output() onExpenseDelete = new EventEmitter<Expense>();
  @Output() onBulkExpenseDelete = new EventEmitter<boolean>();
  @ViewChild('pinVerificationElement') pinVerificationElement: PinVerificationComponent;
  @Input() fromStamp:number = null;
  @Input() toStamp: number = null;

    // Expense
    isExpenseModalOpen = false;
    expense: Expense = null;
    isDeleteExpenseAttachedMoneyOuts = true;
    expenseDeleteStarted = false;
    expenseDeleteCompleted = false;
    //----------------------------------------------------

    // Bulk Expense
    isBulkExpenseModalOpen = false;
    checkAllExpenseCheckboxes: boolean = false;
    bulkExpenses: Expense[] = [];
    selectedBulkExpenses: Expense[] = [];
    bulkExpenseDeleteStarted = false;
    bulkExpenseDeleteCompleted = false;
  //----------------------------------------------------

  // Security Pin common functionality var
  selectedVar: string = '';
  bulkSelectArray: string[] = [];
  viewFilteredList:Expense[] = [];
  // ---------------------------------

  constructor(
    private allDataService: AllDataService,
    private alertController: AlertController,
  ) { }

  ngOnInit() {}
  
  openTransactionPINModal() {
    this.pinVerificationElement?.openTransactionPINModal();
  }

  async verifyTransactionPIN(event) {
    try {
      if(event){
        if(this.selectedVar == 'Expense'){
         this.openExpenseModal(true);
        }else if(this.selectedVar == 'BulkExpense'){
          this.openBulkExpenseModal(true);
          await this.onDateRangeChange();
          //edit bulk sale
          this.bulkExpenses?.forEach((el)=> {
            el["checked"] = false
          })
        }
      }else{
        this.openBulkExpenseModal(false);
        this.openExpenseModal(false);
      }
    } catch (error) {
      SentryUtilites.setLog("ExpenseBulkDeleteComponent:verifyTransactionPIN", error)
    }
  }

  setCheckboxVarValue(variable: string, event: any) {
    this[variable] = event?.detail?.checked;
  }

  async deleteConfirm(): Promise<boolean> {
    return new Promise(async (resolve,reject) => {
      try {
        const alert = await this.alertController.create({
          mode: 'ios',
          header: 'Please Confirm!',
          message: 'Are you sure want to delete?',
          buttons: [
            {
              text: 'No',
              role: 'cancel',
              handler: () => {
                resolve(false);
                return true;
              },
            },
            {
              text: 'Yes',
              role: 'confirm',
              handler: () => {
                resolve(true);
                return true;
              },
            },
          ],
        });
        await alert.present();
      } catch (error) {
        SentryUtilites.setLog("ExpenseBulkDeleteComponent:deleteConfirm", error)
        return false;
      }
    });
  }

  async deleteMoneyOutConfirm(): Promise<boolean> {
    return new Promise(async (resolve,reject) => {
      try {
        const alert = await this.alertController.create({
          mode: 'ios',
          header: `Also Delete Attached Money Out's`,
          message: 'Are you sure want to delete?',
          buttons: [
            {
              text: 'No',
              role: 'cancel',
              handler: () => {
                resolve(false);
                return true;
              },
            },
            {
              text: 'Yes',
              role: 'confirm',
              handler: () => {
                resolve(true);
                return true;
              },
            },
          ],
        });
        await alert.present();
      } catch (error) {
        SentryUtilites.setLog("ExpenseBulkDeleteComponent:deleteMoneyOutConfirm", error)
        return resolve(false);
      }
    });
  }

  async onDateRangeChange(){
    try {
      this.viewFilteredList = await this.allDataService?.expenseService?.getByBillDateRange(this.fromStamp, this.toStamp) || [];
      this.viewFilteredList = this.viewFilteredList?.filter(x => !x?.deletedStamp);
      this.bulkExpenses = this.viewFilteredList.slice(0,50);
    } catch (error) {
      SentryUtilites.setLog("ExpenseBulkDeleteComponent:onDateRangeChange", error)
    }
  }

  // Expense

  async openExpenseModal(value: boolean = true) {
    try {
      if(value) {
        if ((await this.deleteConfirm()) === false) return;
      }
      this.isExpenseModalOpen = value;
    } catch (error) {
      SentryUtilites.setLog("ExpenseBulkDeleteComponent:openExpenseModal", error)
    }
  }

  async initDeleteExpense(expense: Expense) {
    try {
      this.expense = expense;
      this.selectedVar = 'Expense';
      this.openTransactionPINModal();
    } catch (error) {
      SentryUtilites.setLog("ExpenseBulkDeleteComponent:initDeleteExpense", error)
    }
  }

  async deleteExpense() {
    try {
      this.expenseDeleteStarted = true;
      let deleteExpense = await this.allDataService.expenseService.delete(this.expense);
      if (deleteExpense?.deletedStamp) {
        this.expense = deleteExpense;
        this.expenseDeleteCompleted = true;
        this.allDataService.moneyOutService.reloadList();
        this.allDataService.partyService.reloadList();
        this.allDataService.listForceReloadSubs.next('expense-list');
        setTimeout(() => {
          this.openExpenseModal(false);
          this.expense = null;
          this.isDeleteExpenseAttachedMoneyOuts = false;
          this.expenseDeleteStarted = false;
          this.expenseDeleteCompleted = false;
          setTimeout(() => {
            this.onExpenseDelete.emit(deleteExpense);
          }, 1000);
        }, 500);
      }
    } catch (error) {
      SentryUtilites.setLog("ExpenseBulkDeleteComponent:deleteExpense", error)
    }
  }

  //--------------------------------------------------------------------------------

  // Bulk Expense

  async openBulkExpenseModal(value: boolean = true) {
    try {
      if(value) {
        if ((await this.deleteConfirm()) === false) return;
      }
      this.isBulkExpenseModalOpen = value
      this.selectedBulkExpenses = [];
    } catch (error) {
      SentryUtilites.setLog("ExpenseBulkDeleteComponent:openBulkExpenseModal", error)
    }
  }

  async initDeleteBulkExpense() {
    try {
      this.selectedVar = 'BulkExpense';
      if(!this.bulkSelectArray.includes('BulkExpense')) {
        this.bulkSelectArray.push('BulkExpense');
      }
      this.openTransactionPINModal();
    } catch (error) {
      SentryUtilites.setLog("ExpenseBulkDeleteComponent:initDeleteBulkExpense", error)
    }
  }

  /**
   * 
   * @param expense : Provide Expense object
   * @param event : ionic CustomEventInit
   * @description : this method add checked Expense in selectedBulkExpenses array 
   *                if unchecked it will that Expense from selectedBulkExpenses array
   */
  addToSelectedBulkExpenseArr(expense: Expense,event: CustomEventInit) {
    try {
      const index = this.selectedBulkExpenses?.findIndex(x => x?._localUUID === expense?._localUUID);
      this.bulkExpenses?.forEach(x => {
        if(x?._localUUID === expense?._localUUID){
          x["checked"] = event?.detail?.checked;
        }
      });
      if(event?.detail?.checked) {
        if(index == -1){
          this.selectedBulkExpenses.push(expense);    
        }
      }else {
        if(index != -1) {
          this.selectedBulkExpenses.splice(index,1);
        }
      }
    } catch (error) {
      SentryUtilites.setLog("ExpenseBulkDeleteComponent:addToSelectedBulkExpenseArr", error)
    }
  }
  // --------------------------------------------

  async deleteBulkExpense() {
    try {
      if(await this.deleteMoneyOutConfirm()){
        this.bulkExpenseDeleteStarted = true;
        if(this.selectedBulkExpenses?.length) {
          for (let i = 0; i < this.selectedBulkExpenses.length; i++) {
            let expense = this.selectedBulkExpenses[i];
            await this.allDataService.expenseService.delete(expense);
          }
        }
  
        this.bulkExpenseDeleteCompleted = true;
        this.allDataService.moneyOutService.reloadList();
        this.allDataService.partyService.reloadList();
        this.allDataService.listForceReloadSubs.next('expense-list');
  
        setTimeout(() => {
          this.openBulkExpenseModal(false);
          this.bulkExpenses = [];
          this.selectedBulkExpenses = [];
          this.bulkExpenseDeleteStarted = false;
          this.bulkExpenseDeleteCompleted = false;
          setTimeout(() => {
            this.onBulkExpenseDelete.emit(true);
          }, 1000);
        }, 500);
      }
    } catch (error) {
      SentryUtilites.setLog("ExpenseBulkDeleteComponent:deleteBulkExpense", error)
    }
  }

  /**
   * 
   * @param event : ionic CustomEventInit
   * @description : this method push all Expenses in selectedBulkExpenses if select all checkbox is checked
   */
  selectAllExpenseCheckboxes(event: CustomEventInit){
    try {
      if(this.checkAllExpenseCheckboxes) {
        if(event?.detail?.checked) {
          this.viewFilteredList?.forEach((el)=> {el["checked"] = true});
          this.selectedBulkExpenses = [...this.viewFilteredList];
        } else {
          this.bulkExpenses?.forEach((el)=> {el["checked"] = false});
          this.viewFilteredList?.forEach((el)=> {el["checked"] = false});
          this.selectedBulkExpenses = [];
        }
      }
      this.checkAllExpenseCheckboxes = false;
    } catch (error) {
      SentryUtilites.setLog("ExpenseBulkDeleteComponent:selectAllExpenseCheckboxes", error)
    }
  }
  // --------------------------------------

  /**
   * @description: this method check user is click on checkbox or not.
   */
  selectAllExpenseCheckboxClick() {
    this.checkAllExpenseCheckboxes = true;
  }

  //--------------------------------------------------------------------------------

  loadMoreListData(event) {
    try {
      if (this.bulkExpenses.length > 0 && this.bulkExpenses.length <= this.viewFilteredList.length) {
        let appendListLength = this.viewFilteredList.length - this.bulkExpenses.length;
        let lastEl = this.bulkExpenses[this.bulkExpenses.length - 1];
        let counter = 0;
        for (let i = 0; i < this.viewFilteredList.length; i++) {
          if (this.viewFilteredList[i]._localUUID == lastEl._localUUID) {
            counter = 1;
            continue;
          }
          if (counter > 0 && appendListLength >= 52) {
            if (counter <= 52) {
              this.bulkExpenses.push(this.viewFilteredList[i])
            } else {
              break;
            }
            counter++;
          } else if(counter > 0 && appendListLength < 52) {
            if (counter <= appendListLength) {
              this.bulkExpenses.push(this.viewFilteredList[i])
            } else {
              break;
            }
            counter++;
          }
        }
        (event as InfiniteScrollCustomEvent).target.complete();
      }
    } catch (error) {
      SentryUtilites.setLog("ExpenseBulkDeleteComponent:loadMoreListData", error)
    }
  }
}
